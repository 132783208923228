import { Component, OnInit, Input, OnChanges, SimpleChange, Output, EventEmitter } from "@angular/core";
import { trigger, state, style, animate, transition } from "@angular/animations";
import { IProductOptionSelection } from "../../../core/services/bag.service";
import { MainService } from "src/app/core/services/main.service";
import { TranslocoService } from '@ngneat/transloco';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { ProductOptionType, ProductOption, Product } from 'src/app/core/models';
import Dinero from 'dinero.js';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ProductNoLongerAvailableComponent } from "src/app/modals/product-no-longer-available/product-no-longer-available.component";

@Component({
    selector: "app-restaurant-item-options",
    templateUrl: "./restaurant-item-options.component.html",
    styleUrls: ["./restaurant-item-options.component.css"],
    animations: [
        trigger("openClose", [
            state(
                "open",
                style({
                    transform: "translateX(0)",
                })
            ),
            state(
                "closed",
                style({
                    transform: "translateX(100%)",
                })
            ),
            transition("open => closed", [animate("275ms ease-in")]),
            transition("closed => open", [animate("325ms ease-in")]),
        ]),
    ],
})
export class RestaurantItemOptionsComponent implements OnInit, OnChanges {

    @Input() item: Product;
    @Output() clearItem: EventEmitter<any> = new EventEmitter<any>();
    // @Output() inconsistentMenuError: EventEmitter<Product> = new EventEmitter<Product>();

    quantity: number = 1;
    open: boolean = false;
    loading: boolean = false;

    optionsForm: UntypedFormGroup

    constructor(
        private mainService: MainService,
        public translate: TranslocoService,
        private fb: UntypedFormBuilder,
        private modalService: NgbModal
    ) {
        this.optionsForm = fb.group({})
    }

    ngOnInit() { }

    ngOnChanges(changes: { [propName: string]: SimpleChange }) {
        if (
            changes["item"] &&
            changes["item"].currentValue &&
            changes["item"].currentValue !== changes["item"].previousValue
        ) {
            this.open = true;
            let groups = new UntypedFormGroup({})
            this.clearUnavailableAndArchivedOptions()
            this.item.options.forEach(
                option => {
                    let group = new UntypedFormGroup({})
                    option.products.forEach(
                        product => group.addControl(product.id, new UntypedFormControl(false))
                    )
                    groups['checkbox-group-' + option.id] = group
                }
            )
            this.optionsForm = this.fb.group(groups)
        }
    }

    clearUnavailableAndArchivedOptions() {
        this.item.options.forEach(
            (option: ProductOption) => option.products = option.products.filter(
                (p: Product) => p.isAvailable && !p.isArchived
            )
        )
    }

    getSelectedOptions(): IProductOptionSelection[] {
        let selectedOptions: IProductOptionSelection[] = []
        this.item.options.forEach(
            option => option.products.forEach(
                product => {
                    if (this.optionsForm.controls['checkbox-group-' + option.id].value[product.id]) {
                        selectedOptions.push({
                            productId: product.id,
                            id: option.id
                        });
                    }
                }
            )
        )
        return selectedOptions;
    }
    
    getCount(optionId): Number{
        let count = 0;
        for (let key in this.optionsForm.controls['checkbox-group-' + optionId].value){
            if (this.optionsForm.controls['checkbox-group-' + optionId].value[key]){
                count++
            }
        }
        return count
    }
    isSelected(optionId, productId): boolean {
        if (this.optionsForm.controls['checkbox-group-' + optionId].value[productId]){
            return true;
        }
        else {
            return false;
        }
        
    }
    setControlValue(optionId, productId) {
        for (let key in this.optionsForm.controls['checkbox-group-' + optionId].value) {
            if (key == productId) {
                this.optionsForm.controls['checkbox-group-' + optionId].value[key] = true
            } else {
                this.optionsForm.controls['checkbox-group-' + optionId].value[key]= false
            }
        }
    }
    checkboxClick(event, type: string, limit: number, optionId: string, productId: string) {
        if (type == ProductOptionType.Supplemental) {
            // this.handleSupplementalOptions(limit, optionId) //TODO: doesn't even do anything??
            return
        }
        let numSelected = 0
        
        for (let key in this.optionsForm.controls['checkbox-group-' + optionId].value) {
            if (this.optionsForm.controls['checkbox-group-' + optionId].value[key]) {
                numSelected++
            }
        }
        if (event.target.checked === true && numSelected > limit) {
            this.optionsForm.get(('checkbox-group-' + optionId) + '.' + productId).patchValue(false)
        }
    }

    showPrice(product: Product, type: string, limit: number, optionId: string) {
        if (type == ProductOptionType.Supplemental) {
            let numSelected = 0
            for (let key in this.optionsForm.controls['checkbox-group-' + optionId].value) {
                if (this.optionsForm.controls['checkbox-group-' + optionId].value[key]) {
                    numSelected++
                }
            }

            if (numSelected > limit) {
                if (!product.price.isZero()) {
                    if (this.optionsForm.controls['checkbox-group-' + optionId].value[product.id]) return this.item.options.find(o => o.id == optionId).products.slice(0).sort((a, b) => (a.price > b.price) ? 1 : -1).slice(0, limit).findIndex(p => p.id == product.id) == -1
                    else return true
                } else {
                    return false
                }
            } else if (numSelected == limit) {
                if (!product.price.isZero() && !this.optionsForm.controls['checkbox-group-' + optionId].value[product.id]) return true
                else return false
            } else return false
        }
        return !product.price.isZero();
    }

    // handleSupplementalOptions(limit: number, optionId: string) {
    //     let numSelected = 0;
    //     for (let key in this.optionsForm.controls['checkbox-group-' + optionId].value) {
    //         if (this.optionsForm.controls['checkbox-group-' + optionId].value[key]) {
    //             numSelected++;
    //         }
    //     }
    // }

    isFormValid(): boolean {
        

        if (!this.item.options || this.item.options.length === 0) return true;

        let count = 0;
        for (let option of this.item.options) {
            if (option.limits.type === ProductOptionType.Required) {
                count += option.limits.quantity;
            }
        }
        for (let option of this.item.options) {

            let numSelected = 0;
            for (let key in this.optionsForm.controls['checkbox-group-' + option.id].value) {
                if (this.optionsForm.controls['checkbox-group-' + option.id].value[key]) {
                    numSelected++;
                }
            }
            switch (option.limits.type) {
                case ProductOptionType.Required:
                    count -= numSelected;
                    if (numSelected === option.limits.quantity) {
                        continue;
                    }
                    return count === 0;
                case ProductOptionType.Maximal:
                    if (numSelected <= option.limits.quantity) {
                        continue;
                    }
                    return numSelected <= option.limits.quantity;
            }
        }

        return count === 0;
    }

    submitClick(note: string) {
        let selectedOptions = this.getSelectedOptions()
        this.loading = true;
        this.mainService.addBagItem(this.mainService.bag, this.item, this.quantity, note, selectedOptions.length > 0 ? selectedOptions : null).subscribe({
            next: () => {},
            error: (err) => {
                switch(err.error.error) {
                    case 'InconsistentMenu':
                        this.productInconsistentError([this.item]);
                        break;
                }
            },
            complete: () => this.reset()
        });
    }

    productInconsistentError(items: Product[]) {
        const modalRef = this.modalService.open(ProductNoLongerAvailableComponent, {
            backdrop: 'static',
            keyboard: false,
            centered: true
        });
        modalRef.componentInstance.header = "productRecentlyRemovedModal.header";
        modalRef.componentInstance.headerParams = { productCount: items.length };
        modalRef.componentInstance.text = "productRecentlyRemovedModal.message";
        modalRef.componentInstance.textParams = { productCount: items.length };
        modalRef.componentInstance.submitButtonText = "productRecentlyRemovedModal.button";
        modalRef.componentInstance.items = items;
        modalRef.result.then(_ => window.location.reload());
    }

    decreaseQuantity(): void {
        if (this.quantity > 1) this.quantity--;
    }

    increaseQuantity(): void {
        if (this.quantity < 99) this.quantity++;
    }

    calculateTotalCost(): Dinero.Dinero {
        return this.item.price.add(this.calculateCostOfOptions()).multiply(this.quantity);
    }

    //TODO: Extract to product model
    calculateCostOfOptions(): Dinero.Dinero {
        let cost = Dinero({ amount: 0, currency: 'CAD' });
        this.item.options.forEach(option => {
            switch (option.limits.type) {
                case ProductOptionType.Required:
                case ProductOptionType.Maximal:
                case ProductOptionType.None:
                    option.products.forEach(product => {
                        if (this.optionsForm.controls['checkbox-group-' + option.id].value[product.id]) {
                            cost = cost.add(product.price);
                        }
                    })
                    break;
                case ProductOptionType.Supplemental:
                    let productsSelected: Product[] = []
                    option.products.forEach(product => {
                        if (this.optionsForm.controls['checkbox-group-' + option.id].value[product.id]) {
                            productsSelected.push(product)
                        }
                    })

                    if (productsSelected.length > option.limits.quantity) {
                        let freeProducts = productsSelected.slice(0).sort((a, b) => (a.price > b.price) ? 1 : -1).slice(0, option.limits.quantity)
                        productsSelected.forEach(product => {
                            if (freeProducts.findIndex(p => p.id == product.id) == -1) cost = cost.add(product.price);
                        })
                    }
                    break;
            }
        })
        return cost;
    }

    closeOptionsMenu(): void {
        this.clearItem.emit(true)
        this.open = false;
    }

    getNumber(quantity) {
        if (quantity > 3) return quantity;
        else if (quantity == 1) {
            if (this.translate.getActiveLang() == 'en') return 'one'
            else return 'une'
        }
        else if (quantity == 2) {
            if (this.translate.getActiveLang() == 'en') return 'two'
            else return 'deux'
        }
        else if (quantity == 3) {
            if (this.translate.getActiveLang() == 'en') return 'three'
            else return 'trois'
        }
    }

    reset() {
        this.loading = false;
        this.quantity = 1;
        this.open = false;
        this.item = null;
        this.clearItem.emit(true)
    }
}
