<ng-container *ngIf="configService.getPageVersion((restaurant$ | async)?.slug) === RestaurantStyle.Classic">
    <div
        *ngIf="(restaurant$ | async) && reviews"
        class="restaurant-about-container"
        [ngClass]="configService.getCssClass((restaurant$ | async)?.slug)">

        <div class="row">
            <div class="col-sm-12 col-md-6 order-2 order-sm-2 order-md-1 restaurant-about-first-column">
                <!-- <div class="restaurant-about-section card" *ngIf="(restaurant$ | async)?.slug === 'koji-soupe'">
                    <h5 class="restaurant-about-virtual-restaurant">{{ 'restaurant.about.virtual' | transloco }}</h5>
                </div> -->
                <app-restaurant-about-menu-favorites
                    *ngIf="(restaurant$ | async)?.slug != RestaurantSlug.LundisAuSoleil &&
                        (restaurant$ | async)?.slug != RestaurantSlug.OMLT &&
                        (restaurant$ | async)?.slug != RestaurantSlug.Wallys &&
                        (restaurant$ | async)?.slug != RestaurantSlug.HungarianCuisine &&
                        (restaurant$ | async)?.slug != RestaurantSlug.BernieBeigne &&
                        (restaurant$ | async)?.slug != RestaurantSlug.PoissonnerieSherbrooke"
                    class="restaurant-about-section card"
                    [restaurant]="restaurant$ | async">
                </app-restaurant-about-menu-favorites>
                <app-restaurant-about-photo-information class="restaurant-about-section card"
                    [restaurant]="restaurant$ | async">
                </app-restaurant-about-photo-information>
                <!-- <app-restaurant-about-mentions class="restaurant-about-section card" [restaurant]="restaurant">
                </app-restaurant-about-mentions> -->
            </div>

            <div class="col-sm-12 col-md-6 order-1 order-sm-1 order-md-2 restaurant-about-second-column">
                <app-restaurant-about-important
                    class="restaurant-about-section card"
                    [restaurant]="restaurant$ | async"
                    [reviews]="reviews">
                </app-restaurant-about-important>

                <!-- RestoPage TODO: If a resto is not on Instagram, add its slug to ngIf below to hide section on page -->
                <app-restaurant-about-instagram
                    class="restaurant-about-section card"
                    *ngIf="(restaurant$ | async)?.slug != RestaurantSlug.MerRouge &&
                        (restaurant$ | async)?.slug != RestaurantSlug.InochiExpress &&
                        (restaurant$ | async)?.slug != RestaurantSlug.ChezTauBay &&
                        (restaurant$ | async)?.slug != RestaurantSlug.CafeVita &&
                        (restaurant$ | async)?.slug != RestaurantSlug.Wallys &&
                        (restaurant$ | async)?.slug != RestaurantSlug.Dakar &&
                        (restaurant$ | async)?.slug != RestaurantSlug.ElephantThai &&
                        (restaurant$ | async)?.slug != RestaurantSlug.Sushiyo &&
                        (restaurant$ | async)?.slug != RestaurantSlug.JrsCafe &&
                        (restaurant$ | async)?.slug != RestaurantSlug.UbiSushi &&
                        (restaurant$ | async)?.slug != RestaurantSlug.ObsceneVegan &&
                        (restaurant$ | async)?.slug != RestaurantSlug.LestersDeli &&
                        (restaurant$ | async)?.slug != RestaurantSlug.Barranco &&
                        (restaurant$ | async)?.slug != RestaurantSlug.PetitSeoul &&
                        (restaurant$ | async)?.slug != RestaurantSlug.Hooters &&
                        (restaurant$ | async)?.slug != RestaurantSlug.Darbar &&
                        (restaurant$ | async)?.slug != RestaurantSlug.JardinPetros &&
                        (restaurant$ | async)?.slug != RestaurantSlug.ChezAnna &&
                        (restaurant$ | async)?.slug != RestaurantSlug.Krood &&
                        (restaurant$ | async)?.slug != RestaurantSlug.HungarianCuisine &&
                        (restaurant$ | async)?.slug != RestaurantSlug.Maynard &&
                        (restaurant$ | async)?.slug != RestaurantSlug.MFourBurritos &&
                        (restaurant$ | async)?.slug != RestaurantSlug.Bocadillo &&
                        (restaurant$ | async)?.slug != RestaurantSlug.LaurierBBQ &&
                        (restaurant$ | async)?.slug != RestaurantSlug.oFish &&
                        (restaurant$ | async)?.slug != RestaurantSlug.SushiSama &&
                        (restaurant$ | async)?.slug != RestaurantSlug.TeochewFoodie &&
                        (restaurant$ | async)?.slug != RestaurantSlug.Tapigo &&
                        (restaurant$ | async)?.slug != RestaurantSlug.AlAmine &&
                        (restaurant$ | async)?.slug != RestaurantSlug.PokeStationCdn &&
                        (restaurant$ | async)?.slug != RestaurantSlug.PaniniStop &&
                        (restaurant$ | async)?.slug != RestaurantSlug.BrigadePizza &&
                        (restaurant$ | async)?.slug != RestaurantSlug.BernieBeigne &&
                        (restaurant$ | async)?.slug != RestaurantSlug.PoissonnerieSherbrooke &&
                        (restaurant$ | async)?.slug != RestaurantSlug.Cacao70 &&
                        (restaurant$ | async)?.slug != RestaurantSlug.BistroDeLaCite &&
                        (restaurant$ | async)?.slug != RestaurantSlug.OsukaSushi &&
                        (restaurant$ | async)?.slug != RestaurantSlug.Chatpata &&
                        (restaurant$ | async)?.slug != RestaurantSlug.PizzaSport &&
                        (restaurant$ | async)?.slug != RestaurantSlug.MaisonDuDestin &&
                        (restaurant$ | async)?.slug != RestaurantSlug.FlatBellyDeli"
                    [restaurant]="restaurant$ | async">
                </app-restaurant-about-instagram>
                <!--animated bird for Frite Alors! page-->
                <div class="seagull-container" *ngIf="(restaurant$ | async)?.slug === RestaurantSlug.FriteAlors">
                    <img alt="seagull" src="../../../../assets/images/frite-alors/frite-alors-seagull.png" class="seagull">
                </div>
            </div>
        </div>
    </div>
</ng-container>
