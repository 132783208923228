import Dinero from 'dinero.js';

import { Deserializable } from '../deserializable.model';

import { BagItem } from './bag-item.model';
import { Restaurant } from '../restaurant.model';
import { Menu } from '../menu/menu.model';
import { Address } from '../address.model';
import { OrderType } from '../../enums/order-type';
import { Product } from '../product';

export class Bag implements Deserializable<Bag> {
	_id: string;
	restaurant?: Restaurant;
	address?: Address;
	items?: BagItem[];
	isCompleted?: boolean = false;
	_notes?: string;
	menu?: Menu;
	type: OrderType;
	isGuest?: boolean = true;

	constructor() {

	}

	deserialize(input: any): this {
		if (!input) return null
		if (typeof input == 'string') this._id = input;
		else {
			Object.assign(this, input);
			this.id = input._id ? input._id : input.id
			this.restaurant = new Restaurant().deserialize(input?.restaurant);
			this.items = input?.items?.map((item) => new BagItem().deserialize(item));
			this.menu = new Menu().deserialize(input?.menu);
			this.address = new Address().deserialize(input.address);

			if (input.user || input.group) this.isGuest = false;
		}
		return this;
	}

	get id(): string {
		return this._id;
	}

	set id(input: string) {
		this._id = input;
	}

	get notes(): string {
		return this._notes;
	}

	set notes(notes: string) {
		this._notes = notes.trim();
	}

	get isEmpty(): boolean {
		return !this.items.length;
	}

	get itemCount(): number {
		return this.items.reduce((count, item) => count += item.quantity, 0);
	}

	get subtotal(): Dinero.Dinero {
		return this.items.reduce((subtotal, item) => subtotal = subtotal.add(item.price), Dinero({ amount: 0, currency: 'CAD' }));
	}

	get isPlatformType(): boolean {
		return (this.type == 'delivery' || this.type == 'pickup')
	}

	get isMarket(): boolean {
		return this.type == 'market';
	}

	get isExpo(): boolean {
		return this.type == 'expo';
	}

	get isGiftBox(): boolean {
		return this.type == 'gift_box';
	}


	hasItem(product: Product): boolean {
		return this.items.findIndex(item => item.product.id === product.id) != -1;
	}

	getItemByProduct(product: Product): BagItem {
		return this.items.find(item => item.product.id === product.id);
	}

	getVariationsByProduct(product: Product): BagItem[] {
		return this.items?.filter(item => item.product.id === product.id);
	}

	getItemsInMenu(menu: Menu): BagItem[] {
		return this.items?.filter(item => menu.products.find(product => product.id === item.product.id));
	}

	getItemsMissingInMenu(menu: Menu): BagItem[] {
		return this.items?.filter(item => !menu.products.find(product => product.id === item.product.id));
	}
}