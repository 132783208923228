<form action="submit" class="tip-form-container" [formGroup]="expoTipForm">
    <div class="tip-form-title">{{ 'expo.checkout.tips.title' | transloco }}</div>
    <div class="tip-container">
        <div class="tip-options">
            <input id="tip_15" type="radio" value="15" formControlName="tipRadio" (change)="updateTipRadio()" />
            <label class="tip-option-label" for="tip_15">15%</label>
            <input id="tip_18" type="radio" value="18" formControlName="tipRadio" (change)="updateTipRadio()" />
            <label class="tip-option-label" for="tip_18">18%</label>
            <input id="tip_20" type="radio" value="20" formControlName="tipRadio" (change)="updateTipRadio()" />
            <label class="tip-option-label" for="tip_20">20%</label>
            <input decimals="2" class="tip-option-custom box-input form-input"
                (keypress)="onCustomTipKeypress($event)" (input)="onCustomTipChange()"
                formControlName="tipAmountCustom" type="text" placeholder="Amount" appTipKeypress />
        </div>
    </div>
</form>
<!-- <ng-container *ngIf="addTip">
                    <div #tip class="checkout-tip-options">
                        <input #tip id="tip_3" type="radio" value="3" formControlName="tipRadio"
                            (change)="tipRadioChange()" />
                        <label #tip class="checkout-tip-option-label" for="tip_3">$3</label>
                        <input #tip id="tip_5" type="radio" value="5" formControlName="tipRadio"
                            (change)="tipRadioChange()" />
                        <label #tip class="checkout-tip-option-label" for="tip_5">$5</label>
                        <input #tip id="tip_7" type="radio" value="7" formControlName="tipRadio"
                            (change)="tipRadioChange()" />
                        <label #tip class="checkout-tip-option-label" for="tip_7">$7</label>
                        <input #tip decimals="2" class="checkout-tip-option-custom checkout-box-input form-input"
                            (keypress)="onCustomTipKeypress($event)" (input)="onCustomTipChange($event.target.value)"
                            formControlName="tipRadio" type="text" placeholder="Amount" appTipKeypress />
                        <button #tip type="button" (click)="onApplyTip()"
                            class="checkout-tip-option-apply expo-checkout-add-button">{{'promoCodeModal.applyCode'
                            | transloco }}</button>
                    </div>
                </ng-container>
                <button type="button" *ngIf="showPaymentRequestButton && !expoCheckoutForm?.value?.tipAmountCustom"
                    (click)="onAddTip()" class="expo-checkout-order-tip-button expo-checkout-add-button" #tip> {{
                    'checkout.askRestaurantTip' |
                    transloco }}</button>
                <div *ngIf="showPaymentRequestButton && expoCheckoutForm?.value?.tipAmountCustom"
                    class="expo-checkout-order-tip-wrapper">
                    <div class="expo-checkout-order-tip-label-edit-wrapper">
                        <div class="expo-checkout-order-tip-label"> {{ 'checkout.driverTip' | transloco }}</div>
                        <button #tip (click)="onAddTip()" type="button"
                            class="expo-checkout-order-edit-button expo-checkout-add-button">{{ 'checkout.edit'
                            | transloco }}</button>
                    </div>
                    <div class="expo-checkout-order-tip-amount expo-checkout-order-fee-amount">{{
                        expoCheckoutForm?.value?.tipAmountCustom | translocoCurrency }}</div>
                </div> -->