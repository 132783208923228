import { Component, OnInit, ViewChild } from '@angular/core';
import {
	UntypedFormGroup,
	UntypedFormBuilder,
	Validators
} from '@angular/forms';
import { Router } from '@angular/router';
import { RegistrationValidator } from '../shared/validators/register.validator';
import { PhoneNumberComponent } from 'ngx-international-phone-number2'
import { MainService } from '../core/services/main.service';


@Component({
	selector: 'app-register',
	templateUrl: './register.component.html',
	styleUrls: ['./register.component.css'],
})
export class RegisterComponent implements OnInit {

	nameForm: UntypedFormGroup;
	passwordForm: UntypedFormGroup;
	registrationForm: UntypedFormGroup;
	emailInUse: boolean;
	numberInUse: boolean;
	phoneInvalid: boolean;
	emailInvalid: boolean;
	unknownError: boolean;

	loading: boolean = false;

    @ViewChild(PhoneNumberComponent) phoneNumber: PhoneNumberComponent

	constructor(
		private fb: UntypedFormBuilder,
		private router: Router,
		private mainService: MainService
	) {
		this.nameForm = this.fb.group({
			first: ['', Validators.required],
			last: ['', Validators.required],
		});

		this.passwordForm = this.fb.group(
			{
				password: ['', [Validators.required, Validators.minLength(8)]],
				confirmpassword: ['', Validators.required],
			},
			{
				validator: RegistrationValidator('password', 'confirmpassword'),
			}
		);

		this.registrationForm = this.fb.group({
			email: ['', [Validators.required, Validators.email]],
			name: this.nameForm,
			password: this.passwordForm,
			number: [''],
			receiveEmails: [false],
			terms: [''],
		});
	}

	redirectUser() {
        this.router.navigate(['verification'], {queryParams: {
            number: this.phoneNumber.phoneNumberOnly,
            countryCode: this.phoneNumber.selectedCountry.countryCode
        }});
	}

	get registrationFormControls() {
		return this.registrationForm.controls;
	}
	get passwordFormControls() {
		return this.passwordForm.controls;
	}
	get nameFormControls() {
		return this.nameForm.controls;
	}

	ngOnInit() {
	}

	onSubmit(data) {
		this.loading = true;
		this.clearErrors();
		if (this.registrationForm.invalid) return;

		let registrationData = {
			firstName: data.name.first.trim(),
			lastName: data.name.last.trim(),
			password: data.password.password,
			email: data.email,
			number: data.number,
			receiveEmails: data.receiveEmails
		}

		this.mainService.register(registrationData).subscribe({ 
			complete: () => this.redirectUser(),
			error: err => this.handleError(err) });
	}

	handleError(err): void {
		if (err.error.error) {
			switch (err.error.error) {
				case 'ExistingEmail':
					this.emailInUse = true;
					break;
				case 'ExistingNumber':
					this.numberInUse = true;
                    break;
                case 'InvalidEmail':
                    this.emailInvalid = true;
                    break;
                default:
                    this.unknownError = true;
                    break;
			}
		}
		else if (err.error.length > 0) {
			for (let error of err.error) {
				switch (error.msg) {
					case 'PhoneInvalid':
						this.phoneInvalid = true;
						break;
					case 'EmailInvalid':
						this.emailInvalid = true;
                        break;
                    default:
                        this.unknownError = true;
                        break;
				}
			}
		} else {
			this.unknownError = true;
		}
		this.loading = false;
	}

	clearErrors(): void {
		this.emailInUse = false;
		this.numberInUse = false;
		this.phoneInvalid = false;
		this.emailInvalid = false;
		this.unknownError = false;
	}
}
