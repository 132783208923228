<div class="menu-item-container" [style.font-family]="font">
    <app-item-with-option-update
        *ngIf="product.options && product.options.length > 0 && productIncrementOptionsToShow == product.id"
        [product]="product" [bag]="bag" [type]="'increment'" class="item-with-option-update"
        (onNewItemClick)="onNewItemWithOptionsClick($event)" (onClick)="itemOptionsClickHandler($event)">
    </app-item-with-option-update>
    <app-item-with-option-update
        *ngIf="product.options && product.options.length > 0 && productDecrementOptionsToShow == product.id"
        [product]="product" [bag]="bag" [type]="'decrement'" class="item-with-option-update"
        (onNewItemClick)="onNewItemWithOptionsClick($event)" (onClick)="itemOptionsClickHandler($event)">
    </app-item-with-option-update>
    <div *ngIf="image" class="image-container" [style.border-radius]="borderRadius">
        <img [src]="image" alt="">
    </div>
    <div class="content-container">
        <div class="title" [innerHTML]="product.title"></div>
        <app-read-more *ngIf="showDescription && product.description" [content]="product.description" [limit]="40" [fontWeight]="descriptionFontWeight" [color]="descriptionColor" [showReadMoreText]="showReadMoreText"></app-read-more>
        <!-- <div class="icon-container">O</div> -->
        <div class="price-controls-container">
            <div class="price">{{ product.price | price | translocoCurrency }}</div>
            <div class="controls-container">
                <app-menu-item-control-button class="minus-control-button" [class.invisible]="!isItemInBag"
                    [disabled]="disableModifyProductCount"
                    (onButtonClicked)="removeItem(product)"
                    [color]="buttonColor"
                    [iconColor]="buttonIconColor"
                    [disabledColor]="disabledButtonColor"
                    [borderRadius]="borderRadius"
                    [type]="MenuItemButtonType.Minus"
                ></app-menu-item-control-button>
                <div [ngbTooltip]="tooltip" [disableTooltip]="!disableModifyProductCount"
                        tooltipClass="radish-tooltip">
                    <app-menu-item-control-button
                        [disabled]="disableModifyProductCount"
                        (onButtonClicked)="addItem(product)"
                        [color]="buttonColor"
                        [disabledColor]="disabledButtonColor"
                        [iconColor]="buttonIconColor"
                        [borderRadius]="borderRadius"
                        [type]="MenuItemButtonType.Plus"
                    ></app-menu-item-control-button>
                </div>
            </div>
        </div>
    </div>
</div>
