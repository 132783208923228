import { User, Promotion, TranslatedText, TranslatedTextType, Media } from ".";
import { OrderType } from "../enums";
import { Deserializable } from "./deserializable.model";

export class Affiliate implements Deserializable<Affiliate> {

	_id: string;
	_title?: TranslatedText;
	_brief?: TranslatedText;
    user?: User;
	promotion?: Promotion;
	type: OrderType;
    // events: AffiliateEvent[];
	assets?: {
        header?: Media;
	};
    slug: string;

	constructor() { }

	deserialize(input: any): this {
		if (!input) return null;
		if (typeof input == 'string') this.id = input;
		else {
			Object.assign(this, input);
			this.id = input._id ? input._id : input.id;

			this.title = new TranslatedText().deserialize(input.title);
			this.brief = new TranslatedText().deserialize(input.brief);
			this.user = new User().deserialize(input.user);
			this.promotion = new Promotion().deserialize(input.promotion);
			this.assets = input.assets ? input.assets : {}
            this.assets.header = new Media().deserialize(input.assets?.header)
		}

		return this;
	}

	get id(): string {
		return this._id;
	}

	set id(id: string) {
		this._id = id;
	}

	get title(): TranslatedTextType {
		return this._title?.value;
	}

	set title(title: TranslatedTextType) {
		this._title = title as TranslatedText;
	}

	get brief(): TranslatedTextType {
		return this._brief?.value;
	}

	set brief(brief: TranslatedTextType) {
		this._brief = brief as TranslatedText;
	}

	get thumbnailUrl(): string {
        return this.assets?.header?.thumbnailUrl
    }
}