<div class="expo-restaurant-listing-container">
    <ngb-carousel #carousel [interval]="3000" [showNavigationIndicators]="showNavigationIndicators" [showNavigationArrows]="showNavigationArrows">
        <ng-template ngbSlide *ngFor="let item of carouselItems; index as i">
            <div class="carousel-caption">
                <h3>{{ item.title | transloco }}</h3>
            </div>
            <a href="" target="_blank" rel="nofollow noopener noreferrer">
                <div class="picsum-img-wrapper">
                    <img [src]="item.image" alt="">
                </div>
            </a>
        </ng-template>
    </ngb-carousel>
    <h2 class="expo-restaurant-listing-title">{{ 'expo.restaurantListing.restaurantTitle' | transloco }}</h2>
    <div class="expo-restaurants">
        <a *ngFor="let v of exposition?.vendors" class="expo-restaurant" [routerLink]="['./r/' + v.vendor.slug]">
            <div class="restaurant-image-container">
                <div class="time-icon-wrapper" [ngStyle]="{'color': getTimeIconColor(v.delay)}">
                    <div class="duration">{{ getDelayInMinutes(v.delay) }}</div>
                    <div class="minutes">{{ 'expo.restaurantListing.ETADelayMinutes' | transloco }}</div>
                </div>
                <ng-container *ngIf="v.vendor?.assets?.header?.url; else noHeaderImage">
                    <img class="restaurant-image" [src]="v.vendor?.assets?.header?.url" *ngIf="v.vendor?.slug != 'nakamichi'; else nakamichiHeader">
                    <ng-template #nakamichiHeader>
                        <img class="restaurant-image" src="../../../../assets/images/expo/pocha/nakamichi-pocha-header.jpg" >
                    </ng-template>
                </ng-container>
                <ng-template #noHeaderImage>
                    <img class="restaurant-image black-header" src="../../../../assets/images/expo/pocha/rectangle-bg.jpg">
                    <div class="restaurant-header-name">{{ v.vendor?.name }}</div>
                </ng-template>
            </div>
            <p class="restaurant-name" *ngIf="v.vendor?.slug != 'nakamichi'; else nakamichiTitle">{{ v.vendor?.name }}</p>
            <ng-template #nakamichiTitle>
                <p class="restaurant-name">Chef Sunpeong (Nakamichi)</p>
            </ng-template>
            <p class="order-underway">{{ 'expo.restaurantListing.ordersUnderway' | transloco : { orderAmount: getOrderCount(v.orderCount) } }} {{ getEmojiFromOrderUnderway(v.orderCount) }}</p>
        </a>
    </div>
</div>