import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslocoService } from '@ngneat/transloco';
import {  Observable } from 'rxjs';
import { User, UserStubData } from 'src/app/core/models';
import { Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'expo-checkout-details',
    templateUrl: './details.component.html',
    styleUrls: ['./details.component.css']
})
export class ExpoCheckoutDetailsComponent implements OnInit {

    @ViewChild('nameInput') nameInput: ElementRef;
    @ViewChild('numberInput') numberInput: ElementRef;
    @ViewChild('addressInput') addressInput: ElementRef;

    @Input() set userStub(userStub: UserStubData) { this.setFormData(userStub) };
    @Output() detailsCompleted = new EventEmitter<UserStubData>();
    
    user$: Observable<User>;

    expoCheckoutDetailsForm: FormGroup;

    isName: boolean = false;
    isNumber: boolean = false;
    isEmail: boolean = false;
    isLoading: boolean = false;
    error: boolean = false;
    
    constructor(
        public translate: TranslocoService,
        private formBuilder: FormBuilder
    ) { 

        this.expoCheckoutDetailsForm = this.formBuilder.group({
            name: ['', [Validators.required, Validators.min(2), Validators.max(128)]],
            number: ['', [Validators.required, Validators.pattern('[- +()0-9]{6,}')]],
            email: ['', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
            giftCardCheckbox: [true]
        });
    }

    ngOnInit(): void {
    }

    setFormData(userStub: UserStubData) {
        if (userStub == null) return;
        let name = '';

        if (userStub?.name?.first) name += userStub.name.first;
        if (userStub?.name?.last) name += (' ' + userStub.name.last);

        this.expoCheckoutDetailsForm.setValue({
            name: name,
            number: userStub.number,
            email: userStub.email,
            giftCardCheckbox: true
        })
    }

    onSubmit() {
        this.isLoading = true;
		if (this.expoCheckoutDetailsForm.invalid) {
            this.error = true;
			this.isLoading = false;
			return;
		} else {
			this.error = false;
            let name = this.expoCheckoutDetailsControls.name.valid ? this.expoCheckoutDetailsControls.name.value.split(' ') : [];
            let first = name.find((first: string) => first);
            let last = name.find((last: string) => first != last) || '';
            this.detailsCompleted.emit({
                name: { first: first, last: last },
                number: this.expoCheckoutDetailsControls.number.value,
                email: this.expoCheckoutDetailsControls.email.value,
                firstTimePromotion: this.expoCheckoutDetailsControls.giftCardCheckbox.value
            });
		}  
    }

    get expoCheckoutDetailsControls() {
        return this.expoCheckoutDetailsForm.controls;
    }

}
