<div class="restaurant-reviews-item-container"
    [ngClass]="configService.getCssClass(restaurant.slug)">
    <div class="restaurant-reviews-item-details">
        <div class="restaurant-reviews-item-details-stars-container">
            <svg *ngFor="let dummy of ' '.repeat(review.rating).split('')" class="restaurant-reviews-details-star"
                viewBox="0 0 24 22.83">
                <path [attr.fill]="getIconColor()"
                    d="M12,0l3.67,7.57L24,8.72l-6.06,5.83,1.48,8.28-7.42-4-7.42,4,1.48-8.28L0,8.72,8.33,7.57Z" />
            </svg>
            <svg *ngFor="let dummy of ' '.repeat(5.99 - review.rating).split('')" class="restaurant-reviews-details-star"
                viewBox="0 0 24 22.83">
                <path [attr.fill]="getIconColor()"
                    d="M12,4.59,14.34,9.4l5.3.74-3.86,3.71.94,5.26L12,16.59,7.28,19.12l.94-5.27L4.36,10.14l5.3-.74ZM12,0,8.33,7.57,0,8.72l6.06,5.83L4.58,22.83l7.42-4,7.42,4-1.48-8.27L24,8.72,15.67,7.57Z" />
            </svg>
        </div>
        <div class="restaurant-reviews-item-details-date">
            {{ review.createdAt | date: 'mediumDate':'':translate.getActiveLang() }}
        </div>
    </div>
    <div class="restaurant-reviews-item-name" [innerHTML]="review.title"></div>
    <p class="restaurant-reviews-item-text" [innerHTML]="review.text"></p>
    <ng-container *ngIf="review.photos.length > 0">
        <div class="ngx-gallery-wrapper">
            <ngx-gallery id="{{review.id}}" [options]="galleryOptions" [images]="galleryImages" class="ngx-gallery">
            </ngx-gallery>
        </div>
    </ng-container>
    <div *ngIf="review.user" class="restaurant-reviews-item-user">
        {{ 'restaurant.reviews.submittedBy' | transloco }}
        <span class="restaurant-reviews-item-user-name"
            [innerHTML]="review.user.name.first + ' ' + review.user.name.last"></span>
    </div>
</div>
