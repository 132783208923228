import { Component, OnChanges, OnInit, Input, Output, EventEmitter, PLATFORM_ID, Inject, AfterViewInit, SimpleChanges, ViewChild, ElementRef } from '@angular/core';
import { Address, User, Menu, TranslatedTextType } from '../../core/models';
import { Address as GoogleAddress } from 'ngx-google-places-autocomplete/objects/address';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MainService } from '../../core/services/main.service';
import { TranslocoService } from '@ngneat/transloco';
import { Observable, mergeMap } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';
import { OrderType } from '../../core/enums';
import { Router } from '@angular/router';
import { ADDRESS_KEY, distanceBetween, parseGoogleAddress } from '../../core/helpers/address.helper';
import { Options } from 'ngx-google-places-autocomplete/objects/options/options';
import { UntilDestroy } from '@ngneat/until-destroy';
import { AddressModalComponent } from '../../modals/address-modal/address-modal.component';
import { ProductNoLongerAvailableComponent } from '../../modals/product-no-longer-available/product-no-longer-available.component';
import { ErrorRedirectComponent } from '../../modals/error-redirect/error-redirect.component';
import { AuthService } from '../../core/services/auth.service';

@UntilDestroy()
@Component({
    selector: 'app-order-settings',
    templateUrl: './order-settings.component.html',
    styleUrls: ['./order-settings.component.css']
})
export class OrderSettingsComponent implements OnChanges, OnInit, AfterViewInit {

    @ViewChild('inputAddressFocus') addressInput: ElementRef;

    @Input() isLoading: boolean = true;
    @Output() closeSettings = new EventEmitter<boolean>();
    @Output() openSettings = new EventEmitter<boolean>();

    user$: Observable<User>;
    address$: Observable<Address>;
    addresses$: Observable<Address[]>;

    orderType$: Observable<OrderType>;

    activeTabId: number;

    disabledOptionsButtons: boolean = false;
    isNewAddressPageOpen: boolean = false;
    addressValue: string;

    missingLine1Error: boolean = false;
    unspecificAddressError: boolean = false;
    addressError: boolean = false;
    isBrowser: boolean = false

    autocompleteOptions: Options;
    //added with new address confirmation page 
    openEdit: boolean = false;
    isAddressConfirmationPageOpen: boolean = false;
    selectedPrediction: Promise<any>;
    enteredAddress: any;
    preselectedAddress: any;

    constructor(
        @Inject(PLATFORM_ID) platformId: Object,
        public router: Router,
        private modalService: NgbModal,
        private mainService: MainService,
        public translate: TranslocoService,
        private authService: AuthService) {
        if (isPlatformBrowser(platformId)) {
            this.isBrowser = true;
            this.autocompleteOptions = new Options({ componentRestrictions: { country: 'ca' }, bounds: new google.maps.LatLngBounds(new google.maps.LatLng(45, -75), new google.maps.LatLng(46, -73)) });
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.isLoading = !this.isLoading;
    }

    ngOnInit(): void {
        this.user$ = this.mainService.userLoaded$;
        this.address$ = this.mainService.addressLoaded$;
        this.addresses$ = this.mainService.addressesLoaded$;
        this.orderType$ = this.mainService.orderTypeInViewLoaded$;
        this.orderType$.subscribe(orderType => this.activeTabId = (orderType === 'delivery') ? 1 : 2);
        this.resetAddressInput();
    }

    ngAfterViewInit(): void {
        // let input = document.querySelector('#input-container');
        // let pacs = document.getElementsByClassName('pac-item');
        // console.log(input);
        // input.append(pacs)
    }

    handleGoogleAddressChange(googleAddress: GoogleAddress) {
        //TODO: handle address already existing, so don't need to open AddressDetailsModal 
        let address = parseGoogleAddress(googleAddress);

        // this.openSettings.emit();
        let isAddressFound = address != null;
        this.isNewAddressPageOpen = !isAddressFound;
        this.isAddressConfirmationPageOpen = isAddressFound;

        if (!isAddressFound) return;
        this.preselectedAddress = address;
        setTimeout(() => {
            this.openSettings.emit();
        }, 100);
    }

    onConfirmAddress(address: Address) {
        this.isAddressConfirmationPageOpen = false;
        let updatedAddress = this.updateAddress(address);
        this.selectAddress(updatedAddress);
        this.closeSettings.emit(true);
    }

    updateAddress(address: Address) {
        let updatedAddress = this.preselectedAddress;
        updatedAddress.apt = address.apt;
        updatedAddress.line1 = address.line1;
        updatedAddress.city = address.city;
        updatedAddress.notes = address.notes;
        updatedAddress.postal = address.postal;
        return updatedAddress;
    }

    selectAddress(address: Address) {
        let bag = this.mainService.bag;
        let bagRestaurant = this.mainService.restaurantOnBag;
        let restaurantInView = this.mainService.restaurantInView;
        if (bag != null && !bag.isEmpty && distanceBetween(address, bagRestaurant?.address) > bagRestaurant.delivery?.radius) {
            this.displayAddressErrorModal('addressTooFarFromBagRestaurantAddress', bagRestaurant.name, address);
        } else if (bag != null && !bag.isEmpty && restaurantInView && distanceBetween(address, restaurantInView?.address) > restaurantInView.delivery?.radius) {
            this.displayAddressErrorModal('addressTooFarFromRestaurantInView', restaurantInView.name, address);
        } else { 
            this.mainService.updateAddress(address).subscribe();
        }
        this.isNewAddressPageOpen = false;
        this.resetAddressInput();
    }

    displayAddressErrorModal(errorType: string, restaurantName: TranslatedTextType, address: Address) {
        let modalRef = this.modalService.open(ErrorRedirectComponent);
        modalRef.componentInstance.header = errorType === "addressTooFarFromBagRestaurantAddress" ?
            "clearBagModal.title.addressTooFarWithBag" : "clearBagModal.prompt.addressTooFar";
        modalRef.componentInstance.text = errorType === "addressTooFarFromBagRestaurantAddress" ? 
            "clearBagModal.prompt.addressTooFarWithBag" : "clearBagModal.prompt.addressTooFar";
        modalRef.componentInstance.textParams = { restaurantName: restaurantName };
        modalRef.componentInstance.cancelButtonText = "clearBagModal.buttons.keepBag";
        modalRef.componentInstance.submitButtonText = "clearBagModal.buttons.clearBag";
        modalRef.result.then(_ => this.mainService.deleteBag().pipe(mergeMap(() => this.mainService.updateAddress(address))).subscribe(),
        _ => {});
    }

    selectPreferredAddress(address: Address) {
        this.mainService.setPreferredAddress(address);
    }

    resetAddressInput() {
        if (this.mainService.user || !this.authService.loggedIn()) this.addressValue = "";
        else if (localStorage.getItem(ADDRESS_KEY)) {
            let addressKey = JSON.parse(localStorage.getItem(ADDRESS_KEY));
            this.addressValue = addressKey.line1 + ', ' + addressKey.city + ', ' + addressKey.province + ', ' + addressKey.country
        }
    }

    //TODO: switch to active menu for type
    changeOrderType(orderType: string) {
        if (this.mainService.orderTypeInView === orderType) return;

        let restaurantInView = this.mainService.restaurantInView;
        let restaurantOnBag = this.mainService.restaurantOnBag;
        let bag = this.mainService.bag;
        let restaurant = restaurantInView != null ? restaurantInView : restaurantOnBag;

        if (restaurant == null) return this.completeOrderTypeChange(orderType);
        else if (bag == null) return this.completeOrderTypeChange(orderType);
        else if (restaurantInView != null && restaurantOnBag != null && restaurantInView?.id != restaurantOnBag?.id) return this.completeOrderTypeChange(orderType);
        else if (bag && bag.isEmpty) return this.mainService.deleteBag().subscribe(() => this.completeOrderTypeChange(orderType));

        let menu: Menu = restaurant?.menus.find(menu => menu.type === orderType);

        if (orderType == 'delivery') {
            let address = this.mainService.address;
            if (address == null || distanceBetween(address, restaurant.address) > restaurant.delivery.radius) {
                const modalRef = this.modalService.open(AddressModalComponent);
                modalRef.componentInstance.restaurant = restaurant;
                modalRef.result.then(address => this.mainService.deleteBag().pipe().subscribe(() => {
                    this.mainService.address = address;
                    this.completeOrderTypeChange(orderType);
                }),
                    _ => this.disabledOptionsButtons = false);
                // Should get user to fill in new address and valdiate before proceedingl
            }
            else return this.mainService.switchBagMenu(menu).subscribe();
        }

        //if menu == null, but bag exists and bag length > 0, offer to retain or send to restaurants listing
        else if (menu == null && bag && !bag.isEmpty) {
            const modalRef = this.modalService.open(ErrorRedirectComponent);
            modalRef.componentInstance.header = "clearBagModal.title.menuDoesntExist";
            modalRef.componentInstance.text = "clearBagModal.prompt.menuDoesntExist";
            modalRef.componentInstance.textParams = {
                restaurantName: restaurant.name,
                type: orderType
            }
            modalRef.componentInstance.cancelButtonText = "common.cancel";
            modalRef.componentInstance.submitButtonText = "clearBagModal.buttons.seeMoreRestaurants"
            return modalRef.result.then(_ => this.mainService.deleteBag().subscribe(() => this.router.navigate(['/'])),
            _ => this.disabledOptionsButtons = false);
        }

        //if bag length > 0, compare items (if discrepancy, show message with items that will be missing)
        else if (menu && bag && !bag.isEmpty) {
            let itemsToAddToNewBag = bag ? bag.getItemsInMenu(menu) : [];
            if (itemsToAddToNewBag.length == bag.items.length) return this.mainService.switchBagMenu(menu).subscribe();
            let missingItemsInMenu = bag ? bag.getItemsMissingInMenu(menu) : [];
            //if (missingItemsInMenu.length == 0) return;
            //TODO: HANDLE GLITCH if no options are clicked in the modal 
            const modalRef = this.modalService.open(ProductNoLongerAvailableComponent);
            modalRef.componentInstance.header = "changeMenuTypeModal.header";
            modalRef.componentInstance.headerParams = { type: orderType };
            modalRef.componentInstance.text = "changeMenuTypeModal.text";
            modalRef.componentInstance.textParams = {
                restaurantName: restaurant.name,
                type: orderType
            };
            modalRef.componentInstance.cancelButtonText = "changeMenuTypeModal.keepBag";
            modalRef.componentInstance.submitButtonText = "changeMenuTypeModal.removeItems";
            modalRef.componentInstance.submitButtonTextParams = { type: orderType };
            modalRef.componentInstance.items = missingItemsInMenu;
            return modalRef.result.then(_ => this.mainService.switchBagMenu(menu).subscribe(),
                _ => this.disabledOptionsButtons = false);
        }
    }

    completeOrderTypeChange(orderType: string) {
        this.mainService.orderTypeInView = <OrderType>orderType;
        this.disabledOptionsButtons = false;
        return;
    }

    isAddressSelected(address: Address) {
        if (!this.mainService.address || !address) return false;
        return this.mainService.address.id === address.id;
    }

    clickedDone() {
        this.closeSettings.emit(true)
    }


    //Function selects the first auto complete address prediction when user clicks enter
    selectFirstPrediction() {
        this.isLoading = true;
        this.getAutoCompletePredictions();
    }

    private getPlacePredictions(query: string): Promise<any> {
        let autocompleteSrv = new google.maps.places.AutocompleteService()
        return new Promise((resolve, reject) => {
            autocompleteSrv.getPlacePredictions({
                input: query
            }, function (predictions, status) {
                this.isLoading = false;
                if (status == google.maps.places.PlacesServiceStatus.OK) {
                    resolve(predictions);
                } else {
                    reject(status);
                }
            })
        })
    }

    private getAutoCompletePredictions() {
        this.getPlacePredictions(this.addressValue)
            .then(data => {
                this.selectedPrediction = data
                let geocoder = new google.maps.Geocoder();
                geocoder.geocode({ placeId: this.selectedPrediction[0].place_id }, (results) => {
                    this.enteredAddress = results[0];
                })
            })
            .catch(err => {
                console.log(err)
                return;
            })
    }

    onCancelConfirmAddress() {
        this.isAddressConfirmationPageOpen = false;
        this.isNewAddressPageOpen = true;
        this.openSettings.emit();
    }

    focusAddressInput() {
        setTimeout(() => this.addressInput.nativeElement.focus(), 0);
    }
}
