import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { Observable, map } from 'rxjs';
import { TranslocoService } from '@ngneat/transloco';
import { SearchResult } from '../models/search-result.model';

@Injectable({
	providedIn: 'root'
})
export class SearchService {

	constructor(private baseService: BaseService, private transloco: TranslocoService) { }

	searchMarket(query?: string): Observable<(SearchResult)[]> {
		let queryObj: any = {}
		if (query?.length) {
			queryObj.q = query;
			queryObj.language = this.transloco.getActiveLang();
		}
		return this.baseService.fetch('market', 'search', queryObj).pipe(map(searchResults => searchResults?.results.map(searchResult => new SearchResult().deserialize(searchResult))));
	}
}
