import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import Dinero from 'dinero.js';
import { mergeMap } from 'rxjs';
import { Exposition, Menu, Product, ProductOptionType, Restaurant } from 'src/app/core/models';
import { MainService } from 'src/app/core/services/main.service';

@Component({
	selector: 'expo-product',
	templateUrl: './product.component.html',
	styleUrls: ['./product.component.css']
})
export class ExpoProductComponent implements OnInit {

    loading: boolean = false;
    quantity: number = 1;
    product: Product;
    menu: Menu;
    restaurant: Restaurant;
    exposition: Exposition;

    isDisabled: boolean = false;

    constructor(
        private route: ActivatedRoute,
		private router: Router,
        private mainService: MainService
    ) {
        let state = this.router.getCurrentNavigation().extras.state?.isDisabled;
        this.isDisabled = state != null ? state : false;
    }

    ngOnInit(): void {

        //TODO: Redirect out if product not found on menu
        let vendorSlug = this.route.parent.snapshot.paramMap.get('slug');
        let productSlug = this.route.snapshot.paramMap.get('slug');
        this.exposition = this.route.parent.parent.snapshot.data['exposition'];

		let vendor = this.exposition?.vendors.find(v => v.vendor?.slug == vendorSlug);  
		if (vendor == null) {
			this.router.navigate([this.route.parent.snapshot.url.join('/')]);
			return;
		}
        this.restaurant = vendor.vendor;
        this.menu = vendor.menus.find(v => v);
        this.product = this.menu.sections.reduce((r, obj) => r.concat(obj.products), []).find(product => product.slug == productSlug);
    }

    decreaseQuantity(): void {
        if (this.quantity > 1) this.quantity--;
    }

    increaseQuantity(): void {
        if (this.quantity < 99) this.quantity++;
    }

    // //TODO: Extract to product model
    getCost(): Dinero.Dinero {
        return this.product.price.add(this.calculateOptionsCost()).multiply(this.quantity);
    }

    calculateOptionsCost() {
        let cost = Dinero({ amount: 0, currency: 'CAD' });
        this.product.options.forEach(option => {
            switch (option.limits.type) {
                case ProductOptionType.Required:
                case ProductOptionType.Maximal:
                case ProductOptionType.None:
                    option.products.forEach(product => {
                        // if (this.optionsForm.controls['checkbox-group-' + option.id].value[product.id]) {
                        //     cost = cost.add(product.price);
                        // }
                    })
                    break;
                case ProductOptionType.Supplemental:
                    let productsSelected: Product[] = []
                    option.products.forEach(product => {
                        // if (this.optionsForm.controls['checkbox-group-' + option.id].value[product.id]) {
                        //     productsSelected.push(product)
                        // }
                    })

                    if (productsSelected.length > option.limits.quantity) {
                        let freeProducts = productsSelected.slice(0).sort((a, b) => (a.price > b.price) ? 1 : -1).slice(0, option.limits.quantity)
                        productsSelected.forEach(product => {
                            if (freeProducts.findIndex(p => p.id == product.id) == -1) cost = cost.add(product.price);
                        })
                    }
                    break;
            }
        })
        return cost;
    }

    getSelectedOptions() {
        return [];
    }

    onAddToBag() {
        let selectedOptions = this.getSelectedOptions()
        this.loading = true;

        let addRequest$ = (bag) => this.mainService.addBagItem(bag, this.product, this.quantity, null, selectedOptions.length > 0 ? selectedOptions : null);
        let request$;
        if (this.mainService.expoBag == null) request$ = this.mainService.createBag(this.menu, this.restaurant, this.exposition.id).pipe(mergeMap(bag => addRequest$(bag)));
        else request$ = addRequest$(this.mainService.expoBag);
        
        request$.subscribe({
            next: () => {},
            error: (err) => {
                switch(err.error.error) {
                    case 'InconsistentMenu':
                        // this.productInconsistentError([this.product]);
                        // break;
                }
            },
            complete: () => this.reset()
        });
    }

    getCategoryIcon(category: string) {
        //switch case to return icon images
    }

    reset() {
        this.loading = false;
        this.router.navigate(['expo/' + this.exposition.slug + '/r/' + this.restaurant.slug])
    }
}

