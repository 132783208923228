import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from './base.service';
import { DiscountInstance, Order, UserStubData } from 'src/app/core/models';
import { OrderType } from '../enums';

export interface OrderRequestData {
    bagId?: string,
    tip?: Dinero.Dinero,
    options?: {
        noContact?: boolean;
        utensils?: boolean;
        crates?: {
            reusable?: boolean
        }
    }
    scheduledDate?: string;
    giftCard?: string;
    userStub?: UserStubData;
    recipientStub?: UserStubData;
    isVirtualized?: Boolean;
}

@Injectable({
    providedIn: "root",
})
export class OrderService {
    private entity: string = 'orders';
    private discountEntity: string = 'discounts'

    constructor(private baseService: BaseService) { }

    initializeOrder(data: OrderRequestData) {
        return this.baseService.create(this.entity, data)
            .pipe(map(res => {
                return { ...res, order: new Order().deserialize(res.order) }
            }));
    }

    confirmOrder(bagId: string) {
        let data: any = { confirm: true };
        data.bagId = bagId;
        return this.baseService.create(this.entity, data)
            .pipe(map(res => {
                return { ...res, order: new Order().deserialize(res.order) }
            }));
    }

    fetchOrder(id: string, type?: OrderType, fetchUser: boolean = false): Observable<Order> {
        let query: any = {};
        if (type && type == OrderType.Market) query.market = true;
        else if (type && type == OrderType.Expo) query.expo = true;
        if (fetchUser) query.user = true;

        return this.baseService.fetch(this.entity, id, query)
            .pipe(map(res => {
                if (res.queueRank) res.order.rank = res.queueRank;
                return new Order().deserialize(res.order)
            }));

    }

    list(limit: number = null, types?: OrderType[], isActive: boolean = null): Observable<Order[]> {
        let query = {};

        if (limit != null) query['limit'] = limit;
        if (types != null && types.length > 0) query['types'] = types;
        if (isActive != null) query['isActive'] = isActive;

        return this.baseService.list(this.entity, query).pipe(map(res => res?.orders?.map(order => new Order().deserialize(order))));
    }

    applyDiscount(code: String, id: string): Observable<DiscountInstance> {
        return this.baseService.create(this.entity + '/' + id + '/' + this.discountEntity, { code })
            .pipe(map(res => new DiscountInstance().deserialize(res.discountInstance)))
    }

    deleteDiscount(discountId: String, id: string): Observable<DiscountInstance> {
        return this.baseService.delete(this.entity + '/' + id + '/' + this.discountEntity, discountId)
            .pipe(map(res => new DiscountInstance().deserialize(res.discountInstance)))
    }
}
