<div class="menu-favorite-container">
    <div class="menu-favorite-image-wrapper">
        <img class="menu-favorite-image" [src]="favorite.image" alt="">
    </div>
    <div class="menu-favorite-text-container">
        <div class="menu-favorite-title" [innerHtml]="title"></div>
        <div class="menu-favorite-description" [innerHtml]="description"></div>
        <div *ngIf="favorite.type === FeatureType.MenuFavorite" class="menu-favorite-cta" (click)="goToMenuClicked()">{{'menuFavorite.goToMenu' | transloco}}</div>
    </div>
</div>
