<div #verifyAddressModal class="radish-modal verify-address-modal">
    <div class="radish-modal-content">
        <button type="button" class="radish-modal-dismiss-btn" (click)="activeModal.close()">
            <img class="dismiss-btn-icon" src="../../../../../assets/images/icons/close.svg" alt="{{ 'common.dismiss' | transloco }}" />
        </button>

        <div class="radish-modal-header">
            <h2 class="radish-modal-title">
                {{ 'giftBox.addressVerificationModal.verify' | transloco }}
            </h2>
        </div>

        <div class="radish-modal-image-container verify-address-modal-image-container">
            <img class="radish-modal-image" src="../../../../assets/graphics/gifts/delivery-map.png">
        </div>

        <div class="radish-modal-body" [innerHTML]="">
            <p class="radish-modal-body-text">
                {{ 'giftBox.addressVerificationModal.modalText' | transloco }}
            </p>

            <div class="input-container">
                <input class="checkout-form-input form-input input radish-modal-input" type="text" id="address" [placeholder]="'J0J 2E0'">
            </div>

            <div class="feedback-container">
                <p class="radish-invalid-feedback" *ngIf="isAddressValid == false">
                    {{ 'giftBox.addressVerificationModal.invalidAddress' | transloco }}
                </p>
                <p class="radish-valid-feedback" *ngIf="isAddressValid">
                    {{ 'giftBox.addressVerificationModal.validAddress' | transloco }}
                </p>
            </div>
        </div>

        <div class="radish-modal-footer">
            <button (click)="checkAddress()" class="radish-modal-btn-primary">
                {{ (isAddressValid == false ? 'giftBox.addressVerificationModal.dismiss' :
                    isAddressValid ? 'giftBox.addressVerificationModal.continue' :
                    'giftBox.addressVerificationModal.checkAddress') | transloco }}
            </button>
        </div>
    </div>
</div>