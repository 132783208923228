import { Component, OnInit } from '@angular/core';
import { BehaviorSubject, map, Observable, of } from 'rxjs';
import { Bag, BagItem, Order, OrderStatus, TranslatedTextType } from 'src/app/core/models';
import { MainService } from 'src/app/core/services/main.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { trigger, style, animate, transition, state } from '@angular/animations';
import { TranslocoService } from '@ngneat/transloco';
import { OrderService } from 'src/app/core/services/order.service';
import { Router } from '@angular/router';
import { ConfigService } from 'src/app/core/services/config.service';
import { OrderType } from 'src/app/core/enums';


const slideUpToggleAnimation = [
	trigger('slideUpToggle', [
		state('open', style({
			transform: 'translateY(0%)'
		})),
		state('closed', style({
			transform: 'translateY(100%) translateY(-48px)'
		})),
		transition('open => closed', [
			animate('300ms ease-in')
		]),
		transition('closed => open', [
			animate('200ms ease-in')
		])
	])
]

@UntilDestroy()
@Component({
	selector: 'app-market-followup',
	templateUrl: './followup.component.html',
	styleUrls: ['./followup.component.css'],
	animations: [slideUpToggleAnimation]
})
export class MarketOrderFollowupComponent implements OnInit {

	options;
	lng = -73.51;
	lat = 45.63;
	showMap;
	showMapButton;

	crate$: Observable<Bag>;
	itemGroups: { slug: string, name: TranslatedTextType, items: BagItem[] }[] = [];
	order$: BehaviorSubject<Order> = new BehaviorSubject(null);
	displayReturnCrate: boolean;
	openReturnCrate: boolean = true;
	openBottomSheet: boolean = false;
	showOverflowPill: boolean = true;

	constructor(
		public translate: TranslocoService,
		private mainService: MainService,
		private configService: ConfigService,
		private router: Router,
		private orderService: OrderService) { }

	ngOnInit(): void {
		setTimeout(() => {
		this.orderService.list(1, [OrderType.Market]).subscribe(orders => {
			if (orders.length == 0 || orders == null) return this.router.navigate([this.translate.getActiveLang() + '/marché']);
			let order = orders[0];
			this.crate$ = of(order.bag);

			// if (order.status === OrderStatus.Initialized) return this.router.navigate([this.translate.getActiveLang() + '/marché']);
			this.order$.next(order);
			this.itemGroups = this.groupByRestaurant(order.bag?.items)
			this.displayReturnCrate = order.options?.crates?.reusable;
			this.setMap(order.bag);
		});
	}, 150);
	}

	groupByRestaurant(crateItems: BagItem[]): any[] {
		return crateItems.reduce((list: any[], item: BagItem) => {
			let index = list.findIndex(sortedItem => sortedItem.slug == item.product?.restaurant?.slug);
			(index != -1) ? list[index].items.push(item) : list.push({ slug: item.product.restaurant.slug, name: item.product.restaurant.name, items: [item] });
			return list;
		}, []);
	}

	toggleReturnCrate() {
		this.openReturnCrate = !this.openReturnCrate;
	}

	toggleBottomSheet() {
		this.openBottomSheet = !this.openBottomSheet;
	}
	
	scrollDown() {
		let element;
		if (window.innerWidth >= 992) element = document.getElementById('orderSummary');
		else if (window.innerWidth < 992) element = document.getElementById('orderSummaryMobile');
		if (!element) return;
		element.scrollBy(0, 150)
	}

	checkOverflow() {
		let element;
		if (window.innerWidth >= 992) element = document.getElementById('orderSummary');
		else if (window.innerWidth < 992) element = document.getElementById('orderSummaryMobile');
		if (!element) return;
		return element.offsetHeight < element.scrollHeight;
	}

	onScroll(event) {
		let epsilon = 1;
		if (event.target.offsetHeight + event.target.scrollTop + epsilon >= event.target.scrollHeight) {
			this.showOverflowPill = false;
		}
		else {
			this.showOverflowPill = true;
		}
	}

	setMap(crate) {
		this.options = {
			disableDefaultUI: true,
			styles: this.configService.getMapStyle('')
		};
		this.lat = crate.address?.loc?.coordinates[1];
		this.lng = crate.address?.loc?.coordinates[0];
		this.showMap = true;
	}

	getCardLogo(brand: string) {
		switch (brand) {
			case 'visa':
				return '../../../assets/images/icons/visa.svg';
			case 'amex':
				return '../../../assets/images/icons/american-express.svg';
			case 'mastercard':
				return '../../../assets/images/icons/mastercard.svg';
		}
	}
}
