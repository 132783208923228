<div *ngIf="restaurant | async as resto">
    <app-back-button [backgroundColor]="configService.getPrimaryColor(resto.slug)"
        [iconColor]="configService.getSecondaryColor(resto.slug)"></app-back-button>
    <div class="story-container">

    <div class="story-banner">
        <img
            [src]="'../../../assets/images/jc/story/juliette_chocolat_babdeau_large-' + translate.getActiveLang() + '.jpg'">
    </div>
    <div class="story-content">
        <div class="story-content-row">
            <div class="story-content-row-item story-content-row-item-image col order-2 order-md-1">
                <img src="../../../assets/images/jc/story/juliette_chocolat_a_propos_juliette_1984_large.jpg">
            </div>
            <div class="story-content-row-item story-content-row-item-text col order-1 order-md-2">
                {{ jcStory.one[translate.getActiveLang()] }}
            </div>
        </div>
        <div class="story-content-row">
            <div class="story-content-row-item story-content-row-item-text col">
                {{ jcStory.two[translate.getActiveLang()] }}
            </div>
            <div class="story-content-row-item story-content-row-item-image col">
                <img src="../../../assets/images/jc/story/juliette_chocolat_a_propos_juliette_image_3_grande.jpg">
            </div>
        </div>
        <div class="story-content-row">
            <div class="story-content-row-item story-content-row-item-image col order-2 order-md-1">
                <img src="../../../assets/images/jc/story/juliette_chocolat_a_propos_juliette_image_5_grande.jpg">
            </div>
            <div class="story-content-row-item story-content-row-item-text col order-1 order-md-2">
                {{ jcStory.three[translate.getActiveLang()] }}
            </div>
        </div>
        <div class="story-content-row">
            <div class="story-content-row-item story-content-row-item-text col">
                {{ jcStory.four[translate.getActiveLang()] }}
            </div>
            <div class="story-content-row-item story-content-row-item-image col">
                <img
                    src="../../../assets/images/jc/story/juliette_chocolat_a_propos_juliette_image_8_1024x1024.jpg">
            </div>
        </div>
        <div class="story-content-row">
            <div class="story-content-row-item story-content-row-item-image col order-2 order-md-1">
                <img
                    [src]="'../../../assets/images/jc/story/juliette_chocolat_a_propos_juliette_image_2_1024x1024-' + translate.getActiveLang() + '.jpg'">
            </div>
            <div class="story-content-row-item story-content-row-item-text col order-1 order-md-2">
                {{ jcStory.five[translate.getActiveLang()] }}
            </div>
        </div>
        <div class="story-content-row">
            <div class="story-content-row-item story-content-row-item-text col">
                {{ jcStory.six[translate.getActiveLang()] }}
            </div>
            <div class="story-content-row-item story-content-row-item-image col">
                <img src="../../../assets/images/jc/story/juliette_chocolat_a_propos_juliette_image_4_grande.jpg">
            </div>
        </div>
        <div class="story-content-row">
            <div class="story-content-row-item story-content-row-item-image col order-2 order-md-1">
                <img
                    [src]="'../../../assets/images/jc/story/juliette_chocolat_a_propos_juliette_image_6_grande-' + translate.getActiveLang() + '.jpg'">
            </div>
            <div class="story-content-row-item story-content-row-item-text col order-1 order-md-2">
                {{ jcStory.seven[translate.getActiveLang()] }}
            </div>
        </div>
        <div class="story-content-row">
            <div class="story-content-row-item story-content-row-item-text col" [innerHTML]="jcStory.eight[translate.getActiveLang()]">
            </div>
            <div class="story-content-row-item story-content-row-item-image story-content-row-item-image-last col">
                <img src="../../../assets/images/jc/story/juliette_chocolat_a_propos_juliette_image_9_medium.jpg">
            </div>
        </div>
    </div>
</div>

</div>
