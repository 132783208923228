import { GiftCardAppliedComponent } from './../../modals/gift-card-applied/gift-card-applied.component';
import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { filter, throttle, interval, tap, Observable, Subscription, switchMap, catchError } from 'rxjs';
import { GiftCardService } from 'src/app/core/services/gift-card.service';
import { GiftCard } from 'src/app/core/models/gift-card.model';
import { GiftCardValidator } from 'src/app/shared/validators/gift-card.validator';
import { ActivatedRoute, Router } from '@angular/router';
import { NgOtpInputComponent } from 'ng-otp-input';
import { UntilDestroy } from '@ngneat/until-destroy';
import { TranslocoService } from '@ngneat/transloco';
import { MainService } from 'src/app/core/services/main.service';
import { User } from 'src/app/core/models';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
	selector: 'app-gift-card-redeem',
	templateUrl: './redeem.component.html',
	styleUrls: ['./redeem.component.css']
})

@UntilDestroy()
export class GiftCardRedeemComponent implements OnInit {
	// @ViewChild(NgOtpInputComponent, { static: false}) ngOtpInput:NgOtpInputComponent;
	@ViewChild('ngOtpInput') ngOtpInputRef: any;
	@ViewChild('myModal') myModal;
	private modalRef;

	user$: Observable<User>;
	giftCard: GiftCard;

	giftCodeForm: UntypedFormGroup;
	isCodeValid: boolean = false;
	error: boolean = false;
	codeApplied: boolean = false;
	code: string;
	codeRegex = /^[a-zA-Z0-9]{0,12}$/;

	mobileThreshold: number = 576;
	screenWidth: number;
	isMobile: boolean;

	message: string;
	name: string;

	showGuestUserMessage: boolean = false;
	giftCardNotFoundError: boolean = false;
	genericGiftCodeError: boolean = false;
	alreadyClaimedGiftCardError: boolean = false;

	config = {
		length: 12,
		disableAutoFocus: false,
		inputStyles: {
			width: '2.3rem',
			height: '2.6rem',
			border: '2px solid #FFE2E2',
			'border-radius': '0.2rem',
			'font-weight': 'bolder',
			color: 'black',
			'background-color': '#FFE2E2',
			'text-transform': 'uppercase'
		}
	}

	constructor(
		private modalService: NgbModal,
		private fb: FormBuilder,
		private giftCardService: GiftCardService,
		private route: ActivatedRoute,
		private mainService: MainService,
		private router: Router,
		private translate: TranslocoService
	) {
		this.giftCodeForm = this.fb.group({
			code: ['', [Validators.required, Validators.pattern(this.codeRegex)]],
			title: ['', Validators.maxLength(55)]
		});
	}

	ngOnInit(): void {
		this.user$ = this.mainService.userLoaded$;
		localStorage.removeItem('redirect_url');
		this.screenWidth = window.innerWidth;
		this.isMobile = window.innerWidth < this.mobileThreshold;

		this.giftCodeForm.get('code').valueChanges.pipe(
			tap(_ => this.giftCard = null),
			tap(_ => {
				this.showGuestUserMessage = false;
				this.giftCardNotFoundError = false;
				this.codeApplied = false;
				this.alreadyClaimedGiftCardError = false;
				this.genericGiftCodeError = false;
			}),
			filter(code => code.length == 12 && this.giftCodeForm.valid),
			throttle(() => interval(400)),
			switchMap(code => this.giftCardService.previewGiftCard(code)),
			catchError((err, o) => {
				this.error = true;
				this.handleGiftCodeError(err.error?.error);
				return o;
			})
		).subscribe(giftCard => {
			this.giftCard = giftCard;
			this.giftCodeForm.controls.title.setValue(this.giftCard._title[this.translate.getActiveLang()]);
			if (this.mainService.user == null) this.showGuestUserMessage = true;
		});
	}

	ngAfterViewInit() {
		this.route.params.subscribe(params => {
			if (params['code'] != null) {
				this.ngOtpInputRef.setValue(params['code']);
				this.giftCodeForm.controls.code.setValue(params['code']);
			}
		});
	}

	get giftCodeFormControls() {
		return this.giftCodeForm.controls;
	}

	handleGiftCodeError(error): void {
		if (error == "GiftCardNotFound") this.giftCardNotFoundError = true;
		else if (error == "ExistingClaim") this.alreadyClaimedGiftCardError = true;	
		else this.genericGiftCodeError = true;
	}

	onLoginOrCreateAccountClick(): void {
		localStorage.setItem('redirect_url', '/gift-cards/redeem/' + this.ngOtpInputRef.currentVal);
	}
	onRedeemClick() {
		let redirect = localStorage.getItem('redirect_url_checkout');
		if (redirect && redirect.endsWith('/checkout')) {
			this.router.navigate([redirect]);
			localStorage.removeItem('redirect_url_checkout')
		} else {
			this.onRedeemSuccess()
		}
	}

	onRedeemSuccess() {
		const modalRef = this.modalService.open(GiftCardAppliedComponent, {
			centered: true
		});

	}

	onSubmit() {
		if (this.giftCodeForm.invalid) {
			// this.loading = false;
			return;
		} else {
			// this.error = false;
			let title = null;
			this.giftCardService.claim(this.giftCodeFormControls.code.value, this.giftCodeFormControls.title.value, this.translate.getActiveLang()).subscribe({
				next: giftCard => {
					this.onRedeemClick();
					this.codeApplied = true;
				},
				error: err => {
					console.log(err);
					this.codeApplied = false;
					this.error = true;
				}
			});
			//add API call here to claim the gift card to account
			//this.mainService.giftCard(data.code, data.title).subscribe({ error: err => this.enableErrors(err) });
			//displays message on success/fail

			//FOR TESTING PURPOSES ONLY
			//this.error = true;
			// this.codeApplied = true;
			// this.onRedeemClick();
		}
	}

	@HostListener('window:resize', ['$event'])
	onResize(event?) {
		this.screenWidth = window.innerWidth;
		this.isMobile = window.innerWidth < this.mobileThreshold;
		this.configureOtpInput();
	}

	configureOtpInput() {
		if (this.isMobile) {
			this.config.inputStyles.width = '1.3rem',
				this.config.inputStyles.height = '1.6rem',
				this.config.inputStyles['margin-right'] = '4px'
		}
		else {
			this.config.inputStyles.width = '2.3rem',
				this.config.inputStyles.height = '2.6rem',
				this.config.inputStyles['margin-right'] = '8px'
		}
	}
	
}
