<div class="add-address-confirmation-container">
    <div class="map-wrapper">
        <app-location-map [latitude]="address.loc.coordinates[1]" [longitude]="address.loc.coordinates[0]" [zoom]="15"
            [styles]="getMapStyle()" [radius]="5000" [radiusFillColor]="'#ffffff'" [showRadius]="false">
        </app-location-map>
    </div>
    <form class="address-confirmation-form" [formGroup]="addressConfirmationForm" action="submit"
        (submit)="onSaveAddress()">
        <div class="address-container box">
            <div *ngIf="!isEditAddressOpen" class="address address-display">
                <div class="address-display-row">
                    <p class="address-line-1">{{ address.line1 }}</p>
                    <button type="button" class="edit-icon-btn" (click)="isEditAddressOpen = true">
                        <img class="edit-icon" src="../../assets/images/icons/edit.svg">
                    </button>
                </div>
                <div class="address-city-province-postal"> {{ address.city }}, {{ address.province }}, {{ address.postal}}</div>
                <button type="button" class="add-apartment-btn" (click)="isEditAddressOpen = true">
                    + {{ 'checkout.addApartment' | transloco }}
                </button>            
            </div>
            <div *ngIf="isEditAddressOpen" class="address">
                <div class="address-row">
                    <div class="address-col apartment-wrapper">
                        <input class="input edit-input form-control form-input" value="{{ address.apt }}" type="text"
                            id="apartmentNumber" formControlName="apt">
                        <label class="input-label edit-address-label" for="apartmentNumber">{{
                            'addressConfirmationPage.apartmentNumber' | transloco }}</label>
                    </div>
                    <div class="address-col street-address-wrapper">
                        <input class="input edit-input form-control form-input" type="text" value="{{ address.line1 }}"
                            id="streetAddress" formControlName="line1" [ngClass]="{
                                'is-invalid':
                                    addressConfirmationForm.controls.line1.errors?.required }" >
                        <label class="input-label edit-address-label" for="streetAddress">{{
                            'addressConfirmationPage.streetAddress' | transloco }} </label>
                    </div>
                </div>
                <div class="address-row">
                    <div class="address-col city-wrapper">
                        <input class="input edit-input form-control form-input" type="text" value="{{ address.city }}" id="city"
                            formControlName="city" [ngClass]="{
                                'is-invalid':
                                    addressConfirmationForm.controls.city.errors?.required }">
                        <label class="input-label edit-address-label" for="city">{{ 'addressConfirmationPage.city' |
                            transloco }}</label>
                    </div>
                    <div class="address-col province-wrapper">
                        <input class="input edit-input form-control form-input" type="text" value="{{ address.province}}"
                            id="province" formControlName="province">
                        <label class="input-label edit-address-label"
                            for="province">{{'addressConfirmationPage.province' | transloco }}</label>
                    </div>
                    <div class="address-col postal-code-wrapper">
                        <input class="input edit-input form-control form-input" type="text" value="{{ address.postal }}"
                            id="postalCode" formControlName="postal" [ngClass]="{
                                'is-invalid':
                                    addressConfirmationForm.controls.postal.errors?.minlength ||
                                    addressConfirmationForm.controls.postal.errors?.maxlength ||
                                    addressConfirmationForm.controls.postal.errors?.required }" [maxLength]="7">
                        <label class="input-label edit-address-label"
                            for="postalCode">{{'addressConfirmationPage.postalCode' | transloco }}</label>
                    </div>
                </div>
                <div  class="form-control-feedback">
                    <div class="error-message" *ngIf="addressConfirmationForm.controls.postal.errors?.minlength">{{'addressConfirmationPage.reviewPostalCode' | transloco }}</div>
                    <div class="error-message" *ngIf="addressConfirmationForm.controls.postal.errors?.maxlength">{{'addressConfirmationPage.reviewPostalCode' | transloco }}</div>
                    <div class="error-message" *ngIf="addressConfirmationForm.controls.postal.errors?.required">{{'addressConfirmationPage.postalCodeRequired' | transloco }}</div>
                    <div class="error-message" *ngIf="addressConfirmationForm.controls.line1.errors?.required">{{'addressConfirmationPage.streetAddressRequired' | transloco }}</div>
                    <div class="error-message" *ngIf="addressConfirmationForm.controls.city.errors?.required">{{'addressConfirmationPage.cityRequired' | transloco }}</div>
                </div>
            </div>
        </div>
        <div class="delivery-notes box">
            <div class="delivery-notes-label">{{'addressConfirmationPage.notes' | transloco }}</div>
            <textarea class="input form-control form-input" type="multiple" formControlName="notes"
                placeholder="{{'addressConfirmationPage.notesPlaceholder' | transloco}}"></textarea>
        </div>
        <div class="cta-container box">
            <button type="button" class="cta-btn cancel-btn" (click)="onCancel()">{{'common.cancel' |
                transloco }}</button>
            <button type="submit" class="cta-btn save-btn" [disabled]="!addressConfirmationForm.valid">{{'common.save' | transloco }}</button>
        </div>
    </form>
</div>