<div *ngIf="!expandedList" class="compressed" (click)="ExpandList()">
    <div *ngFor="let guest of guestList | slice:0:4; let i=index" class="guest">{{guest.charAt(0)}}</div>
    <div class="click-to-expand">
        ...
    </div>
</div>
<div *ngIf="expandedList" class="expanded" (click)="ExpandList()">
    <div *ngFor="let guest of guestList let i=index" class="guest-expanded">
        <h4>{{guest}}</h4>
    </div>
</div>