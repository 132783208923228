
import { Component, Inject, OnInit, PLATFORM_ID, ViewEncapsulation, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { animate, group, query, style, transition, trigger } from '@angular/animations';
import { TranslocoService } from '@ngneat/transloco';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';

@Component({
    selector: 'app-blog',
    templateUrl: './blog.component.html',
    styleUrls: ['./blog.component.css'],
    encapsulation: ViewEncapsulation.None,
    animations: [
        trigger('wordUpdated', [
            transition("* => *", group([
                query(':enter', [
                    style({ opacity: 0, transform: 'translateY(60%)' }),
                    animate('.4s ease-out', style({ opacity: 1, transform: 'translateY(0%)' }))
                ], { optional: true }),
                query(':leave', [
                    style({ opacity: 1, transform: 'translateY(0%)' }),
                    animate('.4s ease-out', style({ opacity: 0, transform: 'translateY(-60%)' }))
                ], { optional: true })
            ]))

        ]),
    ]
})
export class BlogComponent implements OnInit, OnDestroy {
    private subscription: Subscription = new Subscription();

    blogPost;
    blogBanner;
    navbarType = 'blog';
    footerType = 'blog';

    words = ['UberEATs', 'DoorDash', 'Skip', 'GrubHub', 'Foodora'];
    word: string = this.words[Math.floor(Math.random() * this.words.length)];

    isBrowser = false;

    constructor(
        @Inject(PLATFORM_ID) platformId: Object,
        private http: HttpClient,
        public translate: TranslocoService) {
            this.subscription.add(
                translate.langChanges$.subscribe((lang: string)=>{
                    this.http.get("assets/blog-" + lang + ".html", { responseType: 'text' })
                        .pipe(first())
                        .subscribe(data => {
                            this.blogPost.content = data;
                        })
                })
            );
            if (isPlatformBrowser(platformId)) this.isBrowser = true;
        }

    ngOnInit() {

        if (this.isBrowser) setInterval(_ => this.changeWord(), 3000);

        this.blogBanner = {
            header: 'Buffet Maharani has got you covered for Indian cuisine.',
            button: 'Discover Buffet Maharani',
            slug: 'maharani',
            image: '../../assets/images/buffet-maharani-banner.jpg',
        };

        this.blogPost = {
            title: 'To Outrun a Unicorn',
            date: 'April 12, 2020',
            author: {
                name: 'Mansib Rahman',
                title: 'Directeur général',
                company: 'Radish',
            },
            content:
                'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Iusto libero provident cum eos, rem natus aspernatur vero ut adipisci explicabo officiis facilis repudiandae autem voluptatum. Maxime ipsa consectetur amet in aperiam nulla cum ut laboriosam atque sunt alias nostrum quasi vero temporibus recusandae, iste soluta corrupti, vitae architecto doloribus voluptatem. \n\nLorem ipsum dolor sit amet consectetur adipisicing elit. Nesciunt vel porro obcaecati eum voluptatibus delectus laborum, molestias perspiciatis repellendus repellat, maxime, eligendi praesentium nostrum earum aspernatur impedit enim excepturi necessitatibus aut a tenetur quisquam! Amet nostrum aperiam eum fuga facilis aliquid dolorum mollitia accusamus dignissimos! \n\nLorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptate delectus, quis alias perferendis dolorum, itaque laborum placeat maxime error, minus corrupti asperiores ipsam facere ipsa debitis obcaecati amet. Tenetur accusantium excepturi earum saepe, minima sit odio inventore dolore dignissimos at perspiciatis minus molestiae delectus fugiat aliquam mollitia maxime non consequatur aspernatur molestias laboriosam? Consequuntur dolor nihil totam? Animi nostrum reiciendis quae nesciunt consequuntur accusantium repellendus? \n\nLorem ipsum dolor sit amet consectetur, adipisicing elit. Dolorum quia minus illum itaque beatae nostrum iusto magnam suscipit numquam temporibus fugiat quaerat magni omnis a ad vel quos, accusamus modi. \n\n',
            image: '../../assets/images/horse-unicorn-chess.jpg'
        };

        //choosing blog based on language - only works when page opens
        this.http.get("assets/blog-" + this.translate.getActiveLang() + ".html", { responseType: 'text' })
            .pipe(first())
            .subscribe(data => {
                this.blogPost.content = data;
            })

    }

    changeWord() {
        let newWord: string = this.words[Math.floor(Math.random() * this.words.length)]
        while (newWord === this.word)
            newWord = this.words[Math.floor(Math.random() * this.words.length)]

        this.word = newWord;
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}
