<div class="order-summary-container">
    <img class="order-summary-close" src="../../../assets/images/close-icon.png" alt="" (click)="activeModal.close()" />
    <div class="order-summary-header">{{ 'orderSummary.orderSummary' | transloco  }}</div>
    <div *ngFor="let item of bag.items">
        <div class="order-summary-row order-summary-row-items">
            <div class="order-summary-row-left">
                <span class="order-summary-quantity">
                    {{ item.quantity }}x
                </span>
                <div *ngIf="item.product.title as title" class="order-summary-title" [innerHtml]="title"></div>
            </div>
            <div class="order-summary-row-right">{{ item.price | price | translocoCurrency }}</div>
        </div>
        <div *ngIf="item.options.length > 0" class="order-summary-row order-summary-row-options-notes">
            <div class="order-summary-options">
                <ng-container *ngFor="let option of item.options">
                    <div *ngFor="let product of option.products" class="order-summary-option">
                        <div *ngIf="product.title as title" [innerHtml]="title"></div>
                    </div>
                </ng-container>
            </div>
            <div *ngIf="item.notes" class="order-summary-note"><b>Note: </b>{{ item.notes }}</div>
        </div>
    </div>
    <div class="order-summary-line"></div>
    <div *ngIf="subtotal" class="order-summary-row order-summary-row-bold">
        <div class="order-summary-row-left">{{ 'orderSummary.subTotal' | transloco  }}</div>
        <div class="order-summary-row-right">{{ this.subtotal | price | translocoCurrency }}</div>
    </div>
    <ng-container *ngIf="order.fees as fees">
        <div *ngFor="let fee of fees" class="order-summary-row">
            <div>{{ fee.title }}</div>
            <div>{{ fee.amount | price | translocoCurrency }}</div>
        </div>
    </ng-container>
    <div *ngIf="order.type === 'delivery'" class="order-summary-row">
        <div class="order-summary-row-left">{{ 'orderSummary.deliveryFee' | transloco  }}</div>
        <div class="order-summary-row-right order-summary-strikethrough">
            <span *ngIf="translate.getActiveLang() == 'en'">$</span>3.99<span *ngIf="translate.getActiveLang() == 'fr'">$</span>
        </div>
    </div>
    <div *ngIf="deliveryTip" class="order-summary-row">
        <div class="order-summary-row-left" *ngIf="order.type === 'delivery'">
            {{ 'orderSummary.deliveryTip' | transloco  }}</div>
        <div class="order-summary-row-left" *ngIf="order.type === 'pickup'">
            {{ 'orderSummary.restaurantDonation' | transloco  }}</div>
        <div class="order-summary-row-right">{{ this.deliveryTip | price | translocoCurrency }}</div>
    </div>
    <ng-container *ngIf="discountInstances && discountInstances.length > 0">
        <div *ngFor="let discountInstance of discountInstances" class="order-summary-row discount-row">
            <div class="order-summary-row-left">-{{ discountInstance.discount.title }}</div>
            <div class="order-summary-row-right">-{{ discountInstance.effects.total.value | price | translocoCurrency }}</div>
        </div>
    </ng-container>
    <div class="order-summary-line"></div>
    <div *ngIf="total" class="order-summary-row order-summary-row-bold order-summary-row-total">
        <div class="order-summary-row-left">{{ 'orderSummary.total' | transloco }}</div>
        <div class="order-summary-row-right">{{ this.total | price | translocoCurrency }}</div>
    </div>
</div>
