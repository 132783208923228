<div class="followup-container" *ngIf="!isLoading; else Loading">
    <h1 class="followup-title followup-margin"
        [innerHTML]="'expo.followup.order.title' | transloco : { userName: firstName }"></h1>
    <p class="followup-description followup-margin"
        [innerHTML]="'expo.followup.order.description' | transloco "></p>
    <div class="followup-order-number-underlay">
        <img class="followup-order-icon corn-dog-skewer-icon" 
            [ngClass]="+(orderNumber) < 1000 ? 'corn-dog-order-number-small' : 'corn-dog-order-number-large'" 
            src="../../../../assets/images/icons/skewer-2.svg"
            alt="corn-dog-skewer">
        <div class="followup-order-number-container" [ngClass]="+(orderNumber) < 1000 ? 'order-number-small' : 'order-number-large'">
            <div class="followup-order-number-title">{{ 'expo.followup.order.orderNumber' | transloco }}</div>
            <div class="followup-order-number">{{ orderNumber }}</div>
        </div>
        <img class="followup-order-icon rice-cake-skewer-icon"
            [ngClass]="+(orderNumber) < 1000 ? 'rice-cake-skewer-order-number-small' : 'rice-cake-skewer-order-number-large'" 
            src="../../../../assets/images/icons/skewer-1.svg"
            alt="rice-cake-skewer">
    </div>
    <div class="followup-queue-container">
        <div class="followup-queue-title" [innerHTML]="queueNumber != null && queueNumber > 0 ?
            ('expo.followup.queue.title' | transloco: { restaurantName: restaurantName }) :
            'expo.followup.queue.nextTitle' | transloco
        "></div>
        <div *ngIf="queueNumber != null && queueNumber > 0">
            <div class="followup-queue-number">{{ queueNumber }}</div>
            <div class="followup-queue-details" [innerHTML]="'expo.followup.queue.details' | transloco"></div>
        </div>
    </div>
    <div class="followup-bottom-container">
        <div class="followup-customer-support-container">
            <h4 class="followup-customer-support-title">{{ 'expo.followup.customerSupport.title' | transloco }}</h4>
            <ul class="followup-customer-support-list">
                <li class="followup-customer-support-list-item" *ngFor="let message of supportMessages" [innerHTML]="'expo.followup.customerSupport.' + message | transloco "></li>
            </ul>
        </div>
        <button class="new-order-button" (click)="onClickPlaceAnotherOrder()">{{ 'expo.followup.placeNewOrder' | transloco }}</button>
    </div>
</div>
<ng-template #Loading>
    <div class="followup-loading-container">
        <div *ngIf="isLoading" class="spinner-border spinner-border-lg text-dark" role="status">
            <span class="sr-only">{{ 'common.loading' | transloco }}</span>
        </div>
    </div>
</ng-template>