import { Component, Output, EventEmitter, Input, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { TranslocoService } from "@ngneat/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

// const times = [
// var myToday = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0);
// ]

const times = [
	{
		start: 11,
		end: 13,
		label: {
			en: "11:00 AM - 1:00 PM",
			fr: "11 h 00 - 13 h 00"
		}
	},
	{
		start: 13,
		end: 15,
		label: {
			en: "1:00 PM - 3:00PM",
			fr: "13 h 00 - 15 h 00"
		}
	},
	{
		start: 15,
		end: 17,
		label: {
			en: "3:00 PM - 5:00 PM",
			fr: "15 h 00 - 17 h 00"
		}
	},
	{
		start: 17,
		end: 19,
		label: {
			en: "5:00 PM - 7:00 PM",
			fr: "17 h 00 - 19 h 00"
		}
	},
]
@UntilDestroy()
@Component({
	selector: 'app-crate-delivery-scheduler',
	templateUrl: './crate-delivery-scheduler.component.html',
	styleUrls: ['./crate-delivery-scheduler.component.css']
})
export class CrateDeliveryScheduler implements OnInit {
	@Input() public marketCheckoutForm: FormGroup;
	@Input() set scheduledDate(date) { 
		this._scheduledDate = new Date(date);
		if (date && times.some(time => time.start == this._scheduledDate.getHours())) this.selectedTime = date;
	};
	@Output() dateTimeScheduled = new EventEmitter();

	times = times;
	_scheduledDate: Date;
	selectedTime: string;
	startTime: string;
	endTime: string;

	constructor(public translate: TranslocoService) { }

	ngOnInit(): void {
	}

	setTime(selectedTime: any) {
		this._scheduledDate.setHours(this.marketCheckoutForm.get('scheduledDateTime').value);
		this.dateTimeScheduled.emit(this._scheduledDate);
	}
}

