<app-navbar></app-navbar>
<div class="login-container" [class.disable]="loading || socialProvider != null">
    <div class="container">
        <div class="login-container-column col-md-5">
            <h2 class="login-container-title">
                {{ 'login.logIntoAccount' | transloco }}
            </h2>
            <form [formGroup]="loginForm" (ngSubmit)="onSubmit(loginForm.value)">
                <div class="form-group mb-4">
                    <input formControlName="email" class="form-control" type="text" id="email"
                        [placeholder]="'login.email' | transloco" [ngClass]="{
							'is-invalid':
								loginFormControls.email.errors &&
								loginFormControls.email.errors.pattern
						}" autocomplete="username" />
                    <div class="line"></div>
                    <div *ngIf="loginFormControls.email.errors" class="form-control-feedback invalid-feedback">
                        <div class="noAtSign" *ngIf="loginFormControls.email.errors.pattern"></div>
                    </div>
                </div>
                <div class="form-group mb-4">
                    <input formControlName="password" class="form-control" type="password" id="password"
                        [placeholder]="'login.password' | transloco" value="" autocomplete="current-password" required />
                    <div class="line"></div>
                </div>
                <div class="login-error" *ngIf="error">
                    {{ 'login.' + error | transloco }}
                </div>
                <div class="form-group regular-login mb-4">
                    <button class="btn btn-login form-control" type="submit" [disabled]="!loginForm.valid || loading"
                        angulartics2On="click" angularticsAction="Login" angularticsCategory="Auth"
                        [angularticsProperties]="{event: 'Click', label: 'Regular Login'}">
                        <div *ngIf="loading" class="spinner-border spinner-border-sm" role="status">
                            <span class="sr-only">{{ 'common.loading' | transloco }}</span>
                        </div>
                        <span *ngIf="!loading">
                            {{ 'login.login' | transloco }}
                        </span>
                    </button>
                </div>
                <div class="login-form-separator">
                    <div class="line"></div>
                    <div class="login-form-separator-or">
                        {{ 'login.or' | transloco }}
                    </div>
                </div>
                <div class="form-group facebook-login mb-4">
                    <button type="button" (click)="signInWithFB()" class="btn btn-login btn-facebook-login form-control"
                        angulartics2On="click" angularticsAction="Login" angularticsCategory="Auth"
                        [angularticsProperties]="{event: 'Click', label: 'Facebook Login'}">
                        <div *ngIf="socialProvider == 'facebook'" class="spinner-border spinner-border-sm" role="status">
                            <span class="sr-only">{{ 'common.loading' | transloco }}</span>
                        </div>
                        <ng-container *ngIf="socialProvider == null">
                            <div class="facebook-login-left">
                                <img class="facebook-login-icon"
                                    src="../../assets/images/social-media-icons/facebook-logo-white.png" />
                            </div>
                            <div class="facebook-login-text">
                                {{ 'login.connectWithFacebook' | transloco }}
                            </div>
                            <div class="facebook-login-right"></div>
                        </ng-container>
                    </button>
                </div>
                <div class="form-group mb-4" *ngIf="googleButtonWidth">
                    <google-sign-in-button [locale]="locale.getLocale()" [width]="googleButtonWidth"></google-sign-in-button>
                </div>
                <div class="login-links">
                    <a class="create-account" [routerLink]="'/' + translate.getActiveLang() + '/registration'">{{ 'login.createAccount' | transloco }}</a>
                    <a class="forgot-password" [routerLink]="'/' + translate.getActiveLang() + '/password/forgot'">{{ 'login.forgotPassword' | transloco }}</a>
                </div>
            </form>
        </div>
    </div>
</div>
<app-footer></app-footer>
