import Dinero from 'dinero.js';
import { Deserializable } from '../deserializable.model';
import { Theme } from '../theme.model';
import { User } from '../user.model';
import { Purchase, PurchaseType } from './purchase.model';

export class GiftCardPurchase extends Purchase implements Deserializable<Purchase> {

    _id: string;
    number: number;
    purchaser?: { user?: User };
    payment: { status?: string; intent?: String; };
    value?: Dinero.Dinero;
    data?: {
        theme: Theme
    }
    type: PurchaseType = PurchaseType.GiftCard;
    updatedAt: string;
    createdAt: string;

    get id(): string {
        return this._id;
    }

    set id(id: string) {
        this._id = id;
    }

	deserialize(input: any): this {
        if (!input) return null;
        if (typeof input == 'string') this._id = input;
        else {
            Object.assign(this, input);
            this._id = input._id ? input._id : input.id;
            if (input.purchaser?.user) this.purchaser.user = new User().deserialize(input.purchaser.user);
            if (input.value) this.value = Dinero({ amount: input.value.amount, currency: input.value.currency });
            if (input.data) {
                if (input.data.theme) this.data.theme = new Theme().deserialize(input.data.theme);
            }
        }
		return this;
	}

}