import { Deserializable } from "./deserializable.model";
import { Media } from "./media.model";
import { TranslatedText, TranslatedTextType } from "./translated-text.model";

export class Theme implements Deserializable<Theme> {

    id: string;
    _title: TranslatedText;
    front: Media;

    get title(): TranslatedTextType {
        return this._title?.value;
    }

    set title(title: TranslatedTextType) {
        this._title = title as TranslatedText;
    }

    deserialize(input: any): this {
        if (!input) return null;
        if (typeof input == 'string') this.id = input;
        else {
            Object.assign(this, input);
            this.title = new TranslatedText().deserialize(input.title);
            this.front = new Media().deserialize(input.front);
        }
        return this;
    }
}
