<div #referralCodeModal>
    <div class="radish-modal-content">
        <button type="button" class="radish-modal-dismiss-btn" (click)="activeModal.dismiss()">
            <img class="dismiss-btn-icon" src="../../../../../assets/images/icons/close.svg" 
                alt="{{ 'common.dismiss' | transloco }}" />
                <!-- close -->
            </button>
        <div class="radish-modal-header">
            <h2 class="radish-modal-title" [innerHTML]="">
                {{ affiliate.title }}
            </h2>
        </div>
        <div class="radish-modal-image-container">
            <img src="{{ affiliate.thumbnailUrl || '../../../../../assets/images/gift-box/macarons.jpeg' }}" class="radish-modal-image" alt="">
        </div>
        <div class="radish-modal-body" [innerHTML]="">
            {{ affiliate.brief }}
        </div>
        <div class="radish-modal-footer">
            <button type="button" class="radish-modal-btn-primary" (click)="activeModal.close()">
                {{ 'giftBox.modals.referralCode.buttons.primary' | transloco }}
            </button>
        </div>
    </div>
</div>