<app-navbar
    [navbarType]="configService.getPageVersion((restaurant$ | async)?.slug) === RestaurantStyle.PictureBook ? 'restaurantV2' : 'restaurant'">
</app-navbar>

<ng-container *ngIf="restaurant$ | async">
    <div *ngIf="configService.getPageVersion((restaurant$ | async)?.slug) === RestaurantStyle.PictureBook; else restaurantPageV1">
        <app-restaurant-v2 [orderType]="orderType$ | async" [restaurant]="(restaurant$ | async)"></app-restaurant-v2>
    </div>

    <ng-template #restaurantPageV1>
        <div class="page-container" [ngClass]="configService.getCssClass((restaurant$ | async)?.slug)">
            <div class="overlay"></div>
            <div class="page-hero" [ngClass]="'page-hero-' + configService.getCssClass((restaurant$ | async)?.slug)">
                <div class="page-hero-header">
                    <!-- TODO: add alt attributes -->
                    <div class="page-hero-header-logo">
                        <img *ngIf="(restaurant$ | async)?.slug === RestaurantSlug.Maharani"
                            src="../../assets/images/buffet-maharani/buffet-maharani-logo.png">
                        <img *ngIf="(restaurant$ | async)?.slug === RestaurantSlug.Lyla"
                            src="../../assets/images/lyla/lyla-logo.png">
                        <img *ngIf="(restaurant$ | async)?.slug === RestaurantSlug.MerRouge"
                            src="../../assets/images/mer-rouge/mer-rouge-logo.png">
                        <img *ngIf="(restaurant$ | async)?.slug === RestaurantSlug.Diolo"
                            src="../../assets/images/diolo/diolo-logo-white.svg">
                        <img *ngIf="(restaurant$ | async)?.slug === RestaurantSlug.Arahova" class="arahova-logo"
                            src="../../assets/images/arahova/arahova-logo.png">
                        <img *ngIf="(restaurant$ | async)?.slug === RestaurantSlug.Delicieux" class="delicieux-logo"
                            src="../../assets/images/delicieux/delicieux-logo.png">
                        <img *ngIf="(restaurant$ | async)?.slug === RestaurantSlug.Sham" class="sham-logo"
                            src="../../assets/images/sham/sham-logo.png">
                        <img *ngIf="(restaurant$ | async)?.slug === RestaurantSlug.Nakamichi" class="nakamichi-logo"
                            src="../../assets/images/nakamichi/nakamichi-logo-full.png">
                        <img *ngIf="(restaurant$ | async)?.slug === RestaurantSlug.PtitPlateau" class="ptit-plateau-logo"
                            src="../../assets/images/ptit-plateau/ptit-plateau-logo.png">
                        <img *ngIf="(restaurant$ | async)?.slug === RestaurantSlug.Croqueteria" class="croqueteria-logo"
                            src="../../assets/images/croqueteria/croqueteria-logo.png">
                        <!-- <img *ngIf="(restaurant$ | async)?.slug === 'sushi-plus-beaumont'" class="sushi-plus-logo" src="../../assets/images/sushi-plus-beaumont/sushi-plus-logo.png"> -->
                        <img *ngIf="(restaurant$ | async)?.slug === RestaurantSlug.KojiSoupe" class="koji-soupe-logo"
                            src="../../assets/images/koji-soupe/koji-soupe-logo.png">
                        <img *ngIf="(restaurant$ | async)?.slug === RestaurantSlug.LundisAuSoleil" class="lundis-au-soleil-logo"
                            src="../../assets/images/lundis-au-soleil/lundis-au-soleil-logo-long.svg">
                        <img *ngIf="(restaurant$ | async)?.slug === RestaurantSlug.TincSet" class="tinc-set-logo"
                            src="../../assets/images/tinc-set/tinc-set-logo.svg">
                        <img *ngIf="(restaurant$ | async)?.slug === RestaurantSlug.FanfarePizza" class="fanfare-pizza-logo"
                            src="../../assets/images/fanfare-pizza/fanfare-pizza-logo-blanc.svg">
                        <img *ngIf="(restaurant$ | async)?.slug === RestaurantSlug.InochiExpress" class="inochi-express-logo"
                            src="../../assets/images/inochi-express/inochi-express-logo.png">
                        <div *ngIf="(restaurant$ | async)?.slug === RestaurantSlug.Basha" class="basha-logo-container">
                            <img class="basha-logo-bg" src="../../assets/images/basha/brushstroke.png">
                            <img class="basha-logo" src="../../assets/images/basha/basha-logo.png">
                        </div>
                        <img *ngIf="(restaurant$ | async)?.slug === RestaurantSlug.Uluwatu" class="uluwatu-logo"
                            src="../../assets/images/uluwatu/uluwatu-logo.png">
                        <img *ngIf="(restaurant$ | async)?.slug === RestaurantSlug.SaintHoublon" class="saint-houblon-logo"
                            src="../../assets/images/saint-houblon/saint-houblon-logo.png">
                        <img *ngIf="(restaurant$ | async)?.slug === RestaurantSlug.ChezTauBay" class="chez-tau-bay-logo"
                            src="../../assets/images/chez-tau-bay/chez-tau-bay-logo.png">
                        <img *ngIf="(restaurant$ | async)?.slug === RestaurantSlug.Boustan" class="boustan-logo"
                            src="../../assets/images/boustan/boustan-logo.png">
                        <div *ngIf="(restaurant$ | async)?.slug === RestaurantSlug.ThaiExpressCdn"
                            class="thai-express-cdn-logo-container">
                            <img *ngIf="!isMobile; else thaiExpressMobileLogo" class="thai-express-cdn-logo"
                                src="../../assets/images/thai-express-cdn/thai-express-cdn-logo.png">
                            <ng-template #thaiExpressMobileLogo>
                                <img class="thai-express-cdn-logo"
                                    src="../../assets/images/thai-express-cdn/thai-express-logo-vertical.png">
                            </ng-template>
                        </div>
                        <img *ngIf="(restaurant$ | async)?.slug === RestaurantSlug.JavaUCdn" class="java-u-cdn-logo"
                            src="../../assets/images/java-u-cdn/java-u-logo.png">
                        <img *ngIf="(restaurant$ | async)?.slug === RestaurantSlug.McgillPizza" class="mcgill-pizza-logo"
                            src="../../assets/images/mcgill-pizza/mcgill-pizza-logo.png">
                        <img *ngIf="(restaurant$ | async)?.slug === RestaurantSlug.Yuan" class="yuan-logo"
                            src="../../assets/images/yuan/yuan-logo.png">
                        <img *ngIf="(restaurant$ | async)?.slug === RestaurantSlug.Segreta" class="segreta-logo"
                            src="../../assets/images/segreta/segreta-logo.png">
                        <img *ngIf="(restaurant$ | async)?.slug === RestaurantSlug.OMLT" class="omlt-logo"
                            src="../../assets/images/omlt/omlt-logo.png">
                        <img *ngIf="(restaurant$ | async)?.slug === RestaurantSlug.QuesadaCdn" class="quesada-cdn-logo"
                            src="../../assets/images/quesada-cdn/quesada-cdn-logo.png">
                        <img *ngIf="(restaurant$ | async)?.slug === RestaurantSlug.OiseauxDePassage" class="oiseaux-de-passage-logo"
                            src="../../assets/images/oiseaux-de-passage/oiseaux-de-passage-logo.png">
                        <img *ngIf="(restaurant$ | async)?.slug === RestaurantSlug.CafeVita" class="cafe-vita-logo"
                            src="../../assets/images/cafe-vita/cafe-vita-logo.png">
                        <img *ngIf="(restaurant$ | async)?.slug === RestaurantSlug.BurgerBros" class="burger-bros-logo"
                            src="../../assets/images/burger-bros/burger-bros-logo.png">
                        <img *ngIf="(restaurant$ | async)?.slug === RestaurantSlug.Julians" class="julians-logo"
                            src="../../assets/images/julians/julians-logo.png">
                        <img *ngIf="(restaurant$ | async)?.slug === RestaurantSlug.Wallys" class="wallys-logo"
                            src="../../assets/images/wallys/wallys-logo.png">
                        <img *ngIf="(restaurant$ | async)?.slug === RestaurantSlug.SpiceBros" class="spicebros-logo"
                            src="../../assets/images/spicebros/spicebros-logo.png">
                        <img *ngIf="(restaurant$ | async)?.slug === RestaurantSlug.SantaLucia" class="santa-lucia-logo"
                            src="../../assets/images/santa-lucia/santa-lucia-logo-update.png">
                        <img *ngIf="(restaurant$ | async)?.slug === RestaurantSlug.Dakar" class="dakar-logo"
                            src="../../assets/images/dakar/dakar-logo.png">
                        <img *ngIf="(restaurant$ | async)?.slug === RestaurantSlug.oFour" class="o-four-logo"
                            src="../../assets/images/o-four/o-four-logo.png">
                        <img *ngIf="(restaurant$ | async)?.slug === RestaurantSlug.Karin" class="karin-logo"
                            src="../../assets/images/karin/karin-logo.png">
                        <img *ngIf="(restaurant$ | async)?.slug === RestaurantSlug.MarcheMarema" class="marche-marema-logo"
                            src="../../assets/images/marche-marema/marche-marema-logo.png">
                        <img *ngIf="(restaurant$ | async)?.slug === RestaurantSlug.ElephantThai" class="elephant-thai-logo"
                            src="../../assets/images/elephant-thai/elephant-thai-logo.png">
                        <img *ngIf="(restaurant$ | async)?.slug === RestaurantSlug.Sushiyo" class="sushiyo-logo"
                            src="../../assets/images/sushiyo/sushiyo-logo.png">
                        <img *ngIf="(restaurant$ | async)?.slug === RestaurantSlug.BoiteVegane" class="boite-vegane-logo"
                            src="../../assets/images/boite-vegane/boite-vegane-logo.png">
                        <img *ngIf="(restaurant$ | async)?.slug === RestaurantSlug.JrsCafe" class="jrs-cafe-logo"
                            src="../../assets/images/jrs-cafe/jrs-cafe-logo.png">
                        <img *ngIf="(restaurant$ | async)?.slug === RestaurantSlug.CafeResonance" class="cafe-resonance-logo"
                            src="../../assets/images/cafe-resonance/cafe-resonance-logo.png">
                        <img *ngIf="(restaurant$ | async)?.slug === RestaurantSlug.UbiSushi" class="ubi-sushi-logo"
                            src="../../assets/images/ubi-sushi/ubi-sushi-logo.png">
                        <img *ngIf="(restaurant$ | async)?.slug === RestaurantSlug.Melrose" class="melrose-logo"
                            src="../../assets/images/melrose/melrose-logo.png">
                        <img *ngIf="(restaurant$ | async)?.slug === RestaurantSlug.ObsceneVegan" class="obscene-vegan-logo"
                            src="../../assets/images/obscene-vegan/obscene-vegan-logo.png">
                        <img *ngIf="(restaurant$ | async)?.slug === RestaurantSlug.LestersDeli" class="lesters-deli-logo"
                            src="../../assets/images/lesters-deli/lesters-deli-logo.png">
                        <img *ngIf="(restaurant$ | async)?.slug === RestaurantSlug.Barranco" class="barranco-logo"
                            src="../../assets/images/barranco/barranco-logo-horizontal.svg">
                        <img *ngIf="(restaurant$ | async)?.slug === RestaurantSlug.PetitSeoul" class="petit-seoul-logo"
                            src="../../assets/images/petit-seoul/petit-seoul-logo.png">
                        <img *ngIf="(restaurant$ | async)?.slug === RestaurantSlug.Hooters" class="hooters-logo"
                            src="../../assets/images/hooters/hooters-logo.png">
                        <img *ngIf="(restaurant$ | async)?.slug === RestaurantSlug.Darbar" class="darbar-logo"
                            src="../../assets/images/darbar/darbar-logo.jpg">
                        <img *ngIf="(restaurant$ | async)?.slug === RestaurantSlug.JardinPetros" class="jardin-petros-logo"
                            src="../../assets/images/jardin-petros/jardin-petros-logo.png">
                        <img *ngIf="(restaurant$ | async)?.slug === RestaurantSlug.ChezAnna" class="chez-anna-logo"
                            src="../../assets/images/chez-anna/chez-anna-logo.png">
                        <img *ngIf="(restaurant$ | async)?.slug === RestaurantSlug.Krood" class="krood-logo"
                            src="../../assets/images/krood/krood-logo.jpg">
                        <img *ngIf="(restaurant$ | async)?.slug === RestaurantSlug.Maynard" class="maynard-logo"
                            src="../../assets/images/maynard/maynard-logo.png">
                        <img *ngIf="(restaurant$ | async)?.slug === RestaurantSlug.MFourBurritos" class="m4-burritos-logo"
                            src="../../assets/images/m4-burritos/m4-burritos-logo-black.png">
                        <img *ngIf="(restaurant$ | async)?.slug === RestaurantSlug.Bocadillo" class="bocadillo-logo"
                            src="../../assets/images/bocadillo/bocadillo-logo.png">
                        <img *ngIf="(restaurant$ | async)?.slug === RestaurantSlug.LaurierBBQ" class="laurier-bbq-logo"
                            src="../../assets/images/laurier-bbq/laurier-bbq-logo.png">
                        <img *ngIf="(restaurant$ | async)?.slug === RestaurantSlug.oFish" class="o-fish-logo"
                            src="../../assets/images/o-fish/o-fish-logo.png">
                        <img *ngIf="(restaurant$ | async)?.slug === RestaurantSlug.SushiSama" class="sushi-sama-concordia-logo"
                            src="../../assets/images/sushi-sama/sushi-sama-logo.png">
                        <img *ngIf="(restaurant$ | async)?.slug === RestaurantSlug.PaniniStop" class="panini-stop-logo"
                            src="../../assets/images/panini-stop/panini-stop-logo.png">
                        <img *ngIf="(restaurant$ | async)?.slug === RestaurantSlug.PokeStationCdn" class="poke-station-cdn-logo"
                            src="../../assets/images/poke-station/poke-station-logo.png">
                        <img *ngIf="(restaurant$ | async)?.slug === RestaurantSlug.TeochewFoodie" class="teochew-foodie-logo"
                            src="../../assets/images/teochew/teochew-foodie-logo.png">
                        <img *ngIf="(restaurant$ | async)?.slug === RestaurantSlug.Tapigo" class="tapi-go-logo"
                            src="../../assets/images/tapi-go/tapi-go-logo.png">
                        <img *ngIf="(restaurant$ | async)?.slug === RestaurantSlug.BrigadePizza" class="brigade-pizza-logo"
                            src="../../assets/images/brigade-pizza/brigade-pizza-logo.png">
                        <img *ngIf="(restaurant$ | async)?.slug === RestaurantSlug.AlAmine" class="al-amine-logo"
                            src="../../assets/images/al-amine/al-amine-logo.png">
                        <img *ngIf="(restaurant$ | async)?.slug === RestaurantSlug.BernieBeigne" class="bernie-beigne-logo"
                            src="../../assets/images/bernie-beigne/bernie-beigne-logo.png">
                        <img *ngIf="(restaurant$ | async)?.slug === RestaurantSlug.Cacao70" class="cacao-70-logo"
                            src="../../assets/images/cacao-70/cacao-70-logo.png">
                        <img *ngIf="(restaurant$ | async)?.slug === RestaurantSlug.TacosNation" class="tacos-nation-logo"
                            src="../../assets/images/tacos-nation/tacos-nation-logo.png">
                        <img *ngIf="(restaurant$ | async)?.slug === RestaurantSlug.PoissonnerieSherbrooke" class="poissonnerie-sherbrooke-logo"
                            src="../../assets/images/poissonnerie-sherbrooke/poissonnerie-sherbrooke-logo.png">
                        <img *ngIf="(restaurant$ | async)?.slug === RestaurantSlug.Mintar" class="mintar-logo"
                            src="../../assets/images/mintar/mintar-logo.png">
                        <img *ngIf="(restaurant$ | async)?.slug === RestaurantSlug.AuxMerveilleuxDeFred" class="aux-merveilleux-de-fred-logo"
                            src="../../assets/images/aux-merveilleux-de-fred/aux-merveilleux-de-fred-logo.png">
                        <div *ngIf="(restaurant$ | async)?.slug === RestaurantSlug.LaToile" class="la-toile-logo-container">
                            <img class="la-toile-logo-bg" src="../../assets/images/la-toile/la-toile-stroke.png">
                            <img class="la-toile-logo" src="../../assets/images/la-toile/la-toile-logo.png">
                        </div>
                        <img *ngIf="(restaurant$ | async)?.slug === RestaurantSlug.SepLai"
                             class="sep-lai-logo"
                             src="../../assets/images/sep-lai/sep-lai-logo-banner.png"
                             alt="Sep Lai"
                        >
                        <img *ngIf="(restaurant$ | async)?.slug === RestaurantSlug.BistroDeLaCite"
                             class="bistro-de-la-cite"
                             src="../../assets/images/bistro-de-la-cite/bistro-de-la-cite-logo.png"
                             alt="Bistro de la Cité"
                        >
                        <img *ngIf="(restaurant$ | async)?.slug === RestaurantSlug.Singhs"
                             class="singhs"
                             src="../../assets/images/singhs/singhs-logo.png"
                             alt="Singh's">
                        <img *ngIf="(restaurant$ | async)?.slug === RestaurantSlug.ChaiTeaLounge"
                             class="chai-tea-lounge"
                             src="../../assets/images/chai-tea-lounge/chai-tea-lounge-logo.png"
                             alt="Chai Tea Lounge"
                        >
                        <img *ngIf="(restaurant$ | async)?.slug === RestaurantSlug.LaCroissanterieFigaro"
                             class="la-croissanterie-figaro"
                             src="../../assets/images/la-croissanterie-figaro/la-croissanterie-figaro-logo.png"
                             alt="La Croissanterie Figaro"
                        >
                        <img *ngIf="(restaurant$ | async)?.slug === RestaurantSlug.LaFabriqueDeBagel"
                             class="la-fabrique-de-bagel"
                             src="../../assets/images/la-fabrique-de-bagel/lfdb-logo-white.png"
                             alt="La Fabrique du Bagel"
                        >
                        <img *ngIf="(restaurant$ | async)?.slug === RestaurantSlug.StudentTastyBiryani"
                             class="student-tasty-biryani"
                             src="../../../assets/images/student-tasty-biryani/stb-logo-radial.png"
                             alt="Student Tasty Biryani"
                        >
                        <img *ngIf="(restaurant$ | async)?.slug === RestaurantSlug.OsukaSushi"
                             class="osuka-sushi"
                             src="../../assets/images/osuka-sushi/osuka-sushi-logo.png"
                             alt="Osuka Sushi"
                        >
                        <img *ngIf="(restaurant$ | async)?.slug === RestaurantSlug.Lalis"
                             class="lali"
                             src="../../assets/images/lali/lali-logo.png"
                             alt="Lali's"
                        >
                        <img *ngIf="(restaurant$ | async)?.slug === RestaurantSlug.ChezTaMere"
                             class="chez-ta-mere"
                             src="../../../assets/images/chez-ta-mere/chez-ta-mere-logo.png"
                             alt="Chez Ta Mere"
                        >
                        <img *ngIf="(restaurant$ | async)?.slug === RestaurantSlug.Chatpata"
                             class="chatpata"
                             src="../../../assets/images/chatpata/chatpata-logo.png"
                             alt="Chatpata"
                        >
                        <img *ngIf="(restaurant$ | async)?.slug === RestaurantSlug.FriteAlors"
                             class="frite-alors"
                             src="../../../assets/images/frite-alors/frite-alors-logo.svg"
                             alt="Frite Alors"
                        >
                        <img *ngIf="(restaurant$ | async)?.slug === RestaurantSlug.Tibum"
                             class="tibum"
                             src="../../../assets/images/tibum/tibum-logo.png"
                             alt="Tibum"
                        >
                        <img *ngIf="(restaurant$ | async)?.slug === RestaurantSlug.ThePerle"
                            class="the-perle"
                            src="../../../assets/images/the-perle/the-perle-logo.png"
                            alt="The Perle"
                        >
                        <img *ngIf="(restaurant$ | async)?.slug === RestaurantSlug.Gongcha"
                            class="gong-cha"
                            src="../../../assets/images/gong-cha/gong-cha-logo.png"
                            alt="Gong cha"
                        >
                        <img *ngIf="(restaurant$ | async)?.slug === RestaurantSlug.BuffaloBill"
                            class="buffalo-bill"
                            src="../../../assets/images/buffalo-bill/buffalo-bill-logo.png"
                            alt="Buffalo Bill"
                        >
                        <img *ngIf="(restaurant$ | async)?.slug === RestaurantSlug.SouvlakiGrecPlus"
                            class="souvlaki-grec-plus"
                            src="../../../assets/images/souvlaki-grec-plus/souvlaki-grec-plus-logo.png"
                            alt="Souvlaki Grec Plus"
                        >
                        <img *ngIf="(restaurant$ | async)?.slug === RestaurantSlug.PizzaSport"
                            class="pizza-sport"
                            src="../../../assets/images/pizza-sport/pizza-sport-logo.png"
                            alt="Pizza Sport"
                        >
                        <img *ngIf="(restaurant$ | async)?.slug === RestaurantSlug.SpartaChicken"
                            class="sparta-chicken"
                            src="../../../assets/images/sparta-chicken/sparta-chicken-logo.png"
                            alt="Sparta Chicken"
                        >
                        <img *ngIf="(restaurant$ | async)?.slug === RestaurantSlug.Tsujiri"
                            class="tsujiri"
                            src="../../../assets/images/tsujiri/tsujiri-logo.png"
                            alt="Tsujiri"
                        >
                        <img *ngIf="(restaurant$ | async)?.slug === RestaurantSlug.P23Dumplings"
                            class="p23-dumplings"
                            src="../../../assets/images/p23-dumplings/p23-dumplings-logo.png"
                            alt="P23Dumplings"
                        >
                        <img *ngIf="(restaurant$ | async)?.slug === RestaurantSlug.BurgzCantine"
                            class="burgz-cantine"
                            src="../../../assets/images/burgz-cantine/burgz-cantine-logo.png"
                            alt="Burgz Cantine"
                        >
                        <img *ngIf="(restaurant$ | async)?.slug === RestaurantSlug.Goplana"
                        class="goplana"
                        src="../../../assets/images/goplana/goplana-logo.png"
                        alt="Goplana"
                        >
                        <img *ngIf="(restaurant$ | async)?.slug === RestaurantSlug.PiriPiri"
                        class="piri-piri"
                        src="../../../assets/images/piri-piri/piri-piri-logo.png"
                        alt="Piri Piri"
                        >
                        <img *ngIf="(restaurant$ | async)?.slug === RestaurantSlug.UkiyaIzakaya"
                        class="ukiya-izakaya"
                        src="../../../assets/images/ukiya-izakaya/ukiya-izakaya-logo.png"
                        alt="Ukiya Izakaya"
                        >
                        <img *ngIf="(restaurant$ | async)?.slug === RestaurantSlug.LivSalades"
                        class="liv-salades"
                        src="../../../assets/images/liv-salades/liv-salades-logo.png"
                        alt="Liv Salades"
                        >
                        <img *ngIf="(restaurant$ | async)?.slug === RestaurantSlug.MaisonDuDestin"
                        class="maison-du-destin"
                        src="../../../assets/images/maison-du-destin/maison-du-destin-logo.png"
                        alt="Maison du Destin"
                        >
                        <img *ngIf="(restaurant$ | async)?.slug === RestaurantSlug.FlatBellyDeli"
                        class="flat-belly-deli"
                        src="../../../assets/images/flat-belly-deli/flat-belly-deli-logo.png"
                        alt="Flat Belly Deli"
                        >
                        <img *ngIf="(restaurant$ | async)?.slug === RestaurantSlug.DowntownDhaba"
                        class="downtown-dhaba"
                        src="../../../assets/images/downtown-dhaba/downtown-dhaba-logo.png"
                        alt="Downtown Dhaba"
                        >
                        <img *ngIf="(restaurant$ | async)?.slug === RestaurantSlug.Grillados"
                        class="grillados"
                        src="../../../assets/images/grillados/grillados-logo.png"
                        alt="Grillados"
                        >
                        <!-- RestoPage TODO: Add logo image (and optional logo bg) above -->
                    </div>

                    <h1 class="hero-header-name" *ngIf="configService.isHeaderNameVisible((restaurant$ | async)?.slug)">
                        {{ (restaurant$ | async)?.name }}
                    </h1>
                </div>
            </div>

            <div class="navigation">
                <div class="navigation-wrapper">
                    <div
                        [routerLink]="['./reviews']"
                        class="navigation-item"
                        [ngClass]="{ active: tab == 'reviews' }"
                    >
                        <span class="navigation-item-title">
                            {{ 'restaurant.navigation.reviews' | transloco }}
                        </span>
                    </div>
                    <div
                        [routerLink]="['./about']"
                        class="navigation-item"
                        [ngClass]="{ active: tab == 'about' }"
                    >
                        <span class="navigation-item-title">
                            {{ 'restaurant.navigation.about' | transloco }}
                        </span>
                    </div>
                    <div
                        [routerLink]="['./menu']"
                        class="navigation-item"
                        [ngClass]="{ active: tab == 'menu' }"
                    >
                        <img
                            *ngIf="showOrderHereAnimation$ | async"
                            id="order-here-gif"
                            [src]="'/assets/videos/order-here/order-here-' + configService.getOrderHereAnimationColor((restaurant$ | async)?.slug) + '-' + translate.getActiveLang() + '.gif'"
                            alt="GIF pointing towards menu button that says 'Order Here'"
                        />
                        <span class="navigation-item-title">
                            {{ 'restaurant.navigation.menu' | transloco }}
                        </span>
                    </div>
                </div>
            </div>

            <div class="restaurant-page-content-container">
                <router-outlet></router-outlet>
            </div>
        </div>
    </ng-template>
</ng-container>
