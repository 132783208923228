import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter, Observable } from 'rxjs';
import { Exposition } from 'src/app/core/models';
import { MainService } from 'src/app/core/services/main.service';

@UntilDestroy()
@Component({
	selector: 'expo-nav',
	templateUrl: './nav.component.html',
	styleUrls: ['./nav.component.css']
})
export class ExpoNavComponent implements OnInit {

	@Output() onOpenScheduleEvent: EventEmitter<any> = new EventEmitter<{}>();

	scheduledDate$: Observable<Date>;

	isScheduler: boolean = false;
	isSchedule: boolean = false;

	scheduledTime: Date;

	page: string;

	exposition: Exposition;

	constructor(
		private mainService: MainService,
		private router: Router,
		private route: ActivatedRoute,
		private translate: TranslocoService
	) {
		this.router.events.pipe(untilDestroyed(this), filter(event => event instanceof NavigationEnd)).subscribe(event => {
			this.page = (event as RouterEvent).url.split('/')[4];
			if (this.page != 'checkout' && this.page != 'order') this.page = 'other';
		});
	}

	ngOnInit(): void {
		this.exposition = this.route.snapshot.data['exposition'];
		this.scheduledDate$ = this.mainService.scheduledDateLoaded$;
		this.scheduledDate$.subscribe((scheduledDate: Date) => this.scheduledTime = scheduledDate);
	}

	showSchedule() {
		this.isSchedule = !this.isSchedule;
	}

	onClickRadishLogo() {
        this.router.navigate([this.translate.getActiveLang + '/expo/' + this.exposition.slug])
        .then(() => {
            window.location.reload();
        });
	}
}
