<form [formGroup]="promoCodeForm" class="promo-code-container" (ngSubmit)="onSubmit(promoCodeForm.value)">
    <h2 class="title mb-3"><b>{{ 'promoCodeModal.title' | transloco }}</b></h2>
    <input formControlName="code" type="text" class="form-input" [class.mb-4]="!discountErrorMessage" placeholder="{{ 'promoCodeModal.enterCode' | transloco }}">
    <div *ngIf="discountErrorMessage" class="error mb-4 mt-1" [innerHTML]="discountErrorMessage | transloco:{ restaurantName: restaurant?.name }"></div>
    <button type="submit" class="form-submit-button" [disabled]="!promoCodeForm.valid || loading">
        <span *ngIf="!loading">
            {{ 'promoCodeModal.applyCode' | transloco }}
        </span>
        <div *ngIf="loading" class="spinner-border spinner-border-sm" role="status">
            <span class="sr-only">{{ 'common.loading' | transloco }}</span>
        </div>
    </button>
</form>
