<app-navbar></app-navbar>
<div class="registration-container">
	<div class="container">
		<div class="registration-container-column col-md-8 col-lg-8 col-xl-6">
			<div class="registration-container-title">
				<h2>{{ 'register.register' | transloco }}</h2>
			</div>
			<form class="registration-form" [formGroup]="registrationForm" (ngSubmit)="onSubmit(registrationForm.value)"
				novalidate>
				<form [formGroup]="nameForm">
					<div class="row">
						<div class="col-lg-6 mb-4 form-group">
							<input formControlName="first" type="text" class="form-control" id="first"
								[placeholder]="'register.firstName' | transloco" value="" [ngClass]="{
									'is-invalid':
										nameFormControls.first.invalid &&
										nameFormControls.first.dirty
								}" required />
							<div class="line"></div>
							<div *ngIf="
									nameFormControls.first.errors &&
									(nameFormControls.first.dirty ||
										nameFormControls.first.touched)
								" class="form-control-feedback invalid-feedback">
								<div *ngIf="nameFormControls.first.errors.required">
									{{ 'register.firstNameRequired' | transloco }}
								</div>
							</div>
						</div>
						<div class="col-lg-6 mb-4 form-group">
							<input formControlName="last" type="text" class="form-control" id="last"
								[placeholder]="'register.lastName' | transloco" value="" [ngClass]="{
									'is-invalid':
										nameFormControls.last.invalid && nameFormControls.last.dirty
								}" required />
							<div class="line"></div>
							<div *ngIf="
									nameFormControls.last.errors &&
									(nameFormControls.last.dirty || nameFormControls.last.touched)
								" class="form-control-feedback invalid-feedback">
								<div *ngIf="nameFormControls.last.errors.required">
									{{ 'register.lastNameRequired' | transloco }}
								</div>
							</div>
						</div>
					</div>
				</form>
				<div class="mb-4 form-group">
					<input formControlName="email" type="email" class="form-control" id="email"
						[placeholder]="'register.email' | transloco" [ngClass]="{
							'is-invalid':
								registrationFormControls.email.invalid &&
								registrationFormControls.email.dirty
						}" required />
					<div class="line"></div>
					<div *ngIf="
							registrationFormControls.email.errors &&
							(registrationFormControls.email.dirty ||
								registrationFormControls.email.touched)
						" class="form-control-feedback invalid-feedback">
						<div *ngIf="registrationFormControls.email.errors.required">
							{{ 'register.emailRequired' | transloco }}
						</div>
						<div *ngIf="registrationFormControls.email.errors.email">
							{{ 'register.emailNotValid' | transloco }}
						</div>
					</div>
				</div>
				<div class="mb-4 form-group">
                    <international-phone-number2 [noUSCountryCode]="false" formControlName="number" [placeholder]="'register.phoneNumber' | transloco" [maxlength]="20" [defaultCountry]="'ca'" [required]="true" name="phone_number"></international-phone-number2>
					<div class="line"></div>
					<div *ngIf="
							registrationFormControls.number.errors &&
							(registrationFormControls.number.dirty ||
								registrationFormControls.number.touched)
						" class="form-control-feedback invalid-feedback">
						<div *ngIf="registrationFormControls.number.errors.number">
							{{ 'register.phoneNumberNotValid' | transloco }}
						</div>
					</div>
				</div>
				<form [formGroup]="passwordForm">
					<div class="row">
						<div class="col-lg-6 mb-4 form-group">
							<input formControlName="password" type="password" class="form-control" id="password"
								[placeholder]="'register.password.title' | transloco" value="" [ngClass]="{
									'is-invalid':
										passwordFormControls.password.invalid &&
										passwordFormControls.password.dirty
								}" required />
							<div class="line"></div>
							<div *ngIf="
									passwordFormControls.password.errors &&
									(passwordFormControls.password.dirty ||
										passwordFormControls.password.touched)
								" class="form-control-feedback invalid-feedback">
								<div *ngIf="passwordFormControls.password.errors.required">
									{{ 'register.password.required' | transloco }}
								</div>
								<div *ngIf="
										passwordFormControls.password.errors.minlength ||
										passwordFormControls.password.errors.maxlength
									">
									{{ 'register.password.length' | transloco }}
								</div>
							</div>
						</div>
						<div class="col-lg-6 mb-4 form-group">
							<input formControlName="confirmpassword" type="password" class="form-control" id="confirmpassword"
								[placeholder]="'register.confirmPassword' | transloco" value="" [ngClass]="{
									'is-invalid':
										passwordFormControls.confirmpassword.invalid &&
										passwordFormControls.confirmpassword.dirty
								}" required />
							<div class="line"></div>
							<div *ngIf="
									passwordFormControls.confirmpassword.errors &&
									(passwordFormControls.confirmpassword.dirty ||
										passwordFormControls.confirmpassword.touched)
								" class="form-control-feedback invalid-feedback">
								<div *ngIf="passwordFormControls.confirmpassword.errors.required">
									{{ 'register.password.required' | transloco }}
								</div>
								<div *ngIf="passwordFormControls.confirmpassword.errors?.mustMatch">
									{{ 'register.password.noMatch' | transloco }}
								</div>
							</div>
						</div>
					</div>
				</form>
				<div class="mb-2 form-group register-form-group-checkbox">
					<div class="form-check">
						<input formControlName="terms" class="form-check-input" type="checkbox" id="gridCheckTerms" />
						<label class="form-check-label" for="gridCheckTerms">{{ 'register.readAndAgree' | transloco }}
							<a class="register-terms-link" target="_blank"
								[routerLink]="['../terms']">{{ 'register.termsAndConditions' | transloco }}</a>
							{{ 'register.andThe' | transloco }}
							<a class="register-terms-link" target="_blank"
								[routerLink]="['../privacy']">{{ 'register.privacyPolicy' | transloco }}</a>.
						</label>
					</div>
				</div>
				<div class="mb-4 form-group register-form-group-checkbox">
					<div class="form-check">
						<input formControlName="receiveEmails" class="form-check-input" type="checkbox"
							id="gridCheckReceiveEmails" />
						<label class="form-check-label" for="gridCheckReceiveEmails">{{
							'register.sendMeEmails' | transloco
						}}</label>
					</div>
				</div>
				<div class="form-group register-form-group">
					<button class="btn btn-outline-primary btn-block btn-register form-control" type="submit"
						[disabled]="
							!registrationForm.valid ||
							!nameForm.valid ||
							!passwordForm.valid ||
							registrationForm.value.terms !== true ||
							loading
                        "
                        angulartics2On="click" angularticsAction="Register"
                        angularticsCategory="Auth" [angularticsProperties]="{event: 'Click'}">
						<div *ngIf="loading" class="spinner-border spinner-border-sm" role="status">
							<span class="sr-only">{{ 'common.loading' | transloco }}</span>
						</div>
						<span *ngIf="!loading">
							{{ 'common.submit' | transloco }}
						</span>
					</button>
				</div>
				<div class="form-group registration-errors">
					<div [innerHTML]="'register.emailInUse' | transloco" *ngIf="emailInUse"></div>
					<div [innerHTML]="'register.numberInUse' | transloco" *ngIf="numberInUse"></div>
					<div *ngIf="phoneInvalid">
						{{ 'register.phoneNumberNotValid' | transloco }}
					</div>
					<div *ngIf="emailInvalid">
						{{ 'register.emailNotValid' | transloco }}
					</div>
					<div *ngIf="unknownError">
                        {{ 'register.unknownError' | transloco }}
					</div>
				</div>
			</form>
		</div>
	</div>
</div>
<app-footer></app-footer>
