import { Injectable } from '@angular/core';
import { Color, MenuItemStyle, MenuStyle, RestaurantSlug, RestaurantStyle, RestaurantType } from '../enums';
import Dinero from 'dinero.js';
import { RestaurantStyleType } from 'src/app/core/models';

@Injectable({
    providedIn: 'root'
})
export class ConfigService {

    readonly MINIMUM_ORDER_AMOUNT = Dinero({ amount: 500, currency: 'CAD' }); //TODO: split up
    readonly MINIMUM_DELIVERY_ORDER_AMOUNT = Dinero({ amount: 500, currency: 'CAD' });
    readonly MINIMUM_PICKUP_ORDER_AMOUNT = Dinero({ amount: 500, currency: 'CAD' });
    
    readonly MINIMUM_MARKET_ORDER_AMOUNT = Dinero({ amount: 1000, currency: 'CAD' });
    readonly MINIMUM_EXPO_POCHA_ORDER_AMOUNT = Dinero({ amount: 700, currency: 'CAD' });
    
    readonly SMALL_DELIVERY_ORDER_FEE = Dinero({ amount: 299, currency: 'CAD' });
    readonly MINIMUM_FREE_DELIVERY_THRESHOLD = Dinero({ amount: 1500, currency: 'CAD' });
    
    readonly STANDARD_DELIVERY_FEE = Dinero({ amount: 399, currency: 'CAD' }); // replaced by free delivery text

    private readonly DEFAULT_IMAGE_URL = 'https://dummyimage.com/600x400/dddddd/dddddd'

    private readonly DEFAULT_GOOGLE_MAP_STYLE = [
        {
            elementType: 'geometry',
            stylers: [{ color: '#f7f7f7' }]
        },
        {
            featureType: 'road',
            elementType: 'geometry',
            stylers: [{ color: '#ffffff' }]
        },
        {
            featureType: 'poi.business',
            stylers: [{ visibility: 'off' }]
        },
        {
            featureType: 'water',
            elementType: 'geometry',
            stylers: [{ color: '#51A3A3' }]
        }
    ]

    private readonly RESTAURANT: { [restaurant in RestaurantType]: RestaurantStyleType } = {
        [RestaurantSlug.Maharani]: {
            cssClass: 'maharani',
            pageVersion: RestaurantStyle.Classic,
            color: {
                primary: '#BC9B5D',
                secondary: '#ffffff'
            },
            styles: {
                page: {
                    menu: {
                        button: {
                            color: '#BC9B5D',
                            disabledColor: '#a59a88',
                            iconColor: '#000000'
                        },
                        item: {
                            description: {
                                fontWeight: '400',
                                color: ''
                            }
                        },
                        style: MenuStyle.Standard,
                        selectable: false
                    },
                    reviews: {
                        icon: {
                            color: '#ffffff'
                        },
                        newReviewButton: {
                            backgroundColor: '#ffffff',
                            fontColor: '#000000',
                            disabled: {
                                backgroundColor: '#515151',
                                fontColor: '#a1a1a1',
                            }
                        }
                    },
                    frontPage: {
                        fontSize: '20px',
                        hoursDropdown: {
                            icon: {
                                size: '1.2rem'
                            }
                        },
                        header: {
                            name: {
                                visible: 'true'
                            }
                        }
                    }
                },
                common: {
                    borderRadius: ''
                },
                orderHereAnimation: {
                    color: Color.White,
                    screen: {
                        full: {
                            width: '',
                            bottom: '',
                            right: ''
                        }
                    }
                }
            }
        },
        [RestaurantSlug.PandaBistro]: {
            cssClass: 'panda-bistro',
            pageVersion: RestaurantStyle.Classic,
            color: {
                primary: '#000000',
                secondary: '#ffffff'
            },
            styles: {
                page: {
                    menu: {
                        button: {
                            color: '#000000',
                            disabledColor: '#a59a88',
                            iconColor: '#ffffff'
                        },
                        item: {
                            description: {
                                fontWeight: '400',
                                color: ''
                            }
                        },
                        style: MenuStyle.Standard,
                        selectable: false
                    },
                    reviews: {
                        icon: {
                            color: '#ffffff'
                        },
                        newReviewButton: {
                            backgroundColor: '#ffffff',
                            fontColor: '#000000',
                            disabled: {
                                backgroundColor: '#515151',
                                fontColor: '#a1a1a1',
                            }
                        }
                    },
                    frontPage: {
                        fontSize: '20px',
                        hoursDropdown: {
                            icon: {
                                size: '1.2rem'
                            }
                        },
                        header: {
                            name: {
                                visible: 'true'
                            }
                        }
                    }
                },
                common: {
                    borderRadius: ''
                },
                orderHereAnimation: {
                    color: Color.White,
                    screen: {
                        full: {
                            width: '',
                            bottom: '',
                            right: ''
                        }
                    }
                }
            }
        },
        [RestaurantSlug.Enchanteur]: {
            cssClass: 'enchanteur',
            pageVersion: RestaurantStyle.Classic,
            color: {
                primary: '#000000',
                secondary: '#000000'
            },
            styles: {
                page: {
                    menu: {
                        button: {
                            color: '',
                            disabledColor: '',
                            iconColor: '#000000'
                        },
                        item: {
                            description: {
                                fontWeight: '400',
                                color: ''
                            }
                        },
                        style: MenuStyle.Standard,
                        selectable: false
                    },
                    reviews: {
                        newReviewButton: {
                            disabled: {
                                backgroundColor: '#e0e0e0',
                                fontColor: '#a1a1a1',
                            }
                        }
                    },
                    frontPage: {
                        fontSize: '20px',
                        hoursDropdown: {
                            icon: {
                                size: '1.2rem'
                            }
                        }
                    }
                },
                common: {
                    borderRadius: ''
                },
                orderHereAnimation: {
                    color: Color.Black,
                    screen: {
                        full: {
                            width: '',
                            bottom: '',
                            right: ''
                        }
                    }
                }
            }
        },
        [RestaurantSlug.Lyla]: {
            cssClass: 'lyla',
            pageVersion: RestaurantStyle.Classic,
            color: {
                primary: '#000000',
                secondary: '#000000'
            },
            styles: {
                page: {
                    menu: {
                        button: {
                            color: '#007cc3',
                            disabledColor: '#a7a7a7',
                            iconColor: '#ffffff'
                        },
                        tab: {
                            font: 'Roboto, sans-serif',
                            padding: '1px 0px',
                            active: {
                                backgroundColor: 'transparent',
                                color: '#000',
                                borderBottom: '2px solid #007cc3',
                                border: 'none',
                                fontWeight: '500'
                            },
                            inactive: {
                                font: 'Roboto, sans-serif',
                                backgroundColor: 'transparent',
                                color: '#000',
                                borderBottom: 'none',
                                border: 'none',
                                fontWeight: '400'
                            }
                        },
                        item: {
                            description: {
                                fontWeight: '400',
                                color: ''
                            }
                        },
                        style: MenuStyle.Standard,
                        selectable: true
                    },
                    reviews: {
                        newReviewButton: {
                            disabled: {
                                backgroundColor: '#e0e0e0',
                                fontColor: '#a1a1a1',
                            }
                        }
                    },
                    frontPage: {
                        fontSize: '20px',
                        hoursDropdown: {
                            icon: {
                                size: '1.2rem'
                            }
                        }
                    }
                },
                common: {
                    borderRadius: ''
                },
                orderHereAnimation: {
                    color: Color.Black,
                    screen: {
                        full: {
                            width: '',
                            bottom: '',
                            right: ''
                        }
                    }
                }
            }
        },
        [RestaurantSlug.Diolo]: {
            cssClass: 'diolo',
            pageVersion: RestaurantStyle.Classic,
            color: {
                primary: '#ffffff',
                secondary: '#ffffff'
            },
            styles: {
                page: {
                    menu: {
                        button: {
                            color: '#ffffff',
                            disabledColor: '',
                            iconColor: '#000000'
                        },
                        item: {
                            description: {
                                fontWeight: '400',
                                color: ''
                            }
                        },
                        style: MenuStyle.Standard,
                        selectable: false
                    },
                    reviews: {
                        icon: {
                            color: '#ffffff'
                        },
                        newReviewButton: {
                            backgroundColor: '#ffffff',
                            fontColor: '#000000',
                            disabled: {
                                backgroundColor: '#515151',
                                fontColor: '#a1a1a1',
                            }
                        }
                    },
                    frontPage: {
                        fontSize: '20px',
                        hoursDropdown: {
                            icon: {
                                size: '1.2rem'
                            }
                        }
                    }
                },
                common: {
                    borderRadius: ''
                },
                orderHereAnimation: {
                    color: Color.White,
                    screen: {
                        full: {
                            width: '',
                            bottom: '',
                            right: ''
                        }
                    }
                }
            }
        },
        [RestaurantSlug.MerRouge]: {
            cssClass: 'merRouge',
            pageVersion: RestaurantStyle.Classic,
            color: {
                primary: '#ffffff',
                secondary: '#ffffff'
            },
            styles: {
                page: {
                    menu: {
                        button: {
                            color: '#ffffff',
                            disabledColor: '#838F9E',
                            iconColor: '#000000'
                        },
                        item: {
                            description: {
                                fontWeight: '400',
                                color: ''
                            }
                        },
                        style: MenuStyle.Standard,
                        selectable: false
                    },
                    reviews: {
                        icon: {
                            color: '#ffffff'
                        },
                        newReviewButton: {
                            backgroundColor: '#ffffff',
                            fontColor: '#1B2F49',
                            disabled: {
                                backgroundColor: '#e0e0e0',
                                fontColor: '#a1a1a1',
                            }
                        }
                    },
                    frontPage: {
                        fontSize: '20px',
                        hoursDropdown: {
                            icon: {
                                size: '1.2rem'
                            }
                        },
                        header: {
                            name: {
                                visible: 'true'
                            }
                        }
                    }
                },
                common: {
                    borderRadius: ''
                },
                map: {
                    google: [
                        {
                            elementType: 'geometry',
                            stylers: [{ color: '#f7f7f7' }]
                        },
                        {
                            featureType: 'road',
                            elementType: 'geometry',
                            stylers: [{ color: '#BCD1EB' }]
                        },
                        {
                            featureType: 'poi.business',
                            stylers: [{ visibility: 'off' }]
                        },
                        {
                            featureType: 'water',
                            elementType: 'geometry',
                            stylers: [{ color: '#4C7194' }]
                        }
                    ]
                },
                orderHereAnimation: {
                    color: Color.White,
                    screen: {
                        full: {
                            width: '',
                            bottom: '',
                            right: ''
                        }
                    }
                }
            }
        },
        [RestaurantSlug.Arahova]: {
            cssClass: 'arahova',
            pageVersion: RestaurantStyle.Classic,
            color: {
                primary: '#000000',
                secondary: '#000000'
            },
            styles: {
                page: {
                    menu: {
                        button: {
                            color: '#1fbdef',
                            disabledColor: '#919191',
                            iconColor: '#ffffff'
                        },
                        item: {
                            name: {
                                font: 'Ubuntu, sans-serif',
                                fontSize: '1.1rem',
                                fontWeight: '400',
                            },
                            description: {
                                font: 'Ubuntu, sans-serif',
                                fontWeight: '300',
                                color: ''
                            },
                            price: {
                                font: 'Ubuntu, sans-serif',
                                fontSize: '1rem',
                                fontWeight: '400',
                            },
                            style: MenuItemStyle.HorizontalWithOptionalImage
                        },
                        style: MenuStyle.Standard,
                        selectable: false
                    },
                    reviews: {
                        newReviewButton: {
                            disabled: {
                                backgroundColor: '#e0e0e0',
                                fontColor: '#a1a1a1',
                            }
                        }
                    },
                    frontPage: {
                        fontSize: '20px',
                        hoursDropdown: {
                            icon: {
                                size: '1.2rem'
                            }
                        }
                    }
                },
                common: {
                    borderRadius: ''
                },
                orderHereAnimation: {
                    color: Color.Black,
                    screen: {
                        full: {
                            width: '',
                            bottom: '',
                            right: ''
                        }
                    }
                }
            }
        },
        [RestaurantSlug.Delicieux]: {
            cssClass: 'delicieux',
            pageVersion: RestaurantStyle.Classic,
            color: {
                primary: '#000000',
                secondary: '#000000'
            },
            styles: {
                page: {
                    menu: {
                        button: {
                            color: '#391413',
                            disabledColor: '#787878',
                            iconColor: '#ffffff'
                        },
                        item: {
                            description: {
                                fontWeight: '400',
                                color: ''
                            }
                        },
                        style: MenuStyle.Standard,
                        selectable: false
                    },
                    reviews: {
                        newReviewButton: {
                            disabled: {
                                backgroundColor: '#e0e0e0',
                                fontColor: '#a1a1a1',
                            }
                        }
                    },
                    frontPage: {
                        fontSize: '20px',
                        hoursDropdown: {
                            icon: {
                                size: '1.2rem'
                            }
                        }
                    }
                },
                common: {
                    borderRadius: ''
                },
                orderHereAnimation: {
                    color: Color.White,
                    screen: {
                        full: {
                            width: '',
                            bottom: '',
                            right: ''
                        }
                    }
                }
            }
        },
        [RestaurantSlug.Sham]: {
            cssClass: 'sham',
            pageVersion: RestaurantStyle.Classic,
            color: {
                primary: '#000000',
                secondary: '#000000'
            },
            styles: {
                page: {
                    menu: {
                        button: {
                            color: '',
                            disabledColor: '',
                            iconColor: '#000000'
                        },
                        item: {
                            description: {
                                fontWeight: '400',
                                color: ''
                            }
                        },
                        style: MenuStyle.Standard,
                        selectable: false
                    },
                    reviews: {
                        newReviewButton: {
                            disabled: {
                                backgroundColor: '#e0e0e0',
                                fontColor: '#a1a1a1',
                            }
                        }
                    },
                    frontPage: {
                        fontSize: '20px',
                        hoursDropdown: {
                            icon: {
                                size: '1.2rem'
                            }
                        }
                    }
                },
                common: {
                    borderRadius: ''
                },
                orderHereAnimation: {
                    color: Color.Black,
                    screen: {
                        full: {
                            width: '',
                            bottom: '',
                            right: ''
                        }
                    }
                }
            }
        },
        [RestaurantSlug.Nakamichi]: {
            cssClass: 'nakamichi',
            pageVersion: RestaurantStyle.Classic,
            color: {
                primary: '#000000',
                secondary: '#000000'
            },
            styles: {
                page: {
                    menu: {
                        button: {
                            color: '#ffffff',
                            disabledColor: '',
                            iconColor: '#000000'
                        },
                        item: {
                            description: {
                                fontWeight: '400',
                                color: ''
                            }
                        },
                        style: MenuStyle.Standard,
                        selectable: false
                    },
                    reviews: {
                        newReviewButton: {
                            disabled: {
                                backgroundColor: '#e0e0e0',
                                fontColor: '#a1a1a1',
                            }
                        }
                    },
                    frontPage: {
                        fontSize: '20px',
                        hoursDropdown: {
                            icon: {
                                size: '1.2rem'
                            }
                        }
                    }
                },
                common: {
                    borderRadius: ''
                },
                orderHereAnimation: {
                    color: Color.Black,
                    screen: {
                        full: {
                            width: '',
                            bottom: '',
                            right: ''
                        }
                    }
                }
            }
        },
        [RestaurantSlug.Croqueteria]: {
            cssClass: 'croqueteria',
            pageVersion: RestaurantStyle.Classic,
            color: {
                primary: '#000000',
                secondary: '#000000'
            },
            styles: {
                page: {
                    menu: {
                        button: {
                            color: '#C23D2A',
                            disabledColor: '#9c9c9c',
                            iconColor: '#fefbf0'
                        },
                        item: {
                            // width: 'calc((100% / 3) - (2 * 0.5rem))',
                            imageContainer: {
                                height: '18rem'
                            },
                            marginBottom: '1.5rem',
                            image: {
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover',
                                marginBottom: '0.5rem',
                                order: 2
                            },
                            name: {
                                font: 'trumpgothicpro, sans-serif',
                                fontSize: '1.8rem',
                                fontWeight: '400',
                                textTransform: 'uppercase',
                                letterSpacing: '0.1rem',
                                marginBottom: '0.25rem',
                                marginTop: '0rem',
                                order: 1
                            },
                            description: {
                                font: 'ff-meta-serif-web-pro, serif',
                                fontSize: '0.9rem',
                                fontWeight: '300',
                                color: '',
                                marginBottom: '0.5rem',
                                order: 3
                            },
                            price: {
                                font: 'ff-meta-serif-web-pro, serif',
                                fontSize: '1rem',
                                fontWeight: '600',
                            },
                            style: MenuItemStyle.Image
                        },
                        style: MenuStyle.SingleColumn,
                        selectable: false
                    },
                    reviews: {
                        newReviewButton: {
                            disabled: {
                                backgroundColor: '#e0e0e0',
                                fontColor: '#a1a1a1',
                            }
                        }
                    },
                    frontPage: {
                        fontSize: '20px',
                        hoursDropdown: {
                            icon: {
                                size: '1.2rem'
                            }
                        }
                    }
                },
                common: {
                    borderRadius: ''
                },
                map: {
                    google: [
                        {
                            elementType: 'geometry',
                            stylers: [{ color: '#f7f7f7' }]

                        },
                        {
                            featureType: 'road',
                            elementType: 'geometry',
                            stylers: [{ color: '#FBC8C1' }]
                        },
                        {
                            featureType: 'poi.business',
                            stylers: [{ visibility: 'off' }]
                        },
                        {
                            featureType: 'water',
                            elementType: 'geometry',
                            stylers: [{ color: '#d7e3da' }]

                        }
                    ]
                },
                orderHereAnimation: {
                    color: Color.White,
                    screen: {
                        full: {
                            width: '10rem',
                            bottom: '1.5rem',
                            right: '-0.8rem'
                        }
                    }
                }
            }
        },
        [RestaurantSlug.PtitPlateau]: {
            cssClass: 'ptitPlateau',
            pageVersion: RestaurantStyle.Classic,
            color: {
                primary: '#ffffff',
                secondary: '#586352'
            },
            styles: {
                page: {
                    menu: {
                        button: {
                            color: '#ffffff',
                            disabledColor: '',
                            iconColor: '#000000'
                        },
                        item: {
                            description: {
                                fontWeight: '400',
                                color: ''
                            }
                        },
                        style: MenuStyle.Standard,
                        selectable: false
                    },
                    reviews: {
                        icon: {
                            color: '#ffffff'
                        },
                        newReviewButton: {
                            disabled: {
                                backgroundColor: '#515151',
                                fontColor: '#ffffff',
                            }
                        }
                    },
                    frontPage: {
                        fontSize: '20px',
                        hoursDropdown: {
                            icon: {
                                size: '1.2rem'
                            }
                        }
                    }
                },
                common: {
                    borderRadius: ''
                },
                orderHereAnimation: {
                    color: Color.White,
                    screen: {
                        full: {
                            width: '',
                            bottom: '',
                            right: ''
                        }
                    }
                }
            }
        },
        // [RestaurantSlug.Marquis]: {
        //     cssClass: 'marquis',
        //     pageVersion: RestaurantStyle.Classic,
        //     color: {
        //         primary: '#000000',
        //         secondary: '#000000'
        //     },
        //     styles: {
        //         page: {
        //             menu: {
        //                 button: {
        //                     color: '',
        //                     disabledColor: '',
        //                     iconColor: ''
        //                 },
        //                 item: {
        //                     description: {
        //                         fontWeight: '400',
        //                         color: ''
        //                     }
        //                 },
        //                 style: MenuStyle.Standard,
        //                 selectable: false
        //             },
        //             frontPage: {
        //                 fontSize: '20px',
        //                 hoursDropdown: {
        //                     icon: {
        //                         size: '1.2rem'
        //                     }
        //                 }
        //             }
        //         },
        //         common: {
        //             borderRadius: ''
        //         }
        //     }
        // },
        // [RestaurantSlug.SushiPlusBeaumount]: {
        //     cssClass: 'sushiPlusBeaumont',
        //     pageVersion: RestaurantStyle.Classic,
        //     color: {
        //         primary: '#000000',
        //         secondary: '#000000'
        //     },
        //     styles: {
        //         page: {
        //             menu: {
        //                 button: {
        //                     color: '',
        //                     disabledColor: '',
        //                     iconColor: ''
        //                 },
        //                 item: {
        //                     description: {
        //                         fontWeight: '400',
        //                         color: ''
        //                     }
        //                 },
        //                 style: MenuStyle.Standard,
        //                 selectable: false
        //             },
        //             frontPage: {
        //                 fontSize: '20px',
        //                 hoursDropdown: {
        //                     icon: {
        //                         size: '1.2rem'
        //                     }
        //                 }
        //             }
        //         },
        //         common: {
        //             borderRadius: ''
        //         }
        //     }
        // },
        [RestaurantSlug.KojiSoupe]: {
            cssClass: 'kojiSoupe',
            pageVersion: RestaurantStyle.Classic,
            color: {
                primary: '#183a52',
                secondary: '#000000'
            },
            styles: {
                page: {
                    menu: {
                        button: {
                            color: '#183a52',
                            disabledColor: '#cecece',
                            iconColor: '#d7eeeb'
                        },
                        item: {
                            description: {
                                fontWeight: '400',
                                color: ''
                            }
                        },
                        style: MenuStyle.Standard,
                        selectable: false
                    },
                    reviews: {
                        newReviewButton: {
                            disabled: {
                                backgroundColor: '#e0e0e0',
                                fontColor: '#a1a1a1',
                            }
                        }
                    },
                    frontPage: {
                        fontSize: '20px',
                        hoursDropdown: {
                            icon: {
                                size: '1.2rem'
                            }
                        }
                    }
                },
                common: {
                    borderRadius: ''
                },
                orderHereAnimation: {
                    color: Color.Black,
                    screen: {
                        full: {
                            width: '',
                            bottom: '',
                            right: ''
                        }
                    }
                }
            }
        },
        [RestaurantSlug.LundisAuSoleil]: {
            cssClass: 'lundisAuSoleil',
            pageVersion: RestaurantStyle.Classic,
            color: {
                primary: '#11285C',
                secondary: '#000000'
            },
            styles: {
                page: {
                    menu: {
                        button: {
                            color: '#ffffff',
                            disabledColor: '',
                            iconColor: '#000000'
                        },
                        item: {
                            description: {
                                fontWeight: '400',
                                color: ''
                            }
                        },
                        style: MenuStyle.Standard,
                        selectable: false
                    },
                    reviews: {
                        newReviewButton: {
                            backgroundColor: '#f26f41',
                            disabled: {
                                backgroundColor: '#e0e0e0',
                                fontColor: '#a1a1a1',
                            }
                        }
                    },
                    frontPage: {
                        fontSize: '20px',
                        hoursDropdown: {
                            icon: {
                                size: '1.2rem'
                            }
                        }
                    }
                },
                common: {
                    borderRadius: ''
                },
                map: {
                    google: [
                        {
                            elementType: 'geometry',
                            stylers: [{ color: '#e4f2f5' }]
                        },
                        {
                            featureType: 'road',
                            elementType: 'geometry',
                            stylers: [{ color: '#fdcebd' }]
                        },
                        {
                            featureType: 'poi.business',
                            stylers: [{ visibility: 'off' }]
                        },
                        {
                            featureType: 'water',
                            elementType: 'geometry',
                            stylers: [{ color: '#A8D5DE' }]
                        }
                    ]
                },
                orderHereAnimation: {
                    color: Color.White,
                    screen: {
                        full: {
                            width: '',
                            bottom: '',
                            right: ''
                        }
                    }
                }
            }
        },
        [RestaurantSlug.Jc]: {
            cssClass: 'jc',
            pageVersion: RestaurantStyle.PictureBook,
            color: {
                primary: '#d50032',
                secondary: '#ffffff'
            },
            text: {
                about: {
                    tagline: {
                        en: 'Juliette & Chocolat is the perfect place to discover chocolate in all its forms.',
                        fr: 'Juliette & Chocolat, c’est l’endroit parfait pour (re)tomber dans le chocolat et découvrir cet ingrédient magique dans tous ses états!'
                    },
                    description: {
                        en: 'Our best desserts come to you carefully packaged and meticulously transported to your home with intact flavor. On the menu: decadent brownies, monster cookies, fondants and half-cooked chocolate, but also spreads, fondues, chocolate drinks… Passionate about dark chocolate, milk chocolate, praline, or caramel? You will find your happiness on our shop! <br><br> In love with homemade cooking ? Shop our brownie mix, chocolate drops, and more. We deliver everything you need to cook with chocolate. Is one of your loved ones celebrating their birthday soon? Give him a chocolate present or a gift card so that he can satisfy his sweet tooth.',
                        fr: 'On vous envoie à la maison nos meilleurs desserts, soigneusement emballés et méticuleusement transportés chez vous avec une saveur intacte. Au programme: brownies décadents, cookies monstres,fondants et mi-cuits au chocolat, mais aussi tartinades, fondues,chocolats à boire… Passionné.e de chocolat noir, de chocolat au lait, de praliné, ou de caramel? Vous trouverez votre bonheur sur notre boutique! <br><br> Chocolatiers en herbe ? Magasinez nos préparations pour brownies, pistoles de chocolat, et plus encore. Nous vous livrons tout ce qu’il faut pour cuisiner avec du chocolat. L’un de vos proches fête bientôt son anniversaire ? Offrez-lui un cadeau chocolaté ou une carte-cadeau pour qu’il puisse satisfaire sa gourmandise.'
                    }
                },
            },
            styles: {
                page: {
                    menu: {
                        feature: {
                            primary: {
                                image: "https://dummyimage.com/1000x600/dddddd/ffffff",
                            },
                            secondary: {
                                image: "https://dummyimage.com/1753x523/dddddd/ffffff",
                                text: "BEST SELLERS"
                            }
                        },
                        button: {
                            color: '#d50032',
                            disabledColor: '#c5c5c5',
                            iconColor: '#ffffff'
                        },
                        desktop: {
                            navbar: {
                                backgroundColor: '#EDE4D3'
                            }
                        },
                        mobile: {
                            navbar: {
                                backgroundColor: '#EDE4D3'
                            }
                        },
                        section: {
                            title: {
                                letterSpacing: '2.72px',
                                textTransform: 'uppercase'
                            }
                        },
                        item: {
                            description: {
                                fontWeight: '300',
                                color: '#333'
                            }
                        }
                    },
                    frontPage: {
                        fontSize: '20px',
                        hoursDropdown: {
                            icon: {
                                size: '22px'
                            }
                        }
                    }
                },
                common: {
                    font: 'oswald',
                    borderRadius: '2px'
                },
                map: {
                    radius: {
                        color: '#d50032'
                    }
                }
            },
            defaultImage: 'https://dummyimage.com/600x400/dddddd/dddddd'
        },
        [RestaurantSlug.TincSet]: {
            cssClass: 'tinc-set',
            pageVersion: RestaurantStyle.Classic,
            color: {
                primary: '#054c3f',
                secondary: '#ffffff'
            },
            styles: {
                page: {
                    menu: {
                        button: {
                            color: '#054c3f',
                            disabledColor: '#969696',
                            iconColor: '#ffffff',
                            border: 'none'
                        },
                        tab: {
                            font: 'program, sans-serif',
                            padding: '1px 0px',
                            active: {
                                backgroundColor: 'transparent',
                                color: '#054c3f',
                                borderBottom: '1px solid #054c3f',
                                border: 'none',
                                fontWeight: '500'
                            },
                            inactive: {
                                font: 'program, sans-serif',
                                backgroundColor: 'transparent',
                                color: '#054c3f',
                                borderBottom: 'none',
                                border: 'none',
                                fontWeight: '400'
                            }
                        },
                        item: {
                            description: {
                                fontWeight: '400',
                                color: ''
                            }
                        },
                        style: MenuStyle.Standard,
                        selectable: true
                    },
                    reviews: {
                        newReviewButton: {
                            backgroundColor: '#054c3f',
                            disabled: {
                                backgroundColor: '#e0e0e0',
                                fontColor: '#a1a1a1',
                            }
                        },
                        icon: {
                            color: '#000000'
                        }
                    },
                    frontPage: {
                        fontSize: '20px',
                        hoursDropdown: {
                            icon: {
                                size: '1.2rem'
                            }
                        }
                    }
                },
                map: {
                    radius: {
                        color: '#2a8e7b'
                    }
                },
                common: {
                    borderRadius: ''
                },
                orderHereAnimation: {
                    color: Color.Black,
                    screen: {
                        full: {
                            width: '',
                            bottom: '',
                            right: ''
                        }
                    }
                }
            }
        },
        [RestaurantSlug.FanfarePizza]: {
            cssClass: 'fanfare-pizza',
            pageVersion: RestaurantStyle.Classic,
            color: {
                primary: '#FFBB21',
                secondary: '#ffffff'
            },
            styles: {
                page: {
                    menu: {
                        button: {
                            color: '#FFBB21',
                            disabledColor: '#969696',
                            iconColor: '#292929'
                        },
                        item: {
                            description: {
                                fontWeight: '400',
                                color: ''
                            }
                        },
                        style: MenuStyle.SingleColumn,
                        selectable: false
                    },
                    reviews: {
                        newReviewButton: {
                            backgroundColor: '#FFBB21',
                            fontColor: '#292929',
                            disabled: {
                                backgroundColor: '#e0e0e0',
                                fontColor: '#a1a1a1',
                            }
                        },
                        icon: {
                            color: '#ffffff'
                        }
                    },
                    frontPage: {
                        fontSize: '20px',
                        hoursDropdown: {
                            icon: {
                                size: '1.2rem'
                            }
                        }
                    }
                },
                map: {
                    google: [
                        {
                            elementType: 'geometry',
                            stylers: [{ color: '#f7f7f7' }]
                        },
                        {
                            featureType: 'road',
                            elementType: 'geometry',
                            stylers: [{ color: '#FFDD8E' }]
                        },
                        {
                            featureType: 'poi.business',
                            stylers: [{ visibility: 'off' }]
                        },
                        {
                            featureType: 'water',
                            elementType: 'geometry',
                            stylers: [{ color: '#51a3a3' }]
                        }
                    ]
                },
                common: {
                    borderRadius: ''
                },
                orderHereAnimation: {
                    color: Color.White,
                    screen: {
                        full: {
                            width: '',
                            bottom: '',
                            right: ''
                        }
                    }
                }
            }
        },
        [RestaurantSlug.InochiExpress]: {
            cssClass: 'inochi-express',
            pageVersion: RestaurantStyle.Classic,
            color: {
                primary: '#FFFFFF',
                secondary: '#05aef1'
            },
            styles: {
                page: {
                    menu: {
                        button: {
                            color: '#05aef1',
                            disabledColor: '#969696',
                            iconColor: '#251f20'
                        },
                        item: {
                            description: {
                                fontWeight: '400',
                                color: ''
                            }
                        },
                        style: MenuStyle.Standard,
                        selectable: false
                    },
                    reviews: {
                        newReviewButton: {
                            backgroundColor: '#ffffff',
                            fontColor: '#251f20',
                            disabled: {
                                backgroundColor: '#e0e0e0',
                                fontColor: '#a1a1a1',
                            }
                        },
                        icon: {
                            color: '#ffffff'
                        }
                    },
                    frontPage: {
                        fontSize: '20px',
                        hoursDropdown: {
                            icon: {
                                size: '1.2rem'
                            }
                        }
                    }
                },
                map: {
                    google: [
                        {
                            elementType: 'geometry',
                            stylers: [{ color: '#f7f7f7' }]
                        },
                        {
                            featureType: 'road',
                            elementType: 'geometry',
                            stylers: [{ color: '#A5DAF5' }]
                        },
                        {
                            featureType: 'poi.business',
                            stylers: [{ visibility: 'off' }]
                        },
                        {
                            featureType: 'water',
                            elementType: 'geometry',
                            stylers: [{ color: '#01adf3' }]
                        }
                    ]
                },
                common: {
                    borderRadius: ''
                },
                orderHereAnimation: {
                    color: Color.White,
                    screen: {
                        full: {
                            width: '',
                            bottom: '',
                            right: ''
                        }
                    }
                }
            }
        },
        [RestaurantSlug.Uluwatu]: {
            cssClass: 'uluwatu',
            pageVersion: RestaurantStyle.Classic,
            color: {
                primary: '#FFCD71',
                secondary: '#586352'
            },
            styles: {
                page: {
                    menu: {
                        button: {
                            color: '#ffcd71',
                            disabledColor: '#8A8A8A',
                            iconColor: '#50350B'
                        },
                        item: {
                            name: {
                                font: 'PT Serif, serif',
                                fontSize: '1.1rem',
                                fontWeight: '400',
                                textTransform: 'inherit',
                                color: '#ffcd71'
                            },
                            description: {
                                font: 'Open Sans, sans-serif',
                                fontWeight: '400',
                                color: '#fff5e3'
                            },
                            price: {
                                font: 'PT Serif, serif',
                                fontSize: '1rem',
                                fontWeight: '400',
                                color: '#fff5e3'
                            },
                            style: MenuItemStyle.HorizontalWithOptionalImage
                        },
                        style: MenuStyle.Standard,
                        selectable: false
                    },
                    reviews: {
                        icon: {
                            color: 'white'
                        },
                        newReviewButton: {
                            backgroundColor: '#ffffff',
                            fontColor: '#292929',
                            disabled: {
                                backgroundColor: '#515151',
                                fontColor: '#c3c3c3',
                            }
                        },
                    },
                    frontPage: {
                        fontSize: '20px',
                        hoursDropdown: {
                            icon: {
                                size: '1.2rem'
                            }
                        }
                    }
                },
                map: {
                    google: [
                        {
                            elementType: 'geometry',
                            stylers: [{ color: '#f7f7f7' }]
                        },
                        {
                            featureType: 'road',
                            elementType: 'geometry',
                            stylers: [{ color: '#FFEDCB' }]
                        },
                        {
                            featureType: 'poi.business',
                            stylers: [{ visibility: 'off' }]
                        },
                        {
                            featureType: 'water',
                            elementType: 'geometry',
                            stylers: [{ color: '#D0EBFB' }]
                        }
                    ]
                },
                common: {
                    borderRadius: ''
                },
                orderHereAnimation: {
                    color: Color.White,
                    screen: {
                        full: {
                            width: '',
                            bottom: '',
                            right: ''
                        }
                    }
                }
            }
        },
        [RestaurantSlug.SaintHoublon]: {
            cssClass: 'saint-houblon',
            pageVersion: RestaurantStyle.Classic,
            color: {
                primary: '#FFCD71',
                secondary: '#586352'
            },
            styles: {
                page: {
                    menu: {
                        button: {
                            color: '#ffcd71',
                            disabledColor: '#8A8A8A',
                            iconColor: '#50350B'
                        },
                        item: {
                            description: {
                                fontWeight: '400',
                                color: ''
                            }
                        },
                        style: MenuStyle.Standard,
                        selectable: false
                    },
                    reviews: {
                        icon: {
                            color: '#000000'
                        },
                        newReviewButton: {
                            backgroundColor: '#000000',
                            fontColor: '#FFFFFF',
                            disabled: {
                                backgroundColor: '#515151',
                                fontColor: '#c3c3c3',
                            }
                        },
                    },
                    frontPage: {
                        fontSize: '20px',
                        hoursDropdown: {
                            icon: {
                                size: '1.2rem'
                            }
                        }
                    }
                },
                map: {
                    google: [
                        {
                            elementType: 'geometry',
                            stylers: [{ color: '#f7f7f7' }]
                        },
                        {
                            featureType: 'road',
                            elementType: 'geometry',
                            stylers: [{ color: '#ECDCE2' }]
                        },
                        {
                            featureType: 'poi.business',
                            stylers: [{ visibility: 'off' }]
                        },
                        {
                            featureType: 'water',
                            elementType: 'geometry',
                            stylers: [{ color: '#CED6E1' }]
                        }
                    ]
                },
                common: {
                    borderRadius: ''
                },
                orderHereAnimation: {
                    color: Color.Black,
                    screen: {
                        full: {
                            width: '',
                            bottom: '',
                            right: ''
                        }
                    }
                }
            }
        },
        [RestaurantSlug.Basha]: {
            cssClass: 'basha',
            pageVersion: RestaurantStyle.Classic,
            color: {
                primary: '#000000',
                secondary: '#f05133'
            },
            styles: {
                page: {
                    menu: {
                        button: {
                            color: '#f05133',
                            disabledColor: '#969696',
                            iconColor: '#ffffff'
                        },
                        item: {
                            name: {
                                font: 'Lato, sans-serif',
                                fontSize: '1.1rem',
                                fontWeight: '400',
                                textTransform: 'inherit',
                                color: '#212529'
                            },
                            description: {
                                font: 'Lato, sans-serif',
                                fontSize: '0.8rem',
                                fontWeight: '300',
                                color: '#212529'
                            },
                            price: {
                                font: 'Lato, sans-serif',
                                fontSize: '1rem',
                                fontWeight: '400',
                                color: '#212529'
                            },
                            style: MenuItemStyle.HorizontalWithOptionalImage
                        },
                        style: MenuStyle.Standard,
                        selectable: false
                    },
                    reviews: {
                        newReviewButton: {
                            backgroundColor: '#000000',
                            fontColor: '#ffffff',
                            disabled: {
                                backgroundColor: '#e0e0e0',
                                fontColor: '#a1a1a1',
                            }
                        },
                        icon: {
                            color: '#000000'
                        }
                    },
                    frontPage: {
                        fontSize: '20px',
                        hoursDropdown: {
                            icon: {
                                size: '1.2rem'
                            }
                        }
                    }
                },
                map: {
                    google: [
                        {
                            elementType: 'geometry',
                            stylers: [{ color: '#f7f7f7' }]
                        },
                        {
                            featureType: 'road',
                            elementType: 'geometry',
                            stylers: [{ color: '#d8d8d8' }]
                        },
                        {
                            featureType: 'poi.business',
                            stylers: [{ visibility: 'off' }]
                        },
                        {
                            featureType: 'water',
                            elementType: 'geometry',
                            stylers: [{ color: '#bfdaff' }]
                        }
                    ]
                },
                common: {
                    borderRadius: '',
                    body: {
                        fontWeight: '300'
                    }
                },
                orderHereAnimation: {
                    color: Color.Black,
                    screen: {
                        full: {
                            width: '',
                            bottom: '',
                            right: ''
                        }
                    }
                }
            }
        },
        [RestaurantSlug.ChezTauBay]: {
            cssClass: 'chez-tau-bay',
            pageVersion: RestaurantStyle.Classic,
            color: {
                primary: '#000000',
                secondary: '#000000'
            },
            styles: {
                page: {
                    menu: {
                        button: {
                            color: '#FCAF17',
                            disabledColor: '#D8D0C2',
                            iconColor: '#000000'
                        },
                        item: {
                            description: {
                                fontWeight: '400',
                                color: ''
                            }
                        },
                        style: MenuStyle.Standard,
                        selectable: false
                    },
                    reviews: {
                        newReviewButton: {
                            disabled: {
                                backgroundColor: '#e0e0e0',
                                fontColor: '#a1a1a1',
                            }
                        }
                    },
                    frontPage: {
                        fontSize: '20px',
                        hoursDropdown: {
                            icon: {
                                size: '1.2rem'
                            }
                        }
                    }
                },
                map: {
                    google: [
                        {
                            elementType: 'geometry',
                            stylers: [{ color: '#F8F8F8' }]
                        },
                        {
                            featureType: 'road',
                            elementType: 'geometry',
                            stylers: [{ color: '#DCD6CB' }]
                        },
                        {
                            featureType: 'poi.business',
                            stylers: [{ visibility: 'off' }]
                        },
                        {
                            featureType: 'water',
                            elementType: 'geometry',
                            stylers: [{ color: '#E1EEF2' }]
                        }
                    ]
                },
                common: {
                    borderRadius: ''
                },
                orderHereAnimation: {
                    color: Color.Black,
                    screen: {
                        full: {
                            width: '',
                            bottom: '',
                            right: ''
                        }
                    }
                }
            }
        },
        [RestaurantSlug.Boustan]: {
            cssClass: 'boustan',
            pageVersion: RestaurantStyle.Classic,
            color: {
                primary: '#FFFFFF',
                secondary: '#FFFFFF'
            },
            styles: {
                page: {
                    menu: {
                        button: {
                            color: '#7F0000',
                            disabledColor: '#CDD2C4',
                            iconColor: '#FFFFFF'
                        },
                        item: {
                            name: {
                                font: 'Open Sans, sans-serif',
                                fontSize: '1.1rem',
                                fontWeight: '400',
                                textTransform: 'inherit',
                                color: 'white'
                            },
                            description: {
                                font: 'Open Sans, sans-serif',
                                fontWeight: '400',
                                color: 'white'
                            },
                            price: {
                                font: 'Open Sans, sans-serif',
                                fontSize: '1rem',
                                fontWeight: '400',
                                color: 'white'
                            },
                            style: MenuItemStyle.HorizontalWithOptionalImage
                        },
                        style: MenuStyle.Standard,
                        selectable: false
                    },
                    reviews: {
                        icon: {
                            color: '#FFFFFF'
                        },
                        newReviewButton: {
                            backgroundColor: '#ffffff',
                            fontColor: '#292929',
                            disabled: {
                                backgroundColor: '#515151',
                                fontColor: '#c3c3c3',
                            }
                        },
                    },
                    frontPage: {
                        fontSize: '20px',
                        hoursDropdown: {
                            icon: {
                                size: '1.2rem'
                            }
                        }
                    }
                },
                map: {
                    google: [
                        {
                            elementType: 'geometry',
                            stylers: [{ color: '#f7f7f7' }]
                        },
                        {
                            featureType: 'road',
                            elementType: 'geometry',
                            stylers: [{ color: '#F3CACA' }]
                        },
                        {
                            featureType: 'poi.business',
                            stylers: [{ visibility: 'off' }]
                        },
                        {
                            featureType: 'water',
                            elementType: 'geometry',
                            stylers: [{ color: '#D7ECF0' }]
                        }
                    ]
                },
                common: {
                    borderRadius: ''
                },
                orderHereAnimation: {
                    color: Color.White,
                    screen: {
                        full: {
                            width: '',
                            bottom: '',
                            right: ''
                        }
                    }
                }
            }
        },
        [RestaurantSlug.ThaiExpressCdn]: {
            cssClass: 'thai-express-cdn',
            pageVersion: RestaurantStyle.Classic,
            color: {
                primary: '#507843',
                secondary: '#F390BC'
            },
            styles: {
                page: {
                    menu: {
                        button: {
                            color: '#507843',
                            disabledColor: '#969696',
                            iconColor: '#ffffff'
                        },
                        item: {
                            name: {
                                font: 'Open Sans, sans-serif',
                                fontSize: '1.1rem',
                                fontWeight: '400',
                                textTransform: 'inherit',
                                color: 'black'
                            },
                            description: {
                                font: 'Open Sans, sans-serif',
                                fontSize: '0.8rem',
                                fontWeight: '300',
                                color: 'black'
                            },
                            price: {
                                font: 'Open Sans, sans-serif',
                                fontSize: '1rem',
                                fontWeight: '400',
                                color: 'black'
                            },
                            style: MenuItemStyle.HorizontalWithOptionalImage
                        },
                        style: MenuStyle.Standard,
                        selectable: false
                    },
                    reviews: {
                        newReviewButton: {
                            backgroundColor: '#000000',
                            fontColor: '#ffffff',
                            disabled: {
                                backgroundColor: '#e0e0e0',
                                fontColor: '#a1a1a1',
                            }
                        },
                        icon: {
                            color: '#000000'
                        }
                    },
                    frontPage: {
                        fontSize: '20px',
                        hoursDropdown: {
                            icon: {
                                size: '1.2rem'
                            }
                        }
                    }
                },
                map: {
                    google: [
                        {
                            elementType: 'geometry',
                            stylers: [{ color: '#f7f7f7' }]
                        },
                        {
                            featureType: 'road',
                            elementType: 'geometry',
                            stylers: [{ color: '#EFF6E2' }]
                        },
                        {
                            featureType: 'poi.business',
                            stylers: [{ visibility: 'off' }]
                        },
                        {
                            featureType: 'water',
                            elementType: 'geometry',
                            stylers: [{ color: '#bfdaff' }]
                        }
                    ]
                },
                common: {
                    borderRadius: '',
                    body: {
                        fontWeight: '300'
                    }
                },
                orderHereAnimation: {
                    color: Color.Black,
                    screen: {
                        full: {
                            width: '',
                            bottom: '',
                            right: ''
                        }
                    }
                }
            }
        },
        [RestaurantSlug.JavaUCdn]: {
            cssClass: 'java-u-cdn',
            pageVersion: RestaurantStyle.Classic,
            color: {
                primary: '#FFFFFF',
                secondary: '#FFFFFF'
            },
            styles: {
                page: {
                    menu: {
                        button: {
                            color: '#FFFFFF',
                            disabledColor: '#8c8c8c',
                            iconColor: '#000000'
                        },
                        item: {
                            name: {
                                font: 'Lato, sans-serif',
                                fontSize: '1.1rem',
                                fontWeight: '400',
                                textTransform: 'inherit',
                            },
                            description: {
                                font: 'Lato, sans-serif',
                                fontSize: '0.8rem',
                                fontWeight: '300',
                                color: ''
                            },
                            price: {
                                font: 'Lato, sans-serif',
                                fontSize: '1rem',
                                fontWeight: '400',
                            },
                            style: MenuItemStyle.NoImage
                        },
                        style: MenuStyle.Standard,
                        selectable: false
                    },
                    reviews: {
                        newReviewButton: {
                            backgroundColor: '#FFFFFF',
                            fontColor: '#000000',
                            disabled: {
                                backgroundColor: '#e0e0e0',
                                fontColor: '#a1a1a1',
                            }
                        },
                        icon: {
                            color: '#FFFFFF'
                        }
                    },
                    frontPage: {
                        fontSize: '20px',
                        hoursDropdown: {
                            icon: {
                                size: '1.2rem'
                            }
                        }
                    }
                },
                map: {
                    google: [
                        {
                            elementType: 'geometry',
                            stylers: [{ color: '#f7f7f7' }]
                        },
                        {
                            featureType: 'road',
                            elementType: 'geometry',
                            stylers: [{ color: '#e2e2e2' }]
                        },
                        {
                            featureType: 'poi.business',
                            stylers: [{ visibility: 'off' }]
                        },
                        {
                            featureType: 'water',
                            elementType: 'geometry',
                            stylers: [{ color: '#bfdaff' }]
                        }
                    ]
                },
                common: {
                    borderRadius: '',
                    body: {
                        fontWeight: '300'
                    }
                },
                orderHereAnimation: {
                    color: Color.White,
                    screen: {
                        full: {
                            width: '',
                            bottom: '',
                            right: ''
                        }
                    }
                }
            }
        },
        [RestaurantSlug.McgillPizza]: {
            cssClass: 'mcgill-pizza',
            pageVersion: RestaurantStyle.Classic,
            color: {
                primary: '#941A17',
                secondary: '#FFFFFF'
            },
            styles: {
                page: {
                    menu: {
                        button: {
                            color: '#941A17',
                            disabledColor: '#9D6B66',
                            iconColor: '#FFFFFF'
                        },
                        item: {
                            name: {
                                font: 'Lato, sans-serif',
                                fontSize: '1.1rem',
                                fontWeight: '400',
                                textTransform: 'inherit',
                            },
                            description: {
                                font: 'Lato, sans-serif',
                                fontSize: '0.8rem',
                                fontWeight: '300',
                                color: ''
                            },
                            price: {
                                font: 'Lato, sans-serif',
                                fontSize: '1rem',
                                fontWeight: '400',
                            },
                            style: MenuItemStyle.NoImage
                        },
                        style: MenuStyle.Standard,
                        selectable: false
                    },
                    reviews: {
                        newReviewButton: {
                            backgroundColor: '#000000',
                            fontColor: '#FFFFFF',
                            disabled: {
                                backgroundColor: '#e0e0e0',
                                fontColor: '#a1a1a1',
                            }
                        },
                        icon: {
                            color: '#000000'
                        }
                    },
                    frontPage: {
                        fontSize: '20px',
                        hoursDropdown: {
                            icon: {
                                size: '1.2rem'
                            }
                        }
                    }
                },
                map: {
                    google: [
                        {
                            elementType: 'geometry',
                            stylers: [{ color: '#f7f7f7' }]
                        },
                        {
                            featureType: 'road',
                            elementType: 'geometry',
                            stylers: [{ color: '#E0CFCE' }]
                        },
                        {
                            featureType: 'poi.business',
                            stylers: [{ visibility: 'off' }]
                        },
                        {
                            featureType: 'water',
                            elementType: 'geometry',
                            stylers: [{ color: '#bfdaff' }]
                        }
                    ]
                },
                common: {
                    borderRadius: '',
                    body: {
                        fontWeight: '400'
                    }
                },
                orderHereAnimation: {
                    color: Color.White,
                    screen: {
                        full: {
                            width: '',
                            bottom: '',
                            right: ''
                        }
                    }
                }
            }
        },
        [RestaurantSlug.Yuan]: {
            cssClass: 'yuan',
            pageVersion: RestaurantStyle.Classic,
            color: {
                primary: '#3D2519',
                secondary: '#A2BA64'
            },
            styles: {
                page: {
                    menu: {
                        button: {
                            color: '#3D2519',
                            disabledColor: '#D4CBC6',
                            iconColor: '#FFFFFF'
                        },
                        item: {
                            name: {
                                font: 'Karla, sans-serif',
                                fontSize: '1.1rem',
                                fontWeight: '400',
                                textTransform: 'inherit'
                            },
                            description: {
                                font: 'Karla, sans-serif',
                                fontWeight: '400',
                                color: ''
                            },
                            price: {
                                font: 'Karla, sans-serif',
                                fontSize: '1rem',
                                fontWeight: '400',
                            },
                            style: MenuItemStyle.NoImage
                        },
                        style: MenuStyle.Standard,
                        selectable: false
                    },
                    reviews: {
                        icon: {
                            color: '#000000'
                        },
                        newReviewButton: {
                            backgroundColor: '#000000',
                            fontColor: '#FFFFFF',
                            disabled: {
                                backgroundColor: '#515151',
                                fontColor: '#c3c3c3',
                            }
                        },
                    },
                    frontPage: {
                        fontSize: '20px',
                        hoursDropdown: {
                            icon: {
                                size: '1.2rem'
                            }
                        }
                    }
                },
                map: {
                    google: [
                        {
                            elementType: 'geometry',
                            stylers: [{ color: '#f7f7f7' }]
                        },
                        {
                            featureType: 'road',
                            elementType: 'geometry',
                            stylers: [{ color: '#DDE6C7' }]
                        },
                        {
                            featureType: 'poi.business',
                            stylers: [{ visibility: 'off' }]
                        },
                        {
                            featureType: 'water',
                            elementType: 'geometry',
                            stylers: [{ color: '#D7ECF0' }]
                        }
                    ]
                },
                common: {
                    borderRadius: ''
                },
                orderHereAnimation: {
                    color: Color.Black,
                    screen: {
                        full: {
                            width: '',
                            bottom: '',
                            right: ''
                        }
                    }
                }
            }
        },
        [RestaurantSlug.Segreta]: {
            cssClass: 'segreta',
            pageVersion: RestaurantStyle.Classic,
            color: {
                primary: '#1FB4B2',
                secondary: '#A2BA64'
            },
            styles: {
                page: {
                    menu: {
                        button: {
                            color: '#FFFFFF',
                            disabledColor: '#B1B1B1',
                            iconColor: '#000000'
                        },
                        item: {
                            name: {
                                font: 'Open Sans, sans-serif',
                                fontSize: '1.1rem',
                                fontWeight: '400',
                                textTransform: 'inherit'
                            },
                            description: {
                                font: 'Open Sans, sans-serif',
                                fontWeight: '400',
                                color: ''
                            },
                            price: {
                                font: 'Open Sans, sans-serif',
                                fontSize: '1rem',
                                fontWeight: '400',
                            },
                            style: MenuItemStyle.NoImage
                        },
                        style: MenuStyle.Standard,
                        selectable: false
                    },
                    reviews: {
                        icon: {
                            color: '#000000'
                        },
                        newReviewButton: {
                            backgroundColor: '#000000',
                            fontColor: '#FFFFFF',
                            disabled: {
                                backgroundColor: '#515151',
                                fontColor: '#c3c3c3',
                            }
                        },
                    },
                    frontPage: {
                        fontSize: '20px',
                        hoursDropdown: {
                            icon: {
                                size: '1.2rem'
                            }
                        }
                    }
                },
                map: {
                    google: [
                        {
                            elementType: 'geometry',
                            stylers: [{ color: '#f7f7f7' }]
                        },
                        {
                            featureType: 'road',
                            elementType: 'geometry',
                            stylers: [{ color: '#DEEFEF' }]
                        },
                        {
                            featureType: 'poi.business',
                            stylers: [{ visibility: 'off' }]
                        },
                        {
                            featureType: 'water',
                            elementType: 'geometry',
                            stylers: [{ color: '#D7ECF0' }]
                        }
                    ]
                },
                common: {
                    borderRadius: ''
                },
                orderHereAnimation: {
                    color: Color.White,
                    screen: {
                        full: {
                            width: '',
                            bottom: '',
                            right: ''
                        }
                    }
                }
            }
        },
        [RestaurantSlug.OMLT]: {
            cssClass: 'omlt',
            pageVersion: RestaurantStyle.Classic,
            color: {
                primary: '#1FB4B2',
                secondary: '#A2BA64'
            },
            styles: {
                page: {
                    menu: {
                        button: {
                            color: '#000000',
                            disabledColor: '#B1B1B1',
                            iconColor: '#f1f1ef'
                        },
                        item: {
                            name: {
                                font: 'activ-grotesk, sans-serif',
                                fontSize: '1.2rem',
                                fontWeight: '700',
                                textTransform: 'inherit'
                            },
                            description: {
                                font: 'activ-grotesk, sans-serif',
                                fontWeight: '400',
                                color: ''
                            },
                            price: {
                                font: 'activ-grotesk, sans-serif',
                                fontSize: '1rem',
                                fontWeight: '400',
                            },
                            style: MenuItemStyle.HorizontalWithOptionalImage
                        },
                        style: MenuStyle.SingleColumn,
                        selectable: false
                    },
                    reviews: {
                        icon: {
                            color: '#000000'
                        },
                        newReviewButton: {
                            backgroundColor: '#000000',
                            fontColor: '#FFFFFF',
                            disabled: {
                                backgroundColor: '#515151',
                                fontColor: '#c3c3c3',
                            }
                        },
                    },
                    frontPage: {
                        fontSize: '20px',
                        hoursDropdown: {
                            icon: {
                                size: '1.2rem'
                            }
                        }
                    }
                },
                map: {
                    google: [
                        {
                            elementType: 'geometry',
                            stylers: [{ color: '#f7f7f7' }]
                        },
                        {
                            featureType: 'road',
                            elementType: 'geometry',
                            stylers: [{ color: '#fbe4a5' }]
                        },
                        {
                            featureType: 'poi.business',
                            stylers: [{ visibility: 'off' }]
                        },
                        {
                            featureType: 'water',
                            elementType: 'geometry',
                            stylers: [{ color: '#D7ECF0' }]
                        }
                    ]
                },
                common: {
                    borderRadius: ''
                },
                orderHereAnimation: {
                    color: Color.White,
                    screen: {
                        full: {
                            width: '',
                            bottom: '',
                            right: ''
                        }
                    }
                }
            }
        },
        [RestaurantSlug.QuesadaCdn]: {
            cssClass: 'quesada-cdn',
            pageVersion: RestaurantStyle.Classic,
            color: {
                primary: '#C41425',
                secondary: '#444444'
            },
            styles: {
                page: {
                    menu: {
                        button: {
                            color: '#C41425',
                            disabledColor: '#444444',
                            iconColor: '#FFFFFF'
                        },
                        item: {
                            name: {
                                font: 'Proxima Nova, sans-serif',
                                fontSize: '1.1rem',
                                fontWeight: '500',
                                textTransform: 'inherit'
                            },
                            description: {
                                font: 'Proxima Nova, sans-serif',
                                fontWeight: '400',
                                color: ''
                            },
                            price: {
                                font: 'Proxima Nova, sans-serif',
                                fontSize: '1rem',
                                fontWeight: '400',
                            },
                            style: MenuItemStyle.HorizontalWithOptionalImage
                        },
                        style: MenuStyle.Standard,
                        selectable: false
                    },
                    reviews: {
                        icon: {
                            color: '#000000'
                        },
                        newReviewButton: {
                            backgroundColor: '#000000',
                            fontColor: '#FFFFFF',
                            disabled: {
                                backgroundColor: '#515151',
                                fontColor: '#c3c3c3',
                            }
                        },
                    },
                    frontPage: {
                        fontSize: '20px',
                        hoursDropdown: {
                            icon: {
                                size: '1.2rem'
                            }
                        }
                    }
                },
                map: {
                    google: [
                        {
                            elementType: 'geometry',
                            stylers: [{ color: '#f7f7f7' }]
                        },
                        {
                            featureType: 'road',
                            elementType: 'geometry',
                            stylers: [{ color: '#E3E3E3' }]
                        },
                        {
                            featureType: 'poi.business',
                            stylers: [{ visibility: 'off' }]
                        },
                        {
                            featureType: 'water',
                            elementType: 'geometry',
                            stylers: [{ color: '#D7ECF0' }]
                        }
                    ]
                },
                common: {
                    borderRadius: ''
                },
                orderHereAnimation: {
                    color: Color.White,
                    screen: {
                        full: {
                            width: '',
                            bottom: '',
                            right: ''
                        }
                    }
                }
            }
        },
        [RestaurantSlug.OiseauxDePassage]: {
            cssClass: 'oiseaux-de-passage',
            pageVersion: RestaurantStyle.Classic,
            color: {
                primary: '#DB5E5E',
                secondary: '#202124'
            },
            styles: {
                page: {
                    menu: {
                        button: {
                            color: '#DB5E5E',
                            disabledColor: '#C4C4C4',
                            iconColor: '#FFFFFF'
                        },
                        item: {
                            name: {
                                font: 'Open Sans Condensed, sans-serif',
                                fontSize: '1.1rem',
                                fontWeight: '400',
                                textTransform: 'uppercase'
                            },
                            description: {
                                font: 'Lora, sans-serif',
                                fontWeight: '400',
                                color: ''
                            },
                            price: {
                                font: 'Lora, sans-serif',
                                fontSize: '1rem',
                                fontWeight: '400',
                            },
                            style: MenuItemStyle.HorizontalWithOptionalImage
                        },
                        style: MenuStyle.Standard,
                        selectable: false
                    },
                    reviews: {
                        icon: {
                            color: '#000000'
                        },
                        newReviewButton: {
                            backgroundColor: '#000000',
                            fontColor: '#FFFFFF',
                            disabled: {
                                backgroundColor: '#515151',
                                fontColor: '#c3c3c3',
                            }
                        },
                    },
                    frontPage: {
                        fontSize: '20px',
                        hoursDropdown: {
                            icon: {
                                size: '1.2rem'
                            }
                        }
                    }
                },
                map: {
                    google: [
                        {
                            elementType: 'geometry',
                            stylers: [{ color: '#f7f7f7' }]
                        },
                        {
                            featureType: 'road',
                            elementType: 'geometry',
                            stylers: [{ color: '#E8CFCF' }]
                        },
                        {
                            featureType: 'poi.business',
                            stylers: [{ visibility: 'off' }]
                        },
                        {
                            featureType: 'water',
                            elementType: 'geometry',
                            stylers: [{ color: '#D7ECF0' }]
                        }
                    ]
                },
                common: {
                    borderRadius: ''
                },
                orderHereAnimation: {
                    color: Color.White,
                    screen: {
                        full: {
                            width: '',
                            bottom: '',
                            right: ''
                        }
                    }
                }
            }
        },
        [RestaurantSlug.CafeVita]: {
            cssClass: 'cafe-vita',
            pageVersion: RestaurantStyle.Classic,
            color: {
                primary: '#063775',
                secondary: '#000000'
            },
            styles: {
                page: {
                    menu: {
                        button: {
                            color: '#063775',
                            disabledColor: '#063775',
                            iconColor: '#FFFFFF'
                        },
                        item: {
                            name: {
                                font: 'Lato, sans-serif',
                                fontSize: '1.1rem',
                                fontWeight: '400',
                            },
                            description: {
                                font: 'Lato, sans-serif',
                                fontWeight: '400',
                                color: ''
                            },
                            price: {
                                font: 'Lato, sans-serif',
                                fontSize: '1rem',
                                fontWeight: '400',
                            },
                            style: MenuItemStyle.NoImage
                        },
                        style: MenuStyle.Standard,
                        selectable: false
                    },
                    reviews: {
                        icon: {
                            color: '#000000'
                        },
                        newReviewButton: {
                            backgroundColor: '#000000',
                            fontColor: '#FFFFFF',
                            disabled: {
                                backgroundColor: '#515151',
                                fontColor: '#c3c3c3',
                            }
                        },
                    },
                    frontPage: {
                        fontSize: '20px',
                        hoursDropdown: {
                            icon: {
                                size: '1.2rem'
                            }
                        }
                    }
                },
                map: {
                    google: [
                        {
                            elementType: 'geometry',
                            stylers: [{ color: '#f7f7f7' }]
                        },
                        {
                            featureType: 'road',
                            elementType: 'geometry',
                            stylers: [{ color: 'EFB6B7' }]
                        },
                        {
                            featureType: 'poi.business',
                            stylers: [{ visibility: 'off' }]
                        },
                        {
                            featureType: 'water',
                            elementType: 'geometry',
                            stylers: [{ color: '#D7ECF0' }]
                        }
                    ]
                },
                common: {
                    borderRadius: ''
                },
                orderHereAnimation: {
                    color: Color.Black,
                    screen: {
                        full: {
                            width: '',
                            bottom: '',
                            right: ''
                        }
                    }
                }
            }
        },
        [RestaurantSlug.BurgerBros]: {
            cssClass: 'burger-bros',
            pageVersion: RestaurantStyle.Classic,
            color: {
                primary: '#AEB434',
                secondary: '#FF7703'
            },
            styles: {
                page: {
                    menu: {
                        button: {
                            color: '#AEB434',
                            disabledColor: '#A6A78C',
                            iconColor: '#FFFFFF'
                        },
                        item: {
                            name: {
                                font: 'Josefin Sans, sans-serif',
                                fontSize: '1.1rem',
                                fontWeight: '400',
                            },
                            description: {
                                font: 'Open Sans, sans-serif',
                                fontWeight: '400',
                                color: ''
                            },
                            price: {
                                font: 'Open Sans, sans-serif',
                                fontSize: '1rem',
                                fontWeight: '400',
                            },
                            style: MenuItemStyle.HorizontalWithOptionalImage
                        },
                        style: MenuStyle.Standard,
                        selectable: false
                    },
                    reviews: {
                        icon: {
                            color: '#000000'
                        },
                        newReviewButton: {
                            backgroundColor: '#000000',
                            fontColor: '#FFFFFF',
                            disabled: {
                                backgroundColor: '#515151',
                                fontColor: '#c3c3c3',
                            }
                        },
                    },
                    frontPage: {
                        fontSize: '20px',
                        hoursDropdown: {
                            icon: {
                                size: '1.2rem'
                            }
                        }
                    }
                },
                map: {
                    google: [
                        {
                            elementType: 'geometry',
                            stylers: [{ color: '#f7f7f7' }]
                        },
                        {
                            featureType: 'road',
                            elementType: 'geometry',
                            stylers: [{ color: '#E5E5D5' }]
                        },
                        {
                            featureType: 'poi.business',
                            stylers: [{ visibility: 'off' }]
                        },
                        {
                            featureType: 'water',
                            elementType: 'geometry',
                            stylers: [{ color: '#D7ECF0' }]
                        }
                    ]
                },
                common: {
                    borderRadius: ''
                },
                orderHereAnimation: {
                    color: Color.White,
                    screen: {
                        full: {
                            width: '',
                            bottom: '',
                            right: ''
                        }
                    }
                }
            }
        },
        [RestaurantSlug.Julians]: {
            cssClass: 'julians',
            pageVersion: RestaurantStyle.Classic,
            color: {
                primary: '#EF8A5B',
                secondary: '#000000'
            },
            styles: {
                page: {
                    menu: {
                        button: {
                            color: '#EF8A5B',
                            disabledColor: '#C6BDB9',
                            iconColor: '#FFFFFF'
                        },
                        item: {
                            name: {
                                font: 'Roboto, sans-serif',
                                fontSize: '1.1rem',
                                fontWeight: '400',
                            },
                            description: {
                                font: 'Roboto, sans-serif',
                                fontWeight: '400',
                                color: ''
                            },
                            price: {
                                font: 'Roboto, sans-serif',
                                fontSize: '1rem',
                                fontWeight: '400',
                            },
                            style: MenuItemStyle.HorizontalWithOptionalImage
                        },
                        style: MenuStyle.Standard,
                        selectable: false
                    },
                    reviews: {
                        icon: {
                            color: '#000000'
                        },
                        newReviewButton: {
                            backgroundColor: '#000000',
                            fontColor: '#FFFFFF',
                            disabled: {
                                backgroundColor: '#515151',
                                fontColor: '#c3c3c3',
                            }
                        },
                    },
                    frontPage: {
                        fontSize: '20px',
                        hoursDropdown: {
                            icon: {
                                size: '1.2rem'
                            }
                        }
                    }
                },
                map: {
                    google: [
                        {
                            elementType: 'geometry',
                            stylers: [{ color: '#f7f7f7' }]
                        },
                        {
                            featureType: 'road',
                            elementType: 'geometry',
                            stylers: [{ color: '#FFD9C7' }]
                        },
                        {
                            featureType: 'poi.business',
                            stylers: [{ visibility: 'off' }]
                        },
                        {
                            featureType: 'water',
                            elementType: 'geometry',
                            stylers: [{ color: '#D7ECF0' }]
                        }
                    ]
                },
                common: {
                    borderRadius: ''
                },
                orderHereAnimation: {
                    color: Color.White,
                    screen: {
                        full: {
                            width: '',
                            bottom: '',
                            right: ''
                        }
                    }
                }
            }
        },
        [RestaurantSlug.Wallys]: {
            cssClass: 'wallys',
            pageVersion: RestaurantStyle.Classic,
            color: {
                primary: '#0081A9',
                secondary: '#2E2D2C'
            },
            styles: {
                page: {
                    menu: {
                        button: {
                            color: '#0081A9',
                            disabledColor: '#a2a2a2',
                            iconColor: '#FFFFFF'
                        },
                        item: {
                            imageContainer: {
                                height: '18rem'
                            },
                            marginBottom: '1.5rem',
                            image: {
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover',
                                marginBottom: '0',
                                order: 1
                            },
                            name: {
                                font: 'proxima-nova, sans-serif',
                                fontSize: '1.2rem',
                                fontWeight: '700',
                                marginBottom: '0rem',
                                marginTop: '0.5rem',
                                textTransform: 'uppercase',
                                order: 2
                            },
                            description: {
                                font: 'proxima-nova, sans-serif',
                                fontWeight: '400',
                                color: '',
                                marginBottom: '',
                                order: 3
                            },
                            price: {
                                font: 'proxima-nova, sans-serif',
                                fontSize: '1rem',
                                fontWeight: '400',
                            },
                            style: MenuItemStyle.Image
                        },
                        style: MenuStyle.SingleColumn,
                        selectable: false
                    },
                    reviews: {
                        icon: {
                            color: '#000000'
                        },
                        newReviewButton: {
                            backgroundColor: '#000000',
                            fontColor: '#FFFFFF',
                            disabled: {
                                backgroundColor: '#515151',
                                fontColor: '#c3c3c3',
                            }
                        },
                    },
                    frontPage: {
                        fontSize: '20px',
                        hoursDropdown: {
                            icon: {
                                size: '1.2rem'
                            }
                        }
                    }
                },
                map: {
                    google: [
                        {
                            elementType: 'geometry',
                            stylers: [{ color: '#f7f7f7' }]
                        },
                        {
                            featureType: 'road',
                            elementType: 'geometry',
                            stylers: [{ color: '#D1DCEB' }]
                        },
                        {
                            featureType: 'poi.business',
                            stylers: [{ visibility: 'off' }]
                        },
                        {
                            featureType: 'water',
                            elementType: 'geometry',
                            stylers: [{ color: '#D7ECF0' }]
                        }
                    ]
                },
                common: {
                    borderRadius: ''
                },
                orderHereAnimation: {
                    color: Color.White,
                    screen: {
                        full: {
                            width: '',
                            bottom: '',
                            right: ''
                        }
                    }
                }
            }
        },
        [RestaurantSlug.SpiceBros]: {
            cssClass: 'spicebros',
            pageVersion: RestaurantStyle.Classic,
            color: {
                primary: '#FFFFFF',
                secondary: '#FFFFFF'
            },
            styles: {
                page: {
                    menu: {
                        button: {
                            color: '#F46720',
                            disabledColor: '#888888',
                            iconColor: '#FFFFFF'
                        },
                        item: {
                            marginBottom: '1.5rem',
                            image: {
                                width: '100%',
                                maxHeight: '18rem',
                                objectFit: 'cover',
                                marginBottom: '0.5rem'
                            },
                            name: {
                                font: 'Roboto, sans-serif',
                                fontSize: '1.1rem',
                                fontWeight: '400',
                                marginBottom: '0.25rem',
                                textTransform: ''
                            },
                            description: {
                                font: 'Roboto, sans-serif',
                                fontWeight: '300',
                                color: ''
                            },
                            price: {
                                font: 'Roboto, sans-serif',
                                fontSize: '1rem',
                                fontWeight: '400',
                            },
                            style: MenuItemStyle.HorizontalWithOptionalImage
                        },
                        style: MenuStyle.Standard,
                        selectable: false
                    },
                    reviews: {
                        icon: {
                            color: '#FFFFFF'
                        },
                        newReviewButton: {
                            backgroundColor: '#FFFFFF',
                            fontColor: '#000000',
                            disabled: {
                                backgroundColor: '#515151',
                                fontColor: '#c3c3c3',
                            }
                        },
                    },
                    frontPage: {
                        fontSize: '20px',
                        hoursDropdown: {
                            icon: {
                                size: '1.2rem'
                            }
                        }
                    }
                },
                map: {
                    google: [
                        {
                            elementType: 'geometry',
                            stylers: [{ color: '#f7f7f7' }]
                        },
                        {
                            featureType: 'road',
                            elementType: 'geometry',
                            stylers: [{ color: '#FBDBCB' }]
                        },
                        {
                            featureType: 'poi.business',
                            stylers: [{ visibility: 'off' }]
                        },
                        {
                            featureType: 'water',
                            elementType: 'geometry',
                            stylers: [{ color: '#D7ECF0' }]
                        }
                    ]
                },
                common: {
                    borderRadius: ''
                },
                orderHereAnimation: {
                    color: Color.White,
                    screen: {
                        full: {
                            width: '',
                            bottom: '',
                            right: ''
                        }
                    }
                }
            }
        },
        [RestaurantSlug.SantaLucia]: {
            cssClass: 'santa-lucia',
            pageVersion: RestaurantStyle.Classic,
            color: {
                primary: '#C13617',
                secondary: '#2E221F'
            },
            styles: {
                page: {
                    menu: {
                        button: {
                            color: '#C13617',
                            disabledColor: '#888888',
                            iconColor: '#FFFFFF'
                        },
                        item: {
                            marginBottom: '1.5rem',
                            image: {
                                width: '100%',
                                maxHeight: '18rem',
                                objectFit: 'cover',
                                marginBottom: '0.5rem'
                            },
                            name: {
                                font: 'Merriweather Sans, sans-serif',
                                fontSize: '1.1rem',
                                fontWeight: '400',
                                marginBottom: '0.25rem',
                                textTransform: ''
                            },
                            description: {
                                font: 'Merriweather Sans, sans-serif',
                                fontWeight: '300',
                                color: ''
                            },
                            price: {
                                font: 'Merriweather Sans, sans-serif',
                                fontSize: '1rem',
                                fontWeight: '400',
                            },
                            style: MenuItemStyle.NoImage
                        },
                        style: MenuStyle.Standard,
                        selectable: false
                    },
                    reviews: {
                        icon: {
                            color: '#000000'
                        },
                        newReviewButton: {
                            backgroundColor: '#000000',
                            fontColor: '#FFFFFF',
                            disabled: {
                                backgroundColor: '#515151',
                                fontColor: '#c3c3c3',
                            }
                        },
                    },
                    frontPage: {
                        fontSize: '20px',
                        hoursDropdown: {
                            icon: {
                                size: '1.2rem'
                            }
                        }
                    }
                },
                map: {
                    google: [
                        {
                            elementType: 'geometry',
                            stylers: [{ color: '#f7f7f7' }]
                        },
                        {
                            featureType: 'road',
                            elementType: 'geometry',
                            stylers: [{ color: '#F1BDB1' }]
                        },
                        {
                            featureType: 'poi.business',
                            stylers: [{ visibility: 'off' }]
                        },
                        {
                            featureType: 'water',
                            elementType: 'geometry',
                            stylers: [{ color: '#D7ECF0' }]
                        }
                    ]
                },
                common: {
                    borderRadius: ''
                },
                orderHereAnimation: {
                    color: Color.White,
                    screen: {
                        full: {
                            width: '',
                            bottom: '',
                            right: ''
                        }
                    }
                }
            }
        },
        [RestaurantSlug.Dakar]: {
            cssClass: 'dakar',
            pageVersion: RestaurantStyle.Classic,
            color: {
                primary: '#DFB57A',
                secondary: '#2A2826'
            },
            styles: {
                page: {
                    menu: {
                        button: {
                            color: '#DFB57A',
                            disabledColor: '#C6BDB9',
                            iconColor: '#FFFFFF'
                        },
                        item: {
                            name: {
                                font: 'Josefin Sans, sans-serif',
                                fontSize: '1.1rem',
                                fontWeight: '400',
                            },
                            description: {
                                font: 'Josefin Sans, sans-serif',
                                fontWeight: '400',
                                color: ''
                            },
                            price: {
                                font: 'Josefin Sans, sans-serif',
                                fontSize: '1rem',
                                fontWeight: '400',
                            },
                            style: MenuItemStyle.HorizontalWithOptionalImage
                        },
                        style: MenuStyle.Standard,
                        selectable: false
                    },
                    reviews: {
                        icon: {
                            color: '#000000'
                        },
                        newReviewButton: {
                            backgroundColor: '#000000',
                            fontColor: '#FFFFFF',
                            disabled: {
                                backgroundColor: '#515151',
                                fontColor: '#c3c3c3',
                            }
                        },
                    },
                    frontPage: {
                        fontSize: '20px',
                        hoursDropdown: {
                            icon: {
                                size: '1.2rem'
                            }
                        }
                    }
                },
                map: {
                    google: [
                        {
                            elementType: 'geometry',
                            stylers: [{ color: '#f7f7f7' }]
                        },
                        {
                            featureType: 'road',
                            elementType: 'geometry',
                            stylers: [{ color: '#EADAC4' }]
                        },
                        {
                            featureType: 'poi.business',
                            stylers: [{ visibility: 'off' }]
                        },
                        {
                            featureType: 'water',
                            elementType: 'geometry',
                            stylers: [{ color: '#D7ECF0' }]
                        }
                    ]
                },
                common: {
                    borderRadius: ''
                },
                orderHereAnimation: {
                    color: Color.Black,
                    screen: {
                        full: {
                            width: '',
                            bottom: '',
                            right: ''
                        }
                    }
                }
            }
        },
        [RestaurantSlug.oFour]: {
            cssClass: 'o-four',
            pageVersion: RestaurantStyle.Classic,
            color: {
                //DONE
                primary: '#bf6d00',
                secondary: '#ffffff'
            },
            styles: {
                page: {
                    menu: {
                        button: {
                            color: '#5E2C2E',
                            disabledColor: '#a9a9a9',
                            iconColor: '#F0E7E6'
                        },
                        item: {
                            description: {
                                fontWeight: '400',
                                color: ''
                            }
                        },
                        style: MenuStyle.Standard,
                        selectable: false
                    },
                    reviews: {
                        icon: {
                            color: '#5E2C2E'
                        },
                        newReviewButton: {
                            backgroundColor: '#5E2C2E',
                            fontColor: '#F0E7E6',
                            disabled: {
                                backgroundColor: '#515151',
                                fontColor: '#a1a1a1',
                            }
                        }
                    },
                    frontPage: {
                        fontSize: '20px',
                        hoursDropdown: {
                            icon: {
                                size: '1.2rem'
                            }
                        }
                    }
                },
                map: {
                    google: [
                        {
                            elementType: 'geometry',
                            stylers: [{ color: '#f7f7f7' }]
                        },
                        {
                            featureType: 'road',
                            elementType: 'geometry',
                            stylers: [{ color: '#FFE7CA' }]
                        },
                        {
                            featureType: 'poi.business',
                            stylers: [{ visibility: 'off' }]
                        },
                        {
                            featureType: 'water',
                            elementType: 'geometry',
                            stylers: [{ color: '#D7ECF0' }]
                        }
                    ]
                },
                common: {
                    borderRadius: ''
                },
                orderHereAnimation: {
                    color: Color.White,
                    screen: {
                        full: {
                            width: '',
                            bottom: '',
                            right: ''
                        }
                    }
                }
            }
        },
        [RestaurantSlug.Karin]: {
            cssClass: 'karin',
            pageVersion: RestaurantStyle.Classic,
            color: {
                primary: '#AD4134',
                secondary: '#232323'
            },
            styles: {
                page: {
                    menu: {
                        button: {
                            color: '#28418A',
                            disabledColor: '#757984',
                            iconColor: '#FFFFFF'
                        },
                        item: {
                            name: {
                                font: 'Open Sans, sans-serif',
                                fontSize: '1.1rem',
                                fontWeight: '400',
                            },
                            description: {
                                font: 'Open Sans, sans-serif',
                                fontWeight: '400',
                                color: ''
                            },
                            price: {
                                font: 'Open Sans, sans-serif',
                                fontSize: '1rem',
                                fontWeight: '400',
                            },
                            style: MenuItemStyle.HorizontalWithOptionalImage
                        },
                        style: MenuStyle.Standard,
                        selectable: false
                    },
                    reviews: {
                        icon: {
                            color: '#000000'
                        },
                        newReviewButton: {
                            backgroundColor: '#000000',
                            fontColor: '#FFFFFF',
                            disabled: {
                                backgroundColor: '#515151',
                                fontColor: '#c3c3c3',
                            }
                        },
                    },
                    frontPage: {
                        fontSize: '20px',
                        hoursDropdown: {
                            icon: {
                                size: '1.2rem'
                            }
                        }
                    }
                },
                map: {
                    google: [
                        {
                            elementType: 'geometry',
                            stylers: [{ color: '#f7f7f7' }]
                        },
                        {
                            featureType: 'road',
                            elementType: 'geometry',
                            stylers: [{ color: '#B7C0DA' }]
                        },
                        {
                            featureType: 'poi.business',
                            stylers: [{ visibility: 'off' }]
                        },
                        {
                            featureType: 'water',
                            elementType: 'geometry',
                            stylers: [{ color: '#D7ECF0' }]
                        }
                    ]
                },
                common: {
                    borderRadius: ''
                },
                orderHereAnimation: {
                    color: Color.Black,
                    screen: {
                        full: {
                            width: '',
                            bottom: '',
                            right: ''
                        }
                    }
                }
            }
        },
        [RestaurantSlug.MarcheMarema]: {
            cssClass: 'marche-marema',
            pageVersion: RestaurantStyle.Classic,
            color: {
                primary: '#FFD37B',
                secondary: '#FFFFFF'
            },
            styles: {
                page: {
                    menu: {
                        button: {
                            color: '#DAAD54',
                            disabledColor: '#8e8d8a',
                            iconColor: '#FFFFFF'
                        },
                        item: {
                            name: {
                                font: 'Poppins, sans-serif',
                                fontSize: '1.1rem',
                                fontWeight: '400',
                            },
                            description: {
                                font: 'Poppins, sans-serif',
                                fontWeight: '300',
                                color: ''
                            },
                            price: {
                                font: 'Poppins, sans-serif',
                                fontSize: '1rem',
                                fontWeight: '400',
                            },
                            style: MenuItemStyle.HorizontalWithOptionalImage
                        },
                        style: MenuStyle.Standard,
                        selectable: false
                    },
                    reviews: {
                        icon: {
                            color: '#FFFFFF'
                        },
                        newReviewButton: {
                            backgroundColor: '#FFFFFF',
                            fontColor: '#000000',
                            disabled: {
                                backgroundColor: '#515151',
                                fontColor: '#c3c3c3',
                            }
                        },
                    },
                    frontPage: {
                        fontSize: '20px',
                        hoursDropdown: {
                            icon: {
                                size: '1.2rem'
                            }
                        }
                    }
                },
                map: {
                    google: [
                        {
                            elementType: 'geometry',
                            stylers: [{ color: '#f7f7f7' }]
                        },
                        {
                            featureType: 'road',
                            elementType: 'geometry',
                            stylers: [{ color: '#E9DBC1' }]
                        },
                        {
                            featureType: 'poi.business',
                            stylers: [{ visibility: 'off' }]
                        },
                        {
                            featureType: 'water',
                            elementType: 'geometry',
                            stylers: [{ color: '#D7ECF0' }]
                        }
                    ]
                },
                common: {
                    borderRadius: ''
                },
                orderHereAnimation: {
                    color: Color.White,
                    screen: {
                        full: {
                            width: '',
                            bottom: '',
                            right: ''
                        }
                    }
                }
            }
        },
        [RestaurantSlug.ElephantThai]: {
            cssClass: 'elephant-thai',
            pageVersion: RestaurantStyle.Classic,
            color: {
                primary: '#FFA471',
                secondary: '#FFFFFF'
            },
            styles: {
                page: {
                    menu: {
                        button: {
                            color: '#B83737',
                            disabledColor: '#AAA8A8',
                            iconColor: '#FFFFFF'
                        },
                        item: {
                            name: {
                                font: 'Poppins, sans-serif',
                                fontSize: '1.1rem',
                                fontWeight: '400',
                            },
                            description: {
                                font: 'Poppins, sans-serif',
                                fontWeight: '300',
                                color: ''
                            },
                            price: {
                                font: 'Poppins, sans-serif',
                                fontSize: '1rem',
                                fontWeight: '400',
                            },
                            style: MenuItemStyle.NoImage
                        },
                        style: MenuStyle.Standard,
                        selectable: false
                    },
                    reviews: {
                        icon: {
                            color: '#FFFFFF'
                        },
                        newReviewButton: {
                            backgroundColor: '#FFFFFF',
                            fontColor: '#000000',
                            disabled: {
                                backgroundColor: '#515151',
                                fontColor: '#c3c3c3',
                            }
                        },
                    },
                    frontPage: {
                        fontSize: '20px',
                        hoursDropdown: {
                            icon: {
                                size: '1.2rem'
                            }
                        }
                    }
                },
                map: {
                    google: [
                        {
                            elementType: 'geometry',
                            stylers: [{ color: '#f7f7f7' }]
                        },
                        {
                            featureType: 'road',
                            elementType: 'geometry',
                            stylers: [{ color: '#EDC3C3' }]
                        },
                        {
                            featureType: 'poi.business',
                            stylers: [{ visibility: 'off' }]
                        },
                        {
                            featureType: 'water',
                            elementType: 'geometry',
                            stylers: [{ color: '#D7ECF0' }]
                        }
                    ]
                },
                common: {
                    borderRadius: ''
                },
                orderHereAnimation: {
                    color: Color.White,
                    screen: {
                        full: {
                            width: '',
                            bottom: '',
                            right: ''
                        }
                    }
                }
            }
        },
        [RestaurantSlug.Sushiyo]: {
            cssClass: 'sushiyo',
            pageVersion: RestaurantStyle.Classic,
            color: {
                primary: '#F25D5D',
                secondary: '#000000'
            },
            styles: {
                page: {
                    menu: {
                        button: {
                            color: '#F25D5D',
                            disabledColor: '#ADADAD',
                            iconColor: '#FFFFFF'
                        },
                        item: {
                            name: {
                                font: 'Poppins, sans-serif',
                                fontSize: '1.1rem',
                                fontWeight: '400',
                            },
                            description: {
                                font: 'Poppins, sans-serif',
                                fontWeight: '300',
                                color: ''
                            },
                            price: {
                                font: 'Poppins, sans-serif',
                                fontSize: '1rem',
                                fontWeight: '400',
                            },
                            style: MenuItemStyle.HorizontalWithOptionalImage
                        },
                        style: MenuStyle.Standard,
                        selectable: false
                    },
                    reviews: {
                        icon: {
                            color: '#000000'
                        },
                        newReviewButton: {
                            backgroundColor: '#000000',
                            fontColor: '#FFFFFF',
                            disabled: {
                                backgroundColor: '#515151',
                                fontColor: '#c3c3c3',
                            }
                        },
                    },
                    frontPage: {
                        fontSize: '20px',
                        hoursDropdown: {
                            icon: {
                                size: '1.2rem'
                            }
                        }
                    }
                },
                map: {
                    google: [
                        {
                            elementType: 'geometry',
                            stylers: [{ color: '#f7f7f7' }]
                        },
                        {
                            featureType: 'road',
                            elementType: 'geometry',
                            stylers: [{ color: '#FFB8B8' }]
                        },
                        {
                            featureType: 'poi.business',
                            stylers: [{ visibility: 'off' }]
                        },
                        {
                            featureType: 'water',
                            elementType: 'geometry',
                            stylers: [{ color: '#D7ECF0' }]
                        }
                    ]
                },
                common: {
                    borderRadius: ''
                },
                orderHereAnimation: {
                    color: Color.Black,
                    screen: {
                        full: {
                            width: '',
                            bottom: '',
                            right: ''
                        }
                    }
                }
            }
        },
        [RestaurantSlug.BoiteVegane]: {
            cssClass: 'boite-vegane',
            pageVersion: RestaurantStyle.Classic,
            color: {
                primary: '#468943',
                secondary: '#E095C0'
            },
            styles: {
                page: {
                    menu: {
                        button: {
                            color: '#468943',
                            disabledColor: '#8e8d8a',
                            iconColor: '#FFFFFF'
                        },
                        item: {
                            name: {
                                font: 'Roboto, sans-serif',
                                fontSize: '1.1rem',
                                fontWeight: '400',
                            },
                            description: {
                                font: 'Roboto, sans-serif',
                                fontWeight: '300',
                                color: ''
                            },
                            price: {
                                font: 'Roboto, sans-serif',
                                fontSize: '1rem',
                                fontWeight: '400',
                            },
                            style: MenuItemStyle.HorizontalWithOptionalImage
                        },
                        style: MenuStyle.Standard,
                        selectable: false
                    },
                    reviews: {
                        icon: {
                            color: '#000000'
                        },
                        newReviewButton: {
                            backgroundColor: '#000000',
                            fontColor: '#FFFFFF',
                            disabled: {
                                backgroundColor: '#515151',
                                fontColor: '#c3c3c3',
                            }
                        },
                    },
                    frontPage: {
                        fontSize: '20px',
                        hoursDropdown: {
                            icon: {
                                size: '1.2rem'
                            }
                        }
                    }
                },
                map: {
                    google: [
                        {
                            elementType: 'geometry',
                            stylers: [{ color: '#f7f7f7' }]
                        },
                        {
                            featureType: 'road',
                            elementType: 'geometry',
                            stylers: [{ color: '#f5cbe3' }]
                        },
                        {
                            featureType: 'poi.business',
                            stylers: [{ visibility: 'off' }]
                        },
                        {
                            featureType: 'water',
                            elementType: 'geometry',
                            stylers: [{ color: '#D7ECF0' }]
                        }
                    ]
                },
                common: {
                    borderRadius: ''
                },
                orderHereAnimation: {
                    color: Color.White,
                    screen: {
                        full: {
                            width: '',
                            bottom: '',
                            right: ''
                        }
                    }
                }
            }
        },
        [RestaurantSlug.JrsCafe]: {
            cssClass: 'jrs-cafe',
            pageVersion: RestaurantStyle.Classic,
            color: {
                primary: '#5F9BCE',
                secondary: '#000000'
            },
            styles: {
                page: {
                    menu: {
                        button: {
                            color: '#5F9BCE',
                            disabledColor: '#8e8d8a',
                            iconColor: '#FFFFFF'
                        },
                        item: {
                            name: {
                                font: 'Open Sans, sans-serif',
                                fontSize: '1.1rem',
                                fontWeight: '400',
                            },
                            description: {
                                font: 'Open Sans, sans-serif',
                                fontWeight: '300',
                                color: ''
                            },
                            price: {
                                font: 'Open Sans, sans-serif',
                                fontSize: '1rem',
                                fontWeight: '400',
                            },
                            style: MenuItemStyle.HorizontalWithOptionalImage
                        },
                        style: MenuStyle.Standard,
                        selectable: false
                    },
                    reviews: {
                        icon: {
                            color: '#000000'
                        },
                        newReviewButton: {
                            backgroundColor: '#000000',
                            fontColor: '#FFFFFF',
                            disabled: {
                                backgroundColor: '#515151',
                                fontColor: '#c3c3c3',
                            }
                        },
                    },
                    frontPage: {
                        fontSize: '20px',
                        hoursDropdown: {
                            icon: {
                                size: '1.2rem'
                            }
                        }
                    }
                },
                map: {
                    google: [
                        {
                            elementType: 'geometry',
                            stylers: [{ color: '#f7f7f7' }]
                        },
                        {
                            featureType: 'road',
                            elementType: 'geometry',
                            stylers: [{ color: '#D6ECFF' }]
                        },
                        {
                            featureType: 'poi.business',
                            stylers: [{ visibility: 'off' }]
                        },
                        {
                            featureType: 'water',
                            elementType: 'geometry',
                            stylers: [{ color: '#D7ECF0' }]
                        }
                    ]
                },
                common: {
                    borderRadius: ''
                },
                orderHereAnimation: {
                    color: Color.Black,
                    screen: {
                        full: {
                            width: '',
                            bottom: '',
                            right: ''
                        }
                    }
                }
            }
        },
        [RestaurantSlug.HeroBurgers]: {
            cssClass: 'hero-burgers',
            pageVersion: RestaurantStyle.Classic,
            color: {
                primary: '#78170F',
                secondary: '#E095C0'
            },
            styles: {
                page: {
                    menu: {
                        button: {
                            color: '#78170F',
                            disabledColor: '#8e8d8a',
                            iconColor: '#FFFFFF'
                        },
                        item: {
                            name: {
                                font: 'Roboto, sans-serif',
                                fontSize: '1.1rem',
                                fontWeight: '400',
                            },
                            description: {
                                font: 'Roboto, sans-serif',
                                fontWeight: '300',
                                color: ''
                            },
                            price: {
                                font: 'Roboto, sans-serif',
                                fontSize: '1rem',
                                fontWeight: '400',
                            },
                            style: MenuItemStyle.HorizontalWithOptionalImage
                        },
                        style: MenuStyle.Standard,
                        selectable: false
                    },
                    reviews: {
                        icon: {
                            color: '#000000'
                        },
                        newReviewButton: {
                            backgroundColor: '#000000',
                            fontColor: '#FFFFFF',
                            disabled: {
                                backgroundColor: '#515151',
                                fontColor: '#c3c3c3',
                            }
                        },
                    },
                    frontPage: {
                        fontSize: '20px',
                        hoursDropdown: {
                            icon: {
                                size: '1.2rem'
                            }
                        }
                    }
                },
                map: {
                    google: [
                        {
                            elementType: 'geometry',
                            stylers: [{ color: '#f7f7f7' }]
                        },
                        {
                            featureType: 'road',
                            elementType: 'geometry',
                            stylers: [{ color: '#fbdb95' }]
                        },
                        {
                            featureType: 'poi.business',
                            stylers: [{ visibility: 'off' }]
                        },
                        {
                            featureType: 'water',
                            elementType: 'geometry',
                            stylers: [{ color: '#D7ECF0' }]
                        }
                    ]
                },
                common: {
                    borderRadius: ''
                },
                orderHereAnimation: {
                    color: Color.White,
                    screen: {
                        full: {
                            width: '',
                            bottom: '',
                            right: ''
                        }
                    }
                }
            }
        },
        [RestaurantSlug.CafeResonance]: {
            cssClass: 'cafe-resonance',
            pageVersion: RestaurantStyle.Classic,
            color: {
                primary: '#D9FAF5',
                secondary: '#FFFFFF'
            },
            styles: {
                page: {
                    menu: {
                        button: {
                            color: '#FF6622',
                            disabledColor: '#A3A3A3',
                            iconColor: '#FFFFFF'
                        },
                        item: {
                            name: {
                                font: 'Alegreya Sans, sans-serif',
                                fontSize: '1.2rem',
                                fontWeight: '400',
                                color: '#FF6622'
                            },
                            description: {
                                font: 'Alegreya Sans, sans-serif',
                                fontWeight: '300',
                                color: '',
                                fontSize: '0.9rem'
                            },
                            price: {
                                font: 'Alegreya Sans, sans-serif',
                                fontSize: '1.2rem',
                                fontWeight: '400',
                            },
                            style: MenuItemStyle.HorizontalWithOptionalImage
                        },
                        style: MenuStyle.Standard,
                        selectable: false
                    },
                    reviews: {
                        icon: {
                            color: '#FFFFFF'
                        },
                        newReviewButton: {
                            backgroundColor: '#FFFFFF',
                            fontColor: '#000000',
                            disabled: {
                                backgroundColor: '#515151',
                                fontColor: '#c3c3c3',
                            }
                        },
                    },
                    frontPage: {
                        fontSize: '20px',
                        hoursDropdown: {
                            icon: {
                                size: '1.2rem'
                            }
                        }
                    }
                },
                map: {
                    google: [
                        {
                            elementType: 'geometry',
                            stylers: [{ color: '#f7f7f7' }]
                        },
                        {
                            featureType: 'road',
                            elementType: 'geometry',
                            stylers: [{ color: '#D9FAF5' }]
                        },
                        {
                            featureType: 'poi.business',
                            stylers: [{ visibility: 'off' }]
                        },
                        {
                            featureType: 'water',
                            elementType: 'geometry',
                            stylers: [{ color: '#D7ECF0' }]
                        }
                    ]
                },
                common: {
                    borderRadius: ''
                },
                orderHereAnimation: {
                    color: Color.White,
                    screen: {
                        full: {
                            width: '',
                            bottom: '',
                            right: ''
                        }
                    }
                }
            }
        },
        [RestaurantSlug.UbiSushi]: {
            cssClass: 'ubi-sushi',
            pageVersion: RestaurantStyle.Classic,
            color: {
                primary: '#FA4752',
                secondary: '#FFFFFF'
            },
            styles: {
                page: {
                    menu: {
                        button: {
                            color: '#FA4752',
                            disabledColor: '#AEAEAE',
                            iconColor: '#FFFFFF'
                        },
                        tab: {
                            font: 'Raleway, sans-serif',
                            padding: '1px 0px',
                            active: {
                                backgroundColor: 'transparent',
                                color: '#ffffff',
                                borderBottom: '1px solid #FA4752',
                                border: 'none',
                                fontWeight: '600'
                            },
                            inactive: {
                                font: 'Raleway, sans-serif',
                                backgroundColor: 'transparent',
                                color: '#ffffff',
                                borderBottom: 'none',
                                border: 'none',
                                fontWeight: '400'
                            }
                        },
                        item: {
                            name: {
                                font: 'Raleway, sans-serif',
                                fontSize: '1.1rem',
                                fontWeight: '500'
                            },
                            description: {
                                font: 'Raleway, sans-serif',
                                fontWeight: '300',
                                color: ''
                            },
                            price: {
                                font: 'Raleway, sans-serif',
                                fontSize: '1rem',
                                fontWeight: '500',
                            },
                            style: MenuItemStyle.HorizontalWithOptionalImage
                        },
                        style: MenuStyle.Standard,
                        selectable: true
                    },
                    reviews: {
                        icon: {
                            color: '#FFFFFF'
                        },
                        newReviewButton: {
                            backgroundColor: '#FFFFFF',
                            fontColor: '#000000',
                            disabled: {
                                backgroundColor: '#515151',
                                fontColor: '#c3c3c3',
                            }
                        },
                    },
                    frontPage: {
                        fontSize: '20px',
                        hoursDropdown: {
                            icon: {
                                size: '1.2rem'
                            }
                        }
                    }
                },
                map: {
                    google: [
                        {
                            elementType: 'geometry',
                            stylers: [{ color: '#f7f7f7' }]
                        },
                        {
                            featureType: 'road',
                            elementType: 'geometry',
                            stylers: [{ color: '#FFBDC1' }]
                        },
                        {
                            featureType: 'poi.business',
                            stylers: [{ visibility: 'off' }]
                        },
                        {
                            featureType: 'water',
                            elementType: 'geometry',
                            stylers: [{ color: '#D7ECF0' }]
                        }
                    ]
                },
                common: {
                    borderRadius: ''
                },
                orderHereAnimation: {
                    color: Color.White,
                    screen: {
                        full: {
                            width: '',
                            bottom: '',
                            right: ''
                        }
                    }
                }
            }
        },
        [RestaurantSlug.Melrose]: {
            cssClass: 'melrose',
            pageVersion: RestaurantStyle.Classic,
            color: {
                primary: '#000000',
                secondary: '#000000'
            },
            styles: {
                page: {
                    menu: {
                        button: {
                            color: '#000000',
                            disabledColor: '#AEAEAE',
                            iconColor: '#FFFFFF'
                        },
                        tab: {
                            font: 'futura-pt, sans-serif',
                            padding: '1px 0px',
                            active: {
                                backgroundColor: 'transparent',
                                color: '#000',
                                borderBottom: '2px solid #000000',
                                border: 'none',
                                fontWeight: '500'
                            },
                            inactive: {
                                font: 'futura-pt, sans-serif',
                                backgroundColor: 'transparent',
                                color: '#000',
                                borderBottom: 'none',
                                border: 'none',
                                fontWeight: '400'
                            }
                        },
                        item: {
                            name: {
                                font: 'futura-pt, sans-serif',
                                fontSize: '1.2rem',
                                fontWeight: '500'
                            },
                            description: {
                                font: 'futura-pt, sans-serif',
                                fontWeight: '300',
                                color: '',
                                fontSize: '1rem'
                            },
                            price: {
                                font: 'futura-pt, sans-serif',
                                fontSize: '1rem',
                                fontWeight: '500',
                            },
                            style: MenuItemStyle.HorizontalWithOptionalImage
                        },
                        style: MenuStyle.Standard,
                        selectable: true
                    },
                    reviews: {
                        icon: {
                            color: '#000000'
                        },
                        newReviewButton: {
                            backgroundColor: '#000000',
                            fontColor: '#FFFFFF',
                            disabled: {
                                backgroundColor: '#515151',
                                fontColor: '#c3c3c3',
                            }
                        },
                    },
                    frontPage: {
                        fontSize: '20px',
                        hoursDropdown: {
                            icon: {
                                size: '1.2rem'
                            }
                        }
                    }
                },
                map: {
                    google: [
                        {
                            elementType: 'geometry',
                            stylers: [{ color: '#f7f7f7' }]
                        },
                        {
                            featureType: 'road',
                            elementType: 'geometry',
                            stylers: [{ color: '#DADADA' }]
                        },
                        {
                            featureType: 'poi.business',
                            stylers: [{ visibility: 'off' }]
                        },
                        {
                            featureType: 'water',
                            elementType: 'geometry',
                            stylers: [{ color: '#D7ECF0' }]
                        }
                    ]
                },
                common: {
                    borderRadius: ''
                },
                orderHereAnimation: {
                    color: Color.White,
                    screen: {
                        full: {
                            width: '',
                            bottom: '',
                            right: ''
                        }
                    }
                }
            }
        },
        [RestaurantSlug.ObsceneVegan]: {
            cssClass: 'obscene-vegan',
            pageVersion: RestaurantStyle.Classic,
            color: {
                primary: '#0e8d0d',
                secondary: '#3DC040'
            },
            styles: {
                page: {
                    menu: {
                        button: {
                            color: '#0D8E0F',
                            disabledColor: '#AEAEAE',
                            iconColor: '#FFFFFF'
                        },
                        item: {
                            name: {
                                font: 'Lora,serif',
                                fontSize: '1.1rem',
                                fontWeight: '500'
                            },
                            description: {
                                font: 'Lora,serif',
                                fontWeight: '300',
                                color: ''
                            },
                            price: {
                                font: 'Lora,serif',
                                fontSize: '1rem',
                                fontWeight: '500',
                            },
                            style: MenuItemStyle.HorizontalWithOptionalImage
                        },
                        style: MenuStyle.Standard,
                        selectable: false
                    },
                    reviews: {
                        icon: {
                            color: '#FFFFFF'
                        },
                        newReviewButton: {
                            backgroundColor: '#FFFFFF',
                            fontColor: '#000000',
                            disabled: {
                                backgroundColor: '#515151',
                                fontColor: '#c3c3c3',
                            }
                        },
                    },
                    frontPage: {
                        fontSize: '20px',
                        hoursDropdown: {
                            icon: {
                                size: '1.2rem'
                            }
                        }
                    }
                },
                map: {
                    google: [
                        {
                            elementType: 'geometry',
                            stylers: [{ color: '#f7f7f7' }]
                        },
                        {
                            featureType: 'road',
                            elementType: 'geometry',
                            stylers: [{ color: '#C7EEC8' }]
                        },
                        {
                            featureType: 'poi.business',
                            stylers: [{ visibility: 'off' }]
                        },
                        {
                            featureType: 'water',
                            elementType: 'geometry',
                            stylers: [{ color: '#D7ECF0' }]
                        }
                    ]
                },
                common: {
                    borderRadius: ''
                },
                orderHereAnimation: {
                    color: Color.White,
                    screen: {
                        full: {
                            width: '',
                            bottom: '',
                            right: ''
                        }
                    }
                }
            }
        },
        [RestaurantSlug.LestersDeli]: {
            cssClass: 'lesters-deli',
            pageVersion: RestaurantStyle.Classic,
            color: {
                primary: '#FFFFFF',
                secondary: '#FF4E58'
            },
            styles: {
                page: {
                    menu: {
                        button: {
                            color: '#FF4E58',
                            disabledColor: '#AEAEAE',
                            iconColor: '#FFFFFF'
                        },
                        item: {
                            name: {
                                font: 'Raleway, sans-serif',
                                fontSize: '1.1rem',
                                fontWeight: '500'
                            },
                            description: {
                                font: 'Raleway, sans-serif',
                                fontWeight: '300',
                                color: ''
                            },
                            price: {
                                font: 'Raleway, sans-serif',
                                fontSize: '1rem',
                                fontWeight: '500',
                            },
                            style: MenuItemStyle.HorizontalWithOptionalImage
                        },
                        style: MenuStyle.Standard,
                        selectable: false
                    },
                    reviews: {
                        icon: {
                            color: '#FFFFFF'
                        },
                        newReviewButton: {
                            backgroundColor: '#FFFFFF',
                            fontColor: '#000000',
                            disabled: {
                                backgroundColor: '#515151',
                                fontColor: '#c3c3c3',
                            }
                        },
                    },
                    frontPage: {
                        fontSize: '20px',
                        hoursDropdown: {
                            icon: {
                                size: '1.2rem'
                            }
                        }
                    }
                },
                map: {
                    google: [
                        {
                            elementType: 'geometry',
                            stylers: [{ color: '#f7f7f7' }]
                        },
                        {
                            featureType: 'road',
                            elementType: 'geometry',
                            stylers: [{ color: '#FFC3C7' }]
                        },
                        {
                            featureType: 'poi.business',
                            stylers: [{ visibility: 'off' }]
                        },
                        {
                            featureType: 'water',
                            elementType: 'geometry',
                            stylers: [{ color: '#D7ECF0' }]
                        }
                    ]
                },
                common: {
                    borderRadius: ''
                },
                orderHereAnimation: {
                    color: Color.White,
                    screen: {
                        full: {
                            width: '',
                            bottom: '',
                            right: ''
                        }
                    }
                }
            }
        },
        [RestaurantSlug.Barranco]: {
            cssClass: 'barranco',
            pageVersion: RestaurantStyle.Classic,
            color: {
                primary: '#ffffff',
                secondary: '#FAB07A'
            },
            styles: {
                page: {
                    menu: {
                        button: {
                            color: '#FAB07A',
                            disabledColor: '#CBCBCB',
                            iconColor: '#000000'
                        },
                        item: {
                            name: {
                                font: 'Poppins, sans-serif',
                                fontSize: '1.1rem',
                                fontWeight: '400'
                            },
                            description: {
                                font: 'Poppins, sans-serif',
                                fontWeight: '200',
                                color: ''
                            },
                            price: {
                                font: 'Poppins, sans-serif',
                                fontSize: '1rem',
                                fontWeight: '400',
                            },
                            style: MenuItemStyle.HorizontalWithOptionalImage
                        },
                        style: MenuStyle.Standard,
                        selectable: false
                    },
                    reviews: {
                        icon: {
                            color: '#FFFFFF'
                        },
                        newReviewButton: {
                            backgroundColor: '#FFFFFF',
                            fontColor: '#073530',
                            disabled: {
                                backgroundColor: '#515151',
                                fontColor: '#c3c3c3',
                            }
                        },
                    },
                    frontPage: {
                        fontSize: '20px',
                        hoursDropdown: {
                            icon: {
                                size: '1.2rem'
                            }
                        }
                    }
                },
                map: {
                    google: [
                        {
                            elementType: 'geometry',
                            stylers: [{ color: '#f7f7f7' }]
                        },
                        {
                            featureType: 'road',
                            elementType: 'geometry',
                            stylers: [{ color: '#ffd1b0' }]
                        },
                        {
                            featureType: 'poi.business',
                            stylers: [{ visibility: 'off' }]
                        },
                        {
                            featureType: 'water',
                            elementType: 'geometry',
                            stylers: [{ color: '#D7ECF0' }]
                        }
                    ]
                },
                common: {
                    borderRadius: ''
                },
                orderHereAnimation: {
                    color: Color.White,
                    screen: {
                        full: {
                            width: '',
                            bottom: '',
                            right: ''
                        }
                    }
                }
            }
        },
        [RestaurantSlug.PetitSeoul]: {
            cssClass: 'petit-seoul',
            pageVersion: RestaurantStyle.Classic,
            color: {
                primary: '#fd0109',
                secondary: '#FF4E58'
            },
            styles: {
                page: {
                    menu: {
                        button: {
                            color: '#fd0109',
                            disabledColor: '#555454',
                            iconColor: '#FFFFFF'
                        },
                        item: {
                            name: {
                                font: 'Josefin Sans, sans-serif',
                                fontSize: '1.1rem',
                                fontWeight: '500',
                                color: '#FFFFFF'
                            },
                            description: {
                                font: 'Josefin Sans, sans-serif',
                                fontWeight: '300',
                                color: '#FFFFFF'
                            },
                            price: {
                                font: 'Josefin Sans, sans-serif',
                                fontSize: '1rem',
                                fontWeight: '500',
                                color: '#FFFFFF'
                            },
                            style: MenuItemStyle.HorizontalWithOptionalImage
                        },
                        style: MenuStyle.Standard,
                        selectable: false
                    },
                    reviews: {
                        icon: {
                            color: '#FFFFFF',
                            font: 'Josefin Sans, sans-serif',
                        },
                        newReviewButton: {
                            backgroundColor: '#fd0109',
                            fontColor: '#FFFFFF',
                            disabled: {
                                backgroundColor: '#515151',
                                fontColor: '#c3c3c3',
                            }
                        },
                    },
                    frontPage: {
                        fontSize: '20px',
                        hoursDropdown: {
                            icon: {
                                size: '1.2rem'
                            }
                        }
                    }
                },
                map: {
                    google: [
                        {
                            elementType: 'geometry',
                            stylers: [{ color: '#f7f7f7' }]
                        },
                        {
                            featureType: 'road',
                            elementType: 'geometry',
                            stylers: [{ color: '#e6aeb0' }]
                        },
                        {
                            featureType: 'poi.business',
                            stylers: [{ visibility: 'off' }]
                        },
                        {
                            featureType: 'water',
                            elementType: 'geometry',
                            stylers: [{ color: '#D7ECF0' }]
                        }
                    ]
                },
                common: {
                    borderRadius: ''
                },
                orderHereAnimation: {
                    color: Color.White,
                    screen: {
                        full: {
                            width: '',
                            bottom: '',
                            right: ''
                        }
                    }
                }
            }
        },
        [RestaurantSlug.Hooters]: {
            cssClass: 'hooters',
            pageVersion: RestaurantStyle.Classic,
            color: {
                primary: '#FF571F',
                secondary: '#FAB07A'
            },
            styles: {
                page: {
                    menu: {
                        button: {
                            color: '#381B0B',
                            disabledColor: '#CBCBCB',
                            iconColor: '#FFFFFF'
                        },
                        item: {
                            name: {
                                font: 'Roboto, sans-serif',
                                fontSize: '1.1rem',
                                fontWeight: '600',
                                color: '#381B0B'
                            },
                            description: {
                                font: 'Roboto, sans-serif',
                                fontWeight: '200',
                                color: '#381B0B',
                                fontSize: '0.9rem'
                            },
                            price: {
                                font: 'Roboto, sans-serif',
                                fontSize: '1.1rem',
                                fontWeight: '600',
                            },
                            style: MenuItemStyle.HorizontalWithOptionalImage
                        },
                        style: MenuStyle.Standard,
                        selectable: false
                    },
                    reviews: {
                        icon: {
                            color: '#381B0B'
                        },
                        newReviewButton: {
                            backgroundColor: '#381B0B',
                            fontColor: '#FFFFFF',
                            disabled: {
                                backgroundColor: '#515151',
                                fontColor: '#c3c3c3',
                            }
                        },
                    },
                    frontPage: {
                        fontSize: '20px',
                        hoursDropdown: {
                            icon: {
                                size: '1.2rem'
                            }
                        }
                    }
                },
                map: {
                    google: [
                        {
                            elementType: 'geometry',
                            stylers: [{ color: '#f7f7f7' }]
                        },
                        {
                            featureType: 'road',
                            elementType: 'geometry',
                            stylers: [{ color: '#fc9877' }]
                        },
                        {
                            featureType: 'poi.business',
                            stylers: [{ visibility: 'off' }]
                        },
                        {
                            featureType: 'water',
                            elementType: 'geometry',
                            stylers: [{ color: '#D7ECF0' }]
                        }
                    ]
                },
                common: {
                    borderRadius: ''
                },
                orderHereAnimation: {
                    color: Color.Black,
                    screen: {
                        full: {
                            width: '',
                            bottom: '',
                            right: ''
                        }
                    }
                }
            }
        },
        [RestaurantSlug.Darbar]: {
            cssClass: 'darbar',
            pageVersion: RestaurantStyle.Classic,
            color: {
                primary: '#DAACB4',
                secondary: '#FFFFFF'
            },
            styles: {
                page: {
                    menu: {
                        button: {
                            color: '#DAACB4',
                            disabledColor: '#CBCBCB',
                            iconColor: '#000000'
                        },
                        item: {
                            name: {
                                font: 'Open Sans, sans-serif',
                                fontSize: '1.1rem',
                                fontWeight: '500'
                            },
                            description: {
                                font: 'Open Sans, sans-serif',
                                fontWeight: '100',
                                color: ''
                            },
                            price: {
                                font: 'Open Sans, sans-serif',
                                fontSize: '1rem',
                                fontWeight: '500',
                            },
                            style: MenuItemStyle.HorizontalWithOptionalImage
                        },
                        style: MenuStyle.Standard,
                        selectable: false
                    },
                    reviews: {
                        icon: {
                            color: '#FFFFFF'
                        },
                        newReviewButton: {
                            backgroundColor: '#FFFFFF',
                            fontColor: '#022A2A',
                            disabled: {
                                backgroundColor: '#515151',
                                fontColor: '#c3c3c3',
                            }
                        },
                    },
                    frontPage: {
                        fontSize: '20px',
                        hoursDropdown: {
                            icon: {
                                size: '1.2rem'
                            }
                        }
                    }
                },
                map: {
                    google: [
                        {
                            elementType: 'geometry',
                            stylers: [{ color: '#f7f7f7' }]
                        },
                        {
                            featureType: 'road',
                            elementType: 'geometry',
                            stylers: [{ color: '#F3D4DA' }]
                        },
                        {
                            featureType: 'poi.business',
                            stylers: [{ visibility: 'off' }]
                        },
                        {
                            featureType: 'water',
                            elementType: 'geometry',
                            stylers: [{ color: '#D7ECF0' }]
                        }
                    ]
                },
                common: {
                    borderRadius: ''
                },
                orderHereAnimation: {
                    color: Color.White,
                    screen: {
                        full: {
                            width: '',
                            bottom: '',
                            right: ''
                        }
                    }
                }
            }
        },
        [RestaurantSlug.JardinPetros]: {
            cssClass: 'jardin-petros',
            pageVersion: RestaurantStyle.Classic,
            color: {
                primary: '#0091d0',
                secondary: '#FFFFFF'
            },
            styles: {
                page: {
                    menu: {
                        button: {
                            color: '#d69942',
                            disabledColor: '#CBCBCB',
                            iconColor: '#FFFFFF'
                        },
                        item: {
                            name: {
                                font: 'Almendra Regular, sans-serif',
                                fontSize: '1.1rem',
                                fontWeight: '500'
                            },
                            description: {
                                font: 'Almendra Regular, sans-serif',
                                fontWeight: '100',
                                color: '#191415'
                            },
                            price: {
                                font: 'Almendra Regular, sans-serif',
                                fontSize: '1rem',
                                fontWeight: '500',
                            },
                            style: MenuItemStyle.HorizontalWithOptionalImage
                        },
                        style: MenuStyle.Standard,
                        selectable: false
                    },
                    reviews: {
                        icon: {
                            color: '#D69942'
                        },
                        newReviewButton: {
                            backgroundColor: '#0091D0',
                            fontColor: '#FFFFFF',
                            disabled: {
                                backgroundColor: '#515151',
                                fontColor: '#c3c3c3',
                            }
                        },
                    },
                    frontPage: {
                        fontSize: '20px',
                        hoursDropdown: {
                            icon: {
                                size: '1.2rem'
                            }
                        }
                    }
                },
                map: {
                    google: [
                        {
                            elementType: 'geometry',
                            stylers: [{ color: '#f7f7f7' }]
                        },
                        {
                            featureType: 'road',
                            elementType: 'geometry',
                            stylers: [{ color: '#f0cfa1' }]
                        },
                        {
                            featureType: 'poi.business',
                            stylers: [{ visibility: 'off' }]
                        },
                        {
                            featureType: 'water',
                            elementType: 'geometry',
                            stylers: [{ color: '#D7ECF0' }]
                        }
                    ]
                },
                common: {
                    borderRadius: ''
                },
                orderHereAnimation: {
                    color: Color.Black,
                    screen: {
                        full: {
                            width: '',
                            bottom: '',
                            right: ''
                        }
                    }
                }
            }
        },
        [RestaurantSlug.ChezAnna]: {
            cssClass: 'chez-anna',
            pageVersion: RestaurantStyle.Classic,
            color: {
                primary: '#C64D28',
                secondary: '#FFFFFF'
            },
            styles: {
                page: {
                    menu: {
                        button: {
                            color: '#C64D28',
                            disabledColor: '#CBCBCB',
                            iconColor: '#FFFFFF'
                        },
                        item: {
                            name: {
                                font: 'Almendra Regular, sans-serif',
                                fontSize: '1.1rem',
                                fontWeight: '500'
                            },
                            description: {
                                font: 'Almendra Regular, sans-serif',
                                fontWeight: '100',
                                color: '#191415'
                            },
                            price: {
                                font: 'Almendra Regular, sans-serif',
                                fontSize: '1rem',
                                fontWeight: '500',
                            },
                            style: MenuItemStyle.HorizontalWithOptionalImage
                        },
                        style: MenuStyle.Standard,
                        selectable: false
                    },
                    reviews: {
                        icon: {
                            color: '#C64D28'
                        },
                        newReviewButton: {
                            backgroundColor: '#C64D28',
                            fontColor: '#FFFFFF',
                            disabled: {
                                backgroundColor: '#515151',
                                fontColor: '#c3c3c3',
                            }
                        },
                    },
                    frontPage: {
                        fontSize: '20px',
                        hoursDropdown: {
                            icon: {
                                size: '1.2rem'
                            }
                        }
                    }
                },
                map: {
                    google: [
                        {
                            elementType: 'geometry',
                            stylers: [{ color: '#f7f7f7' }]
                        },
                        {
                            featureType: 'road',
                            elementType: 'geometry',
                            stylers: [{ color: '#C64D28' }]
                        },
                        {
                            featureType: 'poi.business',
                            stylers: [{ visibility: 'off' }]
                        },
                        {
                            featureType: 'water',
                            elementType: 'geometry',
                            stylers: [{ color: '#D7ECF0' }]
                        }
                    ]
                },
                common: {
                    borderRadius: ''
                },
                orderHereAnimation: {
                    color: Color.White,
                    screen: {
                        full: {
                            width: '',
                            bottom: '',
                            right: ''
                        }
                    }
                }
            }
        },
        [RestaurantSlug.Krood]: {
            cssClass: 'krood',
            pageVersion: RestaurantStyle.Classic,
            color: {
                primary: '#BD7D2E',
                secondary: '#FFFFFF'
            },
            styles: {
                page: {
                    menu: {
                        button: {
                            color: '#BD7D2E',
                            disabledColor: '#CBCBCB',
                            iconColor: 'white'
                        },
                        item: {
                            name: {
                                font: 'DM Sans, sans-serif',
                                fontSize: '1rem',
                                fontWeight: '500'
                            },
                            description: {
                                font: 'DM Sans, sans-serif',
                                fontWeight: '100',
                                color: '#191415'
                            },
                            price: {
                                font: 'DM Sans, sans-serif',
                                fontSize: '1rem',
                                fontWeight: '500',
                            },
                            style: MenuItemStyle.HorizontalWithOptionalImage
                        },
                        style: MenuStyle.Standard,
                        selectable: false
                    },
                    reviews: {
                        icon: {
                            color: '#BD7D2E'
                        },
                        newReviewButton: {
                            backgroundColor: '#BD7D2E',
                            fontColor: '#FFFFFF',
                            disabled: {
                                backgroundColor: '#515151',
                                fontColor: '#c3c3c3',
                            }
                        },
                    },
                    frontPage: {
                        fontSize: '20px',
                        hoursDropdown: {
                            icon: {
                                size: '1.2rem'
                            }
                        }
                    }
                },
                map: {
                    google: [
                        {
                            elementType: 'geometry',
                            stylers: [{ color: '#f7f7f7' }]
                        },
                        {
                            featureType: 'road',
                            elementType: 'geometry',
                            stylers: [{ color: '#E9D8CA' }]
                        },
                        {
                            featureType: 'poi.business',
                            stylers: [{ visibility: 'off' }]
                        },
                        {
                            featureType: 'water',
                            elementType: 'geometry',
                            stylers: [{ color: '#D8D9D4' }]
                        }
                    ]
                },
                common: {
                    borderRadius: ''
                },
                orderHereAnimation: {
                    color: Color.Black,
                    screen: {
                        full: {
                            width: '',
                            bottom: '',
                            right: ''
                        }
                    }
                }
            }
        },
        [RestaurantSlug.HungarianCuisine]: {
            cssClass: 'hungarian-cuisine',
            pageVersion: RestaurantStyle.Classic,
            color: {
                primary: '#C8102E',
                secondary: '#20C520'
            },
            styles: {
                page: {
                    menu: {
                        button: {
                            color: '#C8102E',
                            disabledColor: '#AEAEAE',
                            iconColor: '#FFFFFF'
                        },
                        item: {
                            name: {
                                font: 'Roboto, sans-serif',
                                fontSize: '1.1rem',
                                fontWeight: '500'
                            },
                            description: {
                                font: 'Roboto, sans-serif',
                                fontWeight: '300',
                                color: ''
                            },
                            price: {
                                font: 'Roboto, sans-serif',
                                fontSize: '1rem',
                                fontWeight: '500',
                            },
                            style: MenuItemStyle.HorizontalWithOptionalImage
                        },
                        style: MenuStyle.Standard,
                        selectable: false
                    },
                    reviews: {
                        icon: {
                            color: '#000000'
                        },
                        newReviewButton: {
                            backgroundColor: '#000000',
                            fontColor: '#FFFFFF',
                            disabled: {
                                backgroundColor: '#515151',
                                fontColor: '#c3c3c3',
                            }
                        },
                    },
                    frontPage: {
                        fontSize: '20px',
                        hoursDropdown: {
                            icon: {
                                size: '1.2rem'
                            }
                        },
                        header: {
                            name: {
                                visible: 'true'
                            }
                        }
                    }
                },
                map: {
                    google: [
                        {
                            elementType: 'geometry',
                            stylers: [{ color: '#f7f7f7' }]
                        },
                        {
                            featureType: 'road',
                            elementType: 'geometry',
                            stylers: [{ color: '#FFFFFF' }]
                        },
                        {
                            featureType: 'poi.business',
                            stylers: [{ visibility: 'off' }]
                        },
                        {
                            featureType: 'water',
                            elementType: 'geometry',
                            stylers: [{ color: '#D7ECF0' }]
                        }
                    ]
                },
                common: {
                    borderRadius: ''
                },
                orderHereAnimation: {
                    color: Color.White,
                    screen: {
                        full: {
                            width: '',
                            bottom: '',
                            right: ''
                        }
                    }
                }
            }
        },
        [RestaurantSlug.Maynard]: {
            cssClass: 'maynard',
            pageVersion: RestaurantStyle.Classic,
            color: {
                primary: '#000',
                secondary: '#FFFFFF'
            },
            styles: {
                page: {
                    menu: {
                        button: {
                            color: '#000000',
                            disabledColor: '#CBCBCB',
                            iconColor: 'white'
                        },
                        item: {
                            name: {
                                font: 'antique-olive-nord, sans-serif',
                                fontSize: '1rem',
                                fontWeight: '400'
                            },
                            description: {
                                font: 'Montserrat, sans-serif',
                                fontWeight: '500',
                                color: '#191415'
                            },
                            price: {
                                font: 'Montserrat, sans-serif',
                                fontSize: '1rem',
                                fontWeight: '500',
                            }
                        },
                        style: MenuStyle.Standard,
                        selectable: false
                    },
                    reviews: {
                        icon: {
                            color: '#000000'
                        },
                        newReviewButton: {
                            backgroundColor: '#000000',
                            fontColor: '#FFFFFF',
                            disabled: {
                                backgroundColor: '#96D2EC',
                                fontColor: '#CBCBCB',
                            }
                        },
                    },
                    frontPage: {
                        fontSize: '20px',
                        hoursDropdown: {
                            icon: {
                                size: '1.2rem'
                            }
                        }
                    }
                },
                map: {
                    google: [
                        {
                            elementType: 'geometry',
                            stylers: [{ color: '#f7f7f7' }]
                        },
                        {
                            featureType: 'road',
                            elementType: 'geometry',
                            stylers: [{ color: '#96D2EC' }]
                        },
                        {
                            featureType: 'poi.business',
                            stylers: [{ visibility: 'off' }]
                        },
                        {
                            featureType: 'water',
                            elementType: 'geometry',
                            stylers: [{ color: '#D8D9D4' }]
                        }
                    ]
                },
                common: {
                    borderRadius: ''
                },
                orderHereAnimation: {
                    color: Color.White,
                    screen: {
                        full: {
                            width: '',
                            bottom: '',
                            right: ''
                        }
                    }
                }
            }
        },
        [RestaurantSlug.MFourBurritos]: {
            cssClass: 'm4-burritos-concordia',
            pageVersion: RestaurantStyle.Classic,
            color: {
                primary: '#EC661C',
                secondary: '#F5F5F5'
            },
            styles: {
                page: {
                    menu: {
                        button: {
                            color: '#2A2A2A',
                            disabledColor: '#CBCBCB',
                            iconColor: '#F5F5F5'
                        },
                        item: {
                            name: {
                                font: 'Montserrat, sans-serif',
                                fontSize: '1rem',
                                fontWeight: '400'
                            },
                            description: {
                                font: 'Montserrat, sans-serif',
                                fontWeight: '500',
                                color: '#2A2A2A'
                            },
                            price: {
                                font: 'Montserrat, sans-serif',
                                fontSize: '1rem',
                                fontWeight: '500',
                            }
                        },
                        style: MenuStyle.Standard,
                        selectable: false
                    },
                    reviews: {
                        icon: {
                            color: '#2A2A2A'
                        },
                        newReviewButton: {
                            backgroundColor: '#2A2A2A',
                            fontColor: '#F5F5F5',
                            disabled: {
                                backgroundColor: '#CBCBCB',
                                fontColor: '#CBCBCB',
                            }
                        },
                    },
                    frontPage: {
                        fontSize: '20px',
                        hoursDropdown: {
                            icon: {
                                size: '1.2rem'
                            }
                        }
                    }
                },
                map: {
                    google: [
                        {
                            elementType: 'geometry',
                            stylers: [{ color: '#f7f7f7' }]
                        },
                        {
                            featureType: 'road',
                            elementType: 'geometry',
                            stylers: [{ color: '#EC661C' }]
                        },
                        {
                            featureType: 'poi.business',
                            stylers: [{ visibility: 'off' }]
                        },
                        {
                            featureType: 'water',
                            elementType: 'geometry',
                            stylers: [{ color: '#D8D9D4' }]
                        }
                    ]
                },
                common: {
                    borderRadius: ''
                },
                orderHereAnimation: {
                    color: Color.White,
                    screen: {
                        full: {
                            width: '',
                            bottom: '',
                            right: ''
                        }
                    }
                }
            }
        },
        [RestaurantSlug.Bocadillo]: {
            cssClass: 'bocadillo',
            pageVersion: RestaurantStyle.Classic,
            color: {
                primary: '#FFC429',
                secondary: 'white'
            },
            styles: {
                page: {
                    menu: {
                        button: {
                            color: '#FFC429',
                            disabledColor: '#CBCBCB',
                            iconColor: '#000000'
                        },
                        item: {
                            name: {
                                font: 'Source Sans Pro, sans-serif',
                                fontSize: '1.1rem',
                                fontWeight: '400'
                            },
                            description: {
                                font: 'Source Sans Pro, sans-serif',
                                fontWeight: '500',
                                fontSize: '1rem',
                                color: 'white'
                            },
                            price: {
                                font: 'Source Sans Pro, sans-serif',
                                fontSize: '1.1rem',
                                fontWeight: '500',
                            },
                            style: MenuItemStyle.HorizontalWithOptionalImage
                        },
                        style: MenuStyle.Standard,
                        selectable: false
                    },
                    reviews: {
                        icon: {
                            color: '#FFC429'
                        },
                        newReviewButton: {
                            backgroundColor: '#E41D39',
                            fontColor: 'white',
                            disabled: {
                                backgroundColor: '#CBCBCB',
                                fontColor: 'black',
                            }
                        },
                    },
                    frontPage: {
                        fontSize: '20px',
                        hoursDropdown: {
                            icon: {
                                size: '1.2rem'
                            }
                        }
                    }
                },
                map: {
                    google: [
                        {
                            elementType: 'geometry',
                            stylers: [{ color: '#f7f7f7' }]
                        },
                        {
                            featureType: 'road',
                            elementType: 'geometry',
                            stylers: [{ color: '#FFC429' }]
                        },
                        {
                            featureType: 'poi.business',
                            stylers: [{ visibility: 'off' }]
                        },
                        {
                            featureType: 'water',
                            elementType: 'geometry',
                            stylers: [{ color: '#174D9E' }]
                        }
                    ]
                },
                common: {
                    borderRadius: ''
                },
                orderHereAnimation: {
                    color: Color.White,
                    screen: {
                        full: {
                            width: '',
                            bottom: '',
                            right: ''
                        }
                    }
                }
            }
        },
        [RestaurantSlug.LaurierBBQ]: {
            cssClass: 'laurier-bbq',
            pageVersion: RestaurantStyle.Classic,
            color: {
                primary: '#04332D',
                secondary: 'white'
            },
            styles: {
                page: {
                    menu: {
                        button: {
                            color: '#04332D',
                            disabledColor: '#CBCBCB',
                            iconColor: '#F5F5F5'
                        },
                        item: {
                            name: {
                                font: 'EB Garamond',
                                fontSize: '1.2rem',
                                fontWeight: '500'
                            },
                            description: {
                                font: 'EB Garamond',
                                fontWeight: '500',
                                fontSize: '1rem',
                                color: '#04332D'
                            },
                            price: {
                                font: 'EB Garamond',
                                fontSize: '1.2rem',
                                fontWeight: '500',
                            },
                            style: MenuItemStyle.HorizontalWithOptionalImage
                        },
                        style: MenuStyle.Standard,
                        selectable: false
                    },
                    reviews: {
                        icon: {
                            color: '#04332D'
                        },
                        newReviewButton: {
                            backgroundColor: '#04332D',
                            fontColor: 'white',
                            disabled: {
                                backgroundColor: '#CBCBCB',
                                fontColor: 'black',
                            }
                        },
                    },
                    frontPage: {
                        fontSize: '20px',
                        hoursDropdown: {
                            icon: {
                                size: '1.2rem'
                            }
                        }
                    }
                },
                map: {
                    google: [
                        {
                            elementType: 'geometry',
                            stylers: [{ color: '#f7f7f7' }]
                        },
                        {
                            featureType: 'road',
                            elementType: 'geometry',
                            stylers: [{ color: '#799c97' }]
                        },
                        {
                            featureType: 'poi.business',
                            stylers: [{ visibility: 'off' }]
                        },
                        {
                            featureType: 'water',
                            elementType: 'geometry',
                            stylers: [{ color: '#174D9E' }]
                        }
                    ]
                },
                common: {
                    borderRadius: ''
                },
                orderHereAnimation: {
                    color: Color.Black,
                    screen: {
                        full: {
                            width: '',
                            bottom: '',
                            right: ''
                        }
                    }
                }
            }
        },
        [RestaurantSlug.oFish]: {
            cssClass: 'ofish',
            pageVersion: RestaurantStyle.Classic,
            color: {
                primary: '#ED8F16',
                secondary: 'white'
            },
            styles: {
                page: {
                    menu: {
                        button: {
                            color: '#ED8F16',
                            disabledColor: '#CBCBCB',
                            iconColor: '#2a2a2a'
                        },
                        item: {
                            name: {
                                font: 'Montserrat',
                                fontSize: '1rem',
                                fontWeight: '500',
                                color: '#ED8F16'
                            },
                            description: {
                                font: 'Montserrat',
                                fontWeight: '400',
                                color: 'white'
                            },
                            price: {
                                font: 'Montserrat',
                                fontSize: '1rem',
                                fontWeight: '500',
                                color: 'white'
                            },
                            style: MenuItemStyle.HorizontalWithOptionalImage
                        },
                        style: MenuStyle.Standard,
                        selectable: false
                    },
                    reviews: {
                        icon: {
                            color: '#ED8F16'
                        },
                        newReviewButton: {
                            backgroundColor: '#ED8F16',
                            fontColor: 'white',
                            disabled: {
                                backgroundColor: '#CBCBCB',
                                fontColor: 'black',
                            }
                        },
                    },
                    frontPage: {
                        fontSize: '20px',
                        hoursDropdown: {
                            icon: {
                                size: '1.2rem'
                            }
                        }
                    }
                },
                map: {
                    google: [
                        {
                            elementType: 'geometry',
                            stylers: [{ color: '#f7f7f7' }]
                        },
                        {
                            featureType: 'road',
                            elementType: 'geometry',
                            stylers: [{ color: '#fccf95' }]
                        },
                        {
                            featureType: 'poi.business',
                            stylers: [{ visibility: 'off' }]
                        },
                        {
                            featureType: 'water',
                            elementType: 'geometry',
                            stylers: [{ color: '#174D9E' }]
                        }
                    ]
                },
                common: {
                    borderRadius: ''
                },
                orderHereAnimation: {
                    color: Color.Black,
                    screen: {
                        full: {
                            width: '',
                            bottom: '',
                            right: ''
                        }
                    }
                }
            }
        },
        [RestaurantSlug.SushiSama]: {
            cssClass: 'sushi-sama-concordia',
            pageVersion: RestaurantStyle.Classic,
            color: {
                primary: 'white',
                secondary: '#F37338'
            },
            styles: {
                page: {
                    menu: {
                        button: {
                            color: '#F37338',
                            disabledColor: '#CBCBCB',
                            iconColor: '#FFFFFF'
                        },
                        item: {
                            name: {
                                font: 'Roboto',
                                fontSize: '1.2rem',
                                fontWeight: '400'
                            },
                            description: {
                                font: 'Roboto',
                                fontWeight: '300',
                                fontSize: '0.9rem',
                                color: '#FFFFFF'
                            },
                            price: {
                                font: 'Roboto',
                                fontSize: '1.2rem',
                                fontWeight: '400',
                            },
                            style: MenuItemStyle.HorizontalWithOptionalImage
                        },
                        style: MenuStyle.Standard,
                        selectable: false
                    },
                    reviews: {
                        icon: {
                            color: '#FFFFFF'
                        },
                        newReviewButton: {
                            backgroundColor: '#F37338',
                            fontColor: 'white',
                            disabled: {
                                backgroundColor: '#CBCBCB',
                                fontColor: 'black',
                            }
                        },
                    },
                    frontPage: {
                        fontSize: '20px',
                        hoursDropdown: {
                            icon: {
                                size: '1.2rem'
                            }
                        }
                    }
                },
                map: {
                    google: [
                        {
                            elementType: 'geometry',
                            stylers: [{ color: '#f7f7f7' }]
                        },
                        {
                            featureType: 'road',
                            elementType: 'geometry',
                            stylers: [{ color: '#f5b090' }]
                        },
                        {
                            featureType: 'poi.business',
                            stylers: [{ visibility: 'off' }]
                        },
                        {
                            featureType: 'water',
                            elementType: 'geometry',
                            stylers: [{ color: '#174D9E' }]
                        }
                    ]
                },
                common: {
                    borderRadius: ''
                },
                orderHereAnimation: {
                    color: Color.White,
                    screen: {
                        full: {
                            width: '',
                            bottom: '',
                            right: ''
                        }
                    }
                }
            }
        },
        [RestaurantSlug.PaniniStop]: {
            cssClass: 'panini-stop',
            pageVersion: RestaurantStyle.Classic,
            color: {
                primary: '#E91C23;',
                secondary: '#F5F5F5'
            },
            styles: {
                page: {
                    menu: {
                        button: {
                            color: '#E91C23',
                            disabledColor: '#CBCBCB',
                            iconColor: '#F5F5F5'
                        },
                        item: {
                            name: {
                                font: 'Oswald',
                                fontSize: '1.1rem',
                                fontWeight: '500'
                            },
                            description: {
                                font: 'Oswald',
                                fontWeight: '400',
                                fontSize: '1rem',
                                color: '#0A0406'
                            },
                            price: {
                                font: 'Oswald',
                                fontSize: '1.1rem',
                                fontWeight: '500'
                            },
                            style: MenuItemStyle.HorizontalWithOptionalImage
                        },
                        style: MenuStyle.Standard,
                        selectable: false
                    },
                    reviews: {
                        icon: {
                            color: '#E91C23'
                        },
                        newReviewButton: {
                            backgroundColor: '#E91C23',
                            fontColor: '#F5F5F5',
                            disabled: {
                                backgroundColor: '#CBCBCB',
                                fontColor: '#CBCBCB',
                            }
                        },
                    },
                    frontPage: {
                        fontSize: '20px',
                        hoursDropdown: {
                            icon: {
                                size: '1.2rem'
                            }
                        }
                    }
                },
                map: {
                    google: [
                        {
                            elementType: 'geometry',
                            stylers: [{ color: '#F0F0F0' }]
                        },
                        {
                            featureType: 'road',
                            elementType: 'geometry',
                            stylers: [{ color: '#f7f7f7' }]
                        },
                        {
                            featureType: 'poi.business',
                            stylers: [{ visibility: 'off' }]
                        },
                        {
                            featureType: 'water',
                            elementType: 'geometry',
                            stylers: [{ color: '#4C7194' }]
                        }
                    ]
                },
                common: {
                    borderRadius: ''
                },
                orderHereAnimation: {
                    color: Color.Black,
                    screen: {
                        full: {
                            width: '',
                            bottom: '',
                            right: ''
                        }
                    }
                }
            }
        },
        [RestaurantSlug.PokeStationCdn]: {
            cssClass: 'poke-station-cdn',
            pageVersion: RestaurantStyle.Classic,
            color: {
                primary: '#EF7E8A',
                secondary: '#F37338'
            },
            styles: {
                page: {
                    menu: {
                        button: {
                            color: '#EF7E8A',
                            disabledColor: '#CBCBCB',
                            iconColor: '#FFFFFF'
                        },
                        item: {
                            name: {
                                font: 'Roboto',
                                fontSize: '1.2rem',
                                fontWeight: '400'
                            },
                            description: {
                                font: 'Roboto',
                                fontWeight: '300',
                                fontSize: '0.9rem',
                                color: '#333333'
                            },
                            price: {
                                font: 'Roboto',
                                fontSize: '1.2rem',
                                fontWeight: '400',
                            },
                            style: MenuItemStyle.HorizontalWithOptionalImage
                        },
                        style: MenuStyle.Standard,
                        selectable: false
                    },
                    reviews: {
                        icon: {
                            color: '#EF7E8A'
                        },
                        newReviewButton: {
                            backgroundColor: '#EF7E8A',
                            fontColor: 'white',
                            disabled: {
                                backgroundColor: '#CBCBCB',
                                fontColor: 'black',
                            }
                        },
                    },
                    frontPage: {
                        fontSize: '20px',
                        hoursDropdown: {
                            icon: {
                                size: '1.2rem'
                            }
                        }
                    }
                },
                map: {
                    google: [
                        {
                            elementType: 'geometry',
                            stylers: [{ color: '#f7f7f7' }]
                        },
                        {
                            featureType: 'road',
                            elementType: 'geometry',
                            stylers: [{ color: '#f8c4c9' }]
                        },
                        {
                            featureType: 'poi.business',
                            stylers: [{ visibility: 'off' }]
                        },
                        {
                            featureType: 'water',
                            elementType: 'geometry',
                            stylers: [{ color: '#00ADD8' }]
                        }
                    ]
                },
                common: {
                    borderRadius: ''
                },
                orderHereAnimation: {
                    color: Color.White,
                    screen: {
                        full: {
                            width: '',
                            bottom: '',
                            right: ''
                        }
                    }
                }
            }
        },
        [RestaurantSlug.TeochewFoodie]: {
            cssClass: 'teochew-foodie',
            pageVersion: RestaurantStyle.Classic,
            color: {
                primary: '#e03c31',
                secondary: 'white'
            },
            styles: {
                page: {
                    menu: {
                        button: {
                            color: '#e03c31',
                            disabledColor: '#CBCBCB',
                            iconColor: '#F5F5F5'
                        },
                        item: {
                            name: {
                                font: 'Averia Serif Libre, sans-serif',
                                fontSize: '1.2rem',
                                fontWeight: '500'
                            },
                            description: {
                                font: 'Averia Serif Libre, sans-serif',
                                fontWeight: '500',
                                fontSize: '1rem'
                            },
                            price: {
                                font: 'Averia Serif Libre, sans-serif',
                                fontSize: '1.2rem'
                            },
                            style: MenuItemStyle.HorizontalWithOptionalImage
                        },
                        style: MenuStyle.Standard,
                        selectable: false
                    },
                    reviews: {
                        icon: {
                            color: '#E03C31'
                        },
                        newReviewButton: {
                            backgroundColor: '#E03C31',
                            fontColor: 'white',
                            disabled: {
                                backgroundColor: '#CBCBCB',
                                fontColor: 'black',
                            },
                        },
                    },
                    frontPage: {
                        fontSize: '20px',
                        hoursDropdown: {
                            icon: {
                                size: '1.2rem'
                            }
                        }
                    }
                },
                map: {
                    google: [
                        {
                            elementType: 'geometry',
                            stylers: [{ color: '#f7f7f7' }]
                        },
                        {
                            featureType: 'road',
                            elementType: 'geometry',
                            stylers: [{ color: '#ffbcb8' }]
                        },
                        {
                            featureType: 'poi.business',
                            stylers: [{ visibility: 'off' }]
                        },
                        {
                            featureType: 'water',
                            elementType: 'geometry',
                            stylers: [{ color: '#174D9E' }]
                        }
                    ]
                },
                common: {
                    borderRadius: ''
                },
                orderHereAnimation: {
                    color: Color.Black,
                    screen: {
                        full: {
                            width: '',
                            bottom: '',
                            right: ''
                        }
                    }
                }
            }
        },
        [RestaurantSlug.Tapigo]: {
            cssClass: 'tapi-go',
            pageVersion: RestaurantStyle.Classic,
            color: {
                primary: '#BA4834',
                secondary: '#F5F5F5'
            },
            styles: {
                page: {
                    menu: {
                        button: {
                            color: '#BA4834',
                            disabledColor: '#CBCBCB',
                            iconColor: '#F5F5F5'
                        },
                        item: {
                            imageContainer: {
                                height: '14rem'
                            },
                            marginBottom: '2rem',
                            image: {
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover',
                                marginBottom: '0',
                                order: 1
                            },
                            name: {
                                font: 'Montserrat',
                                fontSize: '1.2rem',
                                fontWeight: '700',
                                marginBottom: '0rem',
                                marginTop: '0.5rem',
                                textTransform: 'uppercase',
                                order: 2
                            },
                            description: {
                                font: 'Montserrat',
                                fontWeight: '',
                                color: '',
                                marginBottom: '',
                                order: 3
                            },
                            price: {
                                font: 'Montserrat',
                                fontSize: '1rem',
                                fontWeight: '400',
                            },
                            style: MenuItemStyle.Image
                        },
                        style: MenuStyle.SingleColumn,
                        selectable: false
                    },
                    reviews: {
                        icon: {
                            color: '#BA4834'
                        },
                        newReviewButton: {
                            backgroundColor: '#BA4834',
                            fontColor: '#F5F5F5',
                            disabled: {
                                backgroundColor: '#CBCBCB',
                                fontColor: '#CBCBCB',
                            }
                        },
                    },
                    frontPage: {
                        fontSize: '20px',
                        hoursDropdown: {
                            icon: {
                                size: '1.2rem'
                            }
                        }
                    }
                },
                map: {
                    google: [
                        {
                            elementType: 'geometry',
                            stylers: [{ color: '#F0F0F0' }]
                        },
                        {
                            featureType: 'road',
                            elementType: 'geometry',
                            stylers: [{ color: '#f0c2b7' }]
                        },
                        {
                            featureType: 'poi.business',
                            stylers: [{ visibility: 'off' }]
                        },
                        {
                            featureType: 'water',
                            elementType: 'geometry',
                            stylers: [{ color: '#4C7194' }]
                        }
                    ]
                },
                common: {
                    borderRadius: ''
                },
                orderHereAnimation: {
                    color: Color.Black,
                    screen: {
                        full: {
                            width: '',
                            bottom: '',
                            right: ''
                        }
                    }
                }
            }
        },
        [RestaurantSlug.BrigadePizza]: {
            cssClass: 'brigade-pizza',
            pageVersion: RestaurantStyle.Classic,
            color: {
                primary: '#04332D',
                secondary: 'white'
            },
            styles: {
                page: {
                    menu: {
                        button: {
                            color: '#F53229',
                            disabledColor: '#CBCBCB',
                            iconColor: '#F5F5F5'
                        },
                        item: {
                            name: {
                                font: 'Lato',
                                fontSize: '1.2rem',
                                fontWeight: '500'
                            },
                            description: {
                                font: 'Lato',
                                fontWeight: '500',
                                fontSize: '1rem'
                            },
                            price: {
                                font: 'Lato',
                                fontSize: '1.2rem',
                                fontWeight: '500'
                            },
                            style: MenuItemStyle.HorizontalWithOptionalImage
                        },
                        style: MenuStyle.Standard,
                        selectable: false
                    },
                    reviews: {
                        icon: {
                            color: '#F53229'
                        },
                        newReviewButton: {
                            backgroundColor: '#F53229',
                            fontColor: 'white',
                            disabled: {
                                backgroundColor: '#CBCBCB',
                                fontColor: 'black',
                            }
                        },
                    },
                    frontPage: {
                        fontSize: '20px',
                        hoursDropdown: {
                            icon: {
                                size: '1.2rem'
                            }
                        }
                    }
                },
                map: {
                    google: [
                        {
                            elementType: 'geometry',
                            stylers: [{ color: '#f7f7f7' }]
                        },
                        {
                            featureType: 'road',
                            elementType: 'geometry',
                            stylers: [{ color: '#2C402F' }]
                        },
                        {
                            featureType: 'poi.business',
                            stylers: [{ visibility: 'off' }]
                        },
                        {
                            featureType: 'water',
                            elementType: 'geometry',
                            stylers: [{ color: '#174D9E' }]
                        }
                    ]
                },
                common: {
                    borderRadius: ''
                },
                orderHereAnimation: {
                    color: Color.Black,
                    screen: {
                        full: {
                            width: '',
                            bottom: '',
                            right: ''
                        }
                    }
                }
            }
        },
        [RestaurantSlug.AlAmine]: {
            cssClass: 'al-amine',
            pageVersion: RestaurantStyle.Classic,
            color: {
                primary: '#AA2E30',
                secondary: '#F37338'
            },
            styles: {
                page: {
                    menu: {
                        button: {
                            color: '#AA2E30',
                            disabledColor: '#CBCBCB',
                            iconColor: '#FFFFFF'
                        },
                        item: {
                            name: {
                                font: 'Slabo',
                                fontSize: '1.2rem',
                                fontWeight: '400'
                            },
                            description: {
                                font: 'Slabo',
                                fontWeight: '300',
                                fontSize: '0.9rem'
                            },
                            price: {
                                font: 'Slabo',
                                fontSize: '1.2rem',
                                fontWeight: '400'
                            },
                            style: MenuItemStyle.HorizontalWithOptionalImage
                        },
                        style: MenuStyle.Standard,
                        selectable: false
                    },
                    reviews: {
                        icon: {
                            color: '#AA2E30'
                        },
                        newReviewButton: {
                            backgroundColor: '#AA2E30',
                            fontColor: 'white',
                            disabled: {
                                backgroundColor: '#CBCBCB',
                                fontColor: 'black',
                            }
                        },
                    },
                    frontPage: {
                        fontSize: '20px',
                        hoursDropdown: {
                            icon: {
                                size: '1.2rem'
                            }
                        }
                    }
                },
                map: {
                    google: [
                        {
                            elementType: 'geometry',
                            stylers: [{ color: '#f7f7f7' }]
                        },
                        {
                            featureType: 'road',
                            elementType: 'geometry',
                            stylers: [{ color: '#f5b090' }]
                        },
                        {
                            featureType: 'poi.business',
                            stylers: [{ visibility: 'off' }]
                        },
                        {
                            featureType: 'water',
                            elementType: 'geometry',
                            stylers: [{ color: '#174D9E' }]
                        }
                    ]
                },
                common: {
                    borderRadius: ''
                },
                orderHereAnimation: {
                    color: Color.White,
                    screen: {
                        full: {
                            width: '',
                            bottom: '',
                            right: ''
                        }
                    }
                }
            }
        },
        [RestaurantSlug.BernieBeigne]: {
            cssClass: 'bernie-beigne',
            pageVersion: RestaurantStyle.Classic,
            color: {
                primary: '#C2EAF6',
                secondary: '#F37338'
            },
            styles: {
                page: {
                    menu: {
                        button: {
                            color: '#000000',
                            disabledColor: '#CBCBCB',
                            iconColor: '#FFFFFF'
                        },
                        item: {
                            name: {
                                font: 'Montserrat',
                                fontSize: '1.2rem',
                                fontWeight: '400'
                            },
                            description: {
                                font: 'Montserrat',
                                fontWeight: '300',
                                fontSize: '0.9rem'
                            },
                            price: {
                                font: 'Montserrat',
                                fontSize: '1.2rem',
                                fontWeight: '400'
                            },
                            style: MenuItemStyle.HorizontalWithOptionalImage
                        },
                        style: MenuStyle.SingleColumn,
                        selectable: false
                    },
                    reviews: {
                        icon: {
                            color: '#F5AAC9'
                        },
                        newReviewButton: {
                            backgroundColor: '#F5AAC9',
                            fontColor: 'white',
                            disabled: {
                                backgroundColor: '#CBCBCB',
                                fontColor: 'black',
                            }
                        },
                    },
                    frontPage: {
                        fontSize: '20px',
                        hoursDropdown: {
                            icon: {
                                size: '1.2rem'
                            }
                        }
                    }
                },
                map: {
                    google: [
                        {
                            elementType: 'geometry',
                            stylers: [{ color: '#f7f7f7' }]
                        },
                        {
                            featureType: 'road',
                            elementType: 'geometry',
                            stylers: [{ color: '#f2c4d7' }]
                        },
                        {
                            featureType: 'poi.business',
                            stylers: [{ visibility: 'off' }]
                        },
                        {
                            featureType: 'water',
                            elementType: 'geometry',
                            stylers: [{ color: '#174D9E' }]
                        }
                    ]
                },
                common: {
                    borderRadius: ''
                },
                orderHereAnimation: {
                    color: Color.Black,
                    screen: {
                        full: {
                            width: '',
                            bottom: '',
                            right: ''
                        }
                    }
                }
            }
        },
        [RestaurantSlug.Cacao70]: {
            cssClass: 'cacao-70',
            pageVersion: RestaurantStyle.Classic,
            color: {
                primary: '#093266',
                secondary: '#F37338'
            },
            styles: {
                page: {
                    menu: {
                        button: {
                            color: '#F37136',
                            disabledColor: '#CBCBCB',
                            iconColor: '#FFFFFF'
                        },
                        item: {
                            name: {
                                font: 'Raleway',
                                fontSize: '1.2rem',
                                fontWeight: '400'
                            },
                            description: {
                                font: 'Raleway',
                                fontWeight: '300',
                                fontSize: '0.9rem'
                            },
                            price: {
                                font: 'Raleway',
                                fontSize: '1.2rem',
                                fontWeight: '400'
                            },
                            style: MenuItemStyle.HorizontalWithOptionalImage
                        },
                        style: MenuStyle.Standard,
                        selectable: false
                    },
                    reviews: {
                        icon: {
                            color: '#F37136'
                        },
                        newReviewButton: {
                            backgroundColor: '#F37136',
                            fontColor: 'white',
                            disabled: {
                                backgroundColor: '#CBCBCB',
                                fontColor: 'black',
                            }
                        },
                    },
                    frontPage: {
                        fontSize: '20px',
                        hoursDropdown: {
                            icon: {
                                size: '1.2rem'
                            }
                        }
                    }
                },
                map: {
                    google: [
                        {
                            elementType: 'geometry',
                            stylers: [{ color: '#f7f7f7' }]
                        },
                        {
                            featureType: 'road',
                            elementType: 'geometry',
                            stylers: [{ color: '#ffc0a3' }]
                        },
                        {
                            featureType: 'poi.business',
                            stylers: [{ visibility: 'off' }]
                        },
                        {
                            featureType: 'water',
                            elementType: 'geometry',
                            stylers: [{ color: '#174D9E' }]
                        }
                    ]
                },
                common: {
                    borderRadius: ''
                },
                orderHereAnimation: {
                    color: Color.White,
                    screen: {
                        full: {
                            width: '',
                            bottom: '',
                            right: ''
                        }
                    }
                }
            }
        },
        [RestaurantSlug.TacosNation]: {
            cssClass: 'tacos-nation',
            pageVersion: RestaurantStyle.Classic,
            color: {
                primary: '#0C0608',
                secondary: '#F37338'
            },
            styles: {
                page: {
                    menu: {
                        button: {
                            color: '#0C0608',
                            disabledColor: '#CBCBCB',
                            iconColor: '#FFFFFF'
                        },
                        item: {
                            name: {
                                font: 'Roboto',
                                fontSize: '1.2rem',
                                fontWeight: '400'
                            },
                            description: {
                                font: 'Roboto',
                                fontWeight: '300',
                                fontSize: '0.9rem'
                            },
                            price: {
                                font: 'Roboto',
                                fontSize: '1.2rem',
                                fontWeight: '400'
                            },
                            style: MenuItemStyle.HorizontalWithOptionalImage
                        },
                        style: MenuStyle.Standard,
                        selectable: false
                    },
                    reviews: {
                        icon: {
                            color: '#0C0608'
                        },
                        newReviewButton: {
                            backgroundColor: '#0C0608',
                            fontColor: 'white',
                            disabled: {
                                backgroundColor: '#0C0608',
                                fontColor: 'black',
                            }
                        },
                    },
                    frontPage: {
                        fontSize: '20px',
                        hoursDropdown: {
                            icon: {
                                size: '1.2rem'
                            }
                        }
                    }
                },
                map: {
                    google: [
                        {
                            elementType: 'geometry',
                            stylers: [{ color: '#f7f7f7' }]
                        },
                        {
                            featureType: 'road',
                            elementType: 'geometry',
                            stylers: [{ color: '#FFDB9B' }]
                        },
                        {
                            featureType: 'poi.business',
                            stylers: [{ visibility: 'off' }]
                        },
                        {
                            featureType: 'water',
                            elementType: 'geometry',
                            stylers: [{ color: '#174D9E' }]
                        }
                    ]
                },
                common: {
                    borderRadius: ''
                },
                orderHereAnimation: {
                    color: Color.White,
                    screen: {
                        full: {
                            width: '',
                            bottom: '',
                            right: ''
                        }
                    }
                }
            }
        },
        [RestaurantSlug.PoissonnerieSherbrooke]: {
            cssClass: 'poissonnerie-sherbrooke',
            pageVersion: RestaurantStyle.Classic,
            color: {
                primary: '#000000',
                secondary: '#E095C0'
            },
            styles: {
                page: {
                    menu: {
                        button: {
                            color: '#000000',
                            disabledColor: '#8e8d8a',
                            iconColor: '#FFFFFF'
                        },
                        item: {
                            name: {
                                font: 'Cinzel, serif',
                                fontSize: '1.1rem',
                                fontWeight: '400',
                            },
                            description: {
                                font: 'Roboto, sans-serif',
                                fontWeight: '300',
                                color: ''
                            },
                            price: {
                                font: 'Roboto, sans-serif',
                                fontSize: '1rem',
                                fontWeight: '400',
                            },
                            style: MenuItemStyle.HorizontalWithOptionalImage
                        },
                        style: MenuStyle.Standard,
                        selectable: false
                    },
                    reviews: {
                        icon: {
                            color: '#000000'
                        },
                        newReviewButton: {
                            backgroundColor: '#000000',
                            fontColor: '#FFFFFF',
                            disabled: {
                                backgroundColor: '#515151',
                                fontColor: '#c3c3c3',
                            }
                        },
                    },
                    frontPage: {
                        fontSize: '20px',
                        hoursDropdown: {
                            icon: {
                                size: '1.2rem'
                            }
                        }
                    }
                },
                map: {
                    google: [
                        {
                            elementType: 'geometry',
                            stylers: [{ color: '#f7f7f7' }]
                        },
                        {
                            featureType: 'road',
                            elementType: 'geometry',
                            stylers: [{ color: '#5BC9DE' }]
                        },
                        {
                            featureType: 'poi.business',
                            stylers: [{ visibility: 'off' }]
                        },
                        {
                            featureType: 'water',
                            elementType: 'geometry',
                            stylers: [{ color: '#D8D9D4' }]
                        }
                    ]
                },
                common: {
                    borderRadius: ''
                },
                orderHereAnimation: {
                    color: Color.White,
                    screen: {
                        full: {
                            width: '',
                            bottom: '',
                            right: ''
                        }
                    }
                }
            }
        },
        [RestaurantSlug.Mintar]: {
            cssClass: 'mintar',
            pageVersion: RestaurantStyle.Classic,
            color: {
                primary: '#1E1E1D',
                secondary: '#E095C0'
            },
            styles: {
                page: {
                    menu: {
                        button: {
                            color: '#000000',
                            disabledColor: '#8e8d8a',
                            iconColor: '#FFFFFF'
                        },
                        item: {
                            name: {
                                font: 'Oswald, serif',
                                fontSize: '1.1rem',
                                fontWeight: '400',
                            },
                            description: {
                                font: 'Montserrat, sans-serif',
                                fontWeight: '300',
                                color: ''
                            },
                            price: {
                                font: 'Montserrat, sans-serif',
                                fontSize: '1rem',
                                fontWeight: '400',
                            },
                            style: MenuItemStyle.HorizontalWithOptionalImage
                        },
                        style: MenuStyle.Standard,
                        selectable: false
                    },
                    reviews: {
                        icon: {
                            color: '#000000'
                        },
                        newReviewButton: {
                            backgroundColor: '#000000',
                            fontColor: '#FFFFFF',
                            disabled: {
                                backgroundColor: '#515151',
                                fontColor: '#c3c3c3',
                            }
                        },
                    },
                    frontPage: {
                        fontSize: '20px',
                        hoursDropdown: {
                            icon: {
                                size: '1.2rem'
                            }
                        }
                    }
                },
                map: {
                    google: [
                        {
                            elementType: 'geometry',
                            stylers: [{ color: '#f7f7f7' }]
                        },
                        {
                            featureType: 'road',
                            elementType: 'geometry',
                            stylers: [{ color: '#E8D4B9' }]
                        },
                        {
                            featureType: 'poi.business',
                            stylers: [{ visibility: 'off' }]
                        },
                        {
                            featureType: 'water',
                            elementType: 'geometry',
                            stylers: [{ color: '#174D9E' }]
                        }
                    ]
                },
                common: {
                    borderRadius: ''
                },
                orderHereAnimation: {
                    color: Color.Black,
                    screen: {
                        full: {
                            width: '',
                            bottom: '',
                            right: ''
                        }
                    }
                }
            }
        },
        [RestaurantSlug.AuxMerveilleuxDeFred]: {
            cssClass: 'aux-merveilleux-de-fred',
            pageVersion: RestaurantStyle.Classic,
            color: {
                primary: '#000000',
                secondary: '#E095C0'
            },
            styles: {
                page: {
                    menu: {
                        button: {
                            color: '#000000',
                            disabledColor: '#8e8d8a',
                            iconColor: '#FFFFFF'
                        },
                        item: {
                            name: {
                                font: 'Great Vibes, cursive',
                                fontSize: '1.2rem',
                                fontWeight: '400',
                            },
                            description: {
                                font: 'Poppins, sans-serif',
                                fontWeight: '300',
                                color: ''
                            },
                            price: {
                                font: 'Poppins, sans-serif',
                                fontSize: '1rem',
                                fontWeight: '400',
                            },
                            style: MenuItemStyle.HorizontalWithOptionalImage
                        },
                        style: MenuStyle.Standard,
                        selectable: false
                    },
                    reviews: {
                        icon: {
                            color: '#000000'
                        },
                        newReviewButton: {
                            backgroundColor: '#000000',
                            fontColor: '#FFFFFF',
                            disabled: {
                                backgroundColor: '#515151',
                                fontColor: '#c3c3c3',
                            }
                        },
                    },
                    frontPage: {
                        fontSize: '20px',
                        hoursDropdown: {
                            icon: {
                                size: '1.2rem'
                            }
                        }
                    }
                },
                map: {
                    google: [
                        {
                            elementType: 'geometry',
                            stylers: [{ color: '#f7f7f7' }]
                        },
                        {
                            featureType: 'road',
                            elementType: 'geometry',
                            stylers: [{ color: '#E8D4B9' }]
                        },
                        {
                            featureType: 'poi.business',
                            stylers: [{ visibility: 'off' }]
                        },
                        {
                            featureType: 'water',
                            elementType: 'geometry',
                            stylers: [{ color: '#174D9E' }]
                        }
                    ]
                },
                common: {
                    borderRadius: ''
                },
                orderHereAnimation: {
                    color: Color.Black,
                    screen: {
                        full: {
                            width: '',
                            bottom: '',
                            right: ''
                        }
                    }
                }
            }
        },
        [RestaurantSlug.LaToile]: {
            cssClass: 'la-toile',
            pageVersion: RestaurantStyle.Classic,
            color: {
                primary: '#000000',
                secondary: '#E095C0'
            },
            styles: {
                page: {
                    menu: {
                        button: {
                            color: '#000000',
                            disabledColor: '#8e8d8a',
                            iconColor: '#FFFFFF'
                        },
                        item: {
                            name: {
                                font: 'Playfair Display, serif',
                                fontSize: '1.1rem',
                                fontWeight: '400',
                            },
                            description: {
                                font: 'Roboto, sans-serif',
                                fontWeight: '300',
                                color: ''
                            },
                            price: {
                                font: 'Roboto, sans-serif',
                                fontSize: '1rem',
                                fontWeight: '400',
                            },
                            style: MenuItemStyle.HorizontalWithOptionalImage
                        },
                        style: MenuStyle.Standard,
                        selectable: false
                    },
                    reviews: {
                        icon: {
                            color: '#000000'
                        },
                        newReviewButton: {
                            backgroundColor: '#000000',
                            fontColor: '#FFFFFF',
                            disabled: {
                                backgroundColor: '#515151',
                                fontColor: '#c3c3c3',
                            }
                        },
                    },
                    frontPage: {
                        fontSize: '20px',
                        hoursDropdown: {
                            icon: {
                                size: '1.2rem'
                            }
                        }
                    }
                },
                map: {
                    google: [
                        {
                            elementType: 'geometry',
                            stylers: [{ color: '#f7f7f7' }]
                        },
                        {
                            featureType: 'road',
                            elementType: 'geometry',
                            stylers: [{ color: '#A5A5A5' }]
                        },
                        {
                            featureType: 'poi.business',
                            stylers: [{ visibility: 'off' }]
                        },
                        {
                            featureType: 'water',
                            elementType: 'geometry',
                            stylers: [{ color: '#E1EEF2' }]
                        }
                    ]
                },
                common: {
                    borderRadius: ''
                },
                orderHereAnimation: {
                    color: Color.White,
                    screen: {
                        full: {
                            width: '',
                            bottom: '',
                            right: ''
                        }
                    }
                }
            }
        },
        [RestaurantSlug.SepLai]: {
            cssClass: 'sep-lai',
            pageVersion: RestaurantStyle.Classic,
            color: {
                primary: '#222222',
                secondary: '#CF5434'
            },
            styles: {
                page: {
                    menu: {
                        button: {
                            color: '#CF5434',
                            disabledColor: '#CBCBCB',
                            iconColor: '#FFFFFF'
                        },
                        item: {
                            name: {
                                font: 'Raleway, sans-serif',
                                fontSize: '1.2rem',
                                fontWeight: '400'
                            },
                            description: {
                                font: 'Cabin, sans-serif',
                                fontWeight: '400',
                                fontSize: '0.9rem'
                            },
                            price: {
                                font: 'Cabin, sans-serif',
                                fontSize: '1.2rem',
                                fontWeight: '400'
                            },
                            style: MenuItemStyle.HorizontalWithOptionalImage
                        },
                        style: MenuStyle.Standard,
                        selectable: false
                    },
                    reviews: {
                        icon: {
                            color: '#CF5434'
                        },
                        newReviewButton: {
                            backgroundColor: '#CF5434',
                            fontColor: '#FFFFFF',
                            disabled: {
                                backgroundColor: '#0C0608',
                                fontColor: 'black',
                            }
                        },
                    },
                    frontPage: {
                        fontSize: '20px',
                        hoursDropdown: {
                            icon: {
                                size: '1.2rem'
                            }
                        }
                    }
                },
                map: {
                    google: [
                        {
                            elementType: 'geometry',
                            stylers: [{ color: '#f7f7f7' }]
                        },
                        {
                            featureType: 'road',
                            elementType: 'geometry',
                            stylers: [{ color: '#FCCCC0' }]
                        },
                        {
                            featureType: 'poi.business',
                            stylers: [{ visibility: 'off' }]
                        },
                        {
                            featureType: 'water',
                            elementType: 'geometry',
                            stylers: [{ color: '#DCEBF1' }]
                        }
                    ]
                },
                common: {
                    borderRadius: ''
                },
                orderHereAnimation: {
                    color: Color.White,
                    screen: {
                        full: {
                            width: '',
                            bottom: '',
                            right: ''
                        }
                    }
                }
            }
        },
        [RestaurantSlug.BistroDeLaCite]: {
            cssClass: 'bistro-de-la-cite',
            pageVersion: RestaurantStyle.Classic,
            color: {
                primary: '#aa2e30', // rust
                secondary: '#373F41' // navy
            },
            styles: {
                page: {
                    menu: {
                        button: {
                            color: '#B0481E',
                            disabledColor: '#CBCBCB',
                            iconColor: '#FFFFFF'
                        },
                        item: {
                            name: {
                                font: '"EB Garamond", serif',
                                fontSize: '1.1rem',
                                fontWeight: '400'
                            },
                            description: {
                                font: '"PT Serif", serif',
                                fontWeight: '400',
                                fontSize: '0.8rem'
                            },
                            price: {
                                font: '"PT Serif", serif',
                                fontSize: '0.9rem',
                                fontWeight: '400'
                            },
                            style: MenuItemStyle.HorizontalWithOptionalImage
                        },
                        style: MenuStyle.Standard,
                        selectable: false
                    },
                    reviews: {
                        icon: {
                            color: '#0C0608'
                        },
                        newReviewButton: {
                            backgroundColor: '#aa2e30',
                            fontColor: 'white',
                            disabled: {
                                backgroundColor: '#0C0608',
                                fontColor: 'black',
                            }
                        },
                    },
                    frontPage: {
                        fontSize: '20px',
                        hoursDropdown: {
                            icon: {
                                size: '1.2rem'
                            }
                        }
                    }
                },
                map: {
                    google: [
                        {
                            elementType: 'geometry',
                            stylers: [{ color: '#f7f7f7' }]
                        },
                        {
                            featureType: 'road',
                            elementType: 'geometry',
                            stylers: [{ color: '#eeccc2' }]
                        },
                        {
                            featureType: 'poi.business',
                            stylers: [{ visibility: 'off' }]
                        },
                        {
                            featureType: 'water',
                            elementType: 'geometry',
                            stylers: [{ color: '#9b8080' }]
                        }
                    ]
                },
                common: {
                    borderRadius: ''
                },
                orderHereAnimation: {
                    color: Color.White,
                    screen: {
                        full: {
                            width: '',
                            bottom: '',
                            right: ''
                        }
                    }
                }
            }
        },
        [RestaurantSlug.Singhs]: {
            cssClass: 'singhs',
            pageVersion: RestaurantStyle.Classic,
            color: {
                primary: '#EAC190', // beige-ish peach
                secondary: '#3D0000' // red wine
            },
            styles: {
                page: {
                    menu: {
                        button: {
                            color: '#59CE68', // green
                            disabledColor: '#CBCBCB',
                            iconColor: '#FFFFFF'
                        },
                        item: {
                            name: {
                                font: 'Quicksand, sans-serif',
                                fontSize: '1rem',
                                fontWeight: '600'
                            },
                            description: {
                                font: 'Quicksand, sans-serif',
                                fontWeight: '400',
                                fontSize: '0.9rem'
                            },
                            price: {
                                font: 'Quicksand, sans-serif',
                                fontSize: '1rem',
                                fontWeight: '400'
                            },
                            style: MenuItemStyle.HorizontalWithOptionalImage
                        },
                        style: MenuStyle.Standard,
                        selectable: false
                    },
                    reviews: {
                        icon: {
                            color: '#0C0608'
                        },
                        newReviewButton: {
                            backgroundColor: '#35B546',
                            fontColor: 'white',
                            disabled: {
                                backgroundColor: '#0C0608',
                                fontColor: 'black',
                            }
                        },
                    },
                    frontPage: {
                        fontSize: '20px',
                        hoursDropdown: {
                            icon: {
                                size: '1.2rem'
                            }
                        }
                    }
                },
                map: {
                    google: [
                        {
                            elementType: 'geometry',
                            stylers: [{ color: '#f7f7f7' }]
                        },
                        {
                            featureType: 'road',
                            elementType: 'geometry',
                            stylers: [{ color: '#f2d398' }]
                        },
                        {
                            featureType: 'poi.business',
                            stylers: [{ visibility: 'off' }]
                        },
                        {
                            featureType: 'water',
                            elementType: 'geometry',
                            stylers: [{ color: '#9b8080' }]
                        }
                    ]
                },
                common: {
                    borderRadius: ''
                },
                orderHereAnimation: {
                    color: Color.White,
                    screen: {
                        full: {
                            width: '',
                            bottom: '',
                            right: ''
                        }
                    }
                }
            }
        },
        [RestaurantSlug.ChaiTeaLounge]: {
            cssClass: 'chai-tea-lounge',
            pageVersion: RestaurantStyle.Classic,
            color: {
                primary: '#9f41b9', // purple
                secondary: '#222' // black
            },
            styles: {
                page: {
                    menu: {
                        button: {
                            color: '#9f41b9',
                            disabledColor: '#CBCBCB',
                            iconColor: '#FFFFFF'
                        },
                        item: {
                            name: {
                                font: 'Roboto, sans-serif',
                                fontSize: '1rem',
                                fontWeight: '400'
                            },
                            description: {
                                font: 'Roboto, sans-serif',
                                fontWeight: '400',
                                fontSize: '0.9rem'
                            },
                            price: {
                                font: 'Roboto, sans-serif',
                                fontSize: '1rem',
                                fontWeight: '400'
                            },
                            style: MenuItemStyle.HorizontalWithOptionalImage
                        },
                        style: MenuStyle.Standard,
                        selectable: false
                    },
                    reviews: {
                        icon: {
                            color: '#0C0608'
                        },
                        newReviewButton: {
                            backgroundColor: '#9f41b9',
                            fontColor: 'white',
                            disabled: {
                                backgroundColor: '#CBCBCB',
                                fontColor: 'white',
                            }
                        },
                    },
                    frontPage: {
                        fontSize: '20px',
                        hoursDropdown: {
                            icon: {
                                size: '1.2rem'
                            }
                        }
                    }
                },
                map: {
                    google: [
                        {
                            elementType: 'geometry',
                            stylers: [{ color: '#f7f7f7' }]
                        },
                        {
                            featureType: 'road',
                            elementType: 'geometry',
                            stylers: [{ color: '#F2E4F5' }]
                        },
                        {
                            featureType: 'poi.business',
                            stylers: [{ visibility: 'off' }]
                        },
                        {
                            featureType: 'water',
                            elementType: 'geometry',
                            stylers: [{ color: '#41EAD4' }]
                        }
                    ]
                },
                common: {
                    borderRadius: ''
                },
                orderHereAnimation: {
                    color: Color.White,
                    screen: {
                        full: {
                            width: '',
                            bottom: '',
                            right: ''
                        }
                    }
                }
            }
        },
        [RestaurantSlug.LaCroissanterieFigaro]: {
            cssClass: 'la-croissanterie-figaro',
            pageVersion: RestaurantStyle.Classic,
            color: {
                primary: '#A73F2E', // rust
                secondary: '#050505' // black
            },
            styles: {
                page: {
                    menu: {
                        button: {
                            color: '#A73F2E',
                            disabledColor: '#CBCBCB',
                            iconColor: '#FFFFFF'
                        },
                        item: {
                            name: {
                                font: 'Roboto, sans-serif',
                                fontSize: '1rem',
                                fontWeight: '400'
                            },
                            description: {
                                font: 'Roboto, sans-serif',
                                fontWeight: '300',
                                fontSize: '0.9rem'
                            },
                            price: {
                                font: 'Roboto, sans-serif',
                                fontSize: '0.9rem',
                                fontWeight: '400'
                            },
                            style: MenuItemStyle.HorizontalWithOptionalImage
                        },
                        style: MenuStyle.Standard,
                        selectable: false
                    },
                    reviews: {
                        icon: {
                            color: '#0C0608'
                        },
                        newReviewButton: {
                            backgroundColor: '#A73F2E',
                            fontColor: 'white',
                            disabled: {
                                backgroundColor: '#0C0608',
                                fontColor: 'black',
                            }
                        },
                    },
                    frontPage: {
                        fontSize: '20px',
                        hoursDropdown: {
                            icon: {
                                size: '1.2rem'
                            }
                        }
                    }
                },
                map: {
                    google: [
                        {
                            elementType: 'geometry',
                            stylers: [{ color: '#f7f7f7' }]
                        },
                        {
                            featureType: 'road',
                            elementType: 'geometry',
                            stylers: [{ color: '#E6D9AB' }]
                        },
                        {
                            featureType: 'poi.business',
                            stylers: [{ visibility: 'off' }]
                        },
                        {
                            featureType: 'water',
                            elementType: 'geometry',
                            stylers: [{ color: '#8AE1FC' }]
                        }
                    ]
                },
                common: {
                    borderRadius: ''
                },
                orderHereAnimation: {
                    color: Color.White,
                    screen: {
                        full: {
                            width: '',
                            bottom: '',
                            right: ''
                        }
                    }
                }
            }
        },
        [RestaurantSlug.LaFabriqueDeBagel]: {
            cssClass: 'la-fabrique-de-bagel',
            pageVersion: RestaurantStyle.Classic,
            color: {
                primary: '#f2f2f2',
                secondary: '#222'
            },
            styles: {
                page: {
                    menu: {
                        button: {
                            color: '#f2f2f2',
                            disabledColor: '#CBCBCB',
                            iconColor: '#222'
                        },
                        item: {
                            name: {
                                font: 'Lato, sans-serif',
                                fontSize: '1rem',
                                fontWeight: '400'
                            },
                            description: {
                                font: 'Lato, sans-serif',
                                fontWeight: '300',
                                fontSize: '0.9rem'
                            },
                            price: {
                                font: 'Lato, sans-serif',
                                fontSize: '0.9rem',
                                fontWeight: '400'
                            },
                            style: MenuItemStyle.HorizontalWithOptionalImage
                        },
                        style: MenuStyle.Standard,
                        selectable: false
                    },
                    reviews: {
                        icon: {
                            color: '#000'
                        },
                        newReviewButton: {
                            backgroundColor: '#f2f2f2',
                            fontColor: 'black',
                            disabled: {
                                backgroundColor: '#CBCBCB',
                                fontColor: 'black',
                            }
                        },
                    },
                    frontPage: {
                        fontSize: '20px',
                        hoursDropdown: {
                            icon: {
                                size: '1.2rem'
                            }
                        }
                    }
                },
                map: {
                    google: [
                        {
                            elementType: 'geometry',
                            stylers: [{ color: '#f7f7f7' }]
                        },
                        {
                            featureType: 'road',
                            elementType: 'geometry',
                            stylers: [{ color: '#f4f5f5' }]
                        },
                        {
                            featureType: 'poi.business',
                            stylers: [{ visibility: 'off' }]
                        },
                        {
                            featureType: 'water',
                            elementType: 'geometry',
                            stylers: [{ color: '#dde0e2' }]
                        }
                    ]
                },
                common: {
                    borderRadius: ''
                },
                orderHereAnimation: {
                    color: Color.White,
                    screen: {
                        full: {
                            width: '',
                            bottom: '',
                            right: ''
                        }
                    }
                }
            }
        },
        [RestaurantSlug.StudentTastyBiryani]: {
            cssClass: 'student-tasty-biryani',
            pageVersion: RestaurantStyle.Classic,
            color: {
                primary: '#222',
                secondary: '#fc9d29'
            },
            styles: {
                page: {
                    menu: {
                        button: {
                            color: '#fc9d29',
                            disabledColor: '#CBCBCB',
                            iconColor: '#fff'
                        },
                        item: {
                            name: {
                                font: 'Poppins, sans-serif',
                                fontSize: '1rem',
                                fontWeight: '400'
                            },
                            description: {
                                font: 'Poppins, sans-serif',
                                fontWeight: '300',
                                fontSize: '0.9rem'
                            },
                            price: {
                                font: 'Poppins, sans-serif',
                                fontSize: '0.9rem',
                                fontWeight: '400'
                            },
                            style: MenuItemStyle.HorizontalWithOptionalImage
                        },
                        style: MenuStyle.Standard,
                        selectable: false
                    },
                    reviews: {
                        icon: {
                            color: '#fc9d29'
                        },
                        newReviewButton: {
                            backgroundColor: '#fc9d29',
                            fontColor: 'white',
                            disabled: {
                                backgroundColor: '#CBCBCB',
                                fontColor: 'black',
                            }
                        },
                    },
                    frontPage: {
                        fontSize: '20px',
                        hoursDropdown: {
                            icon: {
                                size: '1.2rem'
                            }
                        }
                    }
                },
                map: {
                    google: [
                        {
                            elementType: 'geometry',
                            stylers: [{ color: '#f7f7f7' }]
                        },
                        {
                            featureType: 'road',
                            elementType: 'geometry',
                            stylers: [{ color: '#f4f5f5' }]
                        },
                        {
                            featureType: 'poi.business',
                            stylers: [{ visibility: 'off' }]
                        },
                        {
                            featureType: 'water',
                            elementType: 'geometry',
                            stylers: [{ color: '#dde0e2' }]
                        }
                    ]
                },
                common: {
                    borderRadius: ''
                },
                orderHereAnimation: {
                    color: Color.White,
                    screen: {
                        full: {
                            width: '',
                            bottom: '',
                            right: ''
                        }
                    }
                }
            }
        },
        [RestaurantSlug.OsukaSushi]: {
            cssClass: 'osuka-sushi',
            pageVersion: RestaurantStyle.Classic,
            color: {
                primary: '#fef8ee',
                secondary: '#0b0b0b'
            },
            styles: {
                page: {
                    menu: {
                        button: {
                            color: '#fef8ee',
                            disabledColor: '#CBCBCB',
                            iconColor: '#0b0b0b'
                        },
                        item: {
                            name: {
                                font: 'Poppins',
                                fontSize: '1rem',
                                fontWeight: '400'
                            },
                            description: {
                                font: 'Poppins',
                                fontWeight: '300',
                                fontSize: '0.9rem'
                            },
                            price: {
                                font: 'Poppins',
                                fontSize: '0.9rem',
                                fontWeight: '400'
                            },
                            style: MenuItemStyle.HorizontalWithOptionalImage
                        },
                        style: MenuStyle.Standard,
                        selectable: false
                    },
                    reviews: {
                        icon: {
                            color: '#000'
                        },
                        newReviewButton: {
                            backgroundColor: '#fef8ee',
                            fontColor: '0b0b0b',
                            disabled: {
                                backgroundColor: '#CBCBCB',
                                fontColor: '0b0b0b',
                            }
                        },
                    },
                    frontPage: {
                        fontSize: '20px',
                        hoursDropdown: {
                            icon: {
                                size: '1.2rem'
                            }
                        }
                    }
                },
                map: {
                    google: [
                        {
                            elementType: 'geometry',
                            stylers: [{ color: '#f7f7f7' }]
                        },
                        {
                            featureType: 'road',
                            elementType: 'geometry',
                            stylers: [{ color: '#E6D9AB' }]
                        },
                        {
                            featureType: 'poi.business',
                            stylers: [{ visibility: 'off' }]
                        },
                        {
                            featureType: 'water',
                            elementType: 'geometry',
                            stylers: [{ color: '#8AE1FC' }]
                        }
                    ]
                },
                common: {
                    borderRadius: ''
                },
                orderHereAnimation: {
                    color: Color.White,
                    screen: {
                        full: {
                            width: '',
                            bottom: '',
                            right: ''
                        }
                    }
                }
            }
        },
        [RestaurantSlug.Lalis]: {
            cssClass: 'lali',
            pageVersion: RestaurantStyle.Classic,
            color: {
                primary: '#E8E7E7',
                secondary: '#0d0d0d'
            },
            styles: {
                page: {
                    menu: {
                        button: {
                            color: '#ffce35',
                            disabledColor: '#cbb46d',
                            iconColor: '#0d0d0d'
                        },
                        item: {
                            name: {
                                font: 'open sans',
                                fontSize: '1rem',
                                fontWeight: '400'
                            },
                            description: {
                                font: 'open sans',
                                fontWeight: '300',
                                fontSize: '0.9rem'
                            },
                            price: {
                                font: 'open sans',
                                fontSize: '0.9rem',
                                fontWeight: '400'
                            },
                            style: MenuItemStyle.HorizontalWithOptionalImage
                        },
                        style: MenuStyle.Standard,
                        selectable: false
                    },
                    reviews: {
                        icon: {
                            color: '#ffce35'
                        },
                        newReviewButton: {
                            backgroundColor: '#ffce35',
                            fontColor: '0d0d0d',
                            disabled: {
                                backgroundColor: '#cbb46d',
                                fontColor: '0d0d0d',
                            }
                        },
                    },
                    frontPage: {
                        fontSize: '20px',
                        hoursDropdown: {
                            icon: {
                                size: '1.2rem'
                            }
                        }
                    }
                },
                map: {
                    google: [
                        {
                            elementType: 'geometry',
                            stylers: [{ color: '#f7f7f7' }]
                        },
                        {
                            featureType: 'road',
                            elementType: 'geometry',
                            stylers: [{ color: '#FFCE35' }]
                        },
                        {
                            featureType: 'poi.business',
                            stylers: [{ visibility: 'off' }]
                        },
                        {
                            featureType: 'water',
                            elementType: 'geometry',
                            stylers: [{ color: '#8AE1FC' }]
                        }
                    ]
                },
                common: {
                    borderRadius: ''
                },
                orderHereAnimation: {
                    color: Color.White,
                    screen: {
                        full: {
                            width: '',
                            bottom: '',
                            right: ''
                        }
                    }
                }
            }
        },
        [RestaurantSlug.ChezTaMere]: {
            cssClass: 'chez-ta-mere',
            pageVersion: RestaurantStyle.Classic,
            color: {
                primary: '#ffffff',
                secondary: '#222'
            },
            styles: {
                page: {
                    menu: {
                        button: {
                            color: '#1D2A57',
                            disabledColor: '#CBCBCB',
                            iconColor: '#5BBAE7'
                        },
                        item: {
                            name: {
                                font: 'Poppins',
                                fontSize: '1rem',
                                fontWeight: '400'
                            },
                            description: {
                                font: 'Poppins',
                                fontWeight: '300',
                                fontSize: '0.9rem'
                            },
                            price: {
                                font: 'Poppins',
                                fontSize: '0.9rem',
                                fontWeight: '400'
                            },
                            style: MenuItemStyle.HorizontalWithOptionalImage
                        },
                        style: MenuStyle.Standard,
                        selectable: false
                    },
                    reviews: {
                        icon: {
                            color: '#5BBAE7'
                        },
                        newReviewButton: {
                            backgroundColor: '#5BBAE7',
                            fontColor: 'white',
                            disabled: {
                                backgroundColor: '#CBCBCB',
                                fontColor: 'black',
                            }
                        },
                    },
                    frontPage: {
                        fontSize: '20px',
                        hoursDropdown: {
                            icon: {
                                size: '1.2rem'
                            }
                        }
                    }
                },
                map: {
                    google: [
                        {
                            elementType: 'geometry',
                            stylers: [{ color: '#f7f7f7' }]
                        },
                        {
                            featureType: 'road',
                            elementType: 'geometry',
                            stylers: [{ color: '#DADADA' }]
                        },
                        {
                            featureType: 'poi.business',
                            stylers: [{ visibility: 'off' }]
                        },
                        {
                            featureType: 'water',
                            elementType: 'geometry',
                            stylers: [{ color: '#5BBAE7' }]
                        }
                    ]
                },
                common: {
                    borderRadius: ''
                },
                orderHereAnimation: {
                    color: Color.White,
                    screen: {
                        full: {
                            width: '',
                            bottom: '',
                            right: ''
                        }
                    }
                }
            }
        },
        [RestaurantSlug.Chatpata]: {
            cssClass: 'chatpata',
            pageVersion: RestaurantStyle.Classic,
            color: {
                primary: '#E84B22', // reddish-orange
                secondary: '#2E221F' // black
            },
            styles: {
                page: {
                    menu: {
                        button: {
                            color: '#E84B22',
                            disabledColor: '#8e8d8a',
                            iconColor: '#FFFFFF'
                        },
                        item: {
                            name: {
                                font: 'Public Sans, sans-serif',
                                fontSize: '1rem',
                                fontWeight: '400',
                                color: '#2E221F'
                            },
                            description: {
                                font: 'Public Sans, sans-serif',
                                fontWeight: '300',
                                fontSize: '0.9rem',
                                color: '#2E221F'
                            },
                            price: {
                                font: 'Public Sans, sans-serif',
                                fontSize: '0.9rem',
                                fontWeight: '400',
                                color: '#2E221F'
                            },
                            style: MenuItemStyle.HorizontalWithOptionalImage
                        },
                        style: MenuStyle.Standard,
                        selectable: false
                    },
                    reviews: {
                        icon: {
                            color: '#E84B22'
                        },
                        newReviewButton: {
                            backgroundColor: '#E84B22',
                            fontColor: '#f8e7e2',
                            disabled: {
                                backgroundColor: '#8e8d8a',
                                fontColor: '#FFFFFF',
                            }
                        },
                    },
                    frontPage: {
                        fontSize: '20px',
                        hoursDropdown: {
                            icon: {
                                size: '1.2rem'
                            }
                        }
                    }
                },
                map: {
                    google: [
                        {
                            elementType: 'geometry',
                            stylers: [{ color: '#CDD6D0' }]
                        },
                        {
                            featureType: 'road',
                            elementType: 'geometry',
                            stylers: [{ color: '#FF9078' }]
                        },
                        {
                            featureType: 'poi.business',
                            stylers: [{ visibility: 'off' }]
                        },
                        {
                            featureType: 'water',
                            elementType: 'geometry',
                            stylers: [{ color: '#467599' }]
                        }
                    ]
                },
                common: {
                    borderRadius: ''
                },
                orderHereAnimation: {
                    color: Color.White,
                    screen: {
                        full: {
                            width: '',
                            bottom: '',
                            right: ''
                        }
                    }
                }
            }
        },
        [RestaurantSlug.FriteAlors]: {
            cssClass: 'frite-alors',
            pageVersion: RestaurantStyle.Classic,
            color: {
                primary: '#ffffff',
                secondary: '#000000'
            },
            styles: {
                page: {
                    menu: {
                        button: {
                            color: '#EC1C24',
                            disabledColor: '#E7E0D0',
                            iconColor: '#ffffff'
                        },
                        item: {
                            name: {
                                font: 'CCMeanwhile',
                                fontSize: '1rem',
                                fontWeight: '400'
                            },
                            description: {
                                font: 'Avenir',
                                fontWeight: '300',
                                fontSize: '0.9rem'
                            },
                            price: {
                                font: 'Avenir',
                                fontSize: '0.9rem',
                                fontWeight: '400'
                            },
                            style: MenuItemStyle.HorizontalWithOptionalImage
                        },
                        style: MenuStyle.Standard,
                        selectable: false
                    },
                    reviews: {
                        icon: {
                            color: 'black'
                        },
                        newReviewButton: {
                            backgroundColor: '#ED1C24',
                            fontColor: 'white',
                            disabled: {
                                backgroundColor: '#CBCBCB',
                                fontColor: 'black',
                            }
                        },
                    },
                    frontPage: {
                        fontSize: '20px',
                        hoursDropdown: {
                            icon: {
                                size: '1.2rem'
                            }
                        }
                    }
                },
                map: {
                    google: [
                        {
                            elementType: 'geometry',
                            stylers: [{ color: '#F4F4F5' }]
                        },
                        {
                            featureType: 'road',
                            elementType: 'geometry',
                            stylers: [{ color: '#FFFFFF' }]
                        },
                        {
                            featureType: 'poi.business',
                            stylers: [{ visibility: 'off' }]
                        },
                        {
                            featureType: 'water',
                            elementType: 'geometry',
                            stylers: [{ color: '#C1D3F5' }]
                        }
                    ]
                },
                common: {
                    borderRadius: ''
                },
                orderHereAnimation: {
                    color: Color.White,
                    screen: {
                        full: {
                            width: '',
                            bottom: '',
                            right: ''
                        }
                    }
                }
            }
        },
        [RestaurantSlug.Tibum]: {
            cssClass: 'tibum',
            pageVersion: RestaurantStyle.Classic,
            color: {
                primary: '#fdfdfd',
                secondary: '#231F20'
            },
            styles: {
                page: {
                    menu: {
                        button: {
                            color: '#d2ac67',
                            disabledColor: '#D6BB8C',
                            iconColor: '#231F20'
                        },
                        item: {
                            name: {
                                font: 'Quicksand, sans-serif',
                                fontSize: '1rem',
                                fontWeight: '400'
                            },
                            description: {
                                font: 'Quicksand, sans-serif',
                                fontWeight: '300',
                                fontSize: '0.9rem'
                            },
                            price: {
                                font: 'Quicksand, sans-serif',
                                fontSize: '0.9rem',
                                fontWeight: '400'
                            },
                            style: MenuItemStyle.HorizontalWithOptionalImage
                        },
                        style: MenuStyle.Standard,
                        selectable: false
                    },
                    reviews: {
                        icon: {
                            color: '#D2AC67'
                        },
                        newReviewButton: {
                            backgroundColor: '#D2AC67',
                            fontColor: '231F20',
                            disabled: {
                                backgroundColor: '#D6BB8C',
                                fontColor: '231F20',
                            }
                        },
                    },
                    frontPage: {
                        fontSize: '20px',
                        hoursDropdown: {
                            icon: {
                                size: '1.2rem'
                            }
                        }
                    }
                },
                map: {
                    google: [
                        {
                            elementType: 'geometry',
                            stylers: [{ color: '#f7f7f7' }]
                        },
                        {
                            featureType: 'road',
                            elementType: 'geometry',
                            stylers: [{ color: '#D2AC67' }]
                        },
                        {
                            featureType: 'poi.business',
                            stylers: [{ visibility: 'off' }]
                        },
                        {
                            featureType: 'water',
                            elementType: 'geometry',
                            stylers: [{ color: '#8AE1FC' }]
                        }
                    ]
                },
                common: {
                    borderRadius: ''
                },
                orderHereAnimation: {
                    color: Color.White,
                    screen: {
                        full: {
                            width: '',
                            bottom: '',
                            right: ''
                        }
                    }
                }
            }
        },
        [RestaurantSlug.ThePerle]: {
            cssClass: 'the-perle',
            pageVersion: RestaurantStyle.Classic,
            color: {
                primary:  '#8b7244',
                secondary: '#231F20'
            },
            styles: {
                page: {
                    menu: {
                        button: {
                            color: '#8B7244',
                            disabledColor: '#BFAA83',
                            iconColor: '#231F20'
                        },
                        item: {
                            name: {
                                font: 'Noto Serif',
                                fontSize: '1rem',
                                fontWeight: '400'
                            },
                            description: {
                                font: 'Noto Serif',
                                fontWeight: '300',
                                fontSize: '0.9rem'
                            },
                            price: {
                                font: 'Noto Serif',
                                fontSize: '0.9rem',
                                fontWeight: '400'
                            },
                            style: MenuItemStyle.HorizontalWithOptionalImage
                        },
                        style: MenuStyle.Standard,
                        selectable: false
                    },
                    reviews: {
                        icon: {
                            color: '#8B7244'
                        },
                        newReviewButton: {
                            backgroundColor: '#8B7244',
                            fontColor: '231F20',
                            disabled: {
                                backgroundColor: '#BFAA83',
                                fontColor: '231F20',
                            }
                        },
                    },
                    frontPage: {
                        fontSize: '20px',
                        hoursDropdown: {
                            icon: {
                                size: '1.2rem'
                            }
                        }
                    }
                },
                map: {
                    google: [
                        {
                            elementType: 'geometry',
                            stylers: [{ color: '#f7f7f7' }]
                        },
                        {
                            featureType: 'road',
                            elementType: 'geometry',
                            stylers: [{ color: '#fdfdfd' }]
                        },
                        {
                            featureType: 'poi.business',
                            stylers: [{ visibility: 'off' }]
                        },
                        {
                            featureType: 'water',
                            elementType: 'geometry',
                            stylers: [{ color: '#8AE1FC' }]
                        }
                    ]
                },
                common: {
                    borderRadius: ''
                },
                orderHereAnimation: {
                    color: Color.White,
                    screen: {
                        full: {
                            width: '',
                            bottom: '',
                            right: ''
                        }
                    }
                }
            }
        },
        [RestaurantSlug.Gongcha]: {
            cssClass: 'gong-cha',
            pageVersion: RestaurantStyle.Classic,
            color: {
                primary: '#C21E34',
                secondary: '#C21E34'
            },
            styles: {
                page: {
                    menu: {
                        button: {
                            color: '#C21E34',
                            disabledColor: '#d16b78',
                            iconColor: '#000000'
                        },
                        item: {
                            name: {
                                font: 'Lusitana',
                                fontSize: '1rem',
                                fontWeight: '400'
                            },
                            description: {
                                font: 'Lusitana',
                                fontWeight: '300',
                                fontSize: '0.9rem'
                            },
                            price: {
                                font: 'Lusitana',
                                fontSize: '0.9rem',
                                fontWeight: '400'
                            },
                            style: MenuItemStyle.HorizontalWithOptionalImage
                        },
                        style: MenuStyle.Standard,
                        selectable: false
                    },
                    reviews: {
                        icon: {
                            color: '#C21E34'
                        },
                        newReviewButton: {
                            backgroundColor: '#C21E34',
                            fontColor: '382B21',
                            disabled: {
                                backgroundColor: '#EAE9E8',
                                fontColor: '382B21',
                            }
                        },
                    },
                    frontPage: {
                        fontSize: '20px',
                        hoursDropdown: {
                            icon: {
                                size: '1.2rem'
                            }
                        }
                    }
                },
                map: {
                    google: [
                        {
                            elementType: 'geometry',
                            stylers: [{ color: '#F1D4BE' }]
                        },
                        {
                            featureType: 'road',
                            elementType: 'geometry',
                            stylers: [{ color: '#FFF8F2' }]
                        },
                        {
                            featureType: 'poi.business',
                            stylers: [{ visibility: 'off' }]
                        },
                        {
                            featureType: 'water',
                            elementType: 'geometry',
                            stylers: [{ color: '#8AE1FC' }]
                        }
                    ]
                },
                common: {
                    borderRadius: ''
                },
                orderHereAnimation: {
                    color: Color.Black,
                    screen: {
                        full: {
                            width: '',
                            bottom: '',
                            right: ''
                        }
                    }
                }
            }
        },
        [RestaurantSlug.BuffaloBill]: {
            cssClass: 'buffalo-bill',
            pageVersion: RestaurantStyle.Classic,
            color: {
                primary: '#fdfdfd',
                secondary: '#0D1627'
            },
            styles: {
                page: {
                    menu: {
                        button: {
                            color: '#B31F16',
                            disabledColor: '#861F19',
                            iconColor: '#fdfdfd'
                        },
                        item: {
                            name: {
                                font: 'Noto Sans',
                                fontSize: '1rem',
                                fontWeight: '400'
                            },
                            description: {
                                font: 'Noto Sans',
                                fontWeight: '300',
                                fontSize: '0.9rem'
                            },
                            price: {
                                font: 'Noto Sans',
                                fontSize: '0.9rem',
                                fontWeight: '400'
                            },
                            style: MenuItemStyle.HorizontalWithOptionalImage
                        },
                        style: MenuStyle.Standard,
                        selectable: false
                    },
                    reviews: {
                        icon: {
                            color: '#B31F16'
                        },
                        newReviewButton: {
                            backgroundColor: '#B31F16',
                            fontColor: 'fdfdfd',
                            disabled: {
                                backgroundColor: '#861F19',
                                fontColor: 'fdfdfd',
                            }
                        },
                    },
                    frontPage: {
                        fontSize: '20px',
                        hoursDropdown: {
                            icon: {
                                size: '1.2rem'
                            }
                        }
                    }
                },
                map: {
                    google: [
                        {
                            elementType: 'geometry',
                            stylers: [{ color: '#f7f7f7' }]
                        },
                        {
                            featureType: 'road',
                            elementType: 'geometry',
                            stylers: [{ color: '#fdfdfd' }]
                        },
                        {
                            featureType: 'poi.business',
                            stylers: [{ visibility: 'off' }]
                        },
                        {
                            featureType: 'water',
                            elementType: 'geometry',
                            stylers: [{ color: '#8AE1FC' }]
                        }
                    ]
                },
                common: {
                    borderRadius: ''
                },
                orderHereAnimation: {
                    color: Color.White,
                    screen: {
                        full: {
                            width: '',
                            bottom: '',
                            right: ''
                        }
                    }
                }
            }
        },

        [RestaurantSlug.SouvlakiGrecPlus]: {
            cssClass: 'souvlaki-grec-plus',
            pageVersion: RestaurantStyle.Classic,
            color: {
                primary: '#0069A8',
                secondary: '#FFFFFF'
            },
            styles: {
                page: {
                    menu: {
                        button: {
                            color: '#0069A8',
                            disabledColor: '#FFFFFF',
                            iconColor: '#0069A8'
                        },
                        item: {
                            name: {
                                font: 'Oswald',
                                fontSize: '1rem',
                                fontWeight: '400'
                            },
                            description: {
                                font: 'Oswald',
                                fontWeight: '300',
                                fontSize: '0.9rem'
                            },
                            price: {
                                font: 'Oswald',
                                fontSize: '0.9rem',
                                fontWeight: '400'
                            },
                            style: MenuItemStyle.HorizontalWithOptionalImage
                        },
                        style: MenuStyle.Standard,
                        selectable: false
                    },
                    reviews: {
                        icon: {
                            color: '#0069A8'
                        },
                        newReviewButton: {
                            backgroundColor: '#0069A8',
                            fontColor: 'FFFFFF',
                            disabled: {
                                backgroundColor: '#FFFFFF',
                                fontColor: 'FFFFFF',
                            }
                        },
                    },
                    frontPage: {
                        fontSize: '20px',
                        hoursDropdown: {
                            icon: {
                                size: '1.2rem'
                            }
                        }
                    }
                },
                map: {
                    google: [
                        {
                            elementType: 'geometry',
                            stylers: [{ color: '#f7f7f7' }]
                        },
                        {
                            featureType: 'road',
                            elementType: 'geometry',
                            stylers: [{ color: '#fdfdfd' }]
                        },
                        {
                            featureType: 'poi.business',
                            stylers: [{ visibility: 'off' }]
                        },
                        {
                            featureType: 'water',
                            elementType: 'geometry',
                            stylers: [{ color: '#00BBEB' }]
                        }
                    ]
                },
                common: {
                    borderRadius: ''
                },
                orderHereAnimation: {
                    color: Color.Black,
                    screen: {
                        full: {
                            width: '',
                            bottom: '',
                            right: ''
                        }
                    }
                }
            }
        },

        [RestaurantSlug.PizzaSport]: {
            cssClass: 'pizza-sport',
            pageVersion: RestaurantStyle.Classic,
            color: {
                primary: '#FFFFFF',
                secondary: '#FFFFFF'
            },
            styles: {
                page: {
                    menu: {
                        button: {
                            color: '#D1061C',
                            disabledColor: '#FFFFFF',
                            iconColor: '#D1061C'
                        },
                        item: {
                            name: {
                                font: 'Arvo',
                                fontSize: '1rem',
                                fontWeight: '400'
                            },
                            description: {
                                font: 'Arvo',
                                fontWeight: '300',
                                fontSize: '0.9rem'
                            },
                            price: {
                                font: 'Arvo',
                                fontSize: '0.9rem',
                                fontWeight: '400'
                            },
                            style: MenuItemStyle.HorizontalWithOptionalImage
                        },
                        style: MenuStyle.Standard,
                        selectable: false
                    },
                    reviews: {
                        icon: {
                            color: '#D1061C'
                        },
                        newReviewButton: {
                            backgroundColor: '#D1061C',
                            fontColor: 'FFFFFF',
                            disabled: {
                                backgroundColor: '#FFFFFF',
                                fontColor: 'FFFFFF',
                            }
                        },
                    },
                    frontPage: {
                        fontSize: '20px',
                        hoursDropdown: {
                            icon: {
                                size: '1.2rem'
                            }
                        }
                    }
                },
                map: {
                    google: [
                        {
                            elementType: 'geometry',
                            stylers: [{ color: '#FFFFFF' }]
                        },
                        {
                            featureType: 'road',
                            elementType: 'geometry',
                            stylers: [{ color: '#F8CFD4' }]
                        },
                        {
                            featureType: 'poi.business',
                            stylers: [{ visibility: 'off' }]
                        },
                        {
                            featureType: 'water',
                            elementType: 'geometry',
                            stylers: [{ color: '#9FDDF1' }]
                        }
                    ]
                },
                common: {
                    borderRadius: ''
                },
                orderHereAnimation: {
                    color: Color.White,
                    screen: {
                        full: {
                            width: '',
                            bottom: '',
                            right: ''
                        }
                    }
                }
            }
        },

        [RestaurantSlug.Tsujiri]: {
            cssClass: 'tsujiri',
            pageVersion: RestaurantStyle.Classic,
            color: {
                primary: '#1C1B1B',
                secondary: '#EFEFEF'
            },
            styles: {
                page: {
                    menu: {
                        button: {
                            color: '#12312B',
                            disabledColor: '#667C78',
                            iconColor: '#EFEFEF'
                        },
                        item: {
                            name: {
                                font: 'Arapey',
                                fontSize: '1rem',
                                fontWeight: '400'
                            },
                            description: {
                                font: 'Arapey',
                                fontWeight: '300',
                                fontSize: '0.9rem'
                            },
                            price: {
                                font: 'Montserrat',
                                fontSize: '0.9rem',
                                fontWeight: '400'
                            },
                            style: MenuItemStyle.HorizontalWithOptionalImage
                        },
                        style: MenuStyle.Standard,
                        selectable: false
                    },
                    reviews: {
                        icon: {
                            color: '#12312B'
                        },
                        newReviewButton: {
                            backgroundColor: '#12312B',
                            fontColor: '#EFEFEF',
                            disabled: {
                                backgroundColor: '#667C78',
                                fontColor: 'EFEFEF',
                            }
                        },
                    },
                    frontPage: {
                        fontSize: '20px',
                        hoursDropdown: {
                            icon: {
                                size: '1.2rem'
                            }
                        }
                    }
                },
                map: {
                    google: [
                        {
                            elementType: 'geometry',
                            stylers: [{ color: '#FFFFFF' }]
                        },
                        {
                            featureType: 'road',
                            elementType: 'geometry',
                            stylers: [{ color: '#bcd6d1' }]
                        },
                        {
                            featureType: 'poi.business',
                            stylers: [{ visibility: 'off' }]
                        },
                        {
                            featureType: 'water',
                            elementType: 'geometry',
                            stylers: [{ color: '#9FDDF1' }]
                        }
                    ]
                },
                common: {
                    borderRadius: ''
                },
                orderHereAnimation: {
                    color: Color.White,
                    screen: {
                        full: {
                            width: '',
                            bottom: '',
                            right: ''
                        }
                    }
                }
            }
        },
        [RestaurantSlug.SpartaChicken]: {
            cssClass: 'sparta-chicken',
            pageVersion: RestaurantStyle.Classic,
            color: {
                primary: '#FF0A01',
                secondary: '#FFC95F'
            },
            styles: {
                page: {
                    menu: {
                        button: {
                            color: '#FF0A01',
                            disabledColor: '#FFABA8',
                            iconColor: '#181818'
                        },
                        item: {
                            name: {
                                font: 'Quicksand, sans-serif',
                                fontSize: '1rem',
                                fontWeight: '400'
                            },
                            description: {
                                font: 'Quicksand, sans-serif',
                                fontWeight: '300',
                                fontSize: '0.9rem'
                            },
                            price: {
                                font: 'Quicksand, sans-serif',
                                fontSize: '0.9rem',
                                fontWeight: '400'
                            },
                            style: MenuItemStyle.HorizontalWithOptionalImage
                        },
                        style: MenuStyle.Standard,
                        selectable: false
                    },
                    reviews: {
                        icon: {
                            color: '#FF0A01'
                        },
                        newReviewButton: {
                            backgroundColor: '#FF0A01',
                            fontColor: 'FFFFFF',
                            disabled: {
                                backgroundColor: '#FFFFFF',
                                fontColor: 'FFFFFF',
                            }
                        },
                    },
                    frontPage: {
                        fontSize: '20px',
                        hoursDropdown: {
                            icon: {
                                size: '1.2rem'
                            }
                        }
                    }
                },
                map: {
                    google: [
                        {
                            elementType: 'geometry',
                            stylers: [{ color: '#6D4800' }]
                        },
                        {
                            featureType: 'road',
                            elementType: 'geometry',
                            stylers: [{ color: '#FFC95F' }]
                        },
                        {
                            featureType: 'poi.business',
                            stylers: [{ visibility: 'off' }]
                        },
                        {
                            featureType: 'water',
                            elementType: 'geometry',
                            stylers: [{ color: '#9FDDF1' }]
                        }
                    ]
                },
                common: {
                    borderRadius: ''
                },
                orderHereAnimation: {
                    color: Color.White,
                    screen: {
                        full: {
                            width: '',
                            bottom: '',
                            right: ''
                        }
                    }
                }
            }
        },
        [RestaurantSlug.P23Dumplings]: {
            cssClass: 'p23-dumplings',
            pageVersion: RestaurantStyle.Classic,
            color: {
                primary: '#FEB52C',
                secondary: '#FEB52C'
            },
            styles: {
                page: {
                    menu: {
                        button: {
                            color: '#FEB52C',
                            disabledColor: '#FFD077',
                            iconColor: '#000000'
                        },
                        item: {
                            name: {
                                font: 'Signika',
                                fontSize: '1rem',
                                fontWeight: '400'
                            },
                            description: {
                                font: 'Signika',
                                fontWeight: '300',
                                fontSize: '0.9rem'
                            },
                            price: {
                                font: 'Signika',
                                fontSize: '0.9rem',
                                fontWeight: '400'
                            },
                            style: MenuItemStyle.HorizontalWithOptionalImage
                        },
                        style: MenuStyle.Standard,
                        selectable: false
                    },
                    reviews: {
                        icon: {
                            color: '#FEB52C'
                        },
                        newReviewButton: {
                            backgroundColor: '#FEB52C',
                            fontColor: 'FFFFFF',
                            disabled: {
                                backgroundColor: '#FFFFFF',
                                fontColor: 'FFFFFF',
                            }
                        },
                    },
                    frontPage: {
                        fontSize: '20px',
                        hoursDropdown: {
                            icon: {
                                size: '1.2rem'
                            }
                        }
                    }
                },
                map: {
                    google: [
                        {
                            elementType: 'geometry',
                            stylers: [{ color: '#460637' }]
                        },
                        {
                            featureType: 'road',
                            elementType: 'geometry',
                            stylers: [{ color: '#BEA6D4' }]
                        },
                        {
                            featureType: 'poi.business',
                            stylers: [{ visibility: 'off' }]
                        },
                        {
                            featureType: 'water',
                            elementType: 'geometry',
                            stylers: [{ color: '#9FDDF1' }]
                        }
                    ]
                },
                common: {
                    borderRadius: ''
                },
                orderHereAnimation: {
                    color: Color.White,
                    screen: {
                        full: {
                            width: '',
                            bottom: '',
                            right: ''
                        }
                    }
                }
            }
        },
        [RestaurantSlug.BurgzCantine]: {
            cssClass: 'burgz-cantine',
            pageVersion: RestaurantStyle.Classic,
            color: {
                primary: '#575151',
                secondary: '#F7F7F7'
            },
            styles: {
                page: {
                    menu: {
                        button: {
                            color: '#575151',
                            disabledColor: '#E2E2E2',
                            iconColor: '#F7F7F7'
                        },
                        item: {
                            name: {
                                font: 'Roboto, sans-serif',
                                fontSize: '1.1rem',
                                fontWeight: '400',
                            },
                            description: {
                                font: 'Roboto, sans-serif',
                                fontWeight: '400',
                                color: ''
                            },
                            price: {
                                font: 'Roboto, sans-serif',
                                fontSize: '1rem',
                                fontWeight: '400',
                            },
                            style: MenuItemStyle.HorizontalWithOptionalImage
                        },
                        style: MenuStyle.Standard,
                        selectable: false
                    },
                    reviews: {
                        icon: {
                            color: '#575151'
                        },
                        newReviewButton: {
                            backgroundColor: '#575151',
                            fontColor: 'F7F7F7',
                            disabled: {
                                backgroundColor: '#E2E2E2',
                                fontColor: 'F7F7F7',
                            }
                        },
                    },
                    frontPage: {
                        fontSize: '20px',
                        hoursDropdown: {
                            icon: {
                                size: '1.2rem'
                            }
                        }
                    }
                },
                map: {
                    google: [
                        {
                            elementType: 'geometry',
                            stylers: [{ color: '#E2E2E2' }]
                        },
                        {
                            featureType: 'road',
                            elementType: 'geometry',
                            stylers: [{ color: '#F7F7F7' }]
                        },
                        {
                            featureType: 'poi.business',
                            stylers: [{ visibility: 'off' }]
                        },
                        {
                            featureType: 'water',
                            elementType: 'geometry',
                            stylers: [{ color: '#9FDDF1' }]
                        }
                    ]
                },
                common: {
                    borderRadius: ''
                },
                orderHereAnimation: {
                    color: Color.White,
                    screen: {
                        full: {
                            width: '',
                            bottom: '',
                            right: ''
                        }
                    }
                }
            }
        },
        [RestaurantSlug.Goplana]: {
            cssClass: 'goplana',
            pageVersion: RestaurantStyle.Classic,
            color: {
                primary: '#EFEFEF',
                secondary: '#460637'
            },
            styles: {
                page: {
                    menu: {
                        button: {
                            color: '#BEA6D4',
                            disabledColor: '#C4BEC9',
                            iconColor: '#EFEFEF'
                        },
                        item: {
                            name: {
                                font: 'Roboto, sans-serif',
                                fontSize: '1.1rem',
                                fontWeight: '400',
                            },
                            description: {
                                font: 'Roboto, sans-serif',
                                fontWeight: '400',
                                color: ''
                            },
                            price: {
                                font: 'Roboto, sans-serif',
                                fontSize: '1rem',
                                fontWeight: '400',
                            },
                            style: MenuItemStyle.HorizontalWithOptionalImage
                        },
                        style: MenuStyle.Standard,
                        selectable: false
                    },
                    reviews: {
                        icon: {
                            color: '#BEA6D4'
                        },
                        newReviewButton: {
                            backgroundColor: '#BEA6D4',
                            fontColor: 'EFEFEF',
                            disabled: {
                                backgroundColor: '#C4BEC9',
                                fontColor: 'EFEFEF',
                            }
                        },
                    },
                    frontPage: {
                        fontSize: '20px',
                        hoursDropdown: {
                            icon: {
                                size: '1.2rem'
                            }
                        }
                    }
                },
                map: {
                    google: [
                        {
                            elementType: 'geometry',
                            stylers: [{ color: '#E2E2E2' }]
                        },
                        {
                            featureType: 'road',
                            elementType: 'geometry',
                            stylers: [{ color: '#F7F7F7' }]
                        },
                        {
                            featureType: 'poi.business',
                            stylers: [{ visibility: 'off' }]
                        },
                        {
                            featureType: 'water',
                            elementType: 'geometry',
                            stylers: [{ color: '#9FDDF1' }]
                        }
                    ]
                },
                common: {
                    borderRadius: ''
                },
                orderHereAnimation: {
                    color: Color.White,
                    screen: {
                        full: {
                            width: '',
                            bottom: '',
                            right: ''
                        }
                    }
                }
            }
        },
        [RestaurantSlug.PiriPiri]: {
            cssClass: 'piri-piri',
            pageVersion: RestaurantStyle.Classic,
            color: {
                primary: '#FFEF5B',
                secondary: '#FFFFFF'
            },
            styles: {
                page: {
                    menu: {
                        button: {
                            color: '#FFEF5B',
                            disabledColor: '#FFF8B7',
                            iconColor: '#000000'
                        },
                        item: {
                            name: {
                                font: 'Roboto Slab',
                                fontSize: '1.1rem',
                                fontWeight: '400',
                            },
                            description: {
                                font: 'Roboto Slab',
                                fontWeight: '400',
                                color: ''
                            },
                            price: {
                                font: 'Roboto Slab',
                                fontSize: '1rem',
                                fontWeight: '400',
                            },
                            style: MenuItemStyle.HorizontalWithOptionalImage
                        },
                        style: MenuStyle.Standard,
                        selectable: false
                    },
                    reviews: {
                        icon: {
                            color: '#FFEF5B'
                        },
                        newReviewButton: {
                            backgroundColor: '#FFEF5B',
                            fontColor: '1E1E1E',
                            disabled: {
                                backgroundColor: '#FFF8B7',
                                fontColor: '1E1E1E',
                            }
                        },
                    },
                    frontPage: {
                        fontSize: '20px',
                        hoursDropdown: {
                            icon: {
                                size: '1.2rem'
                            }
                        }
                    }
                },
                map: {
                    google: [
                        {
                            elementType: 'geometry',
                            stylers: [{ color: '#9B2826' }]
                        },
                        {
                            featureType: 'road',
                            elementType: 'geometry',
                            stylers: [{ color: '#6F6F6F' }]
                        },
                        {
                            featureType: 'poi.business',
                            stylers: [{ visibility: 'off' }]
                        },
                        {
                            featureType: 'water',
                            elementType: 'geometry',
                            stylers: [{ color: '#9FDDF1' }]
                        }
                    ]
                },
                common: {
                    borderRadius: ''
                },
                orderHereAnimation: {
                    color: Color.White,
                    screen: {
                        full: {
                            width: '',
                            bottom: '',
                            right: ''
                        }
                    }
                }
            }
        },
        [RestaurantSlug.UkiyaIzakaya]: {
            cssClass: 'ukiya-izakaya',
            pageVersion: RestaurantStyle.Classic,
            color: {
                primary: '#E3563A',
                secondary: '#FFF1EB'
            },
            styles: {
                page: {
                    menu: {
                        button: {
                            color: '#E3563A',
                            disabledColor: '#FFDACD',
                            iconColor: '#000000'
                        },
                        item: {
                            name: {
                                font: 'Syne',
                                fontSize: '1.1rem',
                                fontWeight: '400',
                            },
                            description: {
                                font: 'Syne',
                                fontWeight: '400',
                                color: ''
                            },
                            price: {
                                font: 'Syne',
                                fontSize: '1rem',
                                fontWeight: '400',
                            },
                            style: MenuItemStyle.HorizontalWithOptionalImage
                        },
                        style: MenuStyle.Standard,
                        selectable: false
                    },
                    reviews: {
                        icon: {
                            color: '#E3563A'
                        },
                        newReviewButton: {
                            backgroundColor: '#E3563A',
                            fontColor: 'FFF1EB',
                            disabled: {
                                backgroundColor: '#E3563A',
                                fontColor: 'FFF1EB',
                            }
                        },
                    },
                    frontPage: {
                        fontSize: '20px',
                        hoursDropdown: {
                            icon: {
                                size: '1.2rem'
                            }
                        }
                    }
                },
                map: {
                    google: [
                        {
                            elementType: 'geometry',
                            stylers: [{ color: '#FFDACD' }]
                        },
                        {
                            featureType: 'road',
                            elementType: 'geometry',
                            stylers: [{ color: '#FFF1EB' }]
                        },
                        {
                            featureType: 'poi.business',
                            stylers: [{ visibility: 'off' }]
                        },
                        {
                            featureType: 'water',
                            elementType: 'geometry',
                            stylers: [{ color: '#9FDDF1' }]
                        }
                    ]
                },
                common: {
                    borderRadius: ''
                },
                orderHereAnimation: {
                    color: Color.White,
                    screen: {
                        full: {
                            width: '',
                            bottom: '',
                            right: ''
                        }
                    }
                }
            }
        },
        [RestaurantSlug.LivSalades]: {
            cssClass: 'liv-salades',
            pageVersion: RestaurantStyle.Classic,
            color: {
                primary: '#13BD5B',
                secondary: '#8D8774'
            },
            styles: {
                page: {
                    menu: {
                        button: {
                            color: '#13BD5B',
                            disabledColor: '#8CDDAE',
                            iconColor: '#000000'
                        },
                        item: {
                            name: {
                                font: 'Lusitana',
                                fontSize: '1.1rem',
                                fontWeight: '400',
                            },
                            description: {
                                font: 'Lusitana',
                                fontWeight: '400',
                                color: ''
                            },
                            price: {
                                font: 'Lusitana',
                                fontSize: '1rem',
                                fontWeight: '400',
                            },
                            style: MenuItemStyle.HorizontalWithOptionalImage
                        },
                        style: MenuStyle.Standard,
                        selectable: false
                    },
                    reviews: {
                        icon: {
                            color: '#13BD5B'
                        },
                        newReviewButton: {
                            backgroundColor: '#13BD5B',
                            fontColor: 'FFFFFF',
                            disabled: {
                                backgroundColor: '#13BD5B',
                                fontColor: 'FFFFFF',
                            }
                        },
                    },
                    frontPage: {
                        fontSize: '20px',
                        hoursDropdown: {
                            icon: {
                                size: '1.2rem'
                            }
                        }
                    }
                },
                map: {
                    google: [
                        {
                            elementType: 'geometry',
                            stylers: [{ color: '#C4EED5' }]
                        },
                        {
                            featureType: 'road',
                            elementType: 'geometry',
                            stylers: [{ color: '#E1FAEB' }]
                        },
                        {
                            featureType: 'poi.business',
                            stylers: [{ visibility: 'off' }]
                        },
                        {
                            featureType: 'water',
                            elementType: 'geometry',
                            stylers: [{ color: '#9FDDF1' }]
                        }
                    ]
                },
                common: {
                    borderRadius: ''
                },
                orderHereAnimation: {
                    color: Color.White,
                    screen: {
                        full: {
                            width: '',
                            bottom: '',
                            right: ''
                        }
                    }
                }
            }
        },
        [RestaurantSlug.MaisonDuDestin]: {
            cssClass: 'maison-du-destin',
            pageVersion: RestaurantStyle.Classic,
            color: {
                primary: '#D2111A',
                secondary: '#FFFFFF'
            },
            styles: {
                page: {
                    menu: {
                        button: {
                            color: '#D2111A',
                            disabledColor: '#FFC6C8',
                            iconColor: '#000000'
                        },
                        item: {
                            name: {
                                font: 'Karla',
                                fontSize: '1.1rem',
                                fontWeight: '400',
                            },
                            description: {
                                font: 'Karla',
                                fontWeight: '400',
                                color: '#FFFFFF'
                            },
                            price: {
                                font: 'Karla',
                                fontSize: '1rem',
                                fontWeight: '400',
                                color: '#FFFFFF'
                            },
                            style: MenuItemStyle.HorizontalWithOptionalImage
                        },
                        style: MenuStyle.Standard,
                        selectable: false
                    },
                    reviews: {
                        icon: {
                            color: '#D2111A'
                        },
                        newReviewButton: {
                            backgroundColor: '#D2111A',
                            fontColor: 'FFFFFF',
                            disabled: {
                                backgroundColor: '#D2111A',
                                fontColor: 'FFFFFF',
                            }
                        },
                    },
                    frontPage: {
                        fontSize: '20px',
                        hoursDropdown: {
                            icon: {
                                size: '1.2rem'
                            }
                        }
                    }
                },
                map: {
                    google: [
                        {
                            elementType: 'geometry',
                            stylers: [{ color: '#FFEEEF' }]
                        },
                        {
                            featureType: 'road',
                            elementType: 'geometry',
                            stylers: [{ color: '#FFC6C8' }]
                        },
                        {
                            featureType: 'poi.business',
                            stylers: [{ visibility: 'off' }]
                        },
                        {
                            featureType: 'water',
                            elementType: 'geometry',
                            stylers: [{ color: '#9FDDF1' }]
                        }
                    ]
                },
                common: {
                    borderRadius: ''
                },
                orderHereAnimation: {
                    color: Color.White,
                    screen: {
                        full: {
                            width: '',
                            bottom: '',
                            right: ''
                        }
                    }
                }
            }
        },

        [RestaurantSlug.FlatBellyDeli]: {
            cssClass: 'flat-belly-deli',
            pageVersion: RestaurantStyle.Classic,
            color: {
                primary: '#F5483B',
                secondary: '#000000'
            },
            styles: {
                page: {
                    menu: {
                        button: {
                            color: '#F5483B',
                            disabledColor: '#F7A39C',
                            iconColor: '#EFEFEF'
                        },
                        item: {
                            name: {
                                font: 'Roboto, sans-serif',
                                fontSize: '1.1rem',
                                fontWeight: '400',
                            },
                            description: {
                                font: 'Roboto, sans-serif',
                                fontWeight: '400',
                                color: ''
                            },
                            price: {
                                font: 'Roboto, sans-serif',
                                fontSize: '1rem',
                                fontWeight: '400',
                            },
                            style: MenuItemStyle.HorizontalWithOptionalImage
                        },
                        style: MenuStyle.Standard,
                        selectable: false
                    },
                    reviews: {
                        icon: {
                            color: '#F5483B'
                        },
                        newReviewButton: {
                            backgroundColor: '#F5483B',
                            fontColor: 'EFEFEF',
                            disabled: {
                                backgroundColor: '#F7A39C',
                                fontColor: 'EFEFEF',
                            }
                        },
                    },
                    frontPage: {
                        fontSize: '20px',
                        hoursDropdown: {
                            icon: {
                                size: '1.2rem'
                            }
                        }
                    }
                },
                map: {
                    google: [
                        {
                            elementType: 'geometry',
                            stylers: [{ color: '#FFDACD' }]
                        },
                        {
                            featureType: 'road',
                            elementType: 'geometry',
                            stylers: [{ color: '#FFF1EB' }]
                        },
                        {
                            featureType: 'poi.business',
                            stylers: [{ visibility: 'off' }]
                        },
                        {
                            featureType: 'water',
                            elementType: 'geometry',
                            stylers: [{ color: '#9FDDF1' }]
                        }
                    ]
                },
                common: {
                    borderRadius: ''
                },
                orderHereAnimation: {
                    color: Color.White,
                    screen: {
                        full: {
                            width: '',
                            bottom: '',
                            right: ''
                        }
                    }
                }
            }
        },

        [RestaurantSlug.DowntownDhaba]: {
            cssClass: 'downtown-dhaba',
            pageVersion: RestaurantStyle.Classic,
            color: {
                primary: '#FF9700',
                secondary: '#FFFFFF'
            },
            styles: {
                page: {
                    menu: {
                        button: {
                            color: '#FF9700',
                            disabledColor: '#FFCE99',
                            iconColor: '#342B23'
                        },
                        item: {
                            name: {
                                font: 'Ultra',
                                fontSize: '1.1rem',
                                fontWeight: '400',
                            },
                            description: {
                                font: 'Ultra',
                                fontWeight: '400',
                                color: ''
                            },
                            price: {
                                font: 'Ultra',
                                fontSize: '1rem',
                                fontWeight: '400',
                            },
                            style: MenuItemStyle.HorizontalWithOptionalImage
                        },
                        style: MenuStyle.Standard,
                        selectable: false
                    },
                    reviews: {
                        icon: {
                            color: '#FF9700'
                        },
                        newReviewButton: {
                            backgroundColor: '#FF9700',
                            fontColor: 'FFFFFF',
                            disabled: {
                                backgroundColor: '#FFCE99',
                                fontColor: '342B23',
                            }
                        },
                    },
                    frontPage: {
                        fontSize: '20px',
                        hoursDropdown: {
                            icon: {
                                size: '1.2rem'
                            }
                        }
                    }
                },
                map: {
                    google: [
                        {
                            elementType: 'geometry',
                            stylers: [{ color: '#FFCE99' }]
                        },
                        {
                            featureType: 'road',
                            elementType: 'geometry',
                            stylers: [{ color: '#FFEEDB' }]
                        },
                        {
                            featureType: 'poi.business',
                            stylers: [{ visibility: 'off' }]
                        },
                        {
                            featureType: 'water',
                            elementType: 'geometry',
                            stylers: [{ color: '#9FDDF1' }]
                        }
                    ]
                },
                common: {
                    borderRadius: ''
                },
                orderHereAnimation: {
                    color: Color.White,
                    screen: {
                        full: {
                            width: '',
                            bottom: '',
                            right: ''
                        }
                    }
                }
            }
        },

        [RestaurantSlug.Grillados]: {
            cssClass: 'grillados',
            pageVersion: RestaurantStyle.Classic,
            color: {
                primary: '#F8A344',
                secondary: '#FFFFFF'
            },
            styles: {
                page: {
                    menu: {
                        button: {
                            color: '#FF9700',
                            disabledColor: '#FFCE99',
                            iconColor: '#342B23'
                        },
                        item: {
                            name: {
                                font: 'Poppins',
                                fontSize: '1.1rem',
                                fontWeight: '400',
                            },
                            description: {
                                font: 'Poppins',
                                fontWeight: '400',
                                color: ''
                            },
                            price: {
                                font: 'Poppins',
                                fontSize: '1rem',
                                fontWeight: '400',
                            },
                            style: MenuItemStyle.HorizontalWithOptionalImage
                        },
                        style: MenuStyle.Standard,
                        selectable: false
                    },
                    reviews: {
                        icon: {
                            color: '#F8A344'
                        },
                        newReviewButton: {
                            backgroundColor: '#F8A344',
                            fontColor: '6A161B',
                            disabled: {
                                backgroundColor: '#FFD09B',
                                fontColor: '6A161B',
                            }
                        },
                    },
                    frontPage: {
                        fontSize: '20px',
                        hoursDropdown: {
                            icon: {
                                size: '1.2rem'
                            }
                        }
                    }
                },
                map: {
                    google: [
                        {
                            elementType: 'geometry',
                            stylers: [{ color: '#FFCE99' }]
                        },
                        {
                            featureType: 'road',
                            elementType: 'geometry',
                            stylers: [{ color: '#FFE4C5' }]
                        },
                        {
                            featureType: 'poi.business',
                            stylers: [{ visibility: 'off' }]
                        },
                        {
                            featureType: 'water',
                            elementType: 'geometry',
                            stylers: [{ color: '#9FDDF1' }]
                        }
                    ]
                },
                common: {
                    borderRadius: ''
                },
                orderHereAnimation: {
                    color: Color.White,
                    screen: {
                        full: {
                            width: '',
                            bottom: '',
                            right: ''
                        }
                    }
                }
            }
        }
    }

    // RestoPage TODO: Add restaurant config object above

    constructor() { }

    getPageVersion(restaurantSlug: string): RestaurantStyle {
        return this.RESTAURANT[restaurantSlug].pageVersion
    }

    getCssClass(restaurantSlug: string): string {
        return this.RESTAURANT[restaurantSlug].cssClass
    }

    getPrimaryFeatureImageForMenuForPageV2(restaurantSlug: string): string {
        let image = this.RESTAURANT[restaurantSlug]?.styles?.page?.menu?.feature?.primary?.image
        return image ? image : this.getDefaultImage(restaurantSlug)
    }

    getSecondaryFeatureImageForMenuForPageV2(restaurantSlug: string): string {
        let image = this.RESTAURANT[restaurantSlug]?.styles?.page?.menu?.feature?.secondary?.image
        return image ? image : this.getDefaultImage(restaurantSlug)
    }

    getDefaultImage(restaurantSlug: string = null): string {
        let image: string
        if (restaurantSlug) {
            image = this.RESTAURANT[restaurantSlug]?.defaultImage
        }
        return image ? image : this.DEFAULT_IMAGE_URL
    }

    getPrimaryColor(restaurantSlug: string): string {
        let color = this.RESTAURANT[restaurantSlug]?.color?.primary
        return color ? color : '#000000'
    }

    getSecondaryColor(restaurantSlug: string): string {
        let color = this.RESTAURANT[restaurantSlug]?.color?.secondary
        return color ? color : '#FFFFFF'
    }

    getMenuButtonColor(restaurantSlug: string): string {
        let color = this.RESTAURANT[restaurantSlug]?.styles?.page?.menu?.button?.color
        return color ? color : '#e0e0e0'
    }

    getMenuButtonDisabledColor(restaurantSlug: string): string {
        let color = this.RESTAURANT[restaurantSlug]?.styles?.page?.menu?.button?.disabledColor
        return color ? color : '#f8f8f8'
    }

    getMenuButtonIconColor(restaurantSlug: string): string {
        let color = this.RESTAURANT[restaurantSlug]?.styles?.page?.menu?.button?.iconColor
        return color ? color : '#000000'
    }

    getMenuButtonBorder(restaurantSlug: string): string {
        const border = this.RESTAURANT[restaurantSlug]?.styles?.page?.menu?.button?.border
        return border ? border : 'none'
    }

    getFont(restaurantSlug: string): string {
        let font = this.RESTAURANT[restaurantSlug]?.styles?.common?.font
        return font ? font : 'mr-eaves-xl-modern, sans-serif'
    }

    getBodyFontWeight(restaurantSlug: string): string {
        let fontWeight = this.RESTAURANT[restaurantSlug]?.styles?.common?.body?.fontWeight
        return fontWeight ? fontWeight : '400'
    }

    getDesktopNavbarBackgroundColor(restaurantSlug: string): string {
        let color = this.RESTAURANT[restaurantSlug]?.styles?.page?.menu?.desktop?.navbar?.backgroundColor
        return color ? color : '#dddddd'
    }

    getMobileNavbarBackgroundColor(restaurantSlug: string): string {
        let color = this.RESTAURANT[restaurantSlug]?.styles?.page?.menu?.mobile?.navbar?.backgroundColor
        return color ? color : '#dddddd'
    }

    getBorderRadius(restaurantSlug: string): string {
        let borderRadius = this.RESTAURANT[restaurantSlug]?.styles?.common?.borderRadius
        return borderRadius ? borderRadius : '0px'
    }

    getFrontPageFontSize(restaurantSlug: string): string {
        let fontSize = this.RESTAURANT[restaurantSlug]?.styles?.page?.frontPage?.fontSize
        return fontSize ? fontSize : '16px'
    }

    getMapStyle(restaurantSlug: string): any[] {
        let style = this.RESTAURANT[restaurantSlug]?.styles?.map?.google
        return style ? style : this.DEFAULT_GOOGLE_MAP_STYLE
    }

    getMapRadiusFillColor(restaurantSlug: string): string {
        let color = this.RESTAURANT[restaurantSlug]?.styles?.map?.radius?.color
        return color ? color : ''
    }

    getSectionTitleLetterSpacing(restaurantSlug: string): string {
        let letterSpacing = this.RESTAURANT[restaurantSlug]?.styles?.page?.menu?.section?.title?.letterSpacing
        return letterSpacing ? letterSpacing : '0px'
    }

    getReviewIconColor(restaurantSlug: string): string {
        let color = this.RESTAURANT[restaurantSlug]?.styles?.page?.reviews?.icon?.color
        return color ? color : '#000000'
    }

    getnewReviewButtonBackgroundColor(restaurantSlug: string): string {
        let color = this.RESTAURANT[restaurantSlug]?.styles?.page?.reviews?.newReviewButton?.backgroundColor
        return color ? color : '#000000'
    }

    getnewReviewButtonFontColor(restaurantSlug: string): string {
        let color = this.RESTAURANT[restaurantSlug]?.styles?.page?.reviews?.newReviewButton?.fontColor
        return color ? color : '#ffffff'
    }

    getnewReviewButtonDisabledBackgroundColor(restaurantSlug: string): string {
        let color = this.RESTAURANT[restaurantSlug]?.styles?.page?.reviews?.newReviewButton?.disabled?.backgroundColor
        return color ? color : '#000000'
    }

    getnewReviewButtonDisabledFontColor(restaurantSlug: string): string {
        let color = this.RESTAURANT[restaurantSlug]?.styles?.page?.reviews?.newReviewButton?.disabled?.fontColor
        return color ? color : '#ffffff'
    }

    getSectionTitleTextTransform(restaurantSlug: string): string {
        let textTransform = this.RESTAURANT[restaurantSlug]?.styles?.page?.menu?.section?.title?.textTransform
        return textTransform ? textTransform : 'inherit'
    }

    getRestaurantTagline(restaurantSlug: string, language: string): string {
        let tagline = this.RESTAURANT[restaurantSlug]?.text?.about?.tagline?.[language]
        return tagline ? tagline : ''
    }

    getRestaurantDescription(restaurantSlug: string, language: string): string {
        let description = this.RESTAURANT[restaurantSlug]?.text?.about?.description?.[language]
        return description ? description : ''
    }

    getFrontPageClockIconSize(restaurantSlug: string): string {
        let size = this.RESTAURANT[restaurantSlug]?.styles?.page?.frontPage?.hoursDropdown?.icon?.size
        return size ? size : '1.2rem'
    }

    getMenuItemDescriptionFontWeight(restaurantSlug: string): string {
        let fontWeight = this.RESTAURANT[restaurantSlug]?.styles?.page?.menu?.item?.description?.fontWeight
        return fontWeight ? fontWeight : '400'
    }

    getMenuItemDescriptionColor(restaurantSlug: string): string {
        let color = this.RESTAURANT[restaurantSlug]?.styles?.page?.menu?.item?.description?.color
        return color ? color : '#212529'
    }

    getMenuStyle(restaurantSlug: string): MenuStyle {
        let style = this.RESTAURANT[restaurantSlug]?.styles?.page?.menu?.style
        return style !== null && style !== undefined ? style : MenuStyle.Standard
    }

    isMenuSelectable(restaurantSlug: string) {
        const isMenuSelectable = this.RESTAURANT[restaurantSlug]?.styles?.page?.menu?.selectable
        return isMenuSelectable !== null && isMenuSelectable !== undefined ? isMenuSelectable : false
    }

    getMenuItemStyle(restaurantSlug: string): MenuItemStyle {
        const style = this.RESTAURANT[restaurantSlug]?.styles?.page?.menu?.item?.style
        return style !== null && style !== undefined ? style : MenuItemStyle.NoImage
    }

    getMenuTabStyle(restaurantSlug: string): any {
        let style = this.RESTAURANT[restaurantSlug]?.styles?.page?.menu?.tab
        return style ? style : null
    }

    getMenuProduct(restaurantSlug: string): any {
        let product = this.RESTAURANT[restaurantSlug]?.styles?.page?.menu?.item
        return product ? product : null;
    }

    getOrderHereAnimationColor(restaurantSlug: string): Color {
        const color = this.RESTAURANT[restaurantSlug]?.styles?.orderHereAnimation?.color
        return color ? color : Color.Black
    }

    getOrderHereAnimationStyle(restaurantSlug: string): any {
        const style = this.RESTAURANT[restaurantSlug]?.styles?.orderHereAnimation?.screen?.full
        return style ? style : null
    }

    isHeaderNameVisible(restaurantSlug: string) {
        const isHeaderNameVisible = this.RESTAURANT[restaurantSlug]?.styles?.page?.frontPage?.header?.name?.visible
        return isHeaderNameVisible !== null && isHeaderNameVisible !== undefined ? isHeaderNameVisible : false
    }
}
