<div class="restaurant-about-important-container" [ngClass]="configService.getCssClass(restaurant.slug)">
    <div *ngIf="reviews && reviews.length > 0"
        class="restaurant-about-important-rating-container restaurant-about-important-section">
        <app-rating [reviews]="reviews" [iconColor]="getIconColor()" [restaurantSlug]="restaurant.slug"></app-rating>
    </div>
    <div *ngIf="restaurant.schedule"
        class="restaurant-about-important-section">
        <app-hours-dropdown [restaurantSlug]="restaurant.slug" [iconColor]="getIconColor()" [schedule]="restaurant.schedule" [iconSize]="configService.getFrontPageClockIconSize(restaurant.slug)"></app-hours-dropdown>
    </div>
    <div *ngIf="restaurant.address"
        class="restaurant-about-important-address-container restaurant-about-important-section">
        <!-- <svg class="restaurant-about-important-address-pin icon" viewBox="0 0 360 512">
            <path [attr.fill]="getIconColor()"
                d="M180,0C80.75,0,0,80.75,0,180a179.62,179.62,0,0,0,26.87,94.65L169.75,504.91A15,15,0,0,0,182.5,512h.12a15,15,0,0,0,12.75-7.29L334.61,272.22A179.78,179.78,0,0,0,360,180C360,80.75,279.25,0,180,0ZM308.87,256.82,182.27,468.19,52.37,258.85A149.79,149.79,0,0,1,29.8,180C29.8,97.29,97.29,29.8,180,29.8S330.1,97.29,330.1,180A149.9,149.9,0,0,1,308.87,256.82Z" />
            <path [attr.fill]="getIconColor()"
                d="M180,90a90,90,0,1,0,90,90A90.1,90.1,0,0,0,180,90Zm0,150.2A60.2,60.2,0,1,1,240.1,180,60.24,60.24,0,0,1,180,240.2Z" />
        </svg> -->
        <svg class="restaurant-about-important-address-pin icon" viewBox="0 0 326.04 415.99">
            <path [attr.fill]="getIconColor()" d="M307.76,87.72A160,160,0,0,0,181.18.91a182.05,182.05,0,0,0-36.31,0A160,160,0,0,0,18.28,87.71,172.89,172.89,0,0,0,42,278L150.66,410.15a16,16,0,0,0,22.52,2.2,15.77,15.77,0,0,0,2.2-2.2L284.07,278A172.89,172.89,0,0,0,307.76,87.72Zm-48.41,170L163,374.8,66.7,257.68a142.85,142.85,0,0,1-20.11-155A128.51,128.51,0,0,1,148.12,32.72,145.44,145.44,0,0,1,163,32a143.07,143.07,0,0,1,14.92.76,128.48,128.48,0,0,1,101.53,69.9,142.85,142.85,0,0,1-20.12,155Z"/>
            <path [attr.fill]="getIconColor()" d="M163,80a80,80,0,1,0,80,80A80,80,0,0,0,163,80Zm0,128a48,48,0,1,1,48-48A48,48,0,0,1,163,208Z"/>
        </svg>
        <div class="restaurant-about-important-address-text-container">
            <div class="restaurant-about-important-address">
                {{ (restaurant.address | address : 'standard') }}
            </div>
            <div class="restaurant-about-important-address-get-directions">
                <a class="link" href="https://www.google.com/maps/search/?api=1&query={{encodeURI((restaurant.address | address : 'standard'))}}"
                    target="_blank">({{'restaurant.about.directions' | transloco}})
                </a>
            </div>
            <!-- <div class="restaurant-about-important-address-borough">{{restaurant.borough}}</div> -->
        </div>
    </div>
    <app-location-map
        [latitude]="restaurant.address.loc.coordinates[1]"
        [longitude]="restaurant.address.loc.coordinates[0]"
        [zoom]="zoom"
        [styles]="mapStyle"
        [radius]="restaurant.delivery.radius"
        [radiusFillColor]="configService.getMapRadiusFillColor(restaurant.slug)"
        [showRadius]="radiusCircleVisible">
    </app-location-map>
    <button type="button" class="restaurant-about-important-show-radius-button" (click)="toggleDeliveryRadiusClick()">
        {{ radiusCircleVisible ? ('restaurant.about.hideRadius' | transloco) : ('restaurant.about.showRadius' | transloco) }}
    </button>
    <div *ngIf="restaurant?.contact?.number"
        class="restaurant-about-important-phone-container restaurant-about-important-section">
        <svg class="restaurant-about-important-phone-icon icon" viewBox="0 0 16.82 17.02">
            <path [attr.fill]="getIconColor()"
                d="M12,17h0a4.37,4.37,0,0,1-2.5-.79,35.44,35.44,0,0,1-4.7-4,35.62,35.62,0,0,1-4-4.71c-1.5-2-.9-4.6,1.4-7a1.89,1.89,0,0,1,2.6,0l1.4,1.4a1.94,1.94,0,0,1,0,2.6l-.6.61c-.2.2-.1.5,0,.7a23.83,23.83,0,0,0,2.6,2.8,37.79,37.79,0,0,0,2.8,2.6.48.48,0,0,0,.7,0l.6-.6a1.85,1.85,0,0,1,2.6,0L16.32,12a1.94,1.94,0,0,1,0,2.6A5.62,5.62,0,0,1,12,17ZM3.52,1a1.58,1.58,0,0,0-.6.2c-2,2-2.4,4.1-1.3,5.7a34,34,0,0,0,3.9,4.6,33.27,33.27,0,0,0,4.6,3.9c1.6,1.1,3.7.71,5.7-1.29a.75.75,0,0,0,.2-.61,1.47,1.47,0,0,0-.2-.6l-1.5-1.4a.94.94,0,0,0-1.2,0l-.6.61a1.52,1.52,0,0,1-2,0,25.63,25.63,0,0,1-2.9-2.71,38.4,38.4,0,0,1-2.7-2.9,1.52,1.52,0,0,1,0-2l.6-.6a.73.73,0,0,0,.2-.6,1.42,1.42,0,0,0-.2-.6l-1.4-1.4A.91.91,0,0,0,3.52,1Z" />
        </svg>
        <a class="restaurant-about-important-phone link" [href]="'tel:+1' + restaurant.contact.number">+1
            {{ restaurant.contact.number | phoneNumber: 'standard' }}
        </a>

    </div>
</div>
