<app-navbar></app-navbar>
<div class="reset-password-container">
    <div class="container reset-password-area">
        <div class="reset-password-container-column col-md-5 form-column">
            <h2 class="reset-password-container-title">{{ 'resetPassword.resetPassword' | transloco }}</h2>
            <p>{{ 'resetPassword.helperMessage' | transloco }}</p>
            <form [formGroup]="resetPasswordForm" (ngSubmit)="onSubmit(resetPasswordForm.value.pwd)" novalidate>
                <div class="form-group">
                    <input formControlName="pwd" class="form-control" type="password" id="pwd"
                        placeholder="{{ 'resetPassword.password.title' | transloco }}"
                        [ngClass]="{ 'is-invalid': resetPasswordControls.pwd.invalid && resetPasswordControls.pwd.dirty }" value="" required>
                    <div class="line"></div>
                    <div *ngIf="resetPasswordControls.pwd.errors && (resetPasswordControls.pwd.dirty || resetPasswordControls.pwd.touched)"
                        class="form-control-feedback invalid-feedback">
                        <div *ngIf="resetPasswordControls.pwd.errors.required">
                            {{ 'resetPassword.password.required' | transloco }}</div>
                        <div *ngIf="resetPasswordControls.pwd.errors.minlength || resetPasswordControls.pwd.errors.maxlength">
                            {{ 'resetPassword.password.length' | transloco }}</div>
                    </div>
                </div>
                <div class="form-group mb-4">
                    <input formControlName="confirmPwd" type="password" class="form-control" id="confirmPwd"
                        placeholder="{{ 'resetPassword.confirmPassword' | transloco }}" value=""
                        [ngClass]="{ 'is-invalid': resetPasswordControls.confirmPwd.invalid && resetPasswordControls.confirmPwd.dirty }" required>
                    <div class="line"></div>
                    <div *ngIf="resetPasswordControls.confirmPwd.errors && (resetPasswordControls.confirmPwd.dirty || resetPasswordControls.confirmPwd.touched)"
                        class="form-control-feedback invalid-feedback">
                        <div *ngIf="resetPasswordControls.confirmPwd.errors.required">
                            {{ 'resetPassword.repeatPasswordRequired' | transloco }}</div>
                        <div *ngIf="resetPasswordControls.confirmPwd.errors.mustMatch">
                            {{ 'resetPassword.password.noMatch' | transloco }}</div>
                    </div>
                </div>
                <div class="form-group regular-resetPassword mb-4">
					<button class="btn btn-resetPassword form-control" type="submit"
						[disabled]="!resetPasswordForm.valid || loading || verificationMessage">
						<div *ngIf="loading" class="spinner-border spinner-border-sm" role="status">
							<span class="sr-only">{{ 'common.loading' | transloco }}</span>
						</div>
						<span *ngIf="!loading">
							{{ 'resetPassword.submit' | transloco }}
						</span>
					</button>
				</div>
            </form>
            <div *ngIf="verificationMessage" class="verification-message">
                {{ 'resetPassword.message.1' | transloco }}
                <br>
                {{ 'resetPassword.message.2' | transloco }}
                 <a [routerLink]="'/' + translate.getActiveLang() + '/login'"> {{ 'resetPassword.login' | transloco }}</a>
                 {{ 'resetPassword.message.3' | transloco }}
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>
