import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Restaurant } from 'src/app/core/models';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { TranslocoService } from '@ngneat/transloco';
import { SEOService } from '../services/seo.service';
import { environment } from 'src/environments/environment';
import { MainService } from '../services/main.service';

@Injectable({
    providedIn: 'root'
})
export class RestaurantResolverService implements Resolve<any>{

    constructor(private mainService: MainService,
        private translate: TranslocoService,
        private router: Router,
        private seoService: SEOService) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | Restaurant> {
        let slug = route.paramMap.get('slug')
        if (!slug)  slug = route.url[route.url.length - 1].path

        let trendType = route.data['trendType'];
        return this.mainService.visitRestaurant(slug, trendType)
            .pipe(catchError(err => this.router.navigate(['restaurants'])),
            tap(res => {
                if (res) {
                    const restaurant: Restaurant = <Restaurant>res
                    this.seoService.updateTitle('Radish | ' + restaurant.name);
                    this.seoService.updateOgTitle('Radish | ' + restaurant.name);
                    const brief = restaurant.brief ? restaurant.brief[this.translate.getActiveLang()] : null;
                    if (brief) {
                        this.seoService.updateDescription(brief);
                        this.seoService.updateOgDescription(brief);
                    }
                    const image = restaurant.assets?.header?.url
                    if (image) {
                        this.seoService.updateImages(image);
                    }
                    this.seoService.updateOgUrl(environment.baseUrl + '/' + route.url.join('/'))
                }
            }))
    }
}
