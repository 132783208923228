import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { Observable } from 'rxjs';
import { tap, first, map } from 'rxjs/operators';

import { User, Address } from '../models';
import { ADDRESS_KEY, generateNewAddressObject } from '../helpers/address.helper';

export const KEY_USER_STUB: string = 'user_stub';
@Injectable({
    providedIn: 'root',
})
export class UserService {

    constructor(private baseService: BaseService) { }

    private entity: string = 'user';

    getSelf(): Observable<User> {
        return this.baseService.list(this.entity + '/self?email=true').pipe(map(res => new User().deserialize(res)));
    }

    assignAddressToUser(address: Address): Observable<Address> {
        const newAddress = generateNewAddressObject(address);
        return this.baseService.create(this.entity + '/addresses', { address: newAddress })
            .pipe(
                // tap(res => localStorage.removeItem(ADDRESS_KEY)),
                map(res => new Address().deserialize(res.address))
            )
    }

    setLanguage(language: string) {
        this.baseService
            .update(this.entity + '/language', { language })
            .pipe(first())
            .subscribe();
    }

    fetchAddressById(id: string): Observable<Address> {
        return this.baseService.fetch(this.entity + '/addresses', id)
            .pipe(map(res => new Address().deserialize(res.address)))
    }

    getAddresses(): Observable<Address[]> {
        return this.baseService.list(this.entity + '/addresses')
            .pipe(map(res => {
                let addresses: Address[] = [];
                if (res?.addresses?.other) addresses = addresses.concat(res.addresses.other.map(address => new Address().deserialize(address)));
                if (res?.addresses?.preferred) {
                    let index = addresses.findIndex(address => address.id == res.addresses.preferred._id);
                    //ddresses.unshift(...addresses.splice(index, 1));
                    addresses.push(...addresses.splice(index, 1))
                    addresses[addresses.length - 1].isPreferred = true;
                }
                return addresses;
            }))
    }
}
