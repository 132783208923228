<div class="restaurant-menu-item" [class.item-unavailable]="!product.isAvailable" [ngClass]="{'large-format': largeFormat}">
    <ng-container *ngIf="!product.isArchived">
        <app-item-with-option-update
            *ngIf="product.options && product.options.length > 0 && productIncrementOptionsToShow == product.id"
            [product]="product" [bag]="bag" [type]="'increment'" class="bag-item"
            (onNewItemClick)="onNewItemWithOptionsClick($event)" (onClick)="itemOptionsClickHandler($event)">
        </app-item-with-option-update>
        <app-item-with-option-update
            *ngIf="product.options && product.options.length > 0 && productDecrementOptionsToShow == product.id"
            [product]="product" [bag]="bag" [type]="'decrement'" class="bag-item"
            (onNewItemClick)="onNewItemWithOptionsClick($event)" (onClick)="itemOptionsClickHandler($event)">
        </app-item-with-option-update>
        <div class="restaurant-menu-item-row">
            <!-- <div class="restaurant-menu-item-row" [ngbTooltip]="tipContent" tooltipClass="restaurant-menu-tooltip"> -->
            <!-- <ng-template #tipContent><img class="restaurant-menu-tooltip-image" src="../../../assets/images/freeze_cream.jpg" /></ng-template> -->
            <ng-container *ngIf="product.thumbnailUrl && product.imageUrl">
                <div class="image-container">
                    <img class="item-image" [src]="largeFormat ? product.imageUrl : product.thumbnailUrl" (click)="clickProduct(product)">
                </div>
            </ng-container>
            <div class="content-container">
                <div class="content-container-name-description-wrapper">
                    <div class="restaurant-menu-item-name" [innerHTML]="product.title" [ngStyle]="{'font-family': productStyle.name.font,
                            'font-size': productStyle.name.fontSize,
                            'font-weight': productStyle.name.fontWeight,
                            'text-transform': productStyle.name.textTransform,
                            'color': productStyle.name.color}"></div>
                    <div class="restaurant-menu-item-description" [ngStyle]="{'font-family': productStyle.description.font,
                            'font-weight': productStyle.description.fontWeight,
                            'font-size': productStyle.description.fontSize,
                            'color': productStyle.description.color}">
                        <ng-container *ngIf="largeFormat && !isMobile; else readMoreDescription">
                            {{ product.description ? product.description : ''}}
                        </ng-container>
                        <ng-template #readMoreDescription>
                            <app-read-more [content]="product.description ? product.description : ''"
                                [limit]="isMobile ? 56 : 75" [completeWords]="true">
                            </app-read-more>
                        </ng-template>
                    </div>
                </div>
                <div class="restaurant-menu-item-price-buttons-wrapper">
                    <div class="restaurant-menu-item-price" [ngStyle]="{'font-family': productStyle.price.font,
                            'font-weight': productStyle.price.fontWeight,
                            'font-size': productStyle.price.fontSize,
                            'color': productStyle.price.color }">
                        {{ product.price | price | translocoCurrency }}
                    </div>
                    <div class="restaurant-menu-item-buttons">
                        <app-menu-item-control-button [class.invisible]="!isItemInBag"
                            class="restaurant-menu-item-button restaurant-menu-item-button-minus"
                            [disabled]="disableModifyProductCount" (onButtonClicked)="removeItem(product)"
                            [color]="buttonColor" [iconColor]="buttonIconColor" [disabledColor]="disabledButtonColor"
                            [type]="MenuItemButtonType.Minus"></app-menu-item-control-button>
                        <div [ngbTooltip]="tooltip" [disableTooltip]="!disableModifyProductCount"
                            tooltipClass="radish-tooltip">
                            <!-- <button class="restaurant-menu-item-button restaurant-menu-item-button-plus"
                                (click)="addItem(product)" [disabled]="disableModifyProductCount">
                                <img src="../../assets/images/icons/plus.svg">
                            </button> -->
                            <app-menu-item-control-button class="restaurant-menu-item-button"
                                [disabled]="disableModifyProductCount" (onButtonClicked)="addItem(product)"
                                [color]="buttonColor" [iconColor]="buttonIconColor"
                                [disabledColor]="disabledButtonColor" [type]="MenuItemButtonType.Plus">
                            </app-menu-item-control-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</div>