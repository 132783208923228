import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslocoService } from '@ngneat/transloco';


@Component({
  selector: 'app-marche-radish-letter',
  templateUrl: './marche-radish-letter.component.html',
  styleUrls: ['./marche-radish-letter.component.css']
})
export class MarcheRadishLetterComponent implements OnInit {

  constructor(
    public translate: TranslocoService,
    public activeModal: NgbActiveModal) 
    { }

  ngOnInit(): void {
  }

}
