import Dinero from 'dinero.js';
import { Deserializable } from './deserializable.model';
import { TranslatedText, TranslatedTextType } from './translated-text.model';

export class CalculatedFee implements Deserializable<CalculatedFee> {
    amount: Dinero.Dinero;
    category: string;
    _description: TranslatedText;
    _title: TranslatedText;

    constructor() {}

    get title(): TranslatedTextType {
        return this._title?.value
    }

    set title(title: TranslatedTextType) {
        this._title = title as TranslatedText
    }

    get description(): TranslatedTextType {
        return this._description.value
    }

    set description(description: TranslatedTextType) {
        this._description = description as TranslatedText
    }

    deserialize(input: any): this {
        if (!input) return null;
        if (typeof input?.amount?.amount == 'number') this.amount = Dinero({ amount: input.amount.amount, currency: input.amount?.currency });
        else if (input?.amount?.getAmount != null) this.amount = input.amount;
        this.description = (input?._description) ? input._description : new TranslatedText().deserialize(input.description);
        this.title = (input?._title) ? input._title : new TranslatedText().deserialize(input.title);
        return this;
    }
}
