<div *ngIf="this.reviews" class="restaurant-reviews-container" [ngClass]="configService.getCssClass(restaurant.slug)">
    <div *ngIf="reviews && reviews.length > 0; else emptyReviews" class="restaurant-reviews-section row">
        <div class="card restaurant-reviews-summary-card col-sm-12 col-md-6">
            <div class="restaurant-reviews-summary-container restaurant-reviews-top-container-section">
                <div class="restaurant-reviews-summary-left">
                    <div class="restaurant-reviews-summary-left-top">
                        <div *ngIf="averageRating" class="restaurant-reviews-summary-average-number">
                            {{ averageRating.toFixed(1) }}
                        </div>
                        <div class="restaurant-reviews-summary-average-stars-container">
                            <ng-container *ngIf="averageRating">
                                <svg *ngFor="let dummy of [].constructor(numFullStars)"
                                    class="restaurant-reviews-summary-average-star" viewBox="0 0 24 22.83">
                                    <path [attr.fill]="getIconColor()"
                                        d="M12,0l3.67,7.57L24,8.72l-6.06,5.83,1.48,8.28-7.42-4-7.42,4,1.48-8.28L0,8.72,8.33,7.57Z" />
                                </svg>
                                <svg *ngFor="let dummy of [].constructor(numHalfStars)"
                                    class="restaurant-reviews-summary-average-star" viewBox="0 0 24 22.83">
                                    <path [attr.fill]="getIconColor()"
                                        d="M12,4.59,14.34,9.4l5.3.74-3.86,3.71.94,5.26L12,16.59ZM12,0,8.33,7.57,0,8.72l6.06,5.83L4.58,22.83l7.42-4,7.42,4-1.48-8.27L24,8.72,15.67,7.57Z" />
                                </svg>
                                <svg *ngFor="let dummy of [].constructor(numEmptyStars)"
                                    class="restaurant-reviews-summary-average-star" viewBox="0 0 24 22.83">
                                    <path [attr.fill]="getIconColor()"
                                        d="M12,4.59,14.34,9.4l5.3.74-3.86,3.71.94,5.26L12,16.59,7.28,19.12l.94-5.27L4.36,10.14l5.3-.74ZM12,0,8.33,7.57,0,8.72l6.06,5.83L4.58,22.83l7.42-4,7.42,4-1.48-8.27L24,8.72,15.67,7.57Z" />
                                </svg>
                            </ng-container>
                        </div>
                        <div class="restaurant-reviews-summary-num-reviews">
                            {{ reviews.length }}
                            {{ 'restaurant.reviews.reviews' | transloco }}
                        </div>
                    </div>
                    <div class="restaurant-reviews-summary-button-wrapper"
                        [ngbTooltip]="userWroteReview ? ('restaurant.reviews.alreadyWroteReview' | transloco) : '' "
                        [disableTooltip]="!userWroteReview" tooltipClass="radish-tooltip">
                        <button class="restaurant-reviews-summary-button"
                            (click)="writeReviewClicked()"
                            [disabled]="userWroteReview"
                            [ngStyle]="{'background-color': userWroteReview ? configService.getnewReviewButtonDisabledBackgroundColor(restaurant.slug) : configService.getnewReviewButtonBackgroundColor(restaurant.slug),
                            'color': userWroteReview ? configService.getnewReviewButtonDisabledFontColor(restaurant.slug) : configService.getnewReviewButtonFontColor(restaurant.slug)}">
                            {{ 'restaurant.reviews.writeAReview' | transloco }}
                        </button>
                    </div>
                </div>
                <div class="restaurant-reviews-summary-right">
                    <!-- <div class="restaurant-reviews-summary-recommendations">{{'restaurant.reviews.percentRecommended' | transloco:{'percent':'86','responses':'34'} }}</div> -->
                    <div class="restaurant-reviews-summary-graph-container">
                        <div class="restaurant-reviews-summary-graph-row">
                            <div class="restaurant-reviews-summary-graph-rating-number">
                                5
                            </div>
                            <svg class="restaurant-reviews-summary-graph-star" viewBox="0 0 24 22.83">
                                <path [attr.fill]="getIconColor()"
                                    d="M12,0l3.67,7.57L24,8.72l-6.06,5.83,1.48,8.28-7.42-4-7.42,4,1.48-8.28L0,8.72,8.33,7.57Z" />
                            </svg>
                            <div [ngStyle]="{
									background: generateBarGradient(
										numRatings[5] / reviews.length
									)
								}" class="restaurant-reviews-summary-graph-rectangle"></div>
                            <div class="restaurant-reviews-summary-graph-rating-count">
                                {{ numRatings[5] }}
                            </div>
                        </div>
                        <div class="restaurant-reviews-summary-graph-row">
                            <div class="restaurant-reviews-summary-graph-rating-number">
                                4
                            </div>
                            <svg class="restaurant-reviews-summary-graph-star" viewBox="0 0 24 22.83">
                                <path [attr.fill]="getIconColor()"
                                    d="M12,0l3.67,7.57L24,8.72l-6.06,5.83,1.48,8.28-7.42-4-7.42,4,1.48-8.28L0,8.72,8.33,7.57Z" />
                            </svg>
                            <div [ngStyle]="{
									background: generateBarGradient(
										numRatings[4] / reviews.length
									)
								}" class="restaurant-reviews-summary-graph-rectangle"></div>
                            <div class="restaurant-reviews-summary-graph-rating-count">
                                {{ numRatings[4] }}
                            </div>
                        </div>
                        <div class="restaurant-reviews-summary-graph-row">
                            <div class="restaurant-reviews-summary-graph-rating-number">
                                3
                            </div>
                            <svg class="restaurant-reviews-summary-graph-star" viewBox="0 0 24 22.83">
                                <path [attr.fill]="getIconColor()"
                                    d="M12,0l3.67,7.57L24,8.72l-6.06,5.83,1.48,8.28-7.42-4-7.42,4,1.48-8.28L0,8.72,8.33,7.57Z" />
                            </svg>
                            <div [ngStyle]="{
									background: generateBarGradient(
										numRatings[3] / reviews.length
									)
								}" class="restaurant-reviews-summary-graph-rectangle"></div>
                            <div class="restaurant-reviews-summary-graph-rating-count">
                                {{ numRatings[3] }}
                            </div>
                        </div>
                        <div class="restaurant-reviews-summary-graph-row">
                            <div class="restaurant-reviews-summary-graph-rating-number">
                                2
                            </div>
                            <svg class="restaurant-reviews-summary-graph-star" viewBox="0 0 24 22.83">
                                <path [attr.fill]="getIconColor()"
                                    d="M12,0l3.67,7.57L24,8.72l-6.06,5.83,1.48,8.28-7.42-4-7.42,4,1.48-8.28L0,8.72,8.33,7.57Z" />
                            </svg>
                            <div [ngStyle]="{
									background: generateBarGradient(
										numRatings[2] / reviews.length
									)
								}" class="restaurant-reviews-summary-graph-rectangle"></div>
                            <div class="restaurant-reviews-summary-graph-rating-count">
                                {{ numRatings[2] }}
                            </div>
                        </div>
                        <div class="restaurant-reviews-summary-graph-row">
                            <div class="restaurant-reviews-summary-graph-rating-number">
                                1
                            </div>
                            <svg class="restaurant-reviews-summary-graph-star" viewBox="0 0 24 22.83">
                                <path [attr.fill]="getIconColor()"
                                    d="M12,0l3.67,7.57L24,8.72l-6.06,5.83,1.48,8.28-7.42-4-7.42,4,1.48-8.28L0,8.72,8.33,7.57Z" />
                            </svg>
                            <div [ngStyle]="{
									background: generateBarGradient(
										numRatings[1] / reviews.length
									)
								}" class="restaurant-reviews-summary-graph-rectangle"></div>
                            <div class="restaurant-reviews-summary-graph-rating-count">
                                {{ numRatings[1] }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="galleryImages && galleryImages.length > 0" class="restaurant-reviews-customer-photos-card card col-sm-12 col-md-6">
            <div class="restaurant-reviews-customer-photos-container restaurant-reviews-top-container-section">
                <div class="restaurant-reviews-customer-photos-header">
                    {{ 'restaurant.reviews.customerPhotos' | transloco }}
                </div>
                <div class="ngx-gallery-wrapper">
                    <ngx-gallery [options]="galleryOptions" [images]="galleryImages" class="ngx-gallery"></ngx-gallery>
                </div>
                <!--				<div class="restaurant-reviews-customer-photos">

                        <div class="restaurant-reviews-customer-photos-item-container">
						<div class="restaurant-reviews-customer-photos-image-wrapper">
							<img class="restaurant-reviews-customer-photos-image"
								src="../../../assets/images/freeze_cream.jpg" alt="" />
							<div class="restaurant-reviews-customer-photos-image-count-container">
								<span class="restaurant-reviews-customer-photos-image-count">+42</span>
							</div>
						</div>
						<div class="restaurant-reviews-customer-photos-category">Food</div>
					</div>
					<div class="restaurant-reviews-customer-photos-item-container">
						<div class="restaurant-reviews-customer-photos-image-wrapper">
							<img class="restaurant-reviews-customer-photos-image"
								src="../../../assets/images/think_good.jpg" alt="" />
							<div class="restaurant-reviews-customer-photos-image-count-container">
								<span class="restaurant-reviews-customer-photos-image-count">+14</span>
							</div>
						</div>
						<div class="restaurant-reviews-customer-photos-category">
							Restaurant
						</div>
					</div>
					<div class="restaurant-reviews-customer-photos-item-container">
						<div class="restaurant-reviews-customer-photos-image-wrapper">
							<img class="restaurant-reviews-customer-photos-image"
								src="../../../assets/images/panda_palace_burgers.jpg" alt="" />
							<div class="restaurant-reviews-customer-photos-image-count-container">
								<span class="restaurant-reviews-customer-photos-image-count">+39</span>
							</div>
						</div>
						<div class="restaurant-reviews-customer-photos-category">
							Others
						</div>
                    </div>
                </div>-->

            </div>
        </div>
    </div>
    <ng-template #emptyReviews>
        <div class="restaurant-reviews-empty-reviews-container">
            <div class="restaurant-reviews-empty-reviews-text">
                {{ 'restaurant.reviews.firstReview' | transloco }}
            </div>
            <button class="restaurant-reviews-summary-button"
                (click)="writeReviewClicked()"
                [style.background-color]="configService.getnewReviewButtonBackgroundColor(restaurant.slug)"
                [style.color]="configService.getnewReviewButtonFontColor(restaurant.slug)">
                {{ 'restaurant.reviews.writeAReview' | transloco }}
            </button>
        </div>
    </ng-template>
    <div *ngIf="reviews && reviews.length > 0" class="restaurant-reviews-controls-section restaurant-reviews-section">
        <div class="restaurant-reviews-controls-title">
            {{ 'restaurant.reviews.deliveryReviews' | transloco }} ({{
				reviews.length
			}})
        </div>
        <div class="restuarant-reviews-controls-container">
            <div class="restaurant-reviews-controls-search-container">
                <svg class="restaurant-reviews-controls-search-icon" viewBox="0 0 14.05 14">
                    <path [attr.fill]="getIconColor()" d="M5.5,11A5.55,5.55,0,0,1,0,5.5,5.55,5.55,0,0,1,5.5,0,5.55,5.55,0,0,1,11,5.5,5.55,5.55,0,0,1,5.5,11Zm0-10A4.5,4.5,0,1,0,10,5.5,4.48,4.48,0,0,0,5.5,1Z" />
                    <path [attr.fill]="getIconColor()" d="M9.5,8.8l4.4,4.4a.48.48,0,0,1,0,.7.85.85,0,0,1-.8,0L8.7,9.5Z" />
                </svg>
                <input type="search" class="restaurant-reviews-controls-search" placeholder="Search"
                    [(ngModel)]="searchReviews" />
            </div>
            <!-- <div ngbDropdown class="d-inline-block">
        <button class="restaurant-reviews-controls-sort-button" id="reviewSortDropdown" ngbDropdownToggle>Newest</button>
        <div ngbDropdownMenu aria-labelledby="reviewSortDropdown">
          <button ngbDropdownItem>{{'restaurant.reviews.newest' | transloco}}</button>
          <button ngbDropdownItem>{{'restaurant.reviews.oldest' | transloco}}</button>
          <button ngbDropdownItem>{{'restaurant.reviews.topRated' | transloco}}</button>
        </div>
      </div> -->
        </div>
    </div>
    <!-- <div *ngIf="reviews && reviews.length > 0"
        class="restaurant-reviews-content-section restaurant-reviews-section card-columns"> -->
    <ngx-masonry *ngIf="reviews && reviews.length > 0"
        class="restaurant-reviews-content-section restaurant-reviews-section card-columns" [ordered]="true">
        <div class="row">
            <div ngxMasonryItem *ngFor="let review of reviews | filterReviews: searchReviews " class="restaurant-reviews-item-card card masonry-item col-sm-12 col-md-6">
                <app-review [review]="review" [restaurant]="restaurant"></app-review>
            </div>
        </div>
    </ngx-masonry>
</div>