import { Component, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { Observable } from 'rxjs';
import { ConfigService } from 'src/app/core/services/config.service';
import { MainService } from 'src/app/core/services/main.service';
import { Restaurant } from 'src/app/core/models';

@Component({
    selector: 'app-story',
    templateUrl: './story.component.html',
    styleUrls: ['./story.component.css']
})
export class StoryComponent implements OnInit {

    restaurant: Observable<Restaurant>
    jcStory;

    constructor(
        public configService: ConfigService,
        public mainService: MainService,
        public translate: TranslocoService) {
         }

    ngOnInit(): void {
        this.restaurant = this.mainService.restaurantLoaded$;
        this.jcStory = {
            one: {
                en: 'As far back as I can remember, I always thought chocolate was magical!',
                fr: 'Aussi loin que remontent mes souvenirs, le chocolat a toujours eu quelque chose de Magique pour moi!'
            },
            two: {
                en: 'Even after becoming an adult, I kept eating as much, if not more, chocolate, and kept dreaming about it. So after finishing my studies in Montreal, I decided I had to follow my sweet tooth, and open the restaurant I was dreaming of. A place where chocolate would be king, where you could get simple, generous desserts to make kids and adults swoon!',
                fr: 'Même devenue adulte, j’ai continué à rêver de chocolat, et à en manger toujours autant! Alors après avoir fini mes études à Montréal, je me suis rendue à l’évidence : il fallait écouter ma dent sucrée. J’ai donc décidé d’ouvrir le restaurant dont je rêvais : un endroit consacré au chocolat dans tous ses états, ou l’on servirait des desserts simples mais de qualité, en portions généreuses, de quoi faire rêver les enfants et les adultes qui ont gardé leur cœur d’enfant.'
            },
            three: {
                en: 'Finding the right name was a challenge, until my sister had an inspiration everyone loved:',
                fr: 'Trouver le bon nom est difficile, mais une inspiration de ma sœur plait à tout le monde:'
            },
            four: {
                en: 'Fifteen years and a lot of work later, Juliette & Chocolat has grown a lot, and so have I. I’m incredibly grateful and lucky to be working with a team that’s incredibly passionate, creative and sweet! Our mission is still the same: share sweet moments!',
                fr: 'Quinze ans et beaucoup de travail plus tard, Juliette & Chocolat a bien grandi, et j’ai la chance de travailler avec une équipe aussi créative, passionnée et gourmande que moi! Notre mission n’a pas changé : Partager des moments de bonheurs gourmands!'
            },
            five: {
                en: 'It’s THE sweet pleasure that I dreamt about that introduced me to cooking, and that gave m so many sweet memories.',
                fr: 'C’était LE plaisir doux et sucré dont je rêvais constamment, qui m’a fait découvrir les joies de la cuisine, et qui m’a donné mes plus beaux souvenirs gourmands!'
            },
            six: {
                en: 'And so in 2003, when I was 22, I opened the first chocolate bar in Quebec!',
                fr: 'Et c’est comme cela qu’en 2003, à 22 ans, j’ouvre le premier bar à chocolat au Québec.'
            },
            seven: {
                en: 'On the menu, all the desserts I dreamt of as a little girl, but also chocolate dishes and drinks to showcase this magical ingredient and travel its origins and flavors.',
                fr: 'Au menu, toutes les gourmandises dont je rêvais étant toute petite, mais aussi une multitude de plats et boissons chocolatés, révélant avec finesse toutes les merveilles de cet ingrédient magique, tout en explorant ses origines variées et ses saveurs complexes.'
            },
            eight: {
                en: 'I’m in my restaurants every day, so if you see me please introduce yourself, I’d love to meet you!<br><br> Follow me! <a href="https://www.instagram.com/juliettechoco/" title="Le compte instagram de Juliette">@juliettechoco</a>',
                fr: 'Je suis dans mes restaurants tous les jours, donc si vous me croisez faites-moi signe, je serai ravie de faire votre connaissance! <br><br> Suivez-moi <a href="https://www.instagram.com/juliettechoco/" title="Le compte instagram de Juliette">@juliettechoco</a>'
            }
        }
    }

}
