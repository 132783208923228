import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { NgbAccordion, NgbActiveModal, NgbPanelChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { TranslocoService } from '@ngneat/transloco';

import FAQen from '../../../assets/faq/faq-en.json';
import FAQfr from '../../../assets/faq/faq-fr.json';

@Component({
    selector: 'app-faq',
    templateUrl: './faq.component.html',
    styleUrls: ['./faq.component.css']
})
export class FaqComponent implements OnInit {

    @ViewChildren('faqAccordions') faqAccordions: QueryList<NgbAccordion>

    faq;
    selectedDropdown: string = '';

    constructor(
        public translate: TranslocoService,
        public activeModal: NgbActiveModal
    ) {}

    ngOnInit(): void {
        this.translate.langChanges$.subscribe(lang => {
            if (lang == 'en') this.faq = FAQen;  
            else if (lang == 'fr') this.faq = FAQfr;     
        });
    }

    onDropdownEvent(value: any) {
        if (value == null) return;
        this.selectedDropdown = value;
    }

    beforePanelChange($event : NgbPanelChangeEvent) {
        this.faqAccordions.forEach(accordion => {
            accordion.panels.forEach(panel => {
                if (panel.id !== $event.panelId && panel.isOpen) accordion.collapse(panel.id)
            })
        })
    }

}
