<div class="component-container" #nav
    (scroll)="onScroll()"
    [class.nav-right-scrollable]="rightScrollable"
    [class.nav-left-scrollable]="leftScrollable"
    [class.nav-left-and-right-scrollable]="leftAndRightScrollable">
    <div *ngIf="false" (click)="onClickExpandSections()" class="expand-icon-container">
        <img class="expand-icon" src="https://dummyimage.com/20x20/000/fff" alt="icon to expand the menu sections">
    </div>
    <div class="inner-container" #sectionsContainer id="temp">
        <div *ngFor="let section of sections"
            class="section-container"
            (click)="clickSection(section)"
            [style.border-bottom]="section.id == selectedSection?.id ? '1px solid ' + selectedColor : ''"
            [style.color]="section.id == selectedSection?.id ? selectedColor : ''">
                {{section.title}}
        </div>
        <div class="dummy-section-container"></div>
    </div>
    <div class="arrow-icon-container arrow-right-container"
        (click)="onClickScrollRight()"
        [ngStyle]="getArrowRightContainerStyle()"
        appClickStopPropagation>
        <app-icon [type]="Icon.ChevronRight"
            class="arrow-icon"
            [height]="'1rem'"
            [width]="'1rem'">
        </app-icon>
    </div>
    <div class="arrow-icon-container arrow-left-container"
        (click)="onClickScrollLeft()"
        [ngStyle]="getArrowLeftContainerStyle()"
        appClickStopPropagation>
        <app-icon [type]="Icon.ChevronLeft"
            class="arrow-icon"
            [height]="'1rem'"
            [width]="'1rem'">
        </app-icon>
    </div>
</div>
