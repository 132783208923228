<div class="followup-container">
    <div class="followup-header">
        <h1 class="followup-title">
            {{ 'giftBox.followup.title' | transloco: { firstName: order?.clientName?.first } }}
        </h1>
        <h2 class="followup-subtitle">
            {{ 'giftBox.followup.subtitle' | transloco }}
        </h2>
    </div>

    <div class="followup-body">
        <section class="scheduled-date-container">
            <p class="scheduled-date">{{ 'giftBox.followup.scheduledDate' | transloco }}</p>
            <p class="formatted-date">
                {{ 'giftBox.followup.formattedDate' 
                    | transloco: { date: order?.scheduledDate 
                    | translocoDate: { dateStyle: 'full' } } }}
                <!-- GIFTBOX TODO?: ADD DATE OBJECT -->
                <!-- TODO: see TS idk -->
                <!-- Gift box delivery scheduled for {{formattedDate}}! -->
            </p>
        </section>

        <section class="order-summary">
            <!-- TODO: ngFor logic to display order imgs - see github? -->
            <div class="order-summary-image-container">
                <img src="../../../../assets/images/gift-box/3.jpg" class="order-summary-image" alt="">
            </div>
        </section>
    </div>

    <div class="followup-footer">
        <a class="redirect-link" (click)="navigateToStart()">
            {{ 'giftBox.followup.redirect' | transloco }}
        </a>
        <!-- <button *ngIf="isToRecipient" class="send-email-btn">Send email to recipient?</button>  -->
        <!-- <button class="redirect-btn" (click)="navigateToStart()">
            {{ 'giftBox.followup.redirect' | transloco }}
        </button> -->
    </div>
</div>