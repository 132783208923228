import { Component, Input, OnInit } from '@angular/core';
import { MainService } from 'src/app/core/services/main.service';
import { TranslocoService } from '@ngneat/transloco';
import { UntilDestroy } from '@ngneat/until-destroy';
import { BagItem, Product } from 'src/app/core/models';

@UntilDestroy()
@Component({
	selector: 'app-crate-item',
	templateUrl: './crate-item.component.html',
	styleUrls: ['./crate-item.component.css']
})
export class CrateItemComponent implements OnInit {

	constructor(
		private mainService: MainService,
		public translate: TranslocoService
	) { }
	@Input() item: BagItem;
	@Input() disableDecrement: boolean;

	// @Input() showDecrementControl = true
	// @Input() showIncrementControl = true
	showDecrementControl = true;
	showIncrementControl = true;
	isLoading: boolean = false;

	ngOnInit(): void {
	}

	incrementItem(item) {
		this.isLoading = true;
		this.mainService.incrementItem(this.mainService.crate, item).subscribe(_ => this.isLoading = false);
	}

	decrementItem(item) {
		this.isLoading = true;
		this.mainService.decrementItem(this.mainService.crate, item).subscribe(_ => this.isLoading = false);
	}

	isIncrementDisabled() {
		return this.isLoading;
	}

	isDecrementDisabled(item: BagItem) {
		return this.isLoading || (item.quantity <= 1 && this.disableDecrement);
	}
}
