import { Deserializable } from '../deserializable.model';
import { Event } from './event.model'
import { EventType } from './event.model'
import { Order, OrderStatus } from '../order.model'

export class OrderEvent extends Event implements Deserializable<Event> {
    id: string;
    type: EventType = EventType.OrderActivity;
    createdAt: string;
    order: string | Order;
    status: OrderStatus;
    reason?: {
        code: string;
        notes: string;
    };
    delay?: number;
    source: OrderEventSource;

	deserialize(input: any): this {
        if (!input) return null;
        if (typeof input == 'string') this.id = input;
        else {
            Object.assign(this, input);
            this.id = input._id ? input._id : input.id
        }
		return this;
	}

    get hasInternalStatus(): boolean {
        return [OrderStatus.Assigned, OrderStatus.DriverArriving, OrderStatus.Delayed].includes(this.status);
    }
}

export enum OrderEventSource {
    User = 'user',
    Restaurant = 'restaurant',
    Driver = 'driver',
    System = 'system'
}
