import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { BehaviorSubject, EMPTY, filter, forkJoin, iif, map, mergeMap, Observable, of, switchMap, tap, first, defaultIfEmpty } from 'rxjs';
import { User, Bag, Order, Address, BagItem, Menu, Restaurant, Product, DiscountInstance, OrderEvent, UserStubData, Affiliate } from 'src/app/core/models';
import { AuthService } from './auth.service';
import { UserService, KEY_USER_STUB } from './user.service';
import { waitForRequest } from '../helpers/api-helper';
import { BagService, IProductModification, IProductOptionSelection, KEY_EXPO_BAG, KEY_GIFT_BOX } from './bag.service';
import { OrderRequestData, OrderService } from './order.service';
import { AddressService } from './address.service';
import { ExtendedOrderType, OrderType } from '../enums';
import { RestaurantService } from './restaurant.service';
import { NavigationStart, Router } from '@angular/router';
import { ADDRESS_KEY, generateNewAddressObject } from '../helpers/address.helper';
import { TranslocoLocaleService } from '@ngneat/transloco-locale';
import { SocketService } from './socket.service';
import { isPlatformBrowser } from '@angular/common';
import { CrateService, KEY_CRATE } from './crate.service';
import moment from 'moment';
import { CookieService } from 'ngx-cookie-service';
import { AffiliateService } from './affiliate.service';


export const SCHEDULED_TIME_KEY: string = 'scheduled_time';
export const AFFILIATES_KEY: string = 'affiliates';
@Injectable({
    providedIn: 'root'
})
export class MainService {

    audio: HTMLAudioElement;

    readonly ADDRESS_ID_KEY: string = 'address_id';

    guestBag: Bag = null;
    guestAddress: Address = null;
    //TODO: Clear when logged out
    private userLoadedSource = new BehaviorSubject<User>(null);
    private addressLoadedSource = new BehaviorSubject<Address>(null);
    private addressesLoadedSource = new BehaviorSubject<Address[]>([]);

    private bagLoadedSource = new BehaviorSubject<Bag>(null);
    private crateLoadedSource = new BehaviorSubject<Bag>(null);
    private expoBagLoadedSource = new BehaviorSubject<Bag>(null);
    private giftBoxLoadedSource = new BehaviorSubject<Bag>(null);
    private bagsLoadedSource = new BehaviorSubject<Bag[]>([]);

    private orderLoadedSource = new BehaviorSubject<Order>(null);
    private marketOrderLoadedSource = new BehaviorSubject<Order>(null);
    private expoOrderLoadedSource = new BehaviorSubject<Order>(null);
    private giftBoxOrderLoadedSource = new BehaviorSubject<Order>(null);
    private ordersLoadedSource = new BehaviorSubject<Order[]>([]);

    private orderTypeInViewSource = new BehaviorSubject<OrderType>(<OrderType>'delivery');
    private scheduledDateLoadedSource = new BehaviorSubject<Date>(null);

    private restaurantLoadedSource = new BehaviorSubject<Restaurant>(null);
    private menuLoadedSource = new BehaviorSubject<Menu>(null);
    private bagRestaurantLoadedSource = new BehaviorSubject<Restaurant>(null);
    private bagMenuLoadedSource = new BehaviorSubject<Menu>(null);
    private expoBagRestaurantLoadedSource = new BehaviorSubject<Restaurant>(null);

    private showOrderHereAnimationSubject = new BehaviorSubject<boolean>(true);

    private isSocketConnectedLoadedSource = new BehaviorSubject<boolean>(null); //Whether there is an active connection to a socket
    private isProcessingLoadedSource = new BehaviorSubject<boolean>(true); //Whether there is major data processing going on (e.g. fetching user)
    private isDynamicComponentLoadedSource = new BehaviorSubject<boolean>(false); //Whether the component is dynamic such as a restaurant or product page

    userLoaded$ = this.userLoadedSource.asObservable();
    addressLoaded$ = this.addressLoadedSource.asObservable();
    addressesLoaded$ = this.addressesLoadedSource.asObservable();
    bagLoaded$ = this.bagLoadedSource.asObservable();
    crateLoaded$ = this.crateLoadedSource.asObservable();
    expoBagLoaded$ = this.expoBagLoadedSource.asObservable();
    giftBoxLoaded$ = this.giftBoxLoadedSource.asObservable();
    bagsLoaded$ = this.bagsLoadedSource.asObservable();
    orderLoaded$ = this.orderLoadedSource.asObservable();
    marketOrderLoaded$ = this.marketOrderLoadedSource.asObservable();
    expoOrderLoaded$ = this.expoOrderLoadedSource.asObservable();
    giftBoxOrderLoaded$ = this.giftBoxOrderLoadedSource.asObservable();
    ordersLoaded$ = this.ordersLoadedSource.asObservable();
    orderTypeInViewLoaded$ = this.orderTypeInViewSource.asObservable();
    scheduledDateLoaded$ = this.scheduledDateLoadedSource.asObservable();
    restaurantLoaded$ = this.restaurantLoadedSource.asObservable();
    menuLoaded$ = this.menuLoadedSource.asObservable();
    bagRestaurantLoaded$ = this.bagRestaurantLoadedSource.asObservable();
    bagMenuLoaded$ = this.bagMenuLoadedSource.asObservable();
    expoBagRestaurant$ = this.expoBagRestaurantLoadedSource.asObservable();

    showOrderhereAnimation$ = this.showOrderHereAnimationSubject.asObservable();

    isSocketConnected$ = this.isSocketConnectedLoadedSource.asObservable();
    isProcessing$ = this.isProcessingLoadedSource.asObservable();
    isDynamicComponent$ = this.isDynamicComponentLoadedSource.asObservable();

    isChatActive: boolean = false;

    constructor(
        private translocoService: TranslocoService,
        private translocaleService: TranslocoLocaleService,
        private authService: AuthService,
        private userService: UserService,
        private addressService: AddressService,
        private bagService: BagService,
        private orderService: OrderService,
        private crateService: CrateService,
        private restaurantService: RestaurantService,
        private socketService: SocketService,
        private router: Router,
        private cookieService: CookieService,
        private affiliateService: AffiliateService,
        @Inject(PLATFORM_ID) private platformId: any) {

        this.router.events
            .pipe(filter(event => event instanceof NavigationStart))
            .subscribe((event: NavigationStart) => {
                let restaurant = this.restaurantLoadedSource.getValue();
                if (restaurant && !event.url.includes('/' + restaurant?.slug)) this.restaurantInView = null;
            });

        // this.orderTypeInViewLoaded$.pipe(
        //     combineLatestWith(this.restaurantLoaded$, this.activeMenuLoaded$),
        //     filter(([orderType, restaurant, _]) => orderType != null && restaurant != null),
        //     filter(([orderType, _, menu]) => orderType != menu.type)) //bag Loaded may already take care of this so don't do twice
        //     .subscribe(([orderType, restaurant, _]) => this.menu = restaurant.menus.find(menu => menu.type === orderType));
        if (isPlatformBrowser(platformId)) {
            this.setupOrderUpdateDing();
            window.addEventListener("chatwoot:ready", () => this.isChatActive = true);
        }
    }

    async bootstrap() {
        if (this.authService.loggedIn()) {
            this.refreshUser();

            if (isPlatformBrowser(this.platformId)) {
                this.socketService.connect();

                this.socketService.on('connect_error', (err) => (err.message == 'Token expired') ? this.authService.refreshToken().subscribe(() => this.socketService.connect()) : null);

                this.socketService.on('connect', () => this.isSocketConnectedLoadedSource.next(true));
                this.socketService.on('reconnect', () => this.isSocketConnectedLoadedSource.next(true));
                this.socketService.on('disconnect', () => {
                    setTimeout(() => {
                        if (!this.socketService.isConnected) this.isSocketConnectedLoadedSource.next(false);
                    }, 2000)
                });

                this.socketService.on('response', (data) => {
                    //TODO: If data.error, then handle error
                });

                this.socketService.on('order_update', (data) => {
                    let order = this.order;
                    let event = new OrderEvent().deserialize(data.event);
                    order.events.push(event);
                    if (!event.hasInternalStatus) {
                        this.order.status = event.status;
                        this.audio.play();
                    }
                    this.order = order;
                    if (order.isCompleted) this.order = null;
                });
            }
        }
        else this.fetchGuestData();

        //TODO: Boostrap guest address and bag from localstorage

        let scheduledDateString = localStorage.getItem(SCHEDULED_TIME_KEY);
        this.scheduledDate = scheduledDateString ? new Date(Number(scheduledDateString)) : null;
        // this.affiliates = JSON.parse(localStorage.getItem(AFFILIATES_KEY)) || [];


        //             }
        //             const savedAddress = localStorage.getItem(this.addressService.ADDRESS_KEY)
        //             if (savedAddress) {
        //                 this.addressService.address = new Address().deserialize(JSON.parse(savedAddress));
        //             }

        // setAddressValue() {
        //     if (this.mainService.user) this.addressValue = "";
        //     // else if (localStorage.getItem(this.addressHelper.ADDRESS_KEY)) {
        //     //     let addressKey = JSON.parse(localStorage.getItem(this.addressHelper.ADDRESS_KEY));
        //     //     this.addressValue = addressKey.line1 + ', ' + addressKey.city + ', ' + addressKey.province + ', ' + addressKey.country
        //     // }
        // }
        this.bagLoaded$.subscribe(async bag => {
            //TODO: NO need to switch if already same restaurant
            if (bag == null) this.restaurantOnBag = null;
            else {
                try {
                    if (this.restaurantOnBag?.id != bag.restaurant.id) {
                        if (bag.restaurant.slug) this.restaurantOnBag = await waitForRequest(this.restaurantService.getRestaurant(bag.restaurant.slug));
                        else this.restaurantOnBag = await waitForRequest(this.restaurantService.getRestaurantById(bag.restaurant.id));
                    }
                    this.menuOnBag = this.restaurantOnBag.menus.find(menu => menu.id == bag.menu.id);
                }
                catch (err) {
                    console.log(err);
                }
            }
        });

        // this.crateLoaded$.subscribe(crate => {
        //     if (crate == null) return; //Crate and crates will have been updated elsewhere
        //     let crates = this.bags;
        //     let crateIndex = crates.findIndex(c => c.id = crate.id);
        //     (crateIndex != -1) ? crates[crateIndex] = crate : crates = [crate].concat(crates);
        //     this.bags = crates;
        // });

        this.marketOrderLoaded$.subscribe(marketOrder => {
            if (marketOrder == null) return; //Crate and crates will have been updated elsewhere
            let orders = this.orders;
            let marketOrderIndex = orders.findIndex(o => o.id = this.marketOrder.id);
            (marketOrderIndex != -1) ? orders[marketOrderIndex] = marketOrder : orders = [marketOrder].concat(orders);
            this.orders = orders;
        });

        this.giftBoxOrderLoaded$.subscribe(giftBoxOrder => {
            if (giftBoxOrder == null) return; //Crate and crates will have been updated elsewhere
            let orders = this.orders;
            let giftBoxOrderIndex = orders.findIndex(o => o.id = this.giftBoxOrder.id);
            (giftBoxOrderIndex != -1) ? orders[giftBoxOrderIndex] = giftBoxOrder : orders = [giftBoxOrder].concat(orders);
            this.orders = orders;
        });
    }

    //TODO: expire or expo bag or render into array (or both)
    fetchGuestData() {
        if (localStorage.getItem(KEY_CRATE) != null) this.crateService.fetch(localStorage.getItem(KEY_CRATE)).subscribe(crate => this.crate = crate);
        if (localStorage.getItem(KEY_GIFT_BOX) != null) this.crateService.fetch(localStorage.getItem(KEY_GIFT_BOX), OrderType.GiftBox).subscribe(giftBox => this.giftBox = giftBox);
        if (localStorage.getItem(KEY_EXPO_BAG) != null) this.bagService.fetch(localStorage.getItem(KEY_EXPO_BAG)).subscribe(bag => {
            this.expoBag = bag;
            this.bags = this.bags.concat(bag);
            if (bag != null) this.restaurantOnExpoBag = bag.restaurant;
        });
        if (isPlatformBrowser(this.platformId)) window.addEventListener("chatwoot:ready", () => window['$chatwoot'].setLocale(this.translocoService.getActiveLang()));
        this.isProcessing = false;
    }

    refreshUser() {
        if (!this.authService.loggedIn()) return;
        this.isProcessing = true;
        this.userService.getSelf().pipe(
            mergeMap(user => {
                user?.preferences?.communications?.language ? localStorage.setItem('language', user?.preferences?.communications?.language) : this.setLanguage('en');

                let requests$: Observable<any>[] = [];
                requests$[0] = this.userService.getAddresses();
                requests$[1] = (user.bags?.length > 0) ? this.bagService.list() : of([]);
                requests$[2] = (user.orders?.length > 0) ? this.orderService.list(null, null, true) : of([]);
                requests$[3] = of(user);
                // requests$[4] = (user.market?.crates?.length > 0) ? this.crateService.list() : of([]);
                // requests$[5] = (user.market?.orders?.[0] != null) ? this.orderService.fetchOrder(user.market.orders?.[0]?.id, true) : of(null);
                // requests$[6] = of([]); //TODO: fetch from backend

                return forkJoin(requests$);
            })).subscribe(([addresses, bags, orders, user]: [Address[], Bag[], Order[], User]) => {

                let oldBag = this.bagLoadedSource.getValue();
                this.guestBag = (oldBag?.isGuest && oldBag?.items.length != 0) ? oldBag : null;
                this.guestAddress = this.addressLoadedSource.getValue();

                this.user = user;
                this.bags = bags;
                this.addresses = addresses;
                this.orders = orders;

                let order = orders.find(order => order.isPlatformType);
                this.order = order;
                let bag = bags.find(bag => bag.isPlatformType);
                this.bag = bag;

                if (order != null && bag == null) this.bag = order.bag;
                if (bag) this.orderTypeInView = bag.type;
                else if (order) this.orderTypeInView = <OrderType>order.type;

                let crates = bags.filter(bag => bag.isMarket);
                let crate = this.crate;
                let marketOrders = orders.filter(order => order.isMarket);
                let marketOrder = marketOrders?.length ? marketOrders[0] : null;
                this.marketOrder = marketOrder;

                if (crates.length && crates.some(c => c.id == crate?.id)) this.crate = crates[0];
                else if (crate == null && crates.length) this.crate = crates[0];
                else if (marketOrder != null) this.crate = null;


                let giftBoxes = bags.filter(bag => bag.isGiftBox);
                let giftBox = this.giftBox;
                let giftBoxOrders = orders.filter(order => order.isGiftBox);
                let giftBoxOrder = giftBoxOrders?.length ? giftBoxOrders[0] : null;
                this.giftBoxOrder = giftBoxOrder;

                if (giftBoxes.length && giftBoxes.some(gb => gb.id == giftBox?.id)) this.giftBox = giftBoxes[0];
                else if (giftBox == null && giftBoxes.length) this.giftBox = giftBoxes[0];
                else if (giftBoxOrder != null) this.giftBox = null;

                // let oldExpoBag = this.expoBagLoadedSource.getValue();
                // if (oldExpoBag == null || oldExpoBag.isEmpty) this.expoBag = bags.find(bag => bag.isExpo);
                // if (oldExpoBag != null) this.restaurantOnExpoBag = this.expoBag.restaurant;
                // this.expoOrder = this.orders.find(order => order.isExpo);

                let selectedAddressId = localStorage.getItem(this.ADDRESS_ID_KEY);
                let persistedAddress = addresses.find(address => address.id == selectedAddressId);
                if (this.address == null && persistedAddress) this.address = persistedAddress;
                if (this.address == null && bag?.address) this.address = addresses.find(address => address.id == bag.address.id);
                else if (this.address == null && user?.preferences?.delivery?.preferredAddress) this.address = addresses.find(address => address.isPreferred);

                if (this.isChatActive) {
                    window['$chatwoot'].setLocale(this.translocoService.getActiveLang());
                    window['$chatwoot'].setUser(user.id, {
                        email: user.email,
                        name: user.name.first + ' ' + user.name.last,
                        identifier_hash: user.chatId
                    });
                }
            }).add(() => this.isProcessing = false);
    }

    setupOrderUpdateDing() {
        this.audio = new Audio();
        this.audio.src = '../assets/sounds/order_update.mp3';
        this.audio.load();
    }

    set isProcessing(isProcessing: boolean) {
        this.isProcessingLoadedSource.next(isProcessing);
    }

    set isDynamicComponent(isDynamicComponent: boolean) {
        this.isDynamicComponentLoadedSource.next(isDynamicComponent);
    }

    get isDynamicComponent(): boolean {
        return this.isDynamicComponentLoadedSource.getValue();
    }

    get user() {
        return this.userLoadedSource.getValue();
    }

    set user(user: User) {
        this.userLoadedSource.next(user);
    }

    get address() {
        return this.addressLoadedSource.getValue();
    }

    set address(address: Address) {
        this.addressLoadedSource.next(address);
        if (address?.id) localStorage.setItem(this.ADDRESS_ID_KEY, address.id);
    }

    get addresses() {
        return this.addressesLoadedSource.getValue();
    }

    set addresses(addresses: Address[]) {
        this.addressesLoadedSource.next(addresses);
    }

    get bag(): Bag {
        return this.bagLoadedSource.getValue();
    }

    set bag(bag: Bag) {
        this.bagLoadedSource.next(bag);
    }

    get bags(): Bag[] {
        return this.bagsLoadedSource.getValue();
    }

    set bags(bags: Bag[]) {
        this.bagsLoadedSource.next(bags);
    }

    get order(): Order {
        return this.orderLoadedSource.getValue();
    }

    set order(order: Order) {
        this.orderLoadedSource.next(order);
    }

    get orderTypeInView() {
        return this.orderTypeInViewSource.getValue();
    }

    set orderTypeInView(orderType: OrderType) {
        this.orderTypeInViewSource.next(orderType);
    }

    get scheduledDate(): Date {
        return this.scheduledDateLoadedSource.getValue();
    }

    set scheduledDate(date: Date) {
        this.scheduledDateLoadedSource.next(date)
    }

    get restaurantInView(): Restaurant {
        return this.restaurantLoadedSource.getValue();
    }

    set restaurantInView(restaurant: Restaurant) {
        this.restaurantLoadedSource.next(restaurant);
    }

    get restaurantOnBag(): Restaurant {
        return this.bagRestaurantLoadedSource.getValue();
    }

    set restaurantOnBag(restaurant: Restaurant) {
        this.bagRestaurantLoadedSource.next(restaurant);
    }

    get restaurantOnExpoBag(): Restaurant {
        return this.expoBagRestaurantLoadedSource.getValue();
    }

    set restaurantOnExpoBag(restaurant: Restaurant) {
        this.expoBagRestaurantLoadedSource.next(restaurant);
    }

    get menuInView(): Menu {
        return this.menuLoadedSource.getValue();
    }

    set menuInView(menu: Menu) {
        this.menuLoadedSource.next(menu);
    }

    get menuOnBag(): Menu {
        return this.bagMenuLoadedSource.getValue();
    }

    set menuOnBag(menu: Menu) {
        this.bagMenuLoadedSource.next(menu);
    }

    get crate(): Bag {
        return this.crateLoadedSource.getValue();
    }

    set crate(crate: Bag) {
        this.crateLoadedSource.next(crate);
    }

    get expoBag(): Bag {
        return this.expoBagLoadedSource.getValue();
    }

    set expoBag(expoBag: Bag) {
        this.expoBagLoadedSource.next(expoBag);
    }

    get giftBox(): Bag {
        return this.giftBoxLoadedSource.getValue();
    }

    set giftBox(giftBox: Bag) {
        this.giftBoxLoadedSource.next(giftBox);
    }

    get marketOrder(): Order {
        return this.marketOrderLoadedSource.getValue();
    }

    set marketOrder(marketOrder: Order) {
        this.marketOrderLoadedSource.next(marketOrder);
    }

    get expoOrder(): Order {
        return this.expoOrderLoadedSource.getValue();
    }

    set expoOrder(expoOrder: Order) {
        this.expoOrderLoadedSource.next(expoOrder);
    }

    get giftBoxOrder(): Order {
        return this.giftBoxOrderLoadedSource.getValue();
    }

    set giftBoxOrder(giftBoxOrder: Order) {
        this.giftBoxOrderLoadedSource.next(giftBoxOrder);
    }

    get orders(): Order[] {
        return this.ordersLoadedSource.getValue();
    }

    set orders(orders: Order[]) {
        this.ordersLoadedSource.next(orders);
    }

    hideOrderHereAnimation() {
        this.showOrderHereAnimationSubject.next(false);
    }

    login(email: string, password: string) {
        return this.authService.login({ email, password }).pipe(
            tap(res => {
                this.authService.setTokens(res.token, res.refreshToken)
                this.refreshUser();
                return EMPTY;
            })
        );
    }

    socialLogin(token: string, provider: string) {
        let persistAuth$ = tap((res: any) => {
            this.authService.setTokens(res.token, res.refreshToken)
            this.refreshUser();
            return EMPTY;
        });

        if (provider == 'google') return this.authService.authenticateGoogleIDToken(token).pipe(persistAuth$);
        else return this.authService.socialLogin(token, provider).pipe(persistAuth$);
    }

    logout() {
        this.user = null;
        this.bag = null;
        this.crate = null;
        this.bags = [];
        // this.crates = [];
        this.address = null;
        this.addresses = [];
        this.order = null;
        this.marketOrder = null;
        this.expoOrder = null;
        this.orders = [];
        // this.marketOrders = [];
        this.restaurantOnBag = null;
        this.restaurantOnExpoBag = null;
        this.menuOnBag = null;

        this.authService.logout();
        if (this.isChatActive) window['$chatwoot'].reset();
        window.location.reload();
    }

    register(registrationData) {
        return this.authService.register(registrationData).pipe(
            tap(res => {
                this.authService.setTokens(res.token, res.refreshToken)
                this.refreshUser();
                return EMPTY;
            })
        );
    }

    setLanguage(language: string) {
        this.translocoService.setActiveLang(language);
        this.translocaleService.setLocale(language + '-CA');
        localStorage.setItem('language', language);
        if (this.isChatActive) window['$chatwoot'].setLocale(language);
        return (this.authService.loggedIn()) ? this.userService.setLanguage(language) : of();
    }

    persistGuestData(isBagRetained: boolean): Observable<any> {
        return this.isProcessing$.pipe(filter(isProcessing => isProcessing == false), first(), mergeMap(_ => {
            if (this.crate && this.crate.isGuest) return this.crateService.assignCrateToSelf(this.crate.id);
            else return of(true);
        }),
            mergeMap(_ => {
                this.isProcessing = true;
                let guestBagAssignment$;
                let guestAddressAsignment$;

                if (isBagRetained == false) this.guestBag = null;
                else if (this.guestBag != null) {
                    guestBagAssignment$ = this.bagService.assignBagToSelf(this.guestBag).pipe(
                        // mergeMap(_ => iif(() => (this.guestBag.type == OrderType.Pickup), this.bagService.assignAddressToBag(this.restaurantOnBag.address.id), of())),
                        tap(_ => {
                            this.orderTypeInView = this.guestBag.type;
                            this.bag = this.guestBag;
                            this.guestBag = null;
                        }));
                }

                if (this.guestAddress) { //TODO: bag assignment and address assignment can be parallelized, followed by bag address assignment
                    guestAddressAsignment$ = this.updateAddress(this.guestAddress);//this.userService.assignAddressToUser(address);
                    // if (guestBagAssignment$ != null) guestBagAddressAssignment$ = this.bagService.assignAddressToBag(address.id);
                }

                if (guestBagAssignment$ != null && guestAddressAsignment$ != null) {
                    return guestBagAssignment$.pipe(mergeMap(_ => guestAddressAsignment$), tap(_ => this.isProcessing = false))
                    // forkJoin(guestBagAssignment$, guestAddressAsignment$).subscribe(res => {
                    //     console.log(res)
                    //     console.log('persisted')
                    // }).add(() => this.isProcessing = false);
                }
                else if (guestBagAssignment$ != null) return guestBagAssignment$.pipe(tap(_ => this.isProcessing = false))
                else if (guestAddressAsignment$ != null) return guestAddressAsignment$.pipe(tap(_ => this.isProcessing = false))
                else {
                    this.isProcessing = false;
                    return of(EMPTY);
                }
            }));
    }

    fetchUserStub(): UserStubData {
        try {
            return JSON.parse(localStorage.getItem(KEY_USER_STUB)) as UserStubData;
        }
        catch (err) {
            console.log(err);

        }
    }
    //TODO: Refactor
    generateUserStub(firstName?: string, lastName?: string, email?: string, number?: string, firstTimePromotion?: boolean) {
        try {
            let userStub = JSON.parse(localStorage.getItem(KEY_USER_STUB));
            if (firstName == null && (email == null || number == null) && userStub == null) return null;
            if (userStub == null) {
                userStub = {
                    name: {
                        first: firstName,
                    },
                    number: number,
                    email: email,
                    language: this.translocoService.getActiveLang(),
                    firstTimePromotion: firstTimePromotion
                }
                if (lastName) userStub.name.lastName = lastName
            }
            else {
                if (firstName) userStub.name.first = firstName;
                if (lastName) userStub.name.last = lastName;
                if (email) userStub.email = email;
                if (number) userStub.number = number;
                if (firstTimePromotion != null) userStub.firstTimePromotion = firstTimePromotion;
            }
            return userStub;
        }
        catch (err) {
            console.log(err);
        }
    }

    setPreferredAddress(address: Address) {
        let addresses = this.addresses;
        for (let a of addresses) { //Show changes on frontend prior to calling API to give impression of haste
            a.isPreferred = (a.id == address.id) ? true : false;
        }
        this.addressService.updateAddress(address.id, null, null, true).subscribe(_ => this.refreshUser());
    }

    //TODO: Should handle case that backend address is not within range and throw to component
    updateAddress(address: Address): Observable<Address> {
        let existingAddress = this.addresses.find(a => a.id == address.id);
        if (existingAddress == null) existingAddress = this.addresses.find(a => a.line1 == address.line1 && a.line2 == address.line2 && a.apt == address.apt);
        if (existingAddress) address = existingAddress;
        let addressRequest$ = (existingAddress == null && this.authService.loggedIn()) ?
            this.userService.assignAddressToUser(address).pipe(
                map(userAddress => new Address().deserialize({ id: userAddress.id, ...address })),
                tap(userAddress => this.addresses.push(userAddress))) :
            of(address).pipe(tap(address => localStorage.setItem(ADDRESS_KEY, JSON.stringify(generateNewAddressObject(address)))));

        return addressRequest$.pipe(tap(address => this.address = address), mergeMap(persistedAddress => {
            let requests$: Observable<any>[] = [];
            if (this.authService.loggedIn()) {
                if (this.bag != null) {
                    requests$.push(this.bagService.assignAddressToBag(this.bag.id, persistedAddress.id).pipe(tap(_ => {
                        let bag = this.bag; //TODO: doesn't update bag address for guest, but not necessary for time being
                        bag.address = persistedAddress;
                        this.bag = bag;
                    })))
                }
                if (this.crate != null) {
                    requests$.push(this.crateService.assignAddressToCrate(this.crate.id, persistedAddress.id).pipe(tap(_ => {
                        let crate = this.crate; //TODO: doesn't update bag address for guest, but not necessary for time being
                        crate.address = persistedAddress;
                        this.crate = crate;
                    })))
                }
            }
            return forkJoin(requests$).pipe(
                defaultIfEmpty(null)
            ).pipe(mergeMap(_ => of(persistedAddress)));
        }));

        // iif(() => (this.authService.loggedIn() && this.bag != null),
        // this.bagService.assignAddressToBag(this.bag.id, persistedAddress.id).pipe(tap(_ => {
        //     let bag = this.bag; //TODO: doesn't update bag address for guest, but not necessary for time being
        //     bag.address = persistedAddress;
        //     this.bag = bag;
        // }), 
        // switchMap(_ => of(persistedAddress))), of(address))));
    }

    updateGiftBoxAddress(address: Address): Observable<void> {
        return this.crateService.assignAddressToCrate(this.giftBox.id, null, OrderType.GiftBox, address);
    }

    persistBag(bag: Bag) {
        if (bag.isGiftBox) this.giftBox = bag;
        else if (bag.isMarket) this.crate = bag;
        else if (bag.isExpo) this.expoBag = bag;
        else this.bag = bag;
    }

    createBag(menu: Menu, restaurant: Restaurant, expositionId?: string): Observable<Bag> {
        //TODO: Validation/Error handling

        let address;
        switch (this.orderTypeInView) {
            case OrderType.Delivery:
                address = this.address;
                break;
            case OrderType.Pickup:
                address = restaurant.address;
            default:
                break;
        }
        // let address = (this.orderTypeInView == <OrderType>'delivery') ? this.address : restaurant.address;
        return this.bagService.createBag(restaurant.slug, menu.id, ((this.authService.loggedIn() && expositionId == null) ? address?.id : null), expositionId)
            .pipe(tap(bag => {
                if (bag.isPlatformType) {
                    this.restaurantOnBag = restaurant;
                    this.bag = bag;
                }
                else if (bag.isExpo) {
                    this.restaurantOnExpoBag = restaurant;
                    this.expoBag = bag;
                }
                this.bags.unshift(bag);
            }));
    }

    createCrate(): Observable<Bag> {
        let address = this.address;
        return this.crateService.create((this.authService.loggedIn()) ? address?.id : null)
            .pipe(tap(crate => {
                this.crate = crate;
                this.bags.unshift(crate);
            }));
    }

    createGiftBox(): Observable<Bag> {
        return this.crateService.create(null, OrderType.GiftBox)
            .pipe(tap(giftBox => {
                this.giftBox = giftBox;
                this.bags.unshift(giftBox);
            }));
    }

    //TODO: Unify
    deleteBag(): Observable<void> {
        if (this.authService.loggedIn()) return this.bagService.deleteBag(this.bag.id).pipe(tap(_ => {
            this.bags = this.bags.filter(bag => bag.id != this.bag.id);
            this.bag = null;
        }));
        else return of(this.bagService.deleteNonUserBag());
    }

    deleteExpoBag(): Observable<void> {
        if (this.authService.loggedIn()) return this.bagService.deleteBag(this.expoBag.id).pipe(tap(_ => {
            this.bags = this.bags.filter(bag => bag.id != this.expoBag.id);
            this.expoBag = null;
            this.restaurantOnExpoBag = null;
        }));
        else {
            this.expoBag = null;
            this.restaurantOnExpoBag = null;
            return of(this.bagService.deleteNonUserExpoBag())
        };
    }

    deleteCrate(): Observable<void> {
        return this.crateService.delete(this.crate.id, this.authService.loggedIn()).pipe(tap(_ => {
            this.bags = this.bags.filter(crate => crate.id != this.crate.id);
            this.crate = null;
        }));
    }

    deleteGiftBox(): Observable<void> {
        return this.crateService.delete(this.giftBox.id, this.authService.loggedIn()).pipe(tap(_ => {
            this.bags = this.bags.filter(giftBox => giftBox.id != this.giftBox.id);
            this.giftBox = null;
        }));
    }

    recreateBag(menu: Menu, restaurant: Restaurant, expositionId?: string): Observable<Bag> {
        return this.deleteBag().pipe(mergeMap(_ => this.createBag(menu, restaurant, expositionId)));
    }

    addCrateItem(product: Product, quantity: number, notes?: string, options?: IProductOptionSelection[]): Observable<Observable<never>> {
        //TODO: Why does this trigger right away? Defining it first makes it impossible to use with future state
        // let addBagItemRequest$ = this.addBagItem(product, quantity, notes, options, true).pipe(map(_ => EMPTY));
        let crate = this.crate;
        if (crate == null) return this.createCrate().pipe(mergeMap(crate => this.addBagItem(crate, product, quantity, notes, options).pipe(map(_ => EMPTY))));

        //TODO: match options as well
        let crateItem = crate.items.find(item => item.product.id == product.id);
        if (crateItem) return this.modifyBagItem(crate, crateItem, quantity).pipe(map(_ => EMPTY))
        else return this.addBagItem(crate, product, quantity, notes, options).pipe(map(_ => EMPTY));
    }

    addGiftBoxItem(product: Product, quantity: number = 1): Observable<Observable<never>> {
        let giftBox = this.giftBox;
        if (giftBox == null) return this.createGiftBox().pipe(mergeMap(giftBox => this.addBagItem(giftBox, product, quantity, null, null).pipe(map(_ => EMPTY))));

        let giftBoxItem = giftBox.items.find(item => item.product.id == product.id);
        if (giftBoxItem) return this.modifyBagItem(giftBox, giftBoxItem, quantity).pipe(map(_ => EMPTY))
        else return this.addBagItem(giftBox, product, quantity, null, null).pipe(map(_ => EMPTY));
    }

    addBagItem(bag: Bag, product: Product, quantity: number, notes: string, options: IProductOptionSelection[]): Observable<Bag> {
        let service = !(bag.isMarket || bag.isGiftBox) ? this.bagService : this.crateService;
        return service.addItem(bag.id, product, quantity, notes, options, bag.type).pipe(tap(res => this.persistBag(res)));
    }

    addBagItems(bag: Bag, items: BagItem[]): Observable<Bag> {
        let productModifications: IProductModification[] = [];

        for (let item of items) {
            let pm: IProductModification = {
                productId: item.product.id,
                quantity: item.quantity,
                notes: item.notes
            };

            if (item.options) {
                let options: IProductOptionSelection[] = [];
                for (let option of item.options) {
                    for (let product of option.products) {
                        options.push({
                            productId: product.id,
                            id: option.id
                        })
                    }
                }
                pm.options = options;
            }
            productModifications.push(pm);
        }
        return this.bagService.addItems(bag.id, productModifications).pipe(tap(res => this.persistBag(res)));
    }

    modifyBagItem(bag: Bag, item: BagItem, quantityDelta: number) {
        let productModifications: IProductModification[] = [{
            instanceId: item.id,
            productId: item.product.id,
            quantity: item.quantity + quantityDelta,
        }];

        if (item.options) {
            let options: IProductOptionSelection[] = [];
            for (let option of item.options) {
                for (let product of option.products) {
                    options.push({
                        productId: product.id,
                        id: option.id
                    })
                }
            }
            productModifications[0].options = options;
        }

        let service = !(bag.isMarket || bag.isGiftBox) ? this.bagService : this.crateService;

        return service.modify(bag.id, productModifications, bag.type)
            .pipe(tap(res => { //TODO: Refactor
                if (bag.isPlatformType) {
                    if (this.order) this.orderService.fetchOrder(this.order.id).subscribe(order => {
                        this.bag = res; //Assigning bag before the fetch will cause the UI to update twice
                        this.order = order;
                    });
                    else this.bag = res;
                }
                else if (bag.isMarket) {
                    if (this.marketOrder) this.orderService.fetchOrder(this.marketOrder.id).subscribe(order => {
                        this.crate = res; //Assigning bag before the fetch will cause the UI to update twice
                        this.marketOrder = order;
                    });
                    else this.crate = res;
                }
                else if (bag.isExpo) {
                    if (this.expoOrder) this.orderService.fetchOrder(this.expoOrder.id).subscribe(order => {
                        this.expoBag = res; //Assigning bag before the fetch will cause the UI to update twice
                        this.expoOrder = order;
                    });
                    else this.expoBag = res;
                }
                else if (bag.isGiftBox) {
                    if (this.giftBoxOrder) this.orderService.fetchOrder(this.giftBoxOrder.id).subscribe(order => {
                        this.giftBox = res; //Assigning bag before the fetch will cause the UI to update twice
                        this.giftBoxOrder = order;
                    });
                    else this.giftBox = res;
                }
            }));
    }

    removeItem(bag: Bag, bagItem: BagItem) {
        return this.bagService.removeItem(bag.id, bagItem.id).pipe(tap(res => this.persistBag(res)));
    }

    removeItems(bag: Bag, items: BagItem[]): Observable<Bag> {
        let productModifications: IProductModification[] = [];
        items.forEach(item => {
            productModifications.push({
                instanceId: item.id,
                productId: item.product.id,
                quantity: 0
            });
        });
        return this.bagService.modify(bag.id, productModifications).pipe(tap(res => this.persistBag(res)));
    }

    //TODO: Handle expo 
    switchBagMenu(menu: Menu): Observable<any> {
        this.orderTypeInView = <OrderType>menu.type;

        let restaurant = this.restaurantOnBag;
        let retainedBagItems = this.bag.getItemsInMenu(menu);
        //let retainedNotes = this.bag.notes;
        let address = (this.orderTypeInView == <OrderType>'delivery') ? this.address : restaurant.address;

        let bagDeletionRequest$ = !this.authService.loggedIn() ? of(this.bagService.deleteNonUserBag()) : this.bagService.deleteBag(this.bag.id);

        return bagDeletionRequest$.pipe(
            mergeMap(_ => this.bagService.createBag(restaurant.slug, menu.id, (address && this.authService.loggedIn()) ? address.id : null)),
            map(bag => new Bag().deserialize(bag)),
            mergeMap(bag => iif(() => retainedBagItems?.length > 0, this.addBagItems(bag, retainedBagItems), of(bag))),
            // mergeMap(bag => iif(() => !!retainedNotes, this.bagService.addNoteToBag(retainedNotes), of(bag))),
            // mergeMap(bag => iif(() => this.address != null, this.bagService.assignAddressToBag(this.address?.id).pipe(map(_ => bag)), of(bag))),
            map(bag => {
                bag.restaurant = restaurant;
                return bag;
            }),
            tap(bag => this.bag = bag));
    }

    incrementItem(bag: Bag, item: BagItem | Product): Observable<Bag> {
        if (item instanceof Product) {
            //TODO: match options as well
            item = bag.items.find(i => i.product.id == item.id);
        }
        return this.modifyBagItem(bag, item, 1);
    }

    decrementItem(bag: Bag, item: BagItem | Product): Observable<Bag> {
        if (item instanceof Product) {
            //TODO: match options as well
            item = bag.items.find(i => i.product.id == item.id);
        }
        return this.modifyBagItem(bag, item, -1);
    }

    removeUnavailableAndArchivedItemsFromBag(): Observable<BagItem[]> {
        let productModifications: IProductModification[] = [];
        let removedItems: BagItem[] = [];
        this.bag.items.filter(item => !item.product.isAvailable || item.product.isArchived)
            .forEach(item => {
                productModifications.push({
                    instanceId: item.id,
                    productId: item.product.id,
                    quantity: 0
                });
                removedItems.push(item);
            });
        return this.bagService.modify(this.bag.id, productModifications).pipe(tap(bag => this.bag = bag), map(_ => removedItems));
    }

    removeUnavailableAndArchivedOptionsFromBag(): Observable<BagItem[]> {
        let productModifications: IProductModification[] = [];
        let removedItems: BagItem[] = [];
        this.bag.items.forEach(item => {
            let options = []
            item.options.forEach(option => {
                options = option.products.filter(product => !product.isAvailable || product.isArchived).map(product => product.title);
            });
            if (options.length > 0) {
                productModifications.push({
                    instanceId: item.id,
                    productId: item.product.id,
                    quantity: 0
                });
                removedItems.push(item)
            }
        });
        return this.bagService.modify(this.bag.id, productModifications).pipe(tap(bag => this.bag = bag), map(_ => removedItems));
    }

    visitRestaurant(slug: string, type?: ExtendedOrderType): Observable<Restaurant> {
        this.isDynamicComponent = true;
        return this.restaurantService.getRestaurant(slug, type)
            .pipe(
                tap(restaurant => this.restaurantInView = restaurant),
                tap(restaurant => this.menuInView = restaurant.menus.find(menu => menu.type == this.orderTypeInView))
            )
    }

    initializeOrder(bag: Bag, data: OrderRequestData): Observable<any> {
        // if (bag == null) return of(); //TODO: prefer leaving null for now so backend be aware of error
        if (!(bag?.isMarket || bag?.isGiftBox)) data.scheduledDate = this.scheduledDate?.toISOString();
        if (bag?.isMarket) {
            data.bagId = this.crate.id;
            if (!data.scheduledDate) data.scheduledDate = ((moment().isBefore(moment().startOf('isoWeek').day(5))) ? moment().startOf('isoWeek').day(6) : moment().startOf('isoWeek').day(6 + 7)).toISOString();
        }
        else if (bag?.isPlatformType) data.bagId = this.bag.id;
        else if (bag?.isExpo) data.bagId = this.expoBag.id;
        else if (bag?.isGiftBox) data.bagId = this.giftBox.id;
        return this.orderService.initializeOrder(data).pipe(tap(res => {
            if (bag.isPlatformType) {
                this.order = res.order;
                this.bag = res.order.bag;
            }
            else if (bag.isMarket) {
                this.marketOrder = res.order;
                this.crate = res.order.bag;
            }
            else if (bag.isExpo && !data.isVirtualized) {
                this.expoOrder = res.order;
                this.expoBag = res.order.bag;
            }
            else if (bag.isGiftBox && !data.isVirtualized) {
                this.giftBoxOrder = res.order;
                this.giftBox = res.order.bag;
            }
            if (res.order.userStub) {
                localStorage.setItem(KEY_USER_STUB, JSON.stringify(res.order.userStub));
            }
        }));
    }

    confirmOrder(bag: Bag): Observable<any> {
        return this.orderService.confirmOrder(bag.id).pipe(tap(res => {
            this.persistOrder(res.order);
            this.persistBag(res.order.bag)
        }));
    }

    applyDiscount(code: string, order: Order): Observable<DiscountInstance> {
        return this.orderService.applyDiscount(code, order.id).pipe(tap(discountInstance => this.updateOrderFromDiscount(discountInstance, order, false)));
    }

    deleteDiscount(discountId: string, order: Order): Observable<any> {
        return this.orderService.deleteDiscount(discountId, order.id).pipe(tap(discountInstance => this.updateOrderFromDiscount(discountInstance, order, true)));
    }

    updateOrderFromDiscount(discountInstance: DiscountInstance, order: Order, remove: boolean) {
        if (remove) order.discounts = order.discounts.filter(di => di.id != discountInstance.id);
        else {
            if (order.discounts.find(di => di.id == discountInstance.id)) return;
            order.discounts.push(discountInstance);
        }
        this.persistOrder(order);
    }

    persistOrder(order: Order) {
        if (order.isPlatformType) this.order = order;
        else if (order.isMarket) this.marketOrder = order;
        else if (order.isExpo) this.expoOrder = order;
        else if (order.isGiftBox) this.giftBoxOrder = order;
        return;
    }

    persistAffiliate(affiliate: Affiliate) {
        if (affiliate == null) return;
        this.cookieService.set(`aid_${affiliate.type}`, affiliate.id, 7, '/');
    }

    fetchAffiliate(type: OrderType): Observable<Affiliate> {
        let id;
        let cookies = this.cookieService.getAll();
        for (let cookie of Object.entries(cookies)) {
            if (cookie[0] == `aid_${type}`) {
                id = cookie[1];
                break;
            }
        }
        return this.affiliateService.fetch(id);
    }

    listenForOrderUpdates(): void {
        this.socketService.emit('track_order_user', {});
    }
}
