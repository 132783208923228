import { isPlatformBrowser } from '@angular/common';
import { Component, ElementRef, EventEmitter, HostListener, Inject, OnInit, Output, PLATFORM_ID, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { finalize } from 'rxjs';
import { Address, User } from 'src/app/core/models';
import { KEY_GIFT_BOX } from 'src/app/core/services/bag.service';
import { MainService } from 'src/app/core/services/main.service';
import { OrderRequestData } from 'src/app/core/services/order.service';
import { environment } from 'src/environments/environment';
import { DatePipe } from '@angular/common';
import { Options } from 'ngx-google-places-autocomplete/objects/options/options';
import { parseGoogleAddress } from 'src/app/core/helpers/address.helper';
import { Address as GoogleAddress } from 'ngx-google-places-autocomplete/objects/address';
import moment from 'moment';

declare var Stripe;


export enum GiftBoxCheckoutFormPage {
	'Sender',
	'Recipient',
	'Address',
	'Payment'
}

@UntilDestroy()
@Component({
	selector: 'app-gift-box-checkout',
	templateUrl: './checkout.component.html',
	styleUrls: ['./checkout.component.css']
})
export class GiftBoxCheckoutComponent implements OnInit {

	GiftBoxCheckoutFormPage = GiftBoxCheckoutFormPage;

	@Output() formCompleted = new EventEmitter<boolean>();
	@ViewChild('cardElement') set ce(cardElement: ElementRef) {
		this.cardElement = cardElement;
	}
	checkoutForm: UntypedFormGroup;
	address: Address; //TODO: should be hidden field in form

	isSubmitting: boolean = false;

	isGift: Boolean = false;
	cardMessage: Boolean = false;

	mobileThreshold: number = 576;
	screenWidth: number;
	isMobile: boolean;
	isLoading: boolean = false;

	pageState: GiftBoxCheckoutFormPage = null;

	cardElement: ElementRef;
	cardNumberElement: ElementRef;
	cardExpiryElement: ElementRef;
	cardCvcElement: ElementRef;
	cardPostalCodeElement: ElementRef;

	stripe;
	card;
	savedCards = [];
	cardErrors: string[];
	isCardEmpty: boolean = true;

	isBrowser: boolean;

	minDate: string;

	autocompleteOptions: Options;

	constructor(
		@Inject(PLATFORM_ID) platformId: Object,
		private formBuilder: UntypedFormBuilder,
		private router: Router,
		private translate: TranslocoService,
		private mainService: MainService,
		private datePipe: DatePipe
	) {
		this.isBrowser = isPlatformBrowser(platformId);

		this.checkoutForm = this.formBuilder.group({
			sender: this.formBuilder.group({
				first: ['', [Validators.required, Validators.min(2), Validators.max(128)]],
				last: ['', [Validators.required, Validators.min(2), Validators.max(128)]],
				number: ['', [Validators.required, Validators.pattern('[- +()0-9]{6,}')]],
				email: ['', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
			}),
			recipient: this.formBuilder.group({
				isSender: [false, [Validators.required]],
				first: ['', [Validators.required, Validators.min(2), Validators.max(128)]],
				last: ['', [Validators.required, Validators.min(2), Validators.max(128)]],
				number: ['', [Validators.required, Validators.pattern('[- +()0-9]{6,}')]],
				email: ['', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
			}),
			address: this.formBuilder.group({
				line1: ['', Validators.required],
				line2: ['', [Validators.max(128)]],
				postal: ['', Validators.required],
				scheduledDate: ['', Validators.required],
				notes: ['', Validators.max(500)],
				// deliveryCardMessage: ['', Validators.required],
			}),
			payment: this.formBuilder.group({
				cardRadio: ['', Validators.required]
			})
		});

		this.autocompleteOptions = new Options({ componentRestrictions: { country: 'ca' }, bounds: new google.maps.LatLngBounds(new google.maps.LatLng(45, -75), new google.maps.LatLng(46, -73)) });
	}

	ngOnInit(): void {
		this.mainService.isProcessing$.pipe(untilDestroyed(this)).subscribe(isProcessing => {
			if (isProcessing) return;
			if (!this.mainService.giftBox || this.mainService.giftBox.isEmpty) this.router.navigate([this.translate.getActiveLang(), 'cadeaux']);
			else if (this.mainService.user != null) this.pageState = GiftBoxCheckoutFormPage.Recipient;
			else this.pageState = GiftBoxCheckoutFormPage.Sender;
		});
		this.isMobile = window.innerWidth < this.mobileThreshold;


		// HANDLE DATE CONSTRICTION
		const threeDays = new Date();
		threeDays.setDate(threeDays.getDate() + 3);
		const hardDate = new Date("2023-12-5");
		const minDate = new Date();

		if (hardDate >= threeDays) {
			minDate.setDate(hardDate.getDate());
		} else {
			minDate.setDate(threeDays.getDate());
		}

		this.checkoutForm.get('address.scheduledDate').setValidators(this.dateValidator(minDate));

		minDate.setDate(minDate.getDate());
		this.minDate = this.datePipe.transform(minDate, "yyyy-MM-dd");
	}

	ngAfterViewInit(): void {
		if (!this.isBrowser) return
	}

	setupPayment() {
		setTimeout(() => {
			this.stripe = Stripe(environment.STRIPE_KEY, { locale: this.translate.getActiveLang() });
			const elements = this.stripe.elements();

			this.card = elements.create('card', {
				style: {
					base: {
						fontWeight: 500,
						fontFamily: 'mr-eaves-xl-modern, sans-serif',
						fontSize: '1.25rem'
					}
				}
			});
			this.card.mount(this.cardElement.nativeElement);
			this.card.addEventListener('change', ({ error }) => this.cardErrors = error && error.message);
			this.card.addEventListener('focus', async () =>
				this.checkoutFormControls.payment.get('cardRadio').setValue('new-card')
			);
			this.card.on('change', (event) => this.isCardEmpty = event.empty);
		}, 75);
	}

	initializeOrder(data) {
		return this.mainService.initializeOrder(this.mainService.giftBox, data ? data : { isVirtualized: true }).pipe(finalize(() => { }))
	}

	createBillingDetails(user: User, userStub, address: Address) {
		let validUser = user ? user : userStub;
		let name = user ? `${user.name.first} ${user.name.last}` : userStub.name.first;
		let billing_details: any = {
			name: name
		};
		if (validUser.number && validUser.number != '') billing_details.phone = validUser.number;
		if (validUser.email && validUser.email != '') billing_details.email = validUser.email;
		if (address) billing_details.address = {
			city: 'Montréal',
			country: 'CA',
			line1: address.line1,
			line2: address.line2,
			postal_code: address.postal,
			state: 'QC'
		}
		return billing_details;
	}

	captureStripePayment(order, secret: string, orderData: OrderRequestData, cardData) {

		let payment_method = {
			card: cardData,
			billing_details: this.createBillingDetails(order.user, orderData.userStub, order.bag.address)
		};

		this.stripe.confirmCardPayment(secret, { payment_method, setup_future_usage: 'off_session' })
			.then(
				(res: any) => {
					if (res.error) this.cardErrors = res.error.message;
					else {
						if (res.paymentIntent.status === 'requires_capture' || res.paymentIntent.status === 'succeeded') {
							//TODO: Refactor, state should be handled in main service
							// let id = this.mainService.giftBoxOrder.id;
							localStorage.removeItem(KEY_GIFT_BOX);
							this.mainService.giftBox = null;
							this.mainService.giftBoxOrder = null;
							this.router.navigate([this.translate.getActiveLang() + '/cadeaux/orders/' + order.id]);
							this.isLoading = false;
						}
					}
				},
				(err: any) => {
					alert(err)
					//TODO: handle
				}
			);
	}

	submitOrder() {
		this.isLoading = true;
		// let userStub = this.mainService.fetchUserStub();
		let data = this.generateOrderData();
		this.initializeOrder(data).subscribe({
			next: res => this.captureStripePayment(res.order, res.secret, data, this.card),
			error: (err) => {
				console.log(err)
				//TODO: Use modal
				alert(err.error?.error)
				this.isLoading = false;
			}
		})
	}

	generateOrderData() {
		let orderData: OrderRequestData = {};
		let senderData = this.checkoutForm.get('sender').value;
		if (!this.mainService.user) {
			orderData.userStub = {};
			orderData.userStub.name = {
				first: senderData.first,
				last: senderData.last
			};
			orderData.userStub.email = senderData.email;
			orderData.userStub.number = senderData.number;
			orderData.userStub.language = this.translate.getActiveLang();
		}
		let recipientData = this.checkoutForm.get('recipient').value;
		if (!recipientData.isSender) {
			orderData.recipientStub = {};
			orderData.recipientStub.name = {
				first: recipientData.first,
				last: recipientData.last
			};
			orderData.recipientStub.email = recipientData.email;
			orderData.recipientStub.number = recipientData.number;
			orderData.recipientStub.language = this.translate.getActiveLang();
		}

		let date = moment(this.checkoutForm.get('address').value.scheduledDate);
		orderData.scheduledDate = date.toISOString();
		return orderData;
	}

	async handleForm(e) {
		e.preventDefault();
		// this.isSubmitting = true;
		// this.disableButtons();
		// this.submitOrder();
	}

	changeCardMessageStatus() {
		this.cardMessage = !this.cardMessage;
	}

	@HostListener('window:resize', ['$event'])
	onResize(event?) {
		this.screenWidth = window.innerWidth;
		this.isMobile = window.innerWidth < this.mobileThreshold;
	}

	get checkoutFormControls() {
		return this.checkoutForm.controls;
	}

	get canProceedToNextPage() {
		if (this.isLoading) return false;
		switch (this.pageState) {
			case GiftBoxCheckoutFormPage.Payment:
				return this.checkoutForm.get('payment').valid;
			case GiftBoxCheckoutFormPage.Address:
				return this.checkoutForm.get('address').valid;
			case GiftBoxCheckoutFormPage.Recipient:
				return this.checkoutForm.get('recipient').valid;
			case GiftBoxCheckoutFormPage.Sender:
				return this.checkoutForm.get('sender').valid;
			default:
				return false;
		}
	}

	toggleRecipientToSender(values) {
		let isSender = values.currentTarget.checked;
		if (isSender) { //dumb af but this is the angular way -mans
			this.checkoutForm.get('recipient.first').clearValidators();
			this.checkoutForm.get('recipient.last').clearValidators();
			this.checkoutForm.get('recipient.number').clearValidators();
			this.checkoutForm.get('recipient.email').clearValidators();
			this.checkoutForm.get('recipient.first').disable();
			this.checkoutForm.get('recipient.last').disable();
			this.checkoutForm.get('recipient.number').disable();
			this.checkoutForm.get('recipient.email').disable();
		}
		else { //TODO: Extract patterns and reuse
			this.checkoutForm.get('recipient.first').setValidators([Validators.required, Validators.min(2), Validators.max(128)]);
			this.checkoutForm.get('recipient.last').setValidators([Validators.required, Validators.min(2), Validators.max(128)]);
			this.checkoutForm.get('recipient.number').setValidators([Validators.required, Validators.pattern('[- +()0-9]{6,}')]);
			this.checkoutForm.get('recipient.email').setValidators([Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]);
			this.checkoutForm.get('recipient.first').enable();
			this.checkoutForm.get('recipient.last').enable();
			this.checkoutForm.get('recipient.number').enable();
			this.checkoutForm.get('recipient.email').enable();
		}
		this.checkoutForm.get('recipient.first').updateValueAndValidity();
		this.checkoutForm.get('recipient.last').updateValueAndValidity();
		this.checkoutForm.get('recipient.number').updateValueAndValidity();
		this.checkoutForm.get('recipient.email').updateValueAndValidity();
	}

	stepForm() {
		switch (this.pageState) {
			case GiftBoxCheckoutFormPage.Payment:
				this.submitOrder()
				break;
			case GiftBoxCheckoutFormPage.Address:
				this.mainService.updateGiftBoxAddress(this.getFormattedAddress()).subscribe(_ => {
					this.pageState = GiftBoxCheckoutFormPage.Payment;
					this.setupPayment();
				});
				break;
			case GiftBoxCheckoutFormPage.Recipient:
				this.pageState = GiftBoxCheckoutFormPage.Address;
				break;
			case GiftBoxCheckoutFormPage.Sender:
				this.pageState = GiftBoxCheckoutFormPage.Recipient;
				break;
			default:
				return;
		}
	}

	stepBackForm() {
		switch (this.pageState) {
			case GiftBoxCheckoutFormPage.Payment:
				this.pageState = GiftBoxCheckoutFormPage.Address;
				break;
			case GiftBoxCheckoutFormPage.Address:
				this.pageState = GiftBoxCheckoutFormPage.Recipient;
				break;
			case GiftBoxCheckoutFormPage.Recipient:
				this.pageState = GiftBoxCheckoutFormPage.Sender;
				break;
			case GiftBoxCheckoutFormPage.Sender:
				this.router.navigate([this.translate.getActiveLang(), 'cadeaux']);
				break;
			default:
				return;
		}
	}

	getFormattedAddress(): Address {
		let address = this.address;
		address.line1 = this.checkoutFormControls['address'].value.line1;
		address.line2 = this.checkoutFormControls['address'].value.line2;
		address.postal = this.checkoutFormControls['address'].value.postal;
		address.notes = this.checkoutFormControls['address'].value.notes;
		return address;
	}

	getCardLogo(brand: string) {
		switch (brand) {
			case 'visa':
				return '../../assets/images/icons/visa.svg';
			case 'amex':
				return '../../assets/images/icons/american-express.svg';
			case 'mastercard':
				return '../../assets/images/icons/mastercard.svg';
		}
	}

	dateValidator(minDate: Date) {
		return (control: FormControl) => {
			const selectedDate = new Date(control.value);
			selectedDate.setDate(selectedDate.getDate() + 1);

			if (selectedDate < minDate) {
				return { invalidDate: true };
			}

			return null;
		};
	}

	handleGoogleAddressChange(googleAddress: GoogleAddress) {
		// fill in address details
		this.address = parseGoogleAddress(googleAddress);
		this.checkoutFormControls.address.get('line1').setValue(this.address.line1);
		this.checkoutFormControls.address.get('postal').setValue(this.address.postal);
	}
}