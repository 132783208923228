import { MainService } from '../services/main.service';
import { RestaurantService } from '../services/restaurant.service';

export function boostrapServicesFactory(
	mainService: MainService) {
	return async () => {
		try {
			await mainService.bootstrap();
		}
		catch (err) {
			console.log(err)
		}
	};
}
