import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { TranslocoService } from '@ngneat/transloco';
import { Observable } from "rxjs/internal/Observable";
import { Subject, catchError, debounceTime, distinctUntilChanged, of, switchMap } from "rxjs";
import { SearchService } from "src/app/core/services/search.service";
import { trigger, state, style, transition, animate } from "@angular/animations"
import { NgbPopover } from "@ng-bootstrap/ng-bootstrap/popover/popover";
import { SearchResult } from "src/app/core/models/search-result.model";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { ExtendedOrderType } from "src/app/core/enums";
@UntilDestroy()
@Component({
	selector: 'app-search-bar',
	templateUrl: './search-bar.component.html',
	styleUrls: ['./search-bar.component.css'],
	animations: [
		trigger('overlay', [
			state('true', style({ opacity: 0.3, pointerEvents: 'all' })),
			state('false', style({ opacity: 0, pointerEvents: 'none' })),
			transition('true <=> false', [animate('0.15s ease-in')])
		])
	],
	encapsulation: ViewEncapsulation.None
})
export class SearchBarComponent implements OnInit {

	ExtendedOrderType = ExtendedOrderType

	readonly MIN_QUERY_LENGTH = 1;
	readonly MAX_RESULT_COUNT = 9;
	readonly TOP_OFFSET = 120; // top gap + navbar + results container heading
	readonly RESULT_HEIGHT = 70;

	query: string;
	private searchTerms = new Subject<string>();
	results$: Observable<SearchResult[]> = of([]);

	mobileThreshold = 852;
	isFocused: boolean = false;
	popoverInstances: any = {}; //I realize this isn't the best way, but it'll do for now

		constructor(public translate: TranslocoService, private searchService: SearchService) {
			this.results$ = this.searchTerms.pipe(
				untilDestroyed(this),
				debounceTime(300),
				distinctUntilChanged(),
				switchMap(query => this.searchService.searchMarket(query)),
				catchError((_err, o) => o)
				// switchMap(query => iif(() => query.length < this.MIN_QUERY_LENGTH, this.searchService.searchMarket(query), this.trendingResults$))
			);
		}

	ngOnInit(): void {
		setTimeout(() => this.searchTerms.next(""), 100);
		//TODO: Potentially load to on click
	}

	get resultCountLimit() {
		return Math.min((this.windowHeight - this.TOP_OFFSET - this.RESULT_HEIGHT) / this.RESULT_HEIGHT, this.MAX_RESULT_COUNT);
	}

	get windowHeight() {
		return window.innerHeight;
	}

	get isMobile() {
		return window.innerWidth < this.mobileThreshold;
	}

	onClick(isClickInside: boolean) {
		this.isFocused = isClickInside;
	}

	toggleSearchBar() {
		this.isFocused = !this.isFocused;
	}

	onInput() {
		this.searchTerms.next(this.query);
	}

	onMouseEnter(p: NgbPopover, isPopover: boolean, key: string) {
		let state = this.popoverInstances[key];
		if (!state) state = this.popoverInstances[key] = {
			isInPopover: false,
			isInListItem: false
		} //TODO: can probs combine with previous statement
		isPopover ? state.isInPopover = true : state.isInListItem = true;
		setTimeout(() => (state.isInPopover != state.isInListItem) ? p.open() : null, 350);
	}

	onMouseLeave(p: NgbPopover, isPopover: boolean, key: string) {
		let state = this.popoverInstances[key];
		isPopover ? state.isInPopover = false : state.isInListItem = false;
		setTimeout(() => (state.isInPopover == state.isInListItem) ? p.close() : null, 150);
	}

}

