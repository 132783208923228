import { MarcheRadishLetterComponent } from './../../../modals/marche-radish-letter/marche-radish-letter.component';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslocoService } from '@ngneat/transloco';
import { FaqComponent } from 'src/app/modals/faq/faq.component';


@Component({
	selector: 'app-main-market-section-header',
	templateUrl: './main-market-section-header.component.html',
	styleUrls: ['./main-market-section-header.component.css']
})
export class MainMarketSectionHeaderComponent implements OnInit {

	url: string;
	browserLang: string;

	features =
		[
			// {
			// 	"title": "market.features.maynard.title",
			// 	"subtitle": "market.features.maynard.subtitle",
			// 	"image": "../../../../assets/images/market/header-features/maynard_holiday_feast.jpg",
			// 	"id": "feature-3",
			// 	"url": "merchants/maynard"
			// },
			{
				"title": "market.features.giftBox.title",
				"subtitle": "market.features.giftBox.subtitle",
				"image": "../../../../assets/images/gift-box/4.jpg",
				"id": "feature-gift-box",
				"url": "cadeaux"
			},
			{
				"title": "market.features.whyWeStarted.title",
				"subtitle": "market.features.whyWeStarted.subtitle",
				"image": "../../../../assets/images/market/header-features/mr-header-1.jpg",
				"id": "feature-1",
				"url": ""
			},
			{
				"title": "market.features.faq.title",
				"subtitle": "market.features.faq.subtitle",
				"image": "../../../../assets/images/market/header-features/mr-header-2.jpg",
				"id": 'feature-2',
				"url": ""
			},
			{
				"title": "market.categories.bakery",
				"subtitle": "",
				"image": "../../../../assets/images/market/header-features/mr-header-3.jpg",
				"id": "feature-3",
				"url": "bakery"
			},
		];

	constructor(
		private modalService: NgbModal,
		public router: Router,
		public translate: TranslocoService
	) { }

	ngOnInit(): void {
		this.translate.langChanges$.subscribe(lang => {
			this.browserLang = lang;
		});
	}

	openMarcheLetterModal() {
		let modal = this.modalService.open(MarcheRadishLetterComponent, {
			scrollable: true,
			keyboard: true,
			centered: true,
			size: 'xl'
		});
		return modal.result.then(() => { }, () => { });
	}

	openFAQModal() {
		let modal = this.modalService.open(FaqComponent, {
			scrollable: true,
			keyboard: true,
			centered: true,
			size: 'xl'
		});
		return modal.result.then(() => { }, () => { });
	}

	onClickHeaderFeature(id: string, url?: string) {
		switch (id) {
			case 'feature-holiday-gift-box':
				this.router.navigate(['/' + this.translate.getActiveLang() + '/' + url]);
				return;
			case 'feature-1':
				this.openMarcheLetterModal();
				return;
			case 'feature-2':
				this.openFAQModal();
				return;
			case 'feature-3':
				this.router.navigate(['/' + this.translate.getActiveLang() + '/marché/' + url]);
		}
	}

}
