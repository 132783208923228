import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Category } from 'src/app/core/models';
@Component({
  selector: 'app-base-market-section-header',
  templateUrl: './base-market-section-header.component.html',
  styleUrls: ['./base-market-section-header.component.css']
})
export class BaseMarketSectionHeaderComponent implements OnInit {

  @Input() isCategorySelected: boolean = false;
  @Input() category$: Observable<Category>;

  constructor(
    ) { }

  ngOnInit(): void {
  }

}
