import { Deserializable } from "./deserializable.model";
import { TranslatedText } from "./translated-text.model";

export class Media implements Deserializable<Media> {
    id: string;
    title: TranslatedText;
    description: TranslatedText;
    type: MediaType;
    extension: string;
    blobname: string;
    filename: string;
    container: string;
    thumbnails: Media[];
    dimensions?: {
        width?: number,
        height?: number
    }
    size: number;
    url: string;

    deserialize(input: any): this {
        if (!input) return null;
        if (typeof input == 'string') this.id = input;
        else {
            Object.assign(this, input);
            this.id = input._id ? input._id : input.id
            this.thumbnails = Array.isArray(input.thumbnails) ? input.thumbnails.map(thumbnail => new Media().deserialize(thumbnail)) : []
        }
		return this;
    }

    get thumbnailUrl(): string {
        if (this.thumbnails?.length && this.thumbnails[0] && this.thumbnails[0].url) {
            return this.thumbnails[0].url
        }
        return this.url
    }
}

export enum MediaType {
    Image = 'Image',
    Video = 'Video'
}
