<div class="product-item-container" (click)="onClickProduct()">
    <div *ngIf="product?.assets?.primary?.url" class="product-item-left product-item-image-wrapper">
        <img class="product-item-image" [src]="product?.assets?.primary?.url">
    </div>
    <div class="product-item-right">
        <div class="product-item-right-top">
            <h5 class="product-item-title">
                {{ product?.title }}
            </h5>
            <p class="product-item-description">
                {{ product?.description }}
            </p>
        </div>
        <div class="product-item-right-bottom">
            <div class="product-item-price">
                {{ product?.price | price | translocoCurrency }}
            </div>
            <div class="product-item-add-to-cart-button-container">
                <button *ngIf="showDecrementControl" type="button" class="minus-button quantity-button"
                    (click)="decrementItem()" appClickStopPropagation [disabled]="isDecrementDisabled() || !isOpen">
                    <img class="minus-icon quantity-icon" src="../../assets/images/icons/minus-white.svg">
                </button>
                <input *ngIf="showDecrementControl" type="number" class="quantity-text" [formControl]="quantity"
                    [disabled]="isLoading" />
                <button type="button" class="plus-button quantity-button" (click)="incrementItem()"
                    appClickStopPropagation [disabled]="!product?.isAvailable || isLoading || !isOpen">
                    <img class="plus-icon quantity-icon" src="../../assets/images/icons/plus-white.svg">
                </button>
            </div>
        </div>
    </div>
</div>