<div id="address-modal">
    <div *ngIf="!showMessage" class="address-container" id="address-modal-container">
        <ng-container *ngIf="parent == 'market-checkout'">
            <div class="address-header">{{ 'addressModal.whereToDeliver' | transloco }}</div>
        </ng-container>
        <ng-container *ngIf="parent != 'market-checkout'">
            <div class="address-header">{{ 'addressModal.confirm' | transloco }}</div>
            <div class="address-text">{{ 'addressModal.pleaseEnter' | transloco }}</div>
        </ng-container>
        <input [placeholder]="'navbar.addressPlaceholder' | transloco" id="address-modal-input"
            ngx-google-places-autocomplete #placesRef="ngx-places" [options]="autocompleteOptions" class="address-input"
            (onAddressChange)="handleAddressChange($event)" />
        <div *ngIf="isLoading" class="spinner-border spinner-border-sm" role="status">
            <span class="sr-only">{{ 'common.loading' | transloco }}</span>
        </div>
        <div *ngIf="addressNotFound" class="address-delivery-message"><img src="../../assets/images/icons/exclamation-mark.svg" class="message-logo">{{ 'addressModal.addressNotFound' | transloco }}</div>
        <div *ngIf="missingLine1Error" class="address-delivery-message"><img src="../../assets/images/icons/exclamation-mark.svg" class="message-logo">{{ 'addressModal.missingLine1Error' | transloco }}</div>
        <div *ngIf="unspecificAddressError" class="address-delivery-message"><img src="../../assets/images/icons/exclamation-mark.svg" class="message-logo">{{ 'addressModal.unspecificAddressError' | transloco }}</div>
        <div *ngIf="!addressWithinRange" class="address-delivery-message"><img src="../../assets/images/icons/exclamation-mark.svg" class="message-logo">{{ 'addressModal.addressOutOfRange' | transloco }}</div>
        <div *ngIf="geolocationError" class="address-delivery-message"><img src="../../assets/images/icons/exclamation-mark.svg" class="message-logo">{{ 'addressModal.geolocationError' | transloco }}</div>
    </div>
    <ng-container *ngIf="showMessage">
        <app-address-confirmation [address]="address" (onSaveAddressClick)="onConfirmAddress($event)" (onCancelClick)="activeModal.dismiss()"></app-address-confirmation>
    </ng-container>
</div>