import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslocoService } from '@ngneat/transloco';

@Component({
    selector: 'app-error-redirect',
    templateUrl: './error-redirect.component.html',
    styleUrls: ['./error-redirect.component.css']
})
export class ErrorRedirectComponent implements OnInit {

    @Input('header') header: string;
    @Input('text') text: string;
    @Input('cancelButtonText') cancelButtonText: string = null;
    @Input('submitButtonText') submitButtonText: string;

    @Input('headerParams') headerParams: any = {};
    @Input('textParams') textParams: any = {};
    @Input('cancelButtonTextParams') cancelButtonTextParams: any = {};
    @Input('submitButtonTextParams') submitButtonTextParams: any = {};

    constructor(
        public translate: TranslocoService,
        public activeModal: NgbActiveModal
    ) {}

    ngOnInit(): void {}
}
