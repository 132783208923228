<form action="submit" (submit)="handleForm($event)" [formGroup]="checkoutForm" div class="checkout-container"
	autocomplete="on">
	<h2 class="checkout-title">{{ 'expo.checkout.title' | transloco }}</h2>
	<!-- <div class="checkout-form-description">{{ 'expo.checkout.general.description' | transloco }}</div> -->

	<!-- SENDER INFORMATION -->
	<div *ngIf="pageState == GiftBoxCheckoutFormPage.Sender" class="section-container" formGroupName="sender">
		<h2 class="checkout-form-title">{{ 'giftBox.checkout.sender.title' | transloco }}</h2>

		<div class="checkout-form-input-wrapper">
			<label class="checkout-form-checkbox-label" for="name">{{ 'expo.checkout.general.name' | transloco
				}}</label>
			<div class="checkout-form-name-input-container">
				<input class="checkout-form-input form-input checkout-form-name-input" type="text" #senderFirstNameInput
					id="senderFirst" [placeholder]="'giftBox.checkout.sender.firstNamePlaceHolder' | transloco"
					formControlName="first" required
					[ngClass]="{ 'complete': checkoutFormControls.sender.get('first').valid }"
					autocomplete="given-name">
				<input class="checkout-form-input form-input checkout-form-name-input" type="text" #senderLastNameInput
					id="senderLast" [placeholder]="'giftBox.checkout.sender.lastNamePlaceHolder' | transloco"
					formControlName="last" required
					[ngClass]="{ 'complete': checkoutFormControls.sender.get('last').valid }"
					autocomplete="family-name">
			</div>
		</div>

		<div class="checkout-form-input-wrapper">
			<label class="checkout-form-checkbox-label" for="phone">{{
				'expo.checkout.general.phoneNumber' | transloco }}</label>
			<input class="checkout-form-input form-input checkout-form-number-input" type="text" id="phone"
				#senderNumberInput autocomplete="tel"
				[placeholder]="'expo.checkout.general.phoneNumberPlaceholder' | transloco" formControlName="number"
				required [ngClass]="{'complete': checkoutFormControls.sender.get('number').valid }">
			<div class="checkout-form-danger invalid-feedback"
				*ngIf="checkoutFormControls.sender.get('number').invalid && (checkoutFormControls.sender.get('number').dirty || checkoutFormControls.sender.get('number').touched)">
				{{ 'expo.checkout.errors.numberEmpty' | transloco }}
			</div>
		</div>

		<div class="checkout-form-input-wrapper">
			<label class="checkout-form-checkbox-label" for="email">{{
				'expo.checkout.general.emailAddress' | transloco }}</label>
			<input class="checkout-form-input form-input checkout-form-email-input" type="email" id="email"
				#addressInput autocomplete="email"
				[placeholder]="'expo.checkout.general.emailAddressPlaceholder' | transloco" formControlName="email"
				required [ngClass]="{'complete': checkoutFormControls.sender.get('email').valid}">

			
			<div class="checkout-form-danger invalid-feedback"
				*ngIf="checkoutFormControls.sender.get('email').invalid && (checkoutFormControls.sender.get('email').dirty || checkoutFormControls.sender.get('email').touched)">
				{{ 'expo.checkout.errors.addressEmpty' | transloco }}
			</div>
		</div>

		<!-- TOGGLE GIFT VS PURCHASE -->
		<!-- <div style="display:flex; flex-direction:row; width:100%; justify-content: flex-start;">
			<div style="width:fit-content; height:3rem; margin-right:1rem;">
				Is this box for a friend?
			</div>
			<label class="form-toggle">
				<input type="checkbox" (change)="changeGiftStatus()" />
				<span class="form-toggle-slider"></span>
			</label>
		</div>-->
	</div>

	<!-- GIFT RECIPIENT INFO -->
	<div *ngIf="pageState == GiftBoxCheckoutFormPage.Recipient" class="section-container" formGroupName="recipient">
		<h2 class="checkout-form-title">{{ 'giftBox.checkout.recipient.title' | transloco }}</h2>
		<div class="checkout-form-checkbox-container">
			<div class="checkout-form-checkbox-wrapper">
				<input class="checkout-form-checkbox" type="checkbox" formControlName="isSender" id="isSender"
					(change)="toggleRecipientToSender($event)">
			</div>
			<label class="checkout-form-checkbox-text form-check-label" for="isSender">
				<span>{{ 'giftBox.checkout.recipient.sameAsSender' | transloco }}</span>
			</label>
		</div>
		<div class="checkout-form-input-wrapper">
			<label class="checkout-form-checkbox-label" for="name">{{ 'expo.checkout.general.name' | transloco
				}}</label>
			<div class="checkout-form-name-input-container">
				<input class="checkout-form-input form-input checkout-form-name-input" type="text"
					#recipientFirstNameInput id="recipientFirst"
					[placeholder]="'giftBox.checkout.sender.firstNamePlaceHolder' | transloco" formControlName="first"
					required [ngClass]="{ 'complete': checkoutFormControls.recipient.get('first').valid }"
					autocomplete="given-name">
				<input class="checkout-form-input form-input checkout-form-name-input" type="text"
					#recipientLastNameInput id="recipientLast"
					[placeholder]="'giftBox.checkout.sender.lastNamePlaceHolder' | transloco" formControlName="last"
					required [ngClass]="{ 'complete': checkoutFormControls.recipient.get('last').valid }"
					autocomplete="family-name">
			</div>
		</div>

		<div class="checkout-form-input-wrapper">
			<label class="checkout-form-checkbox-label" for="number">{{
				'expo.checkout.general.phoneNumber' | transloco }}</label>
			<input class="checkout-form-input form-input checkout-form-number-input" type="number" id="number"
				#recipientNumberInput autocomplete="tel"
				[placeholder]="'expo.checkout.general.phoneNumberPlaceholder' | transloco" formControlName="number"
				required [ngClass]="{'complete': checkoutFormControls.recipient.get('number').valid }">
			<div class="checkout-form-danger invalid-feedback"
				*ngIf="checkoutFormControls.recipient.get('number').invalid && (checkoutFormControls.recipient.get('number').dirty || checkoutFormControls.recipient.get('number').touched)">
				{{ 'expo.checkout.errors.numberEmpty' | transloco }}
			</div>
		</div>

		<div class="checkout-form-input-wrapper">
			<label class="checkout-form-checkbox-label" for="email">{{
				'expo.checkout.general.emailAddress' | transloco }}</label>
			<input class="checkout-form-input form-input checkout-form-email-input" type="email" id="email"
				#addressInput autocomplete="email"
				[placeholder]="'expo.checkout.general.emailAddressPlaceholder' | transloco" formControlName="email"
				required [ngClass]="{'complete': checkoutFormControls.recipient.get('email').valid}">
			<div class="checkout-form-danger invalid-feedback"
				*ngIf="checkoutFormControls.recipient.get('email').invalid && (checkoutFormControls.recipient.get('email').dirty || checkoutFormControls.recipient.get('email').touched)">
				{{ 'expo.checkout.errors.addressEmpty' | transloco }}
			</div>
		</div>

		<!-- <div class="checkout-form-input-wrapper">
			<label class="checkout-form-checkbox-label" for="deliveryCardMessage">{{
				'giftBox.checkout.recipient.card' | transloco }}</label>
			<input class="checkout-form-input form-input checkout-form-delivery-note-input" type="text"
				id="deliveryCardMessage" #deliveryCardMessageInput
				[placeholder]="'giftBox.checkout.recipient.cardPlaceholder' | transloco"
				formControlName="deliveryCardMessage" required
				[ngClass]="{'complete': checkoutFormControls.recipient.get('deliveryCardMessage').valid}">
		</div> -->
	</div>

	<!-- DELIVERY ADDRESS -->
	<div *ngIf="pageState == GiftBoxCheckoutFormPage.Address" class="section-container" formGroupName="address">
		<h2 class="checkout-form-title">{{ 'giftBox.checkout.delivery.title' | transloco }}</h2>

		<div class="checkout-form-input-wrapper">
			<label class="checkout-form-checkbox-label" for="line1">{{
				'giftBox.checkout.delivery.address' | transloco }}</label>
			<input class="checkout-form-input form-input checkout-form-address-input" type="search" id="line1"
			#line1Input autocomplete="false"
			ngx-google-places-autocomplete #placesRef="ngx-places" (onAddressChange)="handleGoogleAddressChange($event)" 
				[options]="autocompleteOptions"
				[placeholder]="'giftBox.checkout.delivery.addressPlaceholder' | transloco" formControlName="line1"
				required [ngClass]="{'complete': checkoutFormControls.address.get('line1').valid}">
			<div class="checkout-form-danger invalid-feedback"
				*ngIf="checkoutFormControls.address.get('line1').invalid && (checkoutFormControls.address.get('line1').dirty || checkoutFormControls.address.get('line1').touched)">
				{{ 'giftBox.checkout.errors.addressInvalid' | transloco }}
			</div>
		</div>

		<div class="checkout-form-input-wrapper">
			<label class="checkout-form-checkbox-label" for="line2">{{
				'giftBox.checkout.delivery.additionalAddress' | transloco }}</label>
			<div class="checkout-form-name-input-container">
				<input class="checkout-form-input form-input checkout-form-name-input" type="text" #line2Input
					id="line2" [placeholder]="'giftBox.checkout.delivery.additionalAddressPlaceholder' | transloco"
					formControlName="line2"
					[ngClass]="{ 'complete': (checkoutFormControls.address.get('line2').valid && checkoutFormControls.address.get('line2').touched)}"
					autocomplete="address-line2">
			</div>
			<!-- <div class="checkout-form-danger invalid-feedback"
				*ngIf="checkoutFormControls.address.get('line2').invalid && (checkoutFormControls.address.get('line2').dirty || checkoutFormControls.address.get('line2').touched)">
				{{ 'giftBox.checkout.errors.addressAdditionalInvalid' | transloco }}
			</div> -->
		</div>
		<div class="checkout-form-input-wrapper">
			<label class="checkout-form-checkbox-label" for="postal">{{
				'giftBox.checkout.delivery.postalLabel' | transloco }}</label>
			<input class="checkout-form-input form-input checkout-form-name-input" type="text" #postalCodeInput
				id="postal" [placeholder]="'giftBox.checkout.delivery.postalCodePlaceholder' | transloco"
				formControlName="postal" required
				[ngClass]="{ 'complete': checkoutFormControls.address.get('postal').valid }" autocomplete="postal-code">
			<div class="checkout-form-danger invalid-feedback"
				*ngIf="checkoutFormControls.address.get('postal').invalid && (checkoutFormControls.address.get('postal').dirty || checkoutFormControls.address.get('postal').touched)">
				{{ 'giftBox.checkout.errors.postalInvalid' | transloco }}
			</div>
			<div class="checkout-form-input-wrapper">
				<label class="checkout-form-checkbox-label" for="scheduledDate">{{
					'giftBox.checkout.delivery.deliveryDate' | transloco }}</label>
				<input class="checkout-form-input form-input checkout-form-date-input" type="date" id="scheduledDate"
					#deliveryDateInput [placeholder]="'expo.checkout.general.additionalAddressPlaceholder' | transloco"
					formControlName="scheduledDate" required onfocus="this.showPicker()"
					min="{{minDate}}"
					[ngClass]="{'complete': checkoutFormControls.address.get('scheduledDate').valid }">
				<div class="checkout-form-danger invalid-feedback"
					*ngIf="checkoutFormControls.address.get('scheduledDate').invalid && (checkoutFormControls.address.get('scheduledDate').dirty || checkoutFormControls.address.get('scheduledDate').touched)">
					{{ 'giftBox.checkout.errors.deliveryDateInvalid' | transloco }}
				</div>
			</div>

			<div class="checkout-form-input-wrapper">
				<label class="checkout-form-checkbox-label" for="notes">{{
					'giftBox.checkout.delivery.deliveryNote' | transloco }}</label>
				<input class="checkout-form-input form-input checkout-form-delivery-note-input" type="text" id="notes"
					#deliveryNoteInput [placeholder]="'giftBox.checkout.delivery.deliveryNotePlaceholder' | transloco"
					formControlName="notes"
					[ngClass]="{'complete': (checkoutFormControls.address.get('notes').valid && checkoutFormControls.address.get('notes').touched)}">
			</div>
		</div>
	</div>

	<!-- BILLING INFO -->
	<div *ngIf="pageState == GiftBoxCheckoutFormPage.Payment" class="section-container" formGroupName="payment">
		<div class="checkout-form-payment-container">
			<h2 class="checkout-form-title">{{ 'giftBox.checkout.payment.title' | transloco }}</h2>
			<div class="checkout-form-payment">
				<span><input class="checkout-card-radio-input" type="radio" formControlName="cardRadio" value="new-card"
						id="new-card" hidden /></span>
				<div class="checkout-box-input form-input" #cardElement>
				</div>
				<div *ngIf="cardErrors" class="card-error">
					{{ cardErrors }}
				</div>
			</div>
		</div>
	</div>

	<div class="button-group">
		<!-- STEP BACKWARD BUTTON -->
		<button class="checkout-button form-submit-button" style="margin-right:1rem;" type="submit" [disabled]="isLoading"
			(click)="stepBackForm()">
			<ng-container *ngIf="!isLoading">{{ 'bag.returnToHomePage' | transloco }}</ng-container>
			<div *ngIf="isLoading" class="spinner-border spinner-border-sm text-light" role="status">
				<span class="sr-only">{{ 'common.loading' | transloco }}</span>
			</div>
		</button>

		<!-- STEP FORWARD BUTTON -->
		<button class="checkout-button form-submit-button" type="submit" [disabled]="!canProceedToNextPage"
			(click)="stepForm()">
			<ng-container *ngIf="!isLoading">
				<div *ngIf="pageState != GiftBoxCheckoutFormPage.Payment">{{ 'common.continue' | transloco }}</div>
				<div *ngIf="pageState == GiftBoxCheckoutFormPage.Payment">{{ 'expo.checkout.placeOrder' | transloco }}</div>
			</ng-container>
			<div *ngIf="isLoading" class="spinner-border spinner-border-sm text-light" role="status">
				<span class="sr-only">{{ 'common.loading' | transloco }}</span>
			</div>
		</button>
	</div>
</form>