import { TranslocoService } from "@ngneat/transloco"
import { ServiceLocator } from "../services/locator.service"
import { Deserializable } from "./deserializable.model"

export class TranslatedText implements Deserializable<TranslatedText> {
    en?: string
    fr?: string
    jp?: string

    translate: TranslocoService = ServiceLocator.injector.get(TranslocoService)

    constructor() {}

    get value(): string{
        return this[this.translate.getActiveLang()]
    }

    deserialize(input: any): this {
        Object.assign(this, input);
        if (typeof input === 'string') {
            this.en = input;
            this.fr = input;
        }
        if (this.fr == null || !this.fr.length) this.fr = this.en;
        else if (this.en == null || !this.en.length) this.en = this.fr;
        //TODO: use fallback loop instead of hardcode
        return this;
    }
}

export type TranslatedTextType = string | TranslatedText
