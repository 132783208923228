import { Component, OnDestroy, OnInit } from '@angular/core';
import { UserService } from '../core/services/user.service';
import { User } from '../core/models';
import {
	UntypedFormGroup,
	UntypedFormBuilder,
	Validators,
	ValidationErrors,
	AbstractControl,
} from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
	selector: 'app-account',
	templateUrl: './account.component.html',
	styleUrls: ['./account.component.css'],
})
export class AccountComponent implements OnInit, OnDestroy {
    private subscription: Subscription = new Subscription();

	user: User;

	profileForm: UntypedFormGroup;
	passwordForm: UntypedFormGroup;

	constructor(
		private userService: UserService,
		private formBuilder: UntypedFormBuilder
	) { }

	ngOnInit(): void {
		// this.getSelf();
	}

	buildForms() {
		this.profileForm = this.formBuilder.group({
			firstName: [this.user.name.first, Validators.required],
			lastName: [this.user.name.last, Validators.required],
			email: ['', this.customEmailValidator],
			number: [''],
		});

		this.passwordForm = this.formBuilder.group({
			oldPassword: [null, Validators.required],
			newPassword: [null, Validators.required],
			confirmNewPassword: [null, Validators.required],
		});
	}

	getSelf() {
		// this.subscription.add(this.userService.userLoaded$.subscribe((user) => {
		// 	this.user = user;
		// 	if (user) this.buildForms();
		// }));
	}

	isProfileFormValid(): boolean {
		return !(
			this.profileForm.invalid ||
			(this.profileForm.controls.firstName.value == this.user.name.first &&
				this.profileForm.controls.lastName.value == this.user.name.last &&
				this.profileForm.controls.email.value == '' &&
				this.profileForm.controls.number.value == '')
		);
	}

	isPasswordFormValid(): boolean {
		return (
			this.passwordForm.valid &&
			this.passwordForm.controls.newPassword.value ==
			this.passwordForm.controls.confirmNewPassword.value
		);
	}

	customEmailValidator(control: AbstractControl): ValidationErrors {
		if (!control.value) {
			return null;
		}
		return Validators.email(control);
	}

	onProfileFormSubmit(data) {
		// change profile information
	}

	onPasswordFormSubmit(data) {
		// change password
	}

	notificationCheckboxChange(e) {
		// change notification setting (e.target.checked)
	}

	newCreditCardClick() {
		// add new credit card
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}
