import { Deserializable } from "./deserializable.model"
import { TranslatedText, TranslatedTextType } from "./translated-text.model"

export class Discount implements Deserializable<Discount> {
    id: string
    _title: TranslatedText;
    _description?: TranslatedText;
    type: DiscountType
    value: string

    constructor() {}

    get title(): TranslatedTextType {
        return this._title?.value
    }

    set title(title: TranslatedTextType) {
        this._title = title as TranslatedText
    }

    get description(): TranslatedTextType {
        return this._description.value
    }

    set description(description: TranslatedTextType) {
        this._description = description as TranslatedText
    }

	deserialize(input: any): this {
        if (!input) return null;
        if (typeof input == 'string') this.id = input;
        else {
            Object.assign(this, input);
            this.id = input._id ? input._id : input.id
            this.title = new TranslatedText().deserialize(input.title)
            this.description = new TranslatedText().deserialize(input.description)
        }
		return this;
	}
}

export enum DiscountType {
    Percent = 'percent',
    Fixed = 'fixed'
}
