<div class="restaurant-about-important-hours-container" [ngClass]="configService.getCssClass(restaurantSlug)" [style.font-size]="fontSize">
    <svg class="restaurant-about-important-hours-clock icon" viewBox="0 0 469.33 469.33" [style.height]="iconSize" [style.width]="iconSize">
        <path [attr.fill]="iconColor" d="M234.67,0C105.06,0,0,105.06,0,234.67S105.06,469.33,234.67,469.33,469.33,364.27,469.33,234.67,364.21.15,234.67,0Zm0,426.67c-106,0-192-86-192-192s86-192,192-192,192,86,192,192S340.65,426.54,234.67,426.67Z"/>
        <path [attr.fill]="iconColor" d="M256,225.83V106.67a21.34,21.34,0,1,0-42.67,0v128a21.34,21.34,0,0,0,6.25,15.08l64,64a21.34,21.34,0,0,0,30.17-30.17Z"/>
    </svg>
    <div ngbDropdown class="d-inline-block">
        <div id="hours-dropdown" class="restaurant-about-important-todays-hours-outer-container" ngbDropdownToggle>
            <div class="restaurant-about-important-open-closed">
                {{
                    isRestaurantOpenRightNow()
                        ? ('restaurant.about.open' | transloco)
                        : ('restaurant.about.closed' | transloco)
                }}
            </div>
            <div *ngIf="isRestaurantOpenToday()" class="restaurant-about-important-todays-hours-container">
                <div *ngFor="let hours of getTodaysHoursString()" class="restaurant-about-important-todays-hours"
                [ngStyle]="{'font-weight': configService.getBodyFontWeight(restaurantSlug)}">
                    {{ hours.time }}
                </div>
            </div>
        </div>

        <div class="restaurant-about-important-hours-dropdown" ngbDropdownMenu aria-labelledby="hours-dropdown">
            <div *ngFor="let day of hours; let i = index"
                class="restaurant-about-important-hours-dropdown-item-container" ngbDropdownItem>
                <div class="restaurant-about-important-hours-dropdown-day">
                    {{ 'common.weekdays.' + i | transloco }}
                </div>
                <div class="restaurant-about-important-hours-dropdown-time-container">
                    <div *ngIf="day.length === 0; else hoursExist">{{'restaurant.about.closed' | transloco}}</div>
                    <ng-template #hoursExist>
                        <div *ngFor="let hour of day" class="restaurant-about-important-hours-dropdown-time"
                            [class.restaurant-about-important-hours-dropdown-time-inactive]="!hour.active"
                            [disableTooltip]="hour.active" ngbTooltip="Temporarily Closed"
                            tooltipClass="radish-tooltip">
                            {{ hour.time }}
                        </div>
                    </ng-template>
                </div>
            </div>
        </div>
    </div>
</div>
