import { Component, Input, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { MenuItemButtonType } from 'src/app/core/enums';
import { ConfigService } from 'src/app/core/services/config.service';
import { Bag, Product } from 'src/app/core/models';
import { MenuItemBaseComponent } from 'src/app/online-ordering/restaurant/restaurant-menu/menu-item-base/menu-item-base.component';

@Component({
    selector: 'app-menu-item-v2',
    templateUrl: './menu-item.component.html',
    styleUrls: ['./menu-item.component.css']
})
export class MenuItemComponent extends MenuItemBaseComponent implements OnInit {

    @Input() borderRadius: string
    @Input() restaurantCssClass: string = ''
    @Input() font: string
    @Input() image: string
    @Input() descriptionFontWeight: string
    @Input() descriptionColor: string
    @Input() showReadMoreText: boolean = true;
    @Input() showDescription: boolean = false

    constructor(public translate: TranslocoService, public configService: ConfigService) {
        super(translate, configService)
    }

    ngOnInit(): void {
        super.ngOnInit()
    }

}
