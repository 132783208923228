import { Component, OnInit, Input } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { ConfigService } from 'src/app/core/services/config.service';

@Component({
    selector: 'app-hours-dropdown',
    templateUrl: './hours-dropdown.component.html',
    styleUrls: ['./hours-dropdown.component.css']
})
export class HoursDropdownComponent implements OnInit {

    @Input() schedule: any
    @Input() iconColor: string
    @Input() fontSize: string
    @Input() iconSize: string
    @Input() restaurantSlug: string

    hours: any[] = []

    constructor(
        public translate: TranslocoService,
        public configService: ConfigService) { }

    ngOnInit(): void {
        this.setupHours()
    }

    setupHours() {
        for (let day of this.schedule) {
            this.hours.push([])
            if (day) {
                for (let hour of this.parseHours(day)) {
                    this.hours[this.hours.length - 1].push({
                        time: hour.time,
                        active: hour.active
                    })
                }
            }
        }
    }

    parseHours(hours): any[] {
        let res: {
            time: string,
            active: boolean
        }[] = []
        for (let hour of hours) {
            let startDate = new Date(hour.start)
            let endDate = new Date(hour.end)
            res.push({
                time: this.prettyPrintTime(startDate) + " - " + this.prettyPrintTime(endDate),
                active: hour.active
            })
        }
        return res
    }

    prettyPrintTime(date): string {
        return date.toLocaleTimeString(this.translate.getActiveLang(), {
            hour: '2-digit',
            minute: '2-digit'
        })
    }

    isRestaurantOpenToday(): boolean {
        let dayOfWeek:  number = (new Date()).getDay()
        return this.schedule[dayOfWeek]?.length > 0
    }

    isRestaurantOpenRightNow(): boolean {
        let now = new Date();
        let openHours = this.getTodaysHours();
        for (let hours of openHours) {
            let start = new Date(hours['start'])
            let end = new Date(hours['end'])
            let dayAfterStartDate = new Date(start.getFullYear(), start.getMonth(), start.getDate() + 1);
            if (dayAfterStartDate.getFullYear() == end.getFullYear() && dayAfterStartDate.getMonth() == end.getMonth() && dayAfterStartDate.getDate() == end.getDate()) { //this end date is one day ahead of start date
                end.setDate(now.getDate() + 1)
            } else {
                end.setDate(now.getDate())
            }
            start.setDate(1)
            start.setMonth(now.getMonth())
            start.setFullYear(now.getFullYear())
            start.setDate(now.getDate())
            end.setMonth(now.getMonth())
            end.setFullYear(now.getFullYear())
            if (hours['active'] === false) continue;
            if ((now.getTime() > start.getTime()) && (now.getTime() < end.getTime()))
                return true;
        }
        return false;
    }

    getTodaysHours(): Object[] {
        let now = new Date();
        return this.schedule[now.getDay()];
    }

    getTodaysHoursString(): any[] {
        return this.parseHours(this.getTodaysHours())
    }

}
