import { Component, Input, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { BagItem } from 'src/app/core/models';
import { MainService } from 'src/app/core/services/main.service';
import { ExpoBagPage } from 'src/app/core/enums';
@Component({
	selector: 'expo-bag-item',
	templateUrl: './bag-item.component.html',
	styleUrls: ['./bag-item.component.css']
})
export class ExpoBagItemComponent implements OnInit {

	@Input() item: BagItem;
	@Input() disableDecrement: boolean;
	@Input() expositionSlug: string;
	@Input() restaurantSlug: string;
	@Input() page: string;
	@Input() bagSize: number;

	showDecrementControl = true;
	showIncrementControl = true;
	isLoading: boolean = false;

	ExpoBagPage = ExpoBagPage;

	constructor(
		private mainService: MainService,
		public translate: TranslocoService
	) { }

	ngOnInit(): void { 
		if (this.page == 'checkout') this.page = ExpoBagPage.Checkout;
		else if (this.page == 'order') this.page = ExpoBagPage.Order;
		else this.page = ExpoBagPage.Menu;
	}

	incrementItem(item) {
		this.isLoading = true;
		this.mainService.incrementItem(this.mainService.expoBag, item).subscribe(_ => this.isLoading = false);
	}

	decrementItem(item) {
		this.isLoading = true;
		this.mainService.decrementItem(this.mainService.expoBag, item).subscribe(_ => this.isLoading = false);
	}

	isIncrementDisabled() {
		return this.isLoading;
	}

	isDecrementDisabled(item: BagItem, bagSize: number) {
		return this.isLoading || (bagSize != null && bagSize == 1 && item.quantity <= 1 && this.disableDecrement);
	}

}
