<!-- <div class="gift-card-applied-container">
    <img class="gift-card-applied-close" src="../../../assets/images/close-icon.png" alt="" (click)="activeModal.close()" />
    <div class="gift-card-applied-header">
        {{ 'giftCard.redeem.redeemed' | transloco }}
    </div>
    <div class="gift-card-applied-content" [innerHtml]="'giftCard.redeem.success' | transloco">
    </div>
</div> -->
<div class="gift-card-applied-container">
    <div class="gift-card-applied-header">
        <div class="gift-card-applied-photo gift-card-applied-photo-left">
            <img class="photo" src="../../../assets/images/modal-image-1.jpg"/>
        </div>
        <div class="gift-card-applied-photo gift-card-applied-photo-right">
            <img class="photo" src="../../../assets/images/modal-image-2.jpg"/>
        </div>
    </div>
    <div class="gift-card-applied-content">
        <div class="gift-card-applied-content-title">
            {{ 'giftCard.redeem.modal.title' | transloco }}
        </div>
        <div class="gift-card-applied-content-text">
            {{ 'giftCard.redeem.modal.text' | transloco }}
        </div> 
        <div class="gift-card-applied-footer">
            <!-- <button type="button" class="routing-to-restaurants-button gift-card-applied-footer-button"> {{ 'giftCard.redeem.modal.getDelivery' | transloco }} </button> -->
            <button type="button" [routerLink]="['/' + translate.getActiveLang() + '/restaurants/']" (click)="activeModal.close()"class="routing-to-restaurants-button gift-card-applied-footer-button"> {{ 'giftCard.redeem.modal.getDelivery' | transloco }} </button>
            <button type="button" [routerLink]="['/' + translate.getActiveLang() + '/marché/']" (click)="activeModal.close()" class="routing-to-market-button gift-card-applied-footer-button"> {{ 'giftCard.redeem.modal.planCrate' | transloco }} </button>
            <!-- <button type="button" class="routing-to-market-button gift-card-applied-footer-button"> {{ 'giftCard.redeem.modal.planCrate' | transloco }} </button> -->
        </div> 
    </div>
</div>
