import { Component, Input } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { ExtendedOrderType } from 'src/app/core/enums';
import { SearchResult } from 'src/app/core/models';

@Component({
  selector: 'app-merchant-popover',
  templateUrl: './merchant-popover.component.html',
  styleUrls: ['./merchant-popover.component.css']
})
export class MerchantPopoverComponent {
  @Input() merchant: SearchResult;

	ExtendedOrderType = ExtendedOrderType;

  constructor(public translate: TranslocoService) { }

}
