import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { AuthService } from '../core/services/auth.service';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';

@Component({
	selector: 'app-forgot-password',
	templateUrl: './forgot-password.component.html',
	styleUrls: ['./forgot-password.component.css'],
})

/**
 * TODO: Refactor
 * Query params observable is short lived, does it require subscription?
 * On submit can be condensed
 */
export class ForgotPasswordComponent implements OnInit, OnDestroy {
    private readonly subscription: Subscription = new Subscription();

	forgotPasswordForm: UntypedFormGroup;
	loading: boolean = false;
	error: boolean;
    verificationMessage: boolean = false;
    submitted: boolean = false;

    invalidToken: boolean = false

	constructor(
        private fb: UntypedFormBuilder,
        private authService: AuthService,
        private route: ActivatedRoute) {
		this.forgotPasswordForm = fb.group({
			email: ['', [Validators.required, Validators.email]],
		});
	}

	get forgotPasswordControls() {
		return this.forgotPasswordForm.controls;
	}

	ngOnInit() {
        this.subscription.add(this.route.queryParams.subscribe(
            params => {
                if (params['source'] == 'resetPassword') this.invalidToken = true
            }
        ))
    }

	onSubmit(email) {
        this.invalidToken = false
		this.loading = true;
		if (this.forgotPasswordForm.invalid) {
			this.loading = false;
			return;
		} else {
			this.error = false;
            this.authService.forgotPassword(email)
                .pipe(first())
                .subscribe(
                    _ => {
                        this.verificationMessage = true;
                        this.loading = false;
                        this.submitted = true;
                    },
                    _ => {
                        this.error = true;
                        this.loading = false;
                    }
                );
		}
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}
