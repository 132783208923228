import { Component, Input, OnInit } from '@angular/core';
import { Restaurant, Review } from '../../../../core/models';
import { TranslocoService } from '@ngneat/transloco';
import { ConfigService } from 'src/app/core/services/config.service';
import { RestaurantSlug } from 'src/app/core/enums';

@Component({
    selector: 'app-restaurant-about-important',
    templateUrl: './restaurant-about-important.component.html',
    styleUrls: ['./restaurant-about-important.component.css']
})
export class RestaurantAboutImportantComponent implements OnInit {

    @Input() restaurant: Restaurant;
    @Input() reviews: Review[];

    zoom : number = 15
    radiusCircleVisible: boolean = false;
    mapStyle: any;

    constructor(public translate: TranslocoService, public configService: ConfigService) { }

    ngOnInit(): void {
        this.mapStyle = this.configService.getMapStyle(this.restaurant.slug)
    }

    toggleDeliveryRadiusClick() {
        if (this.radiusCircleVisible) this.zoom = 15
        else this.zoom = 10

        this.radiusCircleVisible = !this.radiusCircleVisible
    }

    encodeURI(str: string) {
        return encodeURI(str);
    }

    getIconColor() {
        switch (this.restaurant.slug) {
            case RestaurantSlug.Maharani:
                return '#BC9B5D';
            case RestaurantSlug.Diolo:
            case RestaurantSlug.MerRouge:
            case RestaurantSlug.PtitPlateau:
                return '#FFFFFF';
            case RestaurantSlug.KojiSoupe:
                return '#183a52';
            case RestaurantSlug.LundisAuSoleil:
                return '#11285C';
            case RestaurantSlug.TincSet:
                return '#054c3f';
            case RestaurantSlug.FanfarePizza:
                return '#FFBB21';
            case RestaurantSlug.InochiExpress:
                return '#01adf3';
            case RestaurantSlug.Uluwatu:
                return '#E5BD5E';
            case RestaurantSlug.SaintHoublon:
                return '#E87474'
            case RestaurantSlug.ChezTauBay:
                return '#FCAF17'
            case RestaurantSlug.Boustan:
                return '#C94242'
            case RestaurantSlug.ThaiExpressCdn:
                return '#F390BC'
            case RestaurantSlug.JavaUCdn:
                return '#FFFFFF'
            case RestaurantSlug.McgillPizza:
                return '#941A17'
            case RestaurantSlug.Yuan:
                return '#A2BA64'
            case RestaurantSlug.Segreta:
                return '#1FB4B2'
            case RestaurantSlug.QuesadaCdn:
                return '#C41425'
            case RestaurantSlug.OiseauxDePassage:
                return '#DB5E5E'
            case RestaurantSlug.CafeVita:
                return '#FF9126'
            case RestaurantSlug.BurgerBros:
                return '#AEB434'
            case RestaurantSlug.Wallys:
                return '#0081A9'
            case RestaurantSlug.SpiceBros:
                return '#F46720'
            case RestaurantSlug.SantaLucia:
                return '#C13617'
            case RestaurantSlug.Dakar:
                return '#DFB57A'
            case RestaurantSlug.oFour:
                return '#bf6d00'
            case RestaurantSlug.Karin:
                return '#AD4134'
            case RestaurantSlug.MarcheMarema:
                return '#FFA471'
            case RestaurantSlug.ElephantThai:
                return '#FFA471'
            case RestaurantSlug.Sushiyo:
                return '#F25D5D'
            case RestaurantSlug.BoiteVegane:
                return '#468943'
            case RestaurantSlug.HeroBurgers:
                return '#78170F'
            case RestaurantSlug.JrsCafe:
                return '#5F9BCE'
            case RestaurantSlug.CafeResonance:
                return '#FF6622'
            case RestaurantSlug.UbiSushi:
                return '#FA4752'
            case RestaurantSlug.ObsceneVegan:
                return '#3DC040'
            case RestaurantSlug.LestersDeli:
                return '#FF4E58'
            case RestaurantSlug.Barranco:
                return '#FAB07A'
            case RestaurantSlug.PetitSeoul:
                return '#FD0109'
            case RestaurantSlug.Hooters:
                return '#FF571F'
            case RestaurantSlug.Darbar:
                return '#DAACB4'
            case RestaurantSlug.JardinPetros:
                return '#0091D0'
            case RestaurantSlug.ChezAnna:
                return '#FF3D00'
            case RestaurantSlug.Krood:
                return '#BD7D2E'
            case RestaurantSlug.HungarianCuisine:
                return '#C8102E'
            case RestaurantSlug.Maynard:
                return '#000000'
            case RestaurantSlug.MFourBurritos:
                return '#EC661C'
            case RestaurantSlug.Bocadillo:
                return '#E41D39'
            case RestaurantSlug.LaurierBBQ:
                return '#04332D'
            case RestaurantSlug.oFish:
                return '#ED8F16'
            case RestaurantSlug.SushiSama:
                return '#F37338'
            case RestaurantSlug.PaniniStop:
                return '#E91C23'
            case RestaurantSlug.PokeStationCdn:
                return '#EF7E8A'
            case RestaurantSlug.TeochewFoodie:
                return '#E03C31'
            case RestaurantSlug.Tapigo:
                return '#BA4834'
            case RestaurantSlug.BrigadePizza:
                return '#F53229'
            case RestaurantSlug.AlAmine:
                return '#AA2E30'
            case RestaurantSlug.BernieBeigne:
                return '#72C3E1'
            case RestaurantSlug.Cacao70:
                return '#F37136'
            case RestaurantSlug.TacosNation:
                return '#0C0608'
            case RestaurantSlug.PoissonnerieSherbrooke:
                return '#000000'
            case RestaurantSlug.Mintar:
                return '#1E1E1D'
            case RestaurantSlug.AuxMerveilleuxDeFred:
                return '#DDB200'
            case RestaurantSlug.LaToile:
                return '#000000'
            case RestaurantSlug.SepLai:
                return '#CF5434'
            case RestaurantSlug.BistroDeLaCite:
                return '#AA2E30'
            case RestaurantSlug.Singhs:
                return '#35B546'
            case RestaurantSlug.ChaiTeaLounge:
                return '#5A803D'
            case RestaurantSlug.LaCroissanterieFigaro:
                return '#A73F2E'
            case RestaurantSlug.LaFabriqueDeBagel:
                return '#EFEFEF'
            case RestaurantSlug.StudentTastyBiryani:
                return '#fc9d29'
            case RestaurantSlug.OsukaSushi:
                return '#c8322b'
            case RestaurantSlug.Lalis:
                return '#FFCE35'
            case RestaurantSlug.ChezTaMere:
                return '#5bbae7'
            case RestaurantSlug.Chatpata:
                return '#E84B22'
            case RestaurantSlug.FriteAlors:
                return '#ED1C24'
            case RestaurantSlug.Tibum:
                return '#D2AC67'
            case RestaurantSlug.ThePerle:
                return '#8B7244'
            case RestaurantSlug.Gongcha:
                return '#C21E34'
            case RestaurantSlug.BuffaloBill:
                return '#E8CA00'
            case RestaurantSlug.SouvlakiGrecPlus:
                return '#0069A8'
            case RestaurantSlug.PizzaSport:
                return '#FFBD01'
            case RestaurantSlug.Tsujiri:
                return '#12312B'
            case RestaurantSlug.SpartaChicken:
                return '#FF0A01'
            case RestaurantSlug.P23Dumplings:
                return '#FEB52C'
            case RestaurantSlug.BurgzCantine:
                return "#1B1B1B"
            case RestaurantSlug.Goplana:
                return "#BEA6D4"
            case RestaurantSlug.PiriPiri:
                return "#FFEF5B"
            case RestaurantSlug.UkiyaIzakaya:
                    return "#E3563A"
            case RestaurantSlug.LivSalades:
                return "#13BD5B"
            case RestaurantSlug.MaisonDuDestin:
                return "#D2111A"
            case RestaurantSlug.FlatBellyDeli:
                return "#F5483B"
            case RestaurantSlug.DowntownDhaba:
                return "#FF9700"
            case RestaurantSlug.Grillados:
                return "#F8A344"
            default:
                return '#6E6E6E';
        }
    }
}

// RestoPage TODO: Add icon color case above
