import { Component, OnInit, Input, OnChanges, SimpleChange } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { BagItem } from '../../../core/models';
import { MainService } from 'src/app/core/services/main.service';

@Component({
	selector: 'bag-item',
	templateUrl: './bag-item.component.html',
	styleUrls: ['./bag-item.component.css'],
})
export class BagItemComponent implements OnInit, OnChanges {

	@Input() item: BagItem;
	@Input() disabled = false;
    @Input() orderPending = false;
    @Input() showPrice = true;
    @Input() showNotes = true;
	@Input() displayType: string;
	@Input() disableDecrement: boolean;
    @Input() showDecrementControl = true
    @Input() showIncrementControl = true

    isLoading: boolean = false;

	constructor(
		private mainService: MainService,
		public translate: TranslocoService
	) { }

	ngOnInit(): void { }

	ngOnChanges(changes: { [propName: string]: SimpleChange }) {
		if (
			changes['disabled'] &&
			changes['disabled'].currentValue !== changes['disabled'].previousValue
		) {
			this.isLoading = this.disabled;
		}
	}

	incrementItem(item: BagItem) {
		this.isLoading = true;
		this.mainService.incrementItem(this.mainService.bag, item).subscribe(_ => this.isLoading = false);
	}

	decrementItem(item: BagItem) {
		this.isLoading = true;
		this.mainService.decrementItem(this.mainService.bag, item).subscribe(_ => this.isLoading = false);
    }

    disableBagItemsControls() {
        return this.isLoading;
    }

	disableDecrementBagItemsControls(item: BagItem) {
		return this.isLoading || (item.quantity <= 1 && this.disableDecrement);
	}
}
