import { Injectable } from "@angular/core";
import { map, Observable, of, tap } from "rxjs";
import { Address, Bag, Product } from "../models";
import { AuthService } from "./auth.service";
import { IProductModification, IProductOptionSelection, KEY_GIFT_BOX } from "./bag.service";
import { BaseService } from "./base.service";
import { OrderType } from "../enums";
import { environment } from "src/environments/environment";

export const KEY_CRATE: string = 'crate_id';
@Injectable({
    providedIn: 'root',
})
export class CrateService {

    private crateEntity: string = 'crates';
    private giftBoxEntity: string = 'gift-boxes';

    constructor(
        private baseService: BaseService,
        private authService: AuthService) { }

    fetch(id: string, type = OrderType.Market): Observable<Bag> {
        let entity = (type == OrderType.Market) ? this.crateEntity : this.giftBoxEntity;
        return this.baseService.fetch(entity, id).pipe(map((res) => new Bag().deserialize(res.crate || res.giftBox)))
    }

    list(): Observable<Bag[]> {
        return this.baseService.list(this.crateEntity).pipe(map(res => res.crates.map(crate => new Bag().deserialize(crate))));
    }

    create(address?: string, type = OrderType.Market): Observable<Bag> {
        let data: any = {};
        if (address) data.address = address;
        let entity = (type == OrderType.Market) ? this.crateEntity : this.giftBoxEntity;
        if (type == OrderType.GiftBox) data.regionId = environment.MONTREAL_REGION_ID; //TODO: Get some other way

        return this.baseService.create(entity, data).pipe(map((res) => new Bag().deserialize(res.crate || res.giftBox)), tap(crate => {
            if (!this.authService.loggedIn()) localStorage.setItem(crate.isMarket ? KEY_CRATE : KEY_GIFT_BOX, crate.id);
        }));
    }

    addItem(id: string = null, product: Product, quantity: number, notes: string, options: IProductOptionSelection[], type = OrderType.Market): Observable<Bag> {
        let entity = (type == OrderType.Market) ? this.crateEntity : this.giftBoxEntity;

        let productModifications: IProductModification[] = [{ productId: product.id, quantity, notes }];
        if (options) productModifications[0].options = options
        return this.baseService.update(entity + '/' + id, { productModifications }).pipe(map(res => new Bag().deserialize(res.crate || res.giftBox)));
    }

    modify(id, productModifications: IProductModification[], type = OrderType.Market): Observable<Bag> {
        let entity = (type == OrderType.Market) ? this.crateEntity : this.giftBoxEntity;
        return this.baseService
            .update(entity + '/' + id, { productModifications })
            .pipe(map(res => (new Bag().deserialize(res.crate || res.giftBox))));
    }

    delete(id: string, isLoggedIn: boolean = false): Observable<void> {
        if (!isLoggedIn) {
            localStorage.removeItem(KEY_CRATE);
            return of();
        }
        else return this.baseService.delete(this.crateEntity, id);
    }

    assignCrateToSelf(id: string, type = OrderType.Market) {
        let entity = (type == OrderType.Market) ? this.crateEntity : this.giftBoxEntity;
        return this.baseService.create('user/' + entity, { crateId: id }).pipe(tap((res) => localStorage.removeItem((type == OrderType.Market) ? KEY_CRATE : KEY_GIFT_BOX)));
    }

    assignAddressToCrate(id: string, addressId: string, type = OrderType.Market, addressData?: Address): Observable<void> {
        let entity = (type == OrderType.Market) ? this.crateEntity : this.giftBoxEntity;
        let data: any = {};
        if (addressId) data.addressId = addressId;
        else if (addressData) data = { addressData: addressData };

        return this.baseService.update(entity + '/' + id + '/address', data);
    }
}