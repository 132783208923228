import { User, UserStubData } from './user.model';
import { Bag } from './bag'
import { Restaurant } from './restaurant.model'
import { OrderEvent } from './events'
import { DiscountInstance } from './discount-instance.model'

import Dinero from 'dinero.js';
import { Deserializable } from './deserializable.model';
import { CalculatedFee } from './calculated-fee.model';
import { GiftCard } from './gift-card.model';

export class Order implements Deserializable<Order> {
    id?: string;
    number?: number;
    restaurant?: Restaurant;
    user?: User;
    userStub?: UserStubData;
    bag?: Bag;
    payment?: {
        status?: string;
        intent?: string;
        giftCard?: GiftCard;
        balances?: {
            type: PaymentMethod;
            last4: string;
            brand: string;
            value: Dinero.Dinero;
        }[];
    };
    tip?: Dinero.Dinero;
    options?: {
        crates?: {
            reusable?: boolean;
        };
        noContact?: boolean;
        utensils?: boolean;
    };
    deliveryNote?: string;
    status: OrderStatus;
    events: OrderEvent[];
    createdAt: Date;
    type: string;
    scheduledDate?: string | Date;
    discounts: DiscountInstance[];
    fees: CalculatedFee[];
    rank?: number;

    constructor() {
        this.options = {};
    }

    //TODO: Handle group
    get clientName(): any {
        return this.user ? this.user.name : this.userStub.name;
    }

    get paymentMethod(): PaymentMethod {
        let balances = this.payment.balances;
        if (balances.length == 1 && balances[0].type == 'gift_card') return PaymentMethod.GiftCard;
        else if (balances.length == 1 && balances[0].type == 'card') return PaymentMethod.Card;
        else return PaymentMethod.Mixed;
    }

    get isPlatformType(): boolean {
        return (this.type == 'delivery' || this.type == 'pickup')
    }

    get isMarket(): boolean {
        return (this.type == 'market')
    }

    get isGiftBox(): boolean {
        return (this.type == 'gift_box')
    }

    get isExpo(): boolean {
        return (this.type == 'expo')
    }

    get isActive(): boolean {
        if (this.status === OrderStatus.Placed ||
            this.status === OrderStatus.Preparing ||
            this.status === OrderStatus.Ready ||
            this.status === OrderStatus.Delivering ||
            this.status === OrderStatus.Scheduled ||
            this.status === OrderStatus.Planned ||
            this.status === OrderStatus.Collecting ||
            this.status === OrderStatus.Assembling ||
            this.status === OrderStatus.Assembled) return true;
        else return false;
    }

    get isCompleted(): boolean {
        return [
            OrderStatus.Cancelled,
            OrderStatus.Delivered,
            OrderStatus.Refused,
            OrderStatus.PickedUp,
            OrderStatus.Completed,
        ].includes(this.status);
    }

    get subtotal(): Dinero.Dinero {
        return this.bag?.subtotal;
    }

    get tiplessTotal(): Dinero.Dinero {
        let feeTotal = this.fees.reduce((sum, fee) => sum.add(fee.amount), Dinero({ amount: 0, currency: 'CAD' }));
        let discountTotal = this.discounts.reduce((sum, discount) => sum.add(discount.effects.total.value), Dinero({ amount: 0, currency: 'CAD' }));
        let total = this.bag?.subtotal.add(feeTotal).subtract(discountTotal);
        return total.isPositive ? total : Dinero({ amount: 0, currency: 'CAD' });
    }

    get total(): Dinero.Dinero {
        let total = this.tiplessTotal.add(this.tip);
        return total.isPositive ? total : Dinero({ amount: 0, currency: 'CAD' });
    }

    deserialize(input: any): this {
        if (!input) return null;
        if (typeof input == 'string') this.id = input;
        else {
            delete input.subtotal;
            delete input.total;
            delete input.tiplessTotal;
            delete input.isExpo;
            delete input.isMarket;
            delete input.isPlatformType;
            delete input.isGiftBox;

            Object.assign(this, input);
            this.id = input._id ? input._id : input.id;

            this.restaurant = new Restaurant().deserialize(input.restaurant);
            if (input.user) this.user = new User().deserialize(input.user);
            // else if (input.userStub) {
            //     // this.user = new User().deserialize(input.userStub);
            //     // this['userStub'] = undefined;
            // }
            this.bag = new Bag().deserialize(input.bag);
            if (typeof input?.tip?.amount == 'number') this.tip = Dinero({ amount: input.tip.amount, currency: input.tip?.currency });
            else if (input?.tip?.getAmount != null) this.tip = input.tip;

            if (input?.payment?.balances?.length) { //TODO: map would be nicer
                let balances = [];
                for (let balance of input?.payment?.balances) {
                    if (typeof balance?.value?.amount == 'number') balance.value = Dinero({ amount: balance.value.amount, currency: balance.value.currency });
                    balances.push(balance);
                }
                this.payment.balances = balances;
            }

            if (input.events && Array.isArray(input.events)) {
                this.events = input.events.map(event => new OrderEvent().deserialize(event));
            }
            if (input.discounts && Array.isArray(input.discounts)) {
                this.discounts = input.discounts.map(discount => new DiscountInstance().deserialize(discount));
            }
            this.fees = Array.isArray(input.fees) ? input.fees.map(fee => new CalculatedFee().deserialize(fee)) : []
        }
        return this;
    }
}

export enum PaymentMethod {
    Card = 'card',
    GiftCard = 'gift_card',
    Mixed = 'mixed'
}

export enum OrderStatus {
    Initialized = 'initialized',
    Placed = 'placed',
    Preparing = 'preparing',
    Ready = 'ready',
    Delivering = 'delivering',
    Delivered = 'delivered',
    Refused = 'refused',
    Cancelled = 'cancelled',
    Delayed = 'delayed',
    Scheduled = 'scheduled',
    DriverArriving = 'driver_arriving',
    Assigned = 'assigned',
    PickedUp = 'picked_up',
    Completed = 'completed',
    Planned = 'planned',
    Collecting = 'collecting',
    Assembling = 'assembling',
    Assembled = 'assembled',
}
