export enum MenuStyle {
    SingleColumn,
    Standard
}

export enum MenuItemStyle {
    NoImage,
    Image,
    HorizontalWithOptionalImage
}

export enum RestaurantStyle {
    Classic,
    PictureBook
}
