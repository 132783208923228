<app-navbar [navbarType]="'checkout'"></app-navbar>

<div *ngIf="!isMapLoaded" class="checkout-loading-container">
    <div class="checkout-loading-inner-container">
        <div class="checkout-loading-left checkout-loading-column">
            <div class="checkout-loading-box loading-gradient"></div>
            <div class="checkout-loading-box loading-gradient"></div>
        </div>
        <div *ngIf="screenWidth > mobileThreshold"
            class="checkout-loading-right checkout-loading-column loading-gradient"></div>
    </div>
</div>

<div class="checkout-container">
    <google-map *ngIf="screenWidth > mobileThreshold && markersSet" [class.checkout-hidden]="!isMapLoaded"
        [center]="{ lat: restaurantLat, lng: restaurantLng }" height="100%" width="100%" id="checkout-map"
        [options]="mapOptions" [zoom]="15" (mapInitialized)="onMapLoaded($event)" [class.checkout-map-fixed]="fixMap">
        <map-directions-renderer *ngIf="(orderType$ | async) == 'delivery' && (directionsResults$ | async) as directionsResults"
            [directions]="directionsResults" [options]="{ polylineOptions: { strokeColor: '#FE2C7D' }, suppressMarkers: true }">
            <map-marker [position]="{ lat: restaurantLat, lng: restaurantLng }" [icon]="restaurantIcon"></map-marker>
            <map-marker [position]="{ lat: bagLat, lng: bagLng }" [icon]="houseIcon"></map-marker>
        </map-directions-renderer>
        <map-marker *ngIf="(orderType$ | async) !== 'delivery'" [position]="{ lat: restaurantLat, lng: restaurantLng }"
            [icon]="restaurantIcon">
        </map-marker>
    </google-map>
    <!-- <agm-map [class.checkout-hidden]="!mapLoaded" [style.height.%]="mapHeight" [fitBounds]="menuType === 'delivery'"
        [class.checkout-map-fixed]="fixMap" *ngIf="screenWidth > mobileThreshold && markersSet" id="checkout-map"
        [latitude]="restaurantLat" [longitude]="restaurantLng" [mapDraggable]="false" [disableDefaultUI]="true"
        [zoom]="15" (mapReady)="mapLoad($event)" [styles]="mapStyle">
        <agm-direction *ngIf="menuType === 'delivery'" [origin]="{ lat: restaurantLat, lng: restaurantLng }"
            [destination]="{ lat: bagLat, lng: bagLng }" [markerOptions]="markerOptions"
            [renderOptions]="renderOptions"></agm-direction>
        <agm-marker *ngIf="menuType !== 'delivery'" [latitude]="restaurantLat" [longitude]="restaurantLng"
            [iconUrl]="restaurantIcon"></agm-marker>
    </agm-map> -->
    <div class="checkout-inner-container">
        <div #headerContainer class="header-container">
            <div class="page-header">{{ 'checkout.title' | transloco }}</div>
            <div *ngIf="(restaurant$ | async)" class="checkout-restaurant-name">
                {{ 'checkout.from' | transloco }}
                <a class="restaurant-name"
                    [routerLink]="['/', translate.getActiveLang(), 'restaurants', (restaurant$ | async)?.slug]">
                    <strong>{{ (restaurant$ | async)?.name }}</strong>
                </a>
            </div>
        </div>
        <google-map *ngIf="mobileThreshold >= screenWidth && markersSet" [class.checkout-hidden]="!isMapLoaded"
            [center]="{ lat: restaurantLat, lng: restaurantLng }" height="15rem" width="100vw" id="checkout-map"
            [options]="mapOptions" [zoom]="15" (mapInitialized)="onMapLoaded($event)">
            <map-directions-renderer *ngIf="(orderType$ | async) == 'delivery' && (directionsResults$ | async) as directionsResults"
                [directions]="directionsResults" [options]="{ polylineOptions: { strokeColor: '#FE2C7D' }, suppressMarkers: true }">
                <map-marker [position]="{ lat: restaurantLat, lng: restaurantLng }" [icon]="restaurantIcon"></map-marker>
                <map-marker [position]="{ lat: bagLat, lng: bagLng }" [icon]="houseIcon"></map-marker>
            </map-directions-renderer>
            <map-marker *ngIf="(orderType$ | async) !== 'delivery'" 
                [position]="{lat: restaurantLat, lng: restaurantLng}" [icon]="restaurantIcon">
            </map-marker>
        </google-map>
        <!-- <agm-map [class.checkout-hidden]="!mapLoaded" [style.height]="mapHeight" [fitBounds]="menuType === 'delivery'"
            *ngIf="mobileThreshold >= screenWidth && markersSet" id="checkout-map" [latitude]="restaurantLat"
            [longitude]="restaurantLng" [mapDraggable]="false" [disableDefaultUI]="true" [zoom]="15"
            (mapReady)="mapLoad($event)" [styles]="mapStyle">
            <agm-direction *ngIf="menuType === 'delivery'" [origin]="{ lat: restaurantLat, lng: restaurantLng }"
                [destination]="{ lat: bagLat, lng: bagLng }" [markerOptions]="markerOptions"
                [renderOptions]="renderOptions"></agm-direction>
            <agm-marker *ngIf="menuType !== 'delivery'" [latitude]="restaurantLat" [longitude]="restaurantLng"
                [iconUrl]="restaurantIcon"></agm-marker>
        </agm-map> -->
        <form (keydown.enter)="$event.preventDefault()" [class.checkout-hidden]="!isMapLoaded" action="submit"
            (submit)="handleForm($event)" [formGroup]="checkoutForm" class="checkout-form">
            <div class="checkout-form-left">
                <div class="checkout-box">
                    <div class="form-section-title">
                        {{ (orderType$ | async) === 'delivery' ? ('checkout.yourDelivery' | transloco) :
                        ('checkout.yourTakeoutOrder' | transloco) }}
                    </div>
                    <div class="checkout-box-content">
                        <div class="box-row">
                            <div class="checkout-box-icon-text">
                                <img class="checkout-box-icon" src="../../assets/images/icons/clock.svg" alt="" />
                                <div class="checkout-box-text">
                                    <div *ngIf="(scheduledDate$ | async)" class="scheduled-time">
                                        <strong>{{ 'checkout.scheduledFor' | transloco }}
                                            {{ (scheduledDate$ | async) | translocoDate: longScheduledDateFormat
                                            }}</strong>
                                    </div>
                                    <div *ngIf="!(scheduledDate$ | async)">
                                        <strong>{{
                                            'checkout.timeRange'
                                            | transloco: { lower: (restaurant$ | async)?.delivery?.time ?
                                            (restaurant$ | async)?.delivery?.time - 5 : 35, upper: (restaurant$ |
                                            async)?.delivery?.time ?
                                            (restaurant$ | async)?.delivery?.time + 5 : 50 }
                                            }}</strong>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="(bag$ | async)?.address" class="checkout-box-icon-text checkout-address">
                                <img class="checkout-box-icon" src="../../assets/images/icons/pin.svg" alt="" />
                                <div class="checkout-box-text">
                                    <strong>{{ (bag$ | async)?.address | address }}</strong>
                                </div>
                            </div>
                            <ng-container *ngIf="(orderType$ | async) === 'delivery' && !(bag$ | async)?.address?.apt">
                                <button type="button" *ngIf="!addApartment" class="checkout-add-apartment checkout-add-button" (click)="addApartmentClick()">
                                    + {{ 'checkout.addApartment' | transloco }}</button>
                                <div *ngIf="addApartment" class="checkout-label-input-row">
                                    <label class="checkout-label-input-label" for="apartmentNumber"><span
                                            *ngIf="translate.getActiveLang() === 'fr'"># </span>Appt/Suite <span
                                            *ngIf="translate.getActiveLang() === 'en'">#</span></label>
                                    <input class="checkout-box-input form-input" type="text" id="apartmentNumber"
                                        formControlName="apartmentNumber">
                                </div>
                            </ng-container>
                        </div>
                        <div *ngIf="(orderType$ | async) === 'delivery'"
                            class="checkout-delivery-box-instructions-container box-row">
                            <div class="checkout-box-toggle-wrapper checkout-box-no-contact">
                                <div class="checkout-box-title form-toggle-title">
                                    {{ 'checkout.noContactDelivery' | transloco }}
                                </div>
                                <div class="checkout-box-switch-container">
                                    <label class="form-toggle">
                                        <input type="checkbox" formControlName="noContactCheckbox" />
                                        <span class="form-toggle-slider"></span>
                                    </label>
                                </div>
                            </div>
                            <div class="delivery-note-container">
                                <div class="delivery-note-title form-sub-title">
                                    {{ 'checkout.deliveryInstructions' | transloco }}
                                </div>
                                <input type="text" class="checkout-box-input form-input"
                                    (input)="deliveryNoteError = null" formControlName="deliveryInstructions"
                                    [placeholder]="'checkout.deliveryInstructionsPlaceholder' | transloco"
                                    [class.form-input-invalid]="deliveryNoteError" />
                                <div *ngIf="deliveryNoteError === 'RestrictedWords'" class="form-error-message">
                                    {{ 'checkout.deliveryNoteRestrictedWordError' | transloco }}</div>
                                <div *ngIf="deliveryNoteError === 'UnknownError'" class="form-error-message">
                                    {{ 'checkout.deliveryNoteError' | transloco }}</div>
                            </div>
                        </div>
                        <div class="checkout-delivery-box-utensils-container box-row">
                            <div class="checkout-box-toggle-wrapper">
                                <div class="checkout-box-title form-toggle-title">
                                    {{ 'checkout.utensilsQuestion' | transloco }}
                                </div>
                                <div class="checkout-box-switch-container">
                                    <label class="form-toggle">
                                        <input type="checkbox" formControlName="utensilsCheckbox" />
                                        <span class="form-toggle-slider"></span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="checkout-box">
                    <div class="form-section-title">
                        {{ 'checkout.payment.title' | transloco }}
                    </div>
                    <div class="checkout-box-content checkout-box-cards-container">
                        <div class="checkout-card-section-label">
                            {{ 'checkout.cards' | transloco }}
                        </div>
                        <label *ngFor="let card of savedCards" class="checkout-card" (click)="onSavedCardClick()">
                            <span><input class="checkout-card-radio-input" type="radio" formControlName="cardRadio"
                                    [value]="card.id" /></span>
                            <div class="checkout-saved-card">
                                {{ 'checkout.savedCard' | transloco }}
                                <strong>{{ card.last4 }}</strong>
                            </div>
                            <img class="checkout-saved-card-logo" [src]="getCardLogo(card.brand)" alt="" />
                        </label>
                        <button type="button" *ngIf="!openNewCard" (click)="onClickAdd('newCard')" class="add-new-card-label checkout-add-button">
                            {{ 'checkout.addCard' | transloco }}
                        </button>
                        <div [ngClass]="openNewCard == true ? 'open-new-card' : 'close-new-card'">
                            <span><input class="checkout-card-radio-input" type="radio" formControlName="cardRadio"
                                    value="new-card" id="new-card" hidden /></span>
                            <div [class.checkout-disabled]="loading" class="checkout-box-input form-input " #cardElement>
                            </div>
                        </div>
                        <p class="checkout-box-payment-errors">{{ cardErrors }}</p>
                    </div>
                    <div class="box-row gift-card-box-row">
                        <div class="checkout-box-toggle-wrapper">
                            <div class="checkout-box-title form-toggle-title">
                                <div class="gift-card-section-label"> {{ 'checkout.giftCards' | transloco }}</div> 
                                <div class="gift-card-container">
                                    <div class="gift-card-image-wrapper">
                                        <img class="gift-card-image" [src]="selectedGiftCard?.theme?.front ? selectedGiftCard?.theme?.front.url : '../../assets/images/gift-card/gift-card-checkout-icon.png' "> 
                                    </div>
                                    <div ngbDropdown autoClose="true" class="d-flex" #giftCardDropdown="ngbDropdown">
                                        <button type="button" class="gift-card-box" ngbDropdownToggle #giftCardDropdown>
                                           <div class="dropdown-label selected-card-wrapper" *ngIf="selectedGiftCard">
                                               <div class="selected-card-name"> {{ selectedGiftCard.title }} </div>
                                               <div class="selected-card-price"> {{ selectedGiftCard.balance | price | translocoCurrency }}</div>
                                           </div>
                                           <div class="dropdown-label" *ngIf="!selectedGiftCard"> {{ 'checkout.noGiftCardSelected' | transloco }}</div>
                                           <!-- <div class="dropdown-chevron">
                                               <img class="chevron-icon" src="../../assets/images/dropdown-chevron.png"> 
                                           </div> -->
                                        </button>
                                        <div ngbDropdownMenu class="gift-card-dropdown-menu-container gift-card-dropdown-menu-container-wide">
                                            <div class="gift-card-dropdown-menu-wide">
                                                <div class="gift-card-dropdown-item" (click)="selectedGiftCard = null; giftCardDropdown.close()">
                                                    <div class="null-icon-wrapper">
                                                        <img class="null-icon" src="../../assets/images/icons/null.svg">
                                                    </div>
                                                    <div class="gift-card-name"> {{ 'checkout.noGiftCard' | transloco }}</div>
                                                </div> 
                                                <div class="gift-card-dropdown-item" *ngFor="let giftCard of giftCards" (click)="onSelectGiftCard(giftCard.id); giftCardDropdown.close();" [ngClass]="{ 'active': selectedGiftCard === giftCard }">
                                                    <div class="gift-card-image-wrapper">
                                                        <img class="gift-card-image" [src]="giftCard.theme?.front.url"> 
                                                    </div>
                                                    <div class="gift-card-name">
                                                        {{ giftCard.title }}
                                                    </div>
                                                    <div class="gift-card-price">
                                                        {{ giftCard.balance | price | translocoCurrency }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="gift-card-message">
                            {{ 'giftCard.message' | transloco }}
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="(bag$ | async) && (order$ | async)" class="checkout-form-right">
                <div class="checkout-box">
                    <div class="form-section-title">
                        {{ 'checkout.yourOrder' | transloco }}
                    </div>
                    <div class="checkout-box-content">
                        <div class="box-row bag-items">
                            <ng-container *ngIf="(bag$ | async) != null">
                                <div class="bag-item" *ngFor="let item of (bag$ | async)?.items">
                                    <bag-item [item]="item" [disabled]="loading" [disableDecrement]="disableDecrement"></bag-item>
                                </div>
                            </ng-container>
                        </div>
                        <div class="box-row">
                            <div class="bag-note-container form-sub-title">
                                {{ 'checkout.bagNote' | transloco }}
                            </div>
                            <input type="text" class="checkout-box-input form-input" (input)="bagNoteError = null"
                                formControlName="bagNote" [placeholder]="'checkout.bagNotePlaceholder' | transloco"
                                [class.form-input-invalid]="bagNoteError" />
                            <div *ngIf="bagNoteError === 'RestrictedWords'" class="form-error-message">
                                {{ 'checkout.bagNoteRestrictedWordError' | transloco }}</div>
                            <div *ngIf="bagNoteError === 'UnknownError'" class="form-error-message">
                                {{ 'checkout.bagNoteError' | transloco }}</div>
                        </div>
                        
                        <!-- BOPAQ OPTION -->
                        <div class="box-row checkout-bopaq-container" *ngIf="[RestaurantSlug.Nakamichi, RestaurantSlug.Maynard, RestaurantSlug.TeochewFoodie].indexOf((restaurant$ | async)?.slug) != -1">
                            <form (keydown.enter)="$event.preventDefault()" [class.checkout-hidden]="!isMapLoaded"
                                [formGroup]="bopaqForm" class="bopaq-form">
                                <div class="checkout-box-toggle-wrapper">
                                    <div class="checkout-box-toggle-title-wrapper">
                                        <div class="form-toggle-logo">
                                            <img src="../../assets/images/bopaq/bopaq-logo.png" class="bopaq-logo">
                                        </div>
                                        <div class="checkout-box-title form-toggle-title">
                                            {{ 'checkout.bopaq.title' | transloco }}
                                        </div>
                                        <ng-template #bopaqTipContent>
                                            <div class="bopaq-image-wrapper">
                                                <img class="bopaq-image"
                                                    src="../../assets/images/bopaq/bopaq-image.jpg">
                                            </div>
                                            <div class="bopaq-text-wrapper">
                                                <div class="bopaq-title">{{ 'checkout.bopaq.tooltip.title' | transloco
                                                    }}</div>
                                                <div class="bopaq-subtitle">{{ 'checkout.bopaq.tooltip.subtitle.1' |
                                                    transloco }}
                                                    <a href='https://bopaq.com' target='_blank'
                                                        title='Bopaq'>https://bopaq.com</a> {{
                                                    'checkout.bopaq.tooltip.subtitle.2' | transloco }}
                                                </div>
                                            </div>
                                        </ng-template>
                                        <div class="info-tooltip" [placement]="getTooltipPlacement()"
                                            [ngbTooltip]="bopaqTipContent" triggers="hover focus click:blur"
                                            closeDelay="1500" openDelay="300">
                                            <img src="../../assets/images/icons/info-round-button.png"
                                                class="info-tooltip-icon">
                                        </div>
                                    </div>
                                    <div class="checkout-box-switch-container">
                                        <label class="form-toggle">
                                            <input type="checkbox" formControlName="bopaqCheckbox" />
                                            <span class="form-toggle-slider bopaq-form-toggle-slider"></span>
                                        </label>
                                    </div>
                                </div>
                                <div class="bopaq-form-input-wrapper"
                                    *ngIf="isBrowser && bopaqForm.value.bopaqCheckbox">
                                    <input #bopaqId type="text" class="checkout-box-input form-input bopaq-form-input"
                                        (input)="bopaqIdError = null" formControlName="bopaqId" [inputMask]="bopaqInputMask"
                                        placeholder="___-___-____"/>
                                    <!-- [ngClass]="{ 'form-input-invalid': bopaqForm.get('bopaqId').dirty && bopaqForm.get('bopaqId').invalid }" -->
                                    <!-- <div *ngIf="bopaqForm.get('bopaqId').dirty && bopaqForm.get('bopaqId').invalid"
                                        class="form-error-message">
                                        Your user code must be 9 digits long! </div> -->
                                </div>
                            </form>
                        </div>
                        
                        <!-- Fees -->
                        <div class="box-row">
                            <div class="checkout-text-both-ends">
                                <div><b>{{ 'checkout.subtotal' | transloco }}</b></div>
                                <div><b>{{ (bag$ | async)?.subtotal | price | translocoCurrency }}</b></div>
                            </div>
                            <ng-container *ngIf="(order$ | async).fees as fees">
                                <div *ngFor="let fee of fees" class="checkout-text-both-ends">
                                    <div>
                                        {{ fee.title }}
                                        <!-- TODO: change - this is a temporary way to check if small order fee exists -->
                                        <img *ngIf="!fee.description" src="../../assets/images/icons/info-round-button.png" 
                                            class="info-tooltip-icon small-order-fee-info-icon"
                                            [ngbTooltip]="('checkout.tooltips.smallOrderFeeInfo' | transloco: { orderMinimum: (configService.MINIMUM_DELIVERY_ORDER_AMOUNT | price | translocoCurrency) })" triggers="hover focus click:blur" tooltipClass="radish-tooltip" openDelay="200" closeDelay="500">
                                    </div>
                                    <div>{{ fee.amount | price | translocoCurrency }}</div>
                                </div>
                            </ng-container>
                            <div *ngIf="(orderType$ | async) === 'delivery'" class="checkout-text-both-ends">
                                <div>{{ 'checkout.deliveryFee' | transloco }}</div>
                                <div class="checkout-delivery-fee">{{ 'checkout.free' | transloco }}</div>
                                <!-- {{ configService.STANDARD_DELIVERY_FEE | price | translocoCurrency }}</div> -->
                            </div>
                            
                            <!-- Discount -->
                            <div *ngFor="let discountInstance of (order$ | async)?.discounts"
                                class="checkout-text-both-ends discount">
                                <div class="discount-left">
                                    <span class="discount-title">{{ discountInstance.discount.title }}</span>
                                    <button type="button" class="delete-discount-btn" (click)="onDeleteDiscount(discountInstance['_id'])">
                                        <img src="../../assets/images/icons/close.svg">
                                    </button>
                                </div>
                                <div class="discount-right discount-price">
                                    -{{ discountInstance?.effects.total.value | price | translocoCurrency }}
                                </div>
                            </div>
                            <div *ngIf="(orderType$ | async) === 'pickup'" class="checkout-bot-title form-sub-title">
                                {{ 'checkout.donate' | transloco }}
                            </div>
                            <div *ngIf="(orderType$ | async) === 'delivery'"
                                class="checkout-box-title form-sub-title tip-courrier">
                                {{ 'checkout.tipCourrier' | transloco }}
                            </div>
                            <div class="checkout-tip-container">
                                <div class="checkout-tip-options">
                                    <input id="tip_10" type="radio" value="10" formControlName="tipRadio"
                                        (change)="tipRadioChange()" />
                                    <label class="checkout-tip-option-label" for="tip_10">10%</label>
                                    <input id="tip_15" type="radio" value="15" formControlName="tipRadio"
                                        (change)="tipRadioChange()" />
                                    <label class="checkout-tip-option-label" for="tip_15">15%</label>
                                    <input id="tip_20" type="radio" value="20" formControlName="tipRadio"
                                        (change)="tipRadioChange()" />
                                    <label class="checkout-tip-option-label" for="tip_20">20%</label>
                                    <input decimals="2" class="checkout-tip-option-custom checkout-box-input form-input"
                                        (keypress)="onCustomTipKeypress($event)"
                                        (input)="onCustomTipChange($event.target.value)"
                                        formControlName="tipAmountCustom" type="text" placeholder="Amount" appTipKeypress/>
                                </div>
                            </div>
                            <!-- <div *ngIf="menuType === 'pickup'" class="checkout-tip-container">
                                <div class="checkout-tip-options">
                                    <input id="tip_10" type="radio" value="1" formControlName="tipRadio"
                                        (change)="tipRadioChange($event.target.value)" />
                                    <label class="checkout-tip-option-label" for="tip_10">$1</label>
                                    <input id="tip_15" type="radio" value="2" formControlName="tipRadio"
                                        (change)="tipRadioChange($event.target.value)" />
                                    <label class="checkout-tip-option-label" for="tip_15">$2</label>
                                    <input id="tip_20" type="radio" value="3" formControlName="tipRadio"
                                        (change)="tipRadioChange($event.target.value)" />
                                    <label class="checkout-tip-option-label" for="tip_20">$3</label>
                                    <input decimals="2"
                                        class="checkout-tip-option-custom checkout-box-input form-input"
                                        (keypress)="onCustomTipKeypress($event)"
                                        (input)="onCustomTipChange($event.target.value)"
                                        formControlName="tipAmountCustom" type="text" placeholder="Amount" />
                                </div>
                            </div> -->
                        </div>
                        <div class="box-row">
                            <div class="checkout-text-both-ends checkout-text-both-ends-total">
                                <div class="checkout-total checkout-total-text">
                                    {{ 'checkout.total' | transloco }}
                                </div>
                                <div class="checkout-total checkout-total-number">{{ calculateTotal() | price | translocoCurrency }}</div>
                            </div>
                        </div>
                        <div class="box-row">
                            <button class="form-submit-button" type="submit" [disabled]="
                            !checkoutForm.valid ||
                            cardErrors ||
                            loading ||
                            configService.MINIMUM_ORDER_AMOUNT.getAmount() > (bag$ | async)?.subtotal.getAmount() ||
                            (isCardEmpty && checkoutForm.controls.cardRadio.value === 'new-card')">
                                <span *ngIf="!loading">
                                    {{ 'checkout.placeOrder' | transloco }}
                                </span>
                                <div *ngIf="loading" class="spinner-border spinner-border-sm" role="status">
                                    <span class="sr-only">{{ 'common.loading' | transloco }}</span>
                                </div>
                            </button>
                            <button type="button" class="checkout-add-button add-promo-code" (click)="onClickAddPromoCode()">
                                {{ 'checkout.promoCode' | transloco }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
<!--<app-footer [footerType]="'white'"></app-footer> -->