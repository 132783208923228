import { Directive, Input, EventEmitter, Output, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appScrollSpy]'
})
export class ScrollSpyDirective {
  @Input() public spiedTags = [];
  @Output() public sectionChange = new EventEmitter<string>();
  constructor(private el: ElementRef) { }
  
  @HostListener('window:scroll', ['$event'])
  onScroll(event: any) {

    let currentSection: string;
    const children = this.el.nativeElement.children;
    const scrollTop = event.target.scrollingElement.scrollTop;


    // const parentOffset = 90;
    const parentOffset = 100;
    for (let i = 0; i < children.length; i++) {
      const element = children[i]


      if (this.spiedTags.some((spiedTag) => spiedTag === element.tagName)) {
        if ((element.offsetTop - parentOffset) <= scrollTop) {
          currentSection = element.id;
        }
      }
    }
    
    if (currentSection != undefined){
      this.sectionChange.emit(currentSection);
    }
  }
}
