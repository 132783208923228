<form action="submit" class="checkout-form-container needs-validation" novalidate (submit)="onSubmit()" [formGroup]="expoCheckoutDetailsForm">
    <h2 class="checkout-form-title">{{ 'expo.checkout.general.title' | transloco }}</h2>
    <div class="checkout-form-description">{{ 'expo.checkout.general.description' | transloco }}</div>
    <div class="checkout-form-input-wrapper">
        <label class="checkout-form-checkbox-label" for="name">{{ 'expo.checkout.general.name' | transloco
            }}</label>
        <input class="checkout-form-input form-input checkout-form-name-input" type="text" #nameInput id="name"
            [placeholder]="'expo.checkout.general.namePlaceholder' | transloco" formControlName="name" required [ngClass]="{'complete': expoCheckoutDetailsControls.name.valid}">
        <div class="checkout-form-danger invalid-feedback" *ngIf="expoCheckoutDetailsControls.name.invalid && (expoCheckoutDetailsControls.name.dirty || expoCheckoutDetailsControls.name.touched)">
            {{ 'expo.checkout.errors.nameEmpty' | transloco }}
        </div>        
    </div>
    <div class="checkout-form-input-wrapper">
        <label class="checkout-form-checkbox-label" for="phone">{{
            'expo.checkout.general.phoneNumber' | transloco }}</label>
        <input class="checkout-form-input form-input checkout-form-number-input" type="text" id="phone" *ngIf="!isName" #numberInput
            [placeholder]="'expo.checkout.general.phoneNumberPlaceholder' | transloco" formControlName="number" required [ngClass]="{'complete': expoCheckoutDetailsControls.number.valid}">
        <div class="checkout-form-danger invalid-feedback" *ngIf="expoCheckoutDetailsControls.number.invalid && (expoCheckoutDetailsControls.number.dirty || expoCheckoutDetailsControls.number.touched)">
            {{ 'expo.checkout.errors.numberEmpty' | transloco }}
        </div>   
    </div>
    <div class="checkout-form-input-wrapper">
        <label class="checkout-form-checkbox-label" for="email">{{
            'expo.checkout.general.emailAddress' | transloco }}</label>
        <input class="checkout-form-input form-input checkout-form-email-input" type="email" id="email" *ngIf="!isName" #addressInput
            [placeholder]="'expo.checkout.general.emailAddressPlaceholder' | transloco" formControlName="email" required [ngClass]="{'complete': expoCheckoutDetailsControls.email.valid}">
        <div class="checkout-form-danger invalid-feedback" *ngIf="expoCheckoutDetailsControls.email.invalid && (expoCheckoutDetailsControls.email.dirty || expoCheckoutDetailsControls.email.touched)">
            {{ 'expo.checkout.errors.addressEmpty' | transloco }}
        </div> 
    </div>
    <div class="checkout-form-checkbox-container">
        <div class="checkout-form-checkbox-wrapper">
            <input class="checkout-form-checkbox" type="checkbox" formControlName="giftCardCheckbox"
                id="giftCardCheckbox">
        </div>
        <label class="checkout-form-checkbox-text form-check-label" for="giftCardCheckbox">
            <span>{{ 'expo.checkout.general.radishGiftCard.1' | transloco }}</span>
            <span class="radish-logo"><img src="../../../../../assets/images/radish-logos/Radish-Logo-BlackText-Large.png"></span>
            <span>{{ 'expo.checkout.general.radishGiftCard.2' | transloco }}</span>
        </label>
    </div>
    <button class="checkout-button form-submit-button" type="submit"
        [disabled]="expoCheckoutDetailsForm.invalid || isLoading">
        <ng-container *ngIf="!isLoading">
            {{ 'common.continue' | transloco }}
        </ng-container>
        <div *ngIf="isLoading" class="spinner-border spinner-border-sm text-light" role="status">
            <span class="sr-only">{{ 'common.loading' | transloco }}</span>
        </div>
    </button>
</form>