import { Component, HostListener, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { Router, NavigationEnd, RoutesRecognized } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DeliveryClosedComponent } from '../../modals/delivery-closed/delivery-closed.component';
import { Menu, Restaurant } from '../../core/models';
import { PlatformLocation } from '@angular/common';
import { filter, pairwise } from 'rxjs/operators';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { ConfigService } from '../../core/services/config.service';
import { Color, OrderType, RestaurantSlug, RestaurantStyle } from '../../core/enums';
import { isPlatformBrowser } from '@angular/common';
import { MainService } from '../../core/services/main.service';
import 'detect-browser';
import { detect, detectOS } from 'detect-browser';

@Component({
    selector: 'app-restaurant',
    templateUrl: './restaurant.component.html',
    styleUrls: ['./restaurant.component.css'],
})
export class RestaurantComponent implements OnInit {

    private subscription: Subscription = new Subscription();

    RestaurantSlug = RestaurantSlug;
    RestaurantStyle = RestaurantStyle;
    Color = Color;
    scheduledDate: Date;

    restaurant$: Observable<Restaurant>;
    orderType$: Observable<OrderType>;
    menu$: Observable<Menu>;
    showOrderHereAnimation$: Observable<boolean>;

    tab;
    navbarType = 'restaurant';
    lastPathElement = '';

    readonly MOBILE_THRESHOLD = 768;
    isMobile: boolean = false;
    private isBrowser: boolean;

    constructor(
        @Inject(PLATFORM_ID) platformId: Object,
        private router: Router,
        public translate: TranslocoService,
        private modalService: NgbModal,
        private location: PlatformLocation,
        public configService: ConfigService,
        private mainService: MainService
    ) {
        if (isPlatformBrowser(platformId)) this.isBrowser = true;
        location.onPopState(() => this.modalService.dismissAll());

        //TODO: iosfix
        if (this.isBrowser && (detect().name == 'safari' || detect().os == 'iOS')) setTimeout(() => window.scrollTo(0, 0), 115);

        //TODO: Is there a more elegeant way to handle this code?
        this.subscription.add(this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(_ => {
            this.tab = this.router.url.split('/')[4]
            if (this.isBrowser) {
                if (!(this.lastPathElement == 'about' || this.lastPathElement == 'reviews' || this.lastPathElement == 'menu' || this.lastPathElement == '')) {
                    window.scrollTo(0, 0);
                }
            }
        }));

        this.subscription.add(this.router.events
            .pipe(filter((event: any) => event instanceof RoutesRecognized), pairwise())
            .subscribe((events: RoutesRecognized[]) => {
                let prevParts = events[0].urlAfterRedirects.split('/');
                this.lastPathElement = prevParts[prevParts.length - 1];
            })
        )
    }

    ngOnInit() {

        this.restaurant$ = this.mainService.restaurantLoaded$;
        this.orderType$ = this.mainService.orderTypeInViewLoaded$;
        this.menu$ = this.mainService.menuLoaded$;
        this.showOrderHereAnimation$ = this.mainService.showOrderhereAnimation$;
        this.scheduledDate = this.mainService.scheduledDate;

        combineLatest([this.restaurant$, this.orderType$])
            .pipe(filter(values => !values.includes(null)))
            .subscribe();

        let restaurant = this.mainService.restaurantInView;
        if (!restaurant.isOpenForMenuType(this.mainService.orderTypeInView, this.scheduledDate)) this.showNoDeliveryModal(restaurant, this.mainService.orderTypeInView);

        //to keep nav item active on refresh
        var url = this.router.url;
        var check = url.split('/');
        this.tab = check[4];
        if (this.isBrowser) window.scrollTo(0, 0);

        this.isMobile = window.innerWidth < this.MOBILE_THRESHOLD;

        window.addEventListener('scroll', () => {
            document.body.style.setProperty('--scroll', (window.pageYOffset / (document.body.offsetHeight - window.innerHeight)).toString());
          }, false);
    }

    showNoDeliveryModal(restaurant: Restaurant, orderType: string) {
        const modalRef = this.modalService.open(DeliveryClosedComponent, { centered: true });
        modalRef.componentInstance.restaurant = restaurant;
        modalRef.componentInstance.orderType = orderType;
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.isMobile = window.innerWidth < this.MOBILE_THRESHOLD
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}
