<div class="component-container">
    <app-back-button [backgroundColor]="configService.getPrimaryColor(restaurant.slug)" [iconColor]="configService.getSecondaryColor(restaurant.slug)"></app-back-button>
    <div class="inner-container">
        <div class="blurb-container about-row">
            <div class="section-container">
                <div class="section-title" [style.color]="configService.getPrimaryColor(restaurant.slug)">
                    Juliette & Chocolat, c’est l’endroit parfait pour (re)tomber dans le chocolat et découvrir cet
                    ingrédient magique dans tous ses états!
                </div>
                <div class="section-text" [innerHtml]="configService.getRestaurantDescription(restaurant.slug, translate.getActiveLang())">
                </div>
            </div>
        </div>
        <div class="about-row multi-column">
            <div class="location-container section-container">
                <div class="section-title" [style.color]="configService.getPrimaryColor(restaurant.slug)">{{
                    'restaurant.about.location' | transloco }}</div>
                <div *ngIf="restaurant.address"
                    class="location-container-address-container">
                    <svg class="location-container-address-pin icon" viewBox="0 0 32 32">
                        <path [style.fill]="configService.getPrimaryColor(restaurant.slug)" d="m25.0464 8.4834a10 10 0 0 0 -7.9116-5.4258 11.3644 11.3644 0 0 0 -2.2691 0 10.0027 10.0027 0 0 0 -7.9121 5.4253 10.8062 10.8062 0 0 0 1.481 11.8936l6.7929 8.2588a1 1 0 0 0 1.545 0l6.7929-8.2588a10.8055 10.8055 0 0 0 1.481-11.8931zm-9.0464 8.5166a4 4 0 1 1 4-4 4.0047 4.0047 0 0 1 -4 4z"/>
                    </svg>
                    <div class="location-container-address-text">
                        <div class="location-container-address">
                            {{ (restaurant.address | address : 'standard') }}
                        </div>
                        <div class="location-container-address-get-directions">
                            <a class="link"
                                href="https://www.google.com/maps/search/?api=1&query={{encodeURI((restaurant.address | address : 'standard'))}}"
                                target="_blank">({{'restaurant.about.directions' | transloco}})</a>
                        </div>
                    </div>
                </div>
                <app-location-map 
                    [latitude]="restaurant?.address?.loc?.coordinates[1]"
                    [longitude]="restaurant?.address?.loc?.coordinates[0]" 
                    [zoom]="zoom"
                    [style]="configService.getMapStyle(restaurant.slug)"
                    [radius]="restaurant.delivery.radius"
                    [radiusFillColor]="configService.getMapRadiusFillColor(restaurant.slug)"
                    [showRadius]="radiusCircleVisible">
                </app-location-map>
                <button type="button" class="toggle-radius-button" (click)="toggleDeliveryRadiusClick()">
                    {{ radiusCircleVisible ? ('restaurant.about.hideRadius' | transloco) : ('restaurant.about.showRadius' | transloco) }}
                </button>
            </div>
            <div class="contact-container section-container">
                <div class="section-title" [style.color]="configService.getPrimaryColor(restaurant.slug)">{{
                    'restaurant.about.contact' | transloco }}</div>
                <div class="contact-row">
                        <svg class="icon" height="512" viewBox="0 0 32 32" width="512">
                            <path [style.fill]="configService.getPrimaryColor(restaurant.slug)" d="m23.45 20.93a3 3 0 0 0 -4.25 0l-1.45 1.45a26.14 26.14 0 0 1 -4.51-3.62 26.14 26.14 0 0 1 -3.62-4.51l1.45-1.45a3 3 0 0 0 0-4.25l-2.83-2.82a2.93 2.93 0 0 0 -2.11-.88 3 3 0 0 0 -2.13.88l-1.36 1.35a6.37 6.37 0 0 0 -1.64 5.25c.32 3.75 2.78 8.26 6.57 12.06s8.35 6.25 12.1 6.61a7.87 7.87 0 0 0 .84 0 6.07 6.07 0 0 0 4.41-1.64l1.35-1.36a3 3 0 0 0 .88-2.13 2.93 2.93 0 0 0 -.88-2.11z"/>
                            <path [style.fill]="configService.getPrimaryColor(restaurant.slug)" d="m26.6 5.39a14.92 14.92 0 0 0 -10.6-4.39 1 1 0 1 0 0 2 13 13 0 0 1 13 13.11 1 1 0 0 0 1 1 1 1 0 0 0 1-1 14.91 14.91 0 0 0 -4.4-10.72z"/>
                            <path [style.fill]="configService.getPrimaryColor(restaurant.slug)" d="m20.91 11.11a6 6 0 0 1 1.77 4.31 1 1 0 0 0 1 1 1 1 0 0 0 1-1 8 8 0 0 0 -8-8.1 1 1 0 1 0 0 2 6 6 0 0 1 4.23 1.79z"/>
                        </svg>
                    <a class="contact-text" [href]="'tel:+1' + restaurant.contact.number">+1 {{ restaurant.contact.number | phoneNumber: 'standard' }}</a>
                </div>
                <div class="contact-row">
                        <svg height="512" viewBox="0 0 32 32" width="512" class="icon">
                            <path [style.fill]="configService.getPrimaryColor(restaurant.slug)"
                                d="m30.944 7.139a3.684 3.684 0 0 0 -3.633-3.139h-22.622a3.684 3.684 0 0 0 -3.633 3.139l14.944 9.67z" />
                            <path [style.fill]="configService.getPrimaryColor(restaurant.slug)"
                                d="m16.543 18.84a1 1 0 0 1 -1.086 0l-14.457-9.354v14.825a3.693 3.693 0 0 0 3.689 3.689h22.622a3.693 3.693 0 0 0 3.689-3.689v-14.826z" />
                        </svg>
                    <a href="mailto:{{restaurant.contact.email}}"  class="contact-text">{{ restaurant.contact.email }}</a>
                </div>
            </div>
        </div>
    </div>
</div>
