<div class="market-product-wrapper" [ngClass]="{'market-subcategory-product' : isFeaturedRow == false}">
    <div class="market-product-image-container">
        <!-- TODO: add routing to market product page-->
        <a class="market-product-image-routing"
            [routerLink]="['/' + translate.getActiveLang() + '/marché/merchants/' + item.restaurant.slug + '/products/' + item.slug]">
            <img class="market-product-image radish-default-image" *ngIf="!item?.marketThumbnailUrl" src="../../../../assets/images/radish-logos/Radish-LogoMark-Color-Large.png"/>
            <img class="market-product-image" *ngIf="item?.marketThumbnailUrl" src="{{ item?.marketThumbnailUrl }}" />
        </a>
        <div class="add-to-crate-button-container" *ngIf="!hideCrateButton">
            <app-add-to-crate-button [product]="item"></app-add-to-crate-button>
        </div>
    </div>
    <a [routerLink]="['/' + translate.getActiveLang() + '/marché/merchants/' + item.restaurant.slug + '/products/' + item.slug]">
        <div class="market-product-information">
            <div *ngIf="showRestaurantName" class="market-product-vendor"> {{ item.restaurant?.name }} </div>
            <div class="market-product-title"> {{ item.title }} </div>
            <div class="market-price-line">
                <div class="market-product-price"> {{ item.price | price | translocoCurrency }} </div>
                <div class="market-product-units"> {{ 'market.servings.' + item.servings.unit.slug | transloco : { count: item.servings.count } }}</div>
            </div>
            <div class="market-product-availability" *ngIf="!item.isAvailable"> {{ 'market.unavailable' | transloco }}</div>
        </div>
    </a>
</div>