import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'app-guestlist',
	templateUrl: './guestlist.component.html',
	styleUrls: ['./guestlist.component.css']
})
export class GuestlistComponent implements OnInit {

	@Input() guestList: string[];

	expandedList: boolean = false;
	constructor() { }

	ngOnInit(): void {
	}

	ExpandList() {
		this.expandedList = !this.expandedList;
	}
}
