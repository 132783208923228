<app-navbar [navbarType]="'market'"></app-navbar>
<div class="market-product-container">
    <div class="market-header d-none d-sm-flex">
        <a routerLink="/marché">
            <h1 class="header-title">Marché Radish</h1>
        </a>
        <a class="restaurant-listing-routing-button d-none d-md-flex"
            [routerLink]="['/' + translate.getActiveLang() + '/restaurants/']">
            <div class="restaurant-listing-routing-icon-wrapper">
                <img class="restaurant-listing-routing-icon" src="../../assets/images/market/package.png" />
            </div>
            <div class="restaurant-listing-routing-message"> {{ 'routing.toRestaurant' | transloco }}</div>
        </a>
    </div>
    <div class="market-category-nav d-none d-sm-flex">
        <div class="market-category-container">
            <div class="scroll-space d-inline-block d-md-inline-block d-lg-none"></div>
            <button type="button" class="market-category-button-wrapper" *ngFor="let category of categories"
                [routerLink]="['/', translate.getActiveLang(), 'marché', category.route]">
                <div class="market-nav-icon-wrapper">
                    <img class="market-nav-icon" src="{{ category.icon }}" />
                </div>
                <p class="option-title"> {{ category.title?.[translate.getActiveLang()] }} </p>
            </button>
            <div class="scroll-space d-inline-block d-lg-none"></div>
        </div>
    </div>
    <!-- <div class="path-button-container">
        <button type="button" (click)="clickPath(path)" class="path" *ngFor="let path of navigationPath; index as i" [disabled]="i == navigationPath.length - 1">{{ path[translate.getActiveLang()] }}</button>
    </div> -->
    <div class="product-container">
        <div class="product-header">
            <div class="product-image-container">
                <div class="path-button-container">
                    <button type="button" (click)="clickPath(path)" class="path"
                        *ngFor="let path of navigationPath; index as i" [disabled]="i == navigationPath.length - 1">{{
                        path[translate.getActiveLang()] }}</button>
                </div>
                <img src="{{ product?.imageUrl }}">
            </div>
            <div class="product-detail-container">
                <div class="product-title-wrapper">
                    <div class="product-title-wrapper-left">
                        <a class="restaurant-label"
                            [routerLink]="['/', translate.getActiveLang(), 'marché', 'merchants', product?.restaurant?.slug]">
                            {{ product?.restaurant?.name }} </a>
                        <div class="product-label"> {{ product?.title }} </div>
                    </div>
                    <a *ngIf="product?.restaurant?.assets?.logo?.primary?.url"
                        class="product-title-wrapper-right restaurant-logo-container"
                        [routerLink]="['/', translate.getActiveLang(), 'marché', 'merchants', product?.restaurant?.slug]">
                        <img [src]="product?.restaurant?.assets?.logo?.primary?.url" class="restaurant-logo">
                    </a>
                </div>
                <div *ngIf="product?.isFrozen == true || product?.restrictionCategories.length > 0"
                    class="product-info-container">
                    <!--TO-DO: Product review 
                <div
                    class="product-review restaurant-review restaurant-reviews-summary-average-stars-container restaurant-info">
                    <svg *ngFor="let dummy of [].constructor(5)" class="restaurant-reviews-summary-average-star"
                        viewBox="0 0 24 22.83">
                        <path [attr.fill]="'black'"
                            d="M12,0l3.67,7.57L24,8.72l-6.06,5.83,1.48,8.28-7.42-4-7.42,4,1.48-8.28L0,8.72,8.33,7.57Z" />
                    </svg>
                </div> -->
                    <div class="product-frozen-container product-info" *ngIf="product?.isFrozen">
                        <div class="frozen-icon-wrapper">
                            <img class="frozen-icon" src="../../assets/images/frozen-icon.png">
                        </div>
                        <ng-template #frozenTipContent>
                            <div class="frozen-text-wrapper">
                                <div class="frozen-title">{{ 'categories.storage.frozen' | transloco }}</div>
                                <!-- <div class="frozen-subtitle"> {{ restaurant.description }}</div> -->
                            </div>
                        </ng-template>
                        <div class="product-frozen" [placement]="'bottom'" [ngbTooltip]="frozenTipContent"
                            triggers="hover focus click:blur" closeDelay="1500" openDelay="300">
                            {{ 'categories.storage.frozen' | transloco }}
                        </div>
                    </div>

                    <div *ngFor="let category of product?.restrictionCategories"
                        class="product-vegan-container product-veg-container product-info">
                        <div class="veg-icon-wrapper">
                            <img *ngIf="category.slug == 'vegan'" class="veg-icon"
                                src="../../assets/images/vegan-icon.png" />
                            <img *ngIf="category.slug == 'vegetarian'" class="veg-icon"
                                src="../../assets/images/vegan.png" />
                        </div>
                        <div class="product-veg">{{ category?.title }}</div>
                    </div>

                </div>
                <!-- <div class="product-description d-none d-sm-block">
                {{ product.description }}
            </div> -->
                <!-- <div class="product-review restaurant-review product-reviews-summary-average-stars-container restaurant-info ">
                <svg *ngFor="let dummy of [].constructor(5)" class="restaurant-reviews-summary-average-star"
                    viewBox="0 0 24 22.83">
                    <path [attr.fill]="'black'"
                        d="M12,0l3.67,7.57L24,8.72l-6.06,5.83,1.48,8.28-7.42-4-7.42,4,1.48-8.28L0,8.72,8.33,7.57Z" />
                </svg>
            </div> -->
                <div class="product-brief">
                    {{ product.description }}
                </div>
                <div class="product-price-line">
                    <div class="product-price">
                        {{ product.price | price | translocoCurrency }}
                    </div>
                    <div class="product-units">
                        {{ 'market.servings.' + product.servings.unit.slug | transloco : { count: product.servings.count} }}
                    </div>
                </div>
                
                <div class="add-to-basket-button-container">
                    <div class="quantity-button button">
                        <button type="button" class="plus-minus-button" (click)="decrementQuantity()"
                            [disabled]="isLoading || !product.isAvailable">
                            <div class="quantity-button-icon-wrapper minus-button-icon-wrapper">
                                <img class="minus-icon" src="../../assets/images/icons/minus.svg">
                            </div>
                        </button>
                        <input type="number" class="num" [formControl]="quantity"
                            [disabled]="isLoading || !product.isAvailable" />
                        <button type="button" class="plus-minus-button" (click)="incrementQuantity()"
                            [disabled]="isLoading || !product.isAvailable">
                            <div class="quantity-button-icon-wrapper plus-button-icon-wrapper">
                                <img class="plus-icon" src="../../assets/images/icons/plus.svg">
                            </div>
                        </button>
                    </div>
                    <button type="button" class="add-to-basket-button button" (click)="onClickAddToBasket()"
                        [disabled]="isLoading || !product.isAvailable || isAdded"
                        [ngClass]="{ 'disable-add-to-basket-button': isLoading == true || !product.isAvailable, 'added-to-basket-button': isAdded == true }">
                        <div *ngIf="!isLoading && product.isAvailable && !isAdded">{{ 'market.addToCrate' | transloco }}
                        </div>
                        <div *ngIf="!isLoading && !product.isAvailable"> {{ 'market.unavailable' | transloco }} </div>
                        <div *ngIf="isAdded">Added!</div>
                        <div *ngIf="isLoading" class="spinner-border spinner-boder-sm" role="status">
                            <span class="sr-only"> </span>
                        </div>
                    </button>
                </div>
                <ngb-accordion class="market-product-accordion">
                    <ngb-panel title="{{'market.ingredients' | transloco }}"
                        *ngIf="product?.foodInfo && product?.foodInfo?.ingredientsDescription"
                        class="ingredients-panel">
                        <ng-template ngbPanelContent>
                            <div class="ingredients">
                                {{ product?.foodInfo?.ingredientsDescription[translate.getActiveLang()] }}
                            </div>
                        </ng-template>
                    </ngb-panel>
                    <ngb-panel title="{{'market.conservation' | transloco }}"
                        *ngIf="product?.foodInfo && product?.foodInfo?.shelfLife && (product?.foodInfo?.shelfLife?.limits?.lower || product?.foodInfo?.shelfLife?.limits?.upper)"
                        class="shelf-life-panel">
                        <ng-template ngbPanelContent>
                            <div class="shelf-life">
                                <span class="shelf-life-title">{{'market.shelfLife' | transloco }} </span>
                                <span *ngIf="product?.foodInfo?.shelfLife?.limits?.lower">{{
                                    product?.foodInfo?.shelfLife?.limits?.lower}} - </span><span>{{
                                    product?.foodInfo?.shelfLife?.limits?.upper }} {{ 'common.days' | transloco
                                    }}</span>
                            </div>
                        </ng-template>
                    </ngb-panel>
                    <ngb-panel title="{{'market.instructions' | transloco }}"
                        *ngIf="product?.foodInfo && product?.foodInfo?.preparation?.instructions && product?.foodInfo?.preparation?.instructions.length > 1"
                        class="instructions-panel">
                        <ng-template ngbPanelContent>
                            <div class="instruction"
                                *ngFor="let step of product.foodInfo?.preparation?.instructions; index as i">
                                {{i+1}}. {{step.text[translate.getActiveLang()]}}
                            </div>
                        </ng-template>
                    </ngb-panel>
                </ngb-accordion>
            </div>
        </div>
        <!-- TO-DO: Show related products with proper loading css -->
        <!-- <div class="related-products-container">
            <div *ngIf="resolvingRelatedProducts; else productsLoaded">
                <div class="loading-row-wrapper" *ngFor="let list of [].constructor(3)">
                    <div class="loading-list-header loading-gradient"></div>
                    <div class="loading-items-wrapper">
                        <div *ngFor="let product of [].constructor(15)" class="loading-item-wrapper">
                            <div class="loading-item-image loading-gradient"></div>
                            <div class="loading-item-header loading-gradient"></div>
                        </div>
                    </div>
                </div>
            </div>
            <ng-template #productsLoaded>
                <ng-container *ngFor="let product of relatedProducts; index as i">
                    <div class="related-products-row-wrapper">
                        <app-products-market-row [products]="relatedProducts"></app-products-market-row>
                    </div>
                </ng-container>
            </ng-template>
        </div> -->
    </div>
</div>
<app-footer [footerType]="'white'"></app-footer>