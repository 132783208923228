import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-expo-modal',
    templateUrl: './expo-modal.component.html',
    styleUrls: ['./expo-modal.component.css']
})
export class ExpoModalComponent implements OnInit {

    @Input('header') header: string;
    @Input('text') text: string;
    @Input('cancelButtonText') cancelButtonText: string = null;
    @Input('submitButtonText') submitButtonText: string;

    @Input('headerParams') headerParams: any = {};
    @Input('textParams') textParams: any = {};
    @Input('cancelButtonTextParams') cancelButtonTextParams: any = {};
    @Input('submitButtonTextParams') submitButtonTextParams: any = {};

    constructor(public activeModal: NgbActiveModal) { }

    ngOnInit(): void {}
}
