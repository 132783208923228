import { Address as GoogleAddress } from 'ngx-google-places-autocomplete/objects/address';
import { Address } from 'src/app/core/models';
export interface INewAddress {
    apt: string;
    city: string;
    country: string;
    line1: string;
    line2: string;
    loc: {
        type?: string;
        coordinates?: number[];
    };
    notes: string;
    postal: string;
    province: string;
    title: string;
}

export const ADDRESS_KEY = 'address_label';

export function parseGoogleAddress(googleAddress: GoogleAddress): Address {
    let address: Address = new Address();
    address.line1 = '';
    address.line2 = '';
    if (googleAddress == null) return;
    googleAddress.address_components.forEach((component) => {
        if (component.types.includes('street_number'))
            address.line1 = component.long_name + ' ' + address.line1;
        if (component.types.includes('route'))
            address.line1 = address.line1 + component.long_name;
        if (component.types.includes('locality'))
            address.city = component.long_name;
        if (component.types.includes('administrative_area_level_1'))
            address.province = component.short_name;
        if (component.types.includes('country'))
            address.country = component.short_name;
        if (component.types.includes('postal_code'))
            address.postal = component.long_name;
    });
    address.loc.coordinates = [
        googleAddress.geometry.location.lng(),
        googleAddress.geometry.location.lat(),
    ];
    return address;
}

export function generateNewAddressObject(address: Address): INewAddress {
    return {
        apt: address.apt,
        city: address.city,
        country: address.country,
        line1: address.line1,
        line2: address.line2 ? address.line2 : '',
        loc: address.loc,
        notes: address.notes,
        postal: address.postal,
        province: address.province,
        title: address?.title ? address.title as string : ''
    }
}

export function distanceBetween(address1: Address, address2: Address): number {
    let origin: google.maps.LatLng = new google.maps.LatLng(address1.loc.coordinates[1], address1.loc.coordinates[0]);
    let destination: google.maps.LatLng = new google.maps.LatLng(address2.loc.coordinates[1], address2.loc.coordinates[0]);
    return google.maps.geometry.spherical.computeDistanceBetween(origin, destination);
}

