import { Component, ElementRef, Inject, OnDestroy, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { RestaurantService } from '../../core/services/restaurant.service';
import { finalize, map, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Category, Restaurant } from '../../core/models';
import { MainService } from '../../core/services/main.service';
import moment from 'moment';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { isPlatformBrowser } from '@angular/common';
import confetti from 'canvas-confetti';
import { Weather } from 'src/app/core/models/weather.model';
import { WeatherService } from 'src/app/core/services/weather.service';

@UntilDestroy()
@Component({
    selector: 'app-restaurant-listing',
    templateUrl: './restaurant-listing.component.html',
    styleUrls: ['./restaurant-listing.component.css'],
})
export class RestaurantListingComponent implements OnInit, OnDestroy {
    @ViewChild('filters') filtersEl: ElementRef;

    scheduledDate$: Observable<Date>;
    orderType$: Observable<string>;
    //weather$: Observable<Weather>;

    scheduledDate: Date;
    orderType: string;

    restaurants: Restaurant[] = [];
    featuredSmall: any[];
    featuredRestaurantLarge;
    featuredMedium;
    navbarType = 'restaurants';
    showMore = false;

    areRestaurantsResolving: boolean;

    previousFilter: string
    filter: string = null;
    categorySlugs = [
        'all',
        'affordable',
        'local',
        'pizza',
        'sushi+poke',
        'classy',
        'vegan'
    ];

    isBrowser = true;
    screenWidth: number;
    isMobile: boolean;
    mobileThreshold: number = 850;
    confettiInterval;

    constructor(public translate: TranslocoService,
        private mainService: MainService,
        private restaurantService: RestaurantService,
        private route: ActivatedRoute,
        private weatherService: WeatherService,
        @Inject(PLATFORM_ID) platformId: Object,
    ) {
        this.isBrowser = isPlatformBrowser(platformId);
        this.screenWidth = window.innerWidth;
        this.isMobile = window.innerWidth < this.mobileThreshold;
    }

    ngOnDestroy(): void {
        clearInterval(this.confettiInterval)
    }

    ngOnInit() {
        if (this.isBrowser && !this.isMobile) {
            var duration = 20 * 1000;
            var animationEnd = Date.now() + duration;
            var skew = 1;

            function randomInRange(min, max) {
                return Math.random() * (max - min) + min;
            }

            var timeLeft = animationEnd - Date.now();
            var ticks = Math.max(200, 500 * (timeLeft / duration));
            skew = Math.max(0.8, skew - 0.001);


            // ids for different weather conditions
            let rainIds = ["1150", "1153", "1180", "1183", "1186", "1189", "1192", "1195", "1240", "1243", "1246", "1249", "1273", "1276"];
            let snowIds = ["1114", "1117", "1210", "1213", "1216", "1219", "1222", "1225", "1237", "1255", "1258", "1261", "1264", "1279", "1282"];
            let sleetIds = ["1072", "1168", "1171", "1198", "1201", "1204", "1207", "1252"];

            // Check for Cherry blossom season
            let currentDate = new Date();
            let currentYear = currentDate.getFullYear();
            let startDateCherries = new Date(currentYear, 2, 21);
            let endDateCherries = new Date(currentYear, 3, 15);

            this.weatherService.fetchWeather().subscribe(weather => {
                // Standardized confetti template
                let weatherConfetti = ({
                    particleCount: 3,
                    angle: 90,
                    startVelocity: 0,
                    ticks: ticks,
                    origin: {
                        x: Math.random(),
                        y: (Math.random() * skew) - 0.2
                    },
                    colors: ['#4f84db', '#abcbff', '#4c8ffc'],
                    shapes: ['circle'],
                    gravity: randomInRange(0.3, 0.5),
                    scalar: randomInRange(0.6, 1),
                    drift: randomInRange(-0.4, 1.3),
                    spread: 20,
                    disableForReducedMotion: true
                });

                if (rainIds.includes(weather.code)) {
                    this.confettiInterval = setInterval(() => {
                        confetti({
                            ...weatherConfetti,
                            origin: {
                                x: Math.random(),
                                // since particles fall down, skew start toward the top
                                y: 0
                            },
                            gravity: randomInRange(7, 7.5),
                            scalar: randomInRange(0.4, 0.7),
                            spread: 20
                        });
                    }, 20);
                }
                else if (snowIds.includes(weather.code)) {
                    this.confettiInterval = setInterval(() => {
                        confetti({
                            ...weatherConfetti,
                            particleCount: 1,
                            colors: ['#ebebeb', '#fff'],
                            origin: {
                                x: Math.random(),
                                // since particles fall down, skew start toward the top
                                y: 0
                            }
                        });
                    }, 45);
                }
                else if (sleetIds.includes(weather.code)) {
                    this.confettiInterval = setInterval(() => {
                        confetti({
                            ...weatherConfetti,
                            spread: 70,
                            colors: ['#ebebeb', '#4f84db'],
                            shapes: ['square', 'circle'],
                            gravity: randomInRange(3, 3.3),
                        });
                    }, 45);
                }
                else if (currentDate >= startDateCherries && currentDate <= endDateCherries) {
                    this.confettiInterval = setInterval(() => {
                        confetti({
                            ...weatherConfetti,
                            colors: ['#FFDCDD', '#FFA6A6', '#F25477'],
                            origin: {
                                x: Math.random(),
                                y: 0
                            }
                        });
                    }, 45);
                }
            });
        }

        this.route.params.pipe(
            untilDestroyed(this),
            tap(params => this.filter = params.slug ? decodeURI(params.slug) : 'all'),
            tap(params => {
                if (!this.isBrowser) return;
                let filtersEl = document.getElementById('filters');
                let selectedEl = document.getElementById(params.slug || 'all');
                if (selectedEl == null) return;
                let scrollTo = filtersEl.scrollLeft - selectedEl.offsetLeft + (filtersEl.offsetWidth / 2) - (selectedEl.offsetWidth / 2);

                filtersEl.scrollBy({
                    top: null,
                    left: -scrollTo,
                    behavior: 'smooth'
                });
            })
        ).subscribe(params => this.getRestaurants(this.categoriesFromSlugs(params?.slug)));

        this.scheduledDate$ = this.mainService.scheduledDateLoaded$;
        this.orderType$ = this.mainService.orderTypeInViewLoaded$;
        this.scheduledDate$.subscribe(scheduledDate => {
            this.scheduledDate = scheduledDate
            this.restaurants = this.sortRestaurants(this.restaurants);
        });
        this.orderType$.subscribe(orderType => {
            this.orderType = orderType
            this.restaurants = this.sortRestaurants(this.restaurants);
        });
        this.orderType = this.mainService.orderTypeInView;

        this.featuredRestaurantLarge = {
            name: {
                en: 'Click here to order yours now!',
                fr: 'Commander le vôtre dès maintenant !'
            },
            description: {
                en: 'The Valentine\'s Day 2024 Gift Box Collection is here! 💖',
                fr: 'Les coffrets cadeaux pour la Saint-Valentin sont arrivés ! 💖'
            },
            publisher: {
                en: 'From Radish',
                fr: 'Par Radish'
            },
            featured: 1,
            image: '../../assets/images/gift-box/4.jpg',
            url: 'https://radish.coop/cadeaux'
        };

        // this.featuredRestaurantLarge = {
        //     name: {
        //         en: '21.10.23 — Ping Pong Club, Mile End',
        //         fr: '2023.10.21 — Ping Pong Club, Mile End'
        //     },
        //     description: {
        //         en: 'Montreal heats up with the launch of first hot sauce expo',
        //         fr: 'Ça chauffe à Montréal avec le lancement de la première expo de sauces piquantes'
        //     },
        //     publisher: {
        //         en: 'From Radish',
        //         fr: 'Par Radish'
        //     },
        //     featured: 1,
        //     image: '../../assets/images/peppers.png',
        //     url: 'https://partiful.com/e/83gbMx0YscnJiEWhvDDV'
        // };

        // this.featuredRestaurantLarge = {
        //     name: {
        //         en: 'By Katrine Desautels',
        //         fr: 'Par Katrine Desautels'
        //     },
        //     description: {
        //         en: 'Restaurant delivery cooperative aims for carbon neutrality',
        //         fr: 'Une entreprise de livraison pour restaurants vise la carboneutralité'
        //     },
        //     publisher: {
        //         en: 'From Journal Métro',
        //         fr: 'Par Journal Métro'
        //     },
        //     featured: 1,
        //     image: '../../assets/images/velo-8.jpeg',
        //     url: 'https://journalmetro.com/local/ahuntsic-cartierville/2780471/une-entreprise-de-livraison-pour-restaurants-vise-la-carboneutralite/'
        // };

        // this.featuredRestaurantLarge =
        // {
        //     name: {
        //         en: "By Amie Watson",
        //         fr: "Par Amie Watson"
        //     },
        //     description: {
        //         en: "A Guide to Montreal’s Home-Grown Restaurant Delivery Service Alternatives",
        //         fr: "Un guide sur les alternatives de livraison montréalaises pour les restaurants"
        //     },
        //     publisher: {
        //         en: "From Eater Montreal",
        //         fr: "Par Eater Montréal"
        //     },
        //     featured: 1,
        //     image: '../../assets/images/eater-banner.jpg',
        //     slug: '',
        //     url: 'https://montreal.eater.com/2021/2/12/22280132/montreal-restaurant-home-grown-local-delivery-service-alternatives-not-ubereats-doordash'
        // };
        this.featuredSmall = [
            {
                title: {
                    en: 'Virtual Interview',
                    fr: 'Entrevue virtuelle'
                },
                name: {
                    en: 'Investing Local: Hype or Disruptor?',
                    fr: '« Investing Local: Hype or Disruptor? »'
                },
                description: {
                    en: 'Acclaimed journalist Diane Bérard interviews Radish CEO Mansib Rahman on financing cooperative startups.',
                    fr: 'Diane Bérard s\'entretient avec Mansib Rahman, DG de Radish, sur le financement des jeunes pousses coopératives.'
                },
                image: '../../assets/images/future-of-good-logo.jpeg',
                url: 'https://www.youtube.com/watch?v=se8ZR85TPDA',
                slug: '',
            },
            // {
            //     title: {
            //         en: "Virtual Panel",
            //         fr: "Panel en ligne"
            //     },
            //     name: {
            //         en: 'The Future of Digital Co-ops',
            //         fr: 'Le futur des coopératives numériques'
            //     },
            //     description: {
            //         en: 'Common Wealth Think Tank is hosting a live panel on the politics of digital cooperatives.',
            //         fr: "Common Wealth Think Tank organise un panel sur la politique des coopératives numériques."
            //     },
            //     image: '../../assets/images/panel-banner.jpeg',
            //     url: 'https://youtu.be/8ugDaXEMCG0',
            //     slug: '',
            // },
            {
                name: {
                    en: 'Arahova has exclusive offers for you!',
                    fr: 'Arahova vous propose des offres exclusives !'
                },
                title: {
                    en: "Special offers! ",
                    fr: 'Offres spéciales !'
                },
                description: {
                    en: "On the menu: three pitas, vegetarian, chicken or doner (beef & lamb), sole fillet and chicken Caesar.",
                    fr: "Au menu : des trios pitas, végétarien, poulet ou doner (boeuf & agneau), le filet de sole et la salade César au poulet."
                },
                type: {
                    en: '',
                    fr: ''
                },
                image: '../../assets/images/arahova/radish-arahova-feature.jpg',
                slug: 'arahova/menu',
                url: ''
            },
        ];
        this.featuredMedium =
        {
            title: {
                en: "A Guide to Montreal’s Home-Grown Restaurant Delivery Service Alternatives",
                fr: "Un guide sur les alternatives de livraison montréalaises pour les restaurants"
            },
            featured: 1,
            image: '../../assets/images/eater-banner.jpg',
            slug: '',
            url: 'https://montreal.eater.com/2021/2/12/22280132/montreal-restaurant-home-grown-local-delivery-service-alternatives-not-ubereats-doordash'
        };
    }

    getRestaurants(categories?: Category[]) {
        this.areRestaurantsResolving = true;
        this.restaurantService.listRestaurants(environment.MONTREAL_REGION_SLUG, categories)
            .pipe(finalize(() => this.areRestaurantsResolving = false))
            .pipe(map(restaurants => this.sortRestaurants(restaurants)))
            .subscribe(restaurants => this.restaurants = restaurants);

    }

    filterCategories(categories, types) {
        if (!categories) return [];
        else return categories.filter(category => types.includes(category.type));
    }

    onHeaderFeature(url) {
        window.open(url, "_blank");
    }

    isRestaurantOpen(restaurant: Restaurant): boolean {
        return restaurant.isOpenForMenuType(this.orderType, this.scheduledDate);
    }

    getNextOpenDate(restaurant: Restaurant, scheduledDate: Date): Date {
        scheduledDate = scheduledDate ?? new Date();
        return restaurant.getNextOpenDate(this.orderType, scheduledDate);
    }

    getNextOpenDateString(nextOpenDate: Date, scheduledDate: Date): string {
        scheduledDate = scheduledDate ?? new Date();
        let nextDay = moment(scheduledDate).add(1, 'day');
        return nextDay.isSame(nextOpenDate, 'day') ? 'restaurants.tomorrowOpenDate' : (nextDay.isBefore(nextOpenDate) ? 'restaurants.anotherOpenDate' : 'restaurants.nextOpenDate');
    }

    getAnotherOpenWeekDayString(nextOpenDate: Date): string {
        moment.locale(this.translate.getActiveLang())
        return moment(nextOpenDate).format('dddd');
    }

    trimString(text, length) {
        return text.length > length ?
            text.substring(0, length) + '...' :
            text;
    }

    sortRestaurants(restaurants: Restaurant[]): Restaurant[] {
        let closedRestaurants = restaurants.filter(restaurant => !restaurant.isOpenForMenuType(this.mainService.orderTypeInView, this.mainService.scheduledDate))
            .sort((first, second) => {
                let firstMenu = first.menus.filter(menu => menu.type == this.mainService.orderTypeInView)[0];
                let secondMenu = second.menus.filter(menu => menu.type == this.mainService.orderTypeInView)[0];
                let firstDate = firstMenu != null ? firstMenu.nextDateOpen(this.scheduledDate) : null;
                let secondDate = secondMenu != null ? secondMenu.nextDateOpen(this.scheduledDate) : null;
                return firstDate == null ? 1 : (secondDate == null ? -1 : firstDate.getTime() - secondDate.getTime());
            });
        let openRestaurants = restaurants.filter(restaurant => restaurant.isOpenForMenuType(this.mainService.orderTypeInView, this.mainService.scheduledDate));
        return openRestaurants.concat(closedRestaurants);
    }

    categoryRoute(slug?: string) {
        let route = '/' + this.translate.getActiveLang() + '/restaurants';
        if (slug != 'all') route += '/categories/' + slug;
        return route;
    }

    categoriesFromSlugs(slug?: string) {
        if (slug == 'all' || slug == null) return [];
        return slug.split('+').map(s => new Category(null, null, s));
    }
}
