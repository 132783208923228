<div class="error-modal-container">
    <div *ngIf="header" class="header">{{ header | transloco : headerParams}}</div>
    <div *ngIf="text" class="text" [innerHTML]="text | transloco : textParams"></div>
    <div class="modal-footer">
        <button *ngIf="cancelButtonText == null; else showTwoButtons" (click)="activeModal.close()"
            class="form-submit-button">{{ submitButtonText | transloco : submitButtonTextParams }}</button>
        <ng-template #showTwoButtons>
            <button type="button" (click)="activeModal.dismiss()" class="submit-button cancel-button">{{ cancelButtonText | transloco : cancelButtonTextParams }}</button>
            <button type="button" (click)="activeModal.close()" class="submit-button">{{ submitButtonText | transloco : submitButtonTextParams }}</button>
        </ng-template>
    </div>
</div>
