import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
    selector: 'app-address-confirmation',
    templateUrl: './address-confirmation.component.html',
    styleUrls: ['./address-confirmation.component.css']
})
export class AddressConfirmationComponent implements OnInit {

    @Input() address: any;
    @Input() test: boolean;

    @Output() onSaveAddressClick: EventEmitter<any> = new EventEmitter<{}>();
    @Output() onCancelClick: EventEmitter<any> = new EventEmitter<{}>();

    isEditAddressOpen: boolean = false;
    addressConfirmationForm: UntypedFormGroup;

    missingLine1Error: boolean;
    missingPostalCodeError: boolean;
    postalCodeLengthError: boolean;
    addressError: boolean;

    constructor(
        private formBuilder: UntypedFormBuilder
        ) {
            this.addressConfirmationForm = this.formBuilder.group({
                apt: [''],
                city: ['', Validators.required],
                country: [{value: '', disabled: true}],
                line1: ['', Validators.required],
                line2: [''],
                loc: [''],
                notes: [''],
                // postal: [''],
                postal: ['', [Validators.required, Validators.maxLength(7), Validators.minLength(6)]],
                province: [{value: '', disabled: true}],
                title: ['']
            })
        }

    ngOnInit(): void {
        this.setFormValues();
        this.handleAddressInvalid();
    }

    setFormValues(): void {
        this.addressConfirmationForm.controls.apt.setValue(this.address.apt);
        this.addressConfirmationForm.controls.city.setValue(this.address.city);
        this.addressConfirmationForm.controls.country.setValue(this.address.country);
        this.addressConfirmationForm.controls.line1.setValue(this.address.line1);
        this.addressConfirmationForm.controls.line2.setValue(this.address.line2);
        this.addressConfirmationForm.controls.loc.setValue(this.address.loc);
        this.addressConfirmationForm.controls.notes.setValue(this.address.notes);
        this.addressConfirmationForm.controls.postal.setValue(this.address.postal);
        this.addressConfirmationForm.controls.province.setValue(this.address.province);
        this.addressConfirmationForm.controls.title.setValue(this.address.title);
    }

    handleAddressInvalid(): void {
        if (this.addressConfirmationForm.status === "INVALID") {
            this.isEditAddressOpen = true; 
        }
    }

    onSaveAddress() {
        this.onSaveAddressClick.emit(this.addressConfirmationForm.value);
    }

    onCancel() {
        this.onCancelClick.emit();
    }

    getMapStyle() {
        return [
            {
                elementType: 'geometry',
                stylers: [{ color: '#f7f7f7' }]
            },
            {
                featureType: 'road',
                elementType: 'geometry',
                stylers: [{ color: '#ffffff' }]
            },
            {
                featureType: 'poi.business',
                stylers: [{ visibility: 'off' }]
            },
            {
                featureType: 'water',
                elementType: 'geometry',
                stylers: [{ color: '#51A3A3' }]
            }
        ]
    }

}
