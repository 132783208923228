<div class="parent-container">
    <div class="title">{{ product.title }}</div>
    <div class="items-container">
        <ng-container *ngFor="let item of bag.items">
            <div class="bag-item" *ngIf="item.product.id == product.id">
                <bag-item [item]="item" [showPrice]="false" [showNotes]="false" [displayType]="displayType" [showDecrementControl]="type == 'decrement'" [showIncrementControl]="type == 'increment'"></bag-item>
            </div>
        </ng-container>
    </div>
    <button type="button" class="add-new-button" *ngIf="type == 'increment'" (click)="newItemClick()">{{'itemWithOptions.addNew' | transloco}}</button>
</div>
