import { Pipe, PipeTransform } from '@angular/core';
import { Review } from '../models';

@Pipe({
	name: 'filterReviews',
})
export class FilterReviewsPipe implements PipeTransform {
	transform(reviews: Review[], filter: string): any {
		if (!reviews || !filter) return reviews;
		filter = filter.toLowerCase();
		return reviews.filter(
			(review) =>
				review.text.toLowerCase().indexOf(filter) !== -1 ||
				(review.title && review.title.toLowerCase().indexOf(filter) !== -1)
		);
	}
}
