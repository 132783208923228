import { Component, OnInit, ElementRef, Input, AfterViewInit, ViewChild, HostListener, Inject, PLATFORM_ID } from '@angular/core';
import { Observable, zip, merge, mergeMap } from 'rxjs';
import { BagService } from '../../core/services/bag.service';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { environment } from '../../../environments/environment';
import { TranslocoService } from '@ngneat/transloco';
import { OrderRequestData } from '../../core/services/order.service';
import { Router } from '@angular/router';
import { SupportComponent } from '../../modals/support/support.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddressModalComponent } from '../../modals/address-modal/address-modal.component';
import { filter, first, map } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';
import { User, Bag, Order, Restaurant, Address, GiftCard, Menu } from '../../core/models';
import { ErrorRedirectComponent } from '../../modals/error-redirect/error-redirect.component';
import { MainService } from '../../core/services/main.service';
import { ProductNoLongerAvailableComponent } from '../../modals/product-no-longer-available/product-no-longer-available.component';
import { ConfigService } from '../../core/services/config.service';
import { PromocodeComponent } from '../../modals/promocode/promocode.component';
import Dinero from 'dinero.js';
import { createMask } from '@ngneat/input-mask';
import { MapDirectionsService } from '@angular/google-maps';
import { OrderType, RestaurantSlug } from '../../core/enums';
import { DateFormatOptions, TranslocoLocaleService } from '@ngneat/transloco-locale';
import { GiftCardService } from '../../core/services/gift-card.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { RestaurantService } from '../../core/services/restaurant.service';

declare var Stripe;

@UntilDestroy()
@Component({
    selector: 'app-checkout',
    templateUrl: './checkout.component.html',
    styleUrls: ['./checkout.component.css'],
})
export class CheckoutComponent implements OnInit, AfterViewInit {

    RestaurantSlug = RestaurantSlug;

    @Input() amount: number;
    @Input() description: string;
    @ViewChild('cardElement') set ce(cardElement: ElementRef) {
        this.cardElement = cardElement;
    }

    directionsResults$: Observable<google.maps.DirectionsResult | undefined>;

    checkoutForm: UntypedFormGroup;
    bopaqForm: UntypedFormGroup;
    newGiftCardForm: UntypedFormGroup;
    SCHEDULED_TIME_KEY: string = 'scheduled_time';

    bag$: Observable<Bag>;
    order$: Observable<Order>;
    orderType$: Observable<OrderType>;
    restaurant$: Observable<Restaurant>;
    scheduledDate$: Observable<Date>;
    address$: Observable<Address>;
    menuOnBag$: Observable<Menu>;

    openNewCard = false;
    openNewGiftCard = false;

    cardElement: ElementRef;
    cardNumberElement: ElementRef;
    cardExpiryElement: ElementRef;
    cardCvcElement: ElementRef;
    cardPostalCodeElement: ElementRef;

    stripe;
    card;
    cardErrors;
    savedCards = [];
    isCardEmpty: boolean = true;

    giftCards: GiftCard[];
    selectedGiftCard: GiftCard;

    loading = false;
    isMapLoaded: boolean = false;
    attemptedSwitch = false;

    customTipValue: number = 0;

    tipRadio: string = '0';

    screenWidth: number;
    fixMap: boolean = false;
    isMobile: boolean;
    mobileThreshold: number = 850;

    bagLat: number;
    bagLng: number;
    restaurantLat: number;
    restaurantLng: number;
    markersSet: boolean = false;
    markerOptions;
    renderOptions;
    mapHeight;
    mapStyle: any;
    mapOptions: google.maps.MapOptions;
    restaurantIcon;
    houseIcon;

    currentDeliveryNote: string;
    deliveryNoteError: string;

    bagNoteError: string;
    bopaqIdError: string;

    addApartment: boolean = false;
    disableDecrement: boolean = false;

    longScheduledDateFormat: DateFormatOptions = { weekday: 'short', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric' };
    bopaqInputMask: any;

    isBrowser: boolean;

    //giftCodeInstances: GiftCodeInstance[] = [];
    giftCodeInstances: any;

    constructor(
        @Inject(PLATFORM_ID) platformId: Object,
        private bagService: BagService,
        private formBuilder: UntypedFormBuilder,
        public translate: TranslocoService,
        private router: Router,
        private modalService: NgbModal,
        private mainService: MainService,
        private giftCardService: GiftCardService,
        public configService: ConfigService,
        public mapDirectionsService: MapDirectionsService,
        private restaurantService: RestaurantService
    ) {
        // Define screen and browser constants
        this.isBrowser = isPlatformBrowser(platformId);
        this.screenWidth = window.innerWidth;
        this.isMobile = window.innerWidth < this.mobileThreshold

        // Create Google Maps assets
        this.mapHeight = this.screenWidth > this.mobileThreshold ? 99 : '14.9rem';
        this.setupMapAssets();

        // Create checkout form
        this.checkoutForm = this.formBuilder.group({
            deliveryInstructions: [''],
            noteToRestaurant: [''],
            tipRadio: [''],
            tipAmountCustom: [''],
            utensilsCheckbox: [false],
            noContactCheckbox: [true],
            cardRadio: [''],
            bagNote: [''],
            apartmentNumber: [''],
            giftCard: ['']
        });

        // Create Bopaq form
        this.bopaqForm = this.formBuilder.group({
            bopaqCheckbox: [false],
            bopaqId: ['', Validators.required]
        })

        // location.onPopState(() => this.modalService.dismissAll());
    }

    ngOnInit(): void {
        this.bag$ = this.mainService.bagLoaded$;

        this.order$ = this.mainService.orderLoaded$;
        this.orderType$ = this.mainService.orderTypeInViewLoaded$;
        this.restaurant$ = this.mainService.bagRestaurantLoaded$;
        this.scheduledDate$ = this.mainService.scheduledDateLoaded$;
        this.address$ = this.mainService.addressLoaded$;

        this.bag$.pipe(untilDestroyed(this)).subscribe(bag => {
            let order = this.mainService.order;

            if (bag == null || order == null) return;
            this.disableDecrement = bag.itemCount <= 1
            order.bag.items = bag.items;
            this.mainService.order = order;

            this.tipRadioChange();
        });

        this.giftCardService.listGiftCards().pipe(untilDestroyed(this)).subscribe(giftCards => {
            this.giftCards = giftCards;
            this.selectedGiftCard = this.giftCards[this.giftCards.length - 1];
            this.mainService.isProcessing$.pipe(filter(isProcessing => isProcessing != true), first()).subscribe(_ => this.initializeOrder(true)); //TODO: Can probably handled server side as well
        });
        if (!this.isBrowser) return;
        if (this.isBrowser) this.bopaqInputMask = createMask('999-999-9999');
    }

    ngAfterViewInit(): void {
        if (!this.isBrowser) return
        setTimeout(() => {
            this.stripe = Stripe(environment.STRIPE_KEY, { locale: this.translate.getActiveLang() });
            const elements = this.stripe.elements();

            this.card = elements.create('card');
            this.card.mount(this.cardElement.nativeElement);
            this.card.addEventListener('change', ({ error }) => this.cardErrors = error && error.message);
            this.card.addEventListener('focus', () => this.checkoutForm.controls.cardRadio.setValue('new-card'));
            this.card.on('change', (event) => this.isCardEmpty = event.empty);
        }, 75);
    }

    setupMapAssets() {
        this.restaurantIcon = {
            url: '../../assets/images/icons/restaurant.svg',
            scaledSize: {
                width: 60,
                height: 60
            }
        }
        this.houseIcon = {
            url: '../../assets/images/icons/house.svg',
            scaledSize: {
                width: 60,
                height: 60
            }
        }
        this.markerOptions = {
            origin: {
                icon: this.restaurantIcon
            },
            destination: {
                icon: this.houseIcon
            }
        };
        this.renderOptions = {
            polylineOptions: {
                strokeColor: '#FE2C7D',
            },
            suppressMarkers: true,
        };
        this.mapStyle = [
            {
                elementType: 'geometry',
                stylers: [{ color: '#f7f7f7' }]
            },
            {
                featureType: 'road',
                elementType: 'geometry',
                stylers: [{ color: '#ffffff' }]
            },
            {
                featureType: 'poi.business',
                stylers: [{ visibility: 'off' }]
            },
            {
                featureType: 'water',
                elementType: 'geometry',
                stylers: [{ color: '#51A3A3' }]
            }
        ]

        this.mapOptions = {
            gestureHandling: "none",
            disableDefaultUI: true,
            styles: this.mapStyle
        }

    }

    onMapLoaded(map) {
        this.isMapLoaded = true;
        setTimeout(() => this.mapHeight = this.screenWidth > this.mobileThreshold ? 100 : '15rem', 50)
    }

    disableButtons() {
        this.checkoutForm.controls.noContactCheckbox.disable();
        this.checkoutForm.controls.utensilsCheckbox.disable();
        this.checkoutForm.controls.deliveryInstructions.disable();
        this.checkoutForm.controls.noteToRestaurant.disable();
        this.checkoutForm.controls.tipRadio.disable();
        this.checkoutForm.controls.tipAmountCustom.disable();
    }

    enableButtons() {
        this.checkoutForm.controls.noContactCheckbox.enable();
        this.checkoutForm.controls.utensilsCheckbox.enable();
        this.checkoutForm.controls.deliveryInstructions.enable();
        this.checkoutForm.controls.noteToRestaurant.enable();
        this.checkoutForm.controls.tipRadio.enable();
        this.checkoutForm.controls.tipAmountCustom.enable();
    }

    //TODO: Tip logic could be nicer
    calculateTip(): Dinero.Dinero {
        let tipRate = +this.checkoutForm.controls.tipRadio.value;
        return tipRate ? this.mainService.bag.subtotal.percentage(tipRate) : Dinero({ amount: +((+this.checkoutForm.controls.tipAmountCustom.value * 100).toFixed(0)), currency: 'CAD' });
    }

    calculateTotal(): Dinero.Dinero {
        this.mainService.order.tip = Dinero({ amount: +(+this.checkoutForm.controls.tipAmountCustom.value * 100).toFixed(0), currency: 'CAD' });
        return this.mainService.order.tiplessTotal.add(this.mainService.order.tip);
    }

    tipRadioChange() {
        this.checkoutForm.controls.tipAmountCustom.setValue(
            (this.calculateTip().getAmount() / 100).toFixed(2)
        );
    }

    onCustomTipKeypress(event) {
        if (event.target.value.split('.').length > 1 && event.keyCode == 46) return false
        var charCode = (event.which) ? event.which : event.keyCode;
        if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57)) return false;
        return true;
    }

    onCustomTipChange(value: string) {
        this.customTipValue = +value;
        this.checkoutForm.controls.tipRadio.reset();
    }

    addNoteToBag(bagNote: string) {
        this.bagService.addNoteToBag(this.mainService.bag.id, bagNote).pipe(first())
            .subscribe({
                next: _ => this.submitOrder(),
                error: err => {
                    this.loading = false;
                    this.enableButtons();
                    this.bagNoteError = err.error.length > 0 ? this.bagNoteError = err.error[0].msg : this.bagNoteError = "UnknownError";
                }
            })
    }

    createBillingDetails(user: User, address: Address) {
        let billing_details: any = {
            name: user.name.first + ' ' + user.name.last,
            email: user.email,
            address: {
                city: address.city,
                country: address.country,
                line1: address.line1,
                line2: address.line2,
                postal_code: address.postal,
                state: address.province,
            },
        };
        if (user.number && user.number != '') billing_details.phone = user.number;
        return billing_details;
    }

    onSavedCardClick() {
        this.cardErrors = null;
    }

    initializeOrder(ignoreGiftCard = false) {
        this.mainService.initializeOrder(this.mainService.bag, this.generateOrderData(ignoreGiftCard)).pipe(untilDestroyed(this)).subscribe({
            next: (res) => {
                console.log(res);
                this.checkoutForm.controls.deliveryInstructions.setValue(res.order.bag.address.notes);
                this.savedCards = res.paymentMethods;
                this.savedCards.length > 0 ?
                    this.checkoutForm.controls.cardRadio.setValue(this.savedCards[0].id) :
                    this.checkoutForm.controls.cardRadio.setValue('new-card');
                if (this.checkoutForm.controls.tipRadio.value == '') {
                    this.checkoutForm.controls.tipRadio.setValue('15');
                    this.tipRadioChange();
                }
                if (this.isBrowser) this.setMarkers(res.order);
            },
            error: (err) => {
                this.displayErrorModal(err.error.error || err.error[0]?.msg);
            }
        })
    }


    createErrorModal(header: string, text: string, redirect: any[] = [], buttonText: string, textParams?: any) {
        const modalRef = this.modalService.open(ErrorRedirectComponent, {
            backdrop: 'static',
            keyboard: false,
            centered: true
        });
        modalRef.componentInstance.header = header;
        modalRef.componentInstance.text = text;
        if (textParams) modalRef.componentInstance.textParams = textParams;
        modalRef.componentInstance.submitButtonText = buttonText;
        return modalRef.result.then(
            _ => this.router.navigate(redirect),
            _ => window.location.reload()
        )
    }

    supportMessageError(isEmergency: boolean) {
        const modalRef = this.modalService.open(SupportComponent, {
            backdrop: 'static',
            keyboard: false,
            centered: true
        });
        modalRef.componentInstance.emergency = isEmergency;
        return modalRef.result.then(_ => { }, _ => window.location.reload());
    }

    productNotInMenuError() {
        this.loading = true;
        this.restaurantService.getRestaurantById(this.mainService.restaurantOnBag.id).pipe(first(), untilDestroyed(this)).subscribe(restaurant => {
            if (restaurant == null) return this.supportMessageError(false);
            let menu = restaurant.menus.filter(menu => menu.type === this.mainService.orderTypeInView)[0];
            if (menu == null) return this.supportMessageError(false);
            let missingItems = this.mainService.bag.getItemsMissingInMenu(menu);
            let modalRef = this.modalService.open(ProductNoLongerAvailableComponent, {
                backdrop: 'static',
                keyboard: false
            });
            modalRef.componentInstance.header = "productNotInMenuModal.header";
            modalRef.componentInstance.headerParams = { productCount: missingItems.length };
            modalRef.componentInstance.text = "productNotInMenuModal.message";
            modalRef.componentInstance.textParams = { productCount: missingItems.length };
            modalRef.componentInstance.cancelButtonText = "productNotInMenuModal.returnToMenu";
            modalRef.componentInstance.submitButtonText = "productNotInMenuModal.button";
            modalRef.componentInstance.items = missingItems;
            modalRef.result.then(
                _ => {
                    this.mainService.removeItems(this.mainService.bag, missingItems).pipe(untilDestroyed(this)).subscribe(_ => {
                        this.loading = false
                        this.initializeOrder();
                    });
                },
                _ => {
                    this.mainService.removeItems(this.mainService.bag, missingItems).pipe(untilDestroyed(this)).subscribe();
                    this.router.navigate(restaurant.slug ? [this.translate.getActiveLang(), 'restaurants', restaurant.slug, 'menu'] : ['/'])
                });
        });
    }

    productNotAvailableError() {
        zip(this.bag$, this.mainService.removeUnavailableAndArchivedItemsFromBag()).pipe(untilDestroyed(this)).subscribe(([bag, removedItems]) => {
            if (removedItems == null) return;
            let restaurantSlug = bag.restaurant?.slug;
            let modalRef = this.modalService.open(ProductNoLongerAvailableComponent, {
                backdrop: 'static',
                keyboard: false
            });
            modalRef.componentInstance.header = "productNoLongerAvailableModal.header";
            modalRef.componentInstance.headerParams = { productCount: removedItems.length };
            modalRef.componentInstance.text = "productNoLongerAvailableModal.message";
            modalRef.componentInstance.textParams = { productCount: removedItems.length };
            modalRef.componentInstance.cancelButtonText = "productNoLongerAvailableModal.returnToMenu";
            modalRef.componentInstance.submitButtonText = "productNoLongerAvailableModal.button";
            modalRef.componentInstance.items = removedItems;
            return modalRef.result.then(
                _ => this.initializeOrder(),
                _ => this.router.navigate(restaurantSlug ? [this.translate.getActiveLang(), 'restaurants', restaurantSlug, 'menu'] : ['/']));
        });
    }

    optionNotAvailableError() {
        zip(this.bag$, this.mainService.removeUnavailableAndArchivedOptionsFromBag()).pipe(untilDestroyed(this)).subscribe(([bag, removedItems]) => {
            if (removedItems == null) return;
            let restaurantSlug = bag.restaurant?.slug;
            let modalRef = this.modalService.open(ProductNoLongerAvailableComponent, {
                backdrop: 'static',
                keyboard: false
            });
            modalRef.componentInstance.header = "optionNoLongerAvailableModal.header";
            modalRef.componentInstance.headerParams = { productCount: removedItems.length };
            modalRef.componentInstance.text = "optionNoLongerAvailableModal.message";
            modalRef.componentInstance.textParams = { productCount: removedItems.length };
            modalRef.componentInstance.cancelButtonText = "optionNoLongerAvailableModal.returnToMenu";
            modalRef.componentInstance.submitButtonText = "optionNoLongerAvailableModal.button";
            modalRef.componentInstance.items = removedItems;
            modalRef.componentInstance.showProducts = false;
            return modalRef.result.then(
                _ => this.initializeOrder(),
                _ => this.router.navigate(restaurantSlug ? [this.translate.getActiveLang(), 'restaurants', restaurantSlug, 'menu'] : ['/']));
        })
    }

    checkAddress() {
        if (this.mainService.address) return;

        const modalRef = this.modalService.open(AddressModalComponent, {
            backdrop: 'static',
            keyboard: false,
        });

        modalRef.componentInstance.restaurant = this.mainService.restaurantOnBag;
        return merge(modalRef.closed, modalRef.dismissed).pipe(mergeMap(address => {
            if (address == null || !(address instanceof Address)) throw new Error(this.translate.translate('addressModal.error'));
            return this.mainService.updateAddress(address);
        }), untilDestroyed(this)).subscribe();
    }

    setMarkers(order) {
        this.bagLat = order.bag.address?.loc?.coordinates[1];
        this.bagLng = order.bag.address?.loc?.coordinates[0];



        this.restaurantLat = order.restaurant.address.loc.coordinates[1];
        this.restaurantLng = order.restaurant.address.loc.coordinates[0];

        this.markersSet = true;

        const request: google.maps.DirectionsRequest = {
            destination: { lat: this.bagLat, lng: this.bagLng },
            origin: { lat: this.restaurantLat, lng: this.restaurantLng },
            travelMode: google.maps.TravelMode.DRIVING
        };

        this.directionsResults$ = this.mapDirectionsService.route(request).pipe(map(response => response.result), untilDestroyed(this))
    }

    async handleForm(e) {
        e.preventDefault();

        this.disableButtons();
        this.loading = true;
        let bagNote = this.checkoutForm.controls.bagNote.value;
        let bopaqId = this.bopaqForm.controls.bopaqId.value;
        if (bopaqId) bagNote = `Code BOPAQ: ${bopaqId}\n` + bagNote;
        if (bagNote) this.addNoteToBag(bagNote);
        // else if (this.order.type === 'delivery')
        //     this.updateAddress();
        else this.submitOrder();
    }

    captureStripePayment(order, secret: string) {
        let card = this.checkoutForm.controls.cardRadio.value;

        let payment_method =
            card === 'new-card'
                ? {
                    card: this.card,
                    billing_details: this.createBillingDetails(order.user, order.bag.address)
                } : card;

        this.stripe
            .confirmCardPayment(secret, { payment_method, setup_future_usage: 'off_session' })
            .then(
                (res) => {
                    this.loading = false;
                    this.enableButtons();
                    if (res.error) this.cardErrors = res.error.message;
                    else {
                        if (res.paymentIntent.status === 'requires_capture' || res.paymentIntent.status === 'succeeded') {
                            localStorage.removeItem(this.SCHEDULED_TIME_KEY);
                            this.mainService.scheduledDate = null
                            this.router.navigate([this.translate.getActiveLang() + '/order']);
                        }
                    }
                },
                (err) => {
                    this.cardErrors = 'checkout.cardErrors'
                    this.loading = false;
                    this.enableButtons();
                }
            );
    }

    submitOrder() {
        this.mainService.initializeOrder(this.mainService.bag, this.generateOrderData()).pipe(untilDestroyed(this)).subscribe({
            next: res => { //TODO: should compare with payment method enum instead of string
                let order = res.order;
                if (order.paymentMethod == 'gift_card') {
                    this.mainService.confirmOrder(this.mainService.bag).pipe(untilDestroyed(this)).subscribe({
                        next: _ => {
                            this.router.navigate([this.translate.getActiveLang() + '/order']);
                        },
                        //TODO: handle errors
                        error: _ => { }
                    })
                }
                else {
                    this.captureStripePayment(res.order, res.secret)
                }
            },
            error: err => {
                this.displayErrorModal(err.error.error || err.error[0]?.msg);
                this.loading = false;
                this.enableButtons();
            }
        });
    }

    generateOrderData(ignoreGiftCard = false) {
        const data: OrderRequestData = {
            tip: this.checkoutForm.controls.tipAmountCustom.value ? this.checkoutForm.controls.tipAmountCustom.value : null,
            options: {
                noContact: this.checkoutForm.controls.noContactCheckbox.value,
                utensils: this.checkoutForm.controls.utensilsCheckbox.value
            }
        };
        if (data.tip) data.tip = Dinero({ amount: +((+data.tip * 100).toFixed(0)), currency: 'CAD' });
        if (!ignoreGiftCard && this.selectedGiftCard != null) data.giftCard = this.selectedGiftCard.id;

        return data;
    }

    getCardLogo(brand: string) {
        switch (brand) {
            case 'visa':
                return '../../assets/images/icons/visa.svg';
            case 'amex':
                return '../../assets/images/icons/american-express.svg';
            case 'mastercard':
                return '../../assets/images/icons/mastercard.svg';
        }
    }

    addApartmentClick() {
        this.addApartment = true;
        setTimeout(() => {
            document.getElementById('apartmentNumber').focus();
        }, 50)
    }

    onClickRedeemGiftCard() {
        // const modalRef = this.modalService.open(RedeemGiftCardComponent, {
        //     centered: true
        // });
        // modalRef.result.then(giftCodeInstance => {
        //     if (!giftCodeInstance) return
        //     this.giftCodeInstances.push(giftCodeInstance)
        // }, _ => { });
        localStorage.setItem('redirect_url_checkout', this.router.url);
        this.router.navigate([this.translate.getActiveLang() + '/gift-cards/redeem']);
    }

    onClickAddPromoCode() {
        const modalRef = this.modalService.open(PromocodeComponent, {
            centered: true
        });
        modalRef.componentInstance.restaurant = this.mainService.restaurantInView;
        modalRef.result.then(discountInstance => { }, _ => { });
    }

    onDeleteDiscount(discountId: string) {
        this.mainService.deleteDiscount(discountId, this.mainService.order)
            .pipe(first())
            .subscribe({
                next: _ => { },
                error: _ => {
                    this.createErrorModal('bag.promoCodeDeletionHeader', 'bag.promoCodeDeletionText', [''], 'bag.returnToHomePage');
                }
            });
    }

    // TODO: Provide same dollar formatting as frontend. Currently shows CA$.
    orderTooSmallError() {
        let modalHeader = 'bag.belowMinimumOrderHeader';
        let modalText = 'bag.belowMinimumOrderText';
        let slug = this.mainService.restaurantOnBag?.slug;
        let redirect = slug ? ['restaurants', slug, 'menu'] : ['/'];
        let buttonText = 'bag.returnToHomePage';
        let remaining = this.configService.MINIMUM_ORDER_AMOUNT.subtract(this.mainService.bag.subtotal).toFormat('$0.00');

        this.createErrorModal(modalHeader, modalText, redirect, buttonText, { remaining });
    }

    giftPaymentInvalidError() {
        const modalRef = this.modalService.open(ErrorRedirectComponent, {
            centered: true
        });
        modalRef.componentInstance.header = 'giftPaymentInvalidModal.header';
        modalRef.componentInstance.text = 'giftPaymentInvalidModal.text';
        modalRef.componentInstance.cancelButtonText = 'giftPaymentInvalidModal.cancel';
        modalRef.componentInstance.submitButtonText = 'giftPaymentInvalidModal.submit';
        return modalRef.result.then(
            _ => { },
            _ => this.selectedGiftCard = null
        );
    }

    bagRegenerationDiscrepancy() {
        const modalRef = this.modalService.open(ErrorRedirectComponent, {
            backdrop: 'static',
            keyboard: false,
            centered: true
        });
        modalRef.componentInstance.header = 'bag.bagRegenerationDiscrepancyHeader';
        modalRef.componentInstance.text = 'bag.bagRegenerationDiscrepancyText';
        modalRef.componentInstance.submitButtonText = 'bag.refreshThePage';
        modalRef.result.then(
            _ => window.location.reload(),
            _ => window.location.reload()
        )
    }

    scheduledBeforeCurrentTimeError() {
        let modalHeader: string = 'bag.scheduledBeforeCurrentTimeHeader';
        let modalText: string = 'bag.scheduledBeforeCurrentTimeText';
        let buttonText: string = 'bag.returnToHomePage';
        let slug = this.mainService.restaurantOnBag?.slug;
        let redirect: string[] = slug ? ['restaurants', slug] : ['/'];
        this.createErrorModal(modalHeader, modalText, redirect, buttonText);
    }

    scheduledAfterMaxDateError() {
        let modalHeader: string = 'bag.scheduledAfterMaxDateHeader';
        let modalText: string = 'bag.scheduledAfterMaxDateText';
        let buttonText: string = 'bag.returnToHomePage';
        let slug = this.mainService.restaurantOnBag?.slug;
        let redirect: string[] = slug ? ['restaurants', slug] : ['/'];
        this.createErrorModal(modalHeader, modalText, redirect, buttonText);
    }

    menuClosedError() {
        let restaurant = this.mainService.restaurantOnBag;
        if (restaurant == null) return this.supportMessageError(false);

        let modalHeader = 'bag.scheduledBeforeCurrentTimeHeader';
        let modalText: string = "", redirect = [], buttonText: string = "";
        let textParams = { restaurantName: this.mainService.bag?.restaurant.name, type: this.mainService.orderTypeInView };

        const newMenu = restaurant.menus.filter(menu => menu.isOpenAtDate(this.mainService.scheduledDate))[0];

        if (newMenu == null || this.attemptedSwitch == true) {
            return this.mainService.deleteBag().subscribe(() => {
                modalText = 'bag.closedForDelivery';
                redirect = ['restaurants'];
                buttonText = 'clearBagModal.buttons.seeMoreRestaurants';

                return this.createErrorModal(modalHeader, modalText, redirect, buttonText, textParams);
            });
        }

        return this.mainService.switchBagMenu(newMenu).pipe(untilDestroyed(this)).subscribe({
            next: _ => {
                this.attemptedSwitch = true;
                this.loading = false;
                this.initializeOrder();
            },
            error: _ => {
                modalText = 'bag.closedForDelivery';
                redirect = ['restaurants'];
                buttonText = 'clearBagModal.buttons.seeMoreRestaurants';
                this.createErrorModal(modalHeader, modalText, redirect, buttonText);
            }
        });
    }

    invalidGiftCardTypeError() {
        const modalRef = this.modalService.open(ErrorRedirectComponent, {
            centered: true
        });
        modalRef.componentInstance.header = 'modals.errors.InvalidGiftCardType.header';
        modalRef.componentInstance.text = 'modals.errors.InvalidGiftCardType.text';
        modalRef.componentInstance.textParams = { type: this.mainService.orderTypeInView };
        modalRef.componentInstance.submitButtonText = 'modals.errors.InvalidGiftCardType.buttons.primary';
        return modalRef.result.then(
            _ => this.selectedGiftCard = null,
            _ => this.selectedGiftCard = null
        );
    }

    giftCardNotCombinableError() {
        const modalRef = this.modalService.open(ErrorRedirectComponent, {
            centered: true
        });
        modalRef.componentInstance.header = 'modals.errors.DiscountsNotCombinableWithGiftCard.header';
        modalRef.componentInstance.text = 'modals.errors.DiscountsNotCombinableWithGiftCard.text';
        modalRef.componentInstance.textParams = { type: this.mainService.orderTypeInView };
        modalRef.componentInstance.submitButtonText = 'modals.errors.DiscountsNotCombinableWithGiftCard.buttons.primary';
        return modalRef.result.then(
            _ => { },
            _ => { }
        );
    }

    @HostListener('window:resize', ['$event'])
    onResize(_: any) {
        this.screenWidth = window.innerWidth;
        this.isMobile = window.innerWidth < this.mobileThreshold;
    }

    @HostListener('window:scroll', [])
    onWindowScroll() {
        this.fixMap = window.pageYOffset > 208;
    }

    getTooltipPlacement() {
        return this.isMobile ? 'top' : 'left';
    }

    onSelectGiftCard(id) {
        this.selectedGiftCard = this.giftCards.find(giftCard => giftCard.id == id);
    }

    onClickAdd(cardType) {
        if (cardType == 'giftCard') this.openNewGiftCard = true;
        else this.openNewCard = true;
    }

    onApplyNewGiftCard() {
        // TODO:
        // checks for validity, gets the price of gift card, send it to backend
        // set this.selectedGiftCard to new gift card
        // update this.checkoutForm.controls


        // if (this.isValid) {
        //     this.err = false;
        //     this.openNewGiftCard = false
        //     this.newGiftCardForm.reset()
        // } else {
        //     this.err = true;
        // }
    }

    displayErrorModal(errorMessage) {
        switch (errorMessage) {
            case 'CreditCardPaymentTooSmall':
                this.giftPaymentInvalidError();
                break;
            case 'ScheduledBeforeCurrentTime':
                this.scheduledBeforeCurrentTimeError();
                break;
            case 'ScheduledAfterMaxDate':
                this.scheduledAfterMaxDateError();
                break;
            case 'ProductNotAvailable':
                this.productNotAvailableError();
                break;
            case 'BagRegenerationDiscrepancy':
                this.bagRegenerationDiscrepancy();
                break;
            case 'OrderTooSmall':
                this.orderTooSmallError();
                break;
            case 'BagAddressNotFound':
                this.checkAddress();
                break;
            case 'InvalidBagId':
            case 'MissingBagId':
                this.router.navigate(['/']);
                break;
            case 'OptionNotAvailable':
                this.optionNotAvailableError();
                break;
            case 'OrderAlreadyPlaced':
                this.router.navigate([this.translate.getActiveLang() + '/order'])
                break;
            case 'RestaurantMenuClosed':
                this.menuClosedError();
                break;
            case 'ProductNotInMenu':
                this.productNotInMenuError();
                break;
            case 'InvalidGiftCardType':
                this.invalidGiftCardTypeError();
                break;
            case 'DiscountsNotCombinableWithGiftCard':
                this.giftCardNotCombinableError();
                break;
            default: this.supportMessageError(false)
        };
    }
}
