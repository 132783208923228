import { Component, OnInit, Input } from '@angular/core';
import { Order, Bag, Fee, Restaurant, DiscountInstance } from 'src/app/core/models';
import { TranslocoService } from '@ngneat/transloco';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import Dinero from 'dinero.js';

@Component({
    selector: 'app-order-summary',
    templateUrl: './order-summary.component.html',
    styleUrls: ['./order-summary.component.css']
})
export class OrderSummaryComponent implements OnInit {
    @Input('order') order: Order;
    bag: Bag

    subtotal = Dinero({ amount: 0, currency: 'CAD' });
    deliveryFee = Dinero({ amount: 0, currency: 'CAD' });
    deliveryTip: Dinero.Dinero;
    total: Dinero.Dinero;
    discountInstances: DiscountInstance[] = []

    constructor(
        public translate: TranslocoService,
		public activeModal: NgbActiveModal) { }

    ngOnInit(): void {
        this.bag = <Bag>this.order?.bag;
        let restaurant = <Restaurant>this.order?.restaurant
        this.deliveryFee = Dinero({ amount: 0, currency: 'CAD' });
        this.deliveryTip = this.order?.tip;
        this.discountInstances = this.order.discounts
        this.calculateSubtotal();
        this.calculateTotal();
    }

    calculateSubtotal() {
        this.bag.items.forEach(item => {
            this.subtotal = this.subtotal.add(item.price)
        })
    }

    calculateTotal() {
        this.total = Dinero({ amount: 0, currency: 'CAD' });
        this.total = this.total.add(this.subtotal);
        this.order.fees.forEach(fee => {
            this.total = this.total.add(fee.amount);
        });
        for (let discountInstance of this.discountInstances) {
            this.total = this.total.subtract(discountInstance.effects.total.value);
        }
        this.total = this.total.add(this.deliveryFee);
        this.total = this.total.add(this.deliveryTip);
    }
}
