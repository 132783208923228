import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ExpoModalComponent } from 'src/app/modals/expo-modal/expo-modal.component';
@Component({
    selector: 'expo-main',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.css']
})
export class ExpoMainComponent implements OnInit {

    constructor(
        private modalService: NgbModal
    ) { }

    ngOnInit(): void {
        if (localStorage.getItem('brandNewModal') == null) {

            const modalRef = this.modalService.open(ExpoModalComponent, { centered: true });
            modalRef.componentInstance.header = "expo.modal.mainMessage.header";
            modalRef.componentInstance.text = "expo.modal.mainMessage.text";
            modalRef.componentInstance.submitButtonText = "expo.modal.mainMessage.submit";

            modalRef.result.then(_ => this.modalViewed(), _ => this.modalViewed());
        }
    }

    modalViewed() {
        localStorage.setItem('brandNewModal', 'true');
    }
}