import { Restaurant } from 'src/app/core/models';

export enum RestaurantSlug {
    Maharani = 'maharani',
    Enchanteur = 'enchanteur',
    Lyla = 'lyla',
    Diolo = 'diolo',
    MerRouge = 'mer-rouge',
    Arahova = 'arahova',
    Delicieux = 'delicieux',
    Sham = 'sham',
    Nakamichi = 'nakamichi',
    PtitPlateau = 'ptit-plateau',
    Marquis = 'marquis',
    SushiPlusBeaumount = 'sushi-plus-beaumont',
    KojiSoupe = 'koji-soupe',
    LundisAuSoleil = 'lundis-au-soleil',
    Jc = 'juliette-chocolat',
    TincSet = 'tinc-set',
    FanfarePizza = 'fanfare-pizza',
    InochiExpress = 'inochi-express',
    Basha = 'basha',
    Croqueteria = 'croqueteria',
    Uluwatu = 'uluwatu',
    SaintHoublon = 'saint-houblon',
    ChezTauBay = 'tau-bay',
    Boustan = 'boustan-qds',
    ThaiExpressCdn = 'thai-express-cdn',
    JavaUCdn = 'java-u-cdn',
    McgillPizza = 'mcgill-pizza',
    Yuan = 'yuan',
    Segreta = 'segreta',
    OMLT = 'omlt',
    QuesadaCdn = 'quesada-cdn',
    OiseauxDePassage = 'oiseaux-de-passage',
    CafeVita = 'cafe-vita',
    BurgerBros = 'burger-bros',
    Julians = 'julians',
    Wallys = 'wallys',
    SpiceBros = 'spicebros-qds',
    SantaLucia = 'santa-lucia',
    Dakar = 'dakar',
    PandaBistro = 'panda-bistro',
    oFour = 'o-four',
    Karin = 'karin',
    MarcheMarema = 'marche-marema',
    ElephantThai = 'elephant-thai',
    Sushiyo = 'sushiyo',
    HeroBurgers = 'hero-burgers',
    BoiteVegane = 'boite-vegane',
    JrsCafe = 'jrs-cafe',
    CafeResonance = 'resonance',
    UbiSushi = 'ubi-sushi',
    Melrose = 'melrose',
    ObsceneVegan = 'obscene-vegan',
    LestersDeli = 'lesters-deli',
    Barranco = 'barranco',
    PetitSeoul = 'petit-seoul',
    Hooters = 'hooters',
    Darbar = 'darbar',
    JardinPetros = 'jardin-petros',
    ChezAnna = 'chez-anna',
    Krood = 'krood',
    HungarianCuisine = 'hungarian-cuisine',
    Maynard = 'maynard',
    MFourBurritos = 'm4-burritos-concordia',
    Bocadillo = 'bocadillo',
    LaurierBBQ = 'laurier-bbq',
    oFish= 'ofish',
    SushiSama = 'sushi-sama-concordia',
    PaniniStop = 'panini-stop',
    PokeStationCdn = 'poke-station-cdn',
    TeochewFoodie = 'teochew-foodie',
    Tapigo= 'tapi-go',
    BrigadePizza = 'brigade-pizza',
    AlAmine = 'al-amine',
    BernieBeigne = 'bernie-beigne',
    Cacao70 = 'cacao-70-parc',
    Andouille = 'andouille',
    Jaimee = 'jaimee',
    GPizzas = 'g-pizzas',
    PrettyUglyCompany = 'pretty-ugly-company',
    TacosNation = 'tacos-nation',
    PoissonnerieSherbrooke = 'poissonnerie-sherbrooke',
    Mintar = 'mintar',
    AuxMerveilleuxDeFred = 'aux-merveilleux-de-fred',
    LaToile = 'la-toile',
    SepLai = 'sep-lai',
    BistroDeLaCite = 'bistro-de-la-cite',
    Singhs = 'singhs',
    ChaiTeaLounge = 'chai-tea-lounge',
    LaCroissanterieFigaro = 'croissanterie-figaro',
    LaFabriqueDeBagel = 'la-fabrique-de-bagel',
    StudentTastyBiryani = 'student-tasty-biryani',
    OsukaSushi = 'osuka-sushi',
    ChezTaMere = 'chez-ta-mere',
    Lalis = 'lali',
    Chatpata = 'chatpata',
    FriteAlors = 'frite-alors-villeray',
    Tibum = 'tibum',
    ThePerle = 'the-perle',
    Gongcha = 'gong-cha-concordia',
    BuffaloBill = 'buffalo-bill-wings',
    SouvlakiGrecPlus = 'souvlaki-grec-plus',
    PizzaSport = 'pizza-sport',
    Tsujiri = 'tsujiri',
    SpartaChicken = 'sparta-chicken-concordia',
    P23Dumplings = 'p23-dumplings',
    BurgzCantine = 'burgz-cantine',
    Goplana = 'goplana',
    PiriPiri = 'piri-piri',
    UkiyaIzakaya = 'ukiya-izakaya',
    LivSalades = 'liv-salades',
    MaisonDuDestin = 'maison-du-destin',
    FlatBellyDeli = 'flat-belly-deli',
    DowntownDhaba = 'downtown-dhaba',
    Grillados = 'grillados'
}

// RestoPage TODO: Add slug in RestaurantSlug enum above

export type RestaurantType = RestaurantSlug.Maharani |
    RestaurantSlug.Enchanteur |
    RestaurantSlug.Lyla |
    RestaurantSlug.Diolo |
    RestaurantSlug.MerRouge |
    RestaurantSlug.Arahova |
    RestaurantSlug.Delicieux |
    RestaurantSlug.Sham |
    RestaurantSlug.Nakamichi |
    RestaurantSlug.PtitPlateau |
    // RestaurantSlug.Marquis |
    // RestaurantSlug.SushiPlusBeaumount |
    RestaurantSlug.KojiSoupe |
    RestaurantSlug.LundisAuSoleil |
    RestaurantSlug.Jc |
    RestaurantSlug.TincSet |
    RestaurantSlug.FanfarePizza |
    RestaurantSlug.InochiExpress |
    RestaurantSlug.Basha |
    RestaurantSlug.Croqueteria |
    RestaurantSlug.Uluwatu |
    RestaurantSlug.SaintHoublon |
    RestaurantSlug.ChezTauBay |
    RestaurantSlug.Boustan |
    RestaurantSlug.ThaiExpressCdn |
    RestaurantSlug.JavaUCdn |
    RestaurantSlug.McgillPizza |
    RestaurantSlug.Yuan |
    RestaurantSlug.Segreta |
    RestaurantSlug.OMLT |
    RestaurantSlug.QuesadaCdn |
    RestaurantSlug.OiseauxDePassage |
    RestaurantSlug.CafeVita |
    RestaurantSlug.BurgerBros |
    RestaurantSlug.Julians |
    RestaurantSlug.Wallys |
    RestaurantSlug.SpiceBros |
    RestaurantSlug.SantaLucia |
    RestaurantSlug.Dakar |
    RestaurantSlug.PandaBistro |
    RestaurantSlug.oFour |
    RestaurantSlug.Karin |
    RestaurantSlug.MarcheMarema |
    RestaurantSlug.ElephantThai |
    RestaurantSlug.Sushiyo |
    RestaurantSlug.BoiteVegane |
    RestaurantSlug.HeroBurgers |
    RestaurantSlug.JrsCafe |
    RestaurantSlug.CafeResonance |
    RestaurantSlug.UbiSushi |
    RestaurantSlug.Melrose |
    RestaurantSlug.ObsceneVegan |
    RestaurantSlug.LestersDeli |
    RestaurantSlug.Barranco |
    RestaurantSlug.PetitSeoul |
    RestaurantSlug.Hooters |
    RestaurantSlug.Darbar |
    RestaurantSlug.JardinPetros |
    RestaurantSlug.ChezAnna |
    RestaurantSlug.Krood |
    RestaurantSlug.HungarianCuisine |
    RestaurantSlug.Maynard |
    RestaurantSlug.MFourBurritos |
    RestaurantSlug.Bocadillo |
    RestaurantSlug.LaurierBBQ|
    RestaurantSlug.oFish |
    RestaurantSlug.SushiSama |
    RestaurantSlug.PaniniStop  |
    RestaurantSlug.PokeStationCdn |
    RestaurantSlug.TeochewFoodie |
    RestaurantSlug.Tapigo |
    RestaurantSlug.BrigadePizza |
    RestaurantSlug.AlAmine |
    RestaurantSlug.BernieBeigne |
    RestaurantSlug.Cacao70 |
    RestaurantSlug.TacosNation |
    RestaurantSlug.PoissonnerieSherbrooke |
    RestaurantSlug.Mintar |
    RestaurantSlug.AuxMerveilleuxDeFred |
    RestaurantSlug.LaToile |
    RestaurantSlug.SepLai |
    RestaurantSlug.BistroDeLaCite |
    RestaurantSlug.Singhs |
    RestaurantSlug.ChaiTeaLounge |
    RestaurantSlug.LaCroissanterieFigaro |
    RestaurantSlug.LaFabriqueDeBagel |
    RestaurantSlug.StudentTastyBiryani |
    RestaurantSlug.OsukaSushi |
    RestaurantSlug.ChezTaMere |
    RestaurantSlug.Lalis |
    RestaurantSlug.Chatpata |
    RestaurantSlug.FriteAlors |
    RestaurantSlug.Tibum |
    RestaurantSlug.ThePerle |
    RestaurantSlug.Gongcha |
    RestaurantSlug.BuffaloBill |
    RestaurantSlug.SouvlakiGrecPlus |
    RestaurantSlug.PizzaSport |
    RestaurantSlug.Tsujiri |
    RestaurantSlug.SpartaChicken |
    RestaurantSlug.P23Dumplings |
    RestaurantSlug.BurgzCantine |
    RestaurantSlug.Goplana |
    RestaurantSlug.PiriPiri |
    RestaurantSlug.UkiyaIzakaya |
    RestaurantSlug.LivSalades | 
    RestaurantSlug.MaisonDuDestin |
    RestaurantSlug.FlatBellyDeli | 
    RestaurantSlug.DowntownDhaba |
    RestaurantSlug.Grillados;

// RestoPage TODO: add slug to RestaurantType above