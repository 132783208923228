<div class="product-container">
    <button type="button" [routerLink]="['../']" class="back-button-container">
        <img class="back-button-icon" src="../../../../../assets/images/icons/back.svg">
    </button>
    <div *ngIf="product?.assets?.primary?.url" class="product-image-container product-header">
        <img class="product-image" [src]="product?.assets?.primary?.url">
    </div>
    <div class="product-body" [ngClass]="{'no-product-image':!product?.assets?.primary?.url}">
        <div class="product-body-information-container">
            <div class="product-body-information-container-top">
                <h1 class="product-title">
                    {{ product?.title }}
                </h1>
                <!-- UNCOMMENT below when done testing -->
                <!-- <div *ngIf="product?.categories.length > 0" class="product-categories">
                    <span class="product-category badge rounded-pill" *ngFor="let category of product?.categories">
                        <img class="product-category-icon" src="getCategoryIcon(category?.title)">
                        <span class="product-category-title">{{ category?.title }}</span> 
                    </span>
                </div> -->
                <!-- Remove after testing - START -->
                <!-- <div class="product-categories">
                    <span class="product-category badge rounded-pill" >
                        <img class="product-category-icon" src="../../../../../assets/images/icons/vegan.svg">
                        <span class="product-category-title">Vegan</span> 
                    </span>
                    <span class="product-category badge rounded-pill" >
                        <img class="product-category-icon" src="../../../../../assets/images/icons/gluten-free.svg">
                        <span class="product-category-title">Gluten-free</span> 
                    </span>
                </div> -->
                <!-- Remove after testing - END -->
                <p *ngIf="product?.description" class="product-description">
                    {{ product?.description }}
                </p>
                <div class="product-options">

                </div>
            </div>
        </div>
        <div class="product-body-add-to-bag-button-container">
            <div class="product-body-information-container-bottom">
                <div class="product-quantity-buttons-container">
                    <button type="button" class="quantity-button" (click)="decreaseQuantity()" [disabled]="isDisabled">
                        <img class="quantity-icon" src="../../assets/images/icons/minus-white.svg">
                    </button>
                    <div class="quantity-text">{{ quantity }}</div>
                    <button class="quantity-button" (click)="increaseQuantity()" [disabled]="isDisabled">
                        <img class="quantity-icon" src="../../assets/images/icons/plus-white.svg">
                    </button>
                </div>
                <div class="product-cost">
                    {{ getCost() | price | translocoCurrency }}
                </div>
            </div>
            <button class="add-to-bag-button" (click)="onAddToBag()" [disabled]="isDisabled">
                <ng-container *ngIf="!loading">
                    {{ 'expo.product.addToBag' | transloco }}
                </ng-container>
                <div *ngIf="loading" class="spinner-border spinner-border-sm text-light" role="status">
                    <span class="sr-only">{{ 'common.loading' | transloco }}</span>
                </div>
            </button>
            <!-- <div [ngbTooltip]="'productOptions.missingRequiredOptions' | transloco" [disableTooltip]="isFormValid()"
                tooltipClass="radish-tooltip">
                <button class="product-add-to-bag-button" (click)="onAddToBag()"
                    [disabled]="!isFormValid()">
                    <ng-container *ngIf="!loading">
                        {{ 'expo.product.addToBag' | transloco }}
                    </ng-container>
                    <div *ngIf="loading" class="spinner-border spinner-border-sm text-light" role="status">
                        <span class="sr-only">{{ 'common.loading' | transloco }}</span>
                    </div>
                </button>
            </div> -->
        </div>
    </div>
</div>