<div class="menu-item-with-image-container" [ngClass]="configService.getCssClass(restaurant.slug)">
    <div class="top-wrapper">
        <div *ngIf="product.title as title" class="title" [innerHTML]="title" 
        [ngStyle]="{'font-family': productStyle.name.font,
        'font-size': productStyle.name.fontSize,
        'font-weight': productStyle.name.fontWeight,
        'text-transform': productStyle.name.textTransform,
        'letter-spacing': productStyle.name.letterSpacing,
        'margin-bottom': productStyle.name.marginBottom,
        'margin-top': productStyle.name.marginTop,
        'order': productStyle.name.order}">
        </div>
        <!-- <div class="image-description-wrapper"> -->
        <div class="image-container" *ngIf="product.imageUrl as src"
            [ngStyle]="{'order': productStyle.image.order,
            'height':  productStyle.imageContainer.height}">
                <img class="image"[src]="src" [ngStyle]="{'width': productStyle.image.width,
                'height': productStyle.image.height,
                'object-fit': productStyle.image.objectFit,
                'margin-bottom': productStyle.image.marginBottom }">
        </div>
        <div *ngIf="product.description as description" class="description"
            [ngStyle]="{'font-family': productStyle.description.font,
            'order': productStyle.description.order,
            'margin-bottom': productStyle.description.marginBottom}">
            <app-read-more [content]="description" [limit]="isMobile ? 56 : 75"
                [completeWords]="true">
            </app-read-more>
        </div>
    </div>
    <!-- </div> -->
    <div class="price-buttons-wrapper">
        <div *ngIf="product.price as price" [ngStyle]="{'font-family': productStyle.price.font,
        'font-size': productStyle.price.fontSize,
        'font-weight': productStyle.price.fontWeight }">
            {{ price | price | translocoCurrency }}
        </div>
        <div class="control-buttons-wrapper">
            <app-menu-item-control-button [class.invisible]="!isItemInBag" class="restaurant-menu-item-button restaurant-menu-item-button-minus"
                [disabled]="disableModifyProductCount" (onButtonClicked)="removeItem(product)" [color]="buttonColor"
                [iconColor]="buttonIconColor" [disabledColor]="disabledButtonColor" [type]="MenuItemButtonType.Minus">
            </app-menu-item-control-button>
            <div [ngbTooltip]="tooltip" [disableTooltip]="!disableModifyProductCount" tooltipClass="radish-tooltip">
                <app-menu-item-control-button class="restaurant-menu-item-button" [disabled]="disableModifyProductCount"
                    (onButtonClicked)="addItem(product)" [color]="buttonColor" [iconColor]="buttonIconColor"
                    [disabledColor]="disabledButtonColor" [type]="MenuItemButtonType.Plus">
                </app-menu-item-control-button>
            </div>
        </div>
    </div>
</div>
