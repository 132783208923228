<div class="promotions-container">

    <div class="header">
        <div class="radish-logo-container">
            <img class="radish-logo" src="../../assets/images/radish-logos/Radish-Logo-BlackText-Large.png" />
        </div>
        <div class="title">{{ 'promotions.freeLemonade' | transloco }}</div>
        <div class="lemonade-container">
            <img class="lemonade" src="../../assets/images/radish-logos/lemonade.svg" />
        </div>
        <!--  -->
        <div *ngFor="let radish of [].constructor(31); index as i" class="header-radish-wrapper d-block d-md-none" id="{{ 'radish' + i }}">
            <img class="header-radish" src="../../assets/images/radish-logos/radish.svg" ngx-parallax property="transform"
            propertyValue="rotate"
            [speed]="generateRandomNum(50,70, true)"/>
        </div>
    <!--  -->
    </div>
    <div class="form-wrapper">
        <form [formGroup]="promotionForm" action="" class="signup-form" (ngSubmit)="onSubmit(promotionForm.value)">
            <div class="form-title">{{ 'promotions.name' | transloco }}</div>
            <input formControlName="name" id="name" type="text" class="form-input" />
            <div class="form-title">{{ 'promotions.email' | transloco }}</div>
            <input formControlName="email" id="email" type="text" class="form-input" />
            <div class="text">{{ 'promotions.text' | transloco }} </div>
            <button class="form-submit-button" [disabled]="!isFormValid()">
                <div>{{ 'promotions.getLemonade' | transloco }}</div>
                <div class="radish-wrapper">
                    <img class="radish" src="../../assets/images/radish-logos/radish.svg" />
                </div>
            </button>
        </form>
        <div class="button-wrapper">
            <button type="button" class="language-button" *ngIf="translate.getActiveLang() == 'en'" (click)="useLanguage('fr')"> FR </button>
            <button type="button" class="language-button" *ngIf="translate.getActiveLang() == 'fr'" (click)="useLanguage('en')"> EN </button>
        </div>
    </div>
</div>