import { Component, OnInit, Input } from '@angular/core';
import { Review } from 'src/app/core/models';
import { ConfigService } from 'src/app/core/services/config.service';

@Component({
    selector: 'app-rating',
    templateUrl: './rating.component.html',
    styleUrls: ['./rating.component.css']
})
export class RatingComponent implements OnInit {

    @Input() reviews: Review[]
    @Input() iconColor: string
    @Input() fontSize: string
    @Input() restaurantSlug: string

    averageRating: number
    numFullStars: number
    numHalfStars: number
    numEmptyStars: number

    constructor(public configService: ConfigService) { }

    ngOnInit(): void {
        this.calculateRating()
    }

    calculateRating() {
        let ratingSum = 0
        for (let review of this.reviews) {
            ratingSum += review.rating
        }
        let ratingAverage = ratingSum / this.reviews.length
        this.averageRating = Math.round(ratingAverage * 10) / 10
        this.numFullStars = Math.floor(this.averageRating)
        this.numHalfStars = this.averageRating % 1 != 0 ? 1 : 0
        this.numEmptyStars = 5 - this.numFullStars - this.numHalfStars
    }

}
