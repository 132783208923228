import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Bag, BagItem, Order } from 'src/app/core/models';
import { TranslocoService } from '@ngneat/transloco';
import { animate, style, transition, trigger } from '@angular/animations';
import { MainService } from 'src/app/core/services/main.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter, finalize, map, pipe, take } from 'rxjs';
import { OrderRequestData, OrderService } from 'src/app/core/services/order.service';
import { UntypedFormGroup } from '@angular/forms';

export enum OrderSummaryState {
	'Browsing',
	'Checkout',
	'Completed'
}

@UntilDestroy()
@Component({
	selector: 'app-gift-box-order-summary',
	templateUrl: './order-summary.component.html',
	styleUrls: ['./order-summary.component.css'],
	animations: [
		trigger('fadeInOut', [
			transition(':enter', [
				style({ transform: 'translateY(50px)', opacity: 0 }),
				animate(250, style({ transform: 'translateY(0)', opacity: 1 })),
			]),
			transition(':leave', [
				animate(250, style({ transform: 'translateY(50px)', opacity: 0 }))
			])
		])
	]
})

export class GiftBoxOrderSummaryComponent implements OnInit {

	OrderSummaryState = OrderSummaryState;

	virtualizedOrder: Order;
	@Input('order') order: Order;
	@Input('giftBox') giftBox: Bag;
	@Input('checkoutForm') checkoutForm: UntypedFormGroup;
	@Output('closeBottomSheet') closeBottomSheet = new EventEmitter<void>();

	isOverflowPillShowing: Boolean = false;

	pageState: OrderSummaryState = OrderSummaryState.Browsing;
	isLoading: Boolean = false;
	// Promo Modal
	promoCode: string;
	isPromoModalShowing: Boolean = false;
	promoLoading: boolean = false;
	discountErrorMessage: string = null;

	constructor(
		public translate: TranslocoService,
		private route: ActivatedRoute,
		private router: Router,
		private mainService: MainService,
	) {
		route.firstChild.url.pipe(untilDestroyed(this), map(urlSegments => urlSegments.map(urlSegment => urlSegment.path)))
			.subscribe(urlSegments => this.setOrderSummaryState(urlSegments)); //Handles initial load
		this.router.events.pipe(
			untilDestroyed(this),
			filter((event) => event instanceof NavigationEnd),
			map((event: NavigationEnd) => event.url.split('/'))
		).subscribe((urlSegments) => this.setOrderSummaryState(urlSegments)); //Handles subsequent loads

		//Could use input setter to do this but wth
		this.mainService.giftBoxLoaded$.pipe(untilDestroyed(this)).subscribe(giftBox => {
			if (!giftBox) return;
			if (giftBox.isEmpty && this.pageState != OrderSummaryState.Completed) this.router.navigate([translate.getActiveLang(), 'cadeaux']);
			if (!giftBox.isCompleted && this.pageState != OrderSummaryState.Completed) {
				let userStub = this.mainService.fetchUserStub() || null;
				let orderData: OrderRequestData = { isVirtualized: true };
				if (userStub) orderData.userStub = userStub;
				this.mainService.initializeOrder(giftBox, orderData).subscribe({
					next: (res) => {
						this.virtualizedOrder = res.order;
					},
					error: (error) => {
						console.log(error)
						//TODO: Handle error
					}
				})
			}
		})
	}

	ngOnInit(): void {
	}

	setOrderSummaryState(urlSegments) {
		if (urlSegments.includes('checkout')) this.pageState = OrderSummaryState.Checkout;
		else if (urlSegments.includes('orders')) this.pageState = OrderSummaryState.Completed;
		else this.pageState = OrderSummaryState.Browsing;
	}

	onScroll(event) {
		let epsilon = 1;
		this.isOverflowPillShowing = !(event.target.offsetHeight + event.target.scrollTop + epsilon >= event.target.scrollHeight)
	}

	scrollDown() {
		let element;
		// if (window.innerWidth >= 992) element = document.getElementById('orderSummary');
		element = document.getElementById('orderSummaryMobile');
		element.scrollBy(0, 150)
	}

	openPromoModal() {
		this.isPromoModalShowing = true;
	}

	closePromoModal() {
		this.isPromoModalShowing = false;
	}

	onSubmitPromoCode() {
		this.promoLoading = true;
		this.mainService.applyDiscount(this.promoCode.trim(), this.order || this.virtualizedOrder)
			.pipe(take(1), finalize(() => this.promoLoading = false))
			.subscribe({
				next: discountInstance => {
					this.isPromoModalShowing = false;
				},
				error: err => {
					if (!err?.error?.error) {
						return
					}
					switch (err?.error?.error) {
						case 'DiscountNotFound':
						case 'DiscountCodeNotFound':
							this.discountErrorMessage = 'promoCodeModal.error.notFound'
							break;
						case 'DiscountCodeExhausted':
							this.discountErrorMessage = 'promoCodeModal.error.exhausted'
							break;
						case 'DiscountAlreadyApplied':
							this.discountErrorMessage = 'promoCodeModal.error.alreadyApplied'
							break;
						case 'InvalidDiscountOrderType':
							this.discountErrorMessage = 'promoCodeModal.error.invalidForOrderType'
							break;
						case 'InvalidDiscountRestaurant':
							this.discountErrorMessage = 'promoCodeModal.error.invalidRestaurant'
							break;
						case 'OrderTooSmallForDiscount':
							this.discountErrorMessage = 'promoCodeModal.error.orderTooSmall'
							break;
						case 'DiscountsNotCombinable':
							this.discountErrorMessage = 'promoCodeModal.error.notCombinable'
							break;
						case 'DiscountsNotCombinableWithGiftCard':
							this.discountErrorMessage = 'promoCodeModal.error.notCombinableGiftCard'
							break;
						case 'TooManyDiscountUses':
							this.discountErrorMessage = 'promoCodeModal.error.tooManyUses'
							break;
						case 'DiscountTooLarge':
							this.discountErrorMessage = 'promoCodeModal.error.tooLarge'
							break;
						case 'TooManyOrdersForDiscount':
							this.discountErrorMessage = 'promoCodeModal.error.tooManyOrders'
							break;
						case 'TooManyOrdersWithRestaurantForDiscount':
							this.discountErrorMessage = 'promoCodeModal.error.tooManyOrdersWithRestaurant'
							break;
						case 'MissingDiscount':
						case 'MissingUser':
						case 'MissingId':
						case 'MissingDiscountInstanceId':
						default:
							this.discountErrorMessage = 'promoCodeModal.error.generic'
					}
				}
			});
	}

	incrementItem(item) {
		this.isLoading = true;
		this.mainService.incrementItem(this.mainService.giftBox, item).subscribe(_ => this.isLoading = false);
	}

	decrementItem(item) {
		this.isLoading = true;
		this.mainService.decrementItem(this.mainService.giftBox, item).subscribe(_ => this.isLoading = false);
	}

	isIncrementDisabled() {
		return this.isLoading;
	}

	isDecrementDisabled(item: BagItem) {
		return this.isLoading; //|| (item.quantity <= 1 && this.disableDecrement);
	}

	checkout() {
		if (this.pageState == OrderSummaryState.Browsing) this.router.navigate([this.translate.getActiveLang(), 'cadeaux', 'checkout']);
		this.closeBottomSheet.emit();
	}

	// get isOverflow() { // for 
	// 	let element;
	// 	// if (window.innerWidth >= 992) element = document.getElementById('orderSummary');
	// 	element = document.getElementById('orderSummaryMobile');
	// 	return element.offsetHeight < element.scrollHeight;
	// }
}

