import { Component, OnInit } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ListContent, Product, Restaurant } from '../core/models';
import { ContentService } from '../core/services/content.service';
import { TranslocoService } from '@ngneat/transloco';
import { finalize } from 'rxjs';
import Dinero from 'dinero.js';

@Component({
	selector: 'app-main',
	templateUrl: './main.component.html',
	styleUrls: ['./main.component.css']
})

@UntilDestroy()
export class MainComponent implements OnInit {

	isMobile: boolean = false;
	featuredList: ListContent;
	resolvingList: boolean = true;

	testingProduct = new Product().deserialize({id: '1', restaurant: new Restaurant(), price: Dinero({ amount: 100, currency: 'CAD' })})
	testing;

	constructor(
		private contentService: ContentService,
		public translate: TranslocoService) 
	{ }

	ngOnInit(): void {
		this.isMobile = window.innerWidth <= 991;
		window.onresize = () => this.isMobile = window.innerWidth <= 768;

		this.contentService.fetchList('featured').pipe(finalize(() => this.resolvingList = false)).subscribe(list => this.featuredList = list);
		this.testing = [{title: 'testing', items: [this.testingProduct]}]
	}
}
