<div class="add-to-crate-button-container">
    <button [@addToCrate]="crateButtonState" class="add-to-crate-button" (click)="addToCrate($event)"
        [ngClass]="[crateButtonState != CrateButtonState.NotAdded ? 'button-hidden' : 'button-visible', !product.isAvailable ? 'product-unavailable' : '']" 
        [disabled]="!product.isAvailable">
        <img class="add-to-crate-plus-icon" src="../../assets/images/icons/plus-white.svg">
    </button>
    <div class="add-to-crate-button add-to-crate-default-button" [@addingToCrate]="crateButtonState"
        (@addingToCrate.done)="addedToCrate($event)"
        [ngClass]="crateButtonState != CrateButtonState.Adding ? 'button-hidden' : 'button-visible'">
        <div class="add-to-crate-message">{{ 'market.addedToCrate' | transloco }}</div>
    </div>
    <div class="add-to-crate-button quantity-button-wrapper" [@addedToCrate]="crateButtonState"
        (@addedToCrate.done)="removedFromCrate($event)"
        [ngClass]="(crateButtonState != CrateButtonState.Added && crateButtonState != CrateButtonState.Removing) ? 'button-hidden' : 'button-visible'">
        <div class="add-to-crate-quantity-button-container" [ngClass]="{'hide-quantity-button' : crateButtonState == CrateButtonState.Removing }">
            <button type="button" class="add-to-crate-quantity-icon-wrapper add-to-crate-minus-icon-wrapper"
                (click)="decrementQuantity()" [disabled]="isLoading">
                <img class="minus-icon" src="../../assets/images/icons/minus-white.svg">
            </button>
            <input type="number" class="add-to-crate-quantity-input" [formControl]="quantity" disabled="disabled"
                [readonly]="true" />

            <!-- <input *ngIf="!isLoading" type="number" class="add-to-crate-quantity-input" [formControl]="quantity" disabled="disabled" /> -->
            <!-- <div *ngIf="isLoading" class="spinner-border spinner-boder-sm" role="status">
            <span class="sr-only"> </span>
        </div>  -->
            <button type="button" class="add-to-crate-quantity-icon-wrapper add-to-crate-plus-icon-wrapper"
                (click)="incrementQuantity()" [disabled]="isLoading || !product.isAvailable">
                <img class="plus-icon" src="../../assets/images/icons/plus-white.svg">
            </button>
        </div>
    </div>
</div>