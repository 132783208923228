import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { BaseService } from './base.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { SocialAuthService } from '@abacritt/angularx-social-login';
import { CookieService } from 'ngx-cookie-service';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import { isPlatformServer } from '@angular/common';
import { SignupService } from './signup.service';
import { KEY_CRATE } from './crate.service';
import { environment } from 'src/environments/environment';
@Injectable({
	providedIn: 'root',
})
export class AuthService {
	private TOKEN_KEY: string = 'id_token';
	private REFRESH_TOKEN_KEY: string = 'id_refresh_token';
	private FACEBOOK_AUTH_TOKEN_KEY = 'id_facebook_auth_token';
	private GOOGLE_AUTH_TOKEN_KEY = 'id_google_auth_token';

	get token_key() {
		return this.TOKEN_KEY;
	}

	get refresh_token_key() {
		return this.REFRESH_TOKEN_KEY;
	}

	get facebook_token_key() {
		return this.FACEBOOK_AUTH_TOKEN_KEY;
	}

	get google_token_key() {
		return this.GOOGLE_AUTH_TOKEN_KEY;
	}

	constructor(private baseService: BaseService,
		private socialAuthService: SocialAuthService,
		private cookieService: CookieService,
		private ssrCookieService: SsrCookieService,
		private signupService: SignupService,
		@Inject(PLATFORM_ID) private platformId: any) { }

	login(data): Observable<any> {
		return this.baseService.create('auth/login', data).pipe(
			tap((res) => {
				this.setTokens(res.token, res.refreshToken);
			})
		);
	}

	forgotPassword(email: string): Observable<Response> {
		return this.baseService.create('auth/password', email);
	}

	resetPassword(password: string, token: string): Observable<Response> {
		return this.baseService.create('auth/password/reset/' + token, {
			password: password,
		});
	}

	checkResetToken(token: string): Observable<Response> {
		return this.baseService.fetch('auth/password/reset', token);
	}

	sendEmailVerification(email: string, returnUrl: string): Observable<any> {
		return this.baseService.create('auth/verification/email', {
			email: email,
			returnUrl: returnUrl,
		});
	}

	verifyEmail(token: string): Observable<any> {
		return this.baseService.request('auth/verification/email/' + token);
	}

	sendPhoneVerification(number: string): Observable<any> {
		return this.baseService.create('auth/verification/number', { number })
	}

	verifyPhone(number: string, code: string): Observable<any> {
		return this.baseService.create('auth/verification/number/code', { number, code })
	}

	getJwtCookie() {
		return this.universalCookieService.get(this.token_key);
	}

	getJwtToken() {
		if (isPlatformServer(this.platformId)) return this.universalCookieService.get(this.token_key);
		else return localStorage.getItem(this.token_key);
	}

	private getRefreshToken() {
		if (isPlatformServer(this.platformId)) return this.universalCookieService.get(this.refresh_token_key);
		else return localStorage.getItem(this.refresh_token_key);
	}

	//TODO: SSR likely not setting local storage. verify
	private updateJWTToken(jwt: string) {
		localStorage.setItem(this.token_key, jwt);
		this.universalCookieService.set(this.token_key, jwt, new Date(2099, 0), '/', null, true);
		// this.cookieService.put(this.token_key, jwt, { path: '/', expires: new Date(2099, 0), secure: true });
	}

	setTokens(token: string, refreshToken: string): void {
		localStorage.setItem(this.token_key, token);
		localStorage.setItem(this.refresh_token_key, refreshToken);
		this.generateAuthCookies();
	}

	//TODO: Prevent from being double called in race condition between Authenticator and socket
	refreshToken(force: boolean = false): Observable<any> {
		const jwtHelperService = new JwtHelperService();
		if (!force && !jwtHelperService.isTokenExpired(this.getJwtToken())) return of ({})
		return this.baseService
			.submit('auth/token', { refreshToken: this.getRefreshToken() })
			.pipe(tap((token) => this.updateJWTToken(token.token)));
	}

	generateAuthCookies(): void {
		this.universalCookieService.set(this.token_key, localStorage.getItem(this.token_key), new Date(2099, 0), '/', null, true);
		this.universalCookieService.set(this.refresh_token_key, localStorage.getItem(this.refresh_token_key), new Date(2099, 0), '/', null, true);
		// this.cookieService.put(this.token_key, localStorage.getItem(this.token_key), { path: '/', expires: new Date(2099, 0), secure: true });
		// this.cookieService.put(this.refresh_token_key, localStorage.getItem(this.refresh_token_key), { path: '/', expires: new Date(2099, 0), secure: true });
	}

	loggedIn() {
		if ((localStorage.getItem(this.token_key) === null || 
		localStorage.getItem(this.token_key) === '' || 
		!localStorage.getItem(this.token_key)) &&
		!this.universalCookieService.check(this.token_key)) {
			return false;
		} else return true;
	}

	logout() {
		localStorage.removeItem(this.token_key);
		localStorage.removeItem(this.refresh_token_key);

		let facebook_token = localStorage.getItem(this.facebook_token_key);
		let google_token = localStorage.getItem(this.google_token_key);

		if (facebook_token || google_token) this.socialAuthService.signOut();
		localStorage.removeItem(this.facebook_token_key);
		localStorage.removeItem(this.google_token_key);

		localStorage.removeItem('KEY_BAG');
		localStorage.removeItem(KEY_CRATE);
		this.universalCookieService.deleteAll('/', null, true);
	}

	socialLogin(token: string, provider: string) {
		if (provider == 'facebook') localStorage.setItem(this.facebook_token_key, token);
		else if (provider == 'google') localStorage.setItem(this.google_token_key, token);
		return this.baseService.request('auth/' + provider + '/token').pipe(
			tap((res) => {
				this.setTokens(res.token, res.refreshToken);
			})
		);
	}

	authenticateGoogleIDToken(token: string) {
		localStorage.setItem(this.google_token_key, token);
		return this.baseService.request('auth/google/id_token').pipe(tap((res) => this.setTokens(res.token, res.refreshToken)));
	}

	getFacebookAuthToken() {
		return localStorage.getItem(this.facebook_token_key);
	}

	getGoogleAuthToken() {
		return localStorage.getItem(this.google_token_key);
	}

	register(data) {
		let signupAuth = this.signupService.createSignupAuth(data.firstName, data.lastName, data.password, data.email, data.receiveEmails, data.number);
		return this.signupService.signup(signupAuth).pipe(tap((res) => this.setTokens(res.token, res.refreshToken)));
	}

	private get universalCookieService(): any {
		return isPlatformServer(this.platformId) ? this.ssrCookieService : this.cookieService;
	}
}
