import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { Promotion } from '../models/promotion.model';
import { BaseService } from './base.service';

@Injectable({
	providedIn: 'root'
})

export class PromotionService {
	private entity: string = 'promotions';

	constructor(private baseService: BaseService) { }

	fetchPromotion(id: string): Observable<Promotion> {
		let query: any = {};
		return this.baseService.fetch(this.entity, id, query).pipe(map(res => new Promotion().deserialize(res.promotion)));
	}

}