<div class="faq-modal-container">
    <div class="faq-modal-header">
        <div class="faq-modal-title">{{ 'faq.header' | transloco }}</div>
        <img class="faq-modal-close" src="../../../assets/images/close-icon.png" alt="" (click)="activeModal.close()" />
    </div>
    <div class="faq-modal-body">
        <ng-container *ngFor="let section of faq.faq">
            <div class="faq-modal-accordion-title">{{section.title}}</div>
            <ngb-accordion class="faq-modal-accordion" [closeOthers]="true" #faqAccordions (panelChange)="beforePanelChange($event)">
                <ngb-panel [title]="item.question" [cardClass]="section.title" *ngFor="let item of section.questions">
                    <ng-template ngbPanelContent>
                        <p class="accordion-body-text" [innerHTML]="item.text"></p>
                    </ng-template>
                </ngb-panel>
            </ngb-accordion>
        </ng-container>
    </div>
    <div class="faq-modal-footer">{{ 'faq.footer.title' | transloco }} <span><a class="faq-modal-link" href="mailto:support@radish.coop">{{ 'faq.footer.button' | transloco }}</a></span></div>
</div>
