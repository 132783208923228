import { Injectable } from '@angular/core';
import {
	Router,
	CanActivate,
	ActivatedRouteSnapshot,
	RouterStateSnapshot,
} from '@angular/router';
import { AuthService } from '../services/auth.service';
import { TranslocoService } from '@ngneat/transloco';

@Injectable({
	providedIn: 'root',
})
export class LoggedInGuard implements CanActivate {
	constructor(private authService: AuthService, private translate: TranslocoService, private router: Router) { }

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): boolean {
		if (this.authService.loggedIn()) {
			this.router.navigate(['/' + this.translate.getActiveLang()]);
			return false;
		} else {
			return true;
		}
	}
}
