import { Component } from '@angular/core';
import { GoogleMapsModule } from '@angular/google-maps';

@Component({
  selector: 'app-date-time-picker',
  templateUrl: './date-time-picker.component.html',
  styleUrls: ['./date-time-picker.component.css']
})
export class DateTimePickerComponent {
  date: Boolean = true;
	time: Boolean = false;

	setDate() {
		this.date = true;
		this.time = false;
	}

	setTime() {
		this.date = false;
		this.time = true;
	}
}
