<div [@openClose]="open ? 'open' : 'closed'" class="restaurant-item-options-container">
    <div *ngIf="open" [formGroup]="optionsForm" class="restaurant-items-options-wrapper">
        <div class="restaurant-item-options-top-wrapper">
            <img (click)="closeOptionsMenu()" class="restaurant-item-options-close-icon"
                src="../../../assets/images/close-icon.png" alt="x icon" />
            <div class="restaurant-item-options-header-wrapper">
                <h2 class="restaurant-item-options-header" [innerHTML]="item.title"></h2>
                <div *ngIf="item.description" class="restaurant-item-options-description"
                    [innerHTML]="item.description">
                </div>
            </div>
            <div *ngIf="item.options.length > 0" class="restaurant-items-options-product-options-wrapper">
                <div *ngFor="let option of item.options" class="product-option"
                    [formGroupName]="'checkbox-group-' + option.id">
                    <div class="product-option-header">
                        <div class="product-option-title-wrapper">
                            <div class="product-option-title" [innerHTML]="option.title"></div>
                            <ng-container *ngIf="option.limits.type == 'required'">
                                <div class="product-option-quantity">
                                    {{ 'productOptions.pick' | transloco:{quantity: getNumber(option.limits.quantity)}
                                    }}
                                </div>
                            </ng-container>
                            <ng-container *ngIf="option.limits.type == 'maximal'">
                                <div [ngPlural]="option.limits.quantity" class="product-option-quantity">
                                    <ng-template ngPluralCase="=1">{{ 'productOptions.pickUpToOne' | transloco }}
                                    </ng-template>
                                    <ng-template ngPluralCase="other"> {{ 'productOptions.pickUpTo' |
                                        transloco:{quantity: getNumber(option.limits.quantity)} }}</ng-template>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="option.limits.type == 'supplemental'">
                                <div [ngPlural]="option.limits.quantity" class="product-option-quantity">
                                    <ng-template ngPluralCase="=1"> {{ 'productOptions.oneChoiceIncluded' | transloco }}
                                    </ng-template>
                                    <ng-template ngPluralCase="other"> {{ 'productOptions.choicesIncluded' |
                                        transloco:{quantity: getNumber(option.limits.quantity) | titlecase} }}
                                    </ng-template>
                                </div>
                            </ng-container>
                        </div>
                        <div *ngIf="option.limits.type == 'required'" class="product-option-required">
                            {{ 'common.required' | transloco }}
                        </div>
                    </div>
                    <div class="product-option-product-wrapper">
                        <div *ngFor="let product of option.products; let i = index" class="product-option-product">
                            <div class="product-option-product-checkbox">
                                <input *ngIf="option.limits.type == 'required' && option.limits.quantity == 1" type="radio" [attr.name]="option.id"[formControlName]="product.id" id="{{product.id}}"
                                    (change)="setControlValue(option.id, product.id)">
                                <input *ngIf="option.limits.type != 'required' || option.limits.quantity != 1" type="checkbox" [formControlName]="product.id" id="{{product.id}}"
                                    [attr.disabled]="!isSelected(option.id, product.id) && option.limits.type != 'supplemental' && getCount(option.id) == option.limits.quantity ? true: null">
                                <span class="product-option-product-title" [innerHTML]="product.title"></span>
                            </div>
                            <div *ngIf="showPrice(product, option.limits.type, option.limits.quantity, option.id)"
                                class="product-option-product-extra-amount">
                                + {{product.price | price | translocoCurrency}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="restaurant-item-options-note">
                <div class="restaurant-item-options-subheader">
                    {{ 'productOptions.note' | transloco }}
                </div>
                <textarea class="restaurant-item-options-textarea" maxlength="500" name="special-instructions"
                    id="special-instructions" cols="30" rows="3"
                    [placeholder]="'productOptions.notePlaceholder' | transloco" #note></textarea>
            </div>
        </div>
        <div class="restaurant-item-options-bottom-wrapper">
            <div class="restaurant-item-options-row">
                <div class="restaurant-item-options-quantity-container">
                    <button type="button" class="restaurant-item-options-quantity-button" (click)="decreaseQuantity()">
                        <img src="../../assets/images/icons/minus-white.svg">
                    </button>
                    <div class="restaurant-item-options-quantity-text">{{ quantity }}</div>
                    <button class="restaurant-item-options-quantity-button" (click)="increaseQuantity()">
                        <img src="../../assets/images/icons/plus-white.svg">
                    </button>
                </div>
                <div class="restaurant-item-options-cost">
                    {{ calculateTotalCost() | price | translocoCurrency }}
                </div>
            </div>
            <div [ngbTooltip]="'productOptions.missingRequiredOptions' | transloco" [disableTooltip]="isFormValid()"
                tooltipClass="radish-tooltip">
                <button class="restaurant-item-options-checkout-button" (click)="submitClick(note.value)"
                    [disabled]="!isFormValid()">
                    <ng-container *ngIf="!loading">
                        {{ 'productOptions.submitButton' | transloco }}
                    </ng-container>
                    <div *ngIf="loading" class="spinner-border spinner-border-sm text-light" role="status">
                        <span class="sr-only">{{ 'common.loading' | transloco }}</span>
                    </div>
                </button>
            </div>
        </div>
    </div>
</div>