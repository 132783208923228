import { Component, Input, OnInit } from '@angular/core';
import Icon from '../icon.interface'

@Component({
  selector: 'app-icon-gluten-free',
  templateUrl: './gluten-free.component.html',
  styleUrls: ['./gluten-free.component.css']
})
export class GlutenFreeComponent implements OnInit, Icon {

    @Input() fill: string = '#000000'

    constructor() { }

    ngOnInit(): void {
    }

}
