import { Component, OnInit, Input, Inject, PLATFORM_ID } from '@angular/core';
import { Restaurant, TranslatedText, TranslatedTextBasic, TranslatedTextType } from '../../../../core/models';
import { FeatureTypeEnum, IFeature, MenuFavoriteComponent } from 'src/app/modals/menu-favorite/menu-favorite.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslocoService } from '@ngneat/transloco';
import { ConfigService } from 'src/app/core/services/config.service';
import { isPlatformBrowser } from '@angular/common';
import { RestaurantSlug } from 'src/app/core/enums';

export interface IMenuFavoriteRaw {
    title?: TranslatedTextBasic;
    description?: TranslatedTextBasic;
    image?: string;
    restaurantSlug: string;
}

export interface IMenuFavorite extends IFeature {
    restaurantSlug: string;
}

@Component({
    selector: 'app-restaurant-about-menu-favorites',
    templateUrl: './restaurant-about-menu-favorites.component.html',
    styleUrls: ['./restaurant-about-menu-favorites.component.css'],
})
export class RestaurantAboutMenuFavoritesComponent implements OnInit {
    @Input() restaurant: Restaurant;

    readonly maharaniFavorites;
    readonly enchanteurFavorites;
    readonly lylaFavorites;
    readonly dioloFavorites;
    readonly merRougeFavorites;
    readonly arahovaFavorites;
    readonly delicieuxFavorites;
    readonly shamFavorites;
    readonly nakamichiFavorites;
    readonly croqueteriaFavorites;
    readonly ptitPlateauFavorites;
    readonly marquisFavorites;
    readonly sushiPlusBeaumontFavorites;
    readonly kojiSoupeFavorites;
    readonly //lundisAuSoleilFavorites;
    readonly tincSetFavorites;
    readonly fanfarePizzaFavorites;
    readonly inochiExpressFavorites;
    readonly bashaFavorites;
    readonly uluwatuFavorites;
    readonly saintHoublonFavorites;
    readonly chezTauBayFavorites;
    readonly boustanFavorites;
    readonly thaiExpressCdnFavorites;
    readonly javaUCdnFavorites;
    readonly mcgillPizzaFavorites;
    readonly yuanFavorites;
    readonly segretaFavorites;
    readonly quesadaCdnFavorites;
    readonly oiseauxDePassageFavorites;
    readonly cafeVitaFavorites;
    readonly burgerBrosFavorites;
    readonly juliansFavorites;
    readonly spiceBrosFavorites;
    readonly santaLuciaFavorites;
    readonly dakarFavorites;
    // readonly wallysFavorites;
    readonly oFourFavorites;
    readonly karinFavorites;
    readonly marcheMaremaFavorites;
    readonly elephantThaiFavorites;
    readonly sushiyoFavorites;
    readonly boiteVeganeFavorites;
    readonly heroBurgersFavorites;
    readonly jrsCafeFavorites;
    readonly cafeResonanceFavorites;
    readonly ubiSushiFavorites;
    readonly melroseFavorites;
    readonly obsceneVeganFavorites;
    readonly lestersDeliFavorites;
    readonly barrancoFavorites;
    readonly petitSeoulFavorites;
    readonly hootersFavorites;
    readonly darbarFavorites;
    readonly jardinPetrosFavorites;
    readonly chezAnnaFavorites;
    readonly kroodFavorites;
    readonly maynardFavorites;
    readonly mFourBurritosFavorites;
    readonly bocadilloFavorites;
    readonly laurierBbqFavorites;
    readonly oFishFavorites;
    readonly sushiSamaConcordiaFavorites;
    readonly paniniStopFavorites;
    readonly pokeStationCdnFavorites;
    readonly teochewFoodieFavorites;
    readonly TapigoFavorites;
    readonly brigadePizzaFavorites;
    readonly alAmineFavorites;
    readonly cacao70Favorites;
    // readonly bernieBeigneFavorites;
    readonly tacosNationFavorites;
    // readonly poissonnerieSherbrookeFavorites;
    readonly mintarFavorites;
    readonly auxMerveilleuxDeFredFavorites;
    readonly laToileFavorites;
    readonly sepLaiFavorites;
    readonly bistroDeLaCiteFavorites;
    readonly singhsFavorites;
    readonly chaiTeaLoungeFavorites;
    readonly laCroissanterieFigaroFavorites;
    readonly laFabriqueDeBagelFavorites;
    readonly studentTastyBiryaniFavorites;
    readonly osukaSushiFavorites;
    readonly laliFavorites;
    readonly chezTaMereFavorites;
    readonly chatpataFavorites;
    readonly friteAlorsFavorites;
    readonly tibumFavorites;
    readonly thePerleFavorites;
    readonly gongChaFavorites;
    readonly buffaloBillFavorites;
    readonly souvlakiGrecPlusFavorites;
    readonly pizzaSportFavorites;
    readonly tsujiriFavorites;
    readonly spartaChickenFavorites;
    readonly p23DumplingsFavorites;
    readonly burgzCantineFavorites;
    readonly goplanaFavorites;
    readonly piriPiriFavorites;
    readonly ukiyaIzakayaFavorites;
    readonly livSaladesFavorites;
    readonly maisonDuDestinFavorites;
    readonly flatBellyDeliFavorites;
    readonly downtownDhabaFavorites;
    readonly grilladosFavorites;
    favorites;

    // RestoPage TODO: Add readonly restaurantSlugFavorites property above

    private isBrowser: boolean

    constructor(
        @Inject(PLATFORM_ID) platformId: Object,
        private modalService: NgbModal,
        public translate: TranslocoService,
        public configService: ConfigService) {
        if (isPlatformBrowser(platformId)) this.isBrowser = true;
        this.maharaniFavorites = this.generateFeatures(
            {
                title: {
                    en: 'Butter Chicken',
                    fr: 'Poulet au beurre'
                },
                description: {
                    en: 'Tender chicken in a creamy curry sauce, combined with ethnic spices and ingredients like onion, butter and tomatoes.',
                    fr: 'Poulet tendre servi dans une sauce au cari crémeuse et infusée d’épices traditionnels et d’ingrédients naturels tels que des oignons, du beurre et des tomates.'
                },
                image: '../../../../assets/images/buffet-maharani/buffet-maharani-butter-chicken.jpg',
                restaurantSlug: RestaurantSlug.Maharani
            },
            {
                title: {
                    en: 'Palak Paneer',
                    fr: 'Palak paneer'
                },
                description: {
                    en: 'A vegetarian dish that consists of paneer (Indian cottage cheese) in a smooth and creamy curry made from spinach seasoned with ginger, garlic, garam masala and other spices and herbs.',
                    fr: 'Un plat végétarien constitué de paneer (fromage cottage indien) combiné à un curry doux et crémeux à base d’épinards assaisonnés de gingembre, d’ail, de garam masala et d’autres épices et herbes.'
                },
                image: '../../../../assets/images/buffet-maharani/buffet-maharani-palak-paneer.jpg',
                restaurantSlug: RestaurantSlug.Maharani
            },
            {
                title: {
                    en: 'Tandoori Chicken',
                    fr: 'Poulet tandoori'
                },
                description: {
                    en: 'Grilled chicken that is marinated in yogourt, lemon juice and traditional Indian spices.',
                    fr: 'Poulet grillé et mariné dans un mélange de yogourt, jus de citron et épices traditionnels indiens.'
                },
                image: '../../../../assets/images/buffet-maharani/buffet-maharani-tandoori-chicken.jpg',
                restaurantSlug: RestaurantSlug.Maharani
            }
        );
        this.enchanteurFavorites = this.generateFeatures(
            {
                title: {
                    en: 'L’Enchanteur Burger',
                    fr: 'Hambourgeois L’Enchanteur'
                },
                description: {
                    en: '',
                    fr: ''
                },
                image: '../../../../assets/images/enchanteur/enchanteur-burger.jpg',
                restaurantSlug: RestaurantSlug.Enchanteur
            },
            {
                title: {
                    en: 'Seven Vegetables Couscous',
                    fr: 'Couscous aux Sept Légumes'
                },
                description: {
                    en: '',
                    fr: ''
                },
                image: '../../../../assets/images/enchanteur/enchanteur-couscous.jpg',
                restaurantSlug: RestaurantSlug.Enchanteur
            },
            {
                title: {
                    en: 'Healthy Salad',
                    fr: 'Salade Santé'
                },
                description: {
                    en: '',
                    fr: ''
                },
                image: '../../../../assets/images/enchanteur/enchanteur-salade-sante.jpg',
                restaurantSlug: RestaurantSlug.Enchanteur
            }
        );
        this.lylaFavorites = this.generateFeatures(
            {
                title: {
                    en: 'Tonkinese (Pho) Soup',
                    fr: 'Soupe (pho) tonkinoise'
                },
                description: {
                    en: '',
                    fr: ''
                },
                image: '../../../../assets/images/lyla/lyla-food-4.jpg',
                restaurantSlug: RestaurantSlug.Lyla
            },
            {
                title: {
                    en: 'General Tao chicken',
                    fr: 'Poulet général tao'
                },
                description: {
                    en: '',
                    fr: ''
                },
                image: '../../../../assets/images/lyla/lyla-general-tao.jpg',
                restaurantSlug: RestaurantSlug.Lyla
            },
            {
                title: {
                    en: 'Wonton soup',
                    fr: 'Soupe wonton'
                },
                description: {
                    en: '',
                    fr: ''
                },
                image: '../../../../assets/images/lyla/lyla-wonton-soup.jpg',
                restaurantSlug: RestaurantSlug.Lyla
            }
        );
        this.dioloFavorites = this.generateFeatures(
            {
                title: {
                    en: 'Dibi',
                    fr: 'Dibi'
                },
                description: {
                    en: 'Grilled lamb or chicken',
                    fr: 'Agneau ou poulet grillé'
                },
                image: '../../../../assets/images/diolo/diolo-dibi.jpg',
                restaurantSlug: RestaurantSlug.Diolo
            },
            {
                title: {
                    en: 'Thiebou Dieune',
                    fr: 'Thiebou Dieune'
                },
                description: {
                    en: 'Red rice, fish, vegetables',
                    fr: 'Riz rouge, poisson, légumes'
                },
                image: '../../../../assets/images/diolo/diolo-thiebou-dieune.jpg',
                restaurantSlug: RestaurantSlug.Diolo
            },
            {
                title: {
                    en: 'Thiebou Yapp',
                    fr: 'Thiebou Yapp'
                },
                description: {
                    en: 'Rice, meat, vegetables',
                    fr: 'Riz, viande, légumes'
                },
                image: '../../../../assets/images/diolo/diolo-thiebou-yapp.jpg',
                restaurantSlug: RestaurantSlug.Diolo
            }
        );
        this.merRougeFavorites = this.generateFeatures(
            {
                title: {
                    en: 'Family Plate',
                    fr: 'Plat Familial'
                },
                description: {
                    en: '',
                    fr: ''
                },
                image: '../../../../assets/images/mer-rouge/mer-rouge-plate-4.jpg',
                restaurantSlug: RestaurantSlug.MerRouge
            },
            {
                title: {
                    en: 'Salmon',
                    fr: 'Saumon'
                },
                description: {
                    en: '',
                    fr: ''
                },
                image: '../../../../assets/images/mer-rouge/mer-rouge-salmon.jpg',
                restaurantSlug: RestaurantSlug.MerRouge
            },
            {
                title: {
                    en: 'BBQ Shrimp',
                    fr: 'Crevettes BBQ'
                },
                description: {
                    en: '',
                    fr: ''
                },
                image: '../../../../assets/images/mer-rouge/mer-rouge-shrimp.jpg',
                restaurantSlug: RestaurantSlug.MerRouge
            }
        );
        this.arahovaFavorites = this.generateFeatures(
            {
                title: {
                    en: 'Chicken Brochettes',
                    fr: 'Brochettes de poulet'
                },
                description: {
                    en: '',
                    fr: ''
                },
                image: '../../../../assets/images/arahova/arahova-brochettes-poulet.jpg',
                restaurantSlug: RestaurantSlug.Arahova
            },
            {
                title: {
                    en: 'Souvlaki Pita',
                    fr: 'Pita souvlaki'
                },
                description: {
                    en: '',
                    fr: ''
                },
                image: '../../../../assets/images/arahova/arahova-gyro-pita.jpg',
                restaurantSlug: RestaurantSlug.Arahova
            },
            {
                title: {
                    en: 'Fried Calamari',
                    fr: 'Calamars frits'
                },
                description: {
                    en: '',
                    fr: ''
                },
                image: '../../../../assets/images/arahova/arahova-calamari.jpg',
                restaurantSlug: RestaurantSlug.Arahova
            },
        );
        this.delicieuxFavorites = this.generateFeatures(
            {
                title: {
                    en: 'Delicious Noodles with Chicken and Beef',
                    fr: 'Nouilles délicieuses avec poulet et bœuf'
                },
                description: {
                    en: '',
                    fr: ''
                },
                image: '../../../../assets/images/delicieux/delicieux-noodles.jpg',
                restaurantSlug: RestaurantSlug.Delicieux
            },
            {
                title: {
                    en: 'Fried Rice House Special',
                    fr: 'Riz frit spécial maison'
                },
                description: {
                    en: '',
                    fr: ''
                },
                image: '../../../../assets/images/delicieux/delicieux-fried-rice.jpg',
                restaurantSlug: RestaurantSlug.Delicieux
            },
            {
                title: {
                    en: 'Crispy Chicken with Ginger Sauce',
                    fr: 'Poulet croustillant avec sauce gingembre'
                },
                description: {
                    en: '',
                    fr: ''
                },
                image: '../../../../assets/images/delicieux/delicieux-crispy-chicken.jpg',
                restaurantSlug: RestaurantSlug.Delicieux
            }
        );
        this.shamFavorites = this.generateFeatures(
            {
                title: {
                    en: 'Vegan Shish Taouk Plate',
                    fr: 'Assiette de shish taouk végétalien'
                },
                description: {
                    en: '',
                    fr: ''
                },
                image: '../../../../assets/images/sham/sham-plate-shish-taouk.jpg',
                restaurantSlug: RestaurantSlug.Sham
            },
            {
                title: {
                    en: 'Vegan Shawarma Plate',
                    fr: 'Assiette de shawarma végétalien'
                },
                description: {
                    en: '',
                    fr: ''
                },
                image: '../../../../assets/images/sham/sham-plate-shawarma.jpg',
                restaurantSlug: RestaurantSlug.Sham
            },
            {
                title: {
                    en: 'Vegan Falafel Plate',
                    fr: 'Assiette de falafel végétalien'
                },
                description: {
                    en: '',
                    fr: ''
                },
                image: '../../../../assets/images/sham/sham-plate-falafel.jpg',
                restaurantSlug: RestaurantSlug.Sham
            }
        );
        this.nakamichiFavorites = this.generateFeatures(
            {
                title: {
                    en: 'Nakamichi Ramen "SHIRO"',
                    fr: 'Ramen Nakamichi "SHIRO"'
                },
                description: {
                    en: 'Nakamichi‘s signature silky white broth, Chicken, Pork cha siu, Blanched kale, White Onion, Nori, Kikurage Mushroom, Naruto',
                    fr: 'Bouillon blanc soyeux signature Nakamichi, poulet, porc cha siu, chou frisé blanchi, oignon blanc, nori, champignon de Kikurage, narutomaki'
                },
                image: '../../../../assets/images/nakamichi/nakamichi-ramen-shiro.jpg',
                restaurantSlug: RestaurantSlug.Nakamichi
            },
            {
                title: {
                    en: 'Kara-age Chicken',
                    fr: 'Poulet Kara-age'
                },
                description: {
                    en: 'Japanese style fried chicken. Kara-age, pronounced kah-rah-ah-geh, is a style of Japanese cooking that involves deep frying breaded pieces of chicken.',
                    fr: 'Poulet frit de style Japonais. Le kara-age, prononcé kah-rah-ah-geh, est un style classique de cuisine japonaise qui consiste à faire frire des morceaux de poulet panés.'
                },
                image: '../../../../assets/images/nakamichi/nakamichi-karaage.jpg',
                restaurantSlug: RestaurantSlug.Nakamichi
            },
            {
                title: {
                    en: 'Cha-siu Don',
                    fr: 'Cha-siu Don'
                },
                description: {
                    en: 'Pork Cha-siu, Green Onion, QP Mayo, Ramen Sauce, Shichimi pepper, Egg',
                    fr: 'Porc Cha-siu, oignon vert, mayo QP, sauce ramen, poivre Shichimi, œuf'
                },
                image: '../../../../assets/images/nakamichi/nakamichi-cha-siu-don.jpg',
                restaurantSlug: RestaurantSlug.Nakamichi
            }
        );
        this.croqueteriaFavorites = this.generateFeatures(
            {
                title: {
                    en: 'Jamón Serrano',
                    fr: 'Jamón Serrano'
                },
                description: {
                    en: 'Probably our best known and most loved croquette. The most popular. The croquette de Jamón Serrano! Made from Spanish hams cured for 18 months, this gastronomic wonder leaves no one dissatisfied. Pairs well with: Quebec blanc, Arinto, Saint Véran, Graves (Cht Haut-Brion), Rioja.',
                    fr: 'Probablement notre croquette la plus connue et la plus appréciée. Les plus populaires. La croquette de jambon serrano ! Élaborée à partir de jambons espagnols séchés pendant 18 mois, cette merveille de la gastronomie ne laisse personne insatisfait. S’accorde bien avec: Quebec blanc, Arinto, Saint Véran, Graves (Cht Haut-Brion), Rioja.'
                },
                image: '../../../../assets/images/croqueteria/croqueteria-jamon-serrano.jpg',
                restaurantSlug: RestaurantSlug.Croqueteria
            },
            {
                title: {
                    en: 'Garlic Shrimp',
                    fr: 'Crevette À L’ail'
                },
                description: {
                    en: 'A classic Spanish, garlic shrimp. The fried garlic with parsley goes well with the Matan shrimp. Pairs well with: Régal D’automne, (Frontenac Gris), Chardonnay South, Rosé de Provence.',
                    fr: 'Un espagnol classique, des crevettes à l‘ail. L‘ail frit au persil se marie en harmonie avec les crevettes de Matan. S’accorde bien avec: Régal D’automne, (Frontenac Gris), Chardonnay South, Rosé de Provence.'
                },
                image: '../../../../assets/images/croqueteria/croqueteria-garlic-shrimp.jpg',
                restaurantSlug: RestaurantSlug.Croqueteria
            },
            {
                title: {
                    en: 'Cheddar Vieilli',
                    fr: 'Cheddar Vieilli '
                },
                description: {
                    en: 'It‘s a simple and humble croquette that everyone loves. Sometimes less is more. Pairs well with: cider, white Quebec (Frontenac Blanc, Vidal), Mencia de Bierzo.',
                    fr: 'C’est une croquette simple et humble, que tout le monde aime. Parfois, moins, cest plus. S’accorde bien avec: le cidre, blanc Québec (Frontenac Blanc, Vidal), Mencia de Bierzo.'
                },
                image: '../../../../assets/images/croqueteria/croqueteria-cheddar.jpg',
                restaurantSlug: RestaurantSlug.Croqueteria
            }
        );
        this.ptitPlateauFavorites = this.generateFeatures(
            {
                title: {
                    en: 'P’tit Plateau Cassoulet',
                    fr: 'Le cassoulet du P’tit Plateau aux cinq viandes'
                },
                description: {
                    en: 'Pork shoulder, garlic sausage, sausages, pork cheek and duck confit, and white beans.',
                    fr: 'Épaule de porc, saucisson à l’ail, saucisses, joue de porc et canard confit, et haricots blanc.'
                },
                image: '../../../../assets/images/ptit-plateau/ptit-plateau-cassoulet.jpg',
                restaurantSlug: RestaurantSlug.PtitPlateau
            },
            {
                title: {
                    en: 'Duck Confit',
                    fr: 'Confit de canard'
                },
                description: {
                    en: 'Arugula, gizzard, smoked margret, and armagnac sauce. Served with potato gratin, ratatouille, and green beans.',
                    fr: 'Gesier et magret fumé sauce armagnac. Servie avec gratin dauphinois, ratatouille, et haricot française.'
                },
                image: '../../../../assets/images/ptit-plateau/ptit-plateau-confit-canard.jpg',
                restaurantSlug: RestaurantSlug.PtitPlateau
            },
            {
                title: {
                    en: 'Homemade Fois Gras Torchon',
                    fr: 'Foie gras maison cuit au torchon'
                },
                description: {
                    en: 'Apple, pear compote, and brioche bread.',
                    fr: 'Compote de pomme, poire, et pain brioche.'
                },
                image: '../../../../assets/images/ptit-plateau/ptit-plateau-foie-gras.jpg',
                restaurantSlug: RestaurantSlug.PtitPlateau
            }
        );
        this.kojiSoupeFavorites = this.generateFeatures(
            {
                title: {
                    en: 'Koji Beef Stew',
                    fr: 'Ragoût de bœuf Koji'
                },
                description: {
                    en: 'Stew cooked with onions, japanese mushrooms and beef marinated koji sauce (served with rice on the side)',
                    fr: 'Ragoût cuit avec des oignons, des champignons japonais et du boeuf mariné à la sauce koji (servi avec du riz)'
                },
                image: '../../../../assets/images/koji-soupe/koji-soupe-beef-stew.jpg',
                restaurantSlug: RestaurantSlug.KojiSoupe
            },
            {
                title: {
                    en: 'Soy milk & koji miso with chicken balls',
                    fr: 'Miso au lait de soja et au koji avec des boulettes de poulet'
                },
                description: {
                    en: 'Soy milk and koji miso base soup cooked with japanese mushroom, radish and chicken balls (served with rice on the side)',
                    fr: 'Soupe à base de lait de soja et miso koji cuisinés avec des champignons japonais, des radis et des boulettes de poulet (servi avec du riz)'
                },
                image: '../../../../assets/images/koji-soupe/koji-soupe-soy-milk.jpg',
                restaurantSlug: RestaurantSlug.KojiSoupe
            },
            {
                title: {
                    en: 'Koji miso mushrooms (vegan)',
                    fr: 'Koji miso aux champignons (végétalien)'
                },
                description: {
                    en: 'Sautéed mushrooms cooked with Kombu broth and spices',
                    fr: 'Champignons sautés, cuits avec bouillon d‘algue Kombu et des épices'
                },
                image: '../../../../assets/images/koji-soupe/koji-soupe-mushrooms.jpg',
                restaurantSlug: RestaurantSlug.KojiSoupe
            }
        );
        this.tincSetFavorites = this.generateFeatures(
            {
                title: {
                    en: 'Poulet rôti à la barcelonaise',
                    fr: 'Poulet rôti à la barcelonaise'
                },
                description: {
                    en: 'Poulet fermier du QC à la barcelonaise',
                    fr: 'Poulet fermier du QC à la barcelonaise'
                },
                image: '../../../../assets/images/tinc-set/carousel/tinc-set-poulet-roti.jpg',
                restaurantSlug: RestaurantSlug.TincSet
            },
            {
                title: {
                    en: 'Bocadillo de calmar frits, aïoli et verdure',
                    fr: 'Bocadillo de calmar frits, aïoli et verdure'
                },
                description: {
                    en: 'Sandwich espagnol sur baguette',
                    fr: 'Sandwich espagnol sur baguette'
                },
                image: '../../../../assets/images/tinc-set/tinc-set-bocadillo.jpg',
                restaurantSlug: RestaurantSlug.TincSet
            },
            {
                title: {
                    en: 'Churros maison',
                    fr: 'Churros maison'
                },
                description: {
                    en: '',
                    fr: ''
                },
                image: '../../../../assets/images/tinc-set/tinc-set-churros.jpg',
                restaurantSlug: RestaurantSlug.TincSet
            }
        );
        this.fanfarePizzaFavorites = this.generateFeatures(
            {
                title: {
                    en: 'Kiss Ya Mama',
                    fr: 'Kiss Ya Mama'
                },
                description: {
                    en: 'Pesto d‘épinards et pacanes, aubergines grillées, tomates confites, pepperoni, pickel de piment, reggiano',
                    fr: 'Pesto d‘épinards et pacanes, aubergines grillées, tomates confites, pepperoni, pickel de piment, reggiano'
                },
                image: '../../../../assets/images/fanfare-pizza/fanfare-pizza-kiss-ya-mama.jpg',
                restaurantSlug: RestaurantSlug.FanfarePizza
            },
            {
                title: {
                    en: 'Provençale',
                    fr: 'Provençale'
                },
                description: {
                    en: 'Crème fraiche, tomates roties, mozzarella, crevettes grillées à la lime, lard, poivrons fumés, reggiano',
                    fr: 'Crème fraiche, tomates roties, mozzarella, crevettes grillées à la lime, lard, poivrons fumés, reggiano'
                },
                image: '../../../../assets/images/fanfare-pizza/fanfare-pizza-provencale.jpg',
                restaurantSlug: RestaurantSlug.FanfarePizza
            },
            {
                title: {
                    en: 'Pepperoni Tabasco',
                    fr: 'Pepperoni Tabasco'
                },
                description: {
                    en: 'Sauce tomate, tomates roties, pepperoni doux, mozzarella, huile de tabasco.',
                    fr: 'Sauce tomate, tomates roties, pepperoni doux, mozzarella, huile de tabasco.'
                },
                image: '../../../../assets/images/fanfare-pizza/fanfare-pizza-pepperoni.jpg',
                restaurantSlug: RestaurantSlug.FanfarePizza
            }
        );
        this.inochiExpressFavorites = this.generateFeatures(
            {
                title: {
                    en: 'Kamikaze',
                    fr: 'Kamikaze'
                },
                description: {
                    en: 'Spicy tuna, caviar, tempura, avocado, cucumber, lettuce',
                    fr: 'Thon épicé, caviar, tempura, avocat, concombre, laitue'
                },
                image: '',
                restaurantSlug: RestaurantSlug.InochiExpress
            },
            {
                title: {
                    en: 'Mango-Tango',
                    fr: 'Mango-Tango'
                },
                description: {
                    en: 'Croustille de taro, saumon, mangue, fraise, tobiko, sauce épicée',
                    fr: 'taro chip, salmon, mango, strawberry, tobiko, spicy sauce'
                },
                image: '../../../../assets/images/inochi-express/inochi-express-mango-tango.jpg',
                restaurantSlug: RestaurantSlug.InochiExpress
            },
            {
                title: {
                    en: 'Combo D',
                    fr: 'Combo D'
                },
                description: {
                    en: '8 futomakis 6 hosomakis 6 sushis (20 pieces)',
                    fr: '8 futomakis 6 hosomakis 6 sushis (20 morceaux)'
                },
                image: '',
                restaurantSlug: RestaurantSlug.InochiExpress
            }
        );
        this.bashaFavorites = this.generateFeatures(
            {
                title: {
                    en: 'Shawarma Beef and Lamb Sandwich',
                    fr: 'Sandwich shawarma (boeuf et agneau)'
                },
                description: {
                    en: 'Shawarma sandwich (beef / lamb), sesame sauce, garnished with garlic sauce, salad, tomatoes, turnips & onions',
                    fr: 'Sandwich shawarma (boeuf / agneau), sauce sésame, garni avec sauce à l’ail, salade, tomates, navets et oignons'
                },
                image: '../../../../assets/images/basha/carousel/basha-sandwich-shawarma-boeuf-et-agneau.jpeg',
                restaurantSlug: RestaurantSlug.Basha
            },
            {
                title: {
                    en: 'Shish Taouk Chicken Trio',
                    fr: 'Trio shish taouk poulet'
                },
                description: {
                    en: 'Shish taouk (chicken) sandwich, garnished with garlic sauce, salad, tomatoes, turnips & onions.',
                    fr: 'Sandwich shish taouk (poulet), garni avec sauce à l’ail, salade, tomates, navets & oignons.'
                },
                image: '../../../../assets/images/basha/carousel/basha-trio-sandwich-shish-taouk-poulet.jpeg',
                restaurantSlug: RestaurantSlug.Basha
            },
            {
                title: {
                    en: 'Shawarma Beef Poutine',
                    fr: 'Poutine au shawarma au boeuf'
                },
                description: {
                    en: 'Garlic potatoes, beef shawarma, cheese curds, and poutine sauce.',
                    fr: 'Patate à l’ail, boeuf shawarma, fromage en grain, et sauce poutine.'
                },
                image: '../../../../assets/images/basha/carousel/basha-poutine-originale.jpeg',
                restaurantSlug: RestaurantSlug.Basha
            }
        );
        this.uluwatuFavorites = this.generateFeatures(
            {
                title: {
                    en: 'Iga Sapi Bakar Konro (Sulawesi Island)',
                    fr: 'Iga Sapi Bakar Konro (Sulawesi Island)',
                },
                description: {
                    en: 'Barbeque beef short rib, caramelised peanut sauce, fried shallot, beef broth (Lactose Free, Gluten Free)',
                    fr: 'Côte de bœuf au barbecue, sauce aux arachides caramélisées, échalote frite, bouillon de bœuf (sans lactose, sans gluten) '
                },
                image: '../../../../assets/images/uluwatu/uluwatu-beef.jpg',
                restaurantSlug: RestaurantSlug.Uluwatu
            },
            {
                title: {
                    en: 'Sate Kambing (Jakarta) 5 Skewers',
                    fr: 'Sate Kambing (Jakarta) 5 Brochettes'
                },
                description: {
                    en: 'Lamb skewers, caramelised soy sauce dip (Lactose Free)',
                    fr: "Brochettes d'agneau, trempette sauce soja caramélisée (Sans Lactose) "
                },
                image: '../../../../assets/images/uluwatu/uluwatu-lamb.jpg',
                restaurantSlug: RestaurantSlug.Uluwatu
            },
            {
                title: {
                    en: 'Bebek Bengil (Bali)',
                    fr: 'Bebek Bengil (Bali)'
                },
                description: {
                    en: 'Crispy duck confit in Balinese aromatic, (sambal matah) Balinese lemongrass and salsa, serundeng potato chip (Lactose Free, Gluten Free).',
                    fr: 'Croustillant de canard confit aux arômes balinais, (sambal matah) citronnelle balinaise et salsa, croustilles serundeng (Sans Lactose, Sans Gluten).'
                },
                // image: '../../../../assets/images/fanfare-pizza/fanfare-pizza-pepperoni.jpg',
                restaurantSlug: RestaurantSlug.Uluwatu
            }
        );
        this.saintHoublonFavorites = this.generateFeatures(
            {
                title: {
                    en: 'Pekin Duck Poutine',
                    fr: 'Poutine Canard Pékin',
                },
                description: {
                    en: 'The only one, the must-eat… the best seller of Saint Houblon since its opening! Cheese curds, stout and hoisin sauce, green onions ',
                    fr: 'Le seul, l’incontournable… le meilleur vendeur du Saint Houblon depuis son ouverture! Fromages en grains, sauce à la stout et hoisin, oignons verts'
                },
                image: '../../../../assets/images/saint-houblon/saint-houblon-poutine.jpg',
                restaurantSlug: RestaurantSlug.SaintHoublon
            },
            {
                title: {
                    en: 'Saint-Houblon Experience for 2 ',
                    fr: 'Expérience Saint-Houblon pour 2'
                },
                description: {
                    en: 'Appetizer (1), discovery to share (1) and main course (2)',
                    fr: "Entrée (1), découverte à partager (1) et repas (2)"
                },
                image: '../../../../assets/images/saint-houblon/saint-houblon-brunch.jpg',
                restaurantSlug: RestaurantSlug.SaintHoublon
            },
            {
                title: {
                    en: 'Mac & Cheese Crispy Chicken ',
                    fr: 'Mac & Cheese Poulet croustillant'
                },
                description: {
                    en: 'Ssamjang, sesame seeds',
                    fr: 'Ssamjang, graines de sésames'
                },
                image: '../../../../assets/images/saint-houblon/saint-houblon-mac-cheese.jpg',
                restaurantSlug: RestaurantSlug.SaintHoublon
            }
        );
        this.chezTauBayFavorites = this.generateFeatures(
            {
                title: {
                    en: 'P9. Special tonkinese soup',
                    fr: 'P9. Soupe tonkinoise spéciale',
                },
                description: {
                    en: 'Rare, well done, tripe, and beef ball',
                    fr: 'Rare, bien cuit, tripes et boule de bœuf'
                },
                image: '../../../../assets/images/chez-tau-bay/chez-tau-bay-pho.jpg',
                restaurantSlug: RestaurantSlug.ChezTauBay
            },
            {
                title: {
                    en: 'C1. Soup boeuf Hue',
                    fr: 'C1. Soupe boeuf Hue'
                },
                description: {
                    en: 'Hue-style beef vermicelli soup',
                    fr: "Soupe aux vermicelles style Huế"
                },
                image: '../../../../assets/images/chez-tau-bay/chez-tau-bay-hue.jpg',
                restaurantSlug: RestaurantSlug.ChezTauBay
            },
            {
                title: {
                    en: 'D. Grilled shrimps, beef and chicken',
                    fr: 'D. Crevettes, boeuf et poulet grillés'
                },
                description: {
                    en: '',
                    fr: ""
                },
                image: '../../../../assets/images/chez-tau-bay/chez-tau-bay-beef.jpg',
                restaurantSlug: RestaurantSlug.ChezTauBay
            }
        );
        this.boustanFavorites = this.generateFeatures(
            {
                title: {
                    en: 'Mixed Shawarma Plate ',
                    fr: 'Assiette Shawarma Mixte',
                },
                description: {
                    en: 'Beef and Chicken shawarma served with rice, potatoes, coleslaw, garlic sauce and hummus',
                    fr: 'Shawarma bœuf et poulet servi avec du riz, pommes de terre, salade de chou, sauce à l’ail et hummus'
                },
                image: '../../../../assets/images/boustan/boustan-plate.jpg',
                restaurantSlug: RestaurantSlug.Boustan
            },
            {
                title: {
                    en: 'Chicken Shawarma',
                    fr: 'Pita Poulet Shawarma'
                },
                description: {
                    en: '',
                    fr: ""
                },
                image: '../../../../assets/images/boustan/boustan-shawarma.jpg',
                restaurantSlug: RestaurantSlug.Boustan
            },
            {
                title: {
                    en: 'Garlic Potatoes',
                    fr: 'Pomme de terre à l’ail'
                },
                description: {
                    en: 'Golden seasoned garlic potatoes served with garlic sauce.',
                    fr: "Pommes de terre à l'ail assaisonnées dorées servies avec sauce à l'ail. "
                },
                image: '../../../../assets/images/boustan/boustan-potatoes.jpg',
                restaurantSlug: RestaurantSlug.Boustan
            }
        );
        this.thaiExpressCdnFavorites = this.generateFeatures(
            {
                title: {
                    en: 'Pad Thaï',
                    fr: 'Pad Thaï',
                },
                description: {
                    en: 'The national dish of Thailand! Thin rice noodles stir-fried with our sweet and sour sauce, egg, bean sprouts, green onion, tofu and salted radish with your choice of Shrimp, Beef, Chicken, Vegetables, Tofu, Fish or Vegan (contains no seafood or animal product).<br><br>Squeeze a little lime juice and sprinkle some peanuts on top to taste the true Thai experience.',
                    fr: 'Nouilles de riz minces sautées avec sauce aigre-douce avec œufs, fève germée, oignon vert, tofu et radis salés. Au choix de Crevettes, Bœuf, Poulet, Légumes, Tofu ou Poisson.<br><br>Suggestion : ajoutez le jus d’un morceau de lime pressé et saupoudrer d’arachides.'
                },
                image: '../../../../assets/images/thai-express-cdn/thai-express-cdn-pad-thai.jpg',
                restaurantSlug: RestaurantSlug.ThaiExpressCdn
            },
            {
                title: {
                    en: 'Fried Chicken Dumplings',
                    fr: 'Raviolis au poulet frits'
                },
                description: {
                    en: 'Crispy chicken dumplings served with sweet chili sauce.',
                    fr: "Raviolis frits servis avec la sauce au chili sucrée."
                },
                image: '../../../../assets/images/thai-express-cdn/thai-express-cdn-dumpling.jpg',
                restaurantSlug: RestaurantSlug.ThaiExpressCdn
            },
            {
                title: {
                    en: 'Pad See Ew',
                    fr: 'Pad See Ew'
                },
                description: {
                    en: 'Broad rice noodles served with our house brand soy sauce, egg and Chinese broccoli with your choice of Shrimp, Beef, Chicken, Vegetables, Tofu, Fish or Vegan* (contains no seafood or animal product).',
                    fr: "Nouilles de riz larges sautées avec sauce de soja maison avec œufs et brocoli chinois. Au choix de Crevettes, Bœuf, Poulet, Légumes, Tofu ou Poisson."
                },
                image: '../../../../assets/images/thai-express-cdn/thai-express-cdn-pad-see-ew.jpg',
                restaurantSlug: RestaurantSlug.ThaiExpressCdn
            }
        );
        this.javaUCdnFavorites = this.generateFeatures(
            {
                title: {
                    en: 'Latte',
                    fr: 'Latté',
                },
                description: {
                    en: '',
                    fr: ''
                },
                image: '../../../../assets/images/java-u-cdn/carousel/java-u-dish-10.jpg',
                restaurantSlug: RestaurantSlug.JavaUCdn
            },
            {
                title: {
                    en: 'Bagel Salmon',
                    fr: 'Bagel Saumon'
                },
                description: {
                    en: 'Smoked wild salmon, cream cheese, red onion, bagel.',
                    fr: "Saumon sauvage fumé, fromage à la crème, oignons rouges, bagel."
                },
                image: '../../../../assets/images/java-u-cdn/java-u-cdn-bagel.jpg',
                restaurantSlug: RestaurantSlug.JavaUCdn
            },
            {
                title: {
                    en: 'Smoked Salmon Salad',
                    fr: 'Salade de saumon fumé'
                },
                description: {
                    en: '',
                    fr: ""
                },
                image: '../../../../assets/images/java-u-cdn/carousel/java-u-dish-8.jpg',
                restaurantSlug: RestaurantSlug.JavaUCdn
            }
        );
        this.mcgillPizzaFavorites = this.generateFeatures(
            {
                title: {
                    en: 'Pizzaghetti',
                    fr: 'Pizzaghetti',
                },
                description: {
                    en: 'Mini pizza with a half order of spaghetti with meat or marinara sauce',
                    fr: 'Mini pizza avec une demi portion de spaghetti et sauce à la viande ou marinara'
                },
                image: '../../../../assets/images/mcgill-pizza/mcgill-pizza-dish-8.JPG',
                restaurantSlug: RestaurantSlug.McgillPizza
            },
            {
                title: {
                    en: 'Poutine with Gravy',
                    fr: 'Poutine sauce brune'
                },
                description: {
                    en: '',
                    fr: ""
                },
                image: '../../../../assets/images/mcgill-pizza/mcgill-pizza-poutine.jpg',
                restaurantSlug: RestaurantSlug.McgillPizza
            },
            {
                title: {
                    en: 'Club Sandwich',
                    fr: 'Sandwich au club'
                },
                description: {
                    en: 'With chicken, tomato and bacon. Served with coleslaw and french fries',
                    fr: "Avec poulet, tomates et bacon. Servis avec salade chou et patates frites"
                },
                image: '../../../../assets/images/mcgill-pizza/mcgill-pizza-dish-10.JPG',
                restaurantSlug: RestaurantSlug.McgillPizza
            }
        );
        this.yuanFavorites = this.generateFeatures(
            {
                title: {
                    en: 'BAO - BBQ Vegan',
                    fr: 'BAO - BBQ Végétalien',
                },
                description: {
                    en: 'Steam sandwich serve with vegan BBQ sliced,  lettuce, peanuts and Chinese pickle!',
                    fr: 'Sandwich à la vapeur servi avec  "végé-viande bbq" tranché , laitue, arachides et cornichon chinois.'
                },
                image: '../../../../assets/images/yuan/yuan-bao.jpg',
                restaurantSlug: RestaurantSlug.Yuan
            },
            {
                title: {
                    en: 'Sushi Sumo',
                    fr: 'Sushi Sumo'
                },
                description: {
                    en: 'Spicy vege-salmon deluxe (cucumber, red pepper, vege-crab, vege-salmon, avocado tempura, vegan spicy mayo).',
                    fr: "Deluxe végé-saumon épicé (concombre, poivron rouge,  végé-crabe, végé-saumon, tempura, mayo épicée végétalienne)."
                },
                image: '../../../../assets/images/yuan/yuan-sumo.jpg',
                restaurantSlug: RestaurantSlug.Yuan
            },
            {
                title: {
                    en: 'Sushi Ebi Tempura',
                    fr: 'Sushi Ebi Tempura'
                },
                description: {
                    en: 'With chicken, tomato and bacon. Served with coleslaw and french fries.',
                    fr: "Crevettes végétalienne faites à la main avec carotte, concombre, poivron rouge, pousses et garnir de «caviar» végétalien."
                },
                image: '../../../../assets/images/yuan/yuan-tempura.jpg',
                restaurantSlug: RestaurantSlug.Yuan
            }
        );
        this.segretaFavorites = this.generateFeatures(
            {
                title: {
                    en: 'Funghi Pizza',
                    fr: 'Pizza Funghi',
                },
                description: {
                    en: 'Mozzarella, mushrooms, Italian sea salt, extra virgin olive oil, house-made porcini crema.',
                    fr: 'Mozzarella, champignons, sel de mer italien, huile d\'olive extra vierge, crème de cèpes maison'
                },
                restaurantSlug: RestaurantSlug.Segreta
            },
            {
                title: {
                    en: 'Spicy Italian Sausage Pizzao',
                    fr: 'Pizza à la Saucisse Italienne Épicée'
                },
                description: {
                    en: 'Tomato sauce, spicy Italian sausage, mozzarella, spicy extra virgin olive oil.',
                    fr: "Sauce tomate, saucisse italienne épicée, mozzarella, huile d'olive extra vierge épicée"
                },
                restaurantSlug: RestaurantSlug.Segreta
            },
            {
                title: {
                    en: 'Rosemary & Potatoes Pizza',
                    fr: 'Pizza au Romarin et Pommes de Terre'
                },
                description: {
                    en: 'Mozzzarella,crushed potatoes, rosemary, extra virgin olive oil, Italian sea salt.',
                    fr: "Mozzarella, pommes de terre, romarin, huile d'olive extra vierge, sel de mer italien"
                },
                restaurantSlug: RestaurantSlug.Segreta
            }
        );
        this.quesadaCdnFavorites = this.generateFeatures(
            {
                title: {
                    en: 'Large Burrito',
                    fr: 'Grand burrito',
                },
                description: {
                    en: 'Large tortilla filled to bursting with your favourite protein and all your favourite toppings!',
                    fr: 'Laissez-vous séduire par les succulents burritos de Quesada! Ils sont garnis de riz brun assaisonné, haricots et votre choix de garnitures gratuites comme des salsas fraîches et de savoureuses sauces.'
                },
                image: '../../../../assets/images/quesada-cdn/quesada-cdn-burrito.jpeg',
                restaurantSlug: RestaurantSlug.QuesadaCdn
            },
            {
                title: {
                    en: 'Burrito Bowl',
                    fr: 'Bol burrito'
                },
                description: {

                    en: 'Enjoy our delicious choice of protein served on your choice of lettuce or rice with all your favourite toppings! This menu item is gluten free.',
                    fr: "Essayez les délicieux bols burrito de Quesada! Ils sont garnis d'un lit de riz brun assaisonné ou de laitue iceberg, puis de votre choix de garnitures gratuites comme des salsas faites maison et de savoureuses sauces."
                },
                image: '../../../../assets/images/quesada-cdn/quesada-cdn-bowl.jpeg',
                restaurantSlug: RestaurantSlug.QuesadaCdn
            },
            {
                title: {
                    en: '3 Tacos',
                    fr: '3 Tacos'
                },

                description: {
                    en: 'Your choice of 3 delicious soft or hard shell tacos loaded with your favourite protein and your choice of toppings! You can make this menu item gluten free by choosing our hard shell (corn) tacos!',
                    fr: "Votre choix de 3 tacos souples à la farine ou croustillants au maïs garnis de votre choix de garnitures gratuites incluant les délicieuses salsas et sauces de Quesada."
                },
                image: '../../../../assets/images/quesada-cdn/quesada-cdn-taco.jpeg',
                restaurantSlug: RestaurantSlug.QuesadaCdn
            }
        );
        this.oiseauxDePassageFavorites = this.generateFeatures(
            {
                title: {
                    en: 'Duck breast, QC with sour cherry sauce',
                    fr: 'Magret de canard, QC sauce cerises griottes',
                },
                description: {
                    en: 'Moulard duck magret from the Rougié farms (Marieville, QC), Morello cherry sauce along with two sides, home-made bread and half-salted butter.',
                    fr: 'Magret de canard des fermes Rougié (Marieville, QC), sauce aux cerises griottes et deux accompagnements au choix'
                },
                image: '../../../../assets/images/oiseaux-de-passage/oiseaux-de-passage-duck.jpg',
                restaurantSlug: RestaurantSlug.OiseauxDePassage
            },
            {
                title: {
                    en: 'Pan-fried QC foie gras escalope',
                    fr: 'Escalope de foie gras QC poêlée'
                },
                description: {
                    en: '70g of pan seared foie gras served on a soft ginger bread and honey and oriental spice sauce, served with caramelised apples and honeycomb.',
                    fr: "Escalope de foie gras de 70g poêlée servie avec un pain d’épices moelleux et une sauce au miel et aux épices, pommes caramélisées et miel en rayon."
                },
                image: '../../../../assets/images/oiseaux-de-passage/oiseaux-de-passage-foie-gras.jpg',
                restaurantSlug: RestaurantSlug.OiseauxDePassage
            },
            {
                title: {
                    en: 'Lamb Shank',
                    fr: 'Jarret d\'agneau'
                },

                description: {
                    en: ' Lamb shank, roasted then braised, along with two sides, home-made bread and half-salted butter.',
                    fr: "Jarret d'agneau rôti puis mijoté, servi avec deux accompagnements au choix, pain maison et beurre demi-sel."
                },
                image: '../../../../assets/images/oiseaux-de-passage/oiseaux-de-passage-lamb.jpg',
                restaurantSlug: RestaurantSlug.OiseauxDePassage
            }
        );
        this.cafeVitaFavorites = this.generateFeatures(
            {
                title: {
                    en: 'Smoked Ham Panini',
                    fr: 'Sandwich panini jambon fumé',
                },
                description: {
                    en: 'Sauce, red onion, tomato, lettuce, cheese, smoked ham on ciabatta bread, all organic ingredients',
                    fr: 'Sauce, onion rouge, tomate, laitue, fromage, jambon fumé sur pain ciabatta tout bio'
                },
                image: '../../../../assets/images/cafe-vita/cafe-vita-sandwich.jpg',
                restaurantSlug: RestaurantSlug.CafeVita
            },
            {
                title: {
                    en: 'Lungo',
                    fr: 'Double espresso'
                },
                description: {
                    en: '',
                    fr: ""
                },
                image: '../../../../assets/images/cafe-vita/cafe-vita-coffee.jpg',
                restaurantSlug: RestaurantSlug.CafeVita
            },
            {
                title: {
                    en: 'Bagel with Egg, Cheddar',
                    fr: 'Bagel oeuf deluxe & cheddar'
                },

                description: {
                    en: 'Sesame seed bagel, egg, smoked ham, cheese, tomato, lettuce',
                    fr: "Bagel de graines de sésame, oeuf, jambon fumé, fromage, tomate, laitue"
                },
                image: '../../../../assets/images/cafe-vita/cafe-vita-bagel.jpg',
                restaurantSlug: RestaurantSlug.CafeVita
            }
        );
        this.burgerBrosFavorites = this.generateFeatures(
            {
                title: {
                    en: 'BurgerPlexe Suprême',
                    fr: 'BurgerPlexe Suprême',
                },
                description: {
                    en: 'Sauce, red onion, tomato, lettuce, cheese, smoked ham on ciabatta bread, all organic ingredientsDouble decker burger with our famous beef burger and signature Crispy Chicken! Garnished with Sweet BurgerBros sauce, cheddar cheese, lettuce, tomatoes, onions and pickles.',
                    fr: 'Burger à deux étages avec notre fameux burger de bœuf et Poulet Croustillant signature! Garni avec la sauce BurgerBros douce, fromage cheddar laitue, tomates, oignons et de cornichons.'
                },
                image: '../../../../assets/images/burger-bros/burger-bros-signature.jpg',
                restaurantSlug: RestaurantSlug.BurgerBros
            },
            {
                title: {
                    en: 'Swiss Burger',
                    fr: 'Burger suisse'
                },
                description: {
                    en: 'Swiss cheese, caramelized onions, sauteed mushrooms, BurgerBros sauce (mild), lettuce, tomato, and pickles.',
                    fr: "Fromage suisse, champignons sautés, oignons caramelisés, sauce Burger Bros douce, roquette, tomate et cornichons."
                },
                image: '../../../../assets/images/burger-bros/burger-bros-suisse.jpg',
                restaurantSlug: RestaurantSlug.BurgerBros
            },
            {
                title: {
                    en: 'Philly Cheese Steak Beef',
                    fr: 'Sousmarin Philly Cheese Steak Boeuf 7'
                },

                description: {
                    en: 'Slices of ribeye tasty steak, sautéed onions and bell peppers, rich mozzarella cheese, Burger Bros mild sauceSesame seed bagel, egg, smoked ham, cheese, tomato, lettuce',
                    fr: "Steak ribeye tranches savoureau, oignons et piments douces grillé, fromage mozza riche, sauce Burger Bros douce"
                },
                image: '../../../../assets/images/burger-bros/burger-bros-philly.jpg',
                restaurantSlug: RestaurantSlug.BurgerBros
            }
        );
        this.juliansFavorites = this.generateFeatures(
            {
                title: {
                    en: 'The Beast',
                    fr: 'La bête',
                },
                description: {
                    en: 'Crispy panko style fried chicken, honey, Julian\'s coleslaw, and sriracha spicy mayonnaise in a brioche roll.',
                    fr: 'Poulet frit, miel, salade de chou Julian\'s, mayo épicé au sriracha dans un pain brioché '
                },
                image: '../../../../assets/images/julians/julians-fried-chicken.jpg',
                restaurantSlug: RestaurantSlug.Julians
            },
            {
                title: {
                    en: 'Avocado Tartine',
                    fr: 'Tartine avocat'
                },
                description: {
                    en: 'Chunky avocado spread, tomato salad, ricotta cheese with basil, squash and sunflower seeds,olive oil, salt flakes',
                    fr: "Ecrasé d'avocat, salade de tomate, ricotta au basilic, graines de courge et tournesol, huile d'olive, fleur sel."
                },
                image: '../../../../assets/images/julians/julians-avocado.jpg',
                restaurantSlug: RestaurantSlug.Julians
            },
            {
                title: {
                    en: 'La Douce with Chicken',
                    fr: 'La douce au poulet'
                },

                description: {
                    en: 'Chicken, Lettuce mix, cubes of roasted sweet potato, cheddar, caramelized and marinated onions, crispy sweet potato chips and tahini dressing.',
                    fr: "Poulet, Mélange de laitue, cubes de patates douces rôties, cheddar, oignons caramélisés et marinés , croustilles de patates douces et vinaigrette tahini."
                },
                image: '../../../../assets/images/julians/julians-douce.jpg',
                restaurantSlug: RestaurantSlug.Julians
            }
        );
        this.spiceBrosFavorites = this.generateFeatures(
            {
                title: {
                    en: 'Butter Chicken Bowl',
                    fr: 'Bol de poulet au beurre',
                },
                description: {
                    en: 'All our Bowls come topped with Coleslaw, cucumber, tomatoes & carrots. We also have the option of adding Lettuce, Corn, Pickles, Banana Peppers, black olives & Onions at no extra charge.',
                    fr: 'Tous nos bols sont garnis de salade de chou, de concombre, de tomates et de carottes. Nous avons également la possibilité d\'ajouter de la laitue, du maïs, des cornichons, des piments de banane, des olives noires et des oignons, sans frais supplémentaires.'
                },
                image: '../../../../assets/images/spicebros/spicebros-bowl.jpg',
                restaurantSlug: RestaurantSlug.SpiceBros
            },
            {
                title: {
                    en: 'The Sloppy',
                    fr: 'Le Sloppy'
                },
                description: {
                    en: 'Pickles, Pickled Onions, Fried Chicken, Coleslaw, Lettuce & Scorpion Sauce + Butter Chicken Sauce.',
                    fr: "Cornichons, oignons marinés, gros morceau de poulet frit, salade de chou, laitue et sauce scorpion + sauce de poulet au beurre."
                },
                image: '../../../../assets/images/spicebros/spicebros-sloppy.jpg',
                restaurantSlug: RestaurantSlug.SpiceBros
            },
            {
                title: {
                    en: 'Butter Chicken Frankie',
                    fr: 'Poulet au beurre Frankie'
                },

                description: {
                    en: 'All our Frankie come topped with Coleslaw, cucumber, tomatoes & carrots. We also have the option of adding Lettuce, Corn, Pickles, Banana Peppers, Black olives & Onions at no extra charge.',
                    fr: "Tous nos Frankie sont garnis de salade de chou, de concombre, de tomates et de carottes. Nous avons également la possibilité d'ajouter de la laitue, du maïs, des cornichons, des piments de banane, des olives noires et des oignons, sans frais supplémentaires."
                },
                image: '../../../../assets/images/spicebros/spicebros-frankie.jpg',
                restaurantSlug: RestaurantSlug.SpiceBros
            }
        );
        this.santaLuciaFavorites = this.generateFeatures(
            {
                title: {
                    en: 'Pizza Amatriciana',
                    fr: 'Pizza Amatriciana',
                },
                description: {
                    en: 'Ham, bacon, onions, tomato sauce and mozzarella, cooked in a wood burning oven!',
                    fr: 'Jambon, oignons, bacon, sauce tomate et mozzarella, cuite au four à bois.'
                },
                image: '../../../../assets/images/santa-lucia/santa-lucia-amatriciana.jpg',
                restaurantSlug: RestaurantSlug.SantaLucia
            },
            {
                title: {
                    en: 'Fettuccine Pescatore',
                    fr: 'Fettuccine Pescatore'
                },
                description: {
                    en: 'Seafood, white wine with a choice of red or white sauce.',
                    fr: "Fruits de mer, vin blanc et choix de sauce rouge ou blanche."
                },
                image: '../../../../assets/images/santa-lucia/santa-lucia-fettucine-pescatore.jpg',
                restaurantSlug: RestaurantSlug.SantaLucia
            },
            {
                title: {
                    en: 'Combo del Chef',
                    fr: 'Combo del Chef'

                },

                description: {
                    en: 'Veal cutlet, jumbo shrimps, linguine napolitana.',
                    fr: "Escalope de veau, crevettes jumbo, linguine napolitana."
                },
                image: '../../../../assets/images/santa-lucia/carousel/santa-lucia-dish-12.jpg',
                restaurantSlug: RestaurantSlug.SantaLucia
            }
        );

        this.dakarFavorites = this.generateFeatures(
            {
                title: {
                    en: 'Kandia Soup',
                    fr: 'Soupe kandia'
                },
                description: {
                    en: 'Okra sauce with seafood and white rice',
                    fr: 'Sauce gombo avec les fruits de mer et le riz blanc',
                },
                image: '../../../../assets/images/dakar/dakar-kandia.jpeg',
                restaurantSlug: RestaurantSlug.Dakar
            },
            {
                title: {
                    en: 'Dibi Chicken',
                    fr: 'Dibi poulet'
                },
                description: {
                    en: 'Pieces of grilled chicken served with plantain, chili and mustard sauce',
                    fr: "Morceaux de poulet grillé accompagnés de banane plantain sauce piment et moutarde"
                },
                image: '../../../../assets/images/dakar/dakar-dibi.jpeg',
                restaurantSlug: RestaurantSlug.Dakar
            },
            {
                title: {
                    en: 'Yassa Fish',
                    fr: 'Yassa poisson'
                },

                description: {
                    en: 'Braised fish accompanied by white rice, caramelized onion, lemon and pepper',
                    fr: "Poisson braisé accompagné de riz blanc, oignon caramelisé, citron et piment"
                },
                image: '../../../../assets/images/dakar/dakar-yassa.jpeg',
                restaurantSlug: RestaurantSlug.Dakar
            }
        );
        this.oFourFavorites = this.generateFeatures(
            {
                title: {
                    en: 'Soujouk & Cheese Manouche',
                    fr: 'Manouche Soujouk au fromage',
                },
                description: {
                    en: 'Ground beef mixed with Armenian spices (called soujouk), spread, and baked with a mixture of cheese, topped with tomatoes and mayonnaise.',
                    fr: 'Bœuf haché mélangé à des épices arméniennes (appelé soujouk), étalé et cuit au four avec un mélange de fromage, garni de tomates et de mayonnaise.'
                },
                image: '../../../../assets/images/o-four/menu-favorites/o-four-menu-favorites-dish-1.png',
                restaurantSlug: RestaurantSlug.oFour
            },
            {
                title: {
                    en: 'The Famous Chicken Sandwich',
                    fr: 'Sandwich Le fameux poulet'
                },
                description: {
                    en: 'Grilled chicken breast with a mixture of melted cheese, iceberg lettuce, tomatoes, pickles, and garlic sauce.',
                    fr: 'Poitrine de poulet grillée avec un mélange de fromage fondu, laitue iceberg, tomates, cornichons et sauce à l’ail.'
                },
                image: '../../../../assets/images/o-four/menu-favorites/o-four-menu-favorites-dish-2.png',
                restaurantSlug: RestaurantSlug.oFour
            },
            {
                title: {
                    en: 'Zaatar & Cheese',
                    fr: 'Zaatar & fromage'
                },

                description: {
                    en: 'A perfectly balanced meal, a mixture of zaatar and cheese, melted, and cooked on a fresh dough.',
                    fr: 'Un repas parfaitement équilibré, un mélange de zaatar et de fromage, fondu et cuit sur une pâte fraîche.'
                },
                image: '../../../../assets/images/o-four/menu-favorites/o-four-menu-favorites-dish-3.png',
                restaurantSlug: RestaurantSlug.oFour
            }
        );
        this.karinFavorites = this.generateFeatures(
            {
                title: {
                    en: 'House Sashimi Mix (15pcs)',
                    fr: 'Combo Sushi Maki',
                },
                description: {
                    en: 'Karin’s chef special today’s fresh sashimi assorted! (15 pcs)',
                    fr: 'Karin chef spécial sashimi frais d\'aujourd\'hui assorti! (15 pièces)'
                },
                image: '../../../../assets/images/karin/karin-house-sashimi.jpeg',
                restaurantSlug: RestaurantSlug.Karin
            },
            {
                title: {
                    en: 'Karin Bowl (fish)',
                    fr: 'Bol Karin'
                },
                description: {
                    en: 'Rice bowl with fresh cut salmon and tuna sashimi and fresh vege salad. Served with homemade spicy & tasty sauce.',
                    fr: "Bol de riz avec sashimi de saumon et de thon frais coupé et salade de légumes frais. Servi avec sauce épicée et savoureuse maison."
                },
                image: '../../../assets/images/karin/karin-bol.jpg',
                restaurantSlug: RestaurantSlug.Karin
            },
            {
                title: {
                    en: 'Dragonballs (Takoyaki)',
                    fr: 'Boules de dragon (Takoyaki)'
                },

                description: {
                    en: '',
                    fr: ""
                },
                image: '../../../assets/images/karin/karin-dragonballs.jpg',
                restaurantSlug: RestaurantSlug.Karin
            }
        );
        this.marcheMaremaFavorites = this.generateFeatures(
            {
                title: {
                    en: 'Yassa Guinar',
                    fr: 'Yassa Guinar',
                },
                description: {
                    en: 'Braised chicken yassa',
                    fr: 'Yassa au poulet braisé'
                },
                image: '../../../../assets/images/marche-marema/marche-marema-yassa.jpeg',
                restaurantSlug: RestaurantSlug.MarcheMarema
            },
            {
                title: {
                    en: 'Thiébou Dieune',
                    fr: 'Thiébou dieune'
                },
                description: {
                    en: 'Fish rice with tomato',
                    fr: "Riz au poisson à la tomate"
                },
                image: '../../../assets/images/marche-marema/marche-marema-thiebou.jpeg',
                restaurantSlug: RestaurantSlug.MarcheMarema
            },
            {
                title: {
                    en: 'Yassa dieune',
                    fr: 'Yassa dieune'
                },

                description: {
                    en: 'Yassa fish braised with charcoal',
                    fr: "Yassa poisson braisé au charbon"
                },
                image: '../../../assets/images/marche-marema/marche-marema-dieune.jpeg',
                restaurantSlug: RestaurantSlug.MarcheMarema
            }
        );
        this.elephantThaiFavorites = this.generateFeatures(
            {
                title: {
                    en: 'Shrimps Tom Yum Soup',
                    fr: 'Soupe Tom Yum aux crevettes',
                },
                description: {
                    en: 'Spicy lemongrass chicken broth with noodles.',
                    fr: 'Bouillon piquant au poulet à la citronnelle avec nouilles.'
                },
                image: '../../../../assets/images/elephant-thai/carousel/elephant-thai-dish-3.jpg',
                restaurantSlug: RestaurantSlug.ElephantThai
            },
            {
                title: {
                    en: 'Chicken General Tao',
                    fr: 'General Tao aux poulet'
                },
                description: {
                    en: 'Breaded chicken, pepper, onion, carrot, pineapple, tomato and sweet and sour sauce',
                    fr: "Poulet pané, poivron, oignon, carotte, ananas, tomate et sauce aigre-douce"
                },
                image: '../../../assets/images/elephant-thai/carousel/elephant-thai-dish-4.jpg',
                restaurantSlug: RestaurantSlug.ElephantThai
            },
            {
                title: {
                    en: 'Chicken Pad Sew',
                    fr: 'Pad Sew aux poulet'
                },

                description: {
                    en: 'Large rice noodles, egg, chinese broccoli',
                    fr: "Nouilles de riz larges, oeuf, brocoli chinois"
                },
                image: '../../../assets/images/elephant-thai/carousel/elephant-thai-dish-1.jpg',
                restaurantSlug: RestaurantSlug.ElephantThai
            }
        );
        this.sushiyoFavorites = this.generateFeatures(
            {
                title: {
                    en: 'Assorted Sashimi',
                    fr: 'Sashimi assorti',
                },
                description: {
                    en: '16 pcs of raw fish and seafood',
                    fr: '16 mcx de poissons crus et de fruit de mer'
                },
                image: '../../../../assets/images/sushiyo/sushiyo-shashimi.jpg',
                restaurantSlug: RestaurantSlug.Sushiyo
            },
            {
                title: {
                    en: 'Salmon Poké',
                    fr: 'Poké au saumon'
                },
                description: {
                    en: 'Ingredients are subject to change without notice). Rice, tempura, tobiko avocado, lettuce, green onion, chili. carrot, cucumber, ponzu sauce, sesame and teriyaki, Japanese spices, spicy mayo. sesame, nori',
                    fr: "(Ingredients peuvent être changés sans préavis). Riz, tempura, avocat tobiko, laitue, oignon vert, piment. carotte, concombre, sauce ponzu, sésame et teriyaki, épices japonaises, mayo épicée et japonais. sésame, nori"
                },
                image: '../../../assets/images/sushiyo/sushiyo-poke.jpg',
                restaurantSlug: RestaurantSlug.Sushiyo
            },
            {
                title: {
                    en: 'Syake',
                    fr: 'Syake'
                },

                description: {
                    en: 'Raw Salmon',
                    fr: "Saumon cru"
                },
                image: '../../../assets/images/sushiyo/carousel/sushiyo-dish-15.JPG',
                restaurantSlug: RestaurantSlug.Sushiyo
            }
        );
        this.boiteVeganeFavorites = this.generateFeatures(
            {
                title: {
                    en: 'The Big Gad',
                    fr: 'Le Big Gad',
                },
                description: {
                    en: 'The Big Gad a burger like no other! Our burger is made with our special recipe with delicious 100% plant-based Impossible Meat. Contains: Impossible Meat, Big Gad Sauce, salad, onion, pickles and avocado.',
                    fr: 'Le Big Gad est fait avec notre recette spéciale avec de la délicieuse viande Impossible 100% à base de plantes. The Big Gad a burger like no other. Contient: Viande Impossible, Sauce Big Gad, salade, oignon, cornichons et avocat.'
                },
                image: '../../../../assets/images/boite-vegane/boite-vegane-burger.jpg',
                restaurantSlug: RestaurantSlug.BoiteVegane
            },
            {
                title: {
                    en: 'Poutine Kaz',
                    fr: 'Poutine Kaz'
                },
                description: {
                    en: 'A delicious poutine with an onion ring, fried onions, jalapeno and impossible kentucky breaded burger balls inside.',
                    fr: "Une délicieuse poutine avec à l'intérieur rondelle d’oignon, oignons frits, jalapeno et des boules impossible burger pannées à la kentucky."
                },
                image: '../../../assets/images/boite-vegane/boite-vegane-poutine.jpg',
                restaurantSlug: RestaurantSlug.BoiteVegane
            },
            {
                title: {
                    en: '6 Mac & Cheese Fried Balls',
                    fr: "6 boules frites Mac & Cheese"
                },

                description: {
                    en: 'Do you like the Mac and Cheese in life? This side dish is the pinnacle of mac and cheese. Mac balls are served with a slightly spicy sauce that perfectly accompanies the taste of our breadcrumbs.',
                    fr: 'Tu aimes le Macaroni au fromage dans la vie? Cet accompagnement est l\'apogée du macaroni au fromage. Les boules de Mac sont servi avec une sauce un peu épicé qui accompagne parfaitement le goût de notre panure.'
                },
                image: '../../../assets/images/boite-vegane/boite-vegane-cheese.jpg',
                restaurantSlug: RestaurantSlug.BoiteVegane
            }
        );
        this.heroBurgersFavorites = this.generateFeatures(
            {
                title: {
                    en: 'Signature Cheeseburger',
                    fr: 'Signature Cheeseburger',
                },
                description: {
                    en: '4oz patty, real cheddar, relish, tomato, onion and hero sauce.',
                    fr: '4oz patty, real cheddar, relish, tomato, onion and hero sauce.'
                },
                image: '../../../../assets/images/hero-burgers/hero-burgers-signature-cheeseburger.jpeg',
                restaurantSlug: RestaurantSlug.HeroBurgers
            },
            {
                title: {
                    en: 'Blue Moon Burger',
                    fr: 'Blue Moon Burger'
                },
                description: {
                    en: 'Angus beef patty, blue cheese, portobello mushrooms, lettuce and tomato on a sesame poppy seed bun.',
                    fr: "Angus beef patty, blue cheese, portobello mushrooms, lettuce and tomato on a sesame poppy seed bun."
                },
                image: '../../../assets/images/hero-burgers/hero-burgers-blue-moon-burger.jpeg',
                restaurantSlug: RestaurantSlug.HeroBurgers
            },
            {
                title: {
                    en: 'Spicy Plant-Based Combo',
                    fr: "Spicy Plant-Based Combo"
                },

                description: {
                    en: 'Plant-Based Patty, Sauteed Onions, Portobello Mushrooms, jalapenos, lettuce, Hot sauce, Multigrain Bun, Drink',
                    fr: 'Plant-Based Patty, Sauteed Onions, Portobello Mushrooms, jalapenos, lettuce, Hot sauce, Multigrain Bun, Drink'
                },
                image: '../../../assets/images/hero-burgers/hero-burgers-plant-based-combo.jpeg',
                restaurantSlug: RestaurantSlug.HeroBurgers
            }
        );
        this.jrsCafeFavorites = this.generateFeatures(
            {
                title: {
                    en: 'Siomai (12 Pcs)',
                    fr: 'Siomai (12 Pcs)',
                },
                description: {
                    en: '',
                    fr: ''
                },
                image: '../../../../assets/images/jrs-cafe/jrs-cafe-siomai.jpg',
                restaurantSlug: RestaurantSlug.JrsCafe
            },
            {
                title: {
                    en: 'Special Lomi',
                    fr: 'Spécial Lomi'
                },
                description: {
                    en: 'Filipino Style noodle soup with crispy pork belly ,pork liver, fish balls,squid balls, boiled egg, green onion & toasted garlic',
                    fr: "Soupe de nouilles de style philippin avec poitrine de porc croustillante, foie de porc, boulettes de poisson, boules de calmar, œuf à la coque, oignon vert et ail grillé"
                },
                image: '../../../assets/images/jrs-cafe/carousel/jrs-cafe-dish-1.jpg',
                restaurantSlug: RestaurantSlug.JrsCafe
            },
            {
                title: {
                    en: 'Beef Chao Fan with Spring Rolls',
                    fr: "Boeuf Chao Fan avec Rouleaux de Printemps"
                },

                description: {
                    en: '',
                    fr: ''
                },
                image: '../../../assets/images/jrs-cafe/carousel/jrs-cafe-dish-4.jpg',
                restaurantSlug: RestaurantSlug.JrsCafe
            }
        );
        this.cafeResonanceFavorites = this.generateFeatures(
            {
                title: {
                    en: 'Avocado Roll Bowl',
                    fr: 'Bol Rouleau d\'Avocat',
                },
                description: {
                    en: 'Deconstructed avocado roll: guacamole, cucumber, pickled carrot, pickled ginger, sprouts, nori, sesame seeds, brown rice & sides of gluten-free tamari and rice vinegar.',
                    fr: 'Rouleau d\'Avocat déconstruit: guacamole, concombres, carottes marinées, gingembre mariné, graines de sésame, pousses, nori, riz brun et à côtés de tamari sans gluten et vinaigre de riz.'
                },
                image: '../../../../assets/images/cafe-resonance/cafe-resonance-avocado.jpg',
                restaurantSlug: RestaurantSlug.CafeResonance
            },
            {
                title: {
                    en: 'Pesto-Tomato Grilled Cheez Sandwich',
                    fr: 'Sandwich Grilled Cheez Pesto-Tomate'
                },
                description: {
                    en: 'Vegan sunflower "mozzarella", pesto, tomato, spinach.',
                    fr: "«Mozzarella» vegan à base de graines de tournesol, pesto, tomate, épinards."
                },
                image: '../../../assets/images/cafe-resonance/cafe-resonance-pesto.jpeg',
                restaurantSlug: RestaurantSlug.CafeResonance
            },
            {
                title: {
                    en: 'Moroccan Lentil Dal',
                    fr: "Dal aux lentilles à la marocaine"
                },

                description: {
                    en: 'Split lentil stew with Moroccan-style spices. ',
                    fr: 'Ragoût aux lentilles avec épices marocaines.'
                },
                image: '../../../assets/images/cafe-resonance/cafe-resonance-lentil.jpeg',
                restaurantSlug: RestaurantSlug.CafeResonance
            }
        );

        this.ubiSushiFavorites = this.generateFeatures(
            {
                title: {
                    en: 'Chicken Katsu',
                    fr: 'Table d\'hôte Poulet Katsu',
                },
                description: {
                    en: 'Starter of your choice, Katsu crispy chicken, steamed rice and beverage of your choice',
                    fr: 'Entrée au choix, Poulet croustillant katsu, riz à la vapeur et breuvage au choix'
                },
                image: '../../../../assets/images/ubi-sushi/ubi-sushi-katsu.jpeg',
                restaurantSlug: RestaurantSlug.UbiSushi
            },
            {
                title: {
                    en: 'Salmon Poké',
                    fr: 'Poké Saumon'
                },
                description: {
                    en: 'Salmon, caviar, served on the basis of the poké bowl.',
                    fr: "Saumon, caviar, servie sur la base du poké bol. "
                },
                image: '../../../assets/images/ubi-sushi/ubi-sushi-poke.jpeg',
                restaurantSlug: RestaurantSlug.UbiSushi
            },
            {
                title: {
                    en: 'Sriracha Beef plate',
                    fr: "Plat Bœuf sriricha"
                },

                description: {
                    en: 'with steamed rice',
                    fr: 'with steamed rice'
                },
                image: '../../../assets/images/ubi-sushi/ubi-sushi-beef.jpeg',
                restaurantSlug: RestaurantSlug.UbiSushi
            }
        );
        this.melroseFavorites = this.generateFeatures(
            {
                title: {
                    en: 'Spicy Honey Pizza',
                    fr: 'Pizza au miel épicé',
                },
                description: {
                    en: 'Soppressata, fior di latte, red peppers and spicy honey.',
                    fr: 'Soppressata, fior di latte, poivrons rouge et miel épicée.'
                },
                image: '../../../../assets/images/melrose/melrose-honey.jpg',
                restaurantSlug: RestaurantSlug.Melrose
            },
            {
                title: {
                    en: 'Pizza Margherita',
                    fr: 'Pizza Margherita'
                },
                description: {
                    en: '',
                    fr: ""
                },
                image: '../../../assets/images/melrose/melrose-margherita.jpg',
                restaurantSlug: RestaurantSlug.Melrose
            },
            {
                title: {
                    en: 'Pizza proscuitto',
                    fr: "Pizza proscuitto"
                },

                description: {
                    en: 'Tomato sauce, fior di latte, proscuitto, arugula',
                    fr: 'Sauce tomate, fior di latte, proscuitto, roquette'
                },
                image: '../../../assets/images/melrose/carousel/melrose-dish-2.jpg',
                restaurantSlug: RestaurantSlug.Melrose
            }
        );
        this.obsceneVeganFavorites = this.generateFeatures(
            {
                title: {
                    en: 'The Obscene',
                    fr: 'Le Obscene',
                },
                description: {
                    en: 'Vegan American cheddar cheese, special sauce, lettuce, tomato, red onion, pickles, vegan mayo & homemade BBQ.',
                    fr: 'Fromage Américain et cheddar végétalien, sauce spéciale, mayonnaise végétalienne, laitue, tomate, oignon rouge, cornichons et sauce BBQ maison.'
                },
                image: '../../../../assets/images/obscene-vegan/carousel/obscene-vegan-dish-5.jpeg',
                restaurantSlug: RestaurantSlug.ObsceneVegan
            },
            {
                title: {
                    en: 'Cheesy Mushroom Burger',
                    fr: 'Burger au champignon fromage'
                },
                description: {
                    en: 'Vegan provolone, vegan garlic aioli, sauteed mushrooms, and sauteed. Includes fries.',
                    fr: "Provolone végétalien, aïoli à l'ail végétalien, champignons sautés, et sautés. Inclus des frites."
                },
                image: '../../../assets/images/obscene-vegan/carousel/obscene-vegan-dish-3.jpeg',
                restaurantSlug: RestaurantSlug.ObsceneVegan
            },
            {
                title: {
                    en: 'El Charro Burger',
                    fr: "El Charro Burger"
                },

                description: {
                    en: 'Guacamole, fried jalapenos, lettuce, tomato, grilled onions, and pepper cheese on potato roll with fries.',
                    fr: 'Guacamole, jalapenos frits, laitue, tomate, oignons grillés et fromage au poivre sur rouleau de pommes de terre avec frites.'
                },
                image: '../../../assets/images/obscene-vegan/carousel/obscene-vegan-dish-4.jpeg',
                restaurantSlug: RestaurantSlug.ObsceneVegan
            }
        );
        this.lestersDeliFavorites = this.generateFeatures(
            {
                title: {
                    en: 'Famous Old Fashioned Smoked Meat Sandwich',
                    fr: 'Sandwich la fameuse viande fumée à l\'ancienne',
                },
                description: {
                    en: '',
                    fr: ''
                },
                image: '../../../../assets/images/lesters-deli/carousel/lesters-deli-dish-2.jpg',
                restaurantSlug: RestaurantSlug.LestersDeli
            },
            {
                title: {
                    en: 'Smoked Meat Poutine',
                    fr: 'Poutine viande fumée'
                },
                description: {
                    en: 'The signature poutine with smoked meat chopped and piled on.',
                    fr: "La fameuse poutine avec viandé fumée et hachée empilée dessus."
                },
                image: '../../../assets/images/lesters-deli/carousel/lesters-deli-dish-1.jpg',
                restaurantSlug: RestaurantSlug.LestersDeli
            },
            {
                title: {
                    en: 'Club sandwich, fries and dill pickle',
                    fr: "Club sandwich, frites et cornichon"
                },

                description: {
                    en: 'Sliced smoked turkey breast, served with fries and dill pickles.',
                    fr: 'Poitrine de dinde fumee trancher, servis avec frites et cornichons.'
                },
                image: '../../../assets/images/lesters-deli/lesters-deli-club.jpg',
                restaurantSlug: RestaurantSlug.LestersDeli
            }
        );
        this.barrancoFavorites = this.generateFeatures(
            {
                title: {
                    en: 'Ceviche Barranco',
                    fr: 'Ceviche Barranco',
                },
                description: {
                    en: 'Fish of the day, leche de tigre amarillo, octopus, shrimp, red onions, coriander, amarillo julienne, choclo, cancha, fried calamari',
                    fr: 'Poisson du jour, leche de tigre amarillo, pieuvre, crevettes, oignons rouges, coriandre, julienne amarillo, choclo, cancha, calmars frits'
                },
                image: '../../../../assets/images/barranco/barranco-ceviche-barranco-500.jpg',
                restaurantSlug: RestaurantSlug.Barranco
            },
            {
                title: {
                    en: 'Salchipapa La Asada',
                    fr: 'Salchipapa La Asada',
                },
                description: {
                    en: 'Chicken, huacatay mayonnaise, green onions',
                    fr: 'Poulet, mayonnaise huacatay, oignons verts'
                },
                image: '../../../../assets/images/barranco/barranco-salchipapa-asada-500.jpg',
                restaurantSlug: RestaurantSlug.Barranco
            },
            {
                title: {
                    en: 'Machupicchu Burger',
                    fr: 'Buger La Machupicchu',
                },
                description: {
                    en: '2 x 5oz beef patties smash, lettuce, salsa criolla, pickles, cheddar cheese, monterray jack cheese, caretillera & huacatay aïoli',
                    fr: '2 x 5oz galettes de boeuf smash, laitue, salsa criolla, cornichons, cheddar, fromage monterray jack, caretillera et aïoli huacatay'
                },
                image: '../../../../assets/images/barranco/barranco-burger-machupicchu-500.jpeg',
                restaurantSlug: RestaurantSlug.Barranco
            },
        );
        this.petitSeoulFavorites = this.generateFeatures(
            {
                title: {
                    en: 'Soondubu Jjigae',
                    fr: 'Soondubu Jjigae',
                },
                description: {
                    en: 'Delicious combination of spicy and soft tofu, stew seafood, vegetables, raw egg, and a bowl of steamed rice',
                    fr: 'Délicieuse combinaison de ragoût de tofu doux et épicé avec des fruits de mer, des légumes, un oeuf cru, et un bol de riz cuit à la vapeur'
                },
                image: '../../../../assets/images/petit-seoul/menu-favorites/petit-seoul-soondubu-jjigae.jpg',
                restaurantSlug: RestaurantSlug.PetitSeoul
            },
            {
                title: {
                    en: 'Bulgogi Bibimbap',
                    fr: 'Bulgogi Bibimbap'
                },
                description: {
                    en: 'Marinated beef grilled with korean BBQ sauce',
                    fr: 'Boeuf mariné et grillé à la sauce coréennce BBQ'
                },
                image: '../../../assets/images/petit-seoul/menu-favorites/petit-seoul-bulgogi-bibimbap.jpg',
                restaurantSlug: RestaurantSlug.PetitSeoul
            },
            {
                title: {
                    en: 'Tongkatsu',
                    fr: 'Tongkatsu'
                },

                description: {
                    en: 'Deep fried pork cutlets with a salad and a bowl of rice',
                    fr: 'côtelettes de porc frites avec une salade et un bol de riz cuit à la vapeur'
                },
                image: '../../../assets/images/petit-seoul/menu-favorites/petit-seoul-tongkatsu.jpg',
                restaurantSlug: RestaurantSlug.PetitSeoul
            }
        );
        this.hootersFavorites = this.generateFeatures(
            {
                title: {
                    en: 'Hooters Style Original Wings',
                    fr: 'Ailes Originales Style Hooters'
                },
                description: {
                    en: 'Hooters\' classic wings that have been around for over 30 years. They\'re breaded by hand, and tossed in your choice of wing sauce.',
                    fr: 'Les ailes de poulet classiques de Hooters depuis 30 ans. Elles sont panées à la main, et mélangées avec la sauce de votre choix.'
                },
                image: '../../../../assets/images/hooters/menu-favorites/hooters-original-wings.jpg',
                restaurantSlug: RestaurantSlug.Hooters
            },
            {
                title: {
                    en: 'Full Rack of Ribs',
                    fr: 'Grille de Côtes'
                },
                description: {
                    en: 'Hooters\' famous ribs are slow-cooked, slathered with the guest\'s choice of Hooters signature sauces, and then put back on the flames to create a full-bodied caramelized flavor.',
                    fr: 'Les célèbres côtes de Hooters\' sont cuites lentement, arrosées de la sauce Hooters de votre choix, puis remises sur le feu pour créer une saveur caramélisée et corsée.'
                },
                image: '../../../../assets/images/hooters/menu-favorites/hooters-rack-ribs.jpg',
                restaurantSlug: RestaurantSlug.Hooters
            },
            {
                title: {
                    en: 'Chili Cheese Fries',
                    fr: 'Chili Cheese Fries',
                },
                description: {
                    en: 'The curly fries you all love covered with Hooters’ special chili and cheese sauce. The perfect side dish next to your wings!',
                    fr: 'Les frites que vous aimez tous sont recouvertes de la sauce spéciale chili et fromage de Hooters. L\'accompagnement parfait à côté de vos ailes!'
                },
                image: '../../../../assets/images/hooters/menu-favorites/hooters-chili-cheese-fries.jpg',
                restaurantSlug: RestaurantSlug.Hooters
            }
        );
        this.darbarFavorites = this.generateFeatures(
            {
                title: {
                    en: 'Saag Paneer',
                    fr: 'Saag Paneer',
                },
                description: {
                    en: 'Saagpaneer is a classic Indian dish prepared with cooked spinach and studded with soft cubes of homemade Paneer. A hearty yet velvety dish packed with all the nutrition and taste',
                    fr: 'Le Saag Paneer est un plat indien classique préparé avec des épinards cuits et parsemé de cubes mous de Paneer maison, un fromage doux. Un plat copieux et velouté, riche en nutriments au goût exquis'
                },
                image: '../../../../assets/images/darbar/darbar-saag-paneer.jpg',
                restaurantSlug: RestaurantSlug.Darbar
            },
            {
                title: {
                    en: 'Murgh Tandoori',
                    fr: 'Murgh Tandoori'
                },

                description: {
                    en: 'Tandoori chicken served on a bed of onion, green, and red peppers marinated in fresh lemon, accompanied by a mint sauce.',
                    fr: "Poulet tandoori servi sur un lit d'oignon, piment vert et rouge mariné dans du citron frais, accompagné d'une sauce à la menthe."
                },
                image: '../../../assets/images/darbar/darbar-murgh-tandoori.jpg',
                restaurantSlug: RestaurantSlug.Darbar
            },
            {
                title: {
                    en: 'Butter Chicken',
                    fr: 'Poulet Au Beurre'
                },
                description: {
                    en: 'A Darbar-signature, this rich and velvety sauce and tender chicken melts in your mouth – pretty much like butter.A side of Pulao Rice and Buttered Garlic Naan is all that’s needed to please guests over this sumptuous meal',
                    fr: 'Un mets à la signature Darbar : du poulet tendre nappé d’une sauce riche et veloutée qui fondra dans votre bouche comme du beurre. S’accompagne bien du riz Pulao et du beurre et à l’ail Naan. Ce somptueux repas contient tout ce que vous voulez pour vous satisfaire'
                },
                image: '../../../assets/images/darbar/darbar-butter-chicken.jpeg',
                restaurantSlug: RestaurantSlug.Darbar
            }
        );
        this.jardinPetrosFavorites = this.generateFeatures(
            {
                title: {
                    en: 'Chicken Brochette',
                    fr: 'Brochette de Poulet',
                },
                description: {
                    en: 'All dishes accompanied by rice, market fresh vegetables and oven roasted lemon potatoes.',
                    fr: 'Tous les plats sont accompagnés de riz, légumes frais et pommes de terres rôties.'
                },
                image: '',
                restaurantSlug: RestaurantSlug.JardinPetros
            },
            {
                title: {
                    en: 'Petros Special',
                    fr: 'Spécial Petros'
                },

                description: {
                    en: 'Lightly fried zucchini chips, served with tzatziki.',
                    fr: 'Courgettes légèrement pannées, servies avec tzatziki'
                },
                image: '',
                restaurantSlug: RestaurantSlug.JardinPetros
            },
            {
                title: {
                    en: 'Greek Salad',
                    fr: 'Salade Grecque'
                },
                description: {
                    en: 'Greenhouse tomatoes, cucumber, peppers, onions and feta cheese.',
                    fr: 'Tomates de serre, concombres, poivrons, oignons et fromage feta.'
                },
                image: '',
                restaurantSlug: RestaurantSlug.JardinPetros
            }
        );
        this.chezAnnaFavorites = this.generateFeatures(
            {
                title: {
                    en: 'Schnitzel',
                    fr: 'Schnitzel',
                },
                description: {
                    en: 'Chicken, Pork or Eggplant Schnitzel - Served with your choice of rice or potatoes and a side of vegetable ragout.',
                    fr: 'Escalope de poulet, porc ou d’aubergine - Servi avec le choix de riz ou patates et un ragout de légumes à la hongroise.'
                },
                image: '../../../assets/images/chez-anna/menu-favourites/schnitzel-500.jpeg',
                restaurantSlug: RestaurantSlug.ChezAnna
            },
            {
                title: {
                    en: 'Beef Goulash',
                    fr: 'Goulash au Boeuf'
                },

                description: {
                    en: 'Slow cooked beef and paprika stew. Served with Hungarian Spaetzel.',
                    fr: 'Ragoût de bœuf et de paprika cuit lentement. Sevi avec Spaetzel Hongrois.'
                },
                image: '../../../assets/images/chez-anna/menu-favorites/beef-goulash-500.jpeg',
                restaurantSlug: RestaurantSlug.ChezAnna
            },
            {
                title: {
                    en: 'Blue Cord',
                    fr: 'Cordon Bleu'
                },
                description: {
                    en: 'Breaded pork cutlets stuffed with blue cheese and ham. Served with your choice of mashed potatoes or rice with a hungarian vegetable ragout.',
                    fr: 'Escalopes de porc farcis de fromage bleu et de jambon. Servi avec le choix de riz ou patates et un ragout de légumes à la hongroise.'
                },
                image: '../../../assets/images/chez-anna/menu-favorites/cordon-bleu-500.jpeg',
                restaurantSlug: RestaurantSlug.ChezAnna
            }
        );
        this.kroodFavorites = this.generateFeatures(
            {
                title: {
                    en: 'Classique Krood',
                    fr: 'Classique Krood',
                },
                description: {
                    en: 'Beef, scallion, red onion, sweet pickle, dill pickle, parmesan, krood mayo.',
                    fr: 'Boeuf, oignon vert, oignon rouge, cornichon sucré, cornichon à l’aneth, parmesan.'
                },
                image: '../../../assets/images/krood/menu-favorites/classique-krood.jpg',
                restaurantSlug: RestaurantSlug.Krood
            },
            {
                title: {
                    en: 'Ton Thon',
                    fr: 'Ton Thon'
                },

                description: {
                    en: 'Tuna, scallion, red onion, avocado, pomegranata, panko, asian mayo.',
                    fr: 'Thon, oignon vert, oignon rouge, avocat, pomme grenade, panko, mayo asiatique.'
                },
                image: '../../../assets/images/krood/menu-favorites/krood-ton-thon.jpg',
                restaurantSlug: RestaurantSlug.Krood
            },
            {
                title: {
                    en: 'Golden Hour',
                    fr: 'Cordon Bleu'
                },
                description: {
                    en: 'White rice, lettuce, salmon, cucumber edamame, avocado, water melon radish, scallion, fried onion, sesame seed, wakame, spicy mayo, classic poke sauce.',
                    fr: 'Riz blanc, laitue, saumon, concombre edamame, avocat, radis melon d’eau, oignon vert, wakame, oignon frit, sésame, mayo épicée, sauce poké classique.'
                },
                image: '../../../assets/images/krood/menu-favorites/golden-hour.jpg',
                restaurantSlug: RestaurantSlug.Krood
            }
        );
        this.maynardFavorites = this.generateFeatures(
            {
                title: {
                    en: 'Poutine',
                    fr: 'Poutine',
                },
                description: {
                    en: 'Homemade fries, vegan poutine sauce and \"Vegcheese\" cheese curds.',
                    fr: 'Frites maison, sauce à poutine végane et fauxmage en grains « Vegcheese ».'
                },
                image: '../../../assets/images/maynard/menu-favorites/maynard-menu-favorite-dish-1.jpeg',
                restaurantSlug: RestaurantSlug.Maynard
            },
            {
                title: {
                    en: 'Tofu Naville',
                    fr: 'Tofu Naville'
                },

                description: {
                    en: 'Spicy fried tofu sandwich with homemade vegan mayo and pickles.',
                    fr: 'Sandwich épicé au tofu frit avec mayonnaise végane maison et cornichons.'
                },
                image: '../../../assets/images/maynard/menu-favorites/maynard-menu-favorite-dish-2.jpeg',
                restaurantSlug: RestaurantSlug.Maynard
            },
            {
                title: {
                    en: 'Wrap Ranch',
                    fr: 'Wrap Ranch'
                },
                description: {
                    en: 'Crispy spicy tofu, topped with a homemade vegan ranch dressing, lettuce & tomatoes.',
                    fr: 'Wrap végane au tofu frit, sauce ranch maison, tomates et laitue.'
                },
                image: '../../../assets/images/maynard/menu-favorites/maynard-menu-favorite-dish-3.jpeg',
                restaurantSlug: RestaurantSlug.Maynard
            }
        );
        this.mFourBurritosFavorites = this.generateFeatures(
            {
                title: {
                    en: 'Barbacoa Beef Burrito',
                    fr: 'Burrito au boeuf à la Barbacoa'
                },
                description: {
                    en: 'Tortilla, rice, beans, salsa, sour cream, and cheese.',
                    fr: 'Tortilla, riz, haricots, salsa, crème aigre et fromage.'
                },
                image: '../../../assets/images/m4-burritos/menu-favorites/mfourburritos-menu-favorite-dish-1.jpg',
                restaurantSlug: RestaurantSlug.MFourBurritos
            },
            {
                title: {
                    en: 'Carnitas Tacos',
                    fr: 'Carnitas Tacos'
                },
                description: {
                    en: 'Tortilla, beans, salsa, sour cream, and cheese.',
                    fr: 'Tortilla, haricots, salsa, crème aigre et fromage.'
                },
                image: '../../../assets/images/m4-burritos/menu-favorites/mfourburritos-menu-favorite-dish-2.jpg',
                restaurantSlug: RestaurantSlug.MFourBurritos
            },
            {
                title: {
                    en: 'Chicken Bowl',
                    fr: 'Bol Poulet'
                },
                description: {
                    en: 'Rice, beans, salsa, sour cream, and cheese.',
                    fr: 'Riz, haricots, salsa, crème aigre et formages.'
                },
                image: '../../../assets/images/m4-burritos/menu-favorites/mfourburritos-menu-favorite-dish-3.jpg',
                restaurantSlug: RestaurantSlug.MFourBurritos
            }
        );
        this.bocadilloFavorites = this.generateFeatures(
            {
                title: {
                    en: 'Pabellón Especial',
                    fr: 'Pabellón Especial'
                },
                description: {
                    en: 'Shredded beef, black beans, avocado, white fresh cheese and fried plantains',
                    fr: 'Boeuf effiloché, haricots noirs, avocat, fromage frais et plantain frits'
                },
                image: '../../../assets/images/bocadillo/menu-favorites/bocadillo-menu-favorite-dish-1.jpg',
                restaurantSlug: RestaurantSlug.Bocadillo
            }, {
                title: {
                    en: 'Empanadas Mechanda',
                    fr: 'Empanadas Mechanda'
                },
                description: {
                    en: 'Shredded Beef',
                    fr: 'Boeuf effiloché'
                },
                image: '../../../assets/images/bocadillo/menu-favorites/bocadillo-menu-favorite-dish-2.jpg',
                restaurantSlug: RestaurantSlug.Bocadillo
            }, {
                title: {
                    en: 'Tequeños',
                    fr: 'Tequeños'
                },
                description: {
                    en: '4 Cheese sticks, guava, chorizo and fried chocolate plantains',
                    fr: '4  bâtonnets au fromage, goyave, chorizo, plantain au chocolat'
                },
                image: '../../../assets/images/bocadillo/menu-favorites/bocadillo-menu-favorite-dish-3.jpg',
                restaurantSlug: RestaurantSlug.Bocadillo
            },
        );
        this.laurierBbqFavorites = this.generateFeatures(
            {
                title: {
                    en: '1/4 Chicken Breast',
                    fr: '1/4 Poitrine'
                },
                description: {
                    en: 'Served with fries, coleslaw, bun and our famous Laurier sauce.',
                    fr: 'Servi avec frites, salade de chou, petit pain et notre fameuse sauce Laurier.'
                },
                image: '../../../assets/images/laurier-bbq/menu-favorites/laurierbbq-menu-favorite-dish-1.jpg',
                restaurantSlug: RestaurantSlug.LaurierBBQ
            },
            {
                title: {
                    en: 'Fried Chicken Burger',
                    fr: 'Burger poulet frit'
                },
                description: {
                    en: 'Pickle, lettuce, honey and Laurier mayonnaise served on a hamburger bun. come with fries, sauce and coleslaw.',
                    fr: 'Cornichon, laitue, miel et mayonnaise Laurier servie sur un pain hamburger servi avec frites, sauce et salade de choux.'
                },
                image: '../../../assets/images/laurier-bbq/menu-favorites/laurierbbq-menu-favorite-dish-1.jpg',
                restaurantSlug: RestaurantSlug.LaurierBBQ
            },
            {
                title: {
                    en: '1/4 Chicken Leg and Half Rack of Rib COMBO',
                    fr: 'Combo cuisse et côte levée'
                },
                description: {
                    en: 'Served with fries, coleslaw, bun and our famous Laurier sauce.',
                    fr: 'Servi avec frites, salade de chou, petit pain et notre fameuse sauce Laurier.'
                },
                image: '../../../assets/images/laurier-bbq/menu-favorites/laurierbbq-menu-favorite-dish-1.jpg',
                restaurantSlug: RestaurantSlug.LaurierBBQ
            }
        );
        this.oFishFavorites = this.generateFeatures(
            {
                title: {
                    en: 'Classic London',
                    fr: 'Classique Londres'
                },
                description: {
                    en: 'Beer batter served with fries and coleslaw.',
                    fr: 'Panure à la bière servi avec patate anglaise et salade de chou.'
                },
                image: '../../../assets/images/o-fish/menu-favorites/ofish-menu-favorite-dish-1.jpg',
                restaurantSlug: RestaurantSlug.oFish
            }, {
                title: {
                    en: 'Bang Bang Shrimp',
                    fr: 'Crevettes Bang Bang'
                },
                description: {
                    en: 'Ô Fish special fried shrimp.',
                    fr: 'Crevettes frites spéciales d’Ô Fish.'
                },
                image: '../../../assets/images/o-fish/menu-favorites/ofish-menu-favorite-dish-2.jpg',
                restaurantSlug: RestaurantSlug.oFish
            }, {
                title: {
                    en: 'Quinoa Poke with Cod',
                    fr: 'Poké quinoa à la morue'
                },
                description: {
                    en: 'Poke bowl served with variety of vegetables and rice.',
                    fr: 'Bol de poké servi avec une variété de légumes.'
                },
                image: '../../../assets/images/o-fish/menu-favorites/ofish-menu-favorite-dish-3.jpg',
                restaurantSlug: RestaurantSlug.oFish
            }
        );
        this.sushiSamaConcordiaFavorites = this.generateFeatures(
            {
                title: {
                    en: 'Poke Supreme',
                    fr: 'Poke Suprême'
                },
                description: {
                    en: 'Yuzu scallop & marinated salmon & spicy tuna, salad, avocado, cucumber, mango, oshinko, red pepper, carrot, sushi rice, tempura, masago, spicy mayo and teriyaki sauce.',
                    fr: 'Pétoncle yuzu, saumon mariné et thon épicé, salade, avocat, concombre, mangue, oshinko, poivron rouge, carotte, riz sushi, tempura, masago, mayo épicée et sauce teriyaki.'
                },
                image: '../../../assets/images/sushi-sama/menu-favorites/sushi-sama-menu-favorite-dish-1.png',
                restaurantSlug: RestaurantSlug.SushiSama
            },
            {
                title: {
                    en: 'Salmon Ichiban',
                    fr: 'Ichiban Saumon'
                },
                description: {
                    en: 'Salmon, crab stick, tamago, red pepper, cucumber, lettuce, Japanese rice.',
                    fr: 'Saumon, bâtonnet de crabe, tamago, poivron rouge, concombre, laitue, riz japonais.'
                },
                image: '../../../assets/images/sushi-sama/menu-favorites/sushi-sama-menu-favorite-dish-2.png',
                restaurantSlug: RestaurantSlug.SushiSama
            },
            {
                title: {
                    en: 'Sushi Dessert (6 pcs)',
                    fr: 'Sushi Dessert (6 mcx)'
                },
                description: {
                    en: 'Strawberry, banana, kiwi and chocolate tempura. served with our special sauce.',
                    fr: 'Fraise, banane, kiwi et tempura au chocolat, servi avec notre sauce spéciale.'
                },
                image: '../../../assets/images/sushi-sama/menu-favorites/sushi-sama-menu-favorite-dish-3.png',
                restaurantSlug: RestaurantSlug.SushiSama
            }
        );
        this.paniniStopFavorites = this.generateFeatures(
            {
                title: {
                    en: 'Grilled Chicken Panini',
                    fr: 'Panini au poulet grillé'
                },
                description: {
                    en: 'Grilled chicken, mozzarella, lettuce, mayonnaise, tomato.',
                    fr: 'Poulet grillé, mozzarella, laitue, mayonnaise, tomate.'
                },
                image: '../../../assets/images/panini-stop/menu-favorites/panini-stop-menu-favorite-dish-1.png',
                restaurantSlug: RestaurantSlug.PaniniStop
            },
            {
                title: {
                    en: 'Merguez Panini',
                    fr: 'Panini au Merguez'
                },
                description: {
                    en: 'Mozzarella, lettuce, mayonnaise, tomato.',
                    fr: 'Mozzarella, laitue, mayonnaise, tomate.'
                },
                image: '../../../assets/images/panini-stop/menu-favorites/panini-stop-menu-favorite-dish-2.png',
                restaurantSlug: RestaurantSlug.PaniniStop
            },
            {
                title: {
                    en: 'Ground Meat Panini',
                    fr: 'Panini viande hachée'
                },
                description: {
                    en: 'Ground meat, mozzarella, lettuce, mayonnaise.',
                    fr: 'Viande hachée, mozzarella, laitue, mayonnaise.'
                },
                image: '../../../assets/images/panini-stop/menu-favorites/panini-stop-menu-favorite-dish-3.png',
                restaurantSlug: RestaurantSlug.PaniniStop
            }
        );
        this.pokeStationCdnFavorites = this.generateFeatures(
            {
                title: {
                    en: 'Chef’s Salmon and Tuna Poke',
                    fr: 'Poké du chef saumon & thon'
                },
                description: {
                    en: 'Salad, carrot, avocado, English cucumber, red bell pepper, fresh mango, pickled Japanese radish, tempura, rice beads, masago, nori, sesame, teriyaki sauce and spicy mayo.',
                    fr: 'Salade, carotte, avocat, concombre anglais, poivron rouge, mangue fraîche, radis japonais mariné, tempura, perles de riz, masago, nori, sésame, sauce teriyaki et mayo épicée.'
                },
                image: '../../../assets/images/poke-station/menu-favorites/poke-station-cdn-menu-favorite-dish-1.png',
                restaurantSlug: RestaurantSlug.PokeStationCdn
            },
            {
                title: {
                    en: 'B.B.Q Pork Banh Mi',
                    fr: 'Banh Mi au porc B.B.Q'
                },
                description: {
                    en: '',
                    fr: ''
                },
                image: '../../../assets/images/poke-station/menu-favorites/poke-station-cdn-menu-favorite-dish-2.png',
                restaurantSlug: RestaurantSlug.PokeStationCdn
            },
            {
                title: {
                    en: 'Chef’s Lobster Poke',
                    fr: 'Poké du chef au homard'
                },
                description: {
                    en: 'Salad, carrot, avocado, english cucumber, red pepper, fresh mango, oshinko, edamame, tempura, rice beads, masago, nori, sésame, teriyaki sauce, spicy mayo.',
                    fr: 'Salade, carotte, avocat, concombre anglais, poivron rouge, mangue fraîche, radis japonais mariné, tempura, perles de riz, masago, nori, sésame, sauce teriyaki, mayo épicée.'
                },
                image: '../../../assets/images/poke-station/menu-favorites/poke-station-cdn-menu-favorite-dish-3.png',
                restaurantSlug: RestaurantSlug.PokeStationCdn
            }
        );
        this.teochewFoodieFavorites = this.generateFeatures(
            {
                title: {
                    en: 'Steamed Beef Wontons',
                    fr: 'Wontons au boeuf à la vapeur'
                },
                description: {
                    en: 'Wontons (15 pcs) steamed at the store with a choice of a small sauce. Warm wontons ready to eat when received. Contains wheat, soja, and eggs.',
                    fr: 'Wontons (15 mcx) cuit à la vapeur en boutique accompagnés d’une petite sauce au choix. Wontons cuits prêts à manger lorsque reçus. Contient du blé, soja et des oeufs.'
                },
                image: '../../../assets/images/teochew/menu-favorites/teochew-menu-favorite-dish1.png',
                restaurantSlug: RestaurantSlug.TeochewFoodie
            },
            {
                title: {
                    en: 'Vegan Rice Noodle Rolls',
                    fr: 'Rouleaux de nouille végétaliens'
                },
                description: {
                    en: 'This delicacy is completely homemade, the rice noodle dough is directly made from rice flour our own and inside there is shiitake mushrooms, preserved radish, celery, fungus (wood ear), and carrots. A delicious traditional mix! Contains sesame. 4 rolls per box. Warmed up at the store for delivery.',
                    fr: 'Une plat succulent fait maison ! La pâte des rouleaux est faite avec de la farine de riz et la garniture est un mélange de champignons Shiitake, radis confis, céleri, champignons (oreilles de Judas) et des carottes. Frais et délicieux! Contient de la sésame. 4 rouleaux par boîte. '
                },
                image: '../../../assets/images/teochew/menu-favorites/teochew-menu-favorite-dish2.png',
                restaurantSlug: RestaurantSlug.TeochewFoodie
            },
            {
                title: {
                    en: 'Shrimp Wonton Soup',
                    fr: 'Soupe wonton aux crevettes'
                },
                description: {
                    en: 'We prepare our wonton soup with 15 homemade wontons and our specialty soup base. Warm soup ready to eat when received. Contains wheat, sesame, soja, eggs and sea food',
                    fr: 'Nous préparons une soupe wonton avec 15 wontons en utilisant notre base de bouillon de spécialité. Bonne soupe chaude prête à manger lorsque reçue. Contient du blé, sésame, soja, des oeufs et fruits de mer.'
                },
                image: '../../../assets/images/teochew/menu-favorites/teochew-menu-favorite-dish3.png',
                restaurantSlug: RestaurantSlug.TeochewFoodie
            }
        );
        this.TapigoFavorites = this.generateFeatures(
            {
                title: {
                    en: 'WE THE NORTH',
                    fr: 'WE THE NORTH'
                },
                description: {
                    en: 'Laced white base (parmesan crust) with pulled beef with cheddar, sautéed onions, sweet drops, and secret sauce.',
                    fr: 'Tapioca farci de cheddar, boeuf effiloché, oignons sautés, gouttes sucrées et sauce Tapi Go !'
                },
                image: '../../../assets/images/tapi-go/menu-favorites/tapi-go-menu-favorites-dish1.png',
                restaurantSlug: RestaurantSlug.Tapigo
            }, {
                title: {
                    en: 'DIY Acai Bowl',
                    fr: 'Bol d’açaí à ton goût'
                },
                description: {
                    en: 'Organic Açaí mixed with banana, maple syrup and all the toppings of your choice!',
                    fr: 'Açaí biologique mélangé à de la banane, du sirop d’érable et toutes les garnitures de votre choix !'
                },
                image: '../../../assets/images/tapi-go/menu-favorites/tapi-go-menu-favorites-dish2.png',
                restaurantSlug: RestaurantSlug.Tapigo
            }, {
                title: {
                    en: 'Cassava Cake',
                    fr: 'Gâteau Cassava'
                },
                description: {
                    en: 'Rice, beans, salsa, sour cream, and cheese.',
                    fr: 'Riz, haricots, salsa, crème aigre et formages.'
                },
                image: '../../../assets/images/tapi-go/menu-favorites/tapi-go-menu-favorites-dish3.png',
                restaurantSlug: RestaurantSlug.Tapigo
            },
        );
        this.brigadePizzaFavorites = this.generateFeatures(
            {
                title: {
                    en: 'Ford (Detroit-style)',
                    fr: 'Ford (style Détroit)'
                },
                description: {
                    en: 'Chicken, bacon, mixed cheeses, chili flakes, ranch dressing.',
                    fr: 'Poulet, bacon, mélange de fromages, flocons de chili, sauce ranch.'
                },
                image: '../../../assets/images/brigade-pizza/menu-favorites/brigade-pizza-menu-favorite-dish-1.png',
                restaurantSlug: RestaurantSlug.BrigadePizza
            },
            {
                title: {
                    en: 'Nutella Sticks',
                    fr: 'Bâtonnets de Nutella'
                },
                description: {
                    en: 'Pizza Sticks filled with nutella. Drizzled with sweet caramel sauce.',
                    fr: 'Bâtonnets de pizza remplis de Nutella.  Arosé avec une sauce au caramel sucrée.'
                },
                image: '../../../assets/images/brigade-pizza/menu-favorites/brigade-pizza-menu-favorite-dish-2.png',
                restaurantSlug: RestaurantSlug.BrigadePizza
            },
            {
                title: {
                    en: 'Calzone',
                    fr: 'Calzone'
                },
                description: {
                    en: 'Tomato sauce, ricotta, ham, mushrooms, fresh mozzarella.',
                    fr: 'Sauce tomate, ricotta, jambon, champignon, mozzarella frais.'
                },
                image: '../../../assets/images/brigade-pizza/menu-favorites/brigade-pizza-menu-favorite-dish-3.png',
                restaurantSlug: RestaurantSlug.BrigadePizza
            }
        );
        this.alAmineFavorites = this.generateFeatures(
            {
                title: {
                    en: 'Whole Grilled Chicken',
                    fr: 'Poulet entier grillé'
                },
                description: {
                    en: 'Served with rice, garlic potatoes or fries, salad, and grilled spicy saj bread.',
                    fr: 'Accompagné de riz, patates à l’ail ou frites, salade et pain saj grillé et épicé.'
                },
                image: '../../../assets/images/al-amine/menu-favorites/al-amine-menu-favorite-dish-1.png',
                restaurantSlug: RestaurantSlug.AlAmine
            },
            {
                title: {
                    en: 'The Jumbo-Combo',
                    fr: 'La Jumbo-Combo'
                },
                description: {
                    en: '1 Pizza 18", large fries, 24 chicken wings, and 6 drinks.',
                    fr: '1 Pizza 18", grande frite, 24 ailes de poulet et 6 boissons.'
                },
                image: '../../../assets/images/al-amine/menu-favorites/al-amine-menu-favorite-dish-2.png',
                restaurantSlug: RestaurantSlug.AlAmine
            },
            {
                title: {
                    en: 'Falafel Plate',
                    fr: 'Assiette Falafel'
                },
                description: {
                    en: '',
                    fr: ''
                },
                image: '../../../assets/images/al-amine/menu-favorites/al-amine-menu-favorite-dish-3.png',
                restaurantSlug: RestaurantSlug.AlAmine
            }
        );
        this.tacosNationFavorites = this.generateFeatures(
            {
                title: {
                    en: 'Du Bled Tacos',
                    fr: 'Tacos du Bled'
                },
                description: {
                    en: 'Kefta, merguez, caramelized onions, mozzarella, and fries. Served with cheesy sauce and algerian sauce.',
                    fr: 'Kefta, merguez, oignons caramélisés, mozzarella et frites. Servi avec sauce fromagère et sauce algérienne.'
                },
                image: '../../../assets/images/tacos-nation/menu-favorites/tacos-nation-menu-favorites-1.png',
                restaurantSlug: RestaurantSlug.TacosNation
            },
            {
                title: {
                    en: 'Finesse Poutine',
                    fr: 'Poutine Finesse'
                },
                description: {
                    en: 'Fries, bacon, grilled chicken, jalapeño, cheese curd, and gravy.',
                    fr: 'Frites, bacon, poulet grillé, jalapeno, fromage en grain et sauce poutine.'
                },
                image: '../../../assets/images/tacos-nation/menu-favorites/tacos-nation-menu-favorites-2.png',
                restaurantSlug: RestaurantSlug.TacosNation
            },
            {
                title: {
                    en: 'Tokébec Tacos',
                    fr: 'Tacos Tokébec'
                },
                description: {
                    en: 'Chicken nuggets, bacon, cheese curd, and fries. Served with cheesy sauce, and gravy.',
                    fr: 'Croquettes de poulet, bacon, fromage en grain et frites. Servi avec sauce fromagère et sauce poutine.'
                },
                image: '../../../assets/images/tacos-nation/menu-favorites/tacos-nation-menu-favorites-3.png',
                restaurantSlug: RestaurantSlug.TacosNation
            }
        );
        this.cacao70Favorites = this.generateFeatures(
            {
                title: {
                    en: '6 Churros with Chocolate',
                    fr: '6 Churros avec chocolat'
                },
                description: {
                    en: '6 churros with cinnamon sugar and a dipping chocolate sauce of your choice.',
                    fr: '6 churros au sucre à la cannelle avec une sauce au chocolat trempette de votre choix.'
                },
                image: '../../../assets/images/cacao-70/menu-favorites/cacao-70-menu-favorite-1.png',
                restaurantSlug: RestaurantSlug.Cacao70
            },
            {
                title: {
                    en: 'Black and White Waffle',
                    fr: 'Gaufre noire et blanche'
                },
                description: {
                    en: 'A classic waffle topped with whipped cream and a scoop of vanilla and chocolate ice cream, drizzled with melted dark and white chocolate. Served with chocolate coated cereals and melted chocolate.',
                    fr: 'Une gaufre classique garnie de crème fouettée et d’une boule de crème glacée au chocolat et à la vanille et arrosée de chocolat noir et blanc fondu. Servie avec des céréales enrobées de chocolat et de chocolat fondu.'
                },
                image: '../../../assets/images/cacao-70/menu-favorites/cacao-70-menu-favorite-2.png',
                restaurantSlug: RestaurantSlug.Cacao70
            },
            {
                title: {
                    en: 'Apple & Bacon Crunchy Crepe',
                    fr: 'Crêpe Croustillante pomme & bacon'
                },
                description: {
                    en: 'A classic crepe with bacon, fresh apples, mild white cheddar cheese, cream vanilla ice cream, walnuts, icing sugar and caramel sauce.',
                    fr: 'Une crêpe classique avec bacon, pommes fraîches, fromage cheddar blanc doux, crème glacée à la vanille, noix de Grenoble, sucre à glacer et sauce au caramel.'
                },
                image: '../../../assets/images/cacao-70/menu-favorites/cacao-70-menu-favorite-3.png',
                restaurantSlug: RestaurantSlug.Cacao70
            }
        );
        this.mintarFavorites = this.generateFeatures(
            {
                title: {
                    en: 'The Classic',
                    fr: 'Le classique',
                },
                description: {
                    en: 'Lettuce, tomato, ham, turkey, havarti, labneh.',
                    fr: 'Laitue, tomate, jambon, dinde, havarti, labneh.'
                },
                image: '../../../assets/images/mintar/menu-favorites/mintar-menu-favorites-1.jpg',
                restaurantSlug: RestaurantSlug.Mintar
            },
            {
                title: {
                    en: 'The Halloumi & Egg',
                    fr: 'L’oeuf halloumi'
                },

                description: {
                    en: 'Lettuce, tomato, spicy mayo.',
                    fr: 'Laitue, tomate, mayo épicée.'
                },
                image: '../../../assets/images/mintar/menu-favorites/mintar-menu-favorites-2.jpg',
                restaurantSlug: RestaurantSlug.Mintar
            },
            {
                title: {
                    en: 'Chicken Salad',
                    fr: 'Salade au poulet'
                },
                description: {
                    en: 'Lettuce, chicken, cucumber, avocado',
                    fr: 'Laitue, poulet, concombre, avocat'
                },
                image: '../../../assets/images/mintar/menu-favorites/mintar-menu-favorites-3.jpg',
                restaurantSlug: RestaurantSlug.Mintar
            }
        );
        this.auxMerveilleuxDeFredFavorites = this.generateFeatures(
            {
                title: {
                    en: 'Set of 6 mini merveilleux',
                    fr: 'Coffret de 6 mini merveilleux',
                },
                description: {
                    en: '',
                    fr: ''
                },
                image: '',
                restaurantSlug: RestaurantSlug.AuxMerveilleuxDeFred
            },
            {
                title: {
                    en: 'Large Cramique',
                    fr: 'Grand cramique'
                },

                description: {
                    en: '',
                    fr: ''
                },
                image: '',
                restaurantSlug: RestaurantSlug.AuxMerveilleuxDeFred
            },
            {
                title: {
                    en: 'Pack of 6 waffles',
                    fr: 'Paquet de 6 gaufres'
                },
                description: {
                    en: '',
                    fr: ''
                },
                image: '',
                restaurantSlug: RestaurantSlug.AuxMerveilleuxDeFred
            }
        );
        this.laToileFavorites = this.generateFeatures(
            {
                title: {
                    en: 'Enchilada',
                    fr: 'Enchilada',
                },
                description: {
                    en: 'Chicken, salsa verde, sour cream, and coriander.',
                    fr: 'Poulet, salsa verde, crème sure et coriandre.'
                },
                image: '../../../assets/images/la-toile/menu-favorites/la-toile-menu-favorites-1.jpeg',
                restaurantSlug: RestaurantSlug.LaToile
            },
            {
                title: {
                    en: 'Sweet Potato Fries',
                    fr: 'Frites de patates douces'
                },

                description: {
                    en: 'Japanese sweet potatoes and house spice blend.',
                    fr: 'Patates douces japonaises avec mélange d’épices maison.'
                },
                image: '../../../assets/images/la-toile/menu-favorites/la-toile-menu-favorites-2.jpeg',
                restaurantSlug: RestaurantSlug.LaToile
            },
            {
                title: {
                    en: 'Meatballs',
                    fr: 'Boulettes de viande'
                },
                description: {
                    en: 'Meatball, tomato sauce, and parmesan.',
                    fr: 'Boulette de viande, sauce tomate et parmesan.'
                },
                image: '../../../assets/images/la-toile/menu-favorites/la-toile-menu-favorites-3.jpeg',
                restaurantSlug: RestaurantSlug.LaToile
            }
        );
        this.sepLaiFavorites = this.generateFeatures(
            {
                title: {
                    en: ' Laap',
                    fr: ' Laap'
                },
                description: {
                    en: 'Salad of Laotian herbs, lemongrass, lime leaf, mint, coriander, green onion, French shallot, roasted ground rice served with cucumber, red radish and sticky rice.',
                    fr: 'Salade de fines herbes laotiennes, citronnelle, feuille de lime, menthe, coriandre, oignon vert, échalote française, riz moulu rôti servis avec concombre, radis rouge et riz collant.'
                },
                image: '../../../assets/images/sep-lai/menu-favorites/sep-lai-menu-favorites-dish-1.jpg',
                restaurantSlug: RestaurantSlug.SepLai
            },
            {
                title: {
                    en: 'Khao Poon',
                    fr: 'Khao Poon'
                },
                description: {
                    en: 'Red curry made with sōmen noodles and coconut milk, seasoned with fish sauce and herbs',
                    fr: 'Cari rouge et lait de coco avec nouilles sōmen, assaisonné de sauce poisson et de fines herbes'
                },
                image: '../../../assets/images/sep-lai/menu-favorites/sep-lai-menu-favorites-dish-2.jpg',
                restaurantSlug: RestaurantSlug.SepLai
            },
            {
                title: {
                    en: 'Som Tam',
                    fr: 'Som Tam'
                },
                description: {
                    en: 'Grated green papaya salad with carrot, green beans, Thai eggplant, tomato, fish sauce, tamarind, palm sugar and lime.',
                    fr: 'Papaye verte râpée, carotte, long haricot vert, aubergine Thaïe, tomate, sauce poisson, tamarin, sucre de palme et lime.'
                },
                image: '../../../assets/images/sep-lai/menu-favorites/sep-lai-menu-favorites-dish-3.jpg',
                restaurantSlug: RestaurantSlug.SepLai
            }
        );
        this.bistroDeLaCiteFavorites = this.generateFeatures(
            {
                title: {
                    en: 'Chicken Chipotle Bacon Panini',
                    fr: 'Panini au Poulet Chipotle et Bacon'
                },
                description: {
                    en: 'Chicken breast, bacon, chipotle sauce, mozzarella, lettuce, and tomatoes.',
                    fr: 'Poitrine de poulet, bacon, sauce chipotle, mozzarella, laitue, et tomates.'
                },
                image: '../../../assets/images/bistro-de-la-cite/menu-favorites/bistro-de-la-cite-menu-favorites-dish-1.png',
                restaurantSlug: RestaurantSlug.BistroDeLaCite
            },
            {
                title: {
                    en: 'Fresh Homemade Pastries (6)',
                    fr: 'Pâtisseries Maison Fraîches (6)'
                },
                description: {
                    en: 'Box of 6 pastries of the day of your choice, chosen among: chocolatine, amandine, cinnamon roll, apple strudel, triple cheese croissant, raspberry cheesecake croissant, and custard and chocolate croissant.',
                    fr: 'Une boîte composée de 6 pâtisseries du jour à votre choix, parmi: chocolatine, amandine, brioche à la cannelle, strudel aux pommes, croissant aux trois fromages, croissant au gâteau au fromage aux framboise et croissant, croissant crème pâtissière et chocolat.'
                },
                image: '../../../assets/images/bistro-de-la-cite/menu-favorites/bistro-de-la-cite-menu-favorites-dish-2.jpg',
                restaurantSlug: RestaurantSlug.BistroDeLaCite
            },
            {
                title: {
                    en: 'Morning Crunch',
                    fr: 'Croque Matin'
                },
                description: {
                    en: 'Butter croissant, eggs, cheese, ham, bacon, lettuce, and tomatoes.',
                    fr: 'Croissant au beurre, œufs, fromage, jambon, bacon, laitue, et tomates.'
                },
                image: '../../../assets/images/bistro-de-la-cite/menu-favorites/bistro-de-la-cite-menu-favorites-dish-3.png',
                restaurantSlug: RestaurantSlug.BistroDeLaCite
            }
        );
        this.singhsFavorites = this.generateFeatures(
            {
                title: {
                    en: 'Special Singh\'s',
                    fr: 'Spécial Singh\'s'
                },
                description: {
                    en: '(Spicy) combination of tandoori chicken, tikka, and sheek kebab, served with naan bread and salad.',
                    fr: '(Épicé) combinaison de poulet tandoori, tikka, et sheek kebab, servi avec pain naan et salade.'
                },
                image: '../../../assets/images/singhs/menu-favorites/singhs-menu-favorites-dish-1.jpg',
                restaurantSlug: RestaurantSlug.Singhs
            },
            {
                title: {
                    en: 'Butter Chicken',
                    fr: 'Poulet au beurre'
                },
                description: {
                    en: 'Boneless chicken cooked in a rich butter cream and garnished with coriander.',
                    fr: 'Poulet désossé cuit dans une riche crème au beurre et garnie de coriandre.'
                },
                image: '../../../assets/images/singhs/menu-favorites/singhs-menu-favorites-dish-2.jpeg',
                restaurantSlug: RestaurantSlug.Singhs
            },
            {
                title: {
                    en: 'Rogan Josh Chicken',
                    fr: 'Poulet rogan josh'
                },
                description: {
                    en: 'Chicken cooked with golden onions, yogurt, garlic, ginger, and aromatic spices.',
                    fr: 'Poulet cuit avec des oignons dorés , yaourt, ail, gingembre, et épices aromatiques.'
                },
                image: '../../../assets/images/singhs/menu-favorites/singhs-menu-favorites-dish-3.jpeg',
                restaurantSlug: RestaurantSlug.Singhs
            }
        );
        this.chaiTeaLoungeFavorites = this.generateFeatures(
            {
                title: {
                    en: 'Classic Bubble Tea',
                    fr: 'Bubble Tea Classique'
                },
                description: {
                    en: 'Made with jasmine green tea (can add milk) and a flavour of your choice: Coconut, Lychee, Mango, Peach, Passion Fruit... see our menu for many more!',
                    fr: 'Fait avec du thé vert au jasmin (peut ajouter du lait) et une saveur de votre choix : noix de coco, litchi, mangue, pêche, fruit de la passion... consultez notre menu pour bien d\'autres'
                },
                image: '../../../assets/images/chai-tea-lounge/menu-favorites/chai-tea-lounge-menu-favorites-dish-1.jpg',
                restaurantSlug: RestaurantSlug.ChaiTeaLounge
            },
            {
                title: {
                    en: 'Matcha Black Tea Latté',
                    fr: 'Latté matcha thé noir'
                },
                description: {
                    en: 'Pistachio, coconut, vanilla.',
                    fr: 'Pistache, noix de coco, vanille.'
                },
                image: '../../../assets/images/chai-tea-lounge/menu-favorites/chai-tea-lounge-menu-favorites-dish-2.jpg',
                restaurantSlug: RestaurantSlug.ChaiTeaLounge
            },
            {
                title: {
                    en: 'Macarons',
                    fr: 'Macarons'
                },
                description: {
                    en: 'Single or pack of 6 chosen among the following flavours: Exotic, Blueberry, Salted Caramel, Chocolate, Hazelnut, Red Velvet, Coconut, Pistachio',
                    fr: 'À l\'unité ou paquet de 6 au choix parmi les saveurs suivantes: Exotique, Myrtille, Caramel Salé, Chocolat, Noisette, Red Velvet, Noix de Coco, Pistache'
                },
                image: '../../../assets/images/chai-tea-lounge/menu-favorites/chai-tea-lounge-menu-favorites-dish-3.jpg',
                restaurantSlug: RestaurantSlug.ChaiTeaLounge
            }
        );
        this.laCroissanterieFigaroFavorites = this.generateFeatures(
            {
                title: {
                    en: 'Our butter croissants',
                    fr: 'Nos Croissants au beurre'
                },
                description: {
                    en: 'The originals, the ones that made our reputation. Plain, with cheese, almond paste, with Nutella... consult our menu for all the flavors.',
                    fr: 'Les originaux, ceux qui ont fait notre réputation. Nature, au fromage, à la pâte d\'amandes, au Nutella... consultez notre menu pour toutes les saveurs.'
                },
                image: '../../../assets/images/la-croissanterie-figaro/menu-favorites/la-croissanterie-figaro-menu-favorites-dish-1.jpg',
                restaurantSlug: RestaurantSlug.LaCroissanterieFigaro
            },
            {
                title: {
                    en: 'Salade du trésor',
                    fr: 'Salade du trésor'
                },
                description: {
                    en: 'With tuna, chicken, crab, ham, and cheese',
                    fr: 'Avec thon, poulet, crabe, jambon et fromage'
                },
                image: '../../../assets/images/la-croissanterie-figaro/menu-favorites/la-croissanterie-figaro-menu-favorites-dish-2.jpg',
                restaurantSlug: RestaurantSlug.LaCroissanterieFigaro
            },
            {
                title: {
                    en: 'Flammekueche Alsacienne',
                    fr: 'Flammekueche Alsacienne'
                },
                description: {
                    en: 'Also called "tarte flambée" and a speciality of the region of Alsace. Made with sour cream, onions, and bacon.',
                    fr: 'Une spécialité de la région d\'Alsace. Une tarte flambée avec crème sure, oignons et bacon.'
                },
                image: '../../../assets/images/la-croissanterie-figaro/menu-favorites/la-croissanterie-figaro-menu-favorites-dish-3.jpg',
                restaurantSlug: RestaurantSlug.LaCroissanterieFigaro
            }
        );
        this.laFabriqueDeBagelFavorites = this.generateFeatures(
            {
                title: {
                    en: 'Classic Salmon Bagel',
                    fr: 'Bagel saumon classique'
                },
                description: {
                    en: 'Sesame bagel, plain cream cheese, smoked salmon, red onion, and capers.                    ',
                    fr: 'Bagel au sésame, fromage à la crème nature, saumon fumé, oignon rouge et câpres.'
                },
                image: '../../../assets/images/la-fabrique-de-bagel/menu-favorites/lfdb-menu-favorites-dish-1.jpeg',
                restaurantSlug: RestaurantSlug.LaFabriqueDeBagel
            },
            {
                title: {
                    en: 'Bagel and Spread',
                    fr: 'Bagel et tartinade'
                },
                description: {
                    en: 'Your choice of bagel served with a spread.',
                    fr: 'Votre choix de bagel servi avec une tartinade.'
                },
                image: '../../../assets/images/la-fabrique-de-bagel/menu-favorites/lfdb-menu-favorites-dish-2.jpeg',
                restaurantSlug: RestaurantSlug.LaFabriqueDeBagel
            },
            {
                title: {
                    en: 'Brie, Pear and Prosciutto Bagel',
                    fr: 'Bagel brie, poire et prosciutto'
                },
                description: {
                    en: 'Sesame bagel, pesto mayonnaise, brie, pear, prosciutto, candied walnuts, and balsamic glaze.',
                    fr: 'Bagel au sésame, mayonnaise au pesto, brie, poire, prosciutto, noix confites et glaçage balsamique.'
                },
                image: '../../../assets/images/la-fabrique-de-bagel/menu-favorites/lfdb-menu-favorites-dish-3.jpeg',
                restaurantSlug: RestaurantSlug.LaFabriqueDeBagel
            }
        );
        this.studentTastyBiryaniFavorites = this.generateFeatures(
            {
                title: {
                    en: 'Chicken Biryani',
                    fr: 'Biryani au poulet'
                },
                description: {
                    en: 'Seasoned sella basmati rice with two pieces of chicken served with a signature sauce.',
                    fr: 'Riz basmati sella assaisonné avec deux morceaux de poulet servis avec une sauce signature.'
                },
                image: '../../../assets/images/student-tasty-biryani/menu-favorites/stb-menu-favorites-dish-1.jpg',
                restaurantSlug: RestaurantSlug.StudentTastyBiryani
            },
            {
                title: {
                    en: 'Chicken Karhai',
                    fr: 'Poulet Karahi'
                },
                description: {
                    en: 'Slowed-cooked beef stew seasoned with our signature spices, served with white rice and one piece of naan bread.',
                    fr: 'Ragoût de boeuf assaisonné avec nos épices signature, servi avec du riz blanc et un morceau de pain naan.'
                },
                image: '../../../assets/images/student-tasty-biryani/menu-favorites/stb-menu-favorites-dish-2.jpg',
                restaurantSlug: RestaurantSlug.StudentTastyBiryani
            },
            {
                title: {
                    en: 'Lamb Haleem',
                    fr: 'Haleem d\'agneau'
                },
                description: {
                    en: 'Thick stew composed of meat, barley, lentils, wheat, and our signature spices, served with white rice and one piece of naan bread.',
                    fr: 'Ragoût épais composé de viande, d\'orge, de lentilles, de blé et de nos épices signatures, servi avec du riz blanc et un morceau de pain naan.'
                },
                image: '../../../assets/images/student-tasty-biryani/menu-favorites/stb-menu-favorites-dish-3.jpg',
                restaurantSlug: RestaurantSlug.StudentTastyBiryani
            }
        );
        this.osukaSushiFavorites = this.generateFeatures(
            {
                title: {
                    en: 'Kamikaze Roll',
                    fr: 'Rouleau Kamikaze'
                },
                description: {
                    en: 'Spicy salmon. tempura, avocado, and cucumber.',
                    fr: 'Saumon épicé, tempura, avocat et concombre.'
                },
                image: '../../../assets/images/osuka-sushi/menu-favorites/osuka-sushi-menu-favorites-dish-1.jpeg',
                restaurantSlug: RestaurantSlug.OsukaSushi
            },
            {
                title: {
                    en: 'Syake',
                    fr: 'Syake'
                },
                description: {
                    en: 'Fresh salmon.',
                    fr: 'Saumon frais.'
                },
                image: '../../../assets/images/osuka-sushi/menu-favorites/osuka-sushi-menu-favorites-dish-2.jpeg',
                restaurantSlug: RestaurantSlug.OsukaSushi
            },
            {
                title: {
                    en: 'Love Platter',
                    fr: 'Pleateau d\'Amour'
                },
                description: {
                    en: '4 nigiri, 6 hosomaki, 10 futomaki, 10 chef specials.',
                    fr: '4 nigiri, 6 hosomaki, 10 futomaki, 10 specialités du chef.'
                },
                image: '../../../assets/images/osuka-sushi/menu-favorites/osuka-sushi-menu-favorites-dish-3.jpeg',
                restaurantSlug: RestaurantSlug.OsukaSushi
            }
        );
        this.laliFavorites = this.generateFeatures(
            {
                title: {
                    en: 'Smoked Meat Hot Dog',
                    fr: 'Hot-Dog à la Viande Fumée'
                },
                description: {
                    en: 'Hot Dog, mixed pepper, onion, smoked meat, pickle, ketchup, mustard & mayo.',
                    fr: 'Hot-Dog, poivron mélangé, oignon, viande fumée, cornichon, ketchup, moutarde et mayo.'
                },
                image: '../../../assets/images/lali/menu-favorites/lali-menu-favorites-dish-1.png',
                restaurantSlug: RestaurantSlug.Lalis
            },
            {
                title: {
                    en: 'Chicken Mango Sandwich',
                    fr: 'Sandwich Poulet Mangue'
                },
                description: {
                    en: 'Chicken, tomato, onion, pickle, green chili, chiplotle mayo & chili mayo.',
                    fr: 'Poulet, tomate, oignon, cornichon, chili vert, mayo chipotle et mayo au piment.'
                },
                image: '../../../assets/images/lali/menu-favorites/lali-menu-favorites-dish-2.png',
                restaurantSlug: RestaurantSlug.Lalis
            },
            {
                title: {
                    en: 'Famous Bandari',
                    fr: 'Bandari Fameux'
                },
                description: {
                    en: 'Chipped sausage, fried onion, jalapeno, mushroom, mixed cheese, tomato, pickle, hot sauce, mayo & ketchup.',
                    fr: 'saucisses au poulet hachées, oignon frit, jalapeno, champignon, fromage mixte, tomate, corichon, sauce piquante, mayo et ketchup.'
                },
                image: '../../../assets/images/lali/menu-favorites/lali-menu-favorites-dish-3.png',
                restaurantSlug: RestaurantSlug.Lalis
            }
        );
        this.chezTaMereFavorites = this.generateFeatures(
            {
                title: {
                    en: 'Maman Sandrine Sandwich',
                    fr: 'Maman Sandrine Sandwich'
                },
                description: {
                    en: ' Duck rillette, dijon, grilled fennel, radish, and lettuce.',
                    fr: 'Rillette de canard, dijon, fenouil grillé, radis, et laitue.'
                },
                image: '../../../assets/images/chez-ta-mere/menu-favorites/chez-ta-mere-menu-favorites-dish-1.jpeg',
                restaurantSlug: RestaurantSlug.ChezTaMere
            },
            {
                title: {
                    en: 'Maman Sylvie Sandwich',
                    fr: 'Maman Sylvie Sandwich'
                },
                description: {
                    en: 'Smoked Meat Iced with Maple, potatoes, brown sauce, fresh cheddar cheese, mustard, gherkins, and fresh dill.',
                    fr: 'Viande fumée glacé à l\'érable, patates, sauce brune, fromage cheddar frais, moutarde, cornichons, et aneth fraîche.'
                },
                image: '../../../assets/images/chez-ta-mere/menu-favorites/chez-ta-mere-menu-favorites-dish-2.jpeg',
                restaurantSlug: RestaurantSlug.ChezTaMere
            },
            {
                title: {
                    en: 'Me Thi Sandwich',
                    fr: 'Me Thi Sandwich'
                },
                description: {
                    en: 'Potato bread, marinated and grilled tofu, Hanoi mayo, daikon in brine, julienne of carrots, cucumber, and fresh coriander.',
                    fr: 'Pain de pomme de terre, tofu mariné et grillé, mayo Hanoi, daikon en saumure, julienne de carottes, concombre, et coriandre fraîche.'
                },
                image: '../../../assets/images/chez-ta-mere/menu-favorites/chez-ta-mere-menu-favorites-dish-3.jpeg',
                restaurantSlug: RestaurantSlug.ChezTaMere
            }
        );
        this.chatpataFavorites = this.generateFeatures(
            {
                title: {
                    en: 'Noodle Burger',
                    fr: 'Burger aux nouilles'
                },
                description: {
                    en: '',
                    fr: ''
                },
                image: '../../../assets/images/chatpata/menu-favorites/chatpata-menu-favorites-dish-1.jpeg',
                restaurantSlug: RestaurantSlug.Chatpata
            },
            {
                title: {
                    en: 'Veggie Noodles',
                    fr: 'Nouilles végé'
                },
                description: {
                    en: '',
                    fr: ''
                },
                image: '../../../assets/images/chatpata/menu-favorites/chatpata-menu-favorites-dish-2.jpeg',
                restaurantSlug: RestaurantSlug.Chatpata
            },
            {
                title: {
                    en: 'Veggie Momos',
                    fr: 'Momos végé'
                },
                description: {
                    en: '',
                    fr: ''
                },
                image: '../../../assets/images/chatpata/menu-favorites/chatpata-menu-favorites-dish-3.jpeg',
                restaurantSlug: RestaurantSlug.Chatpata
            }
        );
        this.friteAlorsFavorites = this.generateFeatures(
            {
                title: {
                    en: 'The Vladimir',
                    fr: 'La Vladimir'
                },
                description: {
                    en: 'The classic poutine! Delicious Belgian fries, St-Guillaume cheese and brown gravy.',
                    fr: 'Notre poutine classique! Délicieuses frites belges, fromage St-Guillaume et sauce brune.'
                },
                image: '../../../assets/images/frite-alors/menu-favorites/frite-alors-menu-favorites-dish-1.jpg',
                restaurantSlug: RestaurantSlug.FriteAlors
            },
            {
                title: {
                    en: 'La Frite Alors!',
                    fr: 'La Frite Alors!'
                },
                description: {
                    en: 'Mix of bacon, red peppers, onions and grilled mushrooms.',
                    fr: 'Mélange de bacon, de poivrons rouges, d\'oignons et de champignons grillés.'
                },
                image: '../../../assets/images/frite-alors/menu-favorites/frite-alors-menu-favorites-dish-2.jpg',
                restaurantSlug: RestaurantSlug.FriteAlors
            },
            {
                title: {
                    en: 'Fries',
                    fr: 'Frites'
                },
                description: {
                    en: 'Hand-cut and cooked according to Belgian tradition. To enjoy with one of our homemade mayonnaises.',
                    fr: 'Coupées à la main et cuites selon la méthode belge. À savourer avec l\'une de nos mayonnaises maison.'
                },
                image: '../../../assets/images/frite-alors/menu-favorites/frite-alors-menu-favorites-dish-3.jpg',
                restaurantSlug: RestaurantSlug.FriteAlors
            }
        );
        this.tibumFavorites = this.generateFeatures(
            {
                title: {
                    en: 'Pad See Ew',
                    fr: 'Pad See Ew'
                },
                description: {
                    en: '',
                    fr: ''
                },
                image: '../../../assets/images/tibum/menu-favorites/tibum-menu-favorites-dish-1.png',
                restaurantSlug: RestaurantSlug.Tibum
            },
            {
                title: {
                    en: 'General Thai Chicken',
                    fr: 'Poulet Général Thaï'
                },
                description: {
                    en: '',
                    fr: ''
                },
                image: '../../../assets/images/tibum/menu-favorites/tibum-menu-favorites-dish-2.png',
                restaurantSlug: RestaurantSlug.Tibum
            },
            {
                title: {
                    en: 'Salt and Pepper Fried Shrimp',
                    fr: 'Crevettes Frites Sel et Poivre'
                },
                description: {
                    en: '',
                    fr: ''
                },
                image: '../../../assets/images/tibum/menu-favorites/tibum-menu-favorites-dish-3.png',
                restaurantSlug: RestaurantSlug.Tibum
            }
        );
        this.thePerleFavorites = this.generateFeatures(
            {
                title: {
                    en: 'Pork Banh Mi',
                    fr: 'Banh Mi au Porc'
                },
                description: {
                    en: 'Lemongrass grilled pork, pickled carrots and daikon, cucumber, cilantro, and jalapeño.',
                    fr: 'Porc grillé à la citronnelle, carottes et daikon marinés, concombre, coriandre et jalapeño.'
                },
                image: '../../../assets/images/the-perle/menu-favorites/the-perle-menu-favorites-dish-1.jpeg',
                restaurantSlug: RestaurantSlug.ThePerle
            },
            {
                title: {
                    en: 'Pitaya and Mango Tea',
                    fr: 'Thé Pitaya et Mangue'
                },
                description: {
                    en: 'Freshly brewed dragon fruit and mango tea',
                    fr: 'Thé fraîchement infusé au pitaya et à la mangue'
                },
                image: '../../../assets/images/the-perle/menu-favorites/the-perle-menu-favorites-dish-2.jpeg',
                restaurantSlug: RestaurantSlug.ThePerle
            },
            {
                title: {
                    en: 'Brown Sugar Pearl Tea',
                    fr: 'Thé aux Perles et Sucre Brun'
                },
                description: {
                    en: '',
                    fr: ''
                },
                image: '../../../assets/images/the-perle/menu-favorites/the-perle-menu-favorites-dish-3.jpeg',
                restaurantSlug: RestaurantSlug.ThePerle
            }
        );
        this.gongChaFavorites = this.generateFeatures(
            {
                title: {
                    en: 'Pearl Milk Tea',
                    fr: 'Pearl Milk Tea'
                },
                description: {
                    en: 'Commonly known as Bubble Milk Tea. Black Milk Tea with our pearl topping included.',
                    fr: 'Commonly known as Bubble Milk Tea. Black Milk Tea with our pearl topping included.'
                },
                image: '../../../assets/images/gong-cha/menu-favorites/gong-cha-menu-favorites-dish-1.png',
                restaurantSlug: RestaurantSlug.Gongcha
            },
            {
                title: {
                    en: 'Mango Smoothie',
                    fr: 'Mango Smoothie'
                },
                description: {
                    en: '',
                    fr: ''
                },
                image: '../../../assets/images/gong-cha/menu-favorites/gong-cha-menu-favorites-dish-2.jpeg',
                restaurantSlug: RestaurantSlug.Gongcha
            },
            {
                title: {
                    en: 'Original Waffle',
                    fr: 'Original Waffle'
                },
                description: {
                    en: '',
                    fr: ''
                },
                image: '../../../assets/images/gong-cha/menu-favorites/gong-cha-menu-favorites-dish-3.png',
                restaurantSlug: RestaurantSlug.ThePerle
            }
        );
        this.buffaloBillFavorites = this.generateFeatures(
            {
                title: {
                    en: 'Sitting Bull Pack',
                    fr: 'Sitting Bull Pack'
                },
                description: {
                    en: '7 piece and fries',
                    fr: '7 morceaux et frites'
                },
                image: '../../../assets/images/buffalo-bill/menu-favorites/buffalo-bill-menu-favorites-dish-1.jpeg',
                restaurantSlug: RestaurantSlug.BuffaloBill
            },
            {
                title: {
                    en: 'Buffalo Fried Chicken',
                    fr: 'Poulet Frit'
                },
                description: {
                    en: '2 - 6 pieces, fries, coleslaw and 1 Pepsi can.',
                    fr: '2 - 6 morceaux, frites, salade de chou et 1 canette de Pepsi.'
                },
                image: '../../../assets/images/buffalo-bill/menu-favorites/buffalo-bill-menu-favorites-dish-2.jpeg',
                restaurantSlug: RestaurantSlug.BuffaloBill
            },
            {
                title: {
                    en: 'Popcorn Chicken',
                    fr: 'Poulet popcorn'
                },
                description: {
                    en: 'Served with fries, Pepsi and choice of sauce.',
                    fr: 'Servi avec frites, Pepsi et choix de sauce.'
                },
                image: '../../../assets/images/buffalo-bill/menu-favorites/buffalo-bill-menu-favorites-dish-3.jpeg',
                restaurantSlug: RestaurantSlug.BuffaloBill
            }
        );
        this.souvlakiGrecPlusFavorites = this.generateFeatures(
            {
                title: {
                    en: 'Traditional Greek Salad',
                    fr: 'Salade grecque traditionnelle'
                },
                description: {
                    en: 'Tomatoes, cucumbers, onions, peppers, olives, feta, olive oil and oregano.',
                    fr: 'Tomates, concombres, oignons, piments, olives, feta, huile d\'olive et origan.'
                },
                image: '../../../assets/images/souvlaki-grec-plus/menu-favorites/souvlaki-grec-plus-menu-favorites-dish-1.jpg',
                restaurantSlug: RestaurantSlug.SouvlakiGrecPlus
            },
            {
                title: {
                    en: 'Traditional Pork Gyros',
                    fr: 'Gyros porc traditionnel'
                },
                description: {
                    en: '',
                    fr: ''
                },
                image: '../../../assets/images/souvlaki-grec-plus/menu-favorites/souvlaki-grec-plus-menu-favorites-dish-2.jpg',
                restaurantSlug: RestaurantSlug.SouvlakiGrecPlus
            },
            {
                title: {
                    en: 'Baklava',
                    fr: 'Baklava'
                },
                description: {
                    en: 'Walnut cake with syrup and honey.',
                    fr: 'Gâteau aux noix, sirop et miel.'
                },
                image: '../../../assets/images/souvlaki-grec-plus/menu-favorites/souvlaki-grec-plus-menu-favorites-dish-3.jpg',
                restaurantSlug: RestaurantSlug.SouvlakiGrecPlus
            },
        );
        this.pizzaSportFavorites = this.generateFeatures(
            {
                title: {
                    en: 'Pepperoni',
                    fr: 'Pepperoni'
                },
                description: {
                    en: '',
                    fr: ''
                },
                image: '../../../assets/images/pizza-sport/menu-favorites/pizza-sport-menu-favorites-dish-1.jpg',
                restaurantSlug: RestaurantSlug.PizzaSport
            },
            {
                title: {
                    en: 'French Fries',
                    fr: 'Patates Frites'
                },
                description: {
                    en: '',
                    fr: ''
                },
                image: '../../../assets/images/pizza-sport/menu-favorites/pizza-sport-menu-favorites-dish-2.jpg',
                restaurantSlug: RestaurantSlug.PizzaSport
            },
            {
                title: {
                    en: 'Regular',
                    fr: 'Ordinaire'
                },
                description: {
                    en: '',
                    fr: ''
                },
                image: '../../../assets/images/pizza-sport/menu-favorites/pizza-sport-menu-favorites-dish-3.jpg',
                restaurantSlug: RestaurantSlug.PizzaSport
            },
        );
        this.tsujiriFavorites = this.generateFeatures(
            {
                title: {
                    en: 'TSUJIRI Latte (Cold)',
                    fr: 'TSUJIRI Latte (Froid)'
                },
                description: {
                    en: '',
                    fr: ''
                },
                image: '../../../assets/images/tsujiri/menu-favorites/tsujiri-menu-favorites-dish-1.jpeg',
                restaurantSlug: RestaurantSlug.Tsujiri
            },
            {
                title: {
                    en: 'O-Matcha Cake',
                    fr: 'Gâteau O-Matcha'
                },
                description: {
                    en: '',
                    fr: ''
                },
                image: '../../../assets/images/tsujiri/menu-favorites/tsujiri-menu-favorites-dish-2.jpeg',
                restaurantSlug: RestaurantSlug.Tsujiri
            },
            {
                title: {
                    en: 'Salmon Poke Soba',
                    fr: 'Poke Soba au Saumon'
                },
                description: {
                    en: '',
                    fr: ''
                },
                image: '../../../assets/images/tsujiri/menu-favorites/tsujiri-menu-favorites-dish-3.jpeg',
                restaurantSlug: RestaurantSlug.Tsujiri
            }
        );
        this.spartaChickenFavorites = this.generateFeatures(
            {
                title: {
                    en: 'Pita Souvlaki (Chicken)',
                    fr: 'Pita souvlaki (poulet)'
                },
                description: {
                    en: '',
                    fr: ''
                },
                image: '../../../assets/images/sparta-chicken/menu-favorites/sparta-chicken-menu-favorites-dish-1.jpg',
                restaurantSlug: RestaurantSlug.PizzaSport
            },
            {
                title: {
                    en: 'Souvlaki (Chicken Skewer)',
                    fr: 'Souvlaki (brochette au poulet)'
                },
                description: {
                    en: '',
                    fr: ''
                },
                image: '../../../assets/images/sparta-chicken/menu-favorites/sparta-chicken-menu-favorites-dish-2.jpg',
                restaurantSlug: RestaurantSlug.PizzaSport
            },
            {
                title: {
                    en: 'French Fries',
                    fr: 'Frites'
                },
                description: {
                    en: '',
                    fr: ''
                },
                image: '../../../assets/images/sparta-chicken/menu-favorites/sparta-chicken-menu-favorites-dish-3.jpg',
                restaurantSlug: RestaurantSlug.PizzaSport
            },
        );
        this.p23DumplingsFavorites = this.generateFeatures(
            {
                title: {
                    en: 'Peanut Butter Wonton (6 Pcs)',
                    fr: 'Wonton au beurre d’arachide (6 mcx)'
                },
                description: {
                    en: '',
                    fr: ''
                },
                image: '../../../assets/images/p23-dumplings/menu-favorites/p23-dumplings-menu-favorites-dish-1.png',
                restaurantSlug: RestaurantSlug.P23Dumplings
            },
            {
                title: {
                    en: 'Pork with Coriander Dumplings',
                    fr: 'Boulettes porc et coriandre'
                },
                description: {
                    en: '',
                    fr: ''
                },
                image: '../../../assets/images/p23-dumplings/menu-favorites/p23-dumplings-menu-favorites-dish-2.png',
                restaurantSlug: RestaurantSlug.P23Dumplings
            },
            {
                title: {
                    en: 'Sweet Pork Dumplings',
                    fr: 'Boulettes porc sucré'
                },
                description: {
                    en: '',
                    fr: ''
                },
                image: '../../../assets/images/p23-dumplings/menu-favorites/p23-dumplings-menu-favorites-dish-3.png',
                restaurantSlug: RestaurantSlug.P23Dumplings
            },
        );
        this.burgzCantineFavorites = this.generateFeatures(
            {
                title: {
                    en: 'Pork Cart',
                    fr: 'Griot de porc'
                },
                description: {
                    en: '',
                    fr: ''
                },
                image: '../../../assets/images/burgz-cantine/menu-favorites/burgz-cantine-menu-favorites-dish-1.jpeg',
                restaurantSlug: RestaurantSlug.BurgzCantine
            },
            {
                title: {
                    en: 'Vegetarian Option',
                    fr: 'Option végétarienne'
                },
                description: {
                    en: '',
                    fr: ''
                },
                image: '../../../assets/images/burgz-cantine/menu-favorites/burgz-cantine-menu-favorites-dish-2.jpeg',
                restaurantSlug: RestaurantSlug.BurgzCantine
            },
            {
                title: {
                    en: 'Rice and peas',
                    fr: 'Riz collé'
                },
                description: {
                    en: '',
                    fr: ''
                },
                image: '../../../assets/images/burgz-cantine/menu-favorites/burgz-cantine-menu-favorites-dish-3.jpeg',
                restaurantSlug: RestaurantSlug.BurgzCantine
            }
        );
        this.goplanaFavorites = this.generateFeatures(
            {
                title: {
                    en: 'Potato and Bacon Pierogies',
                    fr: 'Pierogis aux pommes de terre et bacon'
                },
                restaurantSlug: RestaurantSlug.Goplana
            },
            {
                title: {
                    en: 'Polish Sausage Sandwich',
                    fr: 'Sandwich à la saucisse polonaise'
                },
                restaurantSlug: RestaurantSlug.Goplana
            },
            {
                title: {
                    en: 'Cabbage Cigars',
                    fr: 'Cigares au chou'
                },
                description: {
                    en: '2 pieces',
                    fr: '2 morceaux'
                },
                restaurantSlug: RestaurantSlug.Goplana
            }
        );
        this.piriPiriFavorites = this.generateFeatures(
            {
                title: {
                    en: 'Chicken Supreme (Breast)',
                    fr: 'PB - Suprême de poulet (Poitrine)'
                },
                description: {
                    en: 'Plate served with coleslaw/fries and/or rice',
                    fr: ''
                },
                restaurantSlug: RestaurantSlug.PiriPiri
            },
            {
                title: {
                    en: 'Half Chicken',
                    fr: 'Demi poulet'
                },
                description: {
                    en: 'Plate served with coleslaw/fries and/or rice',
                    fr: ''
                },
                restaurantSlug: RestaurantSlug.PiriPiri
            },
            {
                title: {
                    en: 'Portuguese\'s Plate',
                    fr: 'L\'Assiette du Portugais'
                },
                description: {
                    en: '1/4 chicken (leg), pork cutlet and 1/4  chouriço (Portuguese sausage) Plate served with coleslaw / fries and/or rice',
                    fr: '1/4 de poulet (cuisse), escalope de porc et chouriço (saucisse portugaise) Assiette servie avec salade de chou/ frites et/ou riz'
                },
                restaurantSlug: RestaurantSlug.PiriPiri
            }
        );
        this.ukiyaIzakayaFavorites = this.generateFeatures(
            {
                title: {
                    en: 'Pork Ramen',
                    fr: 'Ramen au Porc'
                },
                description: {
                    en: 'Served with corn, shallot, bamboo, seaweed and egg.',
                    fr: 'Servi avec maïs, échalote, bambou, algue et oeuf'
                },
                image: '../../../assets/images/ukiya-izakaya/menu-favorites/ukiya-izakaya-menu-favorites-dish-1.png',
                restaurantSlug: RestaurantSlug.UkiyaIzakaya
            },
            {
                title: {
                    en: 'Grilled steak rice',
                    fr: 'Riz Steak Grillé'
                },
                description: {
                    en: 'Grilled steak cubes and hiratake mushroom served with corn and salad',
                    fr: 'Cubes de steak grillé et champignon pleurotus servi avec du maïs et de la salade'
                },
                image: '../../../assets/images/ukiya-izakaya/menu-favorites/ukiya-izakaya-menu-favorites-dish-2.png',
                restaurantSlug: RestaurantSlug.UkiyaIzakaya
            },
            {
                title: {
                    en: 'Fried chicken wings (4 Pieces)',
                    fr: 'Ailes de poulet frites (4 Morceaux)'
                },
                image: '../../../assets/images/ukiya-izakaya/menu-favorites/ukiya-izakaya-menu-favorites-dish-3.png',
                restaurantSlug: RestaurantSlug.UkiyaIzakaya
            }
        );
        this.livSaladesFavorites = this.generateFeatures(
            {
                title: {
                    en: 'No-Boo',
                    fr: 'No-Boo'
                },
                description: {
                    en: 'Brown rice, marinated tofu, red pepper, carrot, cucumber, toasted nori, avocado, tempura anion, and pickled ginger. Served with our miso-ginger- peanut sesame vinaigrette.',
                    fr: 'Riz brun, tofu mariné, poivron rouge, carotte, concombre, feuille de nod, avocat, oignions tempura et gingembre mariné. Servis avec notre vinaigrette sésame et gingembre.'
                },
                image: '../../../assets/images/liv-salades/menu-favorites/liv-salades-menu-favorites-dish-1.png',
                restaurantSlug: RestaurantSlug.LivSalades
            },
            {
                title: {
                    en: 'Bliss Bowl',
                    fr: 'Bol Bliss'
                },
                description: {
                    en: 'Chickpeas, avocado, baby spinach, parsley hummus, quinoa, cherry tomato, and pita bread. Served with our creamy tahini vinaigrette.',
                    fr: 'Pois chiche, avocat, bébé épinard, persil, hummus, quinoa, tomate cerise et pain pita. Servis avec notre vinaigrette tahini crémeuse.'
                },
                image: '../../../assets/images/liv-salades/menu-favorites/liv-salades-menu-favorites-dish-2.png',
                restaurantSlug: RestaurantSlug.LivSalades
            },
            {
                title: {
                    en: 'Delilah',
                    fr: 'Delilah'
                },
                description: {
                    en: 'Romaine lettuce, kale, falafel, feta, cherry tomato, marinated red onion, cucumber, zaatar pita chips, fresh mint, chickpea, and cucumber-tahlni-mint vinaigrette. Dairy products.',
                    fr: 'Laitue romaine, chou frisé, falafel, feta, tomate cerise, oignon rouge mariné, concombre, chips de pita au zaatar, menthe fraîche, pois chiche et vinaigrette concombre-tahlni-menthe. Produits laitiers.'
                },
                image: '../../../assets/images/liv-salades/menu-favorites/liv-salades-menu-favorites-dish-3.png',
                restaurantSlug: RestaurantSlug.LivSalades
            }
        );
        this.maisonDuDestinFavorites = this.generateFeatures(
            {
                title: {
                    en: 'General Tao Chicken',
                    fr: 'Poulet général tao'
                },
                restaurantSlug: RestaurantSlug.MaisonDuDestin
            },
            {
                title: {
                    en: 'Spicy Green Pepper Beef',
                    fr: 'Bœuf épicé au poivre vert'
                },
                restaurantSlug: RestaurantSlug.MaisonDuDestin
            },
            {
                title: {
                    en: 'Pork Fried Rice',
                    fr: 'Riz frit au porc'
                },
                restaurantSlug: RestaurantSlug.MaisonDuDestin
            }
        );
        this.flatBellyDeliFavorites = this.generateFeatures(
            {
                title: {
                    en: 'Jumbo Smoked Meat Sandwich Trio',
                    fr: 'Trio Sandwich au Smoked Meat Jumbo'
                },
                description: {
                    en: 'Served with fries and drink.',
                    fr: 'Servi avec frites et boisson.'
                },
                image: '../../../assets/images/flat-belly-deli/menu-favorites/flat-belly-deli-menu-favorites-dish-1.png',
                restaurantSlug: RestaurantSlug.FlatBellyDeli
            },
            {
                title: {
                    en : 'FBD Burger Trio',
                    fr: 'Trio Burger FBD'
                },
                description: {
                    en: 'Served with fries and drink.',
                    fr: 'Servi avec frites et boisson.'
                },
                image: '../../../assets/images/flat-belly-deli/menu-favorites/flat-belly-deli-menu-favorites-dish-2.png',
                restaurantSlug: RestaurantSlug.FlatBellyDeli
            },
            {
                title: {
                    en : 'Smoked Meat Poutine',
                    fr: 'Poutine au Smoked Meat'
                },
                description: {
                    en: '',
                    fr: ''
                },
                image: '../../../assets/images/flat-belly-deli/menu-favorites/flat-belly-deli-menu-favorites-dish-3.png',
                restaurantSlug: RestaurantSlug.FlatBellyDeli
            }
        );
        this.downtownDhabaFavorites = this.generateFeatures(
            {
                title: {
                    en: 'Butter Chicken',
                    fr: 'Poulet au beurre'
                },
                description: {
                    en: 'Boneless chicken baked and cooked in a rich butter sauce.',
                    fr: 'Poulet désossé cuit et cuit dans une riche sauce au beurre.'
                },
                image: '../../../assets/images/downtown-dhaba/menu-favorites/downtown-dhaba-menu-favorites-dish-1.png',
                restaurantSlug: RestaurantSlug.DowntownDhaba
            },
            {
                title: {
                    en : 'Vegetarian Samosa (2)',
                    fr: 'Samoussa végétarien (2)'
                },
                description: {
                    en: 'Indian patty stuffed with potatoes and peas with various spices.',
                    fr: 'Vegetarian. Galette Indienne farcie de pommes de terre et petits pois avec diverses épices.'
                },
                image: '../../../assets/images/downtown-dhaba/menu-favorites/downtown-dhaba-menu-favorites-dish-2.png',
                restaurantSlug: RestaurantSlug.DowntownDhaba
            },
            {
                title: {
                    en : 'Gulab Jamun',
                    fr: 'Gulab jamun'
                },
                description: {
                    en: '',
                    fr: ''
                },
                image: '../../../assets/images/downtown-dhaba/menu-favorites/downtown-dhaba-menu-favorites-dish-3.png',
                restaurantSlug: RestaurantSlug.DowntownDhaba
            }
        );
        this.grilladosFavorites = this.generateFeatures(
            {
                title: {
                    en: '1/2 Chicken',
                    fr: '1/2 poulet'
                },
                description: {
                    en: 'Served with choice of side and drink.',
                    fr: 'Servi avec choix d\'accompagnement et breuvage.'
                },
                image: '../../../assets/images/grillados/menu-favorites/grillados-menu-favorites-dish-1.png',
                restaurantSlug: RestaurantSlug.Grillados
            },
            {
                title: {
                    en : '1/4 Chicken Breast',
                    fr: '1/4 poitrine de poulet'
                },
                description: {
                    en: 'Served with choice of side and drink.',
                    fr: 'Servi avec choix d\'accompagnement et breuvage.'
                },
                image: '../../../assets/images/grillados/menu-favorites/grillados-menu-favorites-dish-2.png',
                restaurantSlug: RestaurantSlug.Grillados
            },
            {
                title: {
                    en : 'Double Leg',
                    fr: 'Double cuisse'
                },
                description: {
                    en: 'Served with choice of side and drink.',
                    fr: 'Servi avec choix d\'accompagnement et breuvage.'
                },
                image: '../../../assets/images/grillados/menu-favorites/grillados-menu-favorites-dish-3.png',
                restaurantSlug: RestaurantSlug.Grillados
            }
        );
    }

    // RestoPage TODO: Add menu favourites above

    ngOnInit(): void {

        if (this.restaurant.slug === RestaurantSlug.Maharani) this.favorites = this.maharaniFavorites;
        else if (this.restaurant.slug === RestaurantSlug.Enchanteur) this.favorites = this.enchanteurFavorites;
        else if (this.restaurant.slug === RestaurantSlug.Lyla) this.favorites = this.lylaFavorites;
        else if (this.restaurant.slug === RestaurantSlug.Diolo) this.favorites = this.dioloFavorites;
        else if (this.restaurant.slug === RestaurantSlug.MerRouge) this.favorites = this.merRougeFavorites;
        else if (this.restaurant.slug === RestaurantSlug.Arahova) this.favorites = this.arahovaFavorites;
        else if (this.restaurant.slug === RestaurantSlug.Delicieux) this.favorites = this.delicieuxFavorites;
        else if (this.restaurant.slug === RestaurantSlug.Sham) this.favorites = this.shamFavorites;
        else if (this.restaurant.slug === RestaurantSlug.Nakamichi) this.favorites = this.nakamichiFavorites;
        else if (this.restaurant.slug === RestaurantSlug.PtitPlateau) this.favorites = this.ptitPlateauFavorites;
        else if (this.restaurant.slug == RestaurantSlug.Croqueteria) this.favorites = this.croqueteriaFavorites;
        //else if (this.restaurant.slug === "marquis") this.favorites = this.marquisFavorites;
        //else if (this.restaurant.slug === "sushi-plus-beaumont") this.favorites = this.sushiPlusBeaumontFavorites;
        else if (this.restaurant.slug === RestaurantSlug.KojiSoupe) this.favorites = this.kojiSoupeFavorites;
        else if (this.restaurant.slug === RestaurantSlug.TincSet) this.favorites = this.tincSetFavorites;
        else if (this.restaurant.slug === RestaurantSlug.FanfarePizza) this.favorites = this.fanfarePizzaFavorites;
        else if (this.restaurant.slug === RestaurantSlug.InochiExpress) this.favorites = this.inochiExpressFavorites;
        else if (this.restaurant.slug === RestaurantSlug.Basha) this.favorites = this.bashaFavorites;
        else if (this.restaurant.slug === RestaurantSlug.Uluwatu) this.favorites = this.uluwatuFavorites;
        else if (this.restaurant.slug === RestaurantSlug.ChezTauBay) this.favorites = this.chezTauBayFavorites;
        else if (this.restaurant.slug === RestaurantSlug.Boustan) this.favorites = this.boustanFavorites;
        else if (this.restaurant.slug === RestaurantSlug.ThaiExpressCdn) this.favorites = this.thaiExpressCdnFavorites;
        else if (this.restaurant.slug === RestaurantSlug.JavaUCdn) this.favorites = this.javaUCdnFavorites;
        else if (this.restaurant.slug === RestaurantSlug.McgillPizza) this.favorites = this.mcgillPizzaFavorites;
        else if (this.restaurant.slug === RestaurantSlug.Yuan) this.favorites = this.yuanFavorites;
        else if (this.restaurant.slug === RestaurantSlug.Segreta) this.favorites = this.segretaFavorites;
        else if (this.restaurant.slug === RestaurantSlug.QuesadaCdn) this.favorites = this.quesadaCdnFavorites;
        else if (this.restaurant.slug === RestaurantSlug.OiseauxDePassage) this.favorites = this.oiseauxDePassageFavorites;
        else if (this.restaurant.slug === RestaurantSlug.CafeVita) this.favorites = this.cafeVitaFavorites;
        else if (this.restaurant.slug === RestaurantSlug.BurgerBros) this.favorites = this.burgerBrosFavorites;
        else if (this.restaurant.slug === RestaurantSlug.Julians) this.favorites = this.juliansFavorites;
        else if (this.restaurant.slug === RestaurantSlug.SpiceBros) this.favorites = this.spiceBrosFavorites;
        else if (this.restaurant.slug === RestaurantSlug.SantaLucia) this.favorites = this.santaLuciaFavorites;
        else if (this.restaurant.slug === RestaurantSlug.Dakar) this.favorites = this.dakarFavorites;
        // else if (this.restaurant.slug === RestaurantSlug.Wallys) this.favorites = this.wallysFavorites;
        else if (this.restaurant.slug === RestaurantSlug.oFour) this.favorites = this.oFourFavorites;
        else if (this.restaurant.slug === RestaurantSlug.Karin) this.favorites = this.karinFavorites;
        else if (this.restaurant.slug === RestaurantSlug.MarcheMarema) this.favorites = this.marcheMaremaFavorites;
        else if (this.restaurant.slug === RestaurantSlug.ElephantThai) this.favorites = this.elephantThaiFavorites;
        else if (this.restaurant.slug === RestaurantSlug.Sushiyo) this.favorites = this.sushiyoFavorites;
        else if (this.restaurant.slug === RestaurantSlug.BoiteVegane) this.favorites = this.boiteVeganeFavorites;
        else if (this.restaurant.slug === RestaurantSlug.HeroBurgers) this.favorites = this.heroBurgersFavorites;
        else if (this.restaurant.slug === RestaurantSlug.JrsCafe) this.favorites = this.jrsCafeFavorites;
        else if (this.restaurant.slug === RestaurantSlug.CafeResonance) this.favorites = this.cafeResonanceFavorites;
        else if (this.restaurant.slug === RestaurantSlug.UbiSushi) this.favorites = this.ubiSushiFavorites;
        else if (this.restaurant.slug === RestaurantSlug.Melrose) this.favorites = this.melroseFavorites;
        else if (this.restaurant.slug === RestaurantSlug.ObsceneVegan) this.favorites = this.obsceneVeganFavorites;
        else if (this.restaurant.slug === RestaurantSlug.LestersDeli) this.favorites = this.lestersDeliFavorites;
        else if (this.restaurant.slug === RestaurantSlug.Barranco) this.favorites = this.barrancoFavorites;
        else if (this.restaurant.slug === RestaurantSlug.PetitSeoul) this.favorites = this.petitSeoulFavorites;
        else if (this.restaurant.slug === RestaurantSlug.Hooters) this.favorites = this.hootersFavorites;
        else if (this.restaurant.slug === RestaurantSlug.Darbar) this.favorites = this.darbarFavorites;
        else if (this.restaurant.slug === RestaurantSlug.JardinPetros) this.favorites = this.jardinPetrosFavorites;
        else if (this.restaurant.slug === RestaurantSlug.ChezAnna) this.favorites = this.chezAnnaFavorites;
        else if (this.restaurant.slug === RestaurantSlug.Krood) this.favorites = this.kroodFavorites;
        else if (this.restaurant.slug === RestaurantSlug.Maynard) this.favorites = this.maynardFavorites;
        else if (this.restaurant.slug === RestaurantSlug.MFourBurritos) this.favorites = this.mFourBurritosFavorites;
        else if (this.restaurant.slug === RestaurantSlug.Bocadillo) this.favorites = this.bocadilloFavorites;
        else if (this.restaurant.slug === RestaurantSlug.LaurierBBQ) this.favorites = this.laurierBbqFavorites;
        else if (this.restaurant.slug === RestaurantSlug.oFish) this.favorites = this.oFishFavorites;
        else if (this.restaurant.slug === RestaurantSlug.SushiSama) this.favorites = this.sushiSamaConcordiaFavorites;
        else if (this.restaurant.slug === RestaurantSlug.PaniniStop) this.favorites = this.paniniStopFavorites;
        else if (this.restaurant.slug === RestaurantSlug.PokeStationCdn) this.favorites = this.pokeStationCdnFavorites;
        else if (this.restaurant.slug === RestaurantSlug.TeochewFoodie) this.favorites = this.teochewFoodieFavorites;
        else if (this.restaurant.slug === RestaurantSlug.Tapigo) this.favorites = this.TapigoFavorites;
        else if (this.restaurant.slug === RestaurantSlug.BrigadePizza) this.favorites = this.brigadePizzaFavorites;
        else if (this.restaurant.slug === RestaurantSlug.AlAmine) this.favorites = this.alAmineFavorites;
        else if (this.restaurant.slug === RestaurantSlug.Cacao70) this.favorites = this.cacao70Favorites;
        // else if (this.restaurant.slug === RestaurantSlug.BernieBeigne) this.favorites = this.bernieBeigneFavorites;
        else if (this.restaurant.slug === RestaurantSlug.TacosNation) this.favorites = this.tacosNationFavorites;
        // else if (this.restaurant.slug === RestaurantSlug.PoissonnerieSherbrooke) this.favorites = this.poissonnerieSherbrookeFavorites;
        else if (this.restaurant.slug === RestaurantSlug.Mintar) this.favorites = this.mintarFavorites;
        else if (this.restaurant.slug === RestaurantSlug.AuxMerveilleuxDeFred) this.favorites = this.auxMerveilleuxDeFredFavorites;
        else if (this.restaurant.slug === RestaurantSlug.LaToile) this.favorites = this.laToileFavorites;
        else if (this.restaurant.slug === RestaurantSlug.SepLai) this.favorites = this.sepLaiFavorites;
        else if (this.restaurant.slug === RestaurantSlug.BistroDeLaCite) this.favorites = this.bistroDeLaCiteFavorites;
        else if (this.restaurant.slug === RestaurantSlug.Singhs) this.favorites = this.singhsFavorites;
        else if (this.restaurant.slug === RestaurantSlug.ChaiTeaLounge) this.favorites = this.chaiTeaLoungeFavorites;
        else if (this.restaurant.slug === RestaurantSlug.LaCroissanterieFigaro) this.favorites = this.laCroissanterieFigaroFavorites;
        else if (this.restaurant.slug === RestaurantSlug.LaFabriqueDeBagel) this.favorites = this.laFabriqueDeBagelFavorites;
        else if (this.restaurant.slug === RestaurantSlug.StudentTastyBiryani) this.favorites = this.studentTastyBiryaniFavorites;
        else if (this.restaurant.slug === RestaurantSlug.OsukaSushi) this.favorites = this.osukaSushiFavorites;
        else if (this.restaurant.slug === RestaurantSlug.Lalis) this.favorites = this.laliFavorites;
        else if (this.restaurant.slug === RestaurantSlug.ChezTaMere) this.favorites = this.chezTaMereFavorites;
        else if (this.restaurant.slug === RestaurantSlug.Chatpata) this.favorites = this.chatpataFavorites;
        else if (this.restaurant.slug === RestaurantSlug.FriteAlors) this.favorites = this.friteAlorsFavorites;
        else if (this.restaurant.slug === RestaurantSlug.Tibum) this.favorites = this.tibumFavorites;
        else if (this.restaurant.slug === RestaurantSlug.ThePerle) this.favorites = this.thePerleFavorites;
        else if (this.restaurant.slug === RestaurantSlug.Gongcha) this.favorites = this.gongChaFavorites;
        else if (this.restaurant.slug === RestaurantSlug.BuffaloBill) this.favorites = this.buffaloBillFavorites;
        else if (this.restaurant.slug === RestaurantSlug.SouvlakiGrecPlus) this.favorites = this.souvlakiGrecPlusFavorites;
        else if (this.restaurant.slug === RestaurantSlug.PizzaSport) this.favorites = this.pizzaSportFavorites;
        else if (this.restaurant.slug === RestaurantSlug.Tsujiri) this.favorites = this.tsujiriFavorites;
        else if (this.restaurant.slug === RestaurantSlug.SpartaChicken) this.favorites = this.spartaChickenFavorites;
        else if (this.restaurant.slug === RestaurantSlug.P23Dumplings) this.favorites = this.p23DumplingsFavorites;
        else if (this.restaurant.slug === RestaurantSlug.BurgzCantine) this.favorites = this.burgzCantineFavorites;
        else if (this.restaurant.slug === RestaurantSlug.Goplana) this.favorites = this.goplanaFavorites;
        else if (this.restaurant.slug === RestaurantSlug.PiriPiri) this.favorites = this.piriPiriFavorites;
        else if (this.restaurant.slug === RestaurantSlug.UkiyaIzakaya) this.favorites = this.ukiyaIzakayaFavorites;
        else if (this.restaurant.slug === RestaurantSlug.LivSalades) this.favorites = this.livSaladesFavorites;
        else if (this.restaurant.slug === RestaurantSlug.MaisonDuDestin) this.favorites = this.maisonDuDestinFavorites;
        else if (this.restaurant.slug === RestaurantSlug.FlatBellyDeli) this.favorites = this.flatBellyDeliFavorites;
        else if (this.restaurant.slug === RestaurantSlug.DowntownDhaba) this.favorites = this.downtownDhabaFavorites;
        else if (this.restaurant.slug === RestaurantSlug.Grillados) this.favorites = this.grilladosFavorites;

        // RestoPage TODO: Add else if case with RestaurantSlug and restaurantSlugFavorites above

        if (this.isBrowser) {
            // preload menu favorites images
            let images = new Array<HTMLImageElement>(3);
            if (this.favorites) {
                for (let i = 0; i < this.favorites.length; i++) {
                    images[i] = new Image();
                    images[i].src = this.favorites[i].image;
                }
            }
        }
    }

    generateFeatures(...menuFavorites: IMenuFavoriteRaw[]): IMenuFavorite[] {
        const res: IMenuFavorite[] = [];

        for (const favoriteRaw of menuFavorites) {
            const favorite: IMenuFavorite = {
                ...(favoriteRaw.title ? { title: new TranslatedText().deserialize(favoriteRaw.title) } : {}),
                ...(favoriteRaw.description ? { description: new TranslatedText().deserialize(favoriteRaw.description) } : {}),
                ...(favoriteRaw.image ? { image: favoriteRaw.image } : {}),
                type: FeatureTypeEnum.MenuFavorite,
                restaurantSlug: favoriteRaw.restaurantSlug
            }
            res.push(MenuFavoriteComponent.createFeatureFromMenuFavorite(favorite));
        }

        return res;
    }

    menuFavoriteClicked(favorite) {
        const modalRef = this.modalService.open(MenuFavoriteComponent);
        modalRef.componentInstance.favorite = favorite;
    }
}
