import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { trigger, state, style, animate, transition, group, sequence } from '@angular/animations';
import { MainService } from 'src/app/core/services/main.service';
import { TranslocoService } from '@ngneat/transloco';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Product } from 'src/app/core/models';
import { filter, first } from 'rxjs/operators';

const animations = [
	trigger('addToCrate', [
		state('not_added', style({ opacity: 1, width: 'var(--button-width)' })),
		state('adding', style({ width: '0%' })),
		// state('added', style({ display: 'none' })),
		transition('added => not_added', animate('550ms 500ms'))
		// transition('* => not_added', animate('200ms ease-in', style({ opacity: 1, width: '1.8rem' }))),
		// transition('not_added => *', animate('100ms ease-out', style({ width: '100%', opacity: 0.7 })))
	]),
	trigger('addingToCrate', [
		state('not_added', style({ width: '0%', color: '#009468' })),
		state('adding', style({ width: '40%' })),
		transition('* => adding', sequence([
			animate('675ms ease-out', style({ width: '100%', color: 'white' })),
			group([
				animate('500ms 300ms ease-out', style({ width: '40%' })),
				animate('350ms 300ms ease-out', style({ color: '#009468' })),
			])
		]))
	]),
	trigger('addedToCrate', [
		state('not_added', style({ display: 'none' })),
		state('adding', style({ display: 'none' })),
		// state('added', style({ width: 'flex' })),
		// transition('adding => added', animate('500ms 500ms ease-in', style({ opacity: 0.5, width: '60%' }))),
		transition('added => removing', group([
			animate('300ms ease-out', style({ width: 'var(--button-width)'}))
		]))
	])
];

enum CrateButtonState {
	NotAdded = 'not_added',
	Adding = 'adding',
	Added = 'added',
	Removing = 'removing',

}
@UntilDestroy()
@Component({
	selector: 'app-add-to-crate-button',
	templateUrl: './add-to-crate-button.component.html',
	styleUrls: ['./add-to-crate-button.component.css'],
	animations: animations
})
export class AddToCrateButtonComponent implements OnInit {

	CrateButtonState = CrateButtonState;
	buttonState = CrateButtonState.NotAdded;

	quantity = new UntypedFormControl(0);
	isSelfTriggered: boolean = false;
	isLoading: boolean = true;

	@Input() product: Product;
	@Input() initialQuantity = 0;

	constructor(
		private mainService: MainService,
		public translate: TranslocoService
	) {

	}

	ngOnInit(): void {
		this.mainService.crateLoaded$.pipe(filter(crate => crate != null)).subscribe(crate => {
			if (!this.isSelfTriggered) {
				this.initialQuantity = crate.items.find(item => item.product.id == this.product.id)?.quantity || 0;
				// if (this.initialQuantity == this.quantity.value) return;
				if (this.quantity.value > 0 && !crate.hasItem(this.product)) this.buttonState = CrateButtonState.Removing;
				else if (this.initialQuantity > 0 && crate.hasItem(this.product)) this.buttonState = CrateButtonState.Added;
				else this.buttonState = CrateButtonState.NotAdded;
				this.quantity.setValue(this.initialQuantity);
				this.isLoading = false;
			}
		})
	}

	ngAfterViewInit() {

	}

	public get crateButtonState(): CrateButtonState {
		return this.buttonState;
	}

	addToCrate(event) {
		//TODO: Handle errors
		this.buttonState = CrateButtonState.Adding;
		this.isSelfTriggered = true;
		this.quantity.setValue(1);
		this.mainService.addCrateItem(this.product, 1, null, null).subscribe({
			next: () => {
			},
			complete: () => this.isLoading = false,
			error: () => this.isLoading = true
		});
		event.preventDefault();
		event.stopPropagation();
	}

	addedToCrate(event) {
		if (event.toState != 'adding') return;
		this.buttonState = CrateButtonState.Added;
	}

	removedFromCrate(event) {
		if (event.fromState != 'added') return;
		this.buttonState = CrateButtonState.NotAdded;

	}

	decrementQuantity() {
		if (this.quantity.value > 0) this.quantity.setValue(this.quantity.value - 1);
		if (this.quantity.value == 0) this.buttonState = CrateButtonState.Removing;
		this.isSelfTriggered = true;
		this.isLoading = true;
		this.mainService.decrementItem(this.mainService.crate, this.product).subscribe(_ => {
			this.isLoading = false;
			this.isSelfTriggered = false;
		});
	}

	incrementQuantity() {
		this.quantity.setValue(this.quantity.value + 1);
		this.isSelfTriggered = true;
		this.isLoading = true;
		this.mainService.incrementItem(this.mainService.crate, this.product).subscribe(_ => {
			this.isLoading = false;
			this.isSelfTriggered = false;
		});
	}

}

