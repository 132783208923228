<app-navbar [navbarType]="'restaurants'"></app-navbar>
<div class="gift-card-redeem-container">
    <div class="inner-container">
        <h2 class="page-title"> {{ 'giftCard.redeem.title' | transloco }}</h2>
        <div *ngIf="giftCard != null" class="personalized-container">
            <h4 class="personalized-header">
                {{ giftCard.sender.name }} {{ 'giftCard.redeem.header' | transloco }}
            </h4>
            <div class="personalized-message">
                {{ 'common.quote.left' | transloco }}<span [innerHTML]="giftCard.message"></span>{{ 'common.quote.right' | transloco }}
            </div>
        </div>
        <div class="gift-card-wrapper">
            <img class="gift-card-image" [src]="giftCard != null ? giftCard?.theme?.front?.url : '../../../assets/images/gift-card/gift-card-default.png'">
            <!-- <img class="gift-card-image" [src]="isCodeValid ? giftCard.image : '../../../assets/images/gift-card/default-gift-card.svg'"> -->
        </div>
        <div class="input-message">
            {{ 'giftCard.redeem.enterCode' | transloco }}
        </div>
        <form [formGroup]="giftCodeForm" (ngSubmit)="onSubmit()" class="code-form">
            <div class="code-input-wrapper">
                <ng-otp-input #ngOtpInput [formCtrl]="giftCodeFormControls.code" class="gift-card-code-input form-control"
                    type="text" [config]="config"></ng-otp-input>
                <div class="code-pattern-error-message error-message" *ngIf="giftCodeFormControls.code.errors?.pattern">
                    {{ 'giftCard.redeem.invalidCharacter' | transloco }}
                </div>
            </div>
            <div *ngIf="giftCard != null && (user$ | async) != null" class="title-input-wrapper">
                <fieldset [disabled]="codeApplied">
                    <label for="title">{{ 'giftCard.redeem.titleInput' | transloco }}</label>
                    <input formControlName="title" type="text" class="gift-card-name-input form-control"
                    placeholder="{{ 'giftCard.redeem.titlePlaceholder' | transloco }}" [maxLength]="25">
                </fieldset>
                <div *ngIf="giftCodeFormControls.title.errors?.maxlength">
                </div>
            </div>
            <div *ngIf="giftCard != null && (user$ | async) != null" class="redeem-gift-card-button-wrapper">
                <button class="redeem-gift-card-button" type="submit" [disabled]="codeApplied">
                    {{ codeApplied ? ('giftCard.redeem.redeemed' | transloco) : ('giftCard.redeem.submit' | transloco) }}</button>
                <div *ngIf="error" class="redeem-gift-card-error-message error-message">
                    {{ 'giftCard.redeem.errors.generic' | transloco }}
                </div> 
            </div>
            <div *ngIf="(user$ | async) == null && showGuestUserMessage" class="login-required-message">
                {{ 'giftCard.redeem.loginRequired.1' | transloco }} 
                <a routerLink="/login" (click)="onLoginOrCreateAccountClick()">{{ 'giftCard.redeem.loginRequired.2' | transloco }}</a> 
                {{ 'common.or' | transloco }} 
                <a routerLink="/registration" (click)="onLoginOrCreateAccountClick()">{{ 'giftCard.redeem.loginRequired.3' | transloco }}</a> 
                {{ 'giftCard.redeem.loginRequired.4' | transloco }}
            </div>
            <ng-container>
                <div *ngIf="giftCardNotFoundError " class="error-message"> {{ 'giftCard.redeem.errors.invalidCode' | transloco }}</div>
                <div *ngIf="alreadyClaimedGiftCardError" class="error-message"> {{ 'giftCard.redeem.errors.alreadyClaimed' | transloco }}</div>
                <div *ngIf="genericGiftCodeError" class="error-message">{{ 'giftCard.redeem.errors.generic' | transloco }}</div>
            </ng-container>
        </form>
    </div>
</div>