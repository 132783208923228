<div class="date-time-container">
    <div class="start-end">
        <div class="selected">
            start
        </div>
        <div class="unselected">
            + end
        </div>
    </div>
    <div class="date-time-swap">
        <div class="grouped-buttons">
            <button [ngClass]="date == true ? 'active' : 'inactive'" (click)="setDate()">
                <strong>Date</strong>
            </button>
            <button [ngClass]="time == true ? 'active' : 'inactive'" (click)="setTime()">
                <strong>Time</strong>
            </button>
        </div>
    </div>
    <div *ngIf="date" class="date-container">
        <input type="date">
    </div>
    <div *ngIf="time" class="time-container">
    </div>
</div>

<div class="minimap">
    <google-map
        height="15rem"
        width="15rem"
    ></google-map>
</div>