<div class="modal-container">
    <div class="header">{{ header | transloco : headerParams }}</div>
    <div class="description" [innerHTML]="text | transloco : textParams"></div>
    <div class="items-container" *ngIf="showProducts; else showOptions">
        <ul>
            <li *ngFor="let item of items" class="item">
                {{ item.title }}
            </li>
        </ul>
    </div>
    <ng-template class="items-container" #showOptions>
        <div *ngFor="let item of items" class="item">
            <div class="item-title">{{ item.title }}</div>
            <ul class="options-container">
                <li *ngFor="let option of getUnavailableAndArchivedOptions(item)" class="option-title">
                    {{ option.title + ' ' + ('optionNoLongerAvailableModal.unavailable' | transloco) }}
                </li>
            </ul>
        </div>
    </ng-template>
    <div class="modal-footer">
        <button *ngIf="cancelButtonText == null; else showTwoButtons" (click)="activeModal.close()"
            class="single-button">{{ submitButtonText | transloco : submitButtonTextParams }}</button>
        <ng-template #showTwoButtons>
            <button type="button" (click)="activeModal.dismiss()" class="cancel-button">{{ cancelButtonText | transloco : cancelButtonTextParams }}</button>
            <button type="button" (click)="activeModal.close()" class="submit-button">{{ submitButtonText | transloco : submitButtonTextParams }}</button>
        </ng-template>
    </div>
</div>
