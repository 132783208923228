import { animate, style, transition, trigger } from "@angular/animations";
import { Component, Input } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Affiliate } from "src/app/core/models";
@Component({
    selector: 'app-referral-code-modal',
    templateUrl: './referral-code-modal.component.html',
    styleUrls: ['./referral-code-modal.component.css']
})
export class GiftBoxReferralCodeModalComponent {

    @Input() affiliate: Affiliate;

    constructor(public activeModal: NgbActiveModal) { }

}
