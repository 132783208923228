import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Category, CategoryType } from '../models/category.model';
import { HttpParams } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})

export class CategoryService {

    private entity: string = 'categories';

    constructor(private baseService: BaseService) { }

    query(categoryTypes?: CategoryType[], slugs?: string[]): Observable<Category[]> {
        let query: any = {};
        if (categoryTypes != null || slugs != null) {
            if (categoryTypes != null) query.type = categoryTypes;
            if (slugs != null) query.slug = slugs;
            // query = new HttpParams({ fromObject: { type: categoryTypes } });
        }
        return this.baseService.list(this.entity, query).pipe(map(res => res?.categories.map(category => new Category().deserialize(category))));
    }
}
