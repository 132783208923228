<div class="verification-container">
    <div class="container">
        <div class="verification-container-column col-md-5">
            <div class="steps-container" [style.transform]="'translateX(' + (codeSent ? '-50%' : '0px') + ')'">
                <div class="verification-step">
                    <form [formGroup]="sendCodeForm" (ngSubmit)="onSubmitSendCodeForm(sendCodeForm.value)">
                        <h2 class="verification-title">{{ 'verification.verifyNumberTitle' | transloco }}</h2>
                        <div class="form-group" [class.mb-4]="!phoneError">
                            <international-phone-number2 formControlName="phoneNumber" placeholder="{{ 'verification.placeholder' | transloco }}" [maxlength]="20" [defaultCountry]="route.snapshot.queryParams.countryCode ? route.snapshot.queryParams.countryCode : 'ca'" [required]="true" name="phone_number"></international-phone-number2>
                            <div class="error" *ngIf="phoneError">{{phoneError}}</div>
                        </div>
                        <div class="form-group">
                            <button class="btn btn-submit form-control" type="submit" [disabled]="loading">
                                <div *ngIf="loading" class="spinner-border spinner-border-sm" role="status">
                                    <span class="sr-only">{{ 'common.loading' | transloco }}</span>
                                </div>
                                <span *ngIf="!loading">
                                    {{ 'verification.verifyNumberButton' | transloco }}
                                </span>
                            </button>
                            <div class="sub-message">{{ 'verification.messageRates' | transloco }}</div>
                        </div>
                    </form>
                </div>
                <div class="verification-step">
                    <form [formGroup]="verifyCodeForm" (ngSubmit)="onSubmitVerifyCodeForm(verifyCodeForm.value)">
                        <h2 class="verification-title">{{ 'verification.verifyCodeTitle' | transloco }}</h2>
                        <div class="form-group" [class.mb-4]="!codeError" [style.margin-bottom]="codeError ? 0 : ''">
                            <input formControlName="code" type="text" class="form-control" [placeholder]="'verification.codePlaceholder' | transloco">
                            <div class="line"></div>
                        </div>
                        <div class="error mb-4" *ngIf="codeError">{{codeError}}</div>
                        <div class="form-group">
                            <button class="btn btn-submit form-control" type="submit" [disabled]="loading">
                                <div *ngIf="loading" class="spinner-border spinner-border-sm" role="status">
                                    <span class="sr-only">{{ 'common.loading' | transloco }}</span>
                                </div>
                                <span *ngIf="!loading">
                                    {{ 'verification.verifyCodeButton' | transloco }}
                                </span>
                            </button>
                            <div class="sub-message">{{ 'verification.noTextMessage' | transloco }} <a class="send-again-button" (click)="codeSent = false">{{ 'verification.sendAgain' | transloco }}</a></div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
