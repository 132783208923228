import { combineLatestWith, filter, finalize, first, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { OrderRequestData } from './../../core/services/order.service';
import { Address } from './../../core/models/address.model';
import { GiftCard } from './../../core/models/gift-card.model';
import { Renderer2, Component, OnInit, ViewChild, ElementRef, Inject, PLATFORM_ID, ViewChildren, QueryList, HostListener } from '@angular/core';
import { MainService } from 'src/app/core/services/main.service';
import { TranslocoService } from '@ngneat/transloco';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PromocodeComponent } from 'src/app/modals/promocode/promocode.component';
import { AddressModalComponent } from '../../modals/address-modal/address-modal.component';
import { isPlatformBrowser } from '@angular/common';
import { environment } from 'src/environments/environment';
import { GiftCardService } from 'src/app/core/services/gift-card.service';
import { forkJoin, merge, Observable, of } from 'rxjs';
import { Bag, BagItem, Order, OrderStatus, TranslatedTextType, User } from 'src/app/core/models';
import Dinero from 'dinero.js';
import { trigger, style, animate, transition, state } from '@angular/animations';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SupportComponent } from 'src/app/modals/support/support.component';
import { MapDirectionsService } from '@angular/google-maps';
import { ConfigService } from 'src/app/core/services/config.service';
import { parseGoogleAddress } from 'src/app/core/helpers/address.helper';
import { Address as GoogleAddress } from 'ngx-google-places-autocomplete/objects/address';
import { CrateService } from 'src/app/core/services/crate.service';
import { RestaurantService } from 'src/app/core/services/restaurant.service';
import * as turf from '@turf/turf';
import moment from 'moment';
import { loadStripe } from '@stripe/stripe-js';

const slideUpToggleAnimation = [
	trigger('slideUpToggle', [
		state('open', style({
			transform: 'translateY(0%)'
		})),
		state('closed', style({
			transform: 'translateY(100%) translateY(-72px)'
		})),
		transition('open => closed', [
			animate('300ms ease-in')
		]),
		transition('closed => open', [
			animate('200ms ease-in')
		])
	])
]

interface CoordinatePair {
	latitude: number;
	longitude: number;
}

@UntilDestroy()
@Component({
	selector: 'app-market-checkout',
	templateUrl: './checkout.component.html',
	styleUrls: ['./checkout.component.css'],
	animations: [slideUpToggleAnimation]
})

export class MarketCheckoutComponent implements OnInit {

	directionsResults$: Observable<google.maps.DirectionsResult | undefined>[];

	crate$: Observable<Bag>;
	address$: Observable<Address>;
	order$: Observable<Order>;

	isLoading: boolean = true;
	isBrowser: boolean;
	isSubmitting: boolean = false;

	//#region Delivery Details
	// TODO: create enum for tab names eventually
	tab: string = 'details';

	scheduledTime: Date;

	addApartment: boolean = false;

	savedCards = [];
	card;
	giftCards: GiftCard[];
	selectedGiftCard: GiftCard;
	//#endregion

	addTip: boolean = false;
	tipRadio: string = '0';
	tipValue: string = '0.00';

	cardElement: ElementRef;
	stripe;
	isCardEmpty: boolean = true;
	marketCheckoutForm: UntypedFormGroup;
	openNewCard: boolean = false;
	openBottomSheet: boolean = false;

	cardErrors;
	deliveryNoteError;

	itemGroups: { slug: string, name: TranslatedTextType, items: BagItem[] }[] = [];

	bagLat: number;
	bagLng: number;
	restaurantLat: number;
	restaurantLng: number;
	arcPaths: google.maps.Polyline[] = [];
	centerLat: number = 0;
	centerLng: number = 0;
	reconfigureMap: boolean = true;

	vendorCoordinates: CoordinatePair[] = [];
	vendorPaths: google.maps.Polyline[] = [];

	fixMap: boolean = false;
	markersSet: boolean = false;
	isMapLoaded: boolean = false;

	screenWidth: number;
	mobileThreshold: number = 992;
	isMobile: boolean;
	mapHeight;
	mapStyle: any;

	mapOptions: google.maps.MapOptions;
	markerOptions;
	renderOptions;

	restaurantIcon;
	houseIcon;

	disableDecrement: boolean = false;

	hasAddress;
	openAddressInput: boolean = false;
	currentAddress: Address;

	startDeliveryDate: Date;
	endDeliveryDate: Date;
	showTax: boolean = false;
	showOverflowPill: boolean = true;

	fees: any;
	taxes: any;

	crateAddress;
	hideLoading = false;

	@ViewChild('cardElement') set ce(cardElement: ElementRef) {
		this.cardElement = cardElement;
	}

	@ViewChildren('tip') tip: QueryList<ElementRef>;
	@ViewChild('addTipButton') tipButton: ElementRef;

	@HostListener('window:resize', ['$event'])
	onResize(_: any) {
		this.screenWidth = window.innerWidth;
		this.isMobile = window.innerWidth < this.mobileThreshold;
	}

	constructor(
		@Inject(PLATFORM_ID) platformId: Object,
		public elementRef: ElementRef,
		private renderer: Renderer2,
		private router: Router,
		private modalService: NgbModal,
		public mainService: MainService,
		private crateService: CrateService,
		public translate: TranslocoService,
		private formBuilder: UntypedFormBuilder,
		public giftCardService: GiftCardService,
		private mapDirectionsService: MapDirectionsService,
		public restaurantService: RestaurantService,
		public configService: ConfigService) {
		this.isBrowser = isPlatformBrowser(platformId);
		this.marketCheckoutForm = this.formBuilder.group({
			scheduledDateTime: [null, Validators.required],
			deliveryInstructions: [''],
			tipRadio: [''],
			tipAmountCustom: [''],
			reusableCrateCheckbox: [false],
			cardRadio: [''],
			apartmentNumber: [''],
			giftCard: ['']
		});

		this.renderer.listen('window', 'click', (e: Event) => {
			if (!this.tip.find(element => element.nativeElement === e.target)) {
				this.addTip = false;
			}
		})

		// Define screen and browser constants
		this.isBrowser = isPlatformBrowser(platformId);
		this.screenWidth = window.innerWidth;
		this.isMobile = window.innerWidth < this.mobileThreshold

		// Create Google Maps assets
		this.screenWidth = window.innerWidth;
		this.mapHeight = this.screenWidth > this.mobileThreshold ? 99 : '14.9rem';
		this.setupMapAssets();
	}

	ngOnInit(): void {
		//TODO: check if user has saved address
		//this.hasAddress = false;
		loadStripe(environment.STRIPE_KEY, { locale: this.translate.getActiveLang() as any }).then(res => {
			this.stripe = res;
		});

		this.crate$ = this.mainService.crateLoaded$;
		this.crate$.pipe(untilDestroyed(this)).subscribe(crate => {
			if (crate == null) return;
			this.itemGroups = this.groupByRestaurant(crate.items);
			this.disableDecrement = crate.itemCount <= 1;
		});

		this.address$ = this.mainService.addressLoaded$;
		this.address$.pipe(untilDestroyed(this)).subscribe(address => this.hasAddress = address != null)

		this.order$ = this.mainService.marketOrderLoaded$;
		this.order$.pipe(untilDestroyed(this)).subscribe(order => {
			if (order && order.status != OrderStatus.Initialized) this.router.navigate([this.translate.getActiveLang() + '/marché/order']);
			this.taxes = order?.fees.filter(fee => fee.category == 'taxes');
			this.fees = order?.fees.filter(fee => fee.category != 'taxes');
		})

		let o1$ = this.giftCardService.listGiftCards().pipe(tap(giftCards => {
			giftCards.sort((a, b) => a.balance.subtract(b.balance).getAmount())
			this.giftCards = giftCards;
			this.selectedGiftCard = this.giftCards[this.giftCards.length - 1];
		}));

		let o2$ = this.mainService.isProcessing$.pipe(filter(isProcessing => isProcessing != true), first(), mergeMap(_ => {
			let crate = this.mainService.crate;
			if (crate == null) return of(false);
			if (crate.address == null && this.mainService.address != null) return this.crateService.assignAddressToCrate(crate.id, this.mainService.address.id);
			else return of(true);
		}));

		o1$.pipe(combineLatestWith(o2$), mergeMap(_ => this.initializeOrder(true))).subscribe(); //TODO: Can probably handled server side as well
	}

	ngAfterViewInit(): void {
		if (!this.isBrowser) return;
	}

	// Tabs
	switchTab(tab: string) {
		this.tab = tab;
		if (tab == 'payment') {
			setTimeout(() => {
				const elements = this.stripe.elements();
				this.card = elements.create('card');
				this.card.mount(this.cardElement.nativeElement);
				this.card.addEventListener('change', ({ error }) => this.cardErrors = error && error.message);
				this.card.addEventListener('focus', () => this.marketCheckoutForm.controls.cardRadio.setValue('new-card'));
				this.card.on('change', (event) => this.isCardEmpty = event.empty);
			}, 85);
		}
	}

	// Date & Time
	onDateTimeScheduled(date: Date) {
		this.scheduledTime = date;
		this.reconfigureMap = false;
		this.initializeOrder().subscribe();
	}

	// Initialize Order - PUT IN MERGEMAP HERE
	initializeOrder(ignoreGiftCard = false) {
		return this.mainService.initializeOrder(this.mainService.crate, this.generateOrderData(ignoreGiftCard))
			.pipe(
				finalize(() => this.isLoading = false),
				switchMap((res) => {
					const restaurantIds = res.order.restaurants;
					return forkJoin(restaurantIds.map(restaurantAddress => this.restaurantService.getRestaurantById(restaurantAddress))).pipe(
						map(addresses => ({ res, addresses }))
					);
				}),
				tap({
					next: ({ res, addresses }) => {
						this.marketCheckoutForm.controls.deliveryInstructions.setValue(res.order.bag.address.notes);
						this.savedCards = res.paymentMethods;
						this.savedCards.length > 0 ?
							this.marketCheckoutForm.controls.cardRadio.setValue(this.savedCards[0].id) :
							this.marketCheckoutForm.controls.cardRadio.setValue('new-card');
						if (this.marketCheckoutForm.controls.tipRadio.value == '') {
							this.marketCheckoutForm.controls.tipRadio.setValue('15');
							this.tipRadioChange();
						}
						if (this.isBrowser) this.setMarkers(res.order, addresses);
					},


					error: (err) => {
						if (err.error.error == 'OrderAlreadyPlaced') this.router.navigate([this.translate.getActiveLang() + '/marché/order']);
						else this.displayErrorModal(err.error.error);
					}
				}));
	}

	// Address
	addAddress() {
		this.openAddressInput = true;
	}

	addApartmentClick() {
		this.addApartment = true;
		setTimeout(() => {
			document.getElementById('apartmentNumber').focus();
		}, 50)
	}

	handleGoogleAddressChange(googleAddress: GoogleAddress) {
		//TODO: handle address already existing, so don't need to open AddressDetailsModal 

		let address = parseGoogleAddress(googleAddress);
		let isAddressFound = address != null;
		this.currentAddress = address;

		if (!isAddressFound) return;

		this.mainService.updateAddress(address).subscribe(_ => {
			this.openAddressInput = false;
			//this.hasAddress = true;
		});
	}

	findIntermediates(coordinatePair: CoordinatePair) {
		// midpoint
		let midPointLng = (this.bagLng + coordinatePair.longitude) / 2;
		let midPointLat = (this.bagLat + coordinatePair.latitude) / 2;
		// circle diameter
		let diameter = Math.sqrt(Math.pow(coordinatePair.latitude - this.bagLat, 2) + Math.pow(coordinatePair.longitude + this.bagLng, 2)) / 2;
		// 'slope' of perpendicular line
		let slope = (coordinatePair.latitude - this.bagLat) / (coordinatePair.longitude + this.bagLng);
		slope = -1 / slope;

		let interPointLng = midPointLng + ((diameter / 2) * Math.cos(Math.atan(slope)));
		let interPointLat = midPointLat + ((diameter / 2) * Math.cos(Math.atan(slope)));

		let arcPoint: CoordinatePair = {
			latitude: interPointLat,
			longitude: interPointLng
		};

		return arcPoint;
	}

	checkAddress() {
		if (this.mainService.address) return;

		const modalRef = this.modalService.open(AddressModalComponent, {
			backdrop: 'static',
			keyboard: false,
		});
		modalRef.componentInstance.parent = 'market-checkout';
		return merge(modalRef.closed, modalRef.dismissed).pipe(mergeMap(address => {
			if (address == null || !(address instanceof Address)) throw new Error(this.translate.translate('addressModal.error'));
			return this.mainService.updateAddress(address);
		}), mergeMap(_ => this.initializeOrder(true)), untilDestroyed(this)).subscribe();
	}

	changeAddress() {
		// this.marketCheckoutForm.controls.apartmentNumber = null;
		this.marketCheckoutForm.controls.apartmentNumber.setValue('');
		this.openAddressInput = true;
		this.addApartment = false;
	}

	cancelAddress() {
		this.openAddressInput = false;
		this.addApartment = true;
	}

	// Payment
	captureStripePayment(order, secret: string) {
		let card = this.marketCheckoutForm.controls.cardRadio.value;

		let payment_method =
			card === 'new-card'
				? {
					card: this.card,
					billing_details: this.createBillingDetails(order.user, order.bag.address)
				} : card;

		this.stripe
			.confirmCardPayment(secret, { payment_method, setup_future_usage: 'off_session' })
			.then(
				(res) => {
					this.isLoading = this.isSubmitting = false;
					this.enableButtons();
					if (res.error) this.cardErrors = res.error.message;
					else {
						if (res.paymentIntent.status === 'requires_capture' || res.paymentIntent.status === 'succeeded') {
							this.mainService.scheduledDate = null;
							setTimeout(() => { //TOOD: necessary to wait a bit or else order will be initialized on followup and redirect to main
								this.router.navigate([this.translate.getActiveLang() + '/marché/order']);
							}, 425);
						}
					}
				},
				(err) => {
					this.cardErrors = 'checkout.cardErrors'
					this.isLoading = this.isSubmitting = false;
					this.enableButtons();
				}
			);
	}

	//TODO: Can be extracted to common class
	createBillingDetails(user: User, address: Address) {
		let billing_details: any = {
			name: user.name.first + ' ' + user.name.last,
			email: user.email,
			address: {
				city: address.city,
				country: address.country,
				line1: address.line1,
				line2: address.line2,
				postal_code: address.postal,
				state: address.province,
			},
		};
		if (user.number && user.number != '') billing_details.phone = user.number;
		return billing_details;
	}

	onSavedCardClick() {
		this.cardErrors = null;
	}

	generateOrderData(ignoreGiftCard = false) {
		// TODO: create market version of orderRequestData interface
		const data: OrderRequestData = {
			tip: Dinero({ amount: +((+this.tipValue * 100).toFixed(0)), currency: 'CAD' }),
			options: {
				crates: {
					reusable: this.marketCheckoutForm.controls.reusableCrateCheckbox.value
				}
			}
		};
		if (this.scheduledTime) data.scheduledDate = moment(this.scheduledTime).toISOString();
		if (!ignoreGiftCard && this.selectedGiftCard != null) data.giftCard = this.selectedGiftCard.id;
		return data;
	}

	getCardLogo(brand: string) {
		switch (brand) {
			case 'visa':
				return '../../../assets/images/icons/visa.svg';
			case 'amex':
				return '../../../assets/images/icons/american-express.svg';
			case 'mastercard':
				return '../../../assets/images/icons/mastercard.svg';
		}
	}

	onSelectGiftCard(id) {
		this.selectedGiftCard = this.giftCards.find(giftCard => giftCard.id == id);
	}

	calculateTotal(): Observable<Dinero.Dinero> {
		return this.order$.pipe(map(order => {
			if (!order) return null;
			order.tip = Dinero({ amount: +(+this.marketCheckoutForm.controls.tipAmountCustom.value * 100).toFixed(0), currency: 'CAD' });
			return order.tiplessTotal.add(order.tip);
		}))
	}

	calculateTaxes(): any {
		let total = Dinero({ amount: 0, currency: 'CAD' });
		for (let tax of this.taxes) {
			total = total.add(tax?.amount)
		}
		return total;
	}

	tipRadioChange() {
		// this.marketCheckoutForm.controls.tipAmountCustom.setValue(
		// 	(+this.marketCheckoutForm.controls.tipRadio.value).toFixed(2)
		// );
		this.marketCheckoutForm.controls.tipRadio.setValue(
			(+this.marketCheckoutForm.controls.tipRadio.value).toFixed(2)
		);

	}

	onCustomTipKeypress(event) {
		if (event.target.value.split('.').length > 1 && event.keyCode == 46) return false
		var charCode = (event.which) ? event.which : event.keyCode;
		if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57)) return false;
		return true;
	}

	onCustomTipChange(value: string) {
		this.tipValue = value;
		this.marketCheckoutForm.controls.tipRadio.reset();
	}

	onApplyTip() {
		//TODO: sets the value into the form
		this.marketCheckoutForm.controls.tipAmountCustom.setValue(
			this.marketCheckoutForm.controls.tipRadio.value
		);
		this.tipValue = this.marketCheckoutForm.controls.tipRadio.value;
		this.addTip = false;

	}

	onAddTip() {
		if (this.marketCheckoutForm.controls.tipAmountCustom.value == '') {
			this.marketCheckoutForm.controls.tipRadio.setValue('3.00');
		}
		if (this.showTax) this.showTax = false;
		this.addTip = true;
	}

	onDeleteDiscount(id: any) {

	}

	// Order Summary
	groupByRestaurant(crateItems: BagItem[]): any[] {
		return crateItems.reduce((list: any[], item: BagItem) => {
			let index = list.findIndex(sortedItem => sortedItem.slug == item.product?.restaurant?.slug);
			(index != -1) ? list[index].items.push(item) : list.push({ slug: item.product.restaurant.slug, name: item.product.restaurant.name, items: [item] });
			return list;
		}, []);
	}

	toggleBottomSheet() {
		this.openBottomSheet = !this.openBottomSheet;
	}

	onScroll(event) { // for order summary
		let epsilon = 1;
		if (event.target.offsetHeight + event.target.scrollTop + epsilon >= event.target.scrollHeight) {
			this.showOverflowPill = false;
		}
		else {
			this.showOverflowPill = true;
		}
	}

	checkOverflow() { // for order summary
		let element;
		// if (window.innerWidth >= 992) element = document.getElementById('orderSummary');
		element = document.getElementById('orderSummaryMobile');
		return element.offsetHeight < element.scrollHeight;
	}

	scrollDown() { // for order summary
		let element;
		// if (window.innerWidth >= 992) element = document.getElementById('orderSummary');
		element = document.getElementById('orderSummaryMobile');
		element.scrollBy(0, 150)
	}

	// toggleTax() {
	// 	this.showTax = !this.showTax;
	// }

	onClickAddPromoCode() {
		const modalRef = this.modalService.open(PromocodeComponent, {
			centered: true
		});
		modalRef.componentInstance.restaurant = this.mainService.restaurantInView;
		modalRef.componentInstance.orderType = 'market';
		modalRef.result.then(discountInstance => { }, _ => { });
	}

	// disableCheckoutButton(bag: Bag, scheduledDate: Date): boolean {
	// 	return bag == null || bag?.isEmpty ||
	// 		this.isUnderMinimumOrderAmount(bag);
	// }

	disableCheckoutButton(): boolean {
		return this.mainService.crate == null || this.mainService.crate?.isEmpty ||
			this.mainService.crate?.subtotal.lessThan(this.configService.MINIMUM_MARKET_ORDER_AMOUNT)
			|| this.scheduledTime == null;
	}

	// Map
	setupMapAssets() {
		this.restaurantIcon = {
			url: '../../assets/images/icons/restaurant.svg',
			scaledSize: {
				width: 60,
				height: 60
			},
			anchor: {
				x: 30,
				y: 50,
			}
		}
		this.houseIcon = {
			url: '../../assets/images/icons/house.svg',
			scaledSize: {
				width: 60,
				height: 60
			},
			anchor: {
				x: 30,
				y: 50,
			}
		}
		this.markerOptions = {
			origin: {
				icon: this.restaurantIcon
			},
			destination: {
				icon: this.houseIcon
			}
		};
		this.renderOptions = {
			polylineOptions: {
				strokeColor: '#FE2C7D',
			},
			suppressMarkers: true,
		};
		this.mapStyle = [
			{
				elementType: 'geometry',
				stylers: [{ color: '#f7f7f7' }]
			},
			{
				featureType: 'road',
				elementType: 'geometry',
				stylers: [{ color: '#ffffff' }]
			},
			{
				featureType: 'poi.business',
				stylers: [{ visibility: 'off' }]
			},
			{
				featureType: 'water',
				elementType: 'geometry',
				stylers: [{ color: '#51A3A3' }]
			}
		];
		this.mapOptions = {
			gestureHandling: "none",
			disableDefaultUI: true,
			styles: this.mapStyle,
			zoom: 12
		};
		// If mobile view - set zoom to 10
		if (this.mobileThreshold >= this.screenWidth) {
			this.mapOptions.zoom = 11;
		}

	}

	onMapLoaded(map) {
		setTimeout(() => this.isMapLoaded = true, 0);
		setTimeout(() => this.mapHeight = this.screenWidth > this.mobileThreshold ? 100 : '15rem', 50);
	}

	setMarkers(order, restaurants) {
		if (this.reconfigureMap == false)
			return;

		this.bagLat = order.bag.address?.loc?.coordinates[1];
		this.bagLng = order.bag.address?.loc?.coordinates[0];

		let sumLat = this.bagLat;
		let sumLng = this.bagLng;

		restaurants.forEach((restaurant) => {
			const [longitude, latitude] = restaurant.address?.loc?.coordinates;
			const coordinatePair: CoordinatePair = { longitude, latitude };
			sumLat += coordinatePair.latitude;
			sumLng += coordinatePair.longitude;
			this.vendorCoordinates.push(coordinatePair);
		})

		for (let i = 0; i < this.vendorCoordinates.length; i++) {
			let intermediatePoint: CoordinatePair = this.findIntermediates(this.vendorCoordinates[i]);

			let spline = turf.lineString([
				[this.bagLng, this.bagLat],
				[intermediatePoint.longitude, intermediatePoint.latitude],
				[this.vendorCoordinates[i].longitude, this.vendorCoordinates[i].latitude]
			]);
			let curved = turf.bezierSpline(spline, { sharpness: 1 });
			let path = curved.geometry.coordinates.map(coord => ({ lat: coord[1], lng: coord[0] }));


			const line: any = {
				path,
				strokeColor: "#FE2C7D",
				strokeOpacity: 1,
				strokeWeight: 5,
				geodesic: true,
			};

			this.arcPaths.push(line);
		}

		this.centerLat = sumLat / (this.vendorCoordinates.length + 1);
		this.centerLng = (sumLng / (this.vendorCoordinates.length + 1)) + 0.01;

		if (this.mobileThreshold < this.screenWidth) {
			this.centerLng += 0.02;
		}

		this.markersSet = true;
		this.directionsResults$ = [];

		this.vendorCoordinates.forEach((coords) => {
			const request: google.maps.DirectionsRequest = {
				destination: { lat: this.bagLat, lng: this.bagLng },
				origin: { lat: coords.latitude, lng: coords.longitude },
				travelMode: google.maps.TravelMode.DRIVING
			};

			this.directionsResults$.push(this.mapDirectionsService.route(request).pipe(map(response => response.result), untilDestroyed(this)));
		});
	}

	// Errors & Support
	displayErrorModal(errorMessage) {
		switch (errorMessage) {
			// case 'CreditCardPaymentTooSmall':
			//     this.giftPaymentInvalidError();
			//     break;
			// case 'ScheduledBeforeCurrentTime':
			//     this.scheduledBeforeCurrentTimeError();
			//     break;
			// case 'ScheduledAfterMaxDate':
			//     this.scheduledAfterMaxDateError();
			//     break;
			// case 'ProductNotAvailable':
			//     this.productNotAvailableError();
			//     break;
			// case 'BagRegenerationDiscrepancy':
			//     this.bagRegenerationDiscrepancy();
			//     break;
			// case 'OrderTooSmall':
			//     this.orderTooSmallError();
			//     break;
			case 'BagAddressNotFound':
				this.checkAddress();
				break;
			// case 'MissingBagId':
			//     this.router.navigate(['/']);
			//     break;
			// case 'OptionNotAvailable':
			//     this.optionNotAvailableError();
			//     break;
			// case 'OrderAlreadyPlaced':
			//     this.router.navigate([this.translate.getActiveLang() + '/order'])
			//     break;
			// case 'RestaurantMenuClosed':
			//     this.menuClosedError();
			//     break;
			// case 'ProductNotInMenu':
			//     this.productNotInMenuError();
			//     break;
			default: this.supportMessageError(true)
		};
	}

	supportMessageError(isEmergency: boolean) {
		const modalRef = this.modalService.open(SupportComponent, {
			backdrop: 'static',
			keyboard: false,
			centered: true
		});
		modalRef.componentInstance.emergency = isEmergency;
		return modalRef.result.then(_ => { }, _ => window.location.reload());
	}

	isUnderMinimumOrderAmount(bag: Bag): boolean {
		return bag?.subtotal.lessThan(this.configService.MINIMUM_EXPO_POCHA_ORDER_AMOUNT);
	}

	// Submit Order 
	disableButtons() {
		this.marketCheckoutForm.controls.reusableCrateCheckbox.disable();
		this.marketCheckoutForm.controls.deliveryInstructions.disable();
		this.marketCheckoutForm.controls.tipRadio.disable();
		this.marketCheckoutForm.controls.tipAmountCustom.disable();
	}

	enableButtons() {
		this.marketCheckoutForm.controls.reusableCrateCheckbox.enable();
		this.marketCheckoutForm.controls.deliveryInstructions.enable();
		this.marketCheckoutForm.controls.tipRadio.enable();
		this.marketCheckoutForm.controls.tipAmountCustom.enable();
	}

	submitOrder() {
		this.mainService.initializeOrder(this.mainService.crate, this.generateOrderData()).pipe(untilDestroyed(this)).subscribe({
			next: res => { //TODO: should compare with payment method enum instead of string
				let order = res.order;
				if (order.paymentMethod == 'gift_card') {
					this.mainService.confirmOrder(this.mainService.crate).pipe(untilDestroyed(this)).subscribe({
						next: _ => this.router.navigate([this.translate.getActiveLang() + '/marché/order']),
						//TODO: handle errors
						error: err => { console.log(err) }
					})
				}
				else {
					this.captureStripePayment(res.order, res.secret)
				}
			},
			error: err => {
				console.log(err)
				this.displayErrorModal(err.error.error);
				this.isLoading = this.isSubmitting = false;
				this.enableButtons();
			}
		});
	}

	async handleForm(e) {
		e.preventDefault();
		this.isSubmitting = true;
		this.disableButtons();
		this.submitOrder();
	}
}
