<google-map [center]="{ lat: latitude, lng: longitude }" [zoom]="zoom" height="100%" width="100%" [options]="options">
    <map-marker [position]="{lat: latitude, lng: longitude}"></map-marker>
    <map-circle [center]="{lat: latitude, lng: longitude}" [radius]="radius" 
    [options]="{ fillColor: radiusFillColor, visible: showRadius, strokeWeight: 0 }"></map-circle>
</google-map>

<!-- <agm-map [latitude]="mapLat" [longitude]="mapLng" [styles]="style" [mapDraggable]="true"
    [disableDefaultUI]="true" [zoom]="mapZoom" [usePanning]="true">
    <agm-marker [latitude]="mapLat" [longitude]="mapLng"></agm-marker>
    <agm-circle [latitude]="mapLat" [longitude]="mapLng" [radius]="radius"
        [fillColor]="radiusFillColor" [visible]="showRadius"></agm-circle>
</agm-map> -->
