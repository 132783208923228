<div class="overlay" [@overlayToggle]="bottomSheetOpen ? 'open' : 'closed'"
    (click)="bottomSheetOpen = false; walletPaneOpen = false;" *ngIf="(bag$ | async) && !(bag$ | async)?.isEmpty"
    [style.pointer-events]="bottomSheetOpen ? 'auto' : 'none'"></div>
<div *ngIf="(bag$ | async) && (!(bag$ | async)?.isEmpty)" class="bottom-sheet-container"
    [@bottomSheetToggle]="walletPaneOpen ? 'walletOpened' : (bottomSheetOpen ? 'open' : 'closed')"
    [ngClass]="{'bottom-sheet-checkout': (page$ | async) == ExpoBagPage.Checkout, 'payment-mounted': isPaymentMounted, 'wallet-open': walletPaneOpen, 'bottom-sheet-followup': (page$ | async) == ExpoBagPage.Order }">
    <div class="top-line">
    </div>
    <button type="button" #bottomSheetContent class="bottom-sheet-button" (click)="toggleBottomSheet()">
        <div class="bottom-sheet-button-wrapper">
            <div class="bottom-sheet-button-left">
                <div class="bottom-sheet-button-bag" class="bag bag-black">
                    <img id="animated-bag" [src]="bagImgSrc" alt="">
                    <div [ngStyle]="{'font-size': largeBag === true ? '0.8rem' : '1rem','color': totalItems === 0 ? '#000000' : '#FFFFFF'}"
                        class="bag-items-total">
                        {{ totalItems }}
                    </div>
                </div>
                <div class="bottom-sheet-button-title">{{ 'expo.bag.orderSummary' | transloco }}</div>
            </div>
            <div class="bottom-sheet-button-right bottom-sheet-button-price">
                <ng-container *ngIf="(page$ | async) == ExpoBagPage.Menu">
                    {{ (bag$ | async)?.subtotal | price | translocoCurrency }}<span class="plusTax"> +tx</span>
                </ng-container>
                <ng-container *ngIf="(page$ | async) != ExpoBagPage.Menu">
                    {{ ((order$ | async)?.total || virtualizedOrder?.total) | price | translocoCurrency }}
                </ng-container>
            </div>
        </div>
    </button>
    <div class="bottom-sheet-content">
        <div (scroll)="onScroll($event)" id="orderSummaryMobile" class="expo-checkout-order-summary-container">
            <div *ngIf="showOverflowPill && checkOverflow()" class="overflow-pill">
                <button (click)="scrollDown()" class="overflow-pill-button" type='button'>
                    <div>{{ 'checkout.scrollForMore' | transloco }}</div>
                    <div class="arrow-wrapper">
                        <img class="arrow" src="../../../../assets/images/white-arrow.png" />
                    </div>
                </button>
            </div>
            <div class="bag-items-container">
                <div class="bag-items-vendor">{{ (restaurant$ | async)?.name }}</div>
                <div class="bag-items">
                    <expo-bag-item [item]="item" [bagSize]="(bag$ | async).itemCount"
                        [disableDecrement]="disableDecrement" [page]="(page$ | async)"
                        [expositionSlug]="exposition.slug" [restaurantSlug]="(restaurant$ | async)?.slug"
                        *ngFor="let item of (bag$ | async)?.items"></expo-bag-item>
                </div>
            </div>
        </div>
        <!-- Bag Checkout || Followup HMTL - START -->
        <ng-container *ngIf="(page$ | async) != ExpoBagPage.Menu">
            <div class="expo-checkout-order-price-summary-container">
                <div class="expo-checkout-order-price-summary">
                    <div class="expo-checkout-order-subtotal expo-checkout-order-price-detail">
                        <span>{{'checkout.subtotal' | transloco}}</span>
                        <span>{{ (bag$ | async)?.subtotal | price | translocoCurrency }}</span>
                    </div>
                    <ng-container *ngIf="((order$ | async)?.fees || virtualizedOrder?.fees) as fees">
                        <div *ngFor="let fee of fees" class="expo-checkout-order-fee expo-checkout-order-price-detail">
                            <div class="expo-checkout-order-fee-type">{{ fee.title }}</div>
                            <div class="expo-checkout-order-fee-amount">
                                {{ fee.amount | price | translocoCurrency }}
                            </div>
                        </div>
                    </ng-container>
                    <ng-container
                        *ngIf="((page$ | async) == ExpoBagPage.Order) || ((page$ | async) == ExpoBagPage.Checkout)">
                        <div class="expo-checkout-order-tip expo-checkout-order-price-detail">
                            <span>{{'checkout.tipRestaurant' | transloco}}</span>
                            <span>{{ (order$ | async)?.tip || tip | price | translocoCurrency }}</span>
                        </div>
                    </ng-container>
                    <!-- Discount Section - START -->
                    <!-- <div *ngFor="let discountInstance of (order$ | async)?.discounts"
                        class="checkout-text-both-ends discount">
                        <div class="discount-left">
                            <span class="discount-title">{{ discountInstance.discount.title }}</span>
                            <button type="button" class="delete-discount-btn"
                                (click)="onDeleteDiscount(discountInstance['_id'])">
                                <img src="../../assets/images/icons/close.svg"></button>
                        </div>
                        <div class="discount-right discount-price">-{{ discountInstance?.effects.total.value | price |
                            translocoCurrency }}</div>
                        </div> -->
                    <!-- Discount Section - END -->
                </div>
                <div class="order-summary-total-price expo-checkout-order-total-price">
                    <b>{{ 'checkout.total' | transloco }}</b>
                    <b>{{ ((order$ | async)?.total || virtualizedOrder?.total) | price | translocoCurrency }}</b>
                </div>
            </div>
        </ng-container>
        <!-- Bag Followup - PAYMENT INFO - START -->
        <!-- <ng-container *ngIf="(page$ | async) == ExpoBagPage.Order">
            <div class="order-payment-method-container">
                <div class="order-payment-method-title">
                    {{ 'followup.paymentMethod' | transloco }}
                </div>
                <div class="payment-method" *ngFor="let balance of (order$ | async)?.payment.balances">
                    <div class="card-wrapper">
                        <div class="card-image-container"
                            [ngClass]="{ 'gift-card-image-container' : balance.type == 'gift_card'}">
                            <img class="visa-card card-image" *ngIf="balance.type == 'card'"
                                [src]="getCardLogo(balance.brand)" />
                            <img class="gift-card card-image" *ngIf="balance.type == 'gift_card'"
                                [src]="(order$ | async)?.payment?.giftCard?.theme?.front?.url" />
                        </div>
                        <div class="order-payment-method-type">{{ balance.type }} {{ balance?.number }}</div>
                    </div>
                    <div class="payment-amount">{{ balance.value | price | translocoCurrency }}</div>
                </div>
            </div>
        </ng-container> -->
        <!-- Bag Followup HMTL - PAYMENT INFO - END -->
        <ng-container *ngIf="(page$ | async) == ExpoBagPage.Menu">
            <div *ngIf="!(order$ | async)?.isActive" class="bag-checkout-button-wrapper">
                <button [disabled]="disableCheckoutButton(bag$ | async, scheduledDate$ | async) || isLoading"
                    class="bag-checkout-button" routerLink="checkout"
                    (click)="bottomSheetOpen = false; clickCheckout()">
                    <ng-container *ngIf="!isLoading">
                        <span
                            *ngIf="!disableCheckoutButton(bag$ | async, scheduledDate$ | async); else disabledCheckout">{{
                            'bag.checkout' | transloco }}</span>
                        <ng-template #disabledCheckout>
                            <span *ngIf="(bag$ | async) == null; else bagEmpty">{{ 'expo.bag.errors.bagNotFound' |
                                transloco }}</span>
                            <ng-template #bagEmpty>
                                <span *ngIf="(bag$ | async)?.isEmpty; else outsideOfRestaurantHours">{{
                                    'expo.bag.errors.bagEmpty' | transloco }}</span>
                            </ng-template>
                            <ng-template #outsideOfRestaurantHours>
                                <span
                                    *ngIf="isOutsideRestaurantHours(bag$ | async, scheduledDate$ | async); else minimumAmount">{{
                                    'expo.bag.errors.outsideOfRestaurantHours' | transloco }}</span>
                            </ng-template>
                            <ng-template #minimumAmount>
                                <span *ngIf="isUnderMinimumOrderAmount(bag$ | async)">{{ 'expo.bag.errors.minimumAmount'
                                    | transloco : { amount: getMissingAmount(bag$ | async) | price | translocoCurrency }
                                    }}</span>
                            </ng-template>
                        </ng-template>
                    </ng-container>
                    <div *ngIf="isLoading" class="spinner-border spinner-border-sm text-light" role="status">
                        <span class="sr-only">{{ 'common.loading' | transloco }}</span>
                    </div>
                </button>
                <div class="extra-payment-method-wrapper" [style.display]="isPaymentMounted ? 'block' : 'none'">
                    <div class="payment-form-separator">
                        <div class="line"></div>
                        <div class="payment-form-separator-or">
                            {{ 'common.or' | transloco }}
                        </div>
                    </div>
                    <button id="payment-chin" type="button" (click)="walletPaneOpen = !walletPaneOpen;"
                        class="extra-payment-method-prompt">
                        {{ 'expo.bag.payWith' | transloco }}
                        <img *ngIf="walletType == WalletType.Google" class="wallet-logo"
                            src="../../../../assets/images/wallets/Google_Pay_Logo.svg">
                        <img *ngIf="walletType == WalletType.Apple" class="wallet-logo"
                            src="../../../../assets/images/wallets/Apple_Pay_Logo.svg">
                        <span class="dropdown-caret">
                            <svg class="expo-nav-icon" width="10" height="8">
                                <polygon points="0,0 5,5 10,0" />
                            </svg>
                        </span>
                    </button>
                    <div class="extra-payment-method-content-wrapper">
                        <expo-bag-note (notePanelOpen)="notePanelOpen = true"></expo-bag-note>
                        <div class="bag-divider"></div>
                        <expo-tip [order]="((order$ | async) || virtualizedOrder)" (tipUpdated)="tipUpdated($event)">
                        </expo-tip>
                        <div class="bag-divider"></div>
                        <div id="payment-request-button">
                            <!-- A Stripe Element will be inserted here. -->
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</div>