import { Component, OnInit, AfterViewInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { BaseService } from '../core/services/base.service';
import { MainService } from '../core/services/main.service';

@Component({
	selector: 'app-promotions',
	templateUrl: './promotions.component.html',
	styleUrls: ['./promotions.component.css']
})
export class PromotionsComponent implements OnInit {

	promotionForm: UntypedFormGroup;

	constructor(
		private formBuilder: UntypedFormBuilder,
		private router: Router,
		public translate: TranslocoService,
		private baseService: BaseService,
		public mainService: MainService
	) {
		this.promotionForm = this.formBuilder.group({
			name: ['', Validators.required],
			email: ['', Validators.email]
		});
	}

	ngOnInit(): void {
	}
	ngAfterViewInit() {
		this.positionRadish();
	}

	onSubmit(data) {
		this.baseService.create('campaigns/startupfest',
			{ name: data.name, email: data.email, language: this.translate.getActiveLang(), isGift: false }).subscribe(_ => {
				this.redirectUser();
			})
	}

	redirectUser() {
		localStorage.setItem('redirect_url', 'promotions')
		this.router.navigate(['promotions/order'])
	}

	isFormValid() {
		return !(this.promotionForm.invalid || this.promotionForm.controls.email.value == '');
	}

	generateRandomNum(min, max, int = false) {
		if (int) return Math.floor(Math.random() * (max - min) + min);
    else return (Math.random() * (max - min) + min);
  }
  positionRadish() {  
    // for (let i=0; i<21; i++){
    //   let el = document.getElementById('radish'+i);
    //   if (i < 13) el.style.width = '' + this.generateRandomNum(2,3) + 'rem';
    //   else if (i > 13) el.style.width = '' + this.generateRandomNum(1,3, true) + 'rem';
    //   el.style.height = el.style.width
      
    //   if (i < 4) {
    //     el.style.left = '0';
    //     el.style.top = '' + (i * 15) + '%';
    //     if (i % 2 == 0) el.style.transform = 'rotate(45deg)';
    //     else el.style.transform = 'rotate(-45deg)';
    //   } 
    //   else if (i < 8) {
    //     el.style.right = '0';
    //     el.style.top = '' + ((i-4) * 15) + '%';
    //     if (i % 2 == 0) el.style.transform = 'rotate(-45deg)';
    //     else if (i % 2 != 0) el.style.transform = 'rotate(45deg)';
    //   } 
    //   else if (i < 13) {
    //     el.style.top= '0';
    //     el.style.right = '' + ((i-7) * 25) + '%';
    //     el.style.position = 'absolute';
    //     if (i % 2 == 0) el.style.transform = 'rotate(-45deg)';
    //     else if (i % 2 != 0) el.style.transform = 'rotate(45deg)';
    //   }
    //   else if (i < 17) {
    //     el.style.left = '4%';
    //     el.style.top = '' + ((i-13) * 15 + 7) + '%'
    //     if (i % 2 == 0) el.style.transform = 'rotate(-30deg)';

    //   }
    //   else if (i < 21) {
    //     el.style.right = '4%';
    //     el.style.top = '' + ((i-17) * 15 + 7) + '%'
    //     if (i % 2 != 0) el.style.transform = 'rotate(-30deg)';
    //   }
    // }

    for (let i=0; i<36; i++){
      let el = document.getElementById('radish'+i);
      if (!el) return;
      if (i < 19) el.style.width = '3.5rem';
      // if (i < 19) el.style.width = '' + this.generateRandomNum(4,5) + 'rem';
      else if (i > 19) el.style.width = '' + this.generateRandomNum(2,4, true) + 'rem';
      el.style.height = el.style.width
      
      if (i < 7) {
        el.style.left = '0';
        el.style.top = '' + (i * 15) + '%';
        if (i % 2 == 0) el.style.transform = 'rotate(45deg)';
        else el.style.transform = 'rotate(-45deg)';
      } 
      else if (i < 14) {
        el.style.right = '0';
        el.style.top = '' + ((i-7) * 15) + '%';
        if (i % 2 == 0) el.style.transform = 'rotate(-45deg)';
        else if (i % 2 != 0) el.style.transform = 'rotate(45deg)';
      } 
      else if (i < 19) {
        el.style.top= '0';
        el.style.right = '' + ((i-13) * 25) + '%';
        el.style.position = 'absolute';
        if (i % 2 == 0) el.style.transform = 'rotate(-45deg)';
        else if (i % 2 != 0) el.style.transform = 'rotate(45deg)';
      }
      else if (i < 25) {
        el.style.left = '4%';
        el.style.top = '' + ((i-19) * 15 + 7) + '%'
        if (i % 2 == 0) el.style.transform = 'rotate(-30deg)';

      }
      else if (i < 31) {
        el.style.right = '4%';
        el.style.top = '' + ((i-25) * 15 + 7) + '%'
        if (i % 2 != 0) el.style.transform = 'rotate(-30deg)';
      }
      
    }
    
  }
  useLanguage(language: string) {
    this.router.navigateByUrl(this.router.url.replace(this.translate.getActiveLang(), language));
    this.mainService.setLanguage(language);
  }
}
