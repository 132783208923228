<div class="page-container">
    <div class="top-container">
        <div id="restaurant-v2-content-container" class="content-container"
        [style.font-family]="configService.getFont(restaurant.slug)">
            <div *ngIf="!tab" class="default-content">
                <div class="page-section top-content">
                    <div class="logo-container">
                        <img class="logo" [src]="restaurant?.primaryLogoImageUrl" alt="">
                    </div>
                    <div class="dropdown-location-row">
                        <div class="dropdown-location-row-item restaurant-district">
                            <svg class="coordinates-icon icon" [style.height.px]="22" [style.width.px]="22"  viewBox="0 0 326.04 415.99">
                                <path [attr.fill]="configService.getPrimaryColor(restaurant.slug)" d="M307.76,87.72A160,160,0,0,0,181.18.91a182.05,182.05,0,0,0-36.31,0A160,160,0,0,0,18.28,87.71,172.89,172.89,0,0,0,42,278L150.66,410.15a16,16,0,0,0,22.52,2.2,15.77,15.77,0,0,0,2.2-2.2L284.07,278A172.89,172.89,0,0,0,307.76,87.72ZM163,224a64,64,0,1,1,64-64A64,64,0,0,1,163,224Z"/>
                            </svg>
                            <span class="district" [style.font-size.px]="20">
                                {{ restaurant.address.region.title }}
                                <!-- {{ restaurant.address.region.title }} -->
                            </span>
                        </div>
                        <app-hours-dropdown class="dropdown-location-row-item" [schedule]="restaurant.schedule" [restaurantSlug]="restaurant.slug" [iconColor]="configService.getPrimaryColor(restaurant.slug)" [fontSize]="configService.getFrontPageFontSize(restaurant.slug)" [iconSize]="configService.getFrontPageClockIconSize(restaurant.slug)"></app-hours-dropdown>
                    </div>
                    <div class="rating-row" *ngIf="reviews && reviews.length > 0">
                        <app-rating [restaurantSlug]="restaurant.slug" [reviews]="reviews" [iconColor]="configService.getPrimaryColor(restaurant.slug)" [fontSize]="configService.getFrontPageFontSize(restaurant.slug)"></app-rating>
                    </div>
                    <div [routerLink]="['./menu']" class="navigation-button"
                        [style.background-color]="configService.getPrimaryColor(restaurant.slug)"
                        [style.color]="configService.getSecondaryColor(restaurant.slug)"
                        [style.border-radius]="configService.getBorderRadius(restaurant.slug)">
                        {{ 'restaurant.navigation.orderNow' | transloco }}
                    </div>
                    <div class="secondary-buttons-container">
                        <div [routerLink]="['./about']" class="navigation-button"
                            [style.color]="configService.getPrimaryColor(restaurant.slug)"
                            [style.padding]="0"
                            [style.border-bottom]="'1px solid' + configService.getPrimaryColor(restaurant.slug)">
                            {{ 'restaurant.navigation.about' | transloco }}
                        </div>
                        <div [routerLink]="['./story']" class="navigation-button"
                            [style.color]="configService.getPrimaryColor(restaurant.slug)"
                            [style.padding]="0"
                            [style.border-bottom]="'1px solid' + configService.getPrimaryColor(restaurant.slug)">
                            {{ 'restaurant.navigation.story' | transloco }}
                        </div>
                    </div>
                </div>
                <!-- <app-about-v2 class="page-section" [restaurant]="restaurant"></app-about-v2> -->
                <!-- <app-restaurant-reviews class="page-section"></app-restaurant-reviews> -->
            </div>
            <router-outlet></router-outlet>
        </div>
        <div class="photo-container" [style.font-family]="configService.getFont(restaurant.slug)" [style.background-image]="'url(' + (featureImage | async) + ')'" appCenterAndFitBackgroundImage>
            <div class="inner-photo-container">
                <ng-container *ngIf="{description: featureText | async, title: featureTitle | async} as feature">
                    <div *ngIf="feature.title || feature.description" class="feature-text" [style.border-radius]="configService.getBorderRadius(restaurant.slug)">
                        <div class="feature-text-title" [innerHTML]="feature.title"></div>
                        <div class="feature-text-description" [innerHTML]="feature.description"></div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
    <!-- <app-restaurant-reviews [restaurant]="restaurant"></app-restaurant-reviews> -->
</div>
