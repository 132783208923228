<div class="order-settings-container">
    <div *ngIf="isNewAddressPageOpen" class="add-address-container">
        <div class="add-address-header">
            <button class="return-button" (click)="isNewAddressPageOpen = false">
                <svg class="bi bi-arrow-left-short" width="2em" height="2em" viewBox="0 0 16 16" fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd"
                        d="M7.854 4.646a.5.5 0 0 1 0 .708L5.207 8l2.647 2.646a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 0 1 .708 0z" />
                    <path fill-rule="evenodd" d="M4.5 8a.5.5 0 0 1 .5-.5h6.5a.5.5 0 0 1 0 1H5a.5.5 0 0 1-.5-.5z" />
                </svg>
            </button>
            <div class="add-address-title">
                {{'menuTypeDropdown.addNewAddress' | transloco }}
            </div>
        </div>
        <div id="input-container" class="add-address-input-container">
            <input *ngIf="(orderType$ | async) === 'delivery' && isBrowser" [placeholder]="'navbar.addressPlaceholder' | transloco"
                id="address-navbar-input" ngx-google-places-autocomplete #placesRef="ngx-places"
                class="add-address-input" (onAddressChange)="handleGoogleAddressChange($event)" #inputAddressFocus
                #addressNavbarInput>
        </div>
    </div>
    <ng-container *ngIf="isAddressConfirmationPageOpen && !isNewAddressPageOpen">
        <app-address-confirmation [address]="preselectedAddress" (onSaveAddressClick)="onConfirmAddress($event)" (onCancelClick)="onCancelConfirmAddress()" [test]="isAddressConfirmationPageOpen"></app-address-confirmation> 
    </ng-container>
    <ul ngbNav #nav="ngbNav" [(activeId)]="activeTabId" class="nav-tabs"
        [ngStyle]="{'border-bottom': isNewAddressPageOpen || isAddressConfirmationPageOpen ? 'none' : '1px solid #dee2e6' }">
        <li [ngbNavItem]="1" *ngIf="!isNewAddressPageOpen && !isAddressConfirmationPageOpen" [ngClass]="{'active': (orderType$ | async) === 'delivery'}">
            <a ngbNavLink *ngIf="!disabledOptionsButtons" class="order-option-button"
                (click)="changeOrderType('delivery')">
                {{'menuTypeDropdown.delivery' | transloco }}</a>
            <a *ngIf="disabledOptionsButtons" class="order-option-button disabled">
                {{'menuTypeDropdown.delivery' | transloco }}</a>
            <ng-template ngbNavContent>
                <app-order-scheduler (onDateSelected)="clickedDone()">
                </app-order-scheduler>
                <div *ngIf="(orderType$ | async) === 'delivery'" class="addresses">
                    <div class="addresses-header">
                        <div class="addresses-title dropdown-title">
                            {{ (user$ | async) && (addresses$ | async)?.length > 0 ? ('menuTypeDropdown.addresses' | transloco) : ('menuTypeDropdown.addNewAddress' | transloco) }}
                        </div>
                        <button *ngIf="(user$ | async) && (addresses$ | async)?.length > 0"
                            class="add-address-button" (click)="isNewAddressPageOpen = true; focusAddressInput()">
                            <img class="add-address-icon" src="../../assets/images/icons/plus.svg">
                        </button>
                    </div>
                    <ng-container *ngIf="(addresses$ | async)?.length > 0; else addressInput">
                        <div class="addresses-list">
                            <hr class="address-space">
                            <ng-container *ngFor="let address of (addresses$ | async).slice().reverse() | slice:0:4; let i = index;">
                                <button class="address"
                                    (click)="selectAddress(address)"
                                    [ngClass]="{'active': isAddressSelected(address) }">
                                    <div class="address-container">
                                        <div class="address-line-1">
                                            {{ address.line1 }}<span class="address-apt" *ngIf="address.apt">,
                                                {{ address.apt }}</span>
                                        </div>
                                        <div class="address-city-province">
                                            {{ address.city }}, {{ address.province }}
                                        </div>
                                    </div>
                                    <img (click)="selectPreferredAddress(address); $event.stopPropagation();" class="star-icon star-icon-unselected" [src]="address.isPreferred ? '../../assets/images/icons/star-yellow.svg' : (isAddressSelected(address) ? '../../assets/images/icons/star-empty-white.svg' : '../../assets/images/icons/star-empty.svg') ">
                                </button>
                                <hr *ngIf="i < (addresses$ | async)?.length - 1" class="address-space">
                            </ng-container>
                        </div>
                    </ng-container>
                    <ng-template #addressInput>
                        <input *ngIf="(orderType$ | async) === 'delivery' && isBrowser" [placeholder]="'navbar.addressPlaceholder' | transloco"
                            id="address-navbar-input" ngx-google-places-autocomplete #placesRef="ngx-places" [options]="autocompleteOptions"
                            class="add-address-input" (keyup.enter)="selectFirstPrediction()" (onAddressChange)="handleGoogleAddressChange($event)"
                            [(ngModel)]="addressValue" #addressNavbarInput/>
                        <div *ngIf="isLoading" class="spinner-border spinner-border-sm" role="status">
                            <span class="sr-only">{{ 'common.loading' | transloco }}</span>
                        </div>
                        <ng-container *ngIf="addressError">
                            <div *ngIf="missingLine1Error" class="address-error-message">{{'menuTypeDropdown.missingLine1Error' | transloco}}</div>
                            <div *ngIf="unspecificAddressError" class="address-error-message">{{'menuTypeDropdown.unspecificAddressError' | transloco}}</div>
                        </ng-container>
                    </ng-template>
                </div>
            </ng-template>
        </li>
        <li [ngbNavItem]="2" *ngIf="!isNewAddressPageOpen  && !isAddressConfirmationPageOpen" [ngClass]="{'active': (orderType$ | async) === 'pickup'}">
            <a ngbNavLink *ngIf="!disabledOptionsButtons" class="order-option-button"
                (click)="changeOrderType('pickup')">
                {{'menuTypeDropdown.pickup' | transloco }}</a>
            <a *ngIf="disabledOptionsButtons" class="order-option-button disabled">
                {{'menuTypeDropdown.pickup' | transloco }}</a>
            <ng-template ngbNavContent>
                <app-order-scheduler (onDateSelected)="clickedDone()">
                </app-order-scheduler>
            </ng-template>
        </li>
    </ul>
    <div [ngbNavOutlet]="nav" class="mt-3 mb-3" [ngStyle]="{'display': isAddressConfirmationPageOpen ? 'none' : 'block'}"></div>   
    <button class="close-dropdown-mobile-button form-submit-button" type="button" (click)="clickedDone()">Done</button>
</div>
