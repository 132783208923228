import { Address } from './address.model';
import { Menu } from './menu/menu.model';
import { Deserializable } from './deserializable.model';
import { Media } from './media.model';
import { TranslatedText, TranslatedTextType } from './translated-text.model';
import { Category } from './category.model';

export class Restaurant implements Deserializable<Restaurant> {
    id?: string;
	_name?: TranslatedText;
    slug: string;
    _brief?: TranslatedText;
	address?: Address;
	isOpen?: {
		delivery?: boolean;
		pickup?: boolean;
	};
	contact?: {
		email?: string;
		number?: string;
	};
	social?: {
		facebook?: string;
		instagram?: string;
		twitter?: string;
		website?: string;
	};
    info: {
        founded: {
            date: Date;
        };
    };
	menus?: Menu[];
	schedule?: {
		start: Date;
		end: Date;
		active: boolean;
	}[][];
	assets?: {
		logo?: {
			primary?: Media;
			inverse?: any;
		};
		icon?: any;
        background?: Media;
        header?: Media;
        marketHeader?: Media;
	};
	delivery?: {
        radius?: number
        time?: number
    };
    categories?: Category[]
    featuredCategories?: Category[]

    constructor() {
    }

    isOpenForMenuType(type: String, date: Date = new Date()): boolean {
        let menus = this?.menus.filter(menu => menu.type == type);
        for (let menu of menus) {
            if (menu.isOpenAtDate(date)) return true;
        }
        return false;
    }

    getNextOpenDate(type: String, date: Date): Date {
        date = date ?? new Date();
        return this?.menus
            .filter(menu => menu.type == type)
            .map(menu => menu.nextDateOpen(date))
            .filter(date => date != null)
            .sort((firstDate, secondDate) => firstDate.getTime() - secondDate.getTime())
            .find(() => true);
    }

    deserialize(input: any): this {
        if (!input) return null;
        if (typeof input == 'string') this.id = input;
        else {
            Object.assign(this, input);
            this.id = input._id ? input._id : input.id
            if (input.menus && Array.isArray(input.menus)) {
                this.menus = input.menus.map(menu => new Menu().deserialize(menu))
            }
            this.address = new Address().deserialize(input.address)
            this.assets = input.assets ? input.assets : {}
            this.assets.header = new Media().deserialize(input.assets?.header)
            this.assets.marketHeader = new Media().deserialize(input.assets?.marketHeader)
            this.assets.background = new Media().deserialize(input.assets?.background)
            this.assets.logo = this.assets.logo ? this.assets.logo : {}
            this.assets.logo.primary = new Media().deserialize(input.assets?.logo?.primary)
            this.name = new TranslatedText().deserialize(input.name)
            this.brief = new TranslatedText().deserialize(input.brief)
            this.categories = Array.isArray(input.categories) ? input.categories.map(category => new Category().deserialize(category)) : []
            this.featuredCategories = Array.isArray(input.featuredCategories) ? input.featuredCategories.map(category => new Category().deserialize(category)) : []
        }
		return this;
    }

    get headerImageUrl(): string {
        return this.assets?.header?.url
    }

    get marketHeaderImageUrl(): string {
        return this.assets?.marketHeader?.url
    }

    get backgroundImageUrl(): string {
        return this.assets?.background?.url
    }

    get primaryLogoImageUrl(): string {
        return this.assets?.logo?.primary?.url
    }

    get searchResultIconUrl(): string {
        return this.primaryLogoImageUrl;
    }

    get name(): TranslatedTextType {
        return this._name?.value
    }

    set name(name: TranslatedTextType) {
        this._name = name as TranslatedText
    }

    get brief(): TranslatedTextType {
        return this._brief?.value
    }

    set brief(brief: TranslatedTextType) {
        this._brief = brief as TranslatedText
    }
}
