<div *ngIf="showBag" class="bag-container">
    <img (click)="closeBag()" class="bag-close-icon" src="../../assets/images/close-icon.png" alt="x icon" />
    <div class="bag-top-container">
        <h2 class="bag-header">{{ 'bag.myOrder' | transloco }}</h2>
        <div *ngIf="bag$ | async" class="bag-subheader">
            {{ 'bag.from' | transloco }}
            <a class="restaurant-name" [routerLink]="['/r', (restaurant$ | async)?.slug]">
                <strong>{{ (restaurant$ | async)?.name }}</strong>
            </a>
        </div>
        <div class="bag-line"></div>
    </div>
    <div class="bag-middle-container">
        <div *ngIf="!(bag$ | async) || (bag$ | async)?.isEmpty" class="empty-bag-message">
            {{ 'bag.emptyMessage' | transloco }}
        </div>
        <div *ngIf="bag$ | async" class="bag-item-container">
            <div *ngFor="let item of (bag$ | async)?.items" class="bag-item">
                <bag-item [item]="item" [orderPending]="(order$ | async)?.isActive"
                    [disabled]="!(menu$ | async)?.isOpen && !(scheduledDate$ | async)"></bag-item>
            </div>
        </div>
    </div>
    <div class="bag-bottom-container">
        <!-- TODO: refactor eventually - put variables in ts -->
        <span *ngIf="((bag$ | async)?.subtotal.lessThan(configService.MINIMUM_FREE_DELIVERY_THRESHOLD)) && !(bag$ | async)?.isEmpty"
            class="small-order-fee-message">
            {{ ('bag.smallOrderFeeMessage' | transloco: { 
                smallOrderFee: (configService.SMALL_DELIVERY_ORDER_FEE | price | translocoCurrency), 
                remaining: ((configService.MINIMUM_FREE_DELIVERY_THRESHOLD.subtract((bag$ | async)?.subtotal)) | price | translocoCurrency) }) }}
            <img src="../../assets/images/icons/info-round-button.png" class="info-tooltip-icon small-order-fee-info-icon"
                [ngbTooltip]="('checkout.tooltips.smallOrderFeeInfo' | transloco: { orderMinimum: (configService.MINIMUM_DELIVERY_ORDER_AMOUNT | price | translocoCurrency) })" triggers="hover focus click:blur" tooltipClass="radish-tooltip" openDelay="200" closeDelay="500">
        </span>
        <div class="bag-line"></div>
        <div *ngIf="!(order$ | async)?.isActive" class="bag-checkout-button-wrapper" 
            [ngbTooltip]="!isMenuOpenAtScheduledDate
                ? ('bag.menuNotAvailable' | transloco)
                : (!(menu$ | async)?.isOpen && (restaurant$ | async) && !(scheduledDate$ | async))
                ? ('bag.closedForDelivery' | transloco: { name: (restaurant$ | async)?.name, type: (bag$ | async)?.type })
                    : ((bag$ | async)?.isEmpty
                        ? ('bag.emptyMessage' | transloco)
                        : ((bag$ | async)?.subtotal.lessThan(configService.MINIMUM_ORDER_AMOUNT)
                            ? ('bag.belowMinimumOrderText' | transloco: { remaining: ((configService.MINIMUM_ORDER_AMOUNT.subtract((bag$ | async)?.subtotal)) | price | translocoCurrency) })
                            : ''
                        )
                    )" 
            [disableTooltip]="!disableCheckoutButton()" tooltipClass="radish-tooltip">
            <!-- TODO: Refactor previous line -->
            <button type="button" [disabled]="disableCheckoutButton()" class="bag-checkout-button" (click)="clickCheckout()">
                {{ 'bag.checkout' | transloco }} - {{ (bag$ | async)?.subtotal | price | translocoCurrency }}
            </button>
        </div>
        
        <button type="button" *ngIf="(order$ | async)?.isActive" class="bag-checkout-button" (click)="clickTrackOrder()">
            {{ 'bag.trackOrder' | transloco }}
        </button>
    </div>
</div>