import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { ListContent } from '../models/content';
import { BaseService } from './base.service';

@Injectable({
	providedIn: 'root'
})
export class ContentService {

	constructor(private baseService: BaseService) { }

	private entity: string = 'content';

	fetchList(slug: string): Observable<ListContent> {
		return this.baseService.fetch(this.entity + '/lists', slug).pipe(map(res => (res.content.length > 0) ? res.content[0] : null), map(list => new ListContent().deserialize(list)));
	}

}
