import { Component, OnInit, AfterViewInit, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { TranslocoService } from '@ngneat/transloco';
import { RestaurantSlug } from 'src/app/core/enums';
import { Product, Restaurant } from 'src/app/core/models';
import { Observable, Subscription } from 'rxjs';
import { MainService } from 'src/app/core/services/main.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MarketCategoryService } from 'src/app/core/services/data/market-category.service';

@Component({
	selector: 'app-market-product',
	templateUrl: './market-product.component.html',
	styleUrls: ['./market-product.component.css']
})
export class MarketProductComponent implements OnInit {

	@Input() item;

	isVeg = true;
	navigationPath: any[];
	isAdded: boolean = false;
	resolvingRelatedProducts: boolean = false;

	isLoading: boolean = false;
	product: Product;
	quantity = new UntypedFormControl(1);
	initialQuantity = 1;
	relatedProducts: any;

	categories = MarketCategoryService.CATEGORIES;
	
	constructor(
		public translate: TranslocoService,
		private mainService: MainService,
		private route: ActivatedRoute,
		private router: Router,
		public marketCategoryService: MarketCategoryService
	) { }

	ngOnInit(): void {
		this.router.routeReuseStrategy.shouldReuseRoute = () => false;
		this.product = this.route.snapshot.data.product as Product;
		this.getProductPath();

		//TO-DO: add relatedProducts fetch 
	}

	getProductPath() {

		this.navigationPath = [{ en: 'Market', fr: 'Marché' }]

		let subcategories = this.product.categories.filter(category => category.type == 'product');
		if (subcategories.length == 0) {
			this.navigationPath.push(this.product?._title)
			return;
		}

		// choode first subcategory by default
		let subcategory = subcategories[0];

		let parentCategory = subcategory?.categories[0];
		if (!parentCategory?._title.fr || !parentCategory?._title.en) {
			this.navigationPath.push(subcategory?._title);
		}
		else {
			this.navigationPath.push(parentCategory?._title);
			this.navigationPath.push(subcategory?._title);
		}
		this.navigationPath.push(this.product?._title)


	}

	decrementQuantity() {
		if (this.quantity.value > 1) this.quantity.setValue(this.quantity.value - 1);
	}

	incrementQuantity() {
		if (this.quantity.value >= 1) this.quantity.setValue(this.quantity.value + 1);
	}

	onClickAddToBasket() {

		this.mainService.addCrateItem(this.product, this.quantity.value).subscribe(
			// res => this.isLoading = false,
			_ => {
				this.isAdded = true;
				this.isLoading = false
				setTimeout(() => {
					this.isAdded = false;
				}, 800);

			},
			err => {
				this.isLoading = true;
				console.log(err);
			});
	}
	clickPath(path) {
		if (path.en == 'Market') this.router.navigate(['/marché']);
		else {
			let data = { en: path.en, fr: path.fr }

			localStorage.setItem('scroll_to', JSON.stringify(data))
			if (!localStorage.getItem('redirect_url_market')) this.router.navigate(['/marché/products']);
			this.router.navigate(['/marché/' + localStorage.getItem('redirect_url_market')])
		};
	}

}
