import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';

import { environment } from '../../../environments/environment';
import { isPlatformBrowser } from '@angular/common';

const headers = new HttpHeaders({
	'Content-Type': 'application/json; charset=utf-8',
});

@Injectable({
	providedIn: 'root',
})
export class BaseService {
	private isBrowser: boolean;

	constructor(
		@Inject(PLATFORM_ID) platformId: Object,
		private http: HttpClient
	) {
		this.isBrowser = isPlatformBrowser(platformId);
	}

	get endpoint() {
		return environment.API_URL;
	}

	create(entity: string, data: any): Observable<any> {
		return this.http.post(`${this.endpoint}/${entity}`, JSON.stringify(data), {
			headers: headers,
		});
	}

	fetch(entity: string, param: string, query: any = null): Observable<any> {
		let route = `${this.endpoint}/${entity}/${param}`;
		if (query && Object.keys(query).length > 0) route += `?${new URLSearchParams(query).toString()}`
		return this.http.get(route, { headers: headers });
	}

	// query(entity: string, param: string, query: string): Observable<any> {
	// 	return this.http.get(`${this.endpoint}/${entity}/${param}?${new URLSearchParams(query).toString()}`, { headers });
	// }

	list(entity: string, query: any = null): Observable<any> {
		if (query && Object.keys(query).length > 0) {
			let params = new URLSearchParams();
			Object.keys(query).forEach((key, index) => {
				if (!Array.isArray(query[key])) params.append(key, query[key]);
				else query[key].forEach((arrKey, arrIndex) => params.append(key, query[key][arrIndex]))
			})
			return this.http.get(`${this.endpoint}/${entity}?${params.toString()}`, { headers: headers });
		}
		else return this.http.get(`${this.endpoint}/${entity}`, { headers: headers });
	}

	request(entity: string): Observable<any> {
		return this.http.post(`${this.endpoint}/${entity}`, { headers: headers });
	}

	delete(entity: string, param: any = null): Observable<any> {
		if (param)
			return this.http.delete(`${this.endpoint}/${entity}/${param}`, {
				headers: headers,
			});
		else
			return this.http.delete(`${this.endpoint}/${entity}`, {
				headers: headers,
			});
	}

	update(entity: string, data: any): Observable<any> {
		return this.http.patch(`${this.endpoint}/${entity}`, JSON.stringify(data), {
			headers: headers
		});
	}

	submit(entity: string, data: any): Observable<any> {
		return this.http.post(`${this.endpoint}/${entity}`, data);
	}
}
