export enum OrderType {
    Delivery = 'delivery',
    Pickup = 'pickup',
    Market = 'market',
    Dining = 'dining',
    Expo = 'expo',
    LastMile = 'last_mile',
    GiftBox = 'gift_box'
}

export enum ExtendedOrderType {
    Delivery = 'delivery',
    Pickup = 'pickup',
    Market = 'market',
    Dining = 'dining',
    Expo = 'expo',
    Platform = 'platform'
}
