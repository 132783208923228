import { Component, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
@Component({
	selector: 'market-delivery-timer',
	templateUrl: './market-delivery-timer.component.html',
	styleUrls: ['./market-delivery-timer.component.css']
})
export class MarketDeliveryTimerComponent implements OnInit {

	constructor(public translate: TranslocoService) { }

	currentDate: any;
	targetDate: any;
	seconds: any;
	minutes: any;
	hours: any;
	days: any;
	month: number;
	listDays = [
		'Sunday',
		'Monday',
		'Tuesday',
		'Wednesday',
		'Thursday',
		'Friday',
		'Saturday'
	];
	
	countDownDate: Date;
	nextDelivery: Date;
	deliveryDate: string;

	countdown = setInterval(() => {
		var countDownDateTime = this.countDownDate.getTime();

		var now = (new Date()).getTime();
		var distance = countDownDateTime - now;
		this.days = Math.floor(distance / (1000 * 60 * 60 * 24));
		this.hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
		this.minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
		this.seconds = Math.floor((distance % (1000 * 60)) / 1000);

		if (this.days < 0 || this.hours < 0 || this.minutes < 0 || this.seconds < 0) {
			this.setNextTargetDayDate();
		}
	});
	
	ngOnInit(): void {
		this.setNextTargetDayDate();
	}

	setNextTargetDayDate() {
		this.countDownDate = this.getTargetDayDate();
		this.nextDelivery = new Date(this.countDownDate.getTime() + 1000 * 24 * 60 * 60);
	}

	getTargetDayDate() {
		let currentDate = new Date();
		let targetDate = new Date();

		let currentDayIndex = currentDate.getDay();
		let difference = this.listDays.indexOf("Thursday") - currentDayIndex;
		targetDate.setDate((targetDate.getDate() + difference) + 1)
		targetDate.setHours(0, 0, 0, 0);
		if (targetDate.getTime() - currentDate.getTime() < 0) targetDate.setDate(targetDate.getDate() + 7)
		return targetDate;
	}
}
