import { Component, OnInit, Input } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'app-vendor-market-row',
  templateUrl: './vendor-market-row.component.html',
  styleUrls: ['./vendor-market-row.component.css']
})
export class VendorMarketRowComponent implements OnInit {
  @Input() item;
  @Input() routeToRestaurant?:Boolean = false;
  constructor(public translate: TranslocoService) { }
  
  ngOnInit(): void {
  }

}
