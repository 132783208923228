import { Injectable } from '@angular/core';
import {
	CanActivate,
	ActivatedRouteSnapshot,
	RouterStateSnapshot,
	UrlTree,
	Router,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { TranslocoService } from '@ngneat/transloco';

@Injectable({
	providedIn: 'root',
})
export class LanguageGuard implements CanActivate {
    language: string;
    firstParam: string;
	constructor(private authService: AuthService, private translate: TranslocoService, private router: Router) { }

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): boolean {
        this.language = route.paramMap.get('language');
        if (!['en', 'fr'].includes(this.language)) {
            this.firstParam = this.language;
            this.language = localStorage.getItem('language');
            if (this.language == null) {
                this.language = navigator.languages && navigator.languages.length
                    ? navigator.languages[0]
                    : navigator.language;
                if (this.language && this.language.startsWith('fr')) this.language = 'fr'
                else this.language = 'en'
            }
            if (this.language == null) this.language = 'en';
            if (this.firstParam) this.router.navigate(this.setRouteLanguageParam(state, this.language), {queryParams: route.queryParams});
            else this.router.navigate([this.language])
        }
        return true;
    }

    setRouteLanguageParam(state: RouterStateSnapshot, lang: string): any[] {
        let url = decodeURIComponent(state.url.split('?')[0])
        let splitUrl = url.split('/')
        splitUrl[1] = lang
        splitUrl[0] = '/'
        return splitUrl;
    }
}
