import { Component, OnInit } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { Observable, map } from 'rxjs';
import { Bag, Order } from 'src/app/core/models';
import { MainService } from 'src/app/core/services/main.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UntilDestroy } from '@ngneat/until-destroy';

const slideUpToggleAnimation = [
	trigger('overlayToggle', [
		transition(':enter', [
			style({ opacity: 0 }),
			animate('200ms ease-in', style({ opacity: 0.45 }))
		]),
		transition(':leave', [
			animate('300ms ease-in', style({ opacity: 0 }))
		])
	]),
	trigger('slideUpToggle', [
		state('open', style({
			transform: 'translateY(0%)'
		})),
		state('closed', style({
			transform: 'translateY(100%) translateY(-72px)'
		})),
		transition('open => closed', [
			animate('300ms ease-in')
		]),
		transition('closed => open', [
			animate('200ms ease-in')
		])
	]),
	trigger('slideUpAppear', [
		transition(':enter', [
			style({ transform: 'translateY(100%)' }),
			animate('200ms ease-in', style({ transform: 'translateY(100%) translateY(-72px)' }))
		]),
		transition(':leave', [
			animate('300ms ease-in', style({ transform: 'translateY(100%)' }))
		])
	])
]

const slideInOut = [
	trigger('slideInOut', [
    	transition(':leave', [
            style({ width: '*', opacity: 1 }),
        	animate('500ms cubic-bezier(.37,1.04,.68,.98)', style({ width: '0', opacity: 0 }))
    	]),
    	transition(':enter', [
            style({ width: '0', opacity: 0 }),
        	animate('400ms cubic-bezier(.37,1.04,.68,.98)', style({ width: '*', opacity: 1 }))
    	])
  	])
]

@UntilDestroy()
@Component({
	selector: 'app-gift-box-main',
	templateUrl: './main.component.html',
	styleUrls: ['./main.component.css'],
	animations: [slideUpToggleAnimation, slideInOut]
})
export class GiftBoxMainComponent implements OnInit {

	giftBox$: Observable<Bag>
	order$: Observable<Order>

	selectedLanguage: string;
	isMobile: boolean = false;
	bottomSheetOpen: boolean = false;

	constructor(
		public router: Router,
		public route: ActivatedRoute,
		public translate: TranslocoService,
		private mainService: MainService,
		public modalService: NgbModal) { }

	ngOnInit(): void {
		this.giftBox$ = this.mainService.giftBoxLoaded$;
		this.order$ = this.mainService.giftBoxOrderLoaded$;
		this.isMobile = window.innerWidth <= 991;
		window.onresize = () => this.isMobile = window.innerWidth <= 991;

        this.selectedLanguage = this.getSelectedLanguage(this.translate.getActiveLang());
	}

    useLanguage(language: string) {
        this.router.navigateByUrl(this.router.url.replace(this.translate.getActiveLang(), language));
        this.mainService.setLanguage(language);
        this.selectedLanguage = this.getSelectedLanguage(language);
    }

    getSelectedLanguage(language: string): string {
        switch (language) {
            case 'fr':
                return "Français";
            case 'en':
                return "English";
            default:
                return "Français";
        }
    }

	toggleBottomSheet() {
		this.bottomSheetOpen = !this.bottomSheetOpen;
	}

	animationDone(event) {
		// if the giftbox bag is empty
		if (!this.giftBox$ || this.giftBox$.pipe(map((bag) => bag.isEmpty))) this.bottomSheetOpen = false;
	}

	getLogoRoute() {
		return '/' + this.translate.getActiveLang() + '/marché';
	}

	onClickRadishLogo() {
		this.mainService.refreshUser();
	}
}




