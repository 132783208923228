import { Component, OnInit, Input } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { ListContent, Restaurant } from 'src/app/core/models';
import { Product } from 'src/app/core/models';

@Component({
	selector: 'app-base-market-row',
	templateUrl: './base-market-row.component.html',
	styleUrls: ['./base-market-row.component.css']
})
export class BaseMarketRowComponent implements OnInit {

	@Input() items: Product[] | Restaurant[];
	@Input() displayOnMain?: boolean = false; //TODO: Should be seperated renamed to reflect feature toggles i.e. canAddToBasket or routeToMarketRestaurantPage

	constructor(public translate: TranslocoService) { }

	ngOnInit(): void {
	}

	getRowType(data) {
		data = data[0];
		if (data instanceof Restaurant) return "vendor";
		else if (data instanceof Product) return "product";
	}
}
