import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'app-support',
	templateUrl: './support.component.html',
	styleUrls: ['./support.component.css'],
})
export class SupportComponent implements OnInit {
	@Input('emergency') isEmergency: boolean = false;

	constructor(public activeModal: NgbActiveModal) { }

	ngOnInit(): void { }
}
