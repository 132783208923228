import { Deserializable } from "./deserializable.model";
import { TranslatedText, TranslatedTextType } from "./translated-text.model";

export class Category implements Deserializable<Category> {
    id: string;
    _title: TranslatedText;
    _description?: TranslatedText;
    categories?: Category[];
    subcategories?: Category[];
    type: CategoryType;
    slug: string;
    createdAt: Date = new Date(2070, 0);
    updateAt: Date;
    weight?: number

    get title(): TranslatedTextType {
        return this._title?.value
    }

    set title(title: TranslatedTextType) {
        this._title = title as TranslatedText
    }

    get description(): TranslatedTextType {
        return this._description.value
    }

    set description(description: TranslatedTextType) {
        this._description = description as TranslatedText
    }

    get isBaseCategory() {
        return !(this.subcategories?.length > 0);
    }

    constructor(title?: TranslatedTextType, type?: CategoryType, slug?: string) {
        this.title = new TranslatedText().deserialize(title);
        this.type = type;
        this.slug = slug;
    }

	deserialize(input: any): this {
        if (!input) return null;
        if (typeof input == 'string') this.id = input;
        else {
            Object.assign(this, input);
            this.id = input._id ? input._id : input.id;
            this.title = new TranslatedText().deserialize(input.title);
            this.description = new TranslatedText().deserialize(input.description);
            if (input.categories) this.categories = input.categories.map(category => new Category().deserialize(category));
            if (input.subcategories) this.subcategories = input.subcategories.map(category => new Category().deserialize(category));
            this.createdAt = new Date(input.createdAt);
        }
		return this;
	}

    get searchResultIconUrl(): string {
        return '../../../assets/images/search-bar/produce.png';
    }
}

export enum CategoryType {
    Cuisine = 'cuisine',
    Product = 'product',
    Service = 'service',
    Storage = 'storage',
    Trait = 'trait',
    Allergen = 'allergen',
    Restriction = 'restriction'
}
