import { Component, OnInit, Input, ElementRef, ViewChild, HostListener } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { TranslocoService } from '@ngneat/transloco';

import { User, Address, Restaurant, Bag, Order } from '../core/models';
import { OrderType } from '../core/enums';

import { Angulartics2 } from 'angulartics2';
import { MainService } from '../core/services/main.service';
import { DateFormatOptions } from '@ngneat/transloco-locale';
import { NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
    OrderType = OrderType;
    SCHEDULED_TIME_KEY: string = 'scheduled_time';

    @Input('navbarType') type: string = 'other';
    @ViewChild('addressNavbarInput') addressInput: ElementRef;
    @ViewChild('navbar') navbar: ElementRef;
    @ViewChild('ngbNav') ngbNav;

    user$: Observable<User>;
    address$: Observable<Address>;
    adresses$: Observable<Address[]>;
    bagOrCrate$: Observable<Bag>;
    order$: Observable<Order>;
    orderType$: Observable<OrderType>;
    scheduledDate$: Observable<Date>;

    restaurant$: Observable<Restaurant>;

    showBag: boolean = false;
    showCrate: boolean = false;
    totalItems: number = 0; //TODO: can just be observbed from bag, potentially not necessary
    largeBag: boolean = false;
    isLoading: boolean = true;

    whiteBg: boolean = false;

    scheduledTimePlusThirty: Date;
    longScheduledDateFormat: DateFormatOptions = { weekday: 'short', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric' };
    shortScheduledDateFormat: DateFormatOptions = { hour: 'numeric', minute: 'numeric' };

    readonly bagStaticImageSrc: string = '../../assets/images/shopping-cart-black.png'
    readonly fillBagGifSrc: string = '../../assets/videos/bag/fill-bag.gif'
    readonly fillEmptyBagGifSrc: string = '../../assets/videos/bag/fill-empty-bag.gif'
    readonly emptyBagStaticImageSrc: string = '../../assets/images/shopping-cart-black-empty.png'
    bagImgSrc: string = this.emptyBagStaticImageSrc

    readonly crateStaticImageSrc: string[] = [
        '../../assets/images/crate/static-crate-0.png', '../../assets/images/crate/static-crate-1.png', '../../assets/images/crate/static-crate-2.png',
        '../../assets/images/crate/static-crate-3.png', '../../assets/images/crate/static-crate-4.png', '../../assets/images/crate/static-crate-5.png'
    ];
    readonly fillCrateGifSrc: string[] = [
        '../../assets/videos/crate/fill-crate-1.gif', '../../assets/videos/crate/fill-crate-2.gif', '../../assets/videos/crate/fill-crate-3.gif',
        '../../assets/videos/crate/fill-crate-4.gif', '../../assets/videos/crate/fill-crate-5.gif', '../../assets/videos/crate/fill-crate-6.gif',
        '../../assets/videos/crate/fill-crate-7.gif', '../../assets/videos/crate/fill-crate-8.gif', '../../assets/videos/crate/fill-crate-9.gif', '../../assets/videos/crate/fill-crate-10.gif'
    ];
    readonly emptyCrateGifSrc: string[] = [
        '../../assets/videos/crate/empty-crate-0.gif', '../../assets/videos/crate/empty-crate-1.gif', '../../assets/videos/crate/empty-crate-2.gif',
        '../../assets/videos/crate/empty-crate-3.gif', '../../assets/videos/crate/empty-crate-4.gif', '../../assets/videos/crate/empty-crate-5.gif',
        '../../assets/videos/crate/empty-crate-6.gif', '../../assets/videos/crate/empty-crate-7.gif'
    ];
    crateImgSrc: string = this.crateStaticImageSrc[0];

    constructor(
        public router: Router,
        public route: ActivatedRoute,
        public translate: TranslocoService,
        private angulartics2: Angulartics2,
        private mainService: MainService,
        private ngbDropdownConfig: NgbDropdownConfig
    ) {
        ngbDropdownConfig.autoClose = false;
    }

    //TODO: Refactor/Deduplicate, code should be reshared between bag and crate
    ngOnInit() {
        this.user$ = this.mainService.userLoaded$;
        this.address$ = this.mainService.addressLoaded$;

        //TODO: change this.bag$ to this.crate$ if type == market
        // this.bag$ = this.mainService.bagLoaded$;

        this.orderType$ = this.mainService.orderTypeInViewLoaded$;
        this.scheduledDate$ = this.mainService.scheduledDateLoaded$;

        if (this.type === 'market') {
            this.bagOrCrate$ = this.mainService.crateLoaded$;

            this.bagOrCrate$.pipe(untilDestroyed(this)).subscribe(crate => {
                if (crate == null) {
                    this.crateImgSrc = this.crateStaticImageSrc[0];
                    this.totalItems = 0;
                    return;
                }
                const prevTotalItems = this.totalItems;

                this.totalItems = crate.itemCount;
                if (prevTotalItems < this.totalItems) this.animateFillCrate();
                else if (prevTotalItems > this.totalItems) this.animateEmptyCrate();
            })


        } else {
            this.bagOrCrate$ = this.mainService.bagLoaded$;
            this.bagOrCrate$.pipe(untilDestroyed(this)).subscribe(bag => {
                if (bag == null) {
                    this.bagImgSrc = this.emptyBagStaticImageSrc;
                    this.totalItems = 0;
                    return;
                }
                const prevTotalItems = this.totalItems;
                this.totalItems = bag.itemCount;

                if (this.totalItems === 0) this.bagImgSrc = this.emptyBagStaticImageSrc;
                else if (prevTotalItems < this.totalItems) this.animateBag();
            });

            this.scheduledDate$.pipe(untilDestroyed(this)).subscribe(scheduledDate => {
                if (scheduledDate == null) return;
                let secondDate = new Date(scheduledDate);
                secondDate.setMinutes(scheduledDate.getMinutes() + 30);
                this.scheduledTimePlusThirty = secondDate;
            })
        }
        this.largeBag = (this.totalItems > 100) ? true : false;                
    }


    // getScheduledTime() {
    //     let scheduledTime = localStorage.getItem(this.SCHEDULED_TIME_KEY)
    //     this.scheduledTime = scheduledTime ? new Date(Number(scheduledTime)) : null;
    //     if (this.scheduledTime) {
    //         let secondDate = new Date(this.scheduledTime);
    //         secondDate.setMinutes(this.scheduledTime.getMinutes() + 30);
    //         this.scheduledTimePlusThirty = secondDate;
    //     }
    // }

    @HostListener('window:scroll', [])
    onWindowScroll() {
        if (window.pageYOffset > window.innerHeight) {
            this.whiteBg = true;
        } else {
            this.whiteBg = false;
        }
    }

    onClickRadishLogo() {
        this.mainService.refreshUser();
    }

    getLogoRoute() {
        if (this.router.url.includes('march%C3%A9')) return '/' + this.translate.getActiveLang() + '/marché';
        else if (this.router.url.includes('restaurants')) return '/' + this.translate.getActiveLang() + '/restaurants';
        else return '/' + this.translate.getActiveLang();
    }

    dropdownOpenChange(open: boolean) {
        let input: HTMLInputElement = <HTMLInputElement>(
            document.getElementById('address-navbar-input')
        );
        if (navigator.doNotTrack == null) return;
        this.isLoading = true;
        if (open && input && (!input.value || input.value === "")) {
            navigator.geolocation.getCurrentPosition((location: GeolocationPosition) => {
                let geocoder = new google.maps.Geocoder();
                let coords = new google.maps.LatLng(
                    location.coords.latitude,
                    location.coords.longitude
                );
                geocoder.geocode({ location: coords }, (results) => {
                    // if (localStorage.getItem(this.addressHelper.ADDRESS_KEY)) {
                    //     return;
                    // }
                    if (input && results[0]) {
                        input.value = results[0].formatted_address;

                        // blur then focus on the input to trigger the Google Places autocomplete dropdown
                        input.blur();
                        input.focus();
                    }
                });

                this.isLoading = false;
            },
                _ => {
                    this.isLoading = false;
                });
        }
    }
    clickedIcon: boolean = false;
    resetIcon(event) {
        this.clickedIcon = false;
    }
    animateFillCrate() {
        if (this.totalItems <= 5) this.crateImgSrc = this.fillCrateGifSrc[this.totalItems - 1];
        else this.crateImgSrc = this.fillCrateGifSrc[this.totalItems % 5 + 4];
        setTimeout(() => {
            if (this.totalItems <= 5) this.crateImgSrc = this.crateStaticImageSrc[this.totalItems];
            else this.crateImgSrc = this.crateStaticImageSrc[this.crateStaticImageSrc.length - 1];
        }, 1000);
    }

    animateEmptyCrate() {
        if (this.totalItems < 5) this.crateImgSrc = this.emptyCrateGifSrc[this.totalItems];
        else this.crateImgSrc = this.emptyCrateGifSrc[this.totalItems % 3 + 5];
        setTimeout(() => {
            if (this.totalItems <= 5) this.crateImgSrc = this.crateStaticImageSrc[this.totalItems];
            else this.crateImgSrc = this.crateStaticImageSrc[this.crateStaticImageSrc.length - 1];
        }, 1150);
    }

    animateBag() {
        if (this.totalItems === 1) this.bagImgSrc = this.fillEmptyBagGifSrc;
        else this.bagImgSrc = this.fillBagGifSrc;
        setTimeout(() => {
            if (this.totalItems !== 0) this.bagImgSrc = this.bagStaticImageSrc;
        }, 1000);
    }

    toggleBag() {
        // if (!this.showBag) this.onOpenBag = true
        // else this.onOpenBag = false
        if (this.type != 'market') this.showBag = !this.showBag;
        else this.showCrate = !this.showCrate;
    }

    logout() {
        this.mainService.logout();
    }

    login() {
        this.angulartics2.eventTrack.next({
            action: 'Click Signup',
            properties: {
                event: 'Click',
                category: 'Navbar',
                label: this.router.url
            }
        })
        localStorage.setItem('redirect_url', this.router.url);
        if (!this.router.url.includes('login')) this.router.navigate([this.translate.getActiveLang() + '/login']);
        else this.router.navigate([this.translate.getActiveLang() + '/registration']);
    }

    useLanguage(language: string) {
        this.router.navigateByUrl(this.router.url.replace(this.translate.getActiveLang(), language));
        this.mainService.setLanguage(language);
    }
}
