import { ListContent, Product, TranslatedText } from '../models';

export interface MarketSection {
    title: TranslatedText, list?: ListContent, products?: Product[], slug: string, weight: any, category?: any
}

export function categorize(products: Product[], topLevelCategory?: string, sortType?: string): MarketSection[] {
    return products?.reduce((sections: MarketSection[], product) => {
        let category = product.primaryProductCategory;
        let sectionIndex = sections.findIndex(section => section.slug == category.slug);
        if (sectionIndex == -1) return sections.concat([{ title: category._title, products: [product], slug: category.slug, weight: category.weight || category.createdAt, category: topLevelCategory }]);
        else {
            sections[sectionIndex].products.push(product);
            return sections;
        }
    }, []).sort(sortType == 'quantity' ? quantitySort : basicSort);
}

function basicSort(a, b) {
    return (typeof a.weight === 'number' && typeof b.weight === 'number') ?
        a.weight - b.weight :
        a.weight.getTime() - b.weight.getTime();
}

function quantitySort(a, b) {
    return b.products.length - a.products.length;
}