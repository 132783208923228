<div class="support-container">
	<img class="support-close" src="../../../assets/images/close-icon.png" alt="" (click)="activeModal.close()" />
	<ng-template *ngIf="isEmergency; else isNotEmergency">
		<div class="support-header">
			{{ 'support.somethingWentWrong' | transloco }}
		</div>
		<div class="support-description top-message">
			{{ 'support.refreshMessage' | transloco }}
		</div>
		<div class="support-text-container">
			<div class="support-number-description support-description">
				{{ 'support.numberDescription' | transloco }}
			</div>
			<a href="tel:+1-514-500-7998" class="support-number support-bold">+1 514 500-7998</a>
			<div class="support-email-description support-description">
				{{ 'support.emailDescription' | transloco }}
			</div>
			<a href="mailto:support@radish.coop" class="support-email support-bold">support@radish.coop</a>
		</div>
	</ng-template>
	<ng-template #isNotEmergency>
		<div class="support-header">
			{{ 'support.header' | transloco }}
		</div>
		<div class="support-text-container">
			<div class="support-email-description support-description">
				{{ 'support.emailDescription' | transloco }}
			</div>
			<a href="mailto:support@radish.coop" class="support-email support-bold">support@radish.coop</a>
			<div class="support-number-description support-description">
				{{ 'support.numberDescription' | transloco }}
			</div>
			<a href="tel:+1-514-500-7998" class="support-number support-bold">+1 514 500-7998</a>
		</div>
	</ng-template>
</div>