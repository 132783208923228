import Dinero from 'dinero.js';
import { FeeType } from '../enums';
import { Deserializable } from './deserializable.model';
import { TranslatedText, TranslatedTextType } from './translated-text.model';

export class Fee implements Deserializable<Fee> {
    id: string;
	_title?: TranslatedText;
	_description?: TranslatedText;
	type?: FeeType;
	category?: string;
	value?: number | Dinero.Dinero;
	inclusive?: boolean;

    constructor() {}

    get title(): TranslatedTextType {
        return this._title?.value
    }

    set title(title: TranslatedTextType) {
        this._title = title as TranslatedText
    }

    get description(): TranslatedTextType {
        return this._description.value
    }

    set description(description: TranslatedTextType) {
        this._description = description as TranslatedText
    }

	deserialize(input: any): this {
        if (!input) return null;
        if (typeof input == 'string') this.id = input;
        else {
            Object.assign(this, input);
            this.id = input._id ? input._id : input.id
            if (input.type == FeeType.Fixed && typeof input?.value?.amount == 'number') {
                this.value = Dinero({ amount: input.value.amount, currency: input.value.currency });
            }
            this.description = new TranslatedText().deserialize(input.description)
            this.title = new TranslatedText().deserialize(input.title)
        }
		return this;
	}
}
