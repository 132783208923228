export const environment = {
	baseUrl: 'https://demo.radish.coop',
	production: true,
	API_URL: 'https://demo.radish.coop/api',
	FACEBOOK_APP_ID: '210532433620075',
	GOOGLE_APP_ID: '106642041429-1e99o50fd1rnbe8l7kp473tko2v7rjqc.apps.googleusercontent.com',
	GOOGLE_MAPS_API_KEY: 'AIzaSyDXyda5gYmDm2HnnO4HefoQ_cma3yx6fII',
	STRIPE_KEY: 'pk_test_51Gz6IYBL80vOejOqr5osDfhsQfO3ScgQIquB1g0VnifCiAvxJc5f4LugSU7C7F2L7MGL5pdwLRRnmXIDsZ5nUsa4006QeEREjt',
	SOCKET_URL: 'https://demo.radish.coop',
	MONTREAL_REGION_SLUG: 'montreal',
	MONTREAL_REGION_ID: '5e766adfa05ae8a94dd8372e'
};
