import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { Bag, Menu, Restaurant } from 'src/app/core/models';
import { MainService } from 'src/app/core/services/main.service';
import { ExpoModalComponent } from 'src/app/modals/expo-modal/expo-modal.component';
@Component({
	selector: 'expo-restaurant',
	templateUrl: './restaurant.component.html',
	styleUrls: ['./restaurant.component.css']
})
export class ExpoRestaurantComponent implements OnInit {

	scheduledDate$: Observable<Date>;
	bag$: Observable<Bag>;
	bagRestaurant$: Observable<Restaurant>;

	vendor: any;
	restaurant: Restaurant;
	menu: Menu;

	isOpen: boolean = true;
	isBagRestaurant: boolean = true;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private mainService: MainService,
		private modalService: NgbModal
	) { }

	//TODO: fetch restaurant, menus
	ngOnInit(): void {
		this.scheduledDate$ = this.mainService.scheduledDateLoaded$;
		this.scheduledDate$.subscribe((scheduledDate: Date) => {
			let date = scheduledDate ? scheduledDate : new Date();
			this.isOpen = this.menu != null && this.menu.isOpenAtDate(date) && this.menu.isAvailable;
		});

		this.bag$ = this.mainService.expoBagLoaded$;
		this.bagRestaurant$ = this.mainService.expoBagRestaurant$;

		this.bag$.subscribe((bag: Bag) => {
			if (bag == null) return;
		});

		let slug = this.route.snapshot.paramMap.get('slug');
		this.vendor = this.route.parent.parent.snapshot.data['exposition']?.vendors.find(v => v.vendor?.slug == slug);
		if (this.vendor == null) {
			this.router.navigate([this.route.parent.snapshot.url.join('/')]);
			return;
		}
		this.restaurant = this.vendor.vendor;
		this.menu = this.vendor.menus.find(v => v);
		this.isOpen = this.menu != null && this.menu.isOpenAtDate(this.mainService.scheduledDate) && this.menu.isAvailable;

		if (this.menu != null) {
			if (!this.menu.isAvailable) {
				this.showErrorModal(
					"expo.modal.menuUnavailable.header",
					"expo.modal.menuUnavailable.text",
					"expo.modal.menuUnavailable.submit",
					null,
					() => { },
					() => { }
				);
			}
			else if (!this.menu.isOpenAtDate(this.mainService.scheduledDate)) {
				this.showErrorModal(
					"expo.modal.restaurantClosed.header",
					"expo.modal.restaurantClosed.text",
					"expo.modal.restaurantClosed.submit",
					null,
					() => { },
					() => { }
				);
			}
		}
	}

	getNumberOrdersUnderway(): number {
		if (this.vendor == null) return;
		return this.vendor.orderCount;
	}

	getEmojiFromOrderUnderway(): string {
		let emojis = ['🔥', '⚡'];
		let orderCount = this.vendor.orderCount;
		if (orderCount < 10) return "";
		else if (orderCount < 30) return emojis[orderCount % emojis.length];
		else return "🔥🔥🔥";
	}

	showErrorModal(header: string, text: string, submitText: string, cancelText: string = null,
		dismissCallback: () => any, submitCallback: () => any) {
		const modalRef = this.modalService.open(ExpoModalComponent, { centered: true });

		modalRef.componentInstance.header = header;
		modalRef.componentInstance.text = text;
		if (cancelText != null) modalRef.componentInstance.cancelButtonText = cancelText
		modalRef.componentInstance.submitButtonText = submitText;
		modalRef.result.then(submitCallback, dismissCallback);
	}
}