import { Injectable } from '@angular/core';
import io from 'socket.io-client';
import { Observable } from 'rxjs';
import { share } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';

@Injectable({
    providedIn: 'root'
})
export class SocketService {

    subscribersCounter: Record<string, number> = {};
    eventObservables$: Record<string, Observable<any>> = {};
    socket: any;

    constructor(private authService: AuthService) { }
    
    get isConnected(): boolean {
        if (this.socket) return this.socket.connected;
    }

    connect() {
        return this.socket = io(environment.SOCKET_URL, {
            path: "/api/socket.io",
            transports: ["polling", "websocket"],
            transportOptions: {
                polling: {
                    extraHeaders: {
                        "x-auth-token": this.authService.getJwtToken()
                    }
                }
            }
        });
    }

    emit(eventName: string, ..._args: any[]) {
        return this.socket.emit.apply(this.socket, arguments);
    }

    on(eventName: string, callback: Function) {
        this.socket.on(eventName, callback);
    }

    once(eventName: string, callback: Function) {
        this.socket.once(eventName, callback);
    }

    disconnect(close?: any) {
        return this.socket.disconnect.apply(this.socket, arguments);
    }

    reconnect() {
        this.disconnect();
        this.connect();
    }

    removeListener(eventName: string, callback?: Function) {
        return this.socket.removeListener.apply(this.socket, arguments);
    }

    removeAllListeners(eventName?: string) {
        return this.socket.removeAllListeners.apply(this.socket, arguments);
    }

    fromEvent<T>(eventName: string): Observable<T> {
        if (!this.subscribersCounter[eventName]) {
            this.subscribersCounter[eventName] = 0;
          }
          this.subscribersCounter[eventName]++;
      
          if (!this.eventObservables$[eventName]) {
            this.eventObservables$[eventName] = new Observable((observer: any) => {
              const listener = (data: T) => {
                observer.next(data);
              };
              this.socket.on(eventName, listener);
              return () => {
                this.subscribersCounter[eventName]--;
                if (this.subscribersCounter[eventName] === 0) {
                  this.socket.removeListener(eventName, listener);
                  delete this.eventObservables$[eventName];
                }
              };
            }).pipe(share());
          }
          return this.eventObservables$[eventName];
    }

    fromOneTimeEvent<T>(eventName: string): Promise<T> {
        return new Promise<T>(resolve => this.once(eventName, resolve));
    }
}
