import { Pipe, PipeTransform } from '@angular/core';
import Dinero from 'dinero.js';

@Pipe({ name: 'price' })
/**
 * Formats the value into a price that contains two significant figures
 */
export class PricePipe implements PipeTransform {

	constructor() {
	}
	
	transform(value: Dinero.Dinero): string {
		if (value == null) value = Dinero({ amount: 0, currency: 'CAD' });
		return value.toFormat('0.00');
	}
}
