import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { Observable } from 'rxjs';
import { MainService } from 'src/app/core/services/main.service';
import { Restaurant } from 'src/app/core/models';

@Component({
    selector: 'app-order-scheduler',
    templateUrl: './order-scheduler.component.html',
    styleUrls: ['./order-scheduler.component.css']
})
export class OrderSchedulerComponent implements OnInit {

    readonly SCHEDULED_TIME_KEY: string = 'scheduled_time';

    @Output() onDateSelected: EventEmitter<void> = new EventEmitter()
    currentMenuType$: Observable<string>;
    orderType$: Observable<string>;
    orderType: string = 'delivery'
    restaurant: Restaurant;
    scheduledTime: Date;
    laterDateClicked: boolean;
    datesForScheduling: Date[] = [];
    datePicked: Date;
    hoursOfTheDay: {
        date: Date,
        active: boolean
    }[] = []
    clickedDate: boolean = false;
    minNumberOfHoursAfterCurrentHourToAllowScheduling = 1;
    scheduledTimePlusThirty: Date;
    bagRestaurant: Restaurant;

    constructor(
        public translate: TranslocoService,
        private mainService: MainService,
        ) {}

    ngOnInit(): void {
        this.orderType$ = this.mainService.orderTypeInViewLoaded$;
        this.restaurant = this.mainService.restaurantInView;
        this.getScheduledTime()
        this.getSchedule()
        this.subscribeToOrderType()
    }

    subscribeToOrderType() {
        this.orderType$.subscribe(
            orderType => {
                this.orderType = orderType;
                let scheduledTime = localStorage.getItem(this.SCHEDULED_TIME_KEY);
                if (scheduledTime) this.clickDate(this.datePicked, false);
            }
        )
    }

    getSchedule() {
        this.datesForScheduling = [];
        let numberOfDays = 6;
        let now = new Date();

        // only add the day if there are hours available that day
        if (now.getHours() < (23 - this.minNumberOfHoursAfterCurrentHourToAllowScheduling)) {
            this.datesForScheduling.push(new Date(now));
        }

        for (let i = 0; i < numberOfDays - 1; i++) {
            now.setDate(now.getDate() + 1);
            this.datesForScheduling.push(new Date(now));
        }
    }

    getScheduledTime() {
        let scheduledTime = localStorage.getItem(this.SCHEDULED_TIME_KEY)
        this.scheduledTime = scheduledTime ? new Date(Number(scheduledTime)) : null;
        if (this.scheduledTime) {
            this.appendThirtyMinutesToSchedule(this.scheduledTime);
            this.datePicked = new Date(this.scheduledTime);
            this.laterDateClicked = true;
        }
        else {
            this.datePicked = new Date();
            this.laterDateClicked = false;
        }
    }

    setHours(selectedDate: Date) {
        let numIncrementsPerHour = 2 //2 increments means 30 minutes (60 / 2)
        this.hoursOfTheDay = []
        let now: Date = new Date()
        selectedDate.setHours(0)
        selectedDate.setMinutes(0)
        selectedDate.setSeconds(0)
        selectedDate.setMilliseconds(0)
        let numTimesLastHourHit = 0;
        if (this.restaurant) {
            while (selectedDate.getHours() <= 23) {
                let scheduledDate: Date = new Date(selectedDate)
                if (scheduledDate.getTime() > now.getTime()) {
                    this.hoursOfTheDay.push({
                        date: scheduledDate,
                        active: this.restaurant.menus && this.restaurant.menus.length > 0 ? this.restaurant.isOpenForMenuType(this.orderType, scheduledDate) : false
                    })
                }

                // break out after the 23rd hour to avoid infinite loop
                if (selectedDate.getHours() == 23) {
                    if (numTimesLastHourHit === (numIncrementsPerHour - 1)) break;
                    numTimesLastHourHit++;
                }
                selectedDate.setMinutes(selectedDate.getMinutes() + (60 / numIncrementsPerHour))
            }
        }
        else {
            while (selectedDate.getHours() <= 23) {
                let scheduledDate: Date = new Date(selectedDate)
                if (scheduledDate.getTime() > now.getTime()) {
                    this.hoursOfTheDay.push({
                        date: scheduledDate,
                        active: true
                    })
                }

                // break out after the 23rd hour to avoid infinite loop
                if (selectedDate.getHours() == 23) {
                    if (numTimesLastHourHit === (numIncrementsPerHour - 1)) break;
                    numTimesLastHourHit++;
                }
                selectedDate.setMinutes(selectedDate.getMinutes() + (60 / numIncrementsPerHour))
            }
        }
    }

    clickDate(date: Date, setNewDate: boolean = true) {
        this.laterDateClicked = true;
        this.datePicked = new Date(date);
        this.hoursOfTheDay = [];
        this.setHours(date);
        if (this.scheduledTime && !this.clickedDate && setNewDate) {
            localStorage.setItem(this.SCHEDULED_TIME_KEY, this.scheduledTime.getTime().toString())
            this.mainService.scheduledDate = this.scheduledTime
            this.appendThirtyMinutesToSchedule(this.scheduledTime);
        }
        else if (this.hoursOfTheDay.length > 0 && setNewDate) {
            localStorage.setItem(this.SCHEDULED_TIME_KEY, this.hoursOfTheDay[0].date.getTime().toString())
            this.scheduledTime = new Date(this.hoursOfTheDay[0].date)
            this.mainService.scheduledDate = this.scheduledTime
            this.appendThirtyMinutesToSchedule(this.scheduledTime);
        }
    }

    appendThirtyMinutesToSchedule(scheduledTime) {
        let secondDate = new Date(scheduledTime);
        secondDate.setMinutes(scheduledTime.getMinutes() + 30);
        this.scheduledTimePlusThirty = secondDate;
    }

    hourDropdownClicked() {
        // scroll down to the first available hour
        setTimeout(_ => {
            let selected = document.querySelector('.dropdown-hours-menu.dropdown-menu .active-hour');
            if (selected) {
                (selected.parentNode.parentNode as HTMLElement).scrollTop = (selected as HTMLElement).offsetTop;
            }
        })
    }

    scheduledTimeSelected(date) {
        localStorage.setItem(this.SCHEDULED_TIME_KEY, date)
        this.scheduledTime = new Date(Number(date))
        this.mainService.scheduledDate = this.scheduledTime
        this.appendThirtyMinutesToSchedule(this.scheduledTime);
    }

    dateSelected() {
        this.onDateSelected.emit()
    }

    prettyPrintDateTime(date: Date, type: string): string {
        let options: Intl.DateTimeFormatOptions;
        if (type === 'long') options = { weekday: 'short', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric' };
        else if (type === 'short') options = { hour: 'numeric', minute: 'numeric' };
        if (this.translate.getActiveLang() === 'en') return (new Date(date)).toLocaleString("en-US", options);
        else return (new Date(date)).toLocaleString("fr-CA", options);
    }

    asapClick() {
        this.datePicked = new Date();
        localStorage.removeItem(this.SCHEDULED_TIME_KEY);
        this.mainService.scheduledDate = null;
        this.scheduledTime = null;
        this.laterDateClicked = false;
    }

}
