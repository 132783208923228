import {
	Component,
	OnInit,
	Renderer2,
	ElementRef,
	ViewChild,
} from '@angular/core';

@Component({
	selector: 'app-privacy',
	templateUrl: './privacy.component.html',
	styleUrls: ['./privacy.component.css'],
})
export class PrivacyComponent implements OnInit {
	@ViewChild('sidebarWrapper') private el: ElementRef;
	navbarType = 'other';

	constructor(public element: ElementRef, public renderer: Renderer2) { }

	ngOnInit() {
		window.addEventListener('scroll', this.scrollEvent, true);
	}

	ngOnDestroy() {
		window.removeEventListener('scroll', this.scrollEvent, true);
	}

	scrollEvent = (): void => {
		const scrollY = window.scrollY;
		var x = document.body.scrollHeight - scrollY;
		if (x < 970) {
			this.renderer.addClass(this.el.nativeElement, 'fixed-position-bottom');
			this.renderer.removeClass(this.el.nativeElement, 'fixed-position-top');
		} else if (scrollY > 180) {
			this.renderer.addClass(this.el.nativeElement, 'fixed-position-top');
			this.renderer.removeClass(this.el.nativeElement, 'fixed-position-bottom');
		} else if (scrollY < 180) {
			this.renderer.removeClass(this.el.nativeElement, 'fixed-position-top');
		}
	};
}
