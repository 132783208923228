<div *ngIf="item.quantity > 0" class="crate-item">
    <a class="crate-item-left" [routerLink]="['/' + translate.getActiveLang() + '/marché/merchants/' + item?.product?.restaurant?.slug + '/products/' + item?.product?.slug]">
        <div class="crate-item-image-wrapper">
            <img class="crate-item-image" src="{{item?.thumbnailUrl != null ? item?.thumbnailUrl : '../../../assets/images/Radish-LogoMark-200x200.jpg'}}"/>
        </div>
        <div class="crate-item-info">
            <div class="crate-item-order">
                <strong class="crate-item-quantity">{{ item.quantity }} x </strong>
            </div>
            <div class="crate-item-product">{{ item.title }}</div>
        </div>
    </a>
    <div class="crate-item-right">
        <div class="crate-item-buttons">
            <button *ngIf="showDecrementControl" type="button" class="crate-item-button crate-item-buttons-minus"
                (click)="decrementItem(item)" [disabled]="isDecrementDisabled(item)">
                <img src="../../../../assets/images/icons/minus-white.svg">
            </button>
            <button *ngIf="showIncrementControl" type="button" class="crate-item-button crate-item-buttons-plus"
                (click)="incrementItem(item)" [disabled]="isIncrementDisabled()">
                <img src="../../../../assets/images/icons/plus-white.svg">
            </button>
        </div>
        <div class= "crate-item-price">{{ item.price | price | translocoCurrency }}</div>
    </div>
</div>