import { Deserializable } from './deserializable.model';
import { Product } from './product';
import { Region } from './region.model';
import { Restaurant } from './restaurant.model';
import { Measurement } from './measurement.model';

export class Inventory implements Deserializable<Inventory> {

    _id: string;
    products?: Product[];
    restaurant?: Restaurant;
    regions?: Region[];
    unit?: Measurement;

    quantity?: number;
    available?: number;
    reserved?: number;

    deserialize(input: any): this {
        if (!input) return null;
        if (typeof input == 'string') this.id = input;
        else {
            Object.assign(this, input);
            this.products = input.products?.map(product => new Product().deserialize(product));
            this.restaurant = new Restaurant().deserialize(input?.restaurant);
            this.regions = input.regions?.map(region => new Region().deserialize(region));
            this.unit = new Measurement().deserialize(input?.unit);
        }
        return this;
    }

    get id(): string {
        return this._id;
    }

    set id(id: string) {
        this._id = id;
    }
}
