<div class="marche-radish-letter-modal-container">
    <div class="marche-radish-letter-modal-header">
        <div class="marche-radish-letter-modal-image-wrapper">
            <img src="../../../assets/images/market/header-features/mr-header-whyMR.jpeg">
        </div>
    </div>
    <div class="marche-radish-letter-modal-body">
        <h3 class="marche-radish-letter-modal-title">{{ 'whyMR.title' | transloco }}</h3>
        <p class="marche-radish-letter-modal-text" [innerHtml]="'whyMR.text' | transloco">
        </p>
    </div>
</div>