import { AfterViewInit, Component, ElementRef, Inject, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { GiftCardPurchaseData, GiftCardService } from 'src/app/core/services/gift-card.service';
import Dinero from 'dinero.js';
import { isPlatformBrowser } from '@angular/common';
import { environment } from 'src/environments/environment';
import { TranslocoService } from '@ngneat/transloco';
import { Observable, tap } from 'rxjs';
import { GiftCardPurchase } from 'src/app/core/models/purchase/gift-card-purchase.model';

declare var Stripe;

const GIFT_CARD_PURCHASE_ID_KEY: string = 'gift_card_purchase_id';
@Component({
    selector: 'gift-card-checkout',
    templateUrl: './checkout.component.html',
    styleUrls: ['./checkout.component.css']
})
export class GiftCardCheckoutComponent implements OnInit, AfterViewInit {

    @ViewChild('cardElement') set ce(cardElement: ElementRef) {
        this.cardElement = cardElement;
    }


    loading: boolean = false;
    checkoutForm: UntypedFormGroup;

    openNewCard = false;
    openNewGiftCard = false;

    cardElement: ElementRef;
    cardNumberElement: ElementRef;
    cardExpiryElement: ElementRef;
    cardCvcElement: ElementRef;
    cardPostalCodeElement: ElementRef;

    stripe;
    card;
    cardErrors;
    savedCards = [];
    isCardEmpty: boolean = true;

    secret;

    isBrowser: boolean;

    giftCardPurchaseId: string;
    purchaseData: GiftCardPurchaseData;

    constructor(
        @Inject(PLATFORM_ID) platformId: Object,
        private fb: UntypedFormBuilder,
        public translate: TranslocoService,
        private giftCardService: GiftCardService) {

        this.isBrowser = isPlatformBrowser(platformId);
        this.checkoutForm = this.fb.group({
            cardRadio: [''],
            name: [''],
            email: ['']
        });
    }

    ngOnInit(): void {

        this.giftCardPurchaseId = localStorage.getItem(GIFT_CARD_PURCHASE_ID_KEY);
        this.checkoutForm
        this.purchaseData = {
            value: Dinero({ amount: 800, currency: 'CAD' }),
            title: { en: 'TESTING' },
            message: { en: 'Big Raccoon town' },
            recipient: {
                name: 'Soomin',
                email: 'soomin@radish.coop'
            },
            purchaseId: '62c6f381a48c524503d43dd4'
        };
        if (this.purchaseId) this.purchaseData.purchaseId = this.purchaseId;
        this.initializePurchase().subscribe(_ => {});
    }

    ngAfterViewInit(): void {
        if (!this.isBrowser) return;
        setTimeout(() => {
            this.stripe = Stripe(environment.STRIPE_KEY, { locale: this.translate.getActiveLang() });
            const elements = this.stripe.elements();

            this.card = elements.create('card');
            this.card.mount(this.cardElement.nativeElement);
            this.card.addEventListener('change', ({ error }) => this.cardErrors = error && error.message);
            this.card.addEventListener('focus', () => this.checkoutForm.controls.cardRadio.setValue('new-card'));
            this.card.on('change', (event) => this.isCardEmpty = event.empty);
        }, 175);
    }

    get purchaseid(): string {
        return this.giftCardPurchaseId;
    }

    set purchaseId(id: string) {
        this.giftCardPurchaseId = id;
        localStorage.setItem(GIFT_CARD_PURCHASE_ID_KEY, id);
    }

    private clearPurchaseId() {
        this.giftCardPurchaseId = null;
        localStorage.removeItem(GIFT_CARD_PURCHASE_ID_KEY);
    }

    private initializePurchase(): Observable<GiftCardPurchase> {
        return this.giftCardService.initializePurchase(this.purchaseData).pipe(tap({
            next: res => {

                this.secret = res.secret;
                this.savedCards = res.paymentMethods;
                this.savedCards.length > 0 ?
                    this.checkoutForm.controls.cardRadio.setValue(this.savedCards[0].id) :
                    this.checkoutForm.controls.cardRadio.setValue('new-card');

                return res.purchase;
            },
            error: err => {
                console.log(err)
            },
        }))
    }

    captureStripePayment(secret: string) {
        let card = this.checkoutForm.controls.cardRadio.value;

        let billing_details: any = {
            name: 'Mansib Rahman',
            email: 'mansib@radish.coop',
            address: {
                city: 'Montreal',
                country: 'Canada',
                line1: '3414 Avenue du Parc',
                line2: 'Office 320',
                postal_code: 'H2X 2H5',
                state: 'Quebec',
            },
        };

        let payment_method =
            card === 'new-card'
                ? {
                    card: this.card,
                    billing_details: billing_details
                } : card;

        this.stripe
            .confirmCardPayment(secret, {
                payment_method,
                setup_future_usage: 'off_session',
            })
            .then(
                (res) => {
                    // this.loading = false;
                    // this.enableButtons();
                    if (res.error) {
                        this.cardErrors = res.error.message;
                    } else { //TODO: purchase was succesfull, handle
                        if (res.paymentIntent.status === 'requires_capture' || res.paymentIntent.status === 'succeeded') {
                            this.clearPurchaseId();
                        }
                    }
                },
                (err) => {
                    this.cardErrors = 'An error occurred while processing your card. Try again in a little bit.'
                    // this.loading = false;
                    // this.enableButtons();
                }
            );
    }

    onClickAdd(cardType) {
        this.openNewCard = true;
    }

    onSavedCardClick() {
        this.cardErrors = null;
    }

    getCardLogo(brand: string) {
        switch (brand) {
            case 'visa':
                return '../../assets/images/icons/visa.svg';
            case 'amex':
                return '../../assets/images/icons/american-express.svg';
            case 'mastercard':
                return '../../assets/images/icons/mastercard.svg';
        }
    }

    handleForm(event) {
        this.purchaseData.recipient = {
            name: this.checkoutForm.controls.name.value,
            email: this.checkoutForm.controls.email.value
        }
        this.initializePurchase().subscribe(_ => this.captureStripePayment(this.secret));
    }
}
