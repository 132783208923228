import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { Affiliate } from '../models';
import { BaseService } from './base.service';

@Injectable({
	providedIn: 'root'
})

export class AffiliateService {
	private entity: string = 'affiliates';

	private affiliateLoadedSource = new BehaviorSubject<Affiliate>(null);
	affiliateLoaded$ = this.affiliateLoadedSource.asObservable();

	set affiliate(affiliate: Affiliate) {
		this.affiliateLoadedSource.next(affiliate);
	}

	get affiliate(): Affiliate {
		return this.affiliateLoadedSource.getValue();
	}

	constructor(private baseService: BaseService) { }

	fetch(idOrSlug: string): Observable<Affiliate> {
		let query: any = {};
		return this.baseService.fetch(this.entity, idOrSlug, query).pipe(map(res => new Affiliate().deserialize(res.affiliate)));
	}

}