import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SocialAuthService, FacebookLoginProvider, GoogleLoginProvider } from '@abacritt/angularx-social-login';
import { Order, User } from '../core/models';
import { TranslocoService } from '@ngneat/transloco';
import { Observable, zip } from 'rxjs';
import { MainService } from '../core/services/main.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ErrorRedirectComponent } from '../modals/error-redirect/error-redirect.component';
import { TranslocoLocaleService } from '@ngneat/transloco-locale';
import { ErrorType } from '../core/enums/error.enum';

@UntilDestroy()
@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {

	user$: Observable<User>;
	order$: Observable<Order>;
	marketOrder$: Observable<Order>;

	loginForm: UntypedFormGroup;
	error: ErrorType;
	loading: boolean = false;

	socialProvider: string;

	isNumberVerified: boolean = true;

	constructor(
		private fb: UntypedFormBuilder,
		private router: Router,
		private route: ActivatedRoute,
		public translate: TranslocoService,
		public locale: TranslocoLocaleService,
		private modalService: NgbModal,
		private socialAuthService: SocialAuthService,
		private mainService: MainService
	) {
		this.loginForm = this.fb.group({
			email: ['', [Validators.required, Validators.email]],
			password: ['', Validators.required],
		});


	}

	get googleButtonWidth() {
		if (window?.innerWidth <= 370) return 240;
		else if (window?.innerWidth <= 450) return 300;
		else return 400;
	}

	get loginFormControls() {
		return this.loginForm.controls;
	}

	ngOnInit() {
		this.user$ = this.mainService.userLoaded$;
		this.order$ = this.mainService.orderLoaded$;
		this.marketOrder$ = this.mainService.marketOrderLoaded$;

		zip([this.user$, this.order$, this.marketOrder$]).pipe(untilDestroyed(this)).subscribe(([user, order, marketOrder]) => {
			if (user == null) return;
			this.isNumberVerified = user.verified?.number;
			if (localStorage.getItem('redirect_url') == 'marché/checkout') this.redirectUser(false);
			order?.isActive ? this.redirectUser(true) : this.appointBag();
		})

		this.socialAuthService.authState.pipe(untilDestroyed(this)).subscribe((socialUser) => {
			if (socialUser && (this.socialProvider == 'facebook' || socialUser.provider == 'GOOGLE')) {
				this.error = null;
				if (socialUser.provider == 'GOOGLE') this.mainService.socialLogin(socialUser.idToken, 'google').subscribe({ error: err => this.enableErrors(err) })


				// this.socialAuthService.getAccessToken(GoogleLoginProvider.PROVIDER_ID).then(token => {
				// 	this.mainService.socialLogin(token, 'google').subscribe({ error: err => this.enableErrors(err) })
				// })
				else this.mainService.socialLogin(socialUser.authToken, this.socialProvider).subscribe({ error: err => this.enableErrors(err) });
			}
		});
	}

	//TODO: Refactor
	redirectUser(pendingOrder: boolean = false) {
		if (!this.isNumberVerified) {
			this.router.navigate(['verification']);
			return;
		}
		let redirect = decodeURI(localStorage.getItem('redirect_url'));
		localStorage.removeItem('redirect_url');
		if (redirect) {
			redirect = redirect.startsWith('/en/') || redirect.startsWith('/fr/') ? redirect.slice(3) : redirect;
			if (pendingOrder && redirect == '/checkout') redirect = '/order';
			this.router.navigate([this.translate.getActiveLang() + redirect]);
		}
		else this.router.navigate(['/' + this.translate.getActiveLang()]);
	}

	appointBag() { //Need to check user bag because bag observable isn't set before this is called. Ideally the combinelatest check should be fixed in ngoninit
		if (this.mainService.guestBag != null && !this.mainService.guestBag.isEmpty && this.mainService.user.hasNonEmptyPlatformBags) {
			const modalRef = this.modalService.open(ErrorRedirectComponent);
			modalRef.componentInstance.header = "replaceBag.header";
			modalRef.componentInstance.text = "replaceBag.message";
			modalRef.componentInstance.cancelButtonText = "replaceBag.no";
			modalRef.componentInstance.submitButtonText = "replaceBag.yes";
			modalRef.result.then(
				_ => this.completeBagAppointment(true),
				_ => this.completeBagAppointment(false)
			)
		}
		else if (this.mainService.guestBag != null && !this.mainService.guestBag.isEmpty) this.completeBagAppointment(true);
		else this.completeBagAppointment(false);
	}

	completeBagAppointment(isBagRetained: boolean) {
		this.mainService.persistGuestData(isBagRetained).subscribe(_ => this.redirectUser());
	}

	onSubmit(data) {
		this.loading = true;
		if (this.loginForm.invalid) {
			this.loading = false;
			return;
		} else {
			this.error = null;
			this.mainService.login(data.email, data.password).subscribe({ error: err => this.enableErrors(err) });
		}
	}

	signInWithFB(): void {
		this.socialProvider = 'facebook';
		this.socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID);
	}

	enableErrors(err) {
		console.log(err)
		this.error = err.error as ErrorType;
		this.loading = false;
	}
}
