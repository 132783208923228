import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { MenuSection } from 'src/app/core/models';
import { ConfigService } from '../config.service';

@Injectable({
  providedIn: 'root'
})

export class RestaurantPageV2Service {

    constructor(private configService: ConfigService) {}

    private image = new BehaviorSubject('https://dummyimage.com/600x1000/dddddd/fff');
    image$ = this.image.asObservable();

    private text = new BehaviorSubject('')
    text$ = this.text.asObservable()

    private featureTitle = new BehaviorSubject('')
    featureTitle$ = this.featureTitle.asObservable()

    private selectedMenuSection = new BehaviorSubject(null)
    selectedMenuSection$ = this.selectedMenuSection.asObservable()

    updateImage(url: string, clearText: boolean = true, restaurantSlug: string = null) {
        if (!url) {
            let image = this.configService.getDefaultImage(restaurantSlug)
            this.image.next(image)
        } else {
            this.image.next(url)
        }
        if (clearText) this.text.next('')
    }

    updateFeatureDescription(text: string = '') {
        this.text.next(text)
    }

    updateFeatureTitle(text: string = '') {
        this.featureTitle.next(text)
    }

    selectMenuSection(section: MenuSection) {
        this.selectedMenuSection.next(section)
        // if (section?.media[0] && section.media[0].url) {
        //     this.updateImage(section.media[0].url)
        // }
    }
}
