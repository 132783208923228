import { Component, Input, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { ConfigService } from 'src/app/core/services/config.service';
import { RestaurantService } from 'src/app/core/services/restaurant.service';
import { Restaurant } from 'src/app/core/models';

@Component({
    selector: 'app-about-v2',
    templateUrl: './about.component.html',
    styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {

    @Input() restaurant: Restaurant

    radiusCircleVisible: boolean = false
    zoom: number = 15

    constructor(
        public configService: ConfigService,
        public restaurantService: RestaurantService,
        public translate: TranslocoService) { }

    ngOnInit(): void {
    }

        toggleDeliveryRadiusClick() {
        if (this.radiusCircleVisible) this.zoom = 15
        else this.zoom = 10

        this.radiusCircleVisible = !this.radiusCircleVisible
    }

    encodeURI(str: string) {
        return encodeURI(str);
    }
}
