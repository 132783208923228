<app-navbar [navbarType]="'marketCheckout'"></app-navbar>

<!-- TODO: Adjust loading screen to marché format  -->
<div *ngIf="!isMapLoaded" class="checkout-loading-container">
    <div *ngIf="screenWidth > mobileThreshold" class="checkout-loading-inner-container">
        <div class="checkout-loading-left">
            <div class="checkout-loading-box loading-gradient"></div>
        </div>

        <div class="checkout-loading-right loading-gradient"></div>
    </div>
    <div *ngIf="screenWidth < mobileThreshold" class="market-checkout-mobile loading-gradient"></div>
</div>

<div class="market-checkout-container">
    <form action="submit" (submit)="handleForm($event)" [formGroup]="marketCheckoutForm" class="checkout-form">
        <!-- Your Delivery -->
        <div class="market-checkout-left">
            <h1 class="market-checkout-section-header-left">
                {{ 'checkout.title' | transloco }}
            </h1>

            <google-map *ngIf="(mobileThreshold >= screenWidth) && markersSet" [class.checkout-hidden]="!isMapLoaded"
                height="15rem" width="100vw" id="checkout-map" [center]="{ lat: centerLat, lng: centerLng}"
                [options]="mapOptions" (mapInitialized)="onMapLoaded($event)">
                <div *ngFor="let direction$ of directionsResults$">
                    <map-directions-renderer *ngIf="(direction$ | async) as directionsResults"
                        [directions]="directionsResults"
                        [options]="{ polylineOptions: { strokeColor: 'transparent' }, suppressMarkers: true, preserveViewport: true }">
                    </map-directions-renderer>
                </div>
                <map-marker [position]="{ lat: bagLat, lng: bagLng }" [icon]="houseIcon"></map-marker>
                <map-marker *ngFor="let coordinate of vendorCoordinates"
                    [position]="{ lat: coordinate.latitude, lng: coordinate.longitude }"
                    [icon]="restaurantIcon"></map-marker>
                <map-polyline *ngFor="let path of arcPaths" [options]="path"></map-polyline>
            </google-map>

            <div class="boxes-scroll-container">
                <div class="checkout-box">
                    <div class="checkout-box-header">
                        <div class="form-section-title checkout-box-title">
                            {{ 'checkout.yourDelivery' | transloco }}
                        </div>
                        <div class="checkout-box-tabs">
                            <div class="tab-control" [ngClass]="(tab == 'details') ? 'tab-active' : 'tab-inactive'">
                                {{ 'checkout.details.tabTitle' | transloco }}
                            </div>
                            <div class="caret-right">></div>
                            <div class="tab-control" [ngClass]="(tab == 'payment') ? 'tab-active' : 'tab-inactive'">
                                {{ 'checkout.payment.tabTitle' | transloco }}
                            </div>
                        </div>
                    </div>
                    <!-- Delivery Details -->
                    <div class="delivery-details-tab" *ngIf="tab == 'details'">
                        <div *ngIf="!openAddressInput" class="checkout-box-content">
                            <div class="box-row">
                                <!-- Delivery Date -->
                                <div class="checkout-box-icon-text delivery-date">
                                    <img class="checkout-box-icon calendar-icon"
                                        src="../../../assets/images/icons/calendar.svg" alt="" />
                                    <div *ngIf="isLoading; else isNotLoading" class="loading-date loading-gradient">
                                    </div>
                                    <ng-template #isNotLoading>
                                        <div class="checkout-box-text">
                                            <strong>
                                                {{ (order$ | async)?.scheduledDate | translocoDate : { dateStyle: 'full'
                                                } | capitalize }}
                                            </strong>
                                        </div>
                                    </ng-template>
                                </div>

                                <!-- Crate Delivery Time -->
                                <app-crate-delivery-scheduler [scheduledDate]="(order$ | async)?.scheduledDate"
                                    (dateTimeScheduled)="onDateTimeScheduled($event)"
                                    [marketCheckoutForm]="marketCheckoutForm">
                                </app-crate-delivery-scheduler>

                                <!-- Address -->
                                <div class="checkout-box-icon-text checkout-address">
                                    <img class="checkout-box-icon pin-icon" src="../../../assets/images/icons/pin.svg"
                                        alt="" />
                                    <div class="checkout-box-text"><strong>{{ (address$ | async) | address }}</strong>
                                    </div>
                                    <!-- <button type="button" (click)="changeAddress()" class="edit-icon-button">
                                        <img src="../../../assets/images/icons/edit.svg" alt="" class="edit-icon">
                                    </button> -->
                                </div>
                                <button type="button" *ngIf="!addApartment" (click)="addApartmentClick()"
                                    class="checkout-add-apartment checkout-add-button">
                                    + {{ 'checkout.addApartment' | transloco }}</button>
                                <div *ngIf="addApartment" class="checkout-label-input-row">
                                    <!-- TODO: transloco -->
                                    <label class="checkout-label-input-label" for="apartmentNumber"><span
                                            *ngIf="translate.getActiveLang() === 'fr'"># </span>Appt/Suite <span
                                            *ngIf="translate.getActiveLang() === 'en'">#</span></label>
                                    <input class="checkout-box-input form-input" type="text" id="apartmentNumber"
                                        formControlName="apartmentNumber">
                                </div>
                                <button type="button" (click)="changeAddress()" class="change-address-button">
                                    {{ 'checkout.changeAddress' | transloco }}
                                </button>
                            </div>
                            <!-- Reusable Crate -->
                            <!-- <div class="box-row checkout-delivery-box-instructions-container">
                                <div class="checkout-box-toggle-container">
                                    <div class="checkout-box-toggle-wrapper checkout-box-reusable-crate">
                                        <div class="checkout-box-title form-toggle-title">
                                            {{ 'checkout.reusableCrate' | transloco }}
                                        </div>
                                        <div class="checkout-box-switch-container">
                                            <label class="form-toggle">
                                                <input type="checkbox" formControlName="reusableCrateCheckbox" />
                                                <span class="form-toggle-slider"></span>
                                            </label>
                                        </div>
                                    </div>
                                    <div class="form-toggle-content">
                                        {{ 'checkout.reusableCrateMessage' | transloco }}
                                    </div>
                                </div>
                            </div> -->
                            <!-- Delivery Note -->
                            <div class="box-row delivery-note-box-row">
                                <div class="delivery-note-container">
                                    <div class="delivery-note-title form-sub-title">
                                        {{ 'checkout.deliveryInstructions' | transloco }}
                                    </div>
                                    <!-- (input)="deliveryNotesError = null" -->
                                    <input type="text" class="checkout-box-input form-input"
                                        formControlName="deliveryInstructions"
                                        [placeholder]="'checkout.deliveryInstructionsPlaceholder' | transloco"
                                        [class.form-input-invalid]="deliveryNoteError" />
                                    <div *ngIf="deliveryNoteError === 'RestrictedWords'" class="form-error-message">
                                        {{ 'checkout.deliveryNoteError' | transloco }}
                                    </div>
                                    <div *ngIf="deliveryNoteError === 'UnknownError'" class="form-error-message">
                                        {{ 'checkout.deliveryNoteError' | transloco }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Add Address Input -->
                        <div *ngIf="openAddressInput" class="checkout-box-content add-address-box">
                            <div class="address-change-container">
                                <div class="input-header">{{ 'checkout.deliveryAddress' | transloco }}</div>
                                <img class="address-close" src="../../../assets/images/close-icon.png" alt=""
                                    (click)="cancelAddress()" />
                            </div>
                            <input class="add-address-input" id="address-navbar-input"
                                (onAddressChange)="handleGoogleAddressChange($event)"
                                [placeholder]="'navbar.addressPlaceholder' | transloco" ngx-google-places-autocomplete
                                #placesRef="ngx-places" #inputAddressFocus #addressNavbarInput>
                        </div>

                        <div class="box-row">
                            <button type="button" class="continue-to-payment-button" (click)="switchTab('payment')"
                                [disabled]="!scheduledTime ? true : false"
                                [ngbTooltip]="!scheduledTime ? ('checkout.tooltips.deliveryTimeNotSelected' | transloco) : ''">
                                {{ 'checkout.details.continue' | transloco }}
                            </button>
                        </div>
                    </div>
                    <!-- Payment -->
                    <div class="payment-tab" *ngIf="(tab == 'payment')">
                        <!-- Credit/Debit Cards -->
                        <div class="box-row checkout-box-content checkout-box-cards-container">
                            <div class="checkout-card-section-label">{{ 'checkout.cards' | transloco }}</div>
                            <div *ngIf="isLoading; else isCards">
                                <div class="loading-credit-cards loading-gradient"></div>
                            </div>
                            <!-- Saved Cards -->
                            <ng-template #isCards>
                                <label *ngFor="let card of savedCards" class="checkout-card"
                                    (click)="onSavedCardClick()">
                                    <span><input class="checkout-card-radio-input" type="radio"
                                            formControlName="cardRadio" [value]="card.id" /></span>
                                    <div class="checkout-saved-card">
                                        {{ 'checkout.savedCard' | transloco }}
                                        <strong> {{ card.last4 }} </strong>
                                    </div>
                                    <img class="checkout-saved-card-logo" [src]="getCardLogo(card.brand)" alt="" />
                                </label>
                            </ng-template>
                            <!-- Add New Card -->
                            <button type="button" *ngIf="!openNewCard" (click)="openNewCard = true"
                                class="add-new-card-label checkout-add-button"
                                [ngClass]="isLoading ? 'add-new-card-label disabled-checkout-add-button' : 'add-new-card-label checkout-add-button'">
                                {{ 'checkout.addCard' | transloco }}
                            </button>
                            <div [ngClass]="openNewCard == true? 'open-new-card' : 'close-new-card'">
                                <span><input class="checkout-card-radio-input" type="radio" formControlName="cardRadio"
                                        value="new-card" id="new-card" hidden /></span>
                                <div class="checkout-box-input form-input checkout-card-input" #cardElement></div>
                            </div>
                            <p class="checkout-box-payment-errors">{{ cardErrors }}</p>
                        </div>
                        <!-- Gift Card -->
                        <div class="box-row gift-card-box-row">
                            <div class="checkout-box-toggle-wrapper">
                                <div class="checkout-box-title form-toggle-title">
                                    <div class="gift-card-section-label"> {{'checkout.giftCards' | transloco }} </div>
                                    <div class="gift-card-container">
                                        <div class="gift-card-image-wrapper">
                                            <img class="gift-card-image"
                                                [src]="selectedGiftCard?.theme?.front ? selectedGiftCard?.theme?.front.url : '../../assets/images/gift-card/gift-card-checkout-icon.png' ">
                                        </div>
                                        <div ngbDropdown autoClose="true" class="d-flex"
                                            #giftCardDropdown="ngbDropdown">
                                            <button type="button" class="gift-card-box" ngbDropdownToggle
                                                #giftCardDropdown>
                                                <div class="dropdown-label selected-card-wrapper"
                                                    *ngIf="selectedGiftCard">
                                                    <div class="selected-card-name"> {{ selectedGiftCard.title }} </div>
                                                    <div class="selected-card-price"> {{ selectedGiftCard.balance |
                                                        price | translocoCurrency }}</div>
                                                </div>
                                                <div class="dropdown-label" *ngIf="!selectedGiftCard"> {{
                                                    'checkout.noGiftCardSelected' | transloco }}</div>
                                            </button>
                                            <div ngbDropdownMenu
                                                class="gift-card-dropdown-menu-container gift-card-dropdown-menu-container-wide">
                                                <div class="gift-card-dropdown-menu-wide">
                                                    <div class="gift-card-dropdown-item"
                                                        (click)="selectedGiftCard = null; giftCardDropdown.close()">
                                                        <div class="null-icon-wrapper">
                                                            <img class="null-icon"
                                                                src="../../../assets/images/icons/null.svg">
                                                        </div>
                                                        <div class="gift-card-name">{{ 'checkout.noGiftCard' | transloco
                                                            }}</div>
                                                    </div>
                                                    <div class="gift-card-dropdown-item"
                                                        *ngFor="let giftCard of giftCards"
                                                        (click)="onSelectGiftCard(giftCard.id); giftCardDropdown.close()"
                                                        [ngClass]="{ 'active': selectedGiftCard === giftCard } ">
                                                        <div class="gift-card-image-wrapper">
                                                            <img class="gift-card-image"
                                                                [src]="giftCard.theme?.front.url">
                                                        </div>
                                                        <div class="gift-card-name">{{ giftCard.title }}</div>
                                                        <div class="gift-card-price">{{ giftCard.balance | price |
                                                            translocoCurrency }}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="gift-card-message">{{ 'giftCard.message' | transloco }}</div>
                        </div>

                        <div class="box-row back-to-details-box-row">
                            <button type="button" *ngIf="(tab == 'payment') && scheduledTime"
                                class="back-to-details-button" (click)="switchTab('details')">
                                {{ 'checkout.payment.back' | transloco }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <google-map *ngIf="(screenWidth > mobileThreshold) && markersSet" [class.checkout-hidden]="!isMapLoaded"
            height="100%" width="100vw" id="checkout-map" [options]="mapOptions"
            [center]="{ lat: centerLat, lng: centerLng}" (mapInitialized)="onMapLoaded($event)">

            <div *ngFor="let direction$ of directionsResults$">
                <map-directions-renderer *ngIf="(direction$ | async) as directionsResults"
                    [directions]="directionsResults"
                    [options]="{ polylineOptions: { strokeColor: 'transparent' }, suppressMarkers: true, preserveViewport: true }">
                </map-directions-renderer>
            </div>
            <map-marker [position]="{ lat: bagLat, lng: bagLng }" [icon]="houseIcon"></map-marker>
            <map-marker *ngFor="let coordinate of vendorCoordinates"
                [position]="{ lat: coordinate.latitude, lng: coordinate.longitude }"
                [icon]="restaurantIcon"></map-marker>
            <map-polyline *ngFor="let path of arcPaths" [options]="path"></map-polyline>
        </google-map>

        <div class="market-checkout-right">
            <section class="top-section">

                <h3 class="market-checkout-section-header-right d-none d-lg-block">
                    {{ 'orderSummary.orderSummary' | transloco }}
                </h3>

                <div (scroll)="onScroll($event)" id="orderSummary"
                    class="market-checkout-order-summary-container d-none d-lg-block">
                    <div *ngIf="showOverflowPill && checkOverflow()" class="overflow-pill">
                        <button (click)="scrollDown()" class="overflow-pill-button" type='button'>
                            <div>{{ 'checkout.scrollForMore' | transloco }}</div>
                            <div class="arrow-wrapper">
                                <img class="arrow" src="../../../assets/images/white-arrow.png" />
                            </div>
                        </button>
                    </div>
                    <ng-container *ngIf="!(crate$ | async)?.isEmpty">
                        <div *ngFor="let group of itemGroups" class="crate-items-wrapper">
                            <div class="crate-items-restaurant">{{ group.name }}</div>
                            <div *ngFor="let item of group.items" class="crate-item-wrapper">
                                <app-crate-item [item]="item" [disableDecrement]="disableDecrement"></app-crate-item>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </section>
            <section class="bottom-section">

                <div class="market-checkout-order-price-summary d-none d-lg-block">
                    <div class="market-checkout-order-subtotal market-checkout-order-price-detail">
                        <strong>{{ 'checkout.subtotal' | transloco }}</strong>
                        <strong>{{ (crate$ | async)?.subtotal | price | translocoCurrency }} </strong>
                    </div>
                    <div *ngIf="isLoading; else isFees" class="loading-container">
                        <div class="market-checkout-order-fee-type">{{ 'checkout.taxes' | transloco }}</div>
                        <div class="loading-fee loading-gradient"></div>
                    </div>
                    <ng-template #isFees>
                        <ng-container *ngIf="(order$ | async)?.fees as fees">
                            <!-- <ng-container *ngIf="fees"> -->
                            <div *ngFor="let fee of fees"
                                class="market-checkout-order-fee market-checkout-order-price-detail">
                                <div class="market-checkout-order-fee-type">{{ fee.title }}</div>
                                <div class="market-checkout-order-fee-amount">{{ fee.amount | price | translocoCurrency
                                    }}
                                </div>
                            </div>

                            <!-- <div class="tax-detail-button-container">
                                <div class="tax-detail-button-wrapper">
                                    {{ 'checkout.taxes' | transloco }}
                                    <button type="button" class="tax-detail-button" (click)="toggleTax()">
                                        {{ 'checkout.view' | transloco }}
                                    </button>
                                </div>
                                <div class="total-tax-amount">
                                    {{ calculateTaxes() | price | translocoCurrency }}
    
                                </div>
                            </div>
                            <ng-container *ngIf="showTax">
                                <div *ngFor="let fee of taxes" class="market-checkout-order-fee market-checkout-order-price-detail">
                                    <div class="market-checkout-order-fee-type">{{ fee.title }}</div>
                                    <div class="market-checkout-order-fee-amount">
                                        {{ fee.amount | price | translocoCurrency }}
                                    </div>
                                </div>
                            </ng-container> -->

                        </ng-container>
                    </ng-template>
                    <div *ngFor="let discountInstance of (order$ | async)?.discounts"
                        class="checkout-text-both-ends discount">
                        <div class="discount-left">
                            <span class="discount-title">{{ discountInstance.discount.title }}</span>
                            <button type="button" class="delete-discount-btn"
                                (click)="onDeleteDiscount(discountInstance['_id'])">
                                <img src="../../assets/images/icons/close.svg">
                            </button>
                        </div>
                        <div class="discount-right discount-price">-{{ discountInstance?.effects.total.value | price |
                            translocoCurrency }}</div>
                    </div>
                    <!-- Tip -->
                    <ng-container *ngIf="addTip">
                        <div #tip class="checkout-tip-options">
                            <input #tip id="tip_3" type="radio" value="3" formControlName="tipRadio"
                                (change)="tipRadioChange()" />
                            <label #tip class="checkout-tip-option-label" for="tip_3">{{ 3 | translocoCurrency: 'symbol'
                                : { minimumFractionDigits: 0 } }}</label>
                            <input #tip id="tip_5" type="radio" value="5" formControlName="tipRadio"
                                (change)="tipRadioChange()" />
                            <label #tip class="checkout-tip-option-label" for="tip_5">{{ 5 | translocoCurrency: 'symbol'
                                : { minimumFractionDigits: 0 } }}</label>
                            <input #tip id="tip_7" type="radio" value="7" formControlName="tipRadio"
                                (change)="tipRadioChange()" />
                            <label #tip class="checkout-tip-option-label" for="tip_7">{{ 7 | translocoCurrency: 'symbol'
                                : { minimumFractionDigits: 0 } }}</label>
                            <input #tip decimals="2" class="checkout-tip-option-custom checkout-box-input form-input"
                                (keypress)="onCustomTipKeypress($event)"
                                (input)="onCustomTipChange($event.target.value)" formControlName="tipRadio" type="text"
                                placeholder="Amount" appTipKeypress />
                            <button #tip type="button" (click)="onApplyTip()"
                                class="checkout-tip-option-apply market-checkout-add-button">
                                {{'promoCodeModal.applyCode' | transloco }}
                            </button>
                        </div>
                    </ng-container>
                    <button *ngIf="!marketCheckoutForm?.value?.tipAmountCustom" type="button" (click)="onAddTip()"
                        class="market-checkout-order-tip-button market-checkout-add-button" #tip>
                        {{ 'checkout.askTip' | transloco }}
                    </button>
                    <div *ngIf="marketCheckoutForm?.value?.tipAmountCustom" class="market-checkout-order-tip-wrapper">
                        <div class="market-checkout-order-tip-label-edit-wrapper">
                            <div class="market-checkout-order-tip-label"> {{ 'checkout.driverTip' | transloco }}</div>
                            <button #tip (click)="onAddTip()" type="button"
                                class="market-checkout-order-edit-button market-checkout-add-button">
                                {{ 'checkout.edit' | transloco }}
                            </button>
                        </div>
                        <div class="market-checkout-order-tip-amount market-checkout-order-fee-amount">
                            {{ marketCheckoutForm?.value?.tipAmountCustom | translocoCurrency }}
                        </div>
                    </div>
                </div>

                <div
                    class="market-checkout-order-total-price market-checkout-order-total-price-desktop d-none d-lg-flex">
                    <strong>{{ 'checkout.total' | transloco }}</strong>
                    <span *ngIf="isLoading; else isTotal">
                        <div class="loading-total loading-gradient"></div>
                    </span>
                    <ng-template #isTotal>
                        <strong>{{ calculateTotal() | async | price | translocoCurrency }}</strong>
                    </ng-template>
                </div>

                <!-- Place Order Button -->
                <div class="crate-checkout-button-wrapper" [ngbTooltip]="(crate$ | async)?.isEmpty? ('bag.emptyMessage' | transloco) : 
                ((crate$ | async)?.subtotal.lessThan(configService.MINIMUM_MARKET_ORDER_AMOUNT) ? 
                ('bag.belowMinimumOrderText' | transloco: { remaining: ((configService.MINIMUM_MARKET_ORDER_AMOUNT.subtract((crate$ | async)?.subtotal)) | price | translocoCurrency) }) : 
                (scheduledTime == null) ? ('checkout.tooltips.deliveryTimeNotSelected' | transloco) : '')"
                    [disableTooltip]="!disableCheckoutButton()" tooltipClass="radish-tooltip">
                    <!-- *ngIf="!(order$ | async)?.isActive"  -->
                    <div class="crate-checkout-button-wrapper" [ngbTooltip]="(crate$ | async)?.isEmpty? ('bag.emptyMessage' | transloco) : 
                ((crate$ | async)?.subtotal.lessThan(configService.MINIMUM_MARKET_ORDER_AMOUNT) ? 
                ('bag.belowMinimumOrderText' | transloco: { remaining: ((configService.MINIMUM_MARKET_ORDER_AMOUNT.subtract((crate$ | async)?.subtotal)) | price | translocoCurrency) }) : 
                (scheduledTime == null) ? ('checkout.tooltips.deliveryTimeNotSelected' | transloco) : '')"
                        [disableTooltip]="!disableCheckoutButton()" tooltipClass="radish-tooltip">
                        <button class="form-submit-button" type="submit" [disabled]="!(tab == 'payment') ||
                        !marketCheckoutForm.valid ||
                        cardErrors || ((crate$ | async)?.subtotal &&
                        configService.MINIMUM_MARKET_ORDER_AMOUNT.greaterThan((crate$ | async)?.subtotal)) ||
                        (isCardEmpty && marketCheckoutForm.controls.cardRadio.value === 'new-card')">
                            <span *ngIf="!isLoading && !isSubmitting">{{ 'checkout.placeOrder' | transloco }}</span>
                            <div *ngIf="isLoading || isSubmitting" class="spinner-border spinner-border-sm"
                                role="status">
                                <span class="sr-only">{{ 'common.loading' | transloco }}</span>
                            </div>
                        </button>
                    </div>
                </div>
                <!-- Add Promo Code -->
                <button type="button" class="market-checkout-add-button add-promo-code" (click)="onClickAddPromoCode()">
                    {{ 'checkout.promoCode' | transloco }}
                </button>
            </section>
        </div>

        <!-- Bottom Sheet (Mobile Order Summary View)-->
        <div class="overlay d-block d-lg-none" (click)="openBottomSheet = false" *ngIf="openBottomSheet"></div>
        <div class="bottom-sheet-container d-block d-lg-none" [@slideUpToggle]="openBottomSheet ? 'open' : 'closed'">
            <button type="button" #bottomSheetContent class="bottom-sheet-button" (click)="toggleBottomSheet()">
                <div class="bottom-sheet-button-wrapper">
                    <div class="bottom-sheet-button-title">{{ 'orderSummary.orderSummary' | transloco }}</div>
                    <div class="bottom-sheet-button-price">{{ calculateTotal() | async | price | translocoCurrency }}
                    </div>
                </div>
            </button>
            <div class="bottom-sheet-content">
                <div (scroll)="onScroll($event)" id="orderSummaryMobile"
                    class="market-checkout-order-summary-container d-block d-lg-none">
                    <div *ngIf="showOverflowPill && checkOverflow()" class="overflow-pill">
                        <button (click)="scrollDown()" class="overflow-pill-button" type='button'>
                            <div>{{ 'checkout.scrollForMore' | transloco }}</div>
                            <div class="arrow-wrapper">
                                <img class="arrow" src="../../../assets/images/white-arrow.png" />
                            </div>
                        </button>
                    </div>
                    <ng-container *ngIf="!(crate$ | async)?.isEmpty">
                        <div class="crate-items-container">
                            <div *ngFor="let group of itemGroups" class="crate-items-wrapper">
                                <div class="crate-items-restaurant">{{ group.name }}</div>
                                <div *ngFor="let item of group.items" class="crate-item bottom-sheet-crate-item">
                                    <app-crate-item [item]="item"
                                        [disableDecrement]="disableDecrement"></app-crate-item>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
                <!--  -->
                <div class="market-checkout-order-price-summary d-block d-lg-none">
                    <div class="market-checkout-order-subtotal market-checkout-order-price-detail">
                        <span>{{ 'checkout.subtotal' | transloco }}</span>
                        <span>{{ (crate$ | async)?.subtotal | price | translocoCurrency }} </span>
                    </div>
                    <ng-container *ngIf="(order$ | async)?.fees as fees">
                        <div *ngFor="let fee of fees"
                            class="market-checkout-order-fee market-checkout-order-price-detail">
                            <div class="market-checkout-order-fee-type">{{ fee.title }}</div>
                            <div class="market-checkout-order-fee-amount">{{ fee.amount | price | translocoCurrency }}
                            </div>
                        </div>
                    </ng-container>
                    <div *ngFor="let discountInstance of (order$ | async)?.discounts"
                        class="checkout-text-both-ends discount">
                        <div class="discount-left">
                            <span class="discount-title">{{ discountInstance.discount.title }}</span>
                            <button type="button" class="delete-discount-btn"
                                (click)="onDeleteDiscount(discountInstance['_id'])">
                                <img src="../../assets/images/icons/close.svg"></button>
                        </div>
                        <div class="discount-right discount-price">
                            -{{ discountInstance?.effects.total.value | price | translocoCurrency }}
                        </div>
                    </div>
                    <ng-container *ngIf="addTip">
                        <div #tip class="checkout-tip-options">
                            <input #tip id="tip_3" type="radio" value="3" formControlName="tipRadio"
                                (change)="tipRadioChange()" />
                            <label #tip class="checkout-tip-option-label" for="tip_3">{{ 3 | translocoCurrency: 'symbol'
                                : { minimumFractionDigits: 0 } }} </label>
                            <input #tip id="tip_5" type="radio" value="5" formControlName="tipRadio"
                                (change)="tipRadioChange()" />
                            <label #tip class="checkout-tip-option-label" for="tip_5">{{ 5 | translocoCurrency: 'symbol'
                                : { minimumFractionDigits: 0 } }} </label>
                            <input #tip id="tip_7" type="radio" value="7" formControlName="tipRadio"
                                (change)="tipRadioChange()" />
                            <label #tip class="checkout-tip-option-label" for="tip_7">{{ 7 | translocoCurrency: 'symbol'
                                : { minimumFractionDigits: 0 } }} </label>
                            <input #tip decimals="2" class="checkout-tip-option-custom checkout-box-input form-input"
                                (keypress)="onCustomTipKeypress($event)"
                                (input)="onCustomTipChange($event.target.value)" formControlName="tipRadio" type="text"
                                placeholder="Amount" appTipKeypress />
                            <button #tip type="button" (click)="onApplyTip()"
                                class="checkout-tip-option-apply market-checkout-add-button">{{'promoCodeModal.applyCode'
                                | transloco }}</button>
                        </div>
                    </ng-container>
                    <button type="button" *ngIf="!marketCheckoutForm?.value?.tipAmountCustom" (click)="onAddTip()"
                        class="market-checkout-order-tip-button market-checkout-add-button" #tip> {{ 'checkout.askTip' |
                        transloco }}</button>
                    <div *ngIf="marketCheckoutForm?.value?.tipAmountCustom" class="market-checkout-order-tip-wrapper">
                        <div class="market-checkout-order-tip-label-edit-wrapper">
                            <div class="market-checkout-order-tip-label"> {{ 'checkout.driverTip' | transloco }}</div>
                            <button #tip (click)="onAddTip()" type="button"
                                class="market-checkout-order-edit-button market-checkout-add-button">{{ 'checkout.edit'
                                | transloco }}</button>
                        </div>
                        <div class="market-checkout-order-tip-amount market-checkout-order-fee-amount">{{
                            marketCheckoutForm?.value?.tipAmountCustom | translocoCurrency }}</div>
                    </div>
                </div>
                <div class="bottom-sheet-total-price d-block d-lg-none">
                    <b>{{ 'checkout.total' | transloco }}</b>
                    <b>{{ calculateTotal() | async | price | translocoCurrency }}</b>
                </div>

                <div class="crate-checkout-button-wrapper" [ngbTooltip]="(crate$ | async)?.isEmpty? ('bag.emptyMessage' | transloco) : 
            ((crate$ | async)?.subtotal.lessThan(configService.MINIMUM_MARKET_ORDER_AMOUNT) ? 
            ('bag.belowMinimumOrderText' | transloco: { remaining: ((configService.MINIMUM_MARKET_ORDER_AMOUNT.subtract((crate$ | async)?.subtotal)) | price | translocoCurrency) }) : 
            (scheduledTime == null) ? 'Please select a delivery time' : '')"
                    [disableTooltip]="!disableCheckoutButton()" tooltipClass="radish-tooltip">
                    <button class="form-submit-button" type="submit" [disabled]="
                        !marketCheckoutForm.valid ||
                        cardErrors ||
                        configService.MINIMUM_MARKET_ORDER_AMOUNT.getAmount() > (crate$ | async)?.subtotal.getAmount() ||
                        (isCardEmpty && marketCheckoutForm.controls.cardRadio.value === 'new-card')">
                        <span>
                            {{ 'checkout.placeOrder' | transloco }}
                        </span>
                    </button>
                </div>
                <button type="button" style="margin-bottom:1rem;" class="market-checkout-add-button add-promo-code"
                    (click)="onClickAddPromoCode()">
                    {{ 'checkout.promoCode' | transloco }}
                </button>
            </div>
        </div>
    </form>
</div>

<app-footer *ngIf="isMapLoaded" [footerType]="'white'"></app-footer>