import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { MenuItemButtonType } from 'src/app/core/enums';
import { Bag, Product, Restaurant } from 'src/app/core/models';
import { ConfigService } from 'src/app/core/services/config.service';
import { MenuItemBaseComponent } from '../menu-item-base/menu-item-base.component';

@Component({
    selector: 'app-menu-item-with-image',
    templateUrl: './menu-item-with-image.component.html',
    styleUrls: ['./menu-item-with-image.component.css']
})
export class MenuItemWithImageComponent extends MenuItemBaseComponent implements OnInit {

    @Input() restaurant: Restaurant
    @Input() restaurantCssClass: string = ''
    @Input() productStyle;

    readonly MOBILE_THRESHOLD = 700;
    isMobile: boolean = false;

    constructor(public translate: TranslocoService,
        public configService: ConfigService) {
            super(translate, configService)
        }

    ngOnInit(): void {
        super.ngOnInit()
        this.isMobile = window.innerWidth < this.MOBILE_THRESHOLD
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.isMobile = window.innerWidth < this.MOBILE_THRESHOLD
    }

}
