import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Restaurant } from 'src/app/core/models';
import { NgbCarousel, NgbSlideEvent, NgbSlideEventSource } from '@ng-bootstrap/ng-bootstrap';
import { TranslocoService } from '@ngneat/transloco';
import { ConfigService } from 'src/app/core/services/config.service';
import { RestaurantSlug } from 'src/app/core/enums';

@Component({
    selector: 'app-restaurant-about-photo-information',
    templateUrl: './restaurant-about-photo-information.component.html',
    styleUrls: ['./restaurant-about-photo-information.component.css']
})
export class RestaurantAboutPhotoInformationComponent implements OnInit {
    @Input() restaurant: Restaurant;

    images;
    maharani;
    enchanteur;
    lyla;
    diolo;
    merRouge;
    arahova;
    delicieux;
    sham;
    nakamichi;
    croqueteria;
    ptitPlateau;
    marquis;
    sushiPlusBeaumont;
    kojiSoupe;
    lundisAuSoleil;
    tincSet;
    fanfarePizza;
    inochiExpress;
    basha;
    uluwatu;
    saintHoublon;
    chezTauBay;
    boustan;
    thaiExpressCdn;
    javaUCdn;
    mcgillPizza;
    yuan;
    segreta;
    omlt;
    quesadaCdn;
    oiseauxDePassage;
    cafeVita;
    burgerBros;
    julians;
    wallys;
    spiceBros;
    santaLucia;
    dakar;
    oFour;
    karin;
    marcheMarema;
    elephantThai;
    sushiyo;
    boiteVegane;
    heroBurgers;
    jrsCafe;
    cafeResonance;
    ubiSushi;
    melrose;
    obsceneVegan;
    lestersDeli;
    barranco;
    petitSeoul;
    hooters;
    darbar;
    jardinPetros;
    chezAnna;
    krood;
    hungarianCuisine;
    maynard;
    mFourBurritos;
    bocadillo;
    laurierBbq;
    oFish;
    sushiSamaConcordia;
    paniniStop;
    pokeStationCdn;
    teochewFoodie;
    tapigo;
    brigadePizza;
    alAmine;
    bernieBeigne;
    cacao70;
    tacosNation;
    poissonnerieSherbrooke;
    mintar;
    auxmerveilleuxdefred;
    laToile;
    sepLai;
    bistroDeLaCite;
    singhs;
    chaiTeaLounge;
    laCroissanterieFigaro;
    laFabriqueDeBagel;
    studentTastyBiryani;
    osukaSushi;
    lali;
    chezTaMere;
    chatpata;
    friteAlors;
    tibum;
    thePerle;
    gongCha;
    buffaloBill;
    souvlakiGrecPlus;
    pizzaSport;
    tsujiri;
    spartaChicken;
    p23Dumplings;
    burgzCantine;
    goplana;
    piriPiri;
    ukiyaIzakaya;
    livSalades;
    maisonDuDestin;
    flatBellyDeli;
    downtownDhaba;
    grillados;

    // RestoPage TODO: add resto property above.

    paused = false;
    unpauseOnArrow = false;
    pauseOnIndicator = false;
    pauseOnHover = true;
    showNavigationIndicators = false;

    @ViewChild('carousel', { static: true }) carousel: NgbCarousel;

    constructor(
        public translate: TranslocoService,
        public configService: ConfigService) { }

    ngOnInit(): void {
        this.maharani = {
            images: [
                '../../assets/images/buffet-maharani/buffet-maharani-photo-1.jpg',
                '../../assets/images/buffet-maharani/buffet-maharani-photo-2.jpg',
                '../../assets/images/buffet-maharani/buffet-maharani-photo-3.jpg',
                '../../assets/images/buffet-maharani/buffet-maharani-photo-4.jpg'
            ],
            tagline: {
                en: "An Indian buffet restaurant that isn't your typical buffet",
                fr: "Un restaurant indien vous proposant une formule de buffet hors du commun"
            },
            tags: {
                en: ["INDIAN"],
                fr: ["INDIEN"]
            },
            description: {
                en: "Buffet Maharani offers an innovative AYCE format that provides a variety of freshly made dishes all while reducing waste.",
                fr: "Buffet Maharani propose un format AYCE innovant qui offre une variété de plats fraîchement préparés tout en réduisant le gaspillage."
            }
        },
            this.enchanteur = {
                images: [
                    '../../assets/images/enchanteur/enchanteur-outside.jpg',
                    '../../assets/images/enchanteur/enchanteur-inside.jpg',
                    '../../assets/images/enchanteur/enchanteur-fries.jpg',
                    '../../assets/images/enchanteur/enchanteur-room.jpg'
                ],
                tagline: {
                    en: "The bistro in the heart of Villeray",
                    fr: "Le bistro au coeur de Villeray"
                },
                tags: {
                    en: ["BISTRO"],
                    fr: ["BISTRO"]
                },
                description: {
                    en: "It has been 20 years since L'Enchanteur has been pursuing its ambition of gathering people around a table with good food, in a warm, hospitable restaurant where one really feels at home.",
                    fr: "Cela fait 20 ans que l’Enchanteur poursuit son ambition de rassembler les gens du quartier autour d'une bonne table, dans un restaurant accueillant et chaleureux où l'on se sent chez soi. "
                }
            };
        this.lyla = {
            images: [
                '../../assets/images/lyla/lyla-food-1.jpg',
                '../../assets/images/lyla/lyla-food-3.jpg',
                '../../assets/images/lyla/lyla-inside.jpg',
                '../../assets/images/lyla/lyla-counter.jpg',
                '../../assets/images/lyla/lyla-drinks-1.jpg',
            ],
            tagline: {
                en: "A perfect phở with friends",
                fr: "Un phở parfait entre amis"
            },
            tags: {
                en: ["VIETNAMESE", "BYOB"],
                fr: ["VIETNAMIENNE", "APPORTEZ VOTRE VIN"]
            },
            description: {
                en: "For years, Lyla has been diligently serving its loyal clients in Park Extension with a variety of authentic Vietnamese soup, noodle and grilled meat dishes. It’s the perfect place to have a quick lunch or conclude (or begin) a night out with friends. <br><br> While you’re there, enjoy one of their Vietnamese fruit smoothies (Sinh tố) or bubble teas, or bring your own wine for an affordable, yet delectable meal.",
                fr: "Depuis des années, Lyla sert avec diligence ses fidèles clients de Parc-Extension en leur proposant une variété de soupes, de nouilles et de grillades vietnamiennes authentiques. C'est l'endroit idéal pour prendre un lunch rapide ou pour conclure (ou commencer) une soirée entre amis. <br><br> Pendant que vous y êtes, dégustez un de leurs smoothies aux fruits vietnamiens (Sinh tố) ou des thés à perles, ou bien apportez votre propre vin pour un repas abordable mais néanmoins délicieux."
            }
        };
        this.diolo = {
            images: [
                '../../assets/images/diolo/diolo-flag.jpg',
                '../../assets/images/diolo/diolo-table.jpg',
                '../../assets/images/diolo/diolo-table-2.jpg',
                '../../assets/images/diolo/diolo-outside.jpg',
                '../../assets/images/diolo/diolo-window.jpg',
                '../../assets/images/diolo/diolo-specialties.jpg',
                '../../assets/images/diolo/diolo-brochette.jpg',
                '../../assets/images/diolo/diolo-pastel.jpg',
            ],
            tagline: {
                en: "Let yourself be taken away by the flavours of Africa",
                fr: "Laissez-vous tenter par l’Afrique!"
            },
            tags: {
                en: ["SENEGALESE"],
                fr: ["SÉNÉGALAIS"]
            },
            description: {
                en: "At the head of one of Montreal's most prominent African restaurants, Senegalese chef Edmond Benoit Sadio masters to perfection not only his home-style dishes, but also the classics of West Africa, making your culinary journey an unforgettable experience.",
                fr: "À la tête de l’un des restaurants africains les plus en vue de Montréal, le chef sénégalais Edmond Benoit Sadio maîtrise à la perfection les plats à saveur de chez lui mais aussi les classiques de l’Afrique de l’ouest faisant de votre voyage culinaire une expérience inouïe."
            }
        };
        this.merRouge = {
            images: [
                '../../assets/images/mer-rouge/mer-rouge-plate-1.jpg',
                '../../assets/images/mer-rouge/mer-rouge-plate-2.jpg',
                '../../assets/images/mer-rouge/mer-rouge-plate-3.jpg',
                '../../assets/images/mer-rouge/mer-rouge-plate-4.jpg',
                '../../assets/images/mer-rouge/mer-rouge-plate-5.jpg',
                '../../assets/images/mer-rouge/mer-rouge-plate-6.jpg'
            ],
            tagline: {
                en: "From the Sea into the Fire",
                fr: "De la mer au feu"
            },
            tags: {
                en: ["MEDITERRANEAN", "SEAFOOD"],
                fr: ["MÉDITERRANÉEN", "FRUITS DE MER"]
            },
            description: {
                en: "At La Mer Rouge restaurant, our goal is to provide you with an exceptional dining experience you will never forget. Come and discover our diverse menu set to satisfy the most refined palates, and meet our staff who are always happy to welcome you.",
                fr: "Chez restaurant La Mer Rouge, notre objectif est de vous offrir une expérience culinaire vous ne serez jamais oublier. Venez y découvrir un menu varié qui satisfera les palais les plus raffinés, notre personnel toujours est heureux de vous recevoir."
            }
        };
        this.arahova = {
            images: [
                '../../assets/images/arahova/arahova-brochettes-poulet.jpg',
                '../../assets/images/arahova/arahova-calamari.jpg',
                '../../assets/images/arahova/arahova-lamb-chops.jpg',
                '../../assets/images/arahova/arahova-moussaka.jpg',
                '../../assets/images/arahova/arahova-octopus.jpg',
                '../../assets/images/arahova/arahova-pitas.jpg',
                '../../assets/images/arahova/arahova-spinach-cheese-pies.jpg',
                '../../assets/images/arahova/arahova-salad.jpg',
                '../../assets/images/arahova/arahova-platters.jpg'
            ],
            tagline: {
                en: "Bring the fresh flavours of Greece home with every order",
                fr: "Les saveurs fraîches de la Grèce ramenées chez vous à chaque commande"
            },
            tags: {
                en: ["GREEK", "MEDITERRANEAN"],
                fr: ["GREC", "MÉDITERRANÉEN"]
            },
            description: {
                en: "Named after the native Greek village of its founder, Arahova appeared in Montreal in 1971 and became one of the first Greek specialty restaurants. What was initially a small cafe and snack designed by Christos Kalogrias (the founder of restaurants Arahova Souvlaki), has become a local landmark that stands out regularly in the contests and restaurant guides thanks to its souvlakis.<br><br> What's the secret? Christos Kalogrias has always focused on quality, freshness and consistency, which has made Arahova the brand it is today.",
                fr: "Nommé d’après le village de la Grèce natale de son fondateur, Arahova a fait son apparition à Montréal en 1971 et devenait alors l’un des premiers restaurants spécialisés en mets grecs. Ce qui était au départ un petit café et casse-croûte conçu par Christos Kalogrias (le fondateur des restaurants Arahova Souvlaki), est devenu un point de repère local qui se distingue régulièrement dans les concours et les guides de restaurants grâce à ses souvlakis. <br><br> Quel est le secret? Christos Kalogrias a toujours mis l’accent sur la qualité, la fraîcheur et la constance, ce qui a permis à Arahova de devenir la marque qu’elle est aujourd’hui."
            }
        };
        this.delicieux = {
            images: [
                '../../assets/images/delicieux/delicieux-lemongrass-chicken.jpeg',
                '../../assets/images/delicieux/delicieux-dumplings.jpeg',
                '../../assets/images/delicieux/delicieux-dumplings-1.jpg',
                '../../assets/images/delicieux/delicieux-spring-roll.jpeg',
                '../../assets/images/delicieux/delicieux-black-milk-tea.jpg'
            ],
            tagline: {
                en: "A meal that's simply delicious",
                fr: "Un repas tout simplement délicieux"
            },
            tags: {
                en: ["CHINESE", "SZECHUAN", "BUBBLE TEA"],
                fr: ["CHINOIS", "SZECHUAN", "THÉ AU LAIT"]
            },
            description: {
                en: "Try everything from our handmade dumplings to bubble teas and other authentic asian dishes.",
                fr: "Essayez tout, de nos dumplings faits à la main aux thé aux perles et autres plats asiatiques authentiques."
            }
        };
        this.sham = {
            images: [
                '../../assets/images/sham/sham-bowls.jpg',
                '../../assets/images/sham/sham-inside-restaurant.jpg',
                '../../assets/images/sham/sham-plate-1.jpg',
                '../../assets/images/sham/sham-plate-2.jpg',
                '../../assets/images/sham/sham-plate-9.jpg',
                '../../assets/images/sham/sham-salad.jpg',
                '../../assets/images/sham/sham-sauces.jpg',
                '../../assets/images/sham/sham-table-2.jpg',
                '../../assets/images/sham/sham-tray.jpg',
                '../../assets/images/sham/sham-trays.jpg',
            ],
            tagline: {
                en: "'Best Vegan Restaurant in Montreal'",
                fr: "«Meilleur restaurant végétalien de Montréal»"
            },
            tags: {
                en: ["SYRIAN", "VEGAN"],
                fr: ["SYRIEN", "VÉGÉTALIEN"]
            },
            description: {
                en: "Located in the heart of Montreal, our restaurant's mission is to offer the most memorable dishes to our clientele. Vegan or Syrian style, we carefully select our ingredients and use only the freshest and most natural ingredients in the preparation of all our dishes. <br><br>Not sure what to order? Our menu offers a wonderful blend of healthy, homemade Middle Eastern and Mediterranean dishes to delight your taste buds. Start your meal with a fresh salad, such as Tabbouleh and Quinoa salad or Bulgur Tabbouleh salad. Then choose from an assortment of vegan sandwiches and poutines made with cashew cheese or freshly baked 10-inch pizzas.",
                fr: "Situé au cœur de Montréal, notre restaurant a pour mission d’offrir les plats les plus mémorables à notre clientèle. Végétaliens ou de style syrien, nous sélectionnons soigneusement nos ingrédients et n’utilisons que les plus frais et naturels dans la préparation de tous nos mets. <br><br>Vous ne savez pas quoi commander? Notre menu propose un magnifique mélange de plats du Moyen-Orient et méditerranéens sains et faits maison pour le plus grand bonheur de vos papilles gustatives. Commencez votre repas par une salade bien fraîche, comme la salade de taboulé et quinoa ou la salade boulgour taboulé. Pour le plat, choisissez ensuite parmi un assortiment de sandwichs végétaliens et de poutines préparées avec du fromage de noix de cajou ou des pizzas bien fraîches de 10 po."
            }
        };
        this.nakamichi = {
            images: [
                '../../assets/images/nakamichi/nakamichi-ramen-2.jpg',
                '../../assets/images/nakamichi/nakamichi-ramen-3.jpg',
                '../../assets/images/nakamichi/nakamichi-ramen-5.jpg'
            ],
            tagline: {
                en: "Bringing an authentic Japanese quality ramen to our beloved city, Montreal",
                fr: "Nous apportons à notre belle ville qu'est Montréal de délicieux ramens japonais d'une qualité authentique"
            },
            tags: {
                en: ["JAPANESE"],
                fr: ["JAPONAIS"]
            },
            description: {
                en: "Nakamichi was born in Kichijōji, the heart of subculture and a taste for fine things in Tokyo. We are replicating this philosophy in Montreal in hopes of bring the taste of ramen from scratch. We seek to make high quality ramen without adding any MSG or added fat to our soup. Using only the flavours that occur naturally in the ingredients we incorporate into our house made broths and soups.",
                fr: "Nakamichi est né à Kichijōji, le cœur de la sous-culture et le goût des belles choses à Tokyo. Nous reproduisons cette philosophie à Montréal dans l'espoir d'apporter le goût des ramen à partir de zéro. Nous cherchons à faire des ramen de haute qualité sans ajouter de MSG ou de graisse ajoutée à notre soupe. En utilisant uniquement les saveurs naturellement présentes dans les ingrédients que nous incorporons dans nos bouillons et soupes faits maison."
            }
        };
        this.croqueteria = {
            images: [
                '../../assets/images/croqueteria/croqueteria-dish-1.jpg',
                '../../assets/images/croqueteria/croqueteria-dish-2.jpg',
                '../../assets/images/croqueteria/croqueteria-dish-3.jpg',
                '../../assets/images/croqueteria/croqueteria-dish-4.jpg',
                '../../assets/images/croqueteria/croqueteria-dish-5.jpg',
                '../../assets/images/croqueteria/croqueteria-dish-6.jpg',
                '../../assets/images/croqueteria/croqueteria-dish-7.jpg',
                '../../assets/images/croqueteria/croqueteria-dish-8.jpg',
                '../../assets/images/croqueteria/croqueteria-dish-9.jpg',
                '../../assets/images/croqueteria/croqueteria-dish-10.jpg'

            ],
            tagline: {
                en: "There is No Love Like the Love for Croquetas",
                fr: "Il n’y a pas d’amour, comme l’amour pour les croquettes."
            },
            tags: {
                en: ["SPANISH", "MEDITERRANEAN"],
                fr: ["ESPAGNOL", "MÉDITERRANEAN"]
            },
            description: {
                en: "La Croquetería Montréal is a production kitchen who produce, frozen, or prêt-à-manger, traditional Spanish Croquetas. It’s a traditional Spanish product with deep European roots, a reminder of the homemade & comfort food values, so characteristic in the Mediterranean culture. <br><br> Our product is creamy, light and crispy as it can be, it’s a product that the whole family will enjoy and your visits too. Quality of ingredients, care and technique are our three must.",
                fr: "La Croquetería Montréal est une cuisine de production qui offre des Croquettes traditionnelles espagnoles, vendues congelées ou prêt-à-manger. Les croquettes ont des racines européennes, un rappel des plats fait maison et réconfortants, si typique de la culture Méditerranéenne. <br><br> Nos produits sont crémeux, légers et croustillants. Idéal pour toute la famille ainsi que pour vos invités. La qualité des ingrédients, la délicatesse et la technique de production sont nos trois priorités."
            }
        };
        this.ptitPlateau = {
            images: [
                '../../assets/images/ptit-plateau/ptit-plateau-dish-6.jpg',
                '../../assets/images/ptit-plateau/ptit-plateau-dish-8.jpg',
                '../../assets/images/ptit-plateau/ptit-plateau-dish-9.jpg',
                '../../assets/images/ptit-plateau/ptit-plateau-dish-11.jpg',
                '../../assets/images/ptit-plateau/ptit-plateau-dish-3.jpg',
                '../../assets/images/ptit-plateau/ptit-plateau-dish-4.jpg',
                '../../assets/images/ptit-plateau/ptit-plateau-inside-restaurant.jpg',
                '../../assets/images/ptit-plateau/ptit-plateau-inside-restaurant-1.jpg',
                '../../assets/images/ptit-plateau/ptit-plateau-salade.jpg',
                '../../assets/images/ptit-plateau/ptit-plateau-tartare.jpg',
                '../../assets/images/ptit-plateau/ptit-plateau-window.jpg'
            ],
            tagline: {
                en: "Charming little restaurant with specialties from the South West of France",
                fr: "Charmant petit restaurant aux spécialités du Sud-Ouest de la France"
            },
            tags: {
                en: ["FRENCH", "BISTRO"],
                fr: ["FRANÇAIS", "BISTRO"]
            },
            description: {
                en: "Le P'tit Plateau Restaurant is one of those little neighborhood bistros that have built up a large clientele over the years with their personal touch. The menu offers regional French cuisine. Bring your own wine to Restaurant Le P'tit Plateau, if you want to know which bottle to choose, call the restaurant to know the menu of the day.",
                fr: "Restaurant Le P'tit Plateau est un de ces petits bistrots de quartiers qui ont su, au fil des ans, se bâtir une importante clientèle d`habitués grâce à une petite touche personnelle qui donne une dimension humaine à l`endroit. Au menu, on vous propose une cuisine régionale française."
            }
        };
        // this.sushiPlusBeaumont = {
        //     images: [
        //         '../../assets/images/sushi-plus-beaumont/sushi-plus-beaumont-decor.JPG',
        //         '../../assets/images/sushi-plus-beaumont/sushi-plus-beaumont-sushi-1.jpg',
        //         '../../assets/images/sushi-plus-beaumont/sushi-plus-beaumont-fried-rice.JPG',
        //         '../../assets/images/sushi-plus-beaumont/sushi-plus-beaumont-inside-1.jpg',
        //         '../../assets/images/sushi-plus-beaumont/sushi-plus-beaumont-inside-2.JPG',
        //         '../../assets/images/sushi-plus-beaumont/sushi-plus-beaumont-inside-4.JPG',
        //         '../../assets/images/sushi-plus-beaumont/sushi-plus-beaumont-inside.JPG',
        //     ],
        //     tagline: {
        //         en: "TBD",
        //         fr: "TBD"
        //     },
        //     tags: {
        //         en: ["JAPANESE", "SUSHI"],
        //         fr: ["JAPANESE", "SUSHI"]
        //     },
        //     description: {
        //         en: "At Sushi Plus, we are proud to offer you a large selection of authentic and delicious sushi, as well as other Japanese dishes! We use the freshest ingredients and the tastiest spices to ensure that every new bite will be even better than the last. <br><br> Start your meal by ordering one of our soups or salads such as miso soup or hot and sour soup made with tasty spices. You can also try one of our appetizers such as our exotic octopus dumplings (6 dumplings) or our delicious fried mantua served with two steamed fried breads. We also offer a wide selection of Japanese dishes from which you can happily choose, such as our many tempura variations! <br><br> For the main course, come try our specialty, sushi! Whether you order one of our maki rolls or our sushi combos, you will be delighted! Try Rainbow maki roll, snapper sushi or cucumber avocado maki!",
        //         fr: "Chez Sushi Plus, nous sommes fiers de vous proposer une grande sélection de sushis authentiques et délicieux, ainsi que d’autres plats japonais! Nous utilisons les ingrédients les plus frais et les épices les plus goûteuses afin de garantir que chaque nouvelle bouchée sera encore meilleure que la précédente. <br><br> Commencez votre repas en commandant une de nos soupes ou salades comme la soupe miso ou la soupe aigre-piquante, concoctée avec de savoureuses épices. Vous pouvez également essayer une de nos entrées comme les exotiques boulettes de pieuvre (6 boulettes) ou notre délicieux mantou frit servi avec deux pains vapeur frits. Nous offrons également une grande sélection de plats japonais parmi lesquels vous pouvez allègrement choisir, comme nos nombreuses variations de tempura! <br><br> Pour le plat principal, venez essayer notre spécialité : les sushis! Que vous commandiez un de nos rouleaux makis ou nos combos de sushis, vous allez vous délecter! Essayez le rouleau maki Rainbow, le sushi au vivaneau ou le maki au concombre et à l’avocat!"
        //     }
        // };
        this.kojiSoupe = {
            images: [
                '../../assets/images/koji-soupe/koji-soupe-pantry-1.jpg',
                '../../assets/images/koji-soupe/koji-soupe-pantry-2.jpg',
                '../../assets/images/koji-soupe/koji-soupe-soups.jpg',
                '../../assets/images/koji-soupe/koji-soupe-daigakuimo-ps.jpg',
                '../../assets/images/koji-soupe/koji-soupe-mushrooms-ps.jpg',
                '../../assets/images/koji-soupe/koji-soupe-shoyu-beef-ps.jpg',
                '../../assets/images/koji-soupe/koji-soupe-soy-milk-ps.jpg',
                '../../assets/images/koji-soupe/koji-soupe-koji-1.JPG',
                '../../assets/images/koji-soupe/koji-soupe-spoons.JPG',
                '../../assets/images/koji-soupe/koji-soupe-sweet-potato-1.jpg',
                '../../assets/images/koji-soupe/koji-soupe-container.jpg'
            ],
            tagline: {
                en: "Warm up your day with a healthy and homemade Japanese gourmet soup",
                fr: "Rajoutez de la chaleur dans votre journée avec une soupe gastronomique japonaise saine et faite maison"
            },
            tags: {
                en: ["JAPANESE"],
                fr: ["JAPONAIS"]
            },
            description: {
                en: 'During the pandemic in 2020, we came up with the idea of a shared kitchen with a local business. Under the worldwide difficult situation, we are collaborating with a local restaurant which already has been licensed. <br><br> Koji Soupe is offering delivery service from our kitchen. By cutting the overhead, we can offer high-quality foods at a reasonable price. Our virtual kitchen offers homemade Japanese soups. However, it is not only a typical type of miso soup. <br><br> Our soup is "Meal Soup" cooked with koji fermented rice which helps to make meats tender and add flavor and depth to the dish. All our dishes are made with heart, and of course there are NO CHEMICAL ingredients inside.',
                fr: 'Au cours de la pandémie, nous avons eu l‘idée de démarrer une cuisine partagée avec un restaurant local établi. Malgré le contexte difficile dans le monde entier, nous avons choisi de collaborer avec un restaurant local déjà établi. <br><br> Koji Soupe offre un service de livraison depuis notre cuisine. En réduisant les frais généraux typique d’un restaurant brique et mortier, nous pouvons offrir des aliments de haute qualité à un prix raisonnable. Notre cuisine virtuelle propose des soupes japonaises maison. Cependant, ce n‘est pas une simple soupe miso typique. <br><br> Notre soupe est une "Soupe repas" cuite avec du riz fermenté Koji qui qui rend la viande plus tendre et rehausse la saveur de la soupe. Tous nos plats sont faits avec amour et ne contiennent pas d’ingrédients CHIMIQUES.'
            }
        };
        this.lundisAuSoleil = {
            images: [
                '../../assets/images/lundis-au-soleil/lundis-au-soleil-team.jpg',
                '../../assets/images/lundis-au-soleil/lundis-au-soleil-terrasse.jpg',
                '../../assets/images/lundis-au-soleil/lundis-au-soleil-table-2.jpg',
                '../../assets/images/lundis-au-soleil/lundis-au-soleil-noodles.jpg',
                '../../assets/images/lundis-au-soleil/lundis-au-soleil-salad.jpg',
                '../../assets/images/lundis-au-soleil/lundis-au-soleil-salad-1.jpg',
                '../../assets/images/lundis-au-soleil/lundis-au-soleil-dish-1.jpg',
                '../../assets/images/lundis-au-soleil/lundis-au-soleil-dish.jpg',
                '../../assets/images/lundis-au-soleil/lundis-au-soleil-drinks.jpg',
                '../../assets/images/lundis-au-soleil/lundis-au-soleil-inside.jpg',
                '../../assets/images/lundis-au-soleil/lundis-au-soleil-table-1.jpg',
            ],
            tagline: {
                en: 'Lundis au soleil se déplace <br> chez vous!',
                fr: 'Lundis au soleil se déplace <br> chez vous!'
            },
            tags: {
                en: ['BISTRO'],
                fr: ['BISTRO']
            },
            description: {
                en: 'En attendant de pouvoir vous accueillir à nouveau en salle, notre restaurant de quartier offre désormais sa réconfortante cuisine de saison en livraison.',
                fr: 'En attendant de pouvoir vous accueillir à nouveau en salle, notre restaurant de quartier offre désormais sa réconfortante cuisine de saison en livraison.'
            }
        };
        this.tincSet = {
            images: [
                '../../assets/images/tinc-set/carousel/tinc-set-vin.jpg',
                '../../assets/images/tinc-set/carousel/tinc-set-counter.jpg',
                '../../assets/images/tinc-set/carousel/tinc-set-courge.jpg',
                '../../assets/images/tinc-set/carousel/tinc-set-poulet-roti.jpg',
                '../../assets/images/tinc-set/carousel/tinc-set-poulet-roti-2.jpg',
                '../../assets/images/tinc-set/carousel/tinc-set-radicchio-grille.jpg',
                '../../assets/images/tinc-set/carousel/tinc-set-radis-grille.jpg',
                '../../assets/images/tinc-set/carousel/tinc-set-churros.jpg',
                '../../assets/images/tinc-set/carousel/tinc-set-inside.jpg',
                '../../assets/images/tinc-set/carousel/tinc-set-takeout.jpg',
                '../../assets/images/tinc-set/carousel/tinc-set-vin-6.jpg',
                '../../assets/images/tinc-set/carousel/tinc-set-vin-2.jpg'
            ],
            tagline: {
                en: 'TINC SET - J‘AI SOIF',
                fr: 'TINC SET - J‘AI SOIF'
            },
            tags: {
                en: ['ROTISSERIE', 'WINE'],
                fr: ['RÔTISSERIE', "CÂVISTE"]
            },
            description: {
                en: 'Tinc Set veut dire j’ai soif en catalan, une phrase qu’on dit souvent ! C‘est aussi le nom de l‘une de nos cuvées préferées du Celler Mas Candi. <br><br> Il faut dire que le projet était rêvé depuis déjà quelques temps… un concept plus casual, situé non loin du Alma, mais pas forcément dans la ruelle ! <br><br> Autant que le charme de la ruelle nous manque, on est ravis de vous annoncer l’ouverture du nouveau local à côté du Alma. <br><br> De Mexico à Barcelone, le poulet rôti nous fascine depuis toujours. Un hommage à nos souvenirs de jeunesse et de voyage, Juan travaille depuis un moment pour perfectionner sa recette. L‘idée est de faire briller les saveurs méditerranéennes en parallèle avec des produits locaux et les vins de nos vignerons. <br><br> Le Tinc Set est un retour à la simplicité. La famille, le partage, le bonheur.',
                fr: 'Tinc Set veut dire j’ai soif en catalan, une phrase qu’on dit souvent ! C‘est aussi le nom de l‘une de nos cuvées préferées du Celler Mas Candi. <br><br> Il faut dire que le projet était rêvé depuis déjà quelques temps… un concept plus casual, situé non loin du Alma, mais pas forcément dans la ruelle ! <br><br> Autant que le charme de la ruelle nous manque, on est ravis de vous annoncer l’ouverture du nouveau local à côté du Alma. <br><br> De Mexico à Barcelone, le poulet rôti nous fascine depuis toujours. Un hommage à nos souvenirs de jeunesse et de voyage, Juan travaille depuis un moment pour perfectionner sa recette. L‘idée est de faire briller les saveurs méditerranéennes en parallèle avec des produits locaux et les vins de nos vignerons. <br><br> Le Tinc Set est un retour à la simplicité. La famille, le partage, le bonheur.'
            }
        };
        this.fanfarePizza = {
            images: [
                '../../assets/images/fanfare-pizza/carousel/fanfare-pizza-outside.jpg',
                '../../assets/images/fanfare-pizza/carousel/fanfare-pizza-11.jpg',
                '../../assets/images/fanfare-pizza/carousel/fanfare-pizza-3.jpg',
                '../../assets/images/fanfare-pizza/carousel/fanfare-pizza-2.jpg',
                '../../assets/images/fanfare-pizza/carousel/fanfare-pizza-inside.jpg',
                '../../assets/images/fanfare-pizza/carousel/fanfare-pizza-4.jpg',
                '../../assets/images/fanfare-pizza/carousel/fanfare-pizza-5.jpg',
                '../../assets/images/fanfare-pizza/carousel/fanfare-pizza-6.jpg',
                '../../assets/images/fanfare-pizza/carousel/fanfare-pizza-window.jpg',
                '../../assets/images/fanfare-pizza/carousel/fanfare-pizza-7.jpg',
                '../../assets/images/fanfare-pizza/carousel/fanfare-pizza-8.jpg',
                '../../assets/images/fanfare-pizza/carousel/fanfare-pizza-9.jpg',
                '../../assets/images/fanfare-pizza/carousel/fanfare-pizza-patio.jpg',
                '../../assets/images/fanfare-pizza/carousel/fanfare-pizza-10.jpg',
                '../../assets/images/fanfare-pizza/carousel/fanfare-pizza-1.jpg',
            ],
            tagline: {
                en: 'L’alliance d’un chef et d’un boulanger pour des pizzas funky et éclatantes de saveurs',
                fr: 'L’alliance d’un chef et d’un boulanger pour des pizzas funky et éclatantes de saveurs'
            },
            tags: {
                en: ['PIZZA'],
                fr: ['PIZZA']
            },
            description: {
                en: 'Fanfare c’est avant tout une boulangerie et un restaurant de quartier. C’est le plaisir de la cuisine bien faites et des bons produits. La pizza c’était notre solution pour continuer de vous nourrir, et ce fut finalement un succès. Vous retrouverez dans nos pizzas tout notre savoir-faire et notre expérience. Avec ce petit côté festif que nous adorons tant.',
                fr: 'Fanfare c’est avant tout une boulangerie et un restaurant de quartier. C’est le plaisir de la cuisine bien faites et des bons produits. La pizza c’était notre solution pour continuer de vous nourrir, et ce fut finalement un succès. Vous retrouverez dans nos pizzas tout notre savoir-faire et notre expérience. Avec ce petit côté festif que nous adorons tant.'
            }
        };
        this.inochiExpress = {
            images: [
                '../../assets/images/inochi-express/carousel/inochi-express-17.jpg',
                '../../assets/images/inochi-express/carousel/inochi-express-15.jpg',
                '../../assets/images/inochi-express/carousel/inochi-express-16.jpg',
                '../../assets/images/inochi-express/carousel/inochi-express-18.jpg',
                '../../assets/images/inochi-express/carousel/inochi-express-19.jpg',
                '../../assets/images/inochi-express/carousel/inochi-express-20.jpg',
                '../../assets/images/inochi-express/carousel/inochi-express-21.jpg',
                '../../assets/images/inochi-express/carousel/inochi-express-24.jpg',
                '../../assets/images/inochi-express/carousel/inochi-express-25.jpg',
                '../../assets/images/inochi-express/carousel/inochi-express-26.jpg',
                '../../assets/images/inochi-express/carousel/inochi-express-7.jpg',
                '../../assets/images/inochi-express/carousel/inochi-express-9.jpg',
                '../../assets/images/inochi-express/carousel/inochi-express-10.jpg',
                '../../assets/images/inochi-express/carousel/inochi-express-11.jpg',
                '../../assets/images/inochi-express/carousel/inochi-express-12.jpg',
                '../../assets/images/inochi-express/carousel/inochi-express-8.jpg',
            ],
            tagline: {
                en: 'At INOCHI, we strive for quality and perfection in our work.',
                fr: 'Chez INOCHI, nous ne permettons que la qualité et la perfection.'
            },
            tags: {
                en: ['SUSHI'],
                fr: ['SUSHI']
            },
            description: {
                en: 'Our professional staffs meticulously prepare each roll manually, picking and using the freshest ingredients. The variety in selection will leave you indecisive, but not to worry, our knowledgeable team can provide you with recommendations based on your taste and curiosity.',
                fr: 'Notre personnel prépare méticuleusement chaque rouleau manuellement, sélectionnant et employant les ingrédients les plus frais. La variété dans nos choix vous laissera indécis, mais ne pas à vous inquiéter, notre équipe bien informée pourra vous fournir des recommandations basées sur votre goût et curiosité.'
            }
        };
        this.basha = {
            images: [
                '../../assets/images/basha/carousel/basha-plate-8.jpg',
                '../../assets/images/basha/carousel/basha-plate-grillades.jpg',
                '../../assets/images/basha/carousel/basha-plate-combo.jpg',
                '../../assets/images/basha/carousel/basha-plate-falafel.jpg',
                '../../assets/images/basha/carousel/basha-chef-special.jpg',
                '../../assets/images/basha/carousel/basha-baklava.jpg',
                '../../assets/images/basha/carousel/basha-plate-10.jpeg',
                '../../assets/images/basha/carousel/basha-potatoes.jpeg',
                '../../assets/images/basha/carousel/basha-poutine.jpeg',
                '../../assets/images/basha/carousel/basha-salad-2.jpg',
                '../../assets/images/basha/carousel/basha-salad-3.jpg',
                '../../assets/images/basha/carousel/basha-salad-5.jpg',
                '../../assets/images/basha/carousel/basha-salad.jpg',
                '../../assets/images/basha/carousel/basha-sandwich.jpg',
                '../../assets/images/basha/carousel/basha-falafel.jpg',
                '../../assets/images/basha/carousel/basha-green-rolls.jpg',
                '../../assets/images/basha/carousel/basha-meat-1.jpg',
            ],
            tagline: {
                en: 'Lebanese and Vegetarian cuisine Since 1977',
                fr: 'Cuisine libanaise et végétarienne Depuis 1977'
            },
            tags: {
                en: ['LEBANESE'],
                fr: ['LIBANAIS']
            },
            description: {
                en: 'Come and discover our Lebanese inspired cuisine. Fresh products, a warm atmosphere, talented cooks, in short, a perfect cuisine for you, your friends and family! <br><br> The first Basha restaurant was established in 1977. And since then we have been honored and classified as the best Lebanese restaurant.',
                fr: 'Venez découvrir notre cuisine d‘inspiration libanaise. Des produits frais, une ambiance chaleureuse, des cuisiniers talentueux, en bref une cuisine parfaite pour vous, vos amis et votre famille ! <br><br> C’est en 1977 que le premier restaurant Basha voit le jour. Et depuis qu‘on est honoré et classé comme étant le meilleur restaurant libanais.'
            }
        };
        this.uluwatu = {
            images: [
                '../../assets/images/uluwatu/uluwatu-dish-1.jpg',
                '../../assets/images/uluwatu/uluwatu-dish-2.jpg',
                '../../assets/images/uluwatu/uluwatu-dish-3.jpg',
                '../../assets/images/uluwatu/uluwatu-dish-4.jpg',
                '../../assets/images/uluwatu/uluwatu-dish-5.jpg',
                '../../assets/images/uluwatu/uluwatu-dish-6.jpg',
                '../../assets/images/uluwatu/uluwatu-dish-7.jpg',
                '../../assets/images/uluwatu/uluwatu-dish-8.jpg',
                '../../assets/images/uluwatu/uluwatu-dish-9.jpg',
                '../../assets/images/uluwatu/uluwatu-dish-10.jpg'
            ],
            tagline: {
                en: "The Authentic Taste of Indonesian Cuisine",
                fr: "Le goût authentique de la cuisine Indonésiennee"
            },
            tags: {
                en: ["INDONESIAN", "ASIAN FUSION"],
                fr: ["INDONÉSIEN", "FUSION ASIATIQUE"]
            },
            description: {
                en: "Craving some delicious Indonesian food? Maybe you’re in the mood to take a break and comfort food? No matter what kind of meal you have in mind, Uluwatu is ready to serve it for you. <br><br> Welcome to a real Indonesian family business where you can get acquainted with home-made dishes from Indonesian cuisine in a cosy, homely atmosphere. Our restaurant serves breakfast all day, in addition to wholesome and flavorful dining options for lunch and dinner.  <br><br> From Indonesian streetfood to traditional dishes, you’ll find all kinds of hearty meals prepared fresh at Uluwatu. Our ever-changing menu caters to vegetarians and meat-eaters alike. We use the freshest ingredients, local and organic where possible, with real authentic ingredients from Indonesia.",
                fr: "Envie de délicieux plats indonésiens? Peut-être êtes-vous d'humeur à faire une pause et à vous réconforter? Peu importe le type de repas que vous avez en tête, Uluwatu est prêt à vous le servir. <br><br> Bienvenue dans une véritable entreprise familiale indonésienne où vous pourrez vous familiariser avec les plats faits maison de la cuisine indonésienne dans une atmosphère chaleureuse. Notre restaurant sert le petit-déjeuner toute la journée, ainsi que des options de restauration saines et savoureuses pour le déjeuner et le dîner. <br><br>  Des plats de rue indonésiens aux plats traditionnels, vous trouverez toutes sortes de plats copieux préparés frais à Uluwatu. Notre menu en constante évolution s'adresse aux végétariens et aux mangeurs de viande. Nous utilisons les ingrédients les plus frais, locaux et biologiques si possible, avec de vrais ingrédients authentiques d'Indonésie. "
            }
        };
        this.saintHoublon = {
            images: [
                '../../assets/images/saint-houblon/saint-houblon-dish-1.jpg',
                '../../assets/images/saint-houblon/saint-houblon-dish-2.jpg',
                '../../assets/images/saint-houblon/saint-houblon-dish-3.jpg',
                '../../assets/images/saint-houblon/saint-houblon-dish-4.jpg',
                '../../assets/images/saint-houblon/saint-houblon-dish-5.jpg',
                '../../assets/images/saint-houblon/saint-houblon-dish-6.jpg',
                '../../assets/images/saint-houblon/saint-houblon-dish-7.jpg',
                '../../assets/images/saint-houblon/saint-houblon-dish-8.jpg',
                '../../assets/images/saint-houblon/saint-houblon-dish-9.jpg',
                '../../assets/images/saint-houblon/saint-houblon-dish-10.jpg'
            ],
            tagline: {
                en: "Saint-Houblon is a microbrewery, restaurants, a farm and a foodtruck!",
                fr: "Le Saint-Houblon, c'est une microbrasserie, des restaurants, une ferme et un foodtruck !"
            },
            tags: {
                en: ["BAR", "BREW PUB", "FUSION"],
                fr: ["PUB", "BAR À BIÈRES", "FUSION"]
            },
            description: {
                en: "We work very hard to make sure you have a good time at our restaurant. <br><br> Our goal: that Saint-Houblon, from your first visit, becomes one of your favorite restaurants!",
                fr: "Nous travaillons très fort pour vous faire passer un bon moment chez nous. <br><br> Notre objectif : que le Saint-Houblon devienne, dès votre première visite, l’un de vos restaurants préférés !"
            }
        };
        this.chezTauBay = {
            images: [
                '../../assets/images/chez-tau-bay/chez-tau-bay-dish-1.jpg',
                '../../assets/images/chez-tau-bay/chez-tau-bay-dish-2.jpg',
                '../../assets/images/chez-tau-bay/chez-tau-bay-dish-3.jpg',
                '../../assets/images/chez-tau-bay/chez-tau-bay-dish-4.jpg',
                '../../assets/images/chez-tau-bay/chez-tau-bay-dish-5.jpg',
                '../../assets/images/chez-tau-bay/chez-tau-bay-dish-6.jpg',
                '../../assets/images/chez-tau-bay/chez-tau-bay-dish-7.jpg',
                '../../assets/images/chez-tau-bay/chez-tau-bay-dish-8.jpg',
                '../../assets/images/chez-tau-bay/chez-tau-bay-dish-9.jpg',
                '../../assets/images/chez-tau-bay/chez-tau-bay-dish-10.jpg'
            ],
            tagline: {
                en: "Discover Traditional Vietnamese Cuisine",
                fr: "Découvrez la cuisine <br> vietnamienne traditionnelle"
            },
            tags: {
                en: ["VIETNAMESE"],
                fr: ["VIETNAMIENNE"]
            },
            description: {
                en: "Restaurant Chez Tau Bay provides delicious, traditional Vietnamese meals. With over 90 dishes prepared from fresh ingredients and homemade sauces, there is always something new to try. <br><br> Whether you warm up with a steaming bowl of phở or share a plate of our fan favourite spring rolls, Restaurant Chez Tau Bay invites you to try out the plentiful selection our menu has to offer!",
                fr: "Le restaurant Chez Tau Bay propose de délicieux plats vietnamiens traditionnels. Avec plus de 90 plats préparés à partir d'ingrédients frais et de sauces maison, il y a toujours quelque chose de nouveau à essayer. <br><br> Que vous vous réchauffiez avec un bol de ph of fumant ou que vous partagiez une assiette de nos rouleaux de printemps préférés des fans, le restaurant Chez Tau Bay vous invite à essayer la vaste sélection de notre menu!"
            }
        };
        this.boustan = {
            images: [
                '../../assets/images/boustan/boustan-dish-1.jpg',
                '../../assets/images/boustan/boustan-dish-2.jpg',
                '../../assets/images/boustan/boustan-dish-3.jpg',
                '../../assets/images/boustan/boustan-dish-4.jpg',
                '../../assets/images/boustan/boustan-dish-5.jpg',
                '../../assets/images/boustan/boustan-dish-6.jpg',
                '../../assets/images/boustan/boustan-dish-7.jpg',
                '../../assets/images/boustan/boustan-dish-8.jpg',
                '../../assets/images/boustan/boustan-dish-9.jpg',
                '../../assets/images/boustan/boustan-dish-10.jpg'
            ],
            tagline: {
                en: "Lebanese Food Like No Other",
                fr: "Mets libanais pas comme les autres"
            },
            tags: {
                en: ["LEBANESE"],
                fr: ["LIBANAIS"]
            },
            description: {
                en: "Fast casual restaurant, true Lebanese taste with modern local twist. Your first visit will be the first of many. Let your senses guide you to the delectable flavours of the Mediterranean. Only at Boustan will you feel as though you are eating homemade Lebanese food - accessible, convenient and nutritious.",
                fr: "Restaurant décontracté rapide, vrai goût libanais avec une touche locale moderne. Votre première visite sera la première d'une longue série. Laissez vos sens vous guider vers les saveurs délectables de la Méditerranée. Ce n'est qu'au Boustan que vous aurez l'impression de manger de la nourriture libanaise maison - accessible, pratique et nutritive."
            }
        };
        this.thaiExpressCdn = {
            images: [
                '../../assets/images/thai-express-cdn/carousel/thai-express-1.jpg',
                '../../assets/images/thai-express-cdn/carousel/thai-express-2.jpg',
                '../../assets/images/thai-express-cdn/carousel/thai-express-3.jpg',
                '../../assets/images/thai-express-cdn/carousel/thai-express-4.jpg',
                '../../assets/images/thai-express-cdn/carousel/thai-express-5.jpg',
                '../../assets/images/thai-express-cdn/carousel/thai-express-6.jpg',
                '../../assets/images/thai-express-cdn/carousel/thai-express-7.jpg',
                '../../assets/images/thai-express-cdn/carousel/thai-express-8.jpg',
                '../../assets/images/thai-express-cdn/carousel/thai-express-9.jpg',
                '../../assets/images/thai-express-cdn/carousel/thai-express-10.jpg',
                '../../assets/images/thai-express-cdn/carousel/thai-express-11.jpg',
                '../../assets/images/thai-express-cdn/carousel/thai-express-12.jpg',
            ],
            tagline: {
                en: "It’s same same<br>but different™",
                fr: "C’est différemment<br>authentique™"
            },
            tags: {
                en: ["THAI"],
                fr: ["THAÏLANDAIS"]
            },
            description: {
                en: "At Thai Express™, we’ve learned that when you treat your taste buds right, great things happen.<br><br>It all starts with fresh, delicious ingredients. No surprise there. But just as important is what we do to them. We specialize in traditional Thai recipes, but we have the vision to sprinkle in enough new-world creativity to keep you on your toes. And because our focus is on fresh, vibrant food, you can feel good about meals that taste great.",
                fr: "Chez Thai Express ™, nous avons appris que lorsque vous traitez bien vos papilles, de grandes choses se produisent.<br><br>Tout commence avec des ingrédients frais et délicieux. Pas de surprise là. Mais l’importance est ce que nous faisons avec ces ingrédients. Nous nous spécialisons dans les recettes traditionnelles thaïlandaises, mais nous avons la vision de saupoudrer assez de créativité du nouveau monde pour vous tenir en haleine. Et parce que nous mettons l’accent sur des aliments frais et dynamiques, vous pouvez vous sentir à l’aise avec les repas qui ont bon goût."
            }
        };
        this.javaUCdn = {
            images: [
                '../../assets/images/java-u-cdn/carousel/java-u-dish-1.jpg',
                '../../assets/images/java-u-cdn/carousel/java-u-dish-2.jpg',
                '../../assets/images/java-u-cdn/carousel/java-u-dish-3.jpg',
                '../../assets/images/java-u-cdn/carousel/java-u-dish-4.jpg',
                '../../assets/images/java-u-cdn/carousel/java-u-dish-5.jpg',
                '../../assets/images/java-u-cdn/carousel/java-u-dish-6.jpg',
                '../../assets/images/java-u-cdn/carousel/java-u-dish-7.jpg',
                '../../assets/images/java-u-cdn/carousel/java-u-dish-8.jpg',
                '../../assets/images/java-u-cdn/carousel/java-u-dish-9.jpg',
                '../../assets/images/java-u-cdn/carousel/java-u-dish-10.jpg',
            ],
            tagline: {
                en: "The Best Organic & Fair Trade Coffee",
                fr: "Le Meilleur Café Bio & Équitable"
            },
            tags: {
                en: ["CAFE"],
                fr: ["CAFÉ"]
            },
            description: {
                en: "A Montreal born, hip, innovative café that serves exclusively Fairtrade organic coffee, roasted from top quality beans infused with the aromas and flavors that are uniquely Java U. <br><br> We also offer a wide selection of teas that either calm or invigorate the soul. Not only do we serve a great cup of coffee, we have over 20 kinds of gourmet paninis and wraps, mouth-watering baked goods, and a selection of hearty soups and salads. <br><br> Java U brings you quality ingredients, impeccable presentation, and a fast and friendly service for a convenient and affordable pleasure at any time of the day. There is something for everyone!",
                fr: "Nous sommes un café branché et novateur qui a vu le jour à Montréal qui sert exclusivement du café Fairtrade et biologique torréfié à partir de grains de café de première qualité aux parfums et aux saveurs uniques à Java U. <br><br> Nous offrons également une vaste sélection de thés pour calmer ou pour revigorer votre âme. Non seulement nous servons une bonne tasse de café, nous avons plus de 20 sortes de paninis ou de wraps gastronomiques, des pâtisseries succulentes ainsi que différentes soupes et salades nourrissantes.<br><br> Java U vous propose des ingrédients de qualité, une présentation impeccable et un service rapide et sympathique à des prix abordables, quel que soit le moment de la journée. Chacun y trouve son bonheur! "
            }
        };
        this.mcgillPizza = {
            images: [
                '../../assets/images/mcgill-pizza/mcgill-pizza-dish-1.JPG',
                '../../assets/images/mcgill-pizza/mcgill-pizza-dish-2.JPG',
                '../../assets/images/mcgill-pizza/mcgill-pizza-dish-3.JPG',
                '../../assets/images/mcgill-pizza/mcgill-pizza-dish-4.JPG',
                '../../assets/images/mcgill-pizza/mcgill-pizza-dish-5.JPG',
                '../../assets/images/mcgill-pizza/mcgill-pizza-dish-6.JPG',
                '../../assets/images/mcgill-pizza/mcgill-pizza-dish-7.JPG',
                '../../assets/images/mcgill-pizza/mcgill-pizza-dish-8.JPG',
                '../../assets/images/mcgill-pizza/mcgill-pizza-dish-9.JPG',
                '../../assets/images/mcgill-pizza/mcgill-pizza-dish-10.JPG',
                '../../assets/images/mcgill-pizza/mcgill-pizza-dish-11.JPG',
                '../../assets/images/mcgill-pizza/mcgill-pizza-dish-12.JPG',
            ],
            tagline: {
                en: "50 years of...",
                fr: "50 ans de..."
            },
            tags: {
                en: ["PIZZA"],
                fr: ["PIZZA"]
            },
            description: {
                en: "",
                fr: ""
            }
        };
        this.yuan = {
            images: [
                '../../assets/images/yuan/yuan-dish-1.jpg',
                '../../assets/images/yuan/yuan-dish-2.jpg',
                '../../assets/images/yuan/yuan-dish-3.jpg',
                '../../assets/images/yuan/yuan-dish-4.jpg',
                '../../assets/images/yuan/yuan-dish-5.jpg',
                '../../assets/images/yuan/yuan-dish-6.jpg',
                '../../assets/images/yuan/yuan-dish-7.jpg',
                '../../assets/images/yuan/yuan-dish-8.jpg',
                '../../assets/images/yuan/yuan-dish-9.jpg',
                '../../assets/images/yuan/yuan-dish-10.jpg',
            ],
            tagline: {
                en: "VEGAN/VEGETARIAN RESTO-BOUTIQUE",
                fr: "RESTO-BOUTIQUE VÉGÉTALIEN/VÉGÉTARIEN"
            },
            tags: {
                en: ["VEGAN", "ASIAN FUSION", "GROCERY"],
                fr: ["VÉGÉTALIEN", "FUSION ASIATIQUE", "ÉPICERIE"]
            },
            description: {
                en: "Yuan means 'Affinity' in Mandarin. This restaurant wants to share a great way of living: Be Green n’ Go Veg.<br><br>With a great atmosphere and warm service, kitchen staff and waiting staff are there to give you an unforgettable dining experience. Come and let your taste buds be amazed by the magic of our restaurant.",
                fr: "Yuan signifie Affinité en mandarin. Ce restaurant veut vous partager une excellente façon de vivre « Optez Vert , Devenez végé ».<br><br>Avec une bonne ambiance et un service chaleureux, les chefs et les serveurs (euses) s sont là pour vous donné une expérience culinaire inoubliable. Venez et laissez vos papilles étonner par la magie de notre restaurant."
            }
        };
        this.segreta = {
            images: [
                '../../assets/images/segreta/carousel/segreta-dish-1.jpg',
                '../../assets/images/segreta/carousel/segreta-dish-2.jpg',
                '../../assets/images/segreta/carousel/segreta-dish-3.jpg',
                '../../assets/images/segreta/carousel/segreta-dish-4.jpg',
                '../../assets/images/segreta/carousel/segreta-dish-5.jpg',
                '../../assets/images/segreta/carousel/segreta-dish-6.jpg',
                '../../assets/images/segreta/carousel/segreta-dish-7.jpg',
                '../../assets/images/segreta/carousel/segreta-dish-8.jpg',
                '../../assets/images/segreta/carousel/segreta-dish-9.jpg',
                '../../assets/images/segreta/carousel/segreta-dish-10.jpg',
            ],
            tagline: {
                en: "Deliciously  &emsp; Creative",
                fr: "Délicieusement Créative"
            },
            tags: {
                en: ["ITALIAN", "PIZZERIA", "GROCERY STORE"],
                fr: ["ITALIEN", "PIZZERIA", "ÉPICERIE"]
            },
            description: {
                en: "Segreta means secret in Italian, did you know that? From a unique and secret recipe, we make pizza dough the authentic Roman way. Baked in cast iron pizza pans, our dough is made by a passionate pizzaiolo, right here on site.<br><br>Why is pizza al taglio so exquisite you ask?<br><br>Prepared from quality ingredients, the dough rests for a fermentation period of 72 to 96 hours. This process results in a very light pizza dough and it becomes soft and airy at the same time, but once out of the oven, the pizza al taglio keeps an irresistible crispness.",
                fr: "Segreta signifie secrète en italien, le saviez-vous? À partir d’une recette unique et secrète, nous préparons la pâte à la manière romaine authentique. Cuite dans des moules à pizza en fer forgé, notre pâte est élaborée par un pizzaiolo passionné, ici, sur place.<br><br>Pourquoi la pizza al taglio est si exquise?<br><br>Préparée à partir d’ingrédients de qualité, la pâte repose pour une période de fermentation de 72 à 96 heures. Ce procédé permet d’obtenir une pâte à pizza d’une grande légèreté, qui devient moelleuse et aérienne, mais une fois sortie du four, la pizza al taglio garde une croustillance irrésistible."
            }
        };
        this.omlt = {
            images: [
                '../../assets/images/omlt/carousel/omlt-2.jpg',
                '../../assets/images/omlt/carousel/omlt-3.jpg',
                '../../assets/images/omlt/carousel/omlt-1.jpg',
                '../../assets/images/omlt/carousel/omlt-4.jpg',
                '../../assets/images/omlt/carousel/omlt-6.jpg',
                '../../assets/images/omlt/carousel/omlt-7.jpg',
                '../../assets/images/omlt/carousel/omlt-8.jpg',
                '../../assets/images/omlt/carousel/omlt-5.jpg',
                '../../assets/images/omlt/carousel/omlt-9.jpg'
            ],
            tagline: {
                en: "The Spanish Tortilla",
                fr: "La tortilla espagnole"
            },
            tags: {
                en: ["SPANISH"],
                fr: ["ESPAGNOL"]
            },
            description: {
                en: "OMLT serves up a fresh potato omelet made fresh, the same way it is prepared in Madrid. In the wake of COVID restrictions, we decided to offer Montrealers the most authentic Spanish potato omelette available in these parts. Whether it's for breakfast, dinner or lunch, count on us to deliver an authentic Madrid recipe to your table. <br><br>Each tortilla weighs 1 kg and will satisfy two gourmands or four guests. Our tortillas, besides being vegetarian, are made with free range eggs, organic potatoes and onions. It is also a gluten-free product!<br><br>In Spain, the tortilla is traditionally eaten 'sloppy', that is, with the egg running (which we think is the most delicious format!), but feel free to ask us for it any way you like, because we are here to make you happy.",
                fr: "OMLT vous propose une omelette de pommes de terre fraîche préparée à la minute, de la même manière qu'elle est consommée à Madrid. À l'ère des restrictions imposées par la COVID, nous avons décidé d'offrir aux Montréalais la plus authentique omelette espagnole aux pommes de terre que l'on puisse trouver sur ces terres. Que ce soit pour le petit déjeuner, le souper ou le lunch, comptez sur nous pour vous faire parvenir la recette typique de Madrid jusqu'à votre table. <br><br>Chaque tortilla pèse 1 kg, et elle saura satisfaire deux gourmands ou quatre convives. Nos tortillas, en plus d'être végétariennes, sont faites avec des œufs de poules en liberté, des pommes de terre et des oignons biologiques. C'est aussi un produit sans gluten !<br><br>En Espagne, la tortilla est traditionnellement consommée 'baveuse', c'est-à-dire avec l'œuf coulant (ce qui est, à notre avis, le format le plus délicieux !), mais sentez-vous à l'aise de nous la demander comme vous le souhaitez, car nous sommes là pour vous rendre heureux."
            }
        };
        this.quesadaCdn = {
            images: [
                '../../assets/images/quesada-cdn/carousel/quesada-cdn-dish-1.jpg',
                '../../assets/images/quesada-cdn/carousel/quesada-cdn-dish-2.jpg',
                '../../assets/images/quesada-cdn/carousel/quesada-cdn-dish-3.jpg',
                '../../assets/images/quesada-cdn/carousel/quesada-cdn-dish-4.jpg',
                '../../assets/images/quesada-cdn/carousel/quesada-cdn-dish-6.jpg',
                '../../assets/images/quesada-cdn/carousel/quesada-cdn-dish-7.jpg',
                '../../assets/images/quesada-cdn/carousel/quesada-cdn-dish-8.jpg',
                '../../assets/images/quesada-cdn/carousel/quesada-cdn-dish-5.jpg',
                '../../assets/images/quesada-cdn/carousel/quesada-cdn-dish-9.jpg',
                '../../assets/images/quesada-cdn/carousel/quesada-cdn-dish-10.jpg'
            ],
            tagline: {
                en: "The Joy of Mex™",
                fr: "Les Joies du Mex™"
            },
            tags: {
                en: ["MEXICAN"],
                fr: ["MEXICAIN"]
            },
            description: {
                en: "When Canadian software wizard Steve Gill lived in Denver – home of 667 Mexican restaurants – he discovered a passion for his new favourite food: Burritos.<br><br>He was quickly hooked on the complexity and variety of flavours he tasted, and the use of fresh, wholesome ingredients. The food was nothing like the generic, bland products that were being served in so-called “Mexican” fast food chains. The tastes surprised and delighted him, and he loved the ability to customize his meal and try new flavor combinations every time.<br><br> Besides his software skills, Steve is also an accomplished chef, and he returned to Canada with one goal: to bring a little of his Denver taste experience to Canada, and to create a Canadian-owned Mexico-inspired restaurant.",
                fr: "Lorsque Steve Gill, développeur canadien de logiciels, vivait à Denver – où l’on retrouve 667 restaurants mexicains – il s’est découvert une véritable passion pour son mets favori: les burritos.<br><br>Il a immédiatement adoré les saveurs complexes et variées de la cuisine mexicaine, ainsi que son utilisation d’ingrédients frais et sains. Leur nourriture n’avait rien à voir avec les produits génériques et fades des grandes chaînes de restauration rapide “mexicaines”. Il aimait particulièrement avoir la possibilité de personnaliser chaque mets et de pouvoir essayer des nouvelles combinaisons de saveurs à chaque repas. <br><br> En plus de ses talents de développeur, Steve était également un chef accompli. Il décida de retourner au Canada avec un seul but: utiliser son expérience à Denver et lancer une chaîne canadienne de restauration rapide, inspirée par la cuisine mexicaine authentique."
            }
        };

        this.oiseauxDePassage = {
            images: [
                '../../assets/images/oiseaux-de-passage/carousel/oiseaux-de-passage-dish-1.jpg',
                '../../assets/images/oiseaux-de-passage/carousel/oiseaux-de-passage-dish-2.jpg',
                '../../assets/images/oiseaux-de-passage/carousel/oiseaux-de-passage-dish-3.jpg',
                '../../assets/images/oiseaux-de-passage/carousel/oiseaux-de-passage-dish-4.jpg',
                '../../assets/images/oiseaux-de-passage/carousel/oiseaux-de-passage-dish-6.jpg',
                '../../assets/images/oiseaux-de-passage/carousel/oiseaux-de-passage-dish-7.jpg',
                '../../assets/images/oiseaux-de-passage/carousel/oiseaux-de-passage-dish-8.jpg',
                '../../assets/images/oiseaux-de-passage/carousel/oiseaux-de-passage-dish-5.jpg',
                '../../assets/images/oiseaux-de-passage/carousel/oiseaux-de-passage-dish-9.jpg',
                '../../assets/images/oiseaux-de-passage/carousel/oiseaux-de-passage-dish-10.jpg',
            ],
            tagline: {
                en: "Neighborhood Restaurant",
                fr: "Restaurant de quartier"
            },
            tags: {
                en: ["FRENCH", "BISTRO"],
                fr: ["FRANÇAIS", "BISTRO"]
            },
            description: {
                en: "Eco-responsible, our team gives priority to products made in Quebec, resulting from environmentally friendly processes known for their benefits as well as for their taste qualities. The vast majority of products are processed on site in order to offer beautiful little dishes at the best of their freshness. It is also one of the roles of traditional catering, to explore what the terroir has to offer, to always strive to find new ways to highlight the richness of our environment and to support our talented producers. In short, it is as much a duty as it is a pleasure to work with our products!<br><br>Our menu is based on market cuisine composed of local products with European and Mediterranean flavors, highlighting organic and seasonal products. Particular attention is paid to meats, foie gras and products from the smokehouse, while striving to offer creative vegetarian alternatives.",
                fr: "Éco-responsable, notre équipe valorise en priorité des produits fabriqués au Québec, issus de procédés respectueux de l'environnement et connus pour leurs bienfaits ainsi que pour leurs qualités gustatives. La grande majorité des produits sont transformés sur place afin d'offrir des beaux petits plats au meilleur de leur fraîcheur.  C’est aussi un des rôles de la restauration traditionnelle, d’explorer ce que le terroir peut offrir, de s’efforcer de trouver toujours de nouvelles manières de mettre en avant la richesse de notre environnement et de soutenir nos producteurs talentueux. En bref, c’est autant un devoir qu’un plaisir de travailler avec les produits de chez nous!<br><br>Notre menu est basé sur une cuisine de marché composée de produits locaux avec des saveurs européennes et méditerranéennes, mettant en avant des produits biologiques et de saison. Une attention particulière est portée aux viandes, foie gras et produits du fumoir, tout en s’efforçant d’offrir des alternatives végétariennes créatives."
            }
        };

        this.cafeVita = {
            images: [
                '../../assets/images/cafe-vita/carousel/cafe-vita-dish-1.jpg',
                '../../assets/images/cafe-vita/carousel/cafe-vita-dish-2.jpg',
                '../../assets/images/cafe-vita/carousel/cafe-vita-dish-3.jpg',
                '../../assets/images/cafe-vita/carousel/cafe-vita-dish-4.jpg',
                '../../assets/images/cafe-vita/carousel/cafe-vita-dish-6.jpg',
                '../../assets/images/cafe-vita/carousel/cafe-vita-dish-7.jpg',
                '../../assets/images/cafe-vita/carousel/cafe-vita-dish-8.jpg',
                '../../assets/images/cafe-vita/carousel/cafe-vita-dish-5.jpg',
                '../../assets/images/cafe-vita/carousel/cafe-vita-dish-9.jpg',
                '../../assets/images/cafe-vita/carousel/cafe-vita-dish-10.jpg',
                '../../assets/images/cafe-vita/carousel/cafe-vita-dish-11.jpg',
                '../../assets/images/cafe-vita/carousel/cafe-vita-dish-12.jpg',
                '../../assets/images/cafe-vita/carousel/cafe-vita-dish-13.jpg',
                '../../assets/images/cafe-vita/carousel/cafe-vita-dish-14.jpg',
                '../../assets/images/cafe-vita/carousel/cafe-vita-dish-15.jpg',
            ],
            tagline: {
                en: "Great comfort food in the local area",
                fr: "Excellente nourriture réconfortante dans la région"
            },
            tags: {
                en: ["ITALIAN", "COMFORT FOOD"],
                fr: ["ITALIEN", "ALIMENT-RÉCONFORT"]
            },
            description: {
                en: "We never compromise on the quality of our food and drinks and customers both old and new can be sure of a great bite to eat as well as a wide range of coffees and teas to excite and tantalise taste buds. From classic cappuccino, warming lattes and punchy americanos, there’s something for every coffee fan. We also offer a variety of dairy free options.<br><br>Our menu is fully loaded offering a whole host of items to choose from making you almost spoilt for choice! We have foods and beverages suitable for vegetarians and vegan, gluten free and other dietary needs so just ask us and we can give you the lowdown. All you have to do is peruse the menu and select what you have been craving. Feel free to try out anything new on our full menu. Better yet, you can pop in for breakfast, lunch, dinner or just for some coffee.",
                fr: "Nous ne faisons jamais de compromis sur la qualité de nos aliments et boissons et les clients, anciens et nouveaux, peuvent être sûrs d'une bonne bouchée ainsi que d'une large gamme de cafés et de thés pour exciter et séduire les papilles gustatives. Du cappuccino classique, des lattes réchauffants et des americanos punchy, il y en a pour tous les amateurs de café. Nous offrons également une variété d'options sans produits laitiers.<br><br>Notre menu est complet et offre une multitude d'articles à choisir, ce qui vous fait presque l'embarras du choix! Nous avons des aliments et des boissons adaptés aux végétariens et végétaliens, sans gluten et autres besoins alimentaires, alors demandez-nous et nous pourrons vous en dire plus. Tout ce que vous avez à faire est de parcourir le menu et de sélectionner ce dont vous avez envie. N'hésitez pas à essayer quelque chose de nouveau dans notre menu complet. Mieux encore, vous pouvez venir pour le petit-déjeuner, le déjeuner, le dîner ou tout simplement pour prendre un café."
            }
        };

        this.burgerBros = {
            images: [
                '../../assets/images/burger-bros/carousel/burger-bros-dish-1.jpg',
                '../../assets/images/burger-bros/carousel/burger-bros-dish-2.jpg',
                '../../assets/images/burger-bros/carousel/burger-bros-dish-3.jpg',
                '../../assets/images/burger-bros/carousel/burger-bros-dish-4.jpg',
                '../../assets/images/burger-bros/carousel/burger-bros-dish-6.jpg',
                '../../assets/images/burger-bros/carousel/burger-bros-dish-7.jpg',
                '../../assets/images/burger-bros/carousel/burger-bros-dish-8.jpg',
                '../../assets/images/burger-bros/carousel/burger-bros-dish-5.jpg',
                '../../assets/images/burger-bros/carousel/burger-bros-dish-9.jpg',
                '../../assets/images/burger-bros/carousel/burger-bros-dish-10.jpg',
            ],
            tagline: {
                en: "Delicious and tempting",
                fr: "Délicieux et alléchant"
            },
            tags: {
                en: ["BURGERS"],
                fr: ["BURGERS"]
            },
            description: {
                en: "A Montreal-based family business, serving what Halal fast food should really taste like, with a touch of international flavour!<br><br>With our delicious selection of burgers, hot dogs, poutines, side dishes, desserts, and milkshakes, you'll want to try everything we have to offer!",
                fr: "Une entreprise familiale basée à Montréal, servant ce que le “fast food” de viandes Halal devrait vraiment goûter, avec une touche de goût internationale!<br><br>Avec notre sélection délicieuse de burgers, hot dogs, poutines, accompagnements, desserts, et milkshake; vous voudrez essayer tout ce que nous avons à offrir!"
            }
        };
        this.julians = {
            images: [
                '../../assets/images/julians/carousel/julians-dish-1.jpg',
                '../../assets/images/julians/carousel/julians-dish-2.jpg',
                '../../assets/images/julians/carousel/julians-dish-3.jpg',
                '../../assets/images/julians/carousel/julians-dish-4.jpg',
                '../../assets/images/julians/carousel/julians-dish-6.jpg',
                '../../assets/images/julians/carousel/julians-dish-7.jpg',
                '../../assets/images/julians/carousel/julians-dish-8.jpg',
                '../../assets/images/julians/carousel/julians-dish-5.jpg',
                '../../assets/images/julians/carousel/julians-dish-9.jpg',
                '../../assets/images/julians/carousel/julians-dish-10.jpg',
                '../../assets/images/julians/carousel/julians-dish-11.jpg',
                '../../assets/images/julians/carousel/julians-dish-12.jpg',
                '../../assets/images/julians/carousel/julians-dish-13.jpg',
            ],
            tagline: {
                en: "Comptoir gourmand",
                fr: "Comptoir gourmand"
            },
            tags: {
                en: ["SALAD", "SANDWICHES"],
                fr: ["SALADE", "SANDWICHS"]
            },
            description: {
                en: "As longtime friends and colleagues at Tapeo Restaurant, we always had it in our minds to collaborate on our own project. Not necessarily a gourmet restaurant, but rather a place that reflects our ideas and values. We sought to open a sincere and casual spot where we reference the culinary style of gourmet counters. After conducting extensive research, we realized that few places offered 100% satisfaction. We always found that something was missing— that it either lacked soul or that it lacked love in the way of doing things ... We told ourselves \"We will solve this and create the perfect place!\" An unpretentious place, where we do not reinvent the wheel, but where we will do things perfectly. We allow people to have a better experience by offering them what we have best to give. Our wish is that everyone who experiences Julian's will leave with a smile, and a craving to return immediately.",
                fr: "Étant amis et collègues de travail au restaurant Tapeo depuis des années, nous avions toujours en tête d’ouvrir notre propre projet. Pas un grand restaurant gastronomique, mais plutôt une place à notre image. Une place sincère, qui serait une référence dans le milieu des comptoirs gourmands. Après avoir essayé une grande majorité de comptoirs et restaurants de lunch, on s’est rendu compte que peu de places offraient une satisfaction à 100%. On trouvait toujours qu’il manquait quelque chose, qu’il manquait d'âme, qu’il manquait d’amour dans la façon de faire les choses… On s’est donc penchés sur la question et on s’est dit : « On va la faire cette place parfaite! » Une place sans prétention où on ne réinvente pas le monde, mais où on va faire les choses parfaitement, on va permettre aux gens d’avoir une meilleure expérience en leur offrant ce qu’on a de mieux à donner. Notre but est de s’adapter à tous et chacun en particulier. Que chaque personne qui sort de chez Julian’s sorte avec le sourire et le craving de revenir demain…."
            }
        };
        this.wallys = {
            images: [
                '../../assets/images/wallys/carousel/wallys-dish-1.png',
                '../../assets/images/wallys/carousel/wallys-dish-2.png',
                '../../assets/images/wallys/carousel/wallys-dish-3.png',
                '../../assets/images/wallys/carousel/wallys-dish-4.png',
                '../../assets/images/wallys/carousel/wallys-dish-5.png',
                '../../assets/images/wallys/carousel/wallys-dish-6.png',
                '../../assets/images/wallys/carousel/wallys-dish-7.png',
            ],
            tagline: {
                en: "Non-dairy Frozen Dessert",
                fr: "Dessert glacé sans produits laitiers"
            },
            tags: {
                en: ["ICE CREAM", "VEGAN"],
                fr: ["CRÈMERIE VÉGANE"]
            },
            description: {
                en: "Wally’s is a producer of small batch vegan artisanal ice cream flavors, which are made with local ingredients and no additives. Each 473ml container is made by hand with love in Montreal and are made with wholesome ingredients such as homemade cashew milk, coconut cream, cacao butter and other great-tasting ingredients.<br><br> With a new flavour added every month, we can guarantee that you will keep coming back to try our new and fun flavours. Whether you are vegan, lactose intolerant or want to reduce your consumption of animal products, you will definitely enjoy Wally’s.",
                fr: "Wally's est un producteur de petits lots de crèmes glacées artisanales végétaliennes, qui sont faits avec des ingrédients locaux et qui ne contiennent aucun agents de conservation. Chaque contenant de 473 ml est fabriqué à la main avec amour à Montréal et contient des ingrédients sains tels que du lait de cajou fait maison, de la crème de noix de coco, du beurre de cacao et d'autres ingrédients savoureux.<br><br>Avec une nouvelle saveur ajoutée chaque mois, nous pouvons vous garantir que vous reviendrez pour essayer nos nouvelles saveurs amusantes. Que vous soyez végétalien, intolérant au lactose ou que vous souhaitez réduire votre consommation de produits d’origine animale, vous apprécierez certainement Wally’s."
            }
        };
        this.spiceBros = {
            images: [
                '../../assets/images/spicebros/carousel/spicebros-dish-1.jpg',
                '../../assets/images/spicebros/carousel/spicebros-dish-2.jpg',
                '../../assets/images/spicebros/carousel/spicebros-dish-3.jpg',
                '../../assets/images/spicebros/carousel/spicebros-dish-4.jpg',
                '../../assets/images/spicebros/carousel/spicebros-dish-5.jpg',
                '../../assets/images/spicebros/carousel/spicebros-dish-6.jpg',
                '../../assets/images/spicebros/carousel/spicebros-dish-7.jpg',
                '../../assets/images/spicebros/carousel/spicebros-dish-8.jpg',
                '../../assets/images/spicebros/carousel/spicebros-dish-9.jpg',
                '../../assets/images/spicebros/carousel/spicebros-dish-10.jpg',
                '../../assets/images/spicebros/carousel/spicebros-dish-11.jpg',
            ],
            tagline: {
                en: "Bringing the taste of Indian food with a modern twist",
                fr: "Apporter le goût de la cuisine indienne avec une touche moderne"
            },
            tags: {
                en: ["INDIAN"],
                fr: ["INDIEN"]
            },
            description: {
                en: "We are SpiceBros. We don’t just make food. We make people’s days. Spicebros was built on the belief that food should be special, and we carry that belief into everything we do.<br><br>We understand how to best serve our customers through tried and true service principles. Instead of following trends, we set them. We create food we’re proud to serve and deliver it fast, with a smile. No matter where you find us, we’re making sure each meal our customers enjoy is delicious and one-of-a-kind.",
                fr: "Nous sommes SpiceBros. Nous ne faisons pas que de la nourriture. Nous faisons les journées des gens. Spicebros a été construit sur la croyance que la nourriture devrait être spéciale, et nous portons cette croyance dans tout ce que nous faisons.<br><br>Nous comprenons comment servir au mieux nos clients grâce à des principes de service éprouvés. Au lieu de suivre les tendances, nous les fixons. Nous créons des aliments que nous sommes fiers de servir et les livrons rapidement, avec le sourire. Peu importe où vous nous trouvez, nous nous assurons que chaque repas que nos clients apprécient est délicieux et unique en son genre."
            }
        };
        this.santaLucia = {
            images: [
                '../../assets/images/santa-lucia/carousel/santa-lucia-dish-1.jpg',
                '../../assets/images/santa-lucia/carousel/santa-lucia-dish-2.jpg',
                '../../assets/images/santa-lucia/carousel/santa-lucia-dish-3.jpg',
                '../../assets/images/santa-lucia/carousel/santa-lucia-dish-4.jpg',
                '../../assets/images/santa-lucia/carousel/santa-lucia-dish-5.jpg',
                '../../assets/images/santa-lucia/carousel/santa-lucia-dish-6.jpg',
                '../../assets/images/santa-lucia/carousel/santa-lucia-dish-7.jpg',
                '../../assets/images/santa-lucia/carousel/santa-lucia-dish-8.jpg',
                '../../assets/images/santa-lucia/carousel/santa-lucia-dish-9.jpg',
                '../../assets/images/santa-lucia/carousel/santa-lucia-dish-10.jpg',
                '../../assets/images/santa-lucia/carousel/santa-lucia-dish-11.jpg',
                '../../assets/images/santa-lucia/carousel/santa-lucia-dish-12.jpg',
                '../../assets/images/santa-lucia/carousel/santa-lucia-dish-13.jpg',
                '../../assets/images/santa-lucia/carousel/santa-lucia-dish-14.jpg',
                '../../assets/images/santa-lucia/carousel/santa-lucia-dish-15.jpg',

            ],
            tagline: {
                en: "Italian Cuisine & Pizza baked in a wood burning oven!",
                fr: "Cuisine Italienne et Pizza cuite au four à bois! "
            },
            tags: {
                en: ["ITALIAN"],
                fr: ["ITALIEN"]
            },
            description: {
                en: "Restaurant Siciliana has been serving the best Italian food in Montreal since 1985! We specialize in Pizza baked to perfection in a wood burning oven. Montrealers’ say the Calzone is the biggest and juiciest in town. The Tiramisu will melt in your mouth as you wash it down with a creamy Cappucinno! We offer a great selection of imported wines, imported and selected local beer as well as pints & pitchers on tap!",
                fr: "Restaurant Siciliana a été au service de la meilleure cuisine italienne à Montréal depuis 1985 ! Nous nous spécialisons dans des pizzas cuites à la perfection dans un four à bois. Les Montréalais, déclarent le Calzone est le plus grand et le plus juteux de la ville. Le Tiramisu va fondre dans votre bouche suivi par un cappuccino crémeux ! On offre une grande sélection de vins importés, une sélectionne de bière importée et de bière locale en fût!"
            }
        };
        this.dakar = {
            images: [
                '../../assets/images/dakar/carousel/dakar-dish-1.jpg',
                '../../assets/images/dakar/carousel/dakar-dish-2.jpg',
                '../../assets/images/dakar/carousel/dakar-dish-3.jpg',
                '../../assets/images/dakar/carousel/dakar-dish-4.jpg',
                '../../assets/images/dakar/carousel/dakar-dish-5.jpg',
                '../../assets/images/dakar/carousel/dakar-dish-6.jpg',
                '../../assets/images/dakar/carousel/dakar-dish-7.jpg',
                '../../assets/images/dakar/carousel/dakar-dish-8.jpg',
                '../../assets/images/dakar/carousel/dakar-dish-9.jpg',
                '../../assets/images/dakar/carousel/dakar-dish-10.jpg',
            ],
            tagline: {
                en: "Fresh, Healthy & Delicious",
                fr: "Fraiche, saine & delicieuse"
            },
            tags: {
                en: ["SENEGALESE"],
                fr: ["SÉNÉGALAIS"]
            },
            description: {
                en: "We are a Senegalese restaurant based in Montreal. Épicerie Traiteur is a calm and friendly place. Our dishes and desserts are cooked by approved chefs who by their extraordinary talents will make you taste all the good African flavors, in particular Senegalese, at unbeatable prices.",
                fr: "Nous sommes un restaurant sénégalais basé à Montréal. Épicerie traiteur est un endroit calme et convivial. Nos plats et desserts sont cuisinés par des chefs agréées qui par leurs talents hors normes vous feront gouter à toutes les bonnes saveurs africaines en particulier sénégalais à des prix imbattables."
            }
        };
        this.oFour = {
            images: [
                '../../assets/images/o-four/carousel/o-four-dish-1.jpg',
                '../../assets/images/o-four/carousel/o-four-dish-2.jpg',
                '../../assets/images/o-four/carousel/o-four-dish-3.jpg'
            ],
            tagline: {
                en: "Take your taste buds on a journey to the land of the cedars",
                fr: "Faites voyagez vos papilles gustatives au pays des cèdres"
            },
            tags: {
                en: ["LEBANESE RESTAURANT & BAKERY"],
                fr: ["RESTAURANT & BOULANGERIE LIBANAIS"]
            },
            description: {
                en: `Unassuming spot serving up the most authentic and delicious Lebanese food around.
                <br><br>
                Ô Four invites you to "take your taste buds on a journey to the land of the cedars" with their wraps, manouche, and subs served on freshly-made kaak (a bagel-like savoury roll covered with sesame seeds).
                <br><br>
                You can also try their variety of egg dishes, from eggs with traditional soujouk (fermented sausage) or eggs and awarma (lamb confit), each served with freshly baked bread and fresh mint.`,
                fr: `L'endroit pour trouver la cuisine libanaise la plus authentique et la plus délicieuse dans la ville.
                <br><br>
                Ô Four vous invite à « faire voyager vos papilles gustatives au pays des cèdres » avec leurs wraps, manouches et sous-marins servis sur du kaak (un roulé salé recouvert de graines de sésame, semblable à un bagel) toujours fraîchement préparé.
                <br><br>
                Vous pouvez également essayer leur variété de plats aux œufs, comme des œufs au soujouk (saucisse fermentée) ou au l'awarma (confit d'agneau), chacun plat servi avec du pain fraîchement cuit et de la menthe fraîche.`
            }
        };
        this.karin = {
            images: [
                '../../assets/images/karin/carousel/karin-dish-1.jpg',
                '../../assets/images/karin/carousel/karin-dish-2.jpg',
                '../../assets/images/karin/carousel/karin-dish-3.jpg',
                '../../assets/images/karin/carousel/karin-dish-4.jpg',
                '../../assets/images/karin/carousel/karin-dish-6.jpg',
                '../../assets/images/karin/carousel/karin-dish-7.jpg',
                '../../assets/images/karin/carousel/karin-dish-8.jpg',
                '../../assets/images/karin/carousel/karin-dish-5.jpg',
                '../../assets/images/karin/carousel/karin-dish-9.jpg',
                '../../assets/images/karin/carousel/karin-dish-10.jpg',
            ],
            tagline: {
                en: "🇯🇵 モントリオールの日本食屋 営業34年目<br>Japanese restaurant of 34 years 🎉",
                fr: "🇯🇵 モントリオールの日本食屋 営業34年目<br>Restaurant japonais de 34 ans 🎉"
            },
            tags: {
                en: ["JAPANESE", "SUSHI"],
                fr: ["JAPONAIS", "SUSHI"]
            },
            description: {
                en: "Having been in business for over 34 years, Karin Japanese Restaurant remains a local favourite in the heart of Montreal. <br><br> With each order, customers are transported to the restaurant’s roots of Japan with the plethora of traditional Japanese dishes offered.<br><br>Explore Japanese cuisine with Karin’s bountiful menu of bento boxes, noodle & rice dishes, sushi & sashimi, as well as sake & desserts.",
                fr: "En affaires depuis plus de 34 ans, karin Japanese Restaurant demeure un favori local au cœur de Montréal.<br><br>À chaque commande, les clients sont transportés aux racines du restaurant au Japon avec la pléthore de plats traditionnels japonais offerts.<br><br>Explorez la cuisine japonaise avec le menu copieux de Karin de boîtes de bento, de plats de nouilles et de riz, de sushis et de sashimi, ainsi que de saké et de desserts."
            }
        };

        this.marcheMarema = {
            images: [
                '../../assets/images/marche-marema/carousel/marche-marema-dish-1.jpg',
                '../../assets/images/marche-marema/carousel/marche-marema-dish-2.jpg',
                '../../assets/images/marche-marema/carousel/marche-marema-dish-3.jpg',
                '../../assets/images/marche-marema/carousel/marche-marema-dish-4.jpg',
                '../../assets/images/marche-marema/carousel/marche-marema-dish-6.jpg',
            ],
            tagline: {
                en: "Organic African Products",
                fr: "Produits africain bio"
            },
            tags: {
                en: ["SENEGALESE"],
                fr: ["SÉNÉGALAIS"]
            },
            description: {

            }
        };
        this.elephantThai = {
            images: [
                '../../assets/images/elephant-thai/carousel/elephant-thai-dish-1.jpg',
                '../../assets/images/elephant-thai/carousel/elephant-thai-dish-2.jpg',
                '../../assets/images/elephant-thai/carousel/elephant-thai-dish-3.jpg',
                '../../assets/images/elephant-thai/carousel/elephant-thai-dish-4.jpg',
                '../../assets/images/elephant-thai/carousel/elephant-thai-dish-5.jpg',
                '../../assets/images/elephant-thai/carousel/elephant-thai-dish-6.jpg',
                '../../assets/images/elephant-thai/carousel/elephant-thai-dish-7.jpg',
            ],
            tagline: {
                en: "A Taste of Thailand",
                fr: "Un goût de la Thaïlande"
            },
            tags: {
                en: ["THAI"],
                fr: ["THAÏ"]
            },
            description: {
            }
        };
        this.sushiyo = {
            images: [
                '../../assets/images/sushiyo/carousel/sushiyo-dish-1.JPG',
                '../../assets/images/sushiyo/carousel/sushiyo-dish-2.JPG',
                '../../assets/images/sushiyo/carousel/sushiyo-dish-3.JPG',
                '../../assets/images/sushiyo/carousel/sushiyo-dish-4.JPG',
                '../../assets/images/sushiyo/carousel/sushiyo-dish-5.JPG',
                '../../assets/images/sushiyo/carousel/sushiyo-dish-6.JPG',
                '../../assets/images/sushiyo/carousel/sushiyo-dish-7.JPG',
                '../../assets/images/sushiyo/carousel/sushiyo-dish-8.JPG',
                '../../assets/images/sushiyo/carousel/sushiyo-dish-9.JPG',
                '../../assets/images/sushiyo/carousel/sushiyo-dish-10.JPG',
            ],
            tagline: {
                en: "Serving Authentic Asian Cuisine",
                fr: "Servir de la cuisine asiatique authentique"
            },
            tags: {
                en: ["JAPANESE", "KOREAN"],
                fr: ["JAPONAIS", "CORÉEN"]
            },
            description: {
                en: "Casual Japanese and Korean restaurant serving authentic sushi, poké bowls, noodles and Asian cuisine. Ideal for both dining in or ordering to go.",
                fr: "Restaurant décontracté japonais et coréen servant des sushis authentiques, des bols poké, des nouilles et une cuisine asiatique. Idéal pour manger ou commander d'y aller."
            }
        };
        this.boiteVegane = {
            images: [
                '../../assets/images/boite-vegane/carousel/carousel-dish-1.jpg',
                '../../assets/images/boite-vegane/carousel/carousel-dish-2.jpg',
                '../../assets/images/boite-vegane/carousel/carousel-dish-3.JPG',
                '../../assets/images/boite-vegane/carousel/carousel-dish-4.JPG',
                '../../assets/images/boite-vegane/carousel/carousel-dish-5.JPG',
                '../../assets/images/boite-vegane/carousel/carousel-dish-6.jpg',
                '../../assets/images/boite-vegane/carousel/carousel-dish-7.jpg',
            ],
            tagline: {
                en: "A vegan caterer/restaurant of comfort food",
                fr: "Un traiteur/restaurant végane de comfort food"
            },
            tags: {
                en: ["COMFORT FOOD"],
                fr: ["ALIMENT-RÉCONFORT"]
            },
            description: {
                en: "Whether you are vegetarians, vegans or you like healthy food, the vegan box offers you its dishes. Restaurant for takeaway as well as small vegan dishes for delivery. You can also buy from our local caterer in La Prairie to eat a hot meal such as a delicious Impossible Burger, a Colombian style dog or a poutine out of the ordinary!",
                fr: "Que vous soyez végétariens, végétaliens ou que vous aimez la nourriture santé, la boîte végane vous offre ses plats. Restaurant pour emporter ainsi que petits plats végans pour livraison. Il vous est aussi possible d’acheter de notre traiteur local à La Prairie afin de manger un repas chaud tel qu’un délicieux Impossible Burger, un dog de style colombien ou une poutine hors de l’ordinaire!"
            }
        };
        this.heroBurgers = {
            images: [
                '../../assets/images/hero-burgers/carousel/hero-burgers-carousel-1.jpg',
                '../../assets/images/hero-burgers/carousel/hero-burgers-carousel-2.jpg',
                '../../assets/images/hero-burgers/carousel/hero-burgers-carousel-3.jpg',
                '../../assets/images/hero-burgers/carousel/hero-burgers-carousel-4.jpg',
                '../../assets/images/hero-burgers/carousel/hero-burgers-carousel-5.jpg',
            ],
            tagline: {
                en: "THE HERO STORY",
                fr: "THE HERO STORY"
            },
            tags: {
                en: ["BURGERS"],
                fr: ["BURGERS"]
            },
            description: {
                en: "We have come so far so quickly. From a single store in 2003, we are now an established brand with 50 locations. <br><br>Hero was one of the first fast casual dining operations in Canada to offer a fully-traceable Canadian Angus Beef burger, raised without antibiotics or additional hormones.<br><br>Whether our customers dine-in or order for delivery, our staff work tirelessly to exceed their expectations of quality, flavour and service.",
                fr: "Nous venons de loin et on en est fiers : D'une seule boutique en 2003 à une bannière établie comptant plus de 50 emplacements aujourd'hui. <br><br>Hero a été l'une des premières opérations de restauration rapide décontractée au Canada à offrir un hamburger de bœuf Angus canadien entièrement traçable, élevé sans antibiotiques ni hormones supplémentaires.<br><br>Que nos clients dînent sur place ou commandent pour la livraison, notre personnel travaille sans relâche pour surpasser leurs attentes en matière de qualité, de saveur et de service.",
            }
        };
        this.jrsCafe = {
            images: [
                '../../assets/images/jrs-cafe/carousel/jrs-cafe-dish-1.jpg',
                '../../assets/images/jrs-cafe/carousel/jrs-cafe-dish-2.jpg',
                '../../assets/images/jrs-cafe/carousel/jrs-cafe-dish-3.jpg',
                '../../assets/images/jrs-cafe/carousel/jrs-cafe-dish-4.jpg',
                '../../assets/images/jrs-cafe/carousel/jrs-cafe-dish-5.jpg',
            ],
            tagline: {
                en: "Delicious and Fresh Breakfast",
                fr: "Petit déjeuner délicieux et frais"
            },
            tags: {
                en: ["BAKERY"],
                fr: ["BOULANGERIE"]
            },
            description: {
            }
        };
        this.cafeResonance = {
            images: [
                '../../assets/images/cafe-resonance/carousel/cafe-resonance-dish-1.jpg',
                '../../assets/images/cafe-resonance/carousel/cafe-resonance-dish-2.jpg',
                '../../assets/images/cafe-resonance/carousel/cafe-resonance-dish-3.jpg',
                '../../assets/images/cafe-resonance/carousel/cafe-resonance-dish-4.jpg',
                '../../assets/images/cafe-resonance/carousel/cafe-resonance-dish-5.jpg',
            ],
            tagline: {
                en: "An Artist-Run Space",
                fr: "Un espace géré par des artistes"
            },
            tags: {
                en: ["VEGAN"],
                fr: ["VÉGÉTALIEN"]
            },
            description: {
                en: "Presenting live, creative music; serving espresso, tea and vegan fare all day.",
                fr: "Au service de la musique créative, le végétalisme et du café préparé avec soin"
            }
        };
        this.ubiSushi = {
            images: [
                '../../assets/images/ubi-sushi/carousel/ubi-sushi-dish-1.jpg',
                '../../assets/images/ubi-sushi/carousel/ubi-sushi-dish-2.jpg',
                '../../assets/images/ubi-sushi/carousel/ubi-sushi-dish-3.jpg',
                '../../assets/images/ubi-sushi/carousel/ubi-sushi-dish-4.jpg',
                '../../assets/images/ubi-sushi/carousel/ubi-sushi-dish-5.jpg',
            ],
            tagline: {
                en: "Sushi Restaurant in Montreal",
                fr: "Resto de sushi à Montréal"
            },
            tags: {
                en: ["SUSHI"],
                fr: ["SUSHI"]
            },
            description: {
                en: "Ubi Sushi, a sushi restaurant in Montreal, presents its wide selection of Japanese cuisine. Always prepared with the freshest ingredients, delight in our sashimi, hosomakis, spring rolls, crispy rolls, sushi platters and much more.<br><br> Let yourself be tempted by one of the creative specialties of our chefs prepared in our sushi restaurant in Montreal. For example \"November rain\": a leaf of fried mint covered with tuna tartare, mango and lettuce caviar. Or for sweet beaks, try \"sushi dessert\" a leaf of rice filled with strawberry, banana, mango and nutella.<br><br> Have you ever tried our pokés? Ubi Sushi now offers you a selection of these dishes from Hawaii. Fresh, delicious and well balanced, our chefs have concocted a modernized version, merging their know-how in Japanese cuisine, their creativity, and traditional Hawaiian methods to satisfy the finest of palates.",
                fr: "Ubi Sushi, restaurant de sushi à Montréal, vous présente son vaste choix de cuisine japonaise. Toujours préparés avec les ingrédients les plus frais, délectez-vous de nos sashimis, hosomakis, rouleaux de printemps, rouleaux croustillants, plateaux de sushis et bien plus encore.<br><br> Laissez-vous tenter par une des spécialités créatives de nos chefs préparées dans notre resto de sushi à Montréal. Par exemple « November rain » : une feuille de menthe frite recouverte de tartare de thon, de mangue et de laitue caviar. Ou pour les becs sucrés, testez « sushi dessert » une feuille de riz fourrée à la fraise, banane, mangue et nutella.<br><br> Avez-vous déjà essayé nos pokés? Ubi Sushi vous offre maintenant une sélection de ces plats originaires d’Hawaï. Frais, délicieux et bien balancé, nos chefs ont su vous concocter une version modernisée, en fusionnant leur savoir-faire en cuisine japonaise, leur créativité, et les méthodes traditionnelles hawaïennes afin de satisfaire le plus fin des palais."
            }
        };
        this.melrose = {
            images: [
                '../../assets/images/melrose/carousel/melrose-dish-1.jpg',
                '../../assets/images/melrose/carousel/melrose-dish-2.jpg',
                '../../assets/images/melrose/carousel/melrose-dish-3.jpg',
                '../../assets/images/melrose/carousel/melrose-dish-4.jpg',
                '../../assets/images/melrose/carousel/melrose-dish-5.jpg',
            ],
            tagline: {
                en: "─ Restaurant Bar ─",
                fr: "─ Restaurant-Bar ─"
            },
            tags: {
                en: ["ITALIAN"],
                fr: ["ITALIEN"]
            },
            description: {
                en: "Melrose resto is a family owned and operated establishment, specializing in Italian comfort foods, serving the Little Italy neighborhood since 2012. Known for their authentic pizzas, custom cocktails and casual ambience.",
                fr: "Melrose resto est un établissement familial, spécialisé dans les aliments réconfortants italiens, desservant le quartier de la Petite Italie depuis 2012. Connus pour leurs pizzas authentiques, leurs cocktails personnalisés et leur ambiance décontractée."
            }
        };
        this.obsceneVegan = {
            images: [
                '../../assets/images/obscene-vegan/carousel/obscene-vegan-dish-1.jpg',
                '../../assets/images/obscene-vegan/carousel/obscene-vegan-dish-2.jpg',
                '../../assets/images/obscene-vegan/carousel/obscene-vegan-dish-3.jpeg',
                '../../assets/images/obscene-vegan/carousel/obscene-vegan-dish-4.jpeg',
                '../../assets/images/obscene-vegan/carousel/obscene-vegan-dish-5.jpeg',
            ],
            tagline: {
                en: "100% Vegan<br>Obsessively Delicious!",
                fr: "100% Végétalien<br>Obscénément Délicieux!"
            },
            tags: {
                en: ["VEGAN"],
                fr: ["VÉGÉTALIEN"]
            },
            description: {
                en: "",
                fr: ""
            }
        };
        this.lestersDeli = {
            images: [
                '../../assets/images/lesters-deli/carousel/lesters-deli-dish-1.jpg',
                '../../assets/images/lesters-deli/carousel/lesters-deli-dish-2.jpg',
                '../../assets/images/lesters-deli/carousel/lesters-deli-dish-3.jpg',
                '../../assets/images/lesters-deli/carousel/lesters-deli-dish-4.jpg',
                '../../assets/images/lesters-deli/carousel/lesters-deli-dish-5.jpg',
            ],
            tagline: {
                en: "Home of the finest Montreal smoked meat!",
                fr: "Le seul et unique vrai smoked meat montréalais!"
            },
            tags: {
                en: ["DELI"],
                fr: ["DELI"]
            },
            description: {
                en: "We are proud to have served Montrealers for over sixty years, and i would like to restate our continuing commitment to quality, service and, above all, to you, our customer–the reason we exist.<br><br>Taking advantage of the latest packaging and shipping methods, we offer you our delicious montreal smoked meat, freshly cut, vacuum-sealed and ready to be reheated whenever and wherever the craving arises. The quality and taste are second to none, and when you try your first Lester's deli sandwich at home, you'll know why we are so eager to serve you. A secure website, a family pledge with sixty years of dedication and, of course, the most mouth-watering smoked meat sandwich you will ever taste–all these make up the lester's deli commitment.<br><br>On behalf of us all at Lester's deli, thank you and bon appétit!",
                fr: "Nous sommes fiers de servir les Montréalais depuis plus de 60 ans. Aujourd'hui, je tiens à renouveler notre engagement de qualité, de service et surtout, de dévouement envers vous, nos clients, sans qui nous n'existerions pas.<br><br>Grâce à des méthodes d'emballage à la fine pointe de la technologie et de livraison efficace, nous vous offrons notre délicieux smoked meat montréalais fraîchement tranché, emballé sous vide et prêt à réchauffer n'importe où et peu importe quand l'envie vous prend.  La qualité et le goût sont sans pareil. Quand vous dégusterez votre premier sandwich, vous saurez pourquoi nous désirons si vivement vous servir.<br><br>Au nom de toute l'équipe de Déli Lesters, merci et bon appétit !"
            }
        };
        this.barranco = {
            images: [
                '../../assets/images/barranco/carousel/barranco-dish-1.jpeg',
                '../../assets/images/barranco/carousel/barranco-dish-2.jpeg',
                '../../assets/images/barranco/carousel/barranco-dish-3.jpeg',
                '../../assets/images/barranco/carousel/barranco-dish-4.jpeg',
                '../../assets/images/barranco/carousel/barranco-dish-5.jpeg',
            ],
            tagline: {
                en: "The tradition of the Peruvian flavor!",
                fr: "La tradition de la saveur Péruvienne!"
            },
            tags: {
                en: ["PERUVIAN"],
                fr: ["PÉRUVIEN"]
            },
            description: {
                en: "Named after one of the most beautiful districts of Lima, Barranco brings genuine Peruvian cuisine to you with products imported directly from Peru.",
                fr: "Nommé après l'un des plus beaux quartiers de Lima, Barranco vous propose de la véritable cuisine péruvienne avec des produits importés directement du Pérou."
            }
        };
        this.petitSeoul = {
            images: [
                '../../assets/images/petit-seoul/carousel/petit-seoul-dish-1.jpg',
                '../../assets/images/petit-seoul/carousel/petit-seoul-dish-2.jpg',
                '../../assets/images/petit-seoul/carousel/petit-seoul-dish-3.jpg',
                '../../assets/images/petit-seoul/carousel/petit-seoul-dish-4.jpg',
                '../../assets/images/petit-seoul/carousel/petit-seoul-dish-5.jpg',
            ],
            tagline: {
                en: "Serving a part of Seoul<br>in Mile End",
                fr: "Servir une partie de Seoul<br>au Mile End"
            },
            tags: {
                en: ["KOREAN"],
                fr: ["CORÉEN"]
            },
            description: {
                en: "A Korean restaurant in the heart of Mile End, Montreal. We are excited to share our love of authentic Korean cuisine.",
                fr: "Un restaurant coréen au cœur du Mile End. Nous sommes fiers de partager notre amour de la cuisine coréenne à travers nos plats authentiques et réconfortants. "
            }
        };
        this.lestersDeli = {
            images: [
                '../../assets/images/lesters-deli/carousel/lesters-deli-dish-1.jpg',
                '../../assets/images/lesters-deli/carousel/lesters-deli-dish-2.jpg',
                '../../assets/images/lesters-deli/carousel/lesters-deli-dish-3.jpg',
                '../../assets/images/lesters-deli/carousel/lesters-deli-dish-4.jpg',
                '../../assets/images/lesters-deli/carousel/lesters-deli-dish-5.jpg',
            ],
            tagline: {
                en: "Home of the finest Montreal smoked meat!",
                fr: "Le seul et unique vrai smoked meat montréalais!"
            },
            tags: {
                en: ["DELI"],
                fr: ["DELI"]
            },
            description: {
                en: "We are proud to have served Montrealers for over sixty years, and i would like to restate our continuing commitment to quality, service and, above all, to you, our customer–the reason we exist.<br><br>Taking advantage of the latest packaging and shipping methods, we offer you our delicious montreal smoked meat, freshly cut, vacuum-sealed and ready to be reheated whenever and wherever the craving arises. The quality and taste are second to none, and when you try your first Lester's deli sandwich at home, you'll know why we are so eager to serve you. A secure website, a family pledge with sixty years of dedication and, of course, the most mouth-watering smoked meat sandwich you will ever taste–all these make up the lester's deli commitment.<br><br>On behalf of us all at Lester's deli, thank you and bon appétit!",
                fr: "Nous sommes fiers de servir les Montréalais depuis plus de 60 ans. Aujourd'hui, je tiens à renouveler notre engagement de qualité, de service et surtout, de dévouement envers vous, nos clients, sans qui nous n'existerions pas.<br><br>Grâce à des méthodes d'emballage à la fine pointe de la technologie et de livraison efficace, nous vous offrons notre délicieux smoked meat montréalais fraîchement tranché, emballé sous vide et prêt à réchauffer n'importe où et peu importe quand l'envie vous prend.  La qualité et le goût sont sans pareil. Quand vous dégusterez votre premier sandwich, vous saurez pourquoi nous désirons si vivement vous servir.<br><br>Au nom de toute l'équipe de Déli Lesters, merci et bon appétit !"
            }
        };
        this.hooters = {
            images: [
                '../../assets/images/hooters/carousel/hooters-dish-1.jpg',
                '../../assets/images/hooters/carousel/hooters-dish-2.jpg',
                '../../assets/images/hooters/carousel/hooters-dish-3.jpg',
                '../../assets/images/hooters/carousel/hooters-dish-4.jpg',
                '../../assets/images/hooters/carousel/hooters-dish-5.jpg',
            ],
            tagline: {
                en: "The recipe for a good time",
                fr: "La recette pour un bon moment"
            },
            tags: {
                en: ["BURGERS"],
                fr: ["BURGERS"]
            },
            description: {
                en: "One of the most iconic chicken wings in the world can now be delivered on your doorstep. Hooters delivers a wide variety of fried food and snacks to elevate the time with your friends and family.",
                fr: "Une des meilleurs ailes de poulet au monde livrée devant votre maison. Hooters offre de diverses options de burgers et d'entrées afin de divertir tout le monde."
            }
        };
        this.darbar = {
            images: [
                '../../assets/images/darbar/carousel/darbar-dish-1.jpeg',
                '../../assets/images/darbar/carousel/darbar-dish-2.jpeg',
                '../../assets/images/darbar/carousel/darbar-dish-3.jpeg',
                '../../assets/images/darbar/carousel/darbar-dish-4.jpeg',
                '../../assets/images/darbar/carousel/darbar-dish-5.jpeg',
            ],
            tagline: {
                en: "Journey through the sights, sounds, and tastes of India",
                fr: "Voyage à travers les images, les sons et les goûts de l’Inde"
            },
            tags: {
                en: ["INDIAN"],
                fr: ["INDIEN"]
            },
            description: {
                en: "With over thirty years of experience, our chefs are constantly rethinking the classic Punjabi dishes. Simply said, our vision at Darbar is to showcase the food of modern Punjab: bold, healthy, and flavour-intense.",
                fr: "Avec plus de trente ans d’expérience, nos chefs sont constamment en train de réinventer les plats classiques du Pendjab. Plus simplement dit, notre vision est de mettre en valeur les aliments du Pendjab moderne : cuisine pleine d’audace et santé, le tout jumelé à des saveurs intenses."
            }
        };
        this.jardinPetros = {
            images: [
                '../../assets/images/jardin-petros/carousel/jardin-petros-dish-1.jpg',
                '../../assets/images/jardin-petros/carousel/jardin-petros-dish-2.jpg',
                '../../assets/images/jardin-petros/carousel/jardin-petros-dish-3.jpg',
                '../../assets/images/jardin-petros/carousel/jardin-petros-dish-4.jpg',
                '../../assets/images/jardin-petros/carousel/jardin-petros-dish-5.jpg',
            ],
            tagline: {
                en: "Fresh Local<br>Greek Cuisine",
                fr: "Cuisine Grecque<br> du Marché"
            },
            tags: {
                en: ["GREEK"],
                fr: ["GRECQUE"]
            },
            description: {
                en: "Come and visit the Petros restaurant, the tradition of Greek Taverns, an authentic cuisine.<br><br>A Mediterranean cooking, colorful, enhanced with herbs and spices, irrigated with olive oil.",
                fr: "Venez découvrir au restaurant Petros, la tradition des tavernes grecques, une cuisine authentique.<br><br>Une gastronomie ensoleillée, colorée, rehaussée d\'herbes et d\'épices, irriguée d\'huile d\'olive."
            }
        };
        this.chezAnna = {
            images: [
                '../../assets/images/chez-anna/carousel/chez-anna-dish-1.jpg',
                '../../assets/images/chez-anna/carousel/chez-anna-dish-2.jpeg',
                '../../assets/images/chez-anna/carousel/chez-anna-dish-3.jpeg',
                '../../assets/images/chez-anna/carousel/chez-anna-dish-4.jpeg',
                '../../assets/images/chez-anna/carousel/chez-anna-dish-5.jpg'
            ],
            tagline: {
                en: "Authentic Hungarian Cuisine",
                fr: "Authentique Cuisine Hongroise"
            },
            tags: {
                en: ["HUNGARIAN"],
                fr: ["HUNGARIAN"]
            },
            description: {
                en: "Hearty, traditional Hungarian food from Anna to you!",
                fr: "Une cuisine hongroise traditionnelle et copieuse, d'Anna à vous !"
            }
        };
        this.krood = {
            images: [
                '../../assets/images/krood/carousel/krood-dish-1.jpg',
                '../../assets/images/krood/carousel/krood-dish-2.jpg',
                '../../assets/images/krood/carousel/krood-dish-3.jpg',
                '../../assets/images/krood/carousel/krood-dish-4.jpg',
                '../../assets/images/krood/carousel/krood-dish-5.jpg'
            ],
            tagline: {
                en: "A Part of California in Montreal",
                fr: "De la Californie à Montréal"
            },
            tags: {
                en: ["POKE BOWLS & TARTARE", "CALIFORNIAN"],
                fr: ["POKÉ BOL & TARTARE", "CALIFORNIEN"]
            },
            description: {
                en: "Krood is much more than a restaurant of poké bowls and tartars.<br><br>We are first and foremost a community of epicureans who share a passion for healthy food, buying local, respecting the environment and making discoveries. <br><br>We are committed to serving the highest quality ingredients and never compromise on taste.<br><br>Our mission is to bring you the energy of the West Coast through our food and our warm, casual atmosphere.",
                fr: "Krood c\'est bien plus qu\'un restaurant de poké bols et de tartares.<br><br>Nous sommes avant tout une communauté d’épicuriens partageant la même passion pour la nourriture saine, l\'achat local, le respect de l\'environnement et la découverte.<br><br>Nous nous engageons à servir des ingrédients de qualité supérieure et de ne jamais faire de compromis sur le goût.<br><br>Notre mission est de vous apporter l\'énergie de la West Coast à travers nos plats et notre ambiance chaleureuse et décontractée."
            }
        };
        this.hungarianCuisine = {
            images: [
                '../../assets/images/hungarian-cuisine/carousel/hungarian-cuisine-dish-1.jpeg',
                '../../assets/images/hungarian-cuisine/carousel/hungarian-cuisine-dish-2.jpeg',
                '../../assets/images/hungarian-cuisine/carousel/hungarian-cuisine-dish-3.jpeg',
                '../../assets/images/hungarian-cuisine/carousel/hungarian-cuisine-dish-4.jpeg',
                '../../assets/images/hungarian-cuisine/carousel/hungarian-cuisine-dish-5.jpeg'
            ],
            tagline: {
                en: "Enjoy our authentic hungarian culinary specialties",
                fr: "Savourez nos authentiques spécialités culinaires hongroises"
            },
            tags: {
                en: ["HUNGARIAN"],
                fr: ["HONGROIS"]
            },
            description: {
                en: "We make traditional Hungarian and Hungarian regional dishes - with quality Canadian ingredients and ingredients from Hungary. This is how we want to achieve the taste and sight that you can get in Hungary as well.",
                fr: "Nos plats traditionnels sont préparés à partir d'ingrédients canadiens de qualité et de produits provenant de Hongrie afin de vous garantir le goût authentique des spécialités hongroises. Si vous avez été en Hongrie ou à Budapest, vous connaissez notre cuisine. Si vous n'y êtes pas encore allé, voici l'occasion de découvrir notre gastronomie."
            }
        }
        this.maynard = {
            images: [
                '../../assets/images/maynard/carousel/maynard-dish-1.jpeg',
                '../../assets/images/maynard/carousel/maynard-dish-2.jpeg',
                '../../assets/images/maynard/carousel/maynard-dish-3.jpeg',
                '../../assets/images/maynard/carousel/maynard-dish-4.jpeg',
                '../../assets/images/maynard/carousel/maynard-dish-5.jpeg'
            ],
            tagline: {
                en: "Just Come on Home.",
                fr: "Just Come on Home."
            },
            tags: {
                en: ["VEGAN CUISINE"],
                fr: ["CUISINE VÉGANE"]
            },
            description: {
                en: "Welcome to Maynard! Our urban version of a chip shack brings vegan alternatives of all the nostalgic comfort classics that you know and love, all while using high quality locally sourced seasonal ingredients prepared with love.",
                fr: "Bienvenue chez Maynard ! Notre version urbaine d'un « shack à patates » apporte des alternatives véganes à tous les classiques nostalgiques et réconfortants que l'on connaît et apprécie, le tout en utilisant des ingrédients de saison, locaux et de haute qualité, préparés avec amour."
            }
        };
        this.mFourBurritos = {
            images: [
                '../../assets/images/m4-burritos/carousel/mfourburritos-carousel-1.jpg',
                '../../assets/images/m4-burritos/carousel/mfourburritos-carousel-2.jpg',
                '../../assets/images/m4-burritos/carousel/mfourburritos-carousel-3.jpg',
                '../../assets/images/m4-burritos/carousel/mfourburritos-carousel-4.jpg',
                '../../assets/images/m4-burritos/carousel/mfourburritos-carousel-5.jpg'
            ],
            tagline: {
                en: "Roll Fresh",
                fr: "On roule frais"
            },
            tags: {
                en: ["MEXICAN", "BURRITOS"],
                fr: ["MEXICAIN", "BURRITOS"]
            },
            description: {
                en: "M4 Burritos brings succulent Mexican-inspired dishes to everyone!<br><br>M4 Burritos is synonymous with innovation! That is why we are continuously developing our concept to improve our recipes, optimize the quality of our service and enhance our customer's experience.",
                fr: "M4 Burritos désire assurer à tous et chacun des plats savoureux d\'inspiration mexicaine, peu importe où vous êtes au Québec.<br><br>M4 Burritos est synonyme d\'innovation ! Ainsi, nous développons sans cesse notre concept dans le but d\'optimiser notre service à la clientèle et de bonifier le confort de nos clients."
            }
        };
        this.bocadillo = {
            images: [
                '../../assets/images/bocadillo/carousel/bocadillo-dish-1.jpg',
                '../../assets/images/bocadillo/carousel/bocadillo-dish-2.jpg',
                '../../assets/images/bocadillo/carousel/bocadillo-dish-3.jpg',
                '../../assets/images/bocadillo/carousel/bocadillo-dish-4.jpg',
                '../../assets/images/bocadillo/carousel/bocadillo-dish-5.jpg'
            ],
            tagline: {
                en: "The Taste of Venezuela<br>Made in Family",
                fr: "La saveur du Vénézuela<br>fait en famille"
            },
            tags: {
                en: ["VENEZUELAN"],
                fr: ["VÉNÉZUÉLIENNE"]
            },
            description: {
                en: "Our culinary tradition in Montreal begins in 2008, but in reality, it is a continuation of an education in the kitchen of several generations of our family brought from Europe by our great-grandparents and grandmothers, instilling in us that passion in gastronomy and that curiosity for learning traditional flavors and new ingredients for us.<br><br>That gave us a desire to evolve, create and perfect our recipes based on this wonderful Venezuelan Cuisine, awarded around the world, which has remained unchanged over the years, something that our cusomters and friends have always praised us... “CONSTANCE”. Our passion and dedication are our secrets.",
                fr: "Notre tradition culinaire à Montréal commence en 2008, mais en réalité, c’est la continuation d’une éducation dans la cuisine de plusieurs générations de notre famille, apportée d’Europe par nos arrière-grands-parents et grandes-mères, nous inculquant dès l’enfance cette passion pour la gastronomie et cette curiosité pour découvrir les saveurs traditionnelles et les nouveaux ingrédients pour nous.<br><br>Cela nous a donné le désir d’évoluer, de créer et de perfectionner nos recettes basées sur cette merveilleuse cuisine vénézuélienne, récompensée dans le monde entier, qui est restée inchangée au fil des années, ce que nos clients et nos amis nous ont toujours félicité; “CONSTANCE”. Notre passion et notre dévouement sont nos secrets."
            }
        };
        this.laurierBbq = {
            images: [
                '../../assets/images/laurier-bbq/carousel/laurierbbq-carousel-1.jpg',
                '../../assets/images/laurier-bbq/carousel/laurierbbq-carousel-2.jpg',
                '../../assets/images/laurier-bbq/carousel/laurierbbq-carousel-3.jpg',
                '../../assets/images/laurier-bbq/carousel/laurierbbq-carousel-4.jpg',
                '../../assets/images/laurier-bbq/carousel/laurierbbq-carousel-5.jpg'
            ],
            tagline: {
                en: "The Rotisserie Chicken Landmark making it's comeback",
                fr: "Le retour d'un pionner du poulet rôti"
            },
            tags: {
                en: ["BBQ"],
                fr: ["BBQ"]
            },
            description: {
                en: "Laurier BBQ returns with it's classic menu of rotisserie chicken meals with fries, coleslaw, and gravy, as well as a host of salads, including reproduction of the restaurant's famous carrot cakes.",
                fr: "Laurier BBQ est de retour et vous propose une carte moderne de ses plats classiques !  Poulet rôti, frites, sandwichs et salade maison... Sans oublier une reproduction du célèbre gâteau aux carottes du restaurant d'antan."
            }
        };
        this.oFish = {
            images: [
                '../../assets/images/o-fish/carousel/ofish-carousel-1.jpg',
                '../../assets/images/o-fish/carousel/ofish-carousel-2.jpg',
                '../../assets/images/o-fish/carousel/ofish-carousel-3.jpg',
                '../../assets/images/o-fish/carousel/ofish-carousel-4.jpg',
                '../../assets/images/o-fish/carousel/ofish-carousel-5.jpg'
            ],
            tagline: {
                en: "Seafood recipes<br>Around the World",
                fr: "Recettes de poissons du monde entier"
            },
            tags: {
                en: ["SEAFOOD"],
                fr: ["FRUIT DE MER"]
            },
            description: {
                en: "Ô Fish Restaurant is a family restaurant specialized in seafood products situated in Montreal.<br><br>On our menu, you will find a variety of fresh products made with respect for the authentic taste and for the seafood recipes around the world.",
                fr: "Ô Fish Restaurant est un restaurant familial spécialisé dans les produits de la mer situé à Montréal.<br><br>Au menu, vous trouverez une variété de produits frais, cuisinés dans le respect du goût authentique et des recettes de poissons du monde."
            }
        };
        this.sushiSamaConcordia = {
            images: [
                '../../assets/images/sushi-sama/carousel/sushi-sama-carousel-1.jpeg',
                '../../assets/images/sushi-sama/carousel/sushi-sama-carousel-2.jpeg',
                '../../assets/images/sushi-sama/carousel/sushi-sama-carousel-3.jpeg'
            ],
            tagline: {
                en: "A Modernized Japanese Tradition",
                fr: "Une tradition japonaise modernisée"
            },
            tags: {
                en: ["SUSHI"],
                fr: ["SUSHI"]
            },
            description: {
                en: "It is at Sushi Sama that sushi is prepared with finesse and diligence. Each roll has unique ingredients coming together in harmony.<br><br>The original names of each item reflect its contents and they have been carefully created by the experienced Sushi Sama chefs.<br><br>Whether it's to eat healthy or for a special event, you will be satisfied with your experience!eturns with it's classic menu of rotisserie chicken meals with fries, coleslaw, and gravy, as well as a host of salads, including reproduction of the restaurant's famous carrot cakes.",
                fr: "C'est à Sushi Sama le sushi est préparé avec finesse et diligence. Chaque rouleau contient des ingrédients uniques réunis en harmonie.<br><br>Les noms originaux de chaque article reflètent fidèlement leur authenticité et le soin avec lequel ils ont été préparés par les chefs expérimentés de Sushi Sama.<br><br>Que ce soit pour manger sainement ou pour un événement spécial, vous serez heureux de vous y être arrêté !"
            }
        };
        this.paniniStop = {
            images: [
                '../../assets/images/panini-stop/carousel/panini-stop-carousel-dish-1.png',
                '../../assets/images/panini-stop/carousel/panini-stop-carousel-dish-2.png',
                '../../assets/images/panini-stop/carousel/panini-stop-carousel-dish-3.png',
                '../../assets/images/panini-stop/carousel/panini-stop-carousel-dish-4.png',
            ],
            tagline: {
                en: "The Classic French Taco",
                fr: "Le taco français classique"
            },
            tags: {
                en: ["ALGERIAN", "PANINI"],
                fr: ["ALGÉRIEN", "PANINI"]
            },
            description: {
                en: "On the go Algerian restaurant specializing in traditional wraps with various fillings.",
                fr: "Restaurant algérien simple spécialisé dans les wraps traditionnels aux garnitures variées."
            }
        };
        this.brigadePizza = {
            images: [
                '../../assets/images/brigade-pizza/carousel/brigade-pizza-carousel-1.png',
                '../../assets/images/brigade-pizza/carousel/brigade-pizza-carousel-2.png',
                '../../assets/images/brigade-pizza/carousel/brigade-pizza-carousel-3.png',
                '../../assets/images/brigade-pizza/carousel/brigade-pizza-carousel-4.png'
            ],
            tagline: {
                en: "Passion and quality at Brigade",
                fr: "L'intersection de la passion et la qualité"
            },
            tags: {
                en: ["ITALIAN", "PIZZA"],
                fr: ["ITALIEN", "PIZZA"]
            },
            description: {
                en: "Since the beginning, our journey as been filled with excitement about working on new ideas and introducing a new concept to the food scene in Montreal and Canada.<br><br>We only have one goal in mind, offering you delicious authentic neapolitan pizzas. Our pizzas are prepared the same way as in Naples, Italy where neapolitan pizza was born more than 200 years ago.<br><br>Without compromise, at Brigade you have the quality you deserve.",
                fr: "Depuis le début, notre aventure a été remplie d'engouement à l'idée de travailler sur de nouvelles idées et d'introduire un nouveau concept sur la scène alimentaire de Montréal et du Canada.<br><br>Nous n'avons qu'un seul objectif en tête, vous offrir de délicieuses pizzas napolitaines authentiques. Nos pizzas sont préparées de la même façon qu'à Naples, en Italie, où la pizza napolitaine est née il y a plus de 200 ans.<br><br>Chez Brigade, vous avez la qualité que vous méritez, sans compromis."
            }
        };
        this.pokeStationCdn = {
            images: [
                '../../assets/images/poke-station/carousel/poke-station-cdn-carousel-1.png',
                '../../assets/images/poke-station/carousel/poke-station-cdn-carousel-2.png',
                '../../assets/images/poke-station/carousel/poke-station-cdn-carousel-3.png',
                '../../assets/images/poke-station/carousel/poke-station-cdn-carousel-4.png',
                '../../assets/images/poke-station/carousel/poke-station-cdn-carousel-5.png'
            ],
            tagline: {
                en: "POKE, POKE and... more POKE!",
                fr: "POKÉ, POKÉ et... plus de POKÉ !"
            },
            tags: {
                en: ["JAPANESE", "POKE"],
                fr: ["JAPONAIS", "POKÉ"]
            },
            description: {
                en: "Le Poké Station offers sushi that is prepared with finesse and diligence.  Each item on the menu has unique, harmonious ingredients and have been carefully created by the experienced chefs at The Poké Station. <br><br>Whether it is because you are craving a healthy meal or putting together a special event, you will be satisfied with your experience!",
                fr: "Le Poké Station propose des sushis qui sont préparés avec finesse et diligence.  Chaque plat contient des ingrédients uniques et harmonieux et a été soigneusement créé par les chefs expérimentés du Poké Station. <br><br>Que ce soit parce que vous avez envie d'un repas sain ou que vous préparez un événement spécial, vous serez satisfait de votre expérience !"
            }
        };
        this.teochewFoodie = {
            images: [
                '../../assets/images/teochew/carousel/teochew-carousel-1.png',
                '../../assets/images/teochew/carousel/teochew-carousel-2.png',
                '../../assets/images/teochew/carousel/teochew-carousel-3.png',
                '../../assets/images/teochew/carousel/teochew-carousel-4.png',
                '../../assets/images/teochew/carousel/teochew-carousel-5.png',
                '../../assets/images/teochew/carousel/teochew-carousel-6.png'
            ],
            tagline: {
                en: "Authentic Chinese cuisine in the heart of the Plateau.",
                fr: "Une cuisine authentique chinoise au cœur du Plateau."
            },
            tags: {
                en: ["CHINESE"],
                fr: ["CHINOIS"]
            },
            description: {
                en: "A family business that provides the Montreal market with high quality, healthy Chinese food that is ready to eat and easy to prepare.",
                fr: "Une entreprise familiale qui fournit au marché montréalais une cuisine chinoise saine et de haute qualité, prête à savourer et facile à préparer."
            }
        };
        this.alAmine = {
            images: [
                '../../assets/images/al-amine/carousel/al-amine-carousel-1.png',
                '../../assets/images/al-amine/carousel/al-amine-carousel-2.png',
                '../../assets/images/al-amine/carousel/al-amine-carousel-3.png',
                '../../assets/images/al-amine/carousel/al-amine-carousel-4.png'
            ],
            tagline: {
                en: "UdeM's Lebanese canteen",
                fr: "La cantine libanaise de l’UdeM"
            },
            tags: {
                en: ["LEBANESE"],
                fr: ["LIBANAIS"]
            },
            description: {
                en: "In the Côte-des-Neiges area, Al-Amine offers the best Lebanese dishes. With a traditional menu including shawarma, saj, manakish, falafel, tabouleh, and even a unique pizza menu, you are sure to find a dish to your liking.<br><br>But, be sure to go there with an empty stomach, because the portions are well filled and generous. Chef Mario's homemade hot garlic sauce alone is worth the detour!",
                fr: "Dans le secteur Côte-des-Neiges, c'est Al-Amine qui propose les meilleures assiettes libanaises. Avec une carte traditionnelle comprenant shawarma, saj, manakish, falafel, tabouleh, en passant même par une carte de pizzas uniques, vous êtes certains de trouver un plat à votre goût.<br><br>Par contre, rendez-vous avec l'estomac vide, car les portions sont bien garnies et généreuses. La sauce à l'ail piquante maison préparée par le chef Mario vaut, à elle seule, le détour !"
            }
        };
        this.tapigo = {
            images: [
                '../../assets/images/tapi-go/carousel/tapi-go-carousel-1.png',
                '../../assets/images/tapi-go/carousel/tapi-go-carousel-2.png',
            ],
            tagline: {
                en: "Taste of Brazil",
                fr: "Un petit goût du Brésil"
            },
            tags: {
                en: ["BRAZILIAN | STREET FOOD"],
                fr: ["Brésilien | Cuisine de rue"]
            },
            description: {
                en: "Tapi Go! wants to introduce to you the best Brazilian Tapioca Crepes you will ever have! We brought from Brazil the traditional techniques and developed our own special tricks to make delicious and beautiful crepes and other tapioca specialties. We combine locally sourced ingredients with authentic Brazilian recipes. <br><br>Tapi Go! believes that being transparent is key to success. All our products are made from scratch with simple ingredients, no preservatives and tons of love. Taste the difference.",
                fr: "Tapi Go ! veut vous introduire aux meilleures crêpes de tapioca brésiliennes que vous n'ayez jamais mangées ! Nous avons ramené du Brésil les techniques traditionnelles et développé nos propres astuces pour faire de délicieuses crêpes et autres spécialités à base de tapioca. Nous combinons des ingrédients d'origine locale avec d'authentiques recettes brésiliennes. <br><br>Tapi Go ! croit qu'être transparent est la clé du succès. Tous nos produits sont préparés de tout pièce, avec des ingrédients naturels, sans agents de conservation et avec beaucoup d'amour. Goûtez la différence."
            }
        };
        this.bernieBeigne = {
            images: [
                '../../assets/images/bernie-beigne/carousel/bernie-beigne-carousel-1.png',
                '../../assets/images/bernie-beigne/carousel/bernie-beigne-carousel-2.png',
                '../../assets/images/bernie-beigne/carousel/bernie-beigne-carousel-3.png'
            ],
            tagline: {
                en: "Brand-new, Old-School Donuts",
                fr: "Le retour des beignets à l'ancienne"
            },
            tags: {
                en: ["DESSERT | DONUT SHOP"],
                fr: ["DESSERT | BEIGNERIE"]
            },
            description: {
                en: "Modelled after Mile End's old-fashioned bakeries, Bernie Beigne offers over 20 different donut varieties made daily.<br><br>Peek through the window, you’re sure to find one to your liking! ",
                fr: "Inspiré par les boulangeries à l'ancienne du Mile End, Bernie Beigne vous propose plus de 20 variétés de beignets préparés quotidiennement.<br><br>Jetez un coup d'œil par la fenêtre, vous êtes certains d'en trouver un à votre goût ! "
            }
        };
        this.cacao70 = {
            images: [
                '../../assets/images/cacao-70/carousel/cacao-70-carousel-1.png',
                '../../assets/images/cacao-70/carousel/cacao-70-carousel-2.png',
                '../../assets/images/cacao-70/carousel/cacao-70-carousel-3.png'
            ],
            tagline: {
                en: "Welcome to a State of Chocolate, Bon Voyage!",
                fr: "Bienvenue dans l'état du chocolat, bon séjour !"
            },
            tags: {
                en: ["DESSERT | CHOCOLATIER"],
                fr: ["DESSERT | CHOCOLATERIE"]
            },
            description: {
                en: "Chocolate takes us away.<br><br>A cup of hot chocolate brings back childhood memories. Whipped milk takes us back to a 1950s diner. A hint of sea salt transports us to a desert island (or better yet, a dessert island).<br><br>At Cacao 70, we invite you to munch, sip, and sip your way to a world of chocolate.<br><br>Bon appétit and bon voyage!",
                fr: "Le chocolat nous fait voyager.<br><br>Une tasse de chocolat chaud fait surgir des souvenirs d’enfance. Un lait fouetté nous ramène dans un diner des années 50. Un soupçon de sel de mer nous transporte sur une île déserte (ou encore mieux, sur une île de desserts).<br><br>Chez Cacao 70, nous vous invitons à croquer, à siroter, à aspirer à petites gorgées vers un monde de chocolat.<br><br>Bon appétit et bon voyage !"
            }
        };
        this.tacosNation = {
            images: [
                '../../assets/images/tacos-nation/carousel/tacos-nation-carousel-1.png',
                '../../assets/images/tacos-nation/carousel/tacos-nation-carousel-3.png',
                '../../assets/images/tacos-nation/carousel/tacos-nation-carousel-4.png'
            ],
            tagline: {
                en: "The French Original",
                fr: "L’original français"
            },
            tags: {
                en: ["FRENCH TACOS"],
                fr: ["TACOS FRANÇAIS"]
            },
            description: {
                en: "Tacos Nation, the ultimate destination for delicious French tacos!",
                fr: "Tacos Nation, la destination par excellence pour un délicieux tacos français !"
            }
        };
        this.poissonnerieSherbrooke = {
            images: [
                '../../assets/images/poissonnerie-sherbrooke/carousel/poissonnerie-sherbrooke-carousel-1.jpeg',
            ],
            tagline: {
                en: "Over 50 years of fish!",
                fr: "Plus de 50 ans de poisson !"
            },
            tags: {
                en: ["FISHMONGER | SEAFOOD"],
                fr: ["POISSONNERIE | FRUITS DE MER"]
            },
            description: {
                en: "At Sherbrooke Fish & Seafood, we have been striving to be your reliable source of fresh, quality fish and seafood for over 20 years.<br><br>Our wide range of products, carefully selected for taste, texture and nutritional value, allows us to offer you the most delicious meals.<br><br> On site, our customers can expect to find a friendly and knowledgeable staff, passionate about the products they sell and always ready to help.",
                fr: "À la Poissonnerie Sherbrooke, nous nous efforçons d'être votre source fiable de poissons et fruits de mer frais et de qualité depuis plus de 20 ans.<br><br> Notre éventail de produits, soigneusement sélectionné pour son goût, sa texture et sa valeur nutritive, nous permet de vous offrir les repas les plus savoureux.<br><br>Sur place, nos clients peuvent s'attendre à trouver un personnel amical et compétent, passionné par les produits qu'il vend et toujours prêt à aider."
            }
        };
        this.mintar = {
            images: [
                '../../assets/images/mintar/carousel/mintar-carousel-1.jpg',
                '../../assets/images/mintar/carousel/mintar-carousel-2.jpeg',
                '../../assets/images/mintar/carousel/mintar-carousel-3.jpeg'
            ],
            tagline: {
                en: "AHLA WO SAHLA, WELCOME!",
                fr: "AHLA WO SAHLA, BIENVENUE!"
            },
            tags: {
                en: ["SUBS | MIDDLE EASTERN"],
                fr: ["SOUS-MARINS | MOYEN-ORIENT"]
            },
            description: {
                en: `Cozy and chic café found on Park Avenue, in the heart of downtown Montreal.
                <br><br>
                Run by Rajai (with a chef background) and his brother Tareq (with experience in the retail industry), who opened Mintar to express their love for coffee and food—this also gave them the opportunity to showcase their Middle Eastern roots on their menu.
                <br><br>
                Mintar offers a variety of tasty subs, a full coffee menu and a delectable choice of pastries and desserts.`,
                fr: `Café cosy et chic situé sur l'avenue du Parc, au coeur du centre-ville de Montréal.
                <br><br>
                Dirigé par Rajai (avec une formation de chef) et son frère Tareq (avec de l'expérience dans le commerce de détail), qui ont ouvert Mintar pour exprimer leur amour pour le café et la nourriture. Cela leur a également donné l'occasion de mettre en valeur leurs racines moyen-orientales sur leur menu.
                <br><br>
                Mintar propose une variété de sous-marins savoureux, un menu complet de cafés et un choix délicieux de pâtisseries et de desserts.`
            }
        };
        this.auxmerveilleuxdefred = {
            images: [
                '../../assets/images/aux-merveilleux-de-fred/carousel/aux-merveilleux-de-fred-carousel-1.png',
                '../../assets/images/aux-merveilleux-de-fred/carousel/aux-merveilleux-de-fred-carousel-2.png',
                '../../assets/images/aux-merveilleux-de-fred/carousel/aux-merveilleux-de-fred-carousel-3.png',
            ],
            tagline: {
                en: "Worldwide opening",
                fr: "Ouverture à travers le monde"
            },
            tags: {
                en: ["PASTRY SHOP | FRENCH"],
                fr: ["PÂTISSERIE | FRANÇAIS"]
            },
            description: {
                en: "Driven by his love of taste, Frédéric Vaucamps wants to share and introduce people to his recipes around the world.<br><br> Since 2003, Aux Merveilleux de Fred has grown and opened several stores throughout France. Alongside these openings on home soil, since 2009 Frédéric has been crossing new frontiers into prestigious cities around the world.<br><br>In each country, the products on offer are identical. The aim is for every customer - whether in Montreal, London or Lille - to find the same quality, the same magical taste and, above all, the same deliciousness.",
                fr: "C’est par amour du goût que Frédéric Vaucamps souhaite partager et faire découvrir ses recettes.<br><br> Aux Merveilleux de Fred s’est développé, à partir de 2003 : plusieurs boutiques ont vu le jour en France.En parallèle de ces ouvertures sur l'hexagone, depuis 2009, Frédéric a franchi les frontières dans des villes prestigieuses.<br><br> Dans chaque pays, les produits proposés sont identiques. L’objectif est que chaque client, à Montréal, à Londres ou à Lille retrouve la même qualité, le même gout magique et surtout la même gourmandise."
            }
        };
        this.laToile = {
            images: [
                '../../assets/images/la-toile/carousel/la-toile-carousel-1.png',
                '../../assets/images/la-toile/carousel/la-toile-carousel-2.png',
                '../../assets/images/la-toile/carousel/la-toile-carousel-3.png',
                '../../assets/images/la-toile/carousel/la-toile-carousel-4.png',
            ],
            tagline: {
                en: "Pizza is art",
                fr: "L'art d'une bonne pizza"
            },
            tags: {
                en: ["PIZZA"],
                fr: ["PIZZA"]
            },
            description: {
                en: "La Toile Restaurant treats each dough like a painting canvas and each pizza like a completed work of art. Born out of a passion for pizza and a desire to create the best pizza, La Toile Restaurant promises to bring you artisanal pizzas made with fresh, locally sourced ingredients.",
                fr: "Le Restaurant La Toile traite chaque pâte comme une toile de peinture et chaque pizza comme une oeuvre d'art complétée. Né d'une passion pour la pizza et du désir de créer la meilleure pizza, le Restaurant La Toile promet de vous offrir des pizzas artisanales faites à partir d'ingrédients frais et sourcés localement."
            }
        };
        this.sepLai = {
            images: [
                '../../assets/images/sep-lai/carousel/sep-lai-carousel-1.jpg',
                '../../assets/images/sep-lai/carousel/sep-lai-carousel-2.jpg',
                '../../assets/images/sep-lai/carousel/sep-lai-carousel-3.jpg',
                '../../assets/images/sep-lai/carousel/sep-lai-carousel-4.jpg',
                '../../assets/images/sep-lai/carousel/sep-lai-carousel-5.jpg'
            ],
            tagline: {
                en: "Taste the unique and authentic flavours of Laos",
                fr: "Goûtez les saveurs uniques et authentiques du Laos"
            },
            tags: {
                en: ["LAOTIAN"],
                fr: ["LAOTIENNE"]
            },
            description: {
                en: `<em>Sabaidee!</em> Welcome to Sep Lai! We serve traditional Laotian food using many local ingredients to create unique and authentic dishes.
                <br><br>
                Although you may find similarities to other Southeast Asian dishes such as Thai or Vietnamese dishes, Loation cuisine is distinct with its own complex and exciting flavors. Full of fresh herbs, raw vegetables, savory meats, and always accompanied by a basket of sticky rice.
                <br><br>
                We hope that the dishes of our parents and grandparents will inspire you with the same comfort and the same joy as in us, and that you will exclaim: \"<em>Sep lai!</em> It was delicious!\"`,
                fr: `<em>Sabaidee!</em> Bienvenue à Sep Lai! Nous servons de la nourriture traditionnelle laotienne en utilisant de nombreux ingrédients locaux pour créer des plats uniques et authentiques.
                <br><br>
                Bien que vous puissiez trouver des similitudes avec d'autres plats d'Asie du Sud-Est tels que les plats thaïlandais ou vietnamiens, la cuisine laotienne est une cuisine distincte avec ses propres saveurs complexes et excitantes. Pleine d'herbes fraîches, de légumes crus, de viandes savoureuses et toujours accompagnée d'un panier de riz gluant.
                <br><br>
                Nous espérons que les plats de nos parents et de nos grands-parents vous inspireront le même réconfort et la même joie qu'à nous, et que vous vous exclamerez: \"<em>Sep lai!</em> C'était délicieux!\"`
            }
        };
        this.bistroDeLaCite = {
            images: [
                '../../assets/images/bistro-de-la-cite/carousel/bistro-de-la-cite-carousel-1.png',
                '../../assets/images/bistro-de-la-cite/carousel/bistro-de-la-cite-carousel-2.png',
                '../../assets/images/bistro-de-la-cite/carousel/bistro-de-la-cite-carousel-3.png',
                '../../assets/images/bistro-de-la-cite/carousel/bistro-de-la-cite-carousel-4.jpeg'
            ],
            tagline: {
                en: "Fresh croissants, tasty pastries, and friendly smiles!",
                fr: "Croissants frais, pâtisseries savoureuses, et des sourires accueillants!"
            },
            tags: {
                en: ["BISTRO | PASTRY"],
                fr: ["BISTRO | PATISSERIE"]
            },
            description: {
                en: `A family-run bistro located in Les Galeries Du Parc and a must-go for breakfast, coffee, or delicious sandwiches!`,
                fr: `Un bistro familial situé dans Les Galeries Du Parc, incontournable pour le petit-déjeuner, un café chaud, ou des délicieux sandwichs!`
            }
        };
        this.singhs = {
            images: [
                '../../assets/images/singhs/carousel/singhs-carousel-1.jpg',
                '../../assets/images/singhs/carousel/singhs-carousel-2.jpg',
                '../../assets/images/singhs/carousel/singhs-carousel-3.jpg'
            ],
            tagline: {
                en: "Come taste our delicious dishes and feel like you're in India",
                fr: "Venez déguster nos délicieux plats et vous sentirez comme si vous étiez en Inde"
            },
            tags: {
                en: ["INDIAN"],
                fr: ["INDIEN"]
            },
            description: {
                en: `If you want to taste really delicious Indian food, choose Restaurant Singh's, where you will find the best Indian food in the city. Our menu is dedicated to satisfy the tastes of every customer and prepared only with fresh and healthy ingredients.
                <br><br>
                We also offer Lunch Specials such as the Thali with Lamb Curry and Thali with Chicken Curry. Our team is waiting to show you how good our Indian food is!`,
                fr: `Si vous voulez goûter de la cuisine indienne vraiment délicieuse, choisissez le restaurant Singh's, où vous trouverez la meilleure cuisine indienne de la ville. Notre menu est dédié à satisfaire les goûts de chaque client et tous nos plats sont préparé uniquement avec des ingrédients frais et sains.
                <br><br>
                Nous offrons aussi des spéciaux du dîner tels que le thali au curry d'agneau et le thali au curry de poulet. Notre équipe vous attend pour vous montrer à quel point notre cuisine indienne est bonne!`
            }
        };
        this.chaiTeaLounge = {
            images: [
                '../../assets/images/chai-tea-lounge/carousel/chai-tea-lounge-carousel-1.jpg',
                '../../assets/images/chai-tea-lounge/carousel/chai-tea-lounge-carousel-2.jpg',
                '../../assets/images/chai-tea-lounge/carousel/chai-tea-lounge-carousel-3.jpg',
                '../../assets/images/chai-tea-lounge/carousel/chai-tea-lounge-carousel-4.jpg',
                '../../assets/images/chai-tea-lounge/carousel/chai-tea-lounge-carousel-5.jpg'
            ],
            tagline: {
                en: "A zen corner in the heart of the McGill Ghetto",
                fr: "Un coin zen au coeur du ghetto McGill"
            },
            tags: {
                en: ["TEA HOUSE"],
                fr: ["MAISON DE THÉ"]
            },
            description: {
                en: `At Chaï Tea Lounge, we offer a wide selection of teas, including white, black, oolong, pu'erh, rooibos, mate, herbal teas, and even coffee.
                <br><br>
                Our specialities are our tea-based beverages, like bubble tea, smoothies, kombucha, and more!
                <br><br>
                You can also find a selection of fine pastries and healthy meals, even gluten-free and vegan options.`,
                fr: `Chez Chaï Tea Lounge, on vous propose un vaste choix de thés, dont du blanc, noir, oolong, pu'erh, rooibos, maté, des tisanes et même du café.
                <br><br>
                Nos spécialités sont nos boissons à base de thé, comme le bubble tea, les smoothies, le kombucha, et plus encore!
                <br><br>
                Vous pouvez également trouver une sélection de pâtisseries fines et de repas santé, avec même des options sans gluten et végétaliennes.`
            }
        };
        this.laCroissanterieFigaro = {
            images: [
                '../../assets/images/la-croissanterie-figaro/carousel/la-croissanterie-figaro-carousel-1.jpg',
                '../../assets/images/la-croissanterie-figaro/carousel/la-croissanterie-figaro-carousel-2.jpg',
                '../../assets/images/la-croissanterie-figaro/carousel/la-croissanterie-figaro-carousel-3.jpg',
                '../../assets/images/la-croissanterie-figaro/carousel/la-croissanterie-figaro-carousel-4.jpg',
                '../../assets/images/la-croissanterie-figaro/carousel/la-croissanterie-figaro-carousel-5.jpg'
            ],
            tagline: {
                en: "Vibrant cafe offering housemade croissants, brunch & eclectic fare.",
                fr: "Café animé proposant des croissants maison, brunch et des plats éclectiques."
            },
            tags: {
                en: ["CAFÉ"],
                fr: ["CAFÉ"]
            },
            description: {
                en: `Located at the corner of Fairmount and Hutchison, La Croissanterie Figaro has been known for many years for its warm and professional service, its enchanting décor, as well as for its exquisite menu.
                <br><br>
                Whether it's for a good coffee or a drink, a delicious meal or simply to take a little break, le Figaro is without a doubt the place of choice for a relaxing time that will give you the impression of being in a pretty little "coin perdu de Paris"!`,
                fr: `Située à l'angle des rues Hutchison et Fairmount, La Croissanterie Figaro est reconnue depuis plusieurs années pour son service chaleureux et professionnel, pour son décor enchanteur au style art nouveau, ainsi que pour son menu des plus exquis.
                <br><br>
                Que ce soit pour un café ou un apéro, un délicieux repas ou une simple pause lecture, le Figaro est sans doute le café par excellence pour prendre un moment de détente dans un endroit qui vous donne l'impression de vous trouver dans un petit coin perdu de Paris!`
            }
        };
        this.laFabriqueDeBagel = {
            images: [
                '../../assets/images/la-fabrique-de-bagel/carousel/lfdb-carousel-1.jpeg',
                '../../assets/images/la-fabrique-de-bagel/carousel/lfdb-carousel-2.jpeg',
                '../../assets/images/la-fabrique-de-bagel/carousel/lfdb-carousel-3.jpeg',
                '../../assets/images/la-fabrique-de-bagel/carousel/lfdb-carousel-4.jpeg',
                '../../assets/images/la-fabrique-de-bagel/carousel/lfdb-carousel-5.jpeg'
            ],
            tagline: {
                en: "The Passion for Bagels",
                fr: "La passion pour bagels"
            },
            tags: {
                en: ["BAGELS | BAKERY"],
                fr: ["BAGELS | BOULANGERIE"]
            },
            description: {
                en: `The artisans of La Fabrique de Bagel offer you a wide variety of sweet and savoury bagel flavours and a multitude of creative spreads.
                <br><br>
                All of our bagels are proudly rolled, shaped, and baked fresh according to Montreal's classic bagel-making tradition.`,
                fr: `Les artisans de La Fabrique de Bagel vous proposent une grande variété de saveurs de bagels sucrés et salés et une multitude de tartinades créatives.
                <br><br>
                Tous nos bagels sont fièrement roulés, façonnés et cuits frais selon la plus pure tradition Montréalaise.`
            }
        };
        this.studentTastyBiryani = {
            images: [
                '../../assets/images/student-tasty-biryani/carousel/stb-carousel-1.jpg',
                '../../assets/images/student-tasty-biryani/carousel/stb-carousel-2.jpg',
                '../../assets/images/student-tasty-biryani/carousel/stb-carousel-3.jpg',
                '../../assets/images/student-tasty-biryani/carousel/stb-carousel-4.jpg',
                '../../assets/images/student-tasty-biryani/carousel/stb-carousel-5.jpg'
            ],
            tagline: {
                en: "Authentic Pakistani and Indian cuisine accessible to everyone looking for a spicy adventure!",
                fr: "Cuisine pakistanaise et indienne authentique accessible à tous ceux qui recherchent une aventure épicée !"
            },
            tags: {
                en: ["PAKISTANI | INDIAN"],
                fr: ["PAKISTANAIS | INDIEN"]
            },
            description: {
                en: `Student Tasty Biryani was founded over 12 years ago in Montreal, Quebec. Authentic spices and fresh produce are at the core of our delicious meals, and we bring you the best of Indian and Pakistani cuisines to Canada.
                <br><br>
                We offer you a choice of traditional south east asian cuisine to suit every taste with many of the classic dishes. We pride ourselves on quality and taste, so you can be sure to enjoy a sumptuous freshly made dish when you eat.`,
                fr: `Student Tasty Biryani a été fondé il y a plus de 12 ans à Montréal, Québec. Des épices authentiques et des produits frais sont au coeur de nos délicieux repas, et nous vous apportons le meilleur des cuisines indiennes et pakistanaises au Canada.
                <br><br>
                Nous vous proposons un choix de cuisine traditionnelle de l'Asie du sud est pour tous les goûts avec de nombreux plats classiques. Nous sommes fiers de la qualité et du goût de nos plats. Vous pouvez donc être sûr de profiter d'un somptueux plat fraîchement préparé lorsque vous mangez.`
            }
        };
        this.osukaSushi = {
            images: [
                '../../assets/images/osuka-sushi/carousel/osuka-sushi-carousel-1.jpeg',
                '../../assets/images/osuka-sushi/carousel/osuka-sushi-carousel-2.jpeg',
                '../../assets/images/osuka-sushi/carousel/osuka-sushi-carousel-3.jpeg',
                '../../assets/images/osuka-sushi/carousel/osuka-sushi-carousel-4.jpeg',
                '../../assets/images/osuka-sushi/carousel/osuka-sushi-carousel-5.jpeg'
            ],
            tagline: {
                en: "Home of the best sushi in montreal",
                fr: "Home of the best sushi in montreal"
            },
            tags: {
                en: ["JAPANESE | SUSHI"],
                fr: ["JAPONAIS | SUSHI"]
            },
            description: {
                en: `Situated in the heart of the Plateau, we at Osuka specialize in bringing you a unique and authentic culinary experience.
                <br><br>
                From our nigiris to sashimis and futomakis, we only use the freshest ingredients to bring you unparalleled flavors.`,
                fr: `Situated in the heart of the Plateau, we at Osuka specialize in bringing you a unique and authentic culinary experience.
                <br><br>
                From our nigiris to sashimis and futomakis, we only use the freshest ingredients to bring you unparalleled flavors.`
            }
        };
        this.lali = {
            images: [
                '../../assets/images/lali/carousel/lali-carousel-1.jpeg',
                '../../assets/images/lali/carousel/lali-carousel-2.jpeg',
                '../../assets/images/lali/carousel/lali-carousel-3.jpeg',
                '../../assets/images/lali/carousel/lali-carousel-4.jpeg',
                '../../assets/images/lali/carousel/lali-carousel-5.jpeg'
            ],
            tagline: {
                en: "Top it off at Lali's",
                fr: "Couronnez le tout chez Lali"
            },
            tags: {
                en: ["HOT DOG | SANDWICH"],
                fr: ["HOT DOG | SANDWICH"]
            },
            description: {
                en: `Welcome to Lali's, where hot dogs and sandwiches are topped to perfection. Dive into our mouthwatering creations and enjoy the classic diner vibe right on Crescent street.
                <br><br>
                Get ready for deliciousness that hits the spot every time.`,
                fr: `Welcome to Lali's, where hot dogs and sandwiches are topped to perfection. Dive into our mouthwatering creations and enjoy the classic diner vibe right on Crescent street.
                <br><br>
                Get ready for deliciousness that hits the spot every time.`
            }
        };
        this.chezTaMere = {
            images: [
                '../../assets/images/chez-ta-mere/carousel/chez-ta-mere-carousel-1.jpeg',
                '../../assets/images/chez-ta-mere/carousel/chez-ta-mere-carousel-2.jpeg',
                '../../assets/images/chez-ta-mere/carousel/chez-ta-mere-carousel-3.jpeg',
                '../../assets/images/chez-ta-mere/carousel/chez-ta-mere-carousel-4.jpeg',
                '../../assets/images/chez-ta-mere/carousel/chez-ta-mere-carousel-5.jpeg'
            ],
            tagline: {
                en: "Des sandwichs gourmets qui font voyager",
                fr: "Des sandwichs gourmets qui font voyager"
            },
            tags: {
                en: ["SANDWICH"],
                fr: ["SANDWICH"]
            },
            description: {
                en: `Une sandwicherie gourmet & nostalgique! Vous souvenez-vous quand nos mamans préparaient les boîtes à lunch pour aller à l'école ? À l’image du Montréal cosmopolitan, CHEZ TA MÈRE propose des sandwichs inspirés des 5 continents.`,
                fr: `Une sandwicherie gourmet & nostalgique! Vous souvenez-vous quand nos mamans préparaient les boîtes à lunch pour aller à l'école ? À l’image du Montréal cosmopolitan, CHEZ TA MÈRE propose des sandwichs inspirés des 5 continents.`
            }
        };
        this.chatpata = {
            images: [
                '../../assets/images/chatpata/carousel/chatpata-carousel-1.jpeg',
                '../../assets/images/chatpata/carousel/chatpata-carousel-2.jpeg',
                '../../assets/images/chatpata/carousel/chatpata-carousel-3.jpeg',
                '../../assets/images/chatpata/carousel/chatpata-carousel-4.jpeg',
                '../../assets/images/chatpata/carousel/chatpata-carousel-5.jpeg'
            ],
            tagline: {
                en: "Take your palate into the streets of India!",
                fr: "Take your palate into the streets of India!"
            },
            tags: {
                en: ["INDO-CHINESE"],
                fr: ["INDO-CHINOIS"]
            },
            description: {
                en: `A simple spot offering Indo-Chinese cuisine with authentic flavours! Come and savour the flavours with an order of some of our delicious food that will leave your taste buds wanting more.`,
                fr: `A simple spot offering Indo-Chinese cuisine with authentic flavours! Come and savour the flavours with an order of some of our delicious food that will leave your taste buds wanting more.`
            }
        };
        this.friteAlors = {
            images: [
                '../../assets/images/frite-alors/carousel/frite-alors-carousel-1.jpeg',
                '../../assets/images/frite-alors/carousel/frite-alors-carousel-2.jpeg',
                '../../assets/images/frite-alors/carousel/frite-alors-carousel-3.jpeg',
                '../../assets/images/frite-alors/carousel/frite-alors-carousel-4.jpg',
                '../../assets/images/frite-alors/carousel/frite-alors-carousel-5.jpg'
            ],
            tagline: {
                en: "Belgian fries with a Québécois accent!",
                fr: "La vraie frite belge à l'accent québécois!"
            },
            tags: {
                en: ["BELGIAN"],
                fr: ["BELGE"]
            },
            description: {
                en: `Frite Alors! first opened its doors in 1991 in a small premises on Park Avenue in Montreal. Since then, they have delighted the city with their crispy Belgian fries with franchises in over 10 locations! <br><br>
                Not only do they have fries, but they also serve poutines, house-made sauces, burgers, and desserts in a relaxed family atmosphere.`,
                fr: `Frite Alors! first opened its doors in 1991 in a small premises on Park Avenue in Montreal. Since then, they have delighted the city with their crispy Belgian fries with franchises in over 10 locations! <br><br>
                Not only do they have fries, but they also serve poutines, house-made sauces, burgers, and desserts in a relaxed family atmosphere.`
            }
        };
        this.tibum = {
            images: [
                '../../assets/images/tibum/carousel/tibum-carousel-1.jpg',
                '../../assets/images/tibum/carousel/tibum-carousel-2.jpg',
                '../../assets/images/tibum/carousel/tibum-carousel-3.jpg',
                '../../assets/images/tibum/carousel/tibum-carousel-4.jpg',
                '../../assets/images/tibum/carousel/tibum-carousel-5.jpg'
            ],
            tagline: {
                en: "Thai fusion cuisine like you've never tasted before!",
                fr: "Cuisine fusion thaïlandaise comme vous ne l'avez jamais goûtée!"
            },
            tags: {
                en: ["THAI"],
                fr: ["THAÎ"]
            },
            description: {
                en: `Tibum is a Thai fusion restaurant and cocktail lounge serving delicious food and drinks in a fun and relaxed atmosphere.`,
                fr: `Tibum est un restaurant et un salon de cocktails thaïlandais servant de délicieux plats et boissons dans une atmosphère amusante et détendue.`
            }
        };
        this.thePerle = {
            images: [
                '../../assets/images/the-perle/carousel/the-perle-carousel-1.jpeg',
                '../../assets/images/the-perle/carousel/the-perle-carousel-2.jpeg',
                '../../assets/images/the-perle/carousel/the-perle-carousel-3.jpeg',
                '../../assets/images/the-perle/carousel/the-perle-carousel-4.jpeg',
                '../../assets/images/the-perle/carousel/the-perle-carousel-5.jpeg'
            ],
            tagline: {
                en: "Boba and banh-mi, a match made in heaven",
                fr: "Boba et banh-mi, un match fait au paradis"
            },
            tags: {
                en: ["BUBBLE TEA | VIETNAMESE"],
                fr: ["BUBBLE TEA | VIETNAMIEN"]
            },
            description: {
                en: `Thé Perlé is a small family owned restaurant that combines two of the most popular dishes for students: banh-mi and bubble tea!`,
                fr: `Thé Perlé est un petit restaurant familial qui combine deux des plats les plus populaires pour les étudiants: le banh-mi et le bubble tea!`
            }
        }
        this.gongCha = {
            images: [
                '../../assets/images/gong-cha/carousel/gong-cha-carousel-1.png',
                '../../assets/images/gong-cha/carousel/gong-cha-carousel-2.png',
                '../../assets/images/gong-cha/carousel/gong-cha-carousel-3.png',
            ],
            tagline: {
                en: "“To inspire the human spirit and create happiness.”",
                fr: "“To inspire the human spirit and create happiness.”"
            },
            tags: {
                en: ["BUBBLE TEA"],
                fr: ["BUBBLE TEA"]
            },
            description: {
                en: `“Gong cha” is a Chinese term for the act of offering the best tea to the Emperor. It represents teas and beverages of the highest and finest quality, fit for royalty.
                <br><br>
                Gong cha ensures that all drinks are served at their freshest by brewing new batches of tea and pearls every four hours. This commitment to excellence ensures that Gong cha is always brewing happiness wherever we serve our customers."`,
                fr: `“Gong cha” is a Chinese term for the act of offering the best tea to the Emperor. It represents teas and beverages of the highest and finest quality, fit for royalty.
                <br><br>
                Gong cha ensures that all drinks are served at their freshest by brewing new batches of tea and pearls every four hours. This commitment to excellence ensures that Gong cha is always brewing happiness wherever we serve our customers."`
            }
        }
        this.buffaloBill = {
            images: [
                '../../assets/images/buffalo-bill/carousel/buffalo-bill-carousel-1.jpeg',
                '../../assets/images/buffalo-bill/carousel/buffalo-bill-carousel-2.jpeg',
                '../../assets/images/buffalo-bill/carousel/buffalo-bill-carousel-3.jpeg',
                '../../assets/images/buffalo-bill/carousel/buffalo-bill-carousel-4.jpeg',
                '../../assets/images/buffalo-bill/carousel/buffalo-bill-carousel-5.jpeg'
            ],
            tagline: {
                // buffalo bill tagline, referencing buffalo bill and wild west
                en: "Best wings in the west",
                fr: "Les meilleures ailes de l'ouest"
            },
            tags: {
                en: ["WINGS"],
                fr: ["AILES"]
            },
            description: {
                // description that's vague and fast food-y
                en: "At Buffalo Bill's, we serve the best wings in the west. Come and enjoy our delicious wings, burgers, and poutines!",
                fr: "Chez Buffalo Bill's, nous servons les meilleures ailes de l'ouest. Venez déguster nos délicieuses ailes, burgers, et poutines!"
            }
        }
        this.souvlakiGrecPlus = {
            images: [
                '../../assets/images/souvlaki-grec-plus/carousel/souvlaki-grec-plus-carousel-1.png',
                '../../assets/images/souvlaki-grec-plus/carousel/souvlaki-grec-plus-carousel-2.png',
                '../../assets/images/souvlaki-grec-plus/carousel/souvlaki-grec-plus-carousel-3.png',
                '../../assets/images/souvlaki-grec-plus/carousel/souvlaki-grec-plus-carousel-4.png',
                '../../assets/images/souvlaki-grec-plus/carousel/souvlaki-grec-plus-carousel-5.png',
                '../../assets/images/souvlaki-grec-plus/carousel/souvlaki-grec-plus-carousel-6.png',
            ],
            tagline: {
                en: "Most delicious greek food in town",
                fr: "Most delicious greek food in town"
            },
            tags: {
                en: ["GREEK"],
                fr: ["GREEK"]
            },
            description: {
                en: `Come and enjoy a delicious meal with the family or have drinks and mezzes with friends. Quality and freshness are at the heart of Souvlaki Grec Plus. A vast selection of authentic Greek food awaits.`,
                fr: `Come and enjoy a delicious meal with the family or have drinks and mezzes with friends. Quality and freshness are at the heart of Souvlaki Grec Plus. A vast selection of authentic Greek food awaits.`
            }
        }
        this.pizzaSport = {
            images: [
                '../../assets/images/pizza-sport/carousel/pizza-sport-carousel-1.png',
                '../../assets/images/pizza-sport/carousel/pizza-sport-carousel-2.png',
                '../../assets/images/pizza-sport/carousel/pizza-sport-carousel-3.png',
                '../../assets/images/pizza-sport/carousel/pizza-sport-carousel-4.png',
                '../../assets/images/pizza-sport/carousel/pizza-sport-carousel-5.png'
            ],
            tagline: {
                en: "Where your tastebuds win the game!",
                fr: "Where your tastebuds win the game!"
            },
            tags: {
                en: ["PIZZA"],
                fr: ["PIZZA"]
            },
            description: {
                en: `Perfect for active people in search of exquisite flavours`,
                fr: `Parfaits pour les personnes actives en quête de saveurs exquises.`
            }
        }
        this.tsujiri = {
            images: [
                '../../assets/images/tsujiri/carousel/tsujiri-carousel-1.jpeg',
                '../../assets/images/tsujiri/carousel/tsujiri-carousel-2.jpeg',
                '../../assets/images/tsujiri/carousel/tsujiri-carousel-3.jpeg',
                '../../assets/images/tsujiri/carousel/tsujiri-carousel-4.jpeg',
                '../../assets/images/tsujiri/carousel/tsujiri-carousel-5.jpeg'
            ],
            tagline: {
                en: "Quality meets craftsmanship",
                fr: "Qualité rencontre l'artisanat"
            },
            tags: {
                en: ["MATCHA", "JAPANESE"],
                fr: ["MATCHA", "JAPONAIS"]
            },
            description: {
                en: `TSUJIRI was founded in 1860 by Riemon Tsuji who was renowned for his spirit of “YUWA”, meaning "continue to innovate and sustain the tradition".
                    In 2010, CHAHO, which means tea shop in Japanese, was first established outside of Japan to carry on our founder’s spirit and to serve fresh tea to our customers worldwide.`,
                fr: `TSUJIRI a été fondée à Kyoto (Japon) en 1860 par Riemon Tsuji, un maître du thé réputé pour son esprit de "YUWA" — afin de continuer à innover tout en préservant les traditions.
                    En 2010, notre première CHAHO (boutique de thé) en dehors du Japon a été ouverte pour perpétuer l'esprit de notre fondateur et servir du thé frais à nos clients dans le monde entier.`
            }
        }
        this.spartaChicken = {
            images: [
                '../../assets/images/sparta-chicken/carousel/sparta-chicken-carousel-1.png',
                '../../assets/images/sparta-chicken/carousel/sparta-chicken-carousel-2.png',
                '../../assets/images/sparta-chicken/carousel/sparta-chicken-carousel-3.png',
                '../../assets/images/sparta-chicken/carousel/sparta-chicken-carousel-4.png'
            ],
            tagline: {
                en: "\"The hottest grilled chicken in Montreal\"",
                fr: "\"Le poulet grillé le plus chaud de Montréal\""
            },
            tags: {
                en: ["GREEK"],
                fr: ["GREEK"]
            },
            description: {
                en: ``,
                fr: ``
            }
        }
        this.p23Dumplings = {
            images: [
                '../../assets/images/p23-dumplings/carousel/p23-dumplings-carousel-1.png',
                '../../assets/images/p23-dumplings/carousel/p23-dumplings-carousel-2.png',
                '../../assets/images/p23-dumplings/carousel/p23-dumplings-carousel-3.png',
                '../../assets/images/p23-dumplings/carousel/p23-dumplings-carousel-4.png',
                '../../assets/images/p23-dumplings/carousel/p23-dumplings-carousel-5.png',
                '../../assets/images/p23-dumplings/carousel/p23-dumplings-carousel-6.png',
                '../../assets/images/p23-dumplings/carousel/p23-dumplings-carousel-7.png'
            ],
            tagline: {
                en: "\"Let P23 Dumplings awaken your taste buds with Chinese authenticity, one bite at a time.\"",
                fr: "\"Laissez P23 Dumplings éveiller vos papilles à l'authenticité chinoise, une bouchée à la fois.\""
            },
            tags: {
                en: ["CHINESE"],
                fr: ["CHINOIS"]
            },
            description: {
                en: `"Discover the essence of authentic Chinese gastronomy at P23 Dumplings in Pointe-Saint-Charles.
                Savor our delectable handmade artisan dumplings, such as Xiajiao and Sumai, offering an
                unparalleled taste experience. Immerse yourself in a feast of flavors with our delicious
                and varied snacks. Located in the heart of Pointe-Saint-Charles, our Chinese restaurant invites you
                on an exquisite culinary journey. Take advantage of our fast delivery service to enjoy these
                delights at home, or take them for a gastronomic experience wherever you are. Let P23 Dumplings
                awaken your taste buds with Chinese authenticity, one bite at a time."`,
                fr: `"Découvrez l'essence de la gastronomie chinoise authentique au P23 Dumplings à Pointe-saint-charles.
                Savourez nos délectables boulettes artisanales faites à la main, telles que les Xiajiao et Sumai,
                offrant une expérience gustative inégalée. Plongez dans un festin de saveurs avec nos délicieuses
                collations variées. Situé au cœur de Pointe-saint-charles, notre restaurant chinois vous invite à un voyage
                culinaire exquis. Profitez de notre service de livraison rapide pour savourer ces délices chez vous,
                ou emportez-les pour une expérience gastronomique où que vous soyez.
                Laissez P23 Dumplings éveiller vos papilles à l'authenticité chinoise, une bouchée à la fois."`
            }
        }
        this.burgzCantine = {
            images: [
                '../../assets/images/burgz-cantine/carousel/burgz-cantine-carousel-1.jpeg',
                '../../assets/images/burgz-cantine/carousel/burgz-cantine-carousel-2.jpeg',
                '../../assets/images/burgz-cantine/carousel/burgz-cantine-carousel-3.jpeg',
                '../../assets/images/burgz-cantine/carousel/burgz-cantine-carousel-4.jpeg',
            ],
            tagline: {
                en: "The best of the caribbean, straight from Pointe-Saint-Charle",
                fr: "Le meilleur des caraïbes, directement de Pointe-Saint-Charle"
            },
            tags: {
                en: ["HAÏTIAN"],
                fr: ["HAÏTIEN"]
            },
            description: {
                en: `Burgz Cantine puts the spotlight on Haitian culinary tradition, food for the soul, as well as the musical culture of Little Burgundy, Jazz. Both harmonize together to create friendly and pleasant space for everyone to enjoy!`,
                fr: `Burgz Cantine met à l'honneur la tradition culinaire haïtienne, nourriture de l'âme, de même que la culture musicale de la Petite-Bourgogne, le Jazz. Tous deux s'harmonisent dans un espace convivial et agréable!`
            }
        }
        this.goplana = {
            images: [
                '../../assets/images/goplana/carousel/goplana-carousel-1.jpeg',
                '../../assets/images/goplana/carousel/goplana-carousel-2.jpeg',
                '../../assets/images/goplana/carousel/goplana-carousel-3.jpeg',
                '../../assets/images/goplana/carousel/goplana-carousel-4.jpeg',
                '../../assets/images/goplana/carousel/goplana-carousel-5.jpeg',
            ],
            tagline: {
                en: "Home is where the pierogies are",
                fr: "La maison est là où sont les pierogies"
            },
            tags: {
                en: ["POLISH"],
                fr: ["POLONAIS"]
            },
            description: {
                en: "A family-run business with an Eastern European menu, they offer the best pierogis in town. Whether you have a sweet tooth or a savory one, you'll find something to your liking among their excellent pastries, sandwiches, prepared foods, and desserts.",
                fr: "Une entreprise familiale avec un menu d'Europe de l'Est, ils offrent les meilleurs pierogis en ville. Que vous ayez une dent sucrée ou une dent salée, vous trouverez quelque chose à votre goût parmi leurs excellents pâtisseries, sandwichs, plats préparés et desserts."
            }
        }
        this.piriPiri = {
            images: [
                '../../assets/images/piri-piri/carousel/piri-piri-carousel-1.png',
                '../../assets/images/piri-piri/carousel/piri-piri-carousel-2.png',
                '../../assets/images/piri-piri/carousel/piri-piri-carousel-3.png',
                '../../assets/images/piri-piri/carousel/piri-piri-carousel-4.png',
                '../../assets/images/piri-piri/carousel/piri-piri-carousel-5.png',
            ],
            tagline: {
                en: "The rotisserie proud of its origins.",
                fr: "La rôtisserie fière de ses origines"
            },
            tags: {
                en: ["PORTUGESE"],
                fr: ["PORTUGAIS"]
            },
            description: {
                en: "Portuguese cuisine was formed through successive cultural influences. Departing for the spice route, sailors, circumnavigating Africa, arrived in India. On their way, they discovered the piri-piri pepper. Introduced in most of the national dishes, it confers to the Portuguese cuisine the current success that we know it. This is how at Piri Piri, sauces based on spices and peppers enrich your dishes. Taste them and let yourself be carried away by these incomparable flavors in the warm atmosphere of our rotisseries.",
                fr: "La cuisine portugaise s’est formée grâce aux influences culturelles successives. En partance pour la route des épices, les marins, contournant l’Afrique, arrivaient en Inde. C’est ainsi que sur leur route, ils découvrirent le piment piri-piri. Introduit dans la majeure partie des plats nationaux, il confère à la cuisine portugaise le succès actuel qu’on lui connaît. C’est ainsi que chez Piri Piri, les sauces à base d’épices et de piments enrichissent vos plats. Goûtez-y et laissez-vous transporter par ces inégalables saveurs dans l’atmosphère chaleureuse de nos rôtisseries."
            }
        }
        this.ukiyaIzakaya = {
            images: [
                '../../assets/images/ukiya-izakaya/carousel/ukiya-izakaya-carousel-1.png',
                '../../assets/images/ukiya-izakaya/carousel/ukiya-izakaya-carousel-2.png',
                '../../assets/images/ukiya-izakaya/carousel/ukiya-izakaya-carousel-3.png',
                '../../assets/images/ukiya-izakaya/carousel/ukiya-izakaya-carousel-4.png',
            ],
            tagline: {
                en: "",
                fr: ""
            },
            tags: {
                en: ["JAPANESE"],
                fr: ["JAPONAIS"]
            },
            description: {
                en: "Ukiya Izakaya is a cozy, modern hideaway offering sophisticated hot and cold Japanese tapas and fresh seasonal seafood every week. We also offer a wide selection of sake, Japanese beers and cocktails.",
                fr: "Ukiya Izakaya est un refuge confortable et moderne proposant des tapas japonais chaudes et froides sophistiquées et des fruits de mer frais de saison chaque semaine. Nous proposons également une large sélection de sakés, de bières japonaises et de cocktails."
            }
        }
        this.livSalades = {
            images: [
                '../../assets/images/liv-salades/carousel/liv-salades-carousel-1.png',
                '../../assets/images/liv-salades/carousel/liv-salades-carousel-2.png',
                '../../assets/images/liv-salades/carousel/liv-salades-carousel-3.png',
            ],
            tagline: {
                en: "Fresh, gourmet, and simply delicious",
                fr: ""
            },
            tags: {
                en: ["SALAD BAR"],
                fr: [""]
            },
            description: {
                en: "",
                fr: ""
            }
        }
        this.maisonDuDestin = {
            images: [
                '../../assets/images/maison-du-destin/carousel/maison-du-destin-carousel-1.png',
                '../../assets/images/maison-du-destin/carousel/maison-du-destin-carousel-2.png',
                '../../assets/images/maison-du-destin/carousel/maison-du-destin-carousel-3.png',
                '../../assets/images/maison-du-destin/carousel/maison-du-destin-carousel-4.png',
            ],
            tagline: {
                en: "Your gateway to an exceptional culinary journey",
                fr: "Votre passerelle vers un voyage culinaire exceptionnel"
            },
            tags: {
                en: ["CHINESE"],
                fr: ["CHINOIS"]
            },
            description: {
                en: "Discover our wide selection of Chinese delights, from delicious Dim Sum to a variety of stir-fries with unforgettable flavors. Our menu also offers treasures such as Mì Cháo Lẩu, steaming and fragrant bowls of noodles served with a variety of flavorful ingredients.",
                fr: "Découvrez notre large sélection de délices chinois, des délicieux Dim Sum à une variété de plats sautés aux saveurs inoubliables. Notre menu offre également des trésors tels que les Mì Cháo Lẩu, des bols de nouilles fumants et parfumés servis avec une variété d’ingrédients savoureux."
            }
        }
        this.flatBellyDeli = {
            images: [
                '../../assets/images/flat-belly-deli/carousel/flat-belly-deli-carousel-1.jpeg',
                '../../assets/images/flat-belly-deli/carousel/flat-belly-deli-carousel-2.jpeg',
                '../../assets/images/flat-belly-deli/carousel/flat-belly-deli-carousel-3.jpeg',
                '../../assets/images/flat-belly-deli/carousel/flat-belly-deli-carousel-4.jpeg',
            ],
            tagline: {
                en: "Halal smoked meat like no other",
                fr: "Viande fumée halal comme aucune autre"
            },
            tags: {
                en: ["SMOKED MEAT", "HALAL"],
                fr: ["VIANDE FUMÉE", "HALAL"]
            },
            description: {
                en: "Flat Belly Deli is a small local business that specializes in halal smoked meat. We offer a variety of smoked meat sandwiches, burgers, and poutines.",
                fr: "Flat Belly Deli est une petite entreprise locale spécialisée dans la viande fumée halal. Nous proposons une variété de sandwichs, de hamburgers et de poutines à la viande fumée."
            }
        }
        this.downtownDhaba = {
            images: [
                '../../assets/images/downtown-dhaba/carousel/downtown-dhaba-carousel-1.png',
                '../../assets/images/downtown-dhaba/carousel/downtown-dhaba-carousel-2.png',
                '../../assets/images/downtown-dhaba/carousel/downtown-dhaba-carousel-3.png',
            ],
            tagline: {
                en: "A taste of India like never before!",
                fr: ""
            },
            tags: {
                en: ["INDIAN"],
                fr: ["INDIEN"]
            },
            description: {
                en: "",
                fr: ""
            }
        }
        this.grillados = {
            images: [
                '../../assets/images/grillados/carousel/grillados-carousel-1.png',
                '../../assets/images/grillados/carousel/grillados-carousel-2.png',
                '../../assets/images/grillados/carousel/grillados-carousel-3.png',
            ],
            tagline: {
                en: "It all started with a craving...",
                fr: "Tout a commencé avec une envie..."
            },
            tags: {
                en: [""],
                fr: [""]
            },
            description: {
                en: "Grillado’s™ is a casual dining restaurant that focuses on grilling the very best Portuguese chicken with a South African twist. That twist is our variety of world famous sauces. Located in the heart of Montreal, it has quickly become known for its fun and engaging atmosphere, as well as its multitude of flavours that keep customers happy and coming back for more…",
                fr: "Grillado’s™ est un restaurant décontracté qui se concentre sur la grillade du meilleur poulet portugais avec une touche sud-africaine. Cette touche, c’est notre variété de sauces mondialement célèbres. Situé au cœur de Montréal, il est rapidement devenu réputé pour son ambiance amusante et engageante, ainsi que pour sa multitude de saveurs qui rendent les clients heureux et les incitent à revenir pour plus…"
            }
        }
    }

    // RestoPage TODO: Add carousel images, tagline, tags, and description object above.

    getRestaurantImages() {
        if (this.restaurant.slug === RestaurantSlug.Maharani) return this.maharani.images;
        else if (this.restaurant.slug === RestaurantSlug.Enchanteur) return this.enchanteur.images;
        else if (this.restaurant.slug === RestaurantSlug.Lyla) return this.lyla.images;
        else if (this.restaurant.slug === RestaurantSlug.Diolo) return this.diolo.images;
        else if (this.restaurant.slug === RestaurantSlug.MerRouge) return this.merRouge.images;
        else if (this.restaurant.slug === RestaurantSlug.Arahova) return this.arahova.images;
        else if (this.restaurant.slug === RestaurantSlug.Delicieux) return this.delicieux.images;
        else if (this.restaurant.slug === RestaurantSlug.Sham) return this.sham.images;
        else if (this.restaurant.slug === RestaurantSlug.Nakamichi) return this.nakamichi.images;
        else if (this.restaurant.slug === RestaurantSlug.Croqueteria) return this.croqueteria.images;
        else if (this.restaurant.slug === RestaurantSlug.PtitPlateau) return this.ptitPlateau.images;
        //else if (this.restaurant.slug === "sushi-plus-beaumont") return this.sushiPlusBeaumont.images;
        else if (this.restaurant.slug === RestaurantSlug.KojiSoupe) return this.kojiSoupe.images;
        else if (this.restaurant.slug === RestaurantSlug.LundisAuSoleil) return this.lundisAuSoleil.images;
        else if (this.restaurant.slug === RestaurantSlug.TincSet) return this.tincSet.images;
        else if (this.restaurant.slug === RestaurantSlug.FanfarePizza) return this.fanfarePizza.images;
        else if (this.restaurant.slug === RestaurantSlug.InochiExpress) return this.inochiExpress.images;
        else if (this.restaurant.slug === RestaurantSlug.Basha) return this.basha.images;
        else if (this.restaurant.slug === RestaurantSlug.Uluwatu) return this.uluwatu.images;
        else if (this.restaurant.slug === RestaurantSlug.ChezTauBay) return this.chezTauBay.images;
        else if (this.restaurant.slug === RestaurantSlug.Boustan) return this.boustan.images;
        else if (this.restaurant.slug === RestaurantSlug.ThaiExpressCdn) return this.thaiExpressCdn.images;
        else if (this.restaurant.slug === RestaurantSlug.JavaUCdn) return this.javaUCdn.images;
        else if (this.restaurant.slug === RestaurantSlug.McgillPizza) return this.mcgillPizza.images;
        else if (this.restaurant.slug === RestaurantSlug.Yuan) return this.yuan.images;
        else if (this.restaurant.slug === RestaurantSlug.Segreta) return this.segreta.images;
        else if (this.restaurant.slug === RestaurantSlug.OMLT) return this.omlt.images;
        else if (this.restaurant.slug === RestaurantSlug.QuesadaCdn) return this.quesadaCdn.images;
        else if (this.restaurant.slug === RestaurantSlug.OiseauxDePassage) return this.oiseauxDePassage.images;
        else if (this.restaurant.slug === RestaurantSlug.CafeVita) return this.cafeVita.images;
        else if (this.restaurant.slug === RestaurantSlug.BurgerBros) return this.burgerBros.images;
        else if (this.restaurant.slug === RestaurantSlug.Julians) return this.julians.images;
        else if (this.restaurant.slug === RestaurantSlug.Wallys) return this.wallys.images;
        else if (this.restaurant.slug === RestaurantSlug.SpiceBros) return this.spiceBros.images;
        else if (this.restaurant.slug === RestaurantSlug.SantaLucia) return this.santaLucia.images;
        else if (this.restaurant.slug === RestaurantSlug.Dakar) return this.dakar.images;
        else if (this.restaurant.slug === RestaurantSlug.oFour) return this.oFour.images;
        else if (this.restaurant.slug === RestaurantSlug.Karin) return this.karin.images;
        else if (this.restaurant.slug === RestaurantSlug.MarcheMarema) return this.marcheMarema.images;
        else if (this.restaurant.slug === RestaurantSlug.ElephantThai) return this.elephantThai.images;
        else if (this.restaurant.slug === RestaurantSlug.Sushiyo) return this.sushiyo.images;
        else if (this.restaurant.slug === RestaurantSlug.BoiteVegane) return this.boiteVegane.images;
        else if (this.restaurant.slug === RestaurantSlug.HeroBurgers) return this.heroBurgers.images;
        else if (this.restaurant.slug === RestaurantSlug.JrsCafe) return this.jrsCafe.images;
        else if (this.restaurant.slug === RestaurantSlug.CafeResonance) return this.cafeResonance.images;
        else if (this.restaurant.slug === RestaurantSlug.UbiSushi) return this.ubiSushi.images;
        else if (this.restaurant.slug === RestaurantSlug.Melrose) return this.melrose.images;
        else if (this.restaurant.slug === RestaurantSlug.ObsceneVegan) return this.obsceneVegan.images;
        else if (this.restaurant.slug === RestaurantSlug.LestersDeli) return this.lestersDeli.images;
        else if (this.restaurant.slug === RestaurantSlug.Barranco) return this.barranco.images;
        else if (this.restaurant.slug === RestaurantSlug.PetitSeoul) return this.petitSeoul.images;
        else if (this.restaurant.slug === RestaurantSlug.Hooters) return this.hooters.images;
        else if (this.restaurant.slug === RestaurantSlug.Darbar) return this.darbar.images;
        else if (this.restaurant.slug === RestaurantSlug.JardinPetros) return this.jardinPetros.images;
        else if (this.restaurant.slug === RestaurantSlug.ChezAnna) return this.chezAnna.images;
        else if (this.restaurant.slug === RestaurantSlug.Krood) return this.krood.images;
        else if (this.restaurant.slug === RestaurantSlug.HungarianCuisine) return this.hungarianCuisine.images;
        else if (this.restaurant.slug === RestaurantSlug.Maynard) return this.maynard.images;
        else if (this.restaurant.slug === RestaurantSlug.MFourBurritos) return this.mFourBurritos.images;
        else if (this.restaurant.slug === RestaurantSlug.Bocadillo) return this.bocadillo.images;
        else if (this.restaurant.slug === RestaurantSlug.LaurierBBQ) return this.laurierBbq.images;
        else if (this.restaurant.slug === RestaurantSlug.oFish) return this.oFish.images;
        else if (this.restaurant.slug === RestaurantSlug.SushiSama) return this.sushiSamaConcordia.images;
        else if (this.restaurant.slug === RestaurantSlug.PaniniStop) return this.paniniStop.images;
        else if (this.restaurant.slug === RestaurantSlug.PokeStationCdn) return this.pokeStationCdn.images;
        else if (this.restaurant.slug === RestaurantSlug.TeochewFoodie) return this.teochewFoodie.images;
        else if (this.restaurant.slug === RestaurantSlug.Tapigo) return this.tapigo.images;
        else if (this.restaurant.slug === RestaurantSlug.BrigadePizza) return this.brigadePizza.images;
        else if (this.restaurant.slug === RestaurantSlug.AlAmine) return this.alAmine.images;
        else if (this.restaurant.slug === RestaurantSlug.BernieBeigne) return this.bernieBeigne.images;
        else if (this.restaurant.slug === RestaurantSlug.Cacao70) return this.cacao70.images;
        else if (this.restaurant.slug === RestaurantSlug.TacosNation) return this.tacosNation.images;
        else if (this.restaurant.slug === RestaurantSlug.PoissonnerieSherbrooke) return this.poissonnerieSherbrooke.images;
        else if (this.restaurant.slug === RestaurantSlug.Mintar) return this.mintar.images;
        else if (this.restaurant.slug === RestaurantSlug.AuxMerveilleuxDeFred) return this.auxmerveilleuxdefred.images;
        else if (this.restaurant.slug === RestaurantSlug.LaToile) return this.laToile.images;
        else if (this.restaurant.slug === RestaurantSlug.SepLai) return this.sepLai.images;
        else if (this.restaurant.slug === RestaurantSlug.BistroDeLaCite) return this.bistroDeLaCite.images;
        else if (this.restaurant.slug === RestaurantSlug.Singhs) return this.singhs.images;
        else if (this.restaurant.slug === RestaurantSlug.ChaiTeaLounge) return this.chaiTeaLounge.images;
        else if (this.restaurant.slug === RestaurantSlug.LaCroissanterieFigaro) return this.laCroissanterieFigaro.images;
        else if (this.restaurant.slug === RestaurantSlug.LaFabriqueDeBagel) return this.laFabriqueDeBagel.images;
        else if (this.restaurant.slug === RestaurantSlug.StudentTastyBiryani) return this.studentTastyBiryani.images;
        else if (this.restaurant.slug === RestaurantSlug.OsukaSushi) return this.osukaSushi.images;
        else if (this.restaurant.slug === RestaurantSlug.Lalis) return this.lali.images;
        else if (this.restaurant.slug === RestaurantSlug.ChezTaMere) return this.chezTaMere.images;
        else if (this.restaurant.slug === RestaurantSlug.Chatpata) return this.chatpata.images;
        else if (this.restaurant.slug === RestaurantSlug.FriteAlors) return this.friteAlors.images;
        else if (this.restaurant.slug === RestaurantSlug.Tibum) return this.tibum.images;
        else if (this.restaurant.slug === RestaurantSlug.ThePerle) return this.thePerle.images;
        else if (this.restaurant.slug === RestaurantSlug.Gongcha) return this.gongCha.images;
        else if (this.restaurant.slug === RestaurantSlug.BuffaloBill) return this.buffaloBill.images;
        else if (this.restaurant.slug === RestaurantSlug.SouvlakiGrecPlus) return this.souvlakiGrecPlus.images;
        else if (this.restaurant.slug === RestaurantSlug.PizzaSport) return this.pizzaSport.images;
        else if (this.restaurant.slug === RestaurantSlug.Tsujiri) return this.tsujiri.images;
        else if (this.restaurant.slug === RestaurantSlug.SpartaChicken) return this.spartaChicken.images;
        else if (this.restaurant.slug === RestaurantSlug.P23Dumplings) return this.p23Dumplings.images;
        else if (this.restaurant.slug === RestaurantSlug.BurgzCantine) return this.burgzCantine.images;
        else if (this.restaurant.slug === RestaurantSlug.Goplana) return this.goplana.images;
        else if (this.restaurant.slug === RestaurantSlug.PiriPiri) return this.piriPiri.images;
        else if (this.restaurant.slug === RestaurantSlug.UkiyaIzakaya) return this.ukiyaIzakaya.images;
        else if (this.restaurant.slug === RestaurantSlug.LivSalades) return this.livSalades.images;
        else if (this.restaurant.slug === RestaurantSlug.MaisonDuDestin) return this.maisonDuDestin.images;
        else if (this.restaurant.slug === RestaurantSlug.FlatBellyDeli) return this.flatBellyDeli.images;
        else if (this.restaurant.slug === RestaurantSlug.DowntownDhaba) return this.downtownDhaba.images;
        else if (this.restaurant.slug === RestaurantSlug.Grillados) return this.grillados.images;
        else return [];
    }

    // RestoPage TODO: add restaurant case in getRestaurantImages() above.

    getRestaurantItem(item) {
        if (this.restaurant.slug === RestaurantSlug.Maharani) return this.maharani[item][this.translate.getActiveLang()];
        else if (this.restaurant.slug === RestaurantSlug.Enchanteur) return this.enchanteur[item][this.translate.getActiveLang()];
        else if (this.restaurant.slug === RestaurantSlug.Lyla) return this.lyla[item][this.translate.getActiveLang()];
        else if (this.restaurant.slug === RestaurantSlug.Diolo) return this.diolo[item][this.translate.getActiveLang()];
        else if (this.restaurant.slug === RestaurantSlug.MerRouge) return this.merRouge[item][this.translate.getActiveLang()];
        else if (this.restaurant.slug === RestaurantSlug.Arahova) return this.arahova[item][this.translate.getActiveLang()];
        else if (this.restaurant.slug === RestaurantSlug.Delicieux) return this.delicieux[item][this.translate.getActiveLang()];
        else if (this.restaurant.slug === RestaurantSlug.Sham) return this.sham[item][this.translate.getActiveLang()];
        else if (this.restaurant.slug === RestaurantSlug.Nakamichi) return this.nakamichi[item][this.translate.getActiveLang()];
        else if (this.restaurant.slug === RestaurantSlug.PtitPlateau) return this.ptitPlateau[item][this.translate.getActiveLang()];
        //else if (this.restaurant.slug === "sushi-plus-beaumont") return this.sushiPlusBeaumont[item][this.translate.getActiveLang()];
        else if (this.restaurant.slug === RestaurantSlug.KojiSoupe) return this.kojiSoupe[item][this.translate.getActiveLang()];
        else if (this.restaurant.slug === RestaurantSlug.LundisAuSoleil) return this.lundisAuSoleil[item][this.translate.getActiveLang()];
        else if (this.restaurant.slug === RestaurantSlug.TincSet) return this.tincSet[item][this.translate.getActiveLang()];
        else if (this.restaurant.slug === RestaurantSlug.FanfarePizza) return this.fanfarePizza[item][this.translate.getActiveLang()];
        else if (this.restaurant.slug === RestaurantSlug.InochiExpress) return this.inochiExpress[item][this.translate.getActiveLang()];
        else if (this.restaurant.slug === RestaurantSlug.Basha) return this.basha[item][this.translate.getActiveLang()];
        else if (this.restaurant.slug === RestaurantSlug.Uluwatu) return this.uluwatu[item][this.translate.getActiveLang()];
        else if (this.restaurant.slug === RestaurantSlug.Croqueteria) return this.croqueteria[item][this.translate.getActiveLang()];
        else if (this.restaurant.slug === RestaurantSlug.ChezTauBay) return this.chezTauBay[item][this.translate.getActiveLang()];
        else if (this.restaurant.slug === RestaurantSlug.Boustan) return this.boustan[item][this.translate.getActiveLang()];
        else if (this.restaurant.slug === RestaurantSlug.ThaiExpressCdn) return this.thaiExpressCdn[item][this.translate.getActiveLang()];
        else if (this.restaurant.slug === RestaurantSlug.JavaUCdn) return this.javaUCdn[item][this.translate.getActiveLang()];
        else if (this.restaurant.slug === RestaurantSlug.McgillPizza) return this.mcgillPizza[item][this.translate.getActiveLang()];
        else if (this.restaurant.slug === RestaurantSlug.Yuan) return this.yuan[item][this.translate.getActiveLang()];
        else if (this.restaurant.slug === RestaurantSlug.Segreta) return this.segreta[item][this.translate.getActiveLang()];
        else if (this.restaurant.slug === RestaurantSlug.OMLT) return this.omlt[item][this.translate.getActiveLang()];
        else if (this.restaurant.slug === RestaurantSlug.QuesadaCdn) return this.quesadaCdn[item][this.translate.getActiveLang()];
        else if (this.restaurant.slug === RestaurantSlug.OiseauxDePassage) return this.oiseauxDePassage[item][this.translate.getActiveLang()];
        else if (this.restaurant.slug === RestaurantSlug.CafeVita) return this.cafeVita[item][this.translate.getActiveLang()];
        else if (this.restaurant.slug === RestaurantSlug.BurgerBros) return this.burgerBros[item][this.translate.getActiveLang()];
        else if (this.restaurant.slug === RestaurantSlug.Julians) return this.julians[item][this.translate.getActiveLang()];
        else if (this.restaurant.slug === RestaurantSlug.Wallys) return this.wallys[item][this.translate.getActiveLang()];
        else if (this.restaurant.slug === RestaurantSlug.SpiceBros) return this.spiceBros[item][this.translate.getActiveLang()];
        else if (this.restaurant.slug === RestaurantSlug.SantaLucia) return this.santaLucia[item][this.translate.getActiveLang()];
        else if (this.restaurant.slug === RestaurantSlug.Dakar) return this.dakar[item][this.translate.getActiveLang()];
        else if (this.restaurant.slug === RestaurantSlug.oFour) return this.oFour[item][this.translate.getActiveLang()];
        else if (this.restaurant.slug === RestaurantSlug.Karin) return this.karin[item][this.translate.getActiveLang()];
        else if (this.restaurant.slug === RestaurantSlug.MarcheMarema) return this.marcheMarema[item][this.translate.getActiveLang()];
        else if (this.restaurant.slug === RestaurantSlug.ElephantThai) return this.elephantThai[item][this.translate.getActiveLang()];
        else if (this.restaurant.slug === RestaurantSlug.Sushiyo) return this.sushiyo[item][this.translate.getActiveLang()];
        else if (this.restaurant.slug === RestaurantSlug.BoiteVegane) return this.boiteVegane[item][this.translate.getActiveLang()];
        else if (this.restaurant.slug === RestaurantSlug.HeroBurgers) return this.heroBurgers[item][this.translate.getActiveLang()];
        else if (this.restaurant.slug === RestaurantSlug.JrsCafe) return this.jrsCafe[item][this.translate.getActiveLang()];
        else if (this.restaurant.slug === RestaurantSlug.CafeResonance) return this.cafeResonance[item][this.translate.getActiveLang()];
        else if (this.restaurant.slug === RestaurantSlug.UbiSushi) return this.ubiSushi[item][this.translate.getActiveLang()];
        else if (this.restaurant.slug === RestaurantSlug.Melrose) return this.melrose[item][this.translate.getActiveLang()];
        else if (this.restaurant.slug === RestaurantSlug.ObsceneVegan) return this.obsceneVegan[item][this.translate.getActiveLang()];
        else if (this.restaurant.slug === RestaurantSlug.LestersDeli) return this.lestersDeli[item][this.translate.getActiveLang()];
        else if (this.restaurant.slug === RestaurantSlug.Barranco) return this.barranco[item][this.translate.getActiveLang()];
        else if (this.restaurant.slug === RestaurantSlug.PetitSeoul) return this.petitSeoul[item][this.translate.getActiveLang()];
        else if (this.restaurant.slug === RestaurantSlug.Hooters) return this.hooters[item][this.translate.getActiveLang()];
        else if (this.restaurant.slug === RestaurantSlug.Darbar) return this.darbar[item][this.translate.getActiveLang()];
        else if (this.restaurant.slug === RestaurantSlug.JardinPetros) return this.jardinPetros[item][this.translate.getActiveLang()];
        else if (this.restaurant.slug === RestaurantSlug.ChezAnna) return this.chezAnna[item][this.translate.getActiveLang()];
        else if (this.restaurant.slug === RestaurantSlug.Krood) return this.krood[item][this.translate.getActiveLang()];
        else if (this.restaurant.slug === RestaurantSlug.HungarianCuisine) return this.hungarianCuisine[item][this.translate.getActiveLang()];
        else if (this.restaurant.slug === RestaurantSlug.Maynard) return this.maynard[item][this.translate.getActiveLang()];
        else if (this.restaurant.slug === RestaurantSlug.MFourBurritos) return this.mFourBurritos[item][this.translate.getActiveLang()];
        else if (this.restaurant.slug === RestaurantSlug.Bocadillo) return this.bocadillo[item][this.translate.getActiveLang()];
        else if (this.restaurant.slug === RestaurantSlug.LaurierBBQ) return this.laurierBbq[item][this.translate.getActiveLang()];
        else if (this.restaurant.slug === RestaurantSlug.oFish) return this.oFish[item][this.translate.getActiveLang()];
        else if (this.restaurant.slug === RestaurantSlug.SushiSama) return this.sushiSamaConcordia[item][this.translate.getActiveLang()];
        else if (this.restaurant.slug === RestaurantSlug.PaniniStop) return this.paniniStop[item][this.translate.getActiveLang()];
        else if (this.restaurant.slug === RestaurantSlug.PokeStationCdn) return this.pokeStationCdn[item][this.translate.getActiveLang()];
        else if (this.restaurant.slug === RestaurantSlug.TeochewFoodie) return this.teochewFoodie[item][this.translate.getActiveLang()];
        else if (this.restaurant.slug === RestaurantSlug.Tapigo) return this.tapigo[item][this.translate.getActiveLang()];
        else if (this.restaurant.slug === RestaurantSlug.BrigadePizza) return this.brigadePizza[item][this.translate.getActiveLang()];
        else if (this.restaurant.slug === RestaurantSlug.AlAmine) return this.alAmine[item][this.translate.getActiveLang()];
        else if (this.restaurant.slug === RestaurantSlug.BernieBeigne) return this.bernieBeigne[item][this.translate.getActiveLang()];
        else if (this.restaurant.slug === RestaurantSlug.Cacao70) return this.cacao70[item][this.translate.getActiveLang()];
        else if (this.restaurant.slug === RestaurantSlug.TacosNation) return this.tacosNation[item][this.translate.getActiveLang()];
        else if (this.restaurant.slug === RestaurantSlug.PoissonnerieSherbrooke) return this.poissonnerieSherbrooke[item][this.translate.getActiveLang()];
        else if (this.restaurant.slug === RestaurantSlug.Mintar) return this.mintar[item][this.translate.getActiveLang()];
        else if (this.restaurant.slug === RestaurantSlug.AuxMerveilleuxDeFred) return this.auxmerveilleuxdefred[item][this.translate.getActiveLang()];
        else if (this.restaurant.slug === RestaurantSlug.LaToile) return this.laToile[item][this.translate.getActiveLang()];
        else if (this.restaurant.slug === RestaurantSlug.SepLai) return this.sepLai[item][this.translate.getActiveLang()];
        else if (this.restaurant.slug === RestaurantSlug.BistroDeLaCite) return this.bistroDeLaCite[item][this.translate.getActiveLang()];
        else if (this.restaurant.slug === RestaurantSlug.Singhs) return this.singhs[item][this.translate.getActiveLang()];
        else if (this.restaurant.slug === RestaurantSlug.ChaiTeaLounge) return this.chaiTeaLounge[item][this.translate.getActiveLang()];
        else if (this.restaurant.slug === RestaurantSlug.LaCroissanterieFigaro) return this.laCroissanterieFigaro[item][this.translate.getActiveLang()];
        else if (this.restaurant.slug === RestaurantSlug.LaFabriqueDeBagel) return this.laFabriqueDeBagel[item][this.translate.getActiveLang()];
        else if (this.restaurant.slug === RestaurantSlug.StudentTastyBiryani) return this.studentTastyBiryani[item][this.translate.getActiveLang()];
        else if (this.restaurant.slug === RestaurantSlug.OsukaSushi) return this.osukaSushi[item][this.translate.getActiveLang()];
        else if (this.restaurant.slug === RestaurantSlug.Lalis) return this.lali[item][this.translate.getActiveLang()];
        else if (this.restaurant.slug === RestaurantSlug.ChezTaMere) return this.chezTaMere[item][this.translate.getActiveLang()];
        else if (this.restaurant.slug === RestaurantSlug.Chatpata) return this.chatpata[item][this.translate.getActiveLang()];
        else if (this.restaurant.slug === RestaurantSlug.FriteAlors) return this.friteAlors[item][this.translate.getActiveLang()];
        else if (this.restaurant.slug === RestaurantSlug.Tibum) return this.tibum[item][this.translate.getActiveLang()];
        else if (this.restaurant.slug === RestaurantSlug.ThePerle) return this.thePerle[item][this.translate.getActiveLang()];
        else if (this.restaurant.slug === RestaurantSlug.Gongcha) return this.gongCha[item][this.translate.getActiveLang()];
        else if (this.restaurant.slug === RestaurantSlug.BuffaloBill) return this.buffaloBill[item][this.translate.getActiveLang()];
        else if (this.restaurant.slug === RestaurantSlug.SouvlakiGrecPlus) return this.souvlakiGrecPlus[item][this.translate.getActiveLang()];
        else if (this.restaurant.slug === RestaurantSlug.PizzaSport) return this.pizzaSport[item][this.translate.getActiveLang()];
        else if (this.restaurant.slug === RestaurantSlug.Tsujiri) return this.tsujiri[item][this.translate.getActiveLang()];
        else if (this.restaurant.slug === RestaurantSlug.SpartaChicken) return this.spartaChicken[item][this.translate.getActiveLang()];
        else if (this.restaurant.slug === RestaurantSlug.P23Dumplings) return this.p23Dumplings[item][this.translate.getActiveLang()];
        else if (this.restaurant.slug === RestaurantSlug.BurgzCantine) return this.burgzCantine[item][this.translate.getActiveLang()];
        else if (this.restaurant.slug === RestaurantSlug.Goplana) return this.goplana[item][this.translate.getActiveLang()];
        else if (this.restaurant.slug === RestaurantSlug.PiriPiri) return this.piriPiri[item][this.translate.getActiveLang()];
        else if (this.restaurant.slug === RestaurantSlug.UkiyaIzakaya) return this.ukiyaIzakaya[item][this.translate.getActiveLang()];
        else if (this.restaurant.slug === RestaurantSlug.LivSalades) return this.livSalades[item][this.translate.getActiveLang()];
        else if (this.restaurant.slug === RestaurantSlug.MaisonDuDestin) return this.maisonDuDestin[item][this.translate.getActiveLang()];
        else if (this.restaurant.slug === RestaurantSlug.FlatBellyDeli) return this.flatBellyDeli[item][this.translate.getActiveLang()];
        else if (this.restaurant.slug === RestaurantSlug.DowntownDhaba) return this.downtownDhaba[item][this.translate.getActiveLang()];
        else if (this.restaurant.slug === RestaurantSlug.Grillados) return this.grillados[item][this.translate.getActiveLang()];
        else return "";
    }

    // RestoPage TODO: add restaurant case in getRestaurantItem() above.

    togglePaused() {
        if (this.paused) {
            this.carousel.cycle();
        } else {
            this.carousel.pause();
        }
        this.paused = !this.paused;
    }

    onSlide(slideEvent: NgbSlideEvent) {
        if (this.unpauseOnArrow && slideEvent.paused &&
            (slideEvent.source === NgbSlideEventSource.ARROW_LEFT || slideEvent.source === NgbSlideEventSource.ARROW_RIGHT)) {
            this.togglePaused();
        }
        if (this.pauseOnIndicator && !slideEvent.paused && slideEvent.source === NgbSlideEventSource.INDICATOR) {
            this.togglePaused();
        }
    }
}
