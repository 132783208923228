import { Injectable } from '@angular/core';
import Dinero from 'dinero.js';
import { BehaviorSubject, map, Observable, tap } from 'rxjs';
import { GiftCard } from 'src/app/core/models/gift-card.model';
import { TranslatedTextBasic } from '../models';
import { GiftCardPurchase } from '../models/purchase/gift-card-purchase.model';
import { BaseService } from './base.service';

export interface GiftCardPurchaseData {
	purchaseId?: string,
	value: Dinero.Dinero,
	recipient?: {
		name?: string,
		email?: string
	}
	title?: TranslatedTextBasic,
	message?: TranslatedTextBasic,
	theme?: string
}
@Injectable({
	providedIn: 'root'
})
export class GiftCardService {

	constructor(private baseService: BaseService) { }

	private entity: string = 'gift-cards';

	previewGiftCard(code: String): Observable<GiftCard> {
		return this.baseService.list(this.entity, { code })
			.pipe(map(giftCard => new GiftCard().deserialize(giftCard)));
	}

	claim(code: String, title: String, lang: string): Observable<GiftCard> {
		let titleObj = { en: '', fr: '' };
		titleObj[lang] = title;
		return this.baseService.create(this.entity + '/' + code, { title: titleObj })
			.pipe(map(giftCard => new GiftCard().deserialize(giftCard)))
	}

	listGiftCards(): Observable<GiftCard[]> {
		return this.baseService.list(this.entity)
			.pipe(map(giftCards => giftCards.map((giftCard) => new GiftCard().deserialize(giftCard))));
	}

	initializePurchase(data: GiftCardPurchaseData) {
		return this.baseService.create(this.entity, data)
			.pipe(map(res => {
				return { ...res, purchase: new GiftCardPurchase().deserialize(res.purchase) }
			}));
	}
}
