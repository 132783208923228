<div class="promotions-followup-container">
    <div class="radish-logo-container">
        <img class="radish-logo" src="../../../assets/images/radish-logos/Radish-Logo-BlackText-Large.png" />
    </div>
    <div class="title">{{ 'promotions.preparingLemonade' | transloco }}</div>
    <div class="order-info"> {{ 'checkout.yourOrder' | transloco }} <strong># {{orderNumber}}</strong></div>
    <button *ngIf="showMapButton" class="form-submit-button" type="button" (click)="getLocation()" [disabled]="isLoading">
        <div *ngIf="!isLoading">{{ 'promotions.turnGPS' | transloco }}</div>
        <div *ngIf="isLoading" class="spinner-border spinner-border-sm" role="status">
            <span class="sr-only">{{ 'common.loading' | transloco }}</span>
        </div>
    </button>
    <div *ngIf="showMap" class="map-wrapper">
        <google-map [center]="{ lat: lat, lng: long }" [zoom]="15" height="100%" width="100%" [options]="options">
            <map-marker [position]="{lat: lat, lng: long}"></map-marker>
            <map-circle [center]="{lat: lat, lng: long}" [radius]="30" 
            [options]="{ visible: false, strokeWeight: 0 }"></map-circle>
        </google-map>
    </div>
    <div class="button-wrapper">
        <button type="button" class="language-button" *ngIf="translate.getActiveLang() == 'en'" (click)="useLanguage('fr')"> FR </button>
        <button type="button" class="language-button" *ngIf="translate.getActiveLang() == 'fr'" (click)="useLanguage('en')"> EN </button>
    </div>
</div>