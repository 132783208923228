<nav class="category-nav" #nav
    [ngClass]="{
        'nav-left-scrollable': leftScrollable,
        'nav-right-scrollable': rightScrollable,
        'nav-left-and-right-scrollable': leftAndRightScrollable
    }">

    <!-- Categories Menu -->
    <div class="expand-icon-container" (click)="onClickExpandSections()" >
        <img class="expand-icon" src="../../../../../assets/graphics/list-text.svg" alt="">
    </div>

    <!-- Category Items -->
    <ul id="navbar-sections" class="category-list" (wheel)="onScroll()" #sectionsContainer>
        <li class="category-list-item" *ngFor="let item of items" (click)="onSelected(item)" 
            [ngClass]="selectedItem.id == item.id ? 'selected' : ''">
                {{ item.title }}
        </li>
    </ul>

    <!-- Arrows -->
    <div *ngIf="rightScrollable" class="arrow-right"
        (click)="onClickScrollRight()"
        appClickStopPropagation>
        <i class="fa fa-chevron-right"></i>
    </div>
    <div *ngIf="leftScrollable" class="arrow-left"
        (click)="onClickScrollLeft()"
        appClickStopPropagation>
        <i class="fa fa-chevron-left"></i>
    </div>
</nav>

<!-- Mini Menu -->
<nav class="mini-menu" *ngIf="showMenu" (clickOutside)="showMenu=false"     
    [delayClickOutsideInit]="true" [@fadeInOut]="showMenu ? 'in' : 'out'">
    <ul class="mini-menu-list">
        <li class="mini-menu-item" *ngFor="let item of items" 
            (click)="onSelected(item)" 
            [ngClass]="selectedItem.id == item.id ? 'selected' : ''" >
            {{ item.title }}
        </li>
    </ul>
</nav>