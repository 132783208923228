import { Pipe, PipeTransform } from '@angular/core';
import { Address } from 'src/app/core/models';

@Pipe({ name: 'address' })

export class AddressPipe implements PipeTransform {

  transform(address: Address, format: string = 'full'): string {
    if (address == null || address !== address) return null;

    if (format == 'short') return (address.line1 ? address.line1 : '');
    else if (format == 'standard') return address.line1 + ', ' + address.city + ', ' + address.province + ', ' + address.country;
    else if (format == 'full') return (
      (address.apt ? address.apt + ' - ' : '') +
      (address.line1 ? address.line1 + ',' : '') +
      (address.line2 ? address.line2 : '') +
      (address.city ? (true ? '\n' : ', ') + address.city : '') +
      (address.province ? ', ' + address.province : '') +
      (address.postal ? ', ' + address.postal : '')
    );
  }
}

// enum AddressFormatEnum {
//   Short = 'short',
//   Standard = 'standard'
// }

    // parseAddress(): string {
    //     // if (this.currentAddress)
    //     //     return this.addressService.prettyPrintAddressShort(this.currentAddress);
    //     // else {
    //     //     if (this.translate.getActiveLang() == 'fr') return 'Entrez votre adresse';
    //     //     else return 'Enter your address';
    //     // }
    // }