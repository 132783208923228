import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { RestaurantService } from '../../../../core/services/restaurant.service';
import { ActivatedRoute } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxFileDropEntry, FileSystemFileEntry } from 'ngx-file-drop';
import { Restaurant } from 'src/app/core/models';
import { first } from 'rxjs/operators';
import { TranslocoService } from '@ngneat/transloco';
import { ErrorType } from 'src/app/core/enums/error.enum';

@Component({
	selector: 'app-restaurant-reviews-create',
	templateUrl: './restaurant-reviews-create.component.html',
	styleUrls: ['./restaurant-reviews-create.component.css'],
})
export class RestaurantReviewsCreateComponent implements OnInit {
	@Input() slug;
	@Input() restaurant;

	rating: number = 0;
	private hoverRating: number = 0;
	private readonly filledStarImagePath =
		'../../../../assets/images/icons/star-full.svg';
	private readonly unfilledStarImagePath =
		'../../../../assets/images/icons/star-empty.svg';
	createReviewForm: UntypedFormGroup;

	currentStep: string;
	imageSources = [];
	numImagesLoading: number = 0;
	images: {
		file: File,
		description: string,
		source: any
	}[] = [];

	acceptedFileTypes: string[] = ['image/png', 'image/jpg', 'image/jpeg'];
	loading: boolean = false;
	maxNumReviewPhotos: number = 5;

	error: ErrorType = null;

	constructor(
		private formBuilder: UntypedFormBuilder,
		private restaurantService: RestaurantService,
		public activeModal: NgbActiveModal,
		public translate: TranslocoService
	) {
		this.createReviewForm = formBuilder.group({
			reviewTitle: [''],
			reviewText: [''],
		});
	}

	ngOnInit(): void {
		this.currentStep = 'write';
	}

	setRating(rating: number): void {
		this.rating = rating;
	}

	getStar(n: number): string {
		return this.hoverRating >= n
			? this.filledStarImagePath
			: this.unfilledStarImagePath;
	}

	mouseEnterStar(star: number) {
		this.hoverRating = star;
	}

	mouseLeaveStar(): void {
		this.hoverRating = this.rating;
	}

	onSubmit(data): void {
		this.loading = true;
		this.error = null;
		const formData: FormData = new FormData();

		formData.append('lang', 'en');
		formData.append('title', data.reviewTitle);
		formData.append('rating', String(this.rating));
		formData.append('text', data.reviewText);

		var imagesArray = this.images.map(image => image.description);
		for (var i = 0; i < imagesArray.length; i++) {
			formData.append('descriptions', imagesArray[i]);
		}

		for (let file of this.images.map(image => image.file)) {
			formData.append('photos', file, file.name)
		}

		this.restaurantService.createReviewWithPhotos(this.slug, formData)
			.pipe(first())
			.subscribe({
				next: _ => {
					this.activeModal.close(true);
					this.loading = false;
				},
				error: (err) => {
					this.error = err.error?.error as ErrorType;
					this.loading = false
				}
			})
	}

	clickUploadPhotoButton() {
		this.currentStep = 'upload';
	}

	imageLoaded() {
		this.numImagesLoading--;
	}

	generateImageSource(file: File) {
		const reader = new FileReader();
		reader.onload = (e) => {
			this.images.push({
				file,
				description: "",
				source: reader.result
			})
		};
		reader.readAsDataURL(file);
	}

	public dropped(files: NgxFileDropEntry[]) {
		this.numImagesLoading = files.length;

		for (const droppedFile of files) {
			if (droppedFile.fileEntry.isFile) {
				const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
				fileEntry.file((file: File) => {
					if (this.acceptedFileTypes.includes(file.type))
						this.generateImageSource(file);
					else this.numImagesLoading--;
				});
			}
		}
	}

	deletePhoto(index) {
		this.images.splice(index, 1);
	}

	uploadClicked() {
		this.currentStep = 'write';
	}

	cancelClicked() {
		this.images = [];
		this.currentStep = 'write';
	}

	descriptionChanged(index: number, value: string) {
		this.images[index].description = value;
	}
}
