import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslocoService } from '@ngneat/transloco';
import { MenuSection } from 'src/app/core/models';

@Component({
    selector: 'app-menu-sections-list',
    templateUrl: './menu-sections-list.component.html',
    styleUrls: ['./menu-sections-list.component.css']
})
export class MenuSectionsListComponent implements OnInit {

    @Input() sections: MenuSection[] = []

    constructor(
        public translate: TranslocoService,
        public activeModal: NgbActiveModal) { }

    ngOnInit(): void {
    }

}
