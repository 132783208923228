import { Component, OnInit, EventEmitter, Output, Input, HostListener, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { UserService } from '../../core/services/user.service';

import { Restaurant, Bag, Order, Menu } from '../../core/models';
import { combineLatest, filter, Observable } from 'rxjs';
import { MainService } from '../../core/services/main.service';
import { ConfigService } from '../../core/services/config.service';


@Component({
    selector: 'app-bag',
    templateUrl: './bag.component.html',
    styleUrls: ['./bag.component.css'],
})
export class BagComponent implements OnInit {

    @Input('showBag') showBag: boolean;
    @Output() showBagChange = new EventEmitter<boolean>();

    @Input('clickIcon') clickIcon: boolean;
    @Output('resetIcon') resetIcon = new EventEmitter<{}>();

    // @HostListener('document: click', ['$event', '$event.target'])

    bag$: Observable<Bag>;
    order$: Observable<Order>;
    scheduledDate$: Observable<Date>;

    restaurant$: Observable<Restaurant>;
    menu$: Observable<Menu>;

    scheduledDate: Date;
    isMenuOpenAtScheduledDate: boolean = true;

    constructor(
        private router: Router,
        public translate: TranslocoService,
        public userService: UserService,
        private mainService: MainService,
        public configService: ConfigService,
        public elementRef: ElementRef
    ) { }

    ngOnInit() {
        this.bag$ = this.mainService.bagLoaded$;
        this.order$ = this.mainService.orderLoaded$;
        this.scheduledDate$ = this.mainService.scheduledDateLoaded$

        this.restaurant$ = this.mainService.bagRestaurantLoaded$;
        this.menu$ = this.mainService.bagMenuLoaded$;

        combineLatest([this.restaurant$, this.menu$, this.scheduledDate$])
        .pipe(filter(values => !values.includes(null)))
        .subscribe(([restaurant, menu, scheduledDate]) => {
            this.isMenuOpenAtScheduledDate = restaurant.isOpenForMenuType(menu.type, scheduledDate);
            this.disableCheckoutButton()
        });
    }

    // public onClick(event: MouseEvent, targetElement: HTMLElement): void {

    //     if (!targetElement) {
    //         return;
    //     }
    //     let clickOutside = !(this.elementRef.nativeElement.contains(targetElement))

    //     if (this.clickIcon) {
    //         this.resetIcon.emit();
    //         return;
    //     }
    //     else if ((clickOutside) && !this.clickIcon && this.showBag) {
    //         this.resetIcon.emit();
    //         this.closeBag();            
    //     }
    // }

    closeBag() {
        this.showBagChange.emit(false);
    }

    disableCheckoutButton(): boolean {
        let bag = this.mainService.bag;
        return bag == null || bag?.isEmpty || 
        (!this.mainService.menuOnBag?.isOpen && !this.mainService.scheduledDate) || 
        bag?.subtotal.lessThan(this.configService.MINIMUM_ORDER_AMOUNT) || 
        !this.isMenuOpenAtScheduledDate;
    }

    clickCheckout(): void {
        localStorage.setItem('redirect_url', '/checkout');
        this.router.navigate([this.translate.getActiveLang() + '/checkout']);
    }

    clickTrackOrder(): void {
        this.router.navigate([this.translate.getActiveLang() + '/order']);
    }
}
