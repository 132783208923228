<div class="qr-promo-modal-body">
    <!-- may make use of ngIfs depending on what pid the user is accessing (i.e. different modals correspond to different QR codes) -->
    <h1 class="title-heading">{{ (promotion$ | async)?.title }}</h1>
    <div class="qr-product-image">
        <div *ngIf="(promotion$ | async)?.img == null">
            <img src="../../../assets/images/radish-logos/Radish-Logo-BlackText-Large.png">
        </div>
        <div *ngIf="(promotion$ | async)?.img != null">
            <img src="(promotion$ | async)?.img">
        </div>
    </div>
    <p class="qr-promo-text"> 
        {{ (promotion$ | async)?.brief }}
    </p>
    <div class="input-info">
        <input type="text" class="input-field" placeholder="{{ 'giftCard.redeem.qrCodeModal.inputName' | transloco }}" id="name">
        <input type="text" class="input-field" placeholder="{{ 'giftCard.redeem.qrCodeModal.inputEmail' | transloco }}" id="email">
        <p [ngClass]="errorMessage">{{ 'giftCard.redeem.qrCodeModal.errorMessage' | transloco }}</p>
        <button type="button" [ngClass]="submitButton" (click)="submitInfo()">{{ 'giftCard.redeem.qrCodeModal.button' | transloco }}</button>
        <p [ngClass]="confirmationMessage">{{ 'giftCard.redeem.qrCodeModal.confirmationMessage' | transloco }}</p>
    </div>
</div>