import { Address } from "./address.model";
import { Deserializable } from "./deserializable.model"
import { Menu } from "./menu/menu.model";
import { Restaurant } from "./restaurant.model";
import { TranslatedText, TranslatedTextType } from "./translated-text.model"

export class Exposition implements Deserializable<Exposition> {
    _id: string
    _title?: TranslatedText;
    _brief?: TranslatedText;
    address?: Address;
    organizations?: any[];
    dates?: {
        start: Date;
        end: Date;
    }[];
    vendors?: {
        vendor: Restaurant;
        menus: Menu[];
        delay?: number;
        orderCount?: number;
    }[];
    slug: String;

    constructor() {}

    get id(): string {
        return this._id;
    }

    set id(id: string) {
        this._id = id;
    }

    get title(): TranslatedTextType {
        return this._title?.value;
    }

    set title(title: TranslatedTextType) {
        this._title = title as TranslatedText;
    }

    get brief(): TranslatedTextType {
        return this._brief?.value;
    }

    set brief(brief: TranslatedTextType) {
        this._brief = brief as TranslatedText;
    }

	deserialize(input: any): this {
        if (!input) return null;
        if (typeof input == 'string') this.id = input;
        else {
            Object.assign(this, input);
            this.id = input._id ? input._id : input.id;
            this.title = new TranslatedText().deserialize(input.title);
            this.brief = new TranslatedText().deserialize(input.brief);
            this.address = new Address().deserialize(input.address);
            this.dates = input.dates?.map(date => {
                date.start = new Date(date.start);
                date.end = new Date(date.end);
                return date;
            });
            this.vendors = input.vendors?.map(vendor => {
                vendor.vendor = new Restaurant().deserialize(vendor.vendor);
                vendor.menus = vendor.menus?.map((menu) => new Menu().deserialize(menu));
                vendor.orderCount = vendor.orderCount + Math.floor(Math.random() * (3 - 0 + 1) + 0);
                return vendor;
            });
        }
		return this;
	}
}