<div class="menu-version-2-container" *ngIf="configService.getPageVersion((restaurant$ | async)?.slug) === RestaurantStyle.PictureBook">
    <ng-container *ngIf="(menu$ | async) && showSection">
        <app-back-button [backgroundColor]="configService.getPrimaryColor((restaurant$ | async)?.slug)" [iconColor]="configService.getSecondaryColor((restaurant$ | async)?.slug)"></app-back-button>
        <div class="menu-version-2-content-container">
            <div class="menu-features">
                <div *ngIf="(menu$ | async)?.sections && (menu$ | async)?.sections[0] as section"
                    (click)="onClickSection(section)"
                    class="menu-primary-feature menu-feature section-box"
                    [style.background-image]="'url(' + getSectionImage((restaurant$ | async), section, {checkBackup: true}) + ')'"
                    appCenterAndFitBackgroundImage>
                    <div class="section-text" [style.background-color]="configService.getPrimaryColor((restaurant$ | async)?.slug)"
                        [style.color]="configService.getSecondaryColor((restaurant$ | async)?.slug)"
                        [style.letter-spacing]="configService.getSectionTitleLetterSpacing((restaurant$ | async)?.slug)"
                        [innerHTML]="section.title">
                    </div>
                </div>
                <div *ngIf="(menu$ | async)?.sections && (menu$ | async)?.sections[1] as section"
                    (click)="onClickSection(section)"
                    class="menu-secondary-feature menu-feature section-box"
                    [style.background-image]="'url(' + getSectionImage((restaurant$ | async), section, {checkBackup: true}) + ')'"
                    appCenterAndFitBackgroundImage>
                    <div class="section-text" [style.background-color]="configService.getPrimaryColor((restaurant$ | async)?.slug)"
                        [style.color]="configService.getSecondaryColor((restaurant$ | async)?.slug)"
                        [style.letter-spacing]="configService.getSectionTitleLetterSpacing((restaurant$ | async)?.slug)"
                        [innerHTML]="section.title">
                    </div>
                </div>
            </div>
            <div *ngIf="(menu$ | async)?.sections" class="menu-sections">
                <div class="menu-section-box section-box"
                    [style.background-image]="'url(' + getSectionImage((restaurant$ | async), section) + ')'"
                    *ngFor="let section of (menu$ | async)?.sections | slice:2" (click)="onClickSection(section)" appClickStopPropagation>
                    <div class="section-text" [style.background-color]="configService.getPrimaryColor((restaurant$ | async)?.slug)"
                        [style.color]="configService.getSecondaryColor((restaurant$ | async)?.slug)"
                        [style.letter-spacing]="configService.getSectionTitleLetterSpacing((restaurant$ | async)?.slug)">
                        <span [innerHTML]="section.title"></span>
                        <div *ngIf="section.categories && section.categories.length > 0" class="icon-wrapper">
                            <ng-container *ngFor="let category of section.categories">
                                <app-icon class="category-icon"
                                    [type]="category.slug"
                                    [fill]="configService.getSecondaryColor((restaurant$ | async)?.slug)"
                                    [height]="'1rem'"
                                    [width]="'1rem'">
                                </app-icon>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</div>
<!-- <div [routerLink]="['./', 'section']">Menu</div> -->
<router-outlet></router-outlet>
<!-- <app-restaurant-menu></app-restaurant-menu> -->
