<div class="order-summary-container">
    <div class="order-summary-header">{{ 'orderSummary.orderSummary' | transloco }}</div>
    <div (scroll)="onScroll($event)" class="order-items-wrapper">
        <div *ngIf="isOverflowPillShowing" class="overflow-pill">
            <button (click)="scrollDown()" class="overflow-pill-button" type='button'>
                <div>{{ 'checkout.scrollForMore' | transloco }}</div>
                <div class="arrow-wrapper">
                    <img class="arrow" src="../../../assets/images/white-arrow.png" />
                </div>
            </button>
        </div>
        <div *ngFor="let item of (order?.bag.items || giftBox?.items)" class="order-item-wrapper">
            <div class="order-item">
                <div class="order-item-left">
                    <img class="order-item-image"
                        src="{{ item?.thumbnailUrl != null ? item?.thumbnailUrl : '../../../assets/images/Radish-LogoMark-200x200.jpg'}}" />
                    <div class="order-item-info">
                        <strong class="order-item-quantity">{{ item.quantity }} x </strong>
                        <div class="order-item-title">{{ item.title }}</div>
                    </div>
                </div>
                <div class="order-item-right">
                    <div *ngIf="pageState != OrderSummaryState.Completed" class="order-item-buttons">
                        <button type="button" class="order-item-button order-item-buttons-minus"
                            (click)="decrementItem(item)" [disabled]="isDecrementDisabled(item)">
                            <img src="../../../../assets/images/icons/minus-white.svg">
                        </button>
                        <button type="button" class="order-item-button order-item-buttons-plus"
                            (click)="incrementItem(item)" [disabled]="isIncrementDisabled()">
                            <img src="../../../../assets/images/icons/plus-white.svg">
                        </button>
                    </div>
                    <div class="order-item-price">{{ item.price | price | translocoCurrency }}</div>
                </div>

            </div>
        </div>
    </div>
    <div class="order-summary-line truncated"></div>
    <div class="order-summary-checkout-container">
        <div class="order-price-summary">
            <div class="cost-label">
                <strong>{{ 'checkout.subtotal' | transloco }}</strong>
                <strong>{{ (order || virtualizedOrder || giftBox)?.subtotal | price | translocoCurrency }}</strong>
            </div>
            <div *ngFor="let fee of (order || virtualizedOrder)?.fees" class="market-checkout-order-fee market-checkout-order-price-detail">
                <div class="market-checkout-order-fee-type">{{ fee.title }}</div>
                <div class="market-checkout-order-fee-amount">{{ fee.amount | price | translocoCurrency }}</div>
            </div>
            <div class="order-summary-line"></div>
            <div class="cost-label">
                <strong>{{ 'checkout.total' | transloco }}</strong>
                <strong>{{ (order || virtualizedOrder)?.total | price | translocoCurrency }}</strong>
            </div>
        </div>
        <div class="order-checkout-button-container">
            <button *ngIf="pageState == OrderSummaryState.Browsing" class="primary-form-submit-button" [disabled]="!giftBox || giftBox.isEmpty || isLoading"
                (click)="checkout()">
                <span *ngIf="!isLoading && pageState == OrderSummaryState.Browsing">
                    {{ 'bag.checkout' | transloco }}
                </span>
                <!-- <span *ngIf="!isLoading && pageState == OrderSummaryState.Checkout">
                    {{ 'expo.checkout.placeOrder' | transloco }}
                </span> -->
                <div *ngIf="isLoading" class="spinner-border spinner-border-sm" role="status">
                    <span class="sr-only">{{ 'common.loading' | transloco }}</span>
                </div>
            </button>
            <div *ngIf="pageState != OrderSummaryState.Completed" class="promo-code" (click)="openPromoModal()">
                {{ 'giftBox.orderSummary.promoCode' | transloco }}
            </div>
        </div>
    </div>
</div>

<div *ngIf="isPromoModalShowing" class="promo-code-overlay" [@fadeInOut]="isPromoModalShowing ? 'in' : 'out'">
    <div class="promo-code-container" (clickOutside)="closePromoModal()" [delayClickOutsideInit]="true">
        <h2 class="title mb-3"><b>{{ 'promoCodeModal.title' | transloco }}</b></h2>
        <input [(ngModel)]="promoCode" type="text" class="checkout-form-input form-input" [class.mb-4]="!discountErrorMessage"
            placeholder="{{ 'promoCodeModal.enterCode' | transloco }}">
        <div *ngIf="discountErrorMessage" class="error mb-4 mt-1" [innerHTML]="discountErrorMessage | transloco"></div>

        <button type="submit" class="form-submit-button" [disabled]="promoLoading" (click)="onSubmitPromoCode()">
            <span *ngIf="!promoLoading">
                {{ 'promoCodeModal.applyCode' | transloco }}
            </span>
            <div *ngIf="promoLoading" class="spinner-border spinner-border-sm" role="status">
                <span class="sr-only">{{ 'common.promoLoading' | transloco }}</span>
            </div>
        </button>
    </div>
</div>