import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { RegistrationValidator } from '../shared/validators/register.validator';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../core/services/auth.service';
import { TranslocoService } from '@ngneat/transloco';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';

@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.css'],
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
    private readonly subscription: Subscription = new Subscription();

    resetPasswordForm: UntypedFormGroup;
    token: string;
    loading: boolean;
    error: boolean;
    verificationMessage: boolean = false;

    constructor(private fb: UntypedFormBuilder,
        private authService: AuthService,
        public translate: TranslocoService,
        private route: ActivatedRoute,
        private router: Router) {
        this.resetPasswordForm = fb.group({
            pwd: ["", [Validators.required, Validators.minLength(8)]],
            confirmPwd: ["", Validators.required]
        }, {
            validator: RegistrationValidator('pwd', 'confirmPwd')
        });
    }

    get resetPasswordControls() {
        return this.resetPasswordForm.controls;
    }

    ngOnInit() {
        this.subscription.add(this.route.params.subscribe(params => {
            if (!params['token'] || params['token'] == null) this.router.navigate([this.translate.getActiveLang(), 'password', 'forgot'], { queryParams: { source: 'resetPassword' } });
            this.authService.checkResetToken(params['token'])
                .pipe(first())
                .subscribe(
                    _ => {
                        this.token = params['token'];
                    },
                    _ => {
                        this.router.navigate([this.translate.getActiveLang(), 'password', 'forgot'], { queryParams: { source: 'resetPassword' } });
                    });
                }
            )
        );
    }

    onSubmit(password: string) {
        this.loading = true;
        if (this.resetPasswordForm.invalid) {
            this.loading = false;
            return;
        } else {
            this.error = false;
            this.authService.resetPassword(password, this.token)
                .pipe(first())
                .subscribe(
                    _ => {
                        this.loading = false;
                        this.verificationMessage = true;
                    },
                    _ => {
                        this.error = true;
                    }
                );
        }
    }

    ngOnDestroy() {
        this.subscription.unsubscribe()
    }
}
