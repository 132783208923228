import { Component, Input } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { ExtendedOrderType } from 'src/app/core/enums';
import { SearchResult } from 'src/app/core/models';

@Component({
  selector: 'app-category-popover',
  templateUrl: './category-popover.component.html',
  styleUrls: ['./category-popover.component.css']
})
export class CategoryPopoverComponent {
  @Input() category: SearchResult;

	ExtendedOrderType = ExtendedOrderType;

  constructor(public translate: TranslocoService) { }
}
