import { AfterViewInit, Component, ComponentFactory, ComponentFactoryResolver, Input, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { Category, Icon } from 'src/app/core/enums';
import { ChevronLeftComponent } from './chevron-left/chevron-left.component';
import { ChevronRightComponent } from './chevron-right/chevron-right.component';
import { GlutenFreeComponent } from './gluten-free/gluten-free.component';

@Component({
    selector: 'app-icon',
    templateUrl: './icons.component.html',
    styleUrls: ['./icons.component.css']
})
export class IconsComponent implements OnInit, AfterViewInit {

    private readonly ICON_MAP = {
        [Category.GlutenFree]: GlutenFreeComponent,
        [Icon.ChevronRight]: ChevronRightComponent,
        [Icon.ChevronLeft]: ChevronLeftComponent
    }

    @ViewChild("icon", { read: ViewContainerRef }) icon: ViewContainerRef

    @Input() type: string
    @Input() fill: string
    @Input() height: string
    @Input() width: string

    constructor(private resolver: ComponentFactoryResolver) { }

    ngOnInit(): void {
    }

    ngAfterViewInit(): void {
        const factory: ComponentFactory<any> = this.resolver.resolveComponentFactory(this.ICON_MAP[this.type]);
        const componentRef = this.icon.createComponent(factory)
        componentRef.instance.fill = this.fill
        const element: HTMLElement = <HTMLElement>componentRef.location.nativeElement
        element.style.width = this.height
        element.style.height = this.width
        element.style.display = 'flex'
        element.style.justifyContent = 'center'
        element.style.alignItems = 'center'
    }

}
