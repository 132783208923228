<div class="restaurant-about-instagram-container" [ngClass]="configService.getCssClass(restaurant.slug)">
    <h5 class="restaurant-about-instagram-header">
        {{ 'restaurant.about.instagram' | transloco }}
    </h5>
    <!-- USING ROWS AND COLS with IG object
    <div class="row">
        <div *ngFor="let item of instagramMedia | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize"
            class="col-md-4 media-wrapper">
            <a href="{{item.permalink}}" target="_blank" title="">
                <img src="{{item.media_url}}">
            </a>
        </div>
    </div> -->
    <!-- USING FLEXBOX with IG object
    <ul class="media-wrapper list-unstyled">
        <li *ngFor="let item of instagramMedia | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize">
            <a href="{{item.permalink}}" target="_blank" title="">
                <img src="{{item.media_url}}">
            </a>
        </li>
    </ul> -->
    <ul class="media-wrapper list-unstyled" [ngClass]="{'off-number': isMediaOffNumber() }">
        <li *ngFor="let item of getRestaurantMedia() | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize">
            <a href="{{item.link}}" target="_blank" title="">
                <img src="{{item.image}}">
            </a>
        </li>
    </ul>
    <ngb-pagination [(page)]="page" [pageSize]="pageSize" [collectionSize]="getRestaurantMedia().length">
        <ng-template ngbPaginationNumber let-p> &#x25CF;</ng-template>
    </ngb-pagination>
</div>
