import { Time } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { LatLngLiteral } from 'ngx-google-places-autocomplete/objects/latLng';
import { GuestlistComponent } from '../guestlist/guestlist.component';
import { animate, style, transition, trigger } from '@angular/animations';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

const enterTransition = transition(':enter', [
	style({
	  opacity: 0
	}),
	animate('1s ease-in', style({
	  opacity: 1
	}))
  ]);
  
  const leaveTrans = transition(':leave', [
	style({
	  opacity: 1
	}),
	animate('1s ease-out', style({
	  opacity: 0
	}))
  ])
  
  const fadeIn = trigger('fadeIn', [
	enterTransition
  ]);
  
  const fadeOut = trigger('fadeOut', [
	leaveTrans
  ]);

@Component({
	selector: 'app-invitation',
	templateUrl: './invitation.component.html',
	styleUrls: ['./invitation.component.css']
})
export class InvitationComponent implements OnInit {
	public eventTitle: string;
	public dateAndTime: string;
	public location: string;
	/*
	Host object:
		- name
		- profile picture
		- contact info: email/phone number
	*/
	public hostName: string;
	/*
	Full guestlist should contain (optional): (as guest object)
		- guest name
		- profile picture (not required)
	*/
	public guestList: string[];
	/*
	Correct restaurant list should contain:
		- restaurant image
		- restaurant logo
		- restaurant name
	*/
	public restaurantList: string[];
	public eventNotes: string[];
	
	// Google maps components
	public center: LatLngLiteral;
	public options: google.maps.MapOptions = {
		mapTypeId: 'roadmap',
		zoomControl: false,
		scrollwheel: false,
		disableDoubleClickZoom: true,
		maxZoom: 15,
		minZoom: 8,
		streetViewControl: false,
		mapTypeControl: false,
	};

	public show = false;

	constructor() { }

	ngOnInit(): void {
		this.eventTitle = "radish app launch party".toUpperCase();

		let rawDateTime = new Date();
		rawDateTime.setDate(5);
		rawDateTime.setMonth(4);
		// rawDateTime.setFullYear(2023);
		rawDateTime.setHours(17);
		rawDateTime.setMinutes(0);
		rawDateTime.setSeconds(0);

		this.dateAndTime = rawDateTime.toDateString() + " " + rawDateTime.getHours() + ":00";

		this.center = {
			lat: 45.508370,
			lng: -73.571780
		};

		this.guestList = ["Hannah H.", "Hannah S.", "Mansib", "Mayank", "Sofia", "Ksenia"];
		this.restaurantList = ["Maynard", "Le Boit Vegane", "Sham"];
		this.eventNotes = ["Please bring a water bottle", "Love you bestie", "heeheeehoohee"];
	}

	onClick() {
		this.show = true;
	  }
	
	  onSave() {
		this.show = false;
	  }

}
