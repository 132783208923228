import { animate, style, transition, trigger } from '@angular/animations';
import { ViewportScroller } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ListContent } from 'src/app/core/models';

const fadeInOut = trigger('fadeInOut', [
	transition(':enter', [
		style({ transform: 'translateY(10px)', opacity: 0 }),
		animate(250, style({ transform: 'translateY(0)', opacity: 1 })),
	]),
	transition(':leave', [
		animate(250, style({ transform: 'translateY(10px)', opacity: 0 }))
	])
])

@Component({
	selector: 'app-gift-box-combo-listing-navbar',
	templateUrl: './combo-listing-navbar.component.html',
	styleUrls: ['./combo-listing-navbar.component.css'],
	animations: [fadeInOut]
})
export class GiftBoxComboListingNavbarComponent implements OnChanges {

	private readonly SCROLL_OFFSET = 100;

	@Input() items: any;

	@Output() onItemClicked: EventEmitter<ListContent> = new EventEmitter<ListContent>();

	@ViewChild('nav') nav: ElementRef;
	@ViewChild('sectionsContainer') sectionsContainer: ElementRef

	selectedItem: ListContent;

	rightScrollable: boolean = false;
	leftScrollable: boolean = false;
	leftAndRightScrollable: boolean = false;

	showMenu: boolean = false;

	constructor(private viewportScroller: ViewportScroller) {}

	ngOnChanges(changes: SimpleChanges) {
		if (changes['items']) if (!this.selectedItem && this.items?.length) this.selectedItem = this.items[0];
	}

	onScroll() {
		const scrollElement = document.getElementById("navbar-sections");

		this.rightScrollable = scrollElement.scrollLeft < (scrollElement.scrollWidth - scrollElement.clientWidth);
		this.leftScrollable = !(scrollElement.scrollLeft == 0);
	}

	onClickScroll(scrollAmt: number) {
		const scrollElement = document.getElementById("navbar-sections");

		this.rightScrollable = (scrollElement.scrollLeft + scrollAmt) < (scrollElement.scrollWidth - scrollElement.clientWidth);
		this.leftScrollable = (scrollElement.scrollLeft + scrollAmt) > 0;
	}

	onClickExpandSections() {
		this.showMenu = !this.showMenu;
	}

	onClickScrollRight() {
		const scroll = this.sectionsContainer.nativeElement.scrollLeft + this.sectionsContainer.nativeElement.offsetWidth - this.SCROLL_OFFSET
		this.sectionsContainer.nativeElement.scrollBy({
			top: 0,
			left: scroll,
			behavior: 'smooth'
		});
		this.onClickScroll(scroll);
	}

	onClickScrollLeft() {
		const scroll = -(this.sectionsContainer.nativeElement.offsetWidth - this.SCROLL_OFFSET)
		this.sectionsContainer.nativeElement.scrollBy({
			top: 0,
			left: scroll,
			behavior: 'smooth'
		})
		this.onClickScroll(scroll);
	}

	onSelected(list: ListContent) {
		this.selectedItem = list;
		const scrollElement = document.getElementById("listing-header");
		scrollElement.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
		this.onItemClicked.emit(list);
	}
}
