import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { NgxGalleryOptions, NgxGalleryImage, NgxGalleryAnimation } from '@kolkov/ngx-gallery';
import { Review, Restaurant } from 'src/app/core/models';
import { TranslocoService } from '@ngneat/transloco';
import { RestaurantSlug } from 'src/app/core/enums';
import { ConfigService } from 'src/app/core/services/config.service';

@Component({
	selector: 'app-review',
	templateUrl: './review.component.html',
	styleUrls: ['./review.component.css']
})
export class ReviewComponent implements OnInit, OnChanges {

	@Input() review: Review;
	@Input() restaurant: Restaurant;

	galleryOptions: NgxGalleryOptions[];
	galleryImages: NgxGalleryImage[];

	constructor(
		public translate: TranslocoService,
		public configService: ConfigService) { }

	ngOnChanges(changes: SimpleChanges): void {
		let galleryImages: NgxGalleryImage[] = [];
		this.review.photos.forEach(photo => {
			galleryImages.push({
				small: photo.url,
				medium: photo.url,
				big: photo.url,
				description: photo.description[this.translate.getActiveLang()]
			})
		})
		this.galleryImages = galleryImages;
	}

	ngOnInit() {

		this.galleryOptions = [
			{
				image: false,
				width: '100%',
				height: '7rem',
				thumbnailsColumns: 4,
				arrowPrevIcon: 'fa fa-chevron-left',
				arrowNextIcon: 'fa fa-chevron-right',
				imageAnimation: NgxGalleryAnimation.Slide,
				previewAnimation: false,
				previewCloseOnClick: true,
				previewCloseOnEsc: true,
				closeIcon: ""
			},
			// max-width 800
			{
				breakpoint: 800,
				width: '100%',
				imagePercent: 80,
				thumbnailsPercent: 20,
				thumbnailsMargin: 20,
				thumbnailMargin: 20
			},
			// max-width 500
			{
				breakpoint: 500,
				width: "100%",
				thumbnailsColumns: 3
			}
		];

	}

	getIconColor() {
		switch (this.restaurant.slug) {
			case RestaurantSlug.Maharani:
			case RestaurantSlug.Diolo:
			case RestaurantSlug.MerRouge:
			case RestaurantSlug.PtitPlateau:
			case RestaurantSlug.FanfarePizza:
			case RestaurantSlug.InochiExpress:
			case RestaurantSlug.Uluwatu:
			case RestaurantSlug.Boustan:
			case RestaurantSlug.JavaUCdn:
			case RestaurantSlug.SpiceBros:
			case RestaurantSlug.MarcheMarema:
			case RestaurantSlug.ElephantThai:
			case RestaurantSlug.CafeResonance:
			case RestaurantSlug.UbiSushi:
			case RestaurantSlug.ObsceneVegan:
			case RestaurantSlug.LestersDeli:
			case RestaurantSlug.Barranco:
			case RestaurantSlug.PetitSeoul:
			case RestaurantSlug.Darbar:
			case RestaurantSlug.SushiSama:
			case RestaurantSlug.oFish:
			case RestaurantSlug.PizzaSport:
			case RestaurantSlug.SpartaChicken:
			case RestaurantSlug.PiriPiri:
			case RestaurantSlug.UkiyaIzakaya:
			case RestaurantSlug.MaisonDuDestin:
			case RestaurantSlug.DowntownDhaba:
			case RestaurantSlug.Grillados:
				return '#FFFFFF';
			case RestaurantSlug.Bocadillo:
			case RestaurantSlug.P23Dumplings:
				return '#FFC429';
			default:
				return '#000000';
		}
	}

	// restoPage TODO: Modify rating star colors (If resto has a dark background)

}
