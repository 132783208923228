<app-navbar></app-navbar>
<div class="forgot-password-container">
	<div class="container">
		<div class="forgot-password-container-column col-md-5">
            <p *ngIf="invalidToken" class="invalid-token-error">{{ 'forgotPassword.invalidToken' | transloco }}</p>
			<h2 class="forgot-password-container-title">
				{{ 'forgotPassword.forgotPassword' | transloco }}
            </h2>
			<p>{{ 'forgotPassword.helperMessage' | transloco }}</p>
			<form [formGroup]="forgotPasswordForm" (ngSubmit)="onSubmit(forgotPasswordForm.value)">
				<div class="form-group mb-4">
					<input formControlName="email" class="form-control" type="text" id="email"
						[placeholder]="'forgotPassword.email' | transloco" [ngClass]="{
							'is-invalid':
								forgotPasswordControls.email.errors &&
								forgotPasswordControls.email.errors.pattern
						}" />
					<div class="line"></div>
					<div *ngIf="forgotPasswordControls.email.errors" class="form-control-feedback invalid-feedback">
						<div class="noAtSign" *ngIf="forgotPasswordControls.email.errors.pattern"></div>
					</div>
				</div>
				<div class="form-group regular-forgotPassword mb-4">
					<button class="btn btn-forgotPassword form-control" type="submit"
						[disabled]="(!forgotPasswordForm.valid || loading) || submitted">
						<div *ngIf="loading" class="spinner-border spinner-border-sm" role="status">
							<span class="sr-only">{{ 'common.loading' | transloco }}</span>
						</div>
						<span *ngIf="!loading && !submitted">
							{{ 'forgotPassword.submit' | transloco }}
                        </span>
                        <span *ngIf="!loading && submitted">
                            {{ 'forgotPassword.sent' | transloco }}
                        </span>
					</button>
				</div>
			</form>
			<div *ngIf="verificationMessage" class="verification-message">
				{{ 'forgotPassword.successMessage' | transloco }}
			</div>
		</div>
	</div>
</div>
<app-footer></app-footer>
