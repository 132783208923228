
export abstract class Content {
    abstract _id: string;
    abstract slug: string;
    abstract type: ContentType;
    abstract updatedAt: string;
    abstract createdAt: string;

    get id(): string {
        return this._id;
    }

    set id(id: string) {
        this._id = id;
    }
}

export enum ContentType {
    ListContent = 'ListContent'
}