<div class="invitation">
    <div class="background">
        <video #videoPlayer preload="auto" autoplay="true" loop="true">
            <source src="../../../assets/videos/animations/radishVideo0001-0150.mp4" type="video/mp4"/>
        </video>
        <!-- <img class="animation" src="../../../assets/videos/animations/fabric.gif"> -->
    </div>
    <app-navbar></app-navbar>
    <div class="content">
        <div class="info-column">
            <div class="details">
                <div class="text-container">
                    <!-- <img class="blob-1" src="../../../assets/graphics/teal-blob.png"> -->
                    <h1>{{eventTitle}}</h1>
                    <h2 class="date-time"><img src="../../../assets/graphics/clock.png" style="width:3rem; margin-right:0.5rem">{{dateAndTime}}</h2>
                    <h2><img src="../../../assets/graphics/home.png" style="width:3rem; margin-right:0.5rem">Hosted by <img style="width:3rem; height:3rem; border-radius:0.5rem;" src="../../../assets/images/radish-logos/Radish-square-192x192.png"></h2>
                    <h2><img src="../../../assets/graphics/location.png" style="width:3rem; margin-right:0.5rem">
                        <div *ngIf="show" [@fadeIn] [@fadeOut]>The Radish Office</div>
                        <div class="hover-text">
                            (<a href="https://goo.gl/maps/BcDWUbZddkDLVDxh6" target=”_blank” class="map-link">show me</a>)
                            <br>
                            <span class="tooltip-text">
                                <google-map
                                    height="20rem"
                                    width="20rem"
                                    [center]="center"
                                    [options]="options"
                                >
                                    <map-marker
                                        [position]="center"
                                        label="3414 Park Avenue"
                                    ></map-marker>
                                </google-map>
                            </span>
                        </div>
                    </h2>
                </div>
                <div class="mid-width-image">
                    <img class="image" style="border-radius:1rem;" src="../../../assets/images/radish-logos/Radish-square-192x192.png">
                </div>
            </div>
            <h2>The List</h2>
            <div class="guests">
                <!-- Two states: compressed and opened guest list -->
                <app-guestlist [guestList]="guestList"></app-guestlist>
            </div>
            <h2>Featuring Dishes From:</h2>
            <div class="restaurants">
                <app-featured-restaurants [restaurants]="restaurantList"></app-featured-restaurants>
            </div>
            <h2>Notes From the Host:</h2>
            <div class="extras">
                <h3>
                    <div *ngFor="let note of eventNotes">
                        - {{note}}
                    </div>
                </h3>
            </div>
        </div>
        <div class="rsvp-column">
            <div class="event-photo">
                <img class="image" style="border-radius:1rem;" src="../../../assets/images/radish-logos/Radish-square-192x192.png">
            </div>
            <div class="rsvp-buttons">
                <div>
                    <div class="rsvp-button" (click)="onSave()">
                        <img class="image" src="../../../assets/graphics/happy-radish.png">
                    </div>
                    <h2>i'm going</h2>
                </div>
                <div>
                    <div class="rsvp-button" (click)="onClick()">
                        <img class="image" src="../../../assets/graphics/sad-radish.png">
                    </div>
                    <h2>not going</h2>
                </div>
            </div>
        </div>
    </div>
    <div class="mobile-rsvp">
        <div class="rsvp-buttons">
            <div>
                <div class="rsvp-button" (click)="onSave()">
                    <img class="image" src="../../../assets/graphics/happy-radish.png">
                </div>
                <h2>i'm going</h2>
            </div>
            <div>
                <div class="rsvp-button" (click)="onClick()">
                    <img class="image" src="../../../assets/graphics/sad-radish.png">
                </div>
                <h2>not going</h2>
            </div>
        </div>
</div>