import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-read-more',
  templateUrl: './read-more.component.html',
  styleUrls: ['./read-more.component.css']
})
export class ReadMoreComponent implements OnInit, OnChanges {

    @Input() content: string;
    @Input() limit: number;
    @Input() fontWeight: string;
    @Input() color: string;
    @Input() showReadMoreText: boolean = true;
    @Input() completeWords: boolean = true; // doesn't do anything

    isContentToggled: boolean;
    nonEditedContent: string;
    nonEditedLimit: number

    constructor() {

    }

    ngOnChanges(changes: SimpleChanges) {
        if (!changes?.content?.firstChange && changes?.content?.currentValue != changes?.content?.previousValue) {
            this.isContentToggled = false
            this.nonEditedContent = this.content
            this.content = this.formatContent(this.nonEditedContent)
        }
    }

    ngOnInit() {
        this.nonEditedContent = this.content;
        this.nonEditedLimit = this.limit
        this.content = this.formatContent(this.nonEditedContent);
    }

    toggleContent() {
        this.isContentToggled = !this.isContentToggled;
        this.content = this.isContentToggled ? this.nonEditedContent : this.formatContent(this.content);
    }

    formatContent(content: string) {
        // if (this.completeWords) {
        //     this.limit = content.substr(0, this.nonEditedLimit).lastIndexOf(' ');
        // }
        // return `${content.substr(0, this.limit)}...`;
        return `${content.substr(0, this.limit)}${content.length > this.limit ? '... ' : ' '}`;
    }

}
