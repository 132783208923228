import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'capitalize' })
/**
 * Capitalizes only the first letter of a string
 */
export class CapitalizePipe implements PipeTransform {

	constructor() {
	}
	
	transform(value: string): string {
		if (value == null) return '';
		return value.charAt(0).toUpperCase() + value.slice(1);	}
}
