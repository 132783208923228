<app-navbar [navbarType]="navbarType"></app-navbar>
<div class="terms-container">
	<h1 class="terms-title">
		{{ 'terms.title' | transloco }}
	</h1>
	<p class="terms-updated">
		{{ 'terms.updated' | transloco }}
	</p>
	<div class="terms-content-container row">
		<div class="terms-content-sidebar-container col-4">
			<div class="terms-content-sidebar-wrapper" #sidebarWrapper>
				<ul class="side-nav list-unstyled">
					<li class="side-nav-item">
						<a routerLink="." fragment="terms-text-1">{{
							'terms.overview.title' | transloco
							}}</a>
					</li>
					<li class="side-nav-item">
						<a routerLink="." fragment="terms-text-2">{{
							'terms.section1.title' | transloco
							}}</a>
					</li>
					<li class="side-nav-item">
						<a routerLink="." fragment="terms-text-3">{{
							'terms.section2.title' | transloco
							}}</a>
					</li>
					<li class="side-nav-item">
						<a routerLink="." fragment="terms-text-4">{{
							'terms.section3.title' | transloco
							}}</a>
					</li>
					<li class="side-nav-item">
						<a routerLink="." fragment="terms-text-5">{{
							'terms.section4.title' | transloco
							}}</a>
					</li>
					<li class="side-nav-item">
						<a routerLink="." fragment="terms-text-6">{{
							'terms.section5.title' | transloco
							}}</a>
					</li>
					<li class="side-nav-item">
						<a routerLink="." fragment="terms-text-7">{{
							'terms.section6.title' | transloco
							}}</a>
					</li>
					<li class="side-nav-item">
						<a routerLink="." fragment="terms-text-8">{{
							'terms.section7.title' | transloco
							}}</a>
					</li>
					<li class="side-nav-item">
						<a routerLink="." fragment="terms-text-9">{{
							'terms.section8.title' | transloco
							}}</a>
					</li>
					<li class="side-nav-item">
						<a routerLink="." fragment="terms-text-10">{{
							'terms.section9.title' | transloco
							}}</a>
					</li>
					<li class="side-nav-item">
						<a routerLink="." fragment="terms-text-11">{{
							'terms.section10.title' | transloco
							}}</a>
					</li>
					<li class="side-nav-item">
						<a routerLink="." fragment="terms-text-12">{{
							'terms.section11.title' | transloco
							}}</a>
					</li>
					<li class="side-nav-item">
						<a routerLink="." fragment="terms-text-13">{{
							'terms.section12.title' | transloco
							}}</a>
					</li>
					<li class="side-nav-item">
						<a routerLink="." fragment="terms-text-14">{{
							'terms.section13.title' | transloco
							}}</a>
					</li>
					<li class="side-nav-item">
						<a routerLink="." fragment="terms-text-15">{{
							'terms.section14.title' | transloco
							}}</a>
					</li>
					<li class="side-nav-item">
						<a routerLink="." fragment="terms-text-16">{{
							'terms.section15.title' | transloco
							}}</a>
					</li>
					<li class="side-nav-item">
						<a routerLink="." fragment="terms-text-17">{{
							'terms.section16.title' | transloco
							}}</a>
					</li>
					<li class="side-nav-item">
						<a routerLink="." fragment="terms-text-18">{{
							'terms.section17.title' | transloco
							}}</a>
					</li>
					<li class="side-nav-item">
						<a routerLink="." fragment="terms-text-19">{{
							'terms.section18.title' | transloco
							}}</a>
					</li>
					<li class="side-nav-item">
						<a routerLink="." fragment="terms-text-20">{{
							'terms.section19.title' | transloco
							}}</a>
					</li>
					<li class="side-nav-item">
						<a routerLink="." fragment="terms-text-21">{{
							'terms.section20.title' | transloco
							}}</a>
					</li>
				</ul>
			</div>
		</div>
		<div class="terms-content-text col-8">
			<div id="terms-text-1" class="terms-content-text-section">
				<h2 class="terms-content-text-section-title">
					{{ 'terms.overview.title' | transloco }}
				</h2>
				<p class="terms-content-text-section-description" [innerHTML]="'terms.overview.text' | transloco"></p>
			</div>
			<div id="terms-text-2" class="terms-content-text-section">
				<h2 class="terms-content-text-section-title">
					{{ 'terms.section1.title' | transloco }}
				</h2>
				<p class="terms-content-text-section-description" [innerHTML]="'terms.section1.text' | transloco"></p>
			</div>
			<div id="terms-text-3" class="terms-content-text-section">
				<h2 class="terms-content-text-section-title">
					{{ 'terms.section2.title' | transloco }}
				</h2>
				<p class="terms-content-text-section-description" [innerHTML]="'terms.section2.text' | transloco"></p>
			</div>
			<div id="terms-text-4" class="terms-content-text-section">
				<h2 class="terms-content-text-section-title">
					{{ 'terms.section3.title' | transloco }}
				</h2>
				<p class="terms-content-text-section-description" [innerHTML]="'terms.section3.text' | transloco"></p>
			</div>
			<div id="terms-text-5" class="terms-content-text-section">
				<h2 class="terms-content-text-section-title">
					{{ 'terms.section4.title' | transloco }}
				</h2>
				<p class="terms-content-text-section-description" [innerHTML]="'terms.section4.text' | transloco"></p>
			</div>
			<div id="terms-text-6" class="terms-content-text-section">
				<h2 class="terms-content-text-section-title">
					{{ 'terms.section5.title' | transloco }}
				</h2>
				<p class="terms-content-text-section-description" [innerHTML]="'terms.section5.text' | transloco"></p>
			</div>
			<div id="terms-text-7" class="terms-content-text-section">
				<h2 class="terms-content-text-section-title">
					{{ 'terms.section6.title' | transloco }}
				</h2>
				<p class="terms-content-text-section-description" [innerHTML]="'terms.section6.text' | transloco"></p>
			</div>
			<div id="terms-text-8" class="terms-content-text-section">
				<h2 class="terms-content-text-section-title">
					{{ 'terms.section7.title' | transloco }}
				</h2>
				<p class="terms-content-text-section-description" [innerHTML]="'terms.section7.text' | transloco"></p>
			</div>
			<div id="terms-text-9" class="terms-content-text-section">
				<h2 class="terms-content-text-section-title">
					{{ 'terms.section8.title' | transloco }}
				</h2>
				<p class="terms-content-text-section-description" [innerHTML]="'terms.section8.text' | transloco"></p>
			</div>
			<div id="terms-text-10" class="terms-content-text-section">
				<h2 class="terms-content-text-section-title">
					{{ 'terms.section9.title' | transloco }}
				</h2>
				<p class="terms-content-text-section-description" [innerHTML]="'terms.section9.text' | transloco"></p>
			</div>
			<div id="terms-text-11" class="terms-content-text-section">
				<h2 class="terms-content-text-section-title">
					{{ 'terms.section10.title' | transloco }}
				</h2>
				<p class="terms-content-text-section-description" [innerHTML]="'terms.section10.text' | transloco"></p>
			</div>
			<div id="terms-text-12" class="terms-content-text-section">
				<h2 class="terms-content-text-section-title">
					{{ 'terms.section11.title' | transloco }}
				</h2>
				<p class="terms-content-text-section-description" [innerHTML]="'terms.section11.text' | transloco"></p>
			</div>
			<div id="terms-text-13" class="terms-content-text-section">
				<h2 class="terms-content-text-section-title">
					{{ 'terms.section12.title' | transloco }}
				</h2>
				<p class="terms-content-text-section-description" [innerHTML]="'terms.section12.text' | transloco"></p>
			</div>
			<div id="terms-text-14" class="terms-content-text-section">
				<h2 class="terms-content-text-section-title">
					{{ 'terms.section13.title' | transloco }}
				</h2>
				<p class="terms-content-text-section-description" [innerHTML]="'terms.section13.text' | transloco"></p>
			</div>
			<div id="terms-text-15" class="terms-content-text-section">
				<h2 class="terms-content-text-section-title">
					{{ 'terms.section14.title' | transloco }}
				</h2>
				<p class="terms-content-text-section-description" [innerHTML]="'terms.section14.text' | transloco"></p>
			</div>
			<div id="terms-text-16" class="terms-content-text-section">
				<h2 class="terms-content-text-section-title">
					{{ 'terms.section15.title' | transloco }}
				</h2>
				<p class="terms-content-text-section-description" [innerHTML]="'terms.section15.text' | transloco"></p>
			</div>
			<div id="terms-text-17" class="terms-content-text-section">
				<h2 class="terms-content-text-section-title">
					{{ 'terms.section16.title' | transloco }}
				</h2>
				<p class="terms-content-text-section-description" [innerHTML]="'terms.section16.text' | transloco"></p>
			</div>
			<div id="terms-text-18" class="terms-content-text-section">
				<h2 class="terms-content-text-section-title">
					{{ 'terms.section17.title' | transloco }}
				</h2>
				<p class="terms-content-text-section-description" [innerHTML]="'terms.section17.text' | transloco"></p>
			</div>
			<div id="terms-text-19" class="terms-content-text-section">
				<h2 class="terms-content-text-section-title">
					{{ 'terms.section18.title' | transloco }}
				</h2>
				<p class="terms-content-text-section-description" [innerHTML]="'terms.section18.text' | transloco"></p>
			</div>
			<div id="terms-text-20" class="terms-content-text-section">
				<h2 class="terms-content-text-section-title">
					{{ 'terms.section19.title' | transloco }}
				</h2>
				<p class="terms-content-text-section-description" [innerHTML]="'terms.section19.text' | transloco"></p>
			</div>
			<div id="terms-text-21" class="terms-content-text-section">
				<h2 class="terms-content-text-section-title">
					{{ 'terms.section20.title' | transloco }}
				</h2>
				<p class="terms-content-text-section-description" [innerHTML]="'terms.section20.text' | transloco"></p>
			</div>
		</div>
	</div>
</div>
<app-footer></app-footer>