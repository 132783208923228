import { Address } from './address.model';
import { Bag } from './bag';
import { Deserializable } from './deserializable.model';
import { Order } from './order.model';

export class User implements Deserializable<User> {
	id?: string;
	name: {
		first: string;
		last: string;
	};
	email: string;
	number?: string;
	verified?: {
		email?: boolean;
		number?: boolean;
	};
	facebook?: {
		id?: string;
		token?: string;
	};
	stripe?: {
		customer?: {
			id?: string;
		};
	};
	bags?: Bag[];
	orders?: Order[];
	preferences?: {
		delivery?: {
			preferredAddress?: Address;
			noContact?: boolean;
			crates?: {
				reusable?: boolean;
			}
		},
		communications?: {
			language?: string;
			receiveMarketingEmails?: boolean;
		}
    };
	chatId?: string
	// _isStub: boolean = false;

    constructor() {
        this.preferences = {}
        this.preferences.delivery = {}
        this.preferences.communications = {}
    }

	get platformBags(): Bag[] {
		return this.bags.filter(bag => bag.isPlatformType)
	}
	
	get hasNonEmptyPlatformBags(): boolean {
		return this.platformBags?.length && this.platformBags?.some(bag => !bag.isEmpty);
	}

	// get isStub(): boolean {
	// 	return this._isStub;
	// }

	// set isStub(isStub: boolean) {
	// 	this._isStub = isStub;
	// }

	deserialize(input: any): this {
        if (!input) return null;
        if (typeof input == 'string') this.id = input;
        else {
            Object.assign(this, input);
            this.id = input._id ? input._id : input.id
            this.bags = input.bags?.map(bag => new Bag().deserialize(bag));
            this.orders = input.orders?.map(order => new Bag().deserialize(order));
            this.preferences.delivery.preferredAddress = new Address().deserialize(input?.preferences?.delivery?.preferredAddress);
		}
		return this;
	}
}

export interface UserStubData {
	id?: string,
	name?: {
		first?: string,
		last?: string
	},
	email?: string,
	number?: string,
	language?: string,
	firstTimePromotion?: boolean
}