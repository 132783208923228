<div class="restaurant-container">
    <div class="restaurant-overlay" *ngIf="restaurant?.slug != 'raku' || restaurant?.slug != 'chacos' || restaurant?.slug != 'nakamichi' || restaurant?.slug != 'teddy'"></div>
    <ng-container *ngIf="restaurant?.assets?.header?.url; else noHeaderImage">
        <div class="restaurant-hero" [ngStyle]="{'background-image': 'url(' + restaurant?.assets?.header?.url + ')'}" [ngClass]="'restaurant-hero-' + restaurant?.slug">
            <div class="restaurant-hero-header">
                <div class="restaurant-hero-header-logo-container">
                    <img [src]="restaurant?.assets?.logo?.primary?.url" class="page-hero-header-logo">
                </div>
            </div>
        </div>
    </ng-container>
    <ng-template #noHeaderImage>
        <div class="restaurant-hero" [ngStyle]="{'background-image': 'url(../../../../assets/images/expo/pocha/rectangle-bg.jpg)'}">
            <div class="restaurant-hero-header">
                <div class="restaurant-hero-header-logo-container">
                    <ng-container *ngIf="restaurant?.assets?.logo?.primary?.url; else noLogo">
                        <img [src]="restaurant?.assets?.logo?.primary?.url" class="page-hero-header-logo">
                    </ng-container>
                    <ng-template #noLogo>
                        <div class="restaurant-header-name">{{ restaurant?.name }}</div>
                    </ng-template>
                </div>
            </div>
        </div>
    </ng-template>
    <div class= "restaurant-menu-container">
        <div class="restaurant-menu-order-count-wrapper">
            <div class="restaurant-menu-order-count">
                <span [innerHtml]="'expo.restaurant.ordersUnderway' | transloco : { orderAmount: getNumberOrdersUnderway() }"></span>
                <span class="count-emoji"> {{ getEmojiFromOrderUnderway() }}</span>
            </div> 
        </div>
        <div class="restaurant-menu-section" *ngFor="let section of menu?.sections">
            <h3 class="restaurant-menu-section-name" [innerHTML]="section.title"></h3>
            <div *ngIf="section.description" class="restaurant-menu-section-description"
                [innerHTML]="section.description">
            </div>
            <expo-product-item *ngFor="let product of section.products" [product]="product" [isOpen]="isOpen"></expo-product-item>
        </div>
    </div>
</div>