import Dinero from 'dinero.js';

import { Product } from '../product/product.model';
import { Deserializable } from '../deserializable.model';
import { ProductOption } from '../product/product-option.model';
import { TranslatedTextType } from '../translated-text.model';

export class BagItem implements Deserializable<BagItem> {

    id: string;
    product: Product;
    quantity: number;
    options?: ProductOption[];
    price: Dinero.Dinero;
    notes?: string;

    constructor() {

    }

    deserialize(input: any): this {
        if (!input) return null;
        if (typeof input == 'string') this.id = input;
        else {
            Object.assign(this, input);
            this.id = input._id ? input._id : input.id
            this.product = new Product().deserialize(input?.product);
            this.price = input?.price.getAmount == null ? Dinero({ amount: input?.price?.amount, currency: input?.price?.currency }) : input.price;
            this.options = Array.isArray(input.options) ? input.options.map(option => new ProductOption().deserialize(option)) : []
        }
        return this;
    }

    get title(): TranslatedTextType {
        return this.product._title?.value;
    }

    get ImageUrl(): string {
        return this.product?.imageUrl;
    }

    get thumbnailUrl(): string {
        return this.product?.thumbnailUrl;
    }
}
