<nav class="navbar expo-nav-container">
    <a class="expo-nav-left radish-logo-wrapper expo-logo-wrapper" (click)="onClickRadishLogo()">
        <img id="radish-logo" class="expo-logo" src="../../../assets/images/radish-logos/Radish-Logo-BlackText-Large.png" alt="" />
    </a>
    <button type="button" class="expo-nav-middle expo-nav-order-schedule" (click)="showSchedule()" *ngIf="page != 'order'">
        <div class="expo-nav-title">{{ 'expo.nav.scheduler' | transloco }} <span class="expo-nav-schedule">{{ scheduledTime != null ? (scheduledTime | translocoDate : { timeStyle: 'short' }) : ('expo.nav.asap' | transloco) }}</span>
            <svg class="expo-nav-icon" width="8" height="8">
                <polygon points="0,0 4,4 8,0"/>
            </svg>
        </div>
    </button>
    <a class="expo-nav-right expo-logo-wrapper" routerLink="../pocha">
        <img class="expo-logo"  src="../../../assets/images/expo/pocha/pocha-mtl.jpg">
    </a>
    <expo-nav-scheduler (onCloseEvent)="showSchedule()" [ngClass]="isSchedule ? 'scheduler show-schedule' : 'scheduler hide-schedule'"></expo-nav-scheduler>
</nav>
