import { Deserializable } from '../deserializable.model';
import { TranslatedText, TranslatedTextType } from '../translated-text.model';
import { Ingredient } from './ingredient.model';
export class Food implements Deserializable<Food>{

    shelfLife?: {
        limits?: {
            lower?: number,
            upper?: number
        }
    };
    preparation?: {
        _instructions?: TranslatedText[];
    };
    weight?: number;
    volume?: number;
    // ingredients?: Ingredient[];
    _ingredientsDescription?: TranslatedText;
    nutrition?: {
        kcal?: number;
    };

    deserialize(input: any): Food {
        if (!input) return null;
        Object.assign(this, input);
        this._ingredientsDescription = new TranslatedText().deserialize(input._ingredientsDescription);
        this.preparation._instructions = input.preparation?.instructions.map(instruction => new TranslatedText().deserialize(instruction.text));
        return this;
    }

    get instructions() {
        return this.preparation?._instructions.map(instruction => instruction?.value);
    }

    set instructions(instructions: TranslatedTextType[]) {
        this.preparation._instructions = instructions as TranslatedText[];
    }

    get ingredients() {
        return this._ingredientsDescription?.value;
    }

    set ingredients(ingredients: TranslatedTextType) {
        this._ingredientsDescription = ingredients as TranslatedText;
    }
}
