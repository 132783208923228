import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RestaurantSlug, RestaurantStyle } from '../../../core/enums';

import { Restaurant, Review } from '../../../core/models';
import { ConfigService } from 'src/app/core/services/config.service';
import { Observable } from 'rxjs';
import { MainService } from 'src/app/core/services/main.service';

@Component({
    selector: 'app-restaurant-about',
    templateUrl: './restaurant-about.component.html',
    styleUrls: ['./restaurant-about.component.css'],
})
export class RestaurantAboutComponent implements OnInit {

    RestaurantSlug = RestaurantSlug;
    RestaurantStyle = RestaurantStyle

    restaurant$: Observable<Restaurant>;
    reviews: Review[];

    constructor(
        private mainService: MainService,
        private route: ActivatedRoute,
        public configService: ConfigService
    ) { }

    ngOnInit() {
        this.restaurant$ = this.mainService.restaurantLoaded$;
        this.route.data.subscribe(res => this.reviews = res?.data ? res.data : []);
    }
}
