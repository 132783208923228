import { Directive, ElementRef } from '@angular/core';

@Directive({
    selector: '[appCenterAndFitBackgroundImage]'
})
export class CenterBackgroundImageDirective {

    constructor(el: ElementRef) {
        el.nativeElement.style.backgroundSize = 'cover';
        el.nativeElement.style.backgroundRepeat = 'no-repeat';
        el.nativeElement.style.backgroundPosition = '50% 50%';
    }

}
