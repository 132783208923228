import { Injectable } from "@angular/core";
import { Routes } from "@angular/router";

@Injectable({
	providedIn: 'root',
})
export class MarketCategoryService {
    
    static readonly CATEGORIES = [{
        'title': {
            'en': 'Bakery',
            'fr': 'Boulangerie'
        },
        'icon': '../../assets/images/market/categories/bakery.png',
        'route': 'bakery'
    },
    {
        'title': {
            'en': 'Ready-to-Eat',
            'fr': 'Prêt-à-manger'
        },
        'icon': '../../assets/images/market/categories/ready-to-eat.png',
        'route': 'ready-to-eat'
    },
    {
        'title': {
            'en': 'Produce',
            'fr': 'Fruiterie'
        },
        'icon': '../../assets/images/market/categories/produce.png',
        'route': 'produce'
    },
    {
        'title': {
            'en': 'Pantry',
            'fr': 'Garde-manger'
        },
        'icon': '../../assets/images/market/categories/grocery.png',
        'route': 'pantry'
    },
    {
        'title': {
            'en': 'Cheese',
            'fr': 'Fromage'
        },
        'icon': '../../assets/images/market/categories/cheese.png',
        'route': 'cheese'
    },
    {
        'title': {
            'en': 'Butcher',
            'fr': 'Boucherie'
        },
        'icon': '../../assets/images/market/categories/meat.png',
        'route': 'meat'
    },
    {
        'title': {
            'en': 'Fishmonger',
            'fr': 'Poissonnerie'
        },
        'icon': '../../assets/images/market/categories/prawn.png',
        'route': 'fish'
    },
    {
        'title': {
            'en': 'Drinks',
            'fr': 'Boissons'
        },
        'icon': '../../assets/images/market/categories/drinks.png',
        'route': 'drinks'
    },
    {
        'title': {
            'en': 'Home',
            'fr': 'Chez-soi'
        },
        'icon': '../../assets/images/market/categories/home.png',
        'route': 'home'
    }]

    constructor() { }


    static compileMarketRoutes(): Routes {
        let routes: Routes = []
        for (let category of this.CATEGORIES) {
            routes.push({
                path: `marche/${category.route}`,
                redirectTo: `language/marché/${category.route}`
            });
            routes.push({
                path: `marché/${category.route}`,
                redirectTo: `language/marché/${category.route}`
            });
            routes.push({
                path: `:language/marché/${category.route}`,
                data: {
                    type: 'category',
                    category: category.route
                }
            });
        }
        return routes;
    }

    static get topLevelSlugs(): string[] {
        return this.CATEGORIES.map(category => category.route);
    }
}