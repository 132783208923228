import { Food } from './food.model';
import { Restaurant } from '../restaurant.model';
import { Deserializable } from '../deserializable.model';
import { Media } from '../media.model';
import { TranslatedText, TranslatedTextType } from '../translated-text.model';
import { ProductOption } from './product-option.model';
import { OrderType } from '../../enums';
import { Fee } from '../fee.model';
import { Inventory } from '../inventory.model';
import { Measurement } from '../measurement.model';

import Dinero from 'dinero.js';
import { Category, CategoryType } from '../category.model';

export class Product implements Deserializable<Product> {

    _id: string;
    _title?: TranslatedText;
    _brief?: TranslatedText;
    _description?: TranslatedText;
    restaurant: Restaurant;
    types?: OrderType[];
    price: Dinero.Dinero;
    fees?: Fee[];
    overrides?: Fee[];
    options?: ProductOption[];
    foodInfo?: Food;
    isOption?: boolean = false;
    subproducts?: {
        quantity: number;
        product: Product;
    }[];
    categories?: Category[];
    assets?: {
        primary?: Media;
        gallery?: any;
    };
    mappings?: [{
        app?: string;
        value?: string;
    }];
    isAvailable: boolean = true;
    isArchived: boolean = false;
    servings?: {
        count?: number;
        unit?: Measurement;
    };
    inventories?: Inventory[];
    slug?: string;

    constructor() { }

    get id(): string {
        return this._id;
    }

    set id(id: string) {
        this._id = id;
    }

    get title() {
        return this._title?.value;
    }

    set title(title: TranslatedTextType) {
        this._title = title as TranslatedText;
    }

    get brief() {
        return this._brief?.value;
    }

    set brief(brief: TranslatedTextType) {
        this._brief = brief as TranslatedText;
    }

    get description() {
        return this._description?.value;
    }

    set description(description: TranslatedTextType) {
        this._description = description as TranslatedText
    }

    deserialize(input: any): this {
        if (!input) return null;
        if (typeof input == 'string') this.id = input;
        else {
            Object.assign(this, input);
            this.id = input._id ? input._id : input.id
            if (typeof input?.price?.amount == 'number') {
                this.price = Dinero({ amount: input.price.amount, currency: input.price?.currency });
            }
            this.restaurant = new Restaurant().deserialize(input?.restaurant);
            this.options = Array.isArray(input.options) ? input.options.map(option => new ProductOption().deserialize(option)) : [];
            this.assets = input.assets ? input.assets : {}
            this.assets.primary = new Media().deserialize(input?.assets?.primary);

            this.title = new TranslatedText().deserialize(input.title);
            this.brief = new TranslatedText().deserialize(input.brief);
            this.description = new TranslatedText().deserialize(input.description);

            this.fees = Array.isArray(input.fees) ? input.fees.map(fee => new Fee().deserialize(fee)) : [];
            this.overrides = Array.isArray(input.overrides) ? input.overrides.map(fee => new Fee().deserialize(fee)) : [];
            this.categories = Array.isArray(input.categories) ? input.categories.map(category => new Category().deserialize(category)) : [];
            this.inventories = Array.isArray(input.inventories) ? input.inventories.map(inventory => new Inventory().deserialize(inventory)) : [];

            this.foodInfo = new Food().deserialize(input.foodInfo);

            if (input?.servings?.unit) this.servings.unit = new Measurement().deserialize(input.servings.unit);

            this.subproducts = Array.isArray(input.subproducts) ? input.subproducts.map(subproduct => ({
                quantity: subproduct.quantity,
                product: new Product().deserialize(subproduct.product)
            })) : [];
        }
        return this;
    }

    get primaryProductCategory(): Category {
        let category = this.categories.find(category => category.type == CategoryType.Product && category.isBaseCategory);
        if (category == null) category = new Category({ en: 'Uncategorized', fr: 'Non classé' } as TranslatedTextType, CategoryType.Product, 'uncategorized')
        return category;
    }

    get cuisines(): Category[] {
        return this.categories?.length ? this.categories.filter(category => category.type = CategoryType.Cuisine) : [];
    }

    set cuisines(cuisines: Category[]) {
        let ids = cuisines.map(cuisine => cuisine.id);
        this.categories = this.categories.reduce((categories, category) => {
            if (category.type != CategoryType.Cuisine) return categories;
            let cuisineIndex = ids.findIndex(id => id == category.id);
            if (cuisineIndex != -1) { //found, stop searching for it
                ids.splice(cuisineIndex, 1);
                cuisines.splice(cuisineIndex, 1);
            }
            else if (cuisineIndex == -1) return categories.filter(c => c.id != category.id);
        }, this.categories);
        this.categories = this.categories.concat(cuisines); //Append remaining new cuisines
    }

    get isFrozen(): boolean {
        return this.categories?.some(category => category.slug == 'frozen' && category.type == CategoryType.Storage)
    }

    get restrictionCategories(): Category[] {
        return this.categories?.filter(category => category.type == CategoryType.Restriction)
    }

    get thumbnailUrl(): string {
        return this.assets?.primary?.thumbnailUrl
    }

    get marketThumbnailUrl(): string {
        return this.assets?.primary?.thumbnails.find(thumbnail => thumbnail?.dimensions?.width >= 200)?.url || this.imageUrl;
    }

    get imageUrl(): string {
        return this.assets?.primary?.url
    }

    get searchResultIconUrl(): string {
        return this.marketThumbnailUrl;
    }
}
