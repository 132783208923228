import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import moment, { Moment } from 'moment';
import { filter, Observable } from 'rxjs';
import { Bag, Exposition, Restaurant } from 'src/app/core/models';
import { ConfigService } from 'src/app/core/services/config.service';
import { MainService } from 'src/app/core/services/main.service';

@UntilDestroy()
@Component({
    selector: 'expo-nav-scheduler',
    templateUrl: './nav-scheduler.component.html',
    styleUrls: ['./nav-scheduler.component.css']
})
export class NavSchedulerComponent implements OnInit {

    @Output() onCloseEvent: EventEmitter<any> = new EventEmitter<{}>();

    bag$: Observable<Bag>;
    restaurant$: Observable<Restaurant>;
    scheduledDate$ : Observable<Date>;

    exposition: Exposition;

    listOfSchedules: Date[] = [];
    selectedDate: Date;

    expoSlug: string;
    restaurantSlug: string;
    bag: Bag;
    page: string;

    currentDate: Moment;
    delay: string;

    constructor(
        private mainService: MainService,
        private router: Router,
        private route: ActivatedRoute
    ) {}

    ngOnInit(): void {
        this.scheduledDate$ = this.mainService.scheduledDateLoaded$;
        this.scheduledDate$.subscribe(scheduledDate => this.selectedDate = scheduledDate);

        this.bag$ = this.mainService.expoBagLoaded$;
        this.bag$.subscribe(bag => {
            this.bag = bag;
            if (this.bag?.isEmpty) this.generateAvailableSchedules(this.page);
        });
        this.restaurant$ = this.mainService.expoBagRestaurant$;

        this.currentDate = moment();
        this.expoSlug = this.route.snapshot.paramMap.get("slug");
        this.exposition = this.route.snapshot.data["exposition"];
        this.generateAvailableSchedules();
        this.delay = this.findDelay();

        this.router.events.pipe(untilDestroyed(this), filter(event => event instanceof NavigationEnd)).subscribe(event => {
            let route = (event as RouterEvent).url.split('/');
            this.page = route.pop();
            if (this.page === "products") this.page = route.pop();
            this.generateAvailableSchedules(this.page);
            this.delay = this.findDelay(this.page);
		})
    }

    findDelay(page: string = null): string {
        let slug = this.mainService.restaurantOnExpoBag?.slug;
        slug = slug && this.bag && !this.bag.isEmpty ? slug: page;
        let vendor = this.exposition?.vendors.filter(vendor => vendor?.vendor?.slug === slug).pop();
        if (vendor == null) return;
        let delay = vendor?.delay / 60
        if (delay < 15) return "< 15";
        else return `${delay}`;
    }

    findSchedule(page: string = null) {
        let slug = this.mainService.restaurantOnExpoBag?.slug;
        slug = slug && this.bag && !this.bag.isEmpty ? slug : page;
        let vendor = this.exposition?.vendors.filter(vendor => vendor?.vendor?.slug === slug);

        let schedule = vendor?.find(vendor => vendor)?.menus
            .filter(menu => menu.type = "expo")
            .map(menu => menu.schedule)
            .find(schedule => schedule);
        return schedule != undefined ? schedule[this.currentDate.weekday()].find(date => date) : this.findTodaySchedule(this.exposition?.dates);
    }

    findTodaySchedule(dates: any) {
        return dates?.find((date: any) => moment().isSame(date?.start, 'day'));
    }

    generateAvailableSchedules(page: string = null) {
        this.listOfSchedules = [];
        let schedule: any = this.findSchedule(page);
        if (schedule == null) return;
        let startDate = this.getCurrentDate(schedule.start);
        let endDate = this.getCurrentDate(schedule.end);

        endDate = startDate.isSame(endDate) ? endDate.add(1, 'day') : endDate;
        endDate = endDate.subtract(30, 'minute');
        startDate = startDate.isBefore(this.currentDate) ? this.currentDate.clone() : startDate;
        
        let remainder = 30 - (startDate.minute() % 30);
        startDate.add(remainder, 'minute').second(0).millisecond(0);
        
        while (startDate.isBefore(endDate)) {
            startDate = startDate.add(30, 'minute')
            this.listOfSchedules.push(startDate.toDate());
        }
    }

    getCurrentDate(date: Date): Moment {
        let scheduledDate = moment(date);
        let result = moment()
            .hour(scheduledDate.hour())
            .minute(scheduledDate.minute())
            .second(0)
            .millisecond(0);
        return result.clone();
    }

    onScheduleChange(date: Date) {
        this.selectedDate = date;
    }

    onCloseSchedule() {
        this.onCloseEvent.emit(null);
    }

    onConfirmSchedule() {
        this.mainService.scheduledDate = this.selectedDate;
        this.onCloseEvent.emit(null);
    }

    isSameDate(scheduledDate: Date, itemDate: Date): boolean {
        return scheduledDate != null && moment(scheduledDate).isSame(itemDate)
    }

}
