import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslocoService } from '@ngneat/transloco';
import { Icon } from 'src/app/core/enums';
import { ColorService } from 'src/app/core/utils/color.service';
import { MenuSection } from 'src/app/core/models';
import { MenuSectionsListComponent } from '../menu-sections-list/menu-sections-list.component';

@Component({
    selector: 'app-horizontal-mobile-sections-nav',
    templateUrl: './horizontal-mobile-sections-nav.component.html',
    styleUrls: ['./horizontal-mobile-sections-nav.component.css']
})
export class HorizontalMobileSectionsNavComponent implements OnInit {

    private readonly SCROLL_OFFSET = 100

    @Input() sections: MenuSection[] = []
    @Input() selectedSection: MenuSection;
    @Input() selectedColor: string;
    @Input() endsGradientColor: string

    @Output() onSectionClicked: EventEmitter<MenuSection> = new EventEmitter<MenuSection>()

    @ViewChild('nav') nav: ElementRef;
    @ViewChild('sectionsContainer') sectionsContainer: ElementRef

    rightScrollable: boolean = true
    leftScrollable: boolean = false
    leftAndRightScrollable: boolean = false

    Icon = Icon

    constructor(
        public translate: TranslocoService,
        private modalService: NgbModal,
        private colorUtil: ColorService) {}

    ngOnInit(): void {
        if (!this.selectedSection) this.selectedSection = this.sections[0]
    }

    onScroll() {
        this.rightScrollable = this.nav.nativeElement.scrollWidth - this.nav.nativeElement.offsetWidth > this.nav.nativeElement.scrollLeft
        this.leftScrollable = this.nav.nativeElement.scrollLeft != 0
        this.leftAndRightScrollable = this.rightScrollable && this.leftScrollable
    }

    clickSection(section: MenuSection) {
        this.selectedSection = section
        this.onSectionClicked.emit(section)
    }

    onClickExpandSections() {
        const modalRef = this.modalService.open(MenuSectionsListComponent);
        modalRef.componentInstance.sections = this.sections

        modalRef.result.then(
            section => this.onSectionClicked.emit(section),
            _ => {}
        )
    }

    onClickScrollRight() {
        this.sectionsContainer.nativeElement.scrollBy({
            top: 0,
            left: this.sectionsContainer.nativeElement.scrollLeft + this.sectionsContainer.nativeElement.offsetWidth - this.SCROLL_OFFSET,
            behavior: 'smooth'
        })
    }

    onClickScrollLeft() {
        this.sectionsContainer.nativeElement.scrollBy({
            top: 0,
            left: -(this.sectionsContainer.nativeElement.offsetWidth - this.SCROLL_OFFSET),
            behavior: 'smooth'
        })
    }

    getArrowLeftContainerStyle() {
        return {
            'background': 'linear-gradient(90deg,' + this.colorUtil.hexToRgbaString(this.endsGradientColor) + ' 76%, rgba(255,255,255,0) 100%)'
        }
    }

    getArrowRightContainerStyle() {
        return {
            'background': 'linear-gradient(90deg, rgba(255,255,255,0) 0%, ' + this.colorUtil.hexToRgbaString(this.endsGradientColor) + ' 24%)'
        }
    }

}
