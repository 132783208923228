import { Component, Input, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { ExtendedOrderType } from 'src/app/core/enums';
import { SearchResult } from 'src/app/core/models';
@Component({
  selector: 'app-product-popover',
  templateUrl: './product-popover.component.html',
  styleUrls: ['./product-popover.component.css']
})
export class ProductPopoverComponent {
  @Input() product: SearchResult;

  ExtendedOrderType = ExtendedOrderType;

  constructor(public translate: TranslocoService) { }

}
