<div [formGroup]="marketCheckoutForm">
    <div class="crate-delivery-scheduler">
        <img class="clock-icon" src="../../assets/images/icons/clock.svg">
        <ng-select class="delivery-time-select" [placeholder]="'checkout.details.timeDropdownPlaceholder' | transloco"
            [searchable]="false" [selectOnTab]="true" [clearable]="false" (change)="setTime(selectedTime)"
            formControlName="scheduledDateTime">
            <ng-option *ngFor="let time of times" [value]="time.start">
                <ng-container>
                    {{ time.label[translate.getActiveLang()] }}
                </ng-container>
            </ng-option>
        </ng-select>
    </div>
</div>