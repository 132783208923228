<nav class="navbar navbar-expand-md navbar-light" [class.navbar-blog]="type == 'blog'" [class.navbar-white]="type == 'restaurant' || type == 'checkout' || type == 'account'" 
    [class.navbar-grey]="type == 'restaurants' || type == 'market'" [class.navbar-transparent]="type == 'marketCheckout' || type == 'main'" [class.navbar-other]="type == 'other'" [class.navbar-landing]="type == 'landing'" [class.navbar-landing-scroll]="whiteBg == true" [class.navbar-restaurantV2]="type == 'restaurantV2'" 
    [ngClass]="(restaurant$ | async)?.slug" #navbar>
    <div class="navbar-logo-options-container me-auto" [ngClass]="{'navbar-logo-options-help-container' : type == 'marketCheckout'}">
        <a class="navbar-logo-container" routerLink="{{ getLogoRoute() }}" (click)="onClickRadishLogo()">
            <img id="navbar-logo" src="../../assets/images/radish-logos/Radish-Logo-BlackText-Large.png" alt="Radish" />
        </a>
        <app-search-bar *ngIf="type == 'market'"></app-search-bar>
        <div ngbDropdown *ngIf="type == 'restaurant' || type == 'restaurants' || type == 'restaurantV2'" (openChange)="dropdownOpenChange($event)" 
             class="d-inline-block navbar-menu-type-dropdown-container" #orderSettingsDropdown="ngbDropdown" [autoClose]="'outside'">
            <div ngbDropdownToggle id="menu-type-dropdown" class="navbar-options-container" type="button" [ngClass]="orderType$ | async">
                <div *ngIf="(orderType$ | async) === 'pickup'" class="navbar-order-type navbar-option">
                    <span class="navbar-option-menu-type">{{ 'menuTypeDropdown.pickup' | transloco | uppercase }}</span>
                    <span class="em-dash"> — </span>
                    <span *ngIf="scheduledDate$ | async" class="navbar-option-time">
                        {{ (((scheduledDate$ | async) | translocoDate: longScheduledDateFormat) + ' - ' + (scheduledTimePlusThirty | translocoDate: shortScheduledDateFormat)) }}
                    </span>
                    <span *ngIf="!(scheduledDate$ | async)">{{ 'menuTypeDropdown.asap' | transloco }}</span>
                </div>
                <ng-container *ngIf="!((orderType$ | async) === 'pickup')">
                    <!-- TODO: If repeated then extract -->
                    <div class="navbar-option d-none d-lg-block">
                        <div class="navbar-order-type">
                            <span class="navbar-option-menu-type">{{'menuTypeDropdown.delivery' | transloco | uppercase }}</span>
                            <span class="em-dash"> — </span>
                            <span *ngIf="scheduledDate$ | async" class="navbar-option-time">
                                {{ (((scheduledDate$ | async) | translocoDate: longScheduledDateFormat) + ' - ' + (scheduledTimePlusThirty | translocoDate: shortScheduledDateFormat)) }}
                            </span>
                            <span *ngIf="!(scheduledDate$ | async)">{{ 'menuTypeDropdown.asap' | transloco }}</span>
                        </div>
                        <div class="navbar-address">{{ (address$ | async | address: 'short') || ('navbar.addressPlaceholder' | transloco) }}</div>
                    </div>
                    <div class="navbar-option d-block d-lg-none">
                        <div class="navbar-order-type">
                            <span class="navbar-option-menu-type">{{ 'menuTypeDropdown.delivery' | transloco | uppercase }}</span>
                            <span class="em-dash"> — </span>
                            <span *ngIf="scheduledDate$ | async" class="navbar-option-time">
                                {{ (((scheduledDate$ | async) | translocoDate: longScheduledDateFormat) + ' - ' + (scheduledTimePlusThirty | translocoDate: shortScheduledDateFormat)) }}
                            </span>
                            <span *ngIf="!(scheduledDate$ | async)">{{ 'menuTypeDropdown.asap' | transloco }}</span>
                        </div>
                        <div class="navbar-address">{{ ((address$ | async | address: 'short')?.split(',')[0]) || ('navbar.addressPlaceholder' | transloco) }}</div>
                    </div>
                </ng-container>
            </div>
            <div ngbDropdownMenu aria-labelledby="menu-type-dropdown" class="navbar-menu-type-dropdown">
                <app-order-settings [isLoading]="isLoading" (closeSettings)="orderSettingsDropdown.toggle()" (openSettings)="orderSettingsDropdown.open()"></app-order-settings>
            </div>
        </div>
        <div *ngIf="type=='marketCheckout'" class="get-help-container">
            <i class="fas fa-phone-alt"></i>
            <div class="get-help-wrapper">
                {{ 'support.getHelp' | transloco }} <a class="get-help-tel-link" href="tel:514-500-7998"> <strong>{{ 'support.phoneNumber' | transloco }}</strong></a>
            </div>
        </div>
    </div>

    <div class="d-flex d-md-none ml-auto">
        <button class="nav-item navbar-bag navbar-bag-black" *ngIf="type == 'restaurant' || type == 'restaurants' || type == 'restaurantV2' || type == 'market'" (click)="clickedIcon = true; toggleBag()">
            <img id="animated-bag" [src]="type == 'market' ? crateImgSrc : bagImgSrc" [alt]="type == 'market' ? 'Your Crate' : 'Your Bag'">
            <div [ngStyle]="{ 'font-size': largeBag === true ? '0.8rem' : '1rem', 'color': totalItems === 0 || type == 'market' ? '#000000' : '#FFFFFF' }" 
                 class="navbar-bag-total" [ngClass]="type == 'market' ? 'navbar-crate-total' : ''">
                {{ totalItems }}
            </div>
        </button>
    </div>
    <button *ngIf="type == 'landing'" class="nav-item nav-order-now d-block d-md-none ml-auto" routerLink="./restaurants">
        {{ 'navbar.orderNow' | transloco }}
    </button>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" 
            aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
    </button>

    <div class="navbar-controls collapse navbar-collapse" id="navbarSupportedContent">
        <div class="navbar-nav ms-auto">
            <button *ngIf="type == 'landing'" class="nav-item nav-order-now d-none d-md-block" routerLink="./restaurants">
                {{ 'navbar.orderNow' | transloco }}
            </button>
            <div *ngIf="user$ | async" class="ngbDropdown" ngbDropdown #myDrop="ngbDropdown">
                <button class="nav-item navbar-user-dropdown" id="dropdownManual" ngbDropdownAnchor
                    (mouseenter)="$event.stopPropagation(); myDrop.open()"
                    (mouseleave)="$event.stopPropagation(); myDrop.close()">
                    <img class="navbar-user-dropdown-icon navbar-icon" src="../../assets/images/icons/user.svg" />
                    <div class="navbar-user-dropdown-name">{{ (user$ | async)?.name.first }}</div>
                </button>
                <div ngbDropdownMenu aria-labelledby="dropdownManual" class="logout-dropdown-menu"
                    (mouseenter)="$event.stopPropagation(); myDrop.open()"
                    (mouseleave)="$event.stopPropagation(); myDrop.close()">
                    <!-- <div ngbDropdownItem class="navbar-user-dropdown-item" [routerLink]="'/' + translate.getActiveLang() + '/account'">{{'navbar.account' | transloco}}</div> -->
                    <!-- <div ngbDropdownItem class="navbar-user-dropdown-item">Order History</div> -->
                    <div ngbDropdownItem class="navbar-user-dropdown-item" (click)="logout()">
                        {{ 'navbar.logout' | transloco }}
                    </div>
                </div>
            </div>
            <button *ngIf="type == 'landing'" class="nav-item nav-about" routerLink="." fragment="about">
                {{ 'navbar.about' | transloco }}
            </button>
            <!-- <button *ngIf="type == 'landing'" class="nav-item nav-join" routerLink="." fragment="joinUs">
                {{ 'navbar.join' | transloco }}
            </button> -->
            <button *ngIf="!(user$ | async) && type != 'landing'" class="nav-item navbar-account" (click)="login()">
                {{ 'navbar.signup' | transloco }}
            </button>
            <button class="nav-item navbar-bag d-none d-md-block" *ngIf="type == 'restaurant' || type == 'restaurants' || type == 'restaurantV2' || type == 'market' " 
                    (click)="clickedIcon = true; toggleBag()">
                <img id="animated-bag" [src]="type == 'market' ? crateImgSrc : bagImgSrc" [alt]="type == 'market' ? 'Your Crate' : 'Your Bag'">
                <div [ngStyle]="{ 'font-size': largeBag === true ? '0.8rem' : '1rem', 'color': totalItems === 0 || type == 'market' ? '#000000' : '#FFFFFF' }"  
                     class="navbar-bag-total" [ngClass]="type == 'market' ? 'navbar-crate-total' : ''">
                    {{ totalItems }}
                </div>
            </button>
            <button *ngIf="translate.getActiveLang() == 'en'" class="nav-item navbar-language"(click)="useLanguage('fr')" data-toggle="collapse"    
                    data-target="#navbarSupportedContent" aria-expanded="false" aria-controls="navbarSupportedContent">
                FR
            </button>
            <button *ngIf="translate.getActiveLang() == 'fr'" class="nav-item navbar-language" (click)="useLanguage('en')" data-toggle="collapse" 
                    data-target="#navbarSupportedContent" aria-expanded="false" aria-controls="navbarSupportedContent">
                EN
            </button>
        </div>
    </div>
    <div *ngIf="type == 'market'">
        <app-crate [(showCrate)]="showCrate" [clickIcon]="clickedIcon" (resetIcon)="resetIcon($event)"></app-crate>
    </div>
    <div *ngIf="type != 'market'">
        <app-bag [(showBag)]="showBag" [clickIcon]="clickedIcon" (resetIcon)="resetIcon($event)"></app-bag>
    </div>
</nav>