import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'phoneNumber' })

export class PhoneNumberPipe implements PipeTransform {

    transform(phoneNumber: string, format: string = 'standard'): string {
        if (phoneNumber == null || phoneNumber !== phoneNumber) return null;

        if (format == 'short') return
        
        else {
            var cleaned = ('' + phoneNumber).replace(/\D/g, '')
            var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
            if (match) {
                return '(' + match[1] + ') ' + match[2] + '-' + match[3]
            }
            return null
        };
  }
}