<!-- NOTE - HANDLE MAKING THE COMPONENT FULLY INVISIBLE (with ngIf) WHEN NOT IN USE -->
<div [@isVisible]="isFormMode ? 'invisible' : 'visible'" class="landing">
    <div class="column">
        <div class="typewriter">
            <h1>let's create an event!</h1>
        </div>
        <br>
        <br>
        <div style="display:flex; flex-direction:row; justify-content: center;">
            <button class="solid" (click)="startPartyForm()">
                <h1>Start</h1>
            </button>
        </div>
    </div>
</div>

<div *ngIf="isFormMode" [@isVisible]="isFormMode ? 'visible' : 'invisible'" class="form-content">

    <div class="form-column">
        <div *ngIf="formPosition >= 0" class="title">
            <div [ngClass]="formPosition > 0 ? 'nothing' : 'typewriter'" id="question-0">
                <h2>what are you calling it?</h2>
            </div>
            <div style="display:flex; flex-direction:row;">
                <input type="text" class="form-field" placeholder="write something snappy!">
                <button (click)="nextStep(0)" class="submit">✓</button>
            </div>
        </div>

        <div *ngIf="formPosition >= 1" class="title">
            <div [ngClass]="formPosition > 1 ? 'nothing' : 'typewriter'" id="question-1">
                <h2>when is your event?</h2>
            </div>
            <div style="display:flex; flex-direction:row;">
                <input type="datetime-local" class="form-field" placeholder="put date picker here">
                <button (click)="nextStep(1)" class="submit">✓</button>
            </div>
        </div>

        <div *ngIf="formPosition >= 2" class="title">
            <div [ngClass]="formPosition > 2 ? 'nothing' : 'typewriter'" id="question-2">
                <h2>who's organizing this?</h2>
            </div>
            <div style="display:flex; flex-direction:row;">
                <input type="text" class="form-field" placeholder="the boss of this operation!">
                <button (click)="nextStep(2)" class="submit">✓</button>
            </div>
        </div>
        
        <div *ngIf="formPosition >= 3" class="title">
            <div [ngClass]="formPosition > 3 ? 'nothing' : 'typewriter'" id="question-3">
                <h2>where is your event?</h2>
            </div>
            <div style="display:flex; flex-direction:row;">
                <input type="text" class="form-field" placeholder="where it's all going down">
                <button (click)="nextStep(3)" class="submit">✓</button>
            </div>
        </div>
    </div>

    <div class="form-column">
        <div *ngIf="formPosition >= 4" class="title">
            <div [ngClass]="formPosition > 4 ? 'nothing' : 'typewriter'" id="question-0">
                <h2>Let's add a picture!</h2>
                <button (click)="nextStep(4)" class="submit">✓</button>
            </div>
            <div *ngIf="formPosition >= 4" style="width:20rem; height:6rem; background-color:lightgrey; border-radius:1rem;">
                <ngx-file-drop dropZoneLabel="Drop files here"></ngx-file-drop>
            </div>

            
        </div>

        <div *ngIf="formPosition >= 5" class="title">
            <div [ngClass]="formPosition > 5 ? 'nothing' : 'typewriter'" id="question-0">
                <h2>Cater event with Radish?</h2>
            </div>
            <div style="display:flex; flex-direction:row;">
                <input type="text" class="form-field" placeholder="N/A">
                <button (click)="nextStep(5)" class="submit">✓</button>
            </div>
        </div>

        <div *ngIf="formPosition >= 6" class="title">
            <div [ngClass]="formPosition > 6 ? 'nothing' : 'typewriter'" id="question-0">
                <h2>Extra event details:</h2>
            </div>
            <div style="display:flex; flex-direction:row;">
                <input type="text" class="form-field" placeholder="Put any additional info here">
                <button (click)="nextStep(6)" class="submit">✓</button>
            </div>
        </div>
    </div>
    
</div>

<div *ngIf="formPosition >= 7" class="landing" style="position:fixed; z-index:10;">
    <div class="column" style="justify-content: flex-end; padding-bottom:5rem;">
        <button class="solid" [routerLink]="['/' + translate.getActiveLang() + '/parties']">
            <h1>Create Party!</h1>
        </button>
    </div>
</div>