import { Component, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { Router, NavigationStart, ActivatedRoute } from '@angular/router';
import { map, mergeMap, Observable } from 'rxjs';
import { Restaurant } from 'src/app/core/models';
import { MainService } from 'src/app/core/services/main.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ProductService } from 'src/app/core/services/product.service';
import { categorize } from 'src/app/core/helpers/categorize.helper';
import { RestaurantSlug } from 'src/app/core/enums';
import { MarketCategoryService } from 'src/app/core/services/data/market-category.service';

@UntilDestroy()
@Component({
	selector: 'app-market-restaurant',
	templateUrl: './market-restaurant.component.html',
	styleUrls: ['./market-restaurant.component.css'],
})
export class MarketRestaurantComponent implements OnInit {

	restaurant$: Observable<Restaurant>;
	restaurantMarketHeaderImage: string; //TODO: refactor this out
	showNavigationIndicators: boolean = false;
	showNavigationArrows: boolean = false; // TEMPORARY: fix carousel issues and then re-add the nav arrows
	productSections: any;
	showRestaurantName: boolean = false;
	resolvingSections: boolean = false;

	categories = MarketCategoryService.CATEGORIES;

	//mock data 
	carouselItems = [
		{
			title: "",
			url: "../../../../assets/images/barranco/barranco-header.jpeg",
			type: 'image'
		}
		// {
		// 	title: "",
		// 	url: "../../../../assets/temp/panda-video.mp4",
		// 	type: 'video'
		// }
	];

	constructor(
		private router: Router,
		public translate: TranslocoService,
		public mainService: MainService,
		private productService: ProductService,
		private route: ActivatedRoute,
		public marketCategoryService: MarketCategoryService
	) { }

	ngOnInit(): void {
		this.restaurant$ = this.mainService.restaurantLoaded$;
		this.restaurant$.subscribe((restaurant) => {
			this.restaurantMarketHeaderImage = restaurant.marketHeaderImageUrl;
		});
		this.router.events.subscribe((event) => {
			if (!(event instanceof NavigationStart)) {
				return;
			}
			window.scrollTo(0, 0)
		})

		this.route.data.pipe(
			untilDestroyed(this),
			mergeMap(data => this.productService.queryForMarket(null, [data.restaurant.id])),
			map(products => categorize(products, null, 'quantity'))
		).subscribe(sections => this.productSections = sections);
	}

	filterCategories(categories, types) {
		if (!categories) return [];
		else return categories.filter(category => types.includes(category.type));
	}

	getRestaurantImage(slug: string) {
		switch (slug) {
			case RestaurantSlug.Croqueteria:
				return '../../../assets/images/market/featured-restaurants/croqueteria-market.jpg';
			case RestaurantSlug.KojiSoupe:
				return '../../../assets/images/market/featured-restaurants/kojisoupe-market.jpg';
			case RestaurantSlug.Jaimee:
				return '../../../assets/images/market/featured-restaurants/jaimee-market.jpg';
			case RestaurantSlug.TeochewFoodie:
				return '../../../assets/images/market/featured-restaurants/teochew-market.jpg';
			case RestaurantSlug.GPizzas:
				return '../../../assets/images/market/featured-restaurants/gpizzas-market.jpg';
			case RestaurantSlug.Andouille:
				return '../../../assets/images/market/featured-restaurants/landouille-market.jpg';
			case RestaurantSlug.PrettyUglyCompany:
				return '../../../assets/images/market/featured-restaurants/prettyugly-market.jpg';
			default:
				return this.restaurantMarketHeaderImage ? this.restaurantMarketHeaderImage : '../../../assets/images/radish-logos/Radish-square-transparent.png';
		}
	}

}
