<app-navbar [navbarType]="'checkout'"></app-navbar>
<div class="followup-container">
    <div class="header-container">
        <div class="page-header">{{ 'followup.status' | transloco }}</div>
        <div *ngIf="(order$ | async)" class="followup-restaurant-name-container">
            {{ 'followup.from' | transloco }}
            <a class="restaurant-name" [routerLink]="['/', translate.getActiveLang(), 'restaurants', (order$ | async)?.restaurant?.slug]">
                <strong>{{ (order$ | async)?.restaurant?._name.value }}</strong>
            </a>
        </div>
    </div>
    <div *ngIf="(order$ | async)" class="map-container">
        <div class="map-inner-container">
            <div class="map-left-container">
                <div class="followup-order-number-container solid-box">
                    <div *ngIf="(order$ | async)" class="followup-order-number-title">
                        {{ (order$ | async)?.status === OrderStatus.Cancelled
                            ? ('followup.orderCancelled' | transloco)
                            : ((order$ | async)?.status === OrderStatus.Refused
                                ? ('followup.orderRefused' | transloco)
                                : ((order$ | async)?.status === OrderStatus.Delivered)
                                    ? ('followup.orderDelivered' | transloco)
                                    : ('followup.orderPlaced' | transloco)) }}
                    </div>
                    <div class="followup-order-number">
                        {{ 'followup.orderNumber' | transloco }}
                        <strong>{{ (order$ | async)?.number }}</strong>
                    </div>
                </div>
                <div *ngIf="(orderType$ | async) === 'pickup' && ((order$ | async)?.status !== OrderStatus.Cancelled && (order$ | async)?.status !== OrderStatus.Refused && (order$ | async)?.status !== OrderStatus.PickedUp)" class="followup-order-number-container solid-box">
                    <div class="followup-order-number">
                        {{ 'followup.pickupAddress' | transloco }}
                        <br>
                        <a href="https://www.google.com/maps/search/?api=1&query={{encodeAddress(((order$ | async)?.restaurant.address | address : 'standard'))}}" 
                        target="_blank" class="restaurant-address">{{ (((order$ | async)?.restaurant.address | address : 'standard')) }}</a>
                    </div>
                </div>
                <div *ngIf="(order$ | async) && (order$ | async)?.status !== OrderStatus.Cancelled && (order$ | async)?.status !== OrderStatus.Refused" class="followup-timeline-container solid-box">
                    <div class="followup-timeline-text-container">
                        <div *ngIf="!(order$ | async)?.scheduledDate || ((order$ | async)?.scheduledDate && ((order$ | async)?.status !== OrderStatus.Initialized && (order$ | async)?.status !== OrderStatus.Scheduled)); else not_scheduled"
                            class="followup-timeline-text">{{ ((orderType$ | async) === 'delivery' ? 'followup.etaDelivery' : 'followup.etaPickup') | transloco }}</div>
                        <ng-template #not_scheduled>
                            <div class="followup-timeline-text">
                                {{ 'followup.scheduled' | transloco }}
                            </div>
                        </ng-template>
                        <div *ngIf="(order$ | async) && estimatedDrivingDurationInSeconds !== undefined" class="followup-timeline-number">
                            {{ getEstimatedDeliveryTime((order$ | async)) }}
                        </div>
                        <div *ngIf="((order$ | async)?.status === OrderStatus.Placed || (order$ | async)?.status === OrderStatus.Initialized) && !(order$ | async)?.scheduledDate" class="followup-timeline-message">{{ 'followup.revisedMomentarily' | transloco }}</div>
                    </div>
                    <div class="followup-timeline">
                        <div class="followup-timeline-status-text">
                            <div class="timeline-item-status-text" [ngClass]="{ 'timeline-item-status-text-active': inProcessing }">
                                <div class="circle">
                                    <img *ngIf="inPreparation || isReady || inDelivery || isComplete" class="timeline-checkmark-icon"
                                        src="../../assets/images/icons/check.svg" alt="" />
                                    <div *ngIf="inProcessing"
                                        class="spinner-grow spinner-grow-sm followup-active-spinner" role="status">
                                        <span class="sr-only">{{
                                            'followup.loading' | transloco
                                        }}</span>
                                    </div>
                                </div>
                                {{ 'followup.orderStatus.placed' | transloco: {name: (order$ | async)?.restaurant._name.value } }}
                                <div class="timeline-item-line"></div>
                            </div>
                            <div class="timeline-item-status-text" [ngClass]="{ 'timeline-item-status-text-active': inPreparation }">
                                <div class="circle">
                                    <img *ngIf="isReady || inDelivery || isComplete" class="timeline-checkmark-icon"
                                        src="../../assets/images/icons/check.svg" alt="" />
                                    <div *ngIf="inPreparation"
                                        class="spinner-grow spinner-grow-sm followup-active-spinner" role="status" [class.followup-active-spinner-delayed]="isOrderDelayed((order$ | async))">
                                        <span class="sr-only">{{
                                            'followup.loading' | transloco
                                        }}</span>
                                    </div>
                                </div>
                                <div *ngIf="!isOrderDelayed((order$ | async)); else delayed">
                                    {{ 'followup.orderStatus.preparing' | transloco }}
                                </div>
                                <ng-template #delayed>
                                    {{ 'followup.orderStatus.delayed' | transloco }}
                                </ng-template>
                                <div class="timeline-item-line"></div>
                            </div>
                            <div class="timeline-item-status-text" [ngClass]="{'timeline-item-status-text-active': isReady }">
                                <div class="circle">
                                    <img *ngIf="inDelivery || isComplete" class="timeline-checkmark-icon"
                                        src="../../assets/images/icons/check.svg" alt="" />
                                    <div *ngIf="isReady"
                                        class="spinner-grow spinner-grow-sm followup-active-spinner" role="status">
                                        <span class="sr-only">{{
                                            'followup.loading' | transloco
                                        }}</span>
                                    </div>
                                </div>
                                <ng-container *ngIf="(orderType$ | async) === 'delivery'">
                                    {{ 'followup.orderStatus.readyForDelivery' | transloco }}
                                </ng-container>
                                <ng-container *ngIf="(orderType$ | async) === 'pickup'">
                                    {{ 'followup.orderStatus.readyForPickup' | transloco }}
                                </ng-container>
                                <div class="timeline-item-line"></div>
                            </div>
                            <div *ngIf="(orderType$ | async) === 'delivery'" class="timeline-item-status-text" [ngClass]="{'timeline-item-status-text-active': inDelivery }">
                                <div class="circle">
                                    <img *ngIf="isComplete" class="timeline-checkmark-icon"
                                        src="../../assets/images/icons/check.svg" alt="" />
                                    <div *ngIf="inDelivery"
                                        class="spinner-grow spinner-grow-sm followup-active-spinner" role="status">
                                        <span class="sr-only">{{
                                            'followup.loading' | transloco
                                        }}</span>
                                    </div>
                                </div>
                                {{ 'followup.orderStatus.delivering' | transloco }}
                                <div class="timeline-item-line"></div>
                            </div>
                            <div class="timeline-item-status-text" [ngClass]="{'timeline-item-status-text-active': isComplete }">
                                <div class="circle">
                                    <img *ngIf="isComplete" class="timeline-checkmark-icon"
                                        src="../../assets/images/icons/check.svg" alt="" />
                                </div>
                                <ng-container *ngIf="(orderType$ | async) === 'delivery'">
                                    {{ 'followup.orderStatus.delivered' | transloco }}
                                </ng-container>
                                <ng-container *ngIf="(orderType$ | async) === 'pickup'">
                                    {{ 'followup.orderStatus.pickedup' | transloco }}
                                </ng-container>
                                <div class="timeline-item-line"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="contact-container">
                    <img class="contact-icon" src="../../assets/images/icons/buoy.svg" alt="" />
                    <div class="contact-text" (click)="clickSupport()">
                        {{ 'followup.support' | transloco }}
                    </div>
                </div> -->
            </div>
            <div class="map-right-container">
                <div class="right-top">
                    <div *ngIf="(socketIsConnected$ | async) == false" class="solid-box">
                        <div class="connection-lost-title">
                            {{ 'followup.lostConnection' | transloco }}
                        </div>
                        <div class="connection-lost-text">
                            {{ 'followup.pleaseRefresh' | transloco }}
                        </div>
                    </div>
                </div>
                <div class="right-bottom">
                    <div class="order-summary-container order-box" (click)="clickSummary()">
                        <img class="list-icon box-icon" src="../../assets/images/icons/list.svg" alt="" />
                        <div class="contact-text">
                            {{ 'followup.orderSummaryButton' | transloco }}
                        </div>
                    </div>
                    <div class="order-summary-contact-container order-box" (click)="clickSupport()">
                        <img class="contact-icon box-icon" src="../../assets/images/icons/buoy.svg" alt="" />
                        <div class="contact-text">
                            {{ 'followup.support' | transloco }}
                        </div>
                    </div>
                </div>
                <!-- <div class="followup-status"><strong>George</strong> is on the way!</div> -->
            </div>
        </div>
        <google-map
            [center]="{ lat: restaurantLat, lng: restaurantLng }" height="100%" width="100%" id="followup-map"
            [options]="mapOptions" [zoom]="15">
            <map-directions-renderer *ngIf="(orderType$ | async) == 'delivery' && (directionsResults$ | async) as directionsResults"
                [directions]="directionsResults" [options]="{ polylineOptions: { strokeColor: '#FE2C7D' }, suppressMarkers: true }">
                <map-marker [position]="{ lat: restaurantLat, lng: restaurantLng }" [icon]="restaurantIcon"></map-marker>
                <map-marker [position]="{ lat: bagLat, lng: bagLng }" [icon]="houseIcon"></map-marker>
            </map-directions-renderer>
            <map-marker *ngIf="(orderType$ | async) !== 'delivery'" [position]="{lat: restaurantLat, lng: restaurantLng}"
                [icon]="restaurantIcon">
            </map-marker>
        </google-map>
    </div>
</div>
<app-footer></app-footer>
