import { Restaurant } from './restaurant.model'
import { User } from './user.model'
import { Photo } from './photo.model'
import { Deserializable } from './deserializable.model';

export class Review implements Deserializable<Review> {
	id?: string;
	title?: string;
	user: User;
	text: string;
	rating: number;
	language: string;
    restaurant: Restaurant;
    createdAt: string;
    photos: Photo[];

    constructor() {

    }

	deserialize(input: any): this {
        if (!input) return null;
        if (typeof input == 'string') this.id = input;
        else {
            Object.assign(this, input);
            this.id = input._id ? input._id : input.id
            this.user = new User().deserialize(input?.user)
            this.restaurant = new Restaurant().deserialize(input?.restaurant)
        }
		return this;
	}
}
