import Dinero from 'dinero.js';
import { ExtendedOrderType, OrderType } from '../enums';
import { TranslatedText, TranslatedTextType } from './translated-text.model';
import { Deserializable } from './deserializable.model';
import { MarketCategoryService } from '../services/data/market-category.service';

export class SearchResult implements Deserializable<SearchResult> {
    _id?: string;
    slug: string;
    restaurantSlug?: string;
    _brief?: TranslatedText;
    _title: TranslatedText;
    _description?: TranslatedText;
    _restaurantName?: TranslatedText;
    types?: OrderType[];
    price?: Dinero.Dinero;
    model: string; //TODO: Should be an enum
    categories?: TranslatedText[] = [];
    _thumbnail_xsmall?: string;
    _thumbnail_small: string = '../../../assets/images/radish-logos/Radish-square-transparent.png';
    isListed?: boolean;
    servings?: {
        count?: number;
        unit?: string; //TODO: Enum
    } = {
            count: null,
            unit: null
        };

    constructor() {

    }

    deserialize(input: any): this {
        if (!input) return null;
        if (typeof input == 'string') this.id = input;
        else {
            Object.assign(this, input);
            this.id = input._id ? input._id : input.id
            if (input.title_en || input.title_fr) {
                input.title = {
                    en: input.title_en || '',
                    fr: input.title_fr || ''
                }
            }
            else if (input.name_en || input.name_fr) {
                input.title = {
                    en: input.name_en || '',
                    fr: input.name_fr || ''
                }
            }
            this.title = new TranslatedText().deserialize(input.title);
            if (input.brief_en || input.brief_fr) {
                input.brief = {
                    en: input.brief_en || '',
                    fr: input.brief_fr || ''
                }
            }
            this.brief = new TranslatedText().deserialize(input?.brief);
            if (input.description_en || input.description_fr) {
                input.description = {
                    en: input.description_en || '',
                    fr: input.description_fr || ''
                }
            }
            this.description = new TranslatedText().deserialize(input?.description);
            if (input.restaurant_name_en || input.restaurant_name_fr) {
                input.restaurantName = {
                    en: input.restaurant_name_en || '',
                    fr: input.restaurant_name_fr || ''
                }
            }
            this.restaurantName = new TranslatedText().deserialize(input?.restaurantName);
            if (typeof input?.price == 'number') this.price = Dinero({ amount: input.price, currency: 'CAD' }); //TODO: Switch when currency added to es

            for (let i = 0; i < input.category_en.length; i++) {
                this.categories.push(new TranslatedText().deserialize({ en: input.category_en[i], fr: input.category_fr[i] }));
            }

            if (input.restaurant_slug) this.restaurantSlug = input.restaurant_slug;
            if (input.is_listed) this.isListed = input.is_listed;
            if (input.serving_quantity) this.servings.count = input.serving_quantity;
            if (input.serving_type) this.servings.unit = input.serving_type;

            if (input.thumbnail_xsmall) this._thumbnail_xsmall = input.thumbnail_xsmall;
            if (input.thumbnail_small) this._thumbnail_small = input.thumbnail_small;

            return this;
        }
    }

    get id(): string {
        return this._id;
    }

    set id(id: string) {
        this._id = id;
    }

    get title() {
        return this._title?.value;
    }

    set title(title: TranslatedTextType) {
        this._title = title as TranslatedText;
    }

    get brief(): TranslatedTextType {
        return this._brief?.value;
    }

    set brief(brief: TranslatedTextType) {
        this._brief = brief as TranslatedText;
    }

    get description() {
        return this._description?.value;
    }

    set description(description: TranslatedTextType) {
        this._description = description as TranslatedText;
    }

    get restaurantName() {
        return this._restaurantName?.value;
    }

    set restaurantName(restaurantName: TranslatedTextType) {
        this._restaurantName = restaurantName as TranslatedText;
    }

    get thumbnail() {
        return this._thumbnail_xsmall ? this._thumbnail_xsmall : this._thumbnail_small;
    }

    get image() {
        return this._thumbnail_small;
    }

    //TODO: Fetch from routes
    url(platform: ExtendedOrderType) {
        switch (this.model) {
            case 'product':
                return (platform == ExtendedOrderType.Market) ? `/marché/merchants/${this.restaurantSlug}/products/${this.slug}`
                    : `restaurants/${this.restaurantSlug}/menu`;
            case 'restaurant':
                return (platform == ExtendedOrderType.Market) ? `/marché/merchants/${this.slug}`
                    : `restaurants/${this.slug}`;
            case 'category':
                return (platform == ExtendedOrderType.Market) ? `/marché/${MarketCategoryService.topLevelSlugs.some(slug => slug == this.slug) ? '' : 'categories/'}${this.slug}` 
                : `categories/${this.slug}`;
            default:
                break;
        }
    }
}
