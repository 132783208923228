<footer class="row" [class.footer-blog]="type == 'blog'" [class.footer-white]="type == 'white'">
	<div class="footer-column footer-column-1 col-md-4 col-sm-12">
		<div class="footer-panel-logo-wrapper" [routerLink]="'/' + translate.getActiveLang()">
			<img *ngIf="type != 'white'"class="footer-panel-logo-image" src="../../assets/images/radish-logos/Radish-Logo-BlackText-Large.png" alt="Radish logo"
			/>
			<img *ngIf="type == 'white'"class="footer-panel-logo-image" src="../../assets/images/radish-logos/Radish-Logo-WhiteText-Large.png" alt="Radish logo"
			/>
		</div>
		<div class="footer-company-information">
            <p class="footer-company-information-item"
            [innerHTML]="'footer.location' | transloco">
			</p>
		</div>
		<!-- <p class="footer-blurb">{{'footer.personalMessage' | transloco}}</p> -->
	</div>
	<div class="footer-column footer-column-2 col-md col-sm-12">
		<div class="dummy"></div>
		<div class="footer-column-2-row-controls">
			<div class="footer-column-row footer-column-2-row-1 career-links">
				<a class="footer-column-2-row-item btn" (click)="restaurantClick()">{{ 'footer.becomeAMerchant' | transloco }}
                </a>
				<a class="footer-column-2-row-item btn" (click)="courrierClick()">{{ 'footer.becomeACourier' | transloco }}</a>
			</div>
			<div class="footer-column-row footer-column-2-row-2">
				<div class="footer-column-2-row-2-column-1">
					<a class="footer-column-2-row-item" [routerLink]="'/' + translate.getActiveLang() + '/privacy'">{{ 'footer.privacy' | transloco }}
					</a>
					<a class="footer-column-2-row-item" [routerLink]="'/' + translate.getActiveLang() + '/terms'">{{ 'footer.terms' | transloco }}
					</a>
					<a class="footer-column-2-row-item" href="mailto:support@radish.coop" target="_blank">{{ 'footer.contact' | transloco }}</a>
				</div>
				<div class="footer-social-media-container footer-column-2-row-2-column-2">
					<ul class="footer-social-media-menu list-unstyled">
						<li class="list-inline-item footer-social-media-icon">
							<a href="https://facebook.com/radish.coop" target="_blank" title="" class="facebook">
								<img src="../../assets/images/social-media-icons/facebook-logo-black.png" [class.img-white]="type == 'white'"/>
							</a>
						</li>
						<li class="list-inline-item footer-social-media-icon">
							<a href="https://www.instagram.com/radish.coop/" target="_blank" title="" class="instagram">
								<img src="../../assets/images/social-media-icons/instagram-logo.png" [class.img-white]="type == 'white'"/>
							</a>
						</li>
						<li class="list-inline-item footer-social-media-icon">
							<a href="https://twitter.com/RadishCoop" target="_blank" title="" class="twitter">
								<img src="../../assets/images/social-media-icons/twitter-logo.png" [class.img-white]="type == 'white'"/>
							</a>
						</li>
						<li class="list-inline-item footer-social-media-icon">
							<a href="https://www.linkedin.com/company/radishcoop" target="_blank" title="" class="linkedin">
								<img src="../../assets/images/social-media-icons/linkedin-logo-black.png" [class.img-white]="type == 'white'"/>
							</a>
						</li>
					</ul>
				</div>
			</div>
		</div>
		<div class="footer-column-row footer-column-2-row-3">
			<div class="footer-made-with">
                <div class="footer-made-with-text">{{ 'footer.madeWith' | transloco }}</div>
                <i class="fas fa-heart footer-made-with-heart"></i>
			</div>
			<p class="copyright">&copy; {{ date }} Coopérative Radish</p>
		</div>
	</div>
</footer>
