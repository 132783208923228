<div class="market-search">
    <button class="expand-btn" (click)="toggleSearchBar()" 
            [attr.aria-label]="'market.search.toggleIconAriaLabel' | transloco">
        <img src="../../../assets/images/icons/search.svg" [alt]="'market.search.toggleIconAltText' | transloco"
             class="expand-btn-icon">
    </button>
    <div class="market-search-overlay" [@overlay]="isFocused ? true : false"></div>
    <div class="market-search-bar-and-results" (click)="onClick(true)" (clickOutside)="onClick(false)"
        [exclude]="'.expand-btn'" [ngClass]="{ 'collapsed': !isFocused && isMobile }">
        <!-- Search Bar -->
        <div class="market-search-bar">
            <input type="search" class="market-search-bar-input" [(ngModel)]="query" (input)="onInput()"
                [placeholder]="'market.search.searchBar.inputPlaceholder' | transloco"
                [attr.aria-label]="'market.search.searchBar.inputAriaLabel' | transloco" (focus)="onClick(true)"
                [cdkTrapFocusAutoCapture]="isMobile && isFocused" [cdkTrapFocus]="isMobile && isFocused" />
        </div>

        <!-- Search Results -->
        <div class="market-search-results" [ngClass]="{ 'collapsed': !isFocused }">
            <div *ngIf="results$ | async as results; else loadingSpinner">
                <div class="market-search-results-heading trending-searches-heading"
                    *ngIf="query?.length < MIN_QUERY_LENGTH">
                    {{ 'market.search.trendingSearches' | transloco }}
                </div>
                <div class="market-search-results-heading" *ngIf="query?.length >= MIN_QUERY_LENGTH">
                    {{ 'market.search.resultsFor' | transloco }} <strong>"{{ query }}"</strong>:
                </div>
                <ul class="market-search-results-list"
                    *ngIf="!(results.length === 0 && query?.length >= MIN_QUERY_LENGTH); else noResults">
                    <!-- TODO: use trackBy instead of slice in ngFor? (will stop re-rendering already displayed items) -->
                    <li class="market-search-result-item" [routerLink]="'/' + translate.getActiveLang() + result.url(ExtendedOrderType.Market)"
                        *ngFor="let result of results | slice: 0:resultCountLimit" [ngbPopover]="popover"
                        #p="ngbPopover" placement="end end-top end-bottom" popoverClass="market-search-ngbPopover"
                        triggers="manual" (mouseenter)="onMouseEnter(p, false, result.slug)" (mouseleave)="onMouseLeave(p, false, result.slug)">
                        <img class="market-search-result-thumbnail" [src]="result.thumbnail" [alt]="result.title">
                        <div class="market-search-result-info">
                            <h3 class="market-search-result-title">{{ result.title }}</h3>
                            <h4 *ngIf="result.model != 'product'" class="market-search-result-subtitle">{{ 'market.search.types.' + result.model | transloco }}</h4>
                            <h4 *ngIf="result.model === 'product'" class="market-search-result-subtitle">{{ result.restaurantName }}</h4>
                        </div>

                        <!-- Popover -->
                        <ng-template #popover>
                            <app-product-popover *ngIf="result.model === 'product'" [product]="result"
                                (mouseenter)="onMouseEnter(p, true, result.slug)"
                                (mouseleave)="onMouseLeave(p, true, result.slug)"></app-product-popover>
                            <app-merchant-popover *ngIf="result.model === 'restaurant'" [merchant]="result"
                                (mouseenter)="onMouseEnter(p, true, result.slug)"
                                (mouseleave)="onMouseLeave(p, true, result.slug)"></app-merchant-popover>
                            <app-category-popover *ngIf="result.model === 'category'" [category]="result"
                                (mouseenter)="onMouseEnter(p, true, result.slug)"
                                (mouseleave)="onMouseLeave(p, true, result.slug)"></app-category-popover>
                        </ng-template>
                    </li>
                </ul>

                <!-- No Results -->
                <!-- Some emoji options for "no results": 🥸🤔🧐🥺💔🙈😢😿  -->
                <ng-template #noResults>
                    <div class="no-results-container">
                        <div class="no-results-image-container">
                            <img [src]="isMobile ? '../../../assets/images/marche-home-mobile.png' : '../../../assets/images/marche-home-desktop.png'" [alt]="'market.search.screenshotAltText' | transloco" class="homepage-screenshot">
                            <img src="../../../assets/images/confused-travolta.gif" [alt]="'market.search.gifAltText' | transloco" class="john-travolta-gif">
                        </div>
                        <div class="no-results-message">
                            <p class="no-results-message-primary">{{ 'market.search.noResults' | transloco }}</p>
                            <p class="no-results-message-secondary">{{ 'market.search.tryAnotherSearch' | transloco }}</p>
                        </div>
                    </div>
                </ng-template>
            </div>

            <!-- Loading -->
            <ng-template #loadingSpinner>
                <div class="loading-spinner">
                    <div class="lds-ring">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            </ng-template>
        </div>
    </div>
</div>