import { Component } from '@angular/core';

@Component({
    selector: 'gift-card-purchase',
    templateUrl: './purchase.component.html',
    styleUrls: ['./purchase.component.css']
})
export class GiftCardPurchaseComponent {

}
