import { Injectable } from '@angular/core';
import {
	HttpRequest,
	HttpHandler,
	HttpEvent,
	HttpInterceptor,
	HttpErrorResponse,
} from '@angular/common/http';
import { Observable, BehaviorSubject, throwError } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { catchError, filter, take, switchMap } from 'rxjs/operators';
import { MainService } from '../services/main.service';

@Injectable({
	providedIn: 'root',
})
export class TokenInterceptor implements HttpInterceptor {
	private isRefreshing = false;
	private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

	constructor(
		public authService: AuthService,
		public mainService: MainService
	) { }

	//TODO: Ignore requests that are going to self (i.e. for assets)
	intercept(
		request: HttpRequest<any>,
		next: HttpHandler
	): Observable<HttpEvent<any>> {
		let token = this.authService.getJwtToken();

		//For users that are already logged in and thus never had their cookies set.
		if (token != null && this.authService.getJwtCookie() == null) this.authService.generateAuthCookies();
		if (request.url.endsWith('auth/facebook/token')) request = this.setAuthorizationHeader(request, 'Bearer ' + this.authService.getFacebookAuthToken());
		else if (request.url.endsWith('auth/google/id_token')) request = this.setTokenHeader(request, 'id_token', this.authService.getGoogleAuthToken());
		else if (request.url.endsWith('auth/google/token')) request = this.setTokenHeader(request, 'access_token', this.authService.getGoogleAuthToken());
		else if (token) request = this.setAuthorizationHeader(request, token);
		return next.handle(request).pipe(
			catchError((error) => {
				console.log(error)
				if (
					error instanceof HttpErrorResponse &&
					error.status === 401 &&
					error.error.error === 'JWTTokenExpired'
				) {
					return this.handle401Error(request, next);
				} else {
					if (error == 'Unauthorized') this.authService.logout();
					return throwError(() => error);
				}
			})
		);
	}

	private setXAuthorizationHeader(request: HttpRequest<any>, token: string) {
		return request.clone({
			setHeaders: {
				'x-auth-token': token,
			},
		});
	}

	private setAuthorizationHeader(request: HttpRequest<any>, token: string) {
		return request.clone({
			setHeaders: {
				Authorization: token,
			},
		});
	}

	private setTokenHeader(request: HttpRequest<any>, field: string, token: string) {
		let update = { setHeaders: {} };
		update.setHeaders[field] = token;
		return request.clone(update);
	}

	private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
		if (!this.isRefreshing) {
			this.isRefreshing = true;
			this.refreshTokenSubject.next(null);
			return this.authService.refreshToken().pipe(
				switchMap((token: any) => {
					this.isRefreshing = false;
					this.refreshTokenSubject.next(token.token);

					return next.handle(this.setAuthorizationHeader(request, token.token));
				}),
				catchError((err) => {
					if (err.status == 401 || err.error == 'RefreshTokenNotFound') this.mainService.logout();
					return throwError(err);
				})
			);
		} else {
			return this.refreshTokenSubject.pipe(
				filter((token) => token != null),
				take(1),
				switchMap((jwt) => {
					return next.handle(this.setAuthorizationHeader(request, jwt));
				})
			);
		}
	}
}
