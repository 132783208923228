import { AfterViewInit, Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { trigger, style, animate, transition, state, query } from '@angular/animations';
import { ActivatedRoute, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { MainService, SCHEDULED_TIME_KEY } from 'src/app/core/services/main.service';
import { Bag, Menu, Order, Restaurant } from 'src/app/core/models';
import { UntypedFormGroup } from '@angular/forms';
import Dinero from 'dinero.js';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ConfigService } from 'src/app/core/services/config.service';
import { environment } from 'src/environments/environment';
import { OrderRequestData } from 'src/app/core/services/order.service';
import { ExpoModalComponent } from 'src/app/modals/expo-modal/expo-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { KEY_EXPO_BAG } from 'src/app/core/services/bag.service';
import { isPlatformBrowser } from '@angular/common';
import { ExpoBagPage } from 'src/app/core/enums';
import { ErrorType } from 'src/app/core/enums/error.enum';

declare var Stripe;

enum WalletType {
	Google = 'google',
	Apple = 'apple'
}

//TODO: animations should be grouped in same trigger of parent div
const slideUpToggleAnimation = [
	trigger('overlayToggle', [
		state('open', style({
			opacity: 0.45
		})),
		state('closed', style({
			opacity: 0
		})),
		transition('open => closed', [
			animate('300ms ease-in')
		]),
		transition('closed => open', [
			animate('200ms ease-in')
		])
	]),
	trigger('bottomSheetToggle', [
		state('open', style({
			transform: 'translateY(0%)',
			opacity: 1
		})),
		state('walletOpened', style({
			transform: 'translateY(-50%)'
		})),
		state('closed', style({
			transform: 'translateY(100%) translateY(-72px)',
			opacity: 0.95
		})),
		transition('open => closed', [
			animate('300ms ease-in')
		]),
		transition('walletOpened => closed', [
			animate('300ms ease-in')
		]),
		transition('open => walletOpened', [
			animate('170ms ease-in')
		]),
		transition('walletOpened => open', [
			animate('170ms ease-in')
		]),
		transition('closed => open', [
			animate('200ms ease-in')
		])
	])
]
@UntilDestroy()
@Component({
	selector: 'expo-bag',
	templateUrl: './bag.component.html',
	styleUrls: ['./bag.component.css'],
	animations: [slideUpToggleAnimation]
})
export class ExpoBagComponent implements OnInit, AfterViewInit {

	ExpoBagPage = ExpoBagPage;
	WalletType = WalletType;

	bag$: Observable<Bag>;
	restaurant$: Observable<Restaurant>;
	order$: Observable<Order>;
	scheduledDate$: Observable<Date>;
	virtualizedOrder: Order;

	bottomSheetOpen: boolean = false;
	walletPaneOpen: boolean = false;
	showOverflowPill: boolean = true;
	disableDecrement: boolean = false;

	stripe: any;
	paymentRequest;
	confirmed = false;
	walletType: WalletType;

	isLoading: boolean = false;

	taxes: any;
	expoCheckoutForm: UntypedFormGroup;
	showTax: boolean = false;
	addTip: boolean = false;
	customTipValue: number = 0;

	page$: BehaviorSubject<ExpoBagPage> = new BehaviorSubject<ExpoBagPage>(null);

	largeBag: boolean = false;
	readonly bagStaticImageSrc: string = '../../assets/images/shopping-cart-black.png'
	readonly fillBagGifSrc: string = '../../assets/videos/bag/fill-bag.gif'
	readonly fillEmptyBagGifSrc: string = '../../assets/videos/bag/fill-empty-bag.gif'
	readonly emptyBagStaticImageSrc: string = '../../assets/images/shopping-cart-black-empty.png'
	bagImgSrc: string = this.emptyBagStaticImageSrc;
	exposition: any;

	totalItems: number = 0; //TODO: can just be observbed from bag, potentially not necessary

	notePanelOpen: boolean = false;
	isPaymentMounted = false;

	tip: Dinero.Dinero = null;

	isBrowser: boolean;

	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private mainService: MainService,
		public translate: TranslocoService,
		private configService: ConfigService,
		private modalService: NgbModal,
		@Inject(PLATFORM_ID) platformId: Object
	) {
		if (isPlatformBrowser(platformId)) this.isBrowser = true;

		this.router.events.pipe(untilDestroyed(this), filter(event => event instanceof NavigationEnd)).subscribe(event => {
			let pageSlug = (event as RouterEvent).url.split('/')[4];
			this.disableDecrement = false;
			if (pageSlug == 'checkout') {
				this.page$.next(ExpoBagPage.Checkout);
				this.disableDecrement = true;
			}
			else if (pageSlug == 'order') this.page$.next(ExpoBagPage.Order);
			else this.page$.next(ExpoBagPage.Menu);

			// this.page = (event as RouterEvent).url.split('/').pop();
			// if (this.page != 'checkout' && this.page != 'order') this.page = 'other';
		});
	}

	ngOnInit(): void {
		this.exposition = this.route.snapshot.data['exposition'];

		this.bag$ = this.mainService.expoBagLoaded$;
		this.restaurant$ = this.mainService.expoBagRestaurant$;
		this.order$ = this.mainService.expoOrderLoaded$;
		this.scheduledDate$ = this.mainService.scheduledDateLoaded$;

		setTimeout(() => {
			this.bag$.pipe(untilDestroyed(this)).subscribe(bag => {
				if (bag == null) {
					this.confirmed = false;
					this.bagImgSrc = this.emptyBagStaticImageSrc;
					this.totalItems = 0;
					return;
				}
				if (this.tip == null) this.tip = bag.subtotal.percentage(15); //TODO: don't hard code

				const prevTotalItems = this.totalItems;
				this.totalItems = bag.itemCount;

				if (this.totalItems === 0) {
					this.confirmed = false;
					this.bagImgSrc = this.emptyBagStaticImageSrc;
					return;
				}
				else if (prevTotalItems < this.totalItems) this.animateBag();

				if (this.stripe != null && bag.isCompleted != true && this.page$.getValue() == ExpoBagPage.Menu && !this.confirmed) {
					let userStub = this.mainService.fetchUserStub() || null;
					let orderData: OrderRequestData = { isVirtualized: true };
					if (userStub) orderData.userStub = userStub;
					this.mainService.initializeOrder(bag, orderData).subscribe(
						(res: any) => {
							this.virtualizedOrder = res.order;
							if (this.isBrowser) {
								if (this.paymentRequest == null) this.configureStripePaymentRequest(res.order, bag);
								else this.updatePaymentRequest(res.order, bag);
							};
						},
						(err: any) => {
							this.showErrorModal(err.error.error);
						}
					)
				}
			});
		}, 75);
		this.largeBag = (this.totalItems > 100) ? true : false;
	}

	ngAfterViewInit(): void {
		this.page$.pipe(untilDestroyed(this)).subscribe(page => {
			if (!this.isBrowser) return;
			let publicKey = this.exposition.organizations.find(o => o)?.preferences?.finance?.stripe?.publicKey;
			if (page == ExpoBagPage.Menu && this.stripe == null) this.stripe = Stripe(publicKey, { locale: this.translate.getActiveLang() });
		});
	}

	configureStripePaymentRequest(order, bag) {
		this.paymentRequest = this.stripe.paymentRequest({
			country: 'CA',
			currency: 'cad',
			total: {
				label: this.translate.translate('expo.bag.wallets.label', { restaurant: bag.restaurant.name }),
				amount: order.total.getAmount(),
			},
			requestPayerName: true,
			requestPayerEmail: true,
			requestPayerPhone: true
		});

		this.paymentRequest.on('paymentmethod', this.onPaymentMethod.bind(this));

		var elements = this.stripe.elements();
		var prButton = elements.create('paymentRequestButton', {
			paymentRequest: this.paymentRequest,
			style: {
				paymentRequestButton: {
					type: 'default',
					// One of 'default', 'book', 'buy', or 'donate'
					// Defaults to 'default'

					theme: 'dark',
					// One of 'dark', 'light', or 'light-outline'
					// Defaults to 'dark'

					height: '56px'
				},
			},
		});

		// Check the availability of the Payment Request API first.
		this.paymentRequest.canMakePayment().then((function (result) {
			if (result) {
				this.isPaymentMounted = true;
				if (result.googlePay) this.walletType = WalletType.Google;
				else if (result.applePay) this.walletType = WalletType.Apple;
				prButton.mount('#payment-request-button');
			} else {
				this.isPaymentMounted = false;
				if (this.walletType) this.walletType = null;
			}
		}).bind(this)).catch(err => console.log(err));
	}

	updatePaymentRequest(order, bag) {
		this.paymentRequest.update({
			total: {
				label: this.translate.translate('expo.bag.wallets.label', { restaurant: bag.restaurant.name }),
				amount: order.total.getAmount(),
			},
		});
	}

	onPaymentMethod(event) {
		//TODO: handle missing data 
		let name = event.payerName ? event.payerName.split(' ') : [];
		let first = name.find((first: string) => first);
		let last = name.find((last: string) => first != last) || '';

		let orderRequestData: OrderRequestData = {
			tip: this.tip,
			userStub: {
				name: {
					first: first,
					last: last
				},
				email: event.payerEmail,
				number: event.payerPhone,
				language: this.translate.getActiveLang()
			}
		}
		this.mainService.initializeOrder(this.mainService.expoBag, orderRequestData).subscribe({
			next: res => this.captureStripePayment(res.order, res.secret, event),
			error: (err) => {
				console.log(err)
				//this.showErrorModal(err.error.error);
			}
		})
	}

	captureStripePayment(order, secret: string, event) {
		this.stripe.confirmCardPayment(secret, { payment_method: event.paymentMethod.id }, { handleActions: false })
			.then(
				(confirmResult: any) => {
					if (confirmResult.error) {
						console.log(confirmResult.error); //TODO: handle
						event.complete('fail');
					}
					else {
						event.complete('success')
						this.confirmed = true;
						if (confirmResult.paymentIntent.status === "requires_action") { //TODO: Stripe suggest handling this again
							// Let Stripe.js handle the rest of the payment flow.
							this.stripe.confirmCardPayment(secret).then(function (result) {
								if (result.error) {
									console.log(result.error)
									// The payment failed -- ask your customer for a new payment method.
								} else {
									// The payment has succeeded.
								}
							});
						}
						if (confirmResult.paymentIntent.status === 'requires_capture' || confirmResult.paymentIntent.status === 'succeeded') {
							//TODO: Refactor, state should be handled in main service
							localStorage.removeItem(KEY_EXPO_BAG);
							localStorage.removeItem(SCHEDULED_TIME_KEY);
							this.mainService.scheduledDate = null;
							this.mainService.expoBag = null;
							this.mainService.expoOrder = null;

							this.bottomSheetOpen = false;
							this.walletPaneOpen = false;

							this.router.navigate([this.translate.getActiveLang() + '/expo/' + this.exposition?.slug + '/order/' + order.id]);
						}
					}
				},
				(err: any) => {
					console.log(err)
					//TODO: handle
				}
			);
	}

	showErrorModal(errorType?: ErrorType) {
		const modalRef = this.modalService.open(ExpoModalComponent, { centered: true });
		//TODO: use dictionary/mapper
		if (errorType == ErrorType.RestaurantMenuClosed) {
			modalRef.componentInstance.header = "expo.modal.menuUnavailable.header";
			modalRef.componentInstance.text = "expo.modal.menuUnavailable.text";
			modalRef.componentInstance.submitButtonText = "expo.modal.menuUnavailable.submit";
		}
		modalRef.result.then(_ => { }, _ => { });
	}

	animateBag() {
		if (this.totalItems === 1) this.bagImgSrc = this.fillEmptyBagGifSrc;
		else this.bagImgSrc = this.fillBagGifSrc;
		setTimeout(() => {
			if (this.totalItems !== 0) this.bagImgSrc = this.bagStaticImageSrc;
		}, 1000);
	}

	clickCheckout() {
		let bag = this.mainService.expoBag;
		let menu = this.findBagMenu(bag);
		let scheduledDate = this.mainService.scheduledDate;
		if (menu != null && !menu.isOpenAtDate(scheduledDate)) {
			this.showScheduleErrorModal();
			return;
		}
		// localStorage.setItem('redirect_url', '/checkout');
		this.router.navigate(['./checkout'], { relativeTo: this.route });
	}

	tipUpdated(event) {
		this.tip = event.tip;
		this.virtualizedOrder.tip = event.tip;
		this.updatePaymentRequest(this.mainService.expoOrder || this.virtualizedOrder, this.mainService.expoBag);
	}

	findRestaurantMenu(vendors: any, page: string) {
		return vendors
			.filter((vendor: any) => vendor.vendor.slug === page)
			.map((vendor: any) => vendor.menus.find((menu: Menu) => menu.type === "expo"))
			.find((menu: Menu) => menu);
	}

	showScheduleErrorModal() {
		const modalRef = this.modalService.open(ExpoModalComponent, { centered: true });
		modalRef.componentInstance.header = "expo.modal.restaurantClosed.header";
		modalRef.componentInstance.text = "expo.modal.restaurantClosed.text";
		modalRef.componentInstance.submitButtonText = "expo.modal.restaurantClosed.submit";

		modalRef.result.then(_ => { }, _ => { });
	}

	onScroll(event) {
		let epsilon = 1;
		if (event.target.offsetHeight + event.target.scrollTop + epsilon >= event.target.scrollHeight) {
			this.showOverflowPill = false;
		}
		else {
			this.showOverflowPill = true;
		}
	}

	checkOverflow() {
		let element;
		// if (window.innerWidth >= 992) element = document.getElementById('orderSummary');
		element = document.getElementById('orderSummaryMobile');
		return element != null && element.offsetHeight < element.scrollHeight;
	}

	scrollDown() {
		let element;
		// if (window.innerWidth >= 992) element = document.getElementById('orderSummary');
		element = document.getElementById('orderSummaryMobile');
		element.scrollBy(0, 150)
	}

	toggleBottomSheet() {
		if (this.bottomSheetOpen) this.walletPaneOpen = false;
		this.bottomSheetOpen = !this.bottomSheetOpen;
	}

	disableCheckoutButton(bag: Bag, scheduledDate: Date): boolean {
		return bag == null || bag?.isEmpty ||
			this.isUnderMinimumOrderAmount(bag) ||
			this.isOutsideRestaurantHours(bag, scheduledDate);
	}

	isUnderMinimumOrderAmount(bag: Bag): boolean {
		return bag?.subtotal.lessThan(this.configService.MINIMUM_EXPO_POCHA_ORDER_AMOUNT);
	}

	isOutsideRestaurantHours(bag: Bag, scheduledDate: Date): boolean {
		let menu: Menu = this.findBagMenu(bag);
		return menu == null || !menu?.isOpenAtDate(scheduledDate);
	}

	findBagMenu(bag: Bag): Menu {
		return this.exposition.vendors
			.map((vendor: any) => vendor.menus.filter((menu: Menu) => menu.id === bag.menu.id))
			.find((menus: Menu[]) => menus.find((menu: Menu) => menu))?.pop()
	}

	// Bag Checkout Functions - START 
	calculateTotal(): Observable<Dinero.Dinero> {
		return this.order$.pipe(map(order => {
			if (!order) return null;
			//get tip from the tip component
			//order.tip = Dinero({ amount: +(+this.expoCheckoutForm.controls.tipAmountCustom.value * 100).toFixed(0), currency: 'CAD' });
			return order.total;
		}))
	}

	calculateTaxes(): any {
		return this.taxes.reduce((total, tax) => total = total.add(tax?.amount), Dinero({ amount: 0, currency: 'CAD' }));
	}

	getMissingAmount(bag: Bag): Dinero.Dinero {
		return bag ? this.configService.MINIMUM_EXPO_POCHA_ORDER_AMOUNT.subtract(bag.subtotal) : null;
	}

	// Bag Checkout Functions - END

	getCardLogo(brand: string) {
		switch (brand) {
			case 'visa':
				return '../../../assets/images/icons/visa.svg';
			case 'amex':
				return '../../../assets/images/icons/american-express.svg';
			case 'mastercard':
				return '../../../assets/images/icons/mastercard.svg';
		}
	}
}