import { Deserializable } from "./deserializable.model";
import { Restaurant } from "./restaurant.model";
import { TranslatedText, TranslatedTextType } from "./translated-text.model";

export class Promotion implements Deserializable<Promotion>{ 

  _id: string;
  _title?: TranslatedText;
  _brief?: TranslatedText;
  _description?: TranslatedText;
	schedule?: {
		start?: Date;
		end?: Date;
		active?: boolean;
	}[][];
  dates?: { 
    start?: string 
  };
  restaurants?: Restaurant;
  img?: string;


  constructor() { }
 
  deserialize(input: any): this { 
    if (!input) return null;
    if (typeof input == 'string') this.id = input;
    else {
        Object.assign(this, input);
        this.id = input._id ? input._id : input.id;
        
        this.title = new TranslatedText().deserialize(input.title);
        this.brief = new TranslatedText().deserialize(input.brief);
        this.description = new TranslatedText().deserialize(input.description);
        this.restaurants = new Restaurant().deserialize(input.restaurants);
    }
  
  return this;
  }

  get id(): string {
    return this._id;
  }

  set id(id: string) {
      this._id = id;
  }

  get title(): TranslatedTextType {
    return this._title?.value;
  }

  set title(title: TranslatedTextType) {
    this._title = title as TranslatedText;
  }

  get brief(): TranslatedTextType {
    return this._brief?.value;
  }

  set brief(brief: TranslatedTextType) {
    this._brief = brief as TranslatedText;
  }

  get description(): TranslatedTextType {
    return this._description?.value;
  }

  set description(description: TranslatedTextType) {
    this._description = description as TranslatedText;
  }
}