import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from './base.service';
import { TranslocoService } from '@ngneat/transloco';

export interface signupAuth {
	name: {
		first: string;
		last: string;
	};
	password: string;
	email: string;
	number?: string;
	receiveEmails: boolean;
	language: string;
}

@Injectable({
	providedIn: 'root',
})
export class SignupService {
	constructor(
		private baseService: BaseService,
		private translate: TranslocoService
	) { }

	private entity: string = 'auth/';

	signup(data: signupAuth): Observable<any> {
		return this.baseService.create(this.entity + 'registration', data)
	}

	createSignupAuth(
		firstname: string,
		lastname: string,
		password: string,
		email: string,
		receiveEmails: boolean,
		number: string = null
	) {
		let signupAuth: signupAuth = {
			name: {
				first: firstname,
				last: lastname,
			},
			password,
			email,
			receiveEmails,
			language: this.translate.getActiveLang(),
        };
        if (number) signupAuth.number = number;
		return signupAuth;
	}
}
