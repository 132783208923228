<div>
    <app-navbar [navbarType]="'main'"></app-navbar>
    <div class="main-container">
        <div class="header-wrapper">
            <h1 class="header">{{ 'main.radishCoop' | transloco }}</h1>
        </div>
        <!-- <h1 class="header">{{ 'main.radishCoop' | transloco }}</h1> -->
        <hr>
        <div class="app-banner">
            <div class="app-banner-text">
                <div class="app-banner-heading">
                    {{ 'main.appBanner.heading' | transloco }}
                </div>
                <div class="app-banner-subheading">
                    {{ 'main.appBanner.subheading' | transloco }}
                    <a href="{{ 'main.appBanner.link' | transloco }}" class="app-banner-link" target="_blank">
                        {{ 'main.appBanner.cta' | transloco }}
                    </a>
                </div>
            </div>
        </div>
        <!-- TODO: card could probably be its own component at this point -->
        <!-- TODO: format transloco data properly eventually - see "cards" -->
        <div class="card-container">
            <!-- Radish Delivery Card -->
            <div class="card-wrapper">
                <div class="overlay"></div>
                <div class="card-text-wrapper">
                    <div class="card-title mb-2"> {{ 'main.cardRestaurantTitle' | transloco }}</div>
                    <p class="card-content d-none d-sm-block"> {{ 'main.cardRestaurantSubtitle' | transloco }}</p>
                    <h4><button class="routing-button"
                            [routerLink]="['/' + translate.getActiveLang() + '/restaurants/']">{{ 'main.orderNow' |
                            transloco }}</button></h4>
                </div>
                <div class="card-img-wrapper">
                    <img class="card-img" src="../../assets/images/main-page-card-1.png" alt="Card image">
                </div>
            </div>

            <div class="vertical-line d-none d-sm-block"></div>

            <!-- Marché Radish Card -->
            <div class="card-wrapper">
                <div class="overlay"></div>
                <div class="card-text-wrapper">
                    <div class="card-title mb-2"> {{ 'main.cardMarketTitle' | transloco }}</div>
                    <p class="card-content d-none d-sm-block"> {{ 'main.cardMarketSubtitle' | transloco }}</p>
                    <h4><button class="routing-button" [routerLink]="['/' + translate.getActiveLang() + '/marché/']">{{
                            'main.planCrate' | transloco }}</button></h4>
                </div>
                <div class="card-img-wrapper">
                    <img class="card-img" src="../../assets/images/main-page-card-2.png" alt="Card image">
                </div>
            </div>

            <!-- Holiday Gift Boxes Card -->
            <!-- <div class="card-wrapper">
                <div class="overlay"></div>
                <div class="card-text-wrapper">
                    <div class="card-title mb-2">{{ 'main.cards.holidayGiftBoxes.title' | transloco }}</div>
                    <p class="card-content d-none d-sm-block"> {{ 'main.cards.holidayGiftBoxes.subtitle' | transloco }}</p>
                    <h4><button class="routing-button" [routerLink]="['/' + translate.getActiveLang() + '/cadeaux']">{{ 'main.cards.holidayGiftBoxes.button' | transloco }}</button></h4>
                </div>
                <div class="card-img-wrapper">
                    <img class="card-img" src="../../assets/images/main-page-card-holiday-gift-box-2.jpg" alt="Card image">
                </div>
            </div> -->
        </div>
        <div class="double-horizontal-line"></div>
        <div class="body">
            <div *ngIf="resolvingList; else listLoaded">
                <div class="loading-row-wrapper" *ngFor="let list of [].constructor(3)">
                    <div class="loading-list-header loading-gradient"></div>
                    <div class="loading-items-wrapper">
                        <div *ngFor="let product of [].constructor(10)" class="loading-item-wrapper">
                            <div class="loading-item-image loading-gradient"></div>
                            <div class="loading-item-header loading-gradient"></div>
                        </div>
                    </div>
                </div>
            </div>
            <ng-template #listLoaded>
                <div class="base-market-row-wrapper" *ngFor="let list of featuredList?.items">
                    <h3 class="base-market-row-title"> {{ list.title }} </h3>
                    <app-base-market-row [displayOnMain]='true' [items]="list.items"></app-base-market-row>
                </div>
            </ng-template>
        </div>
    </div>
</div>
<app-footer></app-footer>