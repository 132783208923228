<div *ngIf="isShowing" class="gift-box-item-details-container" @floatInOut
    (clickOutside)="closeDetails()" [delayClickOutsideInit]="true" [exclude]="'.listing-item-box'">
		<img (click)="closeDetails()" class="gift-box-close-icon" src="../../assets/images/close-icon.png" alt="x icon"/>
		
		<section class="gift-box-top-container">
			<div id="gift-box-header-container" class="gift-box-header-container">
				<h2 *ngIf="hoveredSubproduct == null && oldHoveredSubproduct == null" id="gift-box-header" class="gift-box-header" @fadeInOut>
					{{ product.title }}
				</h2>
				<h2 *ngIf="oldHoveredSubproduct != null"id="gift-box-header" class="gift-box-header" @fadeOut>
					{{ oldHoveredSubproduct.product.title }}
				</h2>
				<h2 *ngIf="hoveredSubproduct != null" id="gift-box-header" class="gift-box-header" @fadeInOut>
					{{ hoveredSubproduct.product.title }}
				</h2>
			</div>
			<div class="gift-box-line"></div>
		</section>

		<section class="gift-box-middle-container">
			<div class="gift-box-image-container">
				<!-- fades inout between options !! -->
				<img  *ngIf="hoveredSubproduct == null && oldHoveredSubproduct == null" class="gift-box-image" src="{{ product.imageUrl }}" @fadeInOut>
				<img *ngIf="oldHoveredSubproduct != null" class="gift-box-image" src="{{ oldHoveredSubproduct?.product.imageUrl != null ? oldHoveredSubproduct?.product.imageUrl : '../../../assets/images/Radish-LogoMark-200x200.jpg' }}" @fadeOut>
				<img *ngIf="hoveredSubproduct != null" class="gift-box-image" src="{{ hoveredSubproduct?.product.imageUrl != null ? hoveredSubproduct?.product.imageUrl : '../../../assets/images/Radish-LogoMark-200x200.jpg' }}" @fadeInOut>

				<div class="progress-bar" [@progressBarToggle]="subproductClicked ? 'loading' : 'done'"></div>
			</div>

			<!-- Subproducts -->
			<div class="gift-box-products-label-container">
				<div>
                    <h4 class="gift-box-products-header" *ngIf="product.subproducts.length != 0">
						{{ 'giftBox.listingDetailModal.productsHeader' | transloco }}
					</h4>
                </div>
				<h4 *ngIf="hoveredSubproduct == null">
					{{ product.price | price | translocoCurrency }}
				</h4>
			</div>
			
            <div class="gift-box-subproducts-container">
				<!-- Subproduct Item (switch on click) -->
                <div id="subproducts-container" class="gift-box-subproducts" (wheel)="onScroll($event)">
                    <div class="gift-box-subproducts-item"
                         *ngFor="let subproduct of product.subproducts"
                        (click)="onSubproductClick($event, subproduct)">
                        <img class="gift-box-subproducts-image" src="{{ subproduct?.product.thumbnailUrl != null ? subproduct?.product.thumbnailUrl : '../../../assets/images/Radish-LogoMark-200x200.jpg' }}"/>
                    </div>
                </div>

				<!-- Subproduct Item (switch on hover) -->
                <!-- <div id="subproducts-container" class="gift-box-subproducts" (wheel)="onScroll($event)">
                    <div class="gift-box-subproducts-item"
                         *ngFor="let subproduct of product.subproducts"
                         (mouseenter)="onSubproductHover($event, subproduct)"
                         (mouseleave)="onSubproductLeave($event)"
                         (click)="onSubproductClick($event, subproduct)"
                         >
                         (hover)="onSubproductClick($event, subproduct)" comment out
                        <img class="gift-box-subproducts-image" src="{{ subproduct?.product.thumbnailUrl != null ? subproduct?.product.thumbnailUrl : '../../../assets/images/Radish-LogoMark-200x200.jpg' }}"/>
                    </div>
                </div> -->

				<!-- Arrows -->
                <div class="arrow-right" *ngIf="isSubproductScrollable"
                    (click)="onClickScroll('right')"
                    appClickStopPropagation>
                    <i class="fa fa-chevron-right"></i>
                </div>
                <div class="arrow-left" *ngIf="isSubproductScrollable"
                    (click)="onClickScroll('left')"
                    appClickStopPropagation>
                    <i class="fa fa-chevron-left"></i>
                </div>
            </div>

			<!-- Subproduct Description -->
			<div class="gift-box-detail-container">
				<p *ngIf="hoveredSubproduct == null && oldHoveredSubproduct == null" class="gift-box-listing-detail-text" @fadeInOut [innerText]="product.description || null"></p>
				<p *ngIf="oldHoveredSubproduct != null" class="gift-box-listing-detail-text" @fadeOut [innerText]="oldHoveredSubproduct.product.description || null"></p>
				<p *ngIf="hoveredSubproduct != null" class="gift-box-listing-detail-text" @fadeInOut [innerText]="hoveredSubproduct.product.description || null"></p>
			</div>
		</section>

		<section class="gift-box-bottom-container">
			<div class="gift-box-line gift-box-line-bottom"></div>
			<!-- [hidden]="subproductClicked" -->
			<button class="gift-box-details-button" (click)="addItem($event)" 
				[disabled]="!product.isAvailable || isLoading"
				[ngbTooltip]="!product.isAvailable ? 'Product Unavailable :d' : ''">
				<ng-container *ngIf="!isLoading">
					{{ 'giftBox.listingDetailModal.submitButton' | transloco }}
				</ng-container>
				<div *ngIf="isLoading" class="spinner-border spinner-border-sm text-light" role="status">
					<span class="sr-only">{{ 'common.loading' | transloco }}</span>
				</div>
			</button>

			<!-- <button [hidden]="!subproductClicked" class="gift-box-details-button" (click)="closeSubproduct()">
				{{ 'giftBox.listingDetailModal.returnToCrateButton' | transloco }}
			</button> -->
			<!-- </div> -->
		</section>
</div>
