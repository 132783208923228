import { AfterViewInit, Component, ElementRef, Inject, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { Order, User, Address, Exposition, UserStubData, Bag, Menu } from 'src/app/core/models';
import { MainService } from 'src/app/core/services/main.service';
import Dinero from 'dinero.js';
import { TranslocoService } from '@ngneat/transloco';
import { Observable, finalize } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { OrderRequestData } from 'src/app/core/services/order.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ErrorType } from 'src/app/core/enums/error.enum';
import { KEY_EXPO_BAG } from 'src/app/core/services/bag.service';
import { environment } from 'src/environments/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ExpoModalComponent } from 'src/app/modals/expo-modal/expo-modal.component';
import { isPlatformBrowser } from '@angular/common';

declare var Stripe;

enum ExpoCheckoutTab {
    Details = 'details',
    Payment = 'payment'
}
@UntilDestroy()
@Component({
    selector: 'expo-checkout',
    templateUrl: './checkout.component.html',
    styleUrls: ['./checkout.component.css']
})
export class ExpoCheckoutComponent implements OnInit, AfterViewInit {

    exposition: Exposition;
    userStub: UserStubData;
    user$: Observable<User>;
    expoOrder$: Observable<Order>;

    stripe: any;
    cardErrors: string[];

    isLoading: boolean = false;

    ExpoCheckoutTab = ExpoCheckoutTab;
    currentTab: ExpoCheckoutTab = ExpoCheckoutTab.Details;

    SCHEDULED_TIME_KEY: string = 'scheduled_time';

    isBrowser: boolean;

    constructor(
        private mainService: MainService,
        private translate: TranslocoService,
        private router: Router,
        private route: ActivatedRoute,
        private modalService: NgbModal,
        @Inject(PLATFORM_ID) platformId: Object
    ) {
        if (isPlatformBrowser(platformId)) this.isBrowser = true;
     }

    ngOnInit(): void {
        this.userStub = this.mainService.fetchUserStub();
        this.exposition = this.route.parent.snapshot.data['exposition'];
        this.expoOrder$ = this.mainService.expoOrderLoaded$;

        let menu = this.findBagMenu(this.mainService.expoBag);
        let scheduledDate = this.mainService.scheduledDate;
        if (menu != null && !menu.isOpenAtDate(scheduledDate)) this.showErrorModal();

        if (this.mainService.expoBag == null && localStorage.getItem(KEY_EXPO_BAG) == null) {
            this.router.navigate([this.translate.getActiveLang() + '/expo/' + this.exposition.slug]);
            return;
        }
        if (this.userStub) {
            let order = this.mainService.expoOrder;
            let data: OrderRequestData = { tip: order?.tip, userStub: this.userStub }
            this.initializeOrder(data).subscribe();
        }
        else this.initializeOrder(null).subscribe(res => {
            if (res.order?.user) this.userStub = this.mainService.generateUserStub(res.order.user.name.first, res.order.user.name.last, res.order.user.email, res.order.user.number);
        }); //TODO: User stub should just be fetched without main service
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            if (!this.isBrowser) return
            let publicKey = this.exposition.organizations.find(o => o)?.preferences?.finance?.stripe?.publicKey;
            if (this.stripe == null) this.stripe = Stripe(publicKey, { locale: this.translate.getActiveLang() });
        }, 75);
    }

    findBagMenu(bag: Bag): Menu {
        return this.exposition.vendors
            .map((vendor: any) => vendor.menus.filter((menu: Menu) => menu.id === bag?.menu.id))
            .find((menus: Menu[]) => menus.find((menu: Menu) => menu))?.pop()
    }

    showErrorModal(errorType?: ErrorType) {
        const modalRef = this.modalService.open(ExpoModalComponent, { centered: true });

        //TODO: use dictionary/mapper
        if (errorType == ErrorType.OrderTooSmall) {
            modalRef.componentInstance.header = "expo.modal.orderTooSmall.header";
            modalRef.componentInstance.text = "expo.modal.orderTooSmall.text";
            modalRef.componentInstance.submitButtonText = "expo.modal.orderTooSmall.submit";
        }
        else if (errorType == ErrorType.RestaurantMenuClosed) {
            modalRef.componentInstance.header = "expo.modal.menuUnavailable.header";
            modalRef.componentInstance.text = "expo.modal.menuUnavailable.text";
            modalRef.componentInstance.submitButtonText = "expo.modal.menuUnavailable.submit";
        }
        else { //lol
            modalRef.componentInstance.header = "expo.modal.restaurantClosed.header";
            modalRef.componentInstance.text = "expo.modal.restaurantClosed.text";
            modalRef.componentInstance.submitButtonText = "expo.modal.restaurantClosed.submit";
        }
        modalRef.result.then(_ => { }, _ => { });
    }

    detailsCompleted(event: UserStubData) {
        this.userStub = this.mainService.generateUserStub(event?.name?.first, event?.name?.last, event.email, event.number, event.firstTimePromotion);
        this.initializeOrder({ userStub: this.userStub }).subscribe({ next: (res) => this.currentTab = ExpoCheckoutTab.Payment, error: (err) => this.handleError(err) });
    }

    initializeOrder(data) {
        return this.mainService.initializeOrder(this.mainService.expoBag, data ? data : { isVirtualized: true }).pipe(finalize(() => this.isLoading = false))
    }

    handleError(err) {
        if (err?.error?.error == ErrorType.OrderAlreadyPlaced) this.router.navigate([this.translate.getActiveLang() + '/expo/' + this.exposition.slug + '/order']);
        if (err?.error?.error == ErrorType.OrderTooSmall) this.showErrorModal(ErrorType.OrderTooSmall)

        //TODO: Handle
        console.log(err)
    }

    paymentCompleted(event) {
        //TODO: if notes, calle notes then submit order
        this.submitOrder(event.tip, event.card)
    }

    tipUpdated(event) {
        this.mainService.expoOrder.tip = event.tip;
    }

    submitOrder(tip: Dinero.Dinero, card) {
        this.isLoading = true;
        this.userStub = this.mainService.fetchUserStub();
        let data = { tip, userStub: this.userStub };
        this.initializeOrder(data).subscribe({
            next: res => this.captureStripePayment(res.order, res.secret, data, card),
            error: (err) => {
            }
        })
    }

    captureStripePayment(order, secret: string, orderData: OrderRequestData, cardData) {

        let payment_method = {
            card: cardData,
            billing_details: this.createBillingDetails(order.user, orderData.userStub, order.bag.address)
        };

        this.stripe.confirmCardPayment(secret, { payment_method, setup_future_usage: 'off_session' })
            .then(
                (res: any) => {
                    if (res.error) this.cardErrors = res.error.message;
                    else {
                        if (res.paymentIntent.status === 'requires_capture' || res.paymentIntent.status === 'succeeded') {
                            //TODO: Refactor, state should be handled in main service
                            let id = this.mainService.expoOrder.id;
                            localStorage.removeItem(KEY_EXPO_BAG);
                            localStorage.removeItem(this.SCHEDULED_TIME_KEY);
                            this.mainService.scheduledDate = null;
                            this.mainService.expoBag = null;
                            this.mainService.expoOrder = null;
                            this.router.navigate([this.translate.getActiveLang() + '/expo/' + this.exposition?.slug + '/order/' + id]);
                        }
                    }
                },
                (err: any) => {
                    //TODO: handle
                }
            );
    }

    //TODO: only add user stub info and postal if present
    createBillingDetails(user: User, userStub, address: Address) {
        let validUser = user ? user : userStub;
        let name = user ? `${user.name.first} ${user.name.last}` : userStub.name.first;
        let billing_details: any = {
            name: name
        };
        if (validUser.number && validUser.number != '') billing_details.phone = validUser.number;
        if (validUser.email && validUser.email != '') billing_details.email = validUser.email;
        // if (address) billing_details.address = {
        //     city: address.city,
        //     country: address.country,
        //     line1: address.line1,
        //     line2: address.line2,
        //     postal_code: address.postal,
        //     state: address.province
        // }
        return billing_details;
    }
}

