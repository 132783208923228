import { animate, style, transition, trigger, } from "@angular/animations";
import { Component, EventEmitter, OnDestroy, OnInit, Output } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslocoService } from "@ngneat/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import confetti from "canvas-confetti";
import { mergeMap } from "rxjs";
import { OrderType } from "src/app/core/enums";
import { Order } from "src/app/core/models";
import { MainService } from "src/app/core/services/main.service";
import { OrderService } from "src/app/core/services/order.service";

@UntilDestroy()
@Component({
    selector: "app-gift-box-followup",
    templateUrl: "./followup.component.html",
    styleUrls: ["./followup.component.css"],
    animations: [
        trigger("openLid", [
            transition(":enter", [
                style({ transform: "translateY(0)", opacity: 1 }),
                animate(
                    2000,
                    style({ transform: "translateY(-100px)", opacity: 0.5 })
                ),
            ]),
            transition(":leave", [
                style({ transform: "translateY(0)", opacity: 1 }),
                animate(
                    2000,
                    style({ transform: "translateY(50px)", opacity: 0.5 })
                ),
            ]),
        ]),
        trigger("openBox", [
            transition(":enter", [
                style({ transform: "translateY(0)", opacity: 1 }),
                animate(
                    2000,
                    style({ transform: "translateY(100px)", opacity: 0.5 })
                ),
            ]),
            transition(":leave", [
                style({ transform: "translateY(0)", opacity: 1 }),
                animate(
                    2000,
                    style({ transform: "translateY(50px)", opacity: 0.5 })
                ),
            ]),
        ]),
    ],
})
export class GiftBoxFollowupComponent implements OnInit, OnDestroy {

    order: Order;
    parkedOrder: Order;

    isClosed = true;
    isToRecipient: boolean = true; //true/false to prompt whether we need an email button or not

    constructor(
        public route: ActivatedRoute,
        public router: Router,
        public translate: TranslocoService,
        public orderService: OrderService,
        public mainService: MainService
    ) {
    }

    navigateToStart() {
        //TODO: Avoid reload figure out why bag doesnt clear
        this.router.navigate([this.translate.getActiveLang() + '/cadeaux']).then(() => location.reload());
    }

    ngOnInit(): void {
        this.route.params.pipe(untilDestroyed(this), mergeMap(params => this.orderService.fetchOrder(params.id, OrderType.Market, true))).subscribe({
            next: (order) => {
                this.order = order;
                this.parkedOrder = this.mainService.giftBoxOrder;
                this.mainService.giftBoxOrder = this.order;
            },
            error: (err) => this.navigateToStart() 
        });
        
        confetti({
            particleCount: 100,
            spread: 70,
            colors: ["#03956A", "#F02E51", "#F0DD2E", "#77E0C1", "#F29BAA"],
            origin: { x: 0.35, y: 0.4 },
            zIndex: 1100,
        });
    }

    ngOnDestroy(): void {
        this.mainService.order = this.parkedOrder;
    }

    onAnimationDone(event: any) {
        this.isClosed = false;
    }

    sendEmail() {
        console.log("Send email event!");
    }

    private formatDate(date: Date): string {
        const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

        const dayOfWeek = daysOfWeek[date.getDay()];
        const month = months[date.getMonth()];
        const day = date.getDate();

        return `${dayOfWeek} ${month} ${day}`;
    }


}
