<div class="restaurant-about-menu-favorites-container" [ngClass]="configService.getCssClass(restaurant.slug)">
    <h5 class="restaurant-about-menu-favorites-header">{{ 'restaurant.about.favourites' | transloco }}</h5>
    <div *ngFor="let favorite of favorites; let i = index" class="restaurant-about-menu-favorites-item"
        (click)="menuFavoriteClicked(favorite)">
        <div class="number">{{i + 1 + '. '}}</div>
        <button class="title">
            {{favorite.title[translate.getActiveLang()]}}
        </button>
    </div>
</div>
