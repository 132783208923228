import { Directive, ElementRef, Input, OnChanges, SimpleChanges, Renderer2 } from '@angular/core';
import { take } from 'rxjs';
import { SocialAuthService } from '@abacritt/angularx-social-login';

@Directive({
	selector: 'google-sign-in-button'
})
export class GoogleSignInButtonDirective implements OnChanges {
	@Input()
	type: 'icon' | 'standard' = 'standard';

	@Input()
	size: 'small' | 'medium' | 'large' = 'large';

	@Input()
	text: 'signin_with' | 'signup_with' | 'continue_with' = 'continue_with';

	@Input()
	shape: 'square' | 'circle' | 'pill' | 'rectangular' = 'rectangular';

	@Input()
	theme: 'outline' | 'filled_blue' | 'filled_black' = 'filled_blue';

	@Input()
	logo_alignment: 'left' | 'center' = 'left';

	@Input()
	width: string = '';

	@Input()
	locale: string = '';


	constructor(private renderer: Renderer2, private el: ElementRef, private socialAuthService: SocialAuthService) {


		// Promise.resolve(this.locale).then((value) => {

		// 	console.log("value", value);
		// })


		this.renderGoogleButton();
	}

	ngOnChanges(changes: SimpleChanges): void {
		{
			if (changes.locale || changes.width) {
				for (let child of this.el.nativeElement.children) {
					this.renderer.removeChild(this.el.nativeElement, child);
				}
				this.renderGoogleButton();
			}
		}
	}

	renderGoogleButton() {
		this.socialAuthService.initState.pipe(take(1)).subscribe(() => {

			Promise.resolve(this.width).then((value) => {
				if (value > '400' || (value < '200' && value != '')) {
					Promise.reject(
						'Please note .. max-width 400 , min-width 200 ' +
						'(https://developers.google.com/identity/gsi/web/tools/configurator)'
					);
				} else {
					google.accounts.id.renderButton(this.el.nativeElement, {
						type: this.type,
						size: this.size,
						text: this.text,
						width: this.width,
						shape: this.shape,
						theme: this.theme,
						logo_alignment: this.logo_alignment,
						locale: this.locale,
					});
				}
			});
		});
	}
}
