<div class="checkout-container">
    <div class="checkout-title">{{ 'expo.checkout.title' | transloco }}</div>
    <!-- <div class="checkout-divider"></div> -->
    <!-- <ul class="nav nav-tabs" id="checkout-tabs" role="tablist"> -->
        <!-- <li class="nav-item" role="presentation">
            <button class="nav-link" id="details-tab" data-bs-toggle="tab" data-bs-target="#details-tab-pane" [ngClass]="{'active': currentTab == ExpoCheckoutTab.Details}"
                type="button" role="tab" aria-controls="details-tab-pane" aria-selected="true" [disabled]="currentTab != ExpoCheckoutTab.Details">{{ 'expo.checkout.general.title' | transloco }}</button>
        </li>
        <li class="nav-item" role="presentation">
            <button class="nav-link" id="payment-tab" data-bs-toggle="tab" data-bs-target="#payment-tab-pane" [ngClass]="{'active': currentTab == ExpoCheckoutTab.Payment}"
                type="button" role="tab" aria-controls="payment-tab-pane" aria-selected="false" [disabled]="">{{ 'expo.checkout.payment.title' | transloco }}</button>
        </li> -->
    <!-- </ul> -->
    <div class="tab-content" id="checkout-tabs-content">
        <div class="tab-pane fade" id="details-tab-pane" role="tabpanel" aria-labelledby="details-tab" [ngClass]="{'active': currentTab == ExpoCheckoutTab.Details, 'show': currentTab == ExpoCheckoutTab.Details}"
            tabindex="0">
            <expo-checkout-details [userStub]="userStub" (detailsCompleted)="detailsCompleted($event)"></expo-checkout-details>
        </div>
        <div class="tab-pane fade" id="payment-tab-pane" role="tabpanel" aria-labelledby="payment-tab" tabindex="0" [ngClass]="{'active': currentTab == ExpoCheckoutTab.Payment, 'show': currentTab == ExpoCheckoutTab.Payment}">
            <expo-checkout-payment [stripe]="this.stripe" [order]="(expoOrder$ | async)" [checkoutCardErrors]="cardErrors" (paymentCompleted)="paymentCompleted($event)" (tipUpdated)="tipUpdated($event)"></expo-checkout-payment>
        </div>
    </div>
</div>