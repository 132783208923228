import { Component, OnInit, Input } from '@angular/core';
import { BagItem, Product } from 'src/app/core/models';
import { TranslocoService } from '@ngneat/transloco';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-product-no-longer-available',
    templateUrl: './product-no-longer-available.component.html',
    styleUrls: ['./product-no-longer-available.component.css']
})
export class ProductNoLongerAvailableComponent implements OnInit {

    @Input('header') header: string;
    @Input('text') text: string;
    @Input('cancelButtonText') cancelButtonText: string = null;
    @Input('submitButtonText') submitButtonText: string;

    @Input('headerParams') headerParams: any = {};
    @Input('textParams') textParams: any = {};
    @Input('cancelButtonTextParams') cancelButtonTextParams: any = {};
    @Input('submitButtonTextParams') submitButtonTextParams: any = {};

    @Input('showProducts') showProducts: boolean = true;
    @Input('items') items: BagItem[] | Product[];

    constructor(
        public translate: TranslocoService,
        public activeModal: NgbActiveModal) { }

    ngOnInit(): void {}

    getUnavailableAndArchivedOptions(item: BagItem): Product[] {
        let values: Product[] = [];
        item.options.forEach(option => option.products.filter(product => !product.isAvailable || product.isArchived).forEach(product => values.push(product)));
        return values;
    } 
}