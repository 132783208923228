import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { Exposition, Menu, Product, Restaurant } from 'src/app/core/models';
import { MainService } from 'src/app/core/services/main.service';
import { filter, first, mergeMap, tap } from 'rxjs';
import { ExpoModalComponent } from 'src/app/modals/expo-modal/expo-modal.component';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'expo-product-item',
	templateUrl: './product-item.component.html',
	styleUrls: ['./product-item.component.css']
})
export class ExpoProductItemComponent implements OnInit {

	@Input() product: Product;
	@Input() isOpen: boolean;
	@Input() isBagRestaurant: boolean;
	exposition: Exposition;
	menu: Menu;
	restaurant: Restaurant;

	quantity = new UntypedFormControl(0);
	isLoading: boolean = true;
	showDecrementControl = false;
	isSelfTriggered: boolean = false;
	initialQuantity: number;
	showProductInfo: boolean = false;

	modalRef: NgbModalRef

	constructor(
		public translate: TranslocoService,
		private mainService: MainService,
		private router: Router,
		private route: ActivatedRoute,
		private modalService: NgbModal
	) { }

	ngOnInit(): void {
		let vendorSlug = this.route.parent.snapshot.paramMap.get('slug');
		this.exposition = this.route.parent.parent.snapshot.data['exposition']

		let vendor = this.exposition?.vendors.find(v => v.vendor?.slug == vendorSlug);
		if (vendor == null) {
			this.router.navigate([this.route.parent.snapshot.url.join('/')]);
			return;
		}
		this.restaurant = vendor.vendor;
		this.menu = vendor.menus.find(v => v);

		this.mainService.expoBagLoaded$.pipe(tap(bag => bag == null ? this.isLoading = false : null), filter(bag => bag != null)).subscribe(bag => {
			if (!this.isSelfTriggered) {
				this.initialQuantity = bag.items.find(item => item.product.id == this.product.id)?.quantity || 0;
				if (this.initialQuantity == 0) this.showDecrementControl = false;
				else if (this.initialQuantity > 0 && bag.hasItem(this.product)) this.showDecrementControl = true;
				this.quantity.setValue(this.initialQuantity);
				this.isLoading = false;
			}
		})
	}

	onClickProduct() {
		this.router.navigate(['./products/' + this.product.slug], {
			relativeTo: this.route,
			state: { isDisabled: !this.isOpen || !this.isBagRestaurant }
		});
	}

	getSelectedOptions() {
		return [];
	}

	incrementItem() {
		this.isLoading = true;
		this.isSelfTriggered = true;
		if (this.quantity.value == 0) {
			let selectedOptions = this.getSelectedOptions()
			let addRequest$ = (bag) => this.mainService.addBagItem(bag, this.product, 1, null, selectedOptions.length > 0 ? selectedOptions : null);
			let request$;
			if (this.mainService.expoBag == null || this.mainService.restaurantOnExpoBag?.id != this.restaurant?.id) request$ = this.mainService.createBag(this.menu, this.restaurant, this.exposition.id).pipe(mergeMap(bag => addRequest$(bag)));
			else request$ = addRequest$(this.mainService.expoBag);

			request$.pipe(first()).subscribe({
				next: () => {
					this.quantity.setValue(1);
				},
				error: (err) => {
					console.log(err)
					switch (err.error.error) {
						case 'InconsistentMenu':
						// this.productInconsistentError([this.product]);
						// break;
						case 'InconsistentRestaurants':
							// open the modal 
							// this.showErrorModal(
							// 	"expo.modal.oneOrderPerRestaurant.header",
							// 	"expo.modal.oneOrderPerRestaurant.text",
							// 	"expo.modal.oneOrderPerRestaurant.submit",
							// 	"expo.modal.oneOrderPerRestaurant.cancel",
							// 	() => { },
							// 	() => {
							// 		this.modalService.activeInstances.subscribe(r => console.log(r));
							// 		this.mainService.deleteExpoBag().pipe(mergeMap(request$)).subscribe(_ => console.log('done'))
							// 	}
							// );
							break;
					}
				},
				complete: () => this.reset()
			});
		} else {
			this.quantity.setValue(this.quantity.value + 1);
			this.mainService.incrementItem(this.mainService.expoBag, this.product).subscribe(_ => {
				this.isLoading = false;
				this.isSelfTriggered = false;
			});
		}
	}

	decrementItem() {
		if (this.quantity.value > 0) this.quantity.setValue(this.quantity.value - 1);
		if (this.quantity.value == 0) this.showDecrementControl = false;
		this.isLoading = true;
		this.isSelfTriggered = true;
		this.mainService.decrementItem(this.mainService.expoBag, this.product).subscribe(_ => {
			this.isLoading = false;
			this.isSelfTriggered = false;
		});
	}

	reset() {
		this.isLoading = false;
		this.showDecrementControl = true;
		this.isSelfTriggered = false;
	}

	isDecrementDisabled() {
		// return this.isLoading || (this.quantity.value <= 1 && this.disableDecrement);
		return this.isLoading;
	}

	showErrorModal(header: string, text: string, submitText: string, cancelText: string = null, dismissCallback: () => any, submitCallback: () => any) {
		this.modalRef = this.modalService.open(ExpoModalComponent, { centered: true });

		this.modalRef.componentInstance.header = header;
		this.modalRef.componentInstance.text = text;
		if (cancelText != null) this.modalRef.componentInstance.cancelButtonText = cancelText
		this.modalRef.componentInstance.submitButtonText = submitText;
		this.modalRef.result.then(submitCallback, dismissCallback);
	}

}
