import { Component, OnInit, ElementRef } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { LatLngLiteral } from 'ngx-google-places-autocomplete/objects/latLng';
import { TranslocoService } from '@ngneat/transloco';

@Component({
	selector: 'app-party-setup',
	templateUrl: './party-setup.component.html',
	styleUrls: ['./party-setup.component.css'],
	animations: [
		trigger('isVisible', [
			state('visible', style({
				opacity: 1
			})),
			state('invisible', style({
				opacity: 0
			})),
			transition('visible => invisible', [
				animate('0.5s')
			]),
			transition('invisible => visible', [
				animate('1s')
			])
		])
	]
})
export class PartySetupComponent implements OnInit {
	isFormMode: boolean = false;
	formPosition: number = 0;

	// FORM SUBMISSION ELEMENTS

	constructor(public translate: TranslocoService) { }

	ngOnInit(): void {
	}

	public eventTitle: string;
	public dateAndTime: string;
	public location: string;
	/*
	Host object:
		- name
		- profile picture
		- contact info: email/phone number
	*/
	public hostName: string;
	/*
	Full guestlist should contain (optional): (as guest object)
		- guest name
		- profile picture (not required)
	*/
	public guestList: string[];
	/*
	Correct restaurant list should contain:
		- restaurant image
		- restaurant logo
		- restaurant name
	*/
	public restaurantList: string[];
	public eventNotes: string[];
	
	// Google maps components
	public center: LatLngLiteral;
	
	startPartyForm() {
		this.isFormMode = true;
	}

	nextStep(position: number) {
		if(this.formPosition == position) {
			this.formPosition++;
		}
	}
}
