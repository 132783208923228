import { Component, OnInit, Input } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';

@Component({
	selector: 'app-footer',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.css'],
})
export class FooterComponent implements OnInit {
	@Input('footerType') type: string;

    date: number = (new Date()).getFullYear();

	constructor(public translate: TranslocoService) { }

    ngOnInit() { }
    
    courrierClick() {
        let url: string;
        if (this.translate.getActiveLang() == 'fr')
            url = "https://forms.office.com/Pages/ResponsePage.aspx?id=klFWaUzw5UmXFhNYhQA0lfG2ZcKS1oxFjK6lXKVesh1UQ1dPWEhFM045MDNZVFlTNDFXRUNEQlNUMi4u&lang=fr-CA"
        else
            url = "https://forms.office.com/Pages/ResponsePage.aspx?id=klFWaUzw5UmXFhNYhQA0lfG2ZcKS1oxFjK6lXKVesh1UQ1dPWEhFM045MDNZVFlTNDFXRUNEQlNUMi4u"
        window.open(url, "_blank");
    }

    restaurantClick() {
        let url: string;
        if (this.translate.getActiveLang() == 'fr')
            url = "https://forms.office.com/Pages/ResponsePage.aspx?id=klFWaUzw5UmXFhNYhQA0lfG2ZcKS1oxFjK6lXKVesh1URDhaTUhBMk1SODhQUlJTMzlYUlZDOU85TC4u&lang=fr-CA"
        else
            url = "https://forms.office.com/Pages/ResponsePage.aspx?id=klFWaUzw5UmXFhNYhQA0lfG2ZcKS1oxFjK6lXKVesh1URDhaTUhBMk1SODhQUlJTMzlYUlZDOU85TC4u"
        window.open(url, "_blank");
    }
}
