import { Component, OnInit, Input, HostListener, Output, EventEmitter } from '@angular/core';
import { Product, Bag } from 'src/app/core/models';
import { TranslocoService } from '@ngneat/transloco';

@Component({
    selector: 'app-item-with-option-update',
    templateUrl: './item-with-option-update.component.html',
    styleUrls: ['./item-with-option-update.component.css']
})
export class ItemWithOptionUpdateComponent implements OnInit {

    @Input() product: Product;
    @Input() bag: Bag;
    @Input() type: string;

    @Output() onClick: EventEmitter<any> = new EventEmitter<any>();
    @Output() onNewItemClick: EventEmitter<any> = new EventEmitter<any>();

    firstClickOutside: boolean = true
    private wasClickInside = false;
    displayType: string = "option-update";

    constructor(public translate: TranslocoService) { }

    ngOnInit(): void {
    }

    newItemClick() {
        this.onNewItemClick.emit(this.product)
        this.onClick.emit(false)
    }

    @HostListener('click')
    clickInside() {
        this.onClick.emit(true)
        this.wasClickInside = true;
    }

    @HostListener('document:click')
    clickout() {
        if (!this.wasClickInside) {
            if (this.firstClickOutside) {
                this.firstClickOutside = false

            } else {
                this.onClick.emit(false)
            }
        }
        this.wasClickInside = false;
    }
}
