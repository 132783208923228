import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MenuItemButtonType } from 'src/app/core/enums';

@Component({
    selector: 'app-menu-item-control-button',
    templateUrl: './menu-item-control-button.component.html',
    styleUrls: ['./menu-item-control-button.component.css']
})
export class MenuItemControlButtonComponent implements OnInit {

    @Input() disabled: boolean
    @Input() color: string
    @Input() disabledColor: string
    @Input() iconColor: string
    @Input() borderRadius: string
    @Input() type: MenuItemButtonType

    @Output() onButtonClicked: EventEmitter<void> = new EventEmitter<void>()

    constructor() { }

    ngOnInit(): void {
    }

    get menuItemButtonType(): typeof MenuItemButtonType {
        return MenuItemButtonType
    }

}
