import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from './base.service';

import { Address } from '../models';
import { map } from 'rxjs/operators';

export interface IAddressUpdate {
    address?: {
        notes?: string;
        apt?: string;
    },
    preferred?: boolean
}

@Injectable({
    providedIn: 'root',
})
export class AddressService {

    private entity: string = 'addresses';

    constructor(private baseService: BaseService) { }

    addNoteToAddress(addressId: string, notes: string): Observable<any> {
        return this.baseService.update('user/' + this.entity + '/' + addressId, {
            address: { notes },
        });
    }

    updateAddress(addressId: string, notes: string = null, apt: string = null, preferred: boolean = null): Observable<any> {
        let updates: IAddressUpdate = {}
        updates.address = {}
        if (notes) {
            updates.address.notes = notes
        } else {
            updates.address.notes = "";
        }
        if (apt) updates.address.apt = apt;
        if (preferred) updates.preferred = preferred
        return this.baseService.update('user/' + this.entity + '/' + addressId, updates);
    }

    getUserAddress(addressId): Observable<Address> {
        return this.baseService.fetch('user/' + this.entity, addressId)
            .pipe(map(res => new Address().deserialize(res.address)))
    }
}
