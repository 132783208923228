import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AffiliateService } from '../core/services/affiliate.service';
import { catchError, concatMap, of } from 'rxjs';
import { OrderType } from '../core/enums';
import { TranslocoService, getBrowserLang } from '@ngneat/transloco';
import { Affiliate } from '../core/models';
import { MainService } from '../core/services/main.service';

@Component({
	selector: 'app-affiliates',
	templateUrl: './affiliates.component.html',
	styleUrls: ['./affiliates.component.css']
})

/**
 * Could probably be handled by guard, but may use this page to display offers as well. TBD.
 */
@UntilDestroy()
export class AffiliateRoutingComponent implements OnInit {

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private affiliateService: AffiliateService,
		private mainService: MainService,
		private transloco: TranslocoService
	) { }

	ngOnInit(): void {
		this.route.params.pipe(untilDestroyed(this), concatMap(params => {
			if (params['aidOrSlug'] == null) return of(null);
			else return this.affiliateService.fetch(params['aidOrSlug'])
		}), catchError(_ => {
			return of(null)
		})).subscribe((affiliate: Affiliate) => {
			if (affiliate == null) return this.router.navigate(['/']);
			else {
				let language = this.transloco.getActiveLang() || getBrowserLang() || 'en';
				this.mainService.persistAffiliate(affiliate);
				this.affiliateService.affiliate = affiliate;
				if (affiliate.type == OrderType.GiftBox) this.router.navigate([language, 'cadeaux']);
			}
		});
	}
}
