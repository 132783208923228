import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Injectable({
    providedIn: 'root'
})
export class SEOService {

    constructor(private title: Title, private meta: Meta) { }

    updateTitle(title: string) {
        this.title.setTitle(title);
    }

    updateOgTitle(title: string = "Radish | Online Food Delivery") {
        this.meta.updateTag({ name: 'og:title', content: title })
        this.meta.updateTag({ property: 'og:title', content: title })
        this.meta.updateTag({ name: 'twitter:title', content: title })
    }

    updateOgUrl(url: string = "https://radish.coop") {
        this.meta.updateTag({ name: 'og:url', content: url })
        this.meta.updateTag({ property: 'og:url', content: url })
    }

    updateDescription(desc: string = "Une plateforme coopérative pour réunir les restaurants, les livreurs et les gourmands du Québec. Commandez de votre restaurant préféré dès maintenant !") {
        this.meta.updateTag({ name: 'description', content: desc })
        this.meta.updateTag({ property: 'description', content: desc })
    }

    updateOgDescription(desc: string = "Une plateforme coopérative pour réunir les restaurants, les livreurs et les gourmands du Québec. Commandez de votre restaurant préféré dès maintenant !") {
        this.meta.updateTag({ name: 'og:description', content: desc })
        this.meta.updateTag({ property: 'og:description', content: desc })
        this.meta.updateTag({ name: 'twitter:description', content: desc })
    }

    updateOgImage(imageUrl: string) {
        this.meta.updateTag({ name: 'og:image', content: imageUrl })
        this.meta.updateTag({ property: 'og:image', content: imageUrl })
    }

    updateTwitterCard(imageUrl: string) {
        this.meta.updateTag({ name: 'twitter:card', content: imageUrl })
        this.meta.updateTag({ name: 'twitter:image', content: imageUrl })
    }

    updateImages(imageUrl: string) {
        this.updateOgImage(imageUrl)
        this.updateTwitterCard(imageUrl)
    }
}
