import { Discount } from '.';
import Dinero from 'dinero.js';
import { Deserializable } from './deserializable.model';

export class DiscountInstance implements Deserializable<DiscountInstance> {
    id: string;
    discount: Discount;
    effects: {
        total: {
            value: Dinero.Dinero
        }
    }

    constructor() {}

	deserialize(input: any): this {
        if (!input) return null;
        if (typeof input == 'string') this.id = input;
        else {
            Object.assign(this, input);
            this.id = input._id ? input._id : input.id
            if (typeof input.effects?.total?.value?.amount == 'number') {
                this.effects.total.value = Dinero({ amount: input.effects?.total?.value?.amount, currency: input.effects?.total?.value?.currency });
            }
            this.discount = new Discount().deserialize(input.discount);
        }
		return this;
	}
}
