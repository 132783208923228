import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslocoService } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import Dinero from 'dinero.js';
import { Order } from 'src/app/core/models';

@UntilDestroy()
@Component({
    selector: 'expo-tip',
    templateUrl: './tip.component.html',
    styleUrls: ['./tip.component.css']
})
export class ExpoTipComponent implements OnInit {

    @Input() set order(order) { this.setTip(order) };
    @Output() tipUpdated = new EventEmitter<any>();

    _order: Order;
    expoTipForm: FormGroup;

    //TODO: make html ngfor this
    tipOptions = [15, 18, 20];

    constructor(
        public translate: TranslocoService,
        private formBuilder: FormBuilder
    ) {
        this.expoTipForm = this.formBuilder.group({
            cardRadio: ['', Validators.required],
            notes: [''],
            tipRadio: ['15'],
            tipAmountCustom: ['']
        });
    }

    ngOnInit(): void {
        this.expoTipForm.get('tipAmountCustom').valueChanges.pipe(untilDestroyed(this)).subscribe(tip => {
            if (tip == null) return;
            this.tipUpdated.emit({ tip: Dinero({ amount: tip * 100, currency: 'CAD' }) });
        });
    }

    setTip(order: Order) {
        if (order == null) return;
        this._order = order;
        let tip = order.tip.isZero() ? this.calculateTip() : order.tip;
        this.tipUpdated.emit({ tip });
        this.expoTipControls.tipAmountCustom.setValue((tip.getAmount() / 100).toFixed(2));
        for (let rate of this.tipOptions) {
            if (order.subtotal?.percentage(rate).equalsTo(tip)) {
                this.expoTipControls.tipRadio.setValue(String(rate));
                break;
            }
        }
    }

    updateTipRadio() {
        this.expoTipControls.tipAmountCustom.setValue((this.calculateTip()?.getAmount() / 100).toFixed(2));
    }

    calculateTip(): Dinero.Dinero {
        let tipRate = +this.expoTipControls.tipRadio.value;
        let tip = tipRate ? this._order?.subtotal?.percentage(tipRate) : Dinero({ amount: +((+this.expoTipControls.tipAmountCustom.value * 100).toFixed(0)), currency: 'CAD' });
        return tip;
    }

    onCustomTipKeypress(event) {
        if (event.target.value.split('.').length > 1 && event.keyCode == 46) return false
        var charCode = (event.which) ? event.which : event.keyCode;
        if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57)) return false;
        return true;
    }

    onCustomTipChange() {
        this.expoTipControls.tipRadio.reset();
    }

    get expoTipControls() {
        return this.expoTipForm.controls;
    }


}
