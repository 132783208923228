import { Category, ListContent, Product } from 'src/app/core/models';
import { ContentService } from './../core/services/content.service';
import { Component, OnInit, ViewChild, ElementRef, HostListener, AfterViewInit } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { TranslocoService } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ActivatedRoute } from '@angular/router';
import { ProductService } from '../core/services/product.service';
import { Observable, finalize, forkJoin, map } from 'rxjs';
import { categorize, MarketSection } from '../core/helpers/categorize.helper';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { QrPromotionModalComponent } from '../modals/qr-promotion-modal/qr-promotion-modal.component';
import { MarketCategoryService } from '../core/services/data/market-category.service';
import { CategoryService } from '../core/services/category.service';

@UntilDestroy()
@Component({
	selector: 'app-market',
	templateUrl: './market.component.html',
	styleUrls: ['./market.component.css']
})
export class MarketComponent implements OnInit, AfterViewInit {

	categorySlug: string;
	category$: Observable<Category>;

	resolvingSections: boolean = true;
	openCategoryDropdown: boolean = false;
	navbarType = 'market';
	sections: MarketSection[] = [];
	rows: ListContent[];
	products: Product[];
	categories = MarketCategoryService.CATEGORIES;

	sectionIndex = 0;
	icon = '../../assets/images/icons/circle.png';

	hideDropdownButton: boolean = true;
	isCategorySelected: boolean = false;

	pid: string = null;

	constructor(
		private route: ActivatedRoute,
		private contentService: ContentService,
		private productService: ProductService,
		private categoryService: CategoryService,
		public translate: TranslocoService,
		private modalService: NgbModal,
		private viewportScroller: ViewportScroller,
		public marketCategoryService: MarketCategoryService) { }

	@ViewChild("dropdownButton", { static: false }) dropdownButton: ElementRef;
	@HostListener('window:scroll', ['$event'])
	onScroll(event) {
		let firstSectionTitle = document.getElementsByClassName("first-section-title")[0] as HTMLElement;
		if (!firstSectionTitle) return;
		if (window.scrollY > (firstSectionTitle.offsetTop - this.dropdownButton.nativeElement.offsetTop)) {
			this.hideDropdownButton = false;
		}
		else {
			this.hideDropdownButton = true;
			this.openCategoryDropdown = false;
		}

	}


	ngOnInit(): void {
		this.route.data.pipe(untilDestroyed(this)).subscribe(data => {
			if (data.content) {
				this.contentService.fetchList(data.content).pipe(finalize(() => this.resolvingSections = false)).subscribe(list => {
					this.rows = list?.items as ListContent[];
					if (this.rows != null) this.sections = this.sections.concat(this.rows.map(row => ({ title: row._title, list: row, slug: row.slug, weight: 100 })));
				});
			}
			if (data.category != null || data.type == 'category') {
				if (data.category == null) data.category = this.route.snapshot.paramMap.get('category'); //TODO: pipe observable and fetch route route data
				this.categorySlug = data.category == '' ? 'products' : data.category;
				this.isCategorySelected = true;
				this.category$ = this.categoryService.query(null, [data.category]).pipe(map(categories => categories[0]));
				this.productService.queryForMarket([data.category])
					.pipe(finalize(() => this.resolvingSections = false)).subscribe((products) => {
						this.products = products;
						this.sections = categorize(products, this.categorySlug);
					});
			} else {
				if (data.content == "latest") this.categorySlug = 'latest';
			}
		});

		// call API from within pipe
		// chain observables - READ UP ON CONCEPT
		this.route.queryParams.pipe(untilDestroyed(this)).subscribe((queryParams) => {
			this.pid = queryParams['pid'];
			if (this.pid !== null && this.pid !== undefined) {
				this.openMarchePromoModal(this.pid);
			}
		})
		
		if (this.categorySlug) localStorage.setItem('redirect_url_market', this.categorySlug)
	}

	ngAfterViewInit(): void {
		let anchor = JSON.parse(localStorage.getItem('scroll_to'));

		if (!anchor) return;

		else {
			setTimeout(() => {
				localStorage.removeItem('scroll_to');

				let index = this.sections.findIndex(section => section.title.en == anchor.en)

				if (index < 0) return;
				this.onSelect(index)

			}, 800);
		}

	}
	onSectionChange(sectionIndex) {
		this.sectionIndex = sectionIndex;
	}

	onSelect(id) {
		this.viewportScroller.setOffset([0, 90])
		this.viewportScroller.scrollToAnchor('' + id);
	}
	onClickDropdown() {
		this.openCategoryDropdown = !this.openCategoryDropdown;

	}

	//open the modal from this function
	openMarchePromoModal(pid) {
		// open modal
		let modal = this.modalService.open(QrPromotionModalComponent, {
			keyboard: true,
			centered: true,
			size: 'lg'
		});
		modal.componentInstance.pid = pid;
		return modal.result.then(() => { }, () => { });
	}
}

