<div class="delivery-closed-container">
	<img class="delivery-closed-close" src="../../../assets/images/close-icon.png" alt=""
		(click)="activeModal.close()" />
	<div class="delivery-closed-title">
        <b>{{ restaurant.name }}</b>
        <br>
		<span [innerHTML]="isCurrentlyOpen ? 
		('noDeliveryModal.temporarilyClosed' | transloco : { type: orderType })
		: ('noDeliveryModal.currentlyClosed' | transloco : { type: orderType })"></span>
	</div>
	<div class="delivery-hours">
		<div class="delivery-closed-hours-title" [innerHTML]="'noDeliveryModal.deliveryHours' | transloco : { type: orderType }"></div>
		<div *ngFor="let day of masterSchedule; let i = index" class="delivery-closed-hours-container">
            <div class="delivery-closed-day">
                {{ 'common.weekdays.' + i | transloco }}
            </div>

            <div class="restaurant-about-important-hours-dropdown-time-container">
                <div class="restaurant-about-important-hours-dropdown-time" *ngIf="checkSchedule(day); else showSchedule">
                    {{ 'noDeliveryModal.closed' | transloco }}
                </div>
                <ng-template #showSchedule>
                    <div *ngFor="let hour of parseHours(day)" class="restaurant-about-important-hours-dropdown-time">
                        {{ hour }}
                    </div>
                </ng-template>
            </div>
		</div>
	</div>
</div>
