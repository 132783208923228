import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Restaurant } from 'src/app/core/models';
import { TranslocoService } from '@ngneat/transloco';
import { take, finalize } from 'rxjs';
import { MainService } from 'src/app/core/services/main.service';
import { UntilDestroy } from '@ngneat/until-destroy';

@Component({
    selector: 'app-promocode',
    templateUrl: './promocode.component.html',
    styleUrls: ['./promocode.component.css']
})

@UntilDestroy()
export class PromocodeComponent implements OnInit {

    @Input('restaurant') restaurant: Restaurant;
    @Input('orderType') orderType: String = 'on_demand';
    promoCodeForm: UntypedFormGroup
    loading: boolean = false
    discountErrorMessage: string = null;

    constructor(
        private formBuilder: UntypedFormBuilder,
        private activeModal: NgbActiveModal,
        public translate: TranslocoService,
        private mainService: MainService) {
        this.promoCodeForm = this.formBuilder.group({
            code: ['', Validators.required]
        })
    }

    ngOnInit(): void {
    }

    onSubmit(data: any) {
        this.loading = true
        this.mainService.applyDiscount(data.code.trim(), (this.orderType == 'on_demand') ? this.mainService.order : this.mainService.marketOrder)
            .pipe(take(1), finalize(() => this.loading = false))
            .subscribe({
                next: discountInstance => {
                    this.activeModal.close(discountInstance)
                },
                error: err => {
                    if (!err?.error?.error) {
                        return
                    }
                    switch (err?.error?.error) {
                        case 'DiscountNotFound':
                        case 'DiscountCodeNotFound':
                            this.discountErrorMessage = 'promoCodeModal.error.notFound'
                            break;
                        case 'DiscountCodeExhausted':
                            this.discountErrorMessage = 'promoCodeModal.error.exhausted'
                            break;
                        case 'DiscountAlreadyApplied':
                            this.discountErrorMessage = 'promoCodeModal.error.alreadyApplied'
                            break;
                        case 'InvalidDiscountOrderType':
                            this.discountErrorMessage = 'promoCodeModal.error.invalidForOrderType'
                            break;
                        case 'InvalidDiscountRestaurant':
                            this.discountErrorMessage = 'promoCodeModal.error.invalidRestaurant'
                            break;
                        case 'OrderTooSmallForDiscount':
                            this.discountErrorMessage = 'promoCodeModal.error.orderTooSmall'
                            break;
                        case 'DiscountsNotCombinable':
                            this.discountErrorMessage = 'promoCodeModal.error.notCombinable'
                            break;
                        case 'DiscountsNotCombinableWithGiftCard':
                            this.discountErrorMessage = 'promoCodeModal.error.notCombinableGiftCard'
                            break;
                        case 'TooManyDiscountUses':
                            this.discountErrorMessage = 'promoCodeModal.error.tooManyUses'
                            break;
                        case 'DiscountTooLarge':
                            this.discountErrorMessage = 'promoCodeModal.error.tooLarge'
                            break;
                        case 'TooManyOrdersForDiscount':
                            this.discountErrorMessage = 'promoCodeModal.error.tooManyOrders'
                            break;
                        case 'TooManyOrdersWithRestaurantForDiscount':
                            this.discountErrorMessage = 'promoCodeModal.error.tooManyOrdersWithRestaurant'
                            break;
                        case 'MissingDiscount':
                        case 'MissingUser':
                        case 'MissingId':
                        case 'MissingDiscountInstanceId':
                        default:
                            this.discountErrorMessage = 'promoCodeModal.error.generic'
                    }
                }
            });
    }
}
