<form action="submit" class="notes-container" (submit)="onSubmit()" [formGroup]="expoNoteForm">
    <ngb-accordion #acc="ngbAccordion" class="notes-accordion" #notesAccordion (panelChange)="checkPanelOpen($event)" >
        <ngb-panel [title]="'expo.checkout.notes.title' | transloco">
            <ng-template ngbPanelContent>
                <textarea rows="1" class="notes-textarea form-input" type="text"
                    [placeholder]="'expo.checkout.notes.placeholder' | transloco"
                    formControlName="notes"></textarea>
            </ng-template>
        </ngb-panel>
    </ngb-accordion>
</form>