<div>
    <app-navbar [navbarType]="navbarType"></app-navbar>
    <!-- <div class="warning-bar">{{ 'market.maintenanceMessage' | transloco }}</div> -->
    <div class="market-container">
        <div class="market-header">
            <a routerLink="/marché"><h1 class="header-title">Marché Radish</h1></a>
            <a class="restaurant-listing-routing-button" [routerLink]="['/' + translate.getActiveLang() + '/restaurants/']">
                <div class="restaurant-listing-routing-icon-wrapper">
                    <img class="restaurant-listing-routing-icon" src="../../assets/images/market/package.png" />
                </div>
                <div class="restaurant-listing-routing-message"> {{ 'routing.toRestaurant' | transloco }}</div>
            </a>
        </div>
        <div class="market-category-nav">
            <div class="market-category-container">
                <div class="scroll-space d-inline-block d-md-inline-block d-lg-none"></div>
                <button type="button" class="market-category-button-wrapper" *ngFor="let category of categories" [routerLink]="['/', translate.getActiveLang(), 'marché', category.route]">
                    <div class="market-nav-icon-wrapper">
                        <img class="market-nav-icon" src="{{ category.icon }}" />
                    </div>
                    <p [ngClass]="category.route == categorySlug ? 'option-title selected' : 'option-title'">{{ category.title?.[translate.getActiveLang()] }}</p>
                </button>
                <div class="scroll-space d-inline-block d-lg-none"></div>
            </div>
        </div>
        <div class="base-market-section-header-container">
            <app-base-market-section-header [isCategorySelected]="isCategorySelected" [category$]="category$"></app-base-market-section-header>
        </div>
        <!-- TODO: add product and restaurant component routing in here -->
        <!-- <div class="market-listing-container"> -->
        <div class="market-listing-container" *ngIf="categorySlug != 'products'">
            
            <div class="market-sidebar-container d-none d-md-block">
                <div *ngIf="resolvingSections; else sectionTitlesLoaded">
                    <div *ngFor="let title of [].constructor(5)" class="loading-section-title-header loading-gradient"></div>
                </div>
                <ng-template #sectionTitlesLoaded>
                    <div class="market-sidebar" *ngFor="let section of sections; index as i">
                        <a class="market-sidebar-title" id="{{ '#' + i }}" (click)="onSelect(i); sectionIndex = i"
                            [ngClass]="{ 'current-section': sectionIndex == i }"> {{ section.title?.[translate.getActiveLang()] }} </a>
                    </div>
                </ng-template>
            </div>
            <button #dropdownButton class="market-sidebar-dropdown d-flex d-md-none" [ngClass]="{ 'hide-dropdown-button' : hideDropdownButton == true}" (click)="onClickDropdown()">
                <div class="dropdown-section-title">
                    {{ sections?.[sectionIndex]?.title?.[translate.getActiveLang()] }}
                </div>
                <div class="dropdown-chevron-container" [ngClass]="{'rotate-chevron': openCategoryDropdown == true}">
                    <img class="chevron-icon" src="../../assets/images/icons/chevron-right.png"/>
                </div>
            </button>
            <div *ngIf="openCategoryDropdown" class="market-category-dropdown-container">
                <!-- <div class="market-category" *ngFor="let row of rows; index as i"> -->
                <div class="market-category" *ngFor="let row of sections; index as i">
                    <span *ngIf="sectionIndex == i" class="current-section-highlight"></span>
                    <div class="market-category-title" id="{{ '#' + i }}" (click)="onSelect(i); sectionIndex = i; onClickDropdown()"
                        [ngClass]="{'current-section': sectionIndex == i}"> {{ row.title?.[translate.getActiveLang()] }} </div>
                    <!-- <div class="market-category-title" id="{{ '#' + i }}" (click)="onSelect(i); sectionIndex = i; onClickDropdown()"
                        [ngClass]="{'current-section': sectionIndex == i}"> {{ row.title }} </div> -->
                    <!-- <div class="market-category-title" id="{{ '#' + i }}" (click)="onSelect(i); sectionIndex = i; onClickDropdown()"
                        [ngClass]="{'current-section current-section-dropdown': sectionIndex == i}"> {{ row.title?.[translate.getActiveLang()] }} </div> -->
                </div>
            </div>
            <div class="market-subcategory-listing-wrapper" [ngClass]='{"is-not-featured-row": sections && sections[0] && sections[0].products}'>
                <div *ngIf="resolvingSections; else sectionsLoaded">
                    <div class="loading-row-wrapper" *ngFor="let list of [].constructor(3)">
                        <div class="loading-list-header loading-gradient"></div>
                        <div class="loading-items-wrapper">
                            <div *ngFor="let product of [].constructor(15)" class="loading-item-wrapper">
                                <div class="loading-item-image loading-gradient"></div>
                                <div class="loading-item-header loading-gradient"></div>
                                <!-- <div class="loading-item-header loading-gradient"></div> -->
                            </div>
                        </div>
                    </div>
                </div>
                <ng-template #sectionsLoaded>
                    <ng-container *ngFor="let section of sections; index as i">
                        <div class="market-subcategory-listing" appScrollSpy
                            [spiedTags]="['H4']" (sectionChange)="onSectionChange($event)">
                            <h4 class="market-subcategory-title" [ngClass]="{'first-section-title': i == 0}" id="{{ '' + i }}"> {{ section.title?.[translate.getActiveLang()] }} </h4>
                            <div class="market-subcategory-row-wrapper">
                                <app-base-market-row *ngIf="sections && sections[0] && sections[0].list" [items]="section?.list?.items"></app-base-market-row>
                                <app-products-market-row *ngIf="sections && sections[0] && sections[0].products" [products]="section?.products"></app-products-market-row>
                            </div>
                        </div>
                    </ng-container> 
                </ng-template>
            </div>
        </div>


        <!-- TESTING FOR ALL TAB -->

        <div *ngIf="categorySlug == 'products'" class="market-listing-container">
            <div class="market-sidebar-container d-none d-md-block">
                <div *ngIf="resolvingSections; else sectionTitlesLoaded">
                    <div *ngFor="let title of [].constructor(5)" class="loading-section-title-header loading-gradient"></div>
                </div>
                <ng-template #sectionTitlesLoaded>
                    <div class="market-sidebar" *ngFor="let section of sections; index as i">
                        <a class="market-sidebar-title" id="{{ '#' + i }}" (click)="onSelect(i); sectionIndex = i"
                            [ngClass]="{ 'current-section': sectionIndex == i }"> {{ section.title?.[translate.getActiveLang()] }} </a>
                    </div>
                </ng-template>
            </div>
            <button #dropdownButton class="market-sidebar-dropdown d-flex d-md-none" [ngClass]="{ 'hide-dropdown-button' : hideDropdownButton == true}" (click)="onClickDropdown()">
                <div class="dropdown-section-title">
                    {{ sections?.[sectionIndex]?.title?.[translate.getActiveLang()] }}
                </div>
                <div class="dropdown-chevron-container" [ngClass]="{'rotate-chevron': openCategoryDropdown == true}">
                    <img class="chevron-icon" src="../../assets/images/icons/chevron-right.png"/>
                </div>
            </button>
            <div *ngIf="openCategoryDropdown" class="market-category-dropdown-container">
                <div class="market-category" *ngFor="let row of sections; index as i">
                    <span *ngIf="sectionIndex == i" class="current-section-highlight"></span>
                    <div class="market-category-title" id="{{ '#' + i }}" (click)="onSelect(i); sectionIndex = i; onClickDropdown()"
                        [ngClass]="{'current-section': sectionIndex == i}"> {{ row.title?.[translate.getActiveLang()] }} </div>
                </div>
            </div>
            <div class="market-subcategory-listing-wrapper" [ngClass]='{"is-not-featured-row": sections && sections[0] && sections[0].products}'>
                <div *ngIf="resolvingSections; else sectionsLoaded">
                    <div class="loading-row-wrapper" *ngFor="let list of [].constructor(3)">
                        <div class="loading-list-header loading-gradient"></div>
                        <div class="loading-items-wrapper">
                            <div *ngFor="let product of [].constructor(15)" class="loading-item-wrapper">
                                <div class="loading-item-image loading-gradient"></div>
                                <div class="loading-item-header loading-gradient"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <ng-template #sectionsLoaded>
                    <ng-container *ngFor="let section of sections; index as i">
                        <div class="market-subcategory-listing" appScrollSpy
                            [spiedTags]="['H4']" (sectionChange)="onSectionChange($event)">
                            <h4 class="market-subcategory-title" [ngClass]="{'first-section-title': i == 0}" id="{{ '' + i }}"> {{ section.title?.[translate.getActiveLang()] }} </h4>
                            <div class="market-subcategory-row-wrapper">
                                <app-base-market-row *ngIf="sections && sections[0] && sections[0].list" [items]="section?.list?.items"></app-base-market-row>
                                <app-products-market-row *ngIf="sections && sections[0] && sections[0].products" [products]="section?.products"></app-products-market-row>
                            </div>
                        </div>
                    </ng-container> 
                </ng-template>
            </div>
        </div>

        <!-- TESTING FOR ALL TAB -->
    </div>
</div>
<app-footer [footerType]="'white'"></app-footer>
