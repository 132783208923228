<button type="button"
    [routerLink]="['../']"
    class="back-button"
    [style.background-color]="backgroundColor">
    <svg [style.height.rem]="1.2" [style.width.rem]="1.2" viewBox="0 0 6.3499999 6.3500002" class="">
        <g>
            <g transform="translate(0 -290.65)">
                <path id="path9417"
                    d="m1.8505492 292.23437a.26460982.26460982 0 0 0 -.1819011.0801l-1.32239993 1.32395a.26460982.26460982 0 0 0 0 .3731l1.32239993 1.32447a.26528594.26528594 0 0 0 .3751707-.37517l-.8707477-.87075h4.6477783a.2648413.2648413 0 1 0 0-.52968h-4.6498455l.8728149-.87075a.26460982.26460982 0 0 0 -.1932696-.45527z"
                    font-variant-ligatures="normal" font-variant-position="normal" font-variant-caps="normal"
                    font-variant-numeric="normal" font-variant-alternates="normal"
                    font-feature-settings="normal" text-indent="0" text-align="start"
                    text-decoration-line="none" text-decoration-style="solid" text-decoration-color="rgb(0,0,0)"
                    text-transform="none" text-orientation="mixed" white-space="normal" shape-padding="0"
                    isolation="auto" mix-blend-mode="normal" solid-color="rgb(0,0,0)" solid-opacity="1"
                    vector-effect="none" [attr.fill]="iconColor" data-original="#000000" class="" />
            </g>
        </g>
    </svg>
</button>
