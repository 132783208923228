import { Component } from "@angular/core";
import { FormGroup, FormBuilder } from '@angular/forms';
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
	selector: 'app-crate-pickup-scheduler',
	templateUrl: './crate-pickup-scheduler.component.html',
	styleUrls: ['./crate-pickup-scheduler.component.css']
})
export class CratePickupScheduler {

	scheduleCratePickupForm: FormGroup;  
	
	constructor(private formBuilder: FormBuilder) {
		this.scheduleCratePickupForm = this.formBuilder.group({
			selectedDate: '',
			selectedTime: null 
		})    
	}
	
	// weekDays = [
	// 	'Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'
	// ]

	dates = [
		{ 
			dateNumeric: '01/05/2023', 
			weekDay: 'Sun', 
			monthName: 'May', 
			dayOfMonth: 1, 
			isToday: false,
			isDeliveryAvailable: false, 
			isSelected: false,
		},
		{ 
			dateNumeric: '02/05/2023', 
			weekDay: 'Mon', 
			monthName: 'May', 
			dayOfMonth: 2, 
			isToday: false,
			isDeliveryAvailable: false, 
			isSelected: false 
		},
		{ 
			dateNumeric: '03/05/2023', 
			weekDay: 'Tue', 
			monthName: 'May', 
			dayOfMonth: 3, 
			isToday: false,
			isDeliveryAvailable: true, 
			isSelected: false ,
			isDefaultDate: true
		},
		{ 
			dateNumeric: '04/05/2023', 
			weekDay: 'Wed', 
			monthName: 'May', 
			dayOfMonth: 4, 
			isToday: false,
			isDeliveryAvailable: true, 
			isSelected: false 
		},
		{ 
			dateNumeric: '05/05/2023', 
			weekDay: 'Thu', 
			monthName: 'May', 
			dayOfMonth: 5, 
			isToday: true,
			isDeliveryAvailable: true, 
			isSelected: false 
		},
		{ 
			dateNumeric: '06/05/2023', 
			weekDay: 'Fri', 
			monthName: 'May', 
			dayOfMonth: 6, 
			isToday: false,
			isDeliveryAvailable: true, 
			isSelected: true 
		},
		{ 
			dateNumeric: '07/05/2023', 
			weekDay: 'Sat', 
			monthName: 'May', 
			dayOfMonth: 7, 
			isToday: false,
			isDeliveryAvailable: false, 
			isSelected: false 
		},
		// {
		// 	item: 8
		// },
		// {
		// 	item: 9
		// },
		// {
		// 	item: 10
		// },
		// {
		// 	item: 11
		// },
		// {
		// 	item: 12
		// },
		// {
		// 	item: 13
		// },
		// {
		// 	item: 14
		// }
	]
	
	currentDateIndex: number = 0;
	dateIndexStart: number = 0;
	dateIndexEnd: number = 7;
	
	setCurrentWeek() {
		console.log('the current week is...')
	}
	
	seePreviousWeek() {
		console.log(this.currentDateIndex);
	}
	
	seeNextWeek() {
		console.log(this.currentDateIndex);
	}
	
	getCurrentWeek() {
		return `
			<div>hellooooo</div>
		`
	}
	
	setSelectedDate() {
		return 'hello'
	}

	marcheOrderDate = {
		year: 2023,
		month: 5,
		day: 1
	};

	pickupTimes = [
		{ time: '4:00pm - 4:30pm' },
		{ time: '5:00pm - 5:30pm' },
		{ time: '6:00pm - 6:30pm' }
	]

	getSelectedDate() {

	}

	onSubmit() {
		console.log('Form submitted - form value: ', this.scheduleCratePickupForm.value);
	}
	
	// getMarcheOrderDate(date: NgbDate) {
	// 	return this.marcheOrderDate;
	// }

}

