<div class="section-header-container" [ngClass]="{'en': browserLang === 'en', 'fr': browserLang === 'fr'}">
    <div class="featured featured-text">
        <div class="schedule-container">
            <market-delivery-timer></market-delivery-timer>
        </div>
    </div>
    <div class="featured" *ngFor="let feature of features" (click)="onClickHeaderFeature(feature.id, feature.url)">
        <div class="overlay"></div>
        <div class="featured-image-wrapper">
            <img class="featured-image" src='{{feature.image}}' alt="" />
            <div class="featured-image-text-wrapper">
                <div class="featured-image-text title">{{ feature.title | transloco }}</div>
                <div *ngIf="feature.subtitle != ''" class="featured-image-text subtitle">{{ feature.subtitle | transloco }}</div>
            </div>
        </div>
    </div>
</div>