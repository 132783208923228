import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslocoService } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import Dinero from 'dinero.js';
import { Observable } from 'rxjs';
import { Bag, Order } from 'src/app/core/models';
import { ConfigService } from 'src/app/core/services/config.service';
import { MainService } from 'src/app/core/services/main.service';

@UntilDestroy()
@Component({
    selector: 'expo-checkout-payment',
    templateUrl: './payment.component.html',
    styleUrls: ['./payment.component.css']
})
export class ExpoCheckoutPaymentComponent implements OnInit {

    @ViewChild('cardElement') set ce(cardElement: ElementRef) {
        this.cardElement = cardElement;
    }

    @Input() set order(order) { this.setTip(order) };
    @Input() set stripe(stripe) { this.configureStripe(stripe) };
    @Input() checkoutCardErrors: string[];
    @Output() paymentCompleted = new EventEmitter<any>();
    @Output() tipUpdated = new EventEmitter<any>();

    bag$: Observable<Bag>;

    _order: Order;
    //TODO: make html ngfor this
    tipOptions = [15, 18, 20];
    expoCheckoutPaymentForm: FormGroup;
    cardElement: ElementRef

    card: any;
    savedCards = [];
    cardErrors: string[];
    isCardEmpty: boolean = true;

    isAddNote: boolean = false;
    isAddTip: boolean = false;
    isLoading: boolean = false;
    isValid: boolean = false;

    constructor(
        public translate: TranslocoService,
        private formBuilder: FormBuilder,
        private mainService: MainService,
        public configService: ConfigService
    ) {
        this.expoCheckoutPaymentForm = this.formBuilder.group({
            cardRadio: ['', Validators.required],
            notes: [''],
            tipRadio: ['15'],
            tipAmountCustom: ['']
        });
    }

    ngOnInit(): void {
        this.expoCheckoutPaymentForm.get('tipAmountCustom').valueChanges.pipe(untilDestroyed(this)).subscribe(tip => {
            this.tipUpdated.emit({ tip: Dinero({ amount: tip * 100, currency: 'CAD' }) });
        });
        this.bag$ = this.mainService.expoBagLoaded$;
    }

    configureStripe(stripe) {
        if (stripe == null) return;
        setTimeout(() => {
            const elements = stripe.elements({
                fonts: [{ cssSrc: 'https://use.typekit.net/yxk6kbz.css' }]
            });

            this.card = elements.create('card', {
                style: {
                    base: {
                        fontFamily: 'mr-eaves-xl-modern, Open Sans, sans-serif',
                        fontSize: '1.25rem'
                    }
                }
            });
            this.card.mount(this.cardElement.nativeElement);
            this.card.addEventListener('change', ({ error }) => this.cardErrors = error && error.message);
            this.card.addEventListener('focus', () => this.expoCheckoutPaymentControls.cardRadio.setValue('new-card'));
            this.card.on('change', (event) => this.isCardEmpty = event.empty);
        }, 75);
    }

    setTip(order: Order) {
        if (order == null) return;
        this._order = order;
        let tip = order.tip.isZero() ? this.calculateTip() : order.tip;
        this.expoCheckoutPaymentControls.tipAmountCustom.setValue((tip.getAmount() / 100).toFixed(2));
        for (let rate of this.tipOptions) {
            if (order.subtotal?.percentage(rate).equalsTo(tip)) {
                this.expoCheckoutPaymentControls.tipRadio.setValue(String(rate));
                break;
            }
        }
    }


    updateTipRadio() {
        this.expoCheckoutPaymentControls.tipAmountCustom.setValue((this.calculateTip()?.getAmount() / 100).toFixed(2));
        this.tipUpdated.emit({ tip: this.calculateTip(), currency: 'CAD' });

    }

    calculateTip(): Dinero.Dinero {
        let tipRate = +this.expoCheckoutPaymentControls.tipRadio.value;
        let tip = tipRate ? this._order?.subtotal?.percentage(tipRate) : Dinero({ amount: +((+this.expoCheckoutPaymentControls.tipAmountCustom.value * 100).toFixed(0)), currency: 'CAD' });
        return tip;
    }

    onCustomTipKeypress(event) {
        if (event.target.value.split('.').length > 1 && event.keyCode == 46) return false;
        var charCode = (event.which) ? event.which : event.keyCode;
        if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57)) return false;
        return true;
    }

    onCustomTipChange() {
        this.expoCheckoutPaymentControls.tipRadio.reset();
    }

    getMissingAmount(bag: Bag): Dinero.Dinero {
		return bag ? this.configService.MINIMUM_EXPO_POCHA_ORDER_AMOUNT.subtract(bag.subtotal) : null;
	}

    onSubmit() {
        this.cardErrors = this.checkoutCardErrors;
        this.isLoading = document.getElementById("checkoutButton").getAttribute("disabled") == null;
        if (this.isLoading) {
            this.cardErrors = null;
        }
        this.paymentCompleted.emit({
            notes: '', //TODO: add notes here
            tip: this.calculateTip(),
            card: this.card
        });
    }

    get expoCheckoutPaymentControls() {
        return this.expoCheckoutPaymentForm.controls;
    }

}
