import { Component, Input, OnInit } from '@angular/core';
import { Product } from 'src/app/core/models';

@Component({
  selector: 'app-combo-listing-item',
  templateUrl: './combo-listing-item.component.html',
  styleUrls: ['./combo-listing-item.component.css']
})
export class GiftBoxComboListingItemComponent implements OnInit {

  @Input() product: Product

  constructor() { }

  ngOnInit(): void {
  }
  
}
