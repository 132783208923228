<div *ngIf="item.quantity > 0" class="bag-item">
    <a class="bag-item-left" [routerLink]="['/' + translate.getActiveLang() + '/expo/'+ expositionSlug + '/r/' + restaurantSlug + '/products/' + item?.product?.slug]">
        <div class="bag-item-image-wrapper">
            <img class="bag-item-image" src="{{ item?.thumbnailUrl != null ? item?.thumbnailUrl : '../../../assets/images/Radish-LogoMark-200x200.jpg' }}"/>
        </div>
        <div class="bag-item-title">{{ item.product.title }}</div>
    </a>
    <div class="bag-item-right">
        <div class="bag-item-buttons" *ngIf="page != ExpoBagPage.Order">
            <button *ngIf="showDecrementControl" type="button" class="bag-item-button bag-item-buttons-minus"
                (click)="decrementItem(item)" [disabled]="isDecrementDisabled(item, bagSize)">
                <img src="../../../../assets/images/icons/minus-white.svg">
            </button>
            <strong class="bag-item-quantity">{{ item.quantity }}</strong>
            <button *ngIf="showIncrementControl" type="button" class="bag-item-button bag-item-buttons-plus"
                (click)="incrementItem(item)" [disabled]="isIncrementDisabled()">
                <img src="../../../../assets/images/icons/plus-white.svg">
            </button>
        </div>
        <div class= "bag-item-price">{{ item.price | price | translocoCurrency }}</div>
    </div>
</div>