import moment from 'moment';
import { Category } from '../category.model';
import { Deserializable } from '../deserializable.model';
import { Media } from '../media.model';
import { Product } from '../product/product.model';
import { Restaurant} from '../restaurant.model';
import { TimePeriod } from '../time-period.model';
import { TranslatedText, TranslatedTextType } from '../translated-text.model';

export class Menu implements Deserializable<Menu> {
	id: string;
    _title?: TranslatedText;
    slug?: string;
	description?: any;
	restaurant: Restaurant;
	type: string;
	sections?: MenuSection[];
	assets?: {
		primary?: Media;
		gallery?: any[];
	};
    isAvailable: boolean;
    schedule: [TimePeriod[]];
    meal?: Meal

    constructor() {

    }

    get title(): TranslatedTextType {
        return this._title?.value
    }

    set title(title: TranslatedTextType) {
        this._title = title as TranslatedText
    }

    get isOpen(): boolean {
        let now = new Date();
        return this.isOpenAtDate(now);
    }

    get products(): Product[] {
        return this.sections.reduce((list, section) => list = list.concat(section.products), []);
    }

    get itemCount(): number {
        return this.sections.reduce((count, section) => count += section.products.length, 0);
    }

    isOpenAtDate(date: Date): boolean {
        if (date == null) date = new Date();
        if (this.isAvailable == false) return false;

        let daySchedule = this.schedule[date.getDay()];

        return daySchedule.some((period) => {
			if (!period.active) return false;

			let format = 'hh:mm:ss a';
			let start = moment(moment.utc(period.start).clone().local().format(format), format).clone();
			let current = moment(moment.utc(date).clone().local().format(format), format).clone();
			let end = moment(moment.utc(period.end).clone().local().format(format), format).clone();

			return current.isBetween(start, end) || current.isSame(start);
		});
    }

    nextDateOpen(date: Date = new Date()): Date {
        let currentDate = moment(date);
        for (let day = 0; day < 7; day++) {
            let dailyMenuSchedules = this.schedule[(day + currentDate.day()) % 7];
            if (dailyMenuSchedules == null) continue;
            for (let period of dailyMenuSchedules) {
                if (!period.active) continue;
                let scheduleDate = moment(period.start);
                let nextOpenDate = moment(date).utc().local().add(day, 'days').set({'hour': scheduleDate.hour(), 'minute': scheduleDate.minute(), 'second': scheduleDate.second()});
                if (nextOpenDate.isBefore(moment(date))) continue;
                return nextOpenDate.toDate();
            }
        }
        return null;
    }

	deserialize(input: any): this {
        if (!input) return null;
        if (typeof input == 'string') this.id = input;
        else {
            Object.assign(this, input);
            this.id = input._id ? input._id : input.id
            this.sections = input?.sections ? input.sections.map((section) => new MenuSection().deserialize(section)) : []
            this.assets = input.assets ? input.assets : {}
            this.assets.primary = new Media().deserialize(input.assets?.primary)
            this.title = new TranslatedText().deserialize(input.title)
        }
		return this;
    }

    get primaryImageUrl() {
        return this.assets?.primary?.url
    }
}

export class MenuSection implements Deserializable<MenuSection> {

    id: string;
	_title: TranslatedText;
	_description?: TranslatedText;
    products: Product[];
    type?: MenuSectionType;
    media?: Media[];
    controls: [{
        title?: {
            en?: string;
            fr?: string;
        };
        type?: MenuControlType
    }];
    categories: Category[]

    constructor() {}

    get title(): TranslatedTextType {
        return this._title?.value
    }

    set title(title: TranslatedTextType) {
        this._title = title as TranslatedText
    }

    get description(): TranslatedTextType {
        return this._description.value
    }

    set description(description: TranslatedTextType) {
        this._description = description as TranslatedText
    }

	deserialize(input: any): this {
        if (!input) return null;
        if (typeof input == 'string') this.id = input;
        else {
            Object.assign(this, input);
            this.id = input._id ? input._id : input.id
            this.products = Array.isArray(input.products) ? input.products.map((product) => new Product().deserialize(product)) : []
            this.categories = Array.isArray(input.categories) ? input.categories.map(category => new Category().deserialize(category)) : []
            this.media = Array.isArray(input.media) ? input.media.map(media => new Media().deserialize(media)) : []
            this.title = new TranslatedText().deserialize(input.title)
            this.description = new TranslatedText().deserialize(input.description)
        }
		return this;
    }

    get thumbnailUrl(): string {
        return this.media.length > 0 ? this.media[0]?.thumbnailUrl : null
    }

    get imageUrl(): string {
        return this.media.length > 0 ? this.media[0]?.url : null
    }
}

export enum MenuSectionType {
    Feature = "feature",
    ProductList = "product_list"
}

export enum MenuControlType {
    Button = "button",
    Hyperlink = "hyperlink"
}

export enum Meal {
    Breakfast = 'breakfast',
    Lunch = 'lunch',
    Dinner = 'dinner'
}
