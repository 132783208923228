<div class="market-search-result-popover" [routerLink]="'/' + translate.getActiveLang() + product.url(ExtendedOrderType.Market)">
    <img class="popover-header-image" [src]="product.image" [alt]="product.title">
    <div class="popover-content">
        <!-- TODO: use url()? -->
        <a (click)="$event.stopPropagation()" [routerLink]="'/' + translate.getActiveLang() + '/marché/merchants/' + product.restaurantSlug" class="merchant">{{ product.restaurantName }}</a>
        <h3 class="popover-title">{{ product.title }}</h3>
        <!-- TODO: category tags -->
        <!-- <img src="../../../assets/images/icons/tag.svg" [alt]="'market.search.category' | transloco" class="tag-icon"> -->
        <!-- <ul class="tags-list" [hidden]="!product.categories.length">
            <li class="tags-list-item" *ngFor="let category of product.categories">
                <div class="tag-text">{{ category.value }}</div>
            </li>
        </ul> -->
        <p class="brief" [hidden]="!product.brief">{{ product.brief }}</p>
        <div class="price-line">{{ product.price | price | translocoCurrency }} {{ 'market.servings.' + product.servings.unit | transloco : { count: product.servings.count } }}</div>
        <a [routerLink]="'/' + translate.getActiveLang() + product.url(ExtendedOrderType.Market)"
            class="popover-visit-page-link">{{ 'market.search.popoverLink.product' | transloco }}</a>    
        <!-- <button type="button" class="add-to-crate-btn">{{ 'market.search.addToCrate' | transloco }}</button> -->
    </div>
</div>