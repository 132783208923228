import { Deserializable } from "../deserializable.model";
import { Product } from "../product";
import { Restaurant } from "../restaurant.model";
import { TranslatedText, TranslatedTextType } from "../translated-text.model";
import { Content, ContentType } from "./content.model";


export class ListContent extends Content implements Deserializable<ListContent> {

    _id: string;
    _title?: TranslatedText;
    slug: string;
    type: ContentType = ContentType.ListContent;
    updatedAt: string; //TODO: convert to date
    createdAt: string;
    items?: (Restaurant | Product | ListContent)[];

    get title(): TranslatedTextType {
        return this._title?.value
    }

    set title(title: TranslatedTextType) {
        this._title = title as TranslatedText
    }

    deserialize(input: any): ListContent {
        if (!input) return null;
        if (typeof input == 'string') this.id = input;
        else {
            Object.assign(this, input);
            this.id = input._id ? input._id : input.id;
            if (input.items) {
                this.items = [];
                for (let item of input.items) {
                    if (item.restaurant) item = new Restaurant().deserialize(item.restaurant);
                    else if (item.product) item = new Product().deserialize(item.product);
                    else if (item.list) item = new ListContent().deserialize(item.list);
                    this.items.push(item);
                }
            }

            this.title = new TranslatedText().deserialize(input.title)
        }
		return this;    
    }

    
}