<div class="restaurant-about-important-rating-container" [ngClass]="configService.getCssClass(restaurantSlug)" [style.font-size]="fontSize">
    <ng-container *ngIf="averageRating">
        <svg *ngFor="let dummy of [].constructor(numFullStars)" class="restaurant-about-important-rating-star-image"
            viewBox="0 0 24 22.83">
            <path [attr.fill]="iconColor"
                d="M12,0l3.67,7.57L24,8.72l-6.06,5.83,1.48,8.28-7.42-4-7.42,4,1.48-8.28L0,8.72,8.33,7.57Z" />
        </svg>
        <svg *ngFor="let dummy of [].constructor(numHalfStars)" class="restaurant-about-important-rating-star-image"
            viewBox="0 0 24 22.83">
            <path [attr.fill]="iconColor"
                d="M12,4.59,14.34,9.4l5.3.74-3.86,3.71.94,5.26L12,16.59ZM12,0,8.33,7.57,0,8.72l6.06,5.83L4.58,22.83l7.42-4,7.42,4-1.48-8.27L24,8.72,15.67,7.57Z" />
        </svg>
        <svg *ngFor="let dummy of [].constructor(numEmptyStars)"
            class="restaurant-about-important-rating-star-image" viewBox="0 0 24 22.83">
            <path [attr.fill]="iconColor"
                d="M12,4.59,14.34,9.4l5.3.74-3.86,3.71.94,5.26L12,16.59,7.28,19.12l.94-5.27L4.36,10.14l5.3-.74ZM12,0,8.33,7.57,0,8.72l6.06,5.83L4.58,22.83l7.42-4,7.42,4-1.48-8.27L24,8.72,15.67,7.57Z" />
        </svg>
    </ng-container>
    <div class="restaurant-about-important-number-reviews">
        ({{ reviews.length }})
    </div>
</div>
