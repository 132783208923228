<div class="nav-scheduler-container">
    <div class="nav-scheduler-top">
        <img class="nav-scheduler-escape" (click)="onCloseSchedule()" src="../../../../assets/images/icons/close.svg"
            alt="close-page">
        <div class="nav-scheduler-title" [innerHTML]="'expo.nav.title' | transloco"></div>
        <div class="nav-scheduler-description" *ngIf="delay">{{ 'expo.nav.description' | transloco : { delay: delay} }}</div>
    </div>
    <div class="nav-scheduler-selection-container">
        <div class="nav-scheduler-selection-item" (click)="onScheduleChange(null)">
            <input id="default" class="nav-scheduler-selection-radio" type="radio" name="schedule"
                [checked]="(scheduledDate$ | async) == null">
            <label for="default" class="nav-scheduler-selection-title">{{ 'expo.nav.asap' | transloco }}</label>
        </div>
        <div class="nav-scheduler-selection-item" *ngFor="let schedule of listOfSchedules; index as i"
            (click)="onScheduleChange(schedule)">
            <input [id]="i" class="nav-scheduler-selection-radio" type="radio" name="schedule"
                [checked]="isSameDate(scheduledDate$ | async, schedule)">
            <label [for]="i" class="nav-scheduler-selection-title">{{ schedule | translocoDate : { timeStyle:
                'short' } }}</label>
        </div>
    </div>
    <button class="nav-scheduler-button" (click)="onConfirmSchedule()">{{ 'expo.nav.button' | transloco }}</button>
</div>