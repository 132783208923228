import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { MenuItemButtonType } from 'src/app/core/enums';
import { ConfigService } from 'src/app/core/services/config.service';
import { Bag, Product } from 'src/app/core/models';
import { MenuItemBaseComponent } from '../menu-item-base/menu-item-base.component';

@Component({
    selector: 'app-menu-item',
    templateUrl: './menu-item.component.html',
    styleUrls: ['./menu-item.component.css']
})
export class MenuItemComponent extends MenuItemBaseComponent implements OnInit {

    @Input() restaurantCssClass: string = ''

    constructor(public translate: TranslocoService, public configService: ConfigService) {
        super(translate, configService)
    }

    ngOnInit(): void {
        super.ngOnInit()
    }

}
