import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { RestaurantService } from '../services/restaurant.service';
import { Observable } from 'rxjs';
import { Restaurant, Review } from 'src/app/core/models';
import { catchError } from 'rxjs/operators';
import { TranslocoService } from '@ngneat/transloco';

@Injectable({
    providedIn: 'root'
})
export class ReviewsResolverService implements Resolve<any> {

    constructor(private restaurantService: RestaurantService, private translate: TranslocoService, private router: Router) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | Review[]> {
        return this.restaurantService.getReviews(route.parent.paramMap.get('slug'))
            .pipe(catchError(err => this.router.navigate(['restaurants'])))
    }
}
