<app-navbar [navbarType]="navbarType"></app-navbar>
<div class="blog-container">
    <div class="blog-banner-container">
        <div class="blog-banner">
            <div class="blog-banner-text blog-banner-item">
                <h5 class="blog-banner-header">{{ 'blog.banner.text' | transloco }}</h5>
                <button class="blog-banner-button" [routerLink]="['/' + translate.getActiveLang() + '/restaurants/' + blogBanner.slug]">
                    {{ 'blog.banner.button' | transloco }}
                </button>
            </div>
            <div class="blog-banner-image blog-banner-item">
                <img src="{{ blogBanner.image }}" />
            </div>
        </div>
    </div>
    <div class="blog-container-inner">
        <h1 class="blog-title">{{ 'blog.article.title' | transloco }}: </h1>
        <div class="blog-title-second-line" [@wordUpdated]="word">
            {{ 'blog.article.subtitle' | transloco }}
            <div class="blog-title-changing-word-wrapper">
                <div class="blog-title-changing-word" *ngFor="let word of [ word ]" [class.blog-strike-through]="word === 'Foodora'">
                    {{ word + '.' }}
                </div>
                {{ word + '.' }}
            </div>
        </div>
        <p class="blog-date">{{ 'blog.article.date' | transloco }}</p>
        <div class="blog-header-image-container">
            <img class="blog-header-image blog-image" [src]="blogPost.image" alt="" />
        </div>
        <div class="blog-content-container">
            <div class="blog-description-container">
                <div class="blog-author-info">
                    <div class="blog-author-info-image">
                        <img src="../../assets/images/mansib.jpg" />
                    </div>
                    <div class="blog-author-info-text">
                        <div class="blog-author-info-name">
                            <p>{{ blogPost.author.name }}</p>
                        </div>
                        <div class="blog-author-info-title">
                            <p>{{ 'blog.author.title' | transloco }}</p>
                        </div>
                    </div>
                </div>
                <div class="blog-social-media">
                    <div class="blog-facebook">
                        <a href="https://facebook.com/radish.coop" target="_blank" title="" class="facebook">
                            <img src="../../assets/images/social-media-icons/facebook-logo-black.png" />
                        </a>
                    </div>
                    <div class="blog-twitter">
                        <a href="https://twitter.com/RadishCoop" target="_blank" title="" class="twitter">
                            <img src="../../assets/images/social-media-icons/twitter-logo.png" />
                        </a>
                    </div>
                    <div class="blog-linkedin">
                        <a href="https://www.linkedin.com/company/radishcoop" target="_blank" title="" class="linkedin">
                            <img src="../../assets/images/social-media-icons/linkedin-logo-black.png" />
                        </a>
                    </div>
                </div>
            </div>
            <p class="blog-content" [innerHtml]="blogPost.content"></p>
        </div>
    </div>
</div>
<app-footer [footerType]="footerType"></app-footer>
