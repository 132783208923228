import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthService } from '../core/services/auth.service';
import { first, takeWhile, finalize } from 'rxjs/operators';
import { UserService } from '../core/services/user.service';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { PhoneNumberComponent } from 'ngx-international-phone-number2';
import { MainService } from '../core/services/main.service';

@Component({
	selector: 'app-verification',
	templateUrl: './verification.component.html',
    styleUrls: ['./verification.component.css'],
})
export class VerificationComponent implements OnInit, AfterViewInit {
    sendCodeForm: UntypedFormGroup
    verifyCodeForm: UntypedFormGroup
    codeSent: boolean = false
    loading: boolean = false

    phoneError: string;
    codeError: string;

    @ViewChild(PhoneNumberComponent) phoneNumberComponent: PhoneNumberComponent

    constructor(
        private formBuilder: UntypedFormBuilder,
        private authService: AuthService,
        private mainService: MainService,
        private router: Router,
        public route: ActivatedRoute,
        public translate: TranslocoService) {
        this.sendCodeForm = formBuilder.group({
            phoneNumber: ['', Validators.required]
        })
        this.verifyCodeForm = formBuilder.group({
            code: ['', Validators.required]
        })
    }

    ngAfterViewInit(): void {
        if (this.route.snapshot.queryParams.number) this.phoneNumberComponent.writeValue(this.route.snapshot.queryParams.number)
    }

    ngOnInit() {
    }

    onSubmitSendCodeForm(data: any) {
        this.loading = true
        this.codeError = null
        this.phoneError = null
        this.authService.sendPhoneVerification((this.phoneNumberComponent.dialCode + this.phoneNumberComponent.phoneNumberOnly).replace(/[^0-9]/g, ''))
            .pipe(first())
            .pipe(finalize(() => this.loading = false))
            .subscribe(
                _ => this.codeSent = true,
                err => {
                    if (err?.error?.error === 'InvalidNumber') {
                        this.phoneError = this.translate.getActiveLang() == 'fr'
                            ? "Le numéro que vous avez saisi n'est pas valide"
                            : "The number you entered in invalid"
                    } else {
                        this.phoneError = this.translate.getActiveLang() == 'fr'
                            ? "Il y a eu un problème pour la vérification de votre numéro. Si ce problème ne se résout pas lors d'une nouvelle tentative, veuillez contacter l'assistance."
                            : "There was a problem verifying your number. If this doesn’t resolve when re-attempted, please contact support."
                    }
                }
            )
    }

    onSubmitVerifyCodeForm(data: any) {
        this.codeError = null
        this.phoneError = null
        this.loading = true
        this.authService.verifyPhone((this.phoneNumberComponent.dialCode + this.phoneNumberComponent.phoneNumberOnly).replace(/[^0-9]/g, ''), data.code)
            .pipe(first())
            .subscribe(
                _ => {
                    this.mainService.persistGuestData(true).subscribe(_ => this.redirectUser());
                },
                err => {
                    if (err?.error?.error === 'ExistingNumber') {
                        this.codeError = this.translate.getActiveLang() == 'fr' ? 'Le numéro que vous avez saisi est déjà associé à un compte.' : 'The phone number you entered is already in use'
                    }
                    if (err?.error?.error === 'WrongCode') {
                        this.codeError = this.translate.getActiveLang() == 'fr' ? 'Le code que vous avez saisi n\'est pas valide' : 'The code you entered is invalid'
                    }
                    this.loading = false
                }
            )
    }

	get phoneNumber() {
		return this.sendCodeForm.controls.phoneNumber
    }

    redirectUser() {
		let redirect = localStorage.getItem('redirect_url');
        localStorage.removeItem('redirect_url');
		if (redirect) this.router.navigate([redirect]);
		else this.router.navigate(['/' + this.translate.getActiveLang()]);
	}
}
