<div class="listing-container"> 
    <div>
        <app-gift-box-combo-listing-navbar [items]="lists?.items" (onItemClicked)="listSelected($event)"></app-gift-box-combo-listing-navbar>
        
        <div id="listing-header" class="listing-header">
            <div class="listing-header-text">
                <h1 class="listing-header-title">
                    {{ 'giftBox.listing.header.title' | transloco }}
                </h1>
                <!-- <h2 class="listing-header-subtitle">
                    {{ 'giftBox.listing.header.subtitle' | transloco }}
                </h2> -->
                <p class="listing-header-description">
                    {{ 'giftBox.listing.header.description' | transloco }}
                </p>
            </div>
        </div>
        
        <div id="listing-grid" class="listing-grid">
            <div class="listing-item-box" *ngFor="let product of products" (click)="openProductDetails(product)">
                <app-combo-listing-item [product]="product"></app-combo-listing-item>
            </div>
        </div>
        
        <app-gift-box-item-details [(showDetails)]="detailsOpen" [product]="selectedProduct"></app-gift-box-item-details>
   </div>
    
    <div>
        <app-footer [footerType]="'white'"></app-footer>
    </div>
</div>