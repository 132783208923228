import { Component, OnInit, Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Restaurant } from 'src/app/core/models';
import { ConfigService } from 'src/app/core/services/config.service';
import { RestaurantSlug } from 'src/app/core/enums';

@Component({
    selector: 'app-restaurant-about-instagram',
    templateUrl: './restaurant-about-instagram.component.html',
    styleUrls: ['./restaurant-about-instagram.component.css']
})
export class RestaurantAboutInstagramComponent implements OnInit {
    @Input() restaurant: Restaurant;
    maharaniMedia = [];
    enchanteurMedia = [];
    lylaMedia = [];
    dioloMedia = [];
    arahovaMedia = [];
    delicieuxMedia = [];
    shamMedia = [];
    nakamichiMedia = [];
    croqueteriaMedia = [];
    ptitPlateauMedia = [];
    marquisMedia = [];
    sushiPlusBeaumontMedia = [];
    kojiSoupeMedia = [];
    lundisAuSoleilMedia = [];
    tincSetMedia = [];
    fanfarePizzaMedia = [];
    bashaMedia = [];
    uluwatuMedia = [];
    saintHoublonMedia = [];
    boustanMedia = [];
    thaiExpressCdnMedia = [];
    javaUCdnMedia = [];
    mcgillPizzaMedia = [];
    yuanMedia = [];
    segretaMedia = [];
    omltMedia = [];
    quesadaCdnMedia = [];
    oiseauxDePassageMedia = [];
    burgerBrosMedia = [];
    juliansMedia = [];
    spiceBrosMedia = [];
    santaLuciaMedia = [];
    oFourMedia = [];
    karinMedia = [];
    marcheMaremaMedia = [];
    boiteVeganeMedia = [];
    heroBurgersMedia = [];
    cafeResonanceMedia = [];
    melroseMedia = [];
    mintarMedia = [];
    cacao70Media = [];
    laToileMedia = [];
    sepLaiMedia = [];
    chaiTeaLoungeMedia = [];
    laCroissanterieFigaroMedia = [];
    auxMerveilleuxDeFredMedia = [];
    tacosNationMedia = [];
    laFabriqueDeBagelMedia = [];
    studentTastyBiryani = [];
    lali = [];
    chezTaMere = [];
    friteAlors = [];
    tibum = [];
    thePerle = [];
    gongCha = [];
    buffaloBill = [];
    souvlakiGrecPlus = [];
    tsujiri = [];
    spartaChicken = [];
    p23Dumplings = [];
    burgzCantine = [];
    goplana = [];
    piriPiri = [];
    ukiyaIzakaya = [];
    livSalades = [];
    downtownDhaba = [];
    grillados = [];

    // RestoPage TODO: Add restaurantSlugMedia above

    page = 1;
    pageSize = 6;

    restaurantMedia;

    constructor(
        private http: HttpClient,
        public configService: ConfigService
    ) {
        this.maharaniMedia = [
            {
                image: '../../assets/images/buffet-maharani/instagram/buffet-maharani-IG-1.jpg',
                link: 'https://www.instagram.com/p/B-nD5x9HkTk/'
            },
            {
                image: '../../assets/images/buffet-maharani/instagram/buffet-maharani-IG-2.jpg',
                link: 'https://www.instagram.com/p/B-Awug3jBKR/'
            },
            {
                image: '../../assets/images/buffet-maharani/instagram/buffet-maharani-IG-3.jpg',
                link: 'https://www.instagram.com/p/B7C3ESADAWE/'
            },
            {
                image: '../../assets/images/buffet-maharani/instagram/buffet-maharani-IG-4.jpg',
                link: 'https://www.instagram.com/p/B6gxf75jS_5/'
            },
            {
                image: '../../assets/images/buffet-maharani/instagram/buffet-maharani-IG-5.jpg',
                link: 'https://www.instagram.com/p/B6W-U8CDSvs/'
            },
            {
                image: '../../assets/images/buffet-maharani/instagram/buffet-maharani-IG-6.jpg',
                link: 'https://www.instagram.com/p/B5G4trpjJvJ/'
            },
            {
                image: '../../assets/images/buffet-maharani/instagram/buffet-maharani-IG-7.jpg',
                link: 'https://www.instagram.com/p/B4az2aiDFwN/'
            },
            {
                image: '../../assets/images/buffet-maharani/instagram/buffet-maharani-IG-8.jpg',
                link: 'https://www.instagram.com/p/B3LK5hkjSUV/'
            },
            {
                image: '../../assets/images/buffet-maharani/instagram/buffet-maharani-IG-9.jpg',
                link: 'https://www.instagram.com/p/BzhBnadjCeQ/'
            },
            {
                image: '../../assets/images/buffet-maharani/instagram/buffet-maharani-IG-10.jpg',
                link: 'https://www.instagram.com/p/BxTFvR6HrJH/'
            },
            {
                image: '../../assets/images/buffet-maharani/instagram/buffet-maharani-IG-11.jpg',
                link: 'https://www.instagram.com/p/BwVvtJXHmDo/'
            },
            {
                image: '../../assets/images/buffet-maharani/instagram/buffet-maharani-IG-12.jpg',
                link: 'https://www.instagram.com/p/Bv6uGRhhq62/'
            }
        ];
        this.enchanteurMedia = [
            {
                image: '../../assets/images/enchanteur/instagram/enchanteur-IG-7.png',
                link: 'https://www.instagram.com/p/ByEX-I7nEOj/'
            },
            {
                image: '../../assets/images/enchanteur/instagram/enchanteur-IG-8.png',
                link: 'https://www.instagram.com/p/Brnjp6mAiN0/'
            },
            {
                image: '../../assets/images/enchanteur/instagram/enchanteur-IG-9.png',
                link: 'https://www.instagram.com/p/BpHdv4aAEZB/'
            },
            {
                image: '../../assets/images/enchanteur/instagram/enchanteur-IG-10.png',
                link: 'https://www.instagram.com/p/BpE4801Dq2A/'
            },
            {
                image: '../../assets/images/enchanteur/instagram/enchanteur-IG-11.png',
                link: 'https://www.instagram.com/p/BpCUKGDjugE/'
            },
            {
                image: '../../assets/images/enchanteur/instagram/enchanteur-IG-5.png',
                link: 'https://www.instagram.com/p/B1PgafRn7HE/'
            },
            {
                image: '../../assets/images/enchanteur/instagram/enchanteur-IG-12.png',
                link: 'https://www.instagram.com/p/BlLk3gTjeWQ/'
            },
            {
                image: '../../assets/images/enchanteur/instagram/enchanteur-IG-2.png',
                link: 'https://www.instagram.com/p/B2SXKvhHoCl/'
            },
            {
                image: '../../assets/images/enchanteur/instagram/enchanteur-IG-4.png',
                link: 'https://www.instagram.com/p/B1uUC9anhg6/'
            },
            {
                image: '../../assets/images/enchanteur/instagram/enchanteur-IG-13.png',
                link: 'https://www.instagram.com/p/BiHcJl1DSh1/'
            },
            {
                image: '../../assets/images/enchanteur/instagram/enchanteur-IG-14.png',
                link: 'https://www.instagram.com/p/BiCijr_g9c9/'
            },
            {
                image: '../../assets/images/enchanteur/instagram/enchanteur-IG-6.png',
                link: 'https://www.instagram.com/p/BzeHgf7AGSl/'
            },
            {
                image: '../../assets/images/enchanteur/instagram/enchanteur-IG-3.png',
                link: 'https://www.instagram.com/p/B2AVlzygSKa/'
            },
            {
                image: '../../assets/images/enchanteur/instagram/enchanteur-IG-15.png',
                link: 'https://www.instagram.com/p/Bh4OFsGALcZ/'
            },
            {
                image: '../../assets/images/enchanteur/instagram/enchanteur-IG-16.png',
                link: 'https://www.instagram.com/p/Bhjf4_WAYhx/'
            },
            {
                image: '../../assets/images/enchanteur/instagram/enchanteur-IG-17.png',
                link: 'https://www.instagram.com/p/BgE3Hs9HScG/'
            },
            {
                image: '../../assets/images/enchanteur/instagram/enchanteur-IG-25.png',
                link: 'https://www.instagram.com/p/BkDqE1YDy_1/'
            },
            {
                image: '../../assets/images/enchanteur/instagram/enchanteur-IG-18.png',
                link: 'https://www.instagram.com/p/BfQ5cvYHSDX/'
            },
            {
                image: '../../assets/images/enchanteur/instagram/enchanteur-IG-19.png',
                link: 'https://www.instagram.com/p/BdiYWzSjMXA/'
            },
            {
                image: '../../assets/images/enchanteur/instagram/enchanteur-IG-20.png',
                link: 'https://www.instagram.com/p/Bc5axMYjAoo/'
            },
            {
                image: '../../assets/images/enchanteur/instagram/enchanteur-IG-21.png',
                link: 'https://www.instagram.com/p/BZmUbzijZ9q/'
            },
            {
                image: '../../assets/images/enchanteur/instagram/enchanteur-IG-22.png',
                link: 'https://www.instagram.com/p/BRq7XZBDCo2/'
            },
            {
                image: '../../assets/images/enchanteur/instagram/enchanteur-IG-23.png',
                link: 'https://www.instagram.com/p/6DkBQuBdk-/'
            },
            {
                image: '../../assets/images/enchanteur/instagram/enchanteur-IG-24.png',
                link: 'https://www.instagram.com/p/5xrWsCBdne/'
            }
        ];
        this.lylaMedia = [
            {
                image: '../../assets/images/lyla/instagram/lyla-IG-1.jpg',
                link: 'https://www.instagram.com/p/CBZVkLbJkWf/'
            },
            {
                image: '../../assets/images/lyla/instagram/lyla-IG-2.jpg',
                link: 'https://www.instagram.com/p/B_oSEU3FLdM/'
            }
        ];
        this.dioloMedia = [
            {
                image: '../../assets/images/diolo/instagram/diolo-IG-1.jpg',
                link: 'https://www.instagram.com/p/CAIyLKCnv17'
            },
            {
                image: '../../assets/images/diolo/instagram/diolo-IG-2.jpg',
                link: 'https://www.instagram.com/p/CAIht2inPaf/'
            },
            {
                image: '../../assets/images/diolo/instagram/diolo-IG-3.jpg',
                link: 'https://www.instagram.com/p/CAIeE3WHTt-/'
            },
            {
                image: '../../assets/images/diolo/instagram/diolo-IG-5.jpg',
                link: 'https://www.instagram.com/p/B9zo7PClM0Y/'
            },
            {
                image: '../../assets/images/diolo/instagram/diolo-IG-7.jpg',
                link: 'https://www.instagram.com/p/B8euIQhJaEz/'
            },
            {
                image: '../../assets/images/diolo/instagram/diolo-IG-8.jpg',
                link: 'https://www.instagram.com/p/B76o1Jfg3eq/'
            },
            {
                image: '../../assets/images/diolo/instagram/diolo-IG-9.jpg',
                link: 'https://www.instagram.com/p/B7eOz-vn9zA/'
            },
            {
                image: '../../assets/images/diolo/instagram/diolo-IG-10.jpg',
                link: 'https://www.instagram.com/p/B7ZZ0eVD9g7/'
            },
            {
                image: '../../assets/images/diolo/instagram/diolo-IG-11.jpg',
                link: 'https://www.instagram.com/p/B66Xu_IFqn_/'
            },
            {
                image: '../../assets/images/diolo/instagram/diolo-IG-12.jpg',
                link: 'https://www.instagram.com/p/B63jcnYFtE2/'
            },
            {
                image: '../../assets/images/diolo/instagram/diolo-IG-13.jpg',
                link: 'https://www.instagram.com/p/B6JW4VIg29V/'
            },
            {
                image: '../../assets/images/diolo/instagram/diolo-IG-15.jpg',
                link: 'https://www.instagram.com/p/B5vrfoOANX-/'
            },
            {
                image: '../../assets/images/diolo/instagram/diolo-IG-16.jpg',
                link: 'https://www.instagram.com/p/B4BDCCFlIw0/'
            },
            {
                image: '../../assets/images/diolo/instagram/diolo-IG-18.jpg',
                link: 'https://www.instagram.com/p/B1wd6kdAxbA/'
            },
            {
                image: '../../assets/images/diolo/instagram/diolo-IG-19.jpg',
                link: 'https://www.instagram.com/p/B1uKIxMBGPD/'
            },
            {
                image: '../../assets/images/diolo/instagram/diolo-IG-20.jpg',
                link: 'https://www.instagram.com/p/B1o9Rt9FjCS/'
            },
            {
                image: '../../assets/images/diolo/instagram/diolo-IG-21.jpg',
                link: 'https://www.instagram.com/p/B1ZGwo1AkUS/'
            },
            {
                image: '../../assets/images/diolo/instagram/diolo-IG-22.jpg',
                link: 'https://www.instagram.com/p/B1J1ifUlJR0/'
            },
            {
                image: '../../assets/images/diolo/instagram/diolo-IG-23.jpg',
                link: 'https://www.instagram.com/p/B06UqMmHYFe/'
            },
            {
                image: '../../assets/images/diolo/instagram/diolo-IG-25.jpg',
                link: 'https://www.instagram.com/p/B0mFLOZn0Cj/'
            },
            {
                image: '../../assets/images/diolo/instagram/diolo-IG-26.jpg',
                link: 'https://www.instagram.com/p/B0ZBRI9gVuP/'
            },
            {
                image: '../../assets/images/diolo/instagram/diolo-IG-27.jpg',
                link: 'https://www.instagram.com/p/By8dKxBlaoV/'
            },
            {
                image: '../../assets/images/diolo/instagram/diolo-IG-28.jpg',
                link: 'https://www.instagram.com/p/BydZ4WZHUkM/'
            },
            {
                image: '../../assets/images/diolo/instagram/diolo-IG-29.jpg',
                link: 'https://www.instagram.com/p/ByDRUvUAx16/'
            }
        ];
        this.arahovaMedia = [
            {
                image: '../../assets/images/arahova/instagram/arahova-IG-1.jpg',
                link: 'https://www.instagram.com/p/B4AVDtwnoVI/'
            },
            {
                image: '../../assets/images/arahova/instagram/arahova-IG-2.jpg',
                link: 'https://www.instagram.com/p/B35UprOHNGF/'
            },
            {
                image: '../../assets/images/arahova/instagram/arahova-IG-3.jpg',
                link: 'https://www.instagram.com/p/B3xQkVjHCoE/'
            },
            {
                image: '../../assets/images/arahova/instagram/arahova-IG-4.jpg',
                link: 'https://www.instagram.com/p/B3sJ2q9nc4y/'
            },
            {
                image: '../../assets/images/arahova/instagram/arahova-IG-5.jpg',
                link: 'https://www.instagram.com/p/B3m0SRanv69/'
            },
            {
                image: '../../assets/images/arahova/instagram/arahova-IG-6.jpg',
                link: 'https://www.instagram.com/p/B3fFyCtneEN/'
            },
            {
                image: '../../assets/images/arahova/instagram/arahova-IG-7.jpg',
                link: 'https://www.instagram.com/p/B3XmyVenozj/'
            },
            {
                image: '../../assets/images/arahova/instagram/arahova-IG-8.jpg',
                link: 'https://www.instagram.com/p/B3U1Eh-H0PS/'
            },
            {
                image: '../../assets/images/arahova/instagram/arahova-IG-9.jpg',
                link: 'https://www.instagram.com/p/B3SChYznchr/'
            },
            {
                image: '../../assets/images/arahova/instagram/arahova-IG-10.jpg',
                link: 'https://www.instagram.com/p/B3HkVQ9FSiq/'
            },
            {
                image: '../../assets/images/arahova/instagram/arahova-IG-11.jpg',
                link: 'https://www.instagram.com/p/B3C3bKIFrzZ/'
            },
            {
                image: '../../assets/images/arahova/instagram/arahova-IG-12.jpg',
                link: 'https://www.instagram.com/p/B24Umf1lH7h/'
            },
            {
                image: '../../assets/images/arahova/instagram/arahova-IG-13.jpg',
                link: 'https://www.instagram.com/p/B2wcGzXlkmx/'
            },
            {
                image: '../../assets/images/arahova/instagram/arahova-IG-14.jpg',
                link: 'https://www.instagram.com/p/B2rly62FNk2/'
            },
            {
                image: '../../assets/images/arahova/instagram/arahova-IG-15.jpg',
                link: 'https://www.instagram.com/p/B2hu3ESFpwW/'
            },
            {
                image: '../../assets/images/arahova/instagram/arahova-IG-16.jpg',
                link: 'https://www.instagram.com/p/B2eW3raFIT4/'
            },
            {
                image: '../../assets/images/arahova/instagram/arahova-IG-17.jpg',
                link: 'https://www.instagram.com/p/B2Xa7hrlGlW/'
            },
            {
                image: '../../assets/images/arahova/instagram/arahova-IG-18.jpg',
                link: 'https://www.instagram.com/p/B2UuZBIFgkB/'
            },
            {
                image: '../../assets/images/arahova/instagram/arahova-IG-19.jpg',
                link: 'https://www.instagram.com/p/B2PeiJYlfq4/'
            },
            {
                image: '../../assets/images/arahova/instagram/arahova-IG-20.jpg',
                link: 'https://www.instagram.com/p/B2NAxa-Fzjh/'
            }
        ];
        this.delicieuxMedia = [
            {
                image: '../../assets/images/delicieux/instagram/delicieux-IG-1.jpg',
                link: 'https://www.instagram.com/p/BTFaXzgDdMh/'
            },
            {
                image: '../../assets/images/delicieux/instagram/delicieux-IG-2.jpg',
                link: 'https://www.instagram.com/p/BTFVALiD4Y9'
            },
            {
                image: '../../assets/images/delicieux/instagram/delicieux-IG-3.jpg',
                link: 'https://www.instagram.com/p/BRrULYjj2M5/'
            }
        ];
        this.shamMedia = [
            {
                image: '../../assets/images/sham/instagram/sham-IG-1.jpg',
                link: 'https://www.instagram.com/p/B5WD386nXHZ/'
            },
            {
                image: '../../assets/images/sham/instagram/sham-IG-2.jpg',
                link: 'https://www.instagram.com/p/B5L_7Osne5T/'
            },
            {
                image: '../../assets/images/sham/instagram/sham-IG-3.jpg',
                link: 'https://www.instagram.com/p/B5Eb-68nsQZ/'
            },
            {
                image: '../../assets/images/sham/instagram/sham-IG-9.jpg',
                link: 'https://www.instagram.com/p/BjPc7uLgoYi/'
            },
            {
                image: '../../assets/images/sham/instagram/sham-IG-5.jpg',
                link: 'https://www.instagram.com/p/B3QJEytHVGz/'
            },
            {
                image: '../../assets/images/sham/instagram/sham-IG-6.jpg',
                link: 'https://www.instagram.com/p/BjvMwqkAB2K/'
            },
            {
                image: '../../assets/images/sham/instagram/sham-IG-7.jpg',
                link: 'https://www.instagram.com/p/Bjanma5jQox/'
            },
            {
                image: '../../assets/images/sham/instagram/sham-IG-4.jpg',
                link: 'https://www.instagram.com/p/Bi5EjRGDUD3/'
            },
            {
                image: '../../assets/images/sham/instagram/sham-IG-8.jpg',
                link: 'https://www.instagram.com/p/Bjam5hLjI75/'
            },
            {
                image: '../../assets/images/sham/instagram/sham-IG-10.jpg',
                link: 'https://www.instagram.com/p/BjAWlMljETa/'
            },
            {
                image: '../../assets/images/sham/instagram/sham-IG-11.jpg',
                link: 'https://www.instagram.com/p/Bi5E-mfjwha/'
            },
            {
                image: '../../assets/images/sham/instagram/sham-IG-12.jpg',
                link: 'https://www.instagram.com/p/Bi5E6eVDk_z/'
            }
        ];
        this.nakamichiMedia = [
            {
                image: '../../assets/images/nakamichi/instagram/nakamichi-IG-1.jpg',
                link: 'https://www.instagram.com/p/CFnQC0ynnEq/'
            },
            {
                image: '../../assets/images/nakamichi/instagram/nakamichi-IG-3.jpg',
                link: 'https://www.instagram.com/p/B9PhC-fnzV0/'
            },
            {
                image: '../../assets/images/nakamichi/instagram/nakamichi-IG-4.jpg',
                link: 'https://www.instagram.com/p/B9PceuKnzLj/'
            },
            {
                image: '../../assets/images/nakamichi/instagram/nakamichi-IG-5.jpg',
                link: 'https://www.instagram.com/p/B89rC8Vn66x/'
            },
            {
                image: '../../assets/images/nakamichi/instagram/nakamichi-IG-6.jpg',
                link: 'https://www.instagram.com/p/BxKwDHRgB24/'
            },
            {
                image: '../../assets/images/nakamichi/instagram/nakamichi-IG-7.jpg',
                link: 'https://www.instagram.com/p/BuZ2UCnAXIy/'
            },
            {
                image: '../../assets/images/nakamichi/instagram/nakamichi-IG-8.jpg',
                link: 'https://www.instagram.com/p/Bq3KNW4FX1G/'
            },
            {
                image: '../../assets/images/nakamichi/instagram/nakamichi-IG-9.jpg',
                link: 'https://www.instagram.com/p/Bo4TlAvnM_M/'
            },
            {
                image: '../../assets/images/nakamichi/instagram/nakamichi-IG-10.jpg',
                link: 'https://www.instagram.com/p/BokWLayH7PI/'
            },
            {
                image: '../../assets/images/nakamichi/instagram/nakamichi-IG-12.jpg',
                link: 'https://www.instagram.com/p/BoR8j7rHX0r/'
            },
            {
                image: '../../assets/images/nakamichi/instagram/nakamichi-IG-11.jpg',
                link: 'https://www.instagram.com/p/BoU6zRCnZBO/'
            },
            {
                image: '../../assets/images/nakamichi/instagram/nakamichi-IG-13.jpg',
                link: 'https://www.instagram.com/p/Bm1JBQOHl5z/'
            },
            {
                image: '../../assets/images/nakamichi/instagram/nakamichi-IG-17.jpg',
                link: 'https://www.instagram.com/p/Baz2RNVgjaJ/'
            },
            {
                image: '../../assets/images/nakamichi/instagram/nakamichi-IG-15.jpg',
                link: 'https://www.instagram.com/p/BlGcOYSHbh_/'
            },
            {
                image: '../../assets/images/nakamichi/instagram/nakamichi-IG-18.jpg',
                link: 'https://www.instagram.com/p/BamdSj0gPBM/'
            },
            {
                image: '../../assets/images/nakamichi/instagram/nakamichi-IG-14.jpg',
                link: 'https://www.instagram.com/p/BlT9RaSHdnL/'
            },
            {
                image: '../../assets/images/nakamichi/instagram/nakamichi-IG-19.jpg',
                link: 'https://www.instagram.com/p/BafTkcNgBan/'
            },
            {
                image: '../../assets/images/nakamichi/instagram/nakamichi-IG-16.jpg',
                link: 'https://www.instagram.com/p/Bk0Ui3IHWeF/'
            },
            {
                image: '../../assets/images/nakamichi/instagram/nakamichi-IG-20.jpg',
                link: 'https://www.instagram.com/p/BaUUbrKAib9/'
            },
            {
                image: '../../assets/images/nakamichi/instagram/nakamichi-IG-24.jpg',
                link: 'https://www.instagram.com/p/BXyA0UXg4nD/'
            },
            {
                image: '../../assets/images/nakamichi/instagram/nakamichi-IG-22.jpg',
                link: 'https://www.instagram.com/p/BaH-9QQHQVr/'
            },
            {
                image: '../../assets/images/nakamichi/instagram/nakamichi-IG-23.jpg',
                link: 'https://www.instagram.com/p/BX6ht6cA5F1/'
            },
            {
                image: '../../assets/images/nakamichi/instagram/nakamichi-IG-21.jpg',
                link: 'https://www.instagram.com/p/BaPrHgwAE3S/'
            },
            {
                image: '../../assets/images/nakamichi/instagram/nakamichi-IG-25.jpg',
                link: 'https://www.instagram.com/p/BXmUhlsgOyi/'
            },
            {
                image: '../../assets/images/nakamichi/instagram/nakamichi-IG-26.jpg',
                link: 'https://www.instagram.com/p/BXPyjHCAUqJ/'
            },
            {
                image: '../../assets/images/nakamichi/instagram/nakamichi-IG-27.jpg',
                link: 'https://www.instagram.com/p/BV4lsbKgcHd/'
            },
            {
                image: '../../assets/images/nakamichi/instagram/nakamichi-IG-28.jpg',
                link: 'https://www.instagram.com/p/BV2ANUxgqNm/'
            },
            {
                image: '../../assets/images/nakamichi/instagram/nakamichi-IG-29.jpg',
                link: 'https://www.instagram.com/p/BV0H5cDA4Ng/'
            },
            {
                image: '../../assets/images/nakamichi/instagram/nakamichi-IG-30.jpg',
                link: 'https://www.instagram.com/p/BVzwUxdAHKf/'
            },
            {
                image: '../../assets/images/nakamichi/instagram/nakamichi-IG-31.jpg',
                link: 'https://www.instagram.com/p/BVsSDs7gq5H/'
            }
        ];
        this.croqueteriaMedia = [{
            'image': '../../assets/images/croqueteria/instagram/croqueteria-IG-1.jpg',
            'link': 'https://www.instagram.com/p/CLM9KvpnB1A/'
        },
        {
            'image': '../../assets/images/croqueteria/instagram/croqueteria-IG-2.jpg',
            'link': 'https://www.instagram.com/p/CLH31PZHGIN/'
        },
        {
            'image': '../../assets/images/croqueteria/instagram/croqueteria-IG-3.jpg',
            'link': 'https://www.instagram.com/p/B-SB4sGnrM8/'
        },
        {
            'image': '../../assets/images/croqueteria/instagram/croqueteria-IG-4.jpg',
            'link': 'https://www.instagram.com/p/CEcqfpoH2kA/'
        },
        {
            'image': '../../assets/images/croqueteria/instagram/croqueteria-IG-5.jpg',
            'link': 'https://www.instagram.com/p/CEXdocQnZnD/'
        },
        {
            'image': '../../assets/images/croqueteria/instagram/croqueteria-IG-6.jpg',
            'link': 'https://www.instagram.com/p/B6CGl2mH0U2/'
        },
        {
            'image': '../../assets/images/croqueteria/instagram/croqueteria-IG-7.jpg',
            'link': 'https://www.instagram.com/p/CDrUBKsnnm3/'
        },
        {
            'image': '../../assets/images/croqueteria/instagram/croqueteria-IG-8.jpg',
            'link': 'https://www.instagram.com/p/CDW2h46HPzg/'
        },
        {
            'image': '../../assets/images/croqueteria/instagram/croqueteria-IG-9.jpg',
            'link': 'https://www.instagram.com/p/CBvv8KZnUu2/'
        },
        {
            'image': '../../assets/images/croqueteria/instagram/croqueteria-IG-10.jpg',
            'link': 'https://www.instagram.com/p/CBGVJoMHKFI/'
        },
        {
            'image': '../../assets/images/croqueteria/instagram/croqueteria-IG-11.jpg',
            'link': 'https://www.instagram.com/p/B__QObznD_U/'
        },
        {
            'image': '../../assets/images/croqueteria/instagram/croqueteria-IG-12.jpg',
            'link': 'https://www.instagram.com/p/B-w--pnnyeX/'
        },
        {
            'image': '../../assets/images/croqueteria/instagram/croqueteria-IG-13.jpg',
            'link': 'https://www.instagram.com/p/B-fgRhBnGYR/'
        },
        {
            'image': '../../assets/images/croqueteria/instagram/croqueteria-IG-14.jpg',
            'link': 'https://www.instagram.com/p/CHimTnOHyBV/'
        },
        {
            'image': '../../assets/images/croqueteria/instagram/croqueteria-IG-15.jpg',
            'link': 'https://www.instagram.com/p/CDxEJ_THwjb/'
        },
        {
            'image': '../../assets/images/croqueteria/instagram/croqueteria-IG-16.jpg',
            'link': 'https://www.instagram.com/p/B-Iq7mqHQJj/'
        },
        {
            'image': '../../assets/images/croqueteria/instagram/croqueteria-IG-17.jpg',
            'link': 'https://www.instagram.com/p/B-Z43DCHfiC/'
        },
        {
            'image': '../../assets/images/croqueteria/instagram/croqueteria-IG-18.jpg',
            'link': 'https://www.instagram.com/p/CA-3hq0nGym/'
        },
        {
            'image': '../../assets/images/croqueteria/instagram/croqueteria-IG-19.jpg',
            'link': 'https://www.instagram.com/p/CC1nQenHkB6/'
        },
        {
            'image': '../../assets/images/croqueteria/instagram/croqueteria-IG-20.jpg',
            'link': 'https://www.instagram.com/p/CDef2KMnQQt/'
        },
        {
            'image': '../../assets/images/croqueteria/instagram/croqueteria-IG-21.jpg',
            'link': 'https://www.instagram.com/p/CIEurWEniF0/'
        },
        {
            'image': '../../assets/images/croqueteria/instagram/croqueteria-IG-22.jpg',
            'link': 'https://www.instagram.com/p/CMC-CtCnnkQ/'
        },
        {
            'image': '../../assets/images/croqueteria/instagram/croqueteria-IG-23.jpg',
            'link': 'https://www.instagram.com/p/CG7mxpGn0Py/'
        },
        {
            'image': '../../assets/images/croqueteria/instagram/croqueteria-IG-24.jpg',
            'link': 'https://www.instagram.com/p/CFpzQsyn0pE/'
        },
        {
            'image': '../../assets/images/croqueteria/instagram/croqueteria-IG-25.jpg',
            'link': 'https://www.instagram.com/p/CFpzQsyn0pE/'
        },
        {
            'image': '../../assets/images/croqueteria/instagram/croqueteria-IG-26.jpg',
            'link': 'https://www.instagram.com/p/CFpzQsyn0pE/'
        },
        {
            'image': '../../assets/images/croqueteria/instagram/croqueteria-IG-27.jpg',
            'link': 'https://www.instagram.com/p/CFpzQsyn0pE/'
        },
        {
            'image': '../../assets/images/croqueteria/instagram/croqueteria-IG-28.jpg',
            'link': 'https://www.instagram.com/p/CFpzQsyn0pE/'
        },
        {
            'image': '../../assets/images/croqueteria/instagram/croqueteria-IG-29.jpg',
            'link': 'https://www.instagram.com/p/CFpzQsyn0pE/'
        },
        {
            'image': '../../assets/images/croqueteria/instagram/croqueteria-IG-30.jpg',
            'link': 'https://www.instagram.com/p/CFpzQsyn0pE/'
        }]


        this.ptitPlateauMedia = [
            {
                image: '../../assets/images/ptit-plateau/instagram/ptit-plateau-IG-1.jpg',
                link: 'https://www.instagram.com/p/x9XRB1ycLF/'
            },
            {
                image: '../../assets/images/ptit-plateau/instagram/ptit-plateau-IG-2.jpg',
                link: 'https://www.instagram.com/p/wFJsx7ycM6/'
            },
            {
                image: '../../assets/images/ptit-plateau/instagram/ptit-plateau-IG-3.jpg',
                link: 'https://www.instagram.com/p/vY607oycLG/'
            },
            {
                image: '../../assets/images/ptit-plateau/instagram/ptit-plateau-IG-4.jpg',
                link: 'https://www.instagram.com/p/vW4tWjycOl/'
            },
            {
                image: '../../assets/images/ptit-plateau/instagram/ptit-plateau-IG-5.jpg',
                link: 'https://www.instagram.com/p/vW4ZNHycMT/'
            },
            {
                image: '../../assets/images/ptit-plateau/instagram/ptit-plateau-IG-6.jpg',
                link: 'https://www.instagram.com/p/vR_ApUScGa/'
            },
            {
                image: '../../assets/images/ptit-plateau/instagram/ptit-plateau-IG-7.jpg',
                link: 'https://www.instagram.com/p/ui4txDycMl/'
            },
            {
                image: '../../assets/images/ptit-plateau/instagram/ptit-plateau-IG-8.jpg',
                link: 'https://www.instagram.com/p/ui4Xx4ycL7/'
            },
            {
                image: '../../assets/images/ptit-plateau/instagram/ptit-plateau-IG-9.jpg',
                link: 'https://www.instagram.com/p/ui4Al6ScLH/'
            },
            {
                image: '../../assets/images/ptit-plateau/instagram/ptit-plateau-IG-10.jpg',
                link: 'https://www.instagram.com/p/ui30rbScKu/'
            },
            {
                image: '../../assets/images/ptit-plateau/instagram/ptit-plateau-IG-11.jpg',
                link: 'https://www.instagram.com/p/ui3kD8ScKG/'
            },
            {
                image: '../../assets/images/ptit-plateau/instagram/ptit-plateau-IG-12.jpg',
                link: 'https://www.instagram.com/p/ui3cZuycJ7/'
            },
            {
                image: '../../assets/images/ptit-plateau/instagram/ptit-plateau-IG-13.jpg',
                link: 'https://www.instagram.com/p/tL2PJuScA8/'
            },
            {
                image: '../../assets/images/ptit-plateau/instagram/ptit-plateau-IG-14.jpg',
                link: 'https://www.instagram.com/p/tL2AZPScAd/'
            }
        ];
        /* this.sushiPlusBeaumontMedia = [
            {
                image: '../../assets/images/sushi-plus-beaumont/instagram/sushi-plus-beaumont-IG-13.jpg',
                link: 'https://www.instagram.com/p/BqagW2ZAOwj/'
            },
            {
                image: '../../assets/images/sushi-plus-beaumont/instagram/sushi-plus-beaumont-IG-2.jpg',
                link: 'https://www.instagram.com/p/Br-pZTrgCMb/'
            },
            {
                image: '../../assets/images/sushi-plus-beaumont/instagram/sushi-plus-beaumont-IG-3.jpg',
                link: 'https://www.instagram.com/p/Br5dJd0ghYP/'
            },
            {
                image: '../../assets/images/sushi-plus-beaumont/instagram/sushi-plus-beaumont-IG-25.jpg',
                link: 'https://www.instagram.com/p/Bog9tULAIce/'
            },
            {
                image: '../../assets/images/sushi-plus-beaumont/instagram/sushi-plus-beaumont-IG-27.jpg',
                link: 'https://www.instagram.com/p/BoICNrNgqDC/'
            },
            {
                image: '../../assets/images/sushi-plus-beaumont/instagram/sushi-plus-beaumont-IG-6.jpg',
                link: 'https://www.instagram.com/p/Brf0Zp3AETK/'
            },
            {
                image: '../../assets/images/sushi-plus-beaumont/instagram/sushi-plus-beaumont-IG-7.jpg',
                link: 'https://www.instagram.com/p/BravTmsAwBk/'
            },
            {
                image: '../../assets/images/sushi-plus-beaumont/instagram/sushi-plus-beaumont-IG-8.jpg',
                link: 'https://www.instagram.com/p/BrLLawNAuNw/'
            },
            {
                image: '../../assets/images/sushi-plus-beaumont/instagram/sushi-plus-beaumont-IG-9.jpg',
                link: 'https://www.instagram.com/p/BrGTdodgVBF/'
            },
            {
                image: '../../assets/images/sushi-plus-beaumont/instagram/sushi-plus-beaumont-IG-10.jpg',
                link: 'https://www.instagram.com/p/Bq-rk-dA_CC/'
            },
            {
                image: '../../assets/images/sushi-plus-beaumont/instagram/sushi-plus-beaumont-IG-31.jpg',
                link: 'https://www.instagram.com/p/BlQLUr5gJi6/'
            },
            {
                image: '../../assets/images/sushi-plus-beaumont/instagram/sushi-plus-beaumont-IG-23.jpg',
                link: 'https://www.instagram.com/p/Bo1o2kEApdQ/'
            },
            {
                image: '../../assets/images/sushi-plus-beaumont/instagram/sushi-plus-beaumont-IG-1.jpg',
                link: 'https://www.instagram.com/p/BsEU-G-Agsw/'
            },
            {
                image: '../../assets/images/sushi-plus-beaumont/instagram/sushi-plus-beaumont-IG-14.jpg',
                link: 'https://www.instagram.com/p/BqQUCB6gDzA/'
            },
            {
                image: '../../assets/images/sushi-plus-beaumont/instagram/sushi-plus-beaumont-IG-15.jpg',
                link: 'https://www.instagram.com/p/BqGMrkAA3_8/'
            },
            {
                image: '../../assets/images/sushi-plus-beaumont/instagram/sushi-plus-beaumont-IG-16.jpg',
                link: 'https://www.instagram.com/p/Bp7RTfcgOF-/'
            },
            {
                image: '../../assets/images/sushi-plus-beaumont/instagram/sushi-plus-beaumont-IG-17.jpg',
                link: 'https://www.instagram.com/p/Bp2PRbSAsS8/'
            },
            {
                image: '../../assets/images/sushi-plus-beaumont/instagram/sushi-plus-beaumont-IG-18.jpg',
                link: 'https://www.instagram.com/p/BphtGaZg7Zq/'
            },
            {
                image: '../../assets/images/sushi-plus-beaumont/instagram/sushi-plus-beaumont-IG-4.jpg',
                link: 'https://www.instagram.com/p/Br0hT1eAhR_/'
            },
            {
                image: '../../assets/images/sushi-plus-beaumont/instagram/sushi-plus-beaumont-IG-20.jpg',
                link: 'https://www.instagram.com/p/BpQi9BcA4T9/'
            },
            {
                image: '../../assets/images/sushi-plus-beaumont/instagram/sushi-plus-beaumont-IG-21.jpg',
                link: 'https://www.instagram.com/p/BpFDEk1gzE7/'
            },
            {
                image: '../../assets/images/sushi-plus-beaumont/instagram/sushi-plus-beaumont-IG-22.jpg',
                link: 'https://www.instagram.com/p/Bo_1Sp9gV08/'
            },
            {
                image: '../../assets/images/sushi-plus-beaumont/instagram/sushi-plus-beaumont-IG-11.jpg',
                link: 'https://www.instagram.com/p/Bqu8ic0gpTk/'
            },
            {
                image: '../../assets/images/sushi-plus-beaumont/instagram/sushi-plus-beaumont-IG-24.jpg',
                link: 'https://www.instagram.com/p/Bol_PzmAsUE/'
            },
            {
                image: '../../assets/images/sushi-plus-beaumont/instagram/sushi-plus-beaumont-IG-5.jpg',
                link: 'https://www.instagram.com/p/Brqkj3HAKj0/'
            },
            {
                image: '../../assets/images/sushi-plus-beaumont/instagram/sushi-plus-beaumont-IG-26.jpg',
                link: 'https://www.instagram.com/p/BoUSM03gPt7/'
            },
            {
                image: '../../assets/images/sushi-plus-beaumont/instagram/sushi-plus-beaumont-IG-19.jpg',
                link: 'https://www.instagram.com/p/BpXB6tQgJrk/'
            },
            {
                image: '../../assets/images/sushi-plus-beaumont/instagram/sushi-plus-beaumont-IG-12.jpg',
                link: 'https://www.instagram.com/p/Bqhl2kSgIED/'
            },
            {
                image: '../../assets/images/sushi-plus-beaumont/instagram/sushi-plus-beaumont-IG-29.jpg',
                link: 'https://www.instagram.com/p/BmQ0ktJA6Ic/'
            },
            {
                image: '../../assets/images/sushi-plus-beaumont/instagram/sushi-plus-beaumont-IG-30.jpg',
                link: 'https://www.instagram.com/p/BlqrxEpAQJ3/'
            }
        ]; */
        this.kojiSoupeMedia = [
            {
                image: '../../assets/images/koji-soupe/instagram/koji-soupe-IG-26.jpg',
                link: 'https://www.instagram.com/p/CIqV9WtpwRN/'
            },
            {
                image: '../../assets/images/koji-soupe/instagram/koji-soupe-IG-25.jpg',
                link: 'https://www.instagram.com/p/CIn8QgyJImx/'
            },
            {
                image: '../../assets/images/koji-soupe/instagram/koji-soupe-IG-24.jpg',
                link: 'https://www.instagram.com/p/CIn5vCOp7C1/'
            },
            {
                image: '../../assets/images/koji-soupe/instagram/koji-soupe-IG-23.jpg',
                link: 'https://www.instagram.com/p/CIn1IccpNkB/'
            },
            {
                image: '../../assets/images/koji-soupe/instagram/koji-soupe-IG-22.jpg',
                link: 'https://www.instagram.com/p/CIln4k_Jc3s/'
            },
            {
                image: '../../assets/images/koji-soupe/instagram/koji-soupe-IG-21.jpg',
                link: 'https://www.instagram.com/p/CIlWGibJNrD/'
            },
            {
                image: '../../assets/images/koji-soupe/instagram/koji-soupe-IG-20.jpg',
                link: 'https://www.instagram.com/p/CIhOFmgp-lY/'
            },
            {
                image: '../../assets/images/koji-soupe/instagram/koji-soupe-IG-1.jpg',
                link: 'https://www.instagram.com/p/CIT6fzipOZU/'
            },
            {
                image: '../../assets/images/koji-soupe/instagram/koji-soupe-IG-2.jpg',
                link: 'https://www.instagram.com/p/CIGgdLcJagf/'
            },
            {
                image: '../../assets/images/koji-soupe/instagram/koji-soupe-IG-3.jpg',
                link: 'https://www.instagram.com/p/CH5qJT0phRd/'
            },
            {
                image: '../../assets/images/koji-soupe/instagram/koji-soupe-IG-4.jpg',
                link: 'https://www.instagram.com/p/CH3Z7QopNj0/'
            },
            {
                image: '../../assets/images/koji-soupe/instagram/koji-soupe-IG-5.jpg',
                link: 'https://www.instagram.com/p/CH2z-ydJ5Av/'
            },
            {
                image: '../../assets/images/koji-soupe/instagram/koji-soupe-IG-6.jpg',
                link: 'https://www.instagram.com/p/CHvHeT4Dlrb/'
            },
            {
                image: '../../assets/images/koji-soupe/instagram/koji-soupe-IG-7.jpg',
                link: 'https://www.instagram.com/p/CHvGU18jnFe/'
            },
            {
                image: '../../assets/images/koji-soupe/instagram/koji-soupe-IG-8.jpg',
                link: 'https://www.instagram.com/p/CHvDNdfjiqP/'
            },
            {
                image: '../../assets/images/koji-soupe/instagram/koji-soupe-IG-9.jpg',
                link: 'https://www.instagram.com/p/CHuFIjVja5R/'
            },
            {
                image: '../../assets/images/koji-soupe/instagram/koji-soupe-IG-10.jpg',
                link: 'https://www.instagram.com/p/CHrDIhpDrIJ/'
            },
            {
                image: '../../assets/images/koji-soupe/instagram/koji-soupe-IG-11.jpg',
                link: 'https://www.instagram.com/p/CHq2rEsjOtd/'
            },
            {
                image: '../../assets/images/koji-soupe/instagram/koji-soupe-IG-12.jpg',
                link: 'https://www.instagram.com/p/CHqGjmwj26D/'
            },
            {
                image: '../../assets/images/koji-soupe/instagram/koji-soupe-IG-13.jpg',
                link: 'https://www.instagram.com/p/CHo5nKKjtIx/'
            },
            {
                image: '../../assets/images/koji-soupe/instagram/koji-soupe-IG-14.jpg',
                link: 'https://www.instagram.com/p/CHawhjFjVE7/'
            },
            {
                image: '../../assets/images/koji-soupe/instagram/koji-soupe-IG-15.jpg',
                link: 'https://www.instagram.com/p/CHawegjjL9z/'
            },
            {
                image: '../../assets/images/koji-soupe/instagram/koji-soupe-IG-16.jpg',
                link: 'https://www.instagram.com/p/CHawc2zjWSU/'
            },
            {
                image: '../../assets/images/koji-soupe/instagram/koji-soupe-IG-17.jpg',
                link: 'https://www.instagram.com/p/CHawaS6jFF6/'
            },
            {
                image: '../../assets/images/koji-soupe/instagram/koji-soupe-IG-18.jpg',
                link: 'https://www.instagram.com/p/CHawVvEjMRB/'
            },
            {
                image: '../../assets/images/koji-soupe/instagram/koji-soupe-IG-19.jpg',
                link: 'https://www.instagram.com/p/CHawT2eDDHR/'
            }
        ];
        this.lundisAuSoleilMedia = [
            {
                image: '../../assets/images/lundis-au-soleil/instagram/lundis-au-soleil-IG-1.jpg',
                link: 'https://www.instagram.com/p/CIWMJT0HgNM/'
            },
            {
                image: '../../assets/images/lundis-au-soleil/instagram/lundis-au-soleil-IG-2.jpg',
                link: 'https://www.instagram.com/p/CH08LGgnET6/'
            },
            {
                image: '../../assets/images/lundis-au-soleil/instagram/lundis-au-soleil-IG-3.jpg',
                link: 'https://www.instagram.com/p/CHitaxpntJ-/'
            },
            {
                image: '../../assets/images/lundis-au-soleil/instagram/lundis-au-soleil-IG-4.jpg',
                link: 'https://www.instagram.com/p/CHT1odoHgaF/'
            },
            {
                image: '../../assets/images/lundis-au-soleil/instagram/lundis-au-soleil-IG-5.jpg',
                link: 'https://www.instagram.com/p/CHLsw5BHXoW/'
            },
            {
                image: '../../assets/images/lundis-au-soleil/instagram/lundis-au-soleil-IG-6.jpg',
                link: 'https://www.instagram.com/p/CGsY017nAzZ/'
            },
            {
                image: '../../assets/images/lundis-au-soleil/instagram/lundis-au-soleil-IG-7.jpg',
                link: 'https://www.instagram.com/p/CGat3KkHfPf/'
            },
            {
                image: '../../assets/images/lundis-au-soleil/instagram/lundis-au-soleil-IG-8.jpg',
                link: 'https://www.instagram.com/p/CGIWXzmHsRz/'
            },
            {
                image: '../../assets/images/lundis-au-soleil/instagram/lundis-au-soleil-IG-9.jpg',
                link: 'https://www.instagram.com/p/CFxbWk5HRjL/'
            },
            {
                image: '../../assets/images/lundis-au-soleil/instagram/lundis-au-soleil-IG-10.jpg',
                link: 'https://www.instagram.com/p/CFuPUH4nShF/'
            },
            {
                image: '../../assets/images/lundis-au-soleil/instagram/lundis-au-soleil-IG-11.jpg',
                link: 'https://www.instagram.com/p/CFVccPtnHbS/'
            },
            {
                image: '../../assets/images/lundis-au-soleil/instagram/lundis-au-soleil-IG-12.jpg',
                link: 'https://www.instagram.com/p/CEXjQOsnLqy/'
            },
            {
                image: '../../assets/images/lundis-au-soleil/instagram/lundis-au-soleil-IG-13.jpg',
                link: 'https://www.instagram.com/p/CD4bGA4nvpL/'
            },
            {
                image: '../../assets/images/lundis-au-soleil/instagram/lundis-au-soleil-IG-14.jpg',
                link: 'https://www.instagram.com/p/CDkQW97HGhD/'
            },
            {
                image: '../../assets/images/lundis-au-soleil/instagram/lundis-au-soleil-IG-15.jpg',
                link: 'https://www.instagram.com/p/CDUMNnhHlYG/'
            },
            {
                image: '../../assets/images/lundis-au-soleil/instagram/lundis-au-soleil-IG-16.jpg',
                link: 'https://www.instagram.com/p/CDMyhlsHHOi/'
            },
            {
                image: '../../assets/images/lundis-au-soleil/instagram/lundis-au-soleil-IG-17.jpg',
                link: 'https://www.instagram.com/p/CCJr3IknHgZ/'
            },
            {
                image: '../../assets/images/lundis-au-soleil/instagram/lundis-au-soleil-IG-18.jpg',
                link: 'https://www.instagram.com/p/CB_U8RpntpL/'
            },
            {
                image: '../../assets/images/lundis-au-soleil/instagram/lundis-au-soleil-IG-19.jpg',
                link: 'https://www.instagram.com/p/CB1CjNTHogj/'
            },
            {
                image: '../../assets/images/lundis-au-soleil/instagram/lundis-au-soleil-IG-20.jpg',
                link: 'https://www.instagram.com/p/CBqlO2SnRR9/'
            },
            {
                image: '../../assets/images/lundis-au-soleil/instagram/lundis-au-soleil-IG-21.jpg',
                link: 'https://www.instagram.com/p/CBBC6Mgna_L/'
            },
            {
                image: '../../assets/images/lundis-au-soleil/instagram/lundis-au-soleil-IG-22.jpg',
                link: 'https://www.instagram.com/p/CA8b-ILHgpa/'
            },
            {
                image: '../../assets/images/lundis-au-soleil/instagram/lundis-au-soleil-IG-23.jpg',
                link: 'https://www.instagram.com/p/CAv1RtsH3h7/'
            },
            {
                image: '../../assets/images/lundis-au-soleil/instagram/lundis-au-soleil-IG-24.jpg',
                link: 'https://www.instagram.com/p/CALlytOHxcb/'
            },
            {
                image: '../../assets/images/lundis-au-soleil/instagram/lundis-au-soleil-IG-25.jpg',
                link: 'https://www.instagram.com/p/CAJg6IyH3JO/'
            },
            {
                image: '../../assets/images/lundis-au-soleil/instagram/lundis-au-soleil-IG-27.jpg',
                link: 'https://www.instagram.com/p/B_YNlqenkdY/'
            },
            {
                image: '../../assets/images/lundis-au-soleil/instagram/lundis-au-soleil-IG-28.jpg',
                link: 'https://www.instagram.com/p/B_SXUGhHr7d/'
            },
            {
                image: '../../assets/images/lundis-au-soleil/instagram/lundis-au-soleil-IG-34.jpg',
                link: 'https://www.instagram.com/p/B99nm4FnPck/'
            },
            {
                image: '../../assets/images/lundis-au-soleil/instagram/lundis-au-soleil-IG-35.jpg',
                link: 'https://www.instagram.com/p/B97FMn3nMQI/'
            },
            {
                image: '../../assets/images/lundis-au-soleil/instagram/lundis-au-soleil-IG-36.jpg',
                link: 'https://www.instagram.com/p/B9xgcoyHzjp/'
            }
        ];
        this.tincSetMedia = [
            {
                image: '../../assets/images/tinc-set/instagram/tinc-set-IG-1.jpg',
                link: 'https://www.instagram.com/p/CKthnlWnRVT/'
            },
            {
                image: '../../assets/images/tinc-set/instagram/tinc-set-IG-2.jpg',
                link: 'https://www.instagram.com/p/CKrCS3Gnej6/'
            },
            {
                image: '../../assets/images/tinc-set/instagram/tinc-set-IG-3.jpg',
                link: 'https://www.instagram.com/p/CKkmFKLnm_I/'
            },
            {
                image: '../../assets/images/tinc-set/instagram/tinc-set-IG-4.jpg',
                link: 'https://www.instagram.com/p/CKXMyfgnh-z/'
            },
            {
                image: '../../assets/images/tinc-set/instagram/tinc-set-IG-5.jpg',
                link: 'https://www.instagram.com/p/CKUHEIWnlr4/'
            },
            {
                image: '../../assets/images/tinc-set/instagram/tinc-set-IG-6.jpg',
                link: 'https://www.instagram.com/p/CKRcWA8nTt4/'
            },
            {
                image: '../../assets/images/tinc-set/instagram/tinc-set-IG-7.jpg',
                link: 'https://www.instagram.com/p/CKPEjZmnxxv/'
            },
            {
                image: '../../assets/images/tinc-set/instagram/tinc-set-IG-8.jpg',
                link: 'https://www.instagram.com/p/CKJdrY2ng89/'
            },
            {
                image: '../../assets/images/tinc-set/instagram/tinc-set-IG-9.jpg',
                link: 'https://www.instagram.com/p/CKG3uRYnydE/'
            },
            {
                image: '../../assets/images/tinc-set/instagram/tinc-set-IG-10.jpg',
                link: 'https://www.instagram.com/p/CKEmpXHHgoz/'
            },
            {
                image: '../../assets/images/tinc-set/instagram/tinc-set-IG-11.jpg',
                link: 'https://www.instagram.com/p/CKDGR8rHpYX/'
            },
            {
                image: '../../assets/images/tinc-set/instagram/tinc-set-IG-12.jpg',
                link: 'https://www.instagram.com/p/CI8ZD0fHcD5/'
            },
            {
                image: '../../assets/images/tinc-set/instagram/tinc-set-IG-14.jpg',
                link: 'https://www.instagram.com/p/CIvigpfnCFA/'
            },
            {
                image: '../../assets/images/tinc-set/instagram/tinc-set-IG-15.jpg',
                link: 'https://www.instagram.com/p/CIqM6K1n77C/'
            },
            {
                image: '../../assets/images/tinc-set/instagram/tinc-set-IG-16.jpg',
                link: 'https://www.instagram.com/p/CIYUKfRHhB9/'
            },
            {
                image: '../../assets/images/tinc-set/instagram/tinc-set-IG-17.jpg',
                link: 'https://www.instagram.com/p/CIXDTc3HF80/'
            },
            {
                image: '../../assets/images/tinc-set/instagram/tinc-set-IG-18.jpg',
                link: 'https://www.instagram.com/p/CIVpb3bHTSb/'
            },
            {
                image: '../../assets/images/tinc-set/instagram/tinc-set-IG-19.jpg',
                link: 'https://www.instagram.com/p/CH0QP9AHWhm/'
            },
            {
                image: '../../assets/images/tinc-set/instagram/tinc-set-IG-20.jpg',
                link: 'https://www.instagram.com/p/CHwMgC_He4H/'
            },
            {
                image: '../../assets/images/tinc-set/instagram/tinc-set-IG-21.jpg',
                link: 'https://www.instagram.com/p/CHeLqG6HL6Q/'
            },
            {
                image: '../../assets/images/tinc-set/instagram/tinc-set-IG-22.jpg',
                link: 'https://www.instagram.com/p/CHSuHyxHh2C/'
            },
            {
                image: '../../assets/images/tinc-set/instagram/tinc-set-IG-23.jpg',
                link: 'https://www.instagram.com/p/CHOyY2mnD45/'
            },
            {
                image: '../../assets/images/tinc-set/instagram/tinc-set-IG-24.jpg',
                link: 'https://www.instagram.com/p/CHMF5MTnsSV/'
            },
            {
                image: '../../assets/images/tinc-set/instagram/tinc-set-IG-25.jpg',
                link: 'https://www.instagram.com/p/CHDdpOPH-0u/'
            },
            {
                image: '../../assets/images/tinc-set/instagram/tinc-set-IG-26.jpg',
                link: 'https://www.instagram.com/p/CHAmCcGnXh6/'
            },
            {
                image: '../../assets/images/tinc-set/instagram/tinc-set-IG-28.jpg',
                link: 'https://www.instagram.com/p/CGxqJu1H41a/'
            },
            {
                image: '../../assets/images/tinc-set/instagram/tinc-set-IG-29.jpg',
                link: 'https://www.instagram.com/p/CGr_5CLnBKZ/'
            },
            {
                image: '../../assets/images/tinc-set/instagram/tinc-set-IG-30.jpg',
                link: 'https://www.instagram.com/p/CGpdGQhHs4N/'
            },
            {
                image: '../../assets/images/tinc-set/instagram/tinc-set-IG-31.jpg',
                link: 'https://www.instagram.com/p/CGllnbvnBdR/'
            },
            {
                image: '../../assets/images/tinc-set/instagram/tinc-set-IG-32.jpg',
                link: 'https://www.instagram.com/p/CGH3Z4vnLiU/'
            }
        ];
        this.fanfarePizzaMedia = [
            {
                image: '../../assets/images/fanfare-pizza/instagram/fanfare-pizza-IG-2.jpg',
                link: 'https://www.instagram.com/p/CK6vwPYjQDD/'
            },
            {
                image: '../../assets/images/fanfare-pizza/instagram/fanfare-pizza-IG-1.jpg',
                link: 'https://www.instagram.com/p/CK7Rib8D8Ai/'
            },
            {
                image: '../../assets/images/fanfare-pizza/instagram/fanfare-pizza-IG-3.jpg',
                link: 'https://www.instagram.com/p/CI30WWuJ7G5/'
            },
            {
                image: '../../assets/images/fanfare-pizza/instagram/fanfare-pizza-IG-9.jpg',
                link: 'https://www.instagram.com/p/CIzicFtlFpz/'
            },
            {
                image: '../../assets/images/fanfare-pizza/instagram/fanfare-pizza-IG-5.jpg',
                link: 'https://www.instagram.com/p/CIzi33wFxJR/'
            },
            {
                image: '../../assets/images/fanfare-pizza/instagram/fanfare-pizza-IG-12.jpg',
                link: 'https://www.instagram.com/p/CIziPwJFBwx/'
            },
            {
                image: '../../assets/images/fanfare-pizza/instagram/fanfare-pizza-IG-6.jpg',
                link: 'https://www.instagram.com/p/CIzi01FlOmS/'
            },
            {
                image: '../../assets/images/fanfare-pizza/instagram/fanfare-pizza-IG-8.jpg',
                link: 'https://www.instagram.com/p/CIziq2VFmTQ/'
            },
            {
                image: '../../assets/images/fanfare-pizza/instagram/fanfare-pizza-IG-13.jpg',
                link: 'https://www.instagram.com/p/CIziMIYFPwg/'
            },
            {
                image: '../../assets/images/fanfare-pizza/instagram/fanfare-pizza-IG-10.jpg',
                link: 'https://www.instagram.com/p/CIziYz-lChS/'
            },
            {
                image: '../../assets/images/fanfare-pizza/instagram/fanfare-pizza-IG-11.jpg',
                link: 'https://www.instagram.com/p/CIziVU9lWdy/'
            },
            {
                image: '../../assets/images/fanfare-pizza/instagram/fanfare-pizza-IG-4.jpg',
                link: 'https://www.instagram.com/p/CIzlNQxFj1o/'
            },
            {
                image: '../../assets/images/fanfare-pizza/instagram/fanfare-pizza-IG-25.jpg',
                link: 'https://www.instagram.com/p/CIwTdoRF8j9/'
            },
            {
                image: '../../assets/images/fanfare-pizza/instagram/fanfare-pizza-IG-21.jpg',
                link: 'https://www.instagram.com/p/CIzhcmcFLUq/'
            },
            {
                image: '../../assets/images/fanfare-pizza/instagram/fanfare-pizza-IG-22.jpg',
                link: 'https://www.instagram.com/p/CIzhaNTlEdE/'
            },
            {
                image: '../../assets/images/fanfare-pizza/instagram/fanfare-pizza-IG-23.jpg',
                link: 'https://www.instagram.com/p/CIzhV6uFmcX/'
            },
            {
                image: '../../assets/images/fanfare-pizza/instagram/fanfare-pizza-IG-24.jpg',
                link: 'https://www.instagram.com/p/CIwTnlnFmm0/'
            },
            {
                image: '../../assets/images/fanfare-pizza/instagram/fanfare-pizza-IG-20.jpg',
                link: 'https://www.instagram.com/p/CIzhuF8lKTV/'
            },
            {
                image: '../../assets/images/fanfare-pizza/instagram/fanfare-pizza-IG-14.jpg',
                link: 'https://www.instagram.com/p/CIziHDOlNMQ/'
            },
            {
                image: '../../assets/images/fanfare-pizza/instagram/fanfare-pizza-IG-15.jpg',
                link: 'https://www.instagram.com/p/CIziFiglOQO/'
            },
            {
                image: '../../assets/images/fanfare-pizza/instagram/fanfare-pizza-IG-16.jpg',
                link: 'https://www.instagram.com/p/CIziEFpldG_/'
            },
            {
                image: '../../assets/images/fanfare-pizza/instagram/fanfare-pizza-IG-17.jpg',
                link: 'https://www.instagram.com/p/CIziCBeFjqR/'
            },
            {
                image: '../../assets/images/fanfare-pizza/instagram/fanfare-pizza-IG-18.jpg',
                link: 'https://www.instagram.com/p/CIzh8VeFZ2N/'
            },
            {
                image: '../../assets/images/fanfare-pizza/instagram/fanfare-pizza-IG-19.jpg',
                link: 'https://www.instagram.com/p/CIzh6oHF0YE/'
            }
        ];
        this.bashaMedia = [
            {
                "image": "../../assets/images/basha/instagram/basha-IG-1.jpg",
                "link": "https://www.instagram.com/p/B6Q7F_KgbLa"
            },
            {
                "image": "../../assets/images/basha/instagram/basha-IG-2.jpg",
                "link": "https://www.instagram.com/p/CFVAjQ2DGbi"
            },
            {
                "image": "../../assets/images/basha/instagram/basha-IG-3.jpg",
                "link": "https://www.instagram.com/p/CFaF4iOMkUN"
            },
            {
                "image": "../../assets/images/basha/instagram/basha-IG-4.jpg",
                "link": "https://www.instagram.com/p/CFzyjvIMOno"
            },
            {
                "image": "../../assets/images/basha/instagram/basha-IG-5.jpg",
                "link": "https://www.instagram.com/p/CF0RCp8Mhb2"
            },
            {
                "image": "../../assets/images/basha/instagram/basha-IG-6.jpg",
                "link": "https://www.instagram.com/p/CF0n9_-MQnA"
            },
            {
                "image": "../../assets/images/basha/instagram/basha-IG-7.jpg",
                "link": "https://www.instagram.com/p/CF0oI5HsJFW"
            },
            {
                "image": "../../assets/images/basha/instagram/basha-IG-8.jpg",
                "link": "https://www.instagram.com/p/CF08lY5lk6a"
            },
            {
                "image": "../../assets/images/basha/instagram/basha-IG-9.jpg",
                "link": "https://www.instagram.com/p/CF09jJKlslY"
            },
            {
                "image": "../../assets/images/basha/instagram/basha-IG-10.jpg",
                "link": "https://www.instagram.com/p/CGBckZ3JmcV"
            },
            {
                "image": "../../assets/images/basha/instagram/basha-IG-11.jpg",
                "link": "https://www.instagram.com/p/CGS9tqfpKN9"
            },
            {
                "image": "../../assets/images/basha/instagram/basha-IG-12.jpg",
                "link": "https://www.instagram.com/p/CG-jvnHBY8H"
            }
        ];
        this.uluwatuMedia = [
            {
                "image": "../../assets/images/uluwatu/instagram/uluwatu-IG-1.jpg",
                "link": "https://www.instagram.com/p/CJWo8stFylY"
            },
            {
                "image": "../../assets/images/uluwatu/instagram/uluwatu-IG-2.jpg",
                "link": "https://www.instagram.com/p/CJzso5Yl9JE"
            },
            {
                "image": "../../assets/images/uluwatu/instagram/uluwatu-IG-3.jpg",
                "link": "https://www.instagram.com/p/CKC20ABlk6b"
            },
            {
                "image": "../../assets/images/uluwatu/instagram/uluwatu-IG-4.jpg",
                "link": "https://www.instagram.com/p/CKcxqStFqA3"
            },
            {
                "image": "../../assets/images/uluwatu/instagram/uluwatu-IG-5.jpg",
                "link": "https://www.instagram.com/p/CLHrGy7j2SU"
            },
            {
                "image": "../../assets/images/uluwatu/instagram/uluwatu-IG-6.jpg",
                "link": "https://www.instagram.com/p/CKiOS-8lE38"
            },
            {
                "image": "../../assets/images/uluwatu/instagram/uluwatu-IG-7.jpg",
                "link": "https://www.instagram.com/p/CKS5AdWFkfP"
            },
            {
                "image": "../../assets/images/uluwatu/instagram/uluwatu-IG-8.jpg",
                "link": "https://www.instagram.com/p/CJ31o6vlRDI"
            },
            {
                "image": "../../assets/images/uluwatu/instagram/uluwatu-IG-9.jpg",
                "link": "https://www.instagram.com/p/CJWrH-EFoRh"
            },
            {
                "image": "../../assets/images/uluwatu/instagram/uluwatu-IG-10.jpg",
                "link": "https://www.instagram.com/p/CJqy8vPlQDK"
            },
            {
                "image": "../../assets/images/uluwatu/instagram/uluwatu-IG-11.jpg",
                "link": "https://www.instagram.com/p/CJ4jaX3lMS0"
            },
            {
                "image": "../../assets/images/uluwatu/instagram/uluwatu-IG-12.jpg",
                "link": "https://www.instagram.com/p/CKXor6IFniH"
            },
        ];
        this.saintHoublonMedia = [
            {
                "image": "../../assets/images/saint-houblon/instagram/saint-houblon-IG-1.jpg",
                "link": "https://www.instagram.com/p/CMLPdBAH43Q"
            },
            {
                "image": "../../assets/images/saint-houblon/instagram/saint-houblon-IG-2.jpg",
                "link": "https://www.instagram.com/p/CKrwtQ-HLCr"
            },
            {
                "image": "../../assets/images/saint-houblon/instagram/saint-houblon-IG-3.jpg",
                "link": "https://www.instagram.com/p/CJjxeVUga0T"
            },
            {
                "image": "../../assets/images/saint-houblon/instagram/saint-houblon-IG-4.jpg",
                "link": "https://www.instagram.com/p/CLaZUavnaZs"
            },
            {
                "image": "../../assets/images/saint-houblon/instagram/saint-houblon-IG-5.jpg",
                "link": "https://www.instagram.com/p/CIy1CB8Hl6u"
            },
            {
                "image": "../../assets/images/saint-houblon/instagram/saint-houblon-IG-6.jpg",
                "link": "https://www.instagram.com/p/CKkOvA5Jh7q"
            },
            {
                "image": "../../assets/images/saint-houblon/instagram/saint-houblon-IG-7.jpg",
                "link": "https://www.instagram.com/p/CKAHVU8njfT"
            },
            {
                "image": "../../assets/images/saint-houblon/instagram/saint-houblon-IG-8.jpg",
                "link": "https://www.instagram.com/p/CHdo3gpsw32"
            },
            {
                "image": "../../assets/images/saint-houblon/instagram/saint-houblon-IG-9.jpg",
                "link": "https://www.instagram.com/p/CIBkaiNHkcd"
            },
            {
                "image": "../../assets/images/saint-houblon/instagram/saint-houblon-IG-10.jpg",
                "link": "https://www.instagram.com/p/CI3wyRsnJwq"
            },
            {
                "image": "../../assets/images/saint-houblon/instagram/saint-houblon-IG-11.jpg",
                "link": "https://www.instagram.com/p/CHyClbdH9y_"
            },
            {
                "image": "../../assets/images/saint-houblon/instagram/saint-houblon-IG-12.jpg",
                "link": "https://www.instagram.com/p/CDRr9EwqGny"
            },
            {
                "image": "../../assets/images/saint-houblon/instagram/saint-houblon-IG-13.jpg",
                "link": "https://www.instagram.com/p/CGNR5lJnQJQ"
            },
            {
                "image": "../../assets/images/saint-houblon/instagram/saint-houblon-IG-14.jpg",
                "link": "https://www.instagram.com/p/CBBm4ISnFeI"
            },
            {
                "image": "../../assets/images/saint-houblon/instagram/saint-houblon-IG-15.jpg",
                "link": "https://www.instagram.com/p/B7bQmGxncO9"
            },
            {
                "image": "../../assets/images/saint-houblon/instagram/saint-houblon-IG-16.jpg",
                "link": "https://www.instagram.com/p/B-R72G5njFo"
            },
            {
                "image": "../../assets/images/saint-houblon/instagram/saint-houblon-IG-17.jpg",
                "link": "https://www.instagram.com/p/B7B276pHW72"
            },
            {
                "image": "../../assets/images/saint-houblon/instagram/saint-houblon-IG-18.jpg",
                "link": "https://www.instagram.com/p/B5DnMDenj-S"
            },
            {
                "image": "../../assets/images/saint-houblon/instagram/saint-houblon-IG-19.jpg",
                "link": "https://www.instagram.com/p/B7oPEQKnebv"
            },
            {
                "image": "../../assets/images/saint-houblon/instagram/saint-houblon-IG-20.jpg",
                "link": "https://www.instagram.com/p/B1eyXpfnW0n"
            },
            {
                "image": "../../assets/images/saint-houblon/instagram/saint-houblon-IG-21.jpg",
                "link": "https://www.instagram.com/p/B5vfg3enJeu"
            },
            {
                "image": "../../assets/images/saint-houblon/instagram/saint-houblon-IG-22.jpg",
                "link": "https://www.instagram.com/p/B0dmYOAHIag"
            },
            {
                "image": "../../assets/images/saint-houblon/instagram/saint-houblon-IG-23.jpg",
                "link": "https://www.instagram.com/p/Byu2VbUnhyD"
            },
            {
                "image": "../../assets/images/saint-houblon/instagram/saint-houblon-IG-24.jpg",
                "link": "https://www.instagram.com/p/ByYteY9nUJ6"
            },
            {
                "image": "../../assets/images/saint-houblon/instagram/saint-houblon-IG-25.jpg",
                "link": "https://www.instagram.com/p/Bx2b7I5Hxko"
            },
            {
                "image": "../../assets/images/saint-houblon/instagram/saint-houblon-IG-26.jpg",
                "link": "https://www.instagram.com/p/Bw-iR0TAx8j"
            },
            {
                "image": "../../assets/images/saint-houblon/instagram/saint-houblon-IG-27.jpg",
                "link": "https://www.instagram.com/p/BvWfh_HAp7N"
            },
            {
                "image": "../../assets/images/saint-houblon/instagram/saint-houblon-IG-28.jpg",
                "link": "https://www.instagram.com/p/BwsfIE8A21T"
            },
            {
                "image": "../../assets/images/saint-houblon/instagram/saint-houblon-IG-29.jpg",
                "link": "https://www.instagram.com/p/BvINA4uAglY"
            },
            {
                "image": "../../assets/images/saint-houblon/instagram/saint-houblon-IG-30.jpg",
                "link": "https://www.instagram.com/p/ByfTHmBHNxi"
            }

        ];
        this.boustanMedia = [
            {
                "image": "../../assets/images/boustan/instagram/boustan-IG-1.jpg",
                "link": "https://www.instagram.com/p/CMfDLApjyML"
            },
            {
                "image": "../../assets/images/boustan/instagram/boustan-IG-2.jpg",
                "link": "https://www.instagram.com/p/CM5U2JAj4qT"
            },
            {
                "image": "../../assets/images/boustan/instagram/boustan-IG-3.jpg",
                "link": "https://www.instagram.com/p/CMnjGl-DKTE"
            },
            {
                "image": "../../assets/images/boustan/instagram/boustan-IG-4.jpg",
                "link": "https://www.instagram.com/p/CMDelCpD3pN"
            },
            {
                "image": "../../assets/images/boustan/instagram/boustan-IG-5.jpg",
                "link": "https://www.instagram.com/p/CKxKDD3DDZ8"
            },
            {
                "image": "../../assets/images/boustan/instagram/boustan-IG-6.jpg",
                "link": "https://www.instagram.com/p/CJtZQqUD3FU"
            },
            {
                "image": "../../assets/images/boustan/instagram/boustan-IG-7.jpg",
                "link": "https://www.instagram.com/p/CMXjmDsjsP5"
            },
            {
                "image": "../../assets/images/boustan/instagram/boustan-IG-8.jpg",
                "link": "https://www.instagram.com/p/CMK2Y85DY6J"
            },
            {
                "image": "../../assets/images/boustan/instagram/boustan-IG-9.jpg",
                "link": "https://www.instagram.com/p/CMGDv0fDmy4"
            },
            {
                "image": "../../assets/images/boustan/instagram/boustan-IG-10.jpg",
                "link": "https://www.instagram.com/p/CLF2EOMDuMS"
            },
            {
                "image": "../../assets/images/boustan/instagram/boustan-IG-11.jpg",
                "link": "https://www.instagram.com/p/CI83ji5ncrB"
            },
            {
                "image": "../../assets/images/boustan/instagram/boustan-IG-12.jpg",
                "link": "https://www.instagram.com/p/CI52Mykj41g"
            },
            {
                "image": "../../assets/images/boustan/instagram/boustan-IG-13.jpg",
                "link": "https://www.instagram.com/p/CIeb12BDzsT"
            },
            {
                "image": "../../assets/images/boustan/instagram/boustan-IG-14.jpg",
                "link": "https://www.instagram.com/p/CHgJKIhLaQ-"
            },
            {
                "image": "../../assets/images/boustan/instagram/boustan-IG-15.jpg",
                "link": "https://www.instagram.com/p/CHf7mrVDNfc"
            },
            {
                "image": "../../assets/images/boustan/instagram/boustan-IG-16.jpg",
                "link": "https://www.instagram.com/p/CG-YpILDwd-"
            },
            {
                "image": "../../assets/images/boustan/instagram/boustan-IG-17.jpg",
                "link": "https://www.instagram.com/p/CB1WIxqHDwm"
            },
            {
                "image": "../../assets/images/boustan/instagram/boustan-IG-18.jpg",
                "link": "https://www.instagram.com/p/CAvQ00bHwj3"
            },
            {
                "image": "../../assets/images/boustan/instagram/boustan-IG-19.jpg",
                "link": "https://www.instagram.com/p/CArB09LHgsF"
            },
            {
                "image": "../../assets/images/boustan/instagram/boustan-IG-20.jpg",
                "link": "https://www.instagram.com/p/B_iOr3sHf9c"
            },
            {
                "image": "../../assets/images/boustan/instagram/boustan-IG-21.jpg",
                "link": "https://www.instagram.com/p/B_PrhTAn6-J"
            },
            {
                "image": "../../assets/images/boustan/instagram/boustan-IG-22.jpg",
                "link": "https://www.instagram.com/p/B9zv1i8nn3f"
            },
            {
                "image": "../../assets/images/boustan/instagram/boustan-IG-23.jpg",
                "link": "https://www.instagram.com/p/B9pEIGYHTGy"
            },
            {
                "image": "../../assets/images/boustan/instagram/boustan-IG-24.jpg",
                "link": "https://www.instagram.com/p/B8KooA3H4v7"
            },
            {
                "image": "../../assets/images/boustan/instagram/boustan-IG-25.jpg",
                "link": "https://www.instagram.com/p/B7oI3-Ina27"
            },
            {
                "image": "../../assets/images/boustan/instagram/boustan-IG-26.jpg",
                "link": "https://www.instagram.com/p/B7ejjTxHLtD"
            },
            {
                "image": "../../assets/images/boustan/instagram/boustan-IG-27.jpg",
                "link": "https://www.instagram.com/p/B63PnUbHOUe"
            },
            {
                "image": "../../assets/images/boustan/instagram/boustan-IG-28.jpg",
                "link": "https://www.instagram.com/p/B4DEZfvngew"
            },
            {
                "image": "../../assets/images/boustan/instagram/boustan-IG-29.jpg",
                "link": "https://www.instagram.com/p/B3hbx0EnBID"
            },
            {
                "image": "../../assets/images/boustan/instagram/boustan-IG-30.jpg",
                "link": "https://www.instagram.com/p/B2pK8vbnXnd"
            }
        ];
        this.thaiExpressCdnMedia = [
            {
                "image": "../../assets/images/thai-express-cdn/instagram/thai-express-cdn-IG-1.jpg",
                "link": "https://www.instagram.com/p/CBA8Mp6BqKG"
            },
            {
                "image": "../../assets/images/thai-express-cdn/instagram/thai-express-cdn-IG-2.jpg",
                "link": "https://www.instagram.com/p/CE99lZhl-Ha"
            },
            {
                "image": "../../assets/images/thai-express-cdn/instagram/thai-express-cdn-IG-3.jpg",
                "link": "https://www.instagram.com/p/CGNJ2OMh200"
            },
            {
                "image": "../../assets/images/thai-express-cdn/instagram/thai-express-cdn-IG-4.jpg",
                "link": "https://www.instagram.com/p/CDl2hSbBpmj"
            },
            {
                "image": "../../assets/images/thai-express-cdn/instagram/thai-express-cdn-IG-5.jpg",
                "link": "https://www.instagram.com/p/CDeO4uwh0lR"
            },
            {
                "image": "../../assets/images/thai-express-cdn/instagram/thai-express-cdn-IG-6.jpg",
                "link": "https://www.instagram.com/p/CCvs1KIhx9f"
            },
            {
                "image": "../../assets/images/thai-express-cdn/instagram/thai-express-cdn-IG-7.jpg",
                "link": "https://www.instagram.com/p/CBsqN35hPAG"
            },
            {
                "image": "../../assets/images/thai-express-cdn/instagram/thai-express-cdn-IG-8.jpg",
                "link": "https://www.instagram.com/p/CA2pikPhVxW"
            },
            {
                "image": "../../assets/images/thai-express-cdn/instagram/thai-express-cdn-IG-9.jpg",
                "link": "https://www.instagram.com/p/CAfc73PBRtp"
            },
            {
                "image": "../../assets/images/thai-express-cdn/instagram/thai-express-cdn-IG-10.jpg",
                "link": "https://www.instagram.com/p/B-R74EPBtHz"
            },
            {
                "image": "../../assets/images/thai-express-cdn/instagram/thai-express-cdn-IG-11.jpg",
                "link": "https://www.instagram.com/p/B9NSOq0hcag"
            },
            {
                "image": "../../assets/images/thai-express-cdn/instagram/thai-express-cdn-IG-12.jpg",
                "link": "https://www.instagram.com/p/B_2R2kLBneT"
            },
            {
                "image": "../../assets/images/thai-express-cdn/instagram/thai-express-cdn-IG-13.jpg",
                "link": "https://www.instagram.com/p/B9WxGbLhnM-"
            },
            {
                "image": "../../assets/images/thai-express-cdn/instagram/thai-express-cdn-IG-14.jpg",
                "link": "https://www.instagram.com/p/B9rNVMThBgO"
            },
            {
                "image": "../../assets/images/thai-express-cdn/instagram/thai-express-cdn-IG-15.jpg",
                "link": "https://www.instagram.com/p/B9SNW_GBzMY"
            },
            {
                "image": "../../assets/images/thai-express-cdn/instagram/thai-express-cdn-IG-16.jpg",
                "link": "https://www.instagram.com/p/B8tiKCTButL"
            },
            {
                "image": "../../assets/images/thai-express-cdn/instagram/thai-express-cdn-IG-17.jpg",
                "link": "https://www.instagram.com/p/B81QZ52BQSp"
            },
            {
                "image": "../../assets/images/thai-express-cdn/instagram/thai-express-cdn-IG-18.jpg",
                "link": "https://www.instagram.com/p/B8JdJzuhkxL"
            },
            {
                "image": "../../assets/images/thai-express-cdn/instagram/thai-express-cdn-IG-19.jpg",
                "link": "https://www.instagram.com/p/B-PQzWoBrgn"
            },
            {
                "image": "../../assets/images/thai-express-cdn/instagram/thai-express-cdn-IG-20.jpg",
                "link": "https://www.instagram.com/p/B8bf5AXhMRV"
            },
            {
                "image": "../../assets/images/thai-express-cdn/instagram/thai-express-cdn-IG-21.jpg",
                "link": "https://www.instagram.com/p/B7JTy1kBnKX"
            },
            {
                "image": "../../assets/images/thai-express-cdn/instagram/thai-express-cdn-IG-22.jpg",
                "link": "https://www.instagram.com/p/B5lAUp9B3bK"
            },
            {
                "image": "../../assets/images/thai-express-cdn/instagram/thai-express-cdn-IG-23.jpg",
                "link": "https://www.instagram.com/p/B5VhotHBoRr"
            },
            {
                "image": "../../assets/images/thai-express-cdn/instagram/thai-express-cdn-IG-24.jpg",
                "link": "https://www.instagram.com/p/B5dZJHGhDgc"
            },
            {
                "image": "../../assets/images/thai-express-cdn/instagram/thai-express-cdn-IG-25.jpg",
                "link": "https://www.instagram.com/p/B34r84Ohp0I"
            },
            {
                "image": "../../assets/images/thai-express-cdn/instagram/thai-express-cdn-IG-26.jpg",
                "link": "https://www.instagram.com/p/B3fH3VlhxJc"
            },
            {
                "image": "../../assets/images/thai-express-cdn/instagram/thai-express-cdn-IG-27.jpg",
                "link": "https://www.instagram.com/p/B4AblaqHlqs"
            },
            {
                "image": "../../assets/images/thai-express-cdn/instagram/thai-express-cdn-IG-28.jpg",
                "link": "https://www.instagram.com/p/B2kJ3i0hioQ"
            },
            {
                "image": "../../assets/images/thai-express-cdn/instagram/thai-express-cdn-IG-29.jpg",
                "link": "https://www.instagram.com/p/B2W9Tu-hQj-"
            },
            {
                "image": "../../assets/images/thai-express-cdn/instagram/thai-express-cdn-IG-30.jpg",
                "link": "https://www.instagram.com/p/B1Ozf5Ahl_0"
            }
        ];
        this.javaUCdnMedia = [
            {
                "image": "../../assets/images/java-u-cdn/instagram/java-u-IG-1.jpg",
                "link": "https://www.instagram.com/p/CBE-vJpDjsn"
            },

            {
                "image": "../../assets/images/java-u-cdn/instagram/java-u-IG-2.jpg",
                "link": "https://www.instagram.com/p/CBJiy5FjT_P"
            },

            {
                "image": "../../assets/images/java-u-cdn/instagram/java-u-IG-3.jpg",
                "link": "https://www.instagram.com/p/CBJx-iFjEKL"
            },

            {
                "image": "../../assets/images/java-u-cdn/instagram/java-u-IG-4.jpg",
                "link": "https://www.instagram.com/p/CBaoXrAJOXu"
            },
            {
                "image": "../../assets/images/java-u-cdn/instagram/java-u-IG-5.jpg",
                "link": "https://www.instagram.com/p/CBn4IFQJ8CM"
            },
            {
                "image": "../../assets/images/java-u-cdn/instagram/java-u-IG-6.jpg",
                "link": "https://www.instagram.com/p/CBvnZVQpXdH"
            },
            {
                "image": "../../assets/images/java-u-cdn/instagram/java-u-IG-7.jpg",
                "link": "https://www.instagram.com/p/CCv8lixpj9t"
            },
            {
                "image": "../../assets/images/java-u-cdn/instagram/java-u-IG-8.jpg",
                "link": "https://www.instagram.com/p/CC1aXelpehu"
            },
            {
                "image": "../../assets/images/java-u-cdn/instagram/java-u-IG-9.jpg",
                "link": "https://www.instagram.com/p/CDl1mVcJipX"
            },
            {
                "image": "../../assets/images/java-u-cdn/instagram/java-u-IG-10.jpg",
                "link": "https://www.instagram.com/p/CHLYOBJJFxI"
            },
            {
                "image": "../../assets/images/java-u-cdn/instagram/java-u-IG-11.jpg",
                "link": "https://www.instagram.com/p/CIA84DypoA-"
            },
            {
                "image": "../../assets/images/java-u-cdn/instagram/java-u-IG-12.jpg",
                "link": "https://www.instagram.com/p/CIGiiuPJ_52"
            },
            {
                "image": "../../assets/images/java-u-cdn/instagram/java-u-IG-13.jpg",
                "link": "https://www.instagram.com/p/CIij5d_p16T"
            },
            {
                "image": "../../assets/images/java-u-cdn/instagram/java-u-IG-14.jpg",
                "link": "https://www.instagram.com/p/CIn_M5MJrRE"
            },
            {
                "image": "../../assets/images/java-u-cdn/instagram/java-u-IG-15.jpg",
                "link": "https://www.instagram.com/p/CIqe_3WpLng"
            },
            {
                "image": "../../assets/images/java-u-cdn/instagram/java-u-IG-16.jpg",
                "link": "https://www.instagram.com/p/CJrJ6CTpEpW"
            },
            {
                "image": "../../assets/images/java-u-cdn/instagram/java-u-IG-17.jpg",
                "link": "https://www.instagram.com/p/CJvsmnLJPUw"
            },
            {
                "image": "../../assets/images/java-u-cdn/instagram/java-u-IG-18.jpg",
                "link": "https://www.instagram.com/p/CJ09157pvmM"
            }
        ];
        this.mcgillPizzaMedia = [
            {
                "image": "../../assets/images/mcgill-pizza/instagram/mcgill-pizza-IG-1.jpg",
                "link": "https://www.instagram.com/p/CEFAaybHUG2"
            },
            {
                "image": "../../assets/images/mcgill-pizza/instagram/mcgill-pizza-IG-2.jpg",
                "link": "https://www.instagram.com/p/CEKJtOzHOfe"
            },
            {
                "image": "../../assets/images/mcgill-pizza/instagram/mcgill-pizza-IG-3.jpg",
                "link": "https://www.instagram.com/p/CEW4pXsHBnd"
            },
            {
                "image": "../../assets/images/mcgill-pizza/instagram/mcgill-pizza-IG-4.jpg",
                "link": "https://www.instagram.com/p/CEo8LPyFZFs"
            },
            {
                "image": "../../assets/images/mcgill-pizza/instagram/mcgill-pizza-IG-5.jpg",
                "link": "https://www.instagram.com/p/CFXYYOBBfgF"
            },
            {
                "image": "../../assets/images/mcgill-pizza/instagram/mcgill-pizza-IG-6.jpg",
                "link": "https://www.instagram.com/p/CHvkFLvBZGU"
            }
        ];
        this.yuanMedia = [
            {
                "image": "../../assets/images/yuan/instagram/yuan-IG-1.jpg",
                "link": "https://www.instagram.com/p/CESNo3tHIpr"
            },
            {
                "image": "../../assets/images/yuan/instagram/yuan-IG-2.jpg",
                "link": "https://www.instagram.com/p/CDpz3V8nDP7"
            },
            {
                "image": "../../assets/images/yuan/instagram/yuan-IG-3.jpg",
                "link": "https://www.instagram.com/p/CC8etsUH5Ky"
            },
            {
                "image": "../../assets/images/yuan/instagram/yuan-IG-4.jpg",
                "link": "https://www.instagram.com/p/Bc2J4RBlAnj"
            },
            {
                "image": "../../assets/images/yuan/instagram/yuan-IG-5.jpg",
                "link": "https://www.instagram.com/p/Bbkk8rCFYOh"
            },
            {
                "image": "../../assets/images/yuan/instagram/yuan-IG-6.jpg",
                "link": "https://www.instagram.com/p/BbfoqLClt5S"
            },
            {
                "image": "../../assets/images/yuan/instagram/yuan-IG-7.jpg",
                "link": "https://www.instagram.com/p/BbSeWXalVM8"
            },
            {
                "image": "../../assets/images/yuan/instagram/yuan-IG-8.jpg",
                "link": "https://www.instagram.com/p/BaklOFxlugj"
            },
            {
                "image": "../../assets/images/yuan/instagram/yuan-IG-9.jpg",
                "link": "https://www.instagram.com/p/BaKSHpRF1sl"
            },
            {
                "image": "../../assets/images/yuan/instagram/yuan-IG-10.jpg",
                "link": "https://www.instagram.com/p/BaFNJi_lR94"
            },
            {
                "image": "../../assets/images/yuan/instagram/yuan-IG-11.jpg",
                "link": "https://www.instagram.com/p/BZwwIlRFYpZ"
            },
            {
                "image": "../../assets/images/yuan/instagram/yuan-IG-12.jpg",
                "link": "https://www.instagram.com/p/BZUMZU1l25P"
            },
            {
                "image": "../../assets/images/yuan/instagram/yuan-IG-13.jpg",
                "link": "https://www.instagram.com/p/BZQ6C02F3jx"
            },
            {
                "image": "../../assets/images/yuan/instagram/yuan-IG-14.jpg",
                "link": "https://www.instagram.com/p/BX6QzCWFTE7"
            },
            {
                "image": "../../assets/images/yuan/instagram/yuan-IG-15.jpg",
                "link": "https://www.instagram.com/p/BXrT6LslsbY"
            },
            {
                "image": "../../assets/images/yuan/instagram/yuan-IG-16.jpg",
                "link": "https://www.instagram.com/p/BWyXtxoFbtI"
            },
            {
                "image": "../../assets/images/yuan/instagram/yuan-IG-17.jpg",
                "link": "https://www.instagram.com/p/BWgA6CUl24x"
            },
            {
                "image": "../../assets/images/yuan/instagram/yuan-IG-18.jpg",
                "link": "https://www.instagram.com/p/BVpKzfXFyGt"
            },
            {
                "image": "../../assets/images/yuan/instagram/yuan-IG-19.jpg",
                "link": "https://www.instagram.com/p/BVVt8GiF0aa"
            },
            {
                "image": "../../assets/images/yuan/instagram/yuan-IG-20.jpg",
                "link": "https://www.instagram.com/p/BVA9MLOFDFg"
            },
            {
                "image": "../../assets/images/yuan/instagram/yuan-IG-21.jpg",
                "link": "https://www.instagram.com/p/BUsa32plTVM"
            },
            {
                "image": "../../assets/images/yuan/instagram/yuan-IG-22.jpg",
                "link": "https://www.instagram.com/p/BTo6XlglhlF"
            },
            {
                "image": "../../assets/images/yuan/instagram/yuan-IG-23.jpg",
                "link": "https://www.instagram.com/p/BTmtgi1lczS"
            },
            {
                "image": "../../assets/images/yuan/instagram/yuan-IG-24.jpg",
                "link": "https://www.instagram.com/p/BSO4IROga42"
            },
            {
                "image": "../../assets/images/yuan/instagram/yuan-IG-25.jpg",
                "link": "https://www.instagram.com/p/BSEG00BAkYy"
            },
            {
                "image": "../../assets/images/yuan/instagram/yuan-IG-26.jpg",
                "link": "https://www.instagram.com/p/BSCNpiKgdzO"
            },
            {
                "image": "../../assets/images/yuan/instagram/yuan-IG-27.jpg",
                "link": "https://www.instagram.com/p/BPvQdThAMIg"
            },
            {
                "image": "../../assets/images/yuan/instagram/yuan-IG-28.jpg",
                "link": "https://www.instagram.com/p/BONL96-gx5q"
            },
            {
                "image": "../../assets/images/yuan/instagram/yuan-IG-29.jpg",
                "link": "https://www.instagram.com/p/BN1uhPthVWw"
            },
            {
                "image": "../../assets/images/yuan/instagram/yuan-IG-30.jpg",
                "link": "https://www.instagram.com/p/BNch3n8BQxj"
            },
        ];
        this.segretaMedia = [
            {
                "image": "../../assets/images/segreta/instagram/segreta-IG-1.jpg",
                "link": "https://www.instagram.com/p/CNu7qaZjF6D"
            },
            {
                "image": "../../assets/images/segreta/instagram/segreta-IG-2.jpg",
                "link": "https://www.instagram.com/p/CNnUPvhjVvD"
            },
            {
                "image": "../../assets/images/segreta/instagram/segreta-IG-3.jpg",
                "link": "https://www.instagram.com/p/CNdDwarDzlr"
            },
            {
                "image": "../../assets/images/segreta/instagram/segreta-IG-4.jpg",
                "link": "https://www.instagram.com/p/CNVqz_DJBO7"
            },
            {
                "image": "../../assets/images/segreta/instagram/segreta-IG-5.jpg",
                "link": "https://www.instagram.com/p/CM9w0GuDp75"
            },
            {
                "image": "../../assets/images/segreta/instagram/segreta-IG-6.jpg",
                "link": "https://www.instagram.com/p/CMzbCOQjPQE"
            },
            {
                "image": "../../assets/images/segreta/instagram/segreta-IG-7.jpg",
                "link": "https://www.instagram.com/p/CMhe0ARDYeq"
            },
            {
                "image": "../../assets/images/segreta/instagram/segreta-IG-8.jpg",
                "link": "https://www.instagram.com/p/CMKz4zrjVzK"
            },
            {
                "image": "../../assets/images/segreta/instagram/segreta-IG-9.jpg",
                "link": "https://www.instagram.com/p/CMAZDOMjXLs"
            },
            {
                "image": "../../assets/images/segreta/instagram/segreta-IG-10.jpg",
                "link": "https://www.instagram.com/p/CL6ryIsjBK0"
            },
            {
                "image": "../../assets/images/segreta/instagram/segreta-IG-11.jpg",
                "link": "https://www.instagram.com/p/CL1rLm0j8AI"
            },
            {
                "image": "../../assets/images/segreta/instagram/segreta-IG-12.jpg",
                "link": "https://www.instagram.com/p/CLus9FojQxi"
            },
            {
                "image": "../../assets/images/segreta/instagram/segreta-IG-13.jpg",
                "link": "https://www.instagram.com/p/CLptHQlDWrF"
            },
            {
                "image": "../../assets/images/segreta/instagram/segreta-IG-14.jpg",
                "link": "https://www.instagram.com/p/CLo0Xa2DVaP"
            },
            {
                "image": "../../assets/images/segreta/instagram/segreta-IG-15.jpg",
                "link": "https://www.instagram.com/p/CDZQn7hDvrF"
            },
            {
                "image": "../../assets/images/segreta/instagram/segreta-IG-16.jpg",
                "link": "https://www.instagram.com/p/CDUKnYhj87S"
            },
            {
                "image": "../../assets/images/segreta/instagram/segreta-IG-17.jpg",
                "link": "https://www.instagram.com/p/CB8evKzjLM4"
            },
            {
                "image": "../../assets/images/segreta/instagram/segreta-IG-18.jpg",
                "link": "https://www.instagram.com/p/CB3H97pjP_X"
            },
            {
                "image": "../../assets/images/segreta/instagram/segreta-IG-19.jpg",
                "link": "https://www.instagram.com/p/CBOGRldDa3X"
            },
            {
                "image": "../../assets/images/segreta/instagram/segreta-IG-20.jpg",
                "link": "https://www.instagram.com/p/CBBne6LDCuI"
            },
            {
                "image": "../../assets/images/segreta/instagram/segreta-IG-21.jpg",
                "link": "https://www.instagram.com/p/CAsvkRcD_pc"
            },
            {
                "image": "../../assets/images/segreta/instagram/segreta-IG-22.jpg",
                "link": "https://www.instagram.com/p/CAqheGsjf5W"
            },
            {
                "image": "../../assets/images/segreta/instagram/segreta-IG-23.jpg",
                "link": "https://www.instagram.com/p/CAkxFlQDtAC"
            },
            {
                "image": "../../assets/images/segreta/instagram/segreta-IG-24.jpg",
                "link": "https://www.instagram.com/p/CAiNybljssw"
            },
            {
                "image": "../../assets/images/segreta/instagram/segreta-IG-25.jpg",
                "link": "https://www.instagram.com/p/CAYwn0ejEz7"
            },
            {
                "image": "../../assets/images/segreta/instagram/segreta-IG-26.jpg",
                "link": "https://www.instagram.com/p/CATSuW7Dsi-"
            },
            {
                "image": "../../assets/images/segreta/instagram/segreta-IG-27.jpg",
                "link": "https://www.instagram.com/p/CALDCGUjW8A"
            },
            {
                "image": "../../assets/images/segreta/instagram/segreta-IG-28.jpg",
                "link": "https://www.instagram.com/p/CAGBQDwjc8H"
            },
            {
                "image": "../../assets/images/segreta/instagram/segreta-IG-29.jpg",
                "link": "https://www.instagram.com/p/B_sHaLVDnwo"
            },
            {
                "image": "../../assets/images/segreta/instagram/segreta-IG-30.jpg",
                "link": "https://www.instagram.com/p/B_nbvLJDHk9"
            }
        ];
        this.omltMedia = [
            {
                "image": "../../assets/images/omlt/instagram/omlt-IG-1.jpeg",
                "link": "https://www.instagram.com/p/CNP2KjlHVr3/"
            },
            {
                "image": "../../assets/images/omlt/instagram/omlt-IG-2.jpeg",
                "link": "https://www.instagram.com/p/CNItySondvL/"
            },
            {
                "image": "../../assets/images/omlt/instagram/omlt-IG-3.jpeg",
                "link": "https://www.instagram.com/p/CMC-ZMgnIPf/"
            },
            {
                "image": "../../assets/images/omlt/instagram/omlt-IG-4.jpeg",
                "link": "https://www.instagram.com/p/CKzukHwnE-S/"
            },
            {
                "image": "../../assets/images/omlt/instagram/omlt-IG-5.jpeg",
                "link": "https://www.instagram.com/p/CJd6q59neex/"
            },
            {
                "image": "../../assets/images/omlt/instagram/omlt-IG-6.jpeg",
                "link": "https://www.instagram.com/p/CJG53GgnRCu/"
            },
            {
                "image": "../../assets/images/omlt/instagram/omlt-IG-7.jpeg",
                "link": "https://www.instagram.com/p/CI6vpawHvFo/"
            },
            {
                "image": "../../assets/images/omlt/instagram/omlt-IG-9.jpeg",
                "link": "https://www.instagram.com/p/CIJ0wimHwhV/"
            },
            {
                "image": "../../assets/images/omlt/instagram/omlt-IG-10.jpeg",
                "link": "https://www.instagram.com/p/CIG6PV9H2rE/"
            },
            {
                "image": "../../assets/images/omlt/instagram/omlt-IG-11.jpeg",
                "link": "https://www.instagram.com/p/CIGpNW8nQ-K/"
            },
            {
                "image": "../../assets/images/omlt/instagram/omlt-IG-12.jpeg",
                "link": "https://www.instagram.com/p/CIElnK8HaJ0/"
            },
            {
                "image": "../../assets/images/omlt/instagram/omlt-IG-13.jpeg",
                "link": "https://www.instagram.com/p/CHyJIAfHjsi/"
            },
            {
                "image": "../../assets/images/omlt/instagram/omlt-IG-14.jpeg",
                "link": "https://www.instagram.com/p/CHk-yA4nD73/"
            },
            {
                "image": "../../assets/images/omlt/instagram/omlt-IG-15.jpeg",
                "link": "https://www.instagram.com/p/CHgZun7HxOu/"
            },
            {
                "image": "../../assets/images/omlt/instagram/omlt-IG-16.jpeg",
                "link": "https://www.instagram.com/p/CHgYdbZHv-N/"
            },
            {
                "image": "../../assets/images/omlt/instagram/omlt-IG-17.jpeg",
                "link": "https://www.instagram.com/p/CHNmSEDgayF/"
            },
            {
                "image": "../../assets/images/omlt/instagram/omlt-IG-18.jpeg",
                "link": "https://www.instagram.com/p/CHLtS6-AQKP/"
            },
            {
                "image": "../../assets/images/omlt/instagram/omlt-IG-19.jpeg",
                "link": "https://www.instagram.com/p/CHJX5APA4QD/"
            },
            {
                "image": "../../assets/images/omlt/instagram/omlt-IG-20.jpeg",
                "link": "https://www.instagram.com/p/CG8X6dGA3sZ/"
            },
            {
                "image": "../../assets/images/omlt/instagram/omlt-IG-21.jpeg",
                "link": "https://www.instagram.com/p/CG5rIpiAQNh/"
            },
            {
                "image": "../../assets/images/omlt/instagram/omlt-IG-22.jpeg",
                "link": "https://www.instagram.com/p/CG0eBc8gBpO/"
            },
            {
                "image": "../../assets/images/omlt/instagram/omlt-IG-23.jpeg",
                "link": "https://www.instagram.com/p/CGxYLyygx-j/"
            },
            {
                "image": "../../assets/images/omlt/instagram/omlt-IG-25.jpeg",
                "link": "https://www.instagram.com/p/CGnigYzF3JV/"
            },
            {
                "image": "../../assets/images/omlt/instagram/omlt-IG-26.jpeg",
                "link": "https://www.instagram.com/p/CGnauiClHkm/"
            }
        ];

        this.quesadaCdnMedia = [
            {
                "image": "../../assets/images/quesada-cdn/instagram/quesada-cdn-IG-1.jpg",
                "link": "https://www.instagram.com/p/CMxVTlvFOvf"
            },
            {
                "image": "../../assets/images/quesada-cdn/instagram/quesada-cdn-IG-2.jpg",
                "link": "https://www.instagram.com/p/CKVCA8oFJdw"
            },
            {
                "image": "../../assets/images/quesada-cdn/instagram/quesada-cdn-IG-3.jpg",
                "link": "https://www.instagram.com/p/CJ9IEGzH8CU"
            },
            {
                "image": "../../assets/images/quesada-cdn/instagram/quesada-cdn-IG-4.jpg",
                "link": "https://www.instagram.com/p/CJZE7jXlTQI"
            },
            {
                "image": "../../assets/images/quesada-cdn/instagram/quesada-cdn-IG-5.jpg",
                "link": "https://www.instagram.com/p/CIQsBp-lbUc"
            },
            {
                "image": "../../assets/images/quesada-cdn/instagram/quesada-cdn-IG-6.jpg",
                "link": "https://www.instagram.com/p/CIHU6XWFYJi"
            },
            {
                "image": "../../assets/images/quesada-cdn/instagram/quesada-cdn-IG-7.jpg",
                "link": "https://www.instagram.com/p/CGIzfc8h8MH"
            },
            {
                "image": "../../assets/images/quesada-cdn/instagram/quesada-cdn-IG-8.jpg",
                "link": "https://www.instagram.com/p/CFu-GHKBmlM"
            },
            {
                "image": "../../assets/images/quesada-cdn/instagram/quesada-cdn-IG-9.jpg",
                "link": "https://www.instagram.com/p/CDzDXSXh64h"
            },
            {
                "image": "../../assets/images/quesada-cdn/instagram/quesada-cdn-IG-10.jpg",
                "link": "https://www.instagram.com/p/B_5K8w6BYpw"
            },
            {
                "image": "../../assets/images/quesada-cdn/instagram/quesada-cdn-IG-11.jpg",
                "link": "https://www.instagram.com/p/B9l9LC0hbTk"
            },
            {
                "image": "../../assets/images/quesada-cdn/instagram/quesada-cdn-IG-12.jpg",
                "link": "https://www.instagram.com/p/B9CANr1hIMh"
            },
            {
                "image": "../../assets/images/quesada-cdn/instagram/quesada-cdn-IG-13.jpg",
                "link": "https://www.instagram.com/p/B8_cp0ih740"
            },
            {
                "image": "../../assets/images/quesada-cdn/instagram/quesada-cdn-IG-14.jpg",
                "link": "https://www.instagram.com/p/B86yxSeBe9X"
            },
            {
                "image": "../../assets/images/quesada-cdn/instagram/quesada-cdn-IG-15.jpg",
                "link": "https://www.instagram.com/p/B8ypjluh2UU"
            },
            {
                "image": "../../assets/images/quesada-cdn/instagram/quesada-cdn-IG-16.jpg",
                "link": "https://www.instagram.com/p/B8ebiQYh8NW"
            },
            {
                "image": "../../assets/images/quesada-cdn/instagram/quesada-cdn-IG-17.jpg",
                "link": "https://www.instagram.com/p/B8Y4-lvhpuQ"
            },
            {
                "image": "../../assets/images/quesada-cdn/instagram/quesada-cdn-IG-18.jpg",
                "link": "https://www.instagram.com/p/B8RgCWoh4ia"
            },
            {
                "image": "../../assets/images/quesada-cdn/instagram/quesada-cdn-IG-19.jpg",
                "link": "https://www.instagram.com/p/B8He8mWBk1z"
            },
            {
                "image": "../../assets/images/quesada-cdn/instagram/quesada-cdn-IG-20.jpg",
                "link": "https://www.instagram.com/p/B7gT8twh1PJ"
            },
            {
                "image": "../../assets/images/quesada-cdn/instagram/quesada-cdn-IG-21.jpg",
                "link": "https://www.instagram.com/p/B7Y2FgaBS-3"
            },
            {
                "image": "../../assets/images/quesada-cdn/instagram/quesada-cdn-IG-22.jpg",
                "link": "https://www.instagram.com/p/B7RSUg9hW4u"
            },
            {
                "image": "../../assets/images/quesada-cdn/instagram/quesada-cdn-IG-23.jpg",
                "link": "https://www.instagram.com/p/B7JzozzBrVT"
            },
            {
                "image": "../../assets/images/quesada-cdn/instagram/quesada-cdn-IG-24.jpg",
                "link": "https://www.instagram.com/p/B63KrVEhgAr"
            },
            {
                "image": "../../assets/images/quesada-cdn/instagram/quesada-cdn-IG-25.jpg",
                "link": "https://www.instagram.com/p/B6lxzOkB1-o"
            },
            {
                "image": "../../assets/images/quesada-cdn/instagram/quesada-cdn-IG-26.jpg",
                "link": "https://www.instagram.com/p/B6N3aCoh_hs"
            },
            {
                "image": "../../assets/images/quesada-cdn/instagram/quesada-cdn-IG-27.jpg",
                "link": "https://www.instagram.com/p/B6LVo5sBJeE"
            },
            {
                "image": "../../assets/images/quesada-cdn/instagram/quesada-cdn-IG-28.jpg",
                "link": "https://www.instagram.com/p/B6Is8ZmhVeC"
            },
            {
                "image": "../../assets/images/quesada-cdn/instagram/quesada-cdn-IG-29.jpg",
                "link": "https://www.instagram.com/p/B58TNkZB9qx"
            },
            {
                "image": "../../assets/images/quesada-cdn/instagram/quesada-cdn-IG-30.jpg",
                "link": "https://www.instagram.com/p/B5nUgvtBvu_"
            }
        ];
        this.oiseauxDePassageMedia = [
            {
                "image": "../../assets/images/oiseaux-de-passage/instagram/oiseaux-de-passage-IG-1.jpg",
                "link": "https://www.instagram.com/p/CHTotaQnyZm"
            },
            {
                "image": "../../assets/images/oiseaux-de-passage/instagram/oiseaux-de-passage-IG-2.jpg",
                "link": "https://www.instagram.com/p/CHBo1H3HVwN"
            },
            {
                "image": "../../assets/images/oiseaux-de-passage/instagram/oiseaux-de-passage-IG-3.jpg",
                "link": "https://www.instagram.com/p/CFkcMD0p4UJ"
            },
            {
                "image": "../../assets/images/oiseaux-de-passage/instagram/oiseaux-de-passage-IG-4.jpg",
                "link": "https://www.instagram.com/p/CE6peSShWIT"
            },
            {
                "image": "../../assets/images/oiseaux-de-passage/instagram/oiseaux-de-passage-IG-5.jpg",
                "link": "https://www.instagram.com/p/CESkG5xHsNZ"
            },
            {
                "image": "../../assets/images/oiseaux-de-passage/instagram/oiseaux-de-passage-IG-6.jpg",
                "link": "https://www.instagram.com/p/CECnh06HuYf"
            },
            {
                "image": "../../assets/images/oiseaux-de-passage/instagram/oiseaux-de-passage-IG-7.jpg",
                "link": "https://www.instagram.com/p/CD7ciXlnlaP"
            },
            {
                "image": "../../assets/images/oiseaux-de-passage/instagram/oiseaux-de-passage-IG-8.jpg",
                "link": "https://www.instagram.com/p/CD6l6A0HCYr"
            },
            {
                "image": "../../assets/images/oiseaux-de-passage/instagram/oiseaux-de-passage-IG-9.jpg",
                "link": "https://www.instagram.com/p/CDtY1_1n1X8"
            },
            {
                "image": "../../assets/images/oiseaux-de-passage/instagram/oiseaux-de-passage-IG-10.jpg",
                "link": "https://www.instagram.com/p/CDkQiiiBUsF"
            },
            {
                "image": "../../assets/images/oiseaux-de-passage/instagram/oiseaux-de-passage-IG-11.jpg",
                "link": "https://www.instagram.com/p/CDjbwHBhnz2"
            },
            {
                "image": "../../assets/images/oiseaux-de-passage/instagram/oiseaux-de-passage-IG-12.jpg",
                "link": "https://www.instagram.com/p/CDY7BOehRAz"
            }
        ];
        this.burgerBrosMedia =
            [{
                "image": "../../assets/images/burger-bros/instagram/burger-bros-IG-1.jpg",
                "link": "https://www.instagram.com/p/CKhh40fjK00"
            },
            {
                "image": "../../assets/images/burger-bros/instagram/burger-bros-IG-2.jpg",
                "link": "https://www.instagram.com/p/CHbbjwdD_LN"
            },
            {
                "image": "../../assets/images/burger-bros/instagram/burger-bros-IG-3.jpg",
                "link": "https://www.instagram.com/p/CHONNOtjaF0"
            },
            {
                "image": "../../assets/images/burger-bros/instagram/burger-bros-IG-4.jpg",
                "link": "https://www.instagram.com/p/CGOK3ymD54u"
            },
            {
                "image": "../../assets/images/burger-bros/instagram/burger-bros-IG-5.jpg",
                "link": "https://www.instagram.com/p/CFflqMSD77v"
            },
            {
                "image": "../../assets/images/burger-bros/instagram/burger-bros-IG-6.jpg",
                "link": "https://www.instagram.com/p/CDNoEu0hQK3"
            },
            {
                "image": "../../assets/images/burger-bros/instagram/burger-bros-IG-7.jpg",
                "link": "https://www.instagram.com/p/CDG1J3AhosS"
            },
            {
                "image": "../../assets/images/burger-bros/instagram/burger-bros-IG-8.jpg",
                "link": "https://www.instagram.com/p/CDE5wugB1XQ"
            },
            {
                "image": "../../assets/images/burger-bros/instagram/burger-bros-IG-9.jpg",
                "link": "https://www.instagram.com/p/CCzu8PwhNmX"
            },
            {
                "image": "../../assets/images/burger-bros/instagram/burger-bros-IG-10.jpg",
                "link": "https://www.instagram.com/p/CCztt9VBJ06"
            },
            {
                "image": "../../assets/images/burger-bros/instagram/burger-bros-IG-11.jpg",
                "link": "https://www.instagram.com/p/CCztrDphF4k"
            },
            {
                "image": "../../assets/images/burger-bros/instagram/burger-bros-IG-12.jpg",
                "link": "https://www.instagram.com/p/CCztki2B-sm"
            },
            {
                "image": "../../assets/images/burger-bros/instagram/burger-bros-IG-13.jpg",
                "link": "https://www.instagram.com/p/CCztUHMBdwk"
            },
            {
                "image": "../../assets/images/burger-bros/instagram/burger-bros-IG-14.jpg",
                "link": "https://www.instagram.com/p/CC5Ef9IBmiZ"
            },
            {
                "image": "../../assets/images/burger-bros/instagram/burger-bros-IG-15.jpg",
                "link": "https://www.instagram.com/p/CCztQK_hOuj"
            },
            {
                "image": "../../assets/images/burger-bros/instagram/burger-bros-IG-16.jpg",
                "link": "https://www.instagram.com/p/CCztIEmhU8Y"
            },
            {
                "image": "../../assets/images/burger-bros/instagram/burger-bros-IG-17.jpg",
                "link": "https://www.instagram.com/p/CCztCCChmw8"
            },
            {
                "image": "../../assets/images/burger-bros/instagram/burger-bros-IG-18.jpg",
                "link": "https://www.instagram.com/p/CCzsh9sBwRV"
            }
            ];
        this.juliansMedia = [
            {
                "image": "../../assets/images/julians/instagram/julians-IG-1.jpg",
                "link": "https://www.instagram.com/p/COSyPlNpjqQ"
            },
            {
                "image": "../../assets/images/julians/instagram/julians-IG-2.jpg",
                "link": "https://www.instagram.com/p/CONmbfeL-Oi"
            },
            {
                "image": "../../assets/images/julians/instagram/julians-IG-3.jpg",
                "link": "https://www.instagram.com/p/CN8NZomLOmy"
            },
            {
                "image": "../../assets/images/julians/instagram/julians-IG-4.jpg",
                "link": "https://www.instagram.com/p/CNyP9MpnsUu"
            },
            {
                "image": "../../assets/images/julians/instagram/julians-IG-5.jpg",
                "link": "https://www.instagram.com/p/CNuvIYjgVEx"
            },
            {
                "image": "../../assets/images/julians/instagram/julians-IG-6.jpg",
                "link": "https://www.instagram.com/p/CNnOqs1ny-G"
            },
            {
                "image": "../../assets/images/julians/instagram/julians-IG-7.jpg",
                "link": "https://www.instagram.com/p/CNVQ5QFMbGk"
            },
            {
                "image": "../../assets/images/julians/instagram/julians-IG-8.jpg",
                "link": "https://www.instagram.com/p/CM8NkLWHSt7"
            },
            {
                "image": "../../assets/images/julians/instagram/julians-IG-9.jpg",
                "link": "https://www.instagram.com/p/CMxK44vADMx"
            },
            {
                "image": "../../assets/images/julians/instagram/julians-IG-10.jpg",
                "link": "https://www.instagram.com/p/CMfx9MXHX3y"
            },
            {
                "image": "../../assets/images/julians/instagram/julians-IG-11.jpg",
                "link": "https://www.instagram.com/p/CMYG_bXLTHl"
            },
            {
                "image": "../../assets/images/julians/instagram/julians-IG-12.jpg",
                "link": "https://www.instagram.com/p/CMUuthnMaJF"
            },
            {
                "image": "../../assets/images/julians/instagram/julians-IG-13.jpg",
                "link": "https://www.instagram.com/p/CMFWia3A_yk"
            },
            {
                "image": "../../assets/images/julians/instagram/julians-IG-14.jpg",
                "link": "https://www.instagram.com/p/CL7RF1Sn_h_"
            },
            {
                "image": "../../assets/images/julians/instagram/julians-IG-15.jpg",
                "link": "https://www.instagram.com/p/CLwrDZvrcWM"
            },
            {
                "image": "../../assets/images/julians/instagram/julians-IG-16.jpg",
                "link": "https://www.instagram.com/p/CLpzj0TH6Y8"
            },
            {
                "image": "../../assets/images/julians/instagram/julians-IG-17.jpg",
                "link": "https://www.instagram.com/p/CLc2byln28C"
            },
            {
                "image": "../../assets/images/julians/instagram/julians-IG-18.jpg",
                "link": "https://www.instagram.com/p/CLXTPd9l6lY"
            },
            {
                "image": "../../assets/images/julians/instagram/julians-IG-19.jpg",
                "link": "https://www.instagram.com/p/CK6mVwMHyzF"
            },
            {
                "image": "../../assets/images/julians/instagram/julians-IG-20.jpg",
                "link": "https://www.instagram.com/p/CK4yK2THVL8"
            },
            {
                "image": "../../assets/images/julians/instagram/julians-IG-21.jpg",
                "link": "https://www.instagram.com/p/CKzLYhyj6o_"
            },
            {
                "image": "../../assets/images/julians/instagram/julians-IG-22.jpg",
                "link": "https://www.instagram.com/p/CKmsoVuHdds"
            },
            {
                "image": "../../assets/images/julians/instagram/julians-IG-23.jpg",
                "link": "https://www.instagram.com/p/CKhAxxhhn-D"
            },
            {
                "image": "../../assets/images/julians/instagram/julians-IG-24.jpg",
                "link": "https://www.instagram.com/p/CKcLyGSHBV_"
            },
            {
                "image": "../../assets/images/julians/instagram/julians-IG-25.jpg",
                "link": "https://www.instagram.com/p/CKWjO8lLkWy"
            },
            {
                "image": "../../assets/images/julians/instagram/julians-IG-26.jpg",
                "link": "https://www.instagram.com/p/CKHLlBQn3yO"
            },
            {
                "image": "../../assets/images/julians/instagram/julians-IG-27.jpg",
                "link": "https://www.instagram.com/p/CJ_UxijH43W"
            },
            {
                "image": "../../assets/images/julians/instagram/julians-IG-28.jpg",
                "link": "https://www.instagram.com/p/CJ4JHxijTT1"
            },
            {
                "image": "../../assets/images/julians/instagram/julians-IG-29.jpg",
                "link": "https://www.instagram.com/p/CJwr6XaHUVY"
            },
            {
                "image": "../../assets/images/julians/instagram/julians-IG-30.jpg",
                "link": "https://www.instagram.com/p/CJoEUcvLSrr"
            }
        ];
        this.spiceBrosMedia = [
            {
                "image": "../../assets/images/spicebros/instagram/spicebros-IG-1.jpg",
                "link": "https://www.instagram.com/p/COiKFHgjTuv"
            },
            {
                "image": "../../assets/images/spicebros/instagram/spicebros-IG-2.jpg",
                "link": "https://www.instagram.com/p/COJG5khDJ9S"
            },
            {
                "image": "../../assets/images/spicebros/instagram/spicebros-IG-3.jpg",
                "link": "https://www.instagram.com/p/CNm31vxjHIO"
            },
            {
                "image": "../../assets/images/spicebros/instagram/spicebros-IG-4.jpg",
                "link": "https://www.instagram.com/p/CNH4FErjzcQ"
            },
            {
                "image": "../../assets/images/spicebros/instagram/spicebros-IG-5.jpg",
                "link": "https://www.instagram.com/p/CMYr8YQDVbx"
            },
            {
                "image": "../../assets/images/spicebros/instagram/spicebros-IG-6.jpg",
                "link": "https://www.instagram.com/p/CMCr-deDmET"
            },
            {
                "image": "../../assets/images/spicebros/instagram/spicebros-IG-7.jpg",
                "link": "https://www.instagram.com/p/CLsOLk7j62X"
            },
            {
                "image": "../../assets/images/spicebros/instagram/spicebros-IG-8.jpg",
                "link": "https://www.instagram.com/p/CLQAwhnjvck"
            },
            {
                "image": "../../assets/images/spicebros/instagram/spicebros-IG-9.jpg",
                "link": "https://www.instagram.com/p/CK6c7Otjd5L"
            },
            {
                "image": "../../assets/images/spicebros/instagram/spicebros-IG-10.jpg",
                "link": "https://www.instagram.com/p/CKZbEZWjDjy"
            },
            {
                "image": "../../assets/images/spicebros/instagram/spicebros-IG-11.jpg",
                "link": "https://www.instagram.com/p/CIWLHzFjjIQ"
            },
            {
                "image": "../../assets/images/spicebros/instagram/spicebros-IG-12.jpg",
                "link": "https://www.instagram.com/p/CHqLcK7j0iK"
            },
            {
                "image": "../../assets/images/spicebros/instagram/spicebros-IG-13.jpg",
                "link": "https://www.instagram.com/p/CHRgEQQjVhq"
            },
            {
                "image": "../../assets/images/spicebros/instagram/spicebros-IG-14.jpg",
                "link": "https://www.instagram.com/p/CHDYK9PjmHV"
            },
            {
                "image": "../../assets/images/spicebros/instagram/spicebros-IG-15.jpg",
                "link": "https://www.instagram.com/p/CGqrgtMjvKc"
            },
            {
                "image": "../../assets/images/spicebros/instagram/spicebros-IG-16.jpg",
                "link": "https://www.instagram.com/p/CGIXqPEj0vj"
            },
            {
                "image": "../../assets/images/spicebros/instagram/spicebros-IG-17.jpg",
                "link": "https://www.instagram.com/p/CFtCC0ujYRE"
            },
            {
                "image": "../../assets/images/spicebros/instagram/spicebros-IG-18.jpg",
                "link": "https://www.instagram.com/p/CFMyemSjULK"
            },
            {
                "image": "../../assets/images/spicebros/instagram/spicebros-IG-19.jpg",
                "link": "https://www.instagram.com/p/CEoqqpDjt6L"
            },
            {
                "image": "../../assets/images/spicebros/instagram/spicebros-IG-20.jpg",
                "link": "https://www.instagram.com/p/CDWm5apjb_L"
            },
            {
                "image": "../../assets/images/spicebros/instagram/spicebros-IG-21.jpg",
                "link": "https://www.instagram.com/p/CCOWvG4jdYq"
            },
            {
                "image": "../../assets/images/spicebros/instagram/spicebros-IG-22.jpg",
                "link": "https://www.instagram.com/p/CBYKHtTDJRm"
            },
            {
                "image": "../../assets/images/spicebros/instagram/spicebros-IG-23.jpg",
                "link": "https://www.instagram.com/p/CA1hJnFjNTx"
            },
            {
                "image": "../../assets/images/spicebros/instagram/spicebros-IG-24.jpg",
                "link": "https://www.instagram.com/p/CAQxZqkjm0Z"
            },
            {
                "image": "../../assets/images/spicebros/instagram/spicebros-IG-25.jpg",
                "link": "https://www.instagram.com/p/CAIWt4WDzBA"
            },
            {
                "image": "../../assets/images/spicebros/instagram/spicebros-IG-26.jpg",
                "link": "https://www.instagram.com/p/B-kJHCmDFlX"
            },
            {
                "image": "../../assets/images/spicebros/instagram/spicebros-IG-27.jpg",
                "link": "https://www.instagram.com/p/B-PfB3QjT8b"
            },
            {
                "image": "../../assets/images/spicebros/instagram/spicebros-IG-28.jpg",
                "link": "https://www.instagram.com/p/B9iTMmPDvwb"
            },
            {
                "image": "../../assets/images/spicebros/instagram/spicebros-IG-29.jpg",
                "link": "https://www.instagram.com/p/B9FdH3yJFQH"
            },
            {
                "image": "../../assets/images/spicebros/instagram/spicebros-IG-30.jpg",
                "link": "https://www.instagram.com/p/B81jwjbDMLW"
            }
        ];
        this.santaLuciaMedia = [
            {
                "image": "../../assets/images/santa-lucia/instagram/santa-lucia-IG-1.jpg",
                "link": "https://www.instagram.com/p/CO3BbEaHKsc"
            },
            {
                "image": "../../assets/images/santa-lucia/instagram/santa-lucia-IG-2.jpg",
                "link": "https://www.instagram.com/p/CO0SAZSHiKq"
            },
            {
                "image": "../../assets/images/santa-lucia/instagram/santa-lucia-IG-3.jpg",
                "link": "https://www.instagram.com/p/COvOoKiHXgO"
            },
            {
                "image": "../../assets/images/santa-lucia/instagram/santa-lucia-IG-4.jpg",
                "link": "https://www.instagram.com/p/COsran1nKOA"
            },
            {
                "image": "../../assets/images/santa-lucia/instagram/santa-lucia-IG-5.jpg",
                "link": "https://www.instagram.com/p/COk8NqEH4Cs"
            },
            {
                "image": "../../assets/images/santa-lucia/instagram/santa-lucia-IG-6.jpg",
                "link": "https://www.instagram.com/p/COaqwGEnmts"
            },
            {
                "image": "../../assets/images/santa-lucia/instagram/santa-lucia-IG-7.jpg",
                "link": "https://www.instagram.com/p/COS7kUzHU9M"
            },
            {
                "image": "../../assets/images/santa-lucia/instagram/santa-lucia-IG-8.jpg",
                "link": "https://www.instagram.com/p/COQgaaEnufg"
            },
            {
                "image": "../../assets/images/santa-lucia/instagram/santa-lucia-IG-9.jpg",
                "link": "https://www.instagram.com/p/COLHgOJH-Kf"
            },
            {
                "image": "../../assets/images/santa-lucia/instagram/santa-lucia-IG-10.jpg",
                "link": "https://www.instagram.com/p/COI-wcaHx9e"
            },
            {
                "image": "../../assets/images/santa-lucia/instagram/santa-lucia-IG-11.jpg",
                "link": "https://www.instagram.com/p/COA6sOiH9Gc"
            },
            {
                "image": "../../assets/images/santa-lucia/instagram/santa-lucia-IG-12.jpg",
                "link": "https://www.instagram.com/p/CN5OWphnnGL"
            }
        ];
        this.oFourMedia = [
            {
                "image": "../../assets/images/o-four/instagram/o-four-IG-1.jpeg",
                "link": "https://www.instagram.com/p/CRqulhmBWr8"
            },
            {
                "image": "../../assets/images/o-four/instagram/o-four-IG-2.jpeg",
                "link": "https://www.instagram.com/p/CRHZu-mBDwT"
            },
            {
                "image": "../../assets/images/o-four/instagram/o-four-IG-3.jpeg",
                "link": "https://www.instagram.com/p/CQB-Ht_BwaV"
            },
            {
                "image": "../../assets/images/o-four/instagram/o-four-IG-4.jpeg",
                "link": "https://www.instagram.com/p/CPz7nsNh_-K"
            },
            {
                "image": "../../assets/images/o-four/instagram/o-four-IG-5.jpeg",
                "link": "https://www.instagram.com/p/CPoZspdBToM"
            },
            {
                "image": "../../assets/images/o-four/instagram/o-four-IG-6.jpeg",
                "link": "https://www.instagram.com/p/CM7nP7YB7bT"
            }
        ];

        this.karinMedia = [
            {
                "image": "../../assets/images/karin/instagram/karin-IG-1.jpg",
                "link": "https://www.instagram.com/p/CR7pvL-nhyk"
            },
            {
                "image": "../../assets/images/karin/instagram/karin-IG-2.jpg",
                "link": "https://www.instagram.com/p/CRXebLWn4Uh"
            },
            {
                "image": "../../assets/images/karin/instagram/karin-IG-3.jpg",
                "link": "https://www.instagram.com/p/CRWw7xHnHxH"
            },
            {
                "image": "../../assets/images/karin/instagram/karin-IG-4.jpg",
                "link": "https://www.instagram.com/p/CQm8qhSnxg7"
            },
            {
                "image": "../../assets/images/karin/instagram/karin-IG-5.jpg",
                "link": "https://www.instagram.com/p/CPa-xuDHqHp"
            },
            {
                "image": "../../assets/images/karin/instagram/karin-IG-6.jpg",
                "link": "https://www.instagram.com/p/CPMBlqYH8Wl"
            },
            {
                "image": "../../assets/images/karin/instagram/karin-IG-7.jpg",
                "link": "https://www.instagram.com/p/COwbIyHH2QR"
            },
            {
                "image": "../../assets/images/karin/instagram/karin-IG-8.jpg",
                "link": "https://www.instagram.com/p/COomObRn3jg"
            },
            {
                "image": "../../assets/images/karin/instagram/karin-IG-9.jpg",
                "link": "https://www.instagram.com/p/CNNpJrOnS-o"
            },
            {
                "image": "../../assets/images/karin/instagram/karin-IG-10.jpg",
                "link": "https://www.instagram.com/p/CMyBA0Jn2rw"
            },
            {
                "image": "../../assets/images/karin/instagram/karin-IG-11.jpg",
                "link": "https://www.instagram.com/p/CMQd9cPnLxu"
            },
            {
                "image": "../../assets/images/karin/instagram/karin-IG-12.jpg",
                "link": "https://www.instagram.com/p/CMQc0PKnqWm"
            },
            {
                "image": "../../assets/images/karin/instagram/karin-IG-13.jpg",
                "link": "https://www.instagram.com/p/CLkgyS4HrR5"
            },
            {
                "image": "../../assets/images/karin/instagram/karin-IG-14.jpg",
                "link": "https://www.instagram.com/p/CLbAnbcnw20"
            },
            {
                "image": "../../assets/images/karin/instagram/karin-IG-15.jpg",
                "link": "https://www.instagram.com/p/CLH8WiYHJYH"
            },
            {
                "image": "../../assets/images/karin/instagram/karin-IG-16.jpg",
                "link": "https://www.instagram.com/p/CK9oyC5HVaT"
            },
            {
                "image": "../../assets/images/karin/instagram/karin-IG-17.jpg",
                "link": "https://www.instagram.com/p/CKXJMfPn9-y"
            },
            {
                "image": "../../assets/images/karin/instagram/karin-IG-18.jpg",
                "link": "https://www.instagram.com/p/CKFxpOcH56T"
            },
            {
                "image": "../../assets/images/karin/instagram/karin-IG-19.jpg",
                "link": "https://www.instagram.com/p/CJrc0UyHsOU"
            },
            {
                "image": "../../assets/images/karin/instagram/karin-IG-20.jpg",
                "link": "https://www.instagram.com/p/CJKnnIDHc4I"
            },
            {
                "image": "../../assets/images/karin/instagram/karin-IG-21.jpg",
                "link": "https://www.instagram.com/p/CJKka8iH-Su"
            },
            {
                "image": "../../assets/images/karin/instagram/karin-IG-22.jpg",
                "link": "https://www.instagram.com/p/CJFj2yvnrzL"
            },
            {
                "image": "../../assets/images/karin/instagram/karin-IG-23.jpg",
                "link": "https://www.instagram.com/p/CHOicGCHuVh"
            },
            {
                "image": "../../assets/images/karin/instagram/karin-IG-24.jpg",
                "link": "https://www.instagram.com/p/CGD08SVn2qo"
            },
            {
                "image": "../../assets/images/karin/instagram/karin-IG-25.jpg",
                "link": "https://www.instagram.com/p/CGC0FrHn9yc"
            },
            {
                "image": "../../assets/images/karin/instagram/karin-IG-26.jpg",
                "link": "https://www.instagram.com/p/CBjWO93HtQ3"
            },
            {
                "image": "../../assets/images/karin/instagram/karin-IG-27.jpg",
                "link": "https://www.instagram.com/p/CBbpLqLHMAF"
            },
            {
                "image": "../../assets/images/karin/instagram/karin-IG-28.jpg",
                "link": "https://www.instagram.com/p/CBblIEQHVeg"
            },
            {
                "image": "../../assets/images/karin/instagram/karin-IG-29.jpg",
                "link": "https://www.instagram.com/p/CBWWrkvHceR"
            },
            {
                "image": "../../assets/images/karin/instagram/karin-IG-30.jpg",
                "link": "https://www.instagram.com/p/CA83J5uHdJj"
            }
        ];
        this.marcheMaremaMedia = [
            {
                "image": "../../assets/images/marche-marema/instagram/marche-marema-IG-1.jpg",
                "link": "https://www.instagram.com/p/CUDil1OtUTR"
            },
            {
                "image": "../../assets/images/marche-marema/instagram/marche-marema-IG-2.jpg",
                "link": "https://www.instagram.com/p/CUDikFtNi1K"
            },
            {
                "image": "../../assets/images/marche-marema/instagram/marche-marema-IG-3.jpg",
                "link": "https://www.instagram.com/p/CUDiii5NO6i"
            },
            {
                "image": "../../assets/images/marche-marema/instagram/marche-marema-IG-4.jpg",
                "link": "https://www.instagram.com/p/CUDihJytB4W"
            },
            {
                "image": "../../assets/images/marche-marema/instagram/marche-marema-IG-5.jpg",
                "link": "https://www.instagram.com/p/CTz2ylMNIqZ"
            },
            {
                "image": "../../assets/images/marche-marema/instagram/marche-marema-IG-6.jpg",
                "link": "https://www.instagram.com/p/CS-Ex6NNSFl"
            },
            {
                "image": "../../assets/images/marche-marema/instagram/marche-marema-IG-7.jpg",
                "link": "https://www.instagram.com/p/CS-EvtaNF1f"
            },
            {
                "image": "../../assets/images/marche-marema/instagram/marche-marema-IG-8.jpg",
                "link": "https://www.instagram.com/p/CS-EpZNttkj"
            },
            {
                "image": "../../assets/images/marche-marema/instagram/marche-marema-IG-9.jpg",
                "link": "https://www.instagram.com/p/CRzkNLCDI0Q"
            },
            {
                "image": "../../assets/images/marche-marema/instagram/marche-marema-IG-10.jpg",
                "link": "https://www.instagram.com/p/CRwwF1djoEt"
            },
            {
                "image": "../../assets/images/marche-marema/instagram/marche-marema-IG-11.jpg",
                "link": "https://www.instagram.com/p/CRwla7Gj7tp"
            },
            {
                "image": "../../assets/images/marche-marema/instagram/marche-marema-IG-12.jpg",
                "link": "https://www.instagram.com/p/CRwlS7MjgnQ"
            }];
        this.boiteVeganeMedia = [
            {
                "image": "../../assets/images/boite-vegane/instagram/boite-vegane-IG-1.jpg",
                "link": "https://www.instagram.com/p/CWelxE3rj1a"
            },
            {
                "image": "../../assets/images/boite-vegane/instagram/boite-vegane-IG-2.jpg",
                "link": "https://www.instagram.com/p/CV2-kDqMnlz"
            },
            {
                "image": "../../assets/images/boite-vegane/instagram/boite-vegane-IG-3.jpg",
                "link": "https://www.instagram.com/p/CVOL-bkro0s"
            },
            {
                "image": "../../assets/images/boite-vegane/instagram/boite-vegane-IG-4.jpg",
                "link": "https://www.instagram.com/p/CU6EzZ4LYLX"
            },
            {
                "image": "../../assets/images/boite-vegane/instagram/boite-vegane-IG-5.jpg",
                "link": "https://www.instagram.com/p/CT7aXYEttLD"
            },
            {
                "image": "../../assets/images/boite-vegane/instagram/boite-vegane-IG-6.jpg",
                "link": "https://www.instagram.com/p/CT0OIkaLv_V"
            },
            {
                "image": "../../assets/images/boite-vegane/instagram/boite-vegane-IG-7.jpg",
                "link": "https://www.instagram.com/p/CTe3DpDLw6x"
            },
            {
                "image": "../../assets/images/boite-vegane/instagram/boite-vegane-IG-8.jpg",
                "link": "https://www.instagram.com/p/CTP1gevHBmF"
            },
            {
                "image": "../../assets/images/boite-vegane/instagram/boite-vegane-IG-9.jpg",
                "link": "https://www.instagram.com/p/CSzcTirndVM"
            },
            {
                "image": "../../assets/images/boite-vegane/instagram/boite-vegane-IG-10.jpg",
                "link": "https://www.instagram.com/p/CSm9SecHIB6"
            },
            {
                "image": "../../assets/images/boite-vegane/instagram/boite-vegane-IG-11.jpg",
                "link": "https://www.instagram.com/p/CRouCoIN8gq"
            },
            {
                "image": "../../assets/images/boite-vegane/instagram/boite-vegane-IG-12.jpg",
                "link": "https://www.instagram.com/p/CRmLVo9H82Z"
            },
            {
                "image": "../../assets/images/boite-vegane/instagram/boite-vegane-IG-13.jpg",
                "link": "https://www.instagram.com/p/CRjcwCbHubm"
            },
            {
                "image": "../../assets/images/boite-vegane/instagram/boite-vegane-IG-14.jpg",
                "link": "https://www.instagram.com/p/CRbvHYyNNiV"
            },
            {
                "image": "../../assets/images/boite-vegane/instagram/boite-vegane-IG-15.jpg",
                "link": "https://www.instagram.com/p/CRT4O5DrPBD"
            },
            {
                "image": "../../assets/images/boite-vegane/instagram/boite-vegane-IG-16.jpg",
                "link": "https://www.instagram.com/p/CRMRVpIrCJ4"
            },
            {
                "image": "../../assets/images/boite-vegane/instagram/boite-vegane-IG-17.jpg",
                "link": "https://www.instagram.com/p/CRHhHovLK3j"
            },
            {
                "image": "../../assets/images/boite-vegane/instagram/boite-vegane-IG-18.jpg",
                "link": "https://www.instagram.com/p/CRHVrUInxDb"
            },
            {
                "image": "../../assets/images/boite-vegane/instagram/boite-vegane-IG-19.jpg",
                "link": "https://www.instagram.com/p/CQo2FAiHbe8"
            },
            {
                "image": "../../assets/images/boite-vegane/instagram/boite-vegane-IG-20.jpg",
                "link": "https://www.instagram.com/p/CQb_mrBnIDv"
            },
            {
                "image": "../../assets/images/boite-vegane/instagram/boite-vegane-IG-21.jpg",
                "link": "https://www.instagram.com/p/CQEk6JdnOv9"
            },
            {
                "image": "../../assets/images/boite-vegane/instagram/boite-vegane-IG-22.jpg",
                "link": "https://www.instagram.com/p/CP1La5jHVnN"
            },
            {
                "image": "../../assets/images/boite-vegane/instagram/boite-vegane-IG-23.jpg",
                "link": "https://www.instagram.com/p/CPoUXOknroq"
            },
            {
                "image": "../../assets/images/boite-vegane/instagram/boite-vegane-IG-24.jpg",
                "link": "https://www.instagram.com/p/CPEkmVOn_Pu"
            },
            {
                "image": "../../assets/images/boite-vegane/instagram/boite-vegane-IG-25.jpg",
                "link": "https://www.instagram.com/p/COqEuYflT2s"
            },
            {
                "image": "../../assets/images/boite-vegane/instagram/boite-vegane-IG-26.jpg",
                "link": "https://www.instagram.com/p/COOGcXjnF1H"
            },
            {
                "image": "../../assets/images/boite-vegane/instagram/boite-vegane-IG-27.jpg",
                "link": "https://www.instagram.com/p/CN_VMeJHm7u"
            },
            {
                "image": "../../assets/images/boite-vegane/instagram/boite-vegane-IG-28.jpg",
                "link": "https://www.instagram.com/p/CNvBm4ynwUd"
            },
            {
                "image": "../../assets/images/boite-vegane/instagram/boite-vegane-IG-29.jpg",
                "link": "https://www.instagram.com/p/CNfkJIRnblw"
            },
            {
                "image": "../../assets/images/boite-vegane/instagram/boite-vegane-IG-30.jpg",
                "link": "https://www.instagram.com/p/CNYfjx3n5k7"
            }
        ];
        this.heroBurgersMedia = [
            {
                "image": "../../assets/images/hero-burgers/instagram/hero-burgers-IG-1.jpeg",
                "link": "https://www.instagram.com/p/CUidmL3JkAm/"
            },
            {
                "image": "../../assets/images/hero-burgers/instagram/hero-burgers-IG-2.jpeg",
                "link": "https://www.instagram.com/p/CTiu_xJjz2I/"
            },
            {
                "image": "../../assets/images/hero-burgers/instagram/hero-burgers-IG-3.jpeg",
                "link": "https://www.instagram.com/p/CTck6XcnVAW/"
            },
            {
                "image": "../../assets/images/hero-burgers/instagram/hero-burgers-IG-4.jpeg",
                "link": "https://www.instagram.com/p/CRpTpyoDyDZ/"
            },
            {
                "image": "../../assets/images/hero-burgers/instagram/hero-burgers-IG-5.jpeg",
                "link": "https://www.instagram.com/p/CQjCOdfDfrc/"
            },
            {
                "image": "../../assets/images/hero-burgers/instagram/hero-burgers-IG-6.jpeg",
                "link": "https://www.instagram.com/p/CQCZo_0jUOh/"
            },
            {
                "image": "../../assets/images/hero-burgers/instagram/hero-burgers-IG-7.jpeg",
                "link": "https://www.instagram.com/p/CP3fr5mjadD/"
            },
            {
                "image": "../../assets/images/hero-burgers/instagram/hero-burgers-IG-8.jpeg",
                "link": "https://www.instagram.com/p/CO9ikthjXxe/"
            },
            {
                "image": "../../assets/images/hero-burgers/instagram/hero-burgers-IG-9.jpeg",
                "link": "https://www.instagram.com/p/COn0WwSDfKZ/"
            },
            {
                "image": "../../assets/images/hero-burgers/instagram/hero-burgers-IG-10.jpeg",
                "link": "https://www.instagram.com/p/COCO0QHD7KS/"
            },
            {
                "image": "../../assets/images/hero-burgers/instagram/hero-burgers-IG-11.jpeg",
                "link": "https://www.instagram.com/p/CNTR4lLj3MF/"
            },
            {
                "image": "../../assets/images/hero-burgers/instagram/hero-burgers-IG-12.jpeg",
                "link": "https://www.instagram.com/p/CNDC3x7jAmm/"
            }
        ];
        this.cafeResonanceMedia = [
            {
                "image": "../../assets/images/cafe-resonance/instagram/cafe-resonance-IG-1.jpg",
                "link": "https://www.instagram.com/p/CXd-AWVrkto"
            },
            {
                "image": "../../assets/images/cafe-resonance/instagram/cafe-resonance-IG-2.jpg",
                "link": "https://www.instagram.com/p/CXWfJvELILR"
            },
            {
                "image": "../../assets/images/cafe-resonance/instagram/cafe-resonance-IG-3.jpg",
                "link": "https://www.instagram.com/p/CXQ7RmrrfOw"
            },
            {
                "image": "../../assets/images/cafe-resonance/instagram/cafe-resonance-IG-4.jpg",
                "link": "https://www.instagram.com/p/CXMeDUzF58v"
            },
            {
                "image": "../../assets/images/cafe-resonance/instagram/cafe-resonance-IG-5.jpg",
                "link": "https://www.instagram.com/p/CW_JH1frgZV"
            },
            {
                "image": "../../assets/images/cafe-resonance/instagram/cafe-resonance-IG-6.jpg",
                "link": "https://www.instagram.com/p/CW8lmxlgUl0"
            },
            {
                "image": "../../assets/images/cafe-resonance/instagram/cafe-resonance-IG-7.jpg",
                "link": "https://www.instagram.com/p/CW3XuVqLIA9"
            },
            {
                "image": "../../assets/images/cafe-resonance/instagram/cafe-resonance-IG-8.jpg",
                "link": "https://www.instagram.com/p/CWqzjDNLLru"
            },
            {
                "image": "../../assets/images/cafe-resonance/instagram/cafe-resonance-IG-9.jpg",
                "link": "https://www.instagram.com/p/CWlSYFhrtFX"
            },
            {
                "image": "../../assets/images/cafe-resonance/instagram/cafe-resonance-IG-10.jpg",
                "link": "https://www.instagram.com/p/CWa4C7LrIBQ"
            },
            {
                "image": "../../assets/images/cafe-resonance/instagram/cafe-resonance-IG-11.jpg",
                "link": "https://www.instagram.com/p/CWD0csQLdoI"
            },
            {
                "image": "../../assets/images/cafe-resonance/instagram/cafe-resonance-IG-12.jpg",
                "link": "https://www.instagram.com/p/CV3MCHerNzX"
            },
            {
                "image": "../../assets/images/cafe-resonance/instagram/cafe-resonance-IG-13.jpg",
                "link": "https://www.instagram.com/p/CVgAmLZlifX"
            },
            {
                "image": "../../assets/images/cafe-resonance/instagram/cafe-resonance-IG-14.jpg",
                "link": "https://www.instagram.com/p/CVS46CjP6_C"
            },
            {
                "image": "../../assets/images/cafe-resonance/instagram/cafe-resonance-IG-15.jpg",
                "link": "https://www.instagram.com/p/CVLLu7-ATZo"
            },
            {
                "image": "../../assets/images/cafe-resonance/instagram/cafe-resonance-IG-16.jpg",
                "link": "https://www.instagram.com/p/CU7tYjwLRs4"
            },
            {
                "image": "../../assets/images/cafe-resonance/instagram/cafe-resonance-IG-17.jpg",
                "link": "https://www.instagram.com/p/CU27r36LmQA"
            },
            {
                "image": "../../assets/images/cafe-resonance/instagram/cafe-resonance-IG-18.jpg",
                "link": "https://www.instagram.com/p/CUvjwUKrQ7y"
            }];
        this.melroseMedia = [
            {
                "image": "../../assets/images/melrose/instagram/melrose-IG-1.jpg",
                "link": "https://www.instagram.com/p/CR6bpuiHWbY"
            },
            {
                "image": "../../assets/images/melrose/instagram/melrose-IG-2.jpg",
                "link": "https://www.instagram.com/p/COxWFNwD3xg"
            },
            {
                "image": "../../assets/images/melrose/instagram/melrose-IG-3.jpg",
                "link": "https://www.instagram.com/p/CNAxaoMDJvH"
            },
            {
                "image": "../../assets/images/melrose/instagram/melrose-IG-4.jpg",
                "link": "https://www.instagram.com/p/CM5KiygjSFz"
            },
            {
                "image": "../../assets/images/melrose/instagram/melrose-IG-5.jpg",
                "link": "https://www.instagram.com/p/CMXjDiMDl7o"
            },
            {
                "image": "../../assets/images/melrose/instagram/melrose-IG-6.jpg",
                "link": "https://www.instagram.com/p/CLPGE6xDgGv"
            },
            {
                "image": "../../assets/images/melrose/instagram/melrose-IG-7.jpg",
                "link": "https://www.instagram.com/p/CKjmp19jJu8"
            },
            {
                "image": "../../assets/images/melrose/instagram/melrose-IG-8.jpg",
                "link": "https://www.instagram.com/p/CKgxqJxjyOT"
            },
            {
                "image": "../../assets/images/melrose/instagram/melrose-IG-9.jpg",
                "link": "https://www.instagram.com/p/CKV6foLjFd0"
            },
            {
                "image": "../../assets/images/melrose/instagram/melrose-IG-10.jpg",
                "link": "https://www.instagram.com/p/CKRkeG5j79H"
            },
            {
                "image": "../../assets/images/melrose/instagram/melrose-IG-11.jpg",
                "link": "https://www.instagram.com/p/CKPAIabDTmL"
            },
            {
                "image": "../../assets/images/melrose/instagram/melrose-IG-12.jpg",
                "link": "https://www.instagram.com/p/CKMJcHhDIid"
            },
            {
                "image": "../../assets/images/melrose/instagram/melrose-IG-13.jpg",
                "link": "https://www.instagram.com/p/CJ_nOEkj3Qc"
            },
            {
                "image": "../../assets/images/melrose/instagram/melrose-IG-14.jpg",
                "link": "https://www.instagram.com/p/CH-ZTwzjp_0"
            },
            {
                "image": "../../assets/images/melrose/instagram/melrose-IG-15.jpg",
                "link": "https://www.instagram.com/p/CH5ZC-pHHAQ"
            },
            {
                "image": "../../assets/images/melrose/instagram/melrose-IG-16.jpg",
                "link": "https://www.instagram.com/p/CHL4nGtjMS1"
            },
            {
                "image": "../../assets/images/melrose/instagram/melrose-IG-17.jpg",
                "link": "https://www.instagram.com/p/CGlB4rvjBDu"
            },
            {
                "image": "../../assets/images/melrose/instagram/melrose-IG-18.jpg",
                "link": "https://www.instagram.com/p/CGIycXejCTF"
            }];
        this.mintarMedia = [
            {
                "image": "../../assets/images/mintar/instagram/mintar-IG-1.jpeg",
                "link": "https://www.instagram.com/p/CoizMoptMQo/"
            },
            {
                "image": "../../assets/images/mintar/instagram/mintar-IG-2.jpeg",
                "link": "https://www.instagram.com/p/CoKbNC0uOub/"
            },
            {
                "image": "../../assets/images/mintar/instagram/mintar-IG-3.jpeg",
                "link": "https://www.instagram.com/p/CoASzuLOibo/"
            },
            {
                "image": "../../assets/images/mintar/instagram/mintar-IG-4.jpeg",
                "link": "https://www.instagram.com/p/Cn1-4sIuZOW/"
            },
            {
                "image": "../../assets/images/mintar/instagram/mintar-IG-5.jpeg",
                "link": "https://www.instagram.com/p/CpsgsEjO_HS/"
            },
            {
                "image": "../../assets/images/mintar/instagram/mintar-IG-6.jpeg",
                "link": "https://www.instagram.com/p/CmKJRYspJqB/"
            },
            {
                "image": "../../assets/images/mintar/instagram/mintar-IG-7.jpeg",
                "link": "https://www.instagram.com/p/Cl_LdJMOZyB/"
            },
            {
                "image": "../../assets/images/mintar/instagram/mintar-IG-8.jpeg",
                "link": "https://www.instagram.com/p/Cnz3MLYplSo/"
            },
            {
                "image": "../../assets/images/mintar/instagram/mintar-IG-9.jpeg",
                "link": "https://www.instagram.com/p/CpiGgmHOU8c/"
            },
            {
                "image": "../../assets/images/mintar/instagram/mintar-IG-10.jpeg",
                "link": "https://www.instagram.com/p/Cp-_a5XpdGz/"
            },
            {
                "image": "../../assets/images/mintar/instagram/mintar-IG-11.jpeg",
                "link": "https://www.instagram.com/p/CpNyHpEuwPa/"
            },
            {
                "image": "../../assets/images/mintar/instagram/mintar-IG-12.jpeg",
                "link": "https://www.instagram.com/p/Cl91hv6JNb1/"
            }];
        this.cacao70Media = [
            {
                "image": "../../assets/images/cacao-70/instagram/cacao70-IG-1.jpeg",
                "link": "https://www.instagram.com/p/CfbwfsiMCSQ/"
            },
            {
                "image": "../../assets/images/cacao-70/instagram/cacao70-IG-2.jpeg",
                "link": "https://www.instagram.com/p/CfMoUephk47/"
            },
            {
                "image": "../../assets/images/cacao-70/instagram/cacao70-IG-3.jpeg",
                "link": "https://www.instagram.com/p/Ce3wodQgb4c/"
            },
            {
                "image": "../../assets/images/cacao-70/instagram/cacao70-IG-4.jpeg",
                "link": "https://www.instagram.com/p/Cequ_18NTtT/"
            },
            {
                "image": "../../assets/images/cacao-70/instagram/cacao70-IG-5.jpeg",
                "link": "https://www.instagram.com/p/CeYzqdtM3kw/"
            },
            {
                "image": "../../assets/images/cacao-70/instagram/cacao70-IG-6.jpeg",
                "link": "https://www.instagram.com/p/CejAnMJpqP_/"
            }];
        this.laToileMedia = [
            {
                "image": "../../assets/images/la-toile/instagram/la-toile-IG-1.jpeg",
                "link": "https://www.instagram.com/p/Coxrc7fP7Ce/"
            },
            {
                "image": "../../assets/images/la-toile/instagram/la-toile-IG-2.jpeg",
                "link": "https://www.instagram.com/p/Copntmbr1kv/"
            },
            {
                "image": "../../assets/images/la-toile/instagram/la-toile-IG-3.jpeg",
                "link": "https://www.instagram.com/p/CoLhiMmrUVF/"
            },
            {
                "image": "../../assets/images/la-toile/instagram/la-toile-IG-4.jpeg",
                "link": "https://www.instagram.com/p/Cn5o3jRri0G/"
            },
            {
                "image": "../../assets/images/la-toile/instagram/la-toile-IG-5.jpeg",
                "link": "https://www.instagram.com/p/CnXd5QpJeLi/"
            },
            {
                "image": "../../assets/images/la-toile/instagram/la-toile-IG-6.jpeg",
                "link": "https://www.instagram.com/p/CnP5XICv6lm/"
            }];
        this.sepLaiMedia = [
            {
                "image": "../../assets/images/sep-lai/instagram/sep-lai-IG-1.jpg",
                "link": "https://www.instagram.com/p/CpDR79kMJMb"
            },
            {
                "image": "../../assets/images/sep-lai/instagram/sep-lai-IG-2.jpg",
                "link": "https://www.instagram.com/p/CiDiY6CrZRF"
            },
            {
                "image": "../../assets/images/sep-lai/instagram/sep-lai-IG-3.jpg",
                "link": "https://www.instagram.com/p/Clj6USLs2oP"
            },
            {
                "image": "../../assets/images/sep-lai/instagram/sep-lai-IG-4.jpg",
                "link": "https://www.instagram.com/p/Coh1i37MyAc"
            },
            {
                "image": "../../assets/images/sep-lai/instagram/sep-lai-IG-5.jpg",
                "link": "https://www.instagram.com/p/CmKW0vpJKdX"
            },
            {
                "image": "../../assets/images/sep-lai/instagram/sep-lai-IG-6.jpg",
                "link": "https://www.instagram.com/p/Ch7eJJaO3S6"
            },
            {
                "image": "../../assets/images/sep-lai/instagram/sep-lai-IG-7.jpg",
                "link": "https://www.instagram.com/p/Cous1OrMV0D"
            },
            {
                "image": "../../assets/images/sep-lai/instagram/sep-lai-IG-8.jpg",
                "link": "https://www.instagram.com/p/CjtO_bmPi4s"
            },
            {
                "image": "../../assets/images/sep-lai/instagram/sep-lai-IG-9.jpg",
                "link": "https://www.instagram.com/p/CidpjLONzdz"
            },
            {
                "image": "../../assets/images/sep-lai/instagram/sep-lai-IG-10.jpg",
                "link": "https://www.instagram.com/p/CivbkU5LFNG"
            },
            {
                "image": "../../assets/images/sep-lai/instagram/sep-lai-IG-11.jpg",
                "link": "https://www.instagram.com/p/ClR3rJ3yc28"
            },
            {
                "image": "../../assets/images/sep-lai/instagram/sep-lai-IG-12.jpg",
                "link": "https://www.instagram.com/p/ClZmFHEriyM"
            }];
        this.chaiTeaLoungeMedia = [
            {
                "image": "../../assets/images/chai-tea-lounge/instagram/chai-tea-lounge-IG-1.jpg",
                "link": "https://www.instagram.com/p/CoIjPwbPOtq/"
            },
            {
                "image": "../../assets/images/chai-tea-lounge/instagram/chai-tea-lounge-IG-2.jpg",
                "link": "https://www.instagram.com/p/CiQefgkvlWJ/"
            },
            {
                "image": "../../assets/images/chai-tea-lounge/instagram/chai-tea-lounge-IG-3.jpg",
                "link": "https://www.instagram.com/p/Ch48TM5r5lZ/"
            },
            {
                "image": "../../assets/images/chai-tea-lounge/instagram/chai-tea-lounge-IG-4.jpg",
                "link": "https://www.instagram.com/p/B7q6YCoH8Bf/"
            },
            {
                "image": "../../assets/images/chai-tea-lounge/instagram/chai-tea-lounge-IG-5.jpg",
                "link": "https://www.instagram.com/p/B7GyFFcHPjR/"
            },
            {
                "image": "../../assets/images/chai-tea-lounge/instagram/chai-tea-lounge-IG-6.jpg",
                "link": "https://www.instagram.com/p/B8hEMZZHh-C/"
            },
            {
                "image": "../../assets/images/chai-tea-lounge/instagram/chai-tea-lounge-IG-7.jpg",
                "link": "https://www.instagram.com/p/B3xsXFanfhM/"
            },
            {
                "image": "../../assets/images/chai-tea-lounge/instagram/chai-tea-lounge-IG-8.jpg",
                "link": "https://www.instagram.com/p/B2U_XH_HPKo/"
            },
            {
                "image": "../../assets/images/chai-tea-lounge/instagram/chai-tea-lounge-IG-9.jpg",
                "link": "https://www.instagram.com/p/B2U_CKMH4TA/"
            },
            {
                "image": "../../assets/images/chai-tea-lounge/instagram/chai-tea-lounge-IG-10.jpg",
                "link": "https://www.instagram.com/p/B2U-rlnHlKH/"
            },
            {
                "image": "../../assets/images/chai-tea-lounge/instagram/chai-tea-lounge-IG-11.jpg",
                "link": "https://www.instagram.com/p/B2U-kNDHEKI/"
            },
            {
                "image": "../../assets/images/chai-tea-lounge/instagram/chai-tea-lounge-IG-12.jpg",
                "link": "https://www.instagram.com/p/B2U8hAoHHlM/"
            }];
        this.laCroissanterieFigaroMedia = [
            {
                "image": "../../assets/images/la-croissanterie-figaro/instagram/la-croissanterie-figaro-IG-1.jpg",
                "link": "https://www.instagram.com/p/CjEGAL_pmhh/"
            },
            {
                "image": "../../assets/images/la-croissanterie-figaro/instagram/la-croissanterie-figaro-IG-2.jpg",
                "link": "https://www.instagram.com/p/B9hNDC3nfxY/"
            },
            {
                "image": "../../assets/images/la-croissanterie-figaro/instagram/la-croissanterie-figaro-IG-3.jpg",
                "link": "https://www.instagram.com/p/B9Hj9CPn3Uh/"
            },
            {
                "image": "../../assets/images/la-croissanterie-figaro/instagram/la-croissanterie-figaro-IG-4.jpg",
                "link": "https://www.instagram.com/p/B9CaX5pg_39/"
            },
            {
                "image": "../../assets/images/la-croissanterie-figaro/instagram/la-croissanterie-figaro-IG-5.jpg",
                "link": "https://www.instagram.com/p/B81iZH1AZmG/"
            },
            {
                "image": "../../assets/images/la-croissanterie-figaro/instagram/la-croissanterie-figaro-IG-6.jpg",
                "link": "https://www.instagram.com/p/B8MQMGJnUcy/"
            },
            {
                "image": "../../assets/images/la-croissanterie-figaro/instagram/la-croissanterie-figaro-IG-7.jpg",
                "link": "https://www.instagram.com/p/B7_cz8kHfLf/"
            },
            {
                "image": "../../assets/images/la-croissanterie-figaro/instagram/la-croissanterie-figaro-IG-8.jpg",
                "link": "https://www.instagram.com/p/B7tZUBxnon2/"
            },
            {
                "image": "../../assets/images/la-croissanterie-figaro/instagram/la-croissanterie-figaro-IG-9.jpg",
                "link": "https://www.instagram.com/p/B7RK7nBnC59/"
            },
            {
                "image": "../../assets/images/la-croissanterie-figaro/instagram/la-croissanterie-figaro-IG-10.jpg",
                "link": "https://www.instagram.com/p/B6OJ48NnKv6/"
            },
            {
                "image": "../../assets/images/la-croissanterie-figaro/instagram/la-croissanterie-figaro-IG-11.jpg",
                "link": "https://www.instagram.com/p/BozsxUdFu25/"
            },
            {
                "image": "../../assets/images/la-croissanterie-figaro/instagram/la-croissanterie-figaro-IG-12.jpg",
                "link": "https://www.instagram.com/p/BokYz49lY5E/"
            }];
        this.auxMerveilleuxDeFredMedia = [
            {
                "image": "../../assets/images/aux-merveilleux-de-fred/instagram/aux-merveilleux-de-fred-IG-1.jpg",
                "link": "https://www.instagram.com/p/Co5YUxrI0hn/"
            },
            {
                "image": "../../assets/images/aux-merveilleux-de-fred/instagram/aux-merveilleux-de-fred-IG-2.jpg",
                "link": "https://www.instagram.com/p/Coz2B2RIBRV/"
            },
            {
                "image": "../../assets/images/aux-merveilleux-de-fred/instagram/aux-merveilleux-de-fred-IG-3.jpg",
                "link": "https://www.instagram.com/p/ClG0PNzIEjY/"
            },
            {
                "image": "../../assets/images/aux-merveilleux-de-fred/instagram/aux-merveilleux-de-fred-IG-4.jpg",
                "link": "https://www.instagram.com/p/CYRgfPnIwX8/"
            },
            {
                "image": "../../assets/images/aux-merveilleux-de-fred/instagram/aux-merveilleux-de-fred-IG-5.jpg",
                "link": "https://www.instagram.com/p/CeBSqy2oMdE/"
            },
            {
                "image": "../../assets/images/aux-merveilleux-de-fred/instagram/aux-merveilleux-de-fred-IG-6.jpg",
                "link": "https://www.instagram.com/p/CT2NYQgoc-x/"
            }];
        this.tacosNationMedia = [
            {
                "image": "../../assets/images/tacos-nation/instagram/tacos-nation-IG-1.jpg",
                "link": "https://www.instagram.com/p/Cf2jrf5Lpbu/"
            },
            {
                "image": "../../assets/images/tacos-nation/instagram/tacos-nation-IG-2.jpg",
                "link": "https://www.instagram.com/p/CgIE2CKptIl/"
            },
            {
                "image": "../../assets/images/tacos-nation/instagram/tacos-nation-IG-3.jpg",
                "link": "https://www.instagram.com/p/CgxMbgXJ1Yq/"
            },
            {
                "image": "../../assets/images/tacos-nation/instagram/tacos-nation-IG-4.jpg",
                "link": "https://www.instagram.com/p/CbaxqSPp6et/"
            },
            {
                "image": "../../assets/images/tacos-nation/instagram/tacos-nation-IG-5.jpg",
                "link": "https://www.instagram.com/p/CbBOm4BpoOK/"
            },
            {
                "image": "../../assets/images/tacos-nation/instagram/tacos-nation-IG-6.jpg",
                "link": "https://www.instagram.com/p/CZWuhG2NRsh/"
            },
            {
                "image": "../../assets/images/tacos-nation/instagram/tacos-nation-IG-7.jpg",
                "link": "https://www.instagram.com/p/CZhBskJsjbU/"
            },
            {
                "image": "../../assets/images/tacos-nation/instagram/tacos-nation-IG-8.jpg",
                "link": "https://www.instagram.com/p/CYZyYLZpsuT/"
            },
            {
                "image": "../../assets/images/tacos-nation/instagram/tacos-nation-IG-9.jpg",
                "link": "https://www.instagram.com/p/CY_je37lY5c/"
            },
            {
                "image": "../../assets/images/tacos-nation/instagram/tacos-nation-IG-10.jpg",
                "link": "https://www.instagram.com/p/CYVEQBeJSMK/"
            },
            {
                "image": "../../assets/images/tacos-nation/instagram/tacos-nation-IG-11.jpg",
                "link": "https://www.instagram.com/p/CYOoQuUv5-q/"
            },
            {
                "image": "../../assets/images/tacos-nation/instagram/tacos-nation-IG-12.jpg",
                "link": "https://www.instagram.com/p/CUfkmTKJiSS/"
            }];
        this.laFabriqueDeBagelMedia = [
            {
                "image": "../../assets/images/la-fabrique-de-bagel/instagram/lfdb-IG-1.jpg",
                "link": "https://www.instagram.com/p/CCtC9rQHSR7/"
            },
            {
                "image": "../../assets/images/la-fabrique-de-bagel/instagram/lfdb-IG-2.jpg",
                "link": "https://www.instagram.com/p/B-KTuJVpVPq/"
            },
            {
                "image": "../../assets/images/la-fabrique-de-bagel/instagram/lfdb-IG-3.jpg",
                "link": "https://www.instagram.com/p/B-M1kuTJWlw/"
            },
            {
                "image": "../../assets/images/la-fabrique-de-bagel/instagram/lfdb-IG-4.jpg",
                "link": "https://www.instagram.com/p/CBnteS-Ht3j/"
            },
            {
                "image": "../../assets/images/la-fabrique-de-bagel/instagram/lfdb-IG-5.jpg",
                "link": "https://www.instagram.com/p/CCQ5x3aHNzY/"
            },
            {
                "image": "../../assets/images/la-fabrique-de-bagel/instagram/lfdb-IG-6.jpg",
                "link": "https://www.instagram.com/p/CDRfyFXH-46/"
            }];
        this.studentTastyBiryani = [
            {
                "image": "../../assets/images/student-tasty-biryani/instagram/stb-IG-1.jpg",
                "link": "https://www.instagram.com/p/BXBrbwOBC-1/?hl=en"
            },
            {
                "image": "../../assets/images/student-tasty-biryani/instagram/stb-IG-2.jpg",
                "link": "https://www.instagram.com/p/BjfLjQDDyzd/?hl=en"
            },
            {
                "image": "../../assets/images/student-tasty-biryani/instagram/stb-IG-3.jpg",
                "link": "https://www.instagram.com/p/BQRTRdCAozd/?hl=en"
            },
            {
                "image": "../../assets/images/student-tasty-biryani/instagram/stb-IG-4.jpg",
                "link": "https://www.instagram.com/p/Bh9n8QqgoN7/?hl=en"
            },
            {
                "image": "../../assets/images/student-tasty-biryani/instagram/stb-IG-5.jpg",
                "link": "https://www.instagram.com/p/BQBm4phg9UG/?hl=en"
            },
            {
                "image": "../../assets/images/student-tasty-biryani/instagram/stb-IG-6.jpg",
                "link": "https://www.instagram.com/p/BcnTND8nw0E/?hl=en"
            }];
        this.lali = [
            {
                "image": "../../assets/images/lali/instagram/lali-IG-1.jpeg",
                "link": "https://www.instagram.com/p/CpOk_2-rKVq/?hl=en"
            },
            {
                "image": "../../assets/images/lali/instagram/lali-IG-2.jpeg",
                "link": "https://www.instagram.com/p/CpGz-sxrP_l/?hl=en"
            },
            {
                "image": "../../assets/images/lali/instagram/lali-IG-3.jpeg",
                "link": "https://www.instagram.com/p/CpgjVvMLBxs/?hl=en"
            }];
        this.chezTaMere = [
            {
                "image": "../../assets/images/chez-ta-mere/instagram/chez-ta-mere-IG-1.jpeg",
                "link": "https://www.instagram.com/p/CozhZI9rKD-/"
            },
            {
                "image": "../../assets/images/chez-ta-mere/instagram/chez-ta-mere-IG-2.jpeg",
                "link": "https://www.instagram.com/p/CiN11ipr4Tw/"
            },
            {
                "image": "../../assets/images/chez-ta-mere/instagram/chez-ta-mere-IG-3.jpeg",
                "link": "https://www.instagram.com/p/CfWvv6qLV_Y/"
            },
            {
                "image": "../../assets/images/chez-ta-mere/instagram/chez-ta-mere-IG-4.jpeg",
                "link": "https://www.instagram.com/p/CMUlDVcHeuz/"
            },
            {
                "image": "../../assets/images/chez-ta-mere/instagram/chez-ta-mere-IG-5.jpeg",
                "link": "https://www.instagram.com/p/B0L1QGkHET3/"
            },
            {
                "image": "../../assets/images/chez-ta-mere/instagram/chez-ta-mere-IG-6.jpeg",
                "link": "https://www.instagram.com/p/B0Bhe1fnP5a/"
            },
            {
                "image": "../../assets/images/chez-ta-mere/instagram/chez-ta-mere-IG-7.jpeg",
                "link": "https://www.instagram.com/p/Bz-1fPln07g/"
            },
            {
                "image": "../../assets/images/chez-ta-mere/instagram/chez-ta-mere-IG-8.jpeg",
                "link": "https://www.instagram.com/p/Bz8JvUNnaSt/"
            },
            {
                "image": "../../assets/images/chez-ta-mere/instagram/chez-ta-mere-IG-9.jpeg",
                "link": "https://www.instagram.com/p/BzvUFvlnAQR/"
            },
            {
                "image": "../../assets/images/chez-ta-mere/instagram/chez-ta-mere-IG-10.jpeg",
                "link": "https://www.instagram.com/p/BxccIcpnhfd/"
            },
            {
                "image": "../../assets/images/chez-ta-mere/instagram/chez-ta-mere-IG-11.jpeg",
                "link": "https://www.instagram.com/p/BxZ3Ghrg5sX/"
            },
            {
                "image": "../../assets/images/chez-ta-mere/instagram/chez-ta-mere-IG-12.jpeg",
                "link": "https://www.instagram.com/p/BtL8EQAg9r9/"
            },
        ]
        this.friteAlors = [
            {
                "image": "../../assets/images/frite-alors/instagram/frite-alors-IG-1.jpeg",
                "link": "https://www.instagram.com/p/CstlxutrUKu/"
            },
            {
                "image": "../../assets/images/frite-alors/instagram/frite-alors-IG-2.jpeg",
                "link": "https://www.instagram.com/p/CsWf5e9s4NH/"
            },
            {
                "image": "../../assets/images/frite-alors/instagram/frite-alors-IG-3.jpeg",
                "link": "https://www.instagram.com/p/Cq_C7fbrvQo/"
            },
            {
                "image": "../../assets/images/frite-alors/instagram/frite-alors-IG-4.jpeg",
                "link": "https://www.instagram.com/p/Cp5O9QBppnM/"
            },
            {
                "image": "../../assets/images/frite-alors/instagram/frite-alors-IG-5.jpeg",
                "link": "https://www.instagram.com/p/CpSuQoxrNzH/"
            },
            {
                "image": "../../assets/images/frite-alors/instagram/frite-alors-IG-6.jpeg",
                "link": "https://www.instagram.com/p/Cn7RLDGLRYB/"
            },
            {
                "image": "../../assets/images/frite-alors/instagram/frite-alors-IG-7.jpeg",
                "link": "https://www.instagram.com/p/CnFMepzLnS0/"
            },
            {
                "image": "../../assets/images/frite-alors/instagram/frite-alors-IG-8.jpeg",
                "link": "https://www.instagram.com/p/CiNgSwRs8Bl/"
            },
            {
                "image": "../../assets/images/frite-alors/instagram/frite-alors-IG-9.jpeg",
                "link": "https://www.instagram.com/p/Chm4XRRj70x/"
            },
            {
                "image": "../../assets/images/frite-alors/instagram/frite-alors-IG-10.jpeg",
                "link": "https://www.instagram.com/p/CfrNFYLI-sY/"
            },
            {
                "image": "../../assets/images/frite-alors/instagram/frite-alors-IG-11.jpeg",
                "link": "https://www.instagram.com/p/CcnnuxCoSBV/"
            },
            {
                "image": "../../assets/images/frite-alors/instagram/frite-alors-IG-12.jpeg",
                "link": "https://www.instagram.com/p/CZHshpXlu8f/"
            },
        ]
        this.tibum = [
            {
                "image": "../../assets/images/tibum/instagram/tibum-IG-1.jpg",
                "link": "https://www.instagram.com/p/CwIwmSmOJXE/"
            },
            {
                "image": "../../assets/images/tibum/instagram/tibum-IG-2.jpg",
                "link": "https://www.instagram.com/p/CvgDVs5uoIJ/"
            },
            {
                "image": "../../assets/images/tibum/instagram/tibum-IG-3.jpg",
                "link": "https://www.instagram.com/p/CwIFMSxu50Q/"
            },
            {
                "image": "../../assets/images/tibum/instagram/tibum-IG-4.jpg",
                "link": "https://www.instagram.com/p/CwIDy5lAK49/"
            },
            {
                "image": "../../assets/images/tibum/instagram/tibum-IG-5.jpg",
                "link": "https://www.instagram.com/p/CwDV0nZgnrK/"
            },
            {
                "image": "../../assets/images/tibum/instagram/tibum-IG-6.jpg",
                "link": "https://www.instagram.com/p/CwD3DZ5O3x2/"
            },
        ]
        this.thePerle = [
            {
                "image": "../../assets/images/the-perle/instagram/the-perle-IG-1.jpeg",
                "link": "https://www.instagram.com/p/Cupjq4UAL7L/"
            },
            {
                "image": "../../assets/images/the-perle/instagram/the-perle-IG-2.jpeg",
                "link": "https://www.instagram.com/p/Cd_akyQJaYj/"
            },
            {
                "image": "../../assets/images/the-perle/instagram/the-perle-IG-3.jpeg",
                "link": "https://www.instagram.com/p/CjgZQ9vgKUe/"
            }
        ]
        this.gongCha = [
            {
                "image": "../../assets/images/gong-cha/instagram/gong-cha-IG-1.png",
                "link": "https://www.instagram.com/p/CsjSZ9ntiCp/"
            },
            {
                "image": "../../assets/images/gong-cha/instagram/gong-cha-IG-2.png",
                "link": "https://www.instagram.com/p/CuuVXDvtjhQ/?img_index=1"
            },
            {
                "image": "../../assets/images/gong-cha/instagram/gong-cha-IG-3.png",
                "link": "https://www.instagram.com/p/CuXKNF9N8sH/"
            },
            {
                "image": "../../assets/images/gong-cha/instagram/gong-cha-IG-4.png",
                "link": "https://www.instagram.com/p/Ct9aT-ytmhA/"
            },
            {
                "image": "../../assets/images/gong-cha/instagram/gong-cha-IG-5.png",
                "link": "https://www.instagram.com/p/Cs_nJBWSAgH/"
            },
            {
                "image": "../../assets/images/gong-cha/instagram/gong-cha-IG-6.png",
                "link": "https://www.instagram.com/p/CxN-hiHMv8h/"
            },
            {
                "image": "../../assets/images/gong-cha/instagram/gong-cha-IG-7.png",
                "link": "https://www.instagram.com/p/Ct1r2PatGed/"
            },
            {
                "image": "../../assets/images/gong-cha/instagram/gong-cha-IG-8.png",
                "link": "https://www.instagram.com/p/CtHVlOYM626/"
            },
            {
                "image": "../../assets/images/gong-cha/instagram/gong-cha-IG-9.png",
                "link": "https://www.instagram.com/p/CsOlJMLsAPW/"
            },
            {
                "image": "../../assets/images/gong-cha/instagram/gong-cha-IG-10.png",
                "link": "https://www.instagram.com/p/CrQ5HVIMxQp/"
            },
            {
                "image": "../../assets/images/gong-cha/instagram/gong-cha-IG-11.png",
                "link": "https://www.instagram.com/p/Cq0MGBXS_Ld/"
            },
            {
                "image": "../../assets/images/gong-cha/instagram/gong-cha-IG-12.png",
                "link": "https://www.instagram.com/p/Cp5WGxrN4OL/"
            }
        ]
        this.buffaloBill = [
            {
                "image": "../../assets/images/buffalo-bill/instagram/buffalo-bill-IG-1.jpeg",
                "link": "https://www.instagram.com/p/CpF3E9bNw9l/"
            },
            {
                "image": "../../assets/images/buffalo-bill/instagram/buffalo-bill-IG-2.jpeg",
                "link": "https://www.instagram.com/p/CpBLTCnOqWa/?img_index=1"
            },
            {
                "image": "../../assets/images/buffalo-bill/instagram/buffalo-bill-IG-3.jpeg",
                "link": "https://www.instagram.com/p/Cqn6Lw8JNsn/"
            }
        ]
        this.souvlakiGrecPlus = [
            {
                "image": "../../assets/images/souvlaki-grec-plus/instagram/souvlaki-grec-plus-IG-1.png",
                "link": "https://www.instagram.com/p/Cxlcyg-PbRS/"
            },
            {
                "image": "../../assets/images/souvlaki-grec-plus/instagram/souvlaki-grec-plus-IG-2.png",
                "link": "https://www.instagram.com/p/CxlcsY3P62F/"
            },
            {
                "image": "../../assets/images/souvlaki-grec-plus/instagram/souvlaki-grec-plus-IG-3.png",
                "link": "https://www.instagram.com/p/CxlcqIdvwxY/"
            },
            {
                "image": "../../assets/images/souvlaki-grec-plus/instagram/souvlaki-grec-plus-IG-4.png",
                "link": "https://www.instagram.com/p/Cxlck1-vnNI/"
            },
            {
                "image": "../../assets/images/souvlaki-grec-plus/instagram/souvlaki-grec-plus-IG-5.png",
                "link": "https://www.instagram.com/p/Cxlcc0bPUBe/"
            },
            {
                "image": "../../assets/images/souvlaki-grec-plus/instagram/souvlaki-grec-plus-IG-6.png",
                "link": "https://www.instagram.com/p/CxlcbTLvVdm/"
            },
        ]
        this.souvlakiGrecPlus = [
            {
                "image": "../../assets/images/souvlaki-grec-plus/instagram/souvlaki-grec-plus-IG-1.png",
                "link": "https://www.instagram.com/p/Cxlcyg-PbRS/"
            },
            {
                "image": "../../assets/images/souvlaki-grec-plus/instagram/souvlaki-grec-plus-IG-2.png",
                "link": "https://www.instagram.com/p/CxlcsY3P62F/"
            },
            {
                "image": "../../assets/images/souvlaki-grec-plus/instagram/souvlaki-grec-plus-IG-3.png",
                "link": "https://www.instagram.com/p/CxlcqIdvwxY/"
            },
            {
                "image": "../../assets/images/souvlaki-grec-plus/instagram/souvlaki-grec-plus-IG-4.png",
                "link": "https://www.instagram.com/p/Cxlck1-vnNI/"
            },
            {
                "image": "../../assets/images/souvlaki-grec-plus/instagram/souvlaki-grec-plus-IG-5.png",
                "link": "https://www.instagram.com/p/Cxlcc0bPUBe/"
            },
            {
                "image": "../../assets/images/souvlaki-grec-plus/instagram/souvlaki-grec-plus-IG-6.png",
                "link": "https://www.instagram.com/p/CxlcbTLvVdm/"
            },
        ]
        this.tsujiri = [
            {
                "image": "../../assets/images/tsujiri/instagram/tsujiri-IG-1.jpeg",
                "link": "https://www.instagram.com/p/CubFxHCs8yk/"
            },
            {
                "image": "../../assets/images/tsujiri/instagram/tsujiri-IG-2.jpeg",
                "link": "https://www.instagram.com/p/CtzT4Z7gLzJ/"
            },
            {
                "image": "../../assets/images/tsujiri/instagram/tsujiri-IG-3.jpeg",
                "link": "https://www.instagram.com/p/CoLTJSwr3X3/"
            },
            {
                "image": "../../assets/images/tsujiri/instagram/tsujiri-IG-4.jpeg",
                "link": "https://www.instagram.com/p/CoEWB9SsIj2/"
            },
            {
                "image": "../../assets/images/tsujiri/instagram/tsujiri-IG-5.jpeg",
                "link": "https://www.instagram.com/p/Clsd-DDsNoU/"
            },
            {
                "image": "../../assets/images/tsujiri/instagram/tsujiri-IG-6.jpeg",
                "link": "https://www.instagram.com/p/CjTTTMcrSqy/"
            },
            {
                "image": "../../assets/images/tsujiri/instagram/tsujiri-IG-7.jpeg",
                "link": "https://www.instagram.com/p/CjGZJ_IrJ7m/"
            },
            {
                "image": "../../assets/images/tsujiri/instagram/tsujiri-IG-8.jpeg",
                "link": "https://www.instagram.com/p/Cf5U7fCMmtA/"
            },
            {
                "image": "../../assets/images/tsujiri/instagram/tsujiri-IG-9.jpeg",
                "link": "https://www.instagram.com/p/CfFQad2rKvA/"
            }
        ]
        this.spartaChicken = [
            {
                "image": "../../assets/images/sparta-chicken/instagram/sparta-chicken-IG-1.png",
                "link": "https://www.instagram.com/p/CusLBfiJnGa/"
            },
            {
                "image": "../../assets/images/sparta-chicken/instagram/sparta-chicken-IG-2.png",
                "link": "https://www.instagram.com/p/CukjygqJPWN/"
            },
            {
                "image": "../../assets/images/sparta-chicken/instagram/sparta-chicken-IG-3.png",
                "link": "https://www.instagram.com/p/CuknZc4pD4l/"
            },
            {
                "image": "../../assets/images/sparta-chicken/instagram/sparta-chicken-IG-4.png",
                "link": "https://www.instagram.com/p/CUuvufrLWSB/"
            },
            {
                "image": "../../assets/images/sparta-chicken/instagram/sparta-chicken-IG-5.png",
                "link": "https://www.instagram.com/p/CUV_6uMs-1e/"
            },
            {
                "image": "../../assets/images/sparta-chicken/instagram/sparta-chicken-IG-6.png",
                "link": "https://www.instagram.com/p/CJO0Cj8HWjr/"
            }
        ]
        this.p23Dumplings = [
            {
                "image": "../../assets/images/p23-dumplings/instagram/p23-dumplings-IG-1.png",
                "link": "https://www.instagram.com/p/B4bYeFxHLZ8/"
            },
            {
                "image": "../../assets/images/p23-dumplings/instagram/p23-dumplings-IG-2.png",
                "link": "https://www.instagram.com/p/B46LXDdo1Ux/?img_index=1"
            },
            {
                "image": "../../assets/images/p23-dumplings/instagram/p23-dumplings-IG-3.png",
                "link": "https://www.instagram.com/p/B4Qyfnxnp9-/"
            },
            {
                "image": "../../assets/images/p23-dumplings/instagram/p23-dumplings-IG-4.png",
                "link": "https://www.instagram.com/p/B4TpjY6Anuf/"
            },
            {
                "image": "../../assets/images/p23-dumplings/instagram/p23-dumplings-IG-5.png",
                "link": "https://www.instagram.com/p/B5G4KIpoJuL/"
            },
            {
                "image": "../../assets/images/p23-dumplings/instagram/p23-dumplings-IG-6.png",
                "link": "https://www.instagram.com/p/B48htHzoD8S/"
            }
        ]
        this.burgzCantine = [
            {
                "image": "../../assets/images/burgz-cantine/instagram/burgz-cantine-IG-1.jpeg",
                "link": "https://www.instagram.com/p/CEHvZPZH7kf/"
            },
            {
                "image": "../../assets/images/burgz-cantine/instagram/burgz-cantine-IG-2.jpeg",
                "link": "https://www.instagram.com/p/CEE8E4AnXXX/"
            },
            {
                "image": "../../assets/images/burgz-cantine/instagram/burgz-cantine-IG-3.jpeg",
                "link": "https://www.instagram.com/p/CDMPMDKHgsf/"
            },
            {
                "image": "../../assets/images/burgz-cantine/instagram/burgz-cantine-IG-4.jpeg",
                "link": "https://www.instagram.com/p/CC857kxn2XM/"
            },
            {
                "image": "../../assets/images/burgz-cantine/instagram/burgz-cantine-IG-5.jpeg",
                "link": "https://www.instagram.com/p/CCypKKJHjOv/?img_index=1"
            },
            {
                "image": "../../assets/images/burgz-cantine/instagram/burgz-cantine-IG-6.jpeg",
                "link": "https://www.instagram.com/p/CBi3C9ln4Yv/"
            }
        ]
        this.goplana = [
            {
                "image": "../../assets/images/goplana/instagram/goplana-IG-1.jpeg",
                "link": "https://www.instagram.com/p/CxqnXqmA7zS/?img_index=1"
            },
            {
                "image": "../../assets/images/goplana/instagram/goplana-IG-2.jpeg",
                "link": "https://www.instagram.com/p/CxLrr_DAgFZ/"
            },
            {
                "image": "../../assets/images/goplana/instagram/goplana-IG-3.jpeg",
                "link": "https://www.instagram.com/p/CxIiJ9IL3i7/"
            },
            {
                "image": "../../assets/images/goplana/instagram/goplana-IG-4.jpeg",
                "link": "https://www.instagram.com/p/Cw7te_3LNSc/"
            },
            {
                "image": "../../assets/images/goplana/instagram/goplana-IG-5.jpeg",
                "link": "https://www.instagram.com/p/Cw2nw41AaeH/?img_index=1"
            },
            {
                "image": "../../assets/images/goplana/instagram/goplana-IG-6.jpeg",
                "link": "https://www.instagram.com/p/CwF2RfwL_Xp/?img_index=1"
            },
            {
                "image": "../../assets/images/goplana/instagram/goplana-IG-7.jpeg",
                "link": "https://www.instagram.com/p/Cv0MU0sA2vM/"
            },
            {
                "image": "../../assets/images/goplana/instagram/goplana-IG-8.jpeg",
                "link": "https://www.instagram.com/p/Cu1wcnYrRcs/"
            },
            {
                "image": "../../assets/images/goplana/instagram/goplana-IG-9.jpeg",
                "link": "https://www.instagram.com/p/Ct4OSeDrIH4/?img_index=1"
            },
            {
                "image": "../../assets/images/goplana/instagram/goplana-IG-10.jpeg",
                "link": "https://www.instagram.com/p/Cs7Pu0NgnKX/"
            },
            {
                "image": "../../assets/images/goplana/instagram/goplana-IG-11.jpeg",
                "link": "https://www.instagram.com/p/CstlFYZLYzU/?img_index=1"
            },
            {
                "image": "../../assets/images/goplana/instagram/goplana-IG-12.jpeg",
                "link": "https://www.instagram.com/p/CqEZuTyLdkM/?img_index=1"
            },
        ]
        this.piriPiri = [
            {
                "image": "../../assets/images/piri-piri/instagram/piri-piri-IG-1.png",
                "link": "https://www.instagram.com/p/Cyd_e7Kg0p9/"
            },
            {
                "image": "../../assets/images/piri-piri/instagram/piri-piri-IG-2.png",
                "link": "https://www.instagram.com/p/CyTqe7DLv3n/"
            },
            {
                "image": "../../assets/images/piri-piri/instagram/piri-piri-IG-3.png",
                "link": "https://www.instagram.com/p/CyOrzKMAvX9/"
            },
            {
                "image": "../../assets/images/piri-piri/instagram/piri-piri-IG-4.png",
                "link": "https://www.instagram.com/p/CyEZLKWgyIC/"
            },
            {
                "image": "../../assets/images/piri-piri/instagram/piri-piri-IG-5.png",
                "link": "https://www.instagram.com/p/CxgnwmLLgAT/"
            },
            {
                "image": "../../assets/images/piri-piri/instagram/piri-piri-IG-6.png",
                "link": "https://www.instagram.com/p/CxWgWpYrrN2/"
            },
            {
                "image": "../../assets/images/piri-piri/instagram/piri-piri-IG-7.png",
                "link": "https://www.instagram.com/p/CxONMPLrI5w/"
            },
            {
                "image": "../../assets/images/piri-piri/instagram/piri-piri-IG-8.png",
                "link": "https://www.instagram.com/p/CxGXvYbLNN8/"
            },
            {
                "image": "../../assets/images/piri-piri/instagram/piri-piri-IG-9.png",
                "link": "https://www.instagram.com/p/CxD9EdKgAYM/"
            },
            {
                "image": "../../assets/images/piri-piri/instagram/piri-piri-IG-10.png",
                "link": "https://www.instagram.com/p/Cw5PMeFL8C7/"
            },
            {
                "image": "../../assets/images/piri-piri/instagram/piri-piri-IG-11.png",
                "link": "https://www.instagram.com/p/Cw0zujkAqFx/"
            },
            {
                "image": "../../assets/images/piri-piri/instagram/piri-piri-IG-12.png",
                "link": "https://www.instagram.com/p/CwakLpWL39s/"
            },
        ]
        this.ukiyaIzakaya = [
            {
                "image": "../../assets/images/ukiya-izakaya/instagram/ukiya-izakaya-IG-1.png",
                "link": "https://www.instagram.com/p/CyWuBZysbrW/"
            },
            {
                "image": "../../assets/images/ukiya-izakaya/instagram/ukiya-izakaya-IG-2.png",
                "link": "https://www.instagram.com/p/CyMhqzqh_wo/"
            },
            {
                "image": "../../assets/images/ukiya-izakaya/instagram/ukiya-izakaya-IG-3.png",
                "link": "https://www.instagram.com/p/CvM9OvmLXE2/"
            },
            {
                "image": "../../assets/images/ukiya-izakaya/instagram/ukiya-izakaya-IG-4.png",
                "link": "https://www.instagram.com/p/CufF0BHrhdO/"
            },
            {
                "image": "../../assets/images/ukiya-izakaya/instagram/ukiya-izakaya-IG-5.png",
                "link": "https://www.instagram.com/p/CuIWWVeL2P-/"
            },
            {
                "image": "../../assets/images/ukiya-izakaya/instagram/ukiya-izakaya-IG-6.png",
                "link": "https://www.instagram.com/p/CrY7_lpg6Oe/"
            },
            {
                "image": "../../assets/images/ukiya-izakaya/instagram/ukiya-izakaya-IG-7.png",
                "link": "https://www.instagram.com/p/CrPSPPerFzw/"
            },
            {
                "image": "../../assets/images/ukiya-izakaya/instagram/ukiya-izakaya-IG-8.png",
                "link": "https://www.instagram.com/p/Cq8ExZArTj6/"
            },
            {
                "image": "../../assets/images/ukiya-izakaya/instagram/ukiya-izakaya-IG-9.png",
                "link": "https://www.instagram.com/p/Cq4KpDKrPYQ/"
            },
            {
                "image": "../../assets/images/ukiya-izakaya/instagram/ukiya-izakaya-IG-10.png",
                "link": "https://www.instagram.com/p/CqD-qdpvsrp/"
            },
            {
                "image": "../../assets/images/ukiya-izakaya/instagram/ukiya-izakaya-IG-11.png",
                "link": "https://www.instagram.com/p/Cp3CWQDAVSZ/"
            },
            {
                "image": "../../assets/images/ukiya-izakaya/instagram/ukiya-izakaya-IG-12.png",
                "link": "https://www.instagram.com/p/CpkpTBuo9uw/"
            },
        ]
        this.livSalades = [
            {
                "image": "../../assets/images/liv-salades/instagram/liv-salades-IG-1.png",
                "link": "https://www.instagram.com/p/Cu4QSSCgcjF/?hl=en"
            },
            {
                "image": "../../assets/images/liv-salades/instagram/liv-salades-IG-2.png",
                "link": "https://www.instagram.com/p/CxvmT7XrGPN/?hl=en"
            },
            {
                "image": "../../assets/images/liv-salades/instagram/liv-salades-IG-3.png",
                "link": "https://www.instagram.com/p/CyLlszQLZ2S/?hl=en"
            },
            {
                "image": "../../assets/images/liv-salades/instagram/liv-salades-IG-4.png",
                "link": "https://www.instagram.com/p/CbsRcp8r1aU/?hl=en"
            },
            {
                "image": "../../assets/images/liv-salades/instagram/liv-salades-IG-5.png",
                "link": "https://www.instagram.com/p/CwQjpo5Rb3Y/?hl=en"
            },
            {
                "image": "../../assets/images/liv-salades/instagram/liv-salades-IG-6.png",
                "link": "https://www.instagram.com/p/CiNY8CjL9_4/?hl=en"
            },
            {
                "image": "../../assets/images/liv-salades/instagram/liv-salades-IG-7.png",
                "link": "https://www.instagram.com/p/CtwdjGSrl1d/?hl=en"
            },
            {
                "image": "../../assets/images/liv-salades/instagram/liv-salades-IG-8.png",
                "link": "https://www.instagram.com/p/Cu9cPh5gu6V/?hl=en"
            },
            {
                "image": "../../assets/images/liv-salades/instagram/liv-salades-IG-9.png",
                "link": "https://www.instagram.com/p/Ck_O-gOLihT/?hl=en"
            },
            {
                "image": "../../assets/images/liv-salades/instagram/liv-salades-IG-10.png",
                "link": "https://www.instagram.com/p/Cg4ueshL-zv/?hl=en"
            },
            {
                "image": "../../assets/images/liv-salades/instagram/liv-salades-IG-11.png",
                "link": "https://www.instagram.com/p/Cgb1OClAX7x/?hl=en"
            },
            {
                "image": "../../assets/images/liv-salades/instagram/liv-salades-IG-12.png",
                "link": "https://www.instagram.com/p/CYoj3R1rwL0/?hl=en"
            },
        ]
        this.downtownDhaba = [
            {
                "image": "../../assets/images/downtown-dhaba/instagram/downtown-dhaba-IG-1.png",
                "link": "https://www.instagram.com/p/CuOeA-NN95A/"
            },
            {
                "image": "../../assets/images/downtown-dhaba/instagram/downtown-dhaba-IG-2.png",
                "link": "https://www.instagram.com/p/CuJKAXkt71g/"
            },
            {
                "image": "../../assets/images/downtown-dhaba/instagram/downtown-dhaba-IG-3.png",
                "link": "https://www.instagram.com/p/CuG1qS5NPB7/"
            },
            {
                "image": "../../assets/images/downtown-dhaba/instagram/downtown-dhaba-IG-4.png",
                "link": "https://www.instagram.com/p/CuG1qS5NPB7/"
            },
            {
                "image": "../../assets/images/downtown-dhaba/instagram/downtown-dhaba-IG-5.png",
                "link": "https://www.instagram.com/p/CuCP-C8MEaZ/"
            },
            {
                "image": "../../assets/images/downtown-dhaba/instagram/downtown-dhaba-IG-6.png",
                "link": "https://www.instagram.com/p/CuCP5HbMsGH/"
            },
        ]
        this.grillados = [
            {
                "image": "../../assets/images/grillados/instagram/grillados-IG-1.png",
                "link": "https://www.instagram.com/p/CzR7B_VstKo/"
            },
            {
                "image": "../../assets/images/grillados/instagram/grillados-IG-2.png",
                "link": "https://www.instagram.com/p/CzO-t_EMttp/"
            },
            {
                "image": "../../assets/images/grillados/instagram/grillados-IG-3.png",
                "link": "https://www.instagram.com/p/CzKBcbSM6gH/"            },
            {
                "image": "../../assets/images/grillados/instagram/grillados-IG-4.png",
                "link": "https://www.instagram.com/p/Cy84RsvMO58/"            },
            {
                "image": "../../assets/images/grillados/instagram/grillados-IG-5.png",
                "link": "https://www.instagram.com/p/Cy37hqfsB0_/"            },
            {
                "image": "../../assets/images/grillados/instagram/grillados-IG-6.png",
                "link": "https://www.instagram.com/p/CyywW4KsnBq/"            },
            {
                "image": "../../assets/images/grillados/instagram/grillados-IG-7.png",
                "link": "https://www.instagram.com/p/CyrHyRXM4eU/"            },
            {
                "image": "../../assets/images/grillados/instagram/grillados-IG-8.png",
                "link": "https://www.instagram.com/p/CyoIoStMwjo/"            },
            {
                "image": "../../assets/images/grillados/instagram/grillados-IG-9.png",
                "link": "https://www.instagram.com/p/Cyb09j6sXFq/"            },
            {
                "image": "../../assets/images/grillados/instagram/grillados-IG-10.png",
                "link": "https://www.instagram.com/p/CyZFeWdsgnc/"            },
            {
                "image": "../../assets/images/grillados/instagram/grillados-IG-11.png",
                "link": "https://www.instagram.com/p/CyRV6_Islgn/"            },
            {
                "image": "../../assets/images/grillados/instagram/grillados-IG-12.png",
                "link": "https://www.instagram.com/p/Cx0xNnRMW0D/"            },
        ]
    }

    // RestoPage TODO: Add Instagram images above (max 15)

    ngOnInit(): void {
        //this.getRestaurantMedia();
    }

    getRestaurantMedia() {
        if (this.restaurant.slug === RestaurantSlug.Maharani) return this.maharaniMedia;
        else if (this.restaurant.slug === RestaurantSlug.Enchanteur) return this.enchanteurMedia;
        else if (this.restaurant.slug === RestaurantSlug.Lyla) return this.lylaMedia;
        else if (this.restaurant.slug === RestaurantSlug.Diolo) return this.dioloMedia;
        else if (this.restaurant.slug === RestaurantSlug.Arahova) return this.arahovaMedia;
        else if (this.restaurant.slug === RestaurantSlug.Delicieux) return this.delicieuxMedia;
        else if (this.restaurant.slug === RestaurantSlug.Sham) return this.shamMedia;
        else if (this.restaurant.slug === RestaurantSlug.Nakamichi) return this.nakamichiMedia;
        else if (this.restaurant.slug === RestaurantSlug.PtitPlateau) return this.ptitPlateauMedia;
        else if (this.restaurant.slug == RestaurantSlug.Croqueteria) return this.croqueteriaMedia;
        //else if (this.restaurant.slug === 'sushi-plus-beaumont') return this.sushiPlusBeaumontMedia;
        else if (this.restaurant.slug === RestaurantSlug.KojiSoupe) return this.kojiSoupeMedia;
        else if (this.restaurant.slug === RestaurantSlug.LundisAuSoleil) return this.lundisAuSoleilMedia;
        else if (this.restaurant.slug === RestaurantSlug.TincSet) return this.tincSetMedia;
        else if (this.restaurant.slug === RestaurantSlug.FanfarePizza) return this.fanfarePizzaMedia;
        else if (this.restaurant.slug === RestaurantSlug.Basha) return this.bashaMedia;
        else if (this.restaurant.slug === RestaurantSlug.Uluwatu) return this.uluwatuMedia;
        else if (this.restaurant.slug === RestaurantSlug.Boustan) return this.boustanMedia;
        else if (this.restaurant.slug === RestaurantSlug.ThaiExpressCdn) return this.thaiExpressCdnMedia;
        else if (this.restaurant.slug === RestaurantSlug.JavaUCdn) return this.javaUCdnMedia;
        else if (this.restaurant.slug === RestaurantSlug.McgillPizza) return this.mcgillPizzaMedia;
        else if (this.restaurant.slug === RestaurantSlug.Yuan) return this.yuanMedia;
        else if (this.restaurant.slug === RestaurantSlug.Segreta) return this.segretaMedia;
        else if (this.restaurant.slug === RestaurantSlug.OMLT) return this.omltMedia;
        else if (this.restaurant.slug === RestaurantSlug.QuesadaCdn) return this.quesadaCdnMedia;
        else if (this.restaurant.slug === RestaurantSlug.OiseauxDePassage) return this.oiseauxDePassageMedia;
        else if (this.restaurant.slug === RestaurantSlug.BurgerBros) return this.burgerBrosMedia;
        else if (this.restaurant.slug === RestaurantSlug.Julians) return this.juliansMedia;
        else if (this.restaurant.slug === RestaurantSlug.SpiceBros) return this.spiceBrosMedia;
        else if (this.restaurant.slug === RestaurantSlug.SantaLucia) return this.santaLuciaMedia;
        else if (this.restaurant.slug === RestaurantSlug.oFour) return this.oFourMedia;
        else if (this.restaurant.slug === RestaurantSlug.Karin) return this.karinMedia;
        else if (this.restaurant.slug === RestaurantSlug.MarcheMarema) return this.marcheMaremaMedia;
        else if (this.restaurant.slug === RestaurantSlug.BoiteVegane) return this.boiteVeganeMedia;
        else if (this.restaurant.slug === RestaurantSlug.HeroBurgers) return this.heroBurgersMedia;
        else if (this.restaurant.slug === RestaurantSlug.CafeResonance) return this.cafeResonanceMedia;
        else if (this.restaurant.slug === RestaurantSlug.Melrose) return this.melroseMedia;
        else if (this.restaurant.slug === RestaurantSlug.Mintar) return this.mintarMedia;
        else if (this.restaurant.slug === RestaurantSlug.Cacao70) return this.cacao70Media;
        else if (this.restaurant.slug === RestaurantSlug.LaToile) return this.laToileMedia;
        else if (this.restaurant.slug === RestaurantSlug.SepLai) return this.sepLaiMedia;
        else if (this.restaurant.slug === RestaurantSlug.ChaiTeaLounge) return this.chaiTeaLoungeMedia;
        else if (this.restaurant.slug === RestaurantSlug.LaCroissanterieFigaro) return this.laCroissanterieFigaroMedia;
        else if (this.restaurant.slug === RestaurantSlug.AuxMerveilleuxDeFred) return this.auxMerveilleuxDeFredMedia;
        else if (this.restaurant.slug === RestaurantSlug.TacosNation) return this.tacosNationMedia;
        else if (this.restaurant.slug === RestaurantSlug.LaFabriqueDeBagel) return this.laFabriqueDeBagelMedia;
        else if (this.restaurant.slug === RestaurantSlug.StudentTastyBiryani) return this.studentTastyBiryani;
        else if (this.restaurant.slug === RestaurantSlug.Lalis) return this.lali;
        else if (this.restaurant.slug === RestaurantSlug.ChezTaMere) return this.chezTaMere;
        else if (this.restaurant.slug === RestaurantSlug.FriteAlors) return this.friteAlors;
        else if (this.restaurant.slug === RestaurantSlug.Tibum) return this.tibum;
        else if (this.restaurant.slug === RestaurantSlug.ThePerle) return this.thePerle;
        else if (this.restaurant.slug === RestaurantSlug.Gongcha) return this.gongCha;
        else if (this.restaurant.slug === RestaurantSlug.BuffaloBill) return this.buffaloBill;
        else if (this.restaurant.slug === RestaurantSlug.SouvlakiGrecPlus) return this.souvlakiGrecPlus;
        else if (this.restaurant.slug === RestaurantSlug.Tsujiri) return this.tsujiri;
        else if (this.restaurant.slug === RestaurantSlug.SpartaChicken) return this.spartaChicken;
        else if (this.restaurant.slug === RestaurantSlug.P23Dumplings) return this.p23Dumplings;
        else if (this.restaurant.slug === RestaurantSlug.BurgzCantine) return this.burgzCantine;
        else if (this.restaurant.slug === RestaurantSlug.Goplana) return this.goplana;
        else if (this.restaurant.slug === RestaurantSlug.PiriPiri) return this.piriPiri;
        else if (this.restaurant.slug === RestaurantSlug.UkiyaIzakaya) return this.ukiyaIzakaya;
        else if (this.restaurant.slug === RestaurantSlug.LivSalades) return this.livSalades;
        else if (this.restaurant.slug === RestaurantSlug.DowntownDhaba) return this.downtownDhaba;
        else if (this.restaurant.slug === RestaurantSlug.Grillados) return this.grillados;
        else return []
    }

    // RestoPage TODO: Add restaurant slug else if case above

    //checks whether it is of sequence 3*n - 1 in order to properly align the photos
    isMediaOffNumber() {
        let media = this.getRestaurantMedia();
        if (media.length > 1) {
            for (let n = 0; n < 30; n++) {
                if (media.length === 3 * n - 1) return true;
            }
            return false;
        }
    }

    /*     getRestaurantMedia() {
            this.http.get("")
                .subscribe(
                    res => {
                        console.log(res);
                        //only pushing photos to instagramMedia
                        res["data"].forEach(item => {
                            if (item.media_type === "CAROUSEL_ALBUM") {
                                this.instagramMedia.push(item);
                            }
                        });
                    },
                    err => console.log(err)
                );
        } */

}
