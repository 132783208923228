import { Component, OnDestroy, OnInit } from '@angular/core';
import { ConfigService } from 'src/app/core/services/config.service';
import { Menu, MenuSection, Restaurant } from 'src/app/core/models';
import { Observable, Subscription } from 'rxjs';
import { TranslocoService } from '@ngneat/transloco';
import { RestaurantPageV2Service } from 'src/app/core/services/data/restaurant-page-v2.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Category, RestaurantStyle } from 'src/app/core/enums';
import { MainService } from 'src/app/core/services/main.service';

@Component({
    selector: 'app-menu-sections',
    templateUrl: './menu-sections.component.html',
    styleUrls: ['./menu-sections.component.css']
})
export class MenuSectionsComponent implements OnInit, OnDestroy {
    private readonly subscription: Subscription = new Subscription();

    restaurant$: Observable<Restaurant>
    menu$: Observable<Menu>
    menuType: string
    menu: Menu

    showSection: boolean = true

    Category = Category
    RestaurantStyle = RestaurantStyle

    constructor(
        public configService: ConfigService,
        private mainService: MainService,
        public translate: TranslocoService,
        private restaurantV2Service: RestaurantPageV2Service,
        private router: Router,
        private route: ActivatedRoute) {
            this.subscription.add(
                router.events.subscribe((val: NavigationEnd) => {
                    if (val.url) {
                        this.showSection = !this.isChildRouteSection(val.url)
                    }
                })
            )
        }

    ngOnInit(): void {
        this.restaurant$ = this.mainService.restaurantLoaded$;
        this.menu$ = this.mainService.menuLoaded$;
        const menuType$ = this.mainService.orderTypeInViewLoaded$;

        this.showSection = !this.isChildRouteSection(this.router.url)

        // this.subscription.add(
        //     combineLatest([restaurant$, menuType$])
        //         // .pipe(takeWhile(([restaurant, type]) => restaurant == null || type == null, true))
        //         .subscribe(([restaurant, type]) => {

        //             if (restaurant && type && (restaurant?.id != this.restaurant?.id || type != this.menuType)) {
        //                 this.menu = this.menuService.getMenuOfType(restaurant.menus, type)
        //                 this.setMenuImage()
        //             }

        //             this.restaurant = restaurant
        //             this.menuType = type
        //         })
        // )
    }

    isChildRouteSection(url: string): boolean {
        const split = url.split('/')
        return url.split('/')[split.length - 1] == 'section'
    }

    setMenuImage() {
        this.restaurantV2Service.updateImage(this.menu?.primaryImageUrl)
    }

    onClickSection(section: MenuSection) {
        if (section?.imageUrl) {
            this.restaurantV2Service.updateImage(section.imageUrl)
        }
        this.restaurantV2Service.selectMenuSection(section)
        this.router.navigate(['section'], {relativeTo: this.route})
        // else {
        //     this.setMenuImage()
        // }
        // setTimeout(_ => {
        //     if (!this.isMobile) {
        //         let menuContainer = document.getElementById('restaurant-v2-content-container');
        //         (menuContainer as HTMLElement).scrollTop = 0
        //     } else {
        //         let menuContainer = document.getElementById(section._id + this.scrollToAnchorSuffix);
        //         (menuContainer as HTMLElement).scrollIntoView()
        //     }
        // }, 0)
    }

    getSectionImage(restaurant: Restaurant, section: MenuSection, options = {checkBackup: false}): string {
        if (section?.media[0] && section.media[0].url) {
            return section.media[0].url
        } else if (options.checkBackup) {
            return this.configService.getDefaultImage(restaurant.slug)
        }
    }

    createIcon() {

    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

}
