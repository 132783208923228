import { Component, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-gift-card-applied',
  templateUrl: './gift-card-applied.component.html',
  styleUrls: ['./gift-card-applied.component.css']
})
export class GiftCardAppliedComponent implements OnInit {

  constructor(
    public activeModal: NgbActiveModal,
    public translate: TranslocoService) { }

  ngOnInit(): void {
  }

}
