<div *ngIf="item.quantity > 0" class="bag-item" [ngClass]="{'option-update': displayType == 'option-update'}">
    <div class="bag-item-top">
        <div class="bag-item-left" [class.bag-item-left-option]="displayType == 'option-update'">
            <div class="bag-item-quantity">
                <strong>{{ item.quantity }} x</strong>
            </div>
            <div *ngIf="displayType != 'option-update'" class="bag-item-name" [innerHTML]="item.product.title"></div>
            <div *ngIf="displayType == 'option-update'" class="bag-item-bottom">
                <div class="bag-item-options" *ngIf="item.options.length > 0">
                    {{ 'bagItem.with' | transloco }}
                    <ng-container *ngFor="let option of item.options">
                        <span *ngFor="let product of option.products; let i = index" class="bag-item-options-product"
                            [innerHTML]="product.title">
                            <span *ngIf="i != option.products.length - 1">, </span>
                        </span>
                    </ng-container>
                </div>
                <div class="bag-item-options" *ngIf="item.options.length < 1">
                    {{ 'bagItem.withoutOptions' | transloco }}
                </div>
                <div *ngIf="item.notes && showNotes" class="bag-item-special-request"><b>Note: </b>{{ item.notes }}
                </div>
            </div>
        </div>
        <div class="bag-item-right">
            <div *ngIf="!orderPending" class="bag-item-buttons">
                <button *ngIf="showDecrementControl" type="button" class="bag-item-button bag-item-buttons-minus"
                    (click)="decrementItem(item)" [disabled]="disableDecrementBagItemsControls(item)">
                    <img src="../../assets/images/icons/minus-white.svg">
                </button>
                <button *ngIf="showIncrementControl" type="button" class="bag-item-button bag-item-buttons-plus"
                    (click)="incrementItem(item)" [disabled]="disableBagItemsControls()">
                    <img src="../../assets/images/icons/plus-white.svg">
                </button>
            </div>
            <div *ngIf="showPrice" class="bag-item-price">
                {{ item.price | price | translocoCurrency }}
            </div>
        </div>
    </div>
    <div *ngIf="displayType != 'option-update'" class="bag-item-bottom">
        <div class="bag-item-options" *ngIf="item.options.length > 0">
            <ng-container *ngFor="let option of item.options">
                <div *ngFor="let product of option.products" class="bag-item-options-product"
                    [innerHTML]="product.title"></div>
            </ng-container>
        </div>
        <div *ngIf="item.notes && showNotes" class="bag-item-special-request"><b>Note: </b>{{ item.notes }}</div>
    </div>
</div>