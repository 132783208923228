<div *ngIf="(menu$ | async)" id="menu-container"
    [ngClass]="{'v2-menu': configService.getPageVersion((restaurant$ | async)?.slug) === RestaurantStyle.PictureBook}">
    <ng-container *ngIf="configService.getPageVersion((restaurant$ | async)?.slug) === RestaurantStyle.PictureBook">
        <div *ngIf="!isMobile; else mobileNav" class="v2-menu-sections-nav-side"
            [style.background-color]="configService.getDesktopNavbarBackgroundColor((restaurant$ | async)?.slug)">
            <button type="button" class="back-to-menu-button"
                [style.background-color]="configService.getPrimaryColor((restaurant$ | async)?.slug)"
                [style.border-top-right-radius]="configService.getBorderRadius((restaurant$ | async)?.slug)"
                (click)="onClickBackToMenuButton()" appClickStopPropagation>
                <svg [style.height.rem]="1.2" [style.width.rem]="1.2" viewBox="0 0 6.3499999 6.3500002" class="">
                    <g>
                        <g transform="translate(0 -290.65)">
                            <path id="path9417"
                                d="m1.8505492 292.23437a.26460982.26460982 0 0 0 -.1819011.0801l-1.32239993 1.32395a.26460982.26460982 0 0 0 0 .3731l1.32239993 1.32447a.26528594.26528594 0 0 0 .3751707-.37517l-.8707477-.87075h4.6477783a.2648413.2648413 0 1 0 0-.52968h-4.6498455l.8728149-.87075a.26460982.26460982 0 0 0 -.1932696-.45527z"
                                font-variant-ligatures="normal" font-variant-position="normal"
                                font-variant-caps="normal" font-variant-numeric="normal"
                                font-variant-alternates="normal" font-feature-settings="normal" text-indent="0"
                                text-align="start" text-decoration-line="none" text-decoration-style="solid"
                                text-decoration-color="rgb(0,0,0)" text-transform="none" text-orientation="mixed"
                                white-space="normal" shape-padding="0" isolation="auto" mix-blend-mode="normal"
                                solid-color="rgb(0,0,0)" solid-opacity="1" vector-effect="none" fill="#ffffff"
                                data-original="#000000" class="" />
                        </g>
                    </g>
                </svg>
            </button>
            <div class="v2-menu-sections-list">
                <div *ngFor="let section of (menu$ | async).sections" class="v2-menu-section-nav-item"
                    [ngClass]="{'menu-section-selected': section.id == sectionToShow?.id}"
                    (click)="onClickSection(section)" appClickStopPropagation>
                    <span [style.border-bottom-color]="configService.getPrimaryColor((restaurant$ | async)?.slug)"
                        [style.text-transform]="configService.getSectionTitleTextTransform((restaurant$ | async)?.slug)"
                        [ngStyle]="{'color': section.id == sectionToShow?.id ? configService.getPrimaryColor((restaurant$ | async)?.slug) : '#000000'}"
                        class="v2-menu-section-nav-item-text">
                        {{ section.title }}
                    </span>
                </div>
            </div>
        </div>
        <ng-template #mobileNav>
            <div class="v2-menu-sections-nav-mobile"
                [style.backgroundColor]="configService.getMobileNavbarBackgroundColor((restaurant$ | async)?.slug)">
                <app-horizontal-mobile-sections-nav [sections]="(menu$ | async).sections" [selectedSection]="sectionToShow"
                    (onSectionClicked)="onClickSection($event)"
                    [selectedColor]="configService.getPrimaryColor((restaurant$ | async)?.slug)"
                    [endsGradientColor]="configService.getMobileNavbarBackgroundColor((restaurant$ | async)?.slug)">
                </app-horizontal-mobile-sections-nav>
            </div>
        </ng-template>
    </ng-container>
    <ng-container *ngIf="configService.isMenuSelectable((restaurant$ | async)?.slug) && (restaurant$ | async)?.menus && (restaurant$ | async)?.menus.length > 1">
        <div class="restaurant-menu-tabs">
            <button *ngFor="let m of getOrderTypeMenus((restaurant$ | async)?.menus)" (click)="onClickMenu(m.slug)" class="restaurant-menu-tab" appClickStopPropagation
                [ngStyle]="{
                'font-family': configService.getMenuTabStyle((restaurant$ | async)?.slug).font,
                'font-weight': m.id == (menu$ | async)?.id ? configService.getMenuTabStyle((restaurant$ | async)?.slug).active.fontWeight : configService.getMenuTabStyle((restaurant$ | async)?.slug).inactive.fontWeight,
                'background-color': m.id == (menu$ | async)?.id ? configService.getMenuTabStyle((restaurant$ | async)?.slug).active.backgroundColor : configService.getMenuTabStyle((restaurant$ | async)?.slug).inactive.backgroundColor,
                'color': m.id == (menu$ | async)?.id ? configService.getMenuTabStyle((restaurant$ | async)?.slug).active.color : configService.getMenuTabStyle((restaurant$ | async)?.slug).inactive.color,
                'border': m.id == (menu$ | async)?.id ? configService.getMenuTabStyle((restaurant$ | async)?.slug).active.border : configService.getMenuTabStyle((restaurant$ | async)?.slug).inactive.border,
                'border-bottom': m.id == (menu$ | async)?.id ? configService.getMenuTabStyle((restaurant$ | async)?.slug).active.borderBottom : configService.getMenuTabStyle((restaurant$ | async)?.slug).inactive.borderBottom,
                'padding': configService.getMenuTabStyle((restaurant$ | async)?.slug).padding
            }">{{ m.title }}</button>
        </div>
    </ng-container>
    <ngx-masonry class="restaurant-menu-container" [class.card-columns]="configService.getPageVersion((restaurant$ | async)?.slug) === RestaurantStyle.Classic"
        [ngClass]="configService.getCssClass((restaurant$ | async)?.slug)"
        [class.v2]="configService.getPageVersion((restaurant$ | async)?.slug) === RestaurantStyle.PictureBook" [ordered]="true">
        <div class="row">
            <div ngxMasonryItem *ngFor="let section of (sectionToShow && !isMobile ? [sectionToShow] : (menu$ | async)?.sections)"
                [class.card]="configService.getPageVersion((restaurant$ | async)?.slug) === RestaurantStyle.Classic"
                class="restaurant-menu-category-container masonry-item col-sm-12" 
                [ngClass]="{'col-md-6': configService.getMenuStyle((restaurant$ | async)?.slug) === MenuStyle.Standard}"[id]="section.id">
                <div [id]="section.id + scrollToAnchorSuffix" style="position: absolute; top: -3rem; left: 0"></div>
                <h5 class="restaurant-menu-category-name" [style.color]="configService.getPrimaryColor((restaurant$ | async)?.slug)"
                    [innerHTML]="section.title"></h5>
                <div *ngIf="section.description" class="restaurant-menu-section-description"
                    [innerHTML]="section.description"></div>
                <ng-container *ngIf="configService.getPageVersion((restaurant$ | async)?.slug) === RestaurantStyle.Classic; else pageVersion2">
                    <div *ngIf="configService.getMenuItemStyle((restaurant$ | async)?.slug) === MenuItemStyle.Image"
                        [class.menu-section-wrapped]="configService.getMenuItemStyle((restaurant$ | async)?.slug) === MenuItemStyle.Image">
                        <app-menu-item-with-image class="menu-item-with-image" *ngFor="let product of section.products"
                            [product]="product"
                            [restaurant]="(restaurant$ | async)"
                            [restaurantCssClass]="configService.getCssClass((restaurant$ | async)?.slug)"
                            [tooltip]="getTooltipContent(product)"
                            [disableModifyProductCount]="disableModifyProductCount(product)"
                            (onRemoveItem)="clickRemoveItem($event)"
                            [isItemInBag]="(bag$ | async)?.hasItem(product)"
                            (onAddItem)="clickAddItem($event)"
                            [bag]="bag$ | async"
                            (onNewItemWithOptions)="newItemWithOptionsClick($event)"
                            (onItemOptionsClick)="itemOptionsClickHandler($event)"
                            [productIncrementOptionsToShow]="productIncrementOptionsToShow"
                            [productDecrementOptionsToShow]="productDecrementOptionsToShow"
                            [buttonColor]="configService.getMenuButtonColor((restaurant$ | async)?.slug)"
                            [buttonIconColor]="configService.getMenuButtonIconColor((restaurant$ | async)?.slug)"
                            [productStyle]="configService.getMenuProduct((restaurant$ | async)?.slug)"
                            [disabledButtonColor]="configService.getMenuButtonDisabledColor((restaurant$ | async)?.slug)"
                            [ngStyle]="{'margin-bottom': configService.getMenuProduct((restaurant$ | async)?.slug).marginBottom}">
                        </app-menu-item-with-image>
                    </div>
                    <ng-container *ngIf="configService.getMenuItemStyle((restaurant$ | async)?.slug) === MenuItemStyle.NoImage">
                        <app-menu-item *ngFor="let product of section.products" [product]="product"
                            [restaurantCssClass]="configService.getCssClass((restaurant$ | async)?.slug)"
                            [disableModifyProductCount]="disableModifyProductCount(product)"
                            [tooltip]="getTooltipContent(product)"
                            (onRemoveItem)="clickRemoveItem($event)"
                            [isItemInBag]="(bag$ | async)?.hasItem(product)"
                            (onAddItem)="clickAddItem($event)"
                            [bag]="(bag$ | async)"
                            (onNewItemWithOptions)="newItemWithOptionsClick($event)"
                            (onItemOptionsClick)="itemOptionsClickHandler($event)"
                            [productIncrementOptionsToShow]="productIncrementOptionsToShow"
                            [productDecrementOptionsToShow]="productDecrementOptionsToShow"
                            [buttonColor]="configService.getMenuButtonColor((restaurant$ | async)?.slug)"
                            [buttonIconColor]="configService.getMenuButtonIconColor((restaurant$ | async)?.slug)"
                            [disabledButtonColor]="configService.getMenuButtonDisabledColor((restaurant$ | async)?.slug)">
                        </app-menu-item>
                    </ng-container>
                    <ng-container *ngIf="configService.getMenuItemStyle((restaurant$ | async)?.slug) === MenuItemStyle.HorizontalWithOptionalImage">
                        <app-menu-item-horizontal-image *ngFor="let product of section.products" [product]="product"
                            [disableModifyProductCount]="disableModifyProductCount(product)"
                            [tooltip]="getTooltipContent(product)"
                            (onRemoveItem)="clickRemoveItem($event)"
                            [isItemInBag]="(bag$ | async)?.hasItem(product)"
                            (onAddItem)="clickAddItem($event)"
                            [bag]="(bag$ | async)"
                            (onNewItemWithOptions)="newItemWithOptionsClick($event)"
                            (onItemOptionsClick)="itemOptionsClickHandler($event)"
                            [productIncrementOptionsToShow]="productIncrementOptionsToShow"
                            [productDecrementOptionsToShow]="productDecrementOptionsToShow"
                            [buttonColor]="configService.getMenuButtonColor((restaurant$ | async)?.slug)"
                            [buttonIconColor]="configService.getMenuButtonIconColor((restaurant$ | async)?.slug)"
                            [disabledButtonColor]="configService.getMenuButtonDisabledColor((restaurant$ | async)?.slug)"
                            [productStyle]="configService.getMenuProduct((restaurant$ | async)?.slug)"
                            [largeFormat]="(menu$ | async)?.itemCount < 3"
                            (onProductClick)="clickProduct($event)">
                        </app-menu-item-horizontal-image>
                    </ng-container>
                </ng-container>
                <ng-template #pageVersion2>
                    <div class="menu-items">
                        <app-menu-item-v2 class="menu-item-v2" *ngFor="let product of section.products" [product]="product"
                            [restaurantCssClass]="configService.getCssClass((restaurant$ | async)?.slug)"
                            [disableModifyProductCount]="disableModifyProductCount(product)"
                            [tooltip]="getTooltipContent(product)"
                            (onRemoveItem)="clickRemoveItem($event)"
                            [isItemInBag]="(bag$ | async)?.hasItem(product)"
                            (onAddItem)="clickAddItem($event)"
                            [bag]="(bag$ | async)"
                            (onNewItemWithOptions)="newItemWithOptionsClick($event)"
                            (onItemOptionsClick)="itemOptionsClickHandler($event)"
                            [productIncrementOptionsToShow]="productIncrementOptionsToShow"
                            [productDecrementOptionsToShow]="productDecrementOptionsToShow"
                            [buttonColor]="configService.getMenuButtonColor((restaurant$ | async)?.slug)"
                            [borderRadius]="configService.getBorderRadius((restaurant$ | async)?.slug)"
                            [buttonIconColor]="configService.getMenuButtonIconColor((restaurant$ | async)?.slug)"
                            [disabledButtonColor]="configService.getMenuButtonDisabledColor((restaurant$ | async)?.slug)"
                            [font]="configService.getFont((restaurant$ | async)?.slug)"
                            [ngStyle]="{'box-shadow': '0px 7px 29px rgba(100, 100, 111, 0.2)'}"
                            (mouseenter)="onMouseEnterMenuItem(product, section)"
                            (mouseleave)="onMouseLeaveMenuItem(section)"
                            [image]="product?.assets?.primary?.thumbnails && product?.assets?.primary?.thumbnails[0] ? product?.assets?.primary?.thumbnails[0].url : ''"
                            [showDescription]="isMobile"
                            [descriptionFontWeight]="configService.getMenuItemDescriptionFontWeight((restaurant$ | async)?.slug)"
                            [descriptionColor]="configService.getMenuItemDescriptionColor((restaurant$ | async)?.slug)"
                            [showReadMoreText]="false">
                        </app-menu-item-v2>
                    </div>
                </ng-template>
            </div>
        </div>
        <app-restaurant-item-options [item]="selectedItem" (clearItem)="selectedItem = null"></app-restaurant-item-options>
    </ngx-masonry>
</div>
<div *ngIf="!(menu$ | async)" class="no-menu">
    <div *ngIf="(orderType$ | async) == 'delivery'">
        {{ 'restaurant.menu.noDeliveryMenu' | transloco: { restaurantName: (restaurant$ | async)?.name } }}
    </div>
    <div *ngIf="(orderType$ | async) == 'pickup'">
        {{ 'restaurant.menu.noPickupMenu' | transloco: { restaurantName: (restaurant$ | async)?.name } }}
    </div>
</div>
