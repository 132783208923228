import { Component, OnInit } from '@angular/core';
import { RestaurantService } from '../../../core/services/restaurant.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RestaurantReviewsCreateComponent } from './restaurant-reviews-create/restaurant-reviews-create.component';
import { AuthService } from '../../../core/services/auth.service';
import { TranslocoService } from '@ngneat/transloco';
import { UserService } from 'src/app/core/services/user.service';
import { User, Restaurant, Review } from 'src/app/core/models';

import { NgxGalleryOptions, NgxGalleryImage, NgxGalleryAnimation } from '@kolkov/ngx-gallery';

import { first } from 'rxjs/operators';
import { ConfigService } from 'src/app/core/services/config.service';
import { RestaurantSlug } from 'src/app/core/enums';
import { MainService } from 'src/app/core/services/main.service';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
    selector: 'app-restaurant-reviews',
    templateUrl: './restaurant-reviews.component.html',
    styleUrls: ['./restaurant-reviews.component.css'],
})
export class RestaurantReviewsComponent implements OnInit {

    user: User;
    reviews: Review[];
    averageRating: number;
    numRatings: {
        5: number;
        4: number;
        3: number;
        2: number;
        1: number;
    };
    searchReviews;
    userWroteReview: boolean = false;

    numFullStars: number = 0
    numHalfStars: number = 0
    numEmptyStars: number = 0

    galleryOptions: NgxGalleryOptions[];
    galleryImages: NgxGalleryImage[];

    restaurant: Restaurant;

    constructor(
        private mainService: MainService,
        private restaurantService: RestaurantService,
        private userService: UserService,
        private route: ActivatedRoute,
        private modalService: NgbModal,
        public authService: AuthService,
        public translate: TranslocoService,
        private router: Router,
        public configService: ConfigService
    ) {
        this.mainService.restaurantLoaded$
            .subscribe(restaurant => {
                if (restaurant != null) {
                    this.restaurant = restaurant
                    this.getReviews(restaurant)
                }
            })
    }

    ngOnInit() {
        this.galleryOptions = [
            {
                image: false,
                thumbnailsRemainingCount: true,
                width: '100%',
                height: '7rem',
                thumbnailsColumns: 4,
                arrowPrevIcon: 'fa fa-chevron-left',
                arrowNextIcon: 'fa fa-chevron-right',
                imageAnimation: NgxGalleryAnimation.Slide,
                previewAnimation: false
            },
            {
                breakpoint: 500,
                width: "100%",
                thumbnailsColumns: 3
            }
        ];
        this.numRatings = {
            5: 0,
            4: 0,
            3: 0,
            2: 0,
            1: 0,
        };
    }

    generateBarGradient(percent): string {
        percent *= 100;
        if (this.restaurant.slug === RestaurantSlug.Maharani || this.restaurant.slug === RestaurantSlug.Diolo || this.restaurant.slug === RestaurantSlug.MerRouge || this.restaurant.slug === RestaurantSlug.PtitPlateau || this.restaurant.slug === RestaurantSlug.FanfarePizza || this.restaurant.slug === RestaurantSlug.InochiExpress || this.restaurant.slug === RestaurantSlug.Barranco || this.restaurant.slug === RestaurantSlug.PetitSeoul || this.restaurant.slug === RestaurantSlug.Darbar) {
            return (
                'linear-gradient(90deg, rgba(100,100,100,1)' +
                percent +
                '%, rgba(230,230,230,1) ' +
                percent +
                '%)'
            );
        }
        else {
            return (
                'linear-gradient(90deg, rgba(196,196,196,1)' +
                percent +
                '%, rgba(237,237,237,1) ' +
                percent +
                '%)'
            );
        }

    }

    getReviews(restaurant: Restaurant) {
        this.restaurantService.getReviews(restaurant.slug)
            .pipe(first())
            .subscribe(reviews => {
                this.reviews = reviews;
                this.extractReviewData();
            })
    }

    extractReviewData() {
        let ratingSum = 0;
        for (let review of this.reviews) {
            ratingSum += review.rating;
            this.numRatings[review.rating]++;
        }
        let ratingAverage = ratingSum / this.reviews.length;
        this.averageRating = Math.round(ratingAverage * 10) / 10;
        this.numFullStars = Math.floor(this.averageRating)
        this.numHalfStars = this.averageRating % 1 != 0 ? 1 : 0
        this.numEmptyStars = 5 - this.numFullStars - this.numHalfStars

        if (this.user) {
            this.reviews.forEach(review => {
                if (review.user?.id === this.user.id) this.userWroteReview = true;
            })
        }
        let galleryImages: NgxGalleryImage[] = [];
        this.reviews.reverse().forEach(review => {
            review.photos.forEach(photo => {
                galleryImages.push({
                    small: photo.url,
                    medium: photo.url,
                    big: photo.url,
                    description: photo.description[this.translate.getActiveLang()]
                })
            })
        });
        this.galleryImages = galleryImages;
    }

    writeReviewClicked() {
        const slug = this.restaurant.slug
        if (!this.authService.loggedIn()) {
            localStorage.setItem('redirect_url', '/restaurants/' + slug + '/reviews');
            this.router.navigate([this.translate.getActiveLang() + '/login'])
        } else {
            const modalRef = this.modalService.open(RestaurantReviewsCreateComponent);
            modalRef.componentInstance.slug = slug;
            modalRef.componentInstance.restaurant = this.restaurant;

            modalRef.result.then(
                res => {
                    if (res) {
                        this.restaurantService.getReviews(this.restaurant.slug)
                            .pipe(first())
                            .subscribe(
                                reviews => {
                                    this.reviews = reviews
                                    this.extractReviewData()
                                }
                            )
                    }
                }
            )
        }
    }

    getIconColor() {
        return this.configService.getReviewIconColor(this.restaurant.slug)
    }
}
