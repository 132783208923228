<div class="order-schedule">
    <div class="schedule-container">
        <!-- Schedule Dates -->
        <div class="schedule-dates-container">
            <button type="button" class="schedule-asap-button" (click)="asapClick()"
                [ngClass]="{ 'active': !scheduledTime && !laterDateClicked }"
                [ngStyle]="{ 
                    'width': translate.getActiveLang() === 'en' ? '4rem' : '7rem', 
                    'font-size': translate.getActiveLang() === 'en' ? '1rem' : '0.9rem' }"
                [disabled]="!scheduledTime">
                {{'menuTypeDropdown.asap' | transloco }}
            </button>
            <div class="schedule-dates">
                <div *ngFor="let date of datesForScheduling" class="schedule-date" (click)="clickDate(date)"
                    [ngClass]="{ 'active': (laterDateClicked || scheduledTime) && datePicked.getDate() === date.getDate() }">
                    <div *ngIf="translate.getActiveLang() === 'en'" class="schedule-weekday">
                        {{ date.toLocaleString('en-US', { weekday: 'short' }) | uppercase }}
                    </div>
                    <div *ngIf="translate.getActiveLang() === 'fr'" class="schedule-weekday">
                        {{ date.toLocaleString('fr-CA', { weekday: 'short' }) | uppercase }}
                    </div>
                    <div class="schedule-day">{{ date.getDate() }}</div>
                </div>
            </div>
        </div>
        
        <!-- Schedule Hours -->
        <div *ngIf="laterDateClicked" class="schedule-hours-container hours">
            <div class="hours-title">
                <span *ngIf="orderType === 'delivery'">{{ 'menuTypeDropdown.deliveryTime' | transloco }}</span>
                <span *ngIf="orderType === 'pickup'">{{ 'menuTypeDropdown.pickupTime' | transloco }}</span>
            </div>
            <div ngbDropdown class="d-inline-block hours-dropdown" #dropdownDeliveryHours="ngbDropdown" (openChange)="hourDropdownClicked()">
                <button ngbDropdownToggle class="btn btn-light" id="dropdownHours" 
                    [disabled]="hoursOfTheDay.length <= 1 || !laterDateClicked">
                    <ng-container *ngIf="hoursOfTheDay.length > 1; else hoursExist">
                        <span *ngFor="let date of hoursOfTheDay | slice:0:1; let i=index" class="dropdown-hours-display">
                            {{ scheduledTime?.getDate() === date?.date?.getDate() && datePicked?.getDate() === date?.date?.getDate() ? (prettyPrintDateTime(scheduledTime, 'short') + ' - ' + prettyPrintDateTime(scheduledTimePlusThirty, 'short')) : (prettyPrintDateTime(hoursOfTheDay[i]?.date, 'short') + ' - ' + prettyPrintDateTime(hoursOfTheDay[i+1]?.date, 'short')) }}
                        </span>
                    </ng-container>
                    <ng-template #hoursExist>
                        <span class="dropdown-hours-display">{{ 'menuTypeDropdown.noAvailability' | transloco }}</span>
                    </ng-template>
                    <img src="../../assets/images/icons/clock.svg" class="dropdown-hours-clock dropdown-options-icon">
                </button>
                <div ngbDropdownMenu aria-labelledby="dropdownHours" class="dropdown-hours-menu">
                    <div *ngFor="let date of hoursOfTheDay; let i=index" class="dropdown-hours-menu-item" 
                        [ngbTooltip]="!date.active ? ((orderType === 'delivery' ? 'menuTypeDropdown.doesNotDeliver' : 'menuTypeDropdown.doesNotPickup') | transloco:{name: restaurant ? restaurant.name : bagRestaurant.name }) : '' " 
                        [openDelay]="300" placement="top" container="body" tooltipClass="radish-tooltip" 
                        [style.cursor]="date.active ? 'pointer' : 'auto'">
                        <ng-container *ngIf="i < hoursOfTheDay.length - 1">
                            <div id="{{ date.date.getTime() }}" class="dropdown-hours-label"
                                (click)="scheduledTimeSelected($event.target.id); dateSelected()"
                                [class.inactive-hour]="!date.active" [class.active-hour]="date.active">
                                {{ prettyPrintDateTime(hoursOfTheDay[i].date, 'short') + ' - ' + prettyPrintDateTime(hoursOfTheDay[i+1].date, 'short') }}
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
