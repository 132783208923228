<button class="component-container" (click)="onButtonClicked.emit()" [disabled]="disabled"
    [style.background-color]="!disabled ? color : disabledColor" [style.border-radius]="borderRadius"
    appClickStopPropagation>
    <svg *ngIf="type === menuItemButtonType.Plus" class="increment-icon" height="512" viewBox="0 0 512 512" width="512">
        <path [style.fill]="iconColor" d="m256 512a25 25 0 0 1 -25-25v-462a25 25 0 0 1 50 0v462a25 25 0 0 1 -25 25z" />
        <path [style.fill]="iconColor" d="m487 281h-462a25 25 0 0 1 0-50h462a25 25 0 0 1 0 50z" />
    </svg>
    <svg *ngIf="type === menuItemButtonType.Minus" class="increment-icon" viewBox="0 0 512 50">
        <path [style.fill]="iconColor" d="M487,281H25a25,25,0,0,1,0-50H487a25,25,0,0,1,0,50Z"
            transform="translate(0 -231)" />
    </svg>
</button>