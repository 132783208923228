<form action="submit" class="checkout-form-container" (submit)="onSubmit()" [formGroup]="expoCheckoutPaymentForm">
    <div class="checkout-form-payment-container">
        <h2 class="checkout-form-title">{{ 'expo.checkout.payment.title' | transloco }}</h2>
        <div class="checkout-form-payment">
            <span><input class="checkout-card-radio-input" type="radio" formControlName="cardRadio" value="new-card"
                    id="new-card" hidden/></span>
            <div class="checkout-box-input form-input" #cardElement>
            </div>
            <div *ngIf="cardErrors" class="card-error">
                {{ cardErrors }}
            </div>
        </div>
    </div>
    <div class="checkout-form-divider"></div>
    <div class="checkout-form-notes-container">
        <ngb-accordion class="checkout-notes-accordion" #notesAccordion>
            <ngb-panel [title]="'expo.checkout.notes.title' | transloco">
                <ng-template ngbPanelContent>
                    <textarea class="checkout-form-textarea" type="text" rows="1"
                    [placeholder]="'expo.checkout.notes.placeholder' | transloco" formControlName="notes"></textarea>
                </ng-template>
            </ngb-panel>
        </ngb-accordion>
    </div>
    <div class="checkout-form-divider"></div>
    <div class="checkout-form-tips-container">
        <div class="checkout-form-dropdown">{{
            'expo.checkout.tips.title' | transloco }}</div>
        <div class="checkout-tip-container">
            <div class="checkout-tip-options">
                <input id="tip_15" type="radio" value="15" formControlName="tipRadio" (change)="updateTipRadio()" />
                <label class="checkout-tip-option-label" for="tip_15">15%</label>
                <input id="tip_18" type="radio" value="18" formControlName="tipRadio" (change)="updateTipRadio()" />
                <label class="checkout-tip-option-label" for="tip_18">18%</label>
                <input id="tip_20" type="radio" value="20" formControlName="tipRadio" (change)="updateTipRadio()" />
                <label class="checkout-tip-option-label" for="tip_20">20%</label>
                <input decimals="2" class="checkout-tip-option-custom checkout-box-input form-input"
                    (keypress)="onCustomTipKeypress($event)" (input)="onCustomTipChange()"
                    formControlName="tipAmountCustom" type="text" placeholder="Amount" appTipKeypress />
            </div>
        </div>
    </div>
    <div class="checkout-form-divider"></div>
    <button class="checkout-button form-submit-button" type="submit" id="checkoutButton"
        [disabled]="expoCheckoutPaymentForm.invalid || 
            cardErrors ||
            configService.MINIMUM_EXPO_POCHA_ORDER_AMOUNT.getAmount() > (bag$ | async)?.subtotal.getAmount() ||
            (isCardEmpty && expoCheckoutPaymentControls.cardRadio.value === 'new-card')">
        <ng-container *ngIf="configService.MINIMUM_EXPO_POCHA_ORDER_AMOUNT.getAmount() > (bag$ | async)?.subtotal.getAmount(); else defaultCheckoutText">
            {{ 'expo.bag.errors.minimumAmount'
            | transloco : { amount: getMissingAmount(bag$ | async) | price | translocoCurrency } }}
        </ng-container>
        <ng-template #defaultCheckoutText>
            <span *ngIf="!isLoading; else loading">
                {{ 'expo.checkout.placeOrder' | transloco }}
            </span>
        </ng-template>
        <ng-template #loading>
            <div class="spinner-border spinner-border-sm text-light" role="status">
                <span class="sr-only">{{ 'common.loading' | transloco }}</span>
            </div>
        </ng-template>
    </button>
</form>