import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';

import { BaseService } from './base.service';

import { Category, Restaurant, Review } from '../models';
import { ExtendedOrderType } from '../enums';
export interface IReview {
    lang: string;
    title: string;
    rating: number;
    text: string;
    // descriptions: string[];
}

@Injectable({
    providedIn: 'root',
})
export class RestaurantService {

    //TODO: Observables should be seperated from RestaurantService into some type of "Viewing Service"


    private entity: string = 'restaurants';
    private entityMenu: string = 'menu';

    constructor(
        private baseService: BaseService) {

    }

    getRestaurant(slug: string, type?: ExtendedOrderType): Observable<Restaurant> {
        let query = {};

        if (type != null) query['type'] = type;

        return this.baseService.list(this.entity + '/' + slug, query)
            .pipe(debounceTime(500), map(res => new Restaurant().deserialize(res.restaurant)));
    }

    getReviews(slug: string): Observable<Review[]> {
        return this.baseService.list(this.entity + '/' + slug + '/reviews')
            .pipe(map(res => res.reviews.map(review => new Review().deserialize(review))))
    }

    createReviewWithPhotos(slug: string, formData: FormData): Observable<any> {
        return this.baseService.submit(this.entity + '/' + slug + '/reviews', formData);
    }

    getRestaurantById(id: string): Observable<Restaurant> {
        return this.baseService.fetch(this.entity + '/id', id)
            .pipe(debounceTime(500), map(res => new Restaurant().deserialize(res?.restaurant)));
    }

    listRestaurants(region: string, categories?: Category[]): Observable<Restaurant[]> {
        let data: any = { region, types: ['delivery', 'pickup'] };
        if (categories) data.categories = categories.map(category => category.slug);
        return this.baseService.list(this.entity, data).pipe(map(res => Array.isArray(res?.restaurants) ? res.restaurants.map(restaurant => new Restaurant().deserialize(restaurant)) : []));
    }
}
