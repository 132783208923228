import { Deserializable } from "./deserializable.model";

export class Weather implements Deserializable<Weather>{
	
	code: string;

	constructor() { }

	deserialize(input: any): this {
		Object.assign(this, input);
		this.code = input.current.condition.code.toString();
		return this;
	}
}