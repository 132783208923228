import Dinero from "dinero.js";
import { OrderType } from "../enums";
import { Deserializable } from "./deserializable.model";
import { Theme } from "./theme.model";
import { TranslatedText, TranslatedTextType } from "./translated-text.model";

export class GiftCard implements Deserializable<GiftCard> {

    _id: string;
    _title?: TranslatedText;
    sender?: {
        name?: string,
        email?: string
    };
    recipient?: {
        name?: string,
        email?: string
    };
    types: OrderType[];
    balance: Dinero.Dinero;
    _message?: TranslatedText;
    theme: Theme;

    constructor() { }

    get id(): string {
		return this._id;
	}

	set id(input: string) {
		this._id = input;
	}

    get title(): TranslatedTextType {
        return this._title?.value;
    }

    set title(title: TranslatedTextType) {
        this._title = title as TranslatedText;
    }

    get message(): TranslatedTextType {
        return this._message?.value;
    }

    set message(message: TranslatedTextType) {
        this._message = message as TranslatedText;
    }

    deserialize(input: any): this {
        if (!input) return null;
        if (typeof input == 'string') this.id = input;
        else {
            Object.assign(this, input);
            this.title = new TranslatedText().deserialize(input.title);
            this.message = new TranslatedText().deserialize(input.message);
            this
            if (typeof input?.balance?.amount == 'number') this.balance = Dinero({ amount: input.balance.amount, currency: input.balance?.currency });
            this.theme = new Theme().deserialize(input.theme);
        }
        return this;
    }
}
