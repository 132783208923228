import { Component, OnInit, AfterViewInit, ViewChild, PLATFORM_ID, Inject } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbCarousel } from '@ng-bootstrap/ng-bootstrap';
import { isPlatformBrowser } from '@angular/common';

@Component({
    selector: 'app-landing-launch',
    templateUrl: './landing-launch.component.html',
    styleUrls: ['./landing-launch.component.css']
})
export class LandingLaunchComponent implements OnInit, AfterViewInit {

    @ViewChild('carousel', {static : true}) carousel: NgbCarousel;

    subscribeForm: UntypedFormGroup;
    navbarType = 'landing';
    showNavigationArrows = false;
    showNavigationIndicators = false;
    page = 1;
    pageSize = 1;
    cards;
    loading: boolean = false;
    error: boolean;
    pauseOnHover = true;

    heroImageLoaded: boolean = false;
    private isDesktop: boolean;

    constructor(
        @Inject(PLATFORM_ID) platformId: Object,
        public translate: TranslocoService,
        private fb: UntypedFormBuilder,
        private router: Router
        ) {
            this.isDesktop = isPlatformBrowser(platformId);
            this.subscribeForm = fb.group({
                email: ['', [Validators.required, Validators.email]]
            });
    }

    get subscribeFormControls() {
        return this.subscribeForm.controls;
    }

    clickCard(source: string) {
        switch (source) {
            case 'cbc':
                window.open("https://www.cbc.ca/radio/thesundayedition/the-sunday-edition-for-may-3-2020-1.5547652/a-new-app-created-in-montreal-takes-a-different-approach-to-food-delivery-1.5547817", '_blank')
                break;
            case 'maharani':
                this.router.navigate([this.translate.getActiveLang() + '/restaurants/maharani'])
                break;
            case 'unicorn':
                this.router.navigate([this.translate.getActiveLang() + '/blog'])
                break;
        }
    }

    ngOnInit(): void {}

    ngAfterViewInit(): void {
        if (this.isDesktop) {
            let indicators = document.getElementsByClassName('carousel-indicators')[0].children
            for (let i = 0; i < indicators.length; i++) {
                indicators[i].innerHTML = (i + 1).toString()
            }
        }
    }

    teamClick() {
        let url: string;
        if (this.translate.getActiveLang() == 'fr')
            url = "https://forms.office.com/Pages/ResponsePage.aspx?id=klFWaUzw5UmXFhNYhQA0lfG2ZcKS1oxFjK6lXKVesh1UQ1dPWEhFM045MDNZVFlTNDFXRUNEQlNUMi4u&lang=fr-CA"
        else
            url = "https://forms.office.com/Pages/ResponsePage.aspx?id=klFWaUzw5UmXFhNYhQA0lfG2ZcKS1oxFjK6lXKVesh1UQ1dPWEhFM045MDNZVFlTNDFXRUNEQlNUMi4u"
        window.open(url, "_blank");
    }

    restaurantClick() {
        let url: string;
        if (this.translate.getActiveLang() == 'fr')
            url = "https://forms.office.com/Pages/ResponsePage.aspx?id=klFWaUzw5UmXFhNYhQA0lfG2ZcKS1oxFjK6lXKVesh1URDhaTUhBMk1SODhQUlJTMzlYUlZDOU85TC4u&lang=fr-CA"
        else
            url = "https://forms.office.com/Pages/ResponsePage.aspx?id=klFWaUzw5UmXFhNYhQA0lfG2ZcKS1oxFjK6lXKVesh1URDhaTUhBMk1SODhQUlJTMzlYUlZDOU85TC4u"
        window.open(url, "_blank");
    }

}
