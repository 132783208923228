import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { PromotionService } from 'src/app/core/services/promotion.service';
import confetti from 'canvas-confetti';
import { Promotion } from 'src/app/core/models/promotion.model';
import { map, Observable } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';
import { MainService } from 'src/app/core/services/main.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
	selector: 'app-qr-promotion-modal',
	templateUrl: './qr-promotion-modal.component.html',
	styleUrls: ['./qr-promotion-modal.component.css'],
})
export class QrPromotionModalComponent implements OnInit {
	isBrowser: boolean;
	promotion$: Observable<Promotion>;

	errorMessage: string = 'error-message';
	confirmationMessage: string = 'confirmation-message';
	submitButton: string = 'submit-info';

	pid: string;

	constructor(private promotionService: PromotionService,
		@Inject(PLATFORM_ID) platformId: Object
	) {
		if (isPlatformBrowser(platformId)) this.isBrowser = true;
	}

	ngOnInit(): void {
		// observable
		this.promotion$ = this.promotionService.fetchPromotion(this.pid);
	}

	closeModal(): void {
		var qrModal = document.getElementById("modal");
		qrModal.style.display = "hide";
	}

	submitInfo() {
		var nameInput = document.getElementById('name') as HTMLInputElement | null;
		var emailInput = document.getElementById('email') as HTMLInputElement | null;

		const name = nameInput?.value;
		const email = emailInput?.value;

		if (name === "" || email === "") {
			this.errorMessage = 'error-message-visible';
		}
		else {
			this.errorMessage = 'error-message';
			this.confirmationMessage = 'confirmation-message-visible';
			this.submitButton = 'submit-info-blank';

			// confetti not displaying on top of modal?
			confetti({
				particleCount: 100,
				spread: 70,
				// colors: ['#03956a', '#fe2e7e', '#8bceba', '#f2a1b5'],
				origin: { y: 0.9 },
				zIndex: 1100
			});
		}
	}

}
