import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Weather } from '../models/weather.model';
import { BaseService } from './base.service';

const headers = new HttpHeaders({
	'Content-Type': 'application/json; charset=utf-8',
});

@Injectable({
  providedIn: 'root'
})
export class WeatherService {
  private entity: string = 'https://api.openweathermap.org';
  private params: string = 'data/2.5/weather';
  private query: string = 'lat=45.508370&lon=-73.571780&appid=e81c5ee1bafded4532bca09df900c3f5';

	constructor(private baseService: BaseService,
    private http: HttpClient
    ) { }

  fetchWeather(): Observable<Weather> {
    let route = 'https://api.weatherapi.com/v1/current.json?key=cb19106c3a7047a3bb6155320231404&q=45.508370,-73.571780&aqi=no';
    return this.http.get(route, { headers: headers }).pipe(map(res => new Weather().deserialize(res)));
  }
}
