import { Component, OnInit } from '@angular/core';
import { ConfigService } from 'src/app/core/services/config.service';
import { Router } from '@angular/router';
import { MainService } from 'src/app/core/services/main.service';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'app-promotions-followup',
  templateUrl: './promotions-followup.component.html',
  styleUrls: ['./promotions-followup.component.css']
})
export class PromotionsFollowupComponent implements OnInit {

  constructor(
    private configService: ConfigService,
    private router: Router,
    public translate: TranslocoService,
    public mainService: MainService
  ) { }
  orderNumber: any;
  showMap: boolean;
  showMapButton: boolean;
  lat;
  long;
  options;
  isLoading: boolean = false;

  ngOnInit(): void {
    //TODO: get orderNumber
    this.orderNumber;

    if (localStorage.getItem('redirect_url') == 'promotions') {
      localStorage.removeItem('redirect_url')
      this.showMapButton = true;
    } else {
      this.showMap = false;
      this.showMapButton = false;
    }

    this.options = {
      disableDefaultUI: true,
      styles: this.configService.getMapStyle('')
    }
  }

  getLocation() {
    this.isLoading = true;
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        if (position) {
          this.lat = position.coords.latitude;
          this.long = position.coords.longitude;
          this.isLoading = false;
          this.showMap = true;
          this.showMapButton = false;
        }
      },
      (error) => console.log(error));
    } else this.showMap = false;
  }
  useLanguage(language: string) {
    this.router.navigateByUrl(this.router.url.replace(this.translate.getActiveLang(), language));
    this.mainService.setLanguage(language);
  }

}
