<app-navbar [navbarType]="navbarType"></app-navbar>
<div class="privacy-container">
	<h1 class="privacy-title">
		{{ 'privacy.title' | transloco }}
	</h1>
	<p class="privacy-updated">
		{{ 'privacy.updated' | transloco }}
	</p>
	<div class="privacy-content-container row">
		<div class="privacy-content-sidebar-container col-4">
			<div class="privacy-content-sidebar-wrapper" #sidebarWrapper>
				<ul class="side-nav list-unstyled">
					<li class="side-nav-item">
						<a routerLink="." fragment="privacy-text-1">{{
							'privacy.infoWeCollect.title' | transloco
						}}</a>
					</li>
					<li class="side-nav-item">
						<a routerLink="." fragment="privacy-text-2">{{
							'privacy.howWeUseInfo.title' | transloco
						}}</a>
					</li>
					<li class="side-nav-item">
						<a routerLink="." fragment="privacy-text-3">{{
							'privacy.sharingPersonalInfo.title' | transloco
						}}</a>
					</li>
					<li class="side-nav-item">
						<a routerLink="." fragment="privacy-text-4">{{
							'privacy.behaviouralAdvertising.title' | transloco
						}}</a>
					</li>
					<li class="side-nav-item">
						<a routerLink="." fragment="privacy-text-5">{{
							'privacy.doNotTrack.title' | transloco
						}}</a>
					</li>
					<li class="side-nav-item">
						<a routerLink="." fragment="privacy-text-6">{{
							'privacy.yourRights.title' | transloco
						}}</a>
					</li>
					<li class="side-nav-item">
						<a routerLink="." fragment="privacy-text-7">{{
							'privacy.dataRetention.title' | transloco
						}}</a>
					</li>
					<li class="side-nav-item">
						<a routerLink="." fragment="privacy-text-8">{{
							'privacy.minors.title' | transloco
						}}</a>
					</li>
					<li class="side-nav-item">
						<a routerLink="." fragment="privacy-text-9">{{
							'privacy.changes.title' | transloco
						}}</a>
					</li>
					<li class="side-nav-item">
						<a routerLink="." fragment="privacy-text-10">{{
							'privacy.contactUs.title' | transloco
						}}</a>
					</li>
				</ul>
			</div>
		</div>
		<div class="privacy-content-text col-8">
			<div class="privacy-content-text-section">
				<p [innerHTML]="'privacy.mainText' | transloco"></p>
			</div>
			<div id="privacy-text-1" class="privacy-content-text-section">
				<h2 class="privacy-content-text-section-title">
					{{ 'privacy.infoWeCollect.title' | transloco }}
				</h2>
				<p class="privacy-content-text-section-description"
					[innerHTML]="'privacy.infoWeCollect.text' | transloco"></p>
			</div>
			<div id="privacy-text-2" class="privacy-content-text-section">
				<h2 class="privacy-content-text-section-title">
					{{ 'privacy.howWeUseInfo.title' | transloco }}
				</h2>
				<p class="privacy-content-text-section-description"
					[innerHTML]="'privacy.howWeUseInfo.text' | transloco"></p>
			</div>
			<div id="privacy-text-3" class="privacy-content-text-section">
				<h2 class="privacy-content-text-section-title">
					{{ 'privacy.sharingPersonalInfo.title' | transloco }}
				</h2>
				<p class="privacy-content-text-section-description"
					[innerHTML]="'privacy.sharingPersonalInfo.text' | transloco"></p>
			</div>
			<div id="privacy-text-4" class="privacy-content-text-section">
				<h2 class="privacy-content-text-section-title">
					{{ 'privacy.behaviouralAdvertising.title' | transloco }}
				</h2>
				<p class="privacy-content-text-section-description"
					[innerHTML]="'privacy.behaviouralAdvertising.text' | transloco"></p>
			</div>
			<div id="privacy-text-5" class="privacy-content-text-section">
				<h2 class="privacy-content-text-section-title">
					{{ 'privacy.doNotTrack.title' | transloco }}
				</h2>
				<p class="privacy-content-text-section-description" [innerHTML]="'privacy.doNotTrack.text' | transloco">
				</p>
			</div>
			<div id="privacy-text-6" class="privacy-content-text-section">
				<h2 class="privacy-content-text-section-title">
					{{ 'privacy.yourRights.title' | transloco }}
				</h2>
				<p class="privacy-content-text-section-description" [innerHTML]="'privacy.yourRights.text' | transloco">
				</p>
			</div>
			<div id="privacy-text-7" class="privacy-content-text-section">
				<h2 class="privacy-content-text-section-title">
					{{ 'privacy.dataRetention.title' | transloco }}
				</h2>
				<p class="privacy-content-text-section-description"
					[innerHTML]="'privacy.dataRetention.text' | transloco"></p>
			</div>
			<div id="privacy-text-8" class="privacy-content-text-section">
				<h2 class="privacy-content-text-section-title">
					{{ 'privacy.minors.title' | transloco }}
				</h2>
				<p class="privacy-content-text-section-description" [innerHTML]="'privacy.minors.text' | transloco"></p>
			</div>
			<div id="privacy-text-9" class="privacy-content-text-section">
				<h2 class="privacy-content-text-section-title">
					{{ 'privacy.changes.title' | transloco }}
				</h2>
				<p class="privacy-content-text-section-description" [innerHTML]="'privacy.changes.text' | transloco">
				</p>
			</div>
			<div id="privacy-text-10" class="privacy-content-text-section">
				<h2 class="privacy-content-text-section-title">
					{{ 'privacy.contactUs.title' | transloco }}
				</h2>
				<p class="privacy-content-text-section-description" [innerHTML]="'privacy.contactUs.text' | transloco">
				</p>
			</div>
		</div>
	</div>
</div>
<app-footer></app-footer>