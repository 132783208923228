import { Component, OnInit } from '@angular/core';
import { ContentService } from 'src/app/core/services/content.service';
import { BehaviorSubject, concatMap, finalize } from 'rxjs';
import { Affiliate, ListContent, Product } from 'src/app/core/models';
import { TranslocoService } from '@ngneat/transloco';
import { MainService } from 'src/app/core/services/main.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GiftBoxVerifyAddressModalComponent } from '../modals/giftbox-verify-address-modal/verify-address-modal.component';
import { AffiliateService } from 'src/app/core/services/affiliate.service';
import { OrderType } from 'src/app/core/enums';
import { GiftBoxReferralCodeModalComponent } from '../modals/referral-code-modal/referral-code-modal.component';

@Component({
	selector: 'app-gift-box-combo-listing',
	templateUrl: './combo-listing.component.html',
	styleUrls: ['./combo-listing.component.css']
})
@UntilDestroy()
export class GiftBoxComboListingComponent implements OnInit {

	private contentSlug = 'gift-boxes-valentines-2024';
	
	// TODO: Use this slug to make test products appear on staging, but change to original above before committing!
	// private contentSlug = 'gift-boxes-mtl';

	affiliate: Affiliate;
	selectedProduct: Product;

	//Data
	lists: ListContent;
	products: Product[] = [];

	//UI Variables
	isResolvingSections: boolean = true;
	detailsOpen: boolean = false;
    showFooter: boolean = true;

	//TODO: to ensure that multiple modals don't interfere
	private modalQueue$ = new BehaviorSubject<any>(null);

	constructor(
        private mainService: MainService,
		private contentService: ContentService,
		private affiliateService: AffiliateService,
		public translate: TranslocoService,
		private modalService: NgbModal
	) { }

	ngOnInit(): void {
		this.contentService.fetchList(this.contentSlug).pipe(finalize(() => this.isResolvingSections = false)).subscribe(lists => {
			this.lists = lists;
			if (this.lists) this.products = (this.lists.items[0] as ListContent).items as Product[];
		});
		if (!localStorage.getItem('gift_box_address_verified')) this.modalQueue$.next(this.showAddressVerificationModal);

		if (this.affiliateService.affiliate) {
			this.affiliate = this.affiliateService.affiliate;
			this.modalQueue$.next(this.showAffiliateModal);
		}
		else this.mainService.fetchAffiliate(OrderType.GiftBox).subscribe(affiliate => {
			this.affiliate = affiliate;
			this.modalQueue$.next(this.showAffiliateModal);
		})

		this.modalQueue$.pipe(concatMap(o => o(this))).subscribe();
	}

	listSelected(list: ListContent) {
		this.products = (this.lists.items.find(item => item.slug == list.slug) as ListContent).items as Product[];
	}

	showAddressVerificationModal(ctx: this) {
		let modal = ctx.modalService.open(GiftBoxVerifyAddressModalComponent, { centered: true });
		return modal.closed;
	}

	showAffiliateModal(ctx: this) {
		let modal = ctx.modalService.open(GiftBoxReferralCodeModalComponent, { centered: true });
		modal.componentInstance.affiliate = ctx.affiliate;
		return modal.closed;
	}

	openProductDetails(product : Product) {
		this.detailsOpen = true;
		this.selectedProduct = product;
	}
}
