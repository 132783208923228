<div *ngIf="items" class="market-row-wrapper">
    <div class="market-row" *ngIf="getRowType(items) == 'product'">
        <!-- TODO: grab type/title of data from server-->
        <!-- <div class="market-row-title"> {{ rowData.slug }} </div> -->
        <div class="market-product-listing">
            <div *ngFor="let product of items" class="product-wrapper">
                <!-- <div *ngIf="product?.isListed != false && product?.isVisible != false"><app-product-market-row-item [hideCrateButton]="displayOnMain"[item]="product"></app-product-market-row-item></div> -->
                <app-product-market-row-item [hideCrateButton]="displayOnMain"[item]="product"></app-product-market-row-item>
            </div>
        </div>
    </div>
    <div class="market-row" *ngIf="getRowType(items) == 'vendor'">
        <!-- TODO: grab type/title of data from server-->
        <!-- <div class="market-row-title"> {{ rowData.slug }} </div> -->
        <div class="market-product-listing">
            <div *ngFor="let vendor of items" class="vendor-wrapper">
                <app-vendor-market-row [routeToRestaurant]="displayOnMain"[item]="vendor"></app-vendor-market-row>
            </div>
        </div>
    </div>
</div>
