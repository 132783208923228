import { Component } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
    selector: "app-gift-box-verify-address-modal",
    templateUrl: "./verify-address-modal.component.html",
    styleUrls: ["./verify-address-modal.component.css"],
})
export class GiftBoxVerifyAddressModalComponent {
    isAddressValid: boolean;

	// montreal, laval, longeuil
	private postalCodes = ["H1A", "H1B", "H1C", "H1E", "H1H", "H1J", "H1K", 
							"H1L", "H1M", "H1G", "H1P", "H1S", "H1T", "H1V", 
							"H1W", "H1X", "H1R", "H1H", "H1Y", "H1Z", "H2B", "H2C", 
							"H2A", "H2K", "H2H", "H2G", "H2F", "H2M", "H2E", "H2G", 
							"H2N", "H2P", "H2R", "H2S", "H2J", "H2L", "H2T", "H2W", 
							"H2X", "H2Y", "H2V", "H2Z", "H3L", "H3M", "H3N", "H3T", 
							"H3S", "H3V", "H9X", "H9K", "H9W", "H9J", "H9E", "H9C", 
							"H9H", "H8Z", "H9G", "H9A", "H9B", "H7Y", "H9S", "H9R", 
							"H8Y", "H7K", "H9P", "H4Y", "H4S", "H7R", "H7P", "H7L", 
							"H7H", "H7J", "H7B", "H7A", "H7C", "H7E", "H7K", "H7M", 
							"H7G", "H7N", "H7V", "H7S", "H7T", "H7W", "H4K", "H4R", 
							"H4J", "H4L", "H4N", "H3P", "H3R", "H3Y", "H3Z", "H3J", 
							"H3C", "H3B", "H3A", "H3G", "H3K", "H3E", "H8P", "H8R", 
							"H8S", "H4M", "H4P", "H3W", "H3X", "H4T", "H4W", "H8T", 
							"H8N", "H4X", "H4V", "H4B", "H4A", "H4C", "H4E", "H4H", 
							"H4G", "J4G", "J4N", "J4M", "J4L", "J4J", "J4H", "J5C", 
							"J5R", "J5B", "J4X", "J4Y", "J4Z", "J4W", "J4S", "J3Y", 
							"J4V", "J4T", "J4K", "J4P", "J4R", "J4B"];

	/*
		H1A
		H1B
		H1C
		H1E
		H1H
		H1J
		H1K
		H1L
		H1M
		H1G
		H1P
		H1S
		H1T
		H1V
		H1W
		H1X
		H1R
		H1H
		H1Y
		H1Z
		H2B
		H2C
		H2A
		H2K
		H2H
		H2G
		H2F
		H2M
		H2E
		H2G
		H2N
		H2P
		H2R
		H2S
		H2J
		H2L
		H2T
		H2W
		H2X
		H2Y
		H2V
		H2Z
		H3L
		H3M
		H3N
		H3T
		H3S
		H3V
		H9X
		H9K
		H9W
		H9J
		H9E
		H9C
		H9H
		H8Z
		H9G
		H9A
		H9B
		H7Y
		H9S
		H9R
		H8Y
		H7K
		H9P
		H4Y
		H4S
		H7R
		H7P
		H7L
		H7H
		H7J
		H7B
		H7A
		H7C
		H7E
		H7K
		H7M
		H7G
		H7N
		H7V
		H7S
		H7T
		H7W
		H4K
		H4R
		H4J
		H4L
		H4N
		H3P
		H3R
		H3Y
		H3Z
		H3J
		H3C
		H3B
		H3A
		H3G
		H3K
		H3E
		H8P
		H8R
		H8S
		H4M
		H4P
		H3W
		H3X
		H4T
		H4W
		H8T
		H8N
		H4X
		H4V
		H4B
		H4A
		H4C
		H4E
		H4H
		H4G
		J4G
		J4N
		J4M
		J4L
		J4J
		J4H
		J5C
		J5R
		J5B
		J4X
		J4Y
		J4Z
		J4W
		J4S
		J3Y
		J4V
		J4T
		J4K
		J4P
		J4R
		J4B
	*/

    constructor(public activeModal: NgbActiveModal) { }

    checkAddress() {
		if (this.isAddressValid != null) this.activeModal.close();
		// TODO: switch to reactive forms
		let addressEntered = (<HTMLInputElement>document.getElementById("address")).value.toString();
		addressEntered = addressEntered.toUpperCase().trim().substring(0, 3);
		this.isAddressValid = this.postalCodes.includes(addressEntered);
		if (this.isAddressValid) localStorage.setItem('gift_box_address_verified', 'true');
	}
}
