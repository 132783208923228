import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { TranslocoService, getBrowserLang } from '@ngneat/transloco';
import { Angulartics2GoogleTagManager } from 'angulartics2';
import { filter, take, map, mergeMap } from 'rxjs/operators';
import { User } from './core/models';
import { Router, ActivatedRoute, NavigationEnd, NavigationStart } from '@angular/router';
import { SEOService } from './core/services/seo.service';
import { MainService } from './core/services/main.service';
import { isPlatformBrowser } from '@angular/common';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css'],
})

export class AppComponent implements OnInit {

    title = 'radish-frontend';
    user: User;
    language: string;

    loadingRoute: string = null;
    isBrowser: boolean = false;

    metadata;

    constructor(
        private translate: TranslocoService,
        angulartics2GoogleTagManager: Angulartics2GoogleTagManager,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private seoService: SEOService,
        private mainService: MainService,
        @Inject(PLATFORM_ID) platformId: Object,
    ) {
        if (isPlatformBrowser(platformId)) this.isBrowser = true;

        let language = localStorage.getItem('language') || getBrowserLang() || 'en';
        this.translate.setActiveLang(language);

        this.router.events.pipe(filter(event => event instanceof NavigationStart)).subscribe(_ => this.mainService.isDynamicComponent = false);

        this.router.events
            .pipe(filter((event) => event instanceof NavigationEnd),
            map(() => this.activatedRoute),
            map((route) => {
                if (route.firstChild) route = route.firstChild;
                route.paramMap.pipe(take(1)).subscribe(params => this.translate.setActiveLang(params.get('language')));
                while (route.firstChild) route = route.firstChild;
                return route;
            }),
            filter((route) => route.outlet === 'primary'),
            mergeMap((route) => route.data))
            .subscribe((data) => {
                if (this.mainService.isDynamicComponent) { //Necessary to stop from overriding SEO of dynamic pages such as for restaurants or products
                    this.metadata = null;
                    return;
                }
                this.metadata = data;
                this.setMetaTags(data);
            });

            this.translate.langChanges$.subscribe(_ => {
                if (!this.mainService.isDynamicComponent && this.metadata) this.setMetaTags(this.metadata);
            });

        angulartics2GoogleTagManager.startTracking();
    }

    setMetaTags(data) {
        if (data['title'] && data['title'][this.translate.getActiveLang()]) {
            const title = data['title'][this.translate.getActiveLang()];
            this.seoService.updateTitle('Radish' + (title ? ' | ' + title : ""));
        }
        if (data['ogTitle'] && data['ogTitle'][this.translate.getActiveLang()]) {
            this.seoService.updateOgTitle(data['ogTitle'][this.translate.getActiveLang()]);
        }
        if (data['ogUrl'] && data['ogUrl'][this.translate.getActiveLang()]) {
            this.seoService.updateOgUrl(data['ogUrl'][this.translate.getActiveLang()]);
        }
        if (data['description'] && data['description'][this.translate.getActiveLang()]) {
            this.seoService.updateDescription(data['description'][this.translate.getActiveLang()]);
        }
        if (data['ogDescription'] && data['ogDescription'][this.translate.getActiveLang()]) {
            this.seoService.updateOgDescription(data['ogDescription'][this.translate.getActiveLang()]);
        }
    }

    ngOnInit(): void {
        if (this.isBrowser) {
            let scheduledDateString = localStorage.getItem('scheduled_time')
            if (scheduledDateString) {
                let scheduledDate: Date = new Date(Number(scheduledDateString))
                let now: Date = new Date()
                if (scheduledDate > now) {
                    this.mainService.scheduledDate = scheduledDate
                } else {
                    this.mainService.scheduledDate = null
                    localStorage.removeItem('scheduled_time')
                }
            }
            // this.userService
            //     .getSelf()
            //     .pipe(first())
            //     .pipe(
            //         finalize(() => {
            //             if (this.user) {
            //                 if (this.user.order) {
            //                     this.fetchOrder(this.user.order.id)
            //                 }
            //                 if (!this.user.bag) {
            //                     if (this.user.preferences && this.user.preferences.delivery && this.user.preferences.delivery.preferredAddress) {
            //                         this.userService.fetchAddressById(this.user.preferences.delivery.preferredAddress.id)
            //                             .pipe(first())
            //                             .subscribe(
            //                                 address => this.addressService.address = address
            //                             )
            //                     }
            //                     return;
            //                 }
            //             } else {
            //                 let address = localStorage.getItem(this.addressService.ADDRESS_KEY)
            //                 if (address) this.addressService.address = new Address().deserialize(JSON.parse(address))
            //             }
            //             this.bagService.getBag()
            //                 .pipe(first())
            //                 .subscribe(
            //                     bag => {
            //                         if (bag) {
            //                             if (bag.address)
            //                                 this.addressService.address = bag.address
            //                             for (let item of bag.items) {
            //                                 if (!item.product.isAvailable || item.product.isArchived) {
            //                                     this.bagService.removeUnavailableAndArchivedItemsFromBag()
            //                                         .pipe(first())
            //                                         .subscribe()
            //                                     break
            //                                 }
            //                             }
            //                         }
            //                     }
            //                 );
            //         })
            //     )
            //     .subscribe(
            //         (user) => (this.user = user)
            //     );
        }
    }
}
