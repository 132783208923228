<div class="section-header-container" [ngClass]="{'en': browserLang === 'en', 'fr': browserLang === 'fr'}">
    <div *ngIf="(category$ | async) as category; else loading" class="featured featured-text">
        <div class="featured-text-title"> {{ category.title }}</div>
        <div class="featured-text-description"></div>
    </div>
    <ng-template #loading>
        <div class="featured featured-text">
            <div class="featured-text-title-gradient loading-gradient"></div>
            <div class="featured-text-description"></div>
        </div>
    </ng-template>
    <div class="featured" *ngFor="let feature of features" (click)="onClickHeaderFeature(feature.id, feature.url)">
        <div class="overlay"></div>
        <div class="featured-image-wrapper">
            <img class="featured-image" src='{{ feature.image }}' alt="" />
            <div class="featured-image-text-wrapper">
                <div class="featured-image-text title">{{ feature.title | transloco }}</div>
                <div *ngIf="feature.subtitle != ''" class="featured-image-text subtitle">{{ feature.subtitle | transloco
                    }}</div>
            </div>
        </div>
    </div>
</div>