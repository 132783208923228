import { Component, OnInit, ViewChild, Input, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddressModalComponent } from '../../../modals/address-modal/address-modal.component';
import { switchMap, mergeMap, map } from 'rxjs/operators';
import { Menu, Product, Restaurant, Bag, Address, MenuSection, Order } from '../../../core/models';
import { merge, Observable } from 'rxjs';
import { Angulartics2 } from 'angulartics2';
import { MainService } from 'src/app/core/services/main.service';
import { ConfigService } from 'src/app/core/services/config.service';
import { RestaurantPageV2Service } from 'src/app/core/services/data/restaurant-page-v2.service';
import { MenuItemStyle, MenuStyle, OrderType, RestaurantStyle } from 'src/app/core/enums';
import { MenuFavoriteComponent } from 'src/app/modals/menu-favorite/menu-favorite.component';
import { distanceBetween } from 'src/app/core/helpers/address.helper';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ErrorRedirectComponent } from 'src/app/modals/error-redirect/error-redirect.component';

@UntilDestroy()
@Component({
    selector: 'app-restaurant-menu',
    templateUrl: './restaurant-menu.component.html',
    styleUrls: ['./restaurant-menu.component.css'],
})
export class RestaurantMenuComponent implements OnInit {

    MenuStyle = MenuStyle;
    MenuItemStyle = MenuItemStyle;
    RestaurantStyle = RestaurantStyle;

    @Input() showTitle: boolean = true;

    @ViewChild('addressModal') addressModal;

    address$: Observable<Address>;
    bag$: Observable<Bag>;
    menu$: Observable<Menu>;
    scheduledDate$: Observable<Date>;
    orderType$: Observable<OrderType>;
    order$: Observable<Order>;
    restaurant$: Observable<Restaurant>;

    readonly MOBILE_THRESHOLD = 700;
    readonly scrollToAnchorSuffix = '-anchor' // this is to offset the scrollIntoView by a bit
    private readonly MENU_IMAGE_DELAY_MILLIS = 200

    scheduledDate: Date;
    selectedItem: Product;

    handlingAddressChange: boolean = false;
    productIncrementOptionsToShow: string = null;
    productDecrementOptionsToShow: string = null;

    isOutOfRange: boolean = false;

    menuItemStyle: MenuItemStyle;

    sectionToShow: MenuSection

    isMobile: boolean = false;

    private isMouseOnMenuItem: boolean = false;
    private menuItemHoverTimeout: NodeJS.Timeout;

    constructor(
        public translate: TranslocoService,
        private modalService: NgbModal,
        private angulartics2: Angulartics2,
        private mainService: MainService,
        public configService: ConfigService,
        private restaurantPageV2Service: RestaurantPageV2Service,
        private router: Router,
        private route: ActivatedRoute
    ) { }

    ngOnInit() {
        this.isMobile = window.innerWidth < this.MOBILE_THRESHOLD

        this.address$ = this.mainService.addressLoaded$;
        this.bag$ = this.mainService.bagLoaded$;
        this.menu$ = this.mainService.menuLoaded$;
        this.scheduledDate$ = this.mainService.scheduledDateLoaded$;
        this.orderType$ = this.mainService.orderTypeInViewLoaded$;
        this.order$ = this.mainService.orderLoaded$;
        this.restaurant$ = this.mainService.restaurantLoaded$;

        this.scheduledDate$.subscribe(scheduledDate => this.scheduledDate = scheduledDate);

        let restaurant = this.mainService.restaurantInView;
        this.scheduledDate = this.mainService.scheduledDate;

        this.orderType$.subscribe(orderType => {
            this.mainService.menuInView = restaurant.menus.find(menu => menu.type == orderType);
            this.validateRange(orderType, this.mainService.address, restaurant);
        });
        this.address$.subscribe(address => this.validateRange(this.mainService.orderTypeInView, address, restaurant));

        Promise.resolve().then(_ => this.mainService.hideOrderHereAnimation()); //Necessary hack to manage angular change detection
    
        let currentlyOpenedMenu = restaurant.menus.filter(menu => menu.isOpenAtDate(this.mainService.scheduledDate))
            .filter(menu => menu.type === this.mainService.orderTypeInView)[0]
        if (currentlyOpenedMenu != null) this.mainService.menuInView = currentlyOpenedMenu;
    }

    private validateRange(orderType, address, restaurant) {
        if (address == null) return;
        this.isOutOfRange = (orderType == <OrderType> 'delivery' && distanceBetween(address, restaurant.address) > restaurant.delivery.radius);
    }

    private showItemPanel(product: Product) {
        this.selectedItem = product;
    }

    validateAddress(): Observable<Address> {
        this.handlingAddressChange = true;
        const modal = this.modalService.open(AddressModalComponent);
        modal.componentInstance.restaurant = this.mainService.restaurantInView;
        modal.componentInstance.parent = 'RestaurantMenuComponent';

        return merge(modal.closed, modal.dismissed).pipe(map(res => {
            this.handlingAddressChange = false;
            if (res == null || !(res instanceof Address)) throw new Error('No valid address selected!');
            return res;
        }), mergeMap(res => this.mainService.updateAddress(res)));
    }

    clickAddItem(product: Product) {
        this.productIncrementOptionsToShow = null
        this.angulartics2.eventTrack.next({
            action: 'Increment Product',
            properties: {
                event: 'Click',
                category: 'Restaurant Menu',
                gtmCustom: {
                    'productId': product.id,
                    'restaurantId': this.mainService.restaurantInView.id
                }
            }
        });

        let bag = this.mainService.bag;
        let restaurant = this.mainService.restaurantInView;
        let menu = this.mainService.menuInView;
        let address = this.mainService.address;
        let orderType = this.mainService.orderTypeInView;

        if (bag == null) {
            if (address == null && orderType == <OrderType>'delivery') {
                this.validateAddress()
                    .pipe(mergeMap(_ => this.mainService.createBag(menu, restaurant)))
                    .subscribe(_ => this.showItemPanel(product));
            }
            else {
                this.mainService.createBag(menu, restaurant) //TODO: code duplicated, can be combined with previous lines
                    .subscribe(_ => this.showItemPanel(product));
            }
        }
        //Bag is empty, we can just throw it out
        else if (bag != null && bag.isEmpty && (product.restaurant.id != bag.restaurant.id || bag.menu.id != menu.id)) {
            this.mainService.recreateBag(menu, restaurant).subscribe(_ => this.showItemPanel(product));
        }
        else if (bag != null && product.restaurant.id != bag.restaurant.id) {
            const modalRef = this.modalService.open(ErrorRedirectComponent);
            modalRef.componentInstance.header = "clearBagModal.title.addItemFromAnotherRestaurant";
            modalRef.componentInstance.text = "clearBagModal.prompt._addItemFromAnotherRestaurant";
            modalRef.componentInstance.textParams = { previousRestaurantName: bag.restaurant.name, restaurantName: restaurant.name, restaurantClass: "transloco-param" };
            modalRef.componentInstance.cancelButtonText = "common.cancel";
            modalRef.componentInstance.submitButtonText = "clearBagModal.buttons.clearBag";
            modalRef.result.then(_ => this.mainService.recreateBag(menu, restaurant).subscribe(_ => this.showItemPanel(product)));
        }
        else if (bag != null && bag.menu.id != menu.id) {
            const modalRef = this.modalService.open(ErrorRedirectComponent);
            modalRef.componentInstance.text = "clearBagModal.title.addItemFromAnotherMenu";
            modalRef.componentInstance.text = "clearBagModal.prompt.addItemFromAnotherMenu";
            modalRef.componentInstance.textParams = { restaurantName: restaurant.name };
            modalRef.componentInstance.cancelButtonText = "common.cancel";
            modalRef.componentInstance.submitButtonText = "clearBagModal.buttons.clearBag";
            modalRef.result.then(_ => this.mainService.switchBagMenu(menu).subscribe(_ => this.showItemPanel(product)));
        }
        else this.addItem(product);
    }

    private addItem(product: Product) {
        let bag = this.mainService.bag;

        if (!bag.hasItem(product)) return this.showItemPanel(product);
        else if (product.options?.length > 0) return this.productIncrementOptionsToShow = product.id;
        else this.mainService.incrementItem(bag, bag.getItemByProduct(product)).subscribe({
            error: err => {
                if (err.error?.error == 'ProductNotInMenu') this.mainService.removeItem(bag, bag.getItemByProduct(product)).subscribe(); //TODO: call remove item and then popup
            }
        });
    }

    clickRemoveItem(product: Product) {
        this.productDecrementOptionsToShow = null
        this.angulartics2.eventTrack.next({
            action: 'Decrement Product',
            properties: {
                event: 'Click',
                category: 'Restaurant Menu',
                gtmCustom: {
                    'productId': product.id,
                    'restaurantId': this.mainService.restaurantInView.id
                }
            }
        })

        let bag = this.mainService.bag;
        let variations = bag?.getVariationsByProduct(product);
        if (variations?.length > 1) return this.productDecrementOptionsToShow = product.id;
        else if (variations?.length == 1) return this.mainService.decrementItem(bag, variations[0]).subscribe();
        this.selectedItem = null;
    }

    clickProduct(product: Product) {
        const feature = MenuFavoriteComponent.createFeatureFromProduct(product);
        const modalRef = this.modalService.open(MenuFavoriteComponent);
        modalRef.componentInstance.favorite = feature;
    }

    newItemWithOptionsClick(product: Product) {
        this.selectedItem = product
    }

    itemOptionsClickHandler(showItemOptions) {
        this.productIncrementOptionsToShow = this.productIncrementOptionsToShow && showItemOptions ? this.productIncrementOptionsToShow : null
        this.productDecrementOptionsToShow = this.productDecrementOptionsToShow && showItemOptions ? this.productDecrementOptionsToShow : null
    }

    //TODO: Should be in main service. Should handle order type change.
    onClickMenu(slug: string) {
        this.mainService.menuInView = this.mainService.restaurantInView.menus.find(menu => menu.slug === slug);
    }

    disableModifyProductCount(product: Product): boolean {
        let menu = this.mainService.menuInView;

        return !menu.isOpenAtDate(this.scheduledDate) ||
            this.mainService.order?.isActive ||
            this.handlingAddressChange ||
            this.isOutOfRange ||
            !product.isAvailable;
    }

    getTooltipContent(product: Product): string {
        let menu = this.mainService.menuInView;
        let restaurantName = this.mainService.restaurantInView.name.toString();

        return !menu.isOpenAtDate(this.scheduledDate)
            ? this.translate.translate('restaurant.menu.closedForDelivery', { restaurantName: restaurantName, type: menu.type })
            : ((!product.isAvailable)
                ? this.translate.translate('restaurant.menu.notAvailable')
                : (this.mainService.order?.isActive
                    ? this.translate.translate('restaurant.menu.ongoingOrder')
                    : ((this.isOutOfRange)
                        ? this.translate.translate('restaurant.menu.isOutOfRange', { restaurantName })
                        : null
                    )
                )
            );
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.isMobile = window.innerWidth < this.MOBILE_THRESHOLD;
    }

    /**
     * Juliette Specific Code
     */

    getMenuSection() {
        // this.subscription.add(
        //     this.restaurantPageV2Service.selectedMenuSection$
        //         .subscribe(
        //             res => {
        //                 this.sectionToShow = res
        //                 if (this.sectionToShow) this.scrollTo(this.sectionToShow)
        //             }
        //         )
        // )
    }

    onClickSection(section: MenuSection) {
        this.sectionToShow = section
        if (section.thumbnailUrl) {
            this.restaurantPageV2Service.updateImage(section.thumbnailUrl)
        } else {
            this.setMenuImage()
        }
        this.scrollTo(section)
    }

    scrollTo(section: MenuSection) {
        setTimeout(_ => {
            if (!this.isMobile) {
                let menuContainer = document.getElementById('restaurant-v2-content-container');
                (menuContainer as HTMLElement).scrollTop = 0
            } else {
                let menuContainer = document.getElementById(section.id + this.scrollToAnchorSuffix);
                (menuContainer as HTMLElement).scrollIntoView()
            }
        }, 0)
    }

    getSectionImage(section: MenuSection, options = { checkBackup: false }): string {
        if (section?.media[0] && section.media[0].url) {
            return section.media[0].url
        } else if (options.checkBackup) {
            return this.configService.getDefaultImage(this.mainService.restaurantInView?.slug)
        }
    }

    onClickBackToMenuButton() {
        this.setMenuImage()
        this.restaurantPageV2Service.selectMenuSection(null)
        this.router.navigate(['../'], { relativeTo: this.route });
        this.sectionToShow = null
    }

    setMenuImage() {
        this.restaurantPageV2Service.updateImage(this.mainService.menuInView?.assets?.primary?.url)
    }

    onMouseEnterMenuItem(product: Product, section: MenuSection) {
        this.isMouseOnMenuItem = true
        const image = product?.assets?.primary?.url
        if (image) {
            this.restaurantPageV2Service.updateImage(image)
        } else {
            this.setSectionImage(section)
        }
        // const description = product.description && product.description[this.translate.getActiveLang()] ? product.description[this.translate.getActiveLang()] : null
        this.restaurantPageV2Service.updateFeatureDescription(product.description as string)
        // const title = product.title && product.title[this.translate.getActiveLang()] ? product.title[this.translate.getActiveLang()] : null
        this.restaurantPageV2Service.updateFeatureTitle(product.title as string)
    }

    onMouseLeaveMenuItem(section: MenuSection) {
        this.isMouseOnMenuItem = false
        clearTimeout(this.menuItemHoverTimeout)
        this.menuItemHoverTimeout = setTimeout(() => {
            if (!this.isMouseOnMenuItem) {
                this.setSectionImage(section)
                this.restaurantPageV2Service.updateFeatureDescription(null)
                this.restaurantPageV2Service.updateFeatureTitle(null)
            }
        }, this.MENU_IMAGE_DELAY_MILLIS)
        this.setMenuImage()
    }

    setSectionImage(section: MenuSection): void {
        let image = this.getSectionImage(section)
        image ? this.restaurantPageV2Service.updateImage(image) : this.setMenuImage()
    }

    getOrderTypeMenus(menus): any[] {
        let orderType = this.mainService.orderTypeInView;
        let menusInView = menus.filter(menu => menu.type === orderType);
        return menusInView;
    }

}

