<div class="delivery-timer-wrapper">
    <div class="target-container">
        <p class="next-delivery">{{ 'market.timer.scheduled' | transloco }}</p>
        <p class="target-date">{{ nextDelivery | translocoDate : { weekday: 'long', month: 'long', day: 'numeric' } }}</p>
    </div>
    <p class="ordering-ends">{{ 'market.timer.ordering' | transloco }}</p>
    <div class="countdown">
        <div class="inner-count">
            <p class="number">{{ days }}</p>
            <p class="text">{{ 'market.timer.days' | transloco }}</p>
        </div>
        <p class="colon">:</p>
        <div class="inner-count">
            <p class="number">{{ hours }}</p>
            <p class="text">{{ 'market.timer.hours' | transloco }}</p>
        </div>
        <p class="colon">:</p>
        <div class="inner-count">
            <p class="number">{{ minutes }}</p>
            <p class="text">{{ 'market.timer.minutes' | transloco }}</p>
        </div>
        <p class="colon">:</p>
        <div class="inner-count">
            <p class="number">{{ seconds }}</p>
            <p class="text">{{ 'market.timer.seconds' | transloco }}</p>
        </div>
    </div>
    <p class="until">{{ 'market.timer.until' | transloco }}</p>
</div>
