import { Component, OnInit, Input } from '@angular/core';
import { Menu, Restaurant, TimePeriod } from '../../core/models';
import { TranslocoService } from '@ngneat/transloco';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'app-delivery-closed',
	templateUrl: './delivery-closed.component.html',
	styleUrls: ['./delivery-closed.component.css'],
})
export class DeliveryClosedComponent implements OnInit {
	@Input('restaurant') restaurant: Restaurant;
    @Input('orderType') orderType: string;

	isCurrentlyOpen: boolean;
    isOtherMenuOpen: boolean;
    masterSchedule: any[] = [[], [], [], [], [], [], []]

	constructor(
		public translate: TranslocoService,
		public activeModal: NgbActiveModal) {}

	ngOnInit(): void {
        const menus = this.restaurant.menus.filter(menu => menu.type == this.orderType);
        this.masterSchedule = menus != null && menus.length > 0 ? this.parseSchedule(menus) : [[], [], [], [], [], [], []];
        this.isCurrentlyOpen = false//menu.isOpen;
    }

	parseSchedule(menus: Menu[]) {
		let masterSchedule = [[], [], [], [], [], [], []];
		menus.forEach(menu => {
			for (let i = 0; i < masterSchedule.length; i++) {
				masterSchedule[i].push(menu.schedule[i]);
			}
		});
		return masterSchedule;
	}

	checkSchedule(day: any): boolean {
		for (let i = 0; i < day.length; i++) {
			if (day[i].length > 0) return false;
		}
		return true;
	}

	parseHours(schedules: any): string[] {
		let schedule = [];
        schedules.forEach(hours => {
			hours.forEach(hour => {
				schedule.push(this.prettyPrintTime(hour.start) + ' - ' + this.prettyPrintTime(hour.end))
			})
		});
		return schedule;
	}

	private prettyPrintTime(date: any): string {
        let options: Intl.DateTimeFormatOptions = { hour: 'numeric', minute: 'numeric' };
        return this.translate.getActiveLang() === 'en' ? (new Date(date)).toLocaleString("en-US", options) :
        	(new Date(date)).toLocaleString("fr-CA", options);
	}
}
