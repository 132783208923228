import { Component, Input, OnInit } from '@angular/core';
import Icon from '../icon.interface'

@Component({
    selector: 'app-chevron-right',
    templateUrl: './chevron-right.component.html',
    styleUrls: ['./chevron-right.component.css']
})
export class ChevronRightComponent implements OnInit, Icon {

    @Input() fill: string = '#000000'

    constructor() { }

    ngOnInit(): void {
    }

}
