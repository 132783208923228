import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationStart, Router, RouterEvent } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter } from 'rxjs';
import { Exposition, Menu } from 'src/app/core/models';
import { MainService } from 'src/app/core/services/main.service';
import { ExpoModalComponent } from 'src/app/modals/expo-modal/expo-modal.component';

@UntilDestroy()
@Component({
	selector: 'expo-restaurant-listing',
	templateUrl: './restaurant-listing.component.html',
	styleUrls: ['./restaurant-listing.component.css']
})
export class ExpoRestaurantListingComponent implements OnInit {

	exposition: Exposition;
	showNavigationIndicators: boolean = true;
	showNavigationArrows: boolean = false;
	waitTime: number = 60;

	carouselItems = [
		{
			title: "",
			image: "../../../../assets/images/expo/pocha/pocha-header.jpg"
		}
	];

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private modalService: NgbModal,
		private mainService: MainService
	) { }

	ngOnInit(): void {
		this.exposition = this.route.parent?.snapshot?.data?.['exposition'];
	}

	getTimeIconColor(waitTime: number) {
		if (waitTime < 16) return '#198754';
		else if (waitTime < 31) return '#fde92b';
		else if (waitTime <= 46) return '#fd7e14';
		else if (waitTime > 46) return '#dc3545';
	}

	getDelayInMinutes(delay: number) {
		if (delay <= 0) return '<15';
		else return delay / 60;
	}

	findRestaurantMenu(vendors: any, page: string) {
		return vendors
			.filter((vendor: any) => vendor.vendor.slug === page)
			.map((vendor: any) => vendor.menus.find((menu: Menu) => menu.type === "expo"))
			.find((menu: Menu) => menu);
	}

	showErrorModal(header: string, text: string, submitText: string, cancelText: string = null, 
		dismissCallback: () => any, submitCallback: () => any) {
		const modalRef = this.modalService.open(ExpoModalComponent, { centered: true });

		modalRef.componentInstance.header = header;
		modalRef.componentInstance.text = text;
		if (cancelText != null) modalRef.componentInstance.cancelButtonText = cancelText
		modalRef.componentInstance.submitButtonText = submitText;
		modalRef.result.then(submitCallback, dismissCallback);
	}

	getEmojiFromOrderUnderway(orderCount: number): string {
		let emojis = ['🔥','⚡'];
		if (orderCount < 10) return "";
		else if (orderCount < 30) return emojis[orderCount % emojis.length];
		else return "🔥🔥🔥";
	}

	getOrderCount(orderCount: number) {
		return orderCount;
	}
}
