<div *ngIf="showCrate" class="crate-container">
	<img (click)="closeCrate()" class="crate-close-icon" src="../../assets/images/close-icon.png" alt="x icon" />
	<div class="crate-top-container">
		<h2 class="crate-header"> {{ 'crate.myCrate' | transloco }} </h2>
		<div class="crate-line"></div>
	</div>
	<div *ngIf="isLoading; else isNotLoading">
		<div class="loading-crate-restaurant" *ngFor="let index of [0, 1]">
			<div class="loading-crate-header loading-gradient"></div>
			<div class="loading-crate-item-container">
				<div *ngFor="let _ of [].constructor(3)" class="crate-items">
					<div class="loading-item-image loading-gradient"></div>
					<div class="loading-item-details">
						<div class="loading-item-title loading-gradient"></div>
						<div class="loading-item-price loading-gradient"></div>
					</div>
				</div>
			</div>
			<div *ngIf="index == 0" class="loading-restaurant-line"></div>
		</div>
	</div>
	<ng-template #isNotLoading>
		<div class="crate-middle-container">
			<div *ngIf="(crate$ | async) == null || (crate$ | async)?.isEmpty" class="empty-crate-message">
				{{ 'crate.emptyMessage' | transloco }}
			</div>
			<ng-container *ngIf="!(crate$ | async)?.isEmpty">
				<div *ngFor="let group of itemGroups" class="crate-items-wrapper">
					<div class="crate-items-restaurant">{{ group.name }}</div>
					<div *ngFor="let item of group.items" class="crate-item">
						<app-crate-item [item]="item"></app-crate-item>
					</div>
				</div>
			</ng-container>
		</div>
	</ng-template>
	<div class="crate-bottom-container">
		<div class="crate-line"></div>
		<div *ngIf="!(order$ | async)?.isActive" class="crate-checkout-button-wrapper" 
			[ngbTooltip]="(crate$ | async)?.isEmpty
						? ('bag.emptyMessage' | transloco)
						: ((crate$ | async)?.subtotal.lessThan(configService.MINIMUM_MARKET_ORDER_AMOUNT)
							? ('bag.belowMinimumOrderText' | transloco: { remaining: ((configService.MINIMUM_MARKET_ORDER_AMOUNT.subtract((crate$ | async)?.subtotal)) | price | translocoCurrency) })
							: '')" [disableTooltip]="!disableCheckoutButton()" tooltipClass="radish-tooltip">
			<button [disabled]="(crate$ | async) == null || (crate$ | async).isEmpty || disableCheckoutButton() || isLoading" class="crate-checkout-button"
				(click)="clickCheckout()">
				{{ 'bag.checkout' | transloco }} - {{ (crate$ | async)?.subtotal | price | translocoCurrency }}
			</button>
		</div>
        <button *ngIf="(order$ | async)?.isActive" class="crate-checkout-button" (click)="clickTrackOrder()">
            {{ 'bag.trackOrder' | transloco }}
        </button>	</div>
</div>