import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslocoService } from '@ngneat/transloco';
import { Observable } from 'rxjs';
import { Category } from 'src/app/core/models';
import { FaqComponent } from 'src/app/modals/faq/faq.component';
import { MarcheRadishLetterComponent } from 'src/app/modals/marche-radish-letter/marche-radish-letter.component';

@Component({
	selector: 'app-category-market-section-header',
	templateUrl: './category-market-section-header.component.html',
	styleUrls: ['./category-market-section-header.component.css']
})
export class CategoryMarketSectionHeaderComponent implements OnInit {

	@Input() category$: Observable<Category>;
	// @Input() categorySlug: 

	features =
		[
			// TODO: check - does this go here too? already in main-market-section-header component
			// {
			// 	"title": "market.features.holidayGiftBoxes.title",
			// 	"subtitle": "market.features.holidayGiftBoxes.subtitle",
			// 	"image": "../../../../assets/images/market/header-features/mr-header-holiday-gift-box.jpg",
			// 	"id": "feature-holiday-gift-box",
			// 	"url": "cadeaux"
			// },
			{
				"title": "market.features.whyWeStarted.title",
				"subtitle": "market.features.whyWeStarted.subtitle",
				"image": "../../../../assets/images/market/header-features/mr-header-1.jpg",
				"id": "feature-1",
				"url": ""
			},
			{
				"title": "market.features.faq.title",
				"subtitle": "market.features.faq.subtitle",
				"image": "../../../../assets/images/market/header-features/mr-header-2.jpg",
				"id": 'feature-2',
				"url": ""
			},
			{
				"title": "market.categories.bakery",
				"subtitle": "",
				"image": "../../../../assets/images/market/header-features/mr-header-3.jpg",
				"id": "feature-3",
				"url": "bakery"
			},
		];
	browserLang: string;

	constructor(
		private modalService: NgbModal,
		public router: Router,
		public translate: TranslocoService
	) { }

	ngOnInit(): void {
		this.translate.langChanges$.subscribe(lang => {
			this.browserLang = lang;
		});
	}

	openMarcheLetterModal() {
		let modal = this.modalService.open(MarcheRadishLetterComponent, {
			scrollable: true,
			keyboard: true,
			centered: true,
			size: 'xl'
		});
		return modal.result.then(() => { }, () => { });
	}

	openFAQModal() {
		let modal = this.modalService.open(FaqComponent, {
			scrollable: true,
			keyboard: true,
			centered: true,
			size: 'xl'
		});
		return modal.result.then(() => { }, () => { });
	}

	onClickHeaderFeature(id: string, url?: string) {
		switch (id) {
			case 'feature-holiday-gift-box':
				this.router.navigate(['/' + this.translate.getActiveLang() + '/' + url]);
				return;
			case 'feature-1':
				this.openMarcheLetterModal();
				return;
			case 'feature-2':
				this.openFAQModal();
				return;
			case 'feature-3':
				this.router.navigate(['/' + this.translate.getActiveLang() + '/marché/' + url]);
				return;
		}
	}

	// getCategoryTranslation() {
	// 	switch (this.category?.slug) {
	// 		case 'latest':
	// 			// TODO Add the section here
	// 			break;
	// 		case 'products':
	// 			return this.translate.getTranslation('market.categories.all');
	// 		default:
	// 			return this.category.title;

	// 	}
	// }
}