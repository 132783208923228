import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { catchError, Observable } from 'rxjs';
import { Exposition } from '../models';
import { ExpositionService } from '../services/exposition.service';
import { SEOService } from '../services/seo.service';

@Injectable({
	providedIn: 'root'
})
export class ExpositionResolverService implements Resolve<boolean | Exposition> {

	constructor(private expositionService: ExpositionService,
		private router: Router,
		private seoService: SEOService) {

	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> | Observable<Exposition | boolean> {
		let slug = route.paramMap.get('slug');
		if (!slug) return this.router.navigate(['/']);
		return this.expositionService.fetch(slug)
		.pipe(catchError(err => this.router.navigate(['/'])));
	}
}

