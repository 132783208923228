<div class="gift-box-container">
    <div class="gift-box-main-container">
        <nav class="navbar navbar-transparent">
            <div class="navbar-logo-options-container me-auto">
                <a class="navbar-logo-container">
                    <img id="navbar-logo" src="../../assets/images/radish-logos/Radish-Logo-BlackText-Large.png"
                        alt="Radish" routerLink="{{ getLogoRoute() }}" (click)="onClickRadishLogo()" />
                </a>
            </div>
        </nav>
        <div class="language">
            <button *ngIf="translate.getActiveLang() == 'en'"
                (click)="useLanguage('fr')" class="language-btn">
                FR
            </button>
            <button *ngIf="translate.getActiveLang() == 'fr'"
                (click)="useLanguage('en')" class="language-btn">
                EN
            </button>
        </div>
        <div class="gift-box-column">
            <router-outlet></router-outlet>
        </div>
    </div>

    <!-- Order Summary Panel (Desktop) -->
    <div class="gift-box-order-summary-container"
        @slideInOut *ngIf="(giftBox$ | async) && !(giftBox$ | async)?.isEmpty">
            <app-gift-box-order-summary [giftBox]="(giftBox$ | async)" [order]="(order$ | async)"
                [@slideInOut]="(!(giftBox$ | async)?.isEmpty) ? 'open' : 'closed'">
            </app-gift-box-order-summary>
    </div>

    <!-- Order Summary Bottom Sheet (Mobile)-->
    <ng-container *ngIf="isMobile">
        <div class="overlay" @overlayToggle *ngIf="(giftBox$ | async) && !(giftBox$ | async)?.isEmpty && bottomSheetOpen"
            (click)="bottomSheetOpen = false"
            [style.pointer-events]="bottomSheetOpen ? 'auto' : 'none'"></div>
        <div  class="bottom-sheet-container d-block d-lg-none"
            [@slideUpToggle]="bottomSheetOpen ? 'open' : 'closed'"
            @slideUpAppear (@slideUpAppear.done)="animationDone($event)"
            *ngIf="(giftBox$ | async) && !(giftBox$ | async)?.isEmpty">
            <button type="button" #bottomSheetContent class="bottom-sheet-button" (click)="toggleBottomSheet()">
                <div class="bottom-sheet-button-wrapper">
                    <div class="bottom-sheet-button-title">{{ 'orderSummary.orderSummary' | transloco }}</div>
                    <!-- <div class="bottom-sheet-button-price">{{ (order || virtualizedOrder)?.total| price | translocoCurrency }}</div> -->
                </div>
            </button>
            <app-gift-box-order-summary class="bottom-sheet-content" (closeBottomSheet)="toggleBottomSheet()" [giftBox]="(giftBox$ | async)" [order]="(order$ | async)"></app-gift-box-order-summary>
        </div>
    </ng-container>
</div>
