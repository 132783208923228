import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appTipKeypress]'
})
export class TipKeypressDirective {
  constructor(private el: ElementRef) {}

  @HostListener('keypress', ['$event']) 
  onKeyPress(event: KeyboardEvent) {
    let currentValue = this.el.nativeElement.value
    let inputIndex: number = this.el.nativeElement.selectionStart;

    let updatedValue: string = currentValue.slice(0, inputIndex) + event.key + currentValue.slice(inputIndex, currentValue.length)
    let decimalIndex: number = updatedValue.indexOf('.')
    let result: string[] = updatedValue.split('.');
    if ((decimalIndex != -1 && result.length > 1) && (inputIndex > decimalIndex && result[1].length > 2)) event.preventDefault()
    else if (result[0].length >= 5 && +updatedValue >= 100000) event.preventDefault()
  }
}
