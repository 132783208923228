<!-- Gift Card Checkout Tab -->

<div class="gift-card-checkout-container">
    <h2>Checkout</h2>
    
    <form 
        [formGroup]="checkoutForm" 
        action="submit" 
        (submit)="handleForm($event)" 
        class="checkout-box-content checkout-box-cards-container"
    >
        <div>
            <input placeholder="Name" formControlName="name">
            <input type="email" placeholder="Email" formControlName="email">
        </div>
        
        <div class="checkout-card-section-label">
            {{ 'checkout.cards' | transloco }}
        </div>
        
        <label *ngFor="let card of savedCards" class="checkout-card" (click)="onSavedCardClick()">
            <input 
                class="checkout-card-radio-input" 
                type="radio" 
                formControlName="cardRadio"
                [value]="card.id"
            />
            <div class="checkout-saved-card">
                {{ 'checkout.savedCard' | transloco }}
                <strong>{{ card.last4 }}</strong>
            </div>
            <img class="checkout-saved-card-logo" [src]="getCardLogo(card.brand)" alt="" />
        </label>
        
        <button 
            type="button" 
            *ngIf="!openNewCard" 
            (click)="onClickAdd('newCard')" 
            class="add-new-card-label checkout-add-button"
        >
            {{ 'checkout.addCard' | transloco }}
        </button>
        
        <div [ngClass]="openNewCard == true ? 'open-new-card' : 'close-new-card'">
            <input 
                class="checkout-card-radio-input" 
                type="radio" 
                formControlName="cardRadio"
                value="new-card" 
                id="new-card" 
                hidden 
            />
            <div 
                [class.checkout-disabled]="loading" 
                class="checkout-box-input form-input" 
                #cardElement
            >
            </div>
        </div>
        
        <p class="checkout-box-payment-errors">
            {{ cardErrors }}
        </p>
    
        <button type="submit">heyo</button>
    </form>
</div>

