<div class="market-product-wrapper">
    <div class="market-product-image-container" [routerLink]="routeToRestaurant == true ? ['/' + translate.getActiveLang() + '/restaurants/' + item.slug] : ['/' + translate.getActiveLang() + '/marché/merchants/' + item.slug]"> 
            <img [src]="item.marketHeaderImageUrl" alt="" class="restaurants-item-image market-product-image" />
    </div>
    <div class="marche-product-label">
        <a [routerLink]="routeToRestaurant == true ? ['/' + translate.getActiveLang() + '/restaurants/' + item.slug] : ['/' + translate.getActiveLang() + '/marché/merchants/' + item.slug]">
            <div class="market-product-vendor"> {{ item.name }} </div>
            <!-- Might add vendor description -->
            <!-- <div class="market-vendor-description">
                {{ item?.brief }}
            </div> -->
        </a>
    </div>
    <a class="market-product-image-routing" [routerLink]="routeToRestaurant == true ? ['/' + translate.getActiveLang() + '/restaurants/' + item.slug] : ['/' + translate.getActiveLang() + '/marché/merchants/' + item.slug]">
        <div class="overlay">
            <img class="overlay-image" src="../../../assets/images/icons/radish-incubator.svg">
        </div>
    </a>
</div>

