<app-navbar [navbarType]="navbarType"></app-navbar>
<div class="restaurant-listing-container">
    <div class="inner-container">
        <div class="header-wrapper">
            <h1 class="header">{{ 'restaurants.restaurants' | transloco }}</h1>
            <!-- <a class="market-routing-button" [routerLink]="['/' + translate.getActiveLang() + '/marché/']">
                <div class="market-routing-icon-wrapper">
                    <img class="market-routing-icon" src="../../assets/images/icons/grocery.png" />
                </div>
                <div class="market-routing-message"> {{ 'routing.toMarket' | transloco }}</div>
            </a> -->
        </div>
        <div class="filters-top-line"></div>
        <div id="filters" class="filters">
            <a *ngFor="let slug of categorySlugs" [attr.id]="slug" class="filters-link" [routerLink]="[categoryRoute(slug)]"
                [ngClass]="{ active: filter == slug }">
                <span class="filters-emoji">{{ 'restaurants.filters.emojis.' + slug | transloco }}</span>
                <span class="filters-title">{{ 'restaurants.filters.' + slug | transloco }}</span>
            </a>
        </div>
        <!-- <div class="filters">
            <a class="filters-link" [routerLink]="[categoryRoute()]" [ngClass]="{ active: filter == '' }">{{ 'restaurants.filters.all' | transloco }}</a>
            <a class="filters-link" [routerLink]="[categoryRoute('affordable')]" [ngClass]="{ active: filter == 'affordable' }"><span class="filters-emoji">💸</span>{{ 'restaurants.filters.affordable' | transloco }}</a>
            <a class="filters-link" [routerLink]="[categoryRoute('local')]" [ngClass]="{ active: filter == 'local' }"><span class="filters-emoji">❤️</span>{{ 'restaurants.filters.local' | transloco }}</a>
            <a class="filters-link" [routerLink]="[categoryRoute('pizza')]" [ngClass]="{ active: filter == 'pizza' }"><span class="filters-emoji">🍕</span>{{ 'restaurants.filters.pizza' | transloco }}</a>
            <a class="filters-link" [routerLink]="[categoryRoute('sushi+poke')]" [ngClass]="{ active: filter == 'sushi+poke' }"><span class="filters-emoji">🍣</span>{{ 'restaurants.filters.sushi' | transloco }}</a>
            <a class="filters-link" [routerLink]="[categoryRoute('classy')]" [ngClass]="{ active: filter == 'classy' }"><span class="filters-emoji">🥂</span>{{ 'restaurants.filters.classy' | transloco }}</a>
            <a class="filters-link" [routerLink]="[categoryRoute('vegan')]" [ngClass]="{ active: filter == 'vegan' }"><span class="filters-emoji">🌱</span>{{ 'restaurants.filters.vegan' | transloco }}</a>
            <div ngbDropdown class="d-none d-md-flex" ngbTooltip="{{ 'restaurants.filterUnavailable' | transloco }}">
                <button class="filters-filter" id="dropdownBasic1" ngbDropdownToggle disabled>
                    {{ 'restaurants.cuisine' | transloco }}
                </button>
                <div ngbDropdownMenu aria-labelledby="dropdownBasic1"
                    class="filters-dropdown-menu-container filters-dropdown-menu-container-wide">
                    <div class="filters-dropdown-menu-wide">
                        <button class="filters-dropdown-item">
                            Chinese
                        </button>
                        <button class="filters-dropdown-item">
                            Indian
                        </button>
                        <button class="filters-dropdown-item">
                            Canadian
                        </button>
                        <button class="filters-dropdown-item">
                            Portuguese
                        </button>
                        <button class="filters-dropdown-item">
                            Afghan
                        </button>
                        <button class="filters-dropdown-item">
                            French
                        </button>
                        <button class="filters-dropdown-item">
                            Persian
                        </button>
                        <button class="filters-dropdown-item">
                            Italian
                        </button>
                        <button class="filters-dropdown-item">
                            American
                        </button>
                    </div>
                </div>
            </div> -->
        <!--   <div ngbDropdown class="d-none d-md-flex" ngbTooltip="{{ 'restaurants.filterUnavailable' | transloco }}">
                <button class="filters-filter" id="dropdownBasic1" ngbDropdownToggle disabled>
                    {{ 'restaurants.price' | transloco }}
                </button>
                <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="filters-dropdown-menu-container">
                    <div class="filters-dropdown-menu-single">
                        <button class="filters-dropdown-item">$</button>
                        <button class="filters-dropdown-item">$$</button>
                        <button class="filters-dropdown-item">
                            $$$
                        </button>
                        <button class="filters-dropdown-item">
                            $$$$
                        </button>
                    </div>
                </div>
            </div>
            <div ngbDropdown class="d-none d-md-flex" ngbTooltip="{{ 'restaurants.filterUnavailable' | transloco }}">
                <button class="filters-filter" id="dropdownBasic1" ngbDropdownToggle disabled>
                    {{ 'restaurants.time' | transloco }}
                </button>
                <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="filters-dropdown-menu-container">
                    <div class="filters-dropdown-menu-single">
                        <button class="filters-dropdown-item">
                            0 - 30 mins
                        </button>
                        <button class="filters-dropdown-item">
                            30 - 45 mins
                        </button>
                        <button class="filters-dropdown-item">
                            45 - 60 mins
                        </button>
                        <button class="filters-dropdown-item">
                            60+ mins
                        </button>
                    </div>
                </div>
            </div>
            <div ngbDropdown class="d-none d-md-flex" ngbTooltip="{{ 'restaurants.filterUnavailable' | transloco }}">
                <button class="filters-filter" id="dropdownBasic1" ngbDropdownToggle disabled>
                    {{ 'restaurants.restrictions' | transloco }}
                </button>
                <div ngbDropdownMenu aria-labelledby="dropdownBasic1"
                    class="filters-dropdown-menu-container filters-dropdown-menu-container-wide">
                    <div class="filters-dropdown-menu-wide">
                        <button class="filters-dropdown-item">
                            Vegan
                        </button>
                        <button class="filters-dropdown-item">
                            Vegetarian
                        </button>
                        <button class="filters-dropdown-item">
                            Halal
                        </button>
                        <button class="filters-dropdown-item">
                            Gluten free
                        </button>
                    </div>
                </div>
            </div>
            <div ngbDropdown class="d-flex d-sm-flex d-md-none filters"
                ngbTooltip="{{ 'restaurants.filterUnavailable' | transloco }}">
                <button class="filters-filter" id="dropdownBasic1" ngbDropdownToggle disabled>
                    {{ 'restaurants.filter' | transloco }}
                </button>
            </div> 
     </div> -->
    <div class="double-horizontal-line"></div>
    <div class="body">
        <div class="app-banner">
            <div class="app-banner-text">
                <div class="app-banner-heading">
                    {{ 'main.appBanner.heading' | transloco }} 
                </div>
                <div class="app-banner-subheading">
                    {{ 'main.appBanner.subheading' | transloco }} 
                    <a href="{{ 'main.appBanner.link' | transloco }}" class="app-banner-link" target="_blank"> 
                        {{ 'main.appBanner.cta' | transloco }}
                    </a>
                </div>
            </div>
        </div>
        <div class="featured">
            <div class="featured-large desktop d-none d-sm-none d-md-none d-lg-block d-xl-block"
                (click)="onHeaderFeature(featuredRestaurantLarge.url)">
                <div class="featured-large-content-wrapper">
                    <div class="overlay">
                    </div>
                    <div class="featured-large-content">
                        <div class="featured-large-content-top">
                            <img src="../../assets/images/Radish-LogoMark-200x200.jpg">
                            <span class="featured-large-content-top-text">{{
                                featuredRestaurantLarge.publisher[translate.getActiveLang()] }}</span>
                        </div>
                        <!-- <div class="featured-large-content-center">
                            <img src="../../../assets/images/mtl-piquant-logo-text-white.png" alt="">
                        </div> -->
                        <div class="featured-large-text-wrapper">
                            <p class="featured-large-description"
                                [innerHTML]="featuredRestaurantLarge.description[translate.getActiveLang()]">
                            </p>
                            <p class="featured-large-name">
                                {{ featuredRestaurantLarge.name[translate.getActiveLang()] }}
                            </p>
                        </div>
                    </div>
                    <img class="featured-large-image featured-image" [src]="featuredRestaurantLarge.image" alt="" />
                </div>
            </div>
            <div class="first-row-featured-container d-flex d-sm-flex d-md-flex d-lg-none d-xl-none">
                <div class="featured-large mobile" (click)="onHeaderFeature(featuredRestaurantLarge.url)">
                    <div class="featured-large-content-wrapper">
                        <div class="overlay"></div>
                        <div class="featured-large-content">
                            <div class="featured-large-content-top">
                                <img src="../../assets/images/Radish-LogoMark-200x200.jpg">
                                <span class="featured-large-content-top-text">{{
                                    featuredRestaurantLarge.publisher[translate.getActiveLang()] }}</span>
                            </div>
                            <!-- <div class="featured-large-content-center">
                                <img src="../../../assets/images/mtl-piquant-logo-text-white.png" alt="">
                            </div> -->
                            <div class="featured-large-text-wrapper">
                                <p class="featured-large-description"
                                    [innerHTML]="featuredRestaurantLarge.description[translate.getActiveLang()]">
                                </p>
                                <p class="featured-large-name">
                                    {{ featuredRestaurantLarge.name[translate.getActiveLang()] }}
                                </p>
                            </div>
                        </div>
                        <img class="featured-large-image featured-image" [src]="featuredRestaurantLarge.image" alt="" />
                    </div>
                </div>
                <div class="featured-medium mobile" (click)="onHeaderFeature(featuredMedium.url)">
                    <div class="featured-medium-content-wrapper">
                        <div class='overlay' [routerLink]="['/' + translate.getActiveLang() + '/restaurants/' + '']">
                        </div>
                        <div class="featured-medium-content">
                            <div class="featured-medium-text-wrapper">
                                <p class="featured-medium-title">
                                    {{ featuredMedium.title[translate.getActiveLang()] }}
                                </p>
                            </div>
                        </div>
                        <img class="featured-medium-image featured-image" [src]="featuredMedium.image" alt="" />
                    </div>
                </div>
            </div>
            <div class="featured-small-container">
                <div class="scroll-space d-inline-block d-md-inline-block d-lg-none"></div>
                <div class="featured-small-item-container featured-small-item-container-first">
                    <div class="featured-small" (click)="onHeaderFeature(featuredSmall[0].url)">
                        <!-- <div class="featured-small"
                            [routerLink]="['/' + translate.getActiveLang() + '/restaurants/' + featuredSmall[0].slug]"> -->
                        <div class="featured-small-text">
                            <div class="featured-small-text-title featured-small-text-title-first">
                                <span class="featured-small-text-title-text"> {{
                                    featuredSmall[0].title[translate.getActiveLang()] }}</span>
                                <i class="fas fa-microphone"></i>
                            </div>
                            <div class="featured-small-text-name">
                                {{ featuredSmall[0].name[translate.getActiveLang()] }}
                            </div>
                            <div
                                class="featured-small-text-description featured-description d-none d-md-block d-lg-none d-xl-block">
                                {{ featuredSmall[0].description[translate.getActiveLang()] }}
                            </div>
                        </div>
                        <div class="featured-small-link">
                            <img class="featured-small-image" [src]="featuredSmall[0].image" alt="" />
                        </div>
                    </div>
                </div>
                <div class="featured-small-item-container featured-small-item-container-second">
                    <div class="featured-small"
                        [routerLink]="['/' + translate.getActiveLang() + '/restaurants/' + featuredSmall[1].slug]">
                        <div class="featured-small-text">
                            <div class="featured-small-text-title featured-small-text-title-second">
                                <span class="featured-small-text-title-text"> {{
                                    featuredSmall[1].title[translate.getActiveLang()] }}</span>
                                <i class="fas fa-star"></i>
                            </div>
                            <div class="featured-small-text-name">
                                {{ featuredSmall[1].name[translate.getActiveLang()] }}
                            </div>
                            <div class="featured-small-text-description featured-description d-none d-md-block d-lg-none d-xl-block"
                                [innerHTML]="featuredSmall[1].description[translate.getActiveLang()] ">
                            </div>
                        </div>
                        <div class="featured-small-link">
                            <img class="featured-small-image" [src]="featuredSmall[1].image" alt="" />
                        </div>
                    </div>
                </div>
                <div class="scroll-space d-inline-block d-md-inline-block d-lg-none"></div>
            </div>
            <div class="featured-medium desktop d-none d-sm-none d-md-none d-lg-block d-xl-block"
                (click)="onHeaderFeature(featuredMedium.url)">
                <div class="featured-medium-content-wrapper"
                    [routerLink]="['/' + translate.getActiveLang() + '/restaurants/' + '']">
                    <div class="featured-medium-content-top">
                        <img class="featured-medium-image featured-image" [src]="featuredMedium.image" alt="" />
                    </div>
                    <div class="featured-medium-text-wrapper">
                        <p class="featured-medium-title">
                            {{ featuredMedium.title[translate.getActiveLang()] }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <hr>
        <div class="restaurants" [ngClass]="{ 'loading-restaurants' : areRestaurantsResolving == true }">
            <div class="loading-container" *ngIf="areRestaurantsResolving; else restaurantsLoaded">
                <div class="loading">
                    <div *ngFor="let item of [].constructor(36)" class="loading-item">
                        <div class="loading-item-header loading-gradient"></div>
                        <div class="loading-item-title loading-gradient"></div>
                        <div class="loading-item-tag loading-gradient"></div>
                        <div class="loading-item-brief-wrapper">
                            <div class="loading-item-brief loading-gradient"></div>
                            <div class="loading-item-brief loading-gradient"></div>
                            <div class="loading-item-brief loading-gradient"></div>
                        </div>
                    </div>
                </div>
            </div>
            <ng-template #restaurantsLoaded>
                <div *ngFor="let restaurant of restaurants; let i = index" class="restaurants-item-container">

                    <div class="restaurants-item restaurants-item-mobile d-flex d-sm-flex d-md-none"
                        [routerLink]="['/' + translate.getActiveLang() + '/restaurants/' + restaurant.slug]">
                        <div class="restaurants-item-image-container">
                            <div class="restaurants-item-tags">
                                <div *ngFor="let category of filterCategories(restaurant.featuredCategories, ['cuisine', 'service'])"
                                    class="restaurants-item-tag">
                                    {{ category.title }}
                                </div>
                            </div>
                            <div class="restaurants-item-deliveryTime restaurants-item-extra">
                                <img class="clock-icon" src="../../assets/images/icons/clock.svg">
                                <span class="delivery-time">
                                    {{ restaurant.delivery.time - 5 }} - {{ restaurant.delivery.time + 5 }} mins
                                </span>
                            </div>
                            <img [src]="restaurant.headerImageUrl" alt="" class="restaurants-item-image" />
                            <div *ngIf="!isRestaurantOpen(restaurant)" class="restaurant-closed-container">
                                <img *ngIf="translate.getActiveLang() == 'en'" class="restaurant-closed-sign"
                                    src="../../assets/images/icons/closed-en.svg">
                                <img *ngIf="translate.getActiveLang() == 'fr'" class="restaurant-closed-sign"
                                    src="../../assets/images/icons/closed-fr.svg">
                                <div class="restaurant-closed-text"
                                    *ngIf="getNextOpenDate(restaurant, scheduledDate) as nextOpenDate">
                                    {{ getNextOpenDateString(nextOpenDate, scheduledDate) | transloco : { weekDay:
                                    getAnotherOpenWeekDayString(nextOpenDate) } }} {{ nextOpenDate | translocoDate : {
                                    timeStyle: 'short' } }}
                                </div>
                            </div>
                        </div>
                        <div class="restaurants-item-text-container">
                            <div class="restaurants-item-name">
                                {{ restaurant.name }}
                            </div>
                            <div class="restaurants-item-extra-wrapper">
                                <div class="restaurants-item-district restaurants-item-extra">
                                    <img class="coordinates-icon" src="../../assets/images/icons/pin.svg">
                                    <span class="district">
                                        {{ restaurant.address.region.title }}
                                    </span>
                                </div>
                            </div>


                            <p *ngIf="restaurant.brief" class="restaurants-item-description">
                                <app-read-more [content]="restaurant.brief" [limit]="160" [completeWords]="true">
                                </app-read-more>
                            </p>

                            
                            <div *ngIf="filterCategories(restaurant.featuredCategories, ['trait']).length != 0"
                                class="restaurant-item-traits-wrapper">
                                <div *ngFor="let category of filterCategories(restaurant.featuredCategories, ['trait'])"
                                    class="restaurants-item-trait">
                                    <img class="restaurants-item-trait-icon"
                                        [src]="'../../assets/images/icons/' + category.slug + '.svg'"
                                        [title]="category.title" placement="top" [ngbTooltip]="category.title">
                                </div>
                            </div>
                        </div>
                    </div>
                    <a class="restaurants-item d-none d-md-flex">
                        <a class="restaurants-item-image-container" [routerLink]="['/' + translate.getActiveLang() + '/restaurants/' + restaurant.slug]">
                            <img [src]="restaurant.headerImageUrl" alt="" class="restaurants-item-image" />
                            <div *ngIf="!isRestaurantOpen(restaurant)" class="restaurant-closed-container">

                                <img *ngIf="translate.getActiveLang() == 'en'" class="restaurant-closed-sign"
                                    src="../../assets/images/icons/closed-en.svg">
                                <img *ngIf="translate.getActiveLang() == 'fr'" class="restaurant-closed-sign"
                                    src="../../assets/images/icons/closed-fr.svg">
                                <div class="restaurant-closed-text"
                                    *ngIf="getNextOpenDate(restaurant, scheduledDate) as nextOpenDate">
                                    {{ getNextOpenDateString(nextOpenDate, scheduledDate) | transloco : { weekDay:
                                    getAnotherOpenWeekDayString(nextOpenDate) } }} {{ nextOpenDate | translocoDate : {
                                    hour: "numeric", minute: "numeric" } }}
                                </div>
                            </div>
                        </a>
                        <div class="restaurants-item-text-container">
                            <div class="restaurants-item-name" [routerLink]="['/' + translate.getActiveLang() + '/restaurants/' + restaurant.slug]">
                                {{ restaurant.name }}
                            </div>
                            <div class="restaurants-item-tags" [routerLink]="['/' + translate.getActiveLang() + '/restaurants/' + restaurant.slug]">
                                <div *ngFor="let category of filterCategories(restaurant.featuredCategories, ['cuisine', 'service'])"
                                    class="restaurants-item-tag">
                                    {{ category.title }}
                                </div>
                            </div>


                            <p *ngIf="restaurant.brief" class="restaurants-item-description" ng-click="$event.stopPropagation()">
                                <app-read-more [content]="restaurant.brief" [limit]="130" [completeWords]="true">
                                </app-read-more>
                            </p>


                            <div class="restaurants-item-extra-wrapper" [routerLink]="['/' + translate.getActiveLang() + '/restaurants/' + restaurant.slug]">
                                <div class="restaurants-item-deliveryTime restaurants-item-extra">
                                    <img class="clock-icon" src="../../assets/images/icons/clock.svg">
                                    <span class="delivery-time">
                                        {{ restaurant.delivery.time - 5 }} - {{ restaurant.delivery.time + 5 }} mins
                                    </span>
                                </div>
                                <div class="restaurants-item-district restaurants-item-extra">
                                    <img class="coordinates-icon" src="../../assets/images/icons/pin.svg">
                                    <span class="district">
                                        {{ restaurant.address.region.title }}
                                    </span>
                                </div>
                            </div>
                            <div *ngIf="filterCategories(restaurant.featuredCategories, ['trait']).length != 0"
                                class="restaurant-item-traits-wrapper" [routerLink]="['/' + translate.getActiveLang() + '/restaurants/' + restaurant.slug]">
                                <div *ngFor="let category of filterCategories(restaurant.featuredCategories, ['trait'])"
                                    class="restaurants-item-trait">
                                    <img class="restaurants-item-trait-icon"
                                        [src]="'../../assets/images/icons/' + category.slug + '.svg'"
                                        [title]="category.title" placement="top" [ngbTooltip]="category.title">
                                </div>
                            </div>
                        </div>
                    </a>


                </div>

            </ng-template>
        </div>
    </div>
</div>
</div>
<app-footer></app-footer>