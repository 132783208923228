import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-featured-restaurants',
  templateUrl: './featured-restaurants.component.html',
  styleUrls: ['./featured-restaurants.component.css']
})
export class FeaturedRestaurantsComponent implements OnInit {

  @Input() restaurants: string[];

  numRows: number;
  restaurantsPerRow: number;


  
  
  constructor() { }

  ngOnInit(): void {
    this.restaurantsPerRow = 4;

    this.numRows = this.restaurants.length;
    this.numRows = (this.numRows / this.restaurantsPerRow);
    this.numRows = Math.ceil(this.numRows); // Get number of rows


  }

}
