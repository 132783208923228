import { Deserializable } from './deserializable.model';
import { Region } from './region.model';
import { TranslatedText, TranslatedTextType } from './translated-text.model';

export class Address implements Deserializable<Address> {
    id?: string;
    _title?: TranslatedText;
    loc?: {
        type?: string;
        coordinates?: number[];
    };
    line1?: string;
    line2?: string;
    apt?: string;
    city?: string;
    province?: string;
    postal?: string;
    country?: string;
    notes?: string;
    source?: any;
    region?: Region;
    isPreferred: boolean = false;

    constructor() {
        this.loc = {};
        this.apt = '';
        this.notes = '';
    }

    get title(): TranslatedTextType {
        return this._title?.value
    }

    set title(title: TranslatedTextType) {
        this._title = title as TranslatedText
    }

    deserialize(input: any): this {
        if (!input) return null;
        if (typeof input == 'string') this.id = input;
        else {
            Object.assign(this, input);
            this.id = input._id ? input._id : input.id
            this.region = new Region().deserialize(input.region);
            this.title = new TranslatedText().deserialize(input.title)
        }
		return this;
    }
}
