<div class="market-search-result-popover" [routerLink]="'/' + translate.getActiveLang() + merchant.url(ExtendedOrderType.Market)">
    <img class="popover-header-image" [src]="merchant.image" [alt]="merchant.title" [hidden]="!merchant.image">
    <div class="popover-content">
        <h3 class="popover-title">{{ merchant.title }}</h3>
        <!-- <ul class="tags-list">
            <li class="tags-list-item">
                <img src="../../../assets/images/icons/tag.svg" class="tag-icon"
                    [alt]="'market.search.category' | transloco">
                <div class="tag-text">{{ merchant.merchantType }}</div>
            </li>
            <li class="tags-list-item">
                <img src="../../../assets/images/icons/location-pin.svg"
                    class="tag-icon location-tag-icon"
                    [alt]="'market.search.location' | transloco">
                <div class="tag-text">{{ merchant.location }}</div>
            </li>
        </ul> -->
        <p class="brief" [hidden]="!merchant.brief">{{ merchant.brief }}</p>
        <a class="popover-visit-page-link">{{ 'market.search.popoverLink.merchant' | transloco }}</a>
    </div>
</div>