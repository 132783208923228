import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { Exposition } from '../models';
import { BaseService } from './base.service';

@Injectable({
	providedIn: 'root'
})
export class ExpositionService {

	private entity: string = 'expositions';

	constructor(private baseService: BaseService) { }

	fetch(slug: string) {
		return this.baseService.fetch(this.entity, slug).pipe(map(res => new Exposition().deserialize(res.exposition)))
	}
}
