import { Component, OnInit, Input } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'app-product-market-row-item',
  templateUrl: './product-market-row-item.component.html',
  styleUrls: ['./product-market-row-item.component.css']
})
export class ProductMarketRowItemComponent implements OnInit {

  @Input() item;
  @Input() hideCrateButton?:boolean = false;
  @Input() isFeaturedRow: boolean = true;
  @Input() showRestaurantName?:boolean = true;
   
  constructor(public translate: TranslocoService) { }

  ngOnInit(): void {
  }

}
