import { Deserializable } from "./deserializable.model";
import { TranslatedText, TranslatedTextType } from "./translated-text.model";

export class Region implements Deserializable<Region> {
    id: string;
	_title?: TranslatedText;
	address: any;
    fees: any;

    constructor() {}

    get title(): TranslatedTextType {
        return this._title?.value
    }

    set title(title: TranslatedTextType) {
        this._title = title as TranslatedText
    }

    deserialize(input: any): this {
        if (!input) return null;
        if (typeof input == 'string') this.id = input;
        else {
            Object.assign(this, input);
            this.title = new TranslatedText().deserialize(input.title)
        }
        return this;
    }
}
