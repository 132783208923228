import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SupportComponent } from '../../modals/support/support.component';
import { OrderService } from '../../core/services/order.service';
import { TranslocoService } from '@ngneat/transloco';
import { filter, first, map, skip } from 'rxjs/operators';
import { OrderSummaryComponent } from '../../modals/order-summary/order-summary.component';
import { Order, OrderEvent, OrderStatus, User } from '../../core/models';
import { BehaviorSubject, Observable, } from 'rxjs';
import { MapDirectionsService } from '@angular/google-maps';
import { MainService } from '../../core/services/main.service';
import { OrderType } from '../../core/enums';
import { SocketService } from '../../core/services/socket.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import moment from 'moment';

export interface ICachedOrder {
    id?: string;
    expiresAt?: Date;
}
@UntilDestroy()
@Component({
    selector: 'app-order-followup',
    templateUrl: './order-followup.component.html',
    styleUrls: ['./order-followup.component.css'],
})
export class OrderFollowupComponent implements OnInit {

    readonly LEEWAY_MINUTES: number = 3
    readonly DEFAULT_RESTAURANT_PREP_MINUTES = 25;
    readonly DEFAULT_DELIVERING_TIME_MINUTES = 15;
    readonly DEFAULT_TOTAL_ORDER_MINUTES = 45;
    readonly TIME_INCREMENT_IF_LATE = 3;
    readonly DEFAULT_MINUTES_FOR_DRIVER_TO_GET_TO_RESTAURANT = 3;

    user$: Observable<User>;
    order$: BehaviorSubject<Order> = new BehaviorSubject(null);
    orderType$: Observable<OrderType>;
    socketIsConnected$: Observable<boolean>;
    directionsResults$: Observable<google.maps.DirectionsResult | undefined>;

    events: OrderEvent[]

    bagLat: number;
    bagLng: number;
    restaurantLat: number;
    restaurantLng: number;
    
    markersReady: boolean = false;
    markerOptions;
    renderOptions;
    mapOptions: google.maps.MapOptions;
    mapStyle: any;
    houseIcon: any;
    restaurantIcon: any;

    isCancelled: boolean;
    inProcessing: boolean;
    inPreparation: boolean;
    isReady: boolean;
    inDelivery: boolean;
    isComplete: boolean;

    estimatedDrivingDurationInSeconds: number;

    OrderStatus = OrderStatus;

    constructor(
        private modalService: NgbModal,
        private orderService: OrderService,
        public translate: TranslocoService,
        private mainService: MainService,
        public mapDirectionsService: MapDirectionsService
    ) { }

    ngOnInit(): void {
        this.socketIsConnected$ = this.mainService.isSocketConnected$;
        this.user$ = this.mainService.userLoaded$;
        //TODO Double check if needed
        // this.mainService.scheduledDateLoaded$.subscribe((scheduledDate) => this.scheduledDate = scheduledDate);
        this.orderService.list(1, [OrderType.Delivery, OrderType.Pickup]).subscribe(orders => this.order$.next(orders[0]));
        this.order$.subscribe(order => this.setOrderInfo(order));
        this.mainService.orderLoaded$.pipe(filter(order => order != null), skip(1)).subscribe(order => this.order$.next(order));
        this.orderType$ = this.order$.pipe(map(order => <OrderType>order.type));

        this.socketIsConnected$.subscribe(bool => { 
            if (bool) this.mainService.listenForOrderUpdates();
        });

        this.restaurantIcon = {
            url: '../../assets/images/icons/restaurant.svg',
            scaledSize: {
                width: 60,
                height: 60
            }
        }
        this.houseIcon = {
            url: '../../assets/images/icons/house.svg',
            scaledSize: {
                width: 60,
                height: 60
            }
        }
        this.markerOptions = {
            origin: {
                icon: this.restaurantIcon
            },
            destination: {
                icon: this.houseIcon
            }
        };
        this.renderOptions = {
            polylineOptions: {
                strokeColor: '#FE2C7D',
            },
            suppressMarkers: true,
        };
        this.mapStyle = [
            {
                elementType: 'geometry',
                stylers: [{ color: '#f7f7f7' }]
            },
            {
                featureType: 'road',
                elementType: 'geometry',
                stylers: [{ color: '#ffffff' }]
            },
            {
                featureType: 'poi.business',
                stylers: [{ visibility: 'off' }]
            },
            {
                featureType: 'water',
                elementType: 'geometry',
                stylers: [{ color: '#51A3A3' }]
            }
        ];

        this.mapOptions = {
            gestureHandling: "none", 
            disableDefaultUI: true,
            styles: this.mapStyle
        }

    }
    
    setOrderInfo(order: Order) {
        if (order == null) return;

        this.isCancelled = false;
        this.inProcessing = false;
        this.inPreparation = false;
        this.isReady = false;
        this.inDelivery = false;
        this.isComplete = false;

        if (order?.status) {
            switch (order?.status) {
                case OrderStatus.Cancelled:
                case OrderStatus.Refused:
                    this.isCancelled = true;
                    break;
                case OrderStatus.Placed:
                case OrderStatus.Initialized:
                case OrderStatus.Scheduled:
                    this.inProcessing = true;
                    break;
                case OrderStatus.Preparing:
                    this.inPreparation = true;
                    break;
                case OrderStatus.Ready:
                    this.isReady = true;
                    break;
                case OrderStatus.Delivering:
                    this.inDelivery = true;
                    break;
                case OrderStatus.PickedUp:
                case OrderStatus.Delivered:
                    this.isComplete = true;
                    break;
            }
        }
        
        this.setMarkers(order);
    }

    setMarkers(order) {
        if (order) {
            this.bagLat = order.bag.address.loc.coordinates[1];
            this.bagLng = order.bag.address.loc.coordinates[0];
            this.restaurantLat = order.restaurant.address.loc.coordinates[1];
            this.restaurantLng = order.restaurant.address.loc.coordinates[0];

            this.markersReady = true;

            new google.maps.DistanceMatrixService().getDistanceMatrix({'origins': [{lat: this.restaurantLat, lng: this.restaurantLng}], 'destinations': [{lat: this.bagLat, lng: this.bagLng}], travelMode: google.maps.TravelMode.DRIVING}, (results: any) => {
                if (results?.rows?.length > 0 && results?.rows[0]?.elements?.length > 0 && results?.rows[0]?.elements[0]?.duration?.value)
                    this.estimatedDrivingDurationInSeconds = results?.rows[0]?.elements[0]?.duration?.value;
                else
                    this.estimatedDrivingDurationInSeconds = null
            });

            const request: google.maps.DirectionsRequest = {
                destination: { lat: this.bagLat, lng: this.bagLng },
                origin: { lat: this.restaurantLat, lng: this.restaurantLng },
                travelMode: google.maps.TravelMode.DRIVING
            };

            this.directionsResults$ = this.mapDirectionsService.route(request).pipe(map(response => response.result))
        }
    }

    getEstimatedDeliveryTime(order: Order) {
        let scheduledDate = order.scheduledDate != null ? (order.scheduledDate instanceof Date ? order.scheduledDate : new Date(order.scheduledDate)) : null;
        let currentDate = new Date();
        let est: Date;
        let event: OrderEvent;
        let drivingDuration = this.estimatedDrivingDurationInSeconds
                ? this.estimatedDrivingDurationInSeconds / 60 + this.LEEWAY_MINUTES
                : this.DEFAULT_DELIVERING_TIME_MINUTES + this.LEEWAY_MINUTES

        switch (order.status) {
            case OrderStatus.Initialized:
            case OrderStatus.Placed:
                if (scheduledDate == null) {
                    est = this.addMinutes(currentDate, drivingDuration + this.DEFAULT_RESTAURANT_PREP_MINUTES);
                    break;
                }
                return this.getScheduledTime(scheduledDate);
            case OrderStatus.Scheduled:
                return this.getScheduledTime(scheduledDate);
            case OrderStatus.Preparing:
                if (scheduledDate != null && scheduledDate > currentDate)
                    return this.getScheduledTime(scheduledDate)

                let delayEvent = order.events.slice().reverse().find(event => event.status === OrderStatus.Delayed);

                if (delayEvent) {
                    let timePassedSinceDelayInMs = currentDate.getTime() - (new Date(delayEvent.createdAt)).getTime();
                    let timePassedSinceDelayInMinutes = Math.floor(timePassedSinceDelayInMs / 60000);
                    let minutesLeftInDelay = Number(delayEvent.delay / 60) - timePassedSinceDelayInMinutes;
                    est = this.addMinutes(currentDate, minutesLeftInDelay + drivingDuration)
                } else {
                    event = order.events.find(event => event.status === OrderStatus.Preparing)
                    let createdAt = event?.createdAt ? moment.utc(event.createdAt).local().toDate() : new Date();
                    let delay = event?.delay ? event.delay : 0;
                    est = this.addMinutes(createdAt, (+(delay) / 60) + drivingDuration)
                }
                break;
            case OrderStatus.Ready:
                est = this.addMinutes(currentDate, drivingDuration + this.DEFAULT_MINUTES_FOR_DRIVER_TO_GET_TO_RESTAURANT);
                break;
            case OrderStatus.Delivering:
                event = order.events.find(event => event.status === OrderStatus.Delivering)
                console.log(order.events)
                if (event.delay) est = this.addMinutes(new Date(event.createdAt), +(event.delay) / 60);
                else est = this.addMinutes(new Date(event.createdAt), this.DEFAULT_DELIVERING_TIME_MINUTES);

                if (est.getTime() < (new Date()).getTime()) est = this.addMinutes(new Date(), this.TIME_INCREMENT_IF_LATE);
                break;
            case OrderStatus.Delivered:
                return this.translate.translate('followup.orderStatus.delivered');
            case OrderStatus.PickedUp:
                return this.translate.translate('followup.orderStatus.pickedup');
        }
        
        if (this.translate.getActiveLang() == 'fr') return est.getHours() + ':' + this.parseMinutes(est.getMinutes());
        else {
            let partOfDay = est.getHours() < 12 ? 'AM' : 'PM';
            let hour = est.getHours() % 12 == 0 ? 12 : est.getHours() % 12;
            return hour + ':' + this.parseMinutes(est.getMinutes()) + ' ' + partOfDay
        }
    }

    getScheduledTime(scheduledDate: Date) {
        let options: Intl.DateTimeFormatOptions = { weekday: 'short', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
        return this.translate.getActiveLang() == 'fr'
            ? (new Date(scheduledDate)).toLocaleString("fr", options)
            : (new Date(scheduledDate)).toLocaleString("en-US", options)
    }

    parseMinutes(minutes) {
        if (minutes < 10) {
            return '0' + minutes;
        }
        return minutes;
    }

    addMinutes(date: Date, minutes): Date {
        return new Date(date.getTime() + minutes * 60000);
    }

    clickSupport(): void {
        const modalRef = this.modalService.open(SupportComponent);
    }

    clickSummary(): void {
        const modalRef = this.modalService.open(OrderSummaryComponent);
        
        this.order$.subscribe((order) => {
            modalRef.componentInstance.order = order;
        })
    }

    encodeAddress(address: string) {
        return encodeURI(address);
    }

    isOrderDelayed(order: Order): boolean {
        return order.events.find(event => event.status === OrderStatus.Delayed) != null && order.status === OrderStatus.Preparing
    }
}
