import { Component, EventEmitter, Input, OnInit, Output, ElementRef, HostListener } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { Router } from '@angular/router';
import { Bag, BagItem, Order, TranslatedTextType } from 'src/app/core/models'
import { MainService } from 'src/app/core/services/main.service';
import { Observable, tap } from 'rxjs';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ConfigService } from 'src/app/core/services/config.service';

@UntilDestroy()
@Component({
	selector: 'app-crate',
	templateUrl: './crate.component.html',
	styleUrls: ['./crate.component.css']
})
export class CrateComponent implements OnInit {
	@Input('showCrate') showCrate: boolean;
	@Output() showCrateChange = new EventEmitter<boolean>();
	@Input('clickIcon') clickIcon: boolean;
	@Output('resetIcon') resetIcon = new EventEmitter<{}>();

	disableDecrement: boolean;
	isLoading: boolean = true;

	crate$: Observable<Bag>;
	order$: Observable<Order>;
	crate: Bag;
	itemGroups: { slug: string, name: TranslatedTextType, items: BagItem[] }[] = [];

	constructor(
		private router: Router,
		public translate: TranslocoService,
		private mainService: MainService,
		public elementRef: ElementRef,
		public configService: ConfigService
	) { }

	ngOnInit(): void {
		this.order$ = this.mainService.marketOrderLoaded$;
		this.crate$ = this.mainService.crateLoaded$;
		this.crate$.pipe(tap(() => this.isLoading = false)).subscribe(crate => {
			if (crate == null) return;
			this.crate = crate;
			this.itemGroups = this.groupByRestaurant(crate.items);
		});
	}

	@HostListener('document: click', ['$event', '$event.target'])
	public onClick(event: MouseEvent, targetElement: HTMLElement): void {

		if (!targetElement) {
			return;
		}
		let clickOutside = !(this.elementRef.nativeElement.contains(targetElement))

		if (this.clickIcon) {
			this.resetIcon.emit();
			return;
		}
		else if ((clickOutside) && !this.clickIcon && this.showCrate) {

			this.resetIcon.emit();
			this.closeCrate();
		}
	}

	closeCrate() {
		this.showCrateChange.emit(false);
	}

	clickCheckout() {
		localStorage.setItem('redirect_url', '/marché/checkout');
		this.router.navigate([this.translate.getActiveLang() + '/marché/checkout']);
	}

	groupByRestaurant(crateItems: BagItem[]): any[] {
		return crateItems.reduce((list: any[], item: BagItem) => {
			let index = list.findIndex(sortedItem => sortedItem.slug == item.product?.restaurant?.slug);
			(index != -1) ? list[index].items.push(item) : list.push({ slug: item.product.restaurant.slug, name: item.product.restaurant.name, items: [item] });
			return list;
		}, []);
	}

	disableCheckoutButton(): boolean {
        return this.crate == null || this.crate?.isEmpty || 
        this.crate?.subtotal.lessThan(this.configService.MINIMUM_MARKET_ORDER_AMOUNT); 
    }

	clickTrackOrder(): void {
        this.router.navigate([this.translate.getActiveLang() + '/marché/order']);
    }
}
