import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { MenuItemButtonType } from 'src/app/core/enums';
import { ConfigService } from 'src/app/core/services/config.service';
import { Bag, Product } from 'src/app/core/models';

@Component({
    selector: 'app-menu-item-base',
    template: '',
    styleUrls: ['./menu-item-base.component.css']
})
export class MenuItemBaseComponent implements OnInit {

    @Input() product: Product
    @Input() disableModifyProductCount: boolean = false
    @Input() tooltip: string = ''
    @Input() isItemInBag: boolean = false
    @Input() bag: Bag
    @Input() productIncrementOptionsToShow: string = null
    @Input() productDecrementOptionsToShow: string = null
    @Input() buttonColor: string
    @Input() disabledButtonColor: string
    @Input() buttonIconColor: string

    @Output() onRemoveItem: EventEmitter<Product> = new EventEmitter<Product>()
    @Output() onAddItem: EventEmitter<Product> = new EventEmitter<Product>()
    @Output() onNewItemWithOptions: EventEmitter<Product> = new EventEmitter<Product>()
    @Output() onItemOptionsClick: EventEmitter<boolean> = new EventEmitter<boolean>()
    @Output() onProductClick: EventEmitter<Product> = new EventEmitter<Product>();

    MenuItemButtonType = MenuItemButtonType

    constructor(public translate: TranslocoService,
        public configService: ConfigService) { }

    ngOnInit(): void {
    }

    removeItem(product: Product) {
        this.onRemoveItem.emit(product);
    }

    addItem(product: Product) {
        this.onAddItem.emit(product);
    }

    onNewItemWithOptionsClick(product: Product) {
        this.onNewItemWithOptions.emit(product);
    }

    itemOptionsClickHandler(wasClickInside: boolean) {
        this.onItemOptionsClick.emit(wasClickInside);
    }

    clickProduct(product: Product) {
        this.onProductClick.emit(product);
    }

}
