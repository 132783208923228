<app-navbar [navbarType]="'account'"></app-navbar>
<div class="account-container">
	<div class="account-inner-container">
		<div class="page-header">{{ 'account.accountSettings' | transloco }}</div>
		<div *ngIf="user" class="row">
			<div class="col-md">
				<form [formGroup]="profileForm" action="" class="account-form"
					(ngSubmit)="onProfileFormSubmit(profileForm.value)">
					<div class="form-section">
						<div class="form-section-title">
							{{ 'account.myProfile' | transloco }}
						</div>
						<div class="form-section-content">
							<div class="form-group form-sub-section">
								<label for="first-name" class="form-sub-title">{{
									'account.firstName' | transloco
								}}</label>
								<input formControlName="firstName" id="first-name" type="text" class="form-input" />
							</div>
							<div class="form-group form-sub-section">
								<label for="last-name" class="form-sub-title">{{
									'account.lastName' | transloco
								}}</label>
								<input formControlName="lastName" id="last-name" type="text" class="form-input" />
							</div>
							<div class="form-group form-sub-section">
								<label for="email" class="form-sub-title">{{
									'account.email' | transloco
								}}</label>
								<input formControlName="email" id="email" type="email" class="form-input" />
							</div>
							<div class="form-group form-sub-section">
								<label for="phone-number" class="form-sub-title">{{
									'account.phoneNumber' | transloco
								}}</label>
								<input formControlName="number" id="phone-number" type="text" class="form-input" />
							</div>
							<button class="form-submit-button" [disabled]="!isProfileFormValid()">
								{{ 'account.saveChanges' | transloco }}
							</button>
						</div>
					</div>
				</form>
				<form [formGroup]="passwordForm" action="" (ngSubmit)="onPasswordFormSubmit($event)">
					<div class="form-section">
						<div class="form-section-title">
							{{ 'account.changePassword' | transloco }}
						</div>
						<div class="form-section-content">
							<div class="form-group form-sub-section">
								<label for="current-password" class="form-sub-title">{{
									'account.currentPassword' | transloco
								}}</label>
								<input formControlName="oldPassword" id="current-password" type="password"
									class="form-input" placeholder="********" />
							</div>
							<div class="form-group form-sub-section">
								<label for="new-password" class="form-sub-title">{{
									'account.newPassword' | transloco
								}}</label>
								<input formControlName="newPassword" id="new-password" type="password"
									class="form-input" placeholder="********" />
							</div>
							<div class="form-group form-sub-section">
								<label for="confirm-new-password" class="form-sub-title">{{
									'account.confirmNewPassword' | transloco
								}}</label>
								<input formControlName="confirmNewPassword" id="confirm-new-password" type="password"
									class="form-input" placeholder="********" />
							</div>
							<button class="form-submit-button" [disabled]="!isPasswordFormValid()">
								{{ 'account.saveChanges' | transloco }}
							</button>
						</div>
					</div>
				</form>
			</div>
			<div class="col-md">
				<form action="">
					<div class="form-section">
						<div class="form-section-title">
							{{ 'account.orderNotifications' | transloco }}
						</div>
						<div class="form-section-content">
							<div class="form-group form-sub-section account-form-toggle-section">
								<label for="new-password"
									class="form-sub-title">{{ 'account.textMessageUpdates' | transloco }}*</label>
								<div class="form-toggle-sub-text">
									*{{ 'account.carrierChargesWarning' | transloco }}
								</div>
								<div class="account-form-toggle-container">
									<label class="form-toggle">
										<input type="checkbox" (change)="notificationCheckboxChange($event)" />
										<span class="form-toggle-slider"></span>
									</label>
								</div>
							</div>
						</div>
					</div>
				</form>
				<div class="form-section">
					<div class="form-section-title">
						{{ 'account.paymentOptions' | transloco }}
					</div>
					<div class="form-section-content">
						<div class="form-sub-title">
							{{ 'account.savedCards' | transloco }}
						</div>
						<div class="account-saved-card-container">
							<button class="account-form-new-card" (click)="newCreditCardClick()">
								+ {{ 'account.addNewCard' | transloco }}
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>