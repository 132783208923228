import { Component, ViewChild, Input, AfterViewInit } from '@angular/core';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { Address as GoogleAddress } from 'ngx-google-places-autocomplete/objects/address';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { Restaurant, Address } from '../../core/models';
import { distanceBetween, parseGoogleAddress } from '../../core/helpers/address.helper';
import { Options } from 'ngx-google-places-autocomplete/objects/options/options';

@Component({
    selector: 'address-modal',
    templateUrl: './address-modal.component.html',
    styleUrls: ['./address-modal.component.css'],
})
export class AddressModalComponent implements AfterViewInit {

    @Input() restaurant: Restaurant;
    @Input() parent: string;

    @ViewChild('placesRef') placesRef: GooglePlaceDirective;

    showMessage: boolean = false;
    addressWithinRange: boolean = true;
    address: Address = null;
    addressNotFound: boolean = false;
    missingLine1Error: boolean = false;
    unspecificAddressError: boolean = false;
    geolocationError: boolean = false;
    autocompleteOptions: Options;
    isLoading: boolean = false;

    constructor(public activeModal: NgbActiveModal) { 
        this.autocompleteOptions = new Options({ componentRestrictions: { country: 'ca' }, bounds: new google.maps.LatLngBounds(new google.maps.LatLng(45, -75), new google.maps.LatLng(46, -73)) });
    }

    ngAfterViewInit(): void {
        let input: HTMLInputElement = <HTMLInputElement>(document.getElementById('address-modal').querySelector('#address-modal-container').querySelector('#address-modal-input'));
        if (navigator.doNotTrack == null || input == null) return;
        this.isLoading = true;
        navigator.geolocation.getCurrentPosition(
            (location: GeolocationPosition) => {
                let geocoder = new google.maps.Geocoder();
                let coords = new google.maps.LatLng(
                    location.coords.latitude,
                    location.coords.longitude
                );
                geocoder.geocode({ location: coords }, (results) => {
                    if (results != null && results[0]) {
                        input.value = results[0].formatted_address;

                        // blur then focus on the input to trigger the Google Places autocomplete dropdown
                        input.blur();
                        input.focus();
                    }
                });
                this.isLoading = false;
            },
            _ => {
                this.geolocationError = true;
                this.showMessage = false;
                this.isLoading = false;
            }
        );
    }

    handleAddressChange(googleAddress: GoogleAddress) {
        let address = parseGoogleAddress(googleAddress);
        
        this.addressNotFound = address == null;
        this.missingLine1Error = !address.line1;
        this.unspecificAddressError = !address.postal || address.postal.length === 3;
        if (this.parent == 'market-checkout') {
            this.showMessage = !(this.missingLine1Error || this.unspecificAddressError || this.addressNotFound)
        }
        else {
            this.addressWithinRange = distanceBetween(address, this.restaurant.address) <= this.restaurant.delivery.radius;
            this.showMessage = !(this.missingLine1Error || this.unspecificAddressError || this.addressNotFound) && this.addressWithinRange;
        }
        if (!this.showMessage) return
        this.address = address;
    }

    updateAddress(address: Address) {
        let updatedAddress = this.address;
        updatedAddress.apt = address.apt;
        updatedAddress.line1 = address.line1;
        updatedAddress.city = address.city;
        updatedAddress.notes = address.notes;
        updatedAddress.postal = address.postal;
        return updatedAddress;
    }

    onConfirmAddress(address) {
        this.activeModal.close(this.updateAddress(address));
    }
}
