<div class="expo-modal-container modal-content">
    <div *ngIf="header" class="expo-modal-title" [innerHTML]="header | transloco : headerParams"></div>
    <div *ngIf="text" class="expo-modal-description" [innerHTML]="text | transloco : textParams"></div>
    <div class="expo-modal-footer">
        <button *ngIf="cancelButtonText" class="submit-button cancel-button" 
            (click)="activeModal.dismiss()" type="button">{{ cancelButtonText | transloco : cancelButtonTextParams }}</button>
        <button *ngIf="submitButtonText" class="submit-button" 
            (click)="activeModal.close()" type="button">{{ submitButtonText | transloco : submitButtonTextParams }}</button>
    </div>
</div>
