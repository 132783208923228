<div class="restaurant-menu-item" [ngClass]="restaurantCssClass" [class.item-unavailable]="!product.isAvailable">
    <ng-container *ngIf="!product.isArchived">
        <app-item-with-option-update
            *ngIf="product.options && product.options.length > 0 && productIncrementOptionsToShow == product.id"
            [product]="product" [bag]="bag" [type]="'increment'" class="bag-item"
            (onNewItemClick)="onNewItemWithOptionsClick($event)" (onClick)="itemOptionsClickHandler($event)">
        </app-item-with-option-update>
        <app-item-with-option-update
            *ngIf="product.options && product.options.length > 0 && productDecrementOptionsToShow == product.id"
            [product]="product" [bag]="bag" [type]="'decrement'" class="bag-item"
            (onNewItemClick)="onNewItemWithOptionsClick($event)" (onClick)="itemOptionsClickHandler($event)">
        </app-item-with-option-update>
        <div class="restaurant-menu-item-row">
            <!-- <div class="restaurant-menu-item-row" [ngbTooltip]="tipContent" tooltipClass="restaurant-menu-tooltip"> -->
            <!-- <ng-template #tipContent><img class="restaurant-menu-tooltip-image" src="../../../assets/images/freeze_cream.jpg" /></ng-template> -->
            <div class="restaurant-menu-item-name" [innerHTML]="product.title"></div>
            <div class="restaurant-menu-item-price-buttons-wrapper">
                <div class="restaurant-menu-item-price">
                    {{ product.price | price | translocoCurrency}}
                </div>
                <div class="restaurant-menu-item-buttons">
                    <app-menu-item-control-button [class.invisible]="!isItemInBag"
                        class="restaurant-menu-item-button restaurant-menu-item-button-minus"
                        [disabled]="disableModifyProductCount"
                        (onButtonClicked)="removeItem(product)"
                        [color]="buttonColor"
                        [iconColor]="buttonIconColor"
                        [disabledColor]="disabledButtonColor"
                        [type]="MenuItemButtonType.Minus"
                    ></app-menu-item-control-button>
                    <div [ngbTooltip]="tooltip" [disableTooltip]="!disableModifyProductCount"
                        tooltipClass="radish-tooltip">
                        <!-- <button class="restaurant-menu-item-button restaurant-menu-item-button-plus"
                            (click)="addItem(product)" [disabled]="disableModifyProductCount">
                            <img src="../../assets/images/icons/plus.svg">
                        </button> -->
                        <app-menu-item-control-button class="restaurant-menu-item-button"
                            [disabled]="disableModifyProductCount"
                            (onButtonClicked)="addItem(product)"
                            [color]="buttonColor"
                            [iconColor]="buttonIconColor"
                            [disabledColor]="disabledButtonColor"
                            [type]="MenuItemButtonType.Plus"
                        ></app-menu-item-control-button>
                    </div>
                </div>
            </div>
        </div>
        <div class="restaurant-menu-item-description"
            [innerHTML]="product.description ? product.description : ''"></div>
    </ng-container>
</div>
