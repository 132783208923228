import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';
import { TranslocoService } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter } from 'rxjs';
import { MainService } from 'src/app/core/services/main.service';

@UntilDestroy()
@Component({
    selector: 'expo-footer',
    templateUrl: './expo-footer.component.html',
    styleUrls: ['./expo-footer.component.css']
})
export class ExpoFooterComponent implements OnInit {

    selectedLanguage: string;

    showFooter: boolean = false;

    constructor(
        private mainService: MainService,
        private router: Router,
        public translate: TranslocoService
    ) {
        this.router.events.pipe(untilDestroyed(this), filter(event => event instanceof NavigationEnd)).subscribe(event => {
            let pageList = (event as RouterEvent).url.split('/');
            let restaurantPage = pageList.find(page => page === 'r');
            let lastPage = pageList.pop();
            
            this.showFooter = restaurantPage == null && lastPage != 'checkout';
		});
    }

    ngOnInit(): void {
        this.selectedLanguage = this.getSelectedLanguage(this.translate.getActiveLang());
    }

    useLanguage(language: string) {
        this.router.navigateByUrl(this.router.url.replace(this.translate.getActiveLang(), language));
        this.mainService.setLanguage(language);
        this.selectedLanguage = this.getSelectedLanguage(language);
    }

    getSelectedLanguage(language: string): string {
        switch (language) {
            case 'fr':
                return "Français";
            case 'en':
                return "English";
            default:
                return "Français";
        }
    }

}