import { Component, HostListener, Input, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { ConfigService } from 'src/app/core/services/config.service';
import { MenuItemBaseComponent } from '../menu-item-base/menu-item-base.component';

@Component({
    selector: 'app-menu-item-horizontal-image',
    templateUrl: './menu-item-horizontal-image.component.html',
    styleUrls: ['./menu-item-horizontal-image.component.css']
})
export class MenuItemHorizontalImageComponent extends MenuItemBaseComponent implements OnInit {

    @Input() productStyle;
    @Input() largeFormat;

    readonly MOBILE_THRESHOLD = 700;
    isMobile: boolean = false;

    constructor(public translate: TranslocoService, public configService: ConfigService) {
        super(translate, configService)
    }

    ngOnInit(): void {
        super.ngOnInit()
        this.isMobile = window.innerWidth < this.MOBILE_THRESHOLD
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.isMobile = window.innerWidth < this.MOBILE_THRESHOLD
    }

}
