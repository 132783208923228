<app-navbar [navbarType]="'marketCheckout'"></app-navbar>
<div class="market-order-followup-container">
    <div class="market-order-followup-left">
        <h1 class="market-order-followup-left-header">
            {{'followup.thankYou' | transloco }}
        </h1>
        <div class="market-order-followup-left-message">
            {{'followup.confirmationEmail' | transloco }}
        </div>
        <div class="market-order-followup-info market-order-followup-box">
            <div class="order-info">
                {{ 'followup.orderNumberIs' | transloco }}
                <b>{{ (order$ | async)?.number }}</b>
            </div>
            <div class="schedule-info">
                <!-- TODO: replace hardcoded time window once scheduling date & time works -->
                {{ 'followup.crateDelivery' | transloco }}<b> {{ (order$ | async)?.scheduledDate | translocoDate : {
                    dateStyle: 'full' } | capitalize }}, {{ translate.getActiveLang() == 'en' ? '11:00am - 6:00pm' :
                    '11:00 - 18:00'}}</b>
            </div>
        </div>
        <div class="market-order-followup-box market-order-followup-info">
            <div class="address"> {{'followup.crateAddress' | transloco }} <b>{{ (order$ | async)?.bag?.address |
                    address}}</b> </div>
            <div class="map-wrapper">
                <google-map [center]="{ lat: lat, lng: lng }" [zoom]="15" height="100%" width="100%"
                    [options]="options">
                    <map-marker [position]="{lat: lat, lng: lng}"></map-marker>
                    <map-circle [center]="{lat: lat, lng: lng}" [radius]="30"
                        [options]="{ visible: false, strokeWeight: 0 }"></map-circle>
                </google-map>
            </div>
        </div>
        <!--<div class="market-order-followup-crate-info market-order-followup-box" *ngIf="displayReturnCrate">
            <button (click)="toggleReturnCrate()" class="return-crate-header">
                {{ 'followup.returnCrate' | transloco }}
            </button>
            <div *ngIf="openReturnCrate" class="return-crate-content">
                <img class="return-crate-image" src="../../../assets/images/icons/return_crate.png"/>
                <div class="return-crate-message">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt 
                    ut labore et dolore magna aliqua.
                </div>
            </div>
        </div> 
        <button class="market-order-followup-help">
            {{ 'followup.support' | transloco }}
        </button> -->
    </div>
    <div class="overlay d-block d-lg-none" (click)="openBottomSheet = false" *ngIf="openBottomSheet"></div>
    <div class="market-order-followup-right bottom-sheet-container"
        [ngClass]="{'open-bottom-sheet-container' : openBottomSheet == true }"
        [@slideUpToggle]="openBottomSheet == true ? 'open' : 'closed'">
        <h3 class="market-order-followup-right-header d-none d-lg-block">
            {{ 'orderSummary.orderSummary' | transloco }}
        </h3>
        <div #bottomSheetContent class="bottom-sheet-button-wrapper d-flex d-lg-none">
            <button class="order-summary-button" (click)="toggleBottomSheet()">{{ 'orderSummary.orderSummary' |
                transloco }}</button>
            <div class="total-price"><b>{{ (order$ | async)?.total | price | translocoCurrency }}</b></div>
        </div>
        <div (scroll)="onScroll($event)" id="orderSummary" class="market-order-summary-container d-none d-lg-block">
            <div *ngIf="showOverflowPill && checkOverflow()" class="overflow-pill">
                <button (click)="scrollDown()" class="overflow-pill-button" type='button'>{{ 'checkout.scrollForMore' |
                    transloco }}</button>
                <div class="arrow-wrapper">
                    <img class="arrow" src="../../../assets/images/white-arrow.png" />
                </div>
            </div>
            <ng-container *ngIf="!(crate$ | async)?.isEmpty">
                <div *ngFor="let group of itemGroups" class="crate-items-wrapper">
                    <div class="crate-items-restaurant">{{ group.name }}</div>
                    <div *ngFor="let item of group.items" class="crate-item-wrapper">
                        <app-crate-item [item]="item"></app-crate-item>
                    </div>
                </div>
            </ng-container>
        </div>
        <div class="market-order-price-summary d-none d-lg-block">
            <div class="market-order-subtotal market-order-price-detail">
                <b>{{'checkout.subtotal' | transloco}}</b>
                <b>{{ (order$ | async)?.subtotal | price | translocoCurrency }} </b>
            </div>
            <ng-container *ngIf="(order$ | async)?.fees as fees">
                <div *ngFor="let fee of fees" class="market-order-price-detail">
                    <div class="fee-title">{{ fee.title }}</div>
                    <div class="fee-amount">
                        {{ fee.amount | price | translocoCurrency }}
                    </div>
                </div>
            </ng-container>
            <div class="market-order-price-detail">
                <b>{{ 'checkout.driverTip' | transloco }}</b>
                <b>{{ (order$ | async)?.tip | price | translocoCurrency }} </b>
            </div>
        </div>
        <div class="market-order-total-price market-order-price-detail d-none d-lg-flex">
            <b>{{'checkout.total' | transloco}}</b>
            <b>{{ (order$ | async)?.total | price | translocoCurrency }}</b>
        </div>
        <!-- ========PAYMENT INFO======= -->
        <div class="market-order-payment d-none d-lg-block">
            <div class="market-order-payment-title">{{ 'followup.paymentMethod' | transloco }}</div>
            <div class="payment-method" *ngFor="let balance of (order$ | async)?.payment.balances">
                <div class="card-wrapper">
                    <div class="card-image-container"
                        [ngClass]="{ 'gift-card-image-container' : balance.type == 'gift_card'}">
                        <img class="visa-card card-image" *ngIf="balance.type == 'card'"
                            [src]="getCardLogo(balance.brand)" />
                        <img class="gift-card card-image" *ngIf="balance.type == 'gift_card'"
                            [src]="(order$ | async)?.payment?.giftCard?.theme?.front?.url" />
                    </div>
                    <div class="payment-type">{{ 'payment.brands.' + (balance.brand ? balance.brand : balance.type) | transloco }} {{ balance.number }}</div>
                </div>
                <div class="payment-amount">{{ balance.value | price | translocoCurrency }}</div>
            </div>
        </div>
        <!--  -->
        <div class="bottom-sheet-content" *ngIf="openBottomSheet">
            <div (scroll)="onScroll($event)" id="orderSummaryMobile"
                class="market-order-summary-container d-block d-lg-none">
                <div *ngIf="showOverflowPill && checkOverflow()" class="overflow-pill">
                    <button (click)="scrollDown()" class="overflow-pill-button" type='button'>{{
                        'checkout.scrollForMore' | transloco }}</button>
                    <div class="arrow-wrapper">
                        <img class="arrow" src="../../../assets/images/white-arrow.png" />
                    </div>
                </div>
                <ng-container *ngIf="!(crate$ | async)?.isEmpty">
                    <div *ngFor="let group of itemGroups" class="crate-items-wrapper">
                        <div class="crate-items-restaurant">{{ group.name }}</div>
                        <div *ngFor="let item of group.items" class="crate-item-wrapper">
                            <app-crate-item [item]="item"></app-crate-item>
                        </div>
                    </div>
                </ng-container>
            </div>
            <div class="market-order-price-summary d-block d-lg-none">
                <div class="market-order-subtotal market-order-price-detail">
                    <b>{{ 'checkout.subtotal' | transloco }}</b>
                    <b>{{ (order$ | async)?.subtotal | price | translocoCurrency }} </b>
                </div>
                <ng-container *ngIf="(order$ | async)?.fees as fees">
                    <div *ngFor="let fee of fees" class="market-order-price-detail">
                        <div class="fee-title">{{ fee.title }}</div>
                        <div class="fee-amount">
                            {{ fee.amount | price | translocoCurrency }}
                        </div>
                    </div>
                </ng-container>
                <div class="market-order-price-detail">
                    <b>{{ 'checkout.driverTip' | transloco }}</b>
                    <b>{{ (order$ | async)?.tip | price | translocoCurrency }} </b>
                </div>
            </div>
            <div class="market-order-total-price market-order-price-detail">
                <b>{{'checkout.total' | transloco}}</b>
                <b>{{ (order$ | async)?.total | price | translocoCurrency }}</b>
            </div>
            <div class="market-order-payment d-block d-lg-none">
                <div class="market-order-payment-title">{{'followup.paymentMethod' | transloco}}</div>
                <div class="payment-method" *ngFor="let balance of (order$ | async)?.payment.balances">
                    <div class="card-wrapper">
                        <div class="card-image-container"
                            [ngClass]="{ 'gift-card-image-container' : balance.type == 'gift_card'}">
                            <img class="visa-card card-image" *ngIf="balance.type == 'card'"
                                [src]="getCardLogo(balance.brand)" />
                            <img class="gift-card card-image" *ngIf="balance.type == 'gift_card'"
                                [src]="(order$ | async)?.payment?.giftCard?.theme?.front?.url" />
                        </div>
                        <div class="payment-type">{{ balance.type }} {{ balance.last4 }}</div>
                    </div>
                    <div class="payment-amount">{{ balance.amount | price | translocoCurrency }}</div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-footer [footerType]="'white'"></app-footer>