import { Component, OnInit, Input, OnChanges, SimpleChanges, Inject, PLATFORM_ID, OnDestroy } from '@angular/core';
import { Restaurant, Menu, MenuSection, Review } from '../../core/models';
import { TranslocoService } from '@ngneat/transloco';
import { Router, NavigationEnd, RoutesRecognized, ActivatedRoute } from '@angular/router';
import { filter, first, pairwise } from 'rxjs/operators';
import { RestaurantService } from '../../core/services/restaurant.service';
import { Observable, Subscription } from 'rxjs';
import { RestaurantPageV2Service } from '../../core/services/data/restaurant-page-v2.service';
import { ConfigService } from '../../core/services/config.service';
import { isPlatformBrowser } from '@angular/common';
import { MainService } from '../../core/services/main.service';

@Component({
    selector: 'app-restaurant-v2',
    templateUrl: './restaurant-v2.component.html',
    styleUrls: ['./restaurant-v2.component.css']
})
export class RestaurantV2Component implements OnInit, OnChanges, OnDestroy {
    
    @Input() restaurant: Restaurant
    @Input() orderType: string

    private readonly subscription: Subscription = new Subscription();

    menu: Menu
    sectionSelected: MenuSection = null
    tab: string
    lastPathElement: string = ''
    reviews: Review[]

    featureImage: Observable<string>
    featureText: Observable<string>
    featureTitle: Observable<string>

    private isBrowser: boolean;

    constructor(
        @Inject(PLATFORM_ID) platformId: Object,
        public translate: TranslocoService,
        private router: Router,
        private route: ActivatedRoute,
        private restaurantService: RestaurantService,
        private restaurantV2Service: RestaurantPageV2Service,
        public configService: ConfigService,
        private mainService: MainService) {
            if (isPlatformBrowser(platformId)) this.isBrowser = true;
            this.subscription.add(
                this.router.events.pipe(
                    filter(event => event instanceof NavigationEnd)
                ).subscribe(_ => {
                    this.tab = this.router.url.split('/')[4]

                    if (this.isBrowser) {
                        if (!(this.lastPathElement == 'about' || this.lastPathElement == 'reviews' || this.lastPathElement == 'menu')) {
                            window.scrollTo(0, 0)
                        }
                    }

                    if (this.tab == 'about') {
                        this.restaurantV2Service.updateImage(this.restaurant?.backgroundImageUrl)
                    }

                    if (!this.tab) {
                        this.restaurantV2Service.updateImage(this.restaurant?.backgroundImageUrl)
                    }
                })
            )

            this.subscription.add(
                this.router.events
                    .pipe(filter((event: any) => event instanceof RoutesRecognized), pairwise())
                    .subscribe((events: RoutesRecognized[]) => {
                        let prevParts = events[0].urlAfterRedirects.split('/');
                        this.lastPathElement = prevParts[prevParts.length - 1];
                    })
            )
        }

    getReviews() {
        this.restaurantService.getReviews(this.restaurant.slug)
            .pipe(first())
            .subscribe(
                reviews => this.reviews = reviews
            )
    }

    ngOnInit(): void {
        this.featureImage = this.restaurantV2Service.image$
        this.featureText = this.restaurantV2Service.text$
        this.featureTitle = this.restaurantV2Service.featureTitle$
        this.restaurantV2Service.updateImage(this.restaurant?.backgroundImageUrl)
        this.menu = this.mainService.menuInView;

        var url = this.router.url
        var check = url.split('/')
        this.tab = check[4]

        this.getReviews()
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes?.orderType && !changes.orderType.firstChange && changes.orderType.previousValue !== changes.orderType.currentValue) {
            this.menu = this.mainService.menuInView;
        }
    }

    onClickSection(section: MenuSection) {
        this.sectionSelected = section
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}
