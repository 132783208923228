import {
	Component,
	OnInit,
	ViewChild,
	ElementRef,
	Renderer2,
} from '@angular/core';

@Component({
	selector: 'app-terms',
	templateUrl: './terms.component.html',
	styleUrls: ['./terms.component.css'],
})
export class TermsComponent implements OnInit {
	@ViewChild('sidebarWrapper') private el: ElementRef;
	navbarType = 'other';

	constructor(public element: ElementRef, public renderer: Renderer2) { }

	ngOnInit() {
	}

}
