import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { Observable } from 'rxjs';
import { OrderType } from 'src/app/core/enums';
import { Exposition, Order } from 'src/app/core/models';
import { MainService } from 'src/app/core/services/main.service';
import { OrderService } from 'src/app/core/services/order.service';
import confetti from 'canvas-confetti';
import { isPlatformBrowser } from '@angular/common';

@Component({
    selector: 'expo-followup',
    templateUrl: './followup.component.html',
    styleUrls: ['./followup.component.css']
})
export class ExpoFollowupComponent implements OnInit, OnDestroy {

    isBrowser: boolean;
    isLoading: boolean = true;
    exposition: Exposition;
    expoOrder$: Observable<Order>

    supportMessages: string[] = ['firstMessage', 'secondMessage', 'thirdMessage'];
    firstName: string;
    orderNumber: string;
    queueNumber: number;
    restaurantName: any;

    constructor(private mainService: MainService,
        private orderService: OrderService,
        private route: ActivatedRoute,
        private router: Router,
        private translate: TranslocoService,
        @Inject(PLATFORM_ID) platformId: Object
        ) { 
            if (isPlatformBrowser(platformId)) this.isBrowser = true;
        }
        

    ngOnInit(): void {
        // setTimeout(() => {
        //     if (this.isBrowser) confetti({
        //         particleCount: 475,
        //         spread: 70,
        //         ticks: 225,
        //         origin: { y: 0.4 },
        //         disableForReducedMotion: true
        //     });
        // }, 200);

        this.exposition = this.route.parent.snapshot.data['exposition'];
        this.expoOrder$ = this.orderService.fetchOrder(this.route.snapshot.params['id'], OrderType.Expo, true);
        //TODO: only fetch user if user is null
        this.expoOrder$.subscribe({
            next: order => {
                this.isLoading = false;
                this.mainService.expoOrder = order;
                this.mainService.expoBag = order.bag;
                this.restaurantName = order.bag.restaurant.name;
                this.firstName = order?.user?.name.first;
                this.orderNumber = order?.number.toString();
                this.queueNumber = order.rank;
                setTimeout(() => {
                    if (this.isBrowser) confetti({
                        particleCount: 475,
                        spread: 70,
                        ticks: 225,
                        origin: { y: 0.4 },
                        disableForReducedMotion: true
                    });
                }, 200);
            },
            error: (err) => {
                console.log(err)
                this.router.navigate([this.translate.getActiveLang + '/expo/' + this.exposition.slug])
            }
        });
    }

    ngOnDestroy(): void {
        this.mainService.expoOrder = null;
        this.mainService.expoBag = null; //TODO: Potentially refetch
    }

    routeToMainPage() {
        window.open(this.translate.getActiveLang + '/expo/' + this.exposition.slug, '_blank');
    }

	onClickPlaceAnotherOrder() {
        this.router.navigate([this.translate.getActiveLang + '/expo/' + this.exposition.slug])
        .then(() => {
            window.location.reload();
        });
	}

}
