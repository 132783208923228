import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { BaseService } from './base.service';
import { Product } from '../models';

@Injectable({
    providedIn: 'root',
})
export class ProductService {
    constructor(
        private baseService: BaseService
    ) { }

    private entity: string = 'products';

    queryForMarket(categories?: string[], merchants?: string[]): Observable<Product[]> {
        categories = categories?.filter(category => category.trim() != '');
        let query: any = {};
        if (categories?.length) query.categories = categories;
        if (merchants?.length) query.merchants = merchants;
        return this.baseService.list('market/products', query).pipe(map(res => res.products.map(product => new Product().deserialize(product))));
    }

    fetchForMarket(slug: string, restaurantSlug: string): Observable<Product> {
        return this.baseService.fetch('market/merchants/' + restaurantSlug + '/products', slug).pipe(map(res => new Product().deserialize(res.product)));
    }
}
