<app-navbar [navbarType]="navbarType"></app-navbar>
<header class="hero-container">
    <div class="hero-content">
        <div class="hero-cards-container">
            <div class="hero-cards">
                <ngb-carousel #carousel class="hero-carousel shadow-sm" [pauseOnHover]="pauseOnHover" [interval]="false">
                    <ng-template ngbSlide>
                        <div class="card" (click)="clickCard('cbc')"
                            [class.card-height-en]="translate.getActiveLang() === 'en'"
                            [class.card-height-fr]="translate.getActiveLang() === 'fr'"
                            angulartics2On="click"
                            angularticsAction="Goto CBC article" angularticsCategory="Launch Landing Page"
                            [angularticsProperties]="{event: 'Click', label: ('landing.cards.one.cta' | transloco)}">
                            <div class="card-body-wrapper">
                                <div class="card-body">
                                    <div>
                                        <h3 class="card-title">
                                            <div [innerHTML]="'landing.cards.one.title' | transloco"></div>
                                        </h3>
                                        <p class="card-description">
                                            {{'landing.cards.one.description' | transloco}}
                                        </p>
                                    </div>
                                    <div class="card-cta">
                                        {{'landing.cards.one.cta' | transloco}}
                                        <img src="../../assets/images/icons/white-arrow.svg">
                                    </div>
                                </div>
                            </div>
                            <div class="card-image">
                                <img src="../../assets/images/cbc-radish-small.jpg">
                            </div>
                        </div>
                    </ng-template>
                    <ng-template ngbSlide>
                        <div class="card" (click)="clickCard('maharani')"
                            [class.card-height-en]="translate.getActiveLang() === 'en'"
                            [class.card-height-fr]="translate.getActiveLang() === 'fr'"
                            angulartics2On="click"
                            angularticsAction="Goto Buffet Maharani" angularticsCategory="Launch Landing Page"
                            [angularticsProperties]="{event: 'Click', label: ('landing.cards.two.cta' | transloco)}">
                            <div class="card-body-wrapper">
                                <div class="card-body">
                                    <div>
                                        <h3 class="card-title">
                                            <div [innerHTML]="'landing.cards.two.title' | transloco"></div>
                                        </h3>
                                        <p class="card-description">
                                            {{'landing.cards.two.description' | transloco}}
                                        </p>
                                    </div>
                                    <div class="card-cta">
                                        {{'landing.cards.two.cta' | transloco}}
                                        <img src="../../assets/images/icons/white-arrow.svg">
                                    </div>
                                </div>
                            </div>
                            <div class="card-image">
                                <img src="../../assets/images/buffet-maharani-banner-small.jpg">
                            </div>
                        </div>
                    </ng-template>
                    <ng-template ngbSlide>
                        <div class="card" (click)="clickCard('unicorn')"
                            [class.card-height-en]="translate.getActiveLang() === 'en'"
                            [class.card-height-fr]="translate.getActiveLang() === 'fr'"
                            angulartics2On="click"
                            angularticsAction="Goto Unicorn Article" angularticsCategory="Launch Landing Page"
                            [angularticsProperties]="{event: 'Click', label: ('landing.cards.three.cta' | transloco)}">
                            <div class="card-body-wrapper">
                                <div class="card-body">
                                    <div>
                                        <h3 class="card-title">
                                            <div [innerHTML]="'landing.cards.three.title' | transloco"></div>
                                        </h3>
                                        <p class="card-description">
                                            {{'landing.cards.three.description' | transloco}}
                                        </p>
                                    </div>
                                    <div class="card-cta">
                                        {{'landing.cards.three.cta' | transloco}}
                                        <img src="../../assets/images/icons/white-arrow.svg">
                                    </div>
                                </div>
                            </div>
                            <div class="card-image">
                                <img src="../../assets/images/horse-unicorn-chess-small.jpg">
                            </div>
                        </div>
                    </ng-template>
                </ngb-carousel>
            </div>
        </div>
    </div>
    <div class="hero-attribution">
        {{ 'landing.photoAttribution.photoBy' | transloco }}
        <a href="https://unsplash.com/@brookelark?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText"
            target="_blank">Brooke Lark</a> {{ 'landing.photoAttribution.on' | transloco }}
        <a href="https://unsplash.com/@brookelark?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText"
            target="_blank">Unsplash</a>
    </div>
</header>
<section class="landing-section" id="about">
    <div class="landing-section-inner">
        <div class="landing-section-about-text">
            <h1 class="landing-section-header">
                {{ 'landing.about.title' | transloco }}
            </h1>
            <p class="landing-section-subtext">
                {{'landing.about.description' | transloco }}
            </p>
        </div>
    </div>
</section>
<section class="landing-section landing-section-restaurant-courrier" id="joinUs">
    <div class="landing-section-inner landing-section-inner-restaurant">
        <div class="landing-section-restaurant-text">
            <h1 class="landing-section-header">
                {{ 'landing.restaurant.title' | transloco }}
            </h1>
            <p class="landing-section-subtext">
                {{ 'landing.restaurant.description' | transloco }}
            </p>
            <button class="button-cta" type="button" angulartics2On="click"
                angularticsAction="Goto Restaurant Partner Page" angularticsCategory="Launch Landing Page"
                [angularticsProperties]="{event: 'Click', label: ('landing.restaurant.cta' | transloco)}"
                (click)="restaurantClick()">
                {{ 'landing.restaurant.cta' | transloco }}
            </button>
        </div>
    </div>
    <div class="landing-section-inner landing-section-inner-courrier">
        <div class="landing-section-courrier-text">
            <h1 class="landing-section-header">
                {{ 'landing.courrier.title' | transloco }}
            </h1>
            <p class="landing-section-subtext">
                {{ 'landing.courrier.description' | transloco }}
            </p>
            <button class="button-cta" type="button" angulartics2On="click" angularticsAction="Goto Join Our Team Page"
                angularticsCategory="Launch Landing Page"
                [angularticsProperties]="{event: 'Click', label: ('landing.courrier.cta' | transloco)}"
                (click)="teamClick()">
                {{ 'landing.courrier.cta' | transloco }}
            </button>
        </div>
    </div>
</section>
<section class="landing-section landing-section-subscribe">
    <div class="landing-section-inner">
        <div class="landing-section-subscribe-text">
            <h1 class="landing-section-header">
                {{ 'landing.subscribe.title' | transloco }}
            </h1>
            <p class="landing-section-subtext">
                {{ 'landing.subscribe.description' | transloco }}
            </p>
        </div>
        <div class="landing-section-subscribe-input">
            <div class="landing-section-subscribe-email">
                <form #form
                    action="https://radish.us8.list-manage.com/subscribe/post?u=65ce400c2425d4b950da13793&amp;id=f660418979"
                    method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form"
                    class="validate landing-section-subscribe-form" target="_blank" novalidate>
                    <div id="mc_embed_signup_scroll" class="form-group-email">
                        <input type="email" value="" name="EMAIL" class="required email" id="mce-EMAIL"
                            [placeholder]="'common.email' | transloco">
                        <div id="mce-responses" class="clear">
                            <div class="response" id="mce-error-response" style="display:none"></div>
                            <div class="response" id="mce-success-response" style="display:none"></div>
                        </div>
                        <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
                        <div style="position: absolute; left: -5000px;" aria-hidden="true"><input type="text"
                                name="b_65ce400c2425d4b950da13793_f660418979" tabindex="-1" value=""></div>
                        <div class="clear"><input type="submit" value="{{ 'landing.subscribe.submit' | transloco }}" name="subscribe"
                                id="mc-embedded-subscribe" class="button btn-subscribe" (click)="form.submit()"
                                angulartics2On="click" angularticsAction="Subscribe to Mailing List"
                                angularticsCategory="Launch Landing Page"
                                [angularticsProperties]="{event: 'Click', label: 'MailChimp'}"></div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</section>
<app-footer></app-footer>
