<div class="restaurant-about-photo-information-container" [ngClass]="configService.getCssClass(restaurant.slug)">
    <div class="restaurant-about-photo-container">
        <ngb-carousel #carousel [interval]="5000" [pauseOnHover]="pauseOnHover" (slide)="onSlide($event)" [showNavigationIndicators]="showNavigationIndicators">
            <ng-template ngbSlide *ngFor="let img of getRestaurantImages(); index as i">
                <!-- <div class="carousel-caption">
                    <h3>My slide {{i + 1}} title</h3>
                </div> -->
                <div class="img-wrapper">
                    <img [src]="img" alt="My image {{i + 1}} description">
                </div>
            </ng-template>
        </ngb-carousel>
    </div>
    <div class="restaurant-about-information-container">
        <h4 class="restaurant-about-information-tagline" [innerHtml]="getRestaurantItem('tagline')">
        </h4>
        <div class="restaurant-about-information-tags">
            <p *ngFor="let tag of getRestaurantItem('tags')" class="restaurant-about-information-tag">
                {{tag}} <span class="line"></span>
            </p>
        </div>
        <div class="restaurant-about-information-description" [innerHtml]="getRestaurantItem('description')">
        </div>
    </div>
</div>
