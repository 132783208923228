import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { tap, debounceTime, map } from 'rxjs/operators';

import { Bag, BagItem, User, Product, Address, Order } from '../models';

import { BaseService } from './base.service';
import { AuthService } from './auth.service';
import { TranslocoService } from '@ngneat/transloco';
import { OrderType } from '../enums';

export const KEY_EXPO_BAG: string = 'expo_bag_id';
export const KEY_GIFT_BOX: string = 'gift_box_id';

export interface IProductModification {
	instanceId?: string;
	productId: string;
	quantity?: number;
	notes?: string;
	options?: IProductOptionSelection[]
}

export interface IProductOptionSelection {
	productId: string;
	id: string;
}

const KEY_BAG: string = 'KEY_BAG';

@Injectable({
	providedIn: 'root',
})
export class BagService {

	private entity: string = 'bags';

	constructor(
		private baseService: BaseService,
		private authService: AuthService,
		private translate: TranslocoService
	) { }

	createBag(slug: string, menuId: string, address?: string, expositionId?: string): Observable<Bag> {
		let data: any = { slug, menuId };
		if (address) data.address = address;
		if (expositionId) data.expositionId = expositionId;

		return this.baseService.create(this.entity, data)
			.pipe(map((res) => new Bag().deserialize(res.bag)),
				tap((bag) => {
					if (!this.authService.loggedIn()) {
						if (expositionId == null) localStorage.setItem(KEY_BAG, bag.id);
						else localStorage.setItem(KEY_EXPO_BAG, bag.id);
					}
				})
			);
	}

	fetch(id: string): Observable<Bag> {
		return this.baseService.fetch(this.entity, id).pipe(map(res => new Bag().deserialize(res.bag)))
	}

	list(): Observable<Bag[]> {
		return this.baseService.list(this.entity).pipe(map(res => res?.bags?.map(bag => new Bag().deserialize(bag))));
	}

	getBag(): Observable<Bag> {
		if (this.authService.loggedIn()) {
			return this.baseService
				.list(this.entity)
				.pipe(
					debounceTime(250),
					map(bag => new Bag().deserialize(bag))
				);
		} else {
			let bagId = localStorage.getItem(KEY_BAG);
			if (bagId) {
				return this.baseService
					.fetch(this.entity, bagId)
					.pipe(
						debounceTime(250),
						map(bag => new Bag().deserialize(bag))
					);
			} else {
				return of(null);
			}
		}
	}

	assignBagToSelf(bag: Bag) {
		return this.baseService
			.create('user/' + this.entity, { bagId: bag.id })
			.pipe(
				tap((res) => {
					// localStorage.removeItem(KEY_BAG);
					// this.bagSubject.next(bag);
				})
			);
	}

	addItems(id: string, productModifications: IProductModification[]): Observable<any> {
		return this.baseService
			.update(this.entity + '/' + id, { productModifications })
			.pipe(map(res => new Bag().deserialize(res.bag)))
	}

	addItem(id: string = null, product: Product, quantity: number, notes: string, options: IProductOptionSelection[]): Observable<any> {
		let productModifications: IProductModification[] = [
			{
				productId: product.id,
				quantity,
				notes
			},
		];
		if (options) productModifications[0].options = options
		return this.baseService
			.update(this.entity + '/' + id, { productModifications })
			.pipe(map(res => new Bag().deserialize(res.bag)));
	}

	modify(id: string, productModifications: IProductModification[], type = OrderType.Delivery): Observable<Bag> {
		return this.baseService
			.update(this.entity + '/' + id, { productModifications })
			.pipe(map((res) => (new Bag().deserialize(res.bag))));
	}

	removeItem(id: string, productInstanceId: String): Observable<Bag> {
		return this.baseService
			.delete(this.entity + '/' + id + '/items/' + productInstanceId)
			.pipe(map((res) => (new Bag().deserialize(res.bag))));
	}

	assignAddressToBag(id: string, addressId: string): Observable<any> {
		return this.baseService.update(this.entity + '/' + id + '/address', { addressId });
	}

	addNoteToBag(id: string, notes: string): Observable<any> {
		return this.baseService.update(this.entity + '/' + id, { notes });
	}

	getGuestBagId(): string {
		return localStorage.getItem(KEY_BAG);
	}

	deleteBag(id: string) {
		return this.baseService.delete(this.entity + '/' + id);
	}

	deleteNonUserBag() {
		localStorage.removeItem(KEY_BAG);
	}

	deleteNonUserExpoBag() {
		localStorage.removeItem(KEY_EXPO_BAG);
	}

	deleteNonUserGiftBox() {
		localStorage.removeItem(KEY_GIFT_BOX);
	}
}
