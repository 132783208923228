import { Deserializable } from './deserializable.model';
import { TranslatedText, TranslatedTextType } from './translated-text.model';

export class Measurement implements Deserializable<Measurement> {

    _id: string;
    _title: TranslatedText;
    type: MeasurementType;
    slug?: string;
    conversions?: {
        target: Measurement;
        rate: number;
    }[];
    symbol: String; 


    deserialize(input: any): this {
        if (!input) return null;
        if (typeof input == 'string') this.id = input;
        else {
            Object.assign(this, input);
            this.title = new TranslatedText().deserialize(input.title);
            this.conversions = input?.conversions?.map(conversion => {
                conversion.target = new Measurement().deserialize(conversion.target);
                return conversion;
            });
        }
        return this;
    }

    get id(): string {
        return this._id;
    }

    set id(id: string) {
        this._id = id;
    }

    get title(): TranslatedTextType {
        return this._title?.value;
    }

    set title(title: TranslatedTextType) {
        this._title = title as TranslatedText;
    }
}

export enum MeasurementType {
    Count = 'count',
    Weight = 'weight',
    Volume = 'volume',
    Distance = 'distance'
}
