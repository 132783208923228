<div class="restaurant-reviews-create-container">
    <img class="restaurant-reviews-close" src="../../../assets/images/close-icon.png" alt=""
        (click)="activeModal.close()" />
    <div *ngIf="currentStep == 'write'" class="restaurant-reviews-create-step-container">
        <div class="restaurant-reviews-create-header">{{ 'restaurant.reviews.writeAReview' | transloco }}</div>
        <div *ngIf="restaurant" class="restaurant-reviews-create-restaurant-name">
            {{ restaurant.name }}</div>
        <form action="" [formGroup]="createReviewForm" (ngSubmit)="onSubmit(createReviewForm.value)">
            <div class="form-group">
                <div class="restaurant-reviews-create-label">{{ 'restaurant.reviews.yourRating' | transloco }} <span
                        class="asterisk">*</span></div>
                <div class="restaurant-reviews-create-rating-stars-container">
                    <img [src]="getStar(1)" alt="rating star" (click)="setRating(1)" (mouseenter)="mouseEnterStar(1)"
                        (mouseleave)="mouseLeaveStar()" class="restaurant-reviews-create-star" />
                    <img [src]="getStar(2)" alt="rating star" (click)="setRating(2)" (mouseenter)="mouseEnterStar(2)"
                        (mouseleave)="mouseLeaveStar()" class="restaurant-reviews-create-star" />
                    <img [src]="getStar(3)" alt="rating star" (click)="setRating(3)" (mouseenter)="mouseEnterStar(3)"
                        (mouseleave)="mouseLeaveStar()" class="restaurant-reviews-create-star" />
                    <img [src]="getStar(4)" alt="rating star" (click)="setRating(4)" (mouseenter)="mouseEnterStar(4)"
                        (mouseleave)="mouseLeaveStar()" class="restaurant-reviews-create-star" />
                    <img [src]="getStar(5)" alt="rating star" (click)="setRating(5)" (mouseenter)="mouseEnterStar(5)"
                        (mouseleave)="mouseLeaveStar()" class="restaurant-reviews-create-star" />
                </div>
            </div>
            <div class="form-group restaurant-reviews-create-form-group">
                <label class="restaurant-reviews-create-label" for="review-title">{{ 'restaurant.reviews.titleOfReview'
                    | transloco }}</label>
                <input class="restaurant-reviews-create-input-box" formControlName="reviewTitle" type="text"
                    placeholder="{{ 'restaurant.reviews.titleOfReviewPlaceholder' | transloco }}" />
            </div>
            <div class="form-group">
                <label class="restaurant-reviews-create-label" for="review-details">{{ 'restaurant.reviews.yourReview' |
                    transloco }} <span class="asterisk">*</span></label>
                <textarea class="restaurant-reviews-create-input-box" formControlName="reviewText" rows="3" type="text"
                    placeholder="{{ 'restaurant.reviews.yourReviewPlaceholder' | transloco }}" required></textarea>
            </div>
            <div class="form-group">
                <label class="restaurant-reviews-create-label" for="review-photo">{{
                    'restaurant.reviews.sharePhotosTitle' | transloco }}
                    ({{ 'common.optional' | transloco }})</label>
                <div class="restaurant-reviews-create-upload-photos-container">
                    <button type="button" class="restaurant-reviews-create-photo-button"
                        (click)="clickUploadPhotoButton()">
                        <img src="../../../../assets/images/icons/camera.svg" alt="camera icon"
                            class="restaurant-reviews-create-photo-button-icon">
                        {{ 'common.upload' | transloco }}
                    </button>
                    <div *ngFor="let image of images; let i = index"
                        class="restaurant-reviews-create-uploaded-photo-item">
                        <button class="restaurant-reviews-create-uploaded-photo-item-delete-button button"
                            (click)="deletePhoto(i)">
                            <img class="delete-icon" src="../../../../assets/images/icons/empty-white.svg">
                        </button>
                        <img class="restaurant-reviews-create-uploaded-photo-item-image" [src]="image.source"
                            [alt]="image.description">
                        <div class="restaurant-reviews-create-uploaded-photo-item-text">{{image.description}}</div>
                    </div>
                </div>
            </div>
            <button class="restaurant-reviews-create-submit-button" type="submit"
                [disabled]="!createReviewForm.valid || rating === 0">
                <div *ngIf="loading" class="spinner-border spinner-border-sm" role="status">
                    <span class="sr-only">{{ 'common.loading' | transloco }}</span>
                </div>
                <span *ngIf="!loading">
                    {{ 'restaurant.reviews.submitReview' | transloco }}
                </span>
            </button>
            <div *ngIf="error" class="create-review-error">
                {{ 'errors.' + [error] | transloco }}
            </div>
        </form>
    </div>
    <div *ngIf="currentStep == 'upload'" class="restaurant-reviews-step-container">
        <div class="restaurant-reviews-create-header-small">
            {{ 'restaurant.reviews.attachPhotos' | transloco }}
            <span *ngIf="restaurant">{{restaurant.name}}</span>
        </div>
        <div class="restaurant-reviews-create-note">
            {{ 'restaurant.reviews.uploadMax' | transloco }}
        </div>
        <div *ngFor="let image of images; let i = index" class="restaurant-reviews-image-row">
            <button class="restaurant-reviews-image-description-delete-button button" (click)="deletePhoto(i)">
                <img class="delete-icon" src="../../../../assets/images/icons/empty.svg">
            </button>
            <!-- <div *ngFor="let imageSource of imageSources" class="restaurant-reviews-image-row"> -->
            <div class="retaurant-reviews-image-wrapper">
                <img class="restaurant-reviews-image" [src]="image.source" [alt]="image.description"
                    (load)="imageLoaded()">
            </div>
            <div class="restaurant-reviews-image-description-wrapper">
                <div class="restaurant-reviews-image-description-header">
                    {{ 'restaurant.reviews.description' | transloco }} ({{ 'common.optional' | transloco }}):</div>
                <textarea class="restaurant-reviews-image-description-textarea" [value]="image.description"
                    (change)="descriptionChanged(i, $event.target.value)" name="description"></textarea>
            </div>
        </div>
        <ng-container *ngIf="numImagesLoading > 0">
            <div *ngFor="let x of [].constructor(numImagesLoading)" class="restaurant-reviews-dummy-image-row">
                <div class="spinner-border" role="status">
                    <span class="sr-only">{{ 'common.loading' | transloco }}</span>
                </div>
            </div>
        </ng-container>
        <div *ngIf="images.length >= maxNumReviewPhotos" class="restaurant-reviews-upload-max">
            {{ 'restaurant.reviews.reachedLimit' | transloco }}
        </div>
        <ngx-file-drop *ngIf="images.length < maxNumReviewPhotos" [showBrowseBtn]="true"
            browseBtnLabel="{{ 'restaurant.reviews.selectPhotos' | transloco }}"
            dropZoneLabel="{{ 'restaurant.reviews.dragAndDrop' | transloco }}" (onFileDrop)="dropped($event)"
            dropZoneClassName="restaurant-reviews-image-dropzone"
            browseBtnClassName="resturant-reviews-image-browse-button"
            contentClassName="restaurant-reviews-image-content" [multiple]="false">
        </ngx-file-drop>
        <div class="restaurant-reviews-double-button-container">
            <button type="button" class="form-secondary-button restaurant-reviews-photo-upload-button"
                (click)="cancelClicked()">{{ 'common.cancel' | transloco }}</button>
            <button type="button" class="form-submit-button restaurant-reviews-photo-upload-button"
                (click)="uploadClicked()">{{ 'common.upload' | transloco }}</button>
        </div>
    </div>
</div>