import { User } from '../user.model';
import { Restaurant } from '../restaurant.model';

export abstract class Purchase {
    abstract _id: string;
    abstract type: PurchaseType;
    abstract purchaser?: {
        user?: User,
        restaurant?: Restaurant
    };
    abstract payment: {
        status?: string;
        intent?: String;
    };
    abstract updatedAt: string;
    abstract createdAt: string;
}

export enum PurchaseType {
    GiftCard = 'GiftCardPurchase',
}