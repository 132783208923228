<app-navbar [navbarType]="'market'"></app-navbar>
<div class="market-restaurant-container">
    <div class="market-header d-none d-sm-flex">
        <a routerLink="/marché">
            <h1 class="header-title">Marché Radish</h1>
        </a>
        <a class="restaurant-listing-routing-button d-none d-md-flex"
            [routerLink]="['/' + translate.getActiveLang() + '/restaurants/']">
            <div class="restaurant-listing-routing-icon-wrapper">
                <img class="restaurant-listing-routing-icon" src="../../assets/images/market/package.png" />
            </div>
            <div class="restaurant-listing-routing-message"> {{ 'routing.toRestaurant' | transloco }}</div>
        </a>
    </div>
    <div class="market-category-nav d-none d-sm-flex">
        <div class="market-category-container">
            <div class="scroll-space d-inline-block d-md-inline-block d-lg-none"></div>
            <button type="button" class="market-category-button-wrapper" *ngFor="let category of categories"
                [routerLink]="['/', translate.getActiveLang(), 'marché', category.route]">
                <div class="market-nav-icon-wrapper">
                    <img class="market-nav-icon" src="{{ category.icon }}" />
                </div>
                <p class="option-title"> {{ category.title?.[translate.getActiveLang()] }} </p>
            </button>
            <div class="scroll-space d-inline-block d-lg-none"></div>
        </div>
    </div>
    <div class="restaurant-container">
        <div class="restaurant-header">
            <div class="restaurant-carousel-container">
                <ngb-carousel [interval]="0" #carousel [showNavigationIndicators]="showNavigationIndicators"
                    [showNavigationArrows]="showNavigationArrows">
                    <ng-template ngbSlide>
                        <div class="carousel-caption">
                            <h3></h3>
                        </div>
                        <div class="picsum-img-wrapper">
                                <img [src]="getRestaurantImage((restaurant$ | async).slug)" alt="">                
                        </div>
                    </ng-template>
                    <!-- <ng-template ngbSlide *ngFor="let item of carouselItems; index as i">
                        <div class="carousel-caption">
                            <h3>{{ item.title | transloco }}</h3>
                        </div>
                        <a href="" target="_blank" rel="nofollow noopener noreferrer">
                        <div class="picsum-img-wrapper">
                            <ng-container *ngIf="item.type == 'image'">
                                <img [src]="item.url" alt="">
                            </ng-container>
                            <ng-container *ngIf="item.type == 'video'">
                                <video [src]="item.url" controls loop height="400"></video>
                            </ng-container>
                        </div>
                        </a>
                    </ng-template> -->
                </ngb-carousel>
            </div>
            <div class="restaurant-detail-container">
                <div class="restaurant-title-wrapper">
                    <div class="restaurant-title-wrapper-left">
                        <div class="restaurant-name"> {{ (restaurant$ | async).name }} </div>
                        <div *ngIf="(restaurant$ | async).info?.founded?.date" class="circa">Circa {{ (restaurant$ | async).info?.founded?.date | date:'yyyy' }}</div>
                    </div>
                    <div *ngIf="(restaurant$ | async).assets?.logo?.primary?.url" class="restaurant-title-wrapper-right restaurant-logo-container">
                        <img [src]="(restaurant$ | async).assets?.logo?.primary?.url" class="restaurant-logo">
                    </div>
                </div>
                <div class="restaurant-info-container">
                    <div *ngFor="let category of filterCategories((restaurant$ | async).categories, ['cuisine', 'service'])"
                        class="restaurants-item-tag restaurant-cuisine-label restaurant-info">
                        {{ category.title }}
                    </div>
                    <div class="restaurant-location restaurant-info">
                        <img class="restaurant-info-container-icon restaurant-location-icon"
                            src="../../../assets/images/icons/pin.svg">
                        <div class="location-container-address-text">
                            {{ (restaurant$ | async).address.region.title }}
                        </div>
                    </div>
                </div>
                <div class="restaurant-brief">
                    {{ (restaurant$ | async).brief }}
                </div>
            </div>
        </div>
        <div class="restaurant-products-container">
            <div *ngIf="resolvingSections; else sectionsLoaded">
                <div class="loading-row-wrapper" *ngFor="let list of [].constructor(3)">
                    <div class="loading-list-header loading-gradient"></div>
                    <div class="loading-items-wrapper">
                        <div *ngFor="let product of [].constructor(15)" class="loading-item-wrapper">
                            <div class="loading-item-image loading-gradient"></div>
                            <div class="loading-item-header loading-gradient"></div>
                            <!-- <div class="loading-item-header loading-gradient"></div> -->
                        </div>
                    </div>
                </div>
            </div>
            <ng-template #sectionsLoaded>
                <ng-container *ngFor="let section of productSections; index as i">
                    <div class="restaurant-subcategory">
                        <h2 class="restaurant-subcategory-title" [ngClass]="{'first-section-title': i == 0}"
                            id="{{ '' + i }}"> {{ section.title[translate.getActiveLang()] }} </h2>
                        <div class="restaurant-subcategory-row-wrapper">
                            <app-products-market-row [products]="section?.products" [showRestaurantName]="showRestaurantName"></app-products-market-row>
                        </div>
                    </div>
                </ng-container>
            </ng-template>
        </div>
    </div>
</div>
<app-footer [footerType]="'white'"></app-footer>