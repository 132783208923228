import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { MainComponent } from './main/main.component';
import { AccountComponent } from './account/account.component';
import { LoginComponent } from './login/login.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { RegisterComponent } from './register/register.component';
import { VerificationComponent } from './verification/verification.component';
import { CheckoutComponent } from './online-ordering/checkout/checkout.component';
import { OrderFollowupComponent } from './online-ordering/order-followup/order-followup.component';
import { OrdersComponent } from './orders/orders.component';
import { DateTimePickerComponent } from './party/date-time-picker/date-time-picker.component';
import { LandingComponent } from './landing/landing.component';
import { LandingLaunchComponent } from './landing-launch/landing-launch.component';
import { SendPromotionsComponent } from './promotions/send-promotions/send-promotions.component';
import { PromotionsFollowupComponent } from './promotions/promotions-followup/promotions-followup.component';
import { PromotionsComponent } from './promotions/promotions.component';
import { QrPromotionModalComponent } from './modals/qr-promotion-modal/qr-promotion-modal.component';
import { InvitationComponent } from './party/invitation/invitation.component';
import { PartySetupComponent } from './party/party-setup/party-setup.component';
import { BlogComponent } from './blog/blog.component';
import { TermsComponent } from './terms/terms.component';
import { PrivacyComponent } from './privacy/privacy.component';

// Restaurants
import { RestaurantListingComponent } from './online-ordering/restaurant-listing/restaurant-listing.component';
import { RestaurantComponent } from './online-ordering/restaurant/restaurant.component';
import { RestaurantAboutComponent } from './online-ordering/restaurant/restaurant-about/restaurant-about.component';
import { RestaurantMenuComponent } from './online-ordering/restaurant/restaurant-menu/restaurant-menu.component';
import { RestaurantReviewsComponent } from './online-ordering/restaurant/restaurant-reviews/restaurant-reviews.component';

// Marche
import { MarketComponent } from './market/market.component';
import { MarketProductComponent } from './market/market-product/market-product.component';
import { MarketRestaurantComponent } from './market/market-restaurant/market-restaurant.component';
import { MarketCheckoutComponent } from './market/checkout/checkout.component';
import { MarketOrderFollowupComponent } from './market/followup/followup.component'

// Expo
import { ExpoMainComponent } from './expo-ordering/main/main.component';
import { ExpoRestaurantListingComponent } from './expo-ordering/main/restaurant-listing/restaurant-listing.component';
import { ExpoRestaurantComponent } from './expo-ordering/main/restaurant/restaurant.component';
import { ExpoProductComponent } from './expo-ordering/main/restaurant/product/product.component';
import { ExpoCheckoutComponent } from './expo-ordering/main/checkout/checkout.component';
import { ExpoFollowupComponent } from './expo-ordering/main/followup/followup.component'

// Gift Cards
import { GiftCardRedeemComponent } from './gift-cards/redeem/redeem.component';
import { GiftCardCheckoutComponent } from './gift-cards/checkout/checkout.component';
import { GiftCardPurchaseComponent } from './gift-cards/purchase/purchase.component';

// Restaurant V2
import { AboutComponent } from './online-ordering/restaurant-v2/about/about.component';
import { MenuSectionsComponent } from './online-ordering/restaurant-v2/menu-sections/menu-sections.component';
import { StoryComponent } from './online-ordering/restaurant-v2/story/story.component';

// Services
import { RestaurantResolverService } from './core/resolvers/restaurant-resolver.service';
import { MarketProductResolverService } from './core/resolvers/market-product-resolver.service';
import { ReviewsResolverService } from './core/resolvers/reviews-resolver.service';
import { ExpositionResolverService } from './core/resolvers/exposition-resolver.service';

// Guards 
import { LanguageGuard } from './core/guards/language.guard';
import { AuthGuard } from './core/guards/auth.guard';
import { LoggedInGuard } from './core/guards/logged-in.guard';
import { CratePickupScheduler } from './market/crate-pickup-scheduler/crate-pickup-scheduler.component';
import { ExtendedOrderType } from './core/enums';
import { MarketCategoryService } from './core/services/data/market-category.service';
import { GiftBoxMainComponent } from './gift-box/main/main.component';
import { GiftBoxCheckoutComponent } from './gift-box/main/checkout/checkout.component';
import { GiftBoxComboListingComponent } from './gift-box/main/combo-listing/combo-listing.component';
import { GiftBoxFollowupComponent } from './gift-box/main/followup/followup.component';
import { OrderSummaryState } from './gift-box/main/order-summary/order-summary.component';
import { AffiliateRoutingComponent } from './affiliates/affiliates.component';

const affiliateRoutes = [
    {
        path: 'a/:aidOrSlug',
        component: AffiliateRoutingComponent
    },
    {
        path: 'gigi.in.mtl',
        redirectTo: 'a/gigi.in.mtl'
    },
    {
        path: 'mansib',
        redirectTo: 'a/mansib'
    },
    {
        path: 'chandni_in_mtl',
        redirectTo: 'a/chandni_in_mtl'
    },
    {
        path: 'yellowcouple.eatsmtl',
        redirectTo: 'a/yellowcouple.eatsmtl'
    },
    {
        path: 'tramieskitchen.mtl',
        redirectTo: 'a/tramieskitchen.mtl'
    },
    {
        path: 'marieaimelabouffe',
        redirectTo: 'a/marieaimelabouffe'
    },
    {
        path: 'lecuisinomane',
        redirectTo: 'a/lecuisinomane'
    },
    {
        path: 'fulltimefoooodie',
        redirectTo: 'a/fulltimefoooodie'
    },
    {
        path: 'tinyfoodie',
        redirectTo: 'a/tinyfoodie'
    },
    
]

const giftBoxRoutes = [
    {
        path: 'gifts',
        redirectTo: 'language/cadeaux'
    },
    {
        path: 'gift-boxes',
        redirectTo: 'language/cadeaux'
    },
    {
        path: ':language/gift-boxes',
        redirectTo: ':language/cadeaux'
    },
    {
        path: 'cadeaux',
        redirectTo: 'language/cadeaux'
    },
    {
        path: ':language/cadeaux',
        canActivate: [LanguageGuard],
        component: GiftBoxMainComponent,
        children: [
            {
                path: '',
                component: GiftBoxComboListingComponent,
                data: {
                    state: OrderSummaryState.Browsing
                }
            },
            {
                path: 'checkout',
                component: GiftBoxCheckoutComponent,
                data: {
                    state: OrderSummaryState.Checkout
                }
            },
            {
                path: 'orders/:id',
                component: GiftBoxFollowupComponent,
                data: {
                    state: OrderSummaryState.Completed
                }
            },
            {
                path: '**',
                redirectTo: ''
            }
        ],
    }
]

const expoRoutes = [
    {
        path: 'pocha',
        redirectTo: 'language/expo/pocha'
    },
    {
        path: 'expo/:slug',
        redirectTo: 'language/expo/:slug'
    },
    {
        path: ':language/expo/:slug',
        canActivate: [LanguageGuard],
        component: ExpoMainComponent,
        children: [
            {
                path: '',
                component: ExpoRestaurantListingComponent
            },
            {
                path: 'r/:slug',
                children: [
                    {
                        path: '',
                        component: ExpoRestaurantComponent
                    },
                    {
                        path: 'products/:slug',
                        component: ExpoProductComponent
                    },
                    {
                        path: '**',
                        redirectTo: ''
                    }
                ]
            },
            {
                path: 'checkout',
                component: ExpoCheckoutComponent
            },
            {
                path: 'order/:id',
                component: ExpoFollowupComponent
            },
            {
                path: '**',
                redirectTo: ''
            }
        ],
        resolve: {
            exposition: ExpositionResolverService
        }
    },
    // {
    //     path: 'expo/:slug/r/:restaurantSlug',
    //     redirectTo: 'language/expo/:slug/r/:restaurantSlug'
    // },
    // {
    //     path: ':language/expo/:slug/r/:restaurantSlug',
    //     canActivate: [LanguageGuard],
    // },
]

var marketCategoryRoutes: Routes = [];

marketCategoryRoutes = MarketCategoryService.compileMarketRoutes().map((route) => {
    if (route.redirectTo == null) {
        route.canActivate = [LanguageGuard];
        route.component = MarketComponent;
        return route;
    }
    else return route;
});

// let marketMainComponentIndex = routes.findIndex((route) => route.path == ':language/marché');
// routes.splice(marketMainComponentIndex - 1, 0, ...marketCategoryRoutes);

let marketHeaderRoutes = [
    {
        path: 'marche/latest',
        redirectTo: 'language/marché/latest'
    },
    {
        path: 'marché/latest',
        redirectTo: 'language/marché/latest'
    },
    {
        path: ':language/marché/latest',
        canActivate: [LanguageGuard],
        component: MarketComponent,
        data: {
            content: 'latest'
        }
    },
    {
        path: 'marche/products',
        redirectTo: 'language/marché/products'
    },
    {
        path: 'marché/products',
        redirectTo: 'language/marché/products'
    },
    {
        path: ':language/marché/products',
        canActivate: [LanguageGuard],
        component: MarketComponent,
        data: {
            category: ''
        }
    },
    ...marketCategoryRoutes,
    {
        path: 'marche/categories/:category',
        redirectTo: 'language/marché/categories/:category'
    },
    {
        path: 'marché/categories/:category',
        redirectTo: 'language/marché/categories/:category'
    },
    {
        path: ':language/marché/categories/:category',
        canActivate: [LanguageGuard],
        component: MarketComponent,
        data: {
            type: 'category'
        }
    },
]


const routes: Routes = [
    ...affiliateRoutes,
    ...giftBoxRoutes,
    {
        path: ':language/parties',
        canActivate: [LanguageGuard],
        component: InvitationComponent
    },
    {
        path: ':language/partysetup',
        canActivate: [LanguageGuard],
        component: PartySetupComponent
    },
    {
        path: ':language/datetime',
        canActivate: [LanguageGuard],
        component: DateTimePickerComponent
    },

    ...expoRoutes,
    {
        path: 'startupfest/lemonade',
        redirectTo: 'language/startupfest/lemonade'
    },
    {
        path: ':language/startupfest/lemonade',
        canActivate: [LanguageGuard],
        component: PromotionsComponent
    },
    // {
    //     path: ':language/promotions/send',
    //     canActivate: [LanguageGuard],
    //     component: SendPromotionsComponent
    // },
    {
        path: 'promotions/order',
        redirectTo: 'language/promotions/order'
    },
    {
        path: ':language/promotions/order',
        canActivate: [LanguageGuard],
        component: PromotionsFollowupComponent
    },
    {
        path: 'main',
        redirectTo: 'language/main'
    },
    {
        path: ':language/main',
        canActivate: [LanguageGuard],
        component: MainComponent,
        pathMatch: 'full'
    },
    {
        path: 'marche',
        redirectTo: 'language/marché'
    },
    {
        path: 'marché',
        redirectTo: 'language/marché'
    },
    {
        path: 'market',
        redirectTo: 'language/marché'
    },
    {
        path: ':language/marché',
        canActivate: [LanguageGuard],
        component: MarketComponent,
        data: {
            content: 'featured-marché'
        }
    },
    {
        path: ':language/marché/qrmodal',
        component: QrPromotionModalComponent
    },
    {
        path: 'marché/schedule-crate-pickup',
        redirectTo: 'language/marché/schedule-crate-pickup'
    },
    {
        path: ':language/marché/schedule-crate-pickup',
        canActivate: [LanguageGuard],
        component: CratePickupScheduler
    },

    ...marketHeaderRoutes,
    {
        path: 'marché/merchants/:merchantSlug/products/:slug',
        redirectTo: 'language/marché/merchants/:merchantSlug/products/:slug'
    },
    {
        path: ':language/marché/merchants/:merchantSlug/products/:slug',
        canActivate: [LanguageGuard],
        component: MarketProductComponent,
        resolve: {
            product: MarketProductResolverService
        }
    },
    {
        path: 'marché/merchant/:slug',
        redirectTo: 'language/marché/merchants/:slug'
    },
    {
        path: ':language/marché/merchants/:slug',
        canActivate: [LanguageGuard],
        component: MarketRestaurantComponent,
        resolve: {
            restaurant: RestaurantResolverService
        },
        data: { trendType: ExtendedOrderType.Market }
    },
    {
        path: 'marché/checkout',
        redirectTo: 'language/marché/checkout'
    },
    {
        path: ':language/marché/checkout',
        canActivate: [AuthGuard, LanguageGuard],
        component: MarketCheckoutComponent
    },
    {
        path: 'marché/order',
        redirectTo: 'language/marché/order'
    },
    {
        path: ':language/marché/order',
        canActivate: [AuthGuard, LanguageGuard],
        component: MarketOrderFollowupComponent
    },
    {
        path: 'about',
        redirectTo: 'language/about'
    },
    {
        path: ':language/about',
        canActivate: [LanguageGuard],
        component: LandingLaunchComponent,
        pathMatch: 'full'
    },
    {
        path: 'blog',
        redirectTo: 'language/blog'
    },
    {
        path: ':language/blog',
        canActivate: [LanguageGuard],
        component: BlogComponent,
        pathMatch: 'full',
        data: {
            title: {
                en: "Radish Blog",
                fr: "Blogue Radish"
            },
            ogTitle: {
                en: "To Outrun a Unicorn: Or Why We’re Taking on Uber.",
                fr: "Surpasser une Licorne: Ou Pourquoi nous prenons d'assaut Uber."
            }
        }
    },
    {
        path: 'login',
        redirectTo: 'language/login'
    },
    {
        path: ':language/login',
        canActivate: [LoggedInGuard, LanguageGuard],
        component: LoginComponent,
        data: {
            title: {
                en: "Login",
                fr: "Se connecter"
            }
        }
    },
    {
        path: 'password/forgot',
        redirectTo: 'language/password/forgot'
    },
    {
        path: ':language/password/forgot',
        canActivate: [LoggedInGuard, LanguageGuard],
        component: ForgotPasswordComponent,
        data: {
            title: {
                en: "Forgot Password",
                fr: "Mot de passe oublié"
            }
        }
    },
    {
        path: 'password/reset/:token',
        redirectTo: 'language/password/reset/:token'
    },
    {
        path: ':language/password/reset/:token',
        canActivate: [LoggedInGuard, LanguageGuard],
        component: ResetPasswordComponent,
        data: {
            title: {
                en: "Reset Password",
                fr: "Réinitialiser le mot de passe"
            }
        }
    },
    {
        path: 'registration',
        redirectTo: 'language/registration'
    },
    {
        path: ':language/registration',
        canActivate: [LoggedInGuard, LanguageGuard],
        component: RegisterComponent,
        data: {
            title: {
                en: "Register",
                fr: "S'inscrire"
            }
        }
    },
    {
        path: 'verification',
        redirectTo: 'language/verification'
    },
    {
        path: ':language/verification',
        canActivate: [AuthGuard, LanguageGuard],
        component: VerificationComponent,
        data: {
            title: {
                en: "Verify",
                fr: "Vérifier"
            }
        }
    },
    {
        path: 'terms',
        redirectTo: 'language/terms'
    },
    {
        path: ':language/terms',
        canActivate: [LanguageGuard],
        component: TermsComponent,
        data: {
            title: {
                en: "Terms and Conditions",
                fr: "Termes et Conditions"
            }
        }
    },
    {
        path: 'privacy',
        redirectTo: 'language/privacy'
    },
    {
        path: ':language/privacy',
        canActivate: [LanguageGuard],
        component: PrivacyComponent,
        data: {
            title: {
                en: "Privacy Policy",
                fr: "Politique de confidentialité"
            }
        }
    },
    {
        path: 'restaurants/categories/:slug',
        redirectTo: 'language/restaurants/categories/:slug'
    },
    {
        path: ':language/restaurants/categories/:slug',
        canActivate: [LanguageGuard],
        component: RestaurantListingComponent
    },
    {
        path: 'restaurants/:slug',
        redirectTo: 'language/restaurants/:slug'
    },
    {
        path: ':language/restaurants/juliette-chocolat',
        canActivate: [LanguageGuard],
        component: RestaurantComponent,
        children: [
            // { path: '', redirectTo: 'about', pathMatch: 'full' },
            // {
            //     path: 'menu-sections',
            //     component: MenuSectionsComponent
            // },
            {
                path: 'menu',
                component: MenuSectionsComponent,
                children: [
                    {
                        path: 'section',
                        component: RestaurantMenuComponent
                    }
                ]
            },
            {
                path: 'about',
                component: AboutComponent
            },
            {
                path: 'story',
                component: StoryComponent
            }
            // {
            //     path: 'reviews',
            //     component: RestaurantReviewsComponent,
            //     resolve: { data: ReviewsResolverService }
            // },
        ],
        resolve: {
            restaurant: RestaurantResolverService
        }
    },
    {
        path: ':language/restaurants/:slug',
        canActivate: [LanguageGuard],
        component: RestaurantComponent,
        children: [
            {
                path: '',
                redirectTo: 'about',
                pathMatch: 'full'
            },
            {
                path: 'menu',
                component: RestaurantMenuComponent
            },
            {
                path: 'about',
                component: RestaurantAboutComponent,
                resolve: {
                    data: ReviewsResolverService
                }
            },
            {
                path: 'reviews',
                component: RestaurantReviewsComponent,
                resolve: {
                    data: ReviewsResolverService
                }
            },
        ],
        resolve: {
            restaurant: RestaurantResolverService
        }
    },
    {
        path: 'restaurants',
        redirectTo: 'language/restaurants'
    },
    {
        path: ':language/restaurants',
        canActivate: [LanguageGuard],
        component: RestaurantListingComponent
    },
    {
        path: 'r/:slug',
        redirectTo: 'language/restaurants/:slug'
    },
    {
        path: ':language/r/:slug',
        redirectTo: ':language/restaurants/:slug'
    },
    {
        path: 'gift-cards/redeem',
        redirectTo: 'language/gift-cards/redeem/',
        pathMatch: 'full'
    },
    {
        path: 'gift-cards/redeem/:code',
        redirectTo: 'language/gift-cards/redeem/:code'
    },
    {
        path: ':language/gift-cards/redeem',
        redirectTo: ':language/gift-cards/redeem/',
        pathMatch: 'full'
    },
    {
        path: ':language/gift-cards/redeem/:code',
        canActivate: [LanguageGuard],
        component: GiftCardRedeemComponent
    },
    {
        path: 'gift-cards/purchase',
        redirectTo: 'language/gift-cards/purchase'
    },
    {
        path: ':language/gift-cards/purchase',
        canActivate: [LanguageGuard],
        component: GiftCardPurchaseComponent,
    },
    {
        path: 'gift-cards/purchase/checkout',
        redirectTo: 'language/gift-cards/purchase/checkout'
    },
    {
        path: ':language/gift-cards/purchase/checkout',
        canActivate: [LanguageGuard],
        component: GiftCardCheckoutComponent
    },
    {
        path: 'checkout',
        redirectTo: 'language/checkout'
    },
    {
        path: ':language/checkout',
        canActivate: [AuthGuard, LanguageGuard],
        component: CheckoutComponent,
        data: {
            title: {
                en: "Checkout",
                fr: "Passer à la caisse"
            }
        }
    },
    {
        path: 'order',
        redirectTo: 'language/order'
    },
    {
        path: ':language/order',
        canActivate: [AuthGuard, LanguageGuard],
        component: OrderFollowupComponent,
        data: {
            title: {
                en: "Order",
                fr: "Commande"
            }
        }
    },
    {
        path: 'account',
        redirectTo: 'language/account'
    },
    {
        path: ':language/account',
        canActivate: [AuthGuard, LanguageGuard],
        component: AccountComponent,
        data: {
            title: {
                en: "Account",
                fr: "Compte"
            }
        }
    },
    // {
    //     path: 'orders',
    //     canActivate: [AuthGuard],
    //     component: OrdersComponent,
    //     data: {
    //         title: {
    //             en: "Orders",
    //             fr: "Commandes"
    //         }
    //     }
    // },
    {
        path: ':language',
        canActivate: [LanguageGuard],
        component: MainComponent,
        pathMatch: 'full',
        data: {
            title: {
                en: "Online Food Delivery",
                fr: "Livraison Gratuite"
            },
            ogTitle: {
                en: "Radish | Online Food Delivery",
                fr: 'Radish | Livraison Gratuite'
            },
            description: {
                en: 'A cooperative platform to bring together Canada\'s restaurants, couriers and food lovers. Order from your favourite restaurant now!',
                fr: 'Une plateforme coopérative pour réunir les restaurants, les livreurs et les gourmands du Québec. Commandez de votre restaurant préféré dès maintenant !'
            },
            ogDescription: {
                en: 'A cooperative platform to bring together Canada\'s restaurants, couriers and food lovers. Order from your favourite restaurant now!',
                fr: 'Une plateforme coopérative pour réunir les restaurants, les livreurs et les gourmands du Québec. Commandez de votre restaurant préféré dès maintenant !'
            }
        }
    },
    {
        path: '**',
        pathMatch: 'full',
        children: [],
        canActivate: [LanguageGuard]
    },
];


@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            // enableTracing: true,
            scrollPositionRestoration: 'enabled',
            anchorScrolling: 'enabled',
            onSameUrlNavigation: 'reload',
            relativeLinkResolution: 'legacy',
            initialNavigation: 'enabledBlocking',
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {

    constructor() {

    }

}
