import { timeout } from 'rxjs';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { ListContent, Product } from 'src/app/core/models';
import { MainService } from 'src/app/core/services/main.service';

const CLICKTIMEOUT = 5000;
@Component({
	selector: 'app-gift-box-item-details',
	templateUrl: './combo-item-details.component.html',
	styleUrls: ['./combo-item-details.component.css'],
	animations: [
		trigger('floatInOut', [
			transition(':enter', [
				style({ transform: 'translateY(50px)', opacity: 0 }),
				animate(150, style({ transform: 'translateY(0)', opacity: 1 })),
			]),
			transition(':leave', [
				animate(150, style({ transform: 'translateY(50px)', opacity: 0 }))
			])
		]),
		trigger('fadeInOut', [
			transition(':enter', [
				style({ opacity: 0 }),
				animate(150, style({ opacity: 1 })),
			]),
			transition(':leave', [
				style({ position: 'absolute', top: 0, left: 0 }),
				animate(150, style({ opacity: 0 }))
			])
		]),
		trigger('fadeOut', [
			transition(':leave', [
				style({ position: 'absolute', top: 0, left: 0 }),
				animate(150, style({ opacity: 0 }))
			])
		]),
		trigger('progressBarToggle', [
			state('done', style({ width: '0%' })),
			state('loading', style({ width: '100%' })),
			transition('done => loading', [
				animate(CLICKTIMEOUT)
			]),
			transition('loading => done', [
				animate(0)
			])
		])
	]
})
export class GiftBoxComboItemDetailsComponent implements OnInit, OnDestroy {
    private readonly SCROLL_OFFSET = 100;

	@Input('showDetails') isShowing: boolean;
	@Input('product') set newProduct(product: Product) {
		this.product = product;
		this.subproductClicked = false;
		this.hoveredSubproduct = null;
		this.oldHoveredSubproduct = null;
        // check if subproducts are scrollable (if the width of the subproducts is bigger than the width of the container)
        setTimeout(() => {
            this.isSubproductScrollable = document.getElementById("subproducts-container").scrollWidth > document.getElementById("subproducts-container").clientWidth;
            }, 10);
        }

	@Output() showDetailsChange = new EventEmitter<boolean>();

	product: Product;
	isLoading = false;
	subproductClicked = false;

	hoveredSubproduct: any;
	oldHoveredSubproduct: any;
	hoverTimeout: any;
	clickTimeout: any;

	checkingAddress: boolean = false;

    isSubproductScrollable: boolean = false;

	constructor(private renderer: Renderer2, private mainService: MainService) {
		this.renderer.setStyle(document.body, 'overflow', 'hidden');
	}

	ngOnInit(): void {

	}

	ngOnDestroy(): void {
		this.renderer.removeStyle(document.body, 'overflow');
	}

	closeDetails() {
		this.showDetailsChange.emit(false);
	}

	onButtonClick(event) {
		if (this.subproductClicked) this.closeDetails();
		else this.addItem(event);
	}

	addItem(event) {
		this.isLoading = true;
		this.mainService.addGiftBoxItem(this.product, 1).subscribe({
			next: () => {
			},
			complete: () => {
        this.isLoading = false;
        this.showDetailsChange.emit(false);
      },
			error: () => this.isLoading = true
		});
		event.preventDefault();
		event.stopPropagation();
	}

	onSubproductHover(event: MouseEvent, subproduct: any) {
		if (this.subproductClicked) return;
		if (this.hoveredSubproduct != subproduct) {
			this.oldHoveredSubproduct = this.hoveredSubproduct;
			this.hoveredSubproduct = null;
		}

		clearTimeout(this.hoverTimeout);
		this.hoverTimeout = setTimeout(() => {
			this.hoveredSubproduct = subproduct;
			this.oldHoveredSubproduct = null;
			// this.resizeHeaderToFit();
		}, 50);
	}

	onSubproductLeave(event: MouseEvent) {
		if (this.subproductClicked) return;
		clearTimeout(this.hoverTimeout);
		this.hoverTimeout = setTimeout(() => {
			this.hoveredSubproduct = null;
			this.oldHoveredSubproduct = null;
		}, 50);
	}

	onSubproductClick(event: MouseEvent, subproduct: any) {
		clearTimeout(this.hoverTimeout);
		clearTimeout(this.clickTimeout);
		this.oldHoveredSubproduct = null;
		this.hoveredSubproduct = subproduct;

		this.subproductClicked = false;
		setTimeout(() => {
			this.subproductClicked = true;
		}, 10);
		// added slight timeout to allow for smooth crossfade
		// this.hoverTimeout = setTimeout(() => {
		// 	this.hoveredSubproduct = subproduct;
		// 	this.oldHoveredSubproduct = null;
		// }, 10);

		this.clickTimeout = setTimeout(() => {
			this.hoveredSubproduct = null;
			this.oldHoveredSubproduct = null;
			this.subproductClicked = false;
		}, CLICKTIMEOUT);


	}

	closeSubproduct() {
		clearTimeout(this.hoverTimeout);
		this.hoveredSubproduct = null;
		this.subproductClicked = false;
	}

	// resizeHeaderToFit() {
	// 	let header = document.getElementById('gift-box-header-container');
	// 	let headerText = document.getElementById('gift-box-header');
	// 	let headerTextRect = headerText.getBoundingClientRect();
	// 	let headerRect = header.getBoundingClientRect();
	// 	let originalFontSize = parseFloat(headerText.style.fontSize);
	// 	console.log(headerTextRect.width, headerRect.width);
	// 	// try to resize the header to fit the text
	// 	while (headerTextRect.width > headerRect.width && headerText.style.fontSize != '0.5rem') {
	// 		let fontSize = parseFloat(headerText.style.fontSize);
	// 		console.log(fontSize);
	// 		headerText.style.fontSize = (fontSize - 0.1) + 'rem';
	// 		headerTextRect = headerText.getBoundingClientRect();
	// 	}
	// }

    onScroll(event: any) {
        // TODO?? scroll not intuitive on trackpad if implement below

		// const scrollElement = document.getElementById("subproducts-container");
        // // check if scrollY or scrollX is bigger
        // let maxScroll = (Math.abs(event.deltaY) > Math.abs(event.deltaX)) ? event.deltaY : event.deltaX;
        // scrollElement.scrollLeft += maxScroll;
	}

	onClickScroll(direction: string) {
        const scrollElement = document.getElementById("subproducts-container");
        let scroll;
        if (direction == 'left') scroll = -(scrollElement.offsetWidth - this.SCROLL_OFFSET);
        else scroll = scrollElement.scrollLeft + scrollElement.offsetWidth - this.SCROLL_OFFSET
		// const scroll = scrollElement.scrollLeft + scrollElement.offsetWidth - this.SCROLL_OFFSET
		scrollElement.scrollBy({
			top: 0,
			left: scroll,
			behavior: 'smooth'
		});
	}
}
