import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbAccordion } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'expo-bag-note',
    templateUrl: './bag-note.component.html',
    styleUrls: ['./bag-note.component.css']
})
export class ExpoBagNoteComponent implements OnInit {

    @ViewChild('acc') accordionComponent: NgbAccordion;
    
    @Output() note = new EventEmitter<any>();
    @Output() notePanelOpen = new EventEmitter<boolean>();

    expoNoteForm: FormGroup;

    constructor(
        private formBuilder: FormBuilder
    ) {
        this.expoNoteForm = this.formBuilder.group({
            notes: ['']
        });
    }

    ngOnInit(): void {
    }

    checkPanelOpen($event) {
        if (this.accordionComponent.isExpanded($event.panelId)) this.notePanelOpen.emit(true);
    }

    onSubmit(){
        //eitehr check note changes elsewhere and pass to parent component with output or assigning to variable 
        //this.note.emit();
    }



}
