<div class="expo-footer-container" *ngIf="showFooter">
    <div class="expo-footer-sponsored-title">{{ 'expo.footer.powered' | transloco }}</div>
    <img class="expo-footer-radish-logo" src="../../../assets/images/radish-logos/Radish-Logo-BlackText-Large.png" alt="radish-logo">
    <div class="expo-footer-social-container">
        <a href="https://facebook.com/radish.coop" target="_blank" title="">
            <img class="expo-footer-social-logo" src="../../../assets/images/social-media-icons/facebook-logo-black.png" alt="Facebook">
        </a>
        <a href="https://www.instagram.com/radish.coop/" target="_blank" title="">
            <img class="expo-footer-social-logo" src="../../../assets/images/social-media-icons/instagram-logo.png" alt="Instagram">
        </a>
        <a href="https://twitter.com/RadishCoop" target="_blank" title="">
            <img class="expo-footer-social-logo" src="../../../assets/images/social-media-icons/twitter-logo.png" alt="Twitter">
        </a>
        <a href="https://www.linkedin.com/company/radishcoop" target="_blank" title="">
            <img class="expo-footer-social-logo" src="../../../assets/images/social-media-icons/linkedin-logo-black.png" alt="LinkedIn">
        </a>
        <a href="https://www.tiktok.com/@radish.coop" target="_blank" title="">
            <img class="expo-footer-social-logo" src="../../../assets/images/social-media-icons/TikTok_Icon_Black_Circle.png" alt="TikTok">
        </a>
    </div>
    <div class="expo-footer-details-container">
        <div class="expo-footer-details">
            <div class="expo-footer-details-text" [routerLink]="'/' + translate.getActiveLang() + '/privacy'">{{ 'expo.footer.details.privacy' | transloco }}</div>
            <div class="expo-footer-details-text" [routerLink]="'/' + translate.getActiveLang() + '/terms'">{{ 'expo.footer.details.terms' | transloco }}</div>
    </div>
        <div class="expo-footer-dropdown" ngbDropdown>
            <button type="button" class="btn expo-footer-dropdown-button" id="dropdownConfig" ngbDropdownToggle>{{ selectedLanguage }}</button>
            <div ngbDropdownMenu aria-labelledby="dropdownConfig">
                <button ngbDropdownItem (click)="useLanguage('fr')">Français</button>
                <button ngbDropdownItem (click)="useLanguage('en')">English</button>
            </div>
        </div>
    </div>
    <div class="expo-footer-copyright-container">
        <div class="expo-footer-copyright-wrapper">
            <span class="expo-footer-copyright-text">{{ 'expo.footer.copyright.madeWith' | transloco }}</span>
            <i class="fas fa-heart footer-made-with-heart"></i>
        </div>
        <div class="expo-footer-copyright-text" [innerHTML]="'expo.footer.copyright.text' | transloco"></div>
    </div>
</div>