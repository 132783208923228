import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
    selector: 'app-location-map',
    templateUrl: './location-map.component.html',
    styleUrls: ['./location-map.component.css']
})
export class LocationMapComponent implements OnInit, OnChanges {

    @Input() latitude: number
    @Input() longitude: number
    @Input() zoom: number
    @Input() styles: any
    @Input() radius: number
    @Input() radiusFillColor: string
    @Input() showRadius: boolean

    mapZoom: number
    mapLat: number
    mapLng: number

    options: google.maps.MapOptions

    constructor() { }

    ngOnChanges(): void {
        this.recenterMap()
    }

    ngOnInit(): void {
        this.options = {
            disableDefaultUI: true,
            styles: this.styles
        }
    }

    private recenterMap() {
        this.latitude = Number(this.latitude + (0.0000000000100 * Math.random()));
        this.longitude = Number(this.longitude + (0.0000000000100 * Math.random()));
    }

}
