import { Component, Input, OnInit } from '@angular/core';
import { Product } from 'src/app/core/models';

@Component({
  selector: 'app-products-market-row',
  templateUrl: './products-market-row.component.html',
  styleUrls: ['./products-market-row.component.css']
})
export class ProductsMarketRowComponent implements OnInit {

  @Input() products: Product[];
  @Input() showRestaurantName?: boolean = true;
  
  constructor() { }

  ngOnInit(): void {
  }

}
