<!-- TODO: TRANSLOCO -->
<!-- <ng-template #cratePickupScheduler> -->
    <div class="crate-pickup-scheduler-container">
        <h2 class="heading">Schedule your crate pickup</h2>
        <h3 class="subheading">Yo we need our crates back cus they are expeez and we don’t waste thanks bb.</h3>
        <form class="schedule-crate-pickup-form" [formGroup]="scheduleCratePickupForm" (ngSubmit)="onSubmit()">
            <div class="form-group-container">
                <!-- Date Section -->
                <section class="date-section">
                    <div class="section-title-container">
                        <i class="calendar-icon far fa-calendar-alt"></i>
                        <h4 class="section-title date-section-title">May 1 - 7</h4>
                    </div>
                    
                    <div class="select-date-container">    
                        <!-- <div class="current-week-container">
                            <h5 class="current-week-text">May 1 - 7</h5>
                            <div [innerHTML]="getCurrentWeek()"></div>
                        </div> -->
                        <div class="date-list-container">
                            <button type="button" class="see-previous-week-btn" 
                                    (click)="seePreviousWeek()" aria-label="See previous week">
                                <i class="see-previous-week-icon fas fa-caret-left"></i>
                            </button>
                            <ul class="date-list">
                                <li *ngFor="let date of dates" id="selected-date" class="date-list-item" 
                                    formControlName="selectedDate" [value]="setSelectedDate()" 
                                    [ngClass]="{
                                        'date-enabled': date.isDeliveryAvailable,
                                        'date-disabled': !date.isDeliveryAvailable,
                                        'is-today': date.isToday,
                                        'default-selected-date': date.isDefaultDate
                                    }">
                                    <div class="week-day">{{ date.weekDay }}</div>
                                    <div class="day-of-month">{{ date.dayOfMonth }}</div>
                                </li>
                            </ul>
                            
                            <button type="button" class="see-next-week-btn"
                                    (click)="seeNextWeek()" aria-label="See next week">
                                <i class="see-next-week-icon fas fa-caret-right"></i>
                            </button>
                        </div>
                    </div>
                </section>
                
                <!-- Time Section -->
                <section class="time-section">
                    <div class="section-title-container">
                        <i class="clock-icon far fa-clock"></i>
                        <h4 class="section-title time-section-title">Pickup Time</h4>
                    </div>
                    <!-- TODO: change custom class lol -->
                    <ng-select name="time-select" formControlName="selectedTime" class="custom" 
                               [selectOnTab]="true" [searchable]="false" notFoundText="No pickup times available"
                               placeholder="Select a time window...">
                        <ng-option *ngFor="let pickupTime of pickupTimes" [value]="pickupTime.time">
                            {{ pickupTime.time }}
                        </ng-option>
                    </ng-select>
                </section>
            </div>
        
            <button type="submit" class="confirm-btn">Confirm</button>
        </form>  
    </div>
<!-- </ng-template> -->
