import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PlatformLocation } from '@angular/common';
import { TranslocoService } from '@ngneat/transloco';
import { Product, TranslatedText, TranslatedTextBasic, TranslatedTextType } from 'src/app/core/models';
import { IMenuFavorite, IMenuFavoriteRaw } from 'src/app/online-ordering/restaurant/restaurant-about/restaurant-about-menu-favorites/restaurant-about-menu-favorites.component';

export enum FeatureTypeEnum {
    MenuFavorite,
    Product
}

export type Feature = IMenuFavorite | IProductFeature

export interface IFeature {
    title?: TranslatedTextType;
    description?: TranslatedTextType;
    image?: string;
    type: FeatureTypeEnum,
}

export interface IProductFeature extends IFeature {
}

@Component({
    selector: 'app-menu-favorite',
    templateUrl: './menu-favorite.component.html',
    styleUrls: ['./menu-favorite.component.css']
})
export class MenuFavoriteComponent implements OnInit {
    @Input() favorite: Feature

    title: string;
    description: string;

    FeatureType = FeatureTypeEnum

    constructor(private router: Router,
		public activeModal: NgbActiveModal,
        private location: PlatformLocation,
        public translate: TranslocoService) {
            location.onPopState(() => this.activeModal.close())
        }

    ngOnInit(): void {
        const title = this.favorite?.title
        if (title) {
            this.title = typeof title === 'string' ? title : title[this.translate.getActiveLang()]
        }
        const description = this.favorite?.description
        if (description) {
            this.description = typeof description === 'string' ? description : description[this.translate.getActiveLang()]
        }
    }

    goToMenuClicked() {
        this.activeModal.close();
        const feature = this.favorite as IMenuFavorite
        this.router.navigate([this.translate.getActiveLang() + '/restaurants/' + feature.restaurantSlug + '/menu']);
    }

    static createFeatureFromProduct(product: Product): IFeature {
        return {
            ...(product.title ? { title: product.title as string } : {}),
            ...(product.description ? { description: product.description as string } : {}),
            ...(product.imageUrl ? { image: product.imageUrl } : {}),
            type: FeatureTypeEnum.Product
        }
    }

    static createFeatureFromMenuFavorite(menuFavorite: IMenuFavorite): IMenuFavorite {
        return {
            ...(menuFavorite.title ? { title: menuFavorite.title } : {}),
            ...(menuFavorite.description ? { description: menuFavorite.description } : {}),
            ...(menuFavorite.image ? { image: menuFavorite.image } : {}),
            restaurantSlug: menuFavorite.restaurantSlug,
            type: FeatureTypeEnum.MenuFavorite
        }
    }

}
