import { Component, Input, OnInit } from '@angular/core';
import Icon from '../icon.interface'

@Component({
  selector: 'app-chevron-left',
  templateUrl: './chevron-left.component.html',
  styleUrls: ['./chevron-left.component.css']
})
export class ChevronLeftComponent implements OnInit, Icon {

    @Input() fill: string

    constructor() { }

    ngOnInit(): void {
    }

}
