<!-- Gift Card Purchase Tab -->

<div class="gift-card-purchase-container">
    <h2>Send a Radish E-Gift Card</h2>
    <h3>Create a personalized Radish e-gift card blah blah.</h3>
    
    <!-- FORM -->
    
    <form>
        <div>
            <label>
                Title
                <input type="text" placeholder="eg. Alex's Radish Gift Card">
            </label>
        </div>
        <br>
        <div>
            <label>
                Value
                <input type="radio" value="15"> $15
                <input type="radio" value="25"> $25
                <input type="radio" value="50"> $50
                <br>
                <input type="text" placeholder="$ Custom">
            </label>
        </div>
        <br>
        <div>
            <fieldset>
                Who's it for?
                <br>
                <label>
                    Recipient Name
                    <input type="text">
                </label>
                <br>
                <label>
                    Recipient Email
                    <input type="email">
                </label>
            </fieldset>
        </div>
        <br>
        <div>
            <label>
                Custom Message
            </label>
            <br>
            <textarea cols="30" rows="10" placeholder="Write something nice..."></textarea>
        </div>
        <br>
        <div>
            <fieldset>
                Who's it from?
                <br>
                <label>
                    Sender Name
                    <input type="text">
                </label>
                <br>
                <label>
                    Sender Email
                    <input type="email">
                </label>
            </fieldset>
        </div>
    
    </form>
    
    
    
    
    <button type="submit">Purchase</button>
    
</div>